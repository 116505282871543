<div class="cat-grade-edit-sno">
  <dsp-next-gen-ui-cat-grade-asset-details
    [rowData]="rowData"
    [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
    [showDealerInfo]="true"
  ></dsp-next-gen-ui-cat-grade-asset-details>
</div>
<div *ngIf="loader">
  <div class="spin-align">
    <div class="spinner"></div>
  </div>
  <div class="spin-text">
    <span>One moment, we are calculating your<br />license charges...</span>
  </div>
</div>
<!-- <div class="cat-grade-table">
  <cc-table [tableConfig]="catPaymentPreviewTableConfig" [tableData]="catPaymentPreviewTableData"></cc-table>
</div> -->
<div class="rowTbl cat-grade-table" *ngIf="!loader">
  <div class="Table">
    <div class="Row rowHead">
      <div class="CellHead">
        <div class="headerCell">Component</div>
      </div>
      <div class="CellHead">
        <div class="headerCell">Serial Number</div>
      </div>
      <div class="CellHead">
        <div class="headerCell">License</div>
      </div>
      <div class="CellHead">
        <div class="headerCell">Price</div>
      </div>
      <div class="CellHead">
        <div class="headerCell">Promotion</div>
      </div>
      <div class="CellHead">
        <div class="headerCell">Discount</div>
      </div>
      <div class="CellHead priceCell">
        <div class="headerCell">Total</div>
      </div>
    </div>
    <div class="Row" *ngFor="let previewDetails of catPaymentPreviewTableData">
      <div class="Cell">
        <div class="total-plan">{{ previewDetails.type }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan">{{ previewDetails.serialNumber }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan">{{ previewDetails.nextUpgradableLicenseDesc }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan">{{ displayAmount(previewDetails.price) }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan">{{ previewDetails.promotion }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan">{{ displayAmount(previewDetails.discount) }}</div>
      </div>
      <div class="Cell">
        <div class="total-plan-price-cell">{{ displayAmount(previewDetails.total) }}</div>
      </div>
    </div>
  </div>
  <div class="Table">
    <div class="Row cancel-plan-total">
      <div class="Cell">
        <div class="est-total">Estimated Total</div>
      </div>
      <div class="Cell priceCell">
        <div class="total-price">{{ displayAmount(estimatedTotal) }}</div>
      </div>
    </div>
  </div>
</div>

<div class="info-container cat-grade-edit-sno" *ngIf="!loader">
  <div class="info-row">
    <img src="..\assets\images\info-icon.svg" class="info-icon-style" /> &nbsp;&nbsp;This is a one-time non-refundable
    license fee applicable for grade control hardware. By submitting your order, you are agreeing to our&nbsp;
    <a (click)="termsOfModal()" target="_blank" class="tos-hrperlink">Terms of Sale</a>
  </div>
  <div class="info-row">
    <span class="indent"
      >Assets with Grade Connectivity plan will have the licenses automatically sent to the Grade Control System. To
      manually download licenses, please return to the asset details page after purchase.</span
    >
  </div>
  <div class="info-row">
    <span class="indent"
      >Software Enabled Attachments may be bundled with your Grade selection. Any applicable entitlements (credits) will
      be available within Electronic Technician.</span
    >
  </div>
  <div class="info-row">
    <span class="help-center-indent">
      For questions on activating a Cat Grade license, please see the&nbsp;<a
        (click)="helpCenterLink()"
        target="_blank"
        class="dsp-help-hyperlink"
        >DSP Help Center.</a
      >
    </span>
  </div>
</div>
