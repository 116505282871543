import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dspNextGenUiNumericOnly]',
})
export class NumericOnlyDirective {
  constructor(private e1?: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const inputElement = this.e1?.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    inputElement.value = value.replace(/[^0-9]/g, '');
  }
}
