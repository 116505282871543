import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
    LOAD_USER_REQUEST = '[User] Load User Request',
    LOAD_USER_FAILURE = '[User] Load User Failure',
    LOAD_USER_SUCCESS = '[User] Load User Success',
    LOAD_USER_MENU_REQUEST = '[User] Load User Menu Request',
    LOAD_USER_MENU_FAILURE = '[User] Load User Menu Failure',
    LOAD_USER_MENU_SUCCESS = '[User] Load User Menu Success',
    LOAD_DEALER_REQUEST = '[Dealer] Load Dealer Request',
    LOAD_DEALER_FAILURE = '[Dealer] Load Dealer Failure',
    LOAD_DEALER_SUCCESS = '[Dealer] Load Dealer Success'
}

export const loadUserRequestAction = createAction(
    ActionTypes.LOAD_USER_REQUEST
);
   
export const loadUserFailureAction = createAction(
  ActionTypes.LOAD_USER_FAILURE,
  props<{ error: string }>()
);
  
export const loadUserSuccessAction = createAction(
  ActionTypes.LOAD_USER_SUCCESS,
  props<any>()
);

export const loadDealerRequestAction = createAction(
    ActionTypes.LOAD_DEALER_REQUEST,
    props<{ body: any }>()
);
   
export const loadDealerFailureAction = createAction(
  ActionTypes.LOAD_DEALER_FAILURE,
  props<{ error: string }>()
);
  
export const loadDealerSuccessAction = createAction(
  ActionTypes.LOAD_DEALER_SUCCESS,
  props<any>()
);

// User menu
export const loadUserMenuRequestAction = createAction(
  ActionTypes.LOAD_USER_MENU_REQUEST
);
 
export const loadUserMenuFailureAction = createAction(
ActionTypes.LOAD_USER_MENU_FAILURE,
props<{ error: string }>()
);

export const loadUserMenuSuccessAction = createAction(
ActionTypes.LOAD_USER_MENU_SUCCESS,
props<any>()
);