import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'dsp-next-gen-ui-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
})
export class UserAdminComponent {
  isTheme = '2.0';
  userAdminForm: FormGroup = new FormGroup({});
  cwsIdControl: any;
  lastNameControl: any;
  disableSearchBtn = true;
  disableClearAllBtn = true;
  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.userAdminForm = this.formBuilder.group({
      cwsId: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.cwsIdControl = this.userAdminForm.get('cwsId');
    this.lastNameControl = this.userAdminForm.get('lastName');

    this.cwsIdControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.disableClearAllBtn = false;
      if (value.length >= 3) {
        this.lastNameControl.clearValidators();
        this.lastNameControl.updateValueAndValidity();
        this.disableSearchBtn = false;
        if (this.lastNameControl.value) {
          this.lastNameControl.setValidators(null);
          this.lastNameControl.updateValueAndValidity();
        }
      } else {
        if (this.lastNameControl.value.length >= 3 && this.cwsIdControl.value === '') {
          this.cwsIdControl.clearValidators();
          this.cwsIdControl.updateValueAndValidity();
          this.disableSearchBtn = false;
        } else {
          this.cwsIdControl.setValidators([Validators.required, Validators.minLength(3)]);
          this.cwsIdControl.updateValueAndValidity();
          this.disableSearchBtn = true;
          if (this.lastNameControl.value === '' && this.cwsIdControl.value === '') {
            this.disableClearAllBtn = true;
            this.cwsIdControl.clearValidators();
            this.cwsIdControl.updateValueAndValidity();
          }
        }
      }
      this.cdr.detectChanges();
    });

    this.lastNameControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.disableClearAllBtn = false;
      if (value.length >= 3) {
        this.cwsIdControl.clearValidators();
        this.cwsIdControl.updateValueAndValidity();
        this.disableSearchBtn = false;
        if (this.cwsIdControl.value) {
          this.cwsIdControl.setValidators(null);
          this.cwsIdControl.updateValueAndValidity();
        }
      } else {
        if (this.cwsIdControl.value.length >= 3 && this.lastNameControl.value === '') {
          this.lastNameControl.clearValidators();
          this.lastNameControl.updateValueAndValidity();
          this.disableSearchBtn = false;
        } else {
          this.lastNameControl.setValidators([Validators.required, Validators.minLength(3)]);
          this.lastNameControl.updateValueAndValidity();
          this.disableSearchBtn = true;
          if (this.lastNameControl.value === '' && this.cwsIdControl.value === '') {
            this.disableClearAllBtn = true;
            this.lastNameControl.clearValidators();
            this.lastNameControl.updateValueAndValidity();
          }
        }
      }
      this.cdr.detectChanges();
    });
  }

  get formControl() {
    return this.userAdminForm.controls;
  }

  clearAll() {
    if (this.cwsIdControl.value.length > 0 || this.lastNameControl.value.length > 0) {
      this.userAdminForm.setValue({ cwsId: '', lastName: '' });
      this.disableSearchBtn = true;
      this.disableClearAllBtn = true;
    }
  }

  onSearchUser() {}
}
