export const OVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort A to Z',
  },
  {
    name: 'dsc',
    title: 'Sort Z to A',
  },
];
export const NEEDSREVIEWOVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort Oldest to Newest',
  },
  {
    name: 'dsc',
    title: 'Sort Newest to Oldest ',
  },
];
export const NeedsReviewDefaultData = {
  dealerCode: 'TD00',
  dealerQueueReasonId: '48',
  status: 'Active',
  offset: 0,
  limit: 20,
  sortBy: 'eventDate',
  sortOrder: 'desc',
  operation: '',
  filter: {},
  isSortOperation: false,
  searchText: '',
  displayColumns: [],
};

export const DATEANDTIME = [
  {
    name: 'None',
    checked: false,
  },
  {
    name: 'Today',
    checked: false,
  },
  {
    name: 'Yesterday',
    checked: false,
  },
  {
    name: 'Last 7 Days',
    checked: false,
  },
  {
    name: 'Last 30 Days',
    checked: false,
  },
  { name: 'Custom Range', checked: false },
];

export const PROMOTIONCONTRACTDATEANDTIME = [
  { name: 'None', checked: false },
  { name: 'Today', checked: false },
  { name: 'Custom Range', checked: false },
];

export const ReviewColumnsList = {
  make: [],
  model: [],
  manufacturingYear: [],
  deviceType: [],
  ucid: [],
  ucidName: [],
  dealerCustomerNumber: [],
  dcnName: [],
  reportingState: [],
  cellularRadioType: [],
  satelliteRadioType: [],
  ucidNameWithId: [],
  dcnWithName: [],
};
export const RADIOTYPECOLUMNS = {
  Cellular: 'cellularRadioType',
  Satellite: 'satelliteRadioType',
};
