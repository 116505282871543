<div class="cc-drawer">
  <div class="cc-drawer__header" id="manage-asset">
    <svg
      *ngIf="launchType === 'DRAWER'"
      class="cc-drawer__leadingIcon"
      (click)="closeDrawer()"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 5.89258H1"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.3961L1 5.89139L6 1.38672"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="cc-headerText cc-drawer__ellipsis"> Manage Plan </span>
    <div class="cc-drawer__headerAction">
      <div><span class="state-pipe">|</span></div>
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="asset-manage-tracker" id="manage-asset-subscription-tracker" *ngIf="!isOwnershipApiCallInProgress">
      <cc-progress-tracker
        #manageAssetSubscriptionTracker
        [activeStepIndex]="trackerIndex"
        [trackerData]="dynamicTrackerData"
        [isTheme]="isTheme"
      >
      </cc-progress-tracker>
    </div>
    <div *ngIf="setFilterAssetsFlag && setAssetOwnershipFlag">
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Select Ownership'">
        <div class="asset-ownership">
          <dsp-next-gen-ui-select-ownership-menu
            [rowData]="selectedRowData"
            [hasPartialOwnershipData]="hasPartialOwnershipData"
            [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
            (retrieveSyncedOwnership)="fetchOwnershipInfo()"
          ></dsp-next-gen-ui-select-ownership-menu>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Select Plan'">
        <dsp-next-gen-ui-select-plan-menu
          (loadCancelScreen)="loadCancelScreen($event)"
          [rowData]="selectedRowData"
          (updateTrackerIndex)="updateTrackerIndex()"
          [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
          (redirectToSite)="redirectToSitePage($event)"
          [productData]="products"
          [productOrderList]="productList"
        ></dsp-next-gen-ui-select-plan-menu>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Payment Preview'">
        <dsp-next-gen-ui-payment-preview-menu
          [rowData]="selectedRowData"
          [subsPricingEligible]="subsPricingEligible"
          [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
          (updateTrackerIndex)="updateTrackerIndex()"
          [dynamicTrackerData]="dynamicTrackerData"
          [currentPageIndex]="currentPageIndex"
        ></dsp-next-gen-ui-payment-preview-menu>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Billing'">
        <dsp-next-gen-ui-billing-menu
          [hideBillToPartyOwnership]="true"
          [rowData]="selectedRowData"
          [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
          (updateTrackerIndex)="updateTrackerIndex()"
          [dynamicTrackerData]="dynamicTrackerData"
          [currentPageIndex]="currentPageIndex"
        ></dsp-next-gen-ui-billing-menu>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Select Cancellation Reason'">
        <dsp-next-gen-ui-select-cancellation-reason-menu
          [rowData]="selectedRowData"
          (updateTrackerIndex)="updateTrackerIndex()"
          [subsPricingEligible]="subsPricingEligible"
          [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
        ></dsp-next-gen-ui-select-cancellation-reason-menu>
      </ng-container>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button
      id="asset-manage-drawer-cancelBtn"
      cc-btn-secondary
      cc-btn-large
      [setTheme]="true"
      [disabled]="setBackDisabled()"
      (click)="onPrevious()"
      [ngClass]="
        (this.trackerIndex === 0 && this.launchType === 'DRAWER') ||
        (this.trackerIndex === 1 && this.assetRowData?.selectedAssetDetails?.reportingState === 'Subscribed')
          ? 'hide-back-button'
          : ''
      "
    >
      {{ updateCancelButtonLabel() }}
    </button>
    <button
      id="asset-manage-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="setNextDisabled()"
      (click)="onNext()"
    >
      {{ updateSubmitButtonLabel() }}
    </button>
  </div>
</div>

<ng-template #ccsMessage>
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Information</div>
    </div>
    <div class="cc-modal-content">
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(ccsWarningMessage || '')"></div>
    </div>
    <div class="cc-modal-footer">
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="handleClose()">OK</button>
    </div>
  </div>
</ng-template>

<ng-template #cancelTemplateSuspendRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content modal-content">
      <p>
        Your selection indicates that you are choosing to suspend all transmission from this device. To reverse the
        suspension, you will have to contact product support. Do you still want to proceed?
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeCancelSubscriptionModalCancelReason()">
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="checkPrepay()">Submit</button>
    </div>
  </div>
</ng-template>
<ng-template #prePayApplyRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content modal-content">
      <p>
        You have chosen a prepaid fixed-term subscription contract. The prepayment associated to these services may be
        non-refundable. Do you want to continue?
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeCancelSubscriptionModal()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeCancelSubscriptionModal(true)">Submit</button>
    </div>
  </div>
</ng-template>
<ng-template #cancelTemplatePrepayRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content modal-content">
      <p>
        You are cancelling or modifying a prepaid fixed-term subscription contract. The prepayment associated to these
        services may be non-refundable. Do you want to continue?
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeCancelSubscriptionModalCancelReason()">
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeCancelSubscriptionModalCancelReason(true)">
        Submit
      </button>
    </div>
  </div>
</ng-template>
<ng-template #EC520TemplateRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header header-display">
      <div class="cc-modal-title title-Display">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content modal-content">
      <p class="ec520Msg">
        {{ ec520Message }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeEC520PopupModal()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeEC520PopupModal(true)">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #b2cCustWarningTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>
        {{ languageTranslations?.Worklist?.WL_WARNING_MSG_CONTENT }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeB2CWarningPopup(false)">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeB2CWarningPopup(true)">Submit</button>
    </div>
  </div>
</ng-template>
