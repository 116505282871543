<div class="billing" *ngIf="!showBillingParent">
  <div class="billing-main" *ngIf="!showDetailedInvoice">
    <div class="cc-table-pag-header-left billing-sub-header">
      <div class="billing-header">
        <div
          class="billing-search"
          [ngClass]="{
            helptextStyle: searchHelpText !== '',
            textboxField: searchValue && searchValue.value?.length > 0
          }"
        >
          <cc-input-field
            [labelValue]="'Enter Invoice # to search (Min 3 Char)'"
            [inputtype]="'search'"
            isTheme="2.0"
            id="cc-atom-search-s7"
            [value]="searchValue?.value"
            [searchMenuHeader]="mobileHeader"
            [triggerSearchMinChar]="3"
            (valueChange)="onSearchChange($event)"
            [enableMobileOverlay]="false"
            [isPageControl]="true"
            [isLeading]="true"
            [helpText]="searchHelpText"
            [disabled]="isDataLoading"
          >
            <span class="searchIcon"> </span>
            <input
              #billingSearchBox
              input-field
              [formControl]="searchValue"
              class="input-field input-search-width"
              [value]="searchValue.value"
              autocomplete="off"
              [isFloating]="false"
              type="search"
              aria-labelledby="Search Help Text"
              aria-describedby="Search Help Text"
              (click)="onCickEvent($event)"
              (blur)="onBlurEvent($event)"
              (focus)="onFocusEvent()"
              (valueChange)="onSearchChange($event)"
              (keydown.enter)="
                searchValue.value.trim().length > 2 ? dataLoaded() : '';
                searchValue.value.trim().length > 2 ? getBillingDetails(true) : ''
              "
            />
            <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                  fill="#DF3826"
                />
              </svg>
            </span>
            <span
              class="help-text-error"
              *ngIf="searchValue && searchValue.value?.length > 0 && searchValue.value?.length <= 2"
            >
              Please enter atleast 3 characters
            </span>
          </cc-input-field>
          <span
            class="searchButton"
            (click)="
              searchValue.value.trim().length > 2 ? dataLoaded() : '';
              searchValue.value.trim().length > 2 ? getBillingDetails(true) : ''
            "
          >
            <a
              cc-btn-primary
              cc-btn-large
              class="primary button-opacity"
              [setTheme]="true"
              [disabled]="!enableSearch || isDataLoading"
              ><svg width="16" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                  fill="black"
                />
              </svg>
            </a>
          </span>
        </div>
      </div>
      <div *ngIf="!isRemoveBillingInquiryButton" class="billing-right-panel">
        <span class="billing-panel-items">
          <a
            cc-btn-primary
            cc-btn-large
            class="primary button-opacity"
            [setTheme]="true"
            (click)="billingInquiryForm()"
          >
            Billing Inquiry</a
          >
        </span>
      </div>
    </div>
    <div class="billing-table" [ngClass]="{ 'table-content': !isDataLoading }">
      <cc-table
        [ngClass]="{ disable: isDataLoading }"
        #myTable1
        *ngIf="enablebillingTable"
        aria-describedby="billing Table"
        [tableConfig]="billingTableConfig"
        [tableData]="billingTableData"
        [isDataLoading]="isDataLoading"
        (ccTableEvents)="tableEvents($event)"
        #cctable="cctable"
      >
        <ng-container *ngFor="let config of columnConfig">
          <cc-table-column
            [name]="config.name"
            [title]="config.title"
            [minWidth]="config.width"
            [priority]="config.priority"
            [isVisible]="config.isVisible"
            [sortingEnable]="config.sortingEnable"
            [ascendingSort]="config.ascendingSort"
            [sortWithOverlay]="config.sortWithOverlay"
            [overlayData]="config.overlayData"
            contentType="span"
          >
            <ng-template let-column="column" cc-table-header-template>
              <span class="header-cell-content">{{ config.title }}</span>
            </ng-template>
            <ng-template let-rowIndex="rowIndex" let-rowdata="rowdata" cc-table-cell-template>
              <div
                *ngIf="config.name !== 'profileType'"
                [ngClass]="{
                  'hide-expandable-row': !(rowdata.creditMemos && rowdata.creditMemos.length > 0),
                  'invoice-field': config.name === 'invoiceNumber'
                }"
              >
                <span
                  ><span
                    class="detailCls"
                    (click)="openDetailedInvoice(rowdata)"
                    *ngIf="config.name === 'invoiceNumber'"
                    [innerHTML]="rowdata[config.name] | highlightSearch: searchString"
                  ></span
                  ><span
                    class="detailClass"
                    *ngIf="
                      config.name !== 'invoiceNumber' &&
                      config.name !== 'outstandingAmount' &&
                      config.name === 'invoicePeriod'
                    "
                    >{{ rowdata[config.name] }}</span
                  ><span
                    class="detailClass"
                    *ngIf="
                      config.name !== 'invoiceNumber' &&
                      config.name !== 'outstandingAmount' &&
                      config.name === 'invoiceAmount'
                    "
                    >{{ convertNumberFormat(rowdata[config.name])
                    }}<span class="detailClass" *ngIf="config.name === 'invoiceAmount' && rowdata[config.name]"
                      >&nbsp;{{ rowdata.currency }}</span
                    ></span
                  >
                  <span [ngClass]="{ spinnerCls: !rowdata[config.name] }" *ngIf="config.name === 'outstandingAmount'">
                    <span class="detailClass" *ngIf="rowdata[config.name] && rowdata[config.name].length">{{
                      rowdata[config.name]
                    }}</span>
                    <div class="spinner" *ngIf="!rowdata[config.name]">
                      <dsp-next-gen-ui-spot-loader></dsp-next-gen-ui-spot-loader>
                    </div>
                  </span>
                </span>

                <span *ngIf="config.name === 'invoiceNumber'" class="site-panel-items download-container">
                  <div
                    class="menu-content"
                    cc-menu
                    [template]="singleSelectTemplate"
                    [OverlayPosition]="'bottomright'"
                    [label]="'Title'"
                    *ngIf="!rowdata?.isSelected; else downloadLoader"
                  >
                    <div class="ellipse-container" id="cc-menu1" cc-tooltip="Export">
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.16626 6.66699H8.83293V12.3813H6.16626V6.66699Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.49935 16.667L10.8327 10.9527H4.16602L7.49935 16.667Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.58333 20H14.25C15.1208 20 15.8333 19.2742 15.8333 18.3871V7.95161C15.8333 7.51613 15.675 7.1129 15.3742 6.80645L9.15167 0.467742C8.85083 0.16129 8.455 0 8.0275 0H1.58333C0.7125 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.7125 20 1.58333 20ZM0.632324 1.61325C0.632324 1.08099 1.05982 0.645508 1.58232 0.645508H8.02649C8.27982 0.645508 8.51732 0.742282 8.69149 0.93583L14.914 7.27454C15.104 7.45196 15.199 7.69389 15.199 7.95196V18.3874C15.199 18.9197 14.7715 19.3552 14.249 19.3552H1.58232C1.05982 19.3552 0.632324 18.9197 0.632324 18.3874V1.61325Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                  <ng-template #downloadLoader>
                    <div class="loader-container">
                      <div class="loader-container__label"></div>
                      <div>
                        <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                            fill="#43485C"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                            fill="#43485C"
                          />
                        </svg>
                      </div>
                    </div>
                  </ng-template>
                </span>
              </div>
              <ng-template #singleSelectTemplate>
                <div class="cc-contextual-menu" id="cc-single-menu1">
                  <cc-list [theme]="'secondary'" width="1000px">
                    <cc-list-item *ngFor="let item of listItems" (listItemClick)="onListItemClick(item.label, rowdata)">
                      {{ item.label }}
                    </cc-list-item>
                  </cc-list>
                </div>
              </ng-template>
            </ng-template>
          </cc-table-column>
        </ng-container>

        <cc-expandable-row-detail [rowHeight]="rowHeight">
          <ng-template cc-expandable-row-detail-template let-rowdata="rowdata">
            <dsp-next-gen-ui-billing-row-expansion
              *ngIf="rowdata.creditMemos && rowdata.creditMemos.length > 0"
              [tableData]="rowdata"
              [customCellSearch]="searchString"
            >
            </dsp-next-gen-ui-billing-row-expansion>
          </ng-template>
        </cc-expandable-row-detail>

        <cc-table-skeleton *ngIf="isDataLoading">
          <ng-template cc-table-skeleton-template>
            <cc-skeleton-loader [height]="250" [animation]="true">
              <svg:rect x="7" y="5" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="5" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="5" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="18" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="18" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="18" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="31" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="31" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="31" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="44" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="44" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="44" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="57" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="57" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="57" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="70" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="70" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="70" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="83" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="83" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="83" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="96" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="96" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="96" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="109" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="109" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="109" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="122" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="122" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="122" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="135" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="135" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="135" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="148" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="148" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="148" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="161" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="161" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="161" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="174" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="174" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="174" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="189" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="189" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="189" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="7" y="202" rx="1" ry="1" width="135" height="2.5" />
              <svg:rect x="160" y="202" rx="1" ry="1" width="130" height="2.5" />
              <svg:rect x="310" y="202" rx="1" ry="1" width="130" height="2.5" />

              <svg:rect x="0" y="13" width="540" height="0.5" />
              <svg:rect x="0" y="26" width="540" height="0.5" />
              <svg:rect x="0" y="39" width="540" height="0.5" />
              <svg:rect x="0" y="52" width="540" height="0.5" />
              <svg:rect x="0" y="65" width="540" height="0.5" />
              <svg:rect x="0" y="78" width="540" height="0.5" />
              <svg:rect x="0" y="91" width="540" height="0.5" />
              <svg:rect x="0" y="104" width="540" height="0.5" />
              <svg:rect x="0" y="117" width="540" height="0.5" />
              <svg:rect x="0" y="130" width="540" height="0.5" />
              <svg:rect x="0" y="143" width="540" height="0.5" />
              <svg:rect x="0" y="156" width="540" height="0.5" />
              <svg:rect x="0" y="169" width="540" height="0.5" />
              <svg:rect x="0" y="182" width="540" height="0.5" />
            </cc-skeleton-loader>
          </ng-template>
        </cc-table-skeleton>
      </cc-table>
      <div *ngIf="(!billingTableData || billingTableData.length === 0) && !isDataLoading && enablebillingTable">
        <div class="no-results-found">
          <img src="../../../assets/images/no_results_found.png" alt="no results found" />
          <span>No Results Found</span>
        </div>
      </div>
    </div>
  </div>
  <div class="billing-main" *ngIf="showDetailedInvoice">
    <dsp-next-gen-ui-detailed-invoice [detailedInvoice]="invoiceDetails"></dsp-next-gen-ui-detailed-invoice>
  </div>
</div>
<div>
  <dsp-next-gen-ui-child-dealer *ngIf="showBillingParent" [invoiceParentDealerCode]="invoiceParentDealerCode">
  </dsp-next-gen-ui-child-dealer>
</div>
