import { HttpClient } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { environment } from '../environments/environment';
import { parseISO } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { isEmpty } from 'lodash-es';
import moments from 'moment';
import * as dspConstants from './shared/dspConstants';
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  selectedTimeZone: any;
  rootURL = '';
  listOfTimeZones: any;
  localTimeZone: any;
  defaultNumberFormat: any;
  defaultTimeFormat: any;
  defaultDateFormat: any;
  private dateTimeSubject = new Subject<any>();
  dateTimeObservable = this.dateTimeSubject.asObservable();
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }
  updateChangeEvent() {
    this.dateTimeSubject.next(module);
  }
  getTimeZones() {
    return this.http.get(this.rootURL + '/usernotification/getTimeZones');
  }
  setTimeZones(listOfTimeZones: any) {
    this.listOfTimeZones = listOfTimeZones;
  }
  getTimeZonesValues() {
    return this.listOfTimeZones;
  }
  convertTimeToSelectedZone(InputDate: any, isLocalTimeNeeded: any) {
    const utcDateTime = moment.tz(InputDate, 'UTC');
    const convertedDateTime = utcDateTime.tz(this.selectedTimeZone);
    return isLocalTimeNeeded
      ? convertedDateTime.format('MM/DD/YYYY HH:mm:ss') + ' ' + this.localTimeZone
      : convertedDateTime.format('MM/DD/YYYY HH:mm:ss');
  }
  generateCatTimeZones(timeZones: any) {
    const catTimeZones: any = [];
    const timeZoneNames = timeZones.map((timeZone: any) => ({
      key: timeZone.key,
      value: timeZone.value,
    }));

    timeZoneNames.forEach((timeZoneName: any, i: any) => {
      const tzInstance = format(utcToZonedTime(new Date(), timeZoneName.key), "yyyy-MM-dd'T'HH:mm:ssxx", {
        timeZone: timeZoneName.key,
      });

      catTimeZones.push({
        timeZoneName: timeZoneName.key,
        timeZoneValue: timeZoneName.value,
        tzInstance: tzInstance,
      });
    });
    return catTimeZones;
  }
  getLocalTimeZone() {
    return this.localTimeZone;
  }
  updateDefaultTimeZone(timeZone: any) {
    this.selectedTimeZone = timeZone;
  }
  getDefaultTimeZone() {
    return this.selectedTimeZone;
  }
  updateSelectedTimeZone(data: any) {
    this.localTimeZone = data[0]?.localTime?.includes('+')
      ? data[0]?.localTime?.split('+')[0]
      : data[0]?.localTime?.includes('-')
        ? data[0]?.localTime?.split('-')[0]
        : data[0]?.localTime;
    this.updateChangeEvent();
    this.selectedTimeZone = data[0]?.value;
  }
  updateNumberFormat(numberFormat: any) {
    this.defaultNumberFormat = numberFormat;
  }
  getNumberFormat() {
    return this.defaultNumberFormat;
  }
  updateTimeFormat(timeFormat: any) {
    this.defaultTimeFormat = timeFormat;
  }
  updateDateformat(dateFormat: any) {
    this.defaultDateFormat = dateFormat;
  }
  getTimeFormat() {
    return this.defaultTimeFormat;
  }
  getDateFormat() {
    return this.defaultDateFormat;
  }
  convertDateTime(dateTime: any) {
    let timeFormat = this.defaultTimeFormat === dspConstants.Common.DEFAULT_TIME_FORMAT ? 'HH:mm:ss' : 'hh:mm:ss A';
    return moments(dateTime).format(this.defaultDateFormat + ' ' + timeFormat);
  }
  convertDate(date: any) {
    return moments(date).format(this.defaultDateFormat);
  }
  convertTime(time: any) {
    let timeFormat = this.defaultTimeFormat === dspConstants.Common.DEFAULT_TIME_FORMAT ? 'HH:mm:ss' : 'hh:mm:ss A';
    return moments(time, ['HH:mm']).format(timeFormat);
  }
  convertNumberFormat(Number: any, isString: boolean) {
    if (!Number) {
      return Number;
    }
    const Currency = this.getNumberFormat();
    let NumberFormat = 'en-US';
    if (Currency == 'EUR') {
      NumberFormat = 'de-DE';
    }
    if (isString) {
      const numArray = Number.split(' ');
      return (
        parseFloat(numArray[0]).toLocaleString(NumberFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
        ' ' +
        numArray[1]
      );
    }
    return parseFloat(Number).toLocaleString(NumberFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  getUserPreferenceCurrentDate() {
    let currentDate = new Date();
    let userPreferenceDate = this.convertTimeToSelectedZone(currentDate, false);
    return moments(userPreferenceDate).format('YYYY-MM-DD');
  }
}
