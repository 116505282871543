<div class="cards asset-details-card">
  <cc-card>
    <cc-card-content>
      <div>
        <div class="header">
          <div class="header-text">
            <span class="header-text-alignment">ASSET DETAILS</span
            ><span class="psr-alignment" *ngIf="showPSR"
              ><a cc-btn-tertiary cc-btn-large [setTheme]="true" href="{{ psrUrl }}" target="_blank"> PSR </a>
            </span>
          </div>
        </div>
        <div class="details-container">
          <div class="detail-tiles align-flex">
            <div>Asset S/N:</div>
            <div
              *ngIf="assetInfo?.serialNumber && this.isReplaceInfoIcon"
              class="detail-value drawer-link"
              (click)="openEcmInfo()"
            >
              {{ assetInfo?.serialNumber || '-' }}
            </div>
            <div *ngIf="!this.isReplaceInfoIcon" class="detail-value">{{ assetInfo?.serialNumber || '-' }}</div>
            <cc-icon
              class="asset-details-icon-size"
              *ngIf="assetInfo?.serialNumber && !this.isReplaceInfoIcon"
              (click)="openEcmInfo()"
              >info-tooltip</cc-icon
            >
            <img
              src="..\assets\images\pencil-new.svg"
              *ngIf="editAssetSNNumber"
              class="edit-pencil-icon"
              (click)="openDrawerInMultiSize(assetInfo)"
            />
          </div>
          <div class="detail-tiles" *ngIf="isShowAssetIdOnLanding && showAssetId && !isDealerCustomerAssetID">
            <span>Asset ID:</span><span class="detail-value">{{ assetInfo?.nickName || '-' }}</span>
          </div>
          <div class="detail-tiles" *ngIf="showAssetId && isDealerCustomerAssetID">
            <span>Customer / Dealer Asset ID:</span><span class="detail-value">{{ getAssetId() }}</span>
          </div>

          <div class="detail-tiles" *ngIf="assetInfo?.siteId && assetInfo?.siteId !== null">
            <span>Site Name:</span>
            <span
              class="detail-value"
              *ngIf="!showTruncatedText"
              cc-tooltip="{{ siteDetails?.name?.length > 34 ? siteDetails?.name : null }}"
              [tooltipPosition]="'top'"
              [innerHTML]="siteInfoTrimmed()"
            ></span>
            <span
              class="detail-value"
              *ngIf="showTruncatedText"
              cc-tooltip="{{ siteDetails?.name?.length > 34 ? siteDetails?.name : null }}"
              [tooltipPosition]="'top'"
              [innerHTML]="siteInfoTrimmed()"
              dspNextGenUiCopy
              [fullText]="siteDetails?.name ? siteDetails?.name : '-'"
            ></span>
            <ng-template #siteId_tooltip>
              <div style="width: 180px; white-space: normal">
                <span>{{ siteDetails?.name || '-' }}</span>
              </div>
            </ng-template>
          </div>
          <ng-container *ngIf="!deviceInfo1A">
            <div *ngIf="assetInfo?.assetFeedInfoDTO" class="detail-tiles">
              <span>Provider Name:</span
              ><span class="detail-value">{{ assetInfo?.assetFeedInfoDTO.providerName || '-' }}</span>
            </div>
            <div class="detail-tiles" *ngIf="assetInfo?.assetFeedInfoDTO">
              <span>Integration Name:</span
              ><span class="detail-value">{{ assetInfo?.assetFeedInfoDTO.feedName || '-' }}</span>
            </div>
          </ng-container>
          <div class="detail-tiles">
            <span>Engine S/N:</span><span class="detail-value">{{ assetInfo?.engineSerialNo || '-' }}</span>
          </div>
          <div class="detail-tiles" *ngIf="isShowDeviceTypeInfoFlag">
            <span>Device Type:</span><span class="detail-value"> {{ assetInfo?.deviceType || '-' }} </span>
          </div>
          <ng-container *ngIf="!deviceInfo1A">
            <div class="detail-tiles" *ngIf="!showTruncatedText">
              <span>Device Information:</span>
              <span
                class="detail-value"
                *ngIf="!assetInfo?.deviceSerialNumer && assetInfo?.radioSerialNoList?.length <= 0"
                >-</span
              >
              <ng-container *ngIf="deviceInfoTrimmed()?.length >= 34">
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                  class="detail-value"
                  cc-tooltip
                  [template]="deviceInfo_tooltip"
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                  class="detail-value drawer-link"
                  (click)="openDeviceInfo()"
                  cc-tooltip
                  [template]="deviceInfo_tooltip"
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
              </ng-container>
              <ng-container *ngIf="deviceInfoTrimmed()?.length < 34">
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                  class="detail-value"
                  cc-tooltip
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                  class="detail-value drawer-link"
                  (click)="openDeviceInfo()"
                  cc-tooltip
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
              </ng-container>
              <ng-template #deviceInfo_tooltip>
                <div style="width: 275px; white-space: normal">
                  <span>Device Information: </span>
                  <span>{{ deviceInformation() }}</span>
                </div>
              </ng-template>
              <cc-icon
                *ngIf="(assetInfo?.deviceSerialNumer || assetInfo?.radioSerialNoList?.length > 0) && !isReplaceInfoIcon"
                class="asset-details-icon-size"
                (click)="openDeviceInfo()"
                >info-tooltip</cc-icon
              >
            </div>
          </ng-container>
          <ng-container *ngIf="!deviceInfo1A">
            <div class="detail-tiles" *ngIf="showTruncatedText" (copy)="copyEvent($event, deviceInformation())">
              <span>Device Information:</span>
              <span
                class="detail-value"
                *ngIf="!assetInfo?.deviceSerialNumer && assetInfo?.radioSerialNoList?.length <= 0"
                >-</span
              >
              <ng-container *ngIf="deviceInfoTrimmed()?.length >= 34">
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                  class="detail-value"
                  cc-tooltip
                  [template]="deviceInfo_tooltip"
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                  class="detail-value drawer-link"
                  (click)="openDeviceInfo()"
                  cc-tooltip
                  [template]="deviceInfo_tooltip"
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
              </ng-container>
              <ng-container *ngIf="deviceInfoTrimmed()?.length < 34">
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                  class="detail-value"
                  cc-tooltip
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
                <span
                  *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                  class="detail-value drawer-link"
                  (click)="openDeviceInfo()"
                  cc-tooltip
                  [tooltipPosition]="'top'"
                  [innerHTML]="deviceInfoTrimmed()"
                ></span>
              </ng-container>
              <ng-template #deviceInfo_tooltip>
                <div style="width: 275px; white-space: normal">
                  <span>Device Information: </span>
                  <span>{{ deviceInformation() }}</span>
                </div>
              </ng-template>
              <cc-icon
                *ngIf="(assetInfo?.deviceSerialNumer || assetInfo?.radioSerialNoList?.length > 0) && !isReplaceInfoIcon"
                class="asset-details-icon-size"
                (click)="openDeviceInfo()"
                >info-tooltip</cc-icon
              >
            </div>

            <div
              class="detail-tiles"
              *ngIf="
                deviceFeaturesInfo &&
                deviceFeaturesInfo[assetInfo?.deviceType] &&
                deviceFeaturesInfo[assetInfo?.deviceType].Report_Type &&
                assetInfo?.deviceSerialNumer &&
                (assetInfo?.deviceSerialNumer.endsWith('005F') || assetInfo?.deviceSerialNumer.endsWith('005f'))
              "
            >
              <span class="detail-tiles">PLCN1 Report Type: </span>
              <span class="detail-value"
                >{{
                  assetInfo?.reportType
                    ? assetInfo?.reportType
                    : deviceFeaturesInfo[assetInfo?.deviceType].Report_Type[0]
                }}
              </span>
              <span *ngIf="!assetInfo?.suspended && assetInfo?.enableSubscription && assetInfo?.deviceType">
                <img
                  src="..\assets\images\pencil-new.svg"
                  *ngIf="!roleFlag"
                  (click)="updateReportType()"
                  class="edit-pencil-icon"
                />
              </span>
            </div>
          </ng-container>
          <div class="detail-tiles-grade" *ngIf="enableCatGradeCtrl">
            <a (click)="onClickCatGrade()">Grade Control System</a>
          </div>

          <div class="detail-tiles">
            <span *ngIf="cvaProductEnabled && cvaStatus">CVA Status:</span>
            <span *ngIf="!cvaProductEnabled">CVA Status:</span>
            <cc-icon
              *ngIf="cvaStatus === 'Available'"
              cc-tooltip="CVA Status"
              [tooltipPosition]="'bottom'"
              [interactive]="true"
              [template]="tooltipTemplateForCva"
              [appClass]="'-cva-tool-tip'"
              class="icon-CVA-Available11 asset-details-icon-size cva-status-icon-available"
              [ngClass]="cvaIconColor ? 'cva-color-avail' : ''"
            ></cc-icon>
            <cc-icon
              *ngIf="cvaStatus === 'Not Available'"
              cc-tooltip="CVA Status"
              [tooltipPosition]="'bottom'"
              [interactive]="true"
              [appClass]="'-cva-tool-tip'"
              [template]="tooltipTemplateForCva"
              class="icon-CVA-NA11 asset-details-icon-size cva-status-icon-notAvailable"
              [ngClass]="cvaIconColor ? ' cva-color-na' : ''"
            ></cc-icon>
            <span *ngIf="!cvaProductEnabled" class="detail-value capitalize">{{ cvaStatus || '-' }}</span>
            <span *ngIf="cvaProductEnabled && cvaStatus" class="detail-value capitalize">{{ cvaStatus }}</span>
            <span class="detail-value" *ngIf="cvaStatus === 'Available'">{{ '- ' + cvaCount }}</span>
          </div>
          <ng-container *ngIf="!deviceInfo1A">
            <div class="detail-tiles align-flex" *ngIf="showPartNumber">
              <div class="asset-details-row-label">Attachment P/N:</div>
              <div class="asset-part-number">{{ assetInfo?.partNumber || '' }}</div>
              <img
                src="..\assets\images\pencil-new.svg"
                *ngIf="editPartNumber"
                (click)="updatePartNumber()"
                class="edit-pencil-icon"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="!deviceInfo1A">
            <div class="detail-tiles gps-status" *ngIf="assetInfo?.enableGpsWarning && assetInfo?.enableSubscription">
              <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
              <span class="gps-status-body detail-value">GPS Time Rollover Status</span>
              <cc-icon
                [template]="tooltipTemplateForGps"
                [cc-tooltip]="connectionCheckToolTip"
                [interactive]="true"
                [appClass]="'-gps-tool-tip'"
                class="asset-details-icon-size"
                [tooltipPosition]="'bottom'"
                >info-tooltip</cc-icon
              >
            </div>
            <div class="detail-tiles" *ngIf="showParameterConfigUpload">
              <a class="config-upload-link" (click)="openParameterConfigUploadDrawer()"
                >PL444 Parameter Configuration Upload</a
              >
            </div>
            <div class="detail-tiles" *ngIf="isChinaSupportedDevice">
              <div *ngIf="assetInfo.notes?.length > 0">
                <img src="../../../assets/images/info-group.svg" class="info-icon-style info-group" />&nbsp;
                <span class="china-info">{{ assetInfo.notes[0]?.displayMessage || '' }} </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </cc-card-content>
  </cc-card>
</div>
<ng-template #tooltipTemplateForGps>
  <div class="gps-tooltip-header">
    Device Impacted by GPS Time Rollover
    <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="gps-tooltip-body">
    <span
      >Device requires update due to GPS Time Rollover issue. Please review support documentation on
      <a target="_blank" href="https://catdealer.com" style="color: #2595ff; text-decoration: underline"
        >catdealer.com</a
      >
      for further instructions before managing subscriptions.
    </span>
  </div>
</ng-template>

<ng-template #tooltipTemplateForCva>
  <div class="cva-tooltip-header">
    CVA Status
    <cc-icon [appClass]="'-cva-tool-tip'" class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="cva-tooltip-body">
    <span *ngIf="cvaStatus === 'Available'"
      >One or more active CVAs are associated to this asset. These CVAs may or may not be associated to your dealership.
      <!-- <a
        href="{{ cvaUrl }}"
        target="_blank"
        style="color: #2595ff; text-decoration: underline; font-weight: 400; cursor: pointer"
        >Click here</a
      > -->
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>

      to review in Cat Foresight</span
    >
    <span *ngIf="cvaStatus === 'Not Available'"
      >There are no active CVAs recorded for this asset.
      <!-- <a
        href="{{ cvaUrl }}"
        target="_blank"
        style="color: #2595ff; text-decoration: underline; font-weight: 400; cursor: pointer"
        >Click here</a
      > -->
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>
      to review in Cat Foresight
    </span>
  </div>
</ng-template>
