import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Cc_Grid } from '../../../shared/dspConstants';
import { PendingRequestFormData } from '../self-serve.interface';

@Component({
  selector: 'dsp-next-gen-ui-enter-reason',
  templateUrl: './enter-reason.component.html',
  styleUrls: ['./enter-reason.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EnterReasonComponent {
  @Input() pendingRequestFormData!: PendingRequestFormData;
  ccGrid: any;
  declineReason = '';
  ngOnInit() {
    this.ccGrid = Cc_Grid;
    this.pendingRequestFormData.isValidForm = false;
  }

  onTextChange() {
    if (this.declineReason.length == 0) {
      this.pendingRequestFormData.isValidForm = false;
    } else {
      this.pendingRequestFormData.isValidForm = true;
      this.pendingRequestFormData.declineReason = this.declineReason;
    }
  }
}
