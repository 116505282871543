import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dsp-next-gen-ui-dealer-action-required',
  templateUrl: './dealer-action-required.component.html',
  styleUrls: ['./dealer-action-required.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealerActionRequiredComponent implements OnInit {
  modalTitle = '';
  modalBodyMessage = '';
  modalFooterMessage = '';

  constructor(
    public sanitizer: DomSanitizer,
    public modalRef: CcModalRef<DealerActionRequiredComponent>,
    @Inject(CC_MODAL_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.modalBodyMessage = this.handleModalBodyMessage();
    this.modalFooterMessage = this.handleModalFooterMessage();
    this.modalTitle = 'Dealer Action Required';
  }
  /**
   * This function handles the modal body message based on the billing type.
   * It performs the following steps:
   * 1. Destructures billingType and languageTranslations from modalData.
   * 2. Initializes an empty message string.
   * 3. Checks the billingType and assigns the corresponding message from languageTranslations:
   *    - If billingType is 'VIMS', assigns WL_VIMSMESSAGE.
   *    - If billingType is 'HEI', assigns WL_HEIMESSAGE.
   *    - If billingType is 'MineStar', assigns WL_MINESTARMESSAGE.
   * 4. Removes the specific HTML tags from the message.
   * 5. Returns the formatted message.
   *
   * @returns {string} - The formatted modal body message.
   */
  handleModalBodyMessage() {
    const { billingType, languageTranslations } = this.modalData;
    let message = '';
    if (billingType == 'VIMS') {
      message = languageTranslations?.Worklist?.WL_VIMSMESSAGE;
    } else if (billingType == 'HEI') {
      message = languageTranslations?.Worklist?.WL_HEIMESSAGE;
    } else if (billingType == 'MineStar') {
      message = languageTranslations?.Worklist?.WL_MINESTARMESSAGE;
    }
    return message?.replace('<b>Dealer Action Required:</b><br/></br/>', '');
  }
  /**
   * This function handles the modal footer message based on the billing type.
   * It performs the following steps:
   * 1. Destructures billingType and languageTranslations from modalData.
   * 2. Initializes an empty message string.
   * 3. Checks the billingType and assigns the corresponding footer message from languageTranslations:
   *    - If billingType is 'VIMS', assigns WL_FT_VIMSMESSAGE.
   *    - If billingType is 'HEI', assigns WL_FT_HEIMESSAGE.
   *    - If billingType is 'MineStar', assigns WL_FT_MINESTARMESSAGE.
   * 4. Returns the formatted footer message.
   *
   * @returns {string} - The formatted modal footer message.
   */
  handleModalFooterMessage() {
    const { billingType, languageTranslations } = this.modalData;
    let message = '';
    if (billingType == 'VIMS') {
      message = languageTranslations?.Worklist?.WL_FT_VIMSMESSAGE;
    } else if (billingType == 'HEI') {
      message = languageTranslations?.Worklist?.WL_FT_HEIMESSAGE;
    } else if (billingType == 'MineStar') {
      message = languageTranslations?.Worklist?.WL_FT_MINESTARMESSAGE;
    }
    return message;
  }
  /**
   * This function closes the dealer action modal with a specified value.
   * It performs the following steps:
   * 1. Calls the close method on modalRef with the provided value.
   *
   * @param {string} [value='close'] - The value to be passed when closing the modal.
   * @returns {void}
   */
  closeDealerActionModal(value = 'close') {
    this.modalRef.close(value);
  }
}
