// General Purpose Constants
export const errorHandlingRetrySeconds = 6;
export const defaultNumberOfSelectedQuarters = 4;
export const literToGallonMultiplier = 3.78541;
export const maxTimeStamp = 8640000000000000;
export const DATE_FORMAT_MAPPING = {
  ddmmyyyy: 'DD/MM/YYYY',
  mmddyyyy: 'MM/DD/YYYY',
  yyyymmdd: 'YYYY/MM/DD',
};

// LocalStorage Auth Constants
export const TEMP_CONFIG = 'tempConfigStorage';
export const TEMP_FEATURE_FLAG_CONFIG = 'tempFeatureFlagStorage';
export const DSP_TOKEN = 'access_token';
export const DSP_NAME = 'dsp-name';
export const DSP_EXPIRATION = 'dsp-expiration';
export const DSP_ACCOUNT = 'dsp-account';
export const DSP_LOGIN_INTERACTION = 'dsp-login-interaction';
export const PHONE_NUMBER_PATTERN = /^[0-9 /()+-]+$/;
export const POSTAL_CODE_PATTERN = /^[\s.a-zA-Z0-9-]+$/;
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
