import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core';
import { event } from '@googlemaps/jest-mocks';
import { ConfigurationService } from 'apps/dsp-ui/src/app/services/configuration.service';

@Component({
  selector: 'dsp-next-gen-ui-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent {
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    public modalRef: CcModalRef<NotificationPopupComponent>,
    private configurationService: ConfigurationService,
    public sanitizer: DomSanitizer
  ) {}
  isChecked = false;
  ngOnInit() {}

  checkBoxToggled(event: any) {
    this.isChecked = event.checked;
  }

  closePopup() {
    if (this.isChecked) {
      this.configurationService.setDontShowPopup().subscribe({
        next: (res: any) => {},
        error: (err: any) => {},
      });
    }
    this.modalRef.close();
  }
}
