import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DspCommonService } from '../../services/dsp-common.service';
import * as userInfoActions from '../actions/user-info.actions';
import { User, DealerList } from '../../models/user-info.interface';
import * as dspConstants from '../../shared/dspConstants';

@Injectable()
export class UserInfoEffects {
  constructor(private actions$: Actions, private dspCommonService: DspCommonService) {}

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfoActions.loadUserRequestAction),
      switchMap(() =>
        this.dspCommonService.initialUserAndDealerDetail().pipe(
          map((user: User) => {
            return userInfoActions.loadUserSuccessAction({ user });
          }),
          catchError((error: any) => of(userInfoActions.loadUserFailureAction(error)))
        )
      )
    )
  );

  getDealer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfoActions.loadDealerRequestAction),
      switchMap((action) =>
        this.dspCommonService.getDealer(action.body).pipe(
          map((dealer: DealerList) => {
            return userInfoActions.loadDealerSuccessAction({ dealer });
          }),
          catchError((error: any) => of(userInfoActions.loadDealerFailureAction(error)))
        )
      )
    )
  );

  getUserMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfoActions.loadUserMenuRequestAction),
      switchMap(() =>
        this.dspCommonService.getRoleMenus().pipe(
          map((menu) => {
            return userInfoActions.loadUserMenuSuccessAction({ menu });
          }),
          catchError((error: any) => of(userInfoActions.loadUserMenuFailureAction(error)))
        )
      )
    )
  );
}
