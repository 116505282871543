import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-account-sales-reason',
  templateUrl: './account-sales-reason.component.html',
  styleUrls: ['./account-sales-reason.component.scss'],
})
export class AccountSalesReasonComponent implements OnInit {
  public value: any;
  public rowData: any;
  ngOnInit(): void {
    this.rowData = this.value;
  }
}
