<div class="cancel-reason-chart">
  <div class="header">
    <div class="left-header">Cancellation Reasons</div>
    <div class="right-header">
      <div class="icons">
        <cc-icon fontSet="icomoon" cc-tooltip="Expand" [tooltipPosition]="'top'" (click)="openExpanedView()"
          >fullscreen</cc-icon
        >
      </div>

      <div class="icons">
        <cc-icon *ngIf="!filterIcon" fontSet="icomoon" (click)="openFilter()">filter</cc-icon>
        <img
          class="filter-icon-checked"
          *ngIf="filterIcon"
          src="../../../../../assets/images/filter-applied.svg"
          (click)="openFilter()"
        />
      </div>
    </div>
  </div>
  <div class="bar-chart-vertical" *ngIf="cancellationRes && cancellationRes.length">
    <cc-bar-chart
      [chartId]="7"
      [chartData]="cancellationChartData"
      [chartConfig]="cancellationChartConfig"
      [chartType]="'horizontal'"
      [responsiveConfig]="cancellationResponsiveConfig"
      (seriesClick)="cancellationChartClick($event)"
      [chartDataResponsive]="true"
    ></cc-bar-chart>
  </div>
  <div class="cancel-cls-loader" *ngIf="chartSpinner">
    <dsp-next-gen-ui-spot-loader></dsp-next-gen-ui-spot-loader>
  </div>
  <div *ngIf="!chartSpinner && cancellationRes && cancellationRes.length === 0" class="no-results-found">
    <img src="../../../../../assets/images/no-data.png" width="198" height="132" />

    <p class="no-results-text">No data to display</p>
  </div>
</div>
