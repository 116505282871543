import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserAdminService } from '../services/user-admin.service';

@Component({
  selector: 'dsp-next-gen-ui-user-admin-row-expansion',
  templateUrl: './user-admin-row-expansion.component.html',
  styleUrls: ['./user-admin-row-expansion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAdminRowExpansionComponent {
  messageBar: any;
  constructor(private userAdminservice: UserAdminService) {}
  displayInfo: any = [];
  @Input() tableData: any;

  ngOnInit(): void {
    this.getExpandRow(this.tableData);
  }

  getExpandRow(tableData: any) {
    const payload = {
      catRecId: 'QPS-27895B59',
      profileType: 'CAT',
    };
    this.userAdminservice.getExpandRow(payload).subscribe((res: any) => {
      this.displayInfo = res;
    });
  }
}
