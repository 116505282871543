import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CcDrawerConfig, CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import { AssetColumnsList, RADIOTYPECOLUMNS } from '../../components/assets/asset-constants';
import { AssetService } from '../../components/assets/services/asset.service';
import { FetchAssetFiltersPayload } from '../../components/assets/asset-interface';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import { debounce } from 'lodash-es';
@Component({
  selector: 'dsp-next-gen-ui-showall-filter',
  templateUrl: './showall-filter.component.html',
  styleUrls: ['./showall-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ShowallFilterComponent implements OnInit {
  @Output() applyFiltersCallback = new EventEmitter();
  @ViewChild('columnFilterSearchBox') columnFilterSearchBox!: ElementRef;
  @ViewChild('dropdownOverlay') public dropdownOverlay!: ElementRef<HTMLDivElement>;
  @ViewChild('showAllContainer') public showAllContainer!: ElementRef<HTMLDivElement>;
  headerContent: string | undefined;
  title: string | undefined;
  columns: any = {};
  showallData: any = {};
  subFilter: any;
  searchValue = '';
  searchHelpText = '';
  mobileHeader = 'Search';
  searchString = '';
  expandDropdown = false;
  enableViewBtn: any = true;
  SearchFilterData: any;
  isLoading: any;
  isApiCallCompleted = false;
  loader = true;
  cachedData: any;
  offset = 0;
  public LIMIT_PER_CALL = 100;
  public nextCursor: any;
  limit: any = 100;
  isDataLoading: any;
  columnsVisible: any;
  globalSearch: any;
  noSearchResults: any;
  assetFilterColumData: any = AssetColumnsList;
  showAllFilterColumnData!: any;
  fetchedColumnNames: any;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  radioTypeValues: any;
  selectedRadioFilters: any;
  globalSearchInput: any;
  globalSearchType: any;
  globalSortColumn: any;
  dspConfig: any;
  showSearchBox: any;
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      headerTitle: string;
      columns: object;
      columnNames: object;
      showallData: object;
      subFilter: boolean;
      nextCursor: boolean;
      selectedRadioFilters: any;
      radioTypeValues: any;
      customData: any;
      ref: any;
      showSearchBox: any;
    },
    public dialogRef: CcDrawerRef<ShowallFilterComponent, CcDrawerConfig>,
    private cdr: ChangeDetectorRef,
    public assetService: AssetService,
    private store: Store<DSPAppState>
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspConfig = dsp.dspConfig || {};
    });
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.title = this.header.headerTitle ? this.header.headerTitle : '';
    this.columns = !this.header.subFilter && this.header.columns ? this.header.columns : this.header.columnNames;
    this.subFilter = this.header.subFilter ? this.header.subFilter : false;
    this.showallData = this.header.showallData ? cloneDeep(this.header.showallData) : {};
    this.showAllFilterColumnData = this.assetFilterColumData ? cloneDeep(this.assetFilterColumData) : {};
    this.globalSearchInput = this.header.customData?.searchString ? this.header.customData.searchString : '';
    this.globalSearchType = this.header.customData?.searchType ? this.header.customData.searchType : '';
    this.globalSortColumn = this.header.customData?.sortColumn ? this.header.customData.sortColumn : 'serialNumber';
    this.selectedRadioFilters =
      this.header.selectedRadioFilters && Object.keys(this.header.selectedRadioFilters).length
        ? this.header.selectedRadioFilters
        : {};
    this.radioTypeValues =
      this.header.radioTypeValues && this.header.radioTypeValues.length ? this.header.radioTypeValues : [];
    this.SearchFilterData = this.showallData;
    this.nextCursor = this.header.nextCursor;
    this.showSearchBox = this.header.showSearchBox;
    window.scroll(0, 0);
  }
  disable_scroll() {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  }

  enable_scroll() {
    document.ontouchmove = function (_e) {
      return true;
    };
  }
  onScroll(event: any) {
    if (Math.round(event.target.scrollTop) + 1 >= Math.round(event.target.scrollHeight - event.target.offsetHeight)) {
      this.disable_scroll();
      this.emitInfiniteScrollEvent();
      this.cdr.detectChanges();
    }
  }
  getSelectedFilter(event: any, filter: any, value: string) {
    const propVal: any = [];
    if (event.checked) {
      filter.selectedValues.push(value);
      if (
        filter.field === RADIOTYPECOLUMNS.Cellular &&
        this.radioTypeValues[0].cellularRadioType.length &&
        this.radioTypeValues[1].satelliteRadioType.length &&
        filter.selectedValues.length
      ) {
        filter.selectedValues.forEach((value: any) => {
          const cellularVal = this.radioTypeValues[0].cellularRadioType.filter((p: any) => p.includes(value));
          const satelliteVal = this.radioTypeValues[1].satelliteRadioType.filter((p: any) => p.includes(value));
          if (satelliteVal.length && cellularVal.length == 0) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Satellite, propVal, value);
          }
          if (satelliteVal.length && cellularVal.length) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Satellite, propVal, value);
            this.addSelectionToArray(RADIOTYPECOLUMNS.Cellular, propVal, value);
          }
          if (cellularVal.length && satelliteVal.length == 0) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Cellular, propVal, value);
          }
        });
      }
    } else {
      filter.selectedValues.splice(filter.selectedValues.indexOf(value), 1);
      if (
        filter.field === RADIOTYPECOLUMNS.Cellular &&
        this.radioTypeValues[0].cellularRadioType.length &&
        this.radioTypeValues[1].satelliteRadioType.length
      ) {
        const cellularVal = this.radioTypeValues[0].cellularRadioType.filter((p: any) => p.includes(value));
        const satelliteVal = this.radioTypeValues[1].satelliteRadioType.filter((p: any) => p.includes(value));
        if (satelliteVal.length && cellularVal.length == 0) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Satellite, value);
          return;
        }
        if (satelliteVal.length && cellularVal.length) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Satellite, value);
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Cellular, value);
          return;
        }
        if (cellularVal.length && satelliteVal.length == 0) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Cellular, value);
          return;
        }
      }
    }
    this.enableViewBtn = false;
  }
  addSelectionToArray(prop: string, propVal: any, value: any) {
    if (this.selectedRadioFilters[prop]) {
      this.selectedRadioFilters[prop] = this.selectedRadioFilters[prop].concat(value);
    } else {
      this.selectedRadioFilters = {
        ...this.selectedRadioFilters,
        [prop]: propVal.concat(value),
      };
    }
    this.selectedRadioFilters[prop] = this.selectedRadioFilters[prop].filter(function (
      value: any,
      index: any,
      array: any
    ) {
      return array.indexOf(value) === index;
    });
  }
  removeSelectionFromArray(prop: any, value: any) {
    const index = this.selectedRadioFilters[prop]?.indexOf(value);
    if (index !== -1 && this.selectedRadioFilters[prop]?.length && this.selectedRadioFilters[prop].length > 1) {
      this.selectedRadioFilters[prop].splice(index, 1);
    } else if (index !== -1 && this.selectedRadioFilters[prop]?.length && this.selectedRadioFilters[prop].length == 1) {
      delete this.selectedRadioFilters[prop];
    }
  }
  onFilterSearchChange(event: any) {
    this.cachedData = JSON.parse(JSON.stringify(this.showallData));
    if (event.value.trim().length > 2 && this.searchString !== event.value) {
      this.searchValue = event.value?.trim();
      this.searchString = this.searchValue.toLowerCase();
      this.isApiCallCompleted = false;
      this.SearchFilterData[this.columns.field] = [...[]];
      this.getColumnDataOnSearch();
    } else if (event.value.length === 0) {
      this.searchString = '';
      this.SearchFilterData = cloneDeep(this.assetFilterColumData);
      this.nextCursor = true;
      this.offset = 0;
      this.showAllContainer.nativeElement.scrollTop = 0;
    }
  }

  //eslint-disable-next-line @typescript-eslint/member-ordering
  getColumnDataOnSearch = debounce(() => {
    this.getColumnData();
  }, 500);

  getColumnData() {
    const payload: FetchAssetFiltersPayload = {
      dealerCode: this.dealerCode,
      offset: 0,
      limit: 100,
      globalSearchInput: this.globalSearchInput?.trim(),
      searchType: this.globalSearchType,
      sortColumn: this.globalSortColumn,
      searchText: this.searchString,
      sortOrder: 'asc',
      operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
      searchColumn: this.columns.field,
      displayColumns: [this.columns.field],
      selectedProducts: [],
    };
    this.isLoading = true;

    if (this.dspConfig?.isMultiProductEnabled === 'true') {
      this.assetService.getAssetFilterColumnData(payload).subscribe({
        next: (columnData: any) => {
          this.isLoading = false;
          this.isApiCallCompleted = true;
          this.nextCursor = columnData[0].next;
          if (columnData[0] && columnData[0][this.columns.field].length > 0) {
            this.showAllFilterColumnData[this.columns.field] = [...[columnData[0][this.columns.field]]];
          } else {
            this.showAllFilterColumnData[this.columns.field] = [...[]];
          }
          this.SearchFilterData = this.showAllFilterColumnData;
          this.cdr.detectChanges();
        },
        error: (err: any) => {
          this.isLoading = false;
          this.isApiCallCompleted = true;
          this.showAllFilterColumnData[this.columns.field] = [...[]];
          this.SearchFilterData = this.showAllFilterColumnData;
          this.cdr.detectChanges();
        },
      });
    } else {
      this.assetService.getAssetFilterColumnData(payload).subscribe({
        next: (columnData: any) => {
          this.isLoading = false;
          this.isApiCallCompleted = true;
          this.nextCursor = columnData[0].next;
          if (columnData[0] && columnData[0][this.columns.field].length > 0) {
            this.showAllFilterColumnData[this.columns.field] = [...[columnData[0][this.columns.field]]];
          } else {
            this.showAllFilterColumnData[this.columns.field] = [...[]];
          }
          this.SearchFilterData = this.showAllFilterColumnData;
          this.cdr.detectChanges();
        },
        error: (err: any) => {
          this.isLoading = false;
          this.isApiCallCompleted = true;
          this.showAllFilterColumnData[this.columns.field] = [...[]];
          this.SearchFilterData = this.showAllFilterColumnData;
          this.cdr.detectChanges();
        },
      });
    }
  }
  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.length || 0;
      this.columnFilterSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }
  public emitInfiniteScrollEvent(): void {
    if (this.nextCursor) {
      this.loader = true;
      this.offset = this.offset == 0 ? this.limit + this.offset : this.offset;
      this.isLoading = true;
      this.getDealerDetails();
    } else {
      this.loader = false;
      this.isLoading = false;
    }
  }
  getDealerDetails(): void {
    this.loader = true;
    const payload: FetchAssetFiltersPayload = {
      dealerCode: this.dealerCode,
      offset: this.offset,
      limit: this.limit,
      globalSearchInput: this.globalSearchInput?.trim(),
      searchType: this.globalSearchType,
      sortColumn: this.globalSortColumn,
      searchText: this.searchString,
      sortOrder: 'asc',
      operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
      searchColumn: this.columns.field,
      displayColumns: [this.columns.field],
      selectedProducts: [],
    };
    this.isApiCallCompleted = false;
    if (this.dspConfig?.isMultiProductEnabled === 'true') {
      this.assetService.getAssetFilterColumnData(payload).subscribe({
        next: (columnData: any) => {
          this.loader = false;
          this.isLoading = false;
          this.isApiCallCompleted = true;
          if (columnData[0] && columnData[0][this.columns.field].length > 0) {
            columnData[0][this.columns.field].forEach((value: any) => {
              if (this.SearchFilterData[this.columns.field][0].indexOf(value) == -1) {
                this.SearchFilterData[this.columns.field][0].push(value);
              }
            });
            this.showallData = this.SearchFilterData;
            this.nextCursor = columnData[0].next;
            this.offset = this.offset != payload.offset ? this.offset : this.limit + this.offset;
          } else {
            this.nextCursor = null;
          }
          this.enable_scroll();
          this.cdr.detectChanges();
        },
        error: (_err: any) => {
          this.loader = false;
          this.isApiCallCompleted = true;
          this.isLoading = false;
          this.nextCursor = null;
        },
      });
    } else {
      this.assetService.getAssetFilterColumnData(payload).subscribe({
        next: (columnData: any) => {
          this.loader = false;
          this.isLoading = false;
          if (columnData[0] && columnData[0][this.columns.field].length > 0) {
            setTimeout(() => {
              columnData[0][this.columns.field].forEach((value: any) => {
                if (this.SearchFilterData[this.columns.field][0].indexOf(value) == -1) {
                  this.SearchFilterData[this.columns.field][0].push(value);
                }
              });

              this.showallData = this.SearchFilterData;
              this.nextCursor = columnData[0].next;
              this.offset = this.offset != payload.offset ? this.offset : this.limit + this.offset;
            }, 5000);
          } else {
            this.nextCursor = null;
          }
          this.enable_scroll();
          this.cdr.detectChanges();
        },
        error: (_err: any) => {
          this.loader = false;
          this.isLoading = false;
          this.nextCursor = null;
        },
      });
    }
  }

  applyFilters() {
    this.header.selectedRadioFilters = this.selectedRadioFilters;
    this.closeModal();
  }

  closeModal() {
    this.header.selectedRadioFilters = this.selectedRadioFilters;
    this.dialogRef.close(this.enableViewBtn);
  }
  closeAllModal() {
    this.dialogRef.close();
    this.header?.ref?.close();
  }
}
