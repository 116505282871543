<div class="cc-grid">
  <div
    *ngIf="planType === subscribeAccountsConst.TT"
    class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
  >
    <div class="account-note-text">
      <strong>Dealer Action Required:</strong> <br />1. The installation and use of this MineStar software requires the
      Dealer to have a MineStar Subscription Purchase Agreement which includes Attachment 1 – Software End User
      Agreement in place with the Customer prior to activating the technology for any Customer or Dealer’s commercial
      use. A copy of the executed MineStar Subscription Purchase Agreement must be emailed to
      <a style="color: #0088cc; text-decoration: underline" href="mailto:MineStar_Subscription_Agreements@cat.com"
        >MineStar_Subscription_Agreements&#64;cat.com</a
      >
      before clicking the Submit button below. <br />2. The software installation file is only authorized to be used
      with a valid executed MineStar Subscription Purchase Agreement. <br />3. Caterpillar will perform regular audits
      to ensure all Dealers and Customers have valid executed MineStar Subscription Purchase Agreements on record.
      <br />4. Templates for the MineStar Subscription Purchase Agreements can be found
      <a
        href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/cat-minestar-system-support/bronze-subscriptions.html"
        style="text-decoration: underline"
        target="_blank"
        >Here</a
      >.<br />5. Detailed information for Technician Toolbox access and required MineStar Subscription Purchase
      Agreements are available in the Product Bulletin
      <a
        href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/health/applications.html#accordion-2d81dddd66-item-555d79372d-button"
        style="text-decoration: underline"
        target="_blank"
        >Here</a
      >.
    </div>
  </div>
  <div
    *ngIf="planType === subscribeAccountsConst.VM"
    class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
  >
    <div class="account-note-text">
      <strong>Dealer Action Required:</strong><br />1. The installation and use of this MineStar software requires the
      Dealer to have a Subscription Purchase Agreement which includes Attachment 1 – Software End User Agreement in
      place with the Customer prior to activating the technology for any Customer or Dealer’s commercial use. A copy of
      the executed MineStar Subscription Purchase Agreement must be emailed to
      <a style="color: #0088cc; text-decoration: underline" href="mailto:MineStar_Subscription_Agreements@cat.com"
        >MineStar_Subscription_Agreements&#64;cat.com</a
      >
      before clicking the Submit button below. <br />2. The software installation file is only authorized to be used
      with a valid executed MineStar Subscription Purchase Agreement. <br />3. Caterpillar will perform regular audits
      to ensure all Dealers and Customers have valid executed MineStar Subscription Purchase Agreements on record.
      <br />4. Templates for the MineStar Subscription Purchase Agreements can be found
      <a
        href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/cat-minestar-system-support/bronze-subscriptions.html"
        style="text-decoration: underline"
        target="_blank"
        >Here</a
      >.<br />5. Detailed information for VIMS Converter access and required MineStar Subscription Purchase Agreements
      are available in the Product Bulletin
      <a
        href="https://catdealer.com/content/dam/dealer/Products/Technology/minestar-solutions-surface/Health/vims-converter/VIMS%20Converter%20-%20Product%20Bulletin.pdf"
        style="text-decoration: underline"
        target="_blank"
        >Here</a
      >.
    </div>
  </div>
</div>
