import { Injectable } from '@angular/core';
import { DspCommonService } from '../services/dsp-common.service';

@Injectable({
  providedIn: 'root',
})
export class CatBillingService {
  constructor(private apiService: DspCommonService) {}
  getPaymentPreviewDetailsFn(reqParams: any, reqObj: any, callback: any) {
    let defaultParams = {
      assetMake: '',
      assetSerialNumber: '',
      deviceId: '',
      deviceSerialNumber: '',
      deviceType: '',
      dealerCode: '',
      ucid: '',
      customerName: '',
      dcnName: '',
      dcnNumber: '',
      contractEndDate: '',
      contractStartDate: '',
    };
    let framedRequestParams = Object.assign(defaultParams, reqParams);

    this.setPaymentPreviewCallStatus(reqObj, true);

    this.apiService.getPaymentPreview(framedRequestParams).subscribe({
      next: (res: any) => {
        if (res) {
          let result = res.obj || {};
          this.setPaymentPreviewCallStatus(reqObj, false);
          [reqObj.requestKey][reqObj.requestType] = null;
        } else {
          [reqObj.requestKey][reqObj.requestType] = null;
          this.setPaymentPreviewCallStatus(reqObj, false);
          callback(true);
        }
      },
    });
  }

  setPaymentPreviewCallStatus(reqObj: any, status1: any) {
    if (reqObj.requestType == 'current') {
      reqObj.requestKey.currentPaymentInProgress = status1;
    } else if (reqObj.requestType == 'new') {
      reqObj.requestKey.newPaymentInProgress = status1;
    }
  }

  getCurrentPayDetail(requestKey: any) {
    if (requestKey && requestKey['current']) {
      return requestKey['current'];
    }
  }
}
