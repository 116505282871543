<div class="cc-modal" id="billing-inquiry">
  <form [formGroup]="billingInquiryForm">
    <div class="cc-modal-container">
      <div class="cc-modal-header">
        <span class="cc-modal-title"> Submit Billing Inquiry </span>
        <div (click)="closeModel()" class="cc-modal-close">
          <img class="cc-close-semi" src="/assets/icons/closeSemi.svg" [alt]="'closeIcon'" />
        </div>
      </div>
      <div class="cc-modal-content billing-inquiry-container">
        <div class="info">Please use this form to raise any inquiry about your billing queries.</div>
        <div>
          <div class="pb-20">
            <div class="device-input-label pb-10"><span class="bed-rock">*</span>Description of inquiry or problem</div>
            <cc-input-field
              id="cc-atom-textarea-filled"
              [disabled]="false"
              [labelValue]="'Enter Description'"
              [inputtype]="'textarea'"
              [value]="billingInquiryForm.value.description"
              [isTheme]="isTheme"
              [maxlength]="500"
            >
              <textarea
                autosize
                class="textarea-field"
                formControlName="description"
                aria-labelledby="Description"
                aria-describedby="Description"
                rows="2"
                [value]="billingInquiryForm.value.description"
                [maxlength]="500"
              ></textarea>
            </cc-input-field>
            <span
              class="req-text"
              *ngIf="
            billingInquiryForm.controls['description']?.touched 
            &&  billingInquiryForm.controls['description'].errors?.['required'] 
          "
              >Description cannot be empty</span
            >
          </div>
          <div class="pb-20">
            <div class="device-input-label pb-10"><span class="bed-rock">*</span>Invoice # / Credit Memo #</div>
            <cc-input-field
              id="cc-atom-textField"
              [inputtype]="'text'"
              [isTheme]="isTheme"
              [labelValue]="'Enter Invoice # / Credit Memo #'"
              [isTableInlineEdit]="true"
            >
              <input
                input-field
                formControlName="creditMemo"
                [value]="billingInquiryForm.value.creditMemo"
                class="input-field"
                autocomplete="off"
                type="creditMemo"
                aria-labelledby="Enter Text (Optional)"
                aria-describedby="Enter Text (Optional)"
              />
            </cc-input-field>
            <span
              class="req-text"
              *ngIf="billingInquiryForm.controls['creditMemo']?.touched &&  billingInquiryForm.controls['creditMemo'].errors?.['required'] "
              >Invoice # / Credit Memo # cannot be empty</span
            >
          </div>
          <div class="pb-20">
            <div class="device-input-label pb-10">Contact Name</div>
            <cc-input-field
              id="cc-atom-textField"
              [inputtype]="'text'"
              [isTableInlineEdit]="true"
              [isTheme]="isTheme"
              [disabled]="true"
            >
              <input
                input-field
                formControlName="contactName"
                [value]="billingInquiryForm.value.contactName"
                class="input-field"
                autocomplete="off"
                type="text"
                aria-labelledby="Enter Text (Optional)"
                aria-describedby="Enter Text (Optional)"
              />
            </cc-input-field>
          </div>
          <div class="pb-20">
            <div class="device-input-label pb-10"><span class="bed-rock">*</span>Email</div>
            <cc-input-field
              id="cc-atom-textField"
              [labelValue]="'Enter Email'"
              [inputtype]="'text'"
              [isTableInlineEdit]="true"
              [isTheme]="isTheme"
            >
              <input
                input-field
                formControlName="emailID"
                [value]="billingInquiryForm.value.emailID"
                class="input-field"
                autocomplete="off"
                type="text"
                aria-labelledby="Enter Text (Optional)"
                aria-describedby="Enter Text (Optional)"
              />
            </cc-input-field>
            <span
              class="req-text"
              *ngIf="billingInquiryForm.controls['emailID']?.touched &&  billingInquiryForm.controls['emailID'].errors?.['required']"
              >Email cannot be empty</span
            >
          </div>
        </div>
      </div>
      <div class="cc-modal-footer connect-btn" style="text-align: right">
        <button
          cc-btn-secondary
          cc-btn-large
          [setTheme]="true"
          class="cancel button-opacity"
          (click)="closeModel()"
          [setTheme]="true"
        >
          Cancel
        </button>
        <button
          cc-btn-primary
          cc-btn-large
          class="cc-modal-saveBtn"
          (click)="submitInquiry()"
          [setTheme]="true"
          [disabled]="!billingInquiryForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
