import { Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef, MessageBarConfig, TableConfig } from '@cat-digital-workspace/shared-ui-core';
import { BillingService } from '../../services/billing.service';
import { DetailedInvTooltipHighlightComponent } from '../detailed-invoice-tooltip-highlight/detailed-inv-tooltip-highlight.component';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-billing-application-detailed-view',
  templateUrl: './billing-application-detailed-view.component.html',
  styleUrls: ['./billing-application-detailed-view.component.scss'],
})
export class BillingApplicationDetailedViewComponent implements OnInit {
  @ViewChild('billingDetailTableRef') tableRef: any;
  @Input() appDetail: any;
  isTableLoading = false;
  invoiceDet: any;
  appDetailView: any;
  messageBar: any;
  isDealerCustomerAssetID = false;
  billingDetailedTableConfig = {
    height: window.innerHeight - 240,
    stickyHeader: true,
    expandable: false,
    isSelfFoldable: true,
    isBulkAction: false,
    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    footer: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,

    columnConfig: [
      {
        name: 'subscriptionName',
        title: 'Plan Name',
        priority: 1,
        width: 60,
        isAlwaysMandatory: true,
        isVisible: true,
        component: DetailedInvTooltipHighlightComponent,
      },
      {
        name: 'duration',
        title: 'Duration',
        priority: 2,
        width: 50,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
      },
      {
        name: 'billingStartDate',
        title: 'Billing Start Date',
        priority: 3,
        width: 90,
        isFrozen: false,
        isAlwaysMandatory: false,
        isVisible: true,
        component: DetailedInvTooltipHighlightComponent,
      },
      {
        name: 'billingEndDate',
        title: 'Billing End Date',
        priority: 4,
        width: 90,
        isFrozen: false,
        isAlwaysMandatory: false,
        isVisible: true,
        component: DetailedInvTooltipHighlightComponent,
      },

      {
        name: 'promotionName',
        title: 'Promotion Name',
        isFrozen: false,
        width: 90,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
      },
      {
        name: 'promotionEndDate',
        title: 'Promotion End Date',
        width: 110,
        priority: 6,
        isFrozen: false,
        isAlwaysMandatory: false,
        isVisible: true,
        component: DetailedInvTooltipHighlightComponent,
      },
      {
        name: 'prepay',
        title: 'Prepay',
        width: 20,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
      },
      {
        name: 'subTotal',
        title: 'Sub Total',
        width: 70,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
        customData: { isAmount: true },
      },
      {
        name: 'discount',
        title: 'Discount',
        width: 60,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
        customData: { isAmount: true },
      },
      {
        name: 'taxAmount',
        title: 'Tax',
        width: 50,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
        customData: { isAmount: true },
      },
      {
        name: 'lineItemTotal',
        title: 'Total',
        width: 50,
        component: DetailedInvTooltipHighlightComponent,
        isAlwaysMandatory: false,
        isVisible: true,
        customData: { isAmount: true },
      },
    ],
  };

  billingDetailTableData: any[] = [];
  invoiceNo: any;
  isNumberConventionEnabled: any;
  dspStoreData: any;
  showTruncatedText = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    private billingService: BillingService,
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      appDetail: any;
      invoiceNumber: any;
    },
    public dialogRef: CcDrawerRef<BillingApplicationDetailedViewComponent>,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isDealerCustomerAssetID = dsp?.featureFlagInfo?.isDealerCustomerAssetID
        ? dsp?.featureFlagInfo?.isDealerCustomerAssetID
        : false;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
    this.isTableLoading = true;
    this.invoiceDet = this.header.appDetail;
    this.invoiceNo = this.header.invoiceNumber;
    this.getDetailsLineItem();
  }

  getDetailsLineItem() {
    this.isTableLoading = true;
    const payload = {
      customer: this.invoiceDet && this.invoiceDet.customer !== '-' ? this.invoiceDet.customer : null,
      ucid: this.invoiceDet && this.invoiceDet.ucid !== '-' ? this.invoiceDet.ucid : null,
      make: this.invoiceDet && this.invoiceDet.make !== '-' ? this.invoiceDet.make : null,
      assetSerialNumber:
        this.invoiceDet && this.invoiceDet.assetSerialNumber !== '-' ? this.invoiceDet.assetSerialNumber : null,
      dcn: this.invoiceDet && this.invoiceDet.dcn !== '-' ? this.invoiceDet.dcn : null,
      subTotal: this.invoiceDet?.subTotal,
      application: this.invoiceDet && this.invoiceDet.application !== '-' ? this.invoiceDet.application : null,
      dealerCode: this.invoiceDet?.dealerCode,
      assetModel: this.invoiceDet && this.invoiceDet.assetModel !== '-' ? this.invoiceDet.assetModel : null,
      invoiceNumber: this.invoiceNo,
      showSubList: false,
      invoiceDealerCode: this.invoiceDet?.dealerCode,
    };

    this.billingService.getDetailsLineItem(payload).subscribe({
      next: (res: any) => {
        this.appDetailView = res;
        this.billingDetailTableData = res.lineItems;
        this.isTableLoading = false;
      },
      error: (errorResponse: any) => {
        this.isTableLoading = false;
        const message = 'There was an error processing your request.';
        this.showToastMessage(message, 'error');
      },
    });
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar?.open(message, status, undefined, config, true);
  }
  tableEvents(event: any) {}
  closeDrawer() {
    this.dialogRef.close();
  }
  getAssetId() {
    if (
      !isEmpty(this.appDetailView?.customerAssetId) &&
      !isEmpty(this.appDetailView?.dealerAssetId) &&
      this.appDetailView?.customerAssetId !== '-' &&
      this.appDetailView?.dealerAssetId !== '-'
    ) {
      return this.appDetailView?.customerAssetId + ' / ' + this.appDetailView?.dealerAssetId;
    } else if (!isEmpty(this.appDetailView?.customerAssetId) && this.appDetailView?.customerAssetId !== '-') {
      return this.appDetailView?.customerAssetId;
    } else if (!isEmpty(this.appDetailView?.dealerAssetId) && this.appDetailView?.dealerAssetId !== '-') {
      return this.appDetailView?.dealerAssetId;
    }
    return '-';
  }

  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }
}
