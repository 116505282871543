import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef, CcDrawerConfig } from '@cat-digital-workspace/shared-ui-core';
import { AssetService } from '../../services/asset.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { getAssetDataSubscribedPlansEditResponse } from 'apps/dsp-ui/src/app/store/actions/dspcommon.action';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';

@Component({
  selector: 'dsp-next-gen-ui-asset-product-subscription-date-drawer',
  templateUrl: './asset-product-subscription-date-drawer.component.html',
  styleUrls: ['./asset-product-subscription-date-drawer.component.scss'],
})
export class AssetProductSubscriptionDateDrawerComponent implements OnInit {
  title = '';
  data: any;
  columnData: any;
  subscribedPlansData: any;
  planList: any = [];
  seemoreOverlayUpdate = false;

  storedData: any;
  dspStoreData!: any;
  isTimeZoneSelectionEnabled: any;
  isTimeFormatEnabled: any;
  localTimeZone: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      columnData: string;
      title: string;
      rowData: any;
    },
    public assetService: AssetService,
    private store: Store<DSPAppState>,
    public dialogRef: CcDrawerRef<AssetProductSubscriptionDateDrawerComponent, CcDrawerConfig>,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.storedData = this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        ? this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        : {};
      this.seemoreOverlayUpdate = this.dspStoreData?.featureFlagInfo.seeMoreOverlayUpdate
        ? dsp.featureFlagInfo.seeMoreOverlayUpdate
        : false;
    });

    this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
      ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
      : false;
    this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
      ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
      : false;
    this.title = 'Product Subscription Date & Time';
    this.data = this.header.rowData ? this.header.rowData : '';

    this.columnData = this.header.columnData ? this.header.columnData : '';
    if (this.storedData?.hasOwnProperty(this.data.assetId)) {
      let assetIdValue: any = this.storedData[this.data.assetId];
      assetIdValue.servicesSubscribedDTO.forEach((x: any) => {
        const dateTime = x.orginalStartDate ? x.orginalStartDate : ' - ';
        this.planList.push({ appName: x.appName, dateTime: dateTime });
      });
    } else {
      this.assetService.getAssetDataSubscribedPlansEdit(this.data).subscribe((data: any) => {
        this.storedData[data.assetId] = data;
        this.store.dispatch(getAssetDataSubscribedPlansEditResponse(this.storedData));
        data.servicesSubscribedDTO.forEach((x: any) => {
          const dateTime = x.orginalStartDate ? x.orginalStartDate : ' - ';
          this.planList.push({ appName: x.appName, dateTime: dateTime });
        });
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  parseAssetNameData(rowData: any) {
    let assetInfo = '';
    if (rowData) {
      assetInfo += rowData.make ? rowData.make : '';
      assetInfo += rowData.model ? ' - ' + rowData.model : '';
      assetInfo += rowData.modelYear ? ' - ' + rowData.modelYear : '';
      return assetInfo;
    } else {
      return '';
    }
  }

  getColumnToolTipData() {
    if (this.data) {
      return this.getAssetSerialNumber(this.data) + ' ' + this.parseAssetNameData(this.data);
    } else {
      return '';
    }
  }

  getAssetSerialNumber(rowData: any) {
    let assetSerialNo = '';
    if (rowData) {
      assetSerialNo += rowData.serialNumber;
      assetSerialNo += rowData.engineSerialNumber ? ' | ' + rowData.engineSerialNumber : '';
      return assetSerialNo;
    } else {
      return '';
    }
  }
  dateTimeValue(dateTime: any) {
    this.localTimeZone = this.userPreferenceService.getLocalTimeZone();
    let convertDateTimeStamp = this.assetService.convertTimeStampToData(dateTime, true);
    let value = this.userPreferenceService.convertTimeToSelectedZone(convertDateTimeStamp, false);
    if (this.isTimeFormatEnabled) {
      return this.userPreferenceService.convertDateTime(value);
    } else {
      return value;
    }
  }
}
