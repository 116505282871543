import { Component, Inject } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'dsp-next-gen-ui-termsofsale-modal',
  templateUrl: './termsofsale-modal.component.html',
  styleUrls: ['./termsofsale-modal.component.scss'],
})
export class TermsofsaleModalComponent {
  appURL = environment.redirectURL.hostBaseUrl;
  constructor(@Inject(CC_MODAL_DATA) public data: any, private modalRef: CcModalRef<TermsofsaleModalComponent>) {}
  onClickClose() {
    this.modalRef.close();
  }
}
