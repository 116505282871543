<app-navbar
  *ngIf="
    router.url === '/denied' ||
    router.url === '/session-expired' ||
    router.url === '/eula' ||
    router.url === '/tos' ||
    router.url === '/privacyNotice'
  "
>
  <router-outlet></router-outlet
></app-navbar>
<!-- <app-navbar *ngIf="router.url === '/dft'"> <router-outlet></router-outlet></app-navbar> -->
<div
  class="wrapper"
  *ngIf="
    (router.url !== '/denied' &&
      router.url !== '/eula' &&
      router.url !== '/tos' &&
      router.url !== '/privacyNotice' &&
      router.url !== '/session-expired' &&
      !isFetchApiDataLoading) ||
    ['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url)
  "
>
  <div class="main-top">
    <div>
      <cc-top-nav
        [toolbartitle]="title"
        id="cc-top-nav"
        *ngIf="isNavBarEnabled"
        [disableTopNavToggle]="disableTopNavToggle"
        [navdata]="[]"
        [setTheme]="true"
        [setSideNavState]="isSideNavOpen"
        (isLogoClicked)="onLogoClick()"
        (isSideNavCollapsed)="navToggle($event)"
      >
        <div class="topnav-right">
          <span *ngIf="!isWhatFixHelpEnabled"
            ><div
              class="help-text"
              *ngIf="
                !['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url) &&
                showHelpIcon &&
                ishelpIconPopover
              "
              cc-popover
              [template]="enableCookiesTemplate"
              [popoverClass]="'enable-cookies-popover'"
              [title]="'Enable Cookies'"
              [width]="'325'"
              [contentHeight]="'none'"
              [isChevron]="true"
              [headerbackgroundColor]="'#FFDE59'"
              [OverlayPosition]="'bottom'"
            >
              <cc-icon class="navbar-search__helpIcon cc-fm-gray" aria-label="help" tabindex="0" role="complementary">
                help
              </cc-icon>
              <span class="text" aria-label="help" role="complementary"> Help Center </span>
            </div>
            <div
              class="help-text"
              *ngIf="
                !['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url) &&
                showHelpIcon &&
                !ishelpIconPopover
              "
            >
              <cc-icon class="navbar-search__helpIcon cc-fm-gray" aria-label="help" tabindex="0" role="complementary">
                help
              </cc-icon>

              <span class="text"> Help Center</span>
            </div></span
          >
          <span *ngIf="isWhatFixHelpEnabled">
            <div
              class="help-text"
              *ngIf="!['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url) && showHelpIcon"
              cc-menu
              [search]="true"
              [template]="enableCookiesTemplate"
              [customOverlayClass]="'help-center'"
              [headerbackgroundColor]="'#FFDE59'"
              [OverlayPosition]="'bottomCenter'"
              #ccmenu="ccmenu"
            >
              <cc-icon class="navbar-search__helpIcon cc-fm-gray" aria-label="help" tabindex="0" role="complementary">
                help
              </cc-icon>
              <span class="text" aria-label="help" role="complementary"> Help Center </span>
            </div>
          </span>
          <div class="user-name" *ngIf="!['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url)">
            <span>Welcome {{ this.user?.firstName || this.user?.lastName || '' }}</span>
          </div>
          <div
            *ngIf="!['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url)"
            class="navbar-options"
            [ngClass]="{
              isMenuEnabled: (isMenuEnabled$ | async) === true,
            }"
            #topNavMenu
          >
            <div
              [ngClass]="userRole !== 'DSP Factory User' ? 'profile-button' : 'factoryuser-profile-button'"
              class="profile-button-click"
              (click)="showMenu()"
            >
              <div *ngIf="userRole !== 'DSP Factory User'" class="dealer-info">{{ dealerCode }}</div>
              <div class="cc-atom-companyLogo">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    [ngStyle]="{ stroke: (isMenuEnabled$ | async) === true ? 'black' : '#707175' }"
                    [ngClass]="{
                      highlight: (isMenuEnabled$ | async) === true,
                    }"
                    id="cc-atom-outerCircle"
                    d="M25.5 13C25.5 19.9036 19.9036 25.5 13 25.5C6.09644 25.5 0.5 19.9036 0.5 13C0.5 6.09644 6.09644 0.5 13 0.5C19.9036 0.5 25.5 6.09644 25.5 13Z"
                  />
                  <text
                    id="svg"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    [ngClass]="{
                      'text-black': (isMenuEnabled$ | async) === true,
                      'text-white': (isMenuEnabled$ | async) === false,
                    }"
                  >
                    {{ shortName }}
                  </text>
                </svg>
              </div>
            </div>
          </div>
          <cat-digital-workspace-menu
            *ngIf="isMenuEnabled$ | async"
            class="profile-options"
            (menuItemChange)="menuChange($event)"
          >
            <div class="menu-container">
              <ul class="menu">
                <div header class="menu-header">
                  <div class="menu-header-user-name profile-button" title="{{ user.firstName }} {{ user.lastName }}">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>
                  <div class="menu-header-user-email-id profile-button" title="{{ user.email }}">{{ user.email }}</div>
                </div>
                <li *ngIf="showOrganization()" class="menu__links" (click)="OrganizationAction()">
                  <div class="menu__icon_container ng-star-inserted">
                    <cc-icon fontSet="icomoon">org</cc-icon>
                  </div>

                  <div class="menu__info_container">
                    <span class="menu__link ng-star-inserted">ORGANIZATION</span>
                  </div>
                </li>

                <li *ngIf="userPrefRoleCheck()" class="menu__links" (click)="userPreference()">
                  <div class="menu__icon_container ng-star-inserted">
                    <cc-icon fontSet="icomoon">userpref</cc-icon>
                  </div>

                  <div class="menu__info_container">
                    <span class="menu__link ng-star-inserted">USER PREFERENCES </span>
                  </div>
                </li>

                <li class="menu__links" (click)="userProfile()">
                  <div class="menu__icon_container ng-star-inserted">
                    <cc-icon fontSet="icomoon">profile</cc-icon>
                  </div>

                  <div class="menu__info_container">
                    <span class="menu__link ng-star-inserted">PROFILE</span>
                  </div>
                </li>

                <li class="menu__links" (click)="signOut()">
                  <div class="menu__icon_container ng-star-inserted">
                    <cc-icon fontSet="icomoon">signout</cc-icon>
                  </div>

                  <div class="menu__info_container">
                    <span class="menu__link ng-star-inserted">SIGN OUT </span>
                  </div>
                </li>
              </ul>
            </div>
          </cat-digital-workspace-menu>
        </div>
      </cc-top-nav>
    </div>
  </div>
  <div class="main-content">
    <div
      class="aside"
      *ngIf="isShowSidenav && !['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url)"
      id="sideNavComp"
      #sideNavComp
    >
      <cc-side-nav
        *ngIf="!showTruncatedText"
        [theme]="true"
        [expanded]="isSideNavOpen"
        [isAutoCollapse]="false"
        [collapsible]="true"
        [dataSource]="menuOrderList"
        [appClass]="'-sidenav'"
        [toolTipCloseOnClick]="false"
        [toolTipInteractive]="false"
        [tooltipPosition]="'top-right'"
        [expandWidth]="232"
        [collapseWidth]="72"
        [selectedPathName]="selectedPath"
        (navLinkClicked)="navLinkClicked($event)"
        (navLinkExpanded)="navLinkExpanded($event)"
      >
        <footer>
          <dsp-next-gen-ui-footer></dsp-next-gen-ui-footer>
        </footer>
      </cc-side-nav>
      <cc-side-nav
        *ngIf="showTruncatedText"
        [theme]="true"
        [expanded]="isSideNavOpen"
        [isAutoCollapse]="false"
        [collapsible]="true"
        [dataSource]="menuOrderList"
        [appClass]="'-sidenav'"
        [toolTipCloseOnClick]="false"
        [toolTipInteractive]="false"
        [tooltipPosition]="'top-right'"
        [expandWidth]="232"
        [collapseWidth]="72"
        [selectedPathName]="selectedPath"
        (navLinkClicked)="navLinkClicked($event)"
        (navLinkExpanded)="navLinkExpanded($event)"
        dspNextGenUiCopy
        [fullText]="handleEllipsis()"
      >
        <footer>
          <dsp-next-gen-ui-footer></dsp-next-gen-ui-footer>
        </footer>
      </cc-side-nav>
    </div>
    <div class="wrapper_main">
      <div class="wrapper_main__content">
        <main>
          <router-outlet *ngIf="menuOrderList.length"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</div>
<ng-template #enableCookiesTemplate>
  <span *ngIf="!isWhatFixHelpEnabled">
    <div>To access DSP Help, guided tours, and the Contact Support form, you must enable functional cookies.</div>
    <div class="enable-cookies-popover__helpText">
      <span>You can enable them in your </span>
      <span class="enable-cookies-popover__helpText__link" (click)="openCookieSettings()">Cookie Settings</span>
    </div>
  </span>
  <div *ngIf="isWhatFixHelpEnabled" class="helpCenterOptions ng-star-inserted">
    <cc-list [theme]="'secondary'">
      <cc-list-item (listItemClick)="helpCenterNavigate()" class="help-center-link">
        <span class="list-name"> DSP Help Center </span>
      </cc-list-item>
      <cc-list-item [ngClass]="ishelpIconPopover ? 'disable-guided-tours' : 'guided-tours'">
        <div
          class="guided-tours__list-container"
          (click)="ishelpIconPopover ? onHelpIconClick(true) : onHelpIconClick(false)"
        >
          <span class="list-name"> Guided Tours</span>
          <span
            class="guided-tours-tooltip"
            *ngIf="
              !['/legalModal', '/privacyModal', '/termSaleModal'].includes(router.url) &&
              showHelpIcon &&
              ishelpIconPopover
            "
            cc-popover
            [template]="enableCookiesTemplateContent"
            [popoverClass]="'guided-tours-popover'"
            [title]="'Enable Cookies'"
            [width]="'200'"
            [contentHeight]="'none'"
            [isChevron]="true"
            [headerbackgroundColor]="'white'"
            [autoposition]="true"
          >
            <cc-icon class="whatfix-disabled" aria-label="help" tabindex="0" role="complementary">
              asset-info1
            </cc-icon>
          </span>
        </div>
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>
<ng-template #enableCookiesTemplateContent>
  <div>
    <span>Enable functional cookies to access Guided Tours. You can enable them in your </span>
    <span class="enable-cookies-popover__helpText__link" (click)="openCookieSettings()">Cookie Settings</span>.
  </div>
</ng-template>
<dsp-next-gen-ui-loader></dsp-next-gen-ui-loader>
