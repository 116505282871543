import { Component, Inject } from '@angular/core';

import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';

@Component({
  selector: 'dsp-next-gen-ui-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<UserProfileComponent>
  ) {}

 

  onClickClose() {
    this.modalRef.close();
  }
}
