<div class="asset-dat-container">
  <span class="icon-size">
    <cc-icon
      fontSet="icomoon"
      [interactive]="true"
      [tooltipPosition]="'left'"
      [appClass]="'-dat-tool-tip'"
      [ngClass]="checkDATStatus()"
      cc-tooltip
      [template]="tooltipTemplateForDAT"
      *ngIf="this.rowData?.digitalAuthorization !== '-'"
      >{{ updateDATBadge() }}
    </cc-icon>
    <span class="asset-details-value">{{ updateDatStatus() }}</span>
  </span>
</div>
<ng-template #tooltipTemplateForDAT>
  <div class="dat-tooltip-header">
    Digital Authorization Tool <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="dat-tooltip-body">
    <span *ngIf="datStatus === 'AGREE'"
      >Customer authorization on file - No further action required. Click to review in
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'DECLINE'"
      >Customer authorization declined - Click to review and take action through the
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'UNAVAILABLE'"
      >Customer authorization unavailable - Click to review and take action through the
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'SYSTEM ERROR'"
      >Unable to get authorization details - Please retry later or contact Cat Digital support
    </span>
  </div>
</ng-template>
