import { createAction, props } from '@ngrx/store';

export enum DSPCommonActionTypes {
  SET_DEVICE_CATALOGUE_INFO = '[devicesData] SET_DEVICE_CATALOGUE_INFO',
  DEVICE_FEATURES_INFO = '[deviceFeatures] DEVICE_FEATURES_INFO',
  USER_ACTIVE_FEATURES = '[userActiveFeatures] USER_ACTIVE_FEATURES',
  ED_URL = '[ED_URL] ED_URL',
  PSR_URL = '[PSR URL] PSR_URL',
  MULTI_PRODUCT_FEATURE_FLAG_INFO = '[MULTI PRODUCT FEATURE FLAG INFO] MULTI_PRODUCT_FEATURE_FLAG_INFO',
  DSP_CONFIG = '[dspConfig] DSP_CONFIG',
  FEATURE_SUPPORT_MAPPING = '[FEATURE_SUPPORT_MAPPING] FEATURE_SUPPORT_MAPPING',
  LANGUAGE_TRANSLATIONS = '[LANGUAGE_TRANSLATIONS] LANGUAGE_TRANSLATIONS',
  SERVICE_MAPPING = '[SERVICE MAPPING] SERVICE MAPPING',
  BILL_DIRECT_CUSTOMERS = '[BILL_DIRECT_CUSTOMERS] BILL_DIRECT_CUSTOMERS',
  FEATURES_TOGGLE_MAPPING = '[FEATURES_TOGGLE_MAPPING] FEATURES_TOGGLE_MAPPING',
  BILLING_ACCOUNTS = '[BILLING_ACCOUNTS] BILLING_ACCOUNTS',
  PRODUCT_FAMILY = '[PRODUCT_Family] PRODUCT_FAMILY',
  CERTIFIED_COUNTRIES = '[CERTIFIED_COUNTRIES] CERTIFIED_COUNTRIES',
  DEALER_SUBFEATURE = '[DEALER_SUBFEATURE] DEALER_SUBFEATURE',
  USER_DETAILS = '[USER_DETAILS] USER_DETAILS',
  MINESTAR_SERVICE_DETAILS = '[MINESTAR_SERVICE_DETAILS] MINESTAR_SERVICE_DETAILS',
  SITE_CANCEL_REASON_DETAILS = '[SITE_CANCEL_REASON_DETAILS] SITE_CANCEL_REASON_DETAILS',
  ACCOUNT_CANCEL_REASON_DETAILS = '[ ACCOUNT_CANCEL_REASON_DETAILS ]  ACCOUNT_CANCEL_REASON_DETAILS ',
  FEATURE_FLAG_INFO = '[featureFlagInfo] FEATURE_FLAG_INFO',
  SALES_REASON = '[ SALES_REASON] SALES_REASON',
  GET_ASSET_DATA_SUBSCRIBED_PLANS_EDIT_RESPONSE = '[GET ASSET DATA SUBSCRIBED PLANS EDIT RESPONSE] GET_ASSET_DATA_SUBSCRIBED_PLANS_EDIT_RESPONSE',
  LIST_OF_PRODUCTS = '[LIST_OF_PRODUCTS] LIST_OF_PRODUCTS',
}

export const setDeviceCatalogueInfo = createAction(DSPCommonActionTypes.SET_DEVICE_CATALOGUE_INFO, props<any>());

export const getDeviceFeaturesInfo = createAction(DSPCommonActionTypes.DEVICE_FEATURES_INFO, props<any>());

export const getEquipmentDataUrl = createAction(DSPCommonActionTypes.ED_URL, props<any>());
export const getPSRUrl = createAction(DSPCommonActionTypes.PSR_URL, props<any>());
export const getMultiProductFeatureFlagInfo = createAction(
  DSPCommonActionTypes.MULTI_PRODUCT_FEATURE_FLAG_INFO,
  props<any>()
);
export const getDSPConfig = createAction(DSPCommonActionTypes.DSP_CONFIG, props<any>());

export const userActiveFeatures = createAction(DSPCommonActionTypes.USER_ACTIVE_FEATURES, props<any>());

export const getFeatureSupportMapping = createAction(DSPCommonActionTypes.FEATURE_SUPPORT_MAPPING, props<any>());

export const getLanguageTranslations = createAction(DSPCommonActionTypes.LANGUAGE_TRANSLATIONS, props<any>());

export const getSubscriptionServiceMapping = createAction(DSPCommonActionTypes.SERVICE_MAPPING, props<any>());

export const getBillDirectCustomers = createAction(DSPCommonActionTypes.BILL_DIRECT_CUSTOMERS, props<any>());

export const getFeaturesToggleMapping = createAction(DSPCommonActionTypes.FEATURES_TOGGLE_MAPPING, props<any>());

export const getBillingAccounts = createAction(DSPCommonActionTypes.BILLING_ACCOUNTS, props<any>());

export const getProductFamily = createAction(DSPCommonActionTypes.PRODUCT_FAMILY, props<any>());

export const getCertifiedCountries = createAction(DSPCommonActionTypes.CERTIFIED_COUNTRIES, props<any>());

export const getDealerSubFeature = createAction(DSPCommonActionTypes.DEALER_SUBFEATURE, props<any>());

export const getUserDetails = createAction(DSPCommonActionTypes.USER_DETAILS, props<any>());

export const getMinestarServiceDetails = createAction(DSPCommonActionTypes.MINESTAR_SERVICE_DETAILS, props<any>());

export const getSiteCancelReasonDetails = createAction(DSPCommonActionTypes.SITE_CANCEL_REASON_DETAILS, props<any>());

export const getSalesReason = createAction(DSPCommonActionTypes.SALES_REASON, props<any>());

export const getAccountCancelReasonDetails = createAction(
  DSPCommonActionTypes.ACCOUNT_CANCEL_REASON_DETAILS,
  props<any>()
);

export const getFeatureFlagInfo = createAction(DSPCommonActionTypes.FEATURE_FLAG_INFO, props<any>());

export const getAssetDataSubscribedPlansEditResponse = createAction(
  DSPCommonActionTypes.GET_ASSET_DATA_SUBSCRIBED_PLANS_EDIT_RESPONSE,
  props<any>()
);
export const getListOfProducts = createAction(DSPCommonActionTypes.LIST_OF_PRODUCTS, props<any>());
