<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ data.title }}</div>
    </div>
    <div class="cc-modal-content">
      <div id="urlName">
        <p class="modalMessage1">
          <span class="error-text">* </span>{{ data.name1 }}
          <cc-input-field
            [labelValue]="data.labelName1"
            [inputtype]="'text'"
            [isTheme]="'2.0'"
            class="form-control"
            [width]="'327px'"
            [isDense]="true"
            [value]="data.value1"
            tabindex="0"
          >
            <span *ngIf="negative"
              ><img alt="alt img text" class="search-icon" src="../../assets/images/search.png"
            /></span>
            <input
              input-field
              class="input-field"
              autocomplete="off"
              type="text"
              name="urlName"
              minlength="3"
              maxlength="50"
              (valueChange)="urlNameChange($event)"
              (paste)="validateKey($event)"
            />
          </cc-input-field>
        </p>
        <div class="help-text">
          <p *ngIf="negative" class="negative">{{ errorText }}</p>
          &nbsp;
        </div>
      </div>
      <div id="userName">
        <p class="modalMessage2">
          <span class="error-text">* </span>{{ data.name2 }}
          <cc-input-field
            [labelValue]="data.labelName2"
            [inputtype]="'text'"
            [isTheme]="'2.0'"
            class="form-control"
            [isDense]="true"
            [width]="'327px'"
            tabindex="1"
          >
            <span *ngIf="urlChng"
              ><img alt="alt img text" class="search-icon" src="../../assets/images/search.png"
            /></span>
            <input
              input-field
              class="input-field"
              autocomplete="off"
              type="url"
              name="userName"
              [value]="data.value2"
              autocomplete="off"
              [(ngModel)]="data.value2"
              (valueChange)="urlChange($event)"
              minlength="3"
              maxlength="250"
            />
          </cc-input-field>
        </p>
        <div class="help-text">
          <p class="negative">{{ errorTextUrl }}</p>
        </div>
      </div>
    </div>
    <div class="cc-modal-footer modalfooterStyle">
      <button *ngIf="data.multipleButtons" cc-btn-secondary-outline (click)="onClickCancel()" [setTheme]="true">
        {{ data.negativeButtonAction }}
      </button>
      <button
        cc-btn-primary
        [disabled]="checkDisableBtn() || isFormSubmited"
        (click)="onClickSubmit(data.value)"
        [setTheme]="true"
      >
        {{ data.positiveButtonAction }}
      </button>
    </div>
  </div>
</div>
