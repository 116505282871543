import { AccountCustomerComponent } from './account-customer/account-customer.component';
import { AccountCwsIDComponent } from './account-cws-id/account-cws-id.component';
import { AccountDealerCustomerIDComponent } from './account-dealer-customer-id/account-dealer-customer-id.component';
import { AccountPrefixComponent } from './account-prefix/account-prefix.component';
import { AccountProductPlanComponent } from './account-product-plan/account-product-plan.component';
import { AccountSalesReasonComponent } from './account-sales-reason/account-sales-reason.component';

export const OVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort A to Z',
  },
  {
    name: 'dsc',
    title: 'Sort Z to A',
  },
];
export const countOverlayData = [
  {
    name: 'asc',
    title: 'Sort Smallest to Largest',
  },
  {
    name: 'dsc',
    title: 'Sort Largest to Smallest',
  },
];
export const ColumnConfig = [
  {
    name: 'ucName',
    width: 260,
    title: 'Customer',
    isFrozen: true,
    isAlwaysMandatory: true,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: AccountCustomerComponent,
    customData: {
      searchString: '',
    },
  },
  {
    name: 'productplan',
    title: 'Product | Plan',
    width: 180,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: AccountProductPlanComponent,
    customData: {
      searchString: '',
    },
  },
  {
    name: 'cwsId',
    title: 'CWS ID',
    isFrozen: false,
    width: 220,
    isAlwaysMandatory: false,
    isVisible: true,
    ascendingSort: false,
    overlayData: OVERLAYDATA,
    component: AccountCwsIDComponent,
    customData: {
      searchString: '',
    },
  },
  {
    name: 'dcName',
    title: 'Dealer Customer',
    isFrozen: false,
    width: 240,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: AccountDealerCustomerIDComponent,
    customData: {
      searchString: '',
    },
  },
  {
    name: 'assetPrefix',
    title: 'Prefix',
    width: 80,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: true,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: AccountPrefixComponent,
    customData: {
      searchString: '',
    },
  },
  {
    name: 'salesReason',
    title: 'Sale Reason',
    width: 150,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: false,
    sortingEnable: true,
    ascendingSort: true,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: AccountSalesReasonComponent,
  },
  {
    name: 'cvaNumber',
    title: 'CVA Number',
    width: 150,
    isFrozen: false,
    formControl: 'span',
    isAlwaysMandatory: false,
    isVisible: false,
    sortingEnable: true,
    ascendingSort: true,
    sortWithOverlay: true,
    overlayData: countOverlayData,
  },
  {
    name: 'planLevel',
    title: 'Plan Level',
    isFrozen: false,
    width: 240,
    formControl: 'span',
    isAlwaysMandatory: false,
    isVisible: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },
  {
    name: 'planType',
    title: 'Plan Type',
    isFrozen: false,
    width: 240,
    formControl: 'span',
    isAlwaysMandatory: false,
    isVisible: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },
];
