import { Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Cc_Grid, Common } from '../../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { findIndex, isEmpty } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { CCTooltip, MessageBar } from '@cat-digital-workspace/shared-ui-core';
import { PendingRequestFormData } from '../../self-serve.interface';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';

@Component({
  selector: 'dsp-next-gen-ui-pending-request-asset-details',
  templateUrl: './pending-request-asset-details.component.html',
  styleUrls: ['./pending-request-asset-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingRequestAssetDetailsComponent implements OnInit {
  @Input() rowData: any;
  @Input() pendingRequestFormData!: PendingRequestFormData;
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  ccGrid = Cc_Grid;
  dealer = fetchLoggedInDealerDetails();
  ownershipData: any;
  serviceMapping: any;
  datUrl = '';
  systemError = Common.SYSTEM_ERROR;
  b2cCustomers!: any;
  billToParty!: string;
  cvaCount!: any;
  cvaStatus!: any;
  cvaUrl!: any;
  datStatus = '';
  deviceInfoWithoutTrim!: any;
  customerInfoWithoutTrim!: any;
  dealerCustomerInfoWithoutTrim!: any;
  cvaIconColor = false;
  cvaProductEnabled = false;

  constructor(
    private store: Store<DSPAppState>,
    public sanitizer: DomSanitizer,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.serviceMapping = dsp.serviceMapping || {};
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.cvaIconColor = dsp?.featureFlagInfo?.CVAIconColor ? dsp?.featureFlagInfo?.CVAIconColor : false;
      this.cvaProductEnabled = dsp.featureFlagInfo.CVAProduct ? dsp.featureFlagInfo.CVAProduct : false;
    });
    this.mapOwnershipData();
    this.billToParty = this.checkForB2CCustomers();
  }

  getDeviceInformation() {
    if (isEmpty(this.rowData?.deviceSerialNumer) && isEmpty(this.rowData?.radioSerialNoList)) {
      return '-';
    }
    let deviceVal = !isEmpty(this.rowData?.deviceSerialNumer) ? this.rowData.deviceSerialNumer : '';
    if (!isEmpty(this.rowData?.deviceSerialNumer) && !isEmpty(this.rowData?.radioSerialNoList)) {
      deviceVal += ' / ';
    }
    if (this.rowData?.radioSerialNoList?.length > 0) {
      const length = this.rowData?.radioSerialNoList?.length;
      for (let i = 0; i < length; i++) {
        if (i == length - 1) {
          deviceVal += this.rowData?.radioSerialNoList[i] + '';
        } else {
          deviceVal += this.rowData?.radioSerialNoList[i] + ' / ';
        }
      }
      return this.checkForTrim(deviceVal);
    } else {
      return this.checkForTrim(this.rowData?.deviceSerialNumer);
    }
  }

  getUpdatedTime() {
    if (this.rowData.vlRequestDetails?.date_time) {
      let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
        this.rowData.vlRequestDetails?.date_time,
        false
      );

      convertedTime =
        this.userPreferenceService.convertDateTime(convertedTime?.split(' ')[0] + ' ' + convertedTime?.split(' ')[1]) +
        ' (' +
        this.userPreferenceService.getLocalTimeZone() +
        ')';
      return convertedTime;
    }
    return '';
  }

  checkForB2CCustomers() {
    const ucid = this.ownershipData?.ucid || '';
    return findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', ucid]) !== -1 ? 'Customer' : 'Dealer';
  }
  mapOwnershipData() {
    const customer = this.pendingRequestFormData?.customer;
    this.cvaCount = this.pendingRequestFormData?.customer?.cvaDetails?.cvaCount;
    this.cvaStatus = this.pendingRequestFormData?.customer?.cvaDetails?.cvaStatus;
    this.cvaUrl = this.pendingRequestFormData?.customer?.cvaDetails?.catForesightLink;
    this.customerInfoWithoutTrim =
      this.pendingRequestFormData?.customer?.ucidName + ' - ' + this.pendingRequestFormData?.customer?.ucid;
    this.dealerCustomerInfoWithoutTrim =
      this.pendingRequestFormData?.customer?.dealerCustName +
      ' - ' +
      this.pendingRequestFormData?.customer?.dealerCustNo;
    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }

  checkDATStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'AGREE': {
        return 'icon-green';
      }
      case 'DECLINE': {
        return 'icon-red';
      }
      case 'UNAVAILABLE': {
        return 'icon-grey';
      }
      default: {
        return 'icon-amber'; // for UNREACHABLE state
      }
    }
  }

  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }
  checkForTrim(value: string) {
    this.deviceInfoWithoutTrim = value;
    return value;
  }
  dealerCustomerData(id: string, name: string) {
    const trimmedName = name?.trim();
    const trimmedId = id?.trim();
    if (trimmedName && trimmedId) {
      return `${trimmedName} - ${trimmedId}`;
    } else if (trimmedName) {
      return trimmedName;
    } else if (trimmedId) {
      return trimmedId;
    }
    return '-';
  }

  updateDatStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    if (CATDigitalAuthStatus == 'AGREE') {
      this.datStatus = 'AGREE';
      return `AVAILABLE`;
    } else if (CATDigitalAuthStatus == 'DECLINE') {
      this.datStatus = 'DECLINE';
      return `DECLINED`;
    } else if (CATDigitalAuthStatus == 'UNAVAILABLE') {
      this.datStatus = 'UNAVAILABLE';
      return `NOT AVAILABLE`;
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `SYSTEM ERROR`;
    }
  }

  updateDATBadge() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'DECLINE': {
        return 'dat-badge-declined';
      }
      case 'UNAVAILABLE': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, all other states
      }
    }
  }

  cvaURLClick() {
    window.open(this.cvaUrl, '_blank');
  }
}
