import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-expanded-details',
  templateUrl: './expanded-details.component.html',
  styleUrls: ['./expanded-details.component.scss'],
})
export class ExpandedDetailsComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnTitle: any;
  public productName: any;
  showTruncatedText = false;
  constructor(private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp: any) => {
      this.showTruncatedText = dsp?.featureFlagInfo?.showTruncatedText
        ? dsp?.featureFlagInfo?.showTruncatedText
        : false;
    });
    this.rowData = this.value;
  }
  expandedProduct(rowData: any) {
    switch (this.columnTitle) {
      case 'product':
        this.productName = `${rowData.product}`;
        break;
      case 'fromSub':
        this.productName = `${rowData.fromSub}`;
        break;
      case 'toSub':
        this.productName = `${rowData.toSub}`;
        break;
      case 'dealerInfo':
        this.productName = `${rowData.dealerInfo}`;
        break;
      case 'ucidName':
        this.productName = `${rowData.ucidName} - ${rowData.ucid}`;
        break;
      case 'dealerCustomerName':
        this.productName = `${rowData.dealerCustomerName} - ${rowData.dealerCustomerNo}`;
        break;
      case 'createdByUserName':
        this.productName = `${rowData.createdByUserName}`;
        break;
    }

    return this.productName === 'null' || this.productName === 'undefined' ? '' : this.productName;
  }
}
