import { Inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { logoutEndPoint, postLogoutURL } from './auth-config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { MobileAppDownloadComponent } from '../components/mobile-app-download/mobile-app-download.component';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import * as dspConstants from '../shared/dspConstants';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import {
  DSP_EXPIRATION,
  DSP_LOGIN_INTERACTION,
  DSP_TOKEN,
  DSP_NAME,
  DSP_ACCOUNT,
} from '../auth/constants/dsp-ui.constants';

import { AppConfigService } from '../services/app-config.service';

import * as CONFIG_LOCAL from '../../assets/config/app-config.json';
import * as CONFIG_INT from '../../assets/config/app-config.int.json';
import * as CONFIG_QA from '../../assets/config/app-config.qa.json';
import * as CONFIG_QA_DSP_SIDEB from '../../assets/config/app-config.qa.dsp.sideb.json';
import * as CONFIG_QA2_DSP_SIDEB from '../../assets/config/app-config.qa2.dsp.sideb.json';
import * as CONFIG_QA2 from '../../assets/config/app-config.qa2.json';
import * as CONFIG_STAGE from '../../assets/config/app-config.stage.json';
import * as CONFIG_PROD from '../../assets/config/app-config.prod.json';
import * as CONFIG_PROD_SIDEB from '../../assets/config/app-config.prod.sideb.json';

const env = environment.env;

const config_file =
  env === 'local'
    ? CONFIG_LOCAL
    : env === 'int'
    ? CONFIG_INT
    : env === 'qa'
    ? CONFIG_QA
    : env === 'qa_dsp_sideb'
    ? CONFIG_QA_DSP_SIDEB
    : env === 'qa2_dsp_sideb'
    ? CONFIG_QA2_DSP_SIDEB
    : env === 'qa2'
    ? CONFIG_QA2
    : env === 'beta'
    ? CONFIG_STAGE
    : env === 'dsp_sideb'
    ? CONFIG_PROD_SIDEB
    : env === 'prod'
    ? CONFIG_PROD
    : CONFIG_LOCAL;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  env = environment;

  //fedLogin
  private _decodedAccessToken: any;
  private _decodedIDToken: any;

  get decodedAccessToken() {
    return this._decodedAccessToken;
  }
  get decodedIDToken() {
    return this._decodedIDToken;
  }
  vpnError = dspConstants.Common.VPN_ERROR;

  // Common
  private jwtHelper: JwtHelperService = new JwtHelperService();
  breakPoint$!: Observable<any>;
  isMobileOrTablet!: boolean;
  b2c = true;

  //B2c
  selectedPath = '/';
  isLoginRedirected = false;
  isLogoutTriggered = false;
  isMenuDisabled = false;
  isLoggedInUserUnauthorised = false;
  isUserAuthorised = false;
  isUserValid = false;

  expirationTimer$!: Observable<number>;
  expirationSubscription!: Subscription;
  onDestroy$ = new Subject();

  count: any;

  constructor(
    //Common
    private modal: CcModal,
    private breakPointObserver: BreakpointObserver,
    //fedLogin
    private oauthService: OAuthService,
    private authConfig: AuthConfig,
    private messageBar: MessageBar,
    //B2c
    public router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfigService: AppConfigService,
    @Inject('Window') readonly window: any
  ) {
    this.b2c = this.env.redirectURL.b2c;
    if (window?.location?.pathname === '/asset') {
      window.localStorage.setItem('thirdPartyApi', window.location.href);
    }
  }

  async initAuth(): Promise<any> {
    if (this.b2c) {
      await this.msalService.instance.initialize();
      return new Promise<void>((resolveFn, rejectFn) => {
        this.msalService.instance.handleRedirectPromise().then((response) => {
          if (response != null && response.account != null) {
            this.msalService.instance.setActiveAccount(response.account);

            const resExpriesOn = response.expiresOn?.getTime() || '';
            window.localStorage.setItem(DSP_TOKEN, response.accessToken);

            window.localStorage.setItem(DSP_NAME, response.account.name as string);
            window.localStorage.setItem(DSP_EXPIRATION, resExpriesOn.toString());
            window.localStorage.setItem(DSP_LOGIN_INTERACTION, 'login-complete');
            window.localStorage.setItem(DSP_ACCOUNT, JSON.stringify(response.account.idTokenClaims));

            this.setExpirationToken();
            window.location.href = environment.redirectURL.hostBaseUrl;
          }
        });

        if (this.isLoggedIn() && this.isTokenAlive(DSP_EXPIRATION)) {
          this.isUserAuthorised = true;
          this.setExpirationToken();
          if (this.checkForCatInternalUsers(this.decodeToken())) {
            resolveFn();
          } else {
            this.router.navigate(['denied']);
            resolveFn();
          }
        } else if (this.isLoggedIn() && !this.isTokenAlive(DSP_EXPIRATION)) {
          console.log('logout');
          this.logoutSession();
          resolveFn();
        } else {
          console.log('login');
          this.login();
          resolveFn();
        }
      });
    } else {
      return new Promise<void>((resolveFn, rejectFn) => {
        // setup oauthService
        this.oauthService.configure(this.authConfig);
        this.oauthService.setStorage(localStorage);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();

        // subscribe to token events
        this.oauthService.events.pipe(filter((e: any) => e.type === 'token_received')).subscribe(({ _type }) => {
          this.handleNewToken();
        });

        this.oauthService.loadDiscoveryDocumentAndLogin().then(
          (isLoggedIn) => {
            if (isLoggedIn) {
              // Call this event to set or update access and ID token when page refresh
              this.handleNewToken();
              if (this.checkForCatInternalUsers(this.decodedAccessToken)) {
                this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');
                resolveFn();
              }
            } else {
              this.oauthService.initImplicitFlow();
              rejectFn();
            }
          },
          (error) => {
            if (error.status === 400) {
              location.reload();
            }
            if (error.status === 0 && environment.env !== 'prod') {
              this.showToastMessage(this.vpnError, 'error');
            }
          }
        );
      });
    }
  }

  decodeToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token') || '');
  }

  /**
   * @returns A Boolean
   * @description Used for checking if a user account is logged in, it can be in token expired state
   */
  isLoggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  /**
   * @description Used for user account login via B2C redirect login method
   */
  login() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) =>
            status === InteractionStatus.None &&
            !this.isLoggedIn() &&
            window.localStorage.getItem(DSP_LOGIN_INTERACTION) !== 'login-complete'
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        if (!window.localStorage.getItem(DSP_LOGIN_INTERACTION)) {
          window.localStorage.setItem(DSP_LOGIN_INTERACTION, 'redirect-done');
          this.msalService.loginRedirect({
            scopes: ['openid', config_file.msalConfig.auth.clientId, 'profile', 'offline_access'],
            extraQueryParameters: config_file.msalConfig.extraQueryParameters,
          });
        }
      });
  }

  /**
   * @description Used for logging out the user account and trigger re-login
   *  B2c logout
   */
  logoutSession() {
    if (this.b2c) {
      window.sessionStorage.clear();
      this.removeTokensFromStorage();
      this.msalService.instance
        .logoutRedirect({
          postLogoutRedirectUri: environment.redirectURL.hostBaseUrl,
        })
        .then(() => {
          this.login();
        });
    } else {
      this.oauthService.logoutUrl = `${this.oauthService.issuer}${logoutEndPoint}`;
      this.oauthService.stopAutomaticRefresh();
      this.oauthService.logOut({
        id_token_hint: sessionStorage.getItem('id_token'),
        TargetResource: `${window.location.origin}/`,
        InErrorResource: `${window.location.origin}/`,
      });
      sessionStorage.clear();
      localStorage.clear();
    }
  }

  /**
   * @param onTimer Is function called via timer
   * @description Used to refresh the access token for the user automatically
   */
  refreshAccessToken(onTimer = true) {
    if (onTimer) {
      this.expirationSubscription.unsubscribe();
    }

    if (this.count === undefined) {
      this.count = 1;
    }
    //Session timer for 10 hours then logout
    if (this.count == 10) {
      //this.logoutSession();
      this.router.navigate(['session-expired']);
    } else {
      this.msalService
        .acquireTokenSilent({
          scopes: this.appConfigService.getScopes(),
          account: this.msalService.instance.getAllAccounts()[0],
        })
        .subscribe({
          next: (accessTokenResponse) => {
            window.localStorage.setItem(DSP_TOKEN, accessTokenResponse.accessToken);
            const accessExpToken = accessTokenResponse.expiresOn?.getTime() || '';
            window.localStorage.setItem(DSP_EXPIRATION, accessExpToken.toString());
            this.count = !accessTokenResponse.fromCache ? this.count + 1 : this.count;
            this.setExpirationToken();
          },
          error: () => this.logoutSession(),
        });
    }
  }

  /**
   * @param item String to be checked
   * @returns A Boolean
   * @description Used for checking if a string is set in localstorage
   */
  public isTokenAlive(item: string): boolean {
    const accessExpToken = window.localStorage.getItem(item) || '';
    return new Date(+accessExpToken) > new Date();
  }

  /**
   * @description Removes the token information from the browser local storage
   */
  public removeTokensFromStorage() {
    window.localStorage.removeItem(DSP_LOGIN_INTERACTION);
    window.localStorage.removeItem(DSP_TOKEN);
    window.localStorage.removeItem('dsp-refresh');
    window.localStorage.removeItem(DSP_EXPIRATION);
  }

  /**
   * @description Sets the timer observable that will emit when token expires.
   * When it expires, it will attempt to get another token
   */
  setExpirationToken() {
    const tokenExpiration = parseInt(window.localStorage.getItem(DSP_EXPIRATION) as string, 10);
    const timeUntilExpiry = tokenExpiration - Date.now();
    this.expirationTimer$ = timer(timeUntilExpiry - 5 * 60 * 1000);

    this.expirationSubscription = this.expirationTimer$.subscribe(() => this.refreshAccessToken());
  }

  private handleNewToken() {
    this._decodedAccessToken = this.jwtHelper.decodeToken(this.oauthService.getAccessToken());

    this._decodedIDToken = this.jwtHelper.decodeToken(this.oauthService.getIdToken());
  }

  checkForCatInternalUsers(accessToken: any) {
    if (!this.checkForMobileTabletUsers()) {
      const authenticatedCodes = ['001', '005', '012', '013', '014', '019', '048', '053', '054'];
      const catafltncode = accessToken['catafltncode'];
      if (authenticatedCodes.includes(catafltncode)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  checkForMobileTabletUsers() {
    const userAgent = navigator.userAgent || navigator.vendor;
    const message = dspConstants.Common.MOBILE_APP_DOWNLOAD_POPUP_MESSAGE;
    const title = dspConstants.Common.MOBILE_APP_DOWNLOAD_POPUP_TITLE;
    const androidAppLink = dspConstants.Common.MOBILE_APP_DOWNLOAD_POPUP_ANDROID_APP_LINK;
    const iosAppLink = dspConstants.Common.MOBILE_APP_DOWNLOAD_POPUP_IOS_APP_LINK;
    let appLink;

    if (/android/i.test(userAgent)) {
      appLink = androidAppLink;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      appLink = iosAppLink;
    } else {
      return false;
    }

    this.breakPoint$ = this.breakPointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .pipe(map((result: any) => result?.matches));

    this.breakPoint$.subscribe({
      next: (result: boolean) => {
        this.isMobileOrTablet = result ? true : false;
      },
    });

    if (this.isMobileOrTablet) {
      const modalRef = this.modal.openModal(MobileAppDownloadComponent, {
        type: 'semi-modal',
        width: '512px',
        data: {
          title: title,
          link: appLink,
          message: message,
        },
        hasBackdrop: true,
        closeOnEsc: false,
        hideHeader: false,
        hideFooter: false,
        disableMargin: true,
        disableBackdropClick: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      modalRef.afterClosed().subscribe((result: any) => {});

      return true;
    }
    return false;
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
