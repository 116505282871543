<div class="cc-modal-header header-first">
  <div class="header-title">Dealer Services Portal | End User License Agreement</div>
</div>
<div class="legal-page-content header-second">
  <p><strong>Dealer Services Portal Terms of Use</strong></p>
  <p>
    Welcome to dsp.cat.com, a Caterpillar Inc. website. These terms and conditions (the
    <strong>“Terms of Use”</strong>) govern your access to and use of (i) dsp.cat.com (the <strong>“Site”</strong>);
    (ii) all applications and service offerings provided through the Site (e.g., the ability to apply subscriptions or
    the ability to initiate remote flash) (<strong>“Service Offerings”</strong>); and (iii) all data, text, graphics,
    user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code made
    available by or through the Site (<strong>“Content”</strong>). For the avoidance of doubt, the term “Service
    Offerings” does not include any digital offerings that you may purchase on the Site for resale to customers. This is
    an agreement between the Authorized Caterpillar dealer you represent (<strong>“you”</strong>) and Caterpillar Inc.,
    a Delaware corporation with offices located at 100 N.E. Adams St., Peoria, IL 61629 (together with our subsidiaries
    that may make the Site, Service Offerings, or Content available to you,
    <strong>“Caterpillar” “we,” “us,”</strong> or <strong>“our”</strong>). Caterpillar is willing to grant access to and
    use of the Site, Service Offerings and Content to you solely upon your acceptance of all of the terms and conditions
    of these Terms of Use without modification.
  </p>
  <p>
    <strong
      >BY CLICKING ON “OK,” “ACCEPT,” “AGREE,” “SUBMIT,” OR ANY BUTTON INDICATING ACCEPTANCE OF THESE TERMS OF USE OR BY
      ACCESSING OR USING THE SITE, SERVICE OFFERINGS OR CONTENT, YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND ARE
      REPRESENTING THAT YOU ARE AUTHORIZED TO ACCESS AND USE THE SITE, SERVICE OFFERINGS AND CONTENT AND ARE LAWFULLY
      ABLE TO ENTER INTO THESE TERMS OF USE AND HAVE THE LEGAL AUTHORITY TO BIND ANY ENTITY YOU REPRESENT. IF YOU DO NOT
      AGREE TO THESE TERMS OF USE OR ANY SUBSEQUENT MODIFICATION, DO NOT ACCESS, BROWSE OR OTHERWISE USE THE SITE, THE
      SERVICE OFFERINGS OR THE CONTENT.</strong
    >
  </p>

  <p>
    We reserve the right, in our sole discretion, to update or modify these Terms of Use at any time. Your continued
    access to and use of the Site, Service Offerings or Content following the posting of any changes to these Terms of
    Use constitutes acceptance of those changes. Please review the most current version of these Terms of Use at any
    time at
    <a href="{{ dynamicUrl }}" target="_blank" style="color: #2595ff"> {{ dynamicUrl }} </a>.
  </p>
  <p><strong>Networked Websites and Service Offerings</strong></p>
  <p>
    Notwithstanding anything to the contrary in these Terms of Use, additional or different terms and conditions may
    apply to certain Service Offerings and networked sites operated by Caterpillar (the
    <strong>“Networked Sites”</strong>). If applicable, such additional or different terms and conditions will be posted
    on the relevant Networked Sites or Service Offerings. If a Networked Site or Service Offering has imposed additional
    or different terms and conditions, the provisions of that Networked Site or Service Offering shall control in the
    event of a conflict with these Terms of Use. Except as expressly supplemented or superseded as described herein,
    these Terms of Use apply to all Networked Sites and Service Offerings and control your use thereof.
  </p>
  <p><strong>Use of Site, Service Offerings and Content</strong></p>
  <p>
    You may access and use the Site, Service Offerings and Content solely in accordance with these Terms of Use. You
    will strictly adhere to all laws, rules, regulations and Caterpillar policies applicable to the access and use of
    the Site, Service Offerings and Content, including those laws, rules and regulations that apply to online conduct,
    online content, and the export of data to and from the United States and your country of residence. In addition, you
    will not:
  </p>
  <ul>
    <li>
      Access, acquire, copy or monitor any portion of the Site, or in any way reproduce or circumvent the navigational
      structure or presentation of the Site, to obtain or attempt to obtain any materials, documents or information
      through any means not purposely made available through the Site, including through the use of any "deep-link",
      "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or
      equivalent manual process.
    </li>
    <li>
      Attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks
      connected to the Site or to any server, including by hacking, password "mining" or any other illegitimate means.
    </li>
    <li>
      Probe, scan or test the vulnerability of the Site or any network connected to the Site, nor breach the security or
      authentication measures on the Site or any network connected to the Site.
    </li>
    <li>
      Reverse look-up, trace or seek to trace any information on any other user of or visitor to the Site, or any other
      customer of Caterpillar, including but not limited to personal identification or information.
    </li>
    <li>
      Take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Site or
      Caterpillar's systems or networks, or any systems or networks connected to the Site or to Caterpillar.
    </li>
    <li>
      Use any device, software or routine to interfere or attempt to interfere with the proper working of the Site,
      Service Offerings or Content, any transaction being conducted on the Site, or with any other person's use of the
      Site, Service Offerings or Content.
    </li>
    <li>
      Distribute viruses or any other technologies that may harm Caterpillar or the interests or property of any other
      person or entity, including any other user of the Site.
    </li>
    <li>
      Circumvent or manipulate our fee structure, the billing process or fees owed to Caterpillar, its dealers or its
      business associates.
    </li>
    <li>
      Forge or manipulate identifiers in order to disguise the origin of any message or transmittal you send to
      Caterpillar on or through the Site.
    </li>
    <li>Pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</li>
    <li>
      Use the Site, Service Offerings or Content for any purpose that is unlawful, prohibited by these Terms of Use, or
      to solicit the performance of any illegal activity or other activity which infringes the rights of Caterpillar or
      others.
    </li>
  </ul>
  <p><strong>Intellectual Property Rights</strong></p>
  <p>
    Except to the extent expressly provided in these Terms of Use under the heading "Grant of License," and with
    “Site/Service Offering Information,” you agree and acknowledge that:
  </p>
  <ul>
    <li>
      Caterpillar owns or licenses any and all rights under patent law, copyright law, trade secret law, trademark law,
      and any and all other proprietary or moral rights, and any and all applications, renewals, extensions and
      restorations thereof, now or hereafter in force and effect worldwide (<strong
        >“Intellectual Property Rights”</strong
      >) in connection with or relating to the Site, Service Offerings and Content, including but not limited to the
      design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained
      on the Site or provided as part of our Service Offerings (<strong>“Caterpillar IP”</strong>).
    </li>
    <li>
      Except as otherwise set forth under “Grant of License” below, you have no right, license, or interest in any
      Intellectual Property Rights in connection with or relating to the Site, Service Offerings or Content.
    </li>
    <li>
      Caterpillar owns all right, title and interest in and to any replacements, improvements, updates, enhancements,
      derivative works and other modifications (including, without limitation, the incorporation of any ideas, methods
      or processes provided by or through you) to Caterpillar IP made by any person, even if paid for by you and
      regardless of whether or not they are similar to any of your Intellectual Property Rights. You will undertake any
      and all action necessary to vest such ownership in Caterpillar, including without limitation assigning (and you
      hereby assign) to Caterpillar all rights in and to such Intellectual Property Rights, including, without
      limitation, patent applications, patents, moral rights and copyrights arising from or relating to the Site,
      Service Offerings or Content.
    </li>
    <li>
      No part of the Site, Service Offerings or Content may be copied, reproduced, republished, uploaded, posted,
      publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any
      other computer, server, website or other medium for publication or distribution or for any commercial enterprise,
      without Caterpillar's express prior written consent.
    </li>
    <li>
      All marks that appear throughout the Site, Service Offerings and Content belong to Caterpillar, or the respective
      owners of such marks, and are protected by U.S. and international trademark and copyright laws. Any use of any of
      the marks appearing throughout the Site, Service Offerings and Content without the express written consent of
      Caterpillar or the owner of the mark, as appropriate, is strictly prohibited.
    </li>
    <li>
      Caterpillar may, at its sole discretion, disable and terminate access to the Site, Service Offerings and Content
      by any persons or entity that may be infringing the Intellectual Property Rights of Caterpillar or others.
    </li>
  </ul>
  <p><strong>Grant of License</strong></p>
  <p>
    Caterpillar grants you a limited, non-exclusive, non-transferable, non-assignable license (without the right to
    sublicense) to access and use the Site, Service Offerings and Content solely in accordance with this Grant of
    License and these Terms of Use. All rights not expressly granted to you herein are reserved by Caterpillar. You will
    use the Site, Service Offerings and Content only in a commercially reasonable manner for your legitimate internal
    business purposes and purposes otherwise consistent with these Terms of Use. You may not:
  </p>
  <ul>
    <li>
      Reproduce, modify, publish, distribute, publicly display, adapt, alter, translate, or create derivative works from
      the Site, Service Offerings or Content.
    </li>
    <li>
      Sublicense, lease, sell, rent, loan, or otherwise transfer the Site, Service Offerings or Content to any third
      party.
    </li>
    <li>
      Reverse engineer, de‑compile, disassemble, or otherwise attempt to derive the source code for the Site, Service
      Offerings or Content.
    </li>
    <li>
      Otherwise use or copy the Site, Service Offerings or Content except as expressly allowed under this Grant of
      License and these Terms of Use.
    </li>
    <li>
      Use the Site, Service Offerings or Content in a “service bureau” or similar structure whereby third parties obtain
      use of the Site, Service Offerings or Content through you.
    </li>
    <li>
      Remove, obscure, or alter any copyright, trademark, or other proprietary notices embedded in, affixed to or
      accessed in conjunction with the Site, Service Offerings or Content.
    </li>
  </ul>
  <p><strong>Copyright Agent</strong></p>
  <p>
    Caterpillar respects the rights of all copyright holders and in this regard, Caterpillar has adopted and implemented
    a policy that provides for the removal from the Site of materials that infringe the rights of copyright holders. If
    you believe that your work has been copied in a way that constitutes copyright infringement, please provide
    Caterpillar's Copyright Agent all of the following information required by the Online Copyright Infringement
    Liability Limitation Act of the Digital Millennium Copyright Act, 17 U.S.C. § 512:
  </p>
  <ul>
    <li>
      A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that
      is allegedly infringed.
    </li>
    <li>
      Identification of the copyright work claimed to have been infringed, or, if multiple copyrighted works at a single
      on-line site are covered by a single notification, a representative list of such works at that site.
    </li>
    <li>
      Identification of the material that is claimed to be infringing or to be the subject of infringing activity and
      that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to
      locate the material.
    </li>
    <li>Information reasonably sufficient to permit us to contact the complaining party.</li>
    <li>
      A statement that the complaining party has a good-faith belief that use of the material in the manner complained
      of is not authorized by the copyright owner, its agent, or the law.
    </li>
    <li>
      A statement that the information in the notification is accurate, and under penalty of perjury, that the
      complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
    </li>
  </ul>
  <p>
    Caterpillar's Copyright Agent for notice of claims of copyright infringement on or regarding this site can be
    reached as follows:
  </p>
  <div>Copyright Agent</div>
  <div>100 N.E. Adams St.</div>
  <div>Peoria, IL 61629-9620</div>
  <div>Email: <a href="mailto:CopyrightAgent@cat.com" style="color: #2595ff">CopyrightAgent&#64;cat.com</a></div>
  <p>
    <strong><span>Submissions</span></strong>
  </p>

  <p>
    <span
      >Do not use the Site as a means of submitting information you consider to be proprietary. Except as otherwise
      expressly provided in the terms of use for your transactions with a Networked Site, a Service Offering or in a
      written agreement with Caterpillar applicable to your particular use of a Networked Site or Service Offering, any
      submission of materials by you will be considered a contribution to Caterpillar for further use in its sole
      discretion, regardless of any proprietary claims or reservation of rights noted in the submission. Accordingly,
      you agree that any materials, including but not limited to questions, comments, suggestions, ideas, plans, notes,
      drawings, original or creative materials or other information, provided by you in the form of e-mail or
      submissions to Caterpillar, or postings on the Site, are non-confidential (subject to our </span
    ><a style="color: #2595ff" class="privacyLink" (click)="privacyModal()">Privacy Statement</a
    ><span
      >) and shall become the sole property of Caterpillar. Caterpillar shall own exclusive rights, including all
      Intellectual Property Rights, and shall be entitled to the unrestricted use of these materials for any purpose,
      commercial or otherwise, without acknowledgment or compensation to you. The submission of any materials to
      Caterpillar, including the posting of materials to any forum or interactive area, irrevocably waives any and all
      "moral rights" in such materials, including the rights of paternity and integrity.</span
    >
  </p>

  <p>
    <strong><span>Site/Service Offering Information</span></strong>
  </p>

  <p>
    <span
      >By accessing the Site or using the Service Offerings, you consent to the collection, storing, processing, use,
      sharing and disclosure of information, as described in the
    </span>
    <a href="http://www.cat.com/data_governance_statement" target="_blank" style="color: #2595ff"
      >Data Governance Statement</a
    ><span>
      and the <a style="color: #2595ff" class="privacyLink" (click)="privacyModal()">Privacy Statement.</a>&nbsp;The
      Data Governance Statement or the Privacy Statement may change from time to time, so review them with regularity
      and care.&nbsp;The Site and Service Offerings may also collect and process other information arising from access
      to or use of the Site and Service Offerings or that is otherwise communicated through access to or use of the Site
      or Service Offerings, including information collected from other sources (whether provided by third parties or
      Caterpillar) that make such information available to the Site or Service Offerings.&nbsp;The information
      collected, stored, processed, used, shared or disclosed by or through the Site and Service Offerings is referred
      to as &ldquo;<strong>Site/Service Offering Information</strong>.&rdquo;<strong> </strong
    ></span>
  </p>

  <p>
    <span class="s10"
      ><span
        >You hereby grant to Caterpillar, and each of its respective licensors, service providers, suppliers,
        subcontractors and distributors a non-exclusive, worldwide, perpetual, paid-up, right and license, including the
        right to grant and authorize sublicenses through multiple levels, to access, use, process, manipulate, modify,
        compile with other data or works and/or create derivative works of&nbsp;the Site/Service Offering Information
        provided that such use remains in accordance&nbsp;with&nbsp;the Data Governance Statement&nbsp;and the Privacy
        Statement. You agree that the foregoing license shall apply retroactively to the date on which the Site/Service
        Offering Information was collected, which may have occurred prior to you entering into these Terms of
        Use.&nbsp;You understand and agree that&nbsp;the Site/Service Offering Information may be transmitted to and
        processed in countries that have different data protection laws than in the country in which you have your
        principal place of business.&nbsp;Caterpillar does not assume any obligations with respect to&nbsp;the
        Site/Service Offering Information, other than as expressly set forth in these Terms of Use or as required by
        applicable&nbsp;laws.</span
      ></span
    ><span> </span>
  </p>

  <p>
    <span
      >For purposes of these Terms of Use, &ldquo;<span class="s10"
        ><strong>Site/Service Offering User Data</strong></span
      >&rdquo; means all <span class="s10">Site/Service Offering Information</span> that you submit or otherwise make
      available to Caterpillar by accessing the Site or Content or using the Service Offerings.&nbsp;Your rights to the
      <span class="s10">Site/Service Offering </span>User Data shall remain subject to any restrictions or terms that
      apply to the <span class="s10">Site/Service Offering </span>User Data under any other agreement you may have with
      Caterpillar, and Caterpillar does not make any representations or warranties with respect to such data under these
      Terms of Use.&nbsp;You represent, warrant and covenant that (a) you have secured and will maintain all rights, and
      have obtained and provided all required notices and obtained all legally required consents, necessary to make
      available the <span class="s10">Site/Service Offering </span>User Data (including all </span
    ><span>Personal Information</span
    ><span>
      (as such term is defined in the Data Governance Statement) contained therein) to Caterpillar and its respective
      licensors, service providers, suppliers and distributors and to enable such entities to provide the Site, Content
      and Service Offerings in accordance with these Terms of Use, and to exercise the rights and licenses granted
      hereunder, without violating the rights of any third party or otherwise obligating Caterpillar to you or to any
      third party, and (b) you are solely responsible for all the
      <span class="s10">Site/Service Offering </span>User Data, including</span
    ><span>: (i) the accuracy, integrity, quality, legality, reliability, and appropriateness of the </span
    ><span class="s10"><span>Site/Service Offering </span></span><span>User Data</span
    ><span>; (ii) creating and maintaining backups and copies of all the </span
    ><span class="s10"><span>Site/Service Offering </span></span><span>User Data</span
    ><span>, including for use in the event of a</span>&nbsp;<span>disaster or loss of the </span
    ><span class="s10"><span>Site/Service Offering </span></span><span>User Data</span
    ><span>; and (iii) adopting procedures to identify and correct errors and omissions in the </span
    ><span class="s10"><span>Site/Service Offering </span></span><span>User Data </span
    ><span>and correcting such errors and omissions.</span>
  </p>
  <p>
    <span
      >Some features of the Site, Content and Service Offerings may require use of various communications systems, such
      as telematics wireless communications carriers, satellite-based communication systems, internet service providers
      and other similar systems.&nbsp;Caterpillar uses various technologies and processes designed to secure
      communications within Caterpillar-provided communications systems; however, you recognize that such communication
      methods have an inherent risk of interception and/or interference and, therefore, may not be secure.&nbsp;You
      hereby consent to such communications and waive any claims that you may have against Caterpillar with respect to
      such communication.&nbsp;Caterpillar has no responsibility for the availability, quality or performance of
      communications services or equipment furnished by third-party communication carriers.&nbsp;</span
    ><span
      >To the extent not prohibited by applicable laws, Caterpillar may use such communication systems to remotely
      access and program Assets (as defined in the Data Governance Statement), including telematics devices installed on
      Assets, for any purpose, including by way of example, (a)&nbsp;to install updates and upgrades to software,
      firmware, or operating systems (for example, to enhance safety, security or maintain proper operation of Assets);
      or (b) to introduce new features, and/or change the type and frequency of data transmitted through telematics
      devices (for example, to conduct remote troubleshooting and/or provide increased customer value).&nbsp;Caterpillar
      cannot guarantee that user preferences and configuration settings that have been established by you will be
      preserved following an update to Assets, whether performed remotely or otherwise.&nbsp;Except to the extent
      prohibited by applicable laws, Caterpillar may perform such activities without notifying you or the owner of the
      machines or equipment associated with the Assets.</span
    >
  </p>
  <p>
    <span
      >If Caterpillar receives any order, demand, warrant, or any other document requesting or purporting to compel the
      production of Personal Information in the Site/Service Offering User Data (including, for example, by oral
      questions, interrogatories, requests for information or documents in legal proceedings, subpoenas, civil
      investigative demands or other similar processes), Caterpillar will promptly notify you (to the extent permissible
      under applicable laws), so that you may, at your own expense, exercise such rights as you may have under
      applicable laws to prevent or limit such disclosure.&nbsp;Notwithstanding the foregoing, Caterpillar will exercise
      commercially reasonable efforts to prevent and limit any such disclosure and to otherwise preserve the
      confidentiality of Personal Information in the Site/Service Offering User Data and will cooperate with you with
      respect to any action taken with respect to such request, complaint, order or other document, including to obtain
      an appropriate protective order or other reliable assurance that confidential treatment will be accorded to such
      Personal Information.
    </span>
  </p>

  <p>
    <strong><span>Authorized Users, Passwords, License Keys and Security</span></strong>
    <br />
    <span
      >You agree that the Site, Service Offerings and Content may be accessed or used only by your dealership. For that
      purpose your dealership will designate authorized users that you authorize to access or use the Site, Service
      Offerings and Content ("<strong>Authorized User</strong>"). Certain functions of the Site, Service Offerings and
      Content may be restricted to Authorized Users that have acquired an appropriate license from Caterpillar or an
      authorized Caterpillar dealer. In addition, Caterpillar limits access to and use of certain functions of the Site,
      Service Offerings and Content to only authorized Caterpillar dealers, as Caterpillar, in its sole discretion,
      deems appropriate, and no rights to obtain license keys for such functions is granted herein. Your right to use
      the Site, Service Offerings and Content are subject to your registration as an Authorized User, our delivery to
      you of an appropriate license key, if any, and your payment of all fees, if any such fees are imposed by
      Caterpillar for use or access to certain Site, Service Offerings or Content.
    </span>

    <span
      ><br />The Site may include various protection mechanisms to prevent use of (i) the Site, Service Offerings and
      Content until you become registered as an Authorized User and (ii) certain functions of the Site, Content and
      Service Offerings without the appropriate license keys. In order to log on and use certain Site, Service Offerings
      and Content, Authorized Users may be asked to provide Caterpillar with true, accurate, current and complete
      registration information and update such information as necessary. Should Caterpillar have reasonable grounds to
      suspect that any information provided by an Authorized User is inaccurate, incomplete or untrue, it shall have the
      right to suspend or terminate your and such Authorized User's right to use the Site, Service Offerings and
      Content.
    </span>

    <span
      ><br />Upon the initial logon to the Site, Authorized Users may be required to create a username and password,
      which will be needed to access certain features of the Site, Service Offerings and Content. Each time that an
      Authorized User logs onto the Site, certain information, including the username, may be sent in communications
      with the servers hosted by Caterpillar. This information may be used to access such Authorized User's account and
      other personalized features. Caterpillar may match the username to personally identifiable information in order to
      provide the Authorized User with the Site, Service Offerings and Content that the Authorized User is entitled to
      and to provide relevant data and information.
    </span>

    <span
      ><br />You hereby agree that you have complied and will comply with all applicable laws relating to the
      transmission and use of such data and information, including any personally identifiable data contained therein
      and have provided and will provide all required notices to, and have obtained and will obtain all necessary
      consents from, your designated Authorized Users and complied fully with your obligations under the relevant
      legislation.
    </span>

    <span
      ><br />You are responsible for maintaining the confidentiality of your designated Authorized Users' usernames and
      passwords and are fully responsible for all activities that occur on the Site under your designated Authorized
      Users' usernames and passwords. We reserve the right to require you to change your designated Authorized Users'
      username in the event we determine, in our sole discretion, that any username is offensive or for any other
      reason. You agree to immediately notify Caterpillar of any unauthorized use of Authorized Users' username,
      password, or any other breach of security, and to ensure that Authorized Users logout at the end of each session.
    </span>
  </p>

  <p>
    <strong><span>Privacy</span></strong>

    <span
      ><br />When you access or use the Site, Service Offerings or Content, it may ask you for personal information
      (including your name and an identifier for your computer). Caterpillar collects such information, along with
      system information about the computer that you are using, including, the name of your computer, the operating
      system in use on your computer, the version of the software you are running and the IP address of your computer in
      order to validate that you are authorized to access the Site or Content or use the Service Offerings, to improve
      Caterpillar products and services, and to enable Caterpillar dealers to understand who within its service
      territory is using the Site, Service Offerings and Content. Caterpillar may provide such information to its
      affiliates, subsidiaries or other trusted businesses or persons, including Caterpillar dealers. Caterpillar will
      take reasonable and appropriate precautions to protect the confidentiality of such information; however,
      Caterpillar may, or you may by using the Site, Service Offerings or Content, transmit such information to a
      jurisdiction that does not provide the same level of data protection as the legal jurisdiction in which you are
      located. By entering such information and/or using the Site, Service Offerings or Content, you consent to the
      collection, processing and transfer of the information by Caterpillar consistent with this paragraph and the
      <a style="color: #2595ff" class="privacyLink" (click)="privacyModal()">Privacy Statement.</a>The Privacy Statement
      provides additional information on our data collection and processing practices on the websites described therein
      and should be read in conjunction with the Paragraph. In the event of any conflict between this paragraph and the
      Privacy Statement, the terms of the Privacy Statement shall prevail. For details about how other information may
      be collected and otherwise processed by Caterpillar, please see our Data Governance Statement. We may update our
      Privacy Statement at any time. Additional or different privacy policies may apply to specific Networked Sites,
      Service Offerings or Content.</span
    >
  </p>

  <p>
    <strong><span>Interactive Forums and User Materials</span></strong>
  </p>

  <p>
    <span
      >The Site may, from time to time, include discussion forums and interactive areas. Without limiting any other
      provisions of these Terms of Use, by using the forums or interactive areas, you agree not to do any of the
      following:</span
    >
  </p>
  <ul>
    <li>
      <p>
        <span
          >Upload to, distribute or otherwise publish through the Site any message, data, information, text or other
          material ("<strong>User Materials</strong>") that is unlawful, libelous, defamatory, obscene, pornographic,
          indecent, lewd, harassing, threatening, harmful, invasive of privacy or publicity rights, abusive,
          inflammatory or otherwise objectionable.</span
        >
      </p>
    </li>

    <li>
      <p>
        <span
          >Upload or transmit any User Materials that would constitute or encourage a criminal offense, violate the
          rights of any party, or that would otherwise create liability or violate any local, state, national, or
          international law.</span
        >
      </p>
    </li>

    <li>
      <p>
        <span
          >Upload or transmit any User Materials that may infringe any patent, trademark, trade secret, copyright or
          other intellectual or proprietary right of any party. By posting any User Materials, you represent and warrant
          that you have the lawful right to distribute and reproduce such User Materials.</span
        >
      </p>
    </li>
    <li>
      <p>
        <span
          >Without Caterpillar's written permission, distribute or publish unsolicited promotions, advertising or
          solicitations for funds, goods or services, including junk mail and spam.</span
        >
      </p>
    </li>
  </ul>
  <p>
    <span
      >Caterpillar takes no responsibility and assumes no liability for any User Materials posted or uploaded by you or
      any third party, or for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography or
      profanity you may encounter. As a provider of interactive services, if any, Caterpillar is only a forum and is not
      liable for any statements, representations, or User Materials provided by its users.</span
    >
  </p>

  <p>
    <span
      >Caterpillar has the right, but not the obligation, to monitor any activity and User Materials associated with its
      forums and interactive areas. Caterpillar may investigate any reported violation of its policies or complaints and
      take any appropriate action that it deems appropriate. Such action may include, but is not limited to, issuing
      warnings, suspension or termination of service, and/or removal of posted User Materials. Caterpillar reserves the
      right and has absolute discretion, to remove, screen or edit any User Materials that violate these provisions or
      is otherwise objectionable.
    </span>
  </p>

  <p>
    <span
      >You shall remain solely liable for any messages or other User Materials you upload or transmit to the Site,
      including the discussion forums or interactive areas of the Site.</span
    >
  </p>

  <p>
    <strong><span>No Warranties</span></strong>

    <span
      ><br />CATERPILLAR, ITS DIRECTORS, OFFICERS, EMPLOYEES, SUPPLIERS, DEALERS, AFFILIATES, AGENTS AND LICENSORS (THE
      "<strong>CATERPILLAR PARTIES</strong>") DISCLAIM ANY RESPONSIBILITY FOR ANY HARM RESULTING FROM YOUR USE OF THE
      SITE, SERVICE OFFERINGS OR CONTENT. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (a) THE SITE, SERVICE OFFERINGS AND
      CONTENT ARE PROVIDED ON AN "AS IS", "WITH ALL FAULTS" AND "AS AVAILABLE" BASIS AND THE ENTIRE RISK AS TO
      SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU; (b) TO THE FULLEST EXTENT PERMITTED BY
      APPLICABLE LAW, CATERPILLAR PARTIES MAKE NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS, IMPLIED, STATUTORY
      OR OTHERWISE, INCLUDING WITHOUT LIMITATION, (1) WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE, WORKMANLIKE EFFORT, ACCURACY, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT, (2)
      WARRANTIES ARISING THROUGH COURSE OF DEALINGS OR USAGE OF TRADE, (3) WARRANTIES REGARDING THE SECURITY,
      RELIABILITY, TIMELINESS, AND PERFORMANCE OF THE SITE, SERVICE OFFERINGS AND CONTENT, AND (4) WARRANTIES THAT
      ACCESS TO OR USE OF THE SITE, SERVICE OFFERINGS OR CONTENT WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED OR
      ERROR-FREE; AND (c) THAT YOU WILL ACCESS OR USE THE SITE, SERVICE OFFERINGS AND CONTENT AT YOUR OWN DISCRETION AND
      RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
      FROM SUCH ACCESS OR USE. THERE ARE NO WARRANTIES THAT EXTEND BEYOND THOSE EXPRESSLY GRANTED IN THESE TERMS OF USE.
    </span>
  </p>

  <p>
    <strong><span>Limitation of Liability; Remedy</span></strong>

    <span
      ><br />UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, SHALL THE CATERPILLAR PARTIES BE LIABLE TO YOU OR A THIRD
      PARTY (INCLUDING ANY CUSTOMER) FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL,
      SPECIAL, EXEMPLARY, PUNITIVE, THIRD PARTY OR CONSEQUENTIAL (INCLUDING DAMAGES FOR LOSS OF BUSINESS PROFITS,
      BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF BUSINESS INFORMATION, VIRUS INFECTIONS, SYSTEM OUTAGES AND THE LIKE)
      ARISING OUT OF, BASED ON OR RESULTING FROM THESE TERMS OF USE OR YOUR ACCESS TO, USE OF, MISUSE OF OR INABILITY TO
      USE THE SITE, SERVICE OFFERINGS OR CONTENT, EVEN IF CATERPILLAR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES (INCLUDING DAMAGES INCURRED BY THIRD PARTIES). THE EXCLUSION OF DAMAGES UNDER THIS SECTION IS INDEPENDENT
      OF ANY REMEDY PROVIDED UNDER THESE TERMS OF USE AND SURVIVES IN THE EVENT SUCH REMEDY FAILS OF ITS ESSENTIAL
      PURPOSE OR IS OTHERWISE DEEMED UNENFORCEABLE. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER
      DAMAGES ARISE FROM BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR ANY OTHER CAUSE OF ACTION, TO THE EXTENT THAT
      APPLICABLE LAW DOES NOT PROHIBIT SUCH EXCLUSIONS AND LIMITATIONS. IN NO EVENT SHALL CATERPILLAR'S TOTAL LIABILITY
      TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, STRICT LIABILITY, TORT (INCLUDING
      NEGLIGENCE) OR OTHERWISE, EXCEED THE AMOUNT THAT YOU HAVE PAID TO CATERPILLAR WITHIN THE MOST RECENTLY TERMINATED
      MONTH FOR YOUR ACCESS OR USE OF THE RELEVANT SITE, SERVICE OFFERING AND CONTENT.
    </span>
  </p>

  <p>
    <span
      >Certain laws do not allow limitations on implied warranties, or the exclusion or limitation of certain damages.
      If these laws apply, some or all of the above disclaimers, exclusions, or limitations, may not apply to you, and
      you may have additional rights to those contained herein.</span
    >
  </p>

  <p>
    <strong><span>Indemnification </span></strong
    ><span
      ><br />
      You agree to indemnify, defend and hold harmless each of the Caterpillar Parties from and against any and all
      claims, lawsuits, demands, actions or other proceedings brought against it by any third party due to, arising out
      of or related to your (a) use of the Site (including any messages or other User Materials you may post or upload
      to any interactive forum), Service Offerings or Content, (b) violation of these Terms of Use, or (c) violation of
      any law, regulation or third party rights. You shall pay any and all costs, damages and expenses, including,
      without limitation, reasonable attorneys' fees and costs awarded against or otherwise incurred by the Caterpillar
      Parties in connection with or arising from any such claim, lawsuit, action, demand or other proceeding.</span
    >
  </p>

  <p>
    <strong><span>Termination</span></strong
    ><span
      ><br />
      Caterpillar, in its sole discretion, may terminate your username and password and your right to access or use the
      Site, Service Offerings and Content at any time for any reason. The license granted herein will automatically
      terminate without further action of Caterpillar if you breach these Terms of Use or you fail to comply with any of
      the restrictions as recited herein. Upon the termination of these Terms of Use for any reason, you will cease all
      use of the Site, Service Offerings and Content. The provisions under the headings </span
    ><span>Site/Service Offering Information</span
    ><span
      >, No Warranties, Limitation of Liability; Remedy, Indemnification, Import and Export Compliance, Contractual
      Statute of Limitations, and Choice of Law; Severability shall survive the termination of these Terms of Use.</span
    >
  </p>

  <p>
    <strong><span>Updating </span></strong
    ><span
      ><br />
      We reserve the right, in our sole discretion, to change the Site, Service Offerings and Content at any time
      without notice, including the removal of the Site, Service Offerings and Content. As new or enhanced versions of
      the Site are made available, we may require that you update your current version of any related software. Unless
      otherwise explicitly stated by Caterpillar, any new or enhanced versions of the Site, Service Offerings and
      Content are subject to the terms of these Terms of Use.</span
    >
  </p>

  <p>
    <strong><span>Linked Sites</span></strong>

    <span
      ><br />The Site may contain links to other independent third-party websites or resources ("<strong
        >Linked Sites</strong
      >"). These Linked Sites are provided solely as a convenience to you. Such Linked Sites are not under Caterpillar's
      control, and Caterpillar is not responsible for and does not endorse the content of such Linked Sites, including
      any information or materials contained on such Linked Sites. You will need to make your own independent judgment
      regarding your interaction with these Linked Sites.</span
    >
  </p>

  <p>
    <strong><span>Import and Export Compliance</span></strong>

    <span
      ><br />You may not use or otherwise export or re-export the Site, Service Offerings and Content except as
      authorized by United States law and the laws of the jurisdiction in which the Site, Service Offering or Content
      was obtained. In particular, but without limitation, the Site, Service Offerings and Content may not be exported
      or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of
      Specially Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List. You
      represent and warrant that your access and use of the Site, Service Offerings and Content will not violate any
      such laws and that you are not located in any such country or on any such list. You also agree that you will not
      use the Site, Service Offerings or Content for any purposes prohibited by United States law, including, without
      limitation, the development, design, manufacture or production of nuclear, missiles, or chemical or biological
      weapons.</span
    >
  </p>

  <p>
    <strong><span>U.S. Government Rights</span></strong>

    <span
      ><br />The Site, Service Offerings and Content are "Commercial Items", as that term is defined at 48 C.F.R.
      §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation", as such
      terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48
      C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer
      Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with
      only those rights as are granted to all other end users pursuant to the terms and conditions herein.</span
    >
  </p>

  <p>
    <strong><span>ELECTRONIC SIGNATURE AND DISCLOSURE CONSENT NOTICE</span></strong>

    <span
      ><br />You agree to the use of electronic documents and records in connection with these Terms of Use and all
      future documents and records in connection with the Site, Service Offerings, and Content -including this
      electronic signature and disclosure notice- and that this use satisfies any requirement that Caterpillar provide
      you these documents and their content in writing. If you do not agree, do not enter into these Terms of Use. You
      have the right to receive a paper copy of all documents and records. You may (a) obtain a paper copy of any
      document or record (free of charge), (b) withdraw your consent to the use of electronic documents and records, or
      (c) update your contact information through your Site account. To receive or access electronic documents and
      records, you must have the following equipment and software: (i) a device that is capable of accessing the
      Internet; (ii) a compatible Internet browser; and (iii) software that permits you to receive and access Portable
      Document Format or "PDF" files, such as Adobe Acrobat Reader 8.0 or higher. To retain documents and records, your
      device must have the ability to download and store PDF files. Your access to this page verifies that your system
      and device meets the above receipt, access, and retention requirements.</span
    >
  </p>

  <p>
    <strong><span>Notices and Information</span></strong>

    <span
      ><br />You agree that we may contact you, including by e-mail or the user interface for the Site, Service
      Offerings and Content, to provide you with information and notices relating to these Terms of Use.
    </span>
  </p>

  <p>
    <strong><span>Contractual Statute of Limitations</span></strong>

    <span
      ><br />You agree that you will not assert against Caterpillar any claim or make a demand for dispute resolution
      arising directly or indirectly under or in connection with these Terms of Use that relates to, or assert as a
      cause for action, any event which occurred more than 12 months after the earliest date on which the facts are
      based were or could, with the exercise of reasonable diligence, have been known to you.</span
    >
  </p>

  <p>
    <strong><span>Modifications</span></strong>

    <span
      ><br />Caterpillar reserves the right at any time to modify, suspend or permanently discontinue the Site, Service
      Offerings and Content with or without notice. You hereby agree that Caterpillar shall not be liable to you or to
      any third party for any modification, suspension or discontinuance of the Site, Service Offerings or Content.
      Caterpillar further reserves the right to modify, amend, restate or replace all or any portion of these Terms of
      Use at any time. If Caterpillar makes any such changes, they will become effective upon the earlier of your
      acceptance of those changes or your continued access or use of any of the Site, Service Offerings or Content at
      any time more than 30 days immediately following the issuance of such notice.</span
    >
  </p>

  <p>
    <strong><span>Waiver</span></strong>

    <span
      ><br />The failure by Caterpillar to exercise rights granted to Caterpillar hereunder upon the occurrence of any
      violation of these Terms of Use shall not constitute a waiver of such rights upon the recurrence of such violation
      or of any other right granted herein.</span
    >
  </p>

  <p>
    <strong><span>Choice of Law; Severability</span></strong>

    <span
      ><br />These Terms of Use shall be governed by the laws of the State of Illinois, U.S.A. (regardless of the laws
      that might be applicable under its principles of conflict of laws). Each party submits to the exclusive
      jurisdiction of the courts of that place. If any provision of these Terms of Use, or the application thereof, is
      determined by a court of competent jurisdiction to be invalid or unenforceable, such invalidity or
      unenforceability will not affect the other provisions of these Terms of Use, all of which shall remain in full
      force and effect, and such other provisions will be interpreted so as best to reasonably effect the intent of the
      parties. The parties further agree to replace any such invalid or unenforceable provision with a valid and
      enforceable provision designed to achieve, to the extent possible under applicable law, the business purpose and
      intent of such invalid or unenforceable provision. The laws of the United Nations Convention on Contracts for the
      International Sale of Goods shall not apply.</span
    >

    <span
      ><br />We recognize that it is possible for you to obtain access to the site from any jurisdiction in the world,
      but we have no practical ability to prevent such access. The site has been designed to comply with the laws of the
      State of Illinois and of the United States. If any Service Offering, Content or User Materials on the Site, or
      your use of the Site, is contrary to the laws of the place where you are when you access it, such Service
      Offering, Content or Site is not intended for you, and we ask you not to use the Service Offering, Content or
      Site. You are responsible for informing yourself of the laws of your jurisdiction and complying with them.</span
    >
  </p>

  <p>
    <strong><span>Entire Agreement</span></strong>

    <span
      ><br />These Terms of Use (including, to the extent applicable, any additional terms and conditions for any
      particular Networked Site or Service Offering and any end user license agreement for any associated software,
      including mobile applications) constitutes the entire agreement between you and us with respect to the Site,
      Service Offerings and Content and supersedes and replaces all prior agreements. The section titles in these Terms
      of Use are for convenience only and have no legal or contractual effect. The terms and conditions of any
      quotation, offer, acknowledgement, invoice, or similar document, however designated, directly or indirectly made
      or issued by you shall not apply.</span
    >
  </p>

  <p>
    <strong><span>Inquiries</span></strong
    ><span
      ><br />
      Should you have any questions concerning these Terms of Use, write to Caterpillar Inc., 100 N.E. Adams St.,
      Peoria, IL 61629-6490, Attn: Lead Counsel, Cat Digital.</span
    >
    <br />
    <br />
    <span><strong>Last Updated: August 16, 2022</strong></span>
  </p>
</div>

<div class="cc-modal-footer button-container">
  <div class="footer-left">
    <div style="margin-top: 8px">
      <cc-checkbox
        style="padding-left: 15px"
        [isTheme]="'2.0'"
        (ngModelChange)="toggleCheckboxOption($event)"
        [ngModel]="isChecked"
      >
        <span class="textStyle">I have read and agree to the Terms and Conditions and Privacy Policy</span>
      </cc-checkbox>
    </div>
    <div class="copyRight-container">
      <p style="padding-left: 24px">Copyright &copy; {{ dateYear }}. All Rights Reserved.</p>
    </div>
  </div>
  <div>
    <div class="button-position">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" class="cancel button-opacity" (click)="disAgreeButton()">
        Disagree
      </button>
      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        [disabled]="!isChecked"
        (click)="agreeButton()"
        class="delete button-opacity"
      >
        Agree
      </button>
    </div>
  </div>
</div>
