export const Configuration = {
  USER_NOT_FOUND: 'User not found.',
  DUPLICATE_ENTRY: 'Duplicate entry.',
  PERMISSION_UPDATED: 'Permission updated successfully.',
  Admin: 'DSP Administrator',
  Super_Admin: 'Super Administrator',
  CREATE_HEADER: 'Create User Notification',
  EDIT_HEADER: 'Edit User Notification',
  DEALER_SERVICES_PORTAL_NEW_RELEASE_TYPE: 'Dealer Services Portal New Release',
  DEALER_SERVICES_PORTAL_NEW_RELEASE: 'Dealer Services Portal has recently been updated. Click here for more details.',
  SCHEDULED_MAINTENANCE_TYPE: 'Scheduled Maintenance',
  SCHEDULED_MAINTENANCE:
    'Dealer Services Portal is scheduled for planned maintenance between (Start Date/Time) and (End Date/Time). Expect limited or no availability of registration and subscription related functionalities during this time.',
  TEMPORARY_SERVICE_DISRUPTION_TYPE: 'Temporary Service Disruption',
  TEMPORARY_SERVICE_DISRUPTION:
    'Dealer Services Portal is experiencing a temporary service disruption. The information displayed may not be up to date. Please try again later.',
  CUSTOM_TYPE: 'Custom',
  FILE_FORMAT_ERROR: 'Only documents (.doc, .docx, .pdf, .txt) can be uploaded',
  CREATE_NOTIFICATION_SUCCESS_MESSAGE: 'Notification created successfully.',
  EDIT_NOTIFICATION_SUCCESS_MESSAGE: 'Notification edited successfully.',
  DELETE_NOTIFICATION_SUCCESS_MESSAGE: 'Notification deleted successfully.',
  NOTIFICATION_SCHEDULE_STARTED: 'Notification Schedule already started.',
  NOTIFICATION_SCHEDULE_ENDED: 'Notification Schedule already ended.',
  ZUORA_ACCOUNT_NOT_CONFIGURED: 'Zuora account has not been configured. Please contact Zuora.',
};

export const PopupButtons = {
  SAVE_CLOSE: 'Save & Close',
  APPLY_CLOSE: 'Apply & Close',
  CANCEL_CHANGES: 'Cancel Changes',
  SUBMIT: 'Submit',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  SAVE: 'Save',
  OK: 'Ok',
  CROSS: 'X',
  CONFIRM: 'Confirm',
};

export const Cc_Grid = {
  GRID_ONE: 'cc-col cc-lg-col--1 cc-md-col--1 cc-xl-col--1 cc-sm-col--1 cc-xs-col--12',
  GRID_TWO: 'cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--12',
  GRID_THREE: 'cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--12',
  GRID_FOUR: 'cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12',
  GRID_FIVE: 'cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--12',
  GRID_SIX: 'cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12',
  GRID_SEVEN: 'cc-col cc-lg-col--7 cc-md-col--7 cc-xl-col--7 cc-sm-col--7 cc-xs-col--12',
  GRID_EIGHT: 'cc-col cc-lg-col--8 cc-md-col--8 cc-xl-col--8 cc-sm-col--8 cc-xs-col--12',
  GRID_NINE: 'cc-col cc-lg-col--9 cc-md-col--9 cc-xl-col--9 cc-sm-col--9 cc-xs-col--12',
  GRID_TEN: 'cc-col cc-lg-col--10 cc-md-col--10 cc-xl-col--10  cc-sm-col--10 cc-xs-col--12',
  GRID_ELEVEN: 'cc-col cc-lg-col--11 cc-md-col--11 cc-xl-col--11 cc-sm-col--11 cc-xs-col--12',
  GRID_TWELVE: 'cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12',
};
export const Common = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  DEFAULT_TIMEZONE: 'Atlantic/St_Helena',
  DEFAULT_NUMBER_FORMAT: 'USD',
  DEFAULT_TIME_FORMAT: '24 hours',
  DEFAULT_DATE_FORMAT: 'MM/DD/YYYY',
  DSP: 'Dealer Services Portal',
  DEALER: 'Dealer',
  USER: 'User',
  DCN_ERROR:
    'Dealer Customer cannot be retrieved. Please log into the Customer Admin Tool to add the Dealer Customer number to the Customer ID.',
  CUSTOMER: 'Customer',
  NO_ACTIVE_SYNC_ERROR: 'No new active ownership information could be retrieved.',
  OWNERSHIP_SYSTEM_ERROR: 'Ownership information could not be updated. Please try again later',
  CAT: 'CAT',
  NON_CAT: 'NON-CAT',
  MY_WORKLIST: 'MY WORKLIST',
  WORKLIST_SUBTAB_SEARCH: 'WorklistSearch',
  WORKLIST_SUBTAB_ITEM: 'WorklistItem',
  NOT_APPLICABLE: 'Not Applicable',
  VISIBLETOOLTIPTEXT: 'Visible columns are shown at all times in the table.',
  FROZENTOOLTIPTEXT: 'Frozen columns are shown at all times in the table and cannot be rearranged.',
  SYSTEM_ERROR: 'There was an error processing your request. Please contact Cat Digital Support.',
  MOBILE_APP_DOWNLOAD_POPUP_TITLE: 'Download the App',
  MOBILE_APP_DOWNLOAD_POPUP_MESSAGE:
    'For a better experience on your device, please download the Dealer Services Portal (DSP) Mobile app for Cat® dealers.',
  MOBILE_APP_DOWNLOAD_POPUP_ANDROID_APP_LINK:
    'https://play.google.com/store/apps/details?id=com.cat.digital.mobile.dspmobile',
  MOBILE_APP_DOWNLOAD_POPUP_IOS_APP_LINK: 'https://apps.apple.com/us/app/cat-dsp-mobile/id1609645128',
  DEALER_FIELD_TECHNICIAN_BASIC: 'Dealer Field Technician - Basic',
  DEALER_FIELD_TECHNICIAN_ADVANCED: 'Dealer Field Technician - Advanced',
  DEALER_FIELD_TECHNICIAN: 'Dealer Field Technician',
  DSP_FACTORY_USER: 'DSP Factory User',
  DSP_CUSTOM_ACCESS: 'Custom Access',
  DSP_ADMINISTRATOR: 'DSP Administrator',
  USER_ADMINISTRATOR: 'User Administrator',
  SUPER_ADMIN: 'Super Administrator',
  FILTER_CLOSE_CONFIRMATION_POPUP_TITLE: 'Apply Filters',
  FILTER_CLOSE_CONFIRMATION_POPUP_BODY_LINE1:
    'You have made changes to filters. Do you want to apply your changes and close?',
  FILTER_CLOSE_CONFIRMATION_POPUP__BODY_LINE2: 'Are you sure you want to close without applying your changes?',
  TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE: 'Save Settings',
  TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1:
    'You have modified your settings. Do you want to save your changes and close?',
  DELETE_QUICK_LINK_BODY_LINE1: 'Are you sure you want to delete this quick link?',
  ACCOUNT_LIMITED_TYPE_POPUP_BODY_LINE1:
    'Requires Technician Toolbox 2.5 version or higher installed in end user computer.',
  ACCOUNT_LIMITED_TYPE_POPUP_BODY_LINE2:
    'Converting to Limited Users will cancel the current subscription. Do you want to continue?',
  ACCOUNT_UNLIMITED_TYPE_POPUP_BODY_LINE1:
    'Converting to Unlimited Users will result in removal of the existing user list. Do you want to continue?',
  ACCOUNT_CANCEL_PLAN_POPUP_BODY_LINE1: 'Are you sure that you want to cancel this plan?',
  ACCOUNT_REMOVE_USERS_POPUP_BODY_LINE1: 'Are you sure that you want to remove the selected users from the plan?',
  DELETE_SITE_POPUP_BODY_LINE1: 'Are you sure that you want to delete this site?',
  CANCEL_SITE_POPUP_BODY_LINE1: 'Are you sure that you want to cancel this plan?',
  CONFIRMATION_POPUP_TITLE: 'Confirmation',
  CONFIRM_POPUP_TITLE: 'Confirm',
  CONFIGURE_WIFI_CLOSE_CONFIRMATION___BODY_LINE1:
    'This action will result in clearing your Wi-Fi settings. You will need to reset your Wi-Fi. Are you sure you want to continue?',
  PL444_PARAMETER_CONFIGURATION___BODY_LINE1:
    'This action will result in updating the configuration file on the device. Please confirm to proceed.',
  PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1:
    'You have chosen a prepaid fixed-term subscription contract. The prepayment associated to these services may be non-refundable. Do you want to continue?',
  MODIFY_PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1:
    'You are cancelling or modifying a prepaid fixed-term subscription contract. The prepayment associated to these services may be non-refundable. Do you want to continue?',
  CANCEL_SUBSCRIPTION_CONFIRMATION___BODY_LINE1:
    'Your selection indicates that you are choosing to suspend all transmission from this device. To reverse the suspension, you will have to contact product support. Do you still want to proceed?',
  REMOVE_DEALER_CONFIRMATION___BODY_LINE1:
    'This action could delete the access for this dealer. Do you want to continue?',
  FILTER_CLOSE_DASHBOARD_POPUP_TITLE: 'Apply Filters',
  FILTER_CLOSE_DASHBOARD_POPUP__BODY_LINE1:
    'You have made changes to filters. Do you want to apply your changes and close?',
  TOKEN_EXPIRED: 'token is expired',
  TOKEN_NOT_FOUND: 'token not found',
  INVALID_TOKEN: 'invalid token',
  INVALID_TOKEN_: 'invalid_token',
  VPN_ERROR: 'Connect your VPN connection to access the application',
};

export const OrgSubs = {
  VIMS_CONVERTER: 'MineStar Health VIMS Converter',
  TT: 'Technician Toolbox',
  VM: 'VIMS Converter',
  LU: 'Limited Users',
  ULU: 'Unlimited Users',
  MINESTAR_HEI: 'MineStar Health',
  OPERATIONTYPE_ADD: 'ADD',
  OPERATIONTYPE_UPDATE: 'UPDATE',
  OPERATIONTYPE_CANCEL: 'CANCEL',
  OPERATIONTYPE_SMA_CANCEL: 'SMA CANCEL',
  OPERATIONTYPE_VIEW_BY_DEALER: 'VIEW BY DEALER',
  OPERATIONTYPE_VIEW_BY_CUSTOMER: 'VIEW BY CUSTOMER',
  OPERATIONTYPE_VIEW: 'VIEW',
  SIS_GO: 'SIS2GO',
  SIS_USB: 'SIS2.0 USB',
  SMA: 'Service Media Access',
  ADD_HEADER: 'ADD NEW PLAN',
  EDIT_HEADER: 'Edit Plan',
  CANCEL_HEADER: 'Cancel Plan',
  VIEW_HEADER: 'View Details',
  HOVER_ON_ADD_CONTENT: 'Add',
  HOVER_ON_REMOVE_CONTENT: 'Remove',
};

export const WorklistSearch = {
  MY_WORKLIST: 'MY WORKLIST',
  WORKLIST: 'WORKLIST',
  CANCEL: 'cancel',
  FILTER: 'filters',
  SORT: 'sort',
  SMARTSEARCH: 'smartsearch',
};

export const Worklist = {
  WIFI_SIMULTANEOUS: 'Wifi Simultaneous',
  WIFI_HOTSPOT: 'Wifi Hot Spot',
  WIFI_INFRASTRUCTURE: 'Wifi Infrastructure Mode',
  WIFI_CLIENT_AND_HOTSPOT: 'Wifi_Client_and_Hotspot',
  WIFI_CLIENT_OR_HOTSPOT: 'Wifi_Client_or_Hotspot',
  WIFI_CLIENT: 'Wifi_Client',
  CAT_PRODUCTIVITY: 'Cat Productivity',
  PRODUCT_LINK_GENERATION: 'Product Link Generation',
  VISION_LINK: 'VisionLink',
  IS_CVA_ENABLED: 'json:isCvaEnabled',
  MINESTAR: 'MineStar',
  MY_CAT_COM: 'My.Cat.Com',
  FULL_FLEET: 'Full Fleet',
  ECA_LEGACY: 'ECA Legacy',
  EQUIPMENT_INSIGHTS: 'Equipment Insights',
  EQUIPMENT_INSIGHTS_UI_ONLY: 'Equipment Insights - Manual Data Upload',
  EQUIPMENT_INSIGHTS_CAT_CELLULAR: 'Equipment Insights - Cat Cellular',
  EQUIPMENT_INSIGHTS_WI_FI: 'Equipment Insights - Third Party Cellular or Wi-Fi',
  CAT_REMOTE_FLEET_VISION: 'Cat Remote Fleet Vision',
  MARINE_CONCIERGE_PLUS: 'Marine Concierge Plus',
  E_ROUTES: 'eRoutes',
  EROUTESGATEWAY: 'EROUTESGATEWAY',
  OBA: 'Onboard Analytics',
  VSD: 'VIMS Standard Data',
  VED: 'VIMS Engineering Data',
  VIMS_TRANSMITTER_CAT_CELLULAR: 'VIMS Transmitter - Cat Cellular',
  VIMS_TRANSMITTER_MANUAL_DATA: 'VIMS Transmitter - Manual Data',
  VIMS_TRANSMITTER_THIRD_PARTY_CELLULAR_OR_WIFI: 'VIMS Transmitter - Third Party Cellular or Wi-Fi',
  VIMS_TRANSMITTER_MANUAL_DATA_UPLOAD: 'VIMS Transmitter - Manual Data Upload',
  RADIO_SHARING: 'Radio Sharing',
  DUAL_MODE: 'Dual Mode',
  SATELLITE_HOURS: 'Satellite - 4 Hours',
  CELLULAR_RADIO_SUSPENDED: 'Cellular Radio Suspended',
  CELLULAR_HOURLY: 'Cellular- Hourly',
  SATELLITE_RADIO_SUSPENDED: 'Satellite Radio Suspended',
  VIEWSTAR: 'viewstar',
  REMOTE_FLASH: 'Remote Flash',
  TROUBLESHOOT: 'Troubleshoot',
  REMOTE_ASSET_MONITORING: 'Remote Asset Monitoring',
  REMOTE_TROUBLESHOOT: 'Remote Troubleshoot',
  RT_APPROVAL: 'RT Approval',
  ADVANCED_PRODUCTIVITY: 'Advanced Productivity',
  CURRENT_ADVANCED_PRODUCTIVITY: '2018 Advanced Productivity',
  NEW_VISION_LINK: 'New VisionLink',
  VIMS_TRANSMITTER: 'VIMS Transmitter',
  AGREE: 'agree',
  UNREACHABLE: 'unreachable',
  UNAVAILABLE: 'unavailable',
  DECLINE: 'decline',
  QUEUED: 'queued',
  AVAILABLE: 'Available',
  NOTAVAILABLE: 'Not Available',
  CONSUMED: 'consumed',
  SUCCESSFUL: 'SUCCESSFUL',
  INSTALLED: 'INSTALLED',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  PENDING: 'PENDING',
  SUCCESSFUL_STATUS_INFO: 'Previous license request from Dealer Services Portal was successful',
  PENDING_STATUS_INFO: 'Previous license request from Dealer Services Portal is pending',
  UNSUCCESSFUL_STATUS_INFO: 'Previous license request from Dealer Services Portal was unsuccessful',
  INSTALLED_STATUS_INFO: 'Requested license has been installed on the component',
  FAILED: 'failed',
  PREPAY: 'Prepay',
  Device_Impacted_reason: 'Device Impacted by 2G/3G Obsolescence',
  WIFI_PENDING: 'pending',
  WIFI_DEFAULT_STATUS: 'defaultstatus',
  EQUIPMENT_DETAILS_DOC: '/assets/dsp_help_document/index.htm#t=Asset_Details.htm',
  LEVEL1: 'changeLvl1Type',
  LEVEL2: 'changeLvl2Type',
  LEVEL3: 'changeLvl3Type',
  SUBSCRIBED: 'Subscribed',
  SUSPENDED: 'Suspended',
  UNSUBSCRIBED: 'Unsubscribed',
  CAT_GRADE_CONNECTIVITY: 'Grade Connectivity',
  CAT_GRADE_CONTROL: 'Cat Grade Control',
  CUSTOMERLEVEL: 'customerLevel',
  DEALERLEVEL: 'dealerLevel',
  CATLEVEL: 'catLevel',
  GLOBAL_SEARCH: 'globalSearch',
  HOME: 'Home',
  NO_DEVICE_SEARCH: 'noDeviceSearch',
  OTHER_DEALER_SEARCH: 'otherDealerSearch',
  NON_BILLED_DEALER_MESSAGE:
    'Pricing and promotion amounts are not displayed as you do not have access to view pricing or your dealership is invoiced through an alternate process.',
  PROMOTION_NOT_ELIGIBLE:
    'Asset does not meet eligibility criteria for promotion.  Prepaid contract sales are final and will not be re-evaluated for promotion later.  Click here for more information',
};

export const subsLevelCategory = {
  LEVEL3_ADDON_SELECTION: 'Level3_AddOn_Selection',
  LEVEL3_NO_ADDON_SELECTION: 'Level3_No_AddOn_Selection',
  SINGLE_LEVEL_MINESTAR: 'Single_Level_Minestar',
  SINGLE_LEVEL_ECALEGACY: 'Single_Level_Ecalegacy',
  SUBSCRIPTION_REMOTEFLASH: 'Subscription_Remoteflash',
  SUBSCRIPTION_REMOTETROUBLESHOOT: 'Subscription_Remotetroubleshoot',
  SINGLE_LEVEL_PROFILE_ADDON_FSM: 'Single_Level_Profile_AddOn_FSM',
  MULTI_LEVEL_PROFILE_ADDON_FSM: 'Multi_Level_Profile_AddOn_FSM',
};

export const EquipmentInfo = {
  CONFIRM_USER_ACTION: 'CONFIRM_USER_ACTION',
  LEGACY_TO_NEWGEN_CHANGE: 'LEGACY_TO_NEWGEN_CHANGE',
};

export const RemoteConnectionCheck = {
  IN_PROGRESS: 'In Progress',
  SUCCESS: 'Success',
  FAIL: 'Fail',
};

export const ZuoraBilling = {
  BILLING: 'BILLING',
  CREDIT_MEMO: 'Credit Memo',
  INVOICE: 'Invoice',
  HELP_DOC_URL: '/assets/dsp_help_document/index.htm#t=BillingOverview.htm',
};

export const Minestar = {
  MINESTAR: 'MINESTAR',
  CREATE: 'Create',
  ACTIVE: 'Active',
  PENDING: 'Pending',
  SUSPENDED: 'Suspended',
  SAVE: 'Save',
  SUCCESS: 'success',
  SITE_UPDATED: 'Site Updated',
  SUBSCRIPTION_UPDATED: 'SubscriptionUpdated',
  CONFLICT: 'Conflict',
  MANAGE_SITES: 'Manage Sites',
  EDIT_SITE: 'Edit Site',
  CREATE_NEW_SITE: 'Create New Site',
  VIEW: 'View',
  EDIT: 'Edit',
};

export const DataHauling = {
  DATAHAULING: 'DATAHAULING',
  DATA_HAULING: 'DATA HAULING',
};

export const AssetPopulation = {
  THRESHOLDH_HIT: 50,
};

export const AssetSubscriptionConstants = {
  Level1: 'level1',
  Level2: 'level2',
  Level3: 'level3',
  Customer: 'customer',
  Dealer: 'dealer',
  CAT: 'cat',
};

export const NeedsReview = {
  Active: 'Active',
  New: 'New',
  Dismissed: 'Dismissed',
};

export const Home = {
  ASSETS: 'Assets',
  CUSTOMERS: 'Customers',
};

export const DspUtilsServiceVariables = {
  CANCEL_REASONS: 'Cancel_Reasons',
};

export const timeZones = [
  {
    key: 'Pacific/Niue',
    value: 'UTC-11 Pacific/Niue',
  },
  {
    key: 'Pacific/Pago_Pago',
    value: 'UTC-11 Pacific/Pago_Pago',
  },
  {
    key: 'Pacific/Tahiti',
    value: 'Hawaiian Standard Time Pacific/Tahiti',
  },
  {
    key: 'Pacific/Midway',
    value: 'UTC-11 Pacific/Midway',
  },
  {
    key: 'Pacific/Rarotonga',
    value: 'Hawaiian Standard Time Pacific/Rarotonga',
  },
  {
    key: 'Pacific/Honolulu',
    value: 'Hawaiian Standard Time Pacific/Honolulu',
  },
  {
    key: 'Pacific/Johnston',
    value: 'Hawaiian Standard Time Pacific/Johnston',
  },
  {
    key: 'America/Adak',
    value: 'Aleutian Standard Time America/Adak',
  },
  {
    key: 'Pacific/Gambier',
    value: 'UTC-09 Pacific/Gambier',
  },
  {
    key: 'Pacific/Marquesas',
    value: 'Marquesas Standard Time Pacific/Marquesas',
  },
  {
    key: 'America/Nome',
    value: 'Alaskan Standard Time America/Nome',
  },
  {
    key: 'America/Anchorage',
    value: 'Alaskan Standard Time America/Anchorage',
  },
  {
    key: 'America/Sitka',
    value: 'Alaskan Standard Time America/Sitka',
  },
  {
    key: 'America/Metlakatla',
    value: 'Alaskan Standard Time America/Metlakatla',
  },
  {
    key: 'America/Yakutat',
    value: 'Alaskan Standard Time America/Yakutat',
  },
  {
    key: 'America/Juneau',
    value: 'Alaskan Standard Time America/Juneau',
  },
  {
    key: 'Pacific/Pitcairn',
    value: 'UTC-08 Pacific/Pitcairn',
  },
  {
    key: 'America/Los_Angeles',
    value: 'Pacific Standard Time America/Los_Angeles',
  },
  {
    key: 'America/Tijuana',
    value: 'Pacific Standard Time (Mexico) America/Tijuana',
  },
  {
    key: 'America/Vancouver',
    value: 'Pacific Standard Time America/Vancouver',
  },
  {
    key: 'America/Dawson',
    value: 'Pacific Standard Time America/Dawson',
  },
  {
    key: 'America/Whitehorse',
    value: 'Pacific Standard Time America/Whitehorse',
  },
  {
    key: 'America/Mazatlan',
    value: 'Mountain Standard Time (Mexico) America/Mazatlan',
  },
  {
    key: 'America/Hermosillo',
    value: 'US Mountain Standard Time America/Hermosillo',
  },
  {
    key: 'America/Chihuahua',
    value: 'Mountain Standard Time (Mexico) America/Chihuahua',
  },
  {
    key: 'America/Ojinaga',
    value: 'Mountain Standard Time America/Ojinaga',
  },
  {
    key: 'America/Denver',
    value: 'Mountain Standard Time America/Denver',
  },
  {
    key: 'America/Phoenix',
    value: 'US Mountain Standard Time America/Phoenix',
  },
  {
    key: 'America/Boise',
    value: 'Mountain Standard Time America/Boise',
  },
  {
    key: 'America/Edmonton',
    value: 'Mountain Standard Time America/Edmonton',
  },
  {
    key: 'America/Creston',
    value: 'US Mountain Standard Time America/Creston',
  },
  {
    key: 'America/Dawson_Creek',
    value: 'US Mountain Standard Time America/Dawson_Creek',
  },
  {
    key: 'America/Yellowknife',
    value: 'Mountain Standard Time America/Yellowknife',
  },
  {
    key: 'America/Fort_Nelson',
    value: 'US Mountain Standard Time America/Fort_Nelson',
  },
  {
    key: 'America/Cambridge_Bay',
    value: 'Mountain Standard Time America/Cambridge_Bay',
  },
  {
    key: 'Pacific/Easter',
    value: 'Easter Island Standard Time Pacific/Easter',
  },
  {
    key: 'America/Inuvik',
    value: 'Mountain Standard Time America/Inuvik',
  },
  {
    key: 'Pacific/Galapagos',
    value: 'Central America Standard Time Pacific/Galapagos',
  },
  {
    key: 'America/Managua',
    value: 'Central America Standard Time America/Managua',
  },
  {
    key: 'America/Costa_Rica',
    value: 'Central America Standard Time America/Costa_Rica',
  },
  {
    key: 'America/El_Salvador',
    value: 'Central America Standard Time America/El_Salvador',
  },
  {
    key: 'America/Guatemala',
    value: 'Central America Standard Time America/Guatemala',
  },
  {
    key: 'America/Tegucigalpa',
    value: 'Central America Standard Time America/Tegucigalpa',
  },
  {
    key: 'America/Mexico_City',
    value: 'Central Standard Time (Mexico) America/Mexico_City',
  },
  {
    key: 'America/Merida',
    value: 'Central Standard Time (Mexico) America/Merida',
  },
  {
    key: 'America/Belize',
    value: 'Central America Standard Time America/Belize',
  },
  {
    key: 'America/Bahia_Banderas',
    value: 'Central Standard Time (Mexico) America/Bahia_Banderas',
  },
  {
    key: 'America/Matamoros',
    value: 'Central Standard Time America/Matamoros',
  },
  {
    key: 'America/Monterrey',
    value: 'Central Standard Time (Mexico) America/Monterrey',
  },
  {
    key: 'America/Chicago',
    value: 'Central Standard Time America/Chicago',
  },
  {
    key: 'America/Indiana/Knox',
    value: 'Central Standard Time America/Indiana/Knox',
  },
  {
    key: 'America/Indiana/Tell_City',
    value: 'Central Standard Time America/Indiana/Tell_City',
  },
  {
    key: 'America/Menominee',
    value: 'Central Standard Time America/Menominee',
  },
  {
    key: 'America/North_Dakota/Center',
    value: 'Central Standard Time America/North_Dakota/Center',
  },
  {
    key: 'America/North_Dakota/New_Salem',
    value: 'Central Standard Time America/North_Dakota/New_Salem',
  },
  {
    key: 'America/North_Dakota/Beulah',
    value: 'Central Standard Time America/North_Dakota/Beulah',
  },
  {
    key: 'America/Swift_Current',
    value: 'Canada Central Standard Time America/Swift_Current',
  },
  {
    key: 'America/Rainy_River',
    value: 'Central Standard Time America/Rainy_River',
  },
  {
    key: 'America/Winnipeg',
    value: 'Central Standard Time America/Winnipeg',
  },
  {
    key: 'America/Rankin_Inlet',
    value: 'Central Standard Time America/Rankin_Inlet',
  },
  {
    key: 'America/Regina',
    value: 'Canada Central Standard Time America/Regina',
  },
  {
    key: 'America/Resolute',
    value: 'Central Standard Time America/Resolute',
  },
  {
    key: 'America/Rio_Branco',
    value: 'SA Pacific Standard Time America/Rio_Branco',
  },
  {
    key: 'America/Lima',
    value: 'SA Pacific Standard Time America/Lima',
  },
  {
    key: 'America/Eirunepe',
    value: 'SA Pacific Standard Time America/Eirunepe',
  },
  {
    key: 'America/Bogota',
    value: 'SA Pacific Standard Time America/Bogota',
  },
  {
    key: 'America/Guayaquil',
    value: 'SA Pacific Standard Time America/Guayaquil',
  },
  {
    key: 'America/Panama',
    value: 'SA Pacific Standard Time America/Panama',
  },
  {
    key: 'America/Port-au-Prince',
    value: 'Haiti Standard Time America/Port-au-Prince',
  },
  {
    key: 'America/Jamaica',
    value: 'SA Pacific Standard Time America/Jamaica',
  },
  {
    key: 'America/Cancun',
    value: 'Eastern Standard Time (Mexico) America/Cancun',
  },
  {
    key: 'America/Havana',
    value: 'Cuba Standard Time America/Havana',
  },
  {
    key: 'America/Cayman',
    value: 'SA Pacific Standard Time America/Cayman',
  },
  {
    key: 'America/New_York',
    value: 'Eastern Standard Time America/New_York',
  },
  {
    key: 'America/Grand_Turk',
    value: 'Turks and Caicos Standard Time America/Grand_Turk',
  },
  {
    key: 'America/Nassau',
    value: 'Eastern Standard Time America/Nassau',
  },
  {
    key: 'America/Kentucky/Monticello',
    value: 'Eastern Standard Time America/Kentucky/Monticello',
  },
  {
    key: 'America/Kentucky/Louisville',
    value: 'Eastern Standard Time America/Kentucky/Louisville',
  },
  {
    key: 'America/Indiana/Marengo',
    value: 'US Eastern Standard Time America/Indiana/Marengo',
  },
  {
    key: 'America/Indiana/Petersburg',
    value: 'Eastern Standard Time America/Indiana/Petersburg',
  },
  {
    key: 'America/Indiana/Vevay',
    value: 'US Eastern Standard Time America/Indiana/Vevay',
  },
  {
    key: 'America/Indiana/Vincennes',
    value: 'Eastern Standard Time America/Indiana/Vincennes',
  },
  {
    key: 'America/Indiana/Indianapolis',
    value: 'US Eastern Standard Time America/Indiana/Indianapolis',
  },
  {
    key: 'America/Toronto',
    value: 'Eastern Standard Time America/Toronto',
  },
  {
    key: 'America/Indiana/Winamac',
    value: 'Eastern Standard Time America/Indiana/Winamac',
  },
  {
    key: 'America/Detroit',
    value: 'Eastern Standard Time America/Detroit',
  },
  {
    key: 'America/Thunder_Bay',
    value: 'Eastern Standard Time America/Thunder_Bay',
  },
  {
    key: 'America/Montreal',
    value: 'Eastern Standard Time America/Montreal',
  },
  {
    key: 'America/Atikokan',
    value: 'SA Pacific Standard Time America/Atikokan',
  },
  {
    key: 'America/Iqaluit',
    value: 'Eastern Standard Time America/Iqaluit',
  },
  {
    key: 'America/Nipigon',
    value: 'Eastern Standard Time America/Nipigon',
  },
  {
    key: 'America/Pangnirtung',
    value: 'Eastern Standard Time America/Pangnirtung',
  },
  {
    key: 'America/Santiago',
    value: 'Pacific SA Standard Time America/Santiago',
  },
  {
    key: 'America/Coral_Harbour',
    value: 'SA Pacific Standard Time America/Coral_Harbour',
  },
  {
    key: 'America/Asuncion',
    value: 'Paraguay Standard Time America/Asuncion',
  },
  {
    key: 'America/Campo_Grande',
    value: 'Central Brazilian Standard Time America/Campo_Grande',
  },
  {
    key: 'America/La_Paz',
    value: 'SA Western Standard Time America/La_Paz',
  },
  {
    key: 'America/Cuiaba',
    value: 'Central Brazilian Standard Time America/Cuiaba',
  },
  {
    key: 'America/Manaus',
    value: 'SA Western Standard Time America/Manaus',
  },
  {
    key: 'America/Porto_Velho',
    value: 'SA Western Standard Time America/Porto_Velho',
  },
  {
    key: 'America/Boa_Vista',
    value: 'SA Western Standard Time America/Boa_Vista',
  },
  {
    key: 'America/Caracas',
    value: 'Venezuela Standard Time America/Caracas',
  },
  {
    key: 'America/Guyana',
    value: 'SA Western Standard Time America/Guyana',
  },
  {
    key: 'America/Port_of_Spain',
    value: 'SA Western Standard Time America/Port_of_Spain',
  },
  {
    key: 'America/Grenada',
    value: 'SA Western Standard Time America/Grenada',
  },
  {
    key: 'America/Curacao',
    value: 'SA Western Standard Time America/Curacao',
  },
  {
    key: 'America/Kralendijk',
    value: 'SA Western Standard Time America/Kralendijk',
  },
  {
    key: 'America/St_Vincent',
    value: 'SA Western Standard Time America/St_Vincent',
  },
  {
    key: 'America/Aruba',
    value: 'SA Western Standard Time America/Aruba',
  },
  {
    key: 'America/Barbados',
    value: 'SA Western Standard Time America/Barbados',
  },
  {
    key: 'America/Martinique',
    value: 'SA Western Standard Time America/Martinique',
  },
  {
    key: 'America/St_Lucia',
    value: 'SA Western Standard Time America/St_Lucia',
  },
  {
    key: 'America/Dominica',
    value: 'SA Western Standard Time America/Dominica',
  },
  {
    key: 'America/Montserrat',
    value: 'SA Western Standard Time America/Montserrat',
  },
  {
    key: 'America/Guadeloupe',
    value: 'SA Western Standard Time America/Guadeloupe',
  },
  {
    key: 'America/Antigua',
    value: 'SA Western Standard Time America/Antigua',
  },
  {
    key: 'America/Santo_Domingo',
    value: 'SA Western Standard Time America/Santo_Domingo',
  },
  {
    key: 'America/St_Kitts',
    value: 'SA Western Standard Time America/St_Kitts',
  },
  {
    key: 'America/St_Thomas',
    value: 'SA Western Standard Time America/St_Thomas',
  },
  {
    key: 'America/St_Barthelemy',
    value: 'SA Western Standard Time America/St_Barthelemy',
  },
  {
    key: 'America/Puerto_Rico',
    value: 'SA Western Standard Time America/Puerto_Rico',
  },
  {
    key: 'America/Lower_Princes',
    value: 'SA Western Standard Time America/Lower_Princes',
  },
  {
    key: 'America/Anguilla',
    value: 'SA Western Standard Time America/Anguilla',
  },
  {
    key: 'America/Marigot',
    value: 'SA Western Standard Time America/Marigot',
  },
  {
    key: 'America/Tortola',
    value: 'SA Western Standard Time America/Tortola',
  },
  {
    key: 'America/Halifax',
    value: 'Atlantic Standard Time America/Halifax',
  },
  {
    key: 'Atlantic/Bermuda',
    value: 'Atlantic Standard Time Atlantic/Bermuda',
  },
  {
    key: 'America/Moncton',
    value: 'Atlantic Standard Time America/Moncton',
  },
  {
    key: 'America/Blanc-Sablon',
    value: 'SA Western Standard Time America/Blanc-Sablon',
  },
  {
    key: 'America/Glace_Bay',
    value: 'Atlantic Standard Time America/Glace_Bay',
  },
  {
    key: 'America/Goose_Bay',
    value: 'Atlantic Standard Time America/Goose_Bay',
  },
  {
    key: 'America/St_Johns',
    value: 'Newfoundland Standard Time America/St_Johns',
  },
  {
    key: 'America/Thule',
    value: 'Atlantic Standard Time America/Thule',
  },
  {
    key: 'America/Argentina/Ushuaia',
    value: 'Argentina Standard Time America/Argentina/Ushuaia',
  },
  {
    key: 'America/Argentina/Rio_Gallegos',
    value: 'Argentina Standard Time America/Argentina/Rio_Gallegos',
  },
  {
    key: 'Atlantic/Stanley',
    value: 'SA Eastern Standard Time Atlantic/Stanley',
  },
  {
    key: 'America/Argentina/Catamarca',
    value: 'Argentina Standard Time America/Argentina/Catamarca',
  },
  {
    key: 'America/Argentina/Buenos_Aires',
    value: 'Argentina Standard Time America/Argentina/Buenos_Aires',
  },
  {
    key: 'America/Argentina/Salta',
    value: 'Argentina Standard Time America/Argentina/Salta',
  },
  {
    key: 'America/Argentina/Mendoza',
    value: 'Argentina Standard Time America/Argentina/Mendoza',
  },
  {
    key: 'America/Argentina/San_Luis',
    value: 'Argentina Standard Time America/Argentina/San_Luis',
  },
  {
    key: 'America/Montevideo',
    value: 'Montevideo Standard Time America/Montevideo',
  },
  {
    key: 'America/Argentina/Cordoba',
    value: 'Argentina Standard Time America/Argentina/Cordoba',
  },
  {
    key: 'America/Argentina/San_Juan',
    value: 'Argentina Standard Time America/Argentina/San_Juan',
  },
  {
    key: 'America/Sao_Paulo',
    value: 'E. South America Standard Time America/Sao_Paulo',
  },
  {
    key: 'America/Argentina/La_Rioja',
    value: 'Argentina Standard Time America/Argentina/La_Rioja',
  },
  {
    key: 'America/Argentina/Jujuy',
    value: 'Argentina Standard Time America/Argentina/Jujuy',
  },
  {
    key: 'America/Argentina/Tucuman',
    value: 'Argentina Standard Time America/Argentina/Tucuman',
  },
  {
    key: 'America/Bahia',
    value: 'Bahia Standard Time America/Bahia',
  },
  {
    key: 'America/Maceio',
    value: 'SA Eastern Standard Time America/Maceio',
  },
  {
    key: 'America/Araguaina',
    value: 'Tocantins Standard Time America/Araguaina',
  },
  {
    key: 'America/Fortaleza',
    value: 'SA Eastern Standard Time America/Fortaleza',
  },
  {
    key: 'America/Belem',
    value: 'SA Eastern Standard Time America/Belem',
  },
  {
    key: 'America/Recife',
    value: 'SA Eastern Standard Time America/Recife',
  },
  {
    key: 'America/Santarem',
    value: 'SA Eastern Standard Time America/Santarem',
  },
  {
    key: 'America/Paramaribo',
    value: 'SA Eastern Standard Time America/Paramaribo',
  },
  {
    key: 'America/Cayenne',
    value: 'SA Eastern Standard Time America/Cayenne',
  },
  {
    key: 'America/Miquelon',
    value: 'Saint Pierre Standard Time America/Miquelon',
  },
  {
    key: 'Atlantic/South_Georgia',
    value: 'UTC-02 Atlantic/South_Georgia',
  },
  {
    key: 'America/Godthab',
    value: 'Greenland Standard Time America/Godthab',
  },
  {
    key: 'America/Noronha',
    value: 'UTC-02 America/Noronha',
  },
  {
    key: 'Atlantic/Azores',
    value: 'Azores Standard Time Atlantic/Azores',
  },
  {
    key: 'Atlantic/Cape_Verde',
    value: 'Cabo Verde Standard Time Atlantic/Cape_Verde',
  },
  {
    key: 'America/Scoresbysund',
    value: 'Azores Standard Time America/Scoresbysund',
  },
  {
    key: 'Africa/Sao_Tome',
    value: 'Sao Tome Standard Time Africa/Sao_Tome',
  },
  {
    key: 'Atlantic/St_Helena',
    value: 'Greenwich Standard Time Atlantic/St_Helena',
  },
  {
    key: 'Africa/Monrovia',
    value: 'Greenwich Standard Time Africa/Monrovia',
  },
  {
    key: 'Africa/Accra',
    value: 'Greenwich Standard Time Africa/Accra',
  },
  {
    key: 'Africa/Abidjan',
    value: 'Greenwich Standard Time Africa/Abidjan',
  },
  {
    key: 'Africa/Lome',
    value: 'Greenwich Standard Time Africa/Lome',
  },
  {
    key: 'Africa/Conakry',
    value: 'Greenwich Standard Time Africa/Conakry',
  },
  {
    key: 'Africa/Freetown',
    value: 'Greenwich Standard Time Africa/Freetown',
  },
  {
    key: 'Africa/Bamako',
    value: 'Greenwich Standard Time Africa/Bamako',
  },
  {
    key: 'Africa/Bissau',
    value: 'Greenwich Standard Time Africa/Bissau',
  },
  {
    key: 'Africa/Ouagadougou',
    value: 'Greenwich Standard Time Africa/Ouagadougou',
  },
  {
    key: 'Africa/Dakar',
    value: 'Greenwich Standard Time Africa/Dakar',
  },
  {
    key: 'Africa/Nouakchott',
    value: 'Greenwich Standard Time Africa/Nouakchott',
  },
  {
    key: 'Africa/Banjul',
    value: 'Greenwich Standard Time Africa/Banjul',
  },
  {
    key: 'Africa/El_Aaiun',
    value: 'Morocco Standard Time Africa/El_Aaiun',
  },
  {
    key: 'Atlantic/Madeira',
    value: 'GMT Standard Time Atlantic/Madeira',
  },
  {
    key: 'Atlantic/Canary',
    value: 'GMT Standard Time Atlantic/Canary',
  },
  {
    key: 'Africa/Casablanca',
    value: 'Morocco Standard Time Africa/Casablanca',
  },
  {
    key: 'Europe/Jersey',
    value: 'GMT Standard Time Europe/Jersey',
  },
  {
    key: 'Europe/Lisbon',
    value: 'GMT Standard Time Europe/Lisbon',
  },
  {
    key: 'Europe/Guernsey',
    value: 'GMT Standard Time Europe/Guernsey',
  },
  {
    key: 'Europe/Dublin',
    value: 'GMT Standard Time Europe/Dublin',
  },
  {
    key: 'Europe/London',
    value: 'GMT Standard Time Europe/London',
  },
  {
    key: 'Europe/Isle_of_Man',
    value: 'GMT Standard Time Europe/Isle_of_Man',
  },
  {
    key: 'Atlantic/Reykjavik',
    value: 'Greenwich Standard Time Atlantic/Reykjavik',
  },
  {
    key: 'Atlantic/Faroe',
    value: 'GMT Standard Time Atlantic/Faroe',
  },
  {
    key: 'America/Danmarkshavn',
    value: 'UTC America/Danmarkshavn',
  },
  {
    key: 'Africa/Kinshasa',
    value: 'W. Central Africa Standard Time Africa/Kinshasa',
  },
  {
    key: 'Africa/Luanda',
    value: 'W. Central Africa Standard Time Africa/Luanda',
  },
  {
    key: 'Africa/Brazzaville',
    value: 'W. Central Africa Standard Time Africa/Brazzaville',
  },
  {
    key: 'Africa/Malabo',
    value: 'W. Central Africa Standard Time Africa/Malabo',
  },
  {
    key: 'Africa/Libreville',
    value: 'W. Central Africa Standard Time Africa/Libreville',
  },
  {
    key: 'Africa/Douala',
    value: 'W. Central Africa Standard Time Africa/Douala',
  },
  {
    key: 'Africa/Lagos',
    value: 'W. Central Africa Standard Time Africa/Lagos',
  },
  {
    key: 'Africa/Bangui',
    value: 'W. Central Africa Standard Time Africa/Bangui',
  },
  {
    key: 'Africa/Porto-Novo',
    value: 'W. Central Africa Standard Time Africa/Porto-Novo',
  },
  {
    key: 'Africa/Niamey',
    value: 'W. Central Africa Standard Time Africa/Niamey',
  },
  {
    key: 'Africa/Ndjamena',
    value: 'W. Central Africa Standard Time Africa/Ndjamena',
  },
  {
    key: 'Africa/Algiers',
    value: 'W. Central Africa Standard Time Africa/Algiers',
  },
  {
    key: 'Africa/Ceuta',
    value: 'Romance Standard Time Africa/Ceuta',
  },
  {
    key: 'Africa/Tunis',
    value: 'W. Central Africa Standard Time Africa/Tunis',
  },
  {
    key: 'Europe/Rome',
    value: 'W. Europe Standard Time Europe/Rome',
  },
  {
    key: 'Europe/Madrid',
    value: 'Romance Standard Time Europe/Madrid',
  },
  {
    key: 'Europe/Malta',
    value: 'W. Europe Standard Time Europe/Malta',
  },
  {
    key: 'Europe/Gibraltar',
    value: 'W. Europe Standard Time Europe/Gibraltar',
  },
  {
    key: 'Europe/Skopje',
    value: 'Central European Standard Time Europe/Skopje',
  },
  {
    key: 'Europe/Tirane',
    value: 'Central Europe Standard Time Europe/Tirane',
  },
  {
    key: 'Europe/Paris',
    value: 'Romance Standard Time Europe/Paris',
  },
  {
    key: 'Europe/Vatican',
    value: 'W. Europe Standard Time Europe/Vatican',
  },
  {
    key: 'Europe/Belgrade',
    value: 'Central Europe Standard Time Europe/Belgrade',
  },
  {
    key: 'Europe/Zagreb',
    value: 'Central European Standard Time Europe/Zagreb',
  },
  {
    key: 'Europe/Andorra',
    value: 'W. Europe Standard Time Europe/Andorra',
  },
  {
    key: 'Europe/Podgorica',
    value: 'Central Europe Standard Time Europe/Podgorica',
  },
  {
    key: 'Europe/Sarajevo',
    value: 'Central European Standard Time Europe/Sarajevo',
  },
  {
    key: 'Europe/San_Marino',
    value: 'W. Europe Standard Time Europe/San_Marino',
  },
  {
    key: 'Europe/Monaco',
    value: 'W. Europe Standard Time Europe/Monaco',
  },
  {
    key: 'Europe/Budapest',
    value: 'Central Europe Standard Time Europe/Budapest',
  },
  {
    key: 'Europe/Zurich',
    value: 'W. Europe Standard Time Europe/Zurich',
  },
  {
    key: 'Europe/Ljubljana',
    value: 'Central Europe Standard Time Europe/Ljubljana',
  },
  {
    key: 'Europe/Vaduz',
    value: 'W. Europe Standard Time Europe/Vaduz',
  },
  {
    key: 'Europe/Berlin',
    value: 'W. Europe Standard Time Europe/Berlin',
  },
  {
    key: 'Europe/Vienna',
    value: 'W. Europe Standard Time Europe/Vienna',
  },
  {
    key: 'Europe/Busingen',
    value: 'W. Europe Standard Time Europe/Busingen',
  },
  {
    key: 'Europe/Prague',
    value: 'Central Europe Standard Time Europe/Prague',
  },
  {
    key: 'Europe/Bratislava',
    value: 'Central Europe Standard Time Europe/Bratislava',
  },
  {
    key: 'Europe/Luxembourg',
    value: 'W. Europe Standard Time Europe/Luxembourg',
  },
  {
    key: 'Europe/Brussels',
    value: 'Romance Standard Time Europe/Brussels',
  },
  {
    key: 'Europe/Warsaw',
    value: 'Central European Standard Time Europe/Warsaw',
  },
  {
    key: 'Europe/Amsterdam',
    value: 'W. Europe Standard Time Europe/Amsterdam',
  },
  {
    key: 'Europe/Stockholm',
    value: 'W. Europe Standard Time Europe/Stockholm',
  },
  {
    key: 'Europe/Copenhagen',
    value: 'Romance Standard Time Europe/Copenhagen',
  },
  {
    key: 'Europe/Oslo',
    value: 'W. Europe Standard Time Europe/Oslo',
  },
  {
    key: 'Africa/Johannesburg',
    value: 'South Africa Standard Time Africa/Johannesburg',
  },
  {
    key: 'Arctic/Longyearbyen',
    value: 'W. Europe Standard Time Arctic/Longyearbyen',
  },
  {
    key: 'Africa/Maseru',
    value: 'South Africa Standard Time Africa/Maseru',
  },
  {
    key: 'Africa/Mbabane',
    value: 'South Africa Standard Time Africa/Mbabane',
  },
  {
    key: 'Africa/Windhoek',
    value: 'Namibia Standard Time Africa/Windhoek',
  },
  {
    key: 'Africa/Maputo',
    value: 'South Africa Standard Time Africa/Maputo',
  },
  {
    key: 'Africa/Harare',
    value: 'South Africa Standard Time Africa/Harare',
  },
  {
    key: 'Africa/Gaborone',
    value: 'South Africa Standard Time Africa/Gaborone',
  },
  {
    key: 'Africa/Lusaka',
    value: 'South Africa Standard Time Africa/Lusaka',
  },
  {
    key: 'Africa/Lubumbashi',
    value: 'South Africa Standard Time Africa/Lubumbashi',
  },
  {
    key: 'Africa/Blantyre',
    value: 'South Africa Standard Time Africa/Blantyre',
  },
  {
    key: 'Africa/Bujumbura',
    value: 'South Africa Standard Time Africa/Bujumbura',
  },
  {
    key: 'Africa/Khartoum',
    value: 'Sudan Standard Time Africa/Khartoum',
  },
  {
    key: 'Africa/Kigali',
    value: 'South Africa Standard Time Africa/Kigali',
  },
  {
    key: 'Africa/Cairo',
    value: 'Egypt Standard Time Africa/Cairo',
  },
  {
    key: 'Asia/Jerusalem',
    value: 'Jerusalem Standard Time Asia/Jerusalem',
  },
  {
    key: 'Africa/Tripoli',
    value: 'Libya Standard Time Africa/Tripoli',
  },
  {
    key: 'Asia/Amman',
    value: 'Jordan Standard Time Asia/Amman',
  },
  {
    key: 'Asia/Hebron',
    value: 'West Bank Gaza Standard Time Asia/Hebron',
  },
  {
    key: 'Asia/Gaza',
    value: 'West Bank Gaza Standard Time Asia/Gaza',
  },
  {
    key: 'Asia/Damascus',
    value: 'Syria Standard Time Asia/Damascus',
  },
  {
    key: 'Asia/Nicosia',
    value: 'GTB Standard Time Asia/Nicosia',
  },
  {
    key: 'Asia/Beirut',
    value: 'Middle East Standard Time Asia/Beirut',
  },
  {
    key: 'Europe/Athens',
    value: 'GTB Standard Time Europe/Athens',
  },
  {
    key: 'Europe/Bucharest',
    value: 'GTB Standard Time Europe/Bucharest',
  },
  {
    key: 'Europe/Sofia',
    value: 'FLE Standard Time Europe/Sofia',
  },
  {
    key: 'Europe/Kiev',
    value: 'FLE Standard Time Europe/Kiev',
  },
  {
    key: 'Europe/Zaporozhye',
    value: 'FLE Standard Time Europe/Zaporozhye',
  },
  {
    key: 'Europe/Chisinau',
    value: 'E. Europe Standard Time Europe/Chisinau',
  },
  {
    key: 'Europe/Uzhgorod',
    value: 'FLE Standard Time Europe/Uzhgorod',
  },
  {
    key: 'Europe/Kaliningrad',
    value: 'Russia TZ 1 Standard Time Europe/Kaliningrad',
  },
  {
    key: 'Europe/Vilnius',
    value: 'FLE Standard Time Europe/Vilnius',
  },
  {
    key: 'Europe/Riga',
    value: 'FLE Standard Time Europe/Riga',
  },
  {
    key: 'Europe/Helsinki',
    value: 'FLE Standard Time Europe/Helsinki',
  },
  {
    key: 'Europe/Tallinn',
    value: 'FLE Standard Time Europe/Tallinn',
  },
  {
    key: 'Europe/Mariehamn',
    value: 'FLE Standard Time Europe/Mariehamn',
  },
  {
    key: 'Indian/Mayotte',
    value: 'E. Africa Standard Time Indian/Mayotte',
  },
  {
    key: 'Indian/Antananarivo',
    value: 'E. Africa Standard Time Indian/Antananarivo',
  },
  {
    key: 'Indian/Comoro',
    value: 'E. Africa Standard Time Indian/Comoro',
  },
  {
    key: 'Africa/Nairobi',
    value: 'E. Africa Standard Time Africa/Nairobi',
  },
  {
    key: 'Africa/Dar_es_Salaam',
    value: 'E. Africa Standard Time Africa/Dar_es_Salaam',
  },
  {
    key: 'Africa/Mogadishu',
    value: 'E. Africa Standard Time Africa/Mogadishu',
  },
  {
    key: 'Africa/Juba',
    value: 'E. Africa Standard Time Africa/Juba',
  },
  {
    key: 'Africa/Kampala',
    value: 'E. Africa Standard Time Africa/Kampala',
  },
  {
    key: 'Africa/Addis_Ababa',
    value: 'E. Africa Standard Time Africa/Addis_Ababa',
  },
  {
    key: 'Asia/Aden',
    value: 'Arab Standard Time Asia/Aden',
  },
  {
    key: 'Africa/Djibouti',
    value: 'E. Africa Standard Time Africa/Djibouti',
  },
  {
    key: 'Africa/Asmara',
    value: 'E. Africa Standard Time Africa/Asmara',
  },
  {
    key: 'Asia/Qatar',
    value: 'Arab Standard Time Asia/Qatar',
  },
  {
    key: 'Asia/Riyadh',
    value: 'Arab Standard Time Asia/Riyadh',
  },
  {
    key: 'Asia/Bahrain',
    value: 'Arab Standard Time Asia/Bahrain',
  },
  {
    key: 'Asia/Baghdad',
    value: 'Arabic Standard Time Asia/Baghdad',
  },
  {
    key: 'Asia/Kuwait',
    value: 'Arab Standard Time Asia/Kuwait',
  },
  {
    key: 'Europe/Istanbul',
    value: 'Turkey Standard Time Europe/Istanbul',
  },
  {
    key: 'Europe/Simferopol',
    value: 'Russia TZ 2 Standard Time Europe/Simferopol',
  },
  {
    key: 'Europe/Moscow',
    value: 'Russia TZ 2 Standard Time Europe/Moscow',
  },
  {
    key: 'Europe/Minsk',
    value: 'Belarus Standard Time Europe/Minsk',
  },
  {
    key: 'Asia/Tehran',
    value: 'Iran Standard Time Asia/Tehran',
  },
  {
    key: 'Europe/Kirov',
    value: 'Russia TZ 2 Standard Time Europe/Kirov',
  },
  {
    key: 'Indian/Reunion',
    value: 'Mauritius Standard Time Indian/Reunion',
  },
  {
    key: 'Indian/Mahe',
    value: 'Mauritius Standard Time Indian/Mahe',
  },
  {
    key: 'Indian/Mauritius',
    value: 'Mauritius Standard Time Indian/Mauritius',
  },
  {
    key: 'Asia/Muscat',
    value: 'Arabian Standard Time Asia/Muscat',
  },
  {
    key: 'Asia/Baku',
    value: 'Azerbaijan Standard Time Asia/Baku',
  },
  {
    key: 'Asia/Dubai',
    value: 'Arabian Standard Time Asia/Dubai',
  },
  {
    key: 'Asia/Yerevan',
    value: 'Caucasus Standard Time Asia/Yerevan',
  },
  {
    key: 'Europe/Astrakhan',
    value: 'Astrakhan Standard Time Europe/Astrakhan',
  },
  {
    key: 'Asia/Tbilisi',
    value: 'Georgian Standard Time Asia/Tbilisi',
  },
  {
    key: 'Europe/Volgograd',
    value: 'Volgograd Standard Time Europe/Volgograd',
  },
  {
    key: 'Europe/Ulyanovsk',
    value: 'Astrakhan Standard Time Europe/Ulyanovsk',
  },
  {
    key: 'Europe/Samara',
    value: 'Russia TZ 3 Standard Time Europe/Samara',
  },
  {
    key: 'Asia/Kabul',
    value: 'Afghanistan Standard Time Asia/Kabul',
  },
  {
    key: 'Indian/Maldives',
    value: 'West Asia Standard Time Indian/Maldives',
  },
  {
    key: 'Indian/Kerguelen',
    value: 'West Asia Standard Time Indian/Kerguelen',
  },
  {
    key: 'Asia/Karachi',
    value: 'Pakistan Standard Time Asia/Karachi',
  },
  {
    key: 'Asia/Dushanbe',
    value: 'West Asia Standard Time Asia/Dushanbe',
  },
  {
    key: 'Asia/Ashgabat',
    value: 'West Asia Standard Time Asia/Ashgabat',
  },
  {
    key: 'Asia/Samarkand',
    value: 'West Asia Standard Time Asia/Samarkand',
  },
  {
    key: 'Asia/Aqtau',
    value: 'West Asia Standard Time Asia/Aqtau',
  },
  {
    key: 'Asia/Tashkent',
    value: 'West Asia Standard Time Asia/Tashkent',
  },
  {
    key: 'Asia/Qyzylorda',
    value: 'Qyzylorda Standard Time Asia/Qyzylorda',
  },
  {
    key: 'Asia/Oral',
    value: 'West Asia Standard Time Asia/Oral',
  },
  {
    key: 'Asia/Aqtobe',
    value: 'West Asia Standard Time Asia/Aqtobe',
  },
  {
    key: 'Asia/Yekaterinburg',
    value: 'Russia TZ 4 Standard Time Asia/Yekaterinburg',
  },
  {
    key: 'Asia/Kolkata',
    value: 'India Standard Time Asia/Kolkata',
  },
  {
    key: 'Asia/Colombo',
    value: 'Sri Lanka Standard Time Asia/Colombo',
  },
  {
    key: 'Asia/Kathmandu',
    value: 'Nepal Standard Time Asia/Kathmandu',
  },
  {
    key: 'Asia/Dhaka',
    value: 'Bangladesh Standard Time Asia/Dhaka',
  },
  {
    key: 'Indian/Chagos',
    value: 'Central Asia Standard Time Indian/Chagos',
  },
  {
    key: 'Asia/Thimphu',
    value: 'Bangladesh Standard Time Asia/Thimphu',
  },
  {
    key: 'Asia/Almaty',
    value: 'Central Asia Standard Time Asia/Almaty',
  },
  {
    key: 'Asia/Bishkek',
    value: 'Central Asia Standard Time Asia/Bishkek',
  },
  {
    key: 'Asia/Omsk',
    value: 'Omsk Standard Time Asia/Omsk',
  },
  {
    key: 'Asia/Rangoon',
    value: 'Myanmar Standard Time Asia/Rangoon',
  },
  {
    key: 'Indian/Cocos',
    value: 'Myanmar Standard Time Indian/Cocos',
  },
  {
    key: 'Indian/Christmas',
    value: 'SE Asia Standard Time Indian/Christmas',
  },
  {
    key: 'Asia/Pontianak',
    value: 'SE Asia Standard Time Asia/Pontianak',
  },
  {
    key: 'Asia/Jakarta',
    value: 'SE Asia Standard Time Asia/Jakarta',
  },
  {
    key: 'Asia/Bangkok',
    value: 'SE Asia Standard Time Asia/Bangkok',
  },
  {
    key: 'Asia/Phnom_Penh',
    value: 'SE Asia Standard Time Asia/Phnom_Penh',
  },
  {
    key: 'Asia/Ho_Chi_Minh',
    value: 'SE Asia Standard Time Asia/Ho_Chi_Minh',
  },
  {
    key: 'Asia/Vientiane',
    value: 'SE Asia Standard Time Asia/Vientiane',
  },
  {
    key: 'Asia/Barnaul',
    value: 'Altai Standard Time Asia/Barnaul',
  },
  {
    key: 'Asia/Hovd',
    value: 'W. Mongolia Standard Time Asia/Hovd',
  },
  {
    key: 'Asia/Krasnoyarsk',
    value: 'Russia TZ 6 Standard Time Asia/Krasnoyarsk',
  },
  {
    key: 'Asia/Novosibirsk',
    value: 'Novosibirsk Standard Time Asia/Novosibirsk',
  },
  {
    key: 'Asia/Novokuznetsk',
    value: 'Russia TZ 6 Standard Time Asia/Novokuznetsk',
  },
  {
    key: 'Asia/Tomsk',
    value: 'Tomsk Standard Time Asia/Tomsk',
  },
  {
    key: 'Asia/Makassar',
    value: 'Malay Peninsula Standard Time Asia/Makassar',
  },
  {
    key: 'Australia/Perth',
    value: 'W. Australia Standard Time Australia/Perth',
  },
  {
    key: 'Asia/Singapore',
    value: 'Singapore Standard Time Asia/Singapore',
  },
  {
    key: 'Asia/Kuching',
    value: 'Malay Peninsula Standard Time Asia/Kuching',
  },
  {
    key: 'Asia/Kuala_Lumpur',
    value: 'Malay Peninsula Standard Time Asia/Kuala_Lumpur',
  },
  {
    key: 'Asia/Brunei',
    value: 'Malay Peninsula Standard Time Asia/Brunei',
  },
  {
    key: 'Asia/Shanghai',
    value: 'China Standard Time Asia/Shanghai',
  },
  {
    key: 'Asia/Manila',
    value: 'Malay Peninsula Standard Time Asia/Manila',
  },
  {
    key: 'Asia/Taipei',
    value: 'Taipei Standard Time Asia/Taipei',
  },
  {
    key: 'Asia/Hong_Kong',
    value: 'China Standard Time Asia/Hong_Kong',
  },
  {
    key: 'Asia/Macau',
    value: 'China Standard Time Asia/Macau',
  },
  {
    key: 'Asia/Ulaanbaatar',
    value: 'Ulaanbaatar Standard Time Asia/Ulaanbaatar',
  },
  {
    key: 'Asia/Irkutsk',
    value: 'Russia TZ 7 Standard Time Asia/Irkutsk',
  },
  {
    key: 'Asia/Choibalsan',
    value: 'Ulaanbaatar Standard Time Asia/Choibalsan',
  },
  {
    key: 'Australia/Eucla',
    value: 'Aus Central W. Standard Time Australia/Eucla',
  },
  {
    key: 'Asia/Jayapura',
    value: 'Tokyo Standard Time Asia/Jayapura',
  },
  {
    key: 'Asia/Dili',
    value: 'Tokyo Standard Time Asia/Dili',
  },
  {
    key: 'Pacific/Palau',
    value: 'Tokyo Standard Time Pacific/Palau',
  },
  {
    key: 'Asia/Seoul',
    value: 'Korea Standard Time Asia/Seoul',
  },
  {
    key: 'Asia/Tokyo',
    value: 'Tokyo Standard Time Asia/Tokyo',
  },
  {
    key: 'Asia/Pyongyang',
    value: 'North Korea Standard Time Asia/Pyongyang',
  },
  {
    key: 'Asia/Yakutsk',
    value: 'Russia TZ 8 Standard Time Asia/Yakutsk',
  },
  {
    key: 'Asia/Chita',
    value: 'Transbaikal Standard Time Asia/Chita',
  },
  {
    key: 'Asia/Khandyga',
    value: 'Russia TZ 8 Standard Time Asia/Khandyga',
  },
  {
    key: 'Australia/Broken_Hill',
    value: 'Cen. Australia Standard Time Australia/Broken_Hill',
  },
  {
    key: 'Australia/Adelaide',
    value: 'Cen. Australia Standard Time Australia/Adelaide',
  },
  {
    key: 'Australia/Darwin',
    value: 'AUS Central Standard Time Australia/Darwin',
  },
  {
    key: 'Australia/Currie',
    value: 'Tasmania Standard Time Australia/Currie',
  },
  {
    key: 'Australia/Hobart',
    value: 'Tasmania Standard Time Australia/Hobart',
  },
  {
    key: 'Australia/Melbourne',
    value: 'AUS Eastern Standard Time Australia/Melbourne',
  },
  {
    key: 'Australia/Brisbane',
    value: 'E. Australia Standard Time Australia/Brisbane',
  },
  {
    key: 'Australia/Sydney',
    value: 'AUS Eastern Standard Time Australia/Sydney',
  },
  {
    key: 'Australia/Lindeman',
    value: 'E. Australia Standard Time Australia/Lindeman',
  },
  {
    key: 'Pacific/Chuuk',
    value: 'West Pacific Standard Time Pacific/Chuuk',
  },
  {
    key: 'Pacific/Port_Moresby',
    value: 'West Pacific Standard Time Pacific/Port_Moresby',
  },
  {
    key: 'Pacific/Yap',
    value: 'West Pacific Standard Time Pacific/Yap',
  },
  {
    key: 'Pacific/Saipan',
    value: 'West Pacific Standard Time Pacific/Saipan',
  },
  {
    key: 'Pacific/Guam',
    value: 'West Pacific Standard Time Pacific/Guam',
  },
  {
    key: 'Asia/Vladivostok',
    value: 'Russia TZ 9 Standard Time Asia/Vladivostok',
  },
  {
    key: 'Australia/Lord_Howe',
    value: 'Lord Howe Standard Time Australia/Lord_Howe',
  },
  {
    key: 'Asia/Ust-Nera',
    value: 'Russia TZ 9 Standard Time Asia/Ust-Nera',
  },
  {
    key: 'Antarctica/Macquarie',
    value: 'Central Pacific Standard Time Antarctica/Macquarie',
  },
  {
    key: 'Pacific/Noumea',
    value: 'Central Pacific Standard Time Pacific/Noumea',
  },
  {
    key: 'Pacific/Norfolk',
    value: 'Norfolk Standard Time Pacific/Norfolk',
  },
  {
    key: 'Pacific/Efate',
    value: 'Central Pacific Standard Time Pacific/Efate',
  },
  {
    key: 'Pacific/Bougainville',
    value: 'Bougainville Standard Time Pacific/Bougainville',
  },
  {
    key: 'Pacific/Guadalcanal',
    value: 'Central Pacific Standard Time Pacific/Guadalcanal',
  },
  {
    key: 'Pacific/Kosrae',
    value: 'Central Pacific Standard Time Pacific/Kosrae',
  },
  {
    key: 'Asia/Sakhalin',
    value: 'Sakhalin Standard Time Asia/Sakhalin',
  },
  {
    key: 'Pacific/Pohnpei',
    value: 'Central Pacific Standard Time Pacific/Pohnpei',
  },
  {
    key: 'Asia/Srednekolymsk',
    value: 'Russia TZ 10 Standard Time Asia/Srednekolymsk',
  },
  {
    key: 'Pacific/Auckland',
    value: 'New Zealand Standard Time Pacific/Auckland',
  },
  {
    key: 'Asia/Magadan',
    value: 'Magadan Standard Time Asia/Magadan',
  },
  {
    key: 'Pacific/Fiji',
    value: 'Fiji Standard Time Pacific/Fiji',
  },
  {
    key: 'Pacific/Funafuti',
    value: 'UTC+12 Pacific/Funafuti',
  },
  {
    key: 'Pacific/Wallis',
    value: 'UTC+12 Pacific/Wallis',
  },
  {
    key: 'Pacific/Tarawa',
    value: 'UTC+12 Pacific/Tarawa',
  },
  {
    key: 'Pacific/Majuro',
    value: 'UTC+12 Pacific/Majuro',
  },
  {
    key: 'Pacific/Nauru',
    value: 'UTC+12 Pacific/Nauru',
  },
  {
    key: 'Pacific/Kwajalein',
    value: 'UTC+12 Pacific/Kwajalein',
  },
  {
    key: 'Asia/Kamchatka',
    value: 'Russia TZ 11 Standard Time Asia/Kamchatka',
  },
  {
    key: 'Pacific/Wake',
    value: 'UTC+12 Pacific/Wake',
  },
  {
    key: 'Asia/Anadyr',
    value: 'Russia TZ 11 Standard Time Asia/Anadyr',
  },
  {
    key: 'Pacific/Tongatapu',
    value: 'Tonga Standard Time Pacific/Tongatapu',
  },
  {
    key: 'Pacific/Chatham',
    value: 'Chatham Islands Standard Time Pacific/Chatham',
  },
  {
    key: 'Pacific/Apia',
    value: 'Samoa Standard Time Pacific/Apia',
  },
  {
    key: 'Pacific/Enderbury',
    value: 'UTC+13 Pacific/Enderbury',
  },
  {
    key: 'Pacific/Fakaofo',
    value: 'UTC+13 Pacific/Fakaofo',
  },
  {
    key: 'Pacific/Kiritimati',
    value: 'Line Islands Standard Time Pacific/Kiritimati',
  },
];

export const ProgressTrackerLabels = {
  PENDING_REQUEST: 'Pending Request',
  SELECT_OWNERSHIP: 'Select Ownership',
  PAYMENT_PREVIEW: 'Payment Preview',
  ENTER_REASON: 'Enter Reason',
};
