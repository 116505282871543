<div class="organization">
  <div class="modalBody orgModal">
    <div class="cc-modal">
      <div class="cc-modal-header">
        <div class="cc-modal-title">{{ data.title }}</div>
      </div>
      <ng-container *ngIf="!isDealer">
        <div class="cc-modal-content">
          <form [formGroup]="orgFormGroup">
            <div class="org-dealer-header">SELECTED DEALER CODE</div>
            <div class="yellow-border"></div>
            <div class="org-selected-dealer-box">
              <div class="org-selected-dealer">
                <div class="cc-grid">
                  <div class="cc-col cc-lg-col--1 cc-md-col--1 cc-xl-col--1 cc-sm-col--1 cc-xs-col--1">
                    <div><cc-icon fontSet="icomoon">tick</cc-icon></div>
                  </div>
                  <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--10 cc-sm-col--8 cc-xs-col--4">
                    <div class="dealerText">SELECTED DEALER CODE</div>
                    <div class="selectedText">{{ dealer.dealerName }}</div>
                    <div class="selectedText">{{ dealer.dealerCode }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="org-search-dealer">Search Dealer Code or Name</div>
              <div class="cc-grid">
                <div class="cc-md-col--12">
                  <cc-input-field
                    #selDealer
                    [labelValue]="'Minimum three characters'"
                    [inputtype]="'search'"
                    [isDense]="true"
                    [width]="'430px'"
                    [isTheme]="'2.0'"
                    [isLeading]="true"
                    id="cc-atom-search-l9"
                    [value]="searchDealer"
                    (valueChange)="searchDealer = $event.value; searchDealerbyCode()"
                    [triggerSearchMinChar]="3"
                    [enableSearchOverlay]="true"
                    [disabled]="isValidateDealercode"
                  >
                    <span>
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div class="cc-input-search-menu">
                      <cc-list
                        *ngIf="filterDealerList && filterDealerList.length > 0"
                        [theme]="'secondary'"
                        width="1000px"
                      >
                        <cc-list-item
                          *ngFor="let item of filterDealerList; let i = index"
                          class="list-item-pg"
                          (listItemClick)="listItemClick('searchDealer', i)"
                          (listItemEnter)="listItemClick('searchDealer', i)"
                        >
                          <div class="each-item">
                            <div class="txt-name">
                              <span class="label" [innerHTML]="item.innerDealer"></span>
                            </div>
                          </div>
                        </cc-list-item>
                      </cc-list>
                      <div *ngIf="noRecords" class="no-result">
                        <div>Dealer not found</div>
                      </div>
                      <div *ngIf="showSameDealerCodeError" class="no-result">
                        <div>Currently logged in as {{ dealer.dealerCode }}</div>
                      </div>
                    </div>
                    <input
                      #searchbox
                      input-field
                      [(ngModel)]="searchDealer"
                      class="input-field"
                      [value]="searchDealer"
                      autocomplete="off"
                      formControlName="searchDealer"
                      type="search"
                      [isFloating]="false"
                      aria-labelledby="Search"
                      aria-describedby="Search"
                      (valueChange)="onValue($event)"
                      (focus)="onValue($event)"
                      (click)="onFocusInput()"
                    />
                  </cc-input-field>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="cc-modal-footer">
          <div>
            <button cc-btn-secondary [setTheme]="true" (click)="onClickClose()" class="cc-btn cc-btn-secondary-outline">
              Cancel
            </button>
            <button
              cc-btn-primary
              [disabled]="!isSaveBtnActive || searchDealer === ''"
              (click)="onSaveDealer($event)"
              [setTheme]="true"
            >
              Save Changes
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isDealer">
        <div class="cc-modal-content">
          <form [formGroup]="orgFormGroup">
            <div class="org-dealer-header">SELECTED DEALER CODE</div>
            <div class="yellow-border"></div>
            <div class="org-selected-dealer-box">
              <div class="org-selected-dealer">
                <div class="cc-grid">
                  <div class="cc-col cc-lg-col--1 cc-md-col--1 cc-xl-col--1 cc-sm-col--1 cc-xs-col--1">
                    <div><cc-icon fontSet="icomoon">tick</cc-icon></div>
                  </div>
                  <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--10 cc-sm-col--8 cc-xs-col--4">
                    <div class="dealerText">SELECTED DEALER CODE</div>
                    <div class="selectedText">{{ dealer.dealerName }}</div>
                    <div class="selectedText">{{ dealer.dealerCode }}</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="cc-modal-footer">
          <div>
            <button cc-btn-primary (click)="onClickClose()" [setTheme]="true">Close</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
