/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CC_DRAWER_DATA,
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
  DrawerService,
  ITableSettingsPanelConfig,
  MessageBar,
  MessageBarConfig,
  TableConfig,
  HighlightSearchPipe,
} from '@cat-digital-workspace/shared-ui-core';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { invoiceDetailData, getDetailedInvoiceResponseData } from '../../billing-interface';
import { BillingService } from '../../services/billing.service';
import { FormControl, FormControlName, Validators } from '@angular/forms';
import { BillingApplicationDetailedViewComponent } from '../billing-application-detailed-view/billing-application-detailed-view.component';
import { BillingInquiryComponent } from '../billing-inquiry/billing-inquiry.component';
import { debounce } from 'lodash-es';
import { ColumnConfig } from '../../detailed-invoice-column-config';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { DetailedInvTooltipHighlightComponent } from '../detailed-invoice-tooltip-highlight/detailed-inv-tooltip-highlight.component';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import * as dspConstants from '../../../../../../apps/dsp-ui/src/app/shared/dspConstants';
import { TablesettingService } from '../../../../../../apps/dsp-ui/src/app/services/tablesetting.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { CommonFilterService } from 'apps/dsp-ui/src/app/services/common-filter.service';
import { TableSettingsCloseConfirmationComponent } from 'apps/dsp-ui/src/app/components/table-settings-close-confirmation/table-settings-close-confirmation.component';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { ASSETDATEOVERLAYDATA } from 'apps/dsp-ui/src/app/components/assets/asset-constants';
import { DetailedInvoiceComponentAsset } from '../detailed-invoice-asset/detailed-invoice-asset.component';
import { PopupData } from '../../../../../../apps/dsp-ui/src/app/models/popup.interface';
import { CommonConfirmationPopupComponent } from 'apps/dsp-ui/src/app/components/common-confirmation-popup/common-confirmation-popup.component';

@Component({
  selector: 'dsp-next-gen-ui-detailed-invoice',
  templateUrl: './detailed-invoice.component.html',
  styleUrls: ['./detailed-invoice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailedInvoiceComponent implements OnInit, OnDestroy {
  updatedTableConfig: any = [];

  @Input() detailedInvoice: any;
  @ViewChild('invoiceSearchBox') invoiceSearchBox!: ElementRef;
  @ViewChild('settingPanel') settingPanelElement!: ElementRef;
  @ViewChild('settingIcon') settingIconElement!: ElementRef;
  @ViewChild('detailedInvoiceTableRef') tableRef: any;
  isTheme = '2.0';
  searchHelpText = '';
  width = '280px';
  isTableLoading = false;
  appDetail: any;
  isSettingsOutSideClicked = false;
  modalRef: any;
  showLoader = false;
  pageLabel = 'billing';
  searchValue: FormControl = new FormControl('', {
    validators: [Validators.minLength(3)],
    updateOn: 'change', //blur
  });
  mobileHeader = 'Search User';
  overlayData = [
    {
      name: 'asc',
      title: 'Sort A to Z',
    },
    {
      name: 'dsc',
      title: 'Sort Z to A',
    },
  ];
  assetCountOverlayData = [
    {
      name: 'asc',
      title: 'Sort Smallest to Largest',
    },
    {
      name: 'dsc',
      title: 'Sort Largest to Smallest',
    },
  ];
  tableSettingsPanelConfig!: ITableSettings;
  tableSettingsConfig: ITableSettingsPanelConfig = {
    features: [],
    visibilityToggle: false,
  };
  settingsPanelConfig = {
    settingsPanelHeader: 'Table Settings',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  detailedInvoiceTableConfig: any = {
    height: window.innerHeight - 267,
    stickyHeader: true,
    expandable: false,
    isSelfFoldable: true,
    isBulkAction: false,
    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    footer: true,
    isPaginationEnable: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [20, 40, 60, 80, 100],
      advanced: false,
      activePage: 1,
      defaultPageSize: 20,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
      totalNumberOfRecords: 0,
    },
    isEnableSettingsPanel: false,
    columnConfig: ColumnConfig,
  };
  invoiceTableData: invoiceDetailData[] = [];
  dealerCode: any;
  downloadedFileName = '';
  fileType: any;
  billingCoumnList: any;
  detailedInvoiceDealerCode: any;
  showBillingMain: any = false;
  appdetailRef: any;
  rowData: any;
  isSettingVisible = false;
  settingDrawerRef: any;
  invoiceTableCustomData: any = {
    searchString: '',
  };
  prevSearchMain: any;
  appliedFilters: any;
  filterContentsRef: any;
  savedFilters: any;
  filterResponse: any;
  isSaveTableSettings = '';
  dspStoreData: any;
  detailedInvoicePayload = {
    invoiceNumber: '',
    invoiceDealerCode: '',
    pageOffset: 0,
    pageLimit: 20,
    sortColumn: 'customer',
    sortOrder: 'ASC',
    operation: 'fetch',
    searchText: '',
  };
  listItems = [
    {
      label: 'Download PDF',
      selected: true,
    },
    {
      label: 'Download Excel',
    },
  ];
  userActiveFeatures: any;
  modalRef1: any;
  isTableSettingsApply = false;
  isTableSettingClose = false;
  isCloseEvent = false;
  enableSearch = false;
  searchClicked = false;
  isRemovePaginationSearch = false;
  searchDataLoaded = false;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  observable!: Subscription;
  isRemoveOutstandingBalance!: any;
  isNumberConventionEnabled: any;
  searchTextValue: any;
  isMaintainPagination = false;
  isRemoveBillingInquiryButton = false;

  isCommonPopup = false;
  getDetailedInvoiceSearch() {
    if (this.isRemovePaginationSearch) {
      this.searchClicked = true;
      if (this.searchValue?.value.length > 2 && this.searchClicked) {
        this.isTableLoading = true;
        this.fetchBillingDetails(true, true);
      } else {
        this.searchClicked = false;
        this.fetchBillingDetails(false, true);
      }
    } else {
      if (this.searchValue?.value.length > 2) {
        this.isTableLoading = true;
        this.fetchBillingDetails(true, true);
      } else {
        this.fetchBillingDetails(false, true);
      }
    }
  }

  constructor(
    private billingService: BillingService,
    private drawerService: DrawerService,
    public modal: CcModal,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private tableSettingService: TablesettingService,
    private commonFilterService: CommonFilterService,
    private userPreferenceService: UserPreferenceService,
    private dspUtilsCommonService: DspUtilsCommonService
  ) {}
  ngOnInit(): void {
    this.commonFilterService.getFilterColumn(this.pageLabel).subscribe((response: any) => {
      this.billingCoumnList = response;
    });
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];

      if (this.userActiveFeatures?.indexOf('disablePdfReport') > -1) {
        this.listItems = this.listItems.filter((item) => item.label !== 'Download PDF');
      }
      this.dspStoreData = dsp;
      this.isSaveTableSettings = this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        : false;
      this.isRemoveOutstandingBalance = this.dspStoreData?.featureFlagInfo?.isRemoveOutstandingBalance
        ? this.dspStoreData?.featureFlagInfo?.isRemoveOutstandingBalance
        : false;
      this.isNumberConventionEnabled = this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isRemovePaginationSearch = this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        ? this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        : false;
      this.isMaintainPagination = dsp?.featureFlagInfo?.isMaintainPagination
        ? dsp?.featureFlagInfo?.isMaintainPagination
        : false;

      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
      this.isRemoveBillingInquiryButton = this.dspStoreData?.featureFlagInfo?.isRemoveBillingInquiryButton
        ? this.dspStoreData?.featureFlagInfo?.isRemoveBillingInquiryButton
        : false;
    });
    if (this.isSaveTableSettings) {
      let configSaved: any = sessionStorage.getItem('tableSettings');
      configSaved = configSaved ? JSON.parse(configSaved) : null;
      configSaved = configSaved?.billing;
      this.detailedInvoiceTableConfig.columnConfig = configSaved
        ? configSaved
        : this.detailedInvoiceTableConfig.columnConfig;
    } else {
      const configSaved = sessionStorage.getItem('detailedInvoiceColumnConfig');
      this.detailedInvoiceTableConfig.columnConfig = configSaved
        ? JSON.parse(configSaved)
        : this.detailedInvoiceTableConfig.columnConfig;
    }
    this.observable = this.tableSettingService.billingObservable.subscribe((res) => {
      this.createSettingsPanelConfig();
      this.updateComponent();
    });

    this.setLoggedInDealerInfo(this.dealerCode);
    if (!this.isMaintainPagination) {
      this.fetchBillingDetails(false, true);
      this.createSettingsPanelConfig();
    }
    this.fetchSettingDetails();
    this.updateColumnConfigCustomData();
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
  }

  ngOnDestroy() {
    this.observable.unsubscribe();
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }

  @HostListener('document:click', ['$event'])
  documentClick = ($event: any) => {
    const isClickedOnGearIcon =
      this.settingIconElement && this.settingIconElement?.nativeElement.contains($event.target);
    const isClickedOnSettingPanel =
      this.settingIconElement && this.settingPanelElement?.nativeElement.contains($event.target);
    if (!(isClickedOnGearIcon || isClickedOnSettingPanel)) {
      this.closeSettingPanel();
    }
  };
  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
  }
  emptyColumnDataCheck() {
    this.invoiceTableData = this.invoiceTableData.map((user: any) => {
      const attributes = Object.keys(user);
      const formattedAttr = [
        'assetSerialNumber',
        'application',
        'customer',
        'ucid',
        'dcn',
        'siteName',
        'make',
        'assetModel',
        'subTotal',
      ];
      attributes.forEach((item) => {
        if (formattedAttr.indexOf(item) > -1) {
          if (item == 'subTotal') {
            user[item] = user[item] ? this.convertNumberFormat(user[item], true) : '-';
          } else {
            user[item] = user[item] ? user[item] : '-';
          }
        }
      });
      return user;
    });
  }
  fetchSettingDetails() {
    this.isTableLoading = true;

    this.tableSettingService.getTableSettings('billing', this.detailedInvoiceTableConfig);

    const newColumnAvailable = this.detailedInvoiceTableConfig.columnConfig.some(
      (column: any) => column.name === 'billingStartDate' || column.name === 'billingEndDate'
    );
    if (!newColumnAvailable) {
      if (this.dspStoreData?.dspConfig?.isIncludeBillingStartAndEndDate === 'true') {
        this.detailedInvoiceTableConfig.columnConfig.splice(
          5,
          0,
          this.dspUtilsCommonService.addNewColumnToConfig(
            this.detailedInvoiceTableConfig.columnConfig,
            'billingStartDate',
            'Billing Start Date',
            160,
            '',
            false,
            true,
            true,
            true,
            ASSETDATEOVERLAYDATA,
            160,
            DetailedInvTooltipHighlightComponent
          ),
          this.dspUtilsCommonService.addNewColumnToConfig(
            this.detailedInvoiceTableConfig.columnConfig,
            'billingEndDate',
            'Billing End Date',
            160,
            '',
            false,
            true,
            true,
            true,
            ASSETDATEOVERLAYDATA,
            160,
            DetailedInvTooltipHighlightComponent
          )
        );
      }
    }
  }

  setLoggedInDealerInfo(dealerCode: string) {
    this.detailedInvoiceDealerCode = dealerCode;
  }
  fetchBillingDetails(search?: boolean, callPaginationApi?: boolean) {
    this.setAutoHeight();
    this.invoiceTableData = [];
    this.isTableLoading = true;
    this.detailedInvoicePayload = {
      ...this.detailedInvoicePayload,
      invoiceNumber: this.detailedInvoice?.invoiceNumber,
      invoiceDealerCode: this.detailedInvoiceDealerCode,
    };
    
    let fetchDetailedInvoiceAPIPayloadCount: any = {},
      fetchDetailedInvoiceAPIPayload: any = {};
    const restoreFilter = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      this.detailedInvoice?.invoiceNumber,
      ''
    );
    search = this.searchValue.value.length > 2;
    if (!isEmpty(restoreFilter)) {
      const filter = restoreFilter;
      fetchDetailedInvoiceAPIPayloadCount = {
        ...this.detailedInvoicePayload,
        operation: 'filterCount',
        filter: filter,
        searchText: this.searchDataLoaded ? this.searchValue.value.trim() : '',
      };
      fetchDetailedInvoiceAPIPayload = {
        ...this.detailedInvoicePayload,
        operation: 'filter',
        filter: filter,
        searchText: this.searchDataLoaded ? this.searchValue.value.trim() : '',
      };
      this.invoiceTableCustomData.searchString = this.searchDataLoaded ? this.searchValue.value.trim() : '';
    } else if (!search && this.isRemovePaginationSearch) {
      this.appliedFilters = null;

      fetchDetailedInvoiceAPIPayloadCount = {
        ...this.detailedInvoicePayload,
        operation: 'fetch',
        searchText: '',
      };
      fetchDetailedInvoiceAPIPayloadCount.operation = 'fetchCount';
      fetchDetailedInvoiceAPIPayloadCount.searchText = '';
      fetchDetailedInvoiceAPIPayload = {
        ...this.detailedInvoicePayload,
        operation: 'fetch',
        searchText: '',
      };
    } else if (search || this.searchValue?.value.length > 2) {
      this.appliedFilters = null;
      this.invoiceTableCustomData.searchString = this.searchValue.value.trim();

      fetchDetailedInvoiceAPIPayloadCount = {
        ...this.detailedInvoicePayload,
        operation: 'searchCount',
        searchText: this.searchDataLoaded ? this.searchValue?.value.trim() : '',
      };
      fetchDetailedInvoiceAPIPayloadCount.operation = 'searchCount';
      fetchDetailedInvoiceAPIPayloadCount.searchText = this.searchValue?.value.trim();
      fetchDetailedInvoiceAPIPayload = {
        ...this.detailedInvoicePayload,
        operation: 'search',
        searchText: this.searchDataLoaded ? this.searchValue?.value.trim() : '',
      };
    } else {
      fetchDetailedInvoiceAPIPayloadCount = { ...this.detailedInvoicePayload, operation: 'fetchCount' };
      fetchDetailedInvoiceAPIPayloadCount.operation = 'fetchCount';
      fetchDetailedInvoiceAPIPayload = { ...this.detailedInvoicePayload, operation: 'fetch' };
      fetchDetailedInvoiceAPIPayload.operation = 'fetch';
      fetchDetailedInvoiceAPIPayload.searchText =
        this.searchValue?.value.length > 2 ? this.searchValue.value.trim() : '';
    }
    if (callPaginationApi) {
      forkJoin([
        this.billingService.getDetailedInvoicesCount(fetchDetailedInvoiceAPIPayloadCount),
        this.billingService.getDetailedBillingInvoice(fetchDetailedInvoiceAPIPayload),
      ]).subscribe(
        ([invoiceCount, invoiceResponse]) => {
          const invoiceCount1 = {
            pageSizeOptions: [20, 40, 60, 80, 100],
            advanced: false,
            activePage: 1,
            defaultPageSize: this.detailedInvoiceTableConfig?.pagination?.defaultPageSize,
            setTheme: true,
            enableDropdownWithPrefix: false,
            paginationLabelText: 'Viewing',
            totalNumberOfRecords: Object.values(invoiceCount)[0],
          };
          this.detailedInvoiceTableConfig = { ...this.detailedInvoiceTableConfig, pagination: invoiceCount1 };
          this.fetchBillingResponse(invoiceResponse);
          this.emptyColumnDataCheck();
          this.isTableLoading = false;
          this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
        },
        (err) => {
          const message = 'There was an error processing your request.';
          this.showToastMessage(message, 'error');
          this.isTableLoading = false;
        }
      );
    } else {
      this.billingService.getDetailedBillingInvoice(fetchDetailedInvoiceAPIPayload).subscribe(
        (res) => {
          this.fetchBillingResponse(res);
          this.emptyColumnDataCheck();
          this.isTableLoading = false;
          this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
        },
        (_err: any) => {
          const message = 'There was an error processing your request.';
          this.showToastMessage(message, 'error');
          this.isTableLoading = false;
        }
      );
    }
  }
  setAutoHeight() {
    const tableRef = this.tableRef?.table?.nativeElement;
    if (this.renderer && tableRef) {
      this.renderer.setStyle(tableRef, 'height', 'auto');
    }
  }
  adjustTableHeight(event: any) {
    if ((event.eventType === 'SCROLL_EVENT' || event.eventType === 'WINDOW_RESIZE') && this.tableRef) {
      const tableRef = this.tableRef?.table?.nativeElement;
      if (this.invoiceTableData?.length) {
        const adjustHeight = window.innerHeight - 267 + 'px';
        // this.renderer.setStyle(tableRef, 'min-height', adjustHeight);
        this.renderer.setStyle(tableRef, 'height', adjustHeight);
        this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
      }
    }
  }
  goBackToBillingScreen(event: any) {
    this.showBillingMain = true;
  }
  fetchBillingResponse(billingResponse: any) {
    this.invoiceTableData = (billingResponse as getDetailedInvoiceResponseData)?.records.map((obj) => ({
      ...obj,
      customer: obj.customer,
      ucid: obj.ucid,
      make: obj.make,
      assetSerialNumber: obj.assetSerialNumber,
      dcn: obj.dcn,
      subTotal: obj.subTotal,
      application: obj.application,
      dealerCode: obj.dealerCode,
      assetModel: obj.assetModel,
      siteName: obj.siteName,
    }));
    this.isTableLoading = false;
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  updateColumnConfigCustomData() {
    this.detailedInvoiceTableConfig.columnConfig.forEach((config: any) => {
      config.customData = this.invoiceTableCustomData;
    });
  }

  onInvoiceSearchChange(event: any) {
    if (event.value.trim().length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value !== '' && this.searchHelpText !== '') {
      this.searchHelpText = '';
    }
    if (event.value.trim().length > 2 && this.prevSearchMain != this.searchValue?.value) {
      this.savedFilters = {};
      this.searchValue.setValue(event.value);
    } else if (event.value === '') {
      if (!this.isTableLoading) {
        this.searchHelpText = 'Find (Min 3 Char)';
      } else {
        this.searchHelpText = '';
      }
      this.searchValue.setValue('');
      if (this.prevSearchMain?.length > 0 && this.invoiceTableCustomData.searchString.length > 2) {
        if (this.searchDataLoaded) {
          this.getDetailedInvoiceSearch();
          this.searchDataLoaded = false;
        }
      }
      this.invoiceTableCustomData.searchString = '';
    }
    this.prevSearchMain = this.searchValue?.value;
  }

  onCickEvent(event: any) {
    if (event.target.value === '') {
      this.searchHelpText = 'Find (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }

  onBlurEvent(event: any) {
    if (event.type === 'blur') this.searchHelpText = '';
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.value.length || 0;
      this.invoiceSearchBox.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }
  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };
  onListItemClick(value?: any) {
    const item = value.split(' ');
    this.fileType = item[1];
    this.uploadFile(this.fileType);
  }

  uploadFile(type: string) {
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.showLoader = true;
    switch (type) {
      case 'PDF':
        this.downloadedFileName = '.pdf';

        this.exportPDFData();
        break;
      case 'Excel':
        this.downloadedFileName = '.xlsx';

        this.exportExcelDetailedData();
        break;
      default:
        break;
    }
  }

  exportExcelData() {
    const restoreFilter = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      this.detailedInvoice?.invoiceNumber,
      ''
    );

    let filter: any = {};
    if (!isEmpty(restoreFilter)) {
      filter = restoreFilter;
    }

    const input = {
      invoiceNumber: this.detailedInvoice?.invoiceNumber,
      invoiceDealerCode: this.dealerCode,
      filter: filter ? filter : {},
      searchText: this.searchDataLoaded ? this.searchValue?.value.trim() : '',
      pageOffset: 1,
      pageLimit: 100,
      sortColumn: 'customer',
      sortOrder: 'ASC',
      operation: 'exportExcel',
    };
    this.billingService.exportExcelDetailedInvoiceDetails(input).subscribe(
      (downloadResp: any) => {
        this.createDefaultAnchorElementPdf(downloadResp, 'excel');

        this.showLoader = false;
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  exportExcelDetailedData() {
    this.showLoader = true;
    const restoreFilter = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      this.detailedInvoice?.invoiceNumber,
      ''
    );

    let filter: any = {};
    if (!isEmpty(restoreFilter)) {
      filter = restoreFilter;
    }

    const input = {
      invoiceNumber: this.detailedInvoice?.invoiceNumber,
      invoiceDealerCode: this.dealerCode,
      filter: filter ? filter : {},
      searchText: this.searchDataLoaded ? this.searchValue?.value.trim() : '',
      pageOffset: 1,
      pageLimit: 100,
      sortColumn: 'customer',
      sortOrder: 'ASC',
      operation: 'exportExcel',
    };
    this.billingService.exportAssetData(input).subscribe(
      (resp: any) => {
        this.getExportStatus(resp.exportID);
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  getExportStatus(id: string) {
    this.billingService.getAssetDataExportStatus(id).subscribe(
      (resp: any) => {
        if (resp.status === 'Completed') {
          const fileId = resp.fileId;
          this.downloadfile(fileId);
        } else if (resp.status === 'Pending' || resp.status === 'In progress') {
          setTimeout(() => {
            this.getExportStatus(id);
          }, 5000);
        }
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  downloadfile(id: string) {
    this.billingService.downloadAssetData(id).subscribe(
      (downloadResp: any) => {
        const contentDisposition = downloadResp.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].split('"').join('');
        this.createDefaultAnchorElement(downloadResp.body, filename);
        this.showLoader = false;
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  createDefaultAnchorElement(data: any, filename?: any) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const csvUrl = URL.createObjectURL(data);
    a.href = csvUrl;
    a.download = filename;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  exportPDFData() {
    const input = {
      invoiceNumber: this.detailedInvoice?.invoiceNumber,
      dealerCode: this.dealerCode,
    };
    this.billingService.exportPDFInvoiceDetails(input).subscribe(
      (downloadResp: any) => {
        this.createDefaultAnchorElementPdf(downloadResp, 'pdf');

        this.showLoader = false;
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  createDefaultAnchorElementPdf(data: any, format: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    let base64str, fileName, type;
    if (format === 'pdf') {
      base64str = data.pdfContent;
      fileName = data.filename;
      type = 'application/pdf';
    } else if (format === 'excel') {
      base64str = data.entity;
      fileName = data.metadata.fileName[0];
      type = data.metadata['content-type'][0];
    }

    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: type });
    const csvUrl = URL.createObjectURL(blob);
    a.href = csvUrl;
    a.download = fileName + this.downloadedFileName;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
  clearSearch() {
    this.searchValue.setValue('');
    // this.invoiceSearchBox.nativeElement.value = '';
    // this.invoiceSearchBox.nativeElement.focus();
    // this.invoiceSearchBox.nativeElement.blur();
  }

  closeSettingPanel = () => {
    this.isSettingVisible = false;
    return false;
  };

  toggleSetting = () => {
    this.isSettingVisible = !this.isSettingVisible;
  };

  billingInquiryForm() {
    const billinginquiry = sessionStorage.getItem('inquiryNumber');

    this.modalRef = this.modal.openModal(BillingInquiryComponent, {
      width: '466px',
      type: 'semi-modal',

      isAutoHeightModalContent: true,
      disableBackdropClick: true,
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
      data: { invoiceNo: billinginquiry },
    });
  }
  tableEvents(event: any) {
    this.adjustTableHeight(event);
    if (event.eventType === 'SORTING') {
      if (
        this.detailedInvoicePayload.sortOrder != event?.event ||
        this.detailedInvoicePayload.sortColumn != event?.data?.name
      ) {
        this.detailedInvoicePayload.sortColumn = event?.data?.name || 'customer';
        this.detailedInvoicePayload.sortOrder = event?.event || 'ASC';
        const searchFlag = this.searchValue?.value.length > 2 ? true : false;
        this.fetchBillingDetails(searchFlag, false);
      }
    } else if (event.eventType === 'PAGE_CHANGE_EVENT' && this.isPageChanged(event)) {
      this.detailedInvoicePayload.pageOffset = event.range?.startIndex || 0;
      this.detailedInvoicePayload.pageLimit = event.range?.pageSize || 20;
      if (this.isRemovePaginationSearch) {
        const searchFlag = this.searchValue?.value.length > 2 && this.searchClicked ? true : false;
        if (this.isMaintainPagination) {
          if (this.detailedInvoicePayload.pageLimit != this.detailedInvoiceTableConfig.pagination.defaultPageSize) {
            const config =
              this.updatedTableConfig.length > 0
                ? this.updatedTableConfig
                : this.detailedInvoiceTableConfig.columnConfig;
            this.tableSettingService.updateTableSettings(this.pageLabel, config, this.detailedInvoicePayload.pageLimit);
            this.detailedInvoiceTableConfig.pagination.defaultPageSize = this.detailedInvoicePayload.pageLimit;
          }
        }
        this.fetchBillingDetails(searchFlag, false);
      } else {
        const searchFlag = this.searchValue?.value.length > 2 ? true : false;
        if (this.isMaintainPagination) {
          if (this.detailedInvoicePayload.pageLimit != this.detailedInvoiceTableConfig.pagination.defaultPageSize) {
            const config =
              this.updatedTableConfig.length > 0
                ? this.updatedTableConfig
                : this.detailedInvoiceTableConfig.columnConfig;
            this.tableSettingService.updateTableSettings(this.pageLabel, config, this.detailedInvoicePayload.pageLimit);
            this.detailedInvoiceTableConfig.pagination.defaultPageSize = this.detailedInvoicePayload.pageLimit;
          }
        }
        this.fetchBillingDetails(searchFlag, false);
      }
    }
  }
  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.detailedInvoicePayload.pageOffset ||
      pageData?.range?.pageSize !== this.detailedInvoicePayload.pageLimit
    );
  }

  createSettingsPanelConfig() {
    if (this.detailedInvoiceTableConfig.columnConfig) {
      const trimmedTableConfig = this.detailedInvoiceTableConfig.columnConfig.map((config: any) => {
        if (config.isFrozen === undefined) {
          config.isFrozen = false;
        }
        return {
          title: config.title ? config.title : '',
          name: config.name ? config.name : '',
          isFrozen: config.isFrozen ? config.isFrozen : false,
          isVisible: config.isVisible ? config.isVisible : false,
          isAlwaysMandatory: config.isAlwaysMandatory ? config.isAlwaysMandatory : false,
        };
      });
      this.tableSettingsPanelConfig = {
        features: trimmedTableConfig,
        visibilityToggle: false,
        ITableSettingsPanelTooltip: {
          columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
        },
      };
    }
    if (this.isMaintainPagination) {
      this.detailedInvoicePayload.pageLimit = this.detailedInvoiceTableConfig.pagination.defaultPageSize;

      this.fetchBillingDetails(false, true);
    }
  }

  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      const originalConfig = JSON.parse(JSON.stringify(this.detailedInvoiceTableConfig));
      this.updatedTableConfig = [];
      event.features.forEach((config, index) => {
        const data = originalConfig.columnConfig.find(
          (dataObj: { name: string }) => config.name.indexOf(dataObj.name) !== -1
        );
        if (data) {
          data.isVisible = config.isVisible;
          data.isFrozen = config.isFrozen;
          data.priority = index + 1;
          data.title = config.title;
          switch (data.title) {
            case 'Asset S/N':
              data.width = 140;
              data.component = DetailedInvoiceComponentAsset;
              break;
            case 'Application':
              data.width = 155;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Customer Name':
              data.width = 175;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Customer ID':
              data.width = 150;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Dealer Customer Number':
              data.width = 210;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Site Name':
              data.width = 150;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Asset Make':
              data.width = 160;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Asset Model':
              data.width = 160;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            case 'Line Item Total':
              data.width = 160;
              data.component = DetailedInvTooltipHighlightComponent;
              break;
            default:
              break;
          }
        }
        this.updatedTableConfig.push(data);
      });
      originalConfig.columnConfig = this.updatedTableConfig;
      this.detailedInvoiceTableConfig = { ...originalConfig };
      const searchFlag = this.searchValue?.value.length > 2 ? true : false;
      this.fetchBillingDetails(searchFlag, false);
      this.updatedTableConfig.forEach((column: any, index: any) => {
        this.updatedTableConfig[index].sortOrder = index;
      });
      if (this.isMaintainPagination) {
        this.tableSettingService.updateTableSettings(
          'billing',
          this.updatedTableConfig,
          this.detailedInvoicePayload.pageLimit
        );
      } else {
        this.tableSettingService.updateTableSettings('billing', this.updatedTableConfig);
      }
      sessionStorage.setItem(
        'detailedInvoiceColumnConfig',
        JSON.stringify(this.detailedInvoiceTableConfig.columnConfig)
      );
      this.updateColumnConfigCustomData();
      this.createSettingsPanelConfig();
      this.cdr.detectChanges();
    }
    this.isCloseEvent = false;
  }

  updateComponent() {
    this.detailedInvoiceTableConfig.columnConfig?.map((data: any) => {
      switch (data.title) {
        case 'Asset S/N':
          data.width = 140;
          data.component = DetailedInvoiceComponentAsset;
          break;
        case 'Application':
          data.width = 155;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Customer Name':
          data.width = 175;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Customer ID':
          data.width = 150;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Dealer Customer Number':
          data.width = 210;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Site Name':
          data.width = 150;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Asset Make':
          data.width = 160;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Asset Model':
          data.width = 160;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        case 'Line Item Total':
          data.width = 160;
          data.component = DetailedInvTooltipHighlightComponent;
          break;
        default:
          break;
      }
    });
  }
  dataLoaded() {
    this.searchDataLoaded = true;
  }
  loadChangeEmit(res: any) {
    if (this.appliedFilters && Object.keys(this.appliedFilters)?.length > 0) {
      // this.clearSearch();
      if (res) {
        this.isTableLoading = true;
      }
    }
    if (res === false) {
      this.isTableLoading = true;
    }
    if (res !== undefined) {
      this.appliedFilters = res;
    }

    if (res && Object.keys(res) && Object.keys(res).length > 0) {
      this.isTableLoading = true;
      // this.clearSearch();
      this.fetchBillingDetails(false, true);
    } else {
      if (res === false || (res != undefined && Object.keys(res) && Object.keys(res).length == 0)) {
        this.fetchBillingDetails(false, true);
      }
    }
  }

  clearFilters() {
    sessionStorage.removeItem('billingFilters');
    sessionStorage.removeItem('billingFilterData');
    sessionStorage.removeItem('billingDateFilters');
    this.commonFilterService.removeItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      this.detailedInvoice.invoiceNumber
    );
    this.commonFilterService.removeItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'filtersDetails',
      this.pageLabel,
      this.detailedInvoice.invoiceNumber
    );
    this.commonFilterService.isFilterSavedEmptySubject.next('true');
  }

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalConfig = JSON.parse(JSON.stringify(this.detailedInvoiceTableConfig));
    let isConfirmationPopupNeeded = false;

    for (let i = 0; event.features?.length > i; i++) {
      const data = originalConfig.columnConfig.find(
        (dataObj: { name: string }) => event?.features[i]?.name === dataObj?.name
      );
      const indexOfOriginalColumnConfig = originalConfig.columnConfig.indexOf(data);
      if (data) {
        if (typeof data.isFrozen === 'undefined') data.isFrozen = false;
        if (
          data.isVisible !== event.features[i].isVisible ||
          data.isFrozen !== event.features[i].isFrozen ||
          indexOfOriginalColumnConfig !== i
        ) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
        showCrossMark: true,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
      };

      this.modalRef1 = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '534px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef1.afterClosed().subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CROSS) {
          this.isCloseEvent = false;
        }
      });
    } else {
      this.modalRef1 = this.modal.openModal(TableSettingsCloseConfirmationComponent, {
        width: '534px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef1.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === false) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else {
          this.isCloseEvent = false;
        }
      });
    }
  }
  convertNumberFormat(number: any, isString: boolean) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, isString);
    }
    return number;
  }
}
