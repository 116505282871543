import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  formattedDate!: string | null;
  dateValues: any;
  splittedArr: any;
  formattedVal: any;

  transform(value: string, ...args: any[]): any {
    const datePipe = new DatePipe('en-US');
    const format = args[0];
    if (value !== '-') {
      this.dateValues = value ? value.split(',') : '';
      if (this.dateValues.length !== 0) {
        if (this.dateValues.length > 1) {
          this.splittedArr = this.dateValues.map((element: any) => {
            this.formattedVal = datePipe.transform(element, format);
            return this.formattedVal;
          });
          return this.splittedArr.join(',');
        } else if (this.dateValues.length === 1) {
          this.dateValues.forEach((element: any) => {
            this.formattedDate = datePipe.transform(element, format);
          });
          return this.formattedDate;
        }
      }
    }
  }
}
