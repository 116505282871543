import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PendingRequestFormData } from '../../self-serve.interface';
import { Subscription } from 'rxjs';
import { AssetService } from '../../../assets/services/asset.service';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { PlanPaymentComponent } from '../../../assets/manage-asset-subscription/payment-preview/plan-payment/plan-payment.component';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { isEmpty } from 'lodash-es';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-pending-request-subscription-details',
  templateUrl: './pending-request-subscription-details.component.html',
  styleUrls: ['./pending-request-subscription-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingRequestSubscriptionDetailsComponent implements OnInit {
  @Input() rowData!: any;
  @Input() pendingRequestFormData!: PendingRequestFormData;
  newSubscriptionData!: any;
  oldSubscriptionData!: any;
  pendingRequestFormSubscription!: Subscription;
  seeMoreOverlayBasicDetails!: any;
  dspHelpUrl: any = '';
  constructor(
    private assetService: AssetService,
    private drawerService: DrawerService,
    private userPreferenceService: UserPreferenceService
  ) {}
  ngOnInit(): void {
    this.dspHelpUrl = environment.redirectURL.helpDocUrl;
    this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
    this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
    this.pendingRequestFormSubscription = this.assetService.pendingRequestFormEvent.subscribe(
      (data: PendingRequestFormData) => {
        this.pendingRequestFormData = data;
        this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
        this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
      }
    );
    this.setSeeMoreDetails();
  }

  setSeeMoreDetails() {
    this.seeMoreOverlayBasicDetails = {
      make: this.rowData?.make ? this.rowData.make : '',
      model: this.rowData?.model ? this.rowData.model : '',
      modelYear: this.rowData?.manufacturingYear ? this.rowData.manufacturingYear : '',
      serialNumber: this.rowData?.serialNumber ? this.rowData.serialNumber : '',
      engineSerialNumber: this.rowData?.engineSerialNo ? this.rowData.engineSerialNo : '',
      reportingState: this.rowData?.reportingState ? this.rowData.reportingState : '',
    };
  }

  openDrawer(subscriptionData: any, paymentType: any) {
    const applicationName = subscriptionData?.applicationName ? subscriptionData.applicationName : '';
    const basePlan = subscriptionData?.basePlan?.name ? subscriptionData.basePlan.name : '';
    this.drawerService.openDrawer<PlanPaymentComponent, CcDrawerConfig>(PlanPaymentComponent, {
      data: {
        rowData:
          paymentType === 'current'
            ? subscriptionData?.paymentPreviewResponse?.current
            : subscriptionData?.paymentPreviewResponse?.new,
        applicationName: applicationName,
        basePlan: basePlan,
        seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
  }

  displayAmount(amount: any) {
    if (amount === 'N/A' || isEmpty(amount)) {
      return '-';
    }
    return this.userPreferenceService.convertNumberFormat(amount, true);
  }

  getConvertedDate(expirationDate: any) {
    return !isEmpty(expirationDate) ? this.userPreferenceService.convertDate(expirationDate) : '';
  }
}
