import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, retry, Subject, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AssetSubscriptionFormDataType } from '../../../models/assets.interface';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { RequestRemoteConnectionCheckPayload } from '../asset-drawer/asset-drawer-interface';
import { AssetsPayload } from '../asset-interface';
import { assetsMultiProductMockData } from '../asset-multiproduct-mock-data';
import * as dspConstants from '../../../shared/dspConstants';
import { PendingRequestFormData } from '../../manage-pending-request/self-serve.interface';
@Injectable({
  providedIn: 'root',
})
export class AssetService {
  rootURL = '';
  dealer: any;
  connectionStatus: Array<any> = [];
  showExportLoader = false;
  downloadCompleted = new Subject();
  resetFilterData: any;
  listOfProducts: any;
  listOfSubsData: any;
  lastUpdatedTime: any;
  minstarSiteNameDisabled: any;
  listOfPricingDetails: any = {
    priceDetails: '',
    onloadData: '',
    newApplicationIntroduceByOtherAppication: '',
  };
  listOfAddmorePlanDetails: any;
  selectPlanMenuDetails: any = {
    addMorePlanClicked: false,
  };
  manageAssetFormGroup: any;
  private _backButtonNotification = new Subject<any>();
  isCancelAll = new Subject<any>();

  backButtonNotification = this._backButtonNotification.asObservable();
  isCustRequestedSubscription: any = false;
  @Output() assetSubscriptionFormEvent = new EventEmitter<AssetSubscriptionFormDataType>();
  @Output() pendingRequestFormEvent = new EventEmitter<PendingRequestFormData>();
  @Output() assetUnsuspensionDetails = new EventEmitter();
  thirdPartySno: any;
  thirdPartyMake: any;

  queryParamInfo: any;
  thirdpartySearchType: any;
  assetSearchTypeCCAT: any;
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.dealer = fetchLoggedInDealerDetails();
    this.resetFilterData = new Subject();
    this.queryParamInfo = new BehaviorSubject({ assetId: '' });
    this.assetSearchTypeCCAT = new BehaviorSubject({});
  }

  setResetFilterData(data: any) {
    this.resetFilterData.next(data);
  }

  getResetFilterData() {
    return this.resetFilterData.asObservable();
  }
  setListOfProduct(data: any) {
    this.listOfProducts = data;
  }
  getListOfProduct() {
    return this.listOfProducts;
  }
  setListOfSubsData(data: any) {
    this.listOfSubsData = data;
  }
  setManageAssetForm(form: any) {
    this.manageAssetFormGroup = form;
  }
  getManageAssetForm() {
    return this.manageAssetFormGroup;
  }
  getListOfSubsData() {
    return this.listOfSubsData;
  }
  setAssetSubscriptionFormData(formData: AssetSubscriptionFormDataType) {
    this.assetSubscriptionFormEvent.emit(formData);
  }

  setPendingRequestFormData(formData: PendingRequestFormData) {
    this.pendingRequestFormEvent.emit(formData);
  }
  setAssetUnsuspensionDetails(formData: any) {
    this.assetUnsuspensionDetails.emit(formData);
  }
  getAssetFilterSet() {
    return this.http.get(this.rootURL + '/assetdata/fetchAssetLandingFilterColumns');
  }
  getZeroPricedAddons(input: any) {
    return this.http.post(this.rootURL + '/pricing/getZeroPricedAddons', input);
  }
  getAssetFilterColumnData(payload: any) {
    return this.http.post(this.rootURL + '/assetsearch/v2/fetchAssetLandingFilters', payload);
  }
  getAssetsCount(inputData: AssetsPayload): Observable<any> {
    return this.http.post(this.rootURL + '/assetdata/v2/fetchDealerFleetCount', inputData);
  }
  getUpdatedTimeStamp(assetId: any) {
    const queryParams = { assetId: assetId };
    return this.http.get(this.rootURL + '/assetdata/getAssetDetails/', { params: queryParams });
  }
  getEdServicesByAsset(payload: any) {
    return this.http.post(this.rootURL + '/equipment/getEdServicesByAsset', payload);
  }

  getCandidateProductId(payload: any) {
    return this.http
      .post(this.rootURL + '/equipments/getCandidateProductId', payload)
      .pipe(retry(1), catchError(this.handleError));
  }
  updateAssetProductId(payload: any) {
    return this.http.post(this.rootURL + '/equipments/validateProductId', payload).pipe(
      retry(1),
      catchError((error) => throwError(() => error))
    );
  }
  assetTransferAPI(dataParams: any) {
    return this.http
      .post(this.rootURL + '/equipments/assettransfer', dataParams)
      .pipe(retry(1), catchError(this.handleError));
  }
  getDealerInformation(dealercode: any) {
    const queryParams = { dealerCode: dealercode };
    return this.http.get(this.rootURL + '/makecodes/dealercode', { params: queryParams });
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = 'Error Code:${error.status}\nMessage: ${error.message}';
    }

    return throwError(() => {
      return errorMessage;
    });
  }
  getAssetsData(inputData: AssetsPayload): Observable<any> {
    return this.http.post(this.rootURL + '/assetdata/v2/fetchDealerFleet', inputData);
  }

  getMultiProductAssetsData(inputData: AssetsPayload): Observable<any> {
    return of(assetsMultiProductMockData);
    //return this.http.post(this.rootURL + '/assetdata/v2/fetchDealerFleet', inputData);
  }

  exportAssetData(inputData: any) {
    return this.http.post(this.rootURL + '/assetdata/dealerFleet/export', inputData);
  }

  exportAssetSearchData(inputSearchDatData: any) {
    return this.http.post(this.rootURL + '/assetsearch/v2/export', inputSearchDatData);
  }

  getAssetDataExportStatus(id: string) {
    return this.http.get(this.rootURL + '/assetdata/export/' + id);
  }

  getAssetDataSubscribedPlans(rowData: any) {
    return this.http.get(
      this.rootURL + `/assetdata/v2/subscribed-plans?make=${rowData.make}&serialNumber=${rowData.serialNumber}`
    );
  }
  getAssetDataSubscribedPlansEdit(rowData: any) {
    return this.http.get(
      this.rootURL + `/assetdata/subscribed-plans?make=${rowData.make}&serialNumber=${rowData.serialNumber}`
    );
  }

  getMockData() {
    return {
      serialNumber: 'AAA03471',
      make: 'CAT',
      assetId: 649996,
      siteId: '346e1b32-b582-4fe3-9762-ac11581b670d',
      siteName: 'Mine_Test',
      productFamily: null,
      servicesSubscribedDTO: [
        {
          appId: 2843,
          appName: 'MineStar',
          prepay: false,
          promotions: [],
          catLevel: [
            {
              planId: 2040,
              planName: 'Terrain for grading on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2000,
              planName: 'Fleet on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2030,
              planName: 'Health only on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          customerLevel: [
            {
              planId: 2040,
              planName: 'Terrain for grading on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2000,
              planName: 'Fleet on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2030,
              planName: 'Health only on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          dealerLevel: [
            {
              planId: 2030,
              planName: 'Health only on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2040,
              planName: 'Terrain for grading on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2000,
              planName: 'Fleet on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2030,
              planName: 'Health only on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
            {
              planId: 2030,
              planName: 'Health only on-board',
              subscriptionStartDate: 1728691200000,
              type: 'Addon',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          contractStartDate: '10/16/2024',
          contractEndDate: '10/16/2024',
          orginalStartDate: 1721651032724,
          selectedOrder: 1,
        },
        {
          appId: 2848,
          appName: 'Equipment Insights',
          prepay: true,
          promotions: [
            {
              id: 1244987,
              assetId: null,
              application: 'Equipment Insights',
              product: null,
              ucid: null,
              dealerCode: null,
              promoName: 'HEI 2024 New Subscriptions 12 months discount_Base_QA',
              discount: 30.8,
              startDate: 1723161600000,
              endDate: 1735689600000,
              type: null,
              currency: null,
              subscriptionStartDate: null,
              createdTimestamp: null,
              assetSubscription: null,
            },
            {
              id: 1244988,
              assetId: null,
              application: 'Equipment Insights',
              product: null,
              ucid: null,
              dealerCode: null,
              promoName: 'EQUIP INSIGH QA TEST 2024',
              discount: 123.2,
              startDate: 1723161600000,
              endDate: 1724198400000,
              type: null,
              currency: null,
              subscriptionStartDate: null,
              createdTimestamp: null,
              assetSubscription: null,
            },
          ],
          catLevel: [
            {
              planId: 6010,
              planName: 'Equipment Insights - Cat Cellular',
              subscriptionStartDate: 1723161600000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          customerLevel: [
            {
              planId: 6010,
              planName: 'Equipment Insights - Cat Cellular',
              subscriptionStartDate: 1723161600000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          dealerLevel: [
            {
              planId: 6010,
              planName: 'Equipment Insights - Cat Cellular',
              subscriptionStartDate: 1723161600000,
              type: 'Base',
              subscriptionEndDate: null,
              addonPlans: null,
              status: 'Active',
            },
          ],
          contractStartDate: '10/16/2024',
          contractEndDate: '10/16/2024',
          orginalStartDate: 1723179121146,
          selectedOrder: 2,
        },
      ],
    };
  }

  downloadAssetData(fileId: string) {
    return this.http.get(this.rootURL + '/assetdata/download/' + fileId, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getConnectionCheck(inputData: RequestRemoteConnectionCheckPayload) {
    return this.http.post(this.rootURL + '/equipment/requestRemoteConnectionCheck', inputData);
  }

  getConnectionCheckStatus(inputData: RequestRemoteConnectionCheckPayload) {
    return this.http.post(this.rootURL + '/equipment/requestRemoteConnectionCheckStatus', inputData);
  }
  getErouteMakeCode(): Observable<any> {
    return this.http.get(this.rootURL + '/eRoute/getErouteMakeCode');
  }

  saveErouteSubs(input: any): Observable<any> {
    return this.http.post(this.rootURL + '/eRoute/saveErouteSubs', input);
  }
  getSitesById(payload: object) {
    return this.http.post(this.rootURL + '/site/getSitesById', payload);
  }

  getSitesForWorkList(payload: object) {
    return this.http.post(this.rootURL + '/site/getSitesForWorkList', payload);
  }

  getDynamicPricingDetails(payload: object) {
    return this.http.post(this.rootURL + '/subscribe/getPricingDetails', payload);
  }
  getSubscriptionServiceMapping() {
    return this.http.get(this.rootURL + '/subscribe/subscriptionservicemap');
  }
  getBillDirectCustomers(payload: any) {
    return this.http.post(
      this.rootURL + `/zuoraBilling/billToDirectCustomers?dealerCode=${this.dealer.dealerCode}`,
      payload
    );
  }

  getPromotions(payload: any) {
    return this.http.post(this.rootURL + '/zuoraPreview/getPromotionName', payload);
  }

  getFeaturesToggleMapping() {
    return this.http.get(this.rootURL + '/user/featuresMap');
  }

  getAccounts() {
    return this.http.get(this.rootURL + '/user/getMyBillingAccounts');
  }

  getMetaData() {
    return this.http.get(this.rootURL + '/Wifi/configuration/metaData');
  }

  getWiFiConfigurationData(input: any) {
    return this.http.get(
      this.rootURL + '/Wifi/configuration?make=' + input.make + '&serialNumber=' + input.serialNumber
    );
  }

  insertConfiguration(input: any) {
    return this.http.post(this.rootURL + '/Wifi/configuration', input);
  }

  deleteConfiguration(input: any) {
    return this.http.post(this.rootURL + '/Wifi/deleteWifiInfo', input);
  }
  unsuspendAsset(input: any) {
    return this.http.post(this.rootURL + '/unsuspend/asset', input);
  }
  subscribeAsset(payload: any) {
    return this.http.post(this.rootURL + '/subscribe/subscription', payload);
  }
  subscribeMultiProdAsset(payload: any) {
    return this.http.post(this.rootURL + '/subscribe/subscription', payload);
  }
  validateSubscriptionSet(payload: any) {
    return this.http.post(this.rootURL + '/subscribe/manageplans/validation', payload);
  }
  validateMultiProdSubscriptionSet(payload: any) {
    return this.http.post(this.rootURL + '/subscribe/planandproducts/validation', payload);
  }

  fetchEC250SerailNumber(payload: any) {
    return this.http.post(this.rootURL + '/equipments/fetchEc520EcmSerialNumber', payload);
  }

  getConnectionStatus() {
    return this.connectionStatus;
  }

  setConnectionStatus(status: Array<any>) {
    this.connectionStatus.push(status);
  }

  getMakeCode(queryParams: any) {
    return this.http.post(this.rootURL + '/makecodes/getMakeCodes', queryParams);
  }

  backButtonPress(data: any) {
    if (data) {
      this._backButtonNotification.next(data);
    }
  }
  searchAssets(payload: any, isSearchWithContains: boolean): Observable<any> {
    const endpoint = isSearchWithContains ? '/assetsearch/v3/search' : '/assetsearch/v2/search';
    return this.http.post(this.rootURL + endpoint, payload);
  }

  searchMultiproductAssets(payload: any): Observable<any> {
    return of(assetsMultiProductMockData);
  }

  searchAssetsCount(payload: any, isSearchWithContains: any): Observable<any> {
    const endpoint = isSearchWithContains ? '/assetsearch/v3/getassetsearchcount' : '/assetsearch/getassetsearchcount';
    return this.http.post(this.rootURL + endpoint, payload);
  }

  getAssetAgreement(assetid: any) {
    return this.http.get(this.rootURL + `/assetdata/getAgreements/${assetid}`);
  }

  saveAssetAgreement(payload: any) {
    return this.http.post(this.rootURL + '/assetdata/saveAgreement', payload);
  }

  updateReportType(payload: any) {
    return this.http.post(this.rootURL + '/device/updateReportType', payload);
  }
  validateOwnershipSync(activeFeatures: any, assetData: any, enableOwnershipSync: any) {
    if (
      activeFeatures &&
      activeFeatures.indexOf('Subscription Management') > -1 &&
      (activeFeatures.indexOf('Read Only') > -1 ||
        activeFeatures.indexOf('View Only') > -1 ||
        activeFeatures.indexOf('Read and Write') > -1 ||
        activeFeatures.indexOf('Manage - All Plans') > -1 ||
        activeFeatures.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1) &&
      (assetData.reportingState === dspConstants.Worklist.UNSUBSCRIBED ||
        assetData.reportingState === dspConstants.Worklist.SUSPENDED) &&
      assetData.showSyncBtn &&
      enableOwnershipSync
    ) {
      return true;
    } else {
      return false;
    }
  }

  syncOwnershipDATStatus(payload: any) {
    return this.http.post(this.rootURL + '/assetdata/syncOwnershipDatStatus', payload);
  }
  convertTimeStampToData(dateInMs: any, isTime?: any, isChangeFormat?: any) {
    const dateObj = new Date(dateInMs);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    if (isTime) {
      const hours = String(dateObj.getUTCHours()).padStart(2, '0');
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
      formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    if (isChangeFormat) {
      formattedDate = `${year}/${month}/${day}`;
    }
    return formattedDate;
  }
  getThirdPartySerialNumber() {
    return this.thirdPartySno;
  }
  setThirdPartySerialNumber(value: any) {
    this.thirdPartySno = value;
  }
  getThirdPartyMake() {
    return this.thirdPartyMake;
  }
  setThirdPartymake(value: any) {
    this.thirdPartyMake = value;
  }
  getSearchType() {
    return this.thirdpartySearchType;
  }
  setSearchType(value: any) {
    this.thirdpartySearchType = value;
  }
  checkIfReasonListExists(queueReasonList: any, assetQueueReasonLists: any) {
    if (queueReasonList?.length && assetQueueReasonLists?.length) {
      this.isCustRequestedSubscription = false;
      queueReasonList?.map((lists: any) => {
        assetQueueReasonLists?.map((reasonLists: any) => {
          if (reasonLists.includes(lists)) {
            this.isCustRequestedSubscription = true;
          }
        });
      });
      return this.isCustRequestedSubscription;
    }
  }

  getDATExcludedProducts() {
    return this.http.get(this.rootURL + '/subscribe/getDATExcludedProducts');
  }
}
