<div class="cc-drawer parameter-config">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis parameter-header"> PL444 Parameter Configuration Upload </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <dsp-next-gen-ui-config-info [assetData]="assetData"></dsp-next-gen-ui-config-info>
    <div class="chips-group">
      <h3 class="lighter-weight">Select Configuration File</h3>
      <div class="chips-align">
        <cc-list>
          <cc-list-item *ngFor="let config of configFiles">
            <div class="chip-flex-item">
              <span
                id="chip32"
                cc-chip="{{ config.fileName }}"
                class="fileChip"
                type="check"
                (selected)="selectedFile($event, config)"
                [radio]="true"
                [singleSelect]="true"
                state="{{ config.selected ? 'active' : 'default' }}"
              ></span>
            </div>
          </cc-list-item>
        </cc-list>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer parameter-footer">
    <button
      cc-btn-secondary
      cc-btn-medium
      [setTheme]="true"
      class="cancelBtn"
      (click)="closeDrawer()"
    >
      Cancel
    </button>
    <button
      cc-btn-primary
      cc-btn-medium
      [setTheme]="true"
      class="uploadBtn"
      (click)="openUploadFileConfigModal(configUploadModalTemplate)"
      [disabled]="!configSelected"
    >
      Upload and Send
    </button>
  </div>
</div>
<ng-template #configUploadModalTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirm</div>
    </div>

    <div class="cc-modal-content group-info__content">
      <p>
        This action will result in updating the configuration file on the device. Please confirm to
        proceed.
      </p>
    </div>

    <div class="cc-modal-footer group-info__footer">
      <button
        cc-btn-secondary
        cc-btn-large
        [setTheme]="true"
        class="cancel button-opacity"
        (click)="closeUploadFileConfigModal(false)"
      >
        Cancel
      </button>

      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        class="delete button-opacity"
        (click)="closeUploadFileConfigModal(true)"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
