<div class="report-type-drawer cc-drawer">
  <div class="cc-drawer-header">
    <div class="cc-drawer-title">PLCN1 Report Type</div>
    <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeReportTypeModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
  <div class="cc-drawer__content cc-drawer-content">
    <h3 class="heading2">Select Report Type</h3>
    <cc-radio-group (valueChange)="valueChange($event)">
      <cc-radio-button
        [ngClass]="reportType === option ? 'darkMode' : ''"
        class="radio-buttons"
        *ngFor="let option of reportTypeOption"
        [value]="option"
        [checked]="reportType === option"
        >{{ option }}
      </cc-radio-button>
    </cc-radio-group>
  </div>
  <div class="cc-drawer__footer cc-drawer-footer">
    <button
      cc-btn-secondary
      cc-btn-large
      style="width: 100% !important"
      [setTheme]="true"
      (click)="closeReportTypeModal()"
    >
      Cancel
    </button>
    <button
      cc-btn-primary
      cc-btn-large
      style="width: 100% !important"
      [disabled]="enableSubmit()"
      [setTheme]="true"
      (click)="saveChanges()"
    >
      Submit
    </button>
  </div>
</div>
