<div class="privacy-page-content">
  <p><strong>Privacy Notice for the Dealer Services Portal Website</strong></p>
  <p><strong>Last Revised:</strong> January 18, 2019</p>
  <p>
    This privacy notice describes how Caterpillar Inc. collects and processes personal information through the Dealer
    Services Portal website. This notice is consistent with Caterpillar’s Global Data Privacy Statement, which describes
    how Caterpillar collects, processes and shares personal data, rights that you may have under privacy laws, and other
    information relevant to Caterpillar’s processing of personal information. Caterpillar’s Global Data Privacy
    Statement is available at
    <a href="http://www.caterpillar.com/dataprivacy" target="_blank">http://www.caterpillar.com/dataprivacy.</a>
  </p>
  <p><strong>What personal information does this website collect and process, and why?</strong></p>
  <p class="txtUnderLine">User-Provided Information</p>
  <p>
    This website provides a portal through which Cat dealers may manage customer subscriptions for various digital
    services. The website collects and stores the following personal information to record the identity of the user
    logging into the application, as well as to enable the user to submit an inquiry to Caterpillar and to enable
    Caterpillar to investigate and respond to such inquiries:
  </p>
  <ul>
    <li>Corporate Web Security (CWS) ID</li>
    <li>Name</li>
    <li>E-mail Address</li>
  </ul>
  <p>
    We also collect personal information that you voluntarily provide to this website. For example, if you e-mail us
    through the "Submit Billing Inquiry" screen, we will ask for the applicable invoice number, your e-mail address,
    contact phone number, and contact preferences (you may choose to provide additional information as well) so that we
    can respond to your questions or comments.
  </p>
  <p class="txtUnderLine">Automatically Collected Information & Tracking</p>
  <p>
    When you visit this website, it automatically collects your Internet Protocol ("IP") address and other technical
    information provided by your browser (e.g., your browser software, what site referred you to this website, etc.).
  </p>
  <p>
    Additionally, this website uses cookies and other tracking technologies as described in the Cookie Statement
    available
    <a
      class="ot-sdk-show-settings"
      ngClass="cookieslink"
      role="button"
      tabindex="0"
      onclick="window.OneTrust.ToggleInfoDisplay()"
      >here.</a
    >
  </p>
  <p class="txtUnderLine">Do Not Track Signals</p>
  <p>
    At this time, this website does not respond to "Do Not Track" signals that may be sent by your browser. We may
    revisit this as standards develop on how such signals should be interpreted and applied.
  </p>
  <p>
    <strong>Where can I obtain further information about this Website and its privacy practices?</strong>
  </p>
  <p>
    For additional information on this website and its privacy practices you may contact us at
    <a href="mailto:CatDigitalSupport@cat.com" target="_blank">CatDigitalSupport&#64;cat.com.</a>
  </p>
  <p>
    For additional corporate contact information, please see the Caterpillar Global Data Privacy Statement at
    <a href="http://www.caterpillar.com/dataprivacy" target="_blank">http://www.caterpillar.com/dataprivacy.</a>
  </p>
  <p><strong>What happens if this privacy notice changes?</strong></p>
  <p>
    We reserve the right to amend this privacy notice. If we update or change this privacy notice, the changes will be
    made on this page. Your use of this website following the posting of changes to this privacy notice will demonstrate
    your acceptance of those changes.
  </p>
</div>
