import { Component } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent  {
  

}
