<div class="payment-preview-section">
  <dsp-next-gen-ui-pending-request-asset-details [rowData]="rowData" [pendingRequestFormData]="pendingRequestFormData">
  </dsp-next-gen-ui-pending-request-asset-details>

  <div *ngIf="loader">
    <div class="spin-align">
      <div class="spinner"></div>
    </div>
    <div class="spin-text">
      <span>One moment. We are calculating your<br />plan charges...</span>
    </div>
  </div>
  <div *ngIf="!loader && oldSubscriptionData && oldSubscriptionData.length">
    <div>
      <div class="section-line">
        <span class="section-heading new"> Current </span>
      </div>
    </div>
    <div class="accordion" *ngFor="let subscriptionData of oldSubscriptionData">
      <cc-accordion [multi]="true">
        <cc-expandable [expanded]="true">
          <cc-expandable-header>
            <div class="application-name">
              <span>{{ subscriptionData?.applicationName }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>
                <!-- <div class="CellHead" *ngIf="currentProductFamily">
                  <div class="headerCell">Product Family</div>
                </div> -->
                <div class="CellHead">
                  <div class="headerCell">Promotion Expiration Date</div>
                </div>
                <div class="CellHead priceCell" *ngIf="subscriptionData.prepay">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead priceCell" *ngIf="subscriptionData.prepay">
                  <div class="headerCell">Contract End Date</div>
                </div>
                <!-- <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract End Date</div>
                </div> -->
                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>
              <div class="Row" *ngIf="subscriptionData?.basePlan?.name">
                <div class="Cell">
                  <div class="total-plan">{{ subscriptionData.basePlan.name }}</div>
                </div>
                <!-- <div class="Cell" *ngIf="currentProductFamily && i === 1">
                  <div class="total-plan1">{{ currentProductFamily }}</div>
                </div> -->

                <div class="Cell" [class.align-promotion-text]="!subscriptionData.prepay"></div>

                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractStartDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractStartDate) }}</div>
                </div>
                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractEndDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractEndDate) }}</div>
                </div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(subscriptionData.basePlan.amount) }}</div>
                </div>
              </div>
              <div class="Row" *ngFor="let plan of subscriptionData.optionalPlan?.slice(0, 1) || []; let i = index">
                <div class="Cell">
                  <div class="total-plan">{{ plan.name }}</div>
                </div>
                <!-- <div class="Cell" *ngIf="currentProductFamily && i === 1">
                  <div class="total-plan1">{{ currentProductFamily }}</div>
                </div> -->

                <div class="Cell" [class.align-promotion-text]="!subscriptionData.prepay"></div>
                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractStartDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractStartDate) }}</div>
                </div>
                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractEndDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractEndDate) }}</div>
                </div>
                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(plan.amount) }}</div>
                </div>
              </div>
              <div *ngIf="subscriptionData?.optionalPlan?.length > 1" class="Cell">
                <a class="link total-plan-link" (click)="openDrawer(subscriptionData, 'current')"> See more </a>
              </div>
              <div class="Table" *ngIf="subscriptionData?.promotion?.length > 0">
                <div class="Row promotion-apply" *ngFor="let promo of subscriptionData.promotion">
                  <div class="Cell">
                    <div class="total-plan">{{ promo.name }}</div>
                  </div>
                  <div class="Cell" [class.align-promotion-text]="true">
                    <div>{{ getConvertedDate(promo.end_date) }}</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">{{ displayAmount(promo.amount) }}</div>
                  </div>
                </div>
              </div>
              <div class="Table total-section">
                <div class="Row cancel-plan-total">
                  <div class="Cell">
                    <div class="est-total">ESTIMATED TOTAL</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-price">
                      {{ displayAmount(subscriptionData.monthlyRateAfterDiscount) }}
                      <span>
                        {{
                          displayAmount(subscriptionData.monthlyRateAfterDiscount) != '-'
                            ? subscriptionData.prepay
                              ? '(Prepaid)'
                              : '(Monthly)'
                            : ''
                        }}
                      </span>
                      <!-- <span
                        *ngIf="
                          currentPaymentDetails.prepay &&
                          displayAmount(currentPaymentDetails?.new?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Prepaid)</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
  </div>
  <div *ngIf="!loader && newSubscriptionData">
    <div>
      <div class="section-line">
        <span class="section-heading new"> New </span>
      </div>
    </div>
    <div class="accordion">
      <cc-accordion [multi]="true">
        <cc-expandable [expanded]="true">
          <cc-expandable-header>
            <div class="application-name">
              <span>{{ newSubscriptionData?.applicationName }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>
                <!-- <div class="CellHead" *ngIf="currentProductFamily">
                  <div class="headerCell">Product Family</div>
                </div> -->
                <div class="CellHead">
                  <div class="headerCell">Promotion Expiration Date</div>
                </div>
                <!-- <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead" *ngIf="isCurrentProductVision">
                  <div class="headerCell">Contract End Date</div>
                </div> -->
                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>
              <div class="Row">
                <div class="Cell">
                  <div class="total-plan">{{ newSubscriptionData.basePlan.name }}</div>
                </div>
                <!-- <div class="Cell" *ngIf="currentProductFamily && i === 1">
                  <div class="total-plan1">{{ currentProductFamily }}</div>
                </div> -->

                <div class="Cell" [class.align-promotion-text]="true"></div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(newSubscriptionData.basePlan.amount) }}</div>
                </div>
              </div>
              <div class="Row" *ngFor="let plan of newSubscriptionData.optionalPlan?.slice(0, 1) || []; let i = index">
                <div class="Cell">
                  <div class="total-plan">{{ plan.name }}</div>
                </div>
                <!-- <div class="Cell" *ngIf="currentProductFamily && i === 1">
                  <div class="total-plan1">{{ currentProductFamily }}</div>
                </div> -->

                <div class="Cell" [class.align-promotion-text]="true"></div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(plan.amount) }}</div>
                </div>
              </div>
              <div *ngIf="newSubscriptionData?.optionalPlan?.length > 1" class="Cell">
                <a class="link total-plan-link" (click)="openDrawer(newSubscriptionData, 'new')"> See more </a>
              </div>
              <div class="Table" *ngIf="newSubscriptionData?.promotion?.length > 0">
                <div class="Row promotion-apply" *ngFor="let promo of newSubscriptionData.promotion">
                  <div class="Cell">
                    <div class="total-plan">{{ promo.name }}</div>
                  </div>
                  <div class="Cell" [class.align-promotion-text]="true">
                    <div>{{ getConvertedDate(promo.end_date) }}</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-plan">{{ displayAmount(promo.amount) }}</div>
                  </div>
                </div>
              </div>
              <div class="Table total-section">
                <div class="Row cancel-plan-total">
                  <div class="Cell">
                    <div class="est-total">ESTIMATED TOTAL</div>
                  </div>
                  <div class="Cell priceCell">
                    <div class="total-price">
                      {{ displayAmount(newSubscriptionData.monthlyRateAfterDiscount) }}
                      <span>
                        {{
                          displayAmount(newSubscriptionData.monthlyRateAfterDiscount) !== '-'
                            ? newSubscriptionData.prepay
                              ? '(Prepaid)'
                              : '(Monthly)'
                            : ''
                        }}
                      </span>
                      <!-- <span
                        *ngIf="
                          currentPaymentDetails.prepay &&
                          displayAmount(currentPaymentDetails?.new?.monthlyRateAfterDiscount) !== '-'
                        "
                      >
                        (Prepaid)</span
                      > -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
    <div class="cc-grid payment-footer-container" *ngIf="newSubscriptionData">
      <div
        class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 payment-preview-footer-message"
      >
        <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
        <div id="footer-message" class="colorChange" [innerHTML]="getFooterMessage()"></div>
      </div>
    </div>
  </div>
</div>
