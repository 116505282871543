import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DrawerService, CcDrawerConfig, CcDrawerSize } from '@cat-digital-workspace/shared-ui-core/drawer';
import { DashboardService } from '../services/dashboard.service';
import { filter, forkJoin } from 'rxjs';
import { SubAssetTrendFilterComponent } from './filter/sub-asset-trend-filter/sub-asset-trend-filter.component';
import { Line_Chart_Data } from './sub-asset-trend.interface';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { ExpandedViewSubAssetTrendComponent } from './expanded-view-sub-asset-trend/expanded-view-sub-asset-trend.component';
import { Router } from '@angular/router';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { OneTrustService } from '../../../services/one-trust/one-trust.service';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import { setChart1000FilterItem, setInitialTableItem } from '../actions/dashboard-needs-review.actions';
import { chart } from 'highcharts';
import { FilterCloseDashboardComponent } from '../../filter-close-dashboard/filter-close-dashboard.component';

@Component({
  selector: 'dsp-next-gen-ui-subscribed-asset-trend',
  templateUrl: './subscribed-asset-trend.component.html',
  styleUrls: ['./subscribed-asset-trend.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubscribedAssetTrendComponent implements OnInit {
  dealer: any;
  filterContentsRef: any;
  savedFilters: any;
  map = new Map<string, Number>();
  selectedSubscribedAssetFilters: any = {};
  chartColorInit = [
    { color: '#257EE7', label: 'No. of subscribed assets ' },
    { color: '#E8847E', label: 'Equipment Insights' },
    { color: '#1AC69E', label: 'eRoutes' },
    { color: '#8E25FF', label: 'VisionLink' },

    { color: '#E8847E', label: 'Cat Remote Fleet Vision' },
    { color: '#1AC69E', label: 'Marine Concierge Plus' },
    { color: '#8E25FF', label: 'MineStar' },

    { color: '#E8847E', label: 'My.Cat.Com' },
    { color: '#1AC69E', label: 'New VisionLink' },
    { color: '#8E25FF', label: 'Remote Asset Monitoring' },

    { color: '#E8847E', label: 'REMOTE SERVICES' },
    { color: '#1AC69E', label: 'VIMS Transmitter' },
    { color: '#8E25FF', label: 'Full Fleet' },
  ];

  //[('#257EE7', '#6495ED', '#DE3163', '#FFFF00')];
  chartColor = this.getChartColor();
  chart100OriginalData = {
    series: [
      {
        name: '',
        data: [],
      },
      // {
      //   name: 'No. of subscribed assetts',
      //   data: [7500, 4300, 3500, 3200, 4200, 1000, 7500, 4300, 3500, 3200, 4000, 10000],
      // },
      // {
      //   name: 'Equipment Insights',
      //   data: [3500, 5300, 6500, 2200, 5200, 3000, 3500, 5300, 6500, 2200, 5200, 3000],
      // },
      // {
      //   name: 'VisionLink',
      //   data: [1500, 1399, 2500, 1200, 1200, 2000, 1500, 2300, 4500, 2200, 6000, 9000],
      // },
      // {
      //   name: 'eRoutes',
      //   data: [2500, 1300, 2500, 1200, 1200, 2000, 1500, 1300, 4500, 2200, 4200, 3000],
      // },
    ],
  };

  subAssetTrendTableConfig = {
    title: '',
    height: window.devicePixelRatio === 1 ? '430' : '430',

    xAxis: {
      title: '',
      categories: [''],
    },
    yAxis: {
      title: '',
      min: 0,
    },

    tooltip: {
      enabled: true,
      padding: 5,
      shared: true,
      useHTML: true,
      enableHeader: true,
      tooltipseperator: ' |',
      //valueSuffix: 'Hrs',
      enableSort: true,
      descendingSort: true,
    },
    dataLabels: false,

    scrollbar: {
      max_Axis: 5,
      scrollButtonHide: true,
    },
    marginTop: 30,
    marker: {
      enabled: true,
      fill: false,
      pointEnabled: true,
    },
  };
  chartResponsiveConfig = {
    type: 'line',
    legendPlacement: 'top',
    height: 450,
  };
  staticMonthArray = [
    { Jan: 'January' },
    { Feb: 'February' },
    { Mar: 'March' },
    { Apr: 'April' },
    { May: 'May' },
    { Jun: 'June' },
    { Jun: 'July' },
    { Aug: 'August' },
    { Sep: 'September' },
    { Oct: 'October' },
    { Nov: 'November' },
    { Dec: 'December' },
  ];
  //ProductColumnData: Array<string> = [];
  tempArray: any = [];
  rangeList: Array<string> = [];
  productList: Array<string> = [];
  monthList: Array<string> = [];
  dspStoreData!: any;

  isShowSubscribeFilterOnExpand = false;
  //data: Array<string> = [];
  filterApplied = false;
  defaultsubAssetValues: Array<Object> = [];
  defaultXAxisValue: Array<string> = [];
  tempXAxisValue: Array<string> = [];
  FilteredMonthArray: Array<string> = [];
  FilteredRange: any;
  xAxisLabel: any;
  yAxisLabel: any;
  subscribedAssetTrend: any = [];
  subscribedAssetCount: any = [];
  filteredSubscribedAssetCount: any = [];
  rangeCount: any = [];
  minValue: any;
  maxValue: any;
  startValue: any;
  endValue: any;
  savedMonthFilter = false;
  monthArr: any = [];
  dupArr: any = [];

  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  chartSpinner: any;
  showFilterIconOnNoData = false;
  response: any;
  filteredAssetsAPIPayload = {
    dealerCode: this.dealerCode,
    filterList: {},
  };
  subscribedAssetTrendPayload = {
    dealerCode: this.dealerCode || '',
    filterList: [],
  };
  isZero = false;
  noData = false;
  isSubscribeAssetTreandProduct: any;
  dupArrProduct: any;
  selectedFilter: any;
  savedConfig: any;
  restoreFilter: any;
  savedChartData: any;
  filterIcon = false;
  isFullScreenOpened = false;
  modalRef: any;
  constructor(
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    private drawerService: DrawerService,
    public modal: CcModal,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private oneTrustService: OneTrustService,
    private store: Store<DSPAppState>,
    private dspUtilsCommonService: DspUtilsCommonService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isSubscribeAssetTreandProduct = this.dspStoreData?.featureFlagInfo?.isSubscribeAssetTreandProduct
        ? this.dspStoreData?.featureFlagInfo?.isSubscribeAssetTreandProduct
        : false;
      this.isShowSubscribeFilterOnExpand = this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        ? this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        : false;
    });

    this.store.select('subsTrendsavedFilterData').subscribe((x) => {
      this.selectedFilter = x.filterData.subsTrendsavedFilterData;
      if (this.selectedFilter?.month?.length > 0 || this.selectedFilter?.application?.length > 0) {
        this.filterIcon = true;
      } else {
        this.filterIcon = false;
      }
      this.cdr.detectChanges();
    });

    if (this.selectedFilter?.month?.length > 0 || this.selectedFilter?.application?.length > 0) {
      const savedRange = this.dspUtilsCommonService.getValue('rangeCount');
      this.rangeCount = savedRange ? JSON.parse(savedRange) : [];
      this.subscribedAssetCount = this.rangeCount;
      if (this.selectedFilter?.month?.length == 0 && this.selectedFilter?.application?.length! == 0) {
        this.mappingChartData(this.selectedFilter);
      } else if (this.selectedFilter?.month?.length != 0 && this.selectedFilter?.application?.length == 0) {
        this.chartSpinner = true;
        this.getSubscriptionAssetTrend1(this.selectedFilter?.month);
      } else {
        this.mappingChartData(this.selectedFilter);
      }
      this.setFilterData();

      const savedProduct = this.dspUtilsCommonService.getValue('productList');
      // this.productList = [];
      this.productList = savedProduct ? JSON.parse(savedProduct) : [];
      const savedMonth = this.dspUtilsCommonService.getValue('monthList');
      // this.monthList = [];
      this.monthList = savedMonth ? JSON.parse(savedMonth) : [];
    } else {
      this.getInitialApiCalls();
    }
    if (this.isShowSubscribeFilterOnExpand) {
      this.dashboardService.openSubscribeTrendClicked.subscribe((data: any) => {
        if (data && this.isFullScreenOpened) {
          this.openFilter('withActions');
        }
      });
    }
  }
  setFilterData() {
    this.rangeCount = [];
    const savedRange = this.dspUtilsCommonService.getValue('rangeCount');
    this.rangeCount = savedRange ? JSON.parse(savedRange) : [];
    this.calculateRangeValue();
  }
  calculateRangeValue() {
    this.minValue = Math.min(...this.rangeCount);
    this.maxValue = Math.max(...this.rangeCount);
    this.startValue = this.getStartRange(this.minValue);
    this.endValue = this.getEndRange(this.maxValue);
    this.incrementValue(this.startValue, this.endValue);
    this.setRangeList();
  }
  getInitialApiCalls() {
    this.chartSpinner = true;
    forkJoin([
      this.dashboardService.getTrendWidgetDetails(
        this.dealerCode
      ) /**API Call To get the Xaxis label,Yaxis label and product list */,
      this.dashboardService.getSubscriptionAssetTrend(
        this.subscribedAssetTrendPayload
      ) /** API CALL to get the X axis value (eg:mar,apr,may) and count of subscribed assets  */,
    ]).subscribe(
      ([widgetData, subscriptionData]) => {
        this.chartSpinner = false;
        this.response = Object.values(widgetData)[0];
        this.xAxisLabel = this.response.xaxis;
        this.yAxisLabel = this.response.yaxis;
        const labelObj = {
          xAxisLabel: this.xAxisLabel,
          yAxisLabel: this.yAxisLabel,
        };
        this.dspUtilsCommonService.setValue(JSON.stringify(labelObj), 'lineChartLabels');
        if (this.response.filters && this.response.filters.length > 0) {
          this.response.filters.forEach((data: any) => {
            data.values.forEach((ele: any) => {
              this.productList.push(ele.value); /**Product list */
              this.dspUtilsCommonService.setValue(JSON.stringify(this.productList), 'productList');
            });
          });
        }
        if (subscriptionData && Object.keys(subscriptionData) && Object.keys(subscriptionData).length > 0) {
          this.subscribedAssetTrend = Object.values(subscriptionData)[0];
          this.subscribedAssetTrend.map((item: any) => {
            this.defaultXAxisValue.push(item.label);
            this.tempXAxisValue.push(item.label); /**assiging xaxis vlaue to the chart */
            this.map.set(item.label, Number(item.value));
            const obj = {
              label: item.label,
              value: item.value,
            };
            this.defaultsubAssetValues.push(obj);
            this.subscribedAssetCount.push(Number(item.value));
            if (Number(item.value) === 0) {
              this.isZero = true;
            } else {
              this.isZero = false;
            }
            this.rangeCount.push(Number(item.value));
            this.dspUtilsCommonService.setValue(JSON.stringify(this.rangeCount), 'rangeCount');

            this.staticMonthArray.forEach((ele: any) => {
              if (Object.keys(ele) == item.label) {
                this.monthList.push(String(Object.values(ele)[0]));
              }
            });
            this.dspUtilsCommonService.setValue(JSON.stringify(this.monthList), 'monthList');
          });
          if (this.isZero) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          this.dspUtilsCommonService.setValue(JSON.stringify(this.defaultsubAssetValues), 'defaultLineChartValues');
          this.calculateRangeValue();
          const chart_data: Line_Chart_Data = {
            name: '',
            data: [],
          };

          chart_data.name = this.yAxisLabel;
          chart_data.data = this.subscribedAssetCount;
          this.mappingDefaultSubscribedAssetCountValues(this.xAxisLabel, this.defaultXAxisValue, chart_data);
        } else {
          this.showFilterIconOnNoData = false;
        }
      },
      (error: any) => {
        this.chartSpinner = false;
        this.showFilterIconOnNoData = false;
      }
    );
  }
  mappingDefaultSubscribedAssetCountValues(xAxisLabel: any, defaultXAxisValue: any, chart_data: any) {
    const xAxis = {
      title: xAxisLabel,
      categories: defaultXAxisValue,
    };
    const yAxis = {
      title: this.yAxisLabel,
      min: 0,
    };

    this.subAssetTrendTableConfig = { ...this.subAssetTrendTableConfig, xAxis: xAxis, yAxis: yAxis };
    this.chartColor = this.getChartColor();
    this.chart100OriginalData.series[0] = chart_data;
    this.store.dispatch(
      setChart1000FilterItem({
        chartData: {
          chart1000FilterData: this.chart100OriginalData.series,
        },
      })
    );
    this.store.dispatch(
      setInitialTableItem({
        subsasst: {
          subAssetTrendTableConfiguration: this.subAssetTrendTableConfig,
        },
      })
    );
    // this.filterIcon = false;
    // sessionStorage.setItem('subAssetTrendTableConfig', JSON.stringify(this.subAssetTrendTableConfig));
    // sessionStorage.setItem('chart100OriginalData', JSON.stringify(this.chart100OriginalData));
    if (!chart_data.data.filter((val: any) => val > 0).length) {
      this.subscribedAssetTrend = [];
    }
  }
  getChartColor() {
    const chartColorMap = [this.chartColorInit[0].color];
    const application = this.chart100OriginalData;
    if (application !== undefined && application !== null) {
      const app = application.series;
      app.forEach((val: any) => {
        if (val && val.name !== '') {
          const colorArray = this.chartColorInit.filter((chartCol) => chartCol.label === val.name);
          if (colorArray !== undefined) {
            chartColorMap.push(colorArray[0]?.color);
          }
        }
      });
    }
    return chartColorMap;
  }
  getStartRange(minValue: any) {
    let value = 0;
    if (minValue <= 10000) {
      let rem = minValue % 1000;
      value = minValue - rem;
    } else if (minValue >= 10000) {
      let rem = minValue % 10000;
      value = minValue - rem;
    }
    return value;
  }
  getEndRange(maxValue: any) {
    let value = 0;
    if (maxValue <= 10000) {
      let rem = maxValue % 1000;
      value = maxValue - rem + 1000;
    } else if (maxValue >= 10000) {
      let rem = maxValue % 10000;
      value = maxValue - rem + 10000;
    }
    return value;
  }
  incrementValue(startValue: any, endValue: any) {
    if (startValue >= 10000 && endValue >= 10000) {
      /**if startvalue and endvalue grater thanand equal to 10,000*/
      let sum = startValue;
      this.tempArray.push(startValue);
      while (sum != endValue) {
        sum = sum + 10000;
        this.tempArray.push(sum);
      }
    } else if (startValue < 10000 && endValue < 10000) {
      /**if startvalue and endvalue lessthan than 10,000*/
      let sum = startValue;
      this.tempArray.push(startValue);
      while (sum != endValue) {
        sum = sum + 1000;
        this.tempArray.push(sum);
      }
    } else {
      let sum = startValue;
      this.tempArray.push(startValue);
      while (sum != endValue) {
        sum = sum + 1000;
        this.tempArray.push(sum);
      }
    }
  }
  setRangeList() {
    for (let i = 0; i < this.tempArray.length - 1; i++) {
      const val = this.tempArray[i] + '-' + this.tempArray[i + 1];
      this.rangeList.push(val);
    }
    this.rangeList.push('Custom Range'); /**Range list */
  }
  expandedSubAssetTrendView(type?: string) {
    this.cdr.detectChanges();
    this.isFullScreenOpened = true;
    const modalRef = this.modal.openModal(ExpandedViewSubAssetTrendComponent, {
      type: 'full-modal',

      data: {
        title: 'Subscribed Assets Trend',
        config: this.subAssetTrendTableConfig,
        chartData: this.chart100OriginalData,
        responsiveConfig: this.chartResponsiveConfig,
        chartColorInit: this.chartColorInit,
        noData: this.subscribedAssetTrend,
      },
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: false,
      disableBackdropClick: true,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      this.isFullScreenOpened = false;
      this.chartSpinner = true;
      setTimeout(() => {
        this.chartSpinner = false;
      }, 100);
      this.subAssetTrendTableConfig.height = window.devicePixelRatio === 1 ? '430' : '430';
      this.cdr.detectChanges();
    });
  }
  mappingChartData(res: any) {
    this.chartSpinner = true;
    const keys = Object.keys(res);
    this.selectedSubscribedAssetFilters = {};
    keys.forEach((val: any, index) => {
      if (val == 'month' || val == 'application') {
        this.dupArr = Object.values(res)[index];
        this.dupArrProduct = Object.keys(res)[index];
        if (this.dupArr.length != 0) {
          this.selectedSubscribedAssetFilters[val] = this.dupArr;
        }
      } else if (val == 'range') {
        this.dupArr = Object.values(res)[index];
        this.FilteredRange = this.dupArr;
        if (this.dupArr.length != 0) {
          this.selectedSubscribedAssetFilters[val] = { min: this.dupArr[0], max: this.dupArr[1] };
        }
      }
    });
    if (!this.isSubscribeAssetTreandProduct) {
      this.filteredAssetsAPIPayload.filterList = this.selectedSubscribedAssetFilters;
    }
    if (this.isSubscribeAssetTreandProduct) {
      if (
        this.selectedSubscribedAssetFilters.application.length !== 0 &&
        this.selectedSubscribedAssetFilters.month === undefined
      ) {
        this.filteredAssetsAPIPayload.filterList = {
          application: this.selectedSubscribedAssetFilters.application,
          range: this.selectedSubscribedAssetFilters.range,
          month: this.monthList,
        };
      } else {
        this.filteredAssetsAPIPayload.filterList = this.selectedSubscribedAssetFilters;
      }
    }
    this.dashboardService.getFilteredSubscriptionAssetTrend(this.filteredAssetsAPIPayload).subscribe({
      next: (filteredSubscriptionData: any) => {
        if (
          filteredSubscriptionData &&
          Object.keys(filteredSubscriptionData) &&
          Object.keys(filteredSubscriptionData).length > 0
        ) {
          this.chartSpinner = false;
          const getKeys = Object.keys(filteredSubscriptionData);
          this.chart100OriginalData = {
            series: [
              {
                name: '',
                data: [],
              },
            ],
          };
          getKeys.forEach((val: any, index) => {
            const chart_data: Line_Chart_Data = {
              name: '',
              data: [],
            };
            this.subscribedAssetTrend = [];
            this.FilteredMonthArray = [];
            chart_data.name = val; /**assigning tooltip name */
            this.subscribedAssetTrend = Object.values(filteredSubscriptionData)[index];
            this.subscribedAssetTrend.map((item: any) => {
              this.FilteredMonthArray.push(item.label); /**filtered month 'may','june */
              chart_data.data.push(Number(item.value)); /**count of selected application */
            });
            this.chartColor = this.getChartColor();
            this.chart100OriginalData.series[index] = chart_data;
            this.store.dispatch(
              setChart1000FilterItem({
                chartData: {
                  chart1000FilterData: this.chart100OriginalData.series,
                },
              })
            );
            this.showFilterIconOnNoData = false;
            // this.filterIcon = true;
          });
          const restoreLabel = this.dspUtilsCommonService.getValue('lineChartLabels');
          if (restoreLabel) {
            const labelvalues = JSON.parse(restoreLabel);
            this.xAxisLabel = labelvalues.xAxisLabel;
            this.yAxisLabel = labelvalues.yAxisLabel;
          }
          this.mappingFilteredSubAssetCountValues();
          const xAxis = {
            title: this.xAxisLabel,
            categories: this.FilteredMonthArray,
          };
          const yAxis = {
            title: this.yAxisLabel,
            min: 0,
          };
          this.subAssetTrendTableConfig = { ...this.subAssetTrendTableConfig, xAxis: xAxis, yAxis: yAxis };
          this.store.dispatch(
            setInitialTableItem({
              subsasst: {
                subAssetTrendTableConfiguration: this.subAssetTrendTableConfig,
              },
            })
          );
          // sessionStorage.setItem('subAssetTrendTableConfig', JSON.stringify(this.subAssetTrendTableConfig));
        } else {
          // sessionStorage.removeItem('chart100OriginalData');
          // sessionStorage.removeItem('subAssetTrendTableConfig');
          this.subscribedAssetTrend = [];
          this.chart100OriginalData.series = [];
          this.showFilterIconOnNoData = false;
        }
      },
      error: (error) => {
        this.chartSpinner = false;
        this.showFilterIconOnNoData = false;
      },
    });
  }
  mappingFilteredSubAssetCountValues() {
    const chart_data: Line_Chart_Data = {
      name: '',
      data: [],
    };

    chart_data.name = this.yAxisLabel;

    const val1 = this.dspUtilsCommonService.getValue('defaultLineChartValues');
    const val2 = val1 ? JSON.parse(val1) : [];
    if (val2) {
      val2.forEach((obj: any) => {
        if (this.FilteredMonthArray.includes(obj.label)) {
          const data = obj.value;
          if (data >= this.FilteredRange[0] && data <= this.FilteredRange[1]) {
            chart_data.data.push(Number(obj.value));
          } else {
            chart_data.data.push(0);
          }
        }
      });
    }

    this.chartColor = this.getChartColor();
    this.chart100OriginalData.series.unshift(chart_data);
    this.store.dispatch(
      setChart1000FilterItem({
        chartData: {
          chart1000FilterData: this.chart100OriginalData.series,
        },
      })
    );
  }
  getSubscriptionAssetTrend1(selectedMonths: any) {
    this.chartSpinner = true;
    const restoreLabel = this.dspUtilsCommonService.getValue('lineChartLabels');

    if (restoreLabel) {
      const labelvalues = JSON.parse(restoreLabel);
      this.xAxisLabel = labelvalues.xAxisLabel;
      this.yAxisLabel = labelvalues.yAxisLabel;
    }
    this.dashboardService.getSubscriptionAssetTrend(this.subscribedAssetTrendPayload).subscribe({
      next: (response) => {
        this.chartSpinner = false;
        this.defaultXAxisValue = [];

        this.chart100OriginalData = {
          series: [
            {
              name: '',
              data: [],
            },
          ],
        };
        const chart_data: Line_Chart_Data = {
          name: '',
          data: [],
        };
        chart_data.name = this.yAxisLabel;
        if (response && Object.keys(response) && Object.keys(response).length > 0) {
          this.subscribedAssetTrend = Object.values(response)[0];
          this.chartSpinner = false;
          this.showFilterIconOnNoData = false;
        } else {
          this.subscribedAssetTrend = [];
          this.chartSpinner = false;
          this.showFilterIconOnNoData = false;
        }
        this.subscribedAssetTrend.map((item: any) => {
          if (selectedMonths != '') {
            if (selectedMonths.find((mon: string) => mon.substring(0, 3) === item.label)) {
              this.defaultXAxisValue.push(item.label); /**assiging xaxis vlaue in the chart */
              chart_data.data.push(Number(item.value));
            }
          } else {
            this.defaultXAxisValue.push(item.label); /**assiging xaxis vlaue in the chart */
            chart_data.data.push(Number(item.value));
          }
        });
        this.mappingDefaultSubscribedAssetCountValues(this.xAxisLabel, this.defaultXAxisValue, chart_data);
      },
      error: (error) => {
        this.chartSpinner = false;
        this.showFilterIconOnNoData = false;
      },
    });
  }
  openFilter(type?: string) {
    this.savedFilters = this.filterContentsRef?.config?.data?.savedFilters || this.selectedFilter;
    this.filterContentsRef = this.drawerService.openDrawer<SubAssetTrendFilterComponent, CcDrawerConfig>(
      SubAssetTrendFilterComponent,
      {
        data: {
          headerType: type,
          rangeList: this.rangeList,
          monthList: this.monthList,
          productList: this.productList,
          savedFilters: this.savedFilters,
          minValue: 0, //this.startValue,
          maxValue: this.endValue,
          savedMonthFilter: this.savedMonthFilter,
          isDrawerOpen: true,
          noData: this.noData,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    if (this.filterContentsRef) {
      this.filterContentsRef?.onBackdropClick?.subscribe((data: any) => {
        if (data) {
          const month = this.dashboardService.getMonth();
          const product = this.dashboardService.getProduct();
          if (month || product) {
            this.dashboardService.subscrapplyFilterClicked.next(true);
          } else {
            this.filterContentsRef.close();
          }
        }
      });
    }
    this.filterContentsRef.afterClosed().subscribe((res: any) => {
      if (res && Object.keys(res) && Object.keys(res).length > 0) {
        if (!this.isSubscribeAssetTreandProduct) {
          if (res.month.length == 0) {
            // sessionStorage.removeItem('chart100OriginalData');
            // sessionStorage.removeItem('subAssetTrendTableConfig');
            this.selectedFilter = [];
            this.subscribedAssetTrend = [];
            this.chart100OriginalData.series = [];
            this.showFilterIconOnNoData = true;
          } else if (res.month.length != 0 && res.application.length == 0) {
            this.chartSpinner = true;
            this.getSubscriptionAssetTrend1(res.month);
          } else {
            this.mappingChartData(res);
            // this.filterIcon = false;
          }
        }
        if (this.isSubscribeAssetTreandProduct) {
          if (res.month.length == 0 && res.application.length! == 0) {
            this.mappingChartData(res);

            // sessionStorage.removeItem('chart100OriginalData');
            // sessionStorage.removeItem('subAssetTrendTableConfig');
            // this.subscribedAssetTrend = [];
            // this.chart100OriginalData.series = [];
            // this.showFilterIconOnNoData = true;
          } else if (res.month.length != 0 && res.application.length == 0) {
            this.chartSpinner = true;
            this.getSubscriptionAssetTrend1(res.month);
          } else {
            this.mappingChartData(res);
          }
        }
      } else {
        if (res != undefined) {
          this.chartSpinner = true;
          this.filterApplied = false;

          this.getSubscriptionAssetTrend1('');
        }
      }
      this.dashboardService.subscrapplyFilterClicked.next(false);
      this.dashboardService.setMonth(null);
      this.dashboardService.setProduct(null);
    });
  }
}
