import { Component, OnInit } from '@angular/core';
import { siteData } from '../sites-interface';
import { DrawerService, CcDrawerConfig, CcDrawerSize } from '@cat-digital-workspace/shared-ui-core/drawer';
import { AdditionalProductPlanComponent } from './../additional-product-plan/additional-product-plan.component';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-site-product-plan',
  templateUrl: './site-product-plan.component.html',
  styleUrls: ['./site-product-plan.component.scss'],
})
export class SiteProductPlanComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  addtionalproductPlan: any;
  showCustomToolTip = false;
  dspStoreData: any;
  constructor(
    private drawerService: DrawerService,
    private store: Store<DSPAppState>
  ) {}
  // constructor() {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.showCustomToolTip = this.rowData.planName?.split('|').length >= 3;
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
  }

  parseProductPlan(rowData: siteData) {
    const productPlan = `${rowData.product} | ${rowData.planName}`;
    return productPlan;
  }

  openDrawerInMultiSize() {
    this.addtionalproductPlan = this.drawerService.openDrawer<AdditionalProductPlanComponent, CcDrawerConfig>(
      AdditionalProductPlanComponent,
      {
        data: {
          rowData: this.rowData,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }

  showSeeMoreOption() {
    if (
      this.rowData.status === 'Active' &&
      this.rowData.productPlan?.length &&
      this.rowData.planName.split('|')?.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  }
}
