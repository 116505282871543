import { Component, OnInit } from '@angular/core';
import { siteData } from '../sites-interface';
import { isNil } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-ownership-name',
  templateUrl: './ownership-name.component.html',
  styleUrls: ['./ownership-name.component.scss'],
})
export class OwnershipNameComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  dspStoreData: any;
  isChangeOwnership: any;
  showTruncatedText = false;
  columnName: any;
  constructor(private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.columnName = this.columnWithHeader?.name;
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.isChangeOwnership = this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        ? this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        : false;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
  }

  parseOwnershipData(rowData: siteData) {
    const customerName = `${isNil(rowData.ucidName) ? '' : rowData.ucidName}`;
    const customerID = `${isNil(rowData.ucid) ? '' : rowData.ucid}`;
    const dealerName = `${isNil(rowData.dcnName) ? '' : rowData.dcnName}`;
    const dealerID = `${isNil(rowData.dcn) ? '' : rowData.dcn}`;
    let customerInfo, dealerInfo, ownershipData;
    if (customerName && customerID) {
      customerInfo = `${customerName} - ${customerID}`;
    } else {
      customerInfo = `${customerName}${customerID}`;
    }
    if (dealerName && dealerID) {
      dealerInfo = `${dealerName} - ${dealerID}`;
    } else {
      dealerInfo = `${dealerName}${dealerID}`;
    }
    if (customerInfo && dealerInfo) {
      ownershipData = `${customerInfo} | ${dealerInfo}`;
    } else {
      ownershipData = `${customerInfo}${dealerInfo}`;
    }
    if (ownershipData && ownershipData.length > 36) {
      return `${ownershipData.substring(0, 36)}...`;
    } else {
      return ownershipData;
    }
  }

  updateCustomerName(customerId: string, customerName: string) {
    const ID = isNil(customerId) ? '' : customerId;
    const name = isNil(customerName) ? '' : customerName;
    if (ID != '' && name != '') {
      return `${name} - ${ID}`;
    }
    return `${name}${ID}`;
  }
}
