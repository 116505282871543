<div class="asset-main-container">
  <div class="asset-cards-column">
    <div class="asset-cards-top">
      <div class="asset-header">
        <span
          class="asset-header-title"
          [cc-tooltip]="getData(assetData?.serialNumber)"
          [isEllipseEnabled]="false"
          [tooltipPosition]="'top'"
          >{{ checkDealerDataLength(assetData?.serialNumber) }}</span
        >
      </div>
      <div class="asset-header-state">
        <div class="reportingState">
          <div *ngIf="assetData?.reportingState === dspWorklistConstants.SUBSCRIBED">
            <cc-icon class="reportingStateIcon subscribed">subscribed</cc-icon>
            <span class="reportingStateText">{{ dspWorklistConstants.SUBSCRIBED }}</span>
          </div>
          <div *ngIf="assetData?.reportingState === dspWorklistConstants.UNSUBSCRIBED">
            <cc-icon class="reportingStateIcon unsubscribed">unsubscribed</cc-icon>
            <span class="reportingStateText">{{ dspWorklistConstants.UNSUBSCRIBED }}</span>
          </div>
          <div *ngIf="assetData?.reportingState === dspWorklistConstants.SUSPENDED">
            <cc-icon class="reportingStateIcon suspended">suspended</cc-icon>
            <span class="reportingStateText">{{ dspWorklistConstants.SUSPENDED }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="asset-card-mid">
      <span
        *ngIf="assetData?.engineSerialNumber"
        [cc-tooltip]="getData(assetData?.engineSerialNumber)"
        [tooltipPosition]="'top'"
        class="mid-info"
        >{{ checkEnginSerialNumberForTrim(assetData?.engineSerialNumber) }} </span
      ><span *ngIf="assetData?.make" [cc-tooltip]="getData(assetData?.make)" [tooltipPosition]="'top'" class="mid-info"
        >{{ assetData?.make }} </span
      ><span
        *ngIf="assetData?.model"
        [cc-tooltip]="getData(assetData?.model)"
        [tooltipPosition]="'top'"
        class="mid-info"
        >{{ checkEnginSerialNumberForTrim(assetData?.model) }} </span
      ><span
        *ngIf="assetData?.manufacturingYear"
        [cc-tooltip]="getData(assetData?.manufacturingYear)"
        [tooltipPosition]="'top'"
        class="mid-info"
        >{{ assetData?.manufacturingYear }} </span
      ><span
        *ngIf="assetData?.deviceType"
        [cc-tooltip]="getData(assetData?.deviceType)"
        [tooltipPosition]="'top'"
        class="mid-info"
        >{{ assetData?.deviceType }}</span
      >
    </div>
    <section class="asset-card-bottom">
      <div class="container-fluid">
        <div class="slider-wrap">
          <div
            class="prev"
            *ngIf="hideArrow(sliderMain)"
            (click)="previous()"
            [ngClass]="{ disable: validatePrevBtn() }"
          >
            <cc-icon fontSet="icomoon" [ngClass]="'advSeachArrowLeft'">adv-search</cc-icon>
          </div>
          <div class="slider-card" #sliderMain>
            <div *ngIf="isSecondRowLoading" class="slider-loader">
              <cc-skeleton-loader [height]="20" [animation]="true" class="row-skeleton-loader">
                <svg:rect x="2" y="6" rx="2" ry="2" width="90" height="30" />
                <svg:rect x="100" y="6" rx="2" ry="2" width="90" height="30" />
                <svg:rect x="200" y="6" rx="2" ry="2" width="90" height="30" />
                <svg:rect x="300" y="6" rx="2" ry="2" width="90" height="30" />
                <svg:rect x="400" y="6" rx="2" ry="2" width="90" height="30" />
              </cc-skeleton-loader>
            </div>
            <div class="slider-main" *ngIf="!isSecondRowLoading">
              <div class="item" *ngFor="let option of optionDetails">
                <a *ngIf="option.isShow" class="card-options" (click)="optionClick(option, assetData)">{{
                  option.optionName
                }}</a>
              </div>
            </div>
          </div>
          <div
            class="next"
            *ngIf="hideArrow(sliderMain)"
            (click)="next()"
            [ngClass]="{ disable: !checkOverflow(sliderMain) }"
          >
            <cc-icon fontSet="icomoon" [ngClass]="'advSeachArrowRight'"> adv-search</cc-icon>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
