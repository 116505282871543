import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as dspConstants from '../../../../../shared/dspConstants';
import { ApplicableSubscriptionsType } from '../../../../../models/assets.interface';
import { AssetService } from '../../../services/asset.service';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-single-level-product',
  templateUrl: './single-level-product.component.html',
  styleUrls: ['./single-level-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SingleLevelProductComponent {
  constructor(private assetService: AssetService) {}
  @Input() subsData!: any;
  @Input() listOfBasePlans: any;
  @Input() autoSelectItem: any;
  @Input() applicableSubscriptions!: ApplicableSubscriptionsType;
  @Input() applicableAddlSubscriptions: any;
  @Input() listOfOptionalPlans: any;
  @Input() startDateFieldConfig: any;
  @Input() endDateFieldConfig: any;
  @Input() promoLoader = false;
  @Output() basePlanChange = new EventEmitter();
  @Output() OptionalPlanChange = new EventEmitter();
  @Output() togglePrepay = new EventEmitter();
  @Output() prepayDateChange = new EventEmitter();
  @Input() multiYearConfig: any;
  @Input() manageAssetSubscriptionForm!: any;
  @Input() applicablePlansDetails: any;
  @Input() applicableAddOnPlansDetails: any;
  basePlans: any;
  selectedDate = new Date();
  currentYear = this.selectedDate.getFullYear();
  currentMonth = this.selectedDate.getMonth();
  currentDate = this.selectedDate.getDate();
  dateFormat = { value: 'mmm dd, yyyy', label: 'mmm dd, yyyy' };
  dateFieldConfig = {
    disabled: false,
    labelValue: '',
    width: '170px',
    isLeading: true,
    isTrailing: false,
    ariaDescribedBy: 'Select Date',
    required: true,
  };
  datepickerStatus = { isValidDate: true, isValidFormat: true };
  dspConstants!: any;
  dspHelp = 'https://catdealer.com';

  ngOnInit(): void {
    this.dspConstants = dspConstants;
  }

  onBasePlanChange(event: any, level: string) {
    if (event.action !== 'onLoad') {
      this.basePlanChange.emit({ event, level });
    }
  }
  onOptionalPlanChange(event: any, prop: string) {
    if (event.action !== 'onLoad') {
      this.OptionalPlanChange.emit({ event, prop });
    }
  }
  handlePrepayChange(event: any) {
    this.togglePrepay.emit(event);
  }
  handlePrepayDateChange(event: any, contractDateType: string) {
    this.prepayDateChange.emit({ event, contractDateType });
  }
}
