import { Component, OnInit, ChangeDetectorRef, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { cloneDeep, debounce, findIndex } from 'lodash-es';
import { dealerType } from '../../../../models/shared.interface';
import { DspCommonService } from '../../../../services/dsp-common.service';
import { SubscribeAccountsService } from '../../../../services/subscribe-accounts.service';
import { AccountFieldNamesModal, AccountFormType } from '../../accounts-interface';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import moment from 'moment';
import { DatePipe } from '@angular/common';

interface searchCustomerType {
  label: string;
  value: any;
}

@Component({
  selector: 'dsp-next-gen-ui-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountDetailsComponent implements OnInit {
  @ViewChild('customerSearchBox') customerSearchInput!: ElementRef;
  @ViewChild('prefixSearchBox') prefixSearchInput!: ElementRef;
  @Input() selectedData!: any;
  @Input() addPlanConfig: any;
  dealer!: dealerType;
  searchHelpText = '';
  prevSearchCustomer = '';
  prevSearchPrefix = '';
  customer = '';
  prefix = '';
  customerLoader = false;
  prefixLoader = false;
  cvaNumberEmpty = true;
  searchCustItems: searchCustomerType[] = [];
  searchPrefixItems: any = [];
  customerUCID: any;
  userTypes: any = [];
  siteNameExists = false;
  accountDetailsForm!: FormGroup;
  licenseType: any = [
    {
      label: 'Limited Users',
      value: 'Limited Users',
      disabled: false,
    },
    {
      label: 'Unlimited Users',
      value: 'Unlimited Users',
      disabled: false,
    },
  ];
  finalLicenseType = [];
  pageHeaderTitle = '';
  isListItemSelected = false;
  prefixLoading = false;
  customerNotFound = false;
  prefixNotFound = false;
  enableSearchOverlay = true;
  @Input() salesData: any;
  @ViewChild('locationbox') locationbox!: ElementRef;
  inputFieldConfig6 = {
    disabled: true,
    labelValue: 'Select',
    width: '240px',
    isLeading: true,
    isTrailing: false,
    ariaDescribedBy: 'Select',
    required: true,
  };
  timeZoneOffset = 660;
  isTwoPoint0 = true;
  dateFormat = 'MM/dd/yyyy';
  setStartDate: any;
  maxStartEndDate!: Date;
  startDateVal: any;
  minStartEndDate!: Date;
  maxEndDate: any;
  endDateVal: any;
  selectedReason: any;
  enableCVAfield = false;
  selectReasonField = false;
  salesReasonLoader = false;
  edateDisabled = false;
  cva = '';
  saleReasonDescription = '';
  prefixSearchHelpText = '';
  formatedDate: any;
  salesList: any = [];
  isTheme = '2.0';
  fieldNames: AccountFieldNamesModal = {};
  constructor(
    private formBuilder: FormBuilder,
    private apiService: DspCommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private subscribeAccountsService: SubscribeAccountsService,
    private messageBar: MessageBar,
    private datepipe: DatePipe
  ) {
    this.inputFieldConfig6 = {
      disabled: false,
      labelValue: 'Select Date',
      width: '240px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Start Date',
      required: true,
    };
    const selectedDate = new Date();
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();
    const currentDate = selectedDate.getDate();
    this.minStartEndDate = new Date(currentYear, currentMonth, currentDate);
  }

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.finalLicenseType = cloneDeep(this.licenseType);
    this.fieldNames = this.addPlanConfig?.accountDetails;

    this.accountDetailsForm = this.formBuilder.group({
      licensedTypeData: [this.updateSelectedLicensedType()],
      customerData: ['', [Validators.required, Validators.minLength(3)]],
      prefix: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(3)]],
      saleReason: ['', [Validators.required]],
      expirydate: ['', [Validators.required]],
      saleReasonField: [''],
      cva: [''],
    });

    this.customer = this.updateSelectedCustomerData();
    this.customerUCID = this.updateSelectedCustomerUCIDData();
    this.pageHeaderTitle = this.updatePageHeaderTitle();
    this.handleFormValidity();
    this.subscribeAccountsService.setAccountFormData(this.selectedData);

    /** The below logic is for retaining the values of account details page
     * when going back to product plan and again to account details */
    if (this.customer) {
      this.isListItemSelected = true;
      this.accountDetailsForm.controls['customerData'].setValue(this.customer);
      this.prefixLoader = true;
    }

    if (this.selectedData.prefix) {
      this.accountDetailsForm.controls['prefix'].setValue(this.selectedData.prefix);
      this.salesReasonLoader = true;
      if (this.selectedData.expirationDate) {
        this.edateDisabled = true;
      }
    }

    if (this.selectedData.selectedReasonOption) {
      this.accountDetailsForm.controls['saleReason'].setValue(this.selectedData.selectedReasonOption);
      if (this.selectedData.selectedReasonOption === 'CVA') {
        this.enableCVAfield = true;
        this.selectReasonField = false;
        if (this.selectedData.cvaNo) {
          this.edateDisabled = true;
          this.cva = this.selectedData.cvaNo;
          this.accountDetailsForm.controls['cva'].setValue(this.selectedData.cvaNo);
        }
      } else if (this.selectedData.selectedReasonOption === 'Other') {
        this.enableCVAfield = false;
        this.selectReasonField = true;
        if (this.selectedData.selectReasonOtherDesc) {
          this.edateDisabled = true;
          this.saleReasonDescription = this.selectedData.selectReasonOtherDesc;
          this.accountDetailsForm.controls['saleReasonField'].setValue(this.selectedData.selectReasonOtherDesc);
        }
      } else {
        this.edateDisabled = true;
      }
    }

    if (this.selectedData.expirationDate) {
      this.accountDetailsForm.controls['expirydate'].setValue(this.selectedData.expirationDate);
    }
  }

  get formControl() {
    return this.accountDetailsForm.controls;
  }

  handleFormValidity() {
    if (this.selectedData.plan === 'Technician Toolbox') {
      this.selectedData.isValidForm = this.selectedData.customer?.ucid !== '' && this.validateSelectedLicenseType();
    } else if (this.selectedData.plan === 'VIMS Converter') {
      this.selectedData.isValidForm = this.selectedData.customer?.ucid !== '';
    } else if (this.selectedData.plan === 'Service Media Access') {
      this.selectedData.isValidForm = this.selectedData.customer?.ucid !== '';
    }
  }

  validateSelectedLicenseType() {
    return this.selectedData.licenseType !== '' && this.selectedData.isValidLicenseType;
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'account-details-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  updateSelectedLicensedType() {
    if (this.selectedData.licenseType !== '') {
      if (this.selectedData.licenseType !== 'Unlimited Users') this.validateUnLimitedLicensedType();
      this.isListItemSelected = true;
      return this.selectedData.licenseType;
    } else {
      return '';
    }
  }

  updateSelectedCustomerData() {
    const { ucid, ucName, dcn, dcName } = this.selectedData.customer || {};
    if (ucid !== '') {
      return `${ucName} - ${ucid} - ${dcName} - ${dcn}`;
    } else {
      return '';
    }
  }

  updateSelectedCustomerUCIDData() {
    const { ucid, ucName, dcn, dcName } = this.selectedData.customer || {};
    if (ucid !== '') {
      return {
        dealerCode: this.dealer.dealerCode,
        dealerCustomerNumber: dcn,
        dealerName: dcName,
        name: dcn,
        phone: '',
        ucName: ucName,
        ucid: ucid,
      };
    } else {
      return {};
    }
  }

  /**commented below function as we are not using it anywhere */
  // onChangePrefix(event: any) {
  //   if (event.value !== '') {
  //     this.selectedData.prefix = event.value;
  //     if (this.accountDetailsForm.valid && this.accountDetailsForm.controls['customerData'].value !== '') {
  //       this.selectedData.isValidForm = true;
  //     }
  //   } else {
  //     this.selectedData.isValidForm = false;
  //   }

  //   this.subscribeAccountsService.setAccountFormData(this.selectedData);
  // }

  onChangeCVA(event: any) {
    if (event.value !== '') {
      const inputValue = event.value;
      this.selectedData.cvaNo = inputValue;
      this.edateDisabled = true;
      this.cvaNumberEmpty = false;
      if (this.accountDetailsForm.valid && this.accountDetailsForm.controls['customerData'].value !== '') {
        this.selectedData.isValidForm = true;
      }
    } else {
      this.selectedData.isValidForm = false;
      this.edateDisabled = false;
      this.cvaNumberEmpty = true;
    }
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  onChangeOtherDesc(event: any) {
    if (event.value !== '') {
      this.selectedData.selectReasonOtherDesc = event.value;
      this.accountDetailsForm.controls['saleReasonField'].setValue(this.selectedData.selectReasonOtherDesc);
      if (this.accountDetailsForm.valid && this.accountDetailsForm.controls['customerData'].value !== '') {
        this.selectedData.isValidForm = true;
      }
    } else {
      this.selectedData.isValidForm = false;
    }
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  validateUnLimitedLicensedType() {
    if (this.selectedData.restrictUnlimitedUsers) {
      const filteredLicensedType = cloneDeep(this.finalLicenseType);
      filteredLicensedType.forEach((item: any) => {
        if (item.label == 'Unlimited Users') {
          item.disabled = true;
        }
      });
      this.finalLicenseType = filteredLicensedType;
    }
  }

  updatePageHeaderTitle() {
    let headerTitle = '';
    if (this.selectedData.plan === 'SIS2.0 USB') {
      headerTitle = 'Select Users';
    } else if (this.selectedData.plan === 'VIMS Converter') {
      headerTitle = 'Select An Account';
    } else {
      headerTitle = 'Select An Account And Details';
    }
    return headerTitle;
  }

  onClickEvent(event: any) {
    if (event?.target?.value === '') {
      this.searchHelpText = 'Search Customer Name / Customer ID / DCN (Min 3 char)';
    } else {
      this.searchHelpText = '';
    }
  }

  onClickPrefixEvent(event: any) {
    if (event?.target?.value === '') {
      this.prefixSearchHelpText = 'Start Typing Prefix (Min 1 char)';
      this.enableSearchOverlay = false;
      this.changeDetectorRef.detectChanges();
    } else {
      this.prefixSearchHelpText = '';
      this.enableSearchOverlay = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  onBlurEvent(event: any) {
    if (event?.type === 'blur') {
      this.searchHelpText = '';
    }
  }

  onFocusPrefixEvent(event: any) {
    if (event?.target?.value === '') {
      this.prefixSearchHelpText = 'Start Typing Prefix (Min 1 char)';
      this.enableSearchOverlay = false;
      this.changeDetectorRef.detectChanges();
    } else {
      this.prefixSearchHelpText = '';
      this.enableSearchOverlay = true;
      this.changeDetectorRef.detectChanges();
    }
    setTimeout(() => {
      const textLength = this.selectedData?.prefix?.length || event?.target?.value?.length;
      this.prefixSearchInput?.nativeElement.setSelectionRange(textLength, textLength);
    }, 100);
  }

  onBlurPrefixEvent(event: any) {
    if (event?.type === 'blur') {
      this.prefixSearchHelpText = '';
    }
  }

  onFocusEvent() {
    setTimeout(() => {
      const textLength = this.customer?.length || 0;
      this.customerSearchInput?.nativeElement.setSelectionRange(textLength, textLength);
    }, 100);
  }

  searchByCustomer(event: any) {
    if (event.value !== '' && this.prevSearchCustomer != this.customer) {
      if (this.searchHelpText !== '') {
        this.searchHelpText = '';
      }
      this.prevSearchCustomer = this.customer;
      this.customerNotFound = false;
      if (
        event.value &&
        event.value.length >= 3 &&
        !this.isListItemSelected &&
        !this.prefixLoader &&
        !this.salesReasonLoader &&
        !this.edateDisabled
      ) {
        this.searchCustItems = [];
        const searchParam = { dealerCode: this.dealer.dealerCode, searchText: event.value };
        this.fetchCustomerDetails(searchParam);
      }
    } else if (event.value !== '' && this.prevSearchCustomer === this.customer) {
      this.searchHelpText = '';
    } else if (event.value === '') {
      this.customerLoader = false;
      this.customerNotFound = false;
      this.customerUCID = '';
      this.isListItemSelected = false;
      this.prefixLoader = false;
      this.edateDisabled = false;
      this.salesReasonLoader = false;
      this.selectedData.customer = this.updateCustomerData();
      this.selectedData.licenseType = '';
      this.selectedData.isValidLicenseType = true;
      this.selectedData.restrictUnlimitedUsers = false;
      this.accountDetailsForm.get('licensedTypeData')?.patchValue('');
      this.finalLicenseType = cloneDeep(this.licenseType);
      this.selectedData.isValidForm = false;
      this.accountDetailsForm.controls['customerData'].setErrors({ customerNotSet: true });
      this.subscribeAccountsService.setAccountFormData(this.selectedData);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  fetchCustomerDetails = debounce((searchParam: any) => {
    if (this.customer === searchParam.searchText && this.customer?.length > 2) {
      this.customerLoader = true;

      this.changeDetectorRef.detectChanges();

      this.getSeachTextCustomerDetails(searchParam);
    }
  }, 700);

  getSeachTextCustomerDetails(searchParam: any) {
    this.apiService.getCustomers(searchParam).subscribe({
      next: (res: any) => {
        const dealerCustomer = res.dealerCustomer ? res.dealerCustomer : false;
        if (dealerCustomer && dealerCustomer.length > 0) {
          // this.customerLoader = false;

          this.searchCustItems = dealerCustomer.map(function (item: any) {
            return {
              label: item.ucName + ' - ' + item.ucid + ', ' + item.name + ' - ' + item.dealerCustomerNumber,
              value: item,
            };
          });
        }
        if (dealerCustomer.length == 0) {
          this.updateNoResults();
        }
        this.customerLoader = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (_err) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
        this.customerLoader = false;
      },
    });
  }

  updateNoResults() {
    this.customerNotFound = true;
    this.resetOverlayBox();
  }

  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }

  listItemClick(index: number) {
    this.isListItemSelected = true;
    this.prefixLoader = true;

    this.salesReasonLoader = false;
    this.edateDisabled = false;
    this.customer = this.searchCustItems[index]['label'];
    this.customerUCID = this.searchCustItems[index]['value'];
    this.selectedData.customer = this.updateCustomerData(this.customerUCID);
    this.selectedData.licenseType = '';
    this.selectedData.isValidLicenseType = true;
    this.accountDetailsForm.get('licensedTypeData')?.patchValue('');
    this.finalLicenseType = cloneDeep(this.licenseType);
    this.selectedData.selectedUsers = [];
    this.selectedData.searchedUsers = [];
    this.selectedData.isValidForm = false;
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
    this.validateUcidDcnDetails(this.customerUCID);
  }

  updateCustomerData(customerData: any = null) {
    return {
      ucid: customerData?.ucid || '',
      ucName: customerData?.ucName || '',
      dcn: customerData?.dealerCustomerNumber || '',
      dcName: customerData?.name || '',
    };
  }

  validateUcidDcnDetails(customerUCID: any) {
    this.subscribeAccountsService.validateUcidDcn({ ucid: customerUCID?.ucid || '' }).subscribe({
      next: (response: any) => {
        if (this.selectedData.plan === 'VIMS Converter') {
          this.validateLicenseType('');
        } else if (this.selectedData.plan === 'Service Media Access') {
          if (this.accountDetailsForm.valid) {
            this.selectedData.isValidForm = true;
            this.prefixLoader = true;
            this.salesReasonLoader = true;
            this.edateDisabled = true;
          }
        } else if (this.selectedData.plan === 'Technician Toolbox') {
          if (response.length > 1) {
            const filteredLicensedType = cloneDeep(this.finalLicenseType);
            filteredLicensedType.forEach((item: any) => {
              if (item.label == 'Unlimited Users') {
                item.disabled = true;
              }
            });
            this.finalLicenseType = filteredLicensedType;
            this.selectedData.restrictUnlimitedUsers = true;
            this.accountDetailsForm.get('licensedTypeData')?.patchValue('Limited Users' || '');
            this.selectedData.licenseType = 'Limited Users';
            this.validateLicenseType('Limited Users');
          } else {
            this.accountDetailsForm.get('licensedTypeData')?.patchValue('');
            this.finalLicenseType = cloneDeep(this.licenseType);
            this.selectedData.restrictUnlimitedUsers = false;
          }
        }
        this.subscribeAccountsService.setAccountFormData(this.selectedData);
        this.changeDetectorRef.detectChanges();
      },
      error: (_error: any) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
        this.selectedData.isValidForm = false;
        this.subscribeAccountsService.setAccountFormData(this.selectedData);
      },
    });
  }

  licenseTypeDropdownChange(event: any) {
    const selectedLicenseType: string = event?.currentSelection[0]?.label || '';
    this.selectedData.isValidLicenseType = this.validateSelectedLicenseData(this.finalLicenseType, selectedLicenseType);
    if (selectedLicenseType && selectedLicenseType !== this.selectedData.licenseType) {
      this.selectedData.licenseType = selectedLicenseType;
      this.selectedData.selectedUsers = [];
      this.selectedData.searchedUsers = [];
      this.selectedData.isValidForm = false;
      if (this.selectedData.isValidLicenseType) {
        this.validateLicenseType(selectedLicenseType);
      }
    }
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  validateSelectedLicenseData(optionsData: any, selectedOption: string) {
    return findIndex(optionsData, ['label', selectedOption]) > -1;
  }

  validateLicenseType(selectedLicenseType: any) {
    const validateAccountPayload = {
      planName: this.selectedData.plan,
      type: this.selectedData.accountType,
      dealerCode: this.dealer?.dealerCode,
      ucid: this.customerUCID?.ucid || '',
      licensedType: selectedLicenseType,
    };
    this.subscribeAccountsService.validateAccount(validateAccountPayload).subscribe({
      error: (err: HttpErrorResponse) => {
        if (err && err.status === 409) {
          this.selectedData.isValidLicenseType = false;
          this.selectedData.isValidForm = false;
          this.showToastMessage(err.error, 'error');
        } else if (err.status === 202) {
          this.selectedData.isValidLicenseType = true;
          this.selectedData.isValidForm = true;
        }
        this.subscribeAccountsService.setAccountFormData(this.selectedData);
      },
    });
  }

  onDateChange($event: any) {
    let currentYear = 0;
    let currentMonth = 0;
    let currentDate = 0;
    if (moment) {
      currentYear = moment($event).year();
      currentMonth = moment($event).month();
      currentDate = moment($event).date();
    }
    this.startDateVal = new Date(currentYear, currentMonth, currentDate);
    this.selectedData.startDate = this.startDateVal;
    this.formatedDate = this.datepipe.transform(this.startDateVal, 'MM/dd/yyyy');
    this.selectedData.expirationDate = this.formatedDate;
    if (this.accountDetailsForm?.valid && this.accountDetailsForm?.controls['customerData'].value !== '') {
      this.selectedData.isValidForm = true;
    }
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }
  /**commented below function as we are not using it anywhere */
  // formatDate(value: any) {
  //   if (value) {
  //     return (
  //       value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2) + '-' + ('0' + value.getDate()).slice(-2)
  //     );
  //   } else {
  //     return;
  //   }
  // }

  onDatepickerOpen($event: any) {}

  selctReasonChange(event: any) {
    if (event.action === 'close') {
      return;
    }
    this.selectedData.isValidForm = false;

    const selectedOption: string = event?.currentSelection[0]?.label || '';
    this.selectedData.selectReason = event?.currentSelection[0]?.value;
    this.selectedData.selectedReasonOption = event?.currentSelection[0]?.label;
    if (selectedOption === 'CVA') {
      this.accountDetailsForm.controls['cva'].setValidators([Validators.required, Validators.pattern('[0-9]*')]);
      this.enableCVAfield = true;
      this.selectReasonField = false;
      if (this.cvaNumberEmpty) this.edateDisabled = false;
      else this.edateDisabled = true;
    } else if (selectedOption === 'Other') {
      this.accountDetailsForm.controls['saleReasonField'].setValidators([Validators.required]);
      this.enableCVAfield = false;
      this.selectReasonField = true;
      this.edateDisabled = true;
    } else {
      this.enableCVAfield = false;
      this.selectReasonField = false;
      this.edateDisabled = true;
    }
    this.accountDetailsForm.controls['expirydate'].setValue('');
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  searchByPrefix(event: any) {
    if (event.value !== '') {
      this.enableSearchOverlay = true;
      this.changeDetectorRef.detectChanges();
      if (this.prefixSearchHelpText !== '') {
        this.prefixSearchHelpText = '';
      }
      this.prefixNotFound = false;
      // this.prevSearchPrefix = this.prefix;
      if (
        event.value &&
        event.value.length > 0 &&
        !this.isListItemSelected &&
        !this.salesReasonLoader &&
        !this.edateDisabled &&
        this.prefixLoader
      ) {
        this.searchPrefixItems = [];
        this.prefixLoader = true;
        this.prefixLoading = true;
        const searchParam = { dealerCode: this.dealer.dealerCode, prefixCode: event.value, limit: 50, next: 0 };
        this.fetchPrefixDetails(searchParam);
      }
    } else if (event.value === '') {
      this.prefixSearchHelpText = 'Start Typing Prefix (Min 1 char)';
      this.enableSearchOverlay = false;
      this.changeDetectorRef.detectChanges();
      this.prefixLoader = this.customer != '' && this.customerUCID != '' ? true : false;
      this.prefixLoading = false;
      this.salesReasonLoader = false;
      this.selectedData.prefix = event.value;
      this.edateDisabled = false;
      this.prefixNotFound = false;
      this.isListItemSelected = false;
      this.selectedData.isValidForm = false;
      this.accountDetailsForm.controls['prefix'].setErrors({ error: true });
      this.subscribeAccountsService.setAccountFormData(this.selectedData);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  fetchPrefixDetails = debounce((searchParam: any) => {
    if (this.prefix === searchParam.prefixCode && this.prefix?.length > 0) {
      this.changeDetectorRef.detectChanges();
      this.getSearchTextPrefixDetails(searchParam);
    }
  }, 1000);

  getSearchTextPrefixDetails(searchParam: any) {
    this.subscribeAccountsService.validatePrefix(searchParam).subscribe({
      next: (res: any) => {
        const dealerPrefix = res.prefix ? res.prefix : false;
        if (dealerPrefix && dealerPrefix.length > 0) {
          this.subscribeAccountsService.setAccountFormData(this.selectedData);
          this.searchPrefixItems = dealerPrefix.map(function (item: any) {
            return {
              label: item.prefixCode,
              value: item,
            };
          });

          this.prefixLoading = false;
        } else {
          this.updatePrefixNoResults();
        }
        this.prefixLoader = true;
        this.changeDetectorRef.markForCheck();
      },
      error: (_err) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
        this.prefixLoader = false;
      },
    });
  }

  prefixListItemClick(index: any) {
    this.isListItemSelected = true;
    this.prefixLoader = true;
    this.salesReasonLoader = true;
    this.edateDisabled = this.selectedData?.expirationDate ? true : false;
    this.prefix = this.searchPrefixItems[index]['label'];
    this.selectedData.prefix = this.prefix;
    if (this.accountDetailsForm.valid) {
      this.selectedData.isValidForm = true;
    }
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  updatePrefixNoResults() {
    this.prefixNotFound = true;
    this.accountDetailsForm.controls['prefix'].setErrors({ error: true });
    this.resetOverlayBox();
  }
}
