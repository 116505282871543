import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NeedsReviewSharedService {
  constructor() {}

  appliedReviewFilters = new Subject();
  selectedChipStatus = new Subject();
  dataLoaded = new Subject();
  showLoader = false;
  downloadCompleted = new Subject();
  sortingValuesExport = new Subject();
}
