import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  public errorText =
    'There was an error while processing your request. Please try again after sometime';
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }
  getFilters(dealerCode: string) {
    return this.http.get(this.rootURL + `/site/getFilters/${dealerCode}`);
  }
}
