import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';

@Component({
  selector: 'dsp-next-gen-ui-billing-row-expansion',
  templateUrl: './billing-row-expansion.component.html',
  styleUrls: ['./billing-row-expansion.component.scss'],
})
export class BillingRowExpansionComponent implements OnInit {
  dealer: any;
  fileType: any;
  creditMemonumber: any;
  constructor(private billingService: BillingService, private messageBar: MessageBar, private cdr: ChangeDetectorRef) {}
  displayInfo: any = [];
  updatedData: any = [];
  @Input() tableData: any;
  @Input() customCellSearch = '';
  @Output() expansionRowEvent = new EventEmitter();
  showLoader = false;
  listItems = [
    {
      label: 'Download PDF',
      selected: true,
    },
    {
      label: 'Download Excel',
    },
  ];
  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.displayInfo = this.tableData;
  }

  onListItemClick(rowData: any, value?: any) {
    const item = value.split(' ');
    this.fileType = item[1];
    this.displayInfo?.creditMemos.map((item: any) => {
      if (rowData.creditMemoNumber === item.creditMemoNumber) {
        item['isSelected'] = true;
      }
    });
    this.cdr.detectChanges();
    this.uploadFile(this.fileType, rowData);
  }

  onClickMemo(creditMemoNumber: any) {
    this.creditMemonumber = creditMemoNumber;
  }

  uploadFile(type: string, row: any) {
    let creditUrl;
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    switch (type) {
      case 'PDF':
        creditUrl = '/zuoraBilling/exportCreditMemoDetailsPDF';
        const params = {
          creditMemoNumber: this.creditMemonumber,
          dealerCode: this.dealer?.dealerCode,
        };
        this.downloadCreditMemoPDF(creditUrl, params, row);
        break;
      case 'Excel':
        creditUrl = '/zuoraBilling/exportCreditMemoDetailsToExcel';
        const payload = {
          creditMemoNumber: this.creditMemonumber,
          sortColumn: 'customer',
          sortOrder: 'ASC',
        };
        this.downloadCreditMemoExcel(creditUrl, payload, row);
        break;
      default:
        break;
    }
  }

  downloadCreditMemoPDF(url: string, inputData: any, row: any) {
    this.showLoader = true;
    this.billingService.exportCreditMemoPDF(url, inputData).subscribe(
      (res: any) => {
        const pdfContent = res.pdfContent;
        const bytes = new Uint8Array(
          atob(pdfContent)
            .split('')
            .map((char) => char.charCodeAt(0))
        );
        const blob = new Blob([bytes.buffer], { type: 'application/pdf' });
        const fileName = this.creditMemonumber;
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName + '.pdf';
        link.click();
        URL.revokeObjectURL(fileUrl);
        this.showLoader = false;
        this.displayInfo?.creditMemos.map((item: any) => {
          if (row.creditMemoNumber === item.creditMemoNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      },
      (err) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.displayInfo?.creditMemos.map((item: any) => {
          if (row.creditMemoNumber === item.creditMemoNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      }
    );
  }

  downloadCreditMemoExcel(url: string, inputData: any, row: any) {
    this.showLoader = true;
    this.billingService.exportCreditMemoExcel(url, inputData).subscribe(
      (res: any) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        var base64str, fileName, type;
        base64str = res.entity;
        fileName = res.metadata.fileName[0];
        type = res.metadata['content-type'][0];

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        var blob = new Blob([view], { type: type });
        const csvUrl = URL.createObjectURL(blob);
        a.href = csvUrl;
        a.download = fileName;
        a.target = '_parent';
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
        this.showLoader = false;
        this.displayInfo?.creditMemos.map((item: any) => {
          if (row.creditMemoNumber === item.creditMemoNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      },
      (err) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.displayInfo?.creditMemos.map((item: any) => {
          if (row.creditMemoNumber === item.creditMemoNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      }
    );
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
