export interface PendingRequestFormData {
  isValidForm: boolean;
  customer?: any;
  vlRequestDetails: VLRequestData;
  newSubscriptionDetails?: SelfServeSubscriptionData;
  oldSubscriptionDetails?: Array<SelfServeSubscriptionData>;
  status: string;
  declineReason?: string;
  reportingState?: string;
  statusChanged?: boolean;
}

export interface VLRequestData {
  dealerCode?: string;
  ucid?: string;
  application?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  date_time?: string;
  type?: string;
  plan_name?: string;
}

export interface SelfServeSubscriptionData {
  applicationName?: string;
  basePlan?: PlanData;
  optionalPlan?: Array<PlanData>;
  promotion?: Array<PromotionData>;
  prepay?: boolean;
  prepayDates?: PrepayData;
  monthlyRate?: string;
  monthlyRateAfterDiscount?: string;
  cancelNotificationMessage?: string;
  paymentPreviewResponse?: any;
}

export interface PlanData {
  name?: string;
  amount?: string;
}

export interface PromotionData {
  name?: string;
  amount?: string;
  end_date?: string;
}

export interface PrepayData {
  contractStartDate?: string;
  contractEndDate?: string;
  duration?: string;
}
