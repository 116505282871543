<div class="cards asset-details-card">
  <cc-card>
    <cc-card-content>
      <div>
        <div class="header">
          <div class="header-text">
            <div class="header-text-alignment">
              DEVICE INFORMATION
              <span style="padding-left: 7px !important">
                <ng-container *ngIf="connectionStatus?.length === 0">
                  <cc-icon
                    cc-tooltip
                    [template]="connectionCheckTooltip"
                    [appClass]="'-connection-check-tooltip'"
                    [tooltipPosition]="'bottom'"
                    class="asset-header-icons"
                    *ngIf="showConnectionCheckIcon"
                    (click)="checkConnection(rowData.make, rowData.serialNumber)"
                    >connection-check
                  </cc-icon>
                </ng-container>
                <ng-container *ngFor="let status of connectionStatus">
                  <ng-container *ngIf="showConnectionCheckIcon && status.eq_sn === rowData.serialNumber">
                    <cc-icon
                      cc-tooltip
                      [template]="connectionCheckTooltip"
                      [appClass]="'-connection-check-tooltip'"
                      [tooltipPosition]="'bottom'"
                      (click)="checkConnection(rowData.make, rowData.serialNumber)"
                      *ngIf="status.connection_status === 'success' || status.connection_status === 'failure'"
                      class="connection-check"
                      [style.color]="getColor(status.connection_status)"
                      >connection-check
                    </cc-icon>
                    <dsp-next-gen-ui-spinner *ngIf="status.connection_status === 'pending'" class="drawer-pending">
                    </dsp-next-gen-ui-spinner>
                  </ng-container>
                </ng-container>
                <ng-template #connectionCheckTooltip>
                  <span class="lastcheckin-tooltip">Last Check In: </span><br />
                  <dsp-next-gen-ui-spinner *ngIf="isDeviceStatusAPIInProgress" class="status-pending">
                  </dsp-next-gen-ui-spinner>
                  <span *ngIf="this.lastCheckinValue !== '' && !isDeviceStatusAPIInProgress">
                    <span class="lastcheckin-tooltip">{{ lastCheckinValue }}</span>
                  </span>
                  <span *ngIf="this.lastCheckinValue === '' && !isDeviceStatusAPIInProgress"> Not Available </span>
                </ng-template>
                <span *ngIf="showConnectionCheckIcon && showConfigureWifiIcon" class="pipe">|</span>
                <cc-icon
                  [cc-tooltip]="wifiToolTip"
                  (click)="openWifiConfiguration()"
                  class="asset-header-icons wifi"
                  *ngIf="showConfigureWifiIcon"
                  >wifi</cc-icon
                >
                <span *ngIf="(showConnectionCheckIcon || showConfigureWifiIcon) && showDeviceStatusIcon" class="pipe"
                  >|</span
                >
                <img
                  [cc-tooltip]="settingsToolTip"
                  class="asset-header-icons device-status"
                  [tooltipPosition]="'right'"
                  *ngIf="showDeviceStatusIcon"
                  src="..\assets\images\settings.svg"
                  (click)="openDeviceStatus()"
                />
              </span>
            </div>
            <div
              class="details-alignment"
              *ngIf="assetInfo?.deviceSerialNumer && assetInfo?.radioSerialNoList?.length >= 0"
            >
              <span cc-btn-tertiary cc-btn-large [setTheme]="true" (click)="openDeviceInfo()">Details</span>
            </div>
          </div>
        </div>
        <div class="details-container">
          <div class="detail-tiles" *ngIf="showTruncatedText" (copy)="copyEvent($event, deviceInformation())">
            <span>Device S/N:</span>
            <span
              class="detail-value"
              *ngIf="!assetInfo?.deviceSerialNumer && assetInfo?.radioSerialNoList?.length <= 0"
              >-</span
            >
            <ng-container *ngIf="deviceInfoTrimmed()?.length >= 34">
              <span
                *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                class="detail-value"
                cc-tooltip
                [template]="deviceInfo_tooltip"
                [tooltipPosition]="'top'"
                [innerHTML]="deviceInfoTrimmed()"
              ></span>
              <span
                *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                class="detail-value"
                cc-tooltip
                [template]="deviceInfo_tooltip"
                [tooltipPosition]="'top'"
                [innerHTML]="deviceInfoTrimmed()"
              ></span>
            </ng-container>
            <ng-container *ngIf="deviceInfoTrimmed()?.length < 34">
              <span
                *ngIf="assetInfo?.deviceSerialNumer && !isReplaceInfoIcon"
                class="detail-value"
                cc-tooltip
                [tooltipPosition]="'top'"
                [innerHTML]="deviceInfoTrimmed()"
              ></span>
              <span
                *ngIf="assetInfo?.deviceSerialNumer && isReplaceInfoIcon"
                class="detail-value"
                cc-tooltip
                [tooltipPosition]="'top'"
                [innerHTML]="deviceInfoTrimmed()"
              ></span>
            </ng-container>
            <ng-template #deviceInfo_tooltip>
              <div style="width: 275px; white-space: normal">
                <span>Device S/N: </span>
                <span>{{ deviceInformation() }}</span>
              </div>
            </ng-template>
            <cc-icon
              *ngIf="(assetInfo?.deviceSerialNumer || assetInfo?.radioSerialNoList?.length > 0) && !isReplaceInfoIcon"
              class="asset-details-icon-size"
              >info-tooltip</cc-icon
            >
          </div>
          <div *ngIf="assetInfo?.assetFeedInfoDTO" class="detail-tiles">
            <span>Provider Name:</span
            ><span class="detail-value">{{ assetInfo?.assetFeedInfoDTO.providerName || '-' }}</span>
          </div>
          <div class="detail-tiles" *ngIf="assetInfo?.assetFeedInfoDTO">
            <span>Integration Name:</span
            ><span class="detail-value">{{ assetInfo?.assetFeedInfoDTO.feedName || '-' }}</span>
          </div>
          <div
            class="detail-tiles"
            *ngIf="
              deviceFeaturesInfo &&
              deviceFeaturesInfo[assetInfo?.deviceType] &&
              deviceFeaturesInfo[assetInfo?.deviceType].Report_Type &&
              assetInfo?.deviceSerialNumer &&
              (assetInfo?.deviceSerialNumer.endsWith('005F') || assetInfo?.deviceSerialNumer.endsWith('005f'))
            "
          >
            <span class="detail-tiles">PLCN1 Report Type: </span>
            <span class="detail-value"
              >{{
                assetInfo?.reportType ? assetInfo?.reportType : deviceFeaturesInfo[assetInfo?.deviceType].Report_Type[0]
              }}
            </span>
            <span *ngIf="!assetInfo?.suspended && assetInfo?.enableSubscription && assetInfo?.deviceType">
              <img
                src="..\assets\images\pencil-new.svg"
                *ngIf="!roleFlag"
                (click)="updateReportType()"
                class="edit-pencil-icon"
              />
            </span>
          </div>
          <div class="detail-tiles align-flex" *ngIf="showPartNumber">
            <div class="asset-details-row-label">Attachment P/N:</div>
            <div class="asset-part-number">{{ assetInfo?.partNumber || '' }}</div>
            <img
              src="..\assets\images\pencil-new.svg"
              *ngIf="editPartNumber"
              (click)="updatePartNumber()"
              class="edit-pencil-icon"
            />
          </div>
          <div class="detail-tiles gps-status" *ngIf="assetInfo?.enableGpsWarning && assetInfo?.enableSubscription">
            <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
            <span class="gps-status-body detail-value">GPS Time Rollover Status</span>
            <cc-icon
              [template]="tooltipTemplateForGps"
              [cc-tooltip]="connectionCheckToolTip"
              [interactive]="true"
              [appClass]="'-gps-tool-tip'"
              class="asset-details-icon-size"
              [tooltipPosition]="'bottom'"
              >info-tooltip</cc-icon
            >
          </div>
          <div class="detail-tiles" *ngIf="showParameterConfigUpload">
            <a class="config-upload-link" (click)="openParameterConfigUploadDrawer()"
              >PL444 Parameter Configuration Upload</a
            >
          </div>
          <div class="detail-tiles" *ngIf="isChinaSupportedDevice">
            <div *ngIf="assetInfo.notes?.length > 0">
              <img src="../../../assets/images/info-group.svg" class="info-icon-style info-group" />&nbsp;
              <span class="china-info">{{ assetInfo.notes[0]?.displayMessage || '' }} </span>
            </div>
          </div>
          <ng-template #tooltipTemplateForGps>
            <div class="gps-tooltip-header">
              Device Impacted by GPS Time Rollover
              <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
            </div>
            <div class="gps-tooltip-body">
              <span
                >Device requires update due to GPS Time Rollover issue. Please review support documentation on
                <a target="_blank" href="https://catdealer.com" style="color: #2595ff; text-decoration: underline"
                  >catdealer.com</a
                >
                for further instructions before managing subscriptions.
              </span>
            </div>
          </ng-template>
        </div>
      </div>
    </cc-card-content>
  </cc-card>
</div>
