import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { of, Subject } from 'rxjs';
import { isEmpty } from 'lodash-es';
import * as dspConstants from '../../../shared/dspConstants';
import {
  getAssetsCountPayload,
  getAssetsPayload,
  getCustomersCountPayload,
  getCustomersPayload,
} from '../home.interface';
import {
  assetsHomeMockData,
  assetsCountMockData,
  customersMockData,
  customerCountMockData,
  assetsEmptyMockData,
  ownershipData,
  recentlyViewAssets,
} from '../home-mock-data';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  rootURL = '';
  searchType: string = '';
  dspWorklistConstants = dspConstants.Worklist;
  dealer: any;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  dealerCode: any;
  serviceData: any;
  constructor(
    private http: HttpClient,
    private store: Store<DSPAppState>
  ) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.store.select('dsp').subscribe((dsp: any) => {
      this.userActiveFeatures = dsp?.userActiveFeatures;
      this.deviceFeaturesInfo = dsp?.deviceFeaturesInfo;
      this.dealer = fetchLoggedInDealerDetails();
      this.dealerCode = this.dealer.dealerCode;
    });
  }

  setServiceData(data: any) {
    this.serviceData = data;
  }
  getServiceData() {
    return this.serviceData;
  }
  getAssets(body: getAssetsPayload) {
    return this.http.post(this.rootURL + '/home/assetSearch', body);
    //return of(assetsHomeMockData);
  }
  getAssetsCount(body: getAssetsCountPayload) {
    // return of(assetsCountMockData);
    return this.http.post(this.rootURL + '/home/assetSearchCount', body);
  }
  getCustomers(body: getCustomersPayload) {
    //return of(customersMockData);
    return this.http.post(this.rootURL + '/home/customerSearch', body);
  }
  getCustomerCount(body: getCustomersCountPayload) {
    // return of(customerCountMockData);
    return this.http.post(this.rootURL + '/home/customerSearchCount', body);
  }
  checkValidOwnership(payload: any) {
    //return of(ownershipData);
    return this.http.post(this.rootURL + '/home/checkValidOwnership', payload);
  }
  advancedAssetSearch(payload: any) {
    return this.http.post(this.rootURL + '/home/advancedAssetSearch', payload);
  }
  getScrollableMenu(asset: any, responseVal: any) {
    this.validateManagePlan(asset, responseVal);
  }
  validateManagePlan(asset: any, responseVal: any) {
    asset.enableManagePlan = responseVal.enableManagePlan ? true : false;
    asset.hasValidOwnership = responseVal.hasValidOwnership ? true : false;
    asset.isMaskRequired = !responseVal.enableManagePlan;
    if (
      asset?.reportingState === this.dspWorklistConstants.SUSPENDED ||
      !asset.enableManagePlan ||
      (this.userActiveFeatures?.features?.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures?.features?.indexOf('View Only') > -1 ||
          this.userActiveFeatures?.features?.indexOf('Read Only') > -1 ||
          this.userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1))
    ) {
      asset.showManagePlan = false;
    } else {
      asset.showManagePlan = true;
    }

    this.validateUpdateRegistration(asset);
    this.validateDeviceStatus(asset);
    this.validateWiFiConfiguration(asset);
  }
  validateDeviceStatus(assetInfo: any) {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Device Status') > -1 &&
      (isEmpty(assetInfo?.dealerCode) || assetInfo?.dealerCode == this.dealer?.dealerCode) && // enableSubscriptionFlag
      assetInfo?.deviceType !== 'NO_DEVICE' &&
      !isEmpty(assetInfo?.deviceType) &&
      assetInfo?.deviceType !== '-' && //isTelematicsRegistered Flag
      this.deviceFeaturesInfo &&
      !this.deviceFeaturesInfo[assetInfo?.deviceType]?.Disable_Device_Status &&
      assetInfo?.reportingState !== dspConstants.Worklist.SUSPENDED &&
      assetInfo.enableManagePlan
    ) {
      assetInfo.showDeviceStatusIcon = true;
    } else {
      assetInfo.showDeviceStatusIcon = false;
    }
  }
  validateUpdateRegistration(assetInfo: any) {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Product ID') !== -1 &&
      assetInfo &&
      assetInfo.deviceType &&
      this.deviceFeaturesInfo[assetInfo?.deviceType] &&
      !this.deviceFeaturesInfo[assetInfo?.deviceType].Disable_Product_ID &&
      assetInfo.reportingState === this.dspWorklistConstants.UNSUBSCRIBED &&
      assetInfo.deviceType !== 'PRINTUP' &&
      assetInfo.deviceType !== 'NO_DEVICE' &&
      assetInfo.enableManagePlan
    ) {
      assetInfo.showUpdateRegistration = true;
    } else {
      assetInfo.showUpdateRegistration = false;
    }
  }
  validateWiFiConfiguration(assetInfo: any) {
    if (
      assetInfo?.reportingState != dspConstants.Worklist.SUSPENDED &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Wifi') > -1 &&
      this.getWifiConfigurationType(assetInfo) !== '' &&
      assetInfo.enableManagePlan 
    ) {
      assetInfo.showConfigureWifiIcon = true;
    } else {
      assetInfo.showConfigureWifiIcon = false;
    }
  }
  getWifiConfigurationType(assetData: any) {
    const featureSupportMsg = assetData.getSupportedServicesList ? assetData.getSupportedServicesList : [];
    const wifiSimultaneous = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_SIMULTANEOUS) > -1;
    const wifiHotSpot = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_HOTSPOT) > -1;
    const wifiInfrastructure = featureSupportMsg.indexOf(dspConstants.Worklist.WIFI_INFRASTRUCTURE) > -1;

    if (wifiSimultaneous && wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_AND_HOTSPOT;
    } else if (wifiHotSpot && wifiInfrastructure) {
      return dspConstants.Worklist.WIFI_CLIENT_OR_HOTSPOT;
    } else if (wifiInfrastructure && !wifiHotSpot && !wifiSimultaneous) {
      return dspConstants.Worklist.WIFI_CLIENT;
    }
    return '';
  }
  getRecentlyViewAssets(queryParams: any) {
    return this.http.get(this.rootURL + '/home/retrieveLatest', {
      params: queryParams,
    });
  }
  saveRecentlyViewAssets(payload: any) {
    return this.http.post(this.rootURL + '/home/saveRecent', payload);
  }
}
