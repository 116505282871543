// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
interface Environment {
  local: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  int: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  qa_dsp_sideb: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };

  qa2_dsp_sideb: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  qa2: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  qa: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  beta: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  dsp_sideb: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
  prod: {
    hostBaseUrl: string;
    apiUrl: string;
    loginUrl: string;
    logoutUrl: string;
    helpDocUrl: string;
    edUrl: string;
    datUrl: string;
    b2c: boolean;
  };
}
const environments: Environment = {
  local: {
    hostBaseUrl: 'http://localhost:4200/',
    apiUrl: 'https://int-bff-dsp.cat.com',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },

  int: {
    hostBaseUrl: 'https://int-dsp.cat.com/',
    apiUrl: 'https://int-bff-dsp.cat.com',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },

  qa_dsp_sideb: {
    hostBaseUrl: 'https://qa-dsp-sideb.cat.com/',
    apiUrl: 'https://dspservice-qa-staging.azurewebsites.net',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },

  qa2_dsp_sideb: {
    hostBaseUrl: 'https://qa2-dsp-sideb.cat.com',
    apiUrl: 'https://dspservice-qa2-staging.azurewebsites.net',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },
  qa2: {
    hostBaseUrl: 'https://qa2-dsp.cat.com',
    apiUrl: 'https://dspservice-qa2.azurewebsites.net',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },
  qa: {
    hostBaseUrl: 'https://qa-dsp.cat.com/',
    apiUrl: 'https://qa-bff-dsp.cat.com',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },
  beta: {
    hostBaseUrl: 'https://beta-dsp.cat.com/',
    apiUrl: 'https://beta-bff-dsp.cat.com',
    loginUrl: 'https://fedloginqa.cat.com',
    logoutUrl: 'https://loginq.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm--crmiuat.sandbox.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.rd.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },
  dsp_sideb: {
    hostBaseUrl: 'https://dsp-sideb.cat.com',
    apiUrl: 'https://dspservice-prod-staging.azurewebsites.net',
    loginUrl: 'https://fedlogin.cat.com',
    logoutUrl: 'https://login.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.cat.com',
    datUrl: 'https://digitalauthorizationqa.cat.com',
    b2c: true,
  },
  prod: {
    hostBaseUrl: 'https://dsp.cat.com',
    apiUrl: 'https://prod-bff-dsp.cat.com',
    loginUrl: 'https://fedlogin.cat.com',
    logoutUrl: 'https://login.cat.com/cgi-bin/logout',
    helpDocUrl: 'https://catcrm.my.site.com/HelpCenterDSP/s/',
    edUrl: 'https://equipmentdata.cat.com',
    datUrl: 'https://digitalauthorizationtool.cat.com',
    b2c: true,
  },
};
let env: any;

function getConfig() {
  const url = window.location.href;

  for (const key in environments) {
    const envKey = key.split('_').join('-');
    if (url.includes(envKey)) {
      env = key;
      break;
    }
  }
  return env ? environments[env as keyof Environment] : environments['prod'];
}
export const environment = {
  production: env === 'local' ? false : true,

  redirectURL: getConfig(),

  env: env || 'prod',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
