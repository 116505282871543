import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { getAssetDataSubscribedPlansEditResponse } from 'apps/dsp-ui/src/app/store/actions/dspcommon.action';
@Component({
  selector: 'dsp-next-gen-ui-plan-details-drawer',
  templateUrl: './plan-details-drawer.component.html',
  styleUrls: ['./plan-details-drawer.component.scss'],
})
export class PlanDetailsDrawerComponent implements OnInit {
  title = '';
  data: any;
  columnData: any;
  isPlanDetailsDrawerClosed: any;
  subscribedPlansData: any;
  isMultiProductWorkFlow: any;
  expandDropdown = true;
  expanDropDown: any = {};
  seemoreOverlayUpdate = false;
  storedData: any;
  dspStoreData!: any;
  accordionLabel: any;
  telUserType: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      columnData: string;
      title: string;
      rowData: any;
      isMultiProductWorkFlow: any;
    },
    public dialogRef: CcDrawerRef<PlanDetailsDrawerComponent>,
    private assetDrawerService: AssetDrawerService,
    private assetService: AssetService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.storedData = this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        ? this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        : {};

      this.seemoreOverlayUpdate = this.dspStoreData?.featureFlagInfo.seeMoreOverlayUpdate
        ? dsp.featureFlagInfo.seeMoreOverlayUpdate
        : false;
    });
    this.title = this.header.title ? this.header.title : '';
    this.data = this.header.rowData ? this.header.rowData : '';
    this.columnData = this.header.columnData ? this.header.columnData : '';
    this.assetDrawerService.isPlanDetailsDrawerClosed.subscribe((data: any) => {
      this.isPlanDetailsDrawerClosed = data;
    });
    if (this.data?.subscribedProducts && this.data?.subscribedProducts >= 2) {
      let assetIdValue: any = this.storedData[this.data.assetId];
      if (this.storedData?.hasOwnProperty(this.data.assetId)) {
        this.subscribedPlansData = assetIdValue.servicesSubscribedDTO;
        for (const [key, value] of Object.entries(this.subscribedPlansData)) {
          let productObj: any = value;
          this.expanDropDown[productObj.appName] = true;
        }
      } else {
        this.assetService.getAssetDataSubscribedPlansEdit(this.header.rowData).subscribe((data: any) => {
          this.storedData[data.assetId] = data;
          this.store.dispatch(getAssetDataSubscribedPlansEditResponse(this.storedData));
          this.subscribedPlansData = data.servicesSubscribedDTO;
          for (const [key, value] of Object.entries(this.subscribedPlansData)) {
            let productObj: any = value;
            this.expanDropDown[productObj.appName] = true;
          }
        });
      }
      // this.subscribedPlansData = this.data?.subscribedPlans?.subscribedMultiProductApps;
    }
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
    this.isMultiProductWorkFlow = this.header?.isMultiProductWorkFlow ? this.header?.isMultiProductWorkFlow : '';
    this.accordionLabel = 'Collapse All';
  }

  closeModal() {
    if (this.isPlanDetailsDrawerClosed) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }

  parseAssetNameData(rowData: any) {
    let assetInfo = '';
    if (rowData) {
      const year = rowData.modelYear ? rowData.modelYear : rowData.manufacturingYear ? rowData.manufacturingYear : '';
      assetInfo += rowData.make ? rowData.make : '';
      assetInfo += rowData.model ? ' - ' + rowData.model : '';
      assetInfo += year ? ' - ' + year : '';
      return assetInfo;
    } else {
      return '';
    }
  }

  getColumnToolTipData() {
    if (this.data) {
      return this.getAssetSerialNumber(this.data) + ' ' + this.parseAssetNameData(this.data);
    } else {
      return '';
    }
  }
  getOptionalPlanReturn(customerLevel: any) {
    let isData = false;
    customerLevel.find((item: any) => {
      if (item.type == 'Addon' || (item.planName && !item.type)) {
        isData = true;
      }
    });
    return isData;
  }

  acordionLabelChange(label: any) {
    label === 'Collapse All' ? (this.accordionLabel = 'Expand All') : (this.accordionLabel = 'Collapse All');
  }

  toggle(expandDropdown: any) {
    this.acordionLabelChange(this.accordionLabel);
    for (const [key, value] of Object.entries(this.expanDropDown)) {
      if (expandDropdown) {
        this.expanDropDown[key] = false;
      } else {
        this.expanDropDown[key] = true;
      }
    }
    this.expandDropdown = !this.expandDropdown;
  }

  closeService(product: any, accordionState: any) {
    if (accordionState === 'open') {
      this.expanDropDown[product] = true;
      if (Object.values(this.expanDropDown).every((item) => item === true) && this.accordionLabel === 'Expand All') {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
    } else if (accordionState === 'close') {
      this.expanDropDown[product] = false;
      if (Object.values(this.expanDropDown).every((item) => item === false) && this.accordionLabel === 'Collapse All') {
        this.expandDropdown = !this.expandDropdown;
        this.acordionLabelChange(this.accordionLabel);
      }
    }
  }

  getAssetSerialNumber(rowData: any) {
    let assetSerialNo = '';
    if (rowData) {
      const engineSN = rowData.engineSerialNumber
        ? rowData.engineSerialNumber
        : rowData.engineSerialNo
        ? rowData.engineSerialNo
        : '';
      assetSerialNo += rowData.serialNumber;
      assetSerialNo += engineSN ? ' | ' + engineSN : '';
      return assetSerialNo;
    } else {
      return '';
    }
  }
}
