import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { userDetails } from './../accounts-interface';

@Component({
  selector: 'dsp-next-gen-ui-additional-cws-id',
  templateUrl: './additional-cws-id.component.html',
  styleUrls: ['./additional-cws-id.component.scss'],
})
export class AdditionalCwsIdComponent implements OnInit {
  @Input() public listItems: Array<userDetails> = [];
  headerContent: string = '';
  public columnWithHeader: any;
  searchString = '';
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    public dialogRef: CcDrawerRef<AdditionalCwsIdComponent>,
    @Inject(CC_DRAWER_DATA) private header: { headerType: string; searchString: string; ref: any },
    @Inject(CC_DRAWER_DATA) private list: { listItems: Array<userDetails> },

    @Inject(CC_DRAWER_DATA)
    private output: {
      apply: Function;
      cancel: Function;
    }
  ) {}

  ngOnInit(): void {
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.listItems = this.list.listItems.sort((firstArr, secondArr) => {
      const x = firstArr.firstName ? firstArr.firstName.toLowerCase() : '';
      const y = secondArr.firstName ? secondArr.firstName.toLowerCase() : '';
      return x < y ? -1 : x > y ? 1 : 0;
    });
    this.searchString = this.header?.searchString;
  }

  onListItemCLick(_item: any) {}

  closeModal() {
    this.dialogRef.close();
  }
  backToViewDetails() {
    this.dialogRef.close();
  }
  closeCwsIdDrawer() {
    this.dialogRef.close();
    this.header.ref.close();
  }
}
