import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  rootURL = '';
  dealer: any;
  openFilterClicked: BehaviorSubject<boolean>;
  applyFilterClicked: BehaviorSubject<boolean>;
  subscrapplyFilterClicked: BehaviorSubject<boolean>;
  selectedFilters: any[] | undefined;
  openFilterClickedCancellation: BehaviorSubject<boolean>;
  openSubscribeTrendClicked: BehaviorSubject<boolean>;
  selectedFiltersMonth: any;
  selectedFiltersProduct: any;

  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.dealer = fetchLoggedInDealerDetails();
    this.openFilterClicked = new BehaviorSubject<boolean>(false);
    this.openFilterClickedCancellation = new BehaviorSubject<boolean>(false);
    this.openSubscribeTrendClicked = new BehaviorSubject<boolean>(false);
    this.applyFilterClicked = new BehaviorSubject<boolean>(false);
    this.subscrapplyFilterClicked = new BehaviorSubject<boolean>(false);
    // this.selectedFilters = new BehaviorSubject<boolean>(false);
  }

  getWorklistQueue(dealerCode: any) {
    return this.http.get(this.rootURL + `/dealerqueue/getWorkListQueue?dealerCode=${dealerCode}&version=v2`);
  }

  getCancellationReasons(dealerCode: any) {
    return this.http.get(this.rootURL + `/dashboard/getCancelReasonCount?dealerCode=${dealerCode}`);
  }
  getWidgets(dealerCode: any) {
    return this.http.get(this.rootURL + `/dashboard/widgets?dealerCode=${dealerCode}&version=2`);
  }
  getTrendWidgetDetails(dealerCode: any) {
    return this.http.get(this.rootURL + `/dashboard/getTrendWidgetDetalis?dealerCode=${dealerCode}`);
  }
  getSubscriptionAssetTrend(inputData: any) {
    return this.http.post(this.rootURL + '/dashboard/getSubscriptionAssetTrend', inputData);
    //return this.http.post('https://int-dsp.cat.com/dashboard/getSubscriptionAssetTrend', inputData);
  }
  getFilteredSubscriptionAssetTrend(inputData: any) {
    return this.http.post(this.rootURL + '/dashboard/getFilteredSubscriptionAssetTrend', inputData);
  }
  getConnectivitySummary(dealerCode: any) {
    return this.http.get(this.rootURL + `/dashboard/connectivitySummary?dealerCode=${dealerCode}`);
  }
  getAvailableWidgets(dealerCode: any) {
    return this.http.get(this.rootURL + `/dashboard/widgets?dealerCode=${dealerCode}`);
  }
  getbar() {
    return this.selectedFilters;
  }
  setbar(value: any) {
    this.selectedFilters = value;
  }
  getMonth() {
    return this.selectedFiltersMonth;
  }
  setMonth(value: any) {
    this.selectedFiltersMonth = value;
  }
  getProduct() {
    return this.selectedFiltersProduct;
  }
  setProduct(value: any) {
    this.selectedFiltersProduct = value;
  }
}
