<div class="cc-drawer__header">
  <span class="cc-headerText cc-drawer__ellipsis">
    {{ invoiceDet?.assetSerialNumber }} |{{ invoiceDet?.application }}</span
  >
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</div>
<div class="detailed-main">
  <div class="data-block">
    <div class="detail-align-flex">
      <div class="detailCls" *ngIf="!isDealerCustomerAssetID">
        <div class="detailLabelCls">Asset ID:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.assetId }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ appDetailView?.assetId }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.assetId }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="appDetailView?.assetId"
        >
          {{ appDetailView?.assetId }}
        </div>
      </div>
      <div class="detailCls dealerCustWidth" *ngIf="isDealerCustomerAssetID">
        <div class="detailLabelCls">Customer / Dealer Asset ID:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ getAssetId() }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ getAssetId() }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ getAssetId() }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ getAssetId() }}
        </div>
      </div>
      <div class="detailCls">
        <div class="detailLabelCls">Dealer Customer Number:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ invoiceDet?.dcn }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ invoiceDet?.dcn || '-' }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ invoiceDet?.dcn }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="invoiceDet?.dcn"
        >
          {{ invoiceDet?.dcn || '-' }}
        </div>
      </div>
      <div class="detailCls">
        <div class="detailLabelCls">Device S/N:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.deviceSerialNumber }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ appDetailView?.deviceSerialNumber || '-' }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.deviceSerialNumber }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="appDetailView?.deviceSerialNumber"
        >
          {{ appDetailView?.deviceSerialNumber || '-' }}
        </div>
      </div>
      <div class="detailCls">
        <div class="detailLabelCls">Discount Amount:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.discountAmount }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ appDetailView?.discountAmount || '-' }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.discountAmount }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="appDetailView?.discountAmount"
        >
          {{ appDetailView?.discountAmount || '-' }}
        </div>
      </div>
      <div class="detailCls">
        <div class="detailLabelCls">Tax:&nbsp;</div>
        <div
          *ngIf="!showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.taxAmount }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
        >
          {{ appDetailView?.taxAmount || '-' }}
        </div>
        <div
          *ngIf="showTruncatedText"
          class="detailedTooltipCls"
          cc-tooltip="{{ appDetailView?.taxAmount }}"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="appDetailView?.taxAmount"
        >
          {{ appDetailView?.taxAmount || '-' }}
        </div>
      </div>
    </div>
  </div>
  <div class="applicationDetailedView-table">
    <cc-table
      [ngClass]="{ disable: isTableLoading }"
      #appdetailviewTableRef
      aria-describedby="applicationDetailedView-table"
      [tableConfig]="billingDetailedTableConfig"
      [tableData]="billingDetailTableData"
      (ccTableEvents)="tableEvents($event)"
      [isDataLoading]="isTableLoading"
    >
      <cc-table-skeleton *ngIf="isTableLoading">
        <ng-template cc-table-skeleton-template>
          <div class="instance-skeleton-loader-template">
            <cc-skeleton-loader [height]="250" [animation]="true">
              <svg:rect x="7" y="5" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="5" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="5" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="5" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="5" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="5" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="18" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="18" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="18" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="18" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="18" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="18" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="31" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="31" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="31" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="31" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="31" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="31" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="44" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="44" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="44" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="44" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="44" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="44" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="57" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="57" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="57" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="57" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="57" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="57" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="70" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="70" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="70" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="70" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="70" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="70" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="83" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="83" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="83" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="83" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="83" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="83" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="96" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="96" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="96" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="96" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="96" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="96" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="109" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="109" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="109" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="109" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="109" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="109" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="122" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="122" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="122" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="122" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="122" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="122" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="135" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="135" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="135" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="135" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="135" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="135" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="148" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="148" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="148" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="148" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="148" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="148" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="161" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="161" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="161" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="161" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="161" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="161" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="174" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="174" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="174" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="174" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="174" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="174" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="189" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="189" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="189" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="189" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="189" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="189" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="7" y="202" rx="1" ry="1" width="68" height="2.5" />
              <svg:rect x="90" y="202" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="165" y="202" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="240" y="202" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="315" y="202" rx="1" ry="1" width="60" height="2.5" />
              <svg:rect x="390" y="202" rx="1" ry="1" width="50" height="2.5" />

              <svg:rect x="0" y="13" width="540" height="0.5" />
              <svg:rect x="0" y="26" width="540" height="0.5" />
              <svg:rect x="0" y="39" width="540" height="0.5" />
              <svg:rect x="0" y="52" width="540" height="0.5" />
              <svg:rect x="0" y="65" width="540" height="0.5" />
              <svg:rect x="0" y="78" width="540" height="0.5" />
              <svg:rect x="0" y="91" width="540" height="0.5" />
              <svg:rect x="0" y="104" width="540" height="0.5" />
              <svg:rect x="0" y="117" width="540" height="0.5" />
              <svg:rect x="0" y="130" width="540" height="0.5" />
              <svg:rect x="0" y="143" width="540" height="0.5" />
              <svg:rect x="0" y="156" width="540" height="0.5" />
              <svg:rect x="0" y="169" width="540" height="0.5" />
              <svg:rect x="0" y="182" width="540" height="0.5" />
            </cc-skeleton-loader>
          </div>
        </ng-template>
      </cc-table-skeleton>
    </cc-table>
  </div>
</div>
