import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AssetService } from '../../../assets/services/asset.service';
import { PendingRequestFormData } from '../../self-serve.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dsp-next-gen-ui-approve-request',
  templateUrl: './approve-request.component.html',
  styleUrls: ['./approve-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApproveRequestComponent implements OnInit {
  @Input() pendingRequestFormData!: PendingRequestFormData;
  radioStates = ['default', 'default'];
  status = '';
  newSubscriptionData!: any;
  oldSubscriptionData!: any;
  pendingRequestFormSubscription!: Subscription;
  constructor(private assetService: AssetService) {}
  ngOnInit() {
    this.status = this.pendingRequestFormData.status;
    this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
    this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
    this.pendingRequestFormSubscription = this.assetService.pendingRequestFormEvent.subscribe(
      (data: PendingRequestFormData) => {
        this.pendingRequestFormData = data;
        this.status = this.pendingRequestFormData.status;
        this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
        this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
      }
    );
  }

  // ngOnDestroy() {
  //   this.pendingRequestFormSubscription.unsubscribe();
  // }

  toggleRadioGroup(selected: any, i: any) {
    this.radioStates.forEach((item, index) => {
      this.radioStates[index] = i === index && selected ? 'active' : 'default';
    });
  }

  updateSelection(selection: any) {
    this.status = selection;
    this.pendingRequestFormData.status = this.status;
    this.pendingRequestFormData.isValidForm = true;
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }
}
