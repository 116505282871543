import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { LegalModalComponent } from './legal-modal/legal-modal.component';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { CookiesModalComponent } from './cookies-modal/cookies-modal.component';
import { TermModalComponent } from './term-modal/term-modal.component';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { SharedService } from '../../shared.service';
@Component({
  selector: 'dsp-next-gen-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  userActiveFeatures: any;
  dspConfig: any;
  dspStoreData: any;
  constructor(
    private modal: CcModal,
    private router: Router,
    private store: Store<DSPAppState>,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService
  ) {}
  dateYear = new Date().getFullYear();
  showDSPV1Link = false;
  @Input() appClass = '';

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : {};
      this.dspConfig = dsp.dspConfig || {};
    });
    this.showDSPV1Link = this.dspStoreData?.featureFlagInfo?.showDSPV1Link
      ? this.dspStoreData?.featureFlagInfo?.showDSPV1Link
      : false;
  }
  termSaleModal() {
    sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('termSaleModal', '_blank');
  }

  privacyModal() {
    sessionStorage.setItem('isNewWindowOpened', '/privacyModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('privacyModal', '_blank');
  }

  legalModal() {
    sessionStorage.setItem('isNewWindowOpened', '/legalModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('legalModal', '_blank');
  }

  quickLink() {
    sessionStorage.setItem('isNewWindowOpened', '/quickLinks');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    this.router.navigate(['quickLinks']);
  }
}
