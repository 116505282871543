<div>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="modal-content-header">Select a product and plan</div>
    </div>
  </div>
  <form [formGroup]="productPlanForm" id="product-plan-container">
    <div class="cc-grid">
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
        <div class="create-site-label">Product</div>
        <div style="width: 400px; padding-top: 5px">
          <cc-dropdown
            [isTheme]="'2.0'"
            [id]="'product-dropDown'"
            [label]="'Select'"
            [dataSource]="productData"
            [placementPosition]="'bottom'"
            (selectionChange)="productDropdownChange($event)"
            formControlName="selectedProductName"
            [typeahead]="true"
            [searchHelpText]="'Couldn´t find any product'"
            [typeaheadmanualinput]="true"
            [scrollToSelected]="true"
            [search]="true"
          >
          </cc-dropdown>
        </div>
      </div>
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
        <div class="create-site-label">Plan</div>
        <div style="width: 400px; padding-top: 5px">
          <cc-dropdown
            [isTheme]="'2.0'"
            [id]="'plan-dropDown'"
            [label]="'Select'"
            [dataSource]="planData"
            [placementPosition]="'bottom'"
            formControlName="selectedPlanName"
            (selectionChange)="planDropdownChange($event)"
            [typeahead]="true"
            [searchHelpText]="'Couldn´t find any plan'"
            [typeaheadmanualinput]="true"
            [scrollToSelected]="true"
            [search]="true"
            [disabled]="(planData.length === 0 && selectedData.plan === '') || this.isValidProduct === false"
          >
          </cc-dropdown>
        </div>
      </div>
    </div>
    <div class="cc-grid" *ngIf="enableAccountTypeSelection()">
      <div class="cc-col cc-lg-col--10 cc-md-col--10 cc-xl-col--10 cc-sm-col--10 cc-xs-col--12">
        <cc-radio-group (valueChange)="accountOptionClick($event)" class="radio-group">
          <cc-radio-button *ngFor="let option of accountTypes" [value]="option.value" [checked]="option.checked">
            {{ option.label }}
          </cc-radio-button>
        </cc-radio-group>
      </div>
    </div>
  </form>
  <ng-container *ngIf="selectedData.accountType === 'Dealer'">
    <div class="cc-grid dealer-margin">
      <dsp-next-gen-ui-subscription-note [planType]="selectedData.plan"></dsp-next-gen-ui-subscription-note>
    </div>
  </ng-container>
</div>
