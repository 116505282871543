<div class="cc-grid" [formGroup]="manageAssetSubscriptionForm">
  <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
    <div class="service-list-options-label">Base Plan</div>
    <cc-dropdown
      [id]="'base-plan-dropdown-medium'"
      class="manage-asset-dropdown"
      name="service-dropdown"
      [label]="'Select Plan'"
      [dataSource]="listOfBasePlans"
      [mobileHeader]="'Asset Service dropdown'"
      [placementPosition]="'bottom'"
      [isTheme]="'2.0'"
      (selectionChange)="onBasePlanChange($event, 'customerLevel')"
      formControlName="customerPlan"
      [selectionOnClose]="true"
      [isReOrder]="false"
      [disabled]="
        applicableSubscriptions?.level1?.length === 0 || subsData?.templateCheck?.custSubs?.disableServiceCheck
      "
      [wrapperMaxWidth]="250"
    >
    </cc-dropdown>
  </div>
  <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
    <div class="service-list-options-label">Optional Plan</div>
    <cc-dropdown
      [id]="'optional-plan-dropdown-medium'"
      class="manage-asset-dropdown optional-plan"
      name="optional-dropdown"
      [label]="'Select Plan'"
      [dataSource]="listOfOptionalPlans"
      [mobileHeader]="'Asset Service Optional dropdown'"
      [placementPosition]="'bottom'"
      [isTheme]="'2.0'"
      (selectionChange)="onOptionalPlanChange($event, 'addOnOptions')"
      [hideSelectAllLabel]="true"
      [initSelectedItem]="autoSelectItem"
      [expanded]="'selected'"
      formControlName="addOnOptions"
      [isremoveComma]="true"
      [isReOrder]="false"
      [multiple]="true"
      [disabled]="applicableAddlSubscriptions?.length === 0"
    >
    </cc-dropdown>
  </div>
</div>
<ng-container
  *ngIf="subsData?.templateCheck?.promotionDisplayCheck"
  [ngTemplateOutlet]="promotionSection"
></ng-container>
<div *ngIf="subsData?.templateCheck?.prepayDisplayCheck">
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <cc-checkbox
        class="prepay-checkbox-label"
        [isTheme]="'2.0'"
        [(ngModel)]="subsData.prepaySelected"
        (toggleChange)="handlePrepayChange($event)"
        [disabled]="subsData?.templateCheck?.prepayDisableCheck"
        [checked]="subsData?.prepaySelected"
        >Prepay</cc-checkbox
      >
    </div>
  </div>
  <div *ngIf="subsData.prepaySelected" class="cc-grid">
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--6 cc-xs-col--6">
      <div class="prepay-date-label">Contract Start Date</div>
      <div>
        <cc-datepicker
          cc-date-page-large
          [dateFormat]="dateFormat.value"
          [inputFieldConfig]="startDateFieldConfig"
          [multiYearConfig]="multiYearConfig"
          [id]="'visionlink-date-picker'"
          [isTheme]="false"
          [isDense]="true"
          [value]="subsData?.contractPeriod?.startDate"
          (dateChange)="handlePrepayDateChange($event, 'contractStartDate')"
        ></cc-datepicker>
      </div>
    </div>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--6 cc-xs-col--6">
      <div class="prepay-date-label">Contract End Date</div>
      <div>
        <cc-datepicker
          cc-date-page-large
          [dateFormat]="dateFormat.value"
          [inputFieldConfig]="endDateFieldConfig"
          [multiYearConfig]="multiYearConfig"
          [min]="subsData?.contractPeriod?.minEndDate"
          [max]="subsData?.contractPeriod?.maxEndDate"
          [id]="'visionlink-date-picker'"
          [isTheme]="false"
          [isDense]="true"
          [value]="subsData?.contractPeriod?.endDate"
          (dateChange)="handlePrepayDateChange($event, 'contractEndDate')"
        ></cc-datepicker>
      </div>
    </div>
  </div>
</div>

<ng-template #promotionSection>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <section id="promotion-section" *ngIf="subsData?.promoInfo?.showPromoValue">
        <div class="service-list-options-label promo-padding">Promotion(s) Applied</div>
        <div *ngIf="subsData?.promoInfo?.promoValue?.length > 0">
          <!-- Promotion details card -->
          <ng-container *ngFor="let promo of subsData.promoInfo.promoValue">
            <cc-card [isTheme]="'2.0'" [outline]="true">
              <cc-card-content>
                <div class="promo-data-align">
                  <div class="promo-data">{{ promo.name }}</div>
                  <div class="promo-data">-{{ convertNumberFormat(promo.discount) }}</div>
                </div>
              </cc-card-content>
            </cc-card>
          </ng-container>
        </div>
        <div *ngIf="promoLoader">
          <cc-card [isTheme]="'2.0'" [outline]="true">
            <cc-card-content>
              <cc-skeleton-loader [height]="25" [animation]="true" class="promotion-skeleton-loader">
                <svg:rect x="7" y="7" rx="3" ry="3" width="120" height="4" />
                <svg:rect x="378" y="10" rx="5" ry="5" width="65" height="7" />
                <svg:rect x="7" y="16" rx="3" ry="3" width="70" height="4" />
              </cc-skeleton-loader>
            </cc-card-content>
          </cc-card>
        </div>
        <div class="align-no-promo-message" *ngIf="subsData?.promoInfo?.promoValue?.length === 0 && !promoLoader">
          <!-- Promotion not-available detail -->
          <span class="no-promo-label">Not Available</span>
          <cc-icon
            [template]="noPromoTooltipTemplate"
            [cc-tooltip]="''"
            [interactive]="true"
            [appClass]="'-promo-info-tool-tip'"
            class="info-message-icon-size"
            [tooltipPosition]="'bottom'"
            >info-tooltip</cc-icon
          >
        </div>
      </section>
    </div>
  </div>
</ng-template>

<ng-template #noPromoTooltipTemplate>
  <div class="promo-tooltip-body">
    <span
      >Asset does not currently meet eligibility criteria for promotion. If the asset is not on a contract and it
      becomes eligible by the end of the month, it will be re-evaluated prior to your next invoice. Promotions may
      require dealer submission of customer sale date.
      <a [href]="dspHelp" target="_blank" style="color: #2595ff; text-decoration: underline; font-weight: 400"
        >Click here</a
      >
      for more information.</span
    >
  </div>
</ng-template>
