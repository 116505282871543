import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-account-customer',
  templateUrl: './account-customer.component.html',
  styleUrls: ['./account-customer.component.scss'],
})
export class AccountCustomerComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  public customData: any;
  isChangeOwnership!: any;
  showTruncatedText = false;
  dspStoreData: any;
  columnName: any;
  constructor(private store: Store<DSPAppState>) {}
  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.customData = this.columnWithHeader?.customData;
    this.columnName = this.columnWithHeader?.name;
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.isChangeOwnership = this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        ? this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        : false;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
  }

  parseUcNameData(ucName: string) {
    ucName = ucName ? ucName.trim() : ucName;
    if (this.columnWithHeader?.width >= 260 && ucName && ucName.length > 18) {
      return `${ucName.substring(0, 18)}...`;
      // } else if (this.columnWithHeader?.width < 260 && ucName && ucName.length > 15) {
      //   return `${ucName.substring(0, 15)}...`;
    } else {
      return ucName;
    }
  }
}
