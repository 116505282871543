import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import {
  CcDrawerRef,
  CcModal,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
  modalType,
} from '@cat-digital-workspace/shared-ui-core';
import { cloneDeep, findIndex, hasIn, includes, isEmpty, remove, some } from 'lodash-es';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AssetSubscriptionFormDataType, PrepayContractInfo, SubscriptionData } from '../../../models/assets.interface';
import { dealerType } from '../../../models/shared.interface';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../shared/shared';
import { FilterAssetsV2Payload } from '../asset-drawer/asset-drawer-interface';
import { AssetService } from '../services/asset.service';
import * as dspConstants from '../../../shared/dspConstants';
import { DSPAppState } from '../../../store/state/dsp.state';
import { PaymentPreviewComponent } from './payment-preview/payment-preview.component';
import { DealerActionRequiredModalComponent } from './dealer-action-required-modal/dealer-action-required-modal.component';
import { CancelSubscriptionComponent } from './cancel-subscription/cancel-subscription.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetAgreementStatus } from '../asset-interface';
import { forEach } from '@angular-devkit/schematics';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import { PopupData } from '../../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
@Component({
  selector: 'dsp-next-gen-ui-manage-asset-subscription',
  templateUrl: './manage-asset-subscription.component.html',
  styleUrls: ['./manage-asset-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAssetSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('b2cCustWarningTemplate') b2cCustWarningTemplate!: TemplateRef<any>;
  @ViewChild(PaymentPreviewComponent) paymentPreview!: PaymentPreviewComponent;
  @ViewChild(CancelSubscriptionComponent) cancelSubscription!: CancelSubscriptionComponent;
  @ViewChild('cancelTemplateSuspendRef') cancelSubscriptionModalSuspendTemplate!: TemplateRef<any>;
  @ViewChild('cancelTemplatePrepayRef') cancelSubscriptionModalPrepayTemplate!: TemplateRef<any>;
  @ViewChild('prePayApplyRef') prePayApplyTemplate!: TemplateRef<any>;
  @ViewChild('EC520TemplateRef') EC520MessageTemplate!: TemplateRef<any>;
  @ViewChild('ccsMessage') ccsMessage!: TemplateRef<any>;
  syncEventsSubject: Subject<void> = new Subject<void>();
  isTheme = '2.0';
  dealer!: dealerType;
  modalType: modalType = 'semi-modal';
  currentPageIndex = 0; // for UI components
  trackerIndex = 0; // for progress tracker
  isNextDisabled = true;
  isBillingToShow = false;
  isEditFlow = false;
  cancelScreen = false;

  assetDealerInfo: any;
  billingTypeStatus: any;
  isAssetDealerInfoRetrieved!: boolean;
  trackerData = [
    {
      label: 'Select Ownership',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Select Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  dynamicTrackerData: any;
  disableNext = true;
  launchType = '';
  selectedRowData: any;
  assetAgreementStatus!: AssetAgreementStatus;
  selectedAssetSubscriptionFormData: AssetSubscriptionFormDataType = {
    isValidForm: false,
    expandedService: '',
    customer: {},
    service: {
      currentService: '',
      previousService: '',
      selectedService: '',
    },
    subscription: {
      currentSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      previousSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      selectedSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
    },
    addOns: {
      currentAddons: [],
      previousddons: [],
      selectedAddons: [],
    },
    prepay: {
      currentPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      previousPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      selectedPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
    },
    catBillingData: {
      billingGroupId: '',
    },
    productFamily: {
      currentProductFamily: '',
      previousProductFamily: '',
      selectedProductFamily: '',
    },
    selectedCategory: '',
    siteInfo: {
      siteName: '',
      siteId: '',
    },
    previousSiteInfo: {
      siteName: '',
      siteId: '',
    },
    selectedSiteInfo: {
      siteName: '',
      siteId: '',
    },
  };
  systemError = dspConstants.Common.SYSTEM_ERROR;
  setFilterAssetsFlag = true;
  setAssetOwnershipFlag = true;
  b2cCustomers: any = {};
  assetFormSubscription!: any;
  billingPopUpInfo: any = [];
  isDealerActionRequiredPopupAgreed = false;
  userActiveFeatures: any;
  userPreferences: any;
  catalogMapping: any;
  serviceMapping: any;
  assetId: any;
  subsPricingEligible = false;
  ec520Message = '';
  languageTranslations: any = [];
  telUserType = '';
  billingAccounts: any;
  warningModalRef!: any;
  ccsWarningMessage = '';
  catOnlyAddons: any = [];
  getNewSubscriptionData = false;
  isRedirectToSitePage!: boolean;
  modalRef: any;
  prepayOption: any;
  searchType: any;
  dspConfig: any;
  hasPartialOwnershipData = false;
  partialOwnershipData: any = [];
  completeOwnershipData: any = [];
  isOwnershipApiCallInProgress = false;
  isNonBillableCust = true;
  showB2CWarningPopupInPaymentPreview = false;
  dspStoreData: any;
  isShowPriceIfNoBilling = false;
  isCommonPopup = false;
  prepayModalRef: any;
  modifyPrepayModalRef: any;
  cancelSubscriptionModalRef: any;
  ec520ModalRef: any;
  b2cWarningModalRef: any;
  isAutoSelectItemsRejected = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public assetRowData: any,
    public manageAssetPlanContainerRef: CcDrawerRef<ManageAssetSubscriptionComponent>,
    public assetService: AssetService,
    public assetDrawerService: AssetDrawerService,
    public messageBar: MessageBar,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    public dspUtilsService: DspUtilsCommonService
  ) {}

  ngOnInit(): void {
    this.assetService.lastUpdatedTime = '';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.billingPopUpInfo = JSON.parse(dsp.dspConfig?.['billingPopUpInfo']) || [];
      this.catOnlyAddons = JSON.parse(dsp.dspConfig?.['CAT_Only_Addon']) || [];
      this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
      this.userPreferences = dsp.userActiveFeatures?.userPreferences || [];
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.catalogMapping = dsp.deviceCatalog || {};
      this.serviceMapping = dsp.serviceMapping || {};
      this.languageTranslations = dsp.languageTranslations || {};
      this.billingAccounts = dsp.billingAccounts?.accountDetails || [];
      this.dspConfig = dsp.dspConfig || {};
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
    });
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    if (!this.subsPricingEligible) {
      const val = this.trackerData;
      this.trackerData = val.filter((ele) => ele.label != 'Payment Preview');
    }
    this.showB2CWarningPopupInPaymentPreview = this.dspStoreData?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
      ? this.dspStoreData?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
      : false;
    this.dynamicTrackerData = cloneDeep(this.trackerData);
    this.dealer = fetchLoggedInDealerDetails();
    this.launchType = this.assetRowData.type;
    this.selectedRowData = this.assetRowData?.selectedAssetDetails
      ? cloneDeep(this.assetRowData.selectedAssetDetails)
      : {};
    this.searchType = this.selectedRowData?.searchType || dspConstants.Worklist.GLOBAL_SEARCH;
    this.setEditSubscriptionChanges();
    this.setAssetData();
    this.ccsWarningMessage = this.languageTranslations?.Worklist?.WL_CCS_MESSAGE || '';
    this.assetFormSubscription = this.assetService.assetSubscriptionFormEvent.subscribe(
      (data: AssetSubscriptionFormDataType) => {
        this.selectedAssetSubscriptionFormData = data;
        this.handleProgressTrackerUpdate();
      }
    );
    this.getAssetAgreement();
  }

  handleProgressTrackerUpdate() {
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    const { selectedCategory } = this.selectedAssetSubscriptionFormData;
    const { customerLevel, dealerLevel, catLevel } =
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription;
    const {
      customerLevel: prevCustomerLevel,
      dealerLevel: prevDealerLevel,
      catLevel: prevCatLevel,
    } = this.selectedAssetSubscriptionFormData.subscription.previousSubscription;
    const { currentService } = this.selectedAssetSubscriptionFormData.service;
    const { CUSTOMER } = dspConstants.Common;
    const loggedInProfile = this.telUserType || CUSTOMER;
    if (selectedCategory === dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM) {
      /*Removed the billing page Logic as requested from business team.*/
      if (this.cancelScreen == true) {
        const isPaymentAdded = this.checkTrackerData('Payment Preview');
        const isCancelAdded = this.checkTrackerData('Select Cancellation Reason');
        if (isPaymentAdded) {
          remove(this.dynamicTrackerData, (row: any) => row.label === 'Payment Preview');
        }
        if (!isCancelAdded) {
          const cancelPageObj = {
            label: 'Select Cancellation Reason',
            disabled: false,
            clickable: false,
            warning: false,
            danger: false,
            description: '',
          };
          this.dynamicTrackerData.push(cancelPageObj);
        }
      } else if (this.cancelScreen == false) {
        const isPaymentAdded = this.checkTrackerData('Payment Preview');
        if (!isPaymentAdded && this.checkTrackerData('Select Cancellation Reason')) {
          remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Cancellation Reason');
          if (this.subsPricingEligible) {
            const paymentPreviewObject = {
              label: 'Payment Preview',
              disabled: false,
              clickable: false,
              warning: false,
              danger: false,
              description: '',
            };
            this.dynamicTrackerData.push(paymentPreviewObject);
          }
        }
      }
    } /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
     else if (selectedCategory === dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM) {
      if (this.isEditFlow && prevCustomerLevel === '' && customerLevel !== '' && customerLevel !== 'None') {
        const trackerDataLength = this.dynamicTrackerData.length;
        if (!this.checkTrackerData('Select Ownership')) {
          const ownershipTrackerData = {
            label: 'Select Ownership',
            disabled: false,
            clickable: false,
            warning: false,
            danger: false,
            description: '',
          };
          this.dynamicTrackerData.splice(trackerDataLength - 1, 0, ownershipTrackerData);
        }
      } else {
        this.updateOwnershipTrackerData();
        const prevDealerSubscriptionLbl = prevDealerLevel;
        const prevCatSubscriptionLbl = prevCatLevel;
        let dealerGrade = 0;
        if (dealerLevel) {
          dealerGrade =
            this.catalogMapping?.[this.selectedRowData?.deviceType]?.[currentService]?.[dspConstants.Common.CUSTOMER]?.[
              dealerLevel
            ]?.grade;
        }
        let catGrade = 0;
        if (catLevel) {
          catGrade =
            this.catalogMapping?.[this.selectedRowData?.deviceType]?.[currentService]?.[dspConstants.Common.CAT]?.[
              catLevel
            ]?.grade;
        }
        if (
          catGrade > dealerGrade &&
          (catLevel != prevCatSubscriptionLbl || dealerLevel != prevDealerSubscriptionLbl)
        ) {
          if (dealerLevel === '' || dealerLevel === 'None') {
            // Only Billing is allowed
            this.checkTrackerData('Payment Preview') &&
              remove(this.dynamicTrackerData, (row: any) => row.label === 'Payment Preview');
            if (!this.checkTrackerData('Billing')) {
              const billingObject = {
                label: 'Billing',
                disabled: false,
                clickable: false,
                warning: false,
                danger: false,
                description: '',
              };
              this.dynamicTrackerData.push(billingObject);
            }
          } else {
            // Both Billing & Payment-Preview is allowed
            this.checkTrackerData('Payment Preview') &&
              remove(this.dynamicTrackerData, (row: any) => row.label === 'Payment Preview');
            this.checkTrackerData('Billing') && remove(this.dynamicTrackerData, (row: any) => row.label === 'Billing');
            if (!this.checkTrackerData('Billing')) {
              const billingObject = {
                label: 'Billing',
                disabled: false,
                clickable: false,
                warning: false,
                danger: false,
                description: '',
              };
              this.dynamicTrackerData.push(billingObject);
            }
            if (!this.checkTrackerData('Payment Preview')) {
              const paymentPreviewObject = {
                label: 'Payment Preview',
                disabled: false,
                clickable: false,
                warning: false,
                danger: false,
                description: '',
              };
              this.dynamicTrackerData.push(paymentPreviewObject);
            }
          }
        } else {
          // Only Payment-preview is allowed
          this.checkTrackerData('Billing') && remove(this.dynamicTrackerData, (row: any) => row.label === 'Billing');
          if (!this.checkTrackerData('Payment Preview')) {
            const paymentPreviewObject = {
              label: 'Payment Preview',
              disabled: false,
              clickable: false,
              warning: false,
              danger: false,
              description: '',
            };
            this.dynamicTrackerData.push(paymentPreviewObject);
          }
        }
      }
    }*/ else if (selectedCategory === dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR) {
      if (this.cancelScreen == true) {
        const isPaymentAdded = this.checkTrackerData('Payment Preview');
        const isCancelAdded = this.checkTrackerData('Select Cancellation Reason');
        if (isPaymentAdded) {
          remove(this.dynamicTrackerData, (row: any) => row.label === 'Payment Preview');
        }
        if (!isCancelAdded) {
          const cancelPageObj = {
            label: 'Select Cancellation Reason',
            disabled: false,
            clickable: false,
            warning: false,
            danger: false,
            description: '',
          };
          this.dynamicTrackerData.push(cancelPageObj);
        }
      } else if (this.cancelScreen == false) {
        const isPaymentAdded = this.checkTrackerData('Payment Preview');
        if (!isPaymentAdded && this.checkTrackerData('Select Cancellation Reason')) {
          remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Cancellation Reason');
          if (this.subsPricingEligible) {
            const paymentPreviewObject = {
              label: 'Payment Preview',
              disabled: false,
              clickable: false,
              warning: false,
              danger: false,
              description: '',
            };
            this.dynamicTrackerData.push(paymentPreviewObject);
          }
        }
      }
    }
  }

  updateOwnershipTrackerData() {
    this.isEditFlow &&
      this.checkTrackerData('Select Ownership') &&
      remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Ownership');
  }

  checkTrackerData(labelValue: string) {
    return some(this.dynamicTrackerData, ['label', labelValue]);
  }

  hasCatOnlyAddOns(currentAddons: string[]) {
    return currentAddons.some((addOn) => this.catOnlyAddons.includes(addOn));
  }

  ngOnDestroy(): void {
    this.assetFormSubscription?.unsubscribe();
  }

  setEditSubscriptionChanges() {
    if (this.assetRowData?.selectedAssetDetails?.reportingState == 'Subscribed') {
      this.isEditFlow = true;
      remove(this.dynamicTrackerData, (row: any) => {
        return row.label === 'Select Ownership';
      });
    }
  }

  setAssetData() {
    if (this.launchType == 'LANDING') {
      this.setFilterAssetsFlag = false;
      this.setAssetOwnershipFlag = false;
      this.loadAssetAndOwnershipData();
    } else if (this.isEditFlow) {
      this.getDealerInfo();
      this.setOwnershipData(this.assetRowData.selectedAssetDetails?.ownershipDetails || {});
    } else if (this.launchType == 'DRAWER') {
      if (this.assetRowData?.selectedAssetDetails?.ownershipDetails?.length > 0) {
        this.assetRowData.selectedAssetDetails.ownershipDetails = this.filterOwnershipRecords(
          this.assetRowData.selectedAssetDetails.ownershipDetails
        );
        this.assetRowData.selectedAssetDetails.ownershipDetails = this.checkDCNFlagCondition(
          this.assetRowData.selectedAssetDetails.ownershipDetails
        );
        if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership == true) {
          this.checkSelectOwnershipSection(this.assetRowData.selectedAssetDetails.ownershipDetails);
        }
        this.selectedRowData.ownershipDetails = cloneDeep(this.assetRowData.selectedAssetDetails.ownershipDetails);
      }
    }
  }

  setFilterAssetsPayload() {
    if (this.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make || '',
        serialNumber: this.selectedRowData?.serialNumber || '',
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make,
        serialNumber: this.selectedRowData?.serialNumber,
        flag: false,
      };
    }
  }

  getFilteredAssets() {
    this.isOwnershipApiCallInProgress = true;
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.selectedRowData = Object.assign(this.selectedRowData, result);
          if (this.selectedRowData.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.selectedRowData.applicationName =
              this.serviceMapping[this.selectedRowData?.dealerSubscriptionId]?.appName;
          }
          if (this.searchType == dspConstants.Worklist.NO_DEVICE_SEARCH) {
            this.setOwnershipInfoForNoDevice();
          }
          this.getDealerInfo();
          this.setFilterAssetsFlag = true;
        }
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.getDealerInfo();
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  getDealerInfo() {
    if (
      !this.isAssetDealerInfoRetrieved &&
      this.selectedRowData?.reportingState &&
      isEmpty(this.selectedRowData?.dealerName)
    ) {
      const dealerInfoPayload = {
        assetIds: [this.selectedRowData?.assetId] || [],
        dealerCode: this.dealer?.dealerCode || '',
      };
      this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
        next: (result: any) => {
          if (result) {
            this.assetDealerInfo = result;
            this.isAssetDealerInfoRetrieved = true;
            this.mapDealerName();
          }
        },
        error: (err: any) => {
          this.assetDealerInfo = {};
          this.isAssetDealerInfoRetrieved = true;
          this.mapDealerName();
        },
      });
    }
  }

  mapDealerName() {
    if (
      this.selectedRowData?.reportingState == dspConstants.Worklist.SUBSCRIBED &&
      !isEmpty(this.selectedRowData?.dealerCode)
    ) {
      if (hasIn(this.assetDealerInfo, this.selectedRowData?.dealerCode)) {
        this.selectedRowData.dealerName = this.assetDealerInfo[this.selectedRowData?.dealerCode];
      }
    }
  }
  loadAssetAndOwnershipData() {
    this.getFilteredAssets();
    if (this.searchType != dspConstants.Worklist.NO_DEVICE_SEARCH) {
      this.fetchOwnershipInfo();
    }
  }

  setOwnershipInfoForNoDevice() {
    this.isOwnershipApiCallInProgress = false;
    let assetOwners: any[] = [];
    if (this.selectedRowData?.equipmentOwner.length > 0) {
      assetOwners = this.selectedRowData.equipmentOwner;
      assetOwners = this.filterOwnershipRecords(assetOwners);
      assetOwners = this.checkDCNFlagCondition(assetOwners);
      if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership == true) {
        this.checkSelectOwnershipSection(assetOwners);
      }
      this.selectedRowData.ownershipDetails = assetOwners;
      if (this.isEditFlow) {
        this.setOwnershipData(assetOwners);
      }
    } else {
      this.selectedRowData.ownershipDetails = [];
    }
    this.setAssetOwnershipFlag = true;
  }
  checkDCNFlagCondition(assetOwners: any) {
    this.partialOwnershipData = [];
    this.completeOwnershipData = [];
    if (this.dspStoreData?.featureFlagInfo?.enableDCNSelection === true) {
      this.checkOwnershipInfo(assetOwners);
      this.partialOwnershipData = sortOwnershipRecords(this.partialOwnershipData);
      this.completeOwnershipData = sortOwnershipRecords(this.completeOwnershipData);
      assetOwners = [{}];
      assetOwners = [...this.completeOwnershipData, ...this.partialOwnershipData];
    } else {
      assetOwners = sortOwnershipRecords(assetOwners);
    }
    return assetOwners;
  }

  checkSelectOwnershipSection(assetOwners: any) {
    if (!this.hasPartialOwnershipData) {
      if (assetOwners?.length === 1) {
        remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Ownership');
        this.setOwnershipData(assetOwners);
      }
    }
  }

  fetchOwnershipInfo() {
    const ownershipInfoPayload = {
      assetIds: [this.selectedRowData.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    let assetOwners: any[] = [];
    this.isOwnershipApiCallInProgress = true;
    this.hasPartialOwnershipData = false;
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (response: any) => {
        this.isOwnershipApiCallInProgress = false;
        if (response[this.selectedRowData.assetId]) {
          assetOwners = response[this.selectedRowData.assetId] as any;
          assetOwners = this.filterOwnershipRecords(assetOwners);
          assetOwners = this.checkDCNFlagCondition(assetOwners);
          if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership == true) {
            this.checkSelectOwnershipSection(assetOwners);
          }
          this.selectedRowData.ownershipDetails = assetOwners;
          if (this.isEditFlow) {
            this.setOwnershipData(assetOwners);
          }
        } else {
          this.selectedRowData.ownershipDetails = [];
        }
        this.setAssetOwnershipFlag = true;
        this.syncEventsSubject.next();
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  checkOwnershipInfo(assetOwners: any) {
    assetOwners.forEach((owner: any) => {
      if (isEmpty(owner?.dealerCustNo)) {
        this.hasPartialOwnershipData = true;
        this.partialOwnershipData.push(owner);
      } else {
        if (!isEmpty(owner?.ucid)) this.completeOwnershipData.push(owner);
      }
    });
  }
  filterOwnershipRecords(assetOwners: any) {
    const loggedInDealerRecords = assetOwners?.filter((owner: any) => {
      return owner?.dealerCode == this.dealer?.dealerCode || isEmpty(owner?.dealerCode);
    });
    return loggedInDealerRecords;
  }

  setOwnershipData(assetOwnershipInfo: any) {
    if (assetOwnershipInfo && !isEmpty(assetOwnershipInfo)) {
      // Customers data is available
      this.selectedAssetSubscriptionFormData.customer = assetOwnershipInfo[0];
    } else {
      // No customers are there and not subscribed at Multi-level
      this.selectedAssetSubscriptionFormData.customer = '';
    }
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  showToastMessage(message: string, status: string, hostType = 'container-overlay', selectorId = 'manage-asset') {
    const config: MessageBarConfig = {
      hostType: hostType,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: selectorId,
      duration: 3000,
    };
    if (status == 'success') {
      config.appOverlayClass = 'manageAsset-overlay';
    }
    this.messageBar.open(message, status, undefined, config, true);
  }

  closeDrawer() {
    if (this.launchType == 'DRAWER') {
      const managePlanDrawerData = {
        closeAssetDrawer: this.isRedirectToSitePage,
        apiCallNeeded: this.getNewSubscriptionData,
        reportingState: this.selectedAssetSubscriptionFormData.reportingState,
        formData: this.selectedAssetSubscriptionFormData,
      };
      this.manageAssetPlanContainerRef.close(managePlanDrawerData);
    } else if (this.launchType == 'LANDING' && this.getNewSubscriptionData) {
      const managePlanDrawerData = {
        closeAssetDrawer: this.isRedirectToSitePage,
        apiCallNeeded: this.getNewSubscriptionData,
        reportingState: this.selectedAssetSubscriptionFormData.reportingState,
        formData: this.selectedAssetSubscriptionFormData,
      };
      this.manageAssetPlanContainerRef.close(managePlanDrawerData);
    } else {
      this.manageAssetPlanContainerRef.close(this.getNewSubscriptionData);
    }
  }

  redirectToSitePage(event: any) {
    this.isRedirectToSitePage = true;
    this.closeDrawer();
  }

  loadCancelScreen(value: any) {
    this.cancelScreen = value;
  }

  onNext() {
    // trackerIndex is for progress-tracker updates.
    // currentPageIndex is for components to load.
    const { trackerIndex, currentPageIndex, dynamicTrackerData } = this;
    const isSelectCancelReason = dynamicTrackerData[currentPageIndex].label === 'Select Cancellation Reason';
    if (isSelectCancelReason) {
      this.submitCancelReason();
    } else if (this.currentPageIndex === this.dynamicTrackerData.length - 1 && this.subsPricingEligible) {
      this.validatePrePayData();
    } else if (dynamicTrackerData[currentPageIndex].label === 'Select Plan' && this.cancelScreen == false) {
      this.handlePricingActionPopup();
    } else {
      this.trackerIndex = trackerIndex + 1;
      this.currentPageIndex = currentPageIndex + 1;
    }
  }
  validatePrePayData() {
    if (
      (this.assetRowData.selectedAssetDetails.reportingState === 'Unsubscribed' &&
        this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected === true) ||
      (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
        this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected === true &&
        this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected === false)
    ) {
      if (this.isCommonPopup) {
        const validateOnRes = () => {
          this.closeCancelSubscriptionModal(true);
        };
        this.managePopupConfirmation(dspConstants.Common.PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1, validateOnRes);
      } else {
        this.openCancelPlanModal(this.prePayApplyTemplate);
      }
    } else if (
      (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
        this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected === true &&
        this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected === true) ||
      (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
        this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected === false &&
        this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected === true)
    ) {
      if (this.isCommonPopup) {
        const validateOnRes = () => {
          this.closeCancelSubscriptionModal(true);
        };
        this.managePopupConfirmation(
          dspConstants.Common.MODIFY_PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1,
          validateOnRes
        );
      } else {
        this.openCancelPlanModal(this.cancelSubscriptionModalPrepayTemplate);
      }
    } else if (
      this.ec520Message &&
      !this.selectedAssetSubscriptionFormData.addOns.previousddons.includes('Surface Guidance')
    ) {
      if (this.isCommonPopup) {
        const validateOnRes = () => {
          this.closeEC520PopupModal(true);
        };
        this.managePopupConfirmation(this.ec520Message, validateOnRes);
      } else {
        this.openCancelPlanModal(this.EC520MessageTemplate);
      }
    } else {
      if (this.showB2CWarningPopupInPaymentPreview) {
        if (this.showB2CWarningPopup()) {
          if (this.isCommonPopup) {
            const validateOnRes = () => {
              this.closeB2CWarningPopup(true);
            };
            this.managePopupConfirmation(this.languageTranslations?.Worklist?.WL_WARNING_MSG_CONTENT, validateOnRes);
          } else {
            this.openB2CWarningPopup(this.b2cCustWarningTemplate);
          }
        } else {
          this.saveSubscription();
        }
      } else {
        this.saveSubscription();
      }
    }
  }

  managePopupConfirmation(message: any, callbackFunction: any) {
    const popupData: PopupData = {
      title: dspConstants.Common.CONFIRMATION_POPUP_TITLE,
      messageArray: [message],
      showCrossMark: false,
      showPrimaryButton: true,
      showSecondaryButton: true,
      primaryButtonText: dspConstants.PopupButtons.SUBMIT,
      secondaryButtonText: dspConstants.PopupButtons.CANCEL,
    };
    this.prepayModalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
      width: '524px',
      type: 'semi-modal',
      data: popupData,
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.prepayModalRef.afterClosed().subscribe((res: any) => {
      if (res === dspConstants.PopupButtons.SUBMIT) {
        callbackFunction();
      }
    });
  }
  submitCancelReason() {
    this.prepayOption = this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected;
    if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand === 'Suspend') {
      if (this.isCommonPopup) {
        const validateOnRes = () => {
          this.checkPrepay();
        };
        this.managePopupConfirmation(dspConstants.Common.CANCEL_SUBSCRIPTION_CONFIRMATION___BODY_LINE1, validateOnRes);
      } else {
        this.openCancelPlanModal(this.cancelSubscriptionModalSuspendTemplate);
      }
    } else if (this.prepayOption) {
      if (this.isCommonPopup) {
        const validateOnRes = () => {
          this.closeCancelSubscriptionModal(true);
        };
        this.managePopupConfirmation(
          dspConstants.Common.MODIFY_PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1,
          validateOnRes
        );
        //this.modifyPrepayConfirmation();
      } else {
        this.openCancelPlanModal(this.cancelSubscriptionModalPrepayTemplate);
      }
    } else if (!this.prepayOption) {
      this.closeCancelSubscriptionModal(true);
    }
  }
  checkPrepay() {
    if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand === 'Suspend' && this.prepayOption) {
      this.closeCancelSubscriptionModal();
      setTimeout(() => {
        if (this.isCommonPopup) {
          const validateOnRes = () => {
            this.closeCancelSubscriptionModal(true);
          };
          this.managePopupConfirmation(
            dspConstants.Common.MODIFY_PREPAY_SUBSCRIPTION_CONFIRMATION___BODY_LINE1,
            validateOnRes
          );
        } else {
          this.openCancelPlanModal(this.cancelSubscriptionModalPrepayTemplate);
        }
      }, 1000);
    } else {
      this.closeCancelSubscriptionModal(true);
    }
    this.selectedAssetSubscriptionFormData.isValidForm = true;
  }
  openCancelPlanModal(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '524px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }

  closeCancelSubscriptionModal(param = false) {
    if (param) {
      this.saveSubscription();
      this.modalRef.close();
    } else {
      this.modalRef.close();
    }
  }
  closeEC520PopupModal(param = false) {
    if (param) {
      this.saveSubscription();
    }
    {
      this.modalRef.close();
    }
  }
  // Using this function, to get the checked icon in the progress-tracker
  updateTrackerIndex() {
    this.trackerIndex = this.currentPageIndex + 1;
  }

  onPrevious() {
    this.isAutoSelectItemsRejected = true;
    this.assetService.backButtonPress({ isBackButtonPressed: true });
    if (this.trackerIndex === 0 && this.launchType === 'LANDING') {
      this.closeDrawer();
    }
    const { currentPageIndex } = this;
    this.currentPageIndex = currentPageIndex - 1;
    this.trackerIndex = this.currentPageIndex;
    if (this.trackerIndex == 0) {
      this.cancelScreen = false;
    }
  }

  openB2CWarningPopup(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }

  closeB2CWarningPopup(isConfirmed: boolean) {
    if (isConfirmed) {
      this.modal.close();
      this.saveSubscription();
    } else {
      this.modal.close();
    }
  }

  showB2CWarningPopup() {
    this.checkPriceForB2C();
    return (
      this.telUserType == dspConstants.Common.DEALER &&
      !this.isNonBillableCust &&
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel != 'None'
    );
  }

  checkPriceForB2C() {
    const customerInfo = this.getValidCustomerForAsset();
    this.isNonBillableCust = this.dspUtilsService.checkPriceForB2C(
      this.b2cCustomers,
      customerInfo,
      this.selectedAssetSubscriptionFormData.service.currentService
    );
  }

  checkB2CCustomer() {
    const customerInfo = this.selectedAssetSubscriptionFormData.customer;
    const selectedService = this.selectedAssetSubscriptionFormData.service.currentService;
    if (
      customerInfo !== 'None' &&
      findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo?.ucid]) !== -1 &&
      findIndex(this.b2cCustomers?.b2cSupportedApps, selectedService) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  restrictAddOnSelectionIfNoFSM() {
    const selectedService = this.selectedRowData?.customerSubscriptionId
      ? this.serviceMapping[this.selectedRowData?.customerSubscriptionId].appName
      : this.selectedAssetSubscriptionFormData.service.currentService;
    const custSubsVal = this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel;
    const currentSubscriptionFSMCheck =
      this.catalogMapping?.[this.selectedRowData?.deviceType]?.[selectedService]?.[dspConstants.Common.CUSTOMER]?.[
        custSubsVal
      ]?.isFeatureMsgSupported;
    const supportedServiceListCheck = this.selectedRowData?.getSupportedServicesList?.indexOf(custSubsVal) === -1;
    return currentSubscriptionFSMCheck && supportedServiceListCheck;
  }

  handlePricingActionPopup() {
    if (this.restrictAddOnSelectionIfNoFSM()) {
      const inValidSubscriptionMessage = this.languageTranslations.Worklist.WL_RESTRICT_ADDON_SELECTION_IF_NO_FSM;
      return this.showToastMessage(inValidSubscriptionMessage, 'error');
    }
    const billingType: any = [];
    const { billingPopUpInfo, userActiveFeatures, userPreferences } = this;
    const { service, productFamily } = this.selectedAssetSubscriptionFormData;
    const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    const initialAddonSubscribed: any = previousddons;
    const profileBasedAdditionalServices: any = currentAddons;
    this.setSubsPricingEligibility(service.currentService);
    const billingPopUpCheck = userActiveFeatures && userPreferences && userPreferences?.length > 0 && billingPopUpInfo;
    const isSubcribeServiceAtLevel = this.checkPopupEnableAtLevel(service.currentService);
    const isEquipEnable = this.checkIfBillingPopupToBeShown(initialAddonSubscribed, profileBasedAdditionalServices);
    const isVimsAddOnAdded = this.checkIfBillingPopupToBeShownVimsAddonOn(
      initialAddonSubscribed,
      profileBasedAdditionalServices
    );
    if (
      billingPopUpCheck &&
      userActiveFeatures.indexOf('MinestarPaymentInfo') > -1 &&
      userPreferences[0].showMinestarPaymentInfo &&
      billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
      service.currentService == dspConstants.Worklist.MINESTAR &&
      isSubcribeServiceAtLevel
    ) {
      billingType.push('MineStar');
    }
    if (
      billingPopUpCheck &&
      userActiveFeatures.indexOf('HEIPaymentInfo') > -1 &&
      userPreferences[0].showHeiPaymentInfo &&
      ((billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
        service.currentService.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS) > -1 &&
        isSubcribeServiceAtLevel) ||
        isEquipEnable)
    ) {
      billingType.push('HEI');
    } else if (
      (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY) > -1 &&
        profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY) === -1) ||
      (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR) > -1 &&
        profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR) === -1) ||
      (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI) > -1 &&
        profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI) === -1)
    ) {
      this.assetAgreementStatus.isHeiAgreed = false;
    }
    if (
      billingPopUpCheck &&
      userActiveFeatures.indexOf('VIMSPaymentInfo') > -1 &&
      userPreferences[0].showVimsPaymentInfo &&
      ((billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
        service.currentService == dspConstants.Worklist.VIMS_TRANSMITTER &&
        isSubcribeServiceAtLevel) ||
        (!(initialAddonSubscribed.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1) &&
          profileBasedAdditionalServices.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1) ||
        isVimsAddOnAdded)
    ) {
      billingType.push('VIMS');
    } else if (
      initialAddonSubscribed.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1 &&
      profileBasedAdditionalServices.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) === -1
    ) {
      this.assetAgreementStatus.isVimsAgreed = false;
    }

    if (
      this.subsPricingEligible &&
      productFamily?.currentProductFamily &&
      productFamily?.currentProductFamily !== 'None'
    ) {
      this.validateAssetProductFamily(() => {
        this.showBillingModal(billingType);
      });
    } else {
      this.showBillingModal(billingType);
    }
  }

  checkPopupEnableAtLevel(selectedService: string) {
    const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    if (
      (selectedService == dspConstants.Worklist.EQUIPMENT_INSIGHTS ||
        selectedService == dspConstants.Worklist.VIMS_TRANSMITTER ||
        selectedService == dspConstants.Worklist.MINESTAR) &&
      ((currentSubscription.catLevel !== 'None' && currentSubscription.catLevel !== previousSubscription.catLevel) ||
        (currentSubscription.dealerLevel !== 'None' &&
          currentSubscription.dealerLevel !== previousSubscription.dealerLevel) ||
        (currentSubscription.customerLevel !== 'None' &&
          currentSubscription.customerLevel !== previousSubscription.customerLevel))
    ) {
      return true;
    }
    return false;
  }

  checkIfBillingPopupToBeShown(initialAddonSubscribed: any, profileBasedAdditionalServices: any) {
    const equipAddOnList = [
      dspConstants.Worklist.EQUIPMENT_INSIGHTS,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI,
    ];
    let isPopupToBeShown = false;
    profileBasedAdditionalServices.forEach((item: any) => {
      if (equipAddOnList.indexOf(item) > -1 && initialAddonSubscribed.indexOf(item) == -1) {
        isPopupToBeShown = true;
      }
    });
    return isPopupToBeShown;
  }

  checkIfBillingPopupToBeShownVimsAddonOn(initialAddonSubscribed: any, profileBasedAdditionalServices: any) {
    const equipAddOnList = [
      dspConstants.Worklist.VED,
      dspConstants.Worklist.VSD,
      dspConstants.Worklist.VIMS_TRANSMITTER,
      dspConstants.Worklist.VIMS_TRANSMITTER_CAT_CELLULAR,
      dspConstants.Worklist.VIMS_TRANSMITTER_MANUAL_DATA,
      dspConstants.Worklist.VIMS_TRANSMITTER_THIRD_PARTY_CELLULAR_OR_WIFI,
      dspConstants.Worklist.VIMS_TRANSMITTER_MANUAL_DATA_UPLOAD,
    ];
    let isPopupToBeShown = false;
    profileBasedAdditionalServices.forEach((item: any) => {
      if (equipAddOnList.indexOf(item) > -1 && initialAddonSubscribed.indexOf(item) == -1) {
        isPopupToBeShown = true;
      }
    });
    return isPopupToBeShown;
  }

  setSubsPricingEligibility(usrOptedService: any) {
    const deviceServiceCatalog = this.catalogMapping[this.selectedRowData?.deviceType]
      ? this.catalogMapping[this.selectedRowData?.deviceType][usrOptedService]
      : undefined;

    this.subsPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
      (this.userActiveFeatures?.indexOf('View Only') == -1 ||
        this.userActiveFeatures?.indexOf('Read Only') == -1 ||
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1) &&
      ((deviceServiceCatalog && deviceServiceCatalog['enableContractVisualization']) || false);
  }

  validateAssetProductFamily(callback: any) {
    const requestPayload = {
      make: this.selectedRowData.make,
      serialNumber: this.selectedRowData.serialNumber,
    };
    this.assetService.fetchEC250SerailNumber(requestPayload).subscribe({
      next: (res: any) => {
        if (res && !res.ec520ECMSerialNumber) {
          this.ec520Message = this.languageTranslations?.Worklist?.WL_EC520_Message;
          this.ec520Message = this.ec520Message.replace('S/N', 'serial number');
        }
        callback();
      },
      error: () => {
        this.ec520Message = '';
        callback();
      },
    });
  }

  showBillingModal(billingType: any) {
    if (billingType.length !== 0) {
      this.loadBillingNoticePop(billingType);
    } else {
      this.validateCCSMessage();
    }
  }

  checkIfAgreementPopupNeeded(billingType: any, modalIndex = 0) {
    if (billingType[modalIndex] == 'VIMS') {
      return this.assetAgreementStatus?.isVimsAgreed;
    } else if (billingType[modalIndex] == 'HEI') {
      return this.assetAgreementStatus?.isHeiAgreed;
    } else if (billingType[modalIndex] == 'MineStar') {
      return this.assetAgreementStatus?.isMinestarAgreed;
    }

    return;
  }

  loadBillingNoticePop(billingType: any, modalIndex = 0) {
    const dealerActionRequiredModalRef = this.modal.openModal(DealerActionRequiredModalComponent, {
      width: '750px',
      type: this.modalType,
      panelClass: ['test'],
      disableMargin: true,
      disableBackdropClick: true,
      data: {
        languageTranslations: this.languageTranslations,
        billingType: billingType[modalIndex],
      },
    });
    dealerActionRequiredModalRef.afterClosed().subscribe((result: any) => {
      if (result === 'i agree') {
        this.isDealerActionRequiredPopupAgreed = true;
        this.billingTypeStatus = billingType;
        ++modalIndex;
        if (modalIndex === billingType.length) {
          this.validateCCSMessage();
        } else {
          this.loadBillingNoticePop(billingType, modalIndex);
        }
      } else if (result === 'exit') {
        this.isDealerActionRequiredPopupAgreed = false;
        if (billingType[modalIndex] == 'VIMS') {
          this.assetAgreementStatus.isVimsAgreed = false;
        }
        if (billingType[modalIndex] == 'HEI') {
          this.assetAgreementStatus.isHeiAgreed = false;
        }
        if (billingType[modalIndex] == 'MineStar') {
          this.assetAgreementStatus.isMinestarAgreed = false;
        }
      }
    });
  }

  validateCCSMessage() {
    const ccsEnabled = this.isB2CDealer();
    if (this.telUserType === dspConstants.Common.DEALER && ccsEnabled) {
      this.handleWarningMessage(this.ccsMessage);
    } else {
      //Removed checkUcidConsolidated function as part of cleanup feature - post row and china migration(558481)
      this.validateSubscriptionCombination();
    }
  }

  handleWarningMessage(content: any) {
    this.warningModalRef = this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.warningModalRef.afterClosed().subscribe((_result: any) => {
      //Removed checkUcidConsolidated function as part of cleanup feature - post row and china migration(558481)
      this.validateSubscriptionCombination();
    });
  }

  handleClose() {
    this.warningModalRef.close();
  }

  isB2CDealer() {
    // Check if asset belongs to b2C and CAT CONNECT SUPPORT is enabled when logged in as Dealer
    const { currentService } = this.selectedAssetSubscriptionFormData.service;
    const customerInfo = this.getValidCustomerForAsset();
    if (
      customerInfo &&
      customerInfo.length == 1 &&
      customerInfo[0].ucid &&
      this.b2cCustomers &&
      this.b2cCustomers.ucids &&
      this.b2cCustomers.applns &&
      this.b2cCustomers.ucids.indexOf(customerInfo[0].ucid) != -1 &&
      this.b2cCustomers.applns.indexOf(currentService) != -1
    ) {
      const filteredCCS = this.b2cCustomers.ccsCustomers.filter(
        (customer: any) => customer.ucid === customerInfo[0].ucid
      );
      return filteredCCS.length > 0;
    }
    return false;
  }

  getValidCustomerForAsset() {
    const { customer } = this.selectedAssetSubscriptionFormData;
    return this.dspUtilsService.getValidCustomerForAsset(customer);
  }

  validateSubscriptionCombination() {
    this.assetRowData.selectedAssetDetails.updatedTime = this.assetService.lastUpdatedTime
      ? this.assetService.lastUpdatedTime
      : this.assetRowData.updatedTime;
    const { prepaySelected } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    const payload: any = {
      assetId: `${this.assetRowData.selectedAssetDetails.make}|${this.assetRowData.selectedAssetDetails.serialNumber}`,
      commercialType: this.assetRowData.selectedAssetDetails.deviceType,
      model: this.assetRowData.selectedAssetDetails.model,
      serviceSubscribed: this.selectedAssetSubscriptionFormData.service.currentService,
      prepay: this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected,
      contractStartDate: this.formatContractDates('contractStartDate', prepaySelected),
      contractEndDate: this.formatContractDates('contractEndDate', prepaySelected),
      catLevel: this.formatSubscriptionsData('catLevel'),
      customerLevel: this.formatSubscriptionsData('customerLevel'),
      dealerLevel: this.formatSubscriptionsData('dealerLevel'),
      catPrevLevel: '',
      dealerPrevLevel: '',
      customerPrevLevel: '',
      requestingDealerCode: this.dealer?.dealerCode,
      ucid: this.selectedAssetSubscriptionFormData.customer.ucid,
      ucName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dealerCustomerId: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dcn: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      updatedTimeStamp: this.getUpdatedTimeStamp(this.assetRowData?.selectedAssetDetails?.updatedTime || null)
        ? this.assetRowData.selectedAssetDetails.updatedTime
        : new Date().toJSON(),
      billToParty: this.checkB2CCustomer() ? dspConstants.Common.CUSTOMER : dspConstants.Common.DEALER,
    };
    const { currentProductFamily } = this.selectedAssetSubscriptionFormData.productFamily;
    if (currentProductFamily !== '') {
      payload.productFamily = currentProductFamily;
    }

    this.assetService.validateSubscriptionSet(payload).subscribe({
      error: (error: any) => {
        let responseText = error?.error?.text;
        if (responseText === 'Success') {
          if (this.subsPricingEligible) {
            this.currentPageIndex = this.currentPageIndex + 1;
            this.trackerIndex = this.currentPageIndex;
          } else {
            this.saveSubscription();
          }
        } else {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          if (responseText === 'Zuora_Billing_Not_Supported') {
            responseText = this.languageTranslations?.Shared?.['WL_BILLING_NOT_SUPPORTED_MSG'];
            this.showToastMessage(responseText, 'error');
          } else if (!isEmpty(responseText)) {
            this.showToastMessage(responseText, 'error');
          } else {
            this.showToastMessage(this.systemError, 'error');
          }
        }
      },
    });
  }

  saveSubscription() {
    this.assetRowData.selectedAssetDetails.updatedTime = this.assetService.lastUpdatedTime
      ? this.assetService.lastUpdatedTime
      : this.assetRowData.updatedTime;
    const { selectedAssetDetails } = this.assetRowData;
    const { prepaySelected } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    const payload: any = {
      assetId: `${this.assetRowData.selectedAssetDetails.make}|${this.assetRowData.selectedAssetDetails.serialNumber}`,
      commercialType: selectedAssetDetails.deviceType,
      model: selectedAssetDetails.model,
      serviceSubscribed: this.selectedAssetSubscriptionFormData.service.currentService,
      prepay: this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected,
      contractStartDate: this.formatContractDates('contractStartDate', prepaySelected),
      contractEndDate: this.formatContractDates('contractEndDate', prepaySelected),
      catLevel: this.formatSubscriptionsData('catLevel'),
      customerLevel: this.formatSubscriptionsData('customerLevel'),
      dealerLevel: this.formatSubscriptionsData('dealerLevel'),
      catPrevLevel: '',
      dealerPrevLevel: '',
      customerPrevLevel: '',
      requestingDealerCode: this.dealer?.dealerCode,
      ucid: this.selectedAssetSubscriptionFormData.customer.ucid,
      ucName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dealerCustomerId: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dcn: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      updatedTimeStamp: this.getUpdatedTimeStamp(this.assetRowData?.selectedAssetDetails?.updatedTime || null),
      siteId: this.selectedAssetSubscriptionFormData?.siteInfo?.siteId || '',
    };
    const subsCancelReason = this.selectedAssetSubscriptionFormData.cancelOptions?.reason
      ? this.selectedAssetSubscriptionFormData.cancelOptions.reason
      : '';
    if (subsCancelReason !== '') {
      payload.catCancelReason = payload.dealerCancelReason = payload.customerCancelReason = subsCancelReason;
    }
    const billingEnabledApps = [
      dspConstants.Worklist.EQUIPMENT_INSIGHTS,
      dspConstants.Worklist.CAT_REMOTE_FLEET_VISION,
      dspConstants.Worklist.MARINE_CONCIERGE_PLUS,
      dspConstants.Worklist.CAT_PRODUCTIVITY,
    ];
    const { currentService } = this.selectedAssetSubscriptionFormData.service;
    const { billingGroupId } = this.selectedAssetSubscriptionFormData.catBillingData;
    const { customerLevel } = this.selectedAssetSubscriptionFormData.subscription.currentSubscription;
    const { currentProductFamily } = this.selectedAssetSubscriptionFormData.productFamily;
    if (billingEnabledApps.indexOf(currentService) > -1 && billingGroupId) {
      payload.billingGroupId = billingGroupId;
    }
    if (this.checkB2CCustomer() && customerLevel) {
      payload.billToParty = dspConstants.Common.CUSTOMER;
    } else {
      payload.billToParty = dspConstants.Common.DEALER;
    }

    if (currentProductFamily !== '') {
      payload.productFamily = subsCancelReason !== '' ? null : currentProductFamily;
    }
    this.assetService.subscribeAsset(payload).subscribe({
      error: (error: any) => {
        if (error.status === 202) {
          this.getNewSubscriptionData = true;
          this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.SUBSCRIBED;
          const successResponse = 'Plan added successfully.';
          const successResponseForCancelSubs = 'Plan cancelled successfully.';
          const successResponseForEditSubs = 'Plan updated successfully.';
          const selectorId = this.launchType == 'LANDING' ? 'container-id' : 'manage-asset';
          const hostType = this.launchType == 'LANDING' ? 'overlay' : 'container-overlay';
          if (subsCancelReason !== '') {
            if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand == 'Suspend') {
              this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.SUSPENDED;
            } else {
              this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.UNSUBSCRIBED;
            }
            this.showToastMessage(successResponseForCancelSubs, 'success', hostType, selectorId);
          } else if (this.isEditFlow) {
            this.saveAssetAgreement();
            this.showToastMessage(successResponseForEditSubs, 'success', hostType, selectorId);
          } else this.showToastMessage(successResponse, 'success', hostType, selectorId);

          if (!this.isEditFlow && this.isDealerActionRequiredPopupAgreed && subsCancelReason == '')
            this.saveAssetAgreement();
          else if (subsCancelReason !== '') {
            this.cancelAssetAgreement();
          }
        } else {
          this.getNewSubscriptionData = false;
          this.showToastMessage(this.systemError, 'error');
        }
        this.closeDrawer();
      },
    });
  }

  formatSubscriptionsData(selectedLevel: keyof SubscriptionData) {
    const { currentSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (!isEmpty(currentSubscription[selectedLevel]) || !isEmpty(currentAddons)) {
      let selectedSubscriptions = [currentSubscription[selectedLevel], ...currentAddons];
      if (selectedLevel !== 'catLevel') {
        selectedSubscriptions = selectedSubscriptions.filter(
          (subscription) => !includes(this.catOnlyAddons, subscription)
        );
      }
      if (
        (currentSubscription.catLevel || currentSubscription.customerLevel || currentSubscription.dealerLevel) ===
        'None'
      ) {
        return [];
      } else return selectedSubscriptions;
    } else {
      return [];
    }
  }

  formatContractDates(dateOption: keyof PrepayContractInfo, prepayFlag: boolean) {
    if (!prepayFlag) return null;
    const selectedDateRange = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData[dateOption] || '';
    return !isEmpty(selectedDateRange) ? selectedDateRange : null;
  }

  getUpdatedTimeStamp(updatedTime = null) {
    return updatedTime ? updatedTime : null;
  }
  // updateTrackerIndexFor(index: any) {
  //   this.paymentPreviewpStepIndex = index;
  // }
  setNextDisabled() {
    return !this.selectedAssetSubscriptionFormData.isValidForm;
  }

  setBackDisabled() {
    return false; // for now returning it as false
  }

  updateSubmitButtonLabel() {
    if (this.currentPageIndex === this.dynamicTrackerData.length - 1) {
      return 'Submit';
    } else {
      return 'Next';
    }
  }
  updateCancelButtonLabel() {
    if (
      this.trackerIndex === 0 &&
      this.launchType === 'LANDING' &&
      this.assetRowData?.selectedAssetDetails?.reportingState !== 'Subscribed'
    ) {
      return 'Cancel';
    } else {
      return 'Back';
    }
  }

  getAssetAgreement() {
    this.assetId = this.selectedRowData?.assetId;

    this.assetService.getAssetAgreement(this.assetId).subscribe({
      next: (res: any) => {
        this.assetAgreementStatus = res as AssetAgreementStatus;
      },
      error: (error: any) => {
        this.assetAgreementStatus = {};
      },
    });
  }

  saveAssetAgreement() {
    if (this.billingTypeStatus?.length > 0) {
      if (this.billingTypeStatus[0] == 'VIMS') {
        this.assetAgreementStatus.isVimsAgreed = true;
      }
      if (this.billingTypeStatus[0] == 'HEI') {
        if (this.billingTypeStatus.length > 1 && this.billingTypeStatus[1] == 'VIMS')
          this.assetAgreementStatus.isVimsAgreed = true;
        this.assetAgreementStatus.isHeiAgreed = true;
      }
      if (this.billingTypeStatus[0] == 'MineStar') {
        this.billingTypeStatus.forEach((billingType: any) => {
          if (billingType == 'MineStar') this.assetAgreementStatus.isMinestarAgreed = true;
          if (billingType == 'HEI') this.assetAgreementStatus.isHeiAgreed = true;
          if (billingType == 'VIMS') this.assetAgreementStatus.isVimsAgreed = true;
        });
      }
    }
    this.submitAssetAgreement();
  }

  cancelAssetAgreement() {
    this.assetAgreementStatus.isVimsAgreed = this.assetAgreementStatus.isVimsAgreed
      ? false
      : this.assetAgreementStatus.isVimsAgreed;
    this.assetAgreementStatus.isHeiAgreed = this.assetAgreementStatus.isHeiAgreed
      ? false
      : this.assetAgreementStatus.isHeiAgreed;
    this.assetAgreementStatus.isMinestarAgreed = this.assetAgreementStatus.isMinestarAgreed
      ? false
      : this.assetAgreementStatus.isMinestarAgreed;
    this.submitAssetAgreement();
  }

  submitAssetAgreement() {
    this.assetService.saveAssetAgreement(this.assetAgreementStatus).subscribe({
      next: (res: any) => {},
      error: (err: any) => {},
    });
  }
}
