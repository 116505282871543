import { Component, Inject, OnInit, ViewChild, TemplateRef, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { DspCommonService } from '../../../services/dsp-common.service';
import { SitesService } from '../../../services/sites.service';
import { siteData } from '../sites-interface';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { isEmpty, filter } from 'lodash-es';
import { LoaderService } from '../../../services/loader.service';
import { PopupData } from '../../../models/popup.interface';
import * as dspConstants from '../../../shared/dspConstants';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-cancel-site-drawer',
  templateUrl: './cancel-site-drawer.component.html',
  styleUrls: ['./cancel-site-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelSiteDrawerComponent implements OnInit {
  public itemClicked: any;
  modalRef: any;
  currentTableConfig = {
    height: '400',
    expandable: false,
    footer: true,
    columnConfig: [],
  };
  public cancelReasons: any;
  public paymentDetails: any;
  public paymentPreview: any;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  public mainFormGroup!: FormGroup;
  public positiondata = [];
  public selectedReason = '';
  isPricingEligible: any = false;
  userActiveFeatures!: any;
  currentSiteData!: any;
  cancelPlanDetails!: any;
  isCommonPopup = false;
  cancelSiteModalRef: any;
  currentTableData = [
    {
      prodcutName: 'JWJ01355 - CAT 320 FIT',
      prodcutExpDate: 'Asset On',
      price: '4950.00',
    },
    {
      prodcutName: 'JWJ01356 - CAT 320 FIT',
      prodcutExpDate: 'No Reporting',
      price: '1500.00',
    },
    {
      prodcutName: 'JWJ01355 - CAT 320 FIT',
      prodcutExpDate: 'Asset On',
      price: '-1125.00',
    },
    {
      prodcutName: 'JWJ01356 - CAT 320 FIT',
      prodcutExpDate: 'No Reporting',
      price: '-375.00',
    },
  ];
  @ViewChild('templateRef') deleteGroupModalTemplate!: TemplateRef<any>;
  isChinaDealer: boolean = false;
  dealer: any;
  isPaymentPreviewMessage = false;
  paymentMessage: any;
  cancelNotificationMessage: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    public dialogRef: CcDrawerRef<CancelSiteDrawerComponent>,
    @Inject(CC_DRAWER_DATA) public list: any,
    public modal: CcModal,
    private apiService: DspCommonService,
    private messageBar: MessageBar,
    private formBuilder: FormBuilder,
    private sitesService: SitesService,
    private store: Store<DSPAppState>,
    private loaderService: LoaderService,
    private assetDrawerService: AssetDrawerService
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();

    this.mainFormGroup = this.formBuilder.group({
      placementPosition: [],
    });

    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.cancelReasons = dsp?.siteCancelReasons.siteCancelReasons || [];
      if (this.userActiveFeatures?.indexOf('Contract Visualization') == -1) {
        this.cancelReasons.forEach((item: any, index: any) => {
          if (item.reason === 'Price') this.cancelReasons.splice(index, 1);
        });
      }
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp.featureFlagInfo?.isCommonPopup : false;
      this.isPaymentPreviewMessage = dsp?.featureFlagInfo?.isPaymentPreviewMessage
        ? dsp?.featureFlagInfo?.isPaymentPreviewMessage
        : false;
    });
    this.getPaymentDetails();
    this.fetchLocationName();
  }

  fetchLocationName() {
    this.sitesService
      .getMapInfo({
        key: 'AkBeaUitImCsJQi6z9wMXhQsFN78E0IsNcLTiFlV6hBQuQDR2gw6K0ClI_0U79P0',
        query: `${this.list.siteInfo.latitude ? this.list.siteInfo.latitude : ''},${
          this.list.siteInfo.longitude ? this.list.siteInfo.longitude : ''
        }`,
      })
      .subscribe({
        next: (response: any) => {
          this.list.siteInfo.mapLocationInfo = response.resourceSets[0].resources.length
            ? response.resourceSets[0].resources[0].name
            : '-';
        },
        error: (errorResponse) => {},
      });
  }

  updatePaymentPreview(siteInfo: siteData) {
    return {
      dealerCode: siteInfo.dealerCode,
      ucid: siteInfo.ucid,
      customerName: siteInfo.ucidName,
      dcnName: siteInfo.dcnName,
      dealerName: this.dealerStringObj?.dealerName ? JSON.parse(this.dealerStringObj).dealerName : '',
      dcnNumber: siteInfo.dcn,
      siteName: siteInfo.name,
      applicationName: siteInfo.product,
      isNewSubscription: false,
      subscriptionType: 'Site',
      siteLocation: null,
    };
  }

  closeDrawer(refresh = false) {
    this.dialogRef.close(refresh);
  }

  openDeleteGroupModal(content: any) {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.CONFIRM_POPUP_TITLE,
        messageArray: [dspConstants.Common.CANCEL_SITE_POPUP_BODY_LINE1],
        showCrossMark: false,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SUBMIT,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
      };

      this.cancelSiteModalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '466px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.cancelSiteModalRef.afterClosed().subscribe((res: any) => {
        if (res === dspConstants.PopupButtons.SUBMIT) {
          this.deleteGroup();
        }
      });
    } else {
      this.modalRef = this.modal.openModal(content, {
        width: '466px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
    }
  }

  closeDeleteGroupModal() {
    this.modalRef.close();
  }

  deleteGroup() {
    const cancelPlanPayload = this.updateCancelPlanPayload();
    this.apiService.createSiteSubscription(cancelPlanPayload).subscribe({
      next: (_res: any) => {
        this.showDeleteSuccessfulMessage();
        if (this.dialogRef) {
          this.closeDrawer(true);
        }
        this.list.fetchSites();
        this.modalRef.close();
      },
      error: (_err) => {
        this.messageBar.open('Issue In Subscription Canceled subscription', 'error', [], {
          hostSelectorId: 'message-outlet',
          hostType: 'container-overlay',
          duration: 2000,
        });
      },
    });
  }

  getPaymentDetails() {
    const paymentPreviewPayload = this.updatePaymentPreviewPayload(this.list.siteInfo);
    if (this.list?.mineStarDetails && this.list?.siteInfo?.product && this.list?.siteInfo?.planName) {
      this.currentSiteData = this.list.mineStarDetails[this.list.siteInfo.product].filter((ele: any) => {
        return ele.baseService == this.list.siteInfo.plan;
      });
    }
    this.isPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      this.currentSiteData &&
      this.currentSiteData.length &&
      this.currentSiteData[0].enableContractVisualization;
    this.loaderService.show();
    if (this.isPricingEligible) {
      this.apiService.getPaymentPreview(paymentPreviewPayload).subscribe({
        next: (paymentDetails: any) => {
          paymentDetails = !isEmpty(paymentDetails) ? paymentDetails : this.updatePreviousBillingDetails();
          this.paymentDetails = paymentDetails;
          this.cancelNotificationMessage = this.paymentDetails.cancelNotificationMessage
            .split('<br/>')
            .join(' ')
            .replace('href="terms/tos"', 'onclick="termSaleModal()"');
          const TOS_REGEX = new RegExp('When you submitted your order, you agreed to our <a[^>]*>Terms of Sale</a>');
          if (
            (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) ||
            this.userActiveFeatures?.indexOf('Contract Visualization') === -1
          ) {
            this.cancelNotificationMessage = this.cancelNotificationMessage.replace(TOS_REGEX, '');
          }
          this.paymentPreview = this.updatePaymentPreview(this.list.siteInfo);
          this.loaderService.hide();
        },
        error: (err: any) => {
          this.paymentDetails = {};
          this.paymentPreview = this.updatePaymentPreview(this.list.siteInfo);
          this.loaderService.hide();
        },
      });
    } else {
      if (this.isPaymentPreviewMessage) {
        this.paymentPage();
      }
      this.cancelPlanDetails = [];
      this.cancelPlanDetails.push(this.list.siteInfo.plan);
      this.cancelPlanDetails = [...this.cancelPlanDetails, ...this.list.siteInfo.addOnServices];
      this.loaderService.hide();
    }
  }

  updatePaymentPreviewPayload(siteInfo: siteData) {
    const PreviewPayLoad = {
      assetMake: '',
      assetSerialNumber: '',
      deviceId: '',
      deviceSerialNumber: '',
      deviceType: '',
      dealerCode: siteInfo.dealerCode,
      ucid: siteInfo.ucid != '' ? siteInfo.ucid : '',
      customerName: siteInfo.ucidName != '' ? siteInfo.ucidName : '',
      dcnName: siteInfo.dcnName != '' ? siteInfo.dcnName : '',
      dcnNumber: siteInfo.dcn != '' ? siteInfo.dcn : '',
      contractEndDate: '',
      contractStartDate: '',
      siteName: siteInfo.name,
      applicationName: siteInfo.product !== '' ? siteInfo.product : 'MineStar',
      isNewSubscription: true,
    };
    return PreviewPayLoad;
  }

  updatePreviousBillingDetails() {
    const previousBillDetails = {
      billToParty: 'Dealer',
      cancelNotificationMessage:
        '\'You have chosen to cancel a month to month subscription. Termination fees do not apply.<br/>When you submitted your order, you agreed to our <a style=\'color: #0088cc;text-decoration: underline;\' target="_blank" href="terms/tos">Terms of Sale</a>\'',
      new: {},
      current: {
        promotions: [],
        monthlyRateAfterDiscount: '',
        monthlyRate: '',
        terminationFee: [],
        products: this.addProductsData(),
      },
      errorMessage: null,
    };
    previousBillDetails.current.monthlyRateAfterDiscount = this.fetchEstimatedTotal(
      previousBillDetails.current.products
    );
    return previousBillDetails;
  }

  fetchEstimatedTotal(products: any) {
    let totalPrice = 0;
    products.forEach((product: any) => {
      totalPrice += parseInt(product.amount);
    });
    return `${totalPrice}.00 USD`;
  }

  addProductsData() {
    const productsData: any = [];
    const previousPlanData = filter(this.list.mineStarDetails.MineStar, ['baseService', this.list.siteInfo.plan || '']);
    previousPlanData.forEach((plan: any) => {
      const product = {
        duration: null,
        amount: plan.makePrice.Default,
        promotionEndDate: null,
        contractStartDate: '',
        name: plan.baseService,
        contractEndDate: null,
        description: null,
        type: 'Subscription',
      };
      productsData.push(product);
      if (this.list.siteInfo.addOnServices?.length > 0 && plan?.addOnServices?.length > 0) {
        plan.addOnServices.forEach((addOn: any) => {
          const productObj = {
            duration: null,
            amount: addOn.makePrice.Default,
            promotionEndDate: null,
            contractStartDate: '',
            name: addOn.addOnService,
            contractEndDate: null,
            description: null,
            type: 'Subscription',
          };
          productsData.push(product);
        });
      }
    });
    return productsData;
  }

  updateCancelPlanPayload() {
    const setCustomerlevels = this.list.siteInfo.planName.split('|');
    setCustomerlevels[0] = 'None';
    const serviceSubscriptionValue = setCustomerlevels.length > 0 ? setCustomerlevels.join('|') : 'None';
    const subscriptionPreLevelValue = this.list.siteInfo.planName.split('|');
    const payloadResponse = {
      name: this.list.siteInfo.name,
      description: this.list.siteInfo.description,
      latitude: this.list.siteInfo.latitude,
      longitude: this.list.siteInfo.longitude,
      admin: null,
      siteId: this.list.siteInfo.subscriptionId,
      ucid: this.list.siteInfo.ucid,
      dcName: this.list.siteInfo.dcnName,
      ucName: this.list.siteInfo.ucidName,
      dcn: this.list.siteInfo.dcn,
      billToParty: 'Dealer',
      status: 'Inactive',
      dealerCode: this.dealerCode,
      requestingDealerCode: this.dealerCode,
      customerLevel: setCustomerlevels,
      serviceSubscription: serviceSubscriptionValue,
      customerPrevLevel: subscriptionPreLevelValue,
      cancelReason: this.selectedReason,
      organization: 'Customer',
      organizationType: 'Customer',
      level: 'Customer',
      associatedOrganization: 'Customer',
      associatedOrganizationType: 'Customer',
      origin: 'Owned',
      orgType: 'Customer',
    };
    return payloadResponse;
  }
  showDeleteSuccessfulMessage() {
    const messageText = `Plan cancelled successfully.`;
    this.showToastMessage(messageText, 'success');
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  onDropdownChange({ currentSelection }: any, prop: string) {
    this.mainFormGroup.patchValue({
      [prop]: currentSelection,
    });
    this.selectedReason = currentSelection[0].reason;
  }
  paymentPage(): void {
    const paymentPreviewPayload = this.updatePaymentPreviewPayload(this.list.siteInfo);

    this.apiService.getPaymentPreview(paymentPreviewPayload).subscribe({
      next: (paymentDetails: any) => {
        paymentDetails = !isEmpty(paymentDetails) ? paymentDetails : this.updatePreviousBillingDetails();
        this.paymentDetails = paymentDetails;
        this.cancelNotificationMessage = this.paymentDetails.cancelNotificationMessage
          .split('<br/>')
          .join(' ')
          .replace('href="terms/tos"', 'onclick="termSaleModal()"');
        const TOS_REGEX = new RegExp('When you submitted your order, you agreed to our <a[^>]*>Terms of Sale</a>');
        if (
          (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) ||
          this.userActiveFeatures?.indexOf('Contract Visualization') === -1
        ) {
          this.cancelNotificationMessage = this.cancelNotificationMessage.replace(TOS_REGEX, '');
        }
        this.paymentPreview = this.updatePaymentPreview(this.list.siteInfo);
        this.loaderService.hide();
      },
      error: (err: any) => {
        this.paymentDetails = {};
        this.paymentPreview = this.updatePaymentPreview(this.list.siteInfo);
        this.loaderService.hide();
        console.log('Error occured in fetching payment preview : ' + JSON.stringify(err));
      },
    });
  }
}
