import { Component, OnInit, Input } from '@angular/core';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { BillingInquiryComponent } from '../billing-inquiry/billing-inquiry.component';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'dsp-next-gen-ui-child-dealer',
  templateUrl: './child-dealer.component.html',
  styleUrls: ['./child-dealer.component.scss'],
})
export class ChildDealerComponent implements OnInit {
  modalRef: any;
  dealer: any;
  dspStoreData!: any;
  @Input() invoiceParentDealerCode = '';
  constructor(
    public modal: CcModal,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
  }

  billingInquiryForm() {
    this.modalRef = this.modal.openModal(BillingInquiryComponent, {
      width: '466px',
      type: 'semi-modal',

      isAutoHeightModalContent: true,
      disableBackdropClick: true,
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
    });
  }
}
