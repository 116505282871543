import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { needsReviewLeftTabMockData } from '../../needs-review-left-tab-mock-data';
import { NeedsReviewService } from '../../services/needs-review.service';
import { NeedsReviewSharedService } from '../../services/needs-review-shared.service';
import { CommonFilterService } from 'apps/dsp-ui/src/app/services/common-filter.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { DATA_LAYER_OBJECT } from '../../../../../../apps/dsp-ui/src/app/google-analyatics.interface';
import * as dspConstants from '../../../../../../apps/dsp-ui/src/app/shared/dspConstants';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-needs-review-left-tab',
  templateUrl: './needs-review-left-tab.component.html',
  styleUrls: ['./needs-review-left-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedsReviewLeftTabComponent implements OnInit {
  selectedQueueReasonId: any;
  selectedQueueReasonLabel: any;
  isfilterAppliedForSelectedQR: any;
  needsReviewGroups: Array<any> = [];
  dismissStatusSubscription!: Subscription;
  assetStatusChangeSubs!: Subscription;
  globalSearchReviewAPI!: Subscription;
  isGlobalSearchCompleted!: boolean;
  selectedCategory: any;
  isTableLoading = false;
  @Input() searchChange!: Observable<any>;
  @Output() dealerQueueReasonID = new EventEmitter();
  animate: any;
  searchValue: any;
  pageLabel = 'needsReview';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  queueReasonId: any;
  dealer: any;
  constructor(
    private needsReviewService: NeedsReviewService,
    private commonFilterService: CommonFilterService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.isTableLoading = true;
    this.dismissStatusSubscription = this.needsReviewService.dismissStatus.subscribe((event: any) => {
      if (event && event.newCount != 0) {
        let input = {
          searchText: this.searchValue?.trim(),
        };
        this.needsReviewService.getReviewCategories(input).subscribe((data: any) => {
          this.needsReviewGroups = data;
          this.isTableLoading = false;
          return;
        });
      }
    });
    this.assetStatusChangeSubs = this.needsReviewService.assetStatusChangeFromTable.subscribe((event: any) => {
      if (event) {
        let input = {
          searchText: this.needsReviewService.globalSearchText.trim(),
        };
        this.needsReviewService.getReviewCategories(input).subscribe((data: any) => {
          this.needsReviewGroups = data;
          this.isTableLoading = false;
          return;
        });
      }
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.fetchReviewCategory();
    this.globalSearchChange();
  }
  globalSearchChange() {
    this.searchChange?.subscribe((value) => {
      if (this.globalSearchReviewAPI && !this.isGlobalSearchCompleted) {
        this.globalSearchReviewAPI.unsubscribe();
      }
      if (value?.length > 2) {
        this.needsReviewService.globalSearchText = value.trim();
        this.searchValue = value;
        this.fetchReviewCategory(value);
      } else if (value === '' && this.searchValue) {
        this.needsReviewService.globalSearchText = '';
        this.searchValue = '';
        this.fetchReviewCategory();
      }
    });
  }

  fetchReviewCategory(searchText?: string) {
    this.isTableLoading = true;
    this.needsReviewGroups = [];
    let input = {
      searchText: searchText?.trim(),
    };
    this.isGlobalSearchCompleted = false;
    this.globalSearchReviewAPI = this.needsReviewService.getReviewCategories(input).subscribe(
      (data: any) => {
        this.isGlobalSearchCompleted = true;
        let isSearchEmpty = false;
        if (data.length === 0 && searchText) {
          this.needsReviewGroups =
            this.needsReviewService.getCategories() && this.needsReviewService.getCategories().length > 0
              ? this.needsReviewService.getCategories()
              : [];
          isSearchEmpty = false;
          this.setDataLayer('Needs Review Global Search', searchText?.trim(), '', 'failed', 'No Results Found');
        } else {
          this.needsReviewGroups = data;
          let searchAssetLists: any = '';
          this.needsReviewGroups.map((lists: any) => {
            searchAssetLists = searchAssetLists + ' ' + lists?.dealerQueueReason + ' | ';
          });
          searchAssetLists = searchAssetLists.substring(0, searchAssetLists.length - 2);
          if (searchText) {
            this.setDataLayer('Needs Review Global Search', searchText?.trim(), searchAssetLists, 'success', '');
          }
        }

        /** By default the selected group should be initial value */
        if (this.needsReviewGroups.length > 0) {
          if (!searchText) {
            this.needsReviewService.setCategories(data);
          }
        }
        let selectedID;
        const dealerQueueReason = sessionStorage.getItem('dealerQueueReason');
        if (dealerQueueReason) {
          selectedID = this.needsReviewGroups.find((item) => item.label === dealerQueueReason);
        } else {
          selectedID = this.needsReviewService.selectedQueueReason
            ? this.needsReviewGroups.find(
                (item) => item.dealerQueueReasonId === this.needsReviewService.selectedQueueReason
              )
            : this.needsReviewGroups[0];
        }
        this.selectedCategory = isSearchEmpty ? '' : selectedID?.label;
        if (selectedID && !isSearchEmpty) {
          this.needsReviewService.queueReason.next(selectedID);
          this.selectedQueueReasonId = selectedID?.dealerQueueReasonId;
          this.selectedQueueReasonLabel = selectedID?.label;
          if (data.length > 0) {
            this.setDataLayer(
              'Needs Review Queue Reason Selected',
              this.selectedQueueReasonLabel,
              'Facet Nav Selected',
              'success',
              ''
            );
          }
        }

        this.dealerQueueReasonID.emit({
          id: selectedID?.dealerQueueReasonId,
          isQueueReasonSelected: false,
          label: this.selectedCategory,
        });
        this.isTableLoading = false;
      },
      () => {
        this.setDataLayer('Needs Review Global Search', searchText?.trim(), '', 'failed', this.systemError);
        this.isGlobalSearchCompleted = true;
        this.isTableLoading = false;
      }
    );
  }

  /**Emitting the queueReasonId for the table component on click of each group */
  onGroupBlockClick(group: any) {
    this.selectedCategory = group.label;
    this.selectedQueueReasonId = group?.dealerQueueReasonId;
    this.selectedQueueReasonLabel = group?.label;
    this.dealerQueueReasonID.emit({
      id: group.dealerQueueReasonId,
      isQueueReasonSelected: true,
      label: this.selectedCategory,
    });
    this.setDataLayer('Needs Review Queue Reason Selected', this.selectedCategory, 'Facet Nav Selected', 'success', '');
    this.needsReviewService.queueReason.next(group);
  }
  setDataLayer(formName: any, formContent: any, formContent2: any, status: any, errorReason: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: formName,
      formContent: formContent,
      formContent2: formContent2,
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: errorReason,
      formLocation: 'Needs Review Page',
    };
    this.sharedService.updateDataLayer(object);
  }
  ngOnDestroy() {
    sessionStorage.removeItem('dealerQueueReason');
  }
}
