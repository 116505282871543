import { dealerType } from '../models/shared.interface';
import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper: JwtHelperService = new JwtHelperService();
let defaultDealerDetails: dealerType = {
  dealerCode: 'TD00',
  dealerName: 'CATERPILLAR DEMO DEALER',
  dealerStreet: '100 NE ADAMS ST',
  dealerCity: 'PEORIA',
  dealerState: 'IL',
  dealerZipCode: '61629-2340',
  dealerCountry: 'US',
  dealerPhone: '1-309-555-1212',
  dealerEmail: '',
  dealerEDURL: 'https://equipmentdata.rd.cat.com/ed/site/equipmentSearch/home',
};

export function fetchLoggedInDealerDetails() {
  const dealerData = sessionStorage.getItem('dealer');

  const decodedAccessToken = fetchdecodedAccessToken();
  if (decodedAccessToken !== '') {
    let dealerCode = decodedAccessToken?.catafltnorgcode;
    if (['013', '014', '001', '012', '019', '053', '054', '048'].includes(decodedAccessToken?.catafltncode)) {
      dealerCode = 'TD00';
    }
    defaultDealerDetails = { ...defaultDealerDetails, dealerCode: dealerCode };
  }
  return dealerData ? JSON.parse(dealerData) : defaultDealerDetails;
}

export function sortOwnershipRecords(ownershipData: any) {
  const sortorder = ['O', 'R', 'L', 'N', 'X'];
  const orderedOwnershipTypeArray: any = [];
  sortorder.forEach((orderValue) => {
    ownershipData?.forEach((dataValue: any) => {
      if (dataValue.ownershipType == orderValue) {
        orderedOwnershipTypeArray.push(dataValue);
      }
    });
  });
  return orderedOwnershipTypeArray;
}

export function fetchdecodedAccessToken() {
  return jwtHelper.decodeToken(localStorage.getItem('access_token') || '');
  //return jwtHelper.decodeToken(localStorage.getItem('dsp-token') || '');
}
