import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { isEmpty } from 'lodash-es';
import { dealerType } from '../../../models/shared.interface';
import { SubscribeAccountsService } from '../../../services/subscribe-accounts.service';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { AccountFormType, getUsersSearchPayload } from '../accounts-interface';
import { InputRestrictionDirective } from '../../../shared/input-restriction.directive';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-search-cws',
  templateUrl: './search-cws.component.html',
  styleUrls: ['./search-cws.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchCwsComponent implements OnInit {
  @Input() selectedData!: AccountFormType;
  @Input() parentType = '';
  @Output() accountFormChangeEvent = new EventEmitter();
  @ViewChild('cwsidField') cwsidField!: ElementRef;
  @ViewChild('lastNameField') lastnameField!: ElementRef;
  @ViewChild('firstNameField') firstNameField!: ElementRef;
  @ViewChild('emailIDField') emailIDField!: ElementRef;
  @ViewChild('refineSearchModal') refineSearchModal: any;
  searchUsersForm!: FormGroup;
  showAdvancedSearchOptions = false;
  getUsersSearchPayload!: getUsersSearchPayload;
  public userData: Array<object> = [];
  cwsId: any;
  firstName: any;
  lastName: any;
  emailId: any;
  isTheme = '2.0';
  refineSearchModalRef!: CcModalRef<any, any>;
  search: any;
  dealer!: dealerType;
  toastMessageHostElement = '';
  searchTriggered = false;
  disableClearAllBtn = true;
  isShowClearAll = true;
  dspStoreData: any;
  isTrailingCWSID = true;
  isTrailingLastName = true;
  isTrailingFirstName = true;
  isTrailingEmail = true;

  constructor(
    private fb: FormBuilder,
    private accountService: SubscribeAccountsService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageBar: MessageBar,
    private modal: CcModal,
    private store: Store<DSPAppState>
  ) {
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.isShowClearAll = this.dspStoreData?.featureFlagInfo?.isShowClearAll
        ? this.dspStoreData?.featureFlagInfo?.isShowClearAll
        : false;
    });
    this.searchUsersForm = this.fb.group({
      cwsId: ['', [Validators.minLength(3)]],
      lastName: ['', [Validators.minLength(3), InputRestrictionDirective.cannotContainSpace]],
      firstName: ['', [Validators.minLength(3)]],
      emailId: ['', [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    this.updateToastMessageHost();
    this.handleFormValidity();
    this.onChanges();
    this.accountService.setAccountFormData(this.selectedData);
  }

  updateToastMessageHost() {
    if (this.parentType === 'edit-plan') {
      this.toastMessageHostElement = 'edit-plan-container';
    } else if (
      this.selectedData.plan === 'Technician Toolbox' ||
      this.selectedData.plan === 'SIS2.0 USB' ||
      this.selectedData.plan === 'Service Media Access'
    ) {
      this.toastMessageHostElement = 'search-cws-container';
    } else {
      this.toastMessageHostElement = 'container-id';
    }
  }

  handleFormValidity() {
    this.selectedData.isValidForm = this.selectedData.selectedUsers?.length > 0;
  }

  get formControl() {
    return this.searchUsersForm.controls;
  }

  onChanges() {
    this.searchUsersForm.valueChanges.subscribe((value) => {
      if (this.isShowClearAll) {
        Object.keys(this.searchUsersForm.controls).forEach((element) => {
          if (this.searchUsersForm.controls[element].value?.length > 0) {
            this.disableClearAllBtn = false;
          }
        });
      }
      if (value && this.searchUsersForm.status !== 'VALID' && this.searchTriggered) {
        Object.keys(this.searchUsersForm.controls).forEach((element) => {
          if (this.searchUsersForm.controls[element].value?.length > 2) {
            this.searchUsersForm.controls[element].setErrors(null);
          }
          this.searchTriggered = false;
        });
      }
    });
  }

  handleSearchFormChange(formValue: string, event: any) {
    if (event.value === '') {
      this.disableClearAllBtn = this.isShowClearAll ? true : false;
      this.searchUsersForm.controls[formValue].patchValue('');
      this.searchUsersForm.controls[formValue].setErrors(null);
    }
    if (event.value !== '') {
      if (formValue === 'cwsId') {
        this.isTrailingCWSID = true;
      }
      if (formValue === 'lastName') {
        this.isTrailingLastName = true;
      }
      if (formValue === 'firstName') {
        this.isTrailingFirstName = true;
      }
      if (formValue === 'emailId') {
        this.isTrailingEmail = true;
      }
    }
    if (formValue === 'cwsId' && this.searchUsersForm.controls['lastName'].value === '') {
      this.searchUsersForm.controls['lastName'].patchValue('');

      this.searchUsersForm.controls['lastName'].setErrors(null);
    }

    if (formValue === 'lastName' && this.searchUsersForm.controls['cwsId'].value === '') {
      this.searchUsersForm.controls['cwsId'].patchValue('');

      this.searchUsersForm.controls['cwsId'].setErrors(null);
    }
  }

  clearAll() {
    if (this.searchUsersForm.value.cwsId !== '') {
      this.isTrailingCWSID = false;
    }

    if (this.searchUsersForm.value.lastName !== '') {
      this.isTrailingLastName = false;
    }
    if (this.searchUsersForm.value.firstName !== '') {
      this.isTrailingFirstName = false;
    }
    if (this.searchUsersForm.value.emailId !== '') {
      this.isTrailingEmail = false;
    }

    this.searchUsersForm.controls['cwsId'].setValue('');
    this.searchUsersForm.controls['cwsId'].setErrors(null);
    this.searchUsersForm.controls['lastName'].setValue('');
    this.searchUsersForm.controls['lastName'].setErrors(null);
    this.searchUsersForm.controls['firstName'].setValue('');
    this.searchUsersForm.controls['emailId'].setValue('');
    if (
      this.searchUsersForm.value.cwsId == '' &&
      this.searchUsersForm.value.lastName == '' &&
      this.searchUsersForm.value.firstName == '' &&
      this.searchUsersForm.value.emailId == ''
    ) {
      this.disableClearAllBtn = true;
    }
  }

  handleUserSearch(_event: any) {
    if (this.searchUsersForm.value.cwsId == '' && this.searchUsersForm.value.lastName == '') {
      this.searchUsersForm.controls['cwsId'].setValidators([Validators.required, Validators.minLength(3)]);
      this.searchUsersForm.controls['lastName'].setValidators([
        Validators.required,
        Validators.minLength(3),
        InputRestrictionDirective.cannotContainSpace,
      ]);
    } else {
      this.searchUsersForm.controls['cwsId'].setValidators([Validators.minLength(3)]);
      this.searchUsersForm.controls['lastName'].setValidators([
        Validators.minLength(3),
        InputRestrictionDirective.cannotContainSpace,
      ]);
    }
    this.searchUsersForm.controls['cwsId'].updateValueAndValidity();
    this.searchUsersForm.controls['lastName'].updateValueAndValidity();
    this.validateAllFields(this.searchUsersForm);
    this.fetchUsers();
  }
  fetchUsers() {
    if (this.searchUsersForm.status === 'VALID') {
      this.searchTriggered = true;
      this.getUsersSearchPayload = {
        application: this.selectedData?.product || '',
        cwsId: this.searchUsersForm.value.cwsId,
        lastName: this.searchUsersForm.value.lastName,
        firstName: this.searchUsersForm.value.firstName,
        email: this.searchUsersForm.value.emailId,
        plan: this.selectedData?.plan || '',
        resultLimit: 25,
        sortField: 'lastName',
        sortOrder: 'ASC',
        ucid: this.selectedData?.customer?.ucid || '',
        assetPrefix: this.selectedData.prefix,
      };
      this.accountService.getUsersSearch(this.getUsersSearchPayload).subscribe({
        next: (response: any) => {
          if (response) {
            this.userData = [];
            if (!response.users || response.users.length === 0) {
              this.handleShowErrorMessage(response.users, this.selectedData?.plan);
            } else if (!isEmpty(response.users) && this.validateExactTTUserSearch(response.users)) {
              const searchWarningMessage = `A subscription is already active for the selected customer ID and CWS ID, under dealer ${this.dealer.dealerCode}.`;
              this.showToastMessage(searchWarningMessage, 'error');
            } else {
              this.userData = response.users ? response.users : [];
              this.userData = this.filterSelectedUsers(this.userData);
              this.updateUserDataFunc(this.userData);
            }
            this.changeDetectorRef.detectChanges();
          } else {
            this.validateAllFields(this.searchUsersForm);
          }
        },
        error: () => {
          const messageText = `There was error processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
        },
      });
    }
  }

  validateExactTTUserSearch(users: any) {
    return this.selectedData.licenseType === 'Limited Users' && users.length === 1 && users[0].isSubscribed;
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearchOptions = !this.showAdvancedSearchOptions;
  }

  updateUserDataFunc(data: any) {
    this.userData = data;
    this.changeDetectorRef.markForCheck();
  }

  handleShowErrorMessage(userResponse: any, plan: any) {
    let messageText = '';
    if (!userResponse) {
      messageText = `Too many results returned. Please refine your search with advanced search options.`;
    } else {
      messageText = `No results found.`;
    }
    this.updateErrorField();
    this.showToastMessage(messageText, 'error');
  }

  updateErrorField() {
    Object.keys(this.searchUsersForm.controls).forEach((element) => {
      if (!isEmpty(this.searchUsersForm.controls[element].value)) {
        this.searchUsersForm.controls[element].setErrors({ required: true });
      }
    });
  }

  filterSelectedUsers(userResponse: any) {
    if (!isEmpty(this.selectedData.selectedUsers)) {
      const userResponseFinal = userResponse.filter((searchedUser: any) => {
        return !this.selectedData.selectedUsers.some(function (selectedUser: any) {
          return searchedUser.catRecId == selectedUser.catRecId;
        });
      });
      return userResponseFinal;
    } else {
      return userResponse;
    }
  }

  onFocusEvent(fieldType: string, fieldName: string) {
    setTimeout(() => {
      const textLength = this.searchUsersForm.value[fieldType]?.length || 0;
      if (fieldName === 'cwsidField') {
        this.cwsidField.nativeElement.setSelectionRange(textLength, textLength);
      } else if (fieldName === 'lastnameField') {
        this.lastnameField?.nativeElement.setSelectionRange(textLength, textLength);
      } else if (fieldName === 'firstNameField') {
        this.firstNameField?.nativeElement.setSelectionRange(textLength, textLength);
      } else if (fieldName === 'emailIDField') {
        this.emailIDField?.nativeElement.setSelectionRange(textLength, textLength);
      }
    }, 100);
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      appOverlayClass: 'search-cws-overlay',
      hostSelectorId: this.toastMessageHostElement,
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
