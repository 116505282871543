import { CcDrawerConfig, CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { cloneDeep, debounce } from 'lodash-es';
import { RADIOTYPECOLUMNS } from '../../../components/assets/asset-constants';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { FilterCloseConfirmationComponent } from '../../../components/filter-close-confirmation/filter-close-confirmation.component';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { CommonFilterService } from '../../../services/common-filter.service';
import { PopupData } from '../../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../../../components/common-confirmation-popup/common-confirmation-popup.component';
import * as dspConstants from '../../../shared/dspConstants';
import { HomeService } from '../../../components/home/services/home.service';

@Component({
  selector: 'dsp-next-gen-ui-common-showall-filter',
  templateUrl: './common-showall-filter.component.html',
  styleUrls: ['./common-showall-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CommonShowallFilterComponent implements OnInit {
  @Output() applyFiltersCallback = new EventEmitter();
  @ViewChild('columnFilterSearchBox') columnFilterSearchBox!: ElementRef;
  @ViewChild('dropdownOverlay') public dropdownOverlay!: ElementRef<HTMLDivElement>;
  @ViewChild('showAllContainer') public showAllContainer!: ElementRef<HTMLDivElement>;
  headerContent: string | undefined;
  title: string | undefined;
  columns: any = {};
  tempColumns: any = {};
  showallData: any = {};
  subFilter: any;
  searchValue = '';
  searchHelpText = '';
  mobileHeader = 'Search';
  searchString = '';
  expandDropdown = false;
  enableViewBtn: any = true;
  SearchFilterData: any;
  retainShowAllData: any;
  isLoading: any;
  isApiCallCompleted = false;
  loader = true;
  cachedData: any;
  offset = 0;
  public LIMIT_PER_CALL = 100;
  public nextCursor: any;
  limit: any = 100;
  isDataLoading: any;
  columnsVisible: any;
  globalSearch: any;
  noSearchResults: any;
  fetchedColumnNames: any;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  radioTypeValues: any;
  selectedRadioFilters: any;
  globalSearchInput: any;
  globalSearchType: any;
  globalSortColumn: any;
  dspConfig: any;
  showSearchBox: any;
  previousSelectedFilters: any = {};
  modalRef: any;
  selectedFilter: any;
  isGetDealerCalled = false;
  isCommonPopup = false;
  payload: any;
  @HostListener('window:popstate', ['$event'])
  isSearchFilterEnabled = false;
  onPopState(event: PopStateEvent) {
    this.closeAllModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      headerTitle: string;
      columns: object;
      columnNames: object;
      showallData: object;
      subFilter: boolean;
      nextCursor: boolean;
      selectedRadioFilters: any;
      radioTypeValues: any;
      customData: any;
      ref: any;
      showSearchBox: any;
      pageLabel?: any;
      invoiceNumber?: any;
      queueReasonId?: any;
      status?: any;
      columnDataObject: any;
      mainFilterColumnNames: any;
    },
    public dialogRef: CcDrawerRef<CommonShowallFilterComponent, CcDrawerConfig>,
    private cdr: ChangeDetectorRef,
    public modal: CcModal,
    private store: Store<DSPAppState>,
    private commonFilterService: CommonFilterService,
    public homeService: HomeService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspConfig = dsp.dspConfig || {};
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
      this.isSearchFilterEnabled = dsp?.featureFlagInfo?.isSearchFilterEnabled
        ? dsp?.featureFlagInfo?.isSearchFilterEnabled
        : false;
    });
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.title = this.header.headerTitle ? this.header.headerTitle : '';
    const tempColumns = !this.header.subFilter && this.header.columns ? this.header.columns : this.header.columnNames;
    this.columns = cloneDeep(tempColumns);
    this.previousSelectedFilters = cloneDeep(tempColumns);
    this.subFilter = this.header.subFilter ? this.header.subFilter : false;
    this.showallData = this.header.showallData ? cloneDeep(this.header.showallData) : {};
    this.retainShowAllData = this.header.showallData ? cloneDeep(this.header.showallData) : {};
    this.globalSearchInput = this.globalSearchInput = this.header?.customData?.searchString
      ? this.header.customData.searchString
      : '';
    this.globalSearchType = this.header.customData?.searchType ? this.header.customData.searchType : '';
    this.globalSortColumn = this.header.customData?.sortColumn ? this.header.customData.sortColumn : 'serialNumber';
    this.selectedRadioFilters =
      this.header.selectedRadioFilters && Object.keys(this.header.selectedRadioFilters).length
        ? this.header.selectedRadioFilters
        : {};
    this.radioTypeValues =
      this.header.radioTypeValues && this.header.radioTypeValues.length ? this.header.radioTypeValues : [];
    this.SearchFilterData = this.showallData;
    this.nextCursor = this.header.nextCursor;
    this.showSearchBox = this.header.showSearchBox;
    this.selectedFilter = cloneDeep(this.selectedRadioFilters);
    if (!this.header.invoiceNumber) {
      this.header.invoiceNumber = '';
    }
    if (!this.header.queueReasonId) {
      this.header.queueReasonId = '';
    }
    if (!this.header.status) {
      this.header.status = '';
    }
    window.scroll(0, 0);
    this.dialogRef?.onBackdropClick?.subscribe((result: any) => {
      this.closeAllModal();
    });
  }
  /**
   * Disables scrolling.
   */
  disable_scroll() {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  }
  /**
   * Enable scrolling.
   * This function removes the event listener that prevents scrolling.
   */
  enable_scroll() {
    document.ontouchmove = function (_e) {
      return true;
    };
  }
  /**
   * Handles the scroll event of the element.
   * Triggers an action when the scroll reaches the bottom of the container.
   * @param {Event} event - The scroll event object.
   * @returns {void}
   */
  onScroll(event: any) {
    if (Math.round(event.target.scrollTop) + 1 >= Math.round(event.target.scrollHeight - event.target.offsetHeight)) {
      this.disable_scroll();
      this.emitInfiniteScrollEvent();
      this.cdr.detectChanges();
    }
  }
  /**
   * Handles the selection of a filter value.
   * @param {any} event - The event object.
   * @param {any} filter - The list of column names.
   * @param {string} value - The value of the filter chosen.
   * @constant propVal - propVal is an array used to store selected values for a specific filter property. It is used to accumulate selected values before updating the selectedRadioFilters object.
   * @constant cellularVal - cellularVal is an array containing values related to cellular radio types. It is used to filter values based on cellular radio types from the radioTypeValues array.
   * @constant satelliteVal - satelliteVal is an array containing values related to satellite radio types. It is used to filter values based on satellite radio types from the radioTypeValues array.
   */
  getSelectedFilter(event: any, filter: any, value: string) {
    const propVal: any = [];
    if (event.checked) {
      filter.selectedValues.push(value);
      if (!this.selectedFilter[filter.field]) {
        this.selectedFilter[filter.field] = [];
      }
      this.selectedFilter[filter.field].push(value);
      if (
        filter.field === RADIOTYPECOLUMNS.Cellular &&
        this.radioTypeValues[0].cellularRadioType.length &&
        this.radioTypeValues[1].satelliteRadioType.length &&
        filter.selectedValues.length
      ) {
        filter.selectedValues.forEach((value: any) => {
          const cellularVal = this.radioTypeValues[0].cellularRadioType.filter((p: any) => p.includes(value));
          const satelliteVal = this.radioTypeValues[1].satelliteRadioType.filter((p: any) => p.includes(value));
          if (satelliteVal.length && cellularVal.length == 0) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Satellite, propVal, value);
          }
          if (satelliteVal.length && cellularVal.length) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Satellite, propVal, value);
            this.addSelectionToArray(RADIOTYPECOLUMNS.Cellular, propVal, value);
          }
          if (cellularVal.length && satelliteVal.length == 0) {
            this.addSelectionToArray(RADIOTYPECOLUMNS.Cellular, propVal, value);
          }
        });
      }
    } else {
      if (this.header?.columnDataObject && this.header?.columnDataObject?.length > 0) {
        const keyToRemove = Object.keys(this.header.columnDataObject[0]).filter((key) => {
          if (this.header.columnDataObject[0][key].length > 1) {
            const index = this.header.columnDataObject[0][key].indexOf(value);
            if (index !== -1) {
              this.header.columnDataObject[0][key].splice(index, 1);
            }
          }
          return this.header.columnDataObject[0][key].length === 1 && this.header.columnDataObject[0][key][0] === value;
        });
        keyToRemove.forEach((item: any) => {
          if (item) {
            this.header.mainFilterColumnNames[0].selectedValues.splice(
              this.header.mainFilterColumnNames[0].selectedValues.indexOf(item),
              1
            );
          }
        });
      }
      filter.selectedValues?.splice(filter.selectedValues.indexOf(value), 1);
      this.selectedFilter[filter.field]?.splice(this.selectedFilter[filter.field].indexOf(value), 1);
      if (
        filter.field === RADIOTYPECOLUMNS.Cellular &&
        this.radioTypeValues[0].cellularRadioType.length &&
        this.radioTypeValues[1].satelliteRadioType.length
      ) {
        const cellularVal = this.radioTypeValues[0].cellularRadioType.filter((p: any) => p.includes(value));
        const satelliteVal = this.radioTypeValues[1].satelliteRadioType.filter((p: any) => p.includes(value));
        if (satelliteVal.length && cellularVal.length == 0) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Satellite, value);
          return;
        }
        if (satelliteVal.length && cellularVal.length) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Satellite, value);
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Cellular, value);
          return;
        }
        if (cellularVal.length && satelliteVal.length == 0) {
          this.removeSelectionFromArray(RADIOTYPECOLUMNS.Cellular, value);
          return;
        }
      }
    }
    this.enableViewBtn = false;
  }

  /**
   * Adds a value to the specified property array in the selectedRadioFilters object.
   * If the property array doesn't exist, it creates one.
   * Ensures uniqueness of values in the array.
   *
   * @param {string} prop - The property name to add the value to
   * @param {any} propVal - The current array value of the specified property
   * @param {any} value - The checked value to add to the array
   */
  addSelectionToArray(prop: string, propVal: any, value: any) {
    if (this.selectedRadioFilters[prop]) {
      this.selectedRadioFilters[prop] = this.selectedRadioFilters[prop].concat(value);
    } else {
      this.selectedRadioFilters = {
        ...this.selectedRadioFilters,
        [prop]: propVal.concat(value),
      };
    }
    this.selectedRadioFilters[prop] = this.selectedRadioFilters[prop].filter(function (
      value: any,
      index: any,
      array: any
    ) {
      return array.indexOf(value) === index;
    });
  }
  /**
   * Removes a value from the selected filters array for the given property.
   * @param {string} prop - The property from which to remove the value.
   * @param {any} value - The unchecked value to remove from the selected filters array.
   * @constant index - used to find the index of a specific value within an array stored in the selectedRadioFilters object, under the property prop.
   */
  removeSelectionFromArray(prop: any, value: any) {
    const index = this.selectedRadioFilters[prop]?.indexOf(value);
    if (index !== -1 && this.selectedRadioFilters[prop]?.length && this.selectedRadioFilters[prop].length > 1) {
      this.selectedRadioFilters[prop].splice(index, 1);
    } else if (index !== -1 && this.selectedRadioFilters[prop]?.length && this.selectedRadioFilters[prop].length == 1) {
      delete this.selectedRadioFilters[prop];
    }
  }
  /**
   * Handle the change event when the filter search value changes.
   * This method is responsible for triggering the search functionality and updating the filtered data accordingly.
   * @param {any} event - The event object containing information about the filter search change.
   * @returns {void}
   */
  onFilterSearchChange(event: any) {
    this.cachedData = JSON.parse(JSON.stringify(this.showallData));
    if (event.value.trim().length > 2 && this.searchString !== event.value) {
      this.searchValue = event.value;
      this.searchString = this.searchValue.toLowerCase();
      this.isApiCallCompleted = false;
      this.SearchFilterData[this.columns.field] = [...[]];
      this.getColumnDataOnSearch();
    } else if (event.value.length === 0) {
      this.searchString = '';
      this.SearchFilterData = cloneDeep(this.retainShowAllData);
      this.nextCursor = true;
      this.offset = 0;
      this.showAllContainer.nativeElement.scrollTop = 0;
    }
  }
  /**
   * Debounced method to fetch column data based on search criteria.
   * This method is called when the user types in the search box.
   * It debounces the execution of getColumnData to avoid multiple API calls while typing.
   */
  //eslint-disable-next-line @typescript-eslint/member-ordering
  getColumnDataOnSearch = debounce(() => {
    this.getColumnData();
  }, 500);
  /**
   * Fetches column data based on search criteria.
   * Calls the service to retrieve filter column data.
   * Updates SearchFilterData and nextCursor based on the retrieved data.
   * @constant payload - The payload object is used to store the parameters required for fetching filter column data from the service
   */
  getColumnData() {
    this.isLoading = true;
    if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
      const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: this.searchString,
        searchType: 'globalSearch',
        sortColumn: this.columns.field,
        sortOrder: 'asc',
        operation: this.searchString?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: this.columns.field,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: this.offset,
        limit: this.limit,
        selectedProducts: this.header.selectedRadioFilters.applicationName
          ? this.header.selectedRadioFilters.applicationName
          : [],
        invoiceNumber: this.header.invoiceNumber,
        isHomeSearch: true,
        filter: {
          ucidNameWithId: custSearchpayload,
          dcnWithName: dcnSearchpayload,
        },
      };
    } else {
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: this.searchString.trim(),
        searchType: this.globalSearchType,
        sortColumn: this.columns.field,
        sortOrder: 'asc',
        operation: this.searchString?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: this.columns.field,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: 0,
        limit: 100,
        selectedProducts: this.header.selectedRadioFilters.applicationName
          ? this.header.selectedRadioFilters.applicationName
          : [],
        invoiceNumber: this.header.invoiceNumber,
      };
    }
    this.commonFilterService.getFilterAccordianList(this.payload).subscribe({
      next: (columnData: any) => {
        this.offset = 0;
        this.isLoading = false;
        this.isApiCallCompleted = true;
        this.nextCursor = columnData[0]?.next;
        if (columnData[0] && columnData[0][this.columns.field].length > 0) {
          this.SearchFilterData[this.columns.field] = [...[columnData[0][this.columns.field]]];
        } else {
          this.SearchFilterData[this.columns.field] = [...[]];
        }
        this.cdr.detectChanges();
      },
      error: (err: any) => {
        this.isLoading = false;
        this.isApiCallCompleted = true;
        this.SearchFilterData[this.columns.field] = [...[]];
        this.cdr.detectChanges();
      },
    });
  }
  /**
   * Handle the blur event on the input field.
   * This method is triggered when the input field loses focus.
   * @param {Object} event - The blur event object
   * @returns {void}
   */
  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }
  /**
   * Executes when the input field receives focus.
   * Sets the selection range for the input field's value.
   * @return {void}
   * @constant searchLength - used to determine the length of the searchValue string if it exists, or default to 0 if searchValue is null or undefined.
   */
  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.length || 0;
      this.columnFilterSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }
  /**
   * Emit an event when the scroll reaches the bottom of the container for infinite scrolling.
   */
  public emitInfiniteScrollEvent(): void {
    if (this.nextCursor) {
      if (!this.isGetDealerCalled) {
        this.loader = true;
        this.offset = this.offset == 0 ? this.limit + this.offset : this.offset;
        this.isLoading = true;
        this.getDealerDetails();
      }
    } else {
      this.loader = false;
      this.isLoading = false;
    }
  }

  /**
   * Fetches dealer details.
   * @returns {void}
   * @constant payload - used to store data that is then sent as parameters to fetch asset filters from the service.
   */
  getDealerDetails(): void {
    this.loader = true;
    if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
      const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: this.searchString,
        searchType: 'globalSearch',
        sortColumn: this.columns.field,
        sortOrder: 'asc',
        operation: this.searchString?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: this.columns.field,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: this.offset,
        limit: this.limit,
        selectedProducts: this.header.selectedRadioFilters.applicationName
          ? this.header.selectedRadioFilters.applicationName
          : [],
        invoiceNumber: this.header.invoiceNumber,
        isHomeSearch: true,
        filter: {
          ucidNameWithId: custSearchpayload,
          dcnWithName: dcnSearchpayload,
        },
      };
    } else {
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: this.searchString,
        searchType: this.globalSearchType,
        sortColumn: this.columns.field,
        sortOrder: 'asc',
        operation: this.searchString?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: this.columns.field,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: this.offset,
        limit: this.limit,
        selectedProducts: this.header.selectedRadioFilters.applicationName
          ? this.header.selectedRadioFilters.applicationName
          : [],
        invoiceNumber: this.header.invoiceNumber,
      };
    }

    this.isApiCallCompleted = false;
    this.isGetDealerCalled = true;
    this.commonFilterService.getFilterAccordianList(this.payload).subscribe({
      next: (columnData: any) => {
        this.loader = false;
        if (this.dspConfig?.isMultiProductEnabled === 'true') this.isApiCallCompleted = true;
        if (columnData[0] && columnData[0][this.columns.field].length > 0) {
          this.nextCursor = columnData[0].next;
          this.offset = this.offset != this.payload.offset ? this.offset : this.limit + this.offset;
          columnData[0][this.columns.field].forEach((value: any, index: any) => {
            if (this.SearchFilterData[this.columns.field][0]?.indexOf(value) == -1) {
              this.SearchFilterData[this.columns.field][0].push(value);
            }
            if (index == columnData[0][this.columns.field]?.length - 1) {
              this.isLoading = false;
              this.isGetDealerCalled = false;
            }
            this.showallData = this.SearchFilterData;
          });
          //  this.previosOffset = this.offset;
        } else {
          this.nextCursor = null;
          this.isLoading = false;
          this.isGetDealerCalled = false;
        }
        this.enable_scroll();
        this.cdr.detectChanges();
      },
      error: (_err: any) => {
        this.loader = false;
        this.isGetDealerCalled = false;
        if (this.dspConfig?.isMultiProductEnabled === 'true') this.isApiCallCompleted = true;
        this.isLoading = false;
        this.nextCursor = null;
      },
    });
  }
  /**
   * Apply selected filters and close the filter modal.
   */
  applyFilters() {
    this.header.selectedRadioFilters = this.selectedRadioFilters;
    this.header.columns = this.columns;
    this.header.columnNames = this.columns;
    this.closeModal();
  }
  /**
   * Closes the filter modal when back arrow is clicked.
   */
  closeModal() {
    this.header.selectedRadioFilters = this.selectedRadioFilters;
    this.dialogRef.close(this.enableViewBtn);
  }
  /**
   * Closes the filter modal when cross icon is clicked.
   */
  closeAllModal() {
    if (JSON.stringify(this.previousSelectedFilters) !== JSON.stringify(this.columns)) {
      if (this.isCommonPopup) {
        const popupData: PopupData = {
          title: dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_TITLE,
          messageArray: [dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
          showCrossMark: true,
          showPrimaryButton: true,
          showSecondaryButton: true,
          primaryButtonText: dspConstants.PopupButtons.APPLY_CLOSE,
          secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
        };
        this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
          width: '524px',
          type: 'semi-modal',
          data: popupData,
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === dspConstants.PopupButtons.APPLY_CLOSE) {
            this.applyFilters();
          } else if (dspConstants.PopupButtons.CANCEL_CHANGES) {
            this.dialogRef.close();
            this.header?.ref?.close();
          }
        });
      } else {
        this.modalRef = this.modal.openModal(FilterCloseConfirmationComponent, {
          width: '524px',
          type: 'semi-modal',
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === true) {
            this.applyFilters();
          } else if (result === false) {
            this.dialogRef.close();
            this.header?.ref?.close();
          }
        });
      }
    } else {
      this.dialogRef.close();
      this.header?.ref?.close();
    }
  }
}
