import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-additional-plan-payment-details',
  templateUrl: './additional-plan-payment-details.component.html',
  styleUrls: ['./additional-plan-payment-details.component.scss'],
})
export class AdditionalPlanPaymentDetailsComponent implements OnInit {
  rowData: any;
  basePlanMp: any;
  optionalPlanMp: any;
  applicationName = this.data?.applicationName;
  searchType = this.data?.searchType;
  showRatePlans = this.data?.showRatePlans;
  userActiveFeatures: any;
  subsPricingEligible = false;
  isNumberConventionEnabled: any;
  seemoreOverlayUpdate = false;
  seeMoreOverlayDetails: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  isShowPriceIfNoBilling = false;
  constructor(
    public dialogRef: CcDrawerRef<AdditionalPlanPaymentDetailsComponent>,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService,
    @Inject(CC_DRAWER_DATA) public data: any
  ) {}
  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.rowData = this.data?.rowData;
    this.seeMoreOverlayDetails = this.data?.seeMoreOverlayBasicDetails;
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.seemoreOverlayUpdate = dsp?.featureFlagInfo.seeMoreOverlayUpdate
        ? dsp.featureFlagInfo.seeMoreOverlayUpdate
        : false;
    });
    if (this.data?.isMultiProductWorkFlow) {
      this.sortPlansByType(this.data?.rowData);
    }
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
  }

  sortPlansByType(products: any[]) {
    const basePlans = products.filter((plan) => plan.type === 'Base');
    const addOnPlans = products.filter((plan) => plan.type === 'Addon' || (plan.planName && !plan.type));
    this.basePlanMp = basePlans;
    this.optionalPlanMp = addOnPlans;
  }

  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return number !== '-' && number != 'N/A' ? this.userPreferenceService.convertNumberFormat(number, true) : number;
    }
    return number;
  }

  parseAssetNameData(seeMoreOverlayDetails: any) {
    const rowData = seeMoreOverlayDetails;
    let assetInfo = '';
    if (rowData) {
      assetInfo += rowData.make ? rowData.make : '';
      assetInfo += rowData.model ? ' - ' + rowData.model : '';
      assetInfo += rowData.modelYear ? ' - ' + rowData.modelYear : '';
      return assetInfo;
    } else {
      return '';
    }
  }

  getColumnToolTipData() {
    if (this.seeMoreOverlayDetails) {
      return (
        this.getAssetSerialNumber(this.seeMoreOverlayDetails) +
        ' ' +
        this.parseAssetNameData(this.seeMoreOverlayDetails)
      );
    } else {
      return '';
    }
  }

  getAssetSerialNumber(seeMoreOverlayDetails: any) {
    const rowData = seeMoreOverlayDetails;
    let assetSerialNo = '';
    if (rowData) {
      assetSerialNo += rowData.serialNumber;
      assetSerialNo += rowData.engineSerialNumber ? ' | ' + rowData.engineSerialNumber : '';
      return assetSerialNo;
    } else {
      return '';
    }
  }
}
