import { createAction, props } from '@ngrx/store';
import {
  CancellationReasonFilter,
  ChartFilters,
  needsReviewDashboardFilter,
  savedFilters,
  subscribeAssetTrendFilters,
} from '../entities/entity';
// import { ChartFilters, savedFilters, subscribeAssetTrendFilters } from '../entities/subsentity';

export const setInitialNRDFItem = createAction(
  '[Set InitialNRDFItem] Set InitialNRDFItem',
  props<{ nrd: needsReviewDashboardFilter[] }>()
);

export const setSelectedNeedsReviewItem = createAction(
  '[Set SelectedNeedsReviewItem] Set Selected NeedsReview Item',
  props<{ nrd: needsReviewDashboardFilter[] }>()
);

export const setSavedCancellationReason = createAction(
  '[Set SavedCancellationReason] Set Saved Cancellation Reason',
  props<{ cancelReason: CancellationReasonFilter[] }>()
);

// export const getItem = createAction('[Get Item] Get Item', props<{ list: needsReviewDashboardFilter }>());
// export const getItemSuccess = createAction('[Get Item Success] Get Item Success');

//export const setItem = createAction('[Set Item] Set Item', props<{ list: needsReviewDashboardFilter }>());
export const setInitialTableItem = createAction(
  '[Set InitialTableItem] Set InitialTableItem',
  props<{ subsasst: subscribeAssetTrendFilters }>()
);
export const setSelectedFilterItem = createAction(
  '[Set SelectedFilterItem] Set SelectedFilterItem',
  props<{ filterData: savedFilters }>()
);

export const setChart1000FilterItem = createAction(
  '[Set Chart1000FilterItem] Set Chart1000FilterItem',
  props<{ chartData: ChartFilters }>()
);
