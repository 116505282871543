import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { CcDrawerConfig, CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { cloneDeep, isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-part-number-attachment',
  templateUrl: './part-number-attachment.component.html',
  styleUrls: ['./part-number-attachment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartNumberAttachmentComponent implements OnInit {
  @ViewChild('partNumberInput2') partNumberInput2!: ElementRef;
  firstPartNumber: any;
  secondPartNumber: any;
  updatePartNumberDrawerHeaderTitle = !isEmpty(this.selectedAssetInfo?.partNumber)
    ? 'Edit Attachment P/N'
    : 'Add Attachment P/N';
  partNumbers = !isEmpty(this.selectedAssetInfo?.partNumber)
    ? cloneDeep(this.selectedAssetInfo?.partNumber.split('-'))
    : [];
  noSpecialChars = 'noSpecialChars';

  constructor(
    public partNumberDrawerRef: CcDrawerRef<PartNumberAttachmentComponent, CcDrawerConfig>,
    @Inject(CC_DRAWER_DATA)
    public selectedAssetInfo: any,
    public changeDetector: ChangeDetectorRef,
    public assetDrawerService: AssetDrawerService,
    public messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    const { partNumbers } = this;
    if (partNumbers.length === 2) {
      this.firstPartNumber = cloneDeep(partNumbers[0]);
      this.secondPartNumber = cloneDeep(partNumbers[1]);
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  enableSavePartNumber() {
    this.firstPartNumber = this.firstPartNumber?.trim();
    this.secondPartNumber = this.secondPartNumber?.trim();
    const format = /[^a-zA-Z0-9]/g;
    if (
      this.selectedAssetInfo?.partNumber &&
      this.firstPartNumber?.length === 3 &&
      this.secondPartNumber?.length === 4 &&
      !format.test(this.firstPartNumber) &&
      !format.test(this.secondPartNumber)
    ) {
      if (this.partNumbers[0] !== this.firstPartNumber || this.partNumbers[1] !== this.secondPartNumber) {
        return true;
      } else {
        return false;
      }
    } else if (!this.selectedAssetInfo?.partNumber) {
      if (
        this.firstPartNumber?.length === 3 &&
        this.secondPartNumber?.length === 4 &&
        !format.test(this.firstPartNumber) &&
        !format.test(this.secondPartNumber)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.selectedAssetInfo?.partNumber &&
      this.firstPartNumber?.length === 0 &&
      this.secondPartNumber?.length === 0 &&
      !format.test(this.firstPartNumber) &&
      !format.test(this.secondPartNumber)
    ) {
      return true;
    } else {
      return false;
    }
  }

  handlePartNumberDrawerClose() {
    this.partNumberDrawerRef.close();
  }

  handlePartNumberUpdate() {
    const payloadSerialNumber = this.selectedAssetInfo.make + '|' + this.selectedAssetInfo.serialNumber;
    let payloadPartNumber = this.firstPartNumber.toUpperCase() + '-' + this.secondPartNumber.toUpperCase();
    if (isEmpty(payloadPartNumber) || payloadPartNumber == '-') {
      payloadPartNumber = '';
    }
    const partNumberUpdateRequestPayload = {
      serialNumber: payloadSerialNumber,
      bigId: this.selectedAssetInfo.appId,
      partNumber: payloadPartNumber,
    };
    this.assetDrawerService.updatePartNumber(partNumberUpdateRequestPayload).subscribe({
      next: () => {
        this.selectedAssetInfo.partNumber = payloadPartNumber;
        this.updateAssetDetails(this.selectedAssetInfo);
        this.handlePartNumberDrawerClose();
        const messageText = `Part Number saved successfully.`;
        this.showToastMessage(messageText, 'success');
      },
      error: () => {
        this.handlePartNumberDrawerClose();
        const messageText = `There was an error processing your request. Please contact Cat Digital Support.`;
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  focusToNext(event: any, maxlength: number) {
    if (maxlength === event?.length) {
      this.partNumberInput2?.nativeElement?.focus();
    }
  }

  updateAssetDetails(assetData: any) {
    this.assetDrawerService.updateAssetDetails(assetData);
  }
}
