import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  CcModal,
  CcModalRef,
  MessageBarConfig,
  MessageBar,
} from '@cat-digital-workspace/shared-ui-core';
import { ExpandedAssetHistoryComponent } from './expanded-asset-history/expanded-asset-history.component';
import { LISTITEMS } from '../../../assets/asset-constants';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { debounce, isEmpty } from 'lodash-es';
import { cloneDeep } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import * as dspConstants from '../../../../../app/shared/dspConstants';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
@Component({
  selector: 'dsp-next-gen-ui-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.scss'],
})
export class AssetHistoryComponent implements OnInit, OnChanges {
  userActiveFeatures: any;
  @Input() assetSubscriptionHistory: any;
  showLoader = false;
  subscriptionHistory: any;
  isSubscriptionHistoryEmpty: any;
  testData3: any;
  telUserType: any;
  isTheme = '1.0';
  listItems = LISTITEMS;
  expandedAssetHistoryRef!: any;
  fileType: any;
  dealer: any;
  downloadedFileName = '';
  isExport = false;
  objectKeys = Object.keys;
  isXLDownloadEnabled: any;
  dspStoreData: any;
  isTimeZoneSelectionEnabled = false;
  isTimeFormatEnabled: any;
  isMultiProductWorkFlowEnabled = false;
  isAssetHistoryOwnershipAdded: any;

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.dspStoreData = dsp;
      this.isXLDownloadEnabled = this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        ? this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        : false;
      this.isTimeZoneSelectionEnabled = dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? dsp.featureFlagInfo.isTimeZoneSelectionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isMultiProductWorkFlowEnabled = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
      this.isAssetHistoryOwnershipAdded = dsp?.featureFlagInfo?.isAssetHistoryOwnershipAdded
        ? dsp?.featureFlagInfo?.isAssetHistoryOwnershipAdded
        : false;
    });
    this.subscriptionHistory = [];
    this.isSubscriptionHistoryEmpty = isEmpty(this.subscriptionHistory) ? true : false;
  }

  constructor(
    private drawerService: DrawerService,
    public modal: CcModal,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService
  ) {}

  onListItemClick(value?: any) {
    if (this.isXLDownloadEnabled) {
      const item = value?.split(' ');
      this.fileType = item[1];
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        operation: 'exportCsv',
        sortColumn: 'serialNumber',
        sortOrder: 'asc',
        serialNumber: this.assetSubscriptionHistory?.assetData?.serialNumber,
        make: this.assetSubscriptionHistory?.assetData?.make,
        userType: this.telUserType,
      };
      const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
      this.showToastMessage(message, 'success');

      switch (this.fileType) {
        case 'Excel':
          this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.xlsx';
          inputData.operation = 'exportExcel';
          this.getExportID(inputData);
          break;
        case 'CSV':
          this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
          inputData.operation = 'exportCsv';
          this.getExportID(inputData);
          break;
        default:
          break;
      }
    } else {
      // const item = value.split(' ');
      // this.fileType = item[1];
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        operation: 'exportCsv',
        sortColumn: 'serialNumber',
        sortOrder: 'asc',
        serialNumber: this.assetSubscriptionHistory?.assetData?.serialNumber,
        make: this.assetSubscriptionHistory?.assetData?.make,
        userType: this.telUserType,
      };
      const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
      this.showToastMessage(message, 'success');
      this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
      inputData.operation = 'exportCsv';
      this.getExportID(inputData);
      // switch (this.fileType) {
      //   case 'Excel':
      //     this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.xlsx';
      //     inputData.operation = 'exportExcel';
      //     this.getExportID(inputData);
      //     break;
      //   case 'CSV':
      //     this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
      //     inputData.operation = 'exportCsv';
      //     this.getExportID(inputData);
      //     break;
      //   default:
      //     break;
      // }
    }
  }
  getConvertedDateFromTimestamp(convertedtimeStamp: any, event: any) {
    if (!isEmpty(convertedtimeStamp)) {
      const convertedDateTime = this.userPreferenceService.convertTimeToSelectedZone(
        this.getDateTime(convertedtimeStamp, 'date') + ' ' + this.getDateTime(convertedtimeStamp, 'time'),
        false
      );
      if (event == 'date') {
        if (this.isTimeFormatEnabled) {
          return this.userPreferenceService.convertDate(convertedDateTime?.split(' ')[0]);
        } else {
          return convertedDateTime.split(' ')[0];
        }
      }
      if (event == 'time') {
        if (this.isTimeFormatEnabled) {
          return (
            this.userPreferenceService.convertTime(convertedDateTime?.split(' ')[1]) +
            ' ' +
            this.userPreferenceService.getLocalTimeZone()
          );
        } else {
          return convertedDateTime.split(' ')[1] + ' ' + this.userPreferenceService.getLocalTimeZone();
        }
      }
    }
    return '';
  }
  getPrimaryProduct(text: any) {
    let textData = text;
    if (text.split('|')?.length > 0) {
      textData = text.split('|')[0];
    }
    return textData;
  }
  getExportID(data: any) {
    this.showLoader = true;
    this.assetDrawerService.getExportID(data).subscribe({
      next: (result: any) => {
        this.getExportStatus(result.exportID);
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.setDataLayer('failed', message);
      },
    });
  }

  getExportStatus(id: string) {
    // api call for export the asset history
    this.assetDrawerService.getExportStatus(id).subscribe({
      next: (result: any) => {
        if (result?.status == 'Completed') {
          const fileId = result?.fileId;
          this.downloadHistoryFile(fileId);
          this.setDataLayer('success', '');
        } else if (result?.status === 'Pending') {
          setTimeout(() => {
            this.getExportStatus(id);
          }, 3000);
        }
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.setDataLayer('failed', message);
      },
    });
  }

  downloadHistoryFile(id: string) {
    // api call for dowloading history file
    this.assetDrawerService.downloadHistoryFile(id).subscribe({
      next: (response: any) => {
        this.createDefaultAnchorElement(response);

        this.showLoader = false;
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      },
    });
  }

  createDefaultAnchorElement(data: any) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const csvUrl = URL.createObjectURL(data);
    a.href = csvUrl;
    a.download = this.downloadedFileName;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
  getTimeFromTimestamp(timestamp: any) {
    const date = new Date(timestamp);
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = date.getUTCSeconds();
    const utcDateString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second
      .toString()
      .padStart(2, '0')} UTC`;
    return utcDateString;
  }
  getDateFromTimestamp(timestamp: any) {
    return new Date(timestamp).toLocaleDateString('en-US', {
      timeZone: 'UTC',
      hour12: false,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  getDateTime(timestamp: any, field: any) {
    const DateTimeValue = timestamp?.split('|') || []; //Date & Time will be there in the response with pipe as a seperator.
    return DateTimeValue.length == 2 ? (field === 'date' ? DateTimeValue[0] : DateTimeValue[1]) : '';
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  openExpandedHistoryDrawer = debounce(() => {
    this.expandedAssetHistoryRef = this.drawerService.openDrawer<ExpandedAssetHistoryComponent, CcDrawerConfig>(
      ExpandedAssetHistoryComponent,
      {
        data: this.assetSubscriptionHistory,
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );
  }, 1000);
  openExpandedHistory() {
    this.loaderService.show();
    this.openExpandedHistoryDrawer();
  }
  ngOnChanges() {
    if (!isEmpty(this.assetSubscriptionHistory?.subscriptionHistory)) {
      const subscriptionHistory = cloneDeep(this.assetSubscriptionHistory?.subscriptionHistory);
      this.subscriptionHistory = [];
      const tempObj = this.objectKeys(subscriptionHistory);

      //for iterating transactions
      tempObj.forEach((obj) => {
        //for getting dealer details from Dealer Subscription level.
        const dealerLevelHistory = subscriptionHistory[obj].find((history: any) => {
          return history.subscriptionLevel === dspConstants.Common.DEALER;
        });

        //for setting dealer detail to Customer Subscription level
        const customerLevelHistory = subscriptionHistory[obj].find((history: any) => {
          return history.subscriptionLevel === dspConstants.Common.CUSTOMER;
        });

        const catLevelHistory = subscriptionHistory[obj].find((history: any) => {
          return history.subscriptionLevel === dspConstants.Common.CAT;
        });

        if (!isEmpty(customerLevelHistory)) {
          customerLevelHistory.dealerCode = dealerLevelHistory?.dealerCode || '';
          customerLevelHistory.dealerName = dealerLevelHistory?.dealerName || '';
          customerLevelHistory.ucid = dealerLevelHistory?.ucid || '';
          customerLevelHistory.ucidName = dealerLevelHistory?.ucidName || '';
          customerLevelHistory.dealerCustomerName = dealerLevelHistory?.dealerCustomerName || '';
          customerLevelHistory.dealerCustomerNo = dealerLevelHistory?.dealerCustomerNo || '';
          this.subscriptionHistory.push(customerLevelHistory);
        } else if (isEmpty(customerLevelHistory) && isEmpty(dealerLevelHistory) && !isEmpty(catLevelHistory)) {
          this.subscriptionHistory.push(catLevelHistory);
        }
      });
    } else {
      this.subscriptionHistory = [];
    }
    this.isSubscriptionHistoryEmpty = isEmpty(this.subscriptionHistory) ? true : false;
  }
  isDealerNameEmpty(dealerName: any) {
    return isEmpty(dealerName);
  }
  checkDealerDataLength(dealerName: any, dealerCode: any) {
    if (!isEmpty(dealerName) && !isEmpty(dealerCode)) {
      let dealerInfo = dealerName + '-' + dealerCode;
      return dealerCode.length <= 10
        ? dealerName.length > 19
          ? dealerName.slice(0, 19) + '...' + ' - ' + dealerCode
          : dealerName + ' - ' + dealerCode
        : dealerName.length > 19
          ? dealerName.slice(0, 19) + '...' + ' - ' + dealerCode.slice(0, 10) + '...'
          : dealerName + ' - ' + dealerCode.slice(0, 10) + '...';
    } else if (!isEmpty(dealerName)) {
      return dealerName;
    } else if (!isEmpty(dealerCode)) {
      return dealerCode;
    }
    return '-';
  }
  showToolTipData(Name: any, Code: any) {
    let data = Name + ' - ' + Code;
    return data.length > 22 ? data : '';
  }
  toggleExportIcon() {
    this.isExport = !this.isExport;
  }
  copyEventForDealer(event: any, dealerName: string, dealerCode: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(event, clipboardText, '-', dealerName, dealerCode, 24, 'Asset History Dealer');
  }
  copyEvent(event: any, fullText: any) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEventFunc(event, clipboardText, 'None', fullText, 25, 'Asset History');
  }
  setDataLayer(status: any, message: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Asset Subscription History Export',
      formContent:
        this.assetSubscriptionHistory?.assetData?.serialNumber +
        ' | ' +
        this.assetSubscriptionHistory?.assetData?.deviceType,
      formContent2: 'Collapsed View',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: message,
      formLocation: 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
}
