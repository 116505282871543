import { Component, OnInit } from '@angular/core';
import { UserPreferenceService } from '../../../user-preference.service';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { AssetProductSubscriptionDateDrawerComponent } from '../../../components/assets/asset-table-details/asset-product-subscription-date-drawer/asset-product-subscription-date-drawer.component';

@Component({
  selector: 'dsp-next-gen-ui-common-time-zone',
  templateUrl: './display-time-zone.component.html',
  styleUrls: ['./display-time-zone.component.scss'],
})
export class DisplayTimeZoneComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  columnName: any;
  convertedTime: any;
  dspStoreData: any;
  isTimeZoneSelectionEnabled: any;
  isTimeFormatEnabled: any;
  productSubscribeRef: any;
  isMultiProductWorkFlow = false;
  constructor(
    private userPreferenceSerice: UserPreferenceService,
    private store: Store<DSPAppState>,
    private drawerService: DrawerService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
    this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
      ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
      : false;
    this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
      ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
      : false;

    this.isMultiProductWorkFlow = this.dspStoreData?.multiProductFeatureFlagInfo?.data
      ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
      : false;

    this.rowData = this.value;
    this.columnName = this.columnWithHeader?.name;
  }
  convertTimeZone() {
    if (this.rowData[this.columnName] && this.rowData[this.columnName] != '-') {
      let value = (this.convertedTime = this.userPreferenceSerice.convertTimeToSelectedZone(
        this.rowData[this.columnName],
        false
      ));
      if (this.isTimeFormatEnabled) {
        if (this.columnName === 'contractEndDate') {
          return this.userPreferenceSerice.convertDate(value);
        } else {
          return this.userPreferenceSerice.convertDateTime(value);
        }
      } else {
        return value;
      }
    } else {
      return (this.convertedTime = this.rowData[this.columnName]);
    }
  }
  getValue() {
    return this.rowData && this.columnName ? this.rowData[this.columnName] : '';
  }

  checkIfMultiProduct() {
    if (this.isMultiProductWorkFlow) {
      switch (this.columnWithHeader?.name) {
        case 'productSubscriptionStartDate': {
          return this.rowData['reportingState'] === 'Subscribed' &&
            this.rowData?.subscribedProducts &&
            this.rowData?.subscribedProducts >= 2
            ? true
            : false;
        }
        default:
          return false;
      }
    }
    return false;
  }

  openDrawerForProdSubDate() {
    this.productSubscribeRef = this.drawerService.openDrawer<
      AssetProductSubscriptionDateDrawerComponent,
      CcDrawerConfig
    >(AssetProductSubscriptionDateDrawerComponent, {
      data: {
        title: this.columnWithHeader.name,
        columnData: this.columnWithHeader,
        rowData: this.rowData,
      },
      enableBlackBackDrop: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.productSubscribeRef.afterClosed().subscribe((_res: any) => {});
  }
}
