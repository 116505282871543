<div class="skeleton">
  <cc-table-skeleton>
    <cc-skeleton-loader [animation]="true">
      <svg:rect x="6" y="7" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="7" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="7" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="7" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="7" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="20" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="20" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="20" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="20" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="20" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="29" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="29" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="29" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="29" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="29" rx="2" ry="2" width="30" height="4" />
      <!-- secondrow -->

      <svg:rect x="6" y="44" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="44" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="44" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="44" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="44" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="58" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="58" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="58" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="58" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="58" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="67" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="67" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="67" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="67" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="67" rx="2" ry="2" width="30" height="4" />

      <!-- thirdrow -->
      <svg:rect x="6" y="84" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="84" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="84" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="84" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="84" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="98" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="98" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="98" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="98" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="98" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="107" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="107" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="107" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="107" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="107" rx="2" ry="2" width="30" height="4" />

      <!-- fourth row -->
      <svg:rect x="6" y="124" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="124" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="124" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="124" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="124" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="137" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="137" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="137" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="137" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="137" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="146" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="146" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="146" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="146" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="146" rx="2" ry="2" width="30" height="4" />

      <!-- fifth row -->
      <svg:rect x="6" y="161" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="161" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="161" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="161" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="161" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="174" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="174" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="174" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="174" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="174" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="183" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="183" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="183" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="183" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="183" rx="2" ry="2" width="30" height="4" />
      <!-- sixth row -->
      <!-- <svg:rect x="6" y="200" rx="5" ry="5" width="100" height="8" />
      <svg:rect x="14" y="200" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="20" y="200" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="28" y="200" rx="2" ry="2" width="0" height="0" />
      <svg:rect x="38" y="200" rx="2" ry="2" width="0" height="0" />

      <svg:rect x="6" y="213" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="60" y="213" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="115" y="213" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="170" y="213" rx="2" ry="2" width="50" height="4" />
      <svg:rect x="225" y="213" rx="2" ry="2" width="50" height="4" />

      <svg:rect x="6" y="222" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="60" y="222" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="115" y="222" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="170" y="222" rx="2" ry="2" width="30" height="4" />
      <svg:rect x="225" y="222" rx="2" ry="2" width="30" height="4" /> -->

      <!-- horizontal lines -->
      <svg:rect x="0" y="38" width="540" height="0.5" />
      <svg:rect x="0" y="77" width="540" height="0.5" />
      <svg:rect x="0" y="117" width="540" height="0.5" />
      <svg:rect x="0" y="156" width="540" height="0.5" />
      <!-- <svg:rect x="0" y="195" width="540" height="0.5" /> -->
      <!-- <svg:rect x="0" y="234" width="540" height="0.5" /> -->
    </cc-skeleton-loader>
  </cc-table-skeleton>
</div>
