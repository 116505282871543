import { Component, Inject, OnInit } from '@angular/core';

import { CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import { DashboardService } from '../../../services/dashboard.service';
import { cloneDeep } from 'lodash-es';
import { match } from 'assert';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-sub-asset-trend-showall-filter',
  templateUrl: './sub-asset-trend-showall-filter.component.html',
  styleUrls: ['./sub-asset-trend-showall-filter.component.scss'],
})
export class SubAssetTrendShowallFilterComponent {
  headerContent: string | undefined;
  title: string | undefined;
  values: any;
  masterSelected = true;
  indeterminate = false;
  checkboxDisabled = false;
  selectedMonthFilters: Array<string> = [];
  selectedProductFilters: Array<string> = [];
  savedFilters: any;
  monthLength: any;
  flag = false;
  closeIconClick = false;
  selectedFilters: any = [];
  unselectedFilters: any = [];
  monthFilterChange = false;
  productFilterChange = false;
  isShowSubscribeFilterOnExpand = false;
  dspStoreData!: any;

  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      allValues: Array<object>;
      headerTitle: string;
      selectedMonthFilters: Array<string>;
      selectedProductFilters: Array<string>;
      selectedUcNameFilter: Array<string>;
      ref: any;
      savedFilters: any;
      flag: boolean;
      closeIconClick: boolean;
    },
    public dialogRef: CcDrawerRef<SubAssetTrendShowallFilterComponent>,
    public dashboardService: DashboardService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowSubscribeFilterOnExpand = this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        ? this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        : false;
    });
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.title = this.header.headerTitle;
    this.values = this.header.allValues;
    this.monthLength = this.values?.length;
    this.selectedMonthFilters = this.header.selectedMonthFilters;
    this.selectedProductFilters = this.header.selectedProductFilters;
    this.savedFilters = this.header.savedFilters;
    this.flag = this.header.flag;
    this.checkboxvalidation();
  }
  checkboxvalidation() {
    if (this.selectedMonthFilters?.length == 12) {
      this.indeterminate = false;
      this.masterSelected = true;
    } else if (this.selectedMonthFilters?.length != 0 && this.selectedMonthFilters?.length < 12) {
      this.indeterminate = true;
      this.masterSelected = false;
    } else {
      this.indeterminate = false;
      this.masterSelected = false;
    }
  }
  // commonCloseModal() {
  //   this.dialogRef.close(false);
  //   this.header.ref.close(false);
  // }
  closeModal() {
    if (this.isShowSubscribeFilterOnExpand) {
      if (
        !this.monthFilterChange &&
        this.selectedMonthFilters?.length === 0 &&
        !this.productFilterChange &&
        this.selectedProductFilters?.length === 0
      ) {
        this.dialogRef.close(false);
        this.header.ref.close(false);
      } else {
        if (
          (this.selectedMonthFilters?.length > 0 && this.monthFilterChange) ||
          (this.selectedProductFilters?.length > 0 && this.productFilterChange)
        ) {
          this.header.closeIconClick = true;
          this.applyFilters();
          this.dialogRef.close(true);

          // this.header.ref.close(true);
        } else if (
          (this.monthFilterChange && this.selectedMonthFilters?.length === 0) ||
          (this.productFilterChange && this.selectedProductFilters?.length === 0)
        ) {
          this.header.closeIconClick = true;
          this.applyFilters();
          this.dialogRef.close(true);
        } else if (
          (!this.monthFilterChange && this.selectedMonthFilters?.length > 0) ||
          (!this.productFilterChange && this.selectedProductFilters?.length > 0)
        ) {
          this.header.closeIconClick = true;
          this.applyFilters();
          this.dialogRef.close(true);
        } else {
          this.dialogRef.close();
          this.header.ref.close();
        }
      }
    } else {
      this.dialogRef.close();
      this.header.ref.close();
    }
  }

  applyFilters() {
    this.header.selectedProductFilters = this.selectedProductFilters;
    this.header.selectedMonthFilters = this.selectedMonthFilters;
    this.dialogRef.close();
  }
  closeShowAllModal() {
    this.dialogRef.close();
  }
  selectedMonthFilter(event: any, value: any) {
    if (event.checked) {
      this.selectedMonthFilters.push(value.month);
      value.checked = true;
    } else {
      this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(value.month), 1);
      value.checked = false;
    }
    if (this.selectedMonthFilters.length == this.monthLength) {
      this.indeterminate = false;
      this.masterSelected = true;
    } else if (this.selectedMonthFilters.length != 0 && this.selectedMonthFilters.length < this.monthLength) {
      this.indeterminate = true;
      this.masterSelected = false;
    } else {
      this.indeterminate = false;
      this.masterSelected = false;
    }
    this.monthFilterChange = true;
    this.dashboardService.setMonth(this.selectedMonthFilters);
  }
  selectedProductFilter(event: any, value: any) {
    if (event.checked) {
      this.selectedProductFilters?.push(value.product);
      value.checked = true;
    } else {
      this.selectedProductFilters?.splice(this.selectedProductFilters.indexOf(value.product), 1);
      value.checked = false;
    }
    this.productFilterChange = true;
    this.dashboardService.setProduct(this.selectedProductFilters);
  }
  disableCheckbox(filter: any) {
    let result = false;
    if (this.selectedProductFilters?.length == 3) {
      if (filter.checked == false) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  }
  /***Commented the below function, as we are not using it */
  // checkUncheckAllFilters(event: any) {
  //   let checkAllFilter: any = [];
  //   if (event.checked) {
  //     if (this.selectedMonthFilters?.length > 0) {
  //       this.values?.map((data: any) => {
  //         this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(data.month), 1);
  //       });
  //       this.values?.map((data: any) => {
  //         let updatedObj = { ...data, checked: true };
  //         checkAllFilter.push(updatedObj);
  //         this.selectedMonthFilters.push(data.month);
  //       });
  //     } else {
  //       this.values?.map((data: any) => {
  //         let updatedObj = { ...data, checked: true };
  //         checkAllFilter.push(updatedObj);
  //         this.selectedMonthFilters.push(data.month);
  //       });
  //     }
  //     this.flag = false;
  //     this.values = [...checkAllFilter];
  //     this.masterSelected = true;
  //     this.indeterminate = false;
  //   } else {
  //     this.values?.map((data: any) => {
  //       let updatedObj = { ...data, checked: false };
  //       checkAllFilter.push(updatedObj);
  //       this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(data.month), 1);
  //     });
  //     this.values = [...checkAllFilter];
  //     this.masterSelected = false;
  //   }
  // }
}
