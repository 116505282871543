<div
  [cc-tooltip]="getColumnToolTipData()"
  [innerHTML]="getColumnData() | highlightSearch: searchString"
  [appClass]="getColumnData().length ? '-product-tool-tip' : ''"
  class="text-truncate"
  [tooltipPosition]="'left'"
  [isEllipseEnabled]="true"
  dspNextGenUiCopy
  [fullText]="getColumnToolTipData()"
></div>

<div
  *ngIf="
    checkIfEmpty() &&
    (columnWithHeader?.name === 'ucid' ||
      (columnWithHeader?.name === 'application' && checkIsShowMultiProductSeeMore()) ||
      (columnWithHeader?.name === 'dealerSubscription' && checkIsShowMultiProductSeeMore()) ||
      (columnWithHeader?.name === 'customerSubscription' && checkIsShowMultiProductSeeMore()) ||
      (columnWithHeader?.name === 'productSubscriptionStartDate' && checkIsShowMultiProductSeeMore()))
  "
>
  <a class="link" (click)="openDrawerInMultiSize()"> See more </a>
</div>
