<div
  class="asset-name-column-align"
  [ngClass]="rowData && rowData.reportingState === 'Suspended' ? 'disable-check' : ''"
>
  <span class="verticalAlignCls" [template]="template5">
    <div class="asset-info">
      <div
        class="review-status-field"
        [ngClass]="rowData && rowData.status === dspNeedsReviewConstants.New ? 'statusAlignCls' : 'noStatusAlignCls'"
      >
        <cc-status-indicator
          *ngIf="rowData?.status === dspNeedsReviewConstants.New"
          [type]="'dot'"
          [statusType]="'info'"
        >
        </cc-status-indicator>
      </div>
      <div *ngIf="isHighlightMakeModelYear" class="asset-info__meta-data" (click)="openAssetDrawer()">
        <div
          class="asset-field"
          [cc-tooltip]="rowData?.serialNumber"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="rowData?.serialNumber"
        >
          <span [innerHTML]="rowData.serialNumber | highlightSearch: searchString"></span>
        </div>
        <div
          class="asset-detail-info"
          [cc-tooltip]="parseAssetNameData(rowData)"
          [innerHTML]="parseAssetNameData(rowData)"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="parseAssetNameData(rowData)"
        ></div>
      </div>
      <div *ngIf="!isHighlightMakeModelYear" class="asset-info__meta-data" (click)="openAssetDrawer()">
        <div class="asset-field">
          <span
            class="asset-field"
            [innerHTML]="rowData.serialNumber | highlightSearch: searchString"
            [cc-tooltip]="rowData?.serialNumber"
            [isEllipseEnabled]="true"
            dspNextGenUiCopy
            [fullText]="rowData?.serialNumber"
          ></span>
        </div>
        <div
          class="asset-detail-info"
          [innerHTML]="parseAssetNameData(rowData) | highlightSearch: searchString"
          [cc-tooltip]="parseAssetNameData(rowData)"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="parseAssetNameData(rowData)"
        ></div>
      </div>

      <div class="asset-icon" *ngIf="rowData.serialNumber">
        <span class="asset-icon-width">
          <span *ngIf="rowData.reportingState === dspWorklistConstants.SUBSCRIBED">
            <cc-icon cc-tooltip="Subscribed" [tooltipPosition]="'top'" class="reportingStateIcons subscribed"
              >subscribed</cc-icon
            >
          </span>
          <span *ngIf="rowData.reportingState === dspWorklistConstants.UNSUBSCRIBED">
            <cc-icon cc-tooltip="Unsubscribed" [tooltipPosition]="'bottom'" class="reportingStateIcons unsubscribed"
              >unsubscribed</cc-icon
            >
          </span>
          <span *ngIf="rowData.reportingState === dspWorklistConstants.SUSPENDED">
            <cc-icon cc-tooltip="Suspended" [tooltipPosition]="'bottom'" class="reportingStateIcons suspended"
              >suspended</cc-icon
            >
          </span></span
        >
      </div>
      <div
        class="asset-icon"
        *ngIf="
          rowData?.reportingState === dspWorklistConstants.SUSPENDED ||
          rowData.isMaskRequired ||
          (userActiveFeatures?.features?.indexOf('Subscription Management') > -1 &&
            (userActiveFeatures?.features?.indexOf('View Only') > -1 ||
              userActiveFeatures?.features?.indexOf('Read Only') > -1 ||
              userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
          !isShowManagePlanIcon
            ? false
            : true
        "
      >
        <span class="asset-icon-width" (click)="managePlanClicked()">
          <cc-icon cc-tooltip="Manage Plan" [tooltipPosition]="'top'" class="reportingStateIcons">manageplan</cc-icon>
        </span>
      </div>
      <ng-container *ngIf="connectionStatus?.length === 0">
        <span class="connect-icon">
          <cc-icon
            cc-tooltip
            [template]="connectionCheckTooltip"
            [tooltipPosition]="'bottom'"
            [appClass]="'-connection-check-tooltip'"
            class="connection-check-icon"
            (click)="checkConnection(rowData.make, rowData.serialNumber)"
            *ngIf="showConnectionCheckIcon"
            >connection-check
          </cc-icon></span
        >
      </ng-container>

      <ng-container *ngFor="let status of connectionStatus">
        <ng-container *ngIf="status.eq_sn === rowData.serialNumber">
          <span class="connect-icon">
            <cc-icon
              cc-tooltip
              [template]="connectionCheckTooltip"
              [tooltipPosition]="'bottom'"
              [appClass]="'-connection-check-tooltip'"
              *ngIf="status.connection_status === 'success' || status.connection_status === 'failure'"
              class="connection-check-icon updated-status"
              (click)="checkConnection(rowData.make, rowData.serialNumber)"
              [style.color]="getColor(status.connection_status)"
              >connection-check
            </cc-icon>
          </span>
        </ng-container>
        <dsp-next-gen-ui-spinner
          cc-tooltip
          [template]="connectionCheckTooltip"
          [tooltipPosition]="'bottom'"
          *ngIf="status.connection_status === 'pending'"
          class="status-pending"
        >
        </dsp-next-gen-ui-spinner>
      </ng-container>

      <ng-template #connectionCheckTooltip>
        <ng-container *ngFor="let status of connectionStatus">
          <div>Check Connection</div>
          <span *ngIf="status.connection_status === 'success'" class="lastcheckin-tooltip"
            >Successful Check-In: {{ lastCheckinValue || 'Not Available' }}
          </span>
          <span *ngIf="status.connection_status === 'pending'" class="lastcheckin-tooltip">In Progress </span>
          <span *ngIf="status.connection_status === 'failure'" class="lastcheckin-tooltip"
            >Failed Check-In: {{ lastCheckinValue || 'Not Available' }}
          </span>
        </ng-container>

        <span *ngIf="connectionStatus.length === 0">
          <div>Check Connection</div>
          Last Check-In:
        </span>

        <dsp-next-gen-ui-spinner *ngIf="isDeviceStatusAPIInProgress" class="status-pending"> </dsp-next-gen-ui-spinner>
        <span *ngIf="this.lastCheckinValue !== '' && !isDeviceStatusAPIInProgress && connectionStatus.length === 0">
          {{ lastCheckinValue }}
        </span>
        <span *ngIf="this.lastCheckinValue === '' && !isDeviceStatusAPIInProgress && connectionStatus.length === 0">
          Not Available
        </span>
      </ng-template>
    </div>
  </span>

  <dsp-next-gen-ui-asset-action-menus
    [assetInfo]="rowData"
    (refreshTableOnPID)="refreshOnPID()"
    (refreshTableOnManagePlan)="refreshOnManagePlan()"
  ></dsp-next-gen-ui-asset-action-menus>

  <ng-template #template5>
    <div *ngIf="rowData.length > 50" style="width: 234px; white-space: normal">
      {{ rowData }}
    </div>
    <div *ngIf="rowData.length <= 50">
      {{ rowData }}
    </div>
  </ng-template>
</div>
