import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-account-product-plan',
  templateUrl: './account-product-plan.component.html',
  styleUrls: ['./account-product-plan.component.scss'],
})
export class AccountProductPlanComponent implements OnInit {
  @ViewChild('siteSearchBox') siteSearchBox!: ElementRef;
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  searchValue = '';
  searchHelpText = '';
  mobileHeader = 'Search User';
  dspStoreData: any;
  constructor(private store: Store<DSPAppState>) {}
  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
  }
  onSiteSearchChange(_event: any) {}
  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }
  onCickEvent(event: any) {
    if (event?.target?.value === '') {
      this.searchHelpText = 'Find Site name/Ownership/Product|Plan (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }
  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.length || 0;
      this.siteSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }
}
