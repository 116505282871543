<div
  class="needs-review-groups__group-block"
  [ngClass]="{ dataCls: needsReviewGroups.length, hasScroll: needsReviewGroups.length > 5 }"
>
  <div
    *ngFor="let group of needsReviewGroups"
    class="group-block"
    (click)="onGroupBlockClick(group)"
    [ngClass]="group.label === selectedCategory ? 'group-block__active' : 'group-block__in-active'"
  >
    <span>{{ group.label }}</span>
    <cc-status-indicator [type]="'rectangle'" [statusType]="'success'" *ngIf="group.newCount > 0">
      {{ group.newCount + ' ' + 'New' }}
    </cc-status-indicator>
  </div>
</div>
<cc-skeleton-loader
  class="loader-style"
  [ngClass]="{ skeletonHeightCls: isTableLoading }"
  *ngIf="isTableLoading"
  [height]="600"
  [animation]="true"
>
  <svg:rect x="35" y="20" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="40" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="78" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="100" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="145" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="165" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="200" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="220" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="260" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="275" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="320" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="340" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="385" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="410" rx="2" ry="2" width="300" height="4"></svg:rect>
  <svg:rect x="35" y="460" rx="2" ry="2" width="330" height="4"></svg:rect>
  <svg:rect x="35" y="480" rx="2" ry="2" width="300" height="4"></svg:rect>

  <svg:rect x="0" y="60" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="120" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="180" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="240" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="300" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="360" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="430" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="500" width="375" height="0.5"></svg:rect>
  <svg:rect x="0" y="560" width="375" height="0.5"></svg:rect>
</cc-skeleton-loader>
