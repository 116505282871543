import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'dsp-next-gen-ui-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccessDeniedComponent {
  env = environment.redirectURL.hostBaseUrl;
  constructor() {
    window.localStorage.removeItem('thirdPartyApi');
  }
}
