<div id="device-status" class="cc-drawer">
  <div class="cc-drawer__header">
    <svg
      *ngIf="launchType === 'DRAWER' && isReplaceInfoIcon"
      class="cc-drawer__leadingIcon"
      (click)="closeDrawer()"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 5.89258H1"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.3961L1 5.89139L6 1.38672"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="cc-headerText cc-drawer__ellipsis device-header">Device Status </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="device-status-content">
      <div class="device-status-body">
        <div class="cc-grid">
          <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
            <span class="device-heading">DEVICE DETAILS</span>
            <button
              cc-btn-primary
              cc-btn-large
              [setTheme]="true"
              class="smh-btn"
              [disabled]="!enableRequestSmhButton"
              (click)="requestSmhMessage()"
            >
              Request SMH Message
            </button>
          </div>

          <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
            <div class="column1">
              <span class="device-status-title">Make:</span>
              <span class="device-status-value">{{ deviceDetails?.make || '-' }}</span>
            </div>
            <div *ngIf="isShowDeviceTypeInDeviceStatus" class="column1">
              <span class="device-status-title">Device Type:</span>
              <span class="device-status-value">{{ deviceDetails?.deviceType || '-' }}</span>
            </div>
            <div class="column1">
              <span class="device-status-title">Asset S/N:</span>
              <span class="device-status-value">{{ deviceDetails?.assetSerialNumber || '-' }}</span>
            </div>
            <div class="column1">
              <span class="device-status-title">Device S/N:</span>
              <span class="device-status-value">{{ deviceDetails?.deviceSerialNumber || '-' }}</span>
            </div>
          </div>
          <div class="cc-col cc-lg-col--8 cc-md-col--8 cc-xl-col--8 cc-sm-col--8 cc-xs-col--8 column-last">
            <div class="column1">
              <span class="device-status-title">Last Check In:</span>
              <span class="device-status-value">{{ deviceDetails?.lastCheckIn || '-' }}</span>
              <cc-icon
                cc-tooltip
                class="smhLocationIcon"
                [template]="tooltipTemplateForLastCheckIn"
                [tooltipPosition]="'bottom'"
                [appClass]="'-smh-icon'"
                fontSet="icomoon"
                >info-tooltip</cc-icon
              >
            </div>
            <div class="column1">
              <span class="device-status-title">Last HTTP Transmission:</span>
              <span class="device-status-value">{{ deviceDetails?.lastHttpTransmission || '-' }}</span>
              <cc-icon
                cc-tooltip
                class="smhLocationIcon"
                [template]="tooltipTemplateForlastTransmission"
                [tooltipPosition]="'bottom'"
                [appClass]="'-smh-icon'"
                fontSet="icomoon"
                >info-tooltip</cc-icon
              >
            </div>
            <div class="column1">
              <span class="device-status-title">Last SABER Transmission:</span>
              <span class="device-status-value">{{ deviceDetails?.lastSaberTransmission || '-' }}</span>
              <cc-icon
                cc-tooltip
                class="smhLocationIcon"
                [template]="tooltipTemplateForlastSabertransmission"
                [tooltipPosition]="'bottom'"
                [appClass]="'-smh-icon'"
                fontSet="icomoon"
                >info-tooltip</cc-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-section">
      <div>
        <div class="section-line">
          <span class="section-heading"> COMMANDS </span>
        </div>
        <br />
        <div class="rowTbl">
          <div class="Table">
            <div class="Row rowHead">
              <div class="CellHead">
                <div class="headerCell">Event</div>
              </div>
              <div class="CellHead">
                <div class="headerCell">
                  Status
                  <cc-icon
                    cc-tooltip
                    class="smhLocationIcon"
                    [template]="tooltipTemplateForStatus"
                    [tooltipPosition]="'bottom'"
                    [appClass]="'-smh-icon'"
                    fontSet="icomoon"
                    >info-tooltip</cc-icon
                  >
                </div>
              </div>
              <div class="CellHead">
                <div class="headerCell" *ngIf="!isTimeZoneSelectionEnabled">Status Date & Time (UTC)</div>
                <div class="headerCell" *ngIf="isTimeZoneSelectionEnabled">
                  Status Date & Time {{ '(' + localTimeZone + ')' }}
                </div>
              </div>
            </div>
            <div *ngIf="deviceDetails?.deviceStatusEventDetailsList?.length > 0">
              <div class="Row" *ngFor="let event of deviceDetails?.deviceStatusEventDetailsList">
                <div class="Cell format-cell">
                  <div class="event-name">{{ event?.event || '-' }}</div>
                </div>
                <div class="Cell format-cell">
                  <div class="event-status">{{ event?.status || '-' }}</div>
                </div>
                <div class="Cell format-cell">
                  <div class="event-name">{{ event.statusDateTime || '-' }}</div>
                </div>
              </div>
            </div>
            <div
              class="Row None-event"
              *ngIf="deviceDetails?.deviceStatusEventDetailsList?.length < 1 && !deviceStatusError; else error_message"
            >
              No data to display
            </div>
            <ng-template #error_message>
              <div *ngIf="showStatusErrorMessage" class="Row None-event" [innerHTML]="statusErrorMessage || ''"></div>
            </ng-template>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="showSmhTable">
        <div>
          <div class="section-line">
            <span class="section-heading"> MESSAGES </span>
          </div>
          <br />
          <div class="rowTbl">
            <div class="Table">
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Event</div>
                </div>
                <div class="CellHead1">
                  <div class="headerCell1" *ngIf="!isTimeZoneSelectionEnabled">Event Date & Time (UTC)</div>
                  <div class="headerCell1" *ngIf="isTimeZoneSelectionEnabled">
                    Event Date & Time {{ '(' + localTimeZone + ')' }}
                  </div>
                </div>
              </div>
              <div *ngIf="lastMessage?.serviceMeterReadings?.length > 0; else else_message">
                <div class="Row">
                  <div class="Cell format-cell event-cell">
                    <div class="event-name">{{ lastMessage?.event || '-' }}</div>
                    <cc-icon
                      *ngIf="!isReplaceInfoIcon"
                      cc-tooltip
                      class="msgIcon"
                      [interactive]="true"
                      (click)="openLastSmhPopup(lastSmhMessageTemplate)"
                      [tooltipPosition]="'bottom'"
                      [appClass]="'-smh-icon'"
                      fontSet="icomoon"
                      >info-tooltip</cc-icon
                    >
                  </div>
                  <div class="Cell format-cell">
                    <div class="event-name">{{ lastMessage?.eventDateTime || '-' }}</div>
                  </div>
                  <div class="Cell format-cell" *ngIf="isReplaceInfoIcon">
                    <div class="event-name event-action" (click)="openLastSmhPopup(lastSmhMessageTemplate)">
                      View Details
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #else_message>
                <div class="Row None-event-lastSmh" [innerHTML]="deviceSmhMessage || ''"></div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-message">
      <img src="../../../assets/images/info-group.svg" class="info-icon-style info-group" />&nbsp;
      <span> {{ languageTranslations?.Worklist?.WL_DEVICE_STATUS_NOTIFICATIONMSG }}</span>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer()">Cancel</button>
  </div>
</div>

<ng-template #tooltipTemplateForLastCheckIn>
  <div
    style="
      width: 250px;
      white-space: normal;
      text-align: left;
      font-family: 'Noto Sans, sans-serif';
      color: #333;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    "
  >
    This is two way communication and indicates the last time that the device downloaded any commands from the queue
    from the Caterpillar back office. This is the only timestamp that matters when wanting the device to download
    information from the back office, such as an SMH adjustment, or a command to switch subscriptions.
  </div>
</ng-template>
<ng-template #tooltipTemplateForlastTransmission>
  <div
    style="
      width: 250px;
      white-space: normal;
      text-align: left;
      font-family: 'Noto Sans, sans-serif';
      color: #333;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    "
  >
    Last time that the device sent a data file, or responded to a command from the back office, or sent a message via
    HTTP instead of Saber. This type of communication will NOT check the queue to download information from the back
    office.
  </div>
</ng-template>
<ng-template #tooltipTemplateForlastSabertransmission>
  <div
    style="
      width: 250px;
      white-space: normal;
      text-align: left;
      font-family: 'Noto Sans, sans-serif';
      color: #333;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    "
  >
    This is one way communication (device to Caterpillar back office). This is the last time the device sent information
    via Saber to Caterpillar. The device will not check the queue or receive commands from the Caterpillar back office
    during this communication.
  </div>
</ng-template>
<ng-template #tooltipTemplateForStatus>
  <div
    style="
      width: 280px;
      white-space: normal;
      text-align: left;
      color: #000000;
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
    "
  >
    <div style="padding-bottom: 10px; padding-top: 7px">
      <span style="font-weight: 600">Queued : </span><span>Command is queued to the device.</span>
    </div>
    <div style="padding-bottom: 10px">
      <span style="font-weight: 600">Consumed : </span
      ><span
        >Command has been received by the device and acknowledgement received from the device. This does not confirm if
        the configuration in the command has been successfully updated.</span
      >
    </div>
    <div style="padding-bottom: 10px">
      <span style="font-weight: 600">Failed : </span><span>Command has failed.</span>
    </div>
    <div><span style="font-weight: 600">Successful : </span><span>Command has been successfully applied.</span></div>
  </div>
</ng-template>
<ng-template #lastSmhMessageRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title popup-title">SMH</div>
      <div class="close-popup">
        <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closePopUp()">
          <cc-icon fontSet="icomoon" class="popup-cross">cross</cc-icon>
        </div>
      </div>
    </div>
    <div class="cc-modal-content group-info__content modal-content model-outline">
      <div class="content-form">
        <span class="header-content">ECM Description</span
        ><span class="header-content header-content-right">Reported SMH</span>
      </div>
      <div *ngFor="let event of lastMessage.serviceMeterReadings">
        <div class="content-form">
          <span class="content-value">{{ event.ecmDescription || '-' }}</span
          ><span class="content-value header-content-right">{{
            convertNumberFormat(event.serviceMeterReading) || '-'
          }}</span>
        </div>
      </div>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closePopUp()">Cancel</button>
    </div>
  </div>
</ng-template>
