<div>
  <ng-container *ngIf="chipsFilters as data">
    <dsp-next-gen-ui-needs-review-chips-list
      [dataSource]="data"
      [reviewCount]="reviewCount"
      (selectionChanged)="onFilterChange($event)"
    ></dsp-next-gen-ui-needs-review-chips-list>
  </ng-container>
</div>

<div class="needsReview-table" [ngClass]="needsReviewTableData && needsReviewTableData.length > 0 ? 'scroll-view' : ''">
  <cc-table
    [ngClass]="{ disable: isTableLoading }"
    #needsReviewTableRef
    *ngIf="enableNeedsReviewTable"
    aria-describedby="SubscribeAssets Table"
    [tableConfig]="subcribeNeedsReviewTableConfig"
    [tableData]="needsReviewTableData"
    (ccTableEvents)="tableEvents($event)"
    [isDataLoading]="isTableLoading"
  >
    <cc-table-skeleton *ngIf="isTableLoading">
      <ng-template cc-table-skeleton-template>
        <div class="instance-skeleton-loader-template">
          <cc-skeleton-loader [height]="400" [animation]="true">
            <svg:rect x="6" y="12" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="14" y="15" rx="2" ry="2" width="40" height="4" />
            <svg:rect x="125" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="8" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="8" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="42" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="38" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="45" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="38" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="38" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="38" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="38" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="38" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="38" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="70" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="65" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="72" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="65" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="65" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="65" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="65" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="65" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="65" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="96" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="92" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="99" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="92" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="92" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="92" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="90" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="92" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="92" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="125" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="120" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="128" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="120" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="120" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="120" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="120" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="120" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="120" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="150" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="145" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="153" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="145" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="145" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="145" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="145" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="145" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="145" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="180" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="175" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="182" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="175" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="175" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="175" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="175" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="175" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="175" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="210" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="205" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="212" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="205" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="205" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="205" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="205" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="205" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="205" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="6" y="235" rx="2" ry="2" width="4" height="4" />
            <svg:rect x="14" y="230" rx="3" ry="2" width="70" height="4" />
            <svg:rect x="14" y="238" rx="3" ry="2" width="40" height="4" />
            <svg:rect x="125" y="230" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="215" y="230" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="305" y="230" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="385" y="230" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="425" y="230" rx="2" ry="2" width="70" height="4" />
            <svg:rect x="495" y="230" rx="2" ry="2" width="70" height="4" />

            <svg:rect x="0" y="26" width="445" height="0.5" />
            <svg:rect x="0" y="54" width="445" height="0.5" />
            <svg:rect x="0" y="82" width="445" height="0.5" />
            <svg:rect x="0" y="110" width="445" height="0.5" />
            <svg:rect x="0" y="138" width="445" height="0.5" />
            <svg:rect x="0" y="166" width="445" height="0.5" />
            <svg:rect x="0" y="194" width="445" height="0.5" />
            <svg:rect x="0" y="222" width="445" height="0.5" />
            <svg:rect x="0" y="250" width="445" height="0.5" />
            <svg:rect x="0" y="278" width="445" height="0.5" />
            <svg:rect x="0" y="306" width="445" height="0.5" />
            <svg:rect x="0" y="334" width="445" height="0.5" />
          </cc-skeleton-loader>
        </div>
      </ng-template>
    </cc-table-skeleton>
  </cc-table>
  <div *ngIf="(!needsReviewTableData || needsReviewTableData.length === 0) && !isTableLoading && !filterApplied">
    <div class="no-results-found">
      <img src="../../../assets/images/no_results_found.png" alt="no results found" />
      <span>No Results Found</span>
    </div>
  </div>
  <div *ngIf="(!needsReviewTableData || needsReviewTableData.length === 0) && !isTableLoading && filterApplied">
    <div class="no-results-found">
      <img src="../../../assets/images/no_results_found.png" alt="no results found" />
      <span>No Results Found</span>
    </div>
  </div>
  <div *ngIf="(!needsReviewTableData || needsReviewTableData.length === 0) && !isTableLoading && nofilterSelected">
    <section class="img-container">
      <div class="img-container__img">
        <img alt="sourceimage" class="no-instance-image" src="../../../assets/images/no-filter-selected.svg " />
      </div>
      <p class="img-container__title">No Status Selected</p>
      <p class="img-container__desc">Please select at least one state above: Active, Dismissed.</p>
    </section>
  </div>
</div>
