<div class="details-body">
  <div class="cc-grid" *ngIf="displayType === 'info'">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>SITE DETAILS</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2">
      <div class="column1">
        Site Name
        <span class="column-divider site-name-wrap">{{ parseSiteName(siteData.name) }}</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Product | Plan<br />
        <span
          cc-tooltip
          [template]="productPlanTooltip"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          class="column-additional trimmedProductPlan"
          dspNextGenUiCopy
          [fullText]="siteData.product + '|' + siteData.plan"
          >{{ siteData.product }} | {{ siteData.plan }}</span
        >
        <ng-template #productPlanTooltip>
          <div style="width: 200px; white-space: normal">
            <span>{{ siteData.product }} | {{ siteData.plan }}</span>
          </div>
        </ng-template>
        <span><br /></span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Optional Plans
        <br />
        <span
          *ngIf="lengthOfServices > 0"
          class="column-divider trimmedAdditionalServices"
          cc-tooltip
          [template]="addOnsToolTip"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          dspNextGenUiCopy
          [fullText]="addOnService"
          >{{ addOnService }}</span
        >
        <span class="column-divider" *ngIf="lengthOfServices <= 0">-</span>
      </div>
      <ng-template #addOnsToolTip>
        <div style="width: 200px; white-space: normal">
          <span>{{ addOnService }} </span>
        </div>
      </ng-template>
    </div>
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2">
      <div class="column2">
        Status
        <br /><span *ngIf="activeStatus">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
              fill="#006618"
            />
          </svg>
        </span>
        <span *ngIf="inactiveStatus">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
              fill="#DE2222"
            />
          </svg>
        </span>
        <span class="column-divider active site-status-style">{{ statusText }}</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
      <div class="column1">
        Ownership <br />
        <span class="column-additional">
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.ucidName?.trim() + siteData?.ucid?.trim()).length > 28
                ? siteData?.ucidName + ' - ' + siteData?.ucid
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.ucidName, siteData.ucid)"
          >
            <span class="customer-text">Customer: </span>
            <span class="column-content column-divider">{{
              updateCustomerName(siteData.ucid, siteData.ucidName)
            }}</span>
          </div>
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.dcnName?.trim() + siteData?.dcn?.trim()).length > 28
                ? siteData?.dcnName + ' - ' + siteData?.dcn
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.dcnName, siteData.dcn)"
          >
            <span class="customer-text"> Dealer Customer: </span>
            <span class="column-content column-divider">{{ updateCustomerName(siteData.dcn, siteData.dcnName) }}</span>
          </div>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1 sub-assets">
        No. of Assets<br />
        <span class="column-additional">{{ siteData.assetCount }}</span>
      </div>
    </div>
  </div>

  <div class="cc-grid" *ngIf="displayType === 'edit'">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>SITE DETAILS</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">
      <div class="column1">
        Site Name
        <span class="column-divider site-name-wrap">{{ parseSiteName(siteData.name) }}</span>
      </div>
    </div>

    <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
      <div class="column1">
        Ownership <br />
        <span class="column-additional">
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.ucidName?.trim() + siteData?.ucid?.trim()).length > 28
                ? siteData?.ucidName + ' - ' + siteData?.ucid
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.ucidName, siteData.ucid)"
          >
            <span class="customer-text">Customer: </span>
            <span class="column-content column-divider">{{
              updateCustomerName(siteData.ucid, siteData.ucidName)
            }}</span>
          </div>
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.dcnName?.trim() + siteData?.dcn?.trim()).length > 28
                ? siteData?.dcnName + ' - ' + siteData?.dcn
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.dcnName, siteData.dcn)"
          >
            <span class="customer-text"> Dealer Customer: </span>
            <span class="column-content column-divider">{{ updateCustomerName(siteData.dcn, siteData.dcnName) }}</span>
          </div>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">
      <div class="column1">
        Location
        <span class="column-divider"><br />{{ siteData.mapLocationInfo }}</span>
      </div>
    </div>
  </div>
  <div class="cc-grid" *ngIf="displayType === 'cancel'">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>SITE DETAILS</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Site Name
        <span class="column-divider site-name-wrap">{{ parseSiteName(siteData.name) }}</span>
      </div>
    </div>

    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Ownership <br />
        <span class="column-additional">
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.ucidName?.trim() + siteData?.ucid.trim()).length > 28
                ? siteData?.ucidName + ' - ' + siteData?.ucid
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.ucidName, siteData.ucid)"
          >
            <span class="customer-text">Customer: </span>
            <span class="column-content column-divider">{{
              updateCustomerName(siteData.ucid, siteData.ucidName)
            }}</span>
          </div>
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (siteData?.dcnName?.trim() + siteData?.dcn?.trim()).length > 28
                ? siteData?.dcnName + ' - ' + siteData?.dcn
                : null
            }}"
            [tooltipPosition]="'top'"
            (copy)="copyEvent($event, siteData.dcnName, siteData.dcn)"
          >
            <span class="customer-text"> Dealer Customer: </span>
            <span class="column-content column-divider">{{ updateCustomerName(siteData.dcn, siteData.dcnName) }}</span>
          </div>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1 productPlan">
        Product | Plan

        <span
          cc-tooltip
          [template]="productToolTip"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          class="column-divider trimmedProductPlan"
          dspNextGenUiCopy
          [fullText]="siteData.product + '|' + siteData.plan"
          >{{ siteData.product }} | {{ siteData.plan }}</span
        >
      </div>
      <ng-template #productToolTip>
        <div style="width: 200px; white-space: normal">
          <span>{{ siteData.product }} | {{ siteData.plan }}</span>
        </div>
      </ng-template>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Optional Plans
        <br />
        <span
          class="column-divider trimmedAdditionalServices"
          cc-tooltip
          [template]="addOnToolTip"
          [tooltipPosition]="'top'"
          [isEllipseEnabled]="true"
          *ngIf="lengthOfServices > 0"
          dspNextGenUiCopy
          [fullText]="addOnService"
          >{{ addOnService }}</span
        >
        <span class="column-divider" *ngIf="lengthOfServices <= 0">-</span>
      </div>
      <ng-template #addOnToolTip>
        <div style="width: 200px; white-space: normal">
          <span>{{ addOnService }} </span>
        </div>
      </ng-template>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="column1">
        Site Location
        <span class="column-divider"><br />{{ siteData.mapLocationInfo }}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #template5>
  <div *ngIf="siteData.name.length > 50" style="width: 200px; white-space: normal">
    {{ siteData.name }}
  </div>
  <div *ngIf="siteData.name.length <= 50">
    {{ siteData.name }}
  </div>
</ng-template>
