import { Component, Inject, OnInit } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  CC_DRAWER_DATA,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { ASSETFILTERCOLUMN } from '../../components/assets/asset-constants';
import { ShowallFilterComponent } from '../showall-filter/showall-filter.component';

@Component({
  selector: 'dsp-next-gen-ui-sub-filter',
  templateUrl: './sub-filter.component.html',
  styleUrls: ['./sub-filter.component.scss'],
})
export class SubFilterComponent implements OnInit {
  headerContent: string | undefined;
  title: string | undefined;
  columnNames: any = {};
  filterData: any = {};
  accordionListColumns: Array<any> = ASSETFILTERCOLUMN;

  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      title: string;
      columnNames: object;
      subFilterData: object;
      ref: any;
    },
    public dialogRef: CcDrawerRef<SubFilterComponent, CcDrawerConfig>,
    private drawerService: DrawerService,
    public showAllContentRef: CcDrawerRef<ShowallFilterComponent, CcDrawerConfig>
  ) {}

  ngOnInit(): void {
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.title = this.header.title ? this.header.title : '';
    this.columnNames = this.header.columnNames ? this.header.columnNames : {};
    this.filterData = this.header.subFilterData ? this.header.subFilterData : {};
  }
  applyFilters() {
    this.closeModal();
  }

  showAllData(type: string, value: any, columnNames: any) {
    this.showAllContentRef = this.drawerService.openDrawer<ShowallFilterComponent, CcDrawerConfig>(
      ShowallFilterComponent,
      {
        data: {
          headerType: type,
          columnNames: columnNames,
          showallData: value,
          ref: this.dialogRef,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  clearFilters() {
    this.accordionListColumns.forEach((item) => {
      if (item?.subFilters) {
        item.subFilters?.forEach((element: any) => {
          element.selectedValues = [];
        });
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
  closeAllModal() {
    this.dialogRef.close();
    this?.header?.ref?.close();
  }
}
