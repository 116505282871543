import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  @Output() updateExpandRowDetailsEvent = new EventEmitter();
  rootURL = '';
  dealer: any;
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }
  updateExpandRowDetails(data: any) {
    this.updateExpandRowDetailsEvent.emit(data);
  }
  getExpandRow(queryParams: any) {
    return this.http.get(this.rootURL + '/user/appinfo', {
      params: queryParams,
    });
  }
  getUserSearch(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/user/getUserSearch', payload);
  }
  getAppRoleInfo(payload: any) {
    return this.http.post(this.rootURL + '/user/appRoleInfo', payload);
  }
  getAppUserOnboard(payload: any) {
    return this.http.post(this.rootURL + '/user/appUserOnboard', payload);
  }
  getApplicationRoleInfo(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/roleinfo', {
      params: queryParams,
    });
  }
  getAppFeaturesByAppProfile(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/rolefeaturebyappprofile', {
      params: queryParams,
    });
  }
  getActiveFeatures(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/features/active', {
      params: queryParams,
    });
  }
  getuserDealerSearch(payload: any) {
    return this.http.post(this.rootURL + '/user/userDealerSearch', payload);
  }

  getUserExport() {
    return this.http.get(this.rootURL + '/user/exportUserAdminFeatures');
  }
}
