<ng-container *ngIf="expandedProduct(rowData)?.length > 12">
  <span
    dspNextGenUiCopy
    [fullText]="productName"
    class="tooltippointer"
    [cc-tooltip]="expandedProduct(rowData)"
    [innerHTML]="expandedProduct(rowData)"
    [tooltipPosition]="'top'"
    [isEllipseEnabled]="true"
  >
  </span>
</ng-container>
<ng-container *ngIf="expandedProduct(rowData)?.length <= 12">
  <span
    class="tooltippointer"
    [isEllipseEnabled]="true"
    [cc-tooltip]="expandedProduct(rowData)"
    [innerHTML]="expandedProduct(rowData)"
    [tooltipPosition]="'top'"
  >
  </span>
</ng-container>

<ng-template #template5>
  <div *ngIf="productName.length > 12" class="tooltipColor">
    {{ productName }}
  </div>
  <div *ngIf="productName.length <= 12">
    {{ productName }}
  </div>
</ng-template>
