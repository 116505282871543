<div class="search-cws">
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="search-users-header">Select Users</div>
    </div>
  </div>
  <div class="cc-grid search-cws-container" [formGroup]="searchUsersForm" id="search-cws-container">
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12">
      <div class="textbox-label">CWS ID</div>
      <cc-input-field
        [labelValue]="'Search (Min 3 Char)'"
        [inputtype]="'search'"
        [isDense]="true"
        [isTheme]="isTheme"
        [isLeading]="true"
        [triggerSearchMinChar]="3"
        [enableSearchOverlay]="false"
        [value]="cwsId"
        (keyup.enter)="handleUserSearch($event)"
        (valueChange)="cwsId = $event.value; handleSearchFormChange('cwsId', $event)"
        [isTrailing]="isTrailingCWSID"
      >
        <span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
              fill="black"
            />
          </svg>
        </span>
        <input
          #cwsidField
          input-field
          class="input-field"
          autocomplete="off"
          formControlName="cwsId"
          type="search"
          [isFloating]="false"
          aria-labelledby="Search"
          aria-describedby="Search"
          [value]="cwsId"
          (valueChange)="cwsId = $event.value"
          (focus)="onFocusEvent('cwsId', 'cwsidField')"
          maxlength="100"
        />
      </cc-input-field>
    </div>
    <h6 class="headline-semibold orText">OR</h6>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12">
      <div class="textbox-label">Last Name</div>
      <cc-input-field
        [labelValue]="'Search (Min 3 Char)'"
        [inputtype]="'search'"
        [isDense]="true"
        [isTheme]="isTheme"
        [isLeading]="true"
        [value]="lastName"
        (keyup.enter)="handleUserSearch($event)"
        (valueChange)="lastName = $event.value; handleSearchFormChange('lastName', $event)"
        [triggerSearchMinChar]="3"
        [enableSearchOverlay]="false"
        [isTrailing]="isTrailingLastName"
      >
        <span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
              fill="black"
            />
          </svg>
        </span>

        <input
          #lastNameField
          input-field
          class="input-field"
          autocomplete="off"
          formControlName="lastName"
          type="search"
          [isFloating]="false"
          aria-labelledby="Search"
          aria-describedby="Search"
          [value]="lastName"
          (valueChange)="lastName = $event.value"
          (focus)="onFocusEvent('lastName', 'lastnameField')"
          maxlength="100"
        />
      </cc-input-field>
    </div>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--12">
      <button cc-btn-tertiary cc-btn-medium [setTheme]="true" class="advancedSearch" (click)="toggleAdvancedSearch()">
        Advanced Search
        <cc-icon fontSet="icomoon" [ngClass]="showAdvancedSearchOptions ? 'advSeachArrowDown' : 'advSeachArrowUp'">
          adv-search</cc-icon
        >
      </button>
    </div>
    <div
      *ngIf="showAdvancedSearchOptions"
      class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12"
    >
      <div class="textbox-label">First Name</div>
      <cc-input-field
        [labelValue]="'Search (Min 3 Char)'"
        [inputtype]="'search'"
        [isDense]="true"
        [isTheme]="isTheme"
        [isLeading]="true"
        [value]="firstName"
        (keyup.enter)="handleUserSearch($event)"
        (valueChange)="firstName = $event.value; handleSearchFormChange('firstName', $event)"
        [triggerSearchMinChar]="3"
        [enableSearchOverlay]="false"
        [isTrailing]="isTrailingFirstName"
      >
        <span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
              fill="black"
            />
          </svg>
        </span>

        <input
          #firstNameField
          input-field
          class="input-field"
          autocomplete="off"
          formControlName="firstName"
          type="search"
          [isFloating]="false"
          [value]="firstName"
          (valueChange)="firstName = $event.value"
          aria-labelledby="Search"
          aria-describedby="Search"
          (focus)="onFocusEvent('firstName', 'firstNameField')"
          maxlength="100"
        />
      </cc-input-field>
    </div>
    <div
      class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12"
      style="margin-left: 23px"
      *ngIf="showAdvancedSearchOptions"
    >
      <div class="textbox-label">Email</div>
      <cc-input-field
        [labelValue]="'Search'"
        [inputtype]="'search'"
        [value]="emailId"
        (keyup.enter)="handleUserSearch($event)"
        (valueChange)="emailId = $event.value; handleSearchFormChange('emailId', $event)"
        [isDense]="true"
        [isTheme]="isTheme"
        [isLeading]="true"
        [triggerSearchMinChar]="3"
        [enableSearchOverlay]="false"
        [isTrailing]="isTrailingEmail"
      >
        <span>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
              fill="black"
            />
          </svg>
        </span>

        <input
          #emailIDField
          input-field
          class="input-field"
          autocomplete="off"
          [value]="emailId"
          (valueChange)="emailId = $event.value"
          formControlName="emailId"
          type="search"
          [isFloating]="false"
          aria-labelledby="Search"
          aria-describedby="Search"
          (focus)="onFocusEvent('emailId', 'emailIDField')"
          maxlength="100"
        />
      </cc-input-field>
    </div>
  </div>

  <div
    class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12"
    [ngClass]="isShowClearAll ? 'btn-container' : ''"
  >
    <button
      type="submit"
      cc-btn-primary
      [setTheme]="true"
      [disabled]="searchUsersForm.status !== 'VALID'"
      (click)="handleUserSearch($event)"
      class="search-buttons"
    >
      Search
    </button>

    <button
      *ngIf="isShowClearAll"
      cc-btn-secondary-outline
      [setTheme]="true"
      [disabled]="disableClearAllBtn"
      (click)="clearAll()"
    >
      Clear All
    </button>
  </div>
</div>

<dsp-next-gen-ui-search-cws-results [users]="userData" [selectedData]="selectedData" [parentType]="parentType">
</dsp-next-gen-ui-search-cws-results>
