import { Component, OnInit, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core/modal';
import { dealerType } from '../../../models/shared.interface';
import { SubscribeAccountsService } from '../../../services/subscribe-accounts.service';
import { AccountFormType, Plan_Template } from '../accounts-interface';
import { uniqBy, isEmpty, isEqual, remove, cloneDeep } from 'lodash-es';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { getSalesReason } from '../../../store/actions/dspcommon.action';
import { Common, OrgSubs } from '../../../shared/dspConstants';
import * as dspConstants from '../../../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-new-subscription',
  templateUrl: './new-subscription.component.html',
  styleUrls: ['./new-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewSubscriptionComponent implements OnInit {
  isTheme = '2.0';
  currentPageIndex = 0; // for UI components
  trackerIndex = 0; // for progress tracker
  dealer: dealerType = {
    dealerCity: '',
    dealerCode: '',
    dealerCountry: '',
    dealerEDURL: '',
    dealerEmail: '',
    dealerName: '',
    dealerPhone: '',
    dealerState: '',
    dealerStreet: '',
    dealerZipCode: '',
  };
  trackerData = [
    {
      label: 'Product and Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Account and Details',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  dynamicTrackerData: any;
  selectedFormData: AccountFormType = {
    product: '',
    plan: '',
    accountType: '',
    isValidForm: false,
    customer: {
      ucid: '',
      ucName: '',
      dcn: '',
      dcName: '',
    },
    dealer: '',
    licenseType: '',
    selectedUsers: [],
    isValidLicenseType: true,
    restrictUnlimitedUsers: false,
    selectReason: '',
    startDate: '',
    expirationDate: '',
    cvaNo: '',
    selectReasonOtherDesc: '',
    prefix: '',
  };
  createSubscriptionFormGroup!: FormGroup;
  PlanTypeChange = [];
  isShowNewColumnInAccounts = false;

  createSubscriptionForm = [];
  disableNext = true;
  productsList = [];
  productPlansList: any = [];
  salesList = [];
  dspStoreData!: any;
  addPlanConfig = Plan_Template;
  subscribeAccountsConst: any;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<NewSubscriptionComponent>,
    private formBuilder: FormBuilder,
    private accountsService: SubscribeAccountsService,
    private changeDetector: ChangeDetectorRef,
    private datepipe: DatePipe,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.subscribeAccountsConst = { ...OrgSubs, ...Common };
    this.addPlanConfig.headerTitle = this.subscribeAccountsConst.ADD_HEADER;
    this.addPlanConfig.actionType = this.subscribeAccountsConst.OPERATIONTYPE_ADD;
    this.dynamicTrackerData = cloneDeep(this.trackerData);
    this.dealer = fetchLoggedInDealerDetails();
    this.createSubscriptionFormGroup = this.formBuilder.group({
      createSubscriptionForm: [this.createSubscriptionForm],
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowNewColumnInAccounts = this.dspStoreData?.featureFlagInfo?.isShowNewColumnInAccounts
        ? this.dspStoreData?.featureFlagInfo?.isShowNewColumnInAccounts
        : false;
    });
    this.accountsService.accountFormDataEvent.subscribe((data: AccountFormType) => {
      this.selectedFormData = data;
      if (
        this.selectedFormData.product === this.subscribeAccountsConst.SIS_GO &&
        this.selectedFormData.plan === this.subscribeAccountsConst.SMA
      ) {
        if (isEmpty(this.dspStoreData?.salesReason)) {
          this.getSalesReason();
        } else {
          delete this.dspStoreData?.salesReason.type;
          this.salesList = Object.values(this.dspStoreData?.salesReason);
        }
      }
      this.updateSecondPageName();
      this.updateTrackerDetails();
      this.handleTrackerDone();
      if (this.selectedFormData.plan === this.subscribeAccountsConst.TT) {
        this.addPlanConfig.accountDetails = { customerAccount: true, type: true };
      } else if (this.selectedFormData.plan === this.subscribeAccountsConst.VM) {
        this.addPlanConfig.accountDetails = { customerAccount: true };
      } else if (this.selectedFormData.plan === this.subscribeAccountsConst.SIS_USB) {
        this.addPlanConfig.accountDetails = {};
      } else {
        this.addPlanConfig.accountDetails = {
          customerAccount: true,
          prefix: true,
          saleReason: true,
          expirationDate: true,
        };
      }
      this.changeDetector.detectChanges();
    });
    this.fetchProducts();
  }

  updateSecondPageName() {
    if (this.dynamicTrackerData && this.dynamicTrackerData[1]) {
      if (this.selectedFormData.product === this.subscribeAccountsConst.SIS2GO) {
        // this.dynamicTrackerData[1].label = 'User Selection';
        this.dynamicTrackerData[1].label = 'Account and Details';
      } else if (this.selectedFormData.plan === this.subscribeAccountsConst.VM) {
        this.dynamicTrackerData[1].label = 'Account';
      } else {
        this.dynamicTrackerData[1].label = 'Account and Details';
      }
    }
  }
  updateTrackerDetails() {
    const { plan, accountType } = this.selectedFormData;
    if (
      !isEmpty(plan) &&
      isEqual(accountType, this.subscribeAccountsConst.DEALER) &&
      this.dynamicTrackerData.length === 2
    ) {
      remove(this.dynamicTrackerData, (row: any) => {
        return row.label === 'Account and Details' || row.label === 'Account';
      });
    } else if (!isEqual(accountType, 'Dealer') && this.dynamicTrackerData.length === 1) {
      this.dynamicTrackerData = cloneDeep(this.trackerData);
      this.updateSecondPageName();
    }
  }

  handleTrackerDone() {
    if (this.updateSubmitButtonLabel() === 'Submit' && this.selectedFormData.isValidForm) {
      this.trackerIndex = this.currentPageIndex + 1;
    } else if (this.trackerIndex === 3 && this.selectedFormData.licenseType === this.subscribeAccountsConst.LU) {
      this.trackerIndex = this.trackerIndex - 2;
    } else {
      this.trackerIndex = this.currentPageIndex;
    }
  }

  fetchProducts() {
    const productsParams = {
      orgType: 'Customer',
      orgCode: this.dealer?.dealerCode,
      dealerCode: this.dealer?.dealerCode,
    };
    this.accountsService.getProducts(productsParams).subscribe({
      next: (res: any) => {
        if (res) {
          this.productsList = res.map((product: any) => ({
            label: product.name,
            value: product.description,
          }));
          this.changeDetector.detectChanges();
        }
      },
      error: (error) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  fetchPlans() {
    if (this.selectedFormData.product !== '') {
      const getPlansPayload = {
        dealerCode: this.dealer?.dealerCode,
        application: this.selectedFormData.product,
        version: '2.0',
      };
      this.accountsService.getPlans(getPlansPayload).subscribe({
        next: (res: any) => {
          if (res) {
            this.productPlansList = res.map((plan: any) => ({
              label: plan.name,
              value: plan.name,
            }));
            this.productPlansList = uniqBy(this.productPlansList, 'label');
            this.changeDetector.detectChanges();
          }
        },
        error: (error) => {
          const messageText = `There was error processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
        },
      });
    }
  }

  getSalesReason() {
    this.accountsService.getSalesReason().subscribe(
      (res: any) => {
        if (res) {
          this.salesList = res.map((item: any) => ({
            label: item.reason,
            value: item.id,
          }));
          this.store.dispatch(getSalesReason(this.salesList));
          this.changeDetector.detectChanges();
        }
      },
      (error) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
      }
    );
  }

  closeModel() {
    this.modalRef.close();
  }

  submitButtonDisableCheck() {
    if (this.selectedFormData.isValidForm) {
      return true;
    }
    return false;
  }

  updateSubmitButtonLabel() {
    if (
      (this.currentPageIndex === 0 && this.selectedFormData.dealer !== '') ||
      (this.currentPageIndex === 1 &&
        (this.selectedFormData.licenseType === this.subscribeAccountsConst.ULU ||
          this.selectedFormData.plan == this.subscribeAccountsConst.VM ||
          this.selectedFormData.plan == this.subscribeAccountsConst.SIS_USB)) ||
      this.currentPageIndex === 2
    ) {
      return 'Submit';
    } else {
      return 'Next';
    }
  }

  onNext() {
    if (this.selectedFormData?.accountType === this.subscribeAccountsConst.DEALER) {
      this.handleCreateSubscription();
    } else {
      if (this.currentPageIndex === 0) {
        this.currentPageIndex = 1;
        this.trackerIndex = 1;
      } else if (this.currentPageIndex === 1) {
        if (
          (this.selectedFormData.plan == this.subscribeAccountsConst.TT &&
            this.selectedFormData.licenseType === this.subscribeAccountsConst.LU) ||
          this.selectedFormData.plan == this.subscribeAccountsConst.SMA
        ) {
          // for TT & SMA
          this.currentPageIndex = 2;
          this.trackerIndex = 1;
        } else {
          // for VIMS & SIS2GO
          this.handleCreateSubscription(); // check if this form is valid and submit is enabled then update the tracker.
        }
      } else if (this.currentPageIndex === 2) {
        this.handleCreateSubscription();
      }
    }
    this.accountsService.setAccountFormData(this.selectedFormData);
    this.changeDetector.detectChanges();
  }

  onPrevious() {
    if (
      this.selectedFormData.plan == this.subscribeAccountsConst.TT &&
      this.selectedFormData.licenseType == this.subscribeAccountsConst.LU &&
      this.currentPageIndex >= 2
    ) {
      // for TT & SMA
      this.currentPageIndex = 1;
    } else if (this.selectedFormData.plan == this.subscribeAccountsConst.SMA && this.currentPageIndex >= 2) {
      this.currentPageIndex = 1;
    } else {
      // for VIMS & SIS2GO
      this.currentPageIndex = 0;
      this.trackerIndex = 0;
    }
    this.selectedFormData.isValidForm = true;
    this.accountsService.setAccountFormData(this.selectedFormData);
  }

  enableFormSubmit() {
    if (
      this.selectedFormData.plan == this.subscribeAccountsConst.SIS_USB ||
      this.selectedFormData.plan == this.subscribeAccountsConst.TT
    ) {
      return this.selectedFormData.selectedUsers.length > 0;
    } else if (this.selectedFormData.plan == this.subscribeAccountsConst.VM) {
      return this.selectedFormData.customer?.ucid !== '';
    } else {
      return false;
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  updateFormValue(valueType: string) {
    if (valueType === 'dcName') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER
        ? ''
        : this.selectedFormData.customer?.dcName;
    } else if (valueType === 'dcn') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER
        ? ''
        : this.selectedFormData.customer?.dcn;
    } else if (valueType === 'licensedType') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER
        ? ''
        : this.selectedFormData.licenseType;
    } else if (valueType === 'ucName') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER
        ? ''
        : this.selectedFormData.customer?.ucName.trim();
    } else if (valueType === 'ucid') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER
        ? ''
        : this.selectedFormData.customer?.ucid.trim();
    } else if (valueType === 'users') {
      return this.selectedFormData.accountType === this.subscribeAccountsConst.DEALER ? [] : this.mapSelectedTTUsers();
    }
  }

  handleCreateSubscription() {
    if (this.selectedFormData.product && this.selectedFormData.plan) {
      if (this.selectedFormData.product == this.subscribeAccountsConst.SIS_GO) {
        this.handleCreateSIS2GOSubscription();
      } else if (this.selectedFormData.product == this.subscribeAccountsConst.MINESTAR_HEI) {
        this.handleCreateMineStarSubscription();
      }
    }
  }

  handleCreateMineStarSubscription() {
    let createTTSubscriptionPayload;
    if (this.isShowNewColumnInAccounts) {
      let planTypeValue = this.updateFormValue('licensedType');
      if (planTypeValue === 'Limited Users') {
        planTypeValue = 'Limited';
      } else if (planTypeValue === 'Unlimited Users') {
        planTypeValue = 'Unlimited';
      } else {
        planTypeValue = '';
      }
      createTTSubscriptionPayload = {
        appName: this.selectedFormData.product,
        dcName: this.updateFormValue('dcName'),
        dcn: this.updateFormValue('dcn'),
        dealerCode: this.dealer.dealerCode,
        dealerName: this.dealer.dealerName || '',
        isAutoRenewed: false,
        licensedType: this.updateFormValue('licensedType'),
        operation: 'ADD',
        planName: this.selectedFormData.plan,
        planType: planTypeValue,
        planLevel: this.selectedFormData.accountType,
        startDate: this.datepipe.transform(new Date(), 'MM/dd/yyyy'),
        subsDtl: {
          name: this.selectedFormData.plan,
          pricing: [],
          type: this.selectedFormData.accountType,
        },
        type: this.selectedFormData.accountType,
        ucName: this.updateFormValue('ucName'),
        ucid: this.updateFormValue('ucid'),
        users: this.updateFormValue('users'),
      };
    } else {
      createTTSubscriptionPayload = {
        appName: this.selectedFormData.product,
        dcName: this.updateFormValue('dcName'),
        dcn: this.updateFormValue('dcn'),
        dealerCode: this.dealer.dealerCode,
        dealerName: this.dealer.dealerName || '',
        isAutoRenewed: false,
        licensedType: this.updateFormValue('licensedType'),
        operation: 'ADD',
        planName: this.selectedFormData.plan,

        startDate: this.datepipe.transform(new Date(), 'MM/dd/yyyy'),
        subsDtl: {
          name: this.selectedFormData.plan,
          pricing: [],
          type: this.selectedFormData.accountType,
        },
        type: this.selectedFormData.accountType,
        ucName: this.updateFormValue('ucName'),
        ucid: this.updateFormValue('ucid'),
        users: this.updateFormValue('users'),
      };
    }

    this.accountsService.setNewSubscription(createTTSubscriptionPayload).subscribe({
      next: (response: any) => {
        this.closeModel();
        if (
          this.selectedFormData.licenseType === this.subscribeAccountsConst.LU &&
          this.checkFailedSubscriptionStatus(response)
        ) {
          const messageText = `Plan could not be processed for one or more users. Please try again later.`;
          this.showToastMessage(messageText, 'error');
        } else {
          const messageText = `Plan added successfully.`;
          this.showToastMessage(messageText, 'success');
        }
      },
      error: () => {
        this.closeModel();
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  handleCreateSIS2GOSubscription() {
    let subscriptionPayload;
    if (this.isShowNewColumnInAccounts) {
      subscriptionPayload = {
        appName: this.selectedFormData.product,
        type:
          this.selectedFormData.plan == this.subscribeAccountsConst.SIS_USB
            ? this.subscribeAccountsConst.USER
            : this.subscribeAccountsConst.CUSTOMER,
        billToParty: null,
        operation: this.subscribeAccountsConst.OPERATIONTYPE_ADD,
        dealerCode: this.dealer.dealerCode,
        users: this.mapSelectedSis2goUsers(),
        startDate: this.datepipe.transform(new Date(), 'MM/dd/yyyy'),
        endDate: this.selectedFormData.expirationDate,
        ...(this.selectedFormData.selectReason ? { salesReason: { id: this.selectedFormData.selectReason } } : {}),
        assetPrefix: this.selectedFormData.prefix,
        salesDescription: this.selectedFormData.selectReasonOtherDesc,
        cvaNumber: this.selectedFormData.selectReason === 1 ? this.selectedFormData.cvaNo : '',
        isAutoRenewed: false,
        subsDtl: {
          name: this.selectedFormData.plan,
          pricing: [],
        },
        planName: this.selectedFormData.plan,
        planLevel: dspConstants.Common.CUSTOMER,
        planType: '',

        ucName: this.updateFormValue('ucName'),
        ucid: this.updateFormValue('ucid'),
        dcName: this.updateFormValue('dcName'),
        dcn: this.updateFormValue('dcn'),
        store: 'DSP',
      };
    } else {
      subscriptionPayload = {
        appName: this.selectedFormData.product,
        type:
          this.selectedFormData.plan == this.subscribeAccountsConst.SIS_USB
            ? this.subscribeAccountsConst.USER
            : this.subscribeAccountsConst.CUSTOMER,
        billToParty: null,
        operation: this.subscribeAccountsConst.OPERATIONTYPE_ADD,
        dealerCode: this.dealer.dealerCode,
        users: this.mapSelectedSis2goUsers(),
        startDate: this.datepipe.transform(new Date(), 'MM/dd/yyyy'),
        endDate: this.selectedFormData.expirationDate,
        ...(this.selectedFormData.selectReason ? { salesReason: { id: this.selectedFormData.selectReason } } : {}),
        assetPrefix: this.selectedFormData.prefix,
        salesDescription: this.selectedFormData.selectReasonOtherDesc,
        cvaNumber: this.selectedFormData.selectReason === 1 ? this.selectedFormData.cvaNo : '',
        isAutoRenewed: false,
        subsDtl: {
          name: this.selectedFormData.plan,
          pricing: [],
        },
        planName: this.selectedFormData.plan,
        ucName: this.updateFormValue('ucName'),
        ucid: this.updateFormValue('ucid'),
        dcName: this.updateFormValue('dcName'),
        dcn: this.updateFormValue('dcn'),
        store: 'DSP',
      };
    }
    this.accountsService.setNewSubscription(subscriptionPayload).subscribe({
      next: (response: any) => {
        this.closeModel();
        if (this.checkFailedSubscriptionStatus(response)) {
          const messageText = `Plan could not be processed for one or more users. Please try again later.`;
          this.showToastMessage(messageText, 'error');
        } else {
          const messageText = `Plan added successfully.`;
          this.showToastMessage(messageText, 'success');
        }
      },
      error: () => {
        this.closeModel();
        const messageText = `Plan could not be processed for one or more users. Please try again later.`;
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  checkFailedSubscriptionStatus(response: any) {
    return (
      (response.failedCws && response.failedCws.length > 0) || (response.skippedCws && response.skippedCws.length > 0)
    );
  }

  mapSelectedSis2goUsers() {
    if (!isEmpty(this.selectedFormData.selectedUsers)) {
      return this.selectedFormData.selectedUsers.map(function (user: any) {
        return {
          cwsId: user.cwsId,
          catRecId: user.catRecId,
          emailAddress: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          userOperation: 'ADD',
        };
      });
    } else {
      return [];
    }
  }

  mapSelectedTTUsers() {
    if (!isEmpty(this.selectedFormData.selectedUsers)) {
      return this.selectedFormData.selectedUsers.map(function (user: any) {
        return {
          cwsId: user.cwsId,
          catRecId: user.catRecId,
          emailAddress: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          userOperation: 'ADD',
        };
      });
    } else {
      return [];
    }
  }

  progressTracker(event: any) {
    if (event == 0 && this.currentPageIndex >= 1) {
      if (this.currentPageIndex == 2) this.onPrevious();
      this.onPrevious();
    }
  }
}
