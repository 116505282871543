<div class="needs-review-chart">
  <div class="header">
    <div class="left-header">Needs Review</div>
    <div class="right-header">
      <div class="icons">
        <cc-icon fontSet="icomoon" (click)="expandedChartView()">fullscreen</cc-icon>
      </div>
      <div class="icons" *ngIf="!isShowNeedsReviewExpandedViewFilter">
        <cc-icon fontSet="icomoon" (click)="openFilter()">filter</cc-icon>
      </div>
      <div class="icons" *ngIf="isShowNeedsReviewExpandedViewFilter">
        <cc-icon *ngIf="!filterIcon" fontSet="icomoon" (click)="openFilter()">filter</cc-icon>
        <img
          class="filter-icon-checked"
          *ngIf="filterIcon"
          src="../../../../../assets/images/filter-applied.svg"
          (click)="openFilter()"
        />
      </div>
    </div>
  </div>
  <div class="needs-review-dashboard" *ngIf="needsReview && needsReview.length && !chartSpinner">
    <cc-bar-chart
      [chartId]="1"
      [chartData]="needsReviewChartData"
      [chartConfig]="needsReviewChartConfig"
      [chartType]="'horizontal'"
      [responsiveConfig]="chart2ResponsiveConfig"
      (seriesClick)="needsreviewChartClick($event)"
      [chartDataResponsive]="true"
    ></cc-bar-chart>
  </div>
  <dsp-next-gen-ui-spot-loader *ngIf="chartSpinner"></dsp-next-gen-ui-spot-loader>
  <div *ngIf="!chartSpinner && needsReview && needsReview.length === 0" class="no-results-found">
    <img src="../../../../../assets/images/no-data.png" width="198" height="132" />
    <p class="no-results-text">No data to display</p>
  </div>
</div>
