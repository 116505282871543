import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewEncapsulation,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { CCTooltip } from '@cat-digital-workspace/shared-ui-core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { hasIn, isEmpty } from 'lodash-es';
import * as dspConstants from '../../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { SelfServeService } from 'apps/dsp-ui/src/app/self-serve.service';
import { VLRequestData } from '../../../manage-pending-request/self-serve.interface';

@Component({
  selector: 'dsp-next-gen-ui-asset-ownership',
  templateUrl: './asset-ownership.component.html',
  styleUrls: ['./asset-ownership.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetOwnershipComponent implements OnInit, OnChanges {
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() assetOwnershipDetails: any;
  @Output() refreshSyncedData = new EventEmitter();
  assetOwnerData: any;
  dealerData: any;
  dealer!: any;
  edUrl: any;
  datUrl: any;
  ownershipDetailsarr: any;
  datStatus = '';
  userActiveFeatures: any;
  isBorderShow = false;
  assetOwners = '';
  assetDealerInfo: any;
  dspConfiguration: any;
  replaceEDUrl!: any;
  dspStoreData!: any;
  enableOwnershipSync!: any;
  queueReasonList: any;
  isSelfServeSubscription: any;
  isCustRequestedSubscription: any = false;
  constructor(
    private assetDrawerService: AssetDrawerService,
    private selfServeService: SelfServeService,
    private assetService: AssetService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<DSPAppState>,
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private loaderService: LoaderService
  ) {
    this.dealer = fetchLoggedInDealerDetails();
  }

  ngOnInit(): void {
    this.setAssetOwnershipDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.enableOwnershipSync = this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        ? this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.edUrl = dsp.edUrl?.data;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspConfiguration = dsp.dspConfig;
      this.replaceEDUrl = this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        ? this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        : false;
      this.isSelfServeSubscription = dsp?.featureFlagInfo?.isSelfServeSubscription
        ? dsp?.featureFlagInfo?.isSelfServeSubscription
        : false;
      this.queueReasonList = dsp?.dspConfig?.Queue_Reason_List ? JSON.parse(dsp.dspConfig.Queue_Reason_List) : [];
    });
  }
  ngOnChanges(): void {
    this.setAssetOwnershipDetails();
  }
  enableSyncOwnership() {
    return this.assetService.validateOwnershipSync(
      this.userActiveFeatures,
      this.assetOwnershipDetails,
      this.enableOwnershipSync
    );
  }

  initiateSync() {
    this.refreshSyncedData.emit();
  }
  setAssetOwnershipDetails() {
    this.assetOwnerData = this.assetOwnershipDetails?.ownershipDetails;
    if (!isEmpty(this.assetOwnershipDetails?.dealerInfo) && !this.assetOwnershipDetails?.callDealerInfoAPI) {
      let reasonList = this.assetOwnershipDetails.dealerInfo[this.assetOwnershipDetails?.assetId];
      this.checkIfReasonListExists(reasonList);
      this.assetDealerInfo = this.assetOwnershipDetails.dealerInfo;
      this.mapDealerName();
    } else {
      this.getDealerInfo();
    }
  }
  copyEvent(event: any, dealerCustName: string, dealerCustNo: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(event, clipboardText, ':', dealerCustName, dealerCustNo, 53, 'Asset Ownership');
  }
  updateDatStatus(catAuthStatus: string) {
    if (catAuthStatus == 'AGREE') {
      this.datStatus = 'AGREE';
      return `Available`;
    } else if (catAuthStatus == 'DECLINE') {
      this.datStatus = 'DECLINE';
      return `Declined`;
    } else if (catAuthStatus == 'UNAVAILABLE') {
      this.datStatus = 'UNAVAILABLE';
      return `Not Available`;
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `System Error`;
    }
  }

  isOwnershipEmpty() {
    if (this.assetOwnerData?.length > 1) {
      this.isBorderShow = true;
    }
    return this.assetOwnerData?.length == 0;
  }

  checkDATStatus(datStatus: string) {
    switch (datStatus) {
      case 'AGREE': {
        return 'icon-DAT-green';
      }
      case 'DECLINE': {
        return 'icon-DAT-red';
      }
      case 'UNAVAILABLE': {
        return 'icon-DAT-grey';
      }
      default: {
        return 'icon-DAT-amber'; // for UNREACHABLE datStatus
      }
    }
  }

  updateDATBadge(datStatus: string) {
    switch (datStatus) {
      case 'DECLINE': {
        return 'dat-badge-declined';
      }
      case 'UNAVAILABLE': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, for all other states
      }
    }
  }

  showScroll(assetOwners: any): any {
    if (assetOwners?.length > 1) return 'asset-ownership-card-container';
  }

  closeDATTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }

  getDealerInfo() {
    if (this.assetOwnershipDetails?.reportingState && this.assetOwnershipDetails?.ownershipDetails) {
      const dealerInfoPayload = {
        assetIds: [this.assetOwnershipDetails?.assetId] || [],
        dealerCode: this.dealer?.dealerCode || '',
      };
      this.loaderService.show();
      this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
        next: (result: any) => {
          if (result) {
            let reasonList = result[this.assetOwnershipDetails?.assetId];
            this.checkIfReasonListExists(reasonList);
            this.loaderService.hide();
            this.assetDealerInfo = result;
            this.mapDealerName();
          }
        },
        error: (err: any) => {
          this.loaderService.hide();
          this.assetDealerInfo = {};
          this.mapDealerName();
        },
      });
    }
  }

  checkIfReasonListExists(reasonList: any) {
    this.isCustRequestedSubscription = this.assetService.checkIfReasonListExists(this.queueReasonList, reasonList);
    if (this.isCustRequestedSubscription && this.isSelfServeSubscription) {
      this.assetOwnershipDetails.showPendingRequestChip = true;
      this.fetchVLRequestDetails();
    } else {
      this.assetOwnershipDetails.showPendingRequestChip = false;
    }
  }
  fetchVLRequestDetails() {
    this.selfServeService
      .getVLRequestDetails(this.assetOwnershipDetails.make, this.assetOwnershipDetails.serialNumber)
      .subscribe({
        next: (requestData: any) => {
          if (requestData) {
            const data: VLRequestData = {
              dealerCode: requestData?.dealerCode || '',
              ucid: requestData?.requcid || '',
              application: requestData?.requestApplicationName || '',
              name: requestData?.requestDetailsList[0]?.name || '',
              email: requestData?.requestDetailsList[0]?.email || '',
              phoneNumber: requestData?.requestDetailsList[0]?.phoneNumber || '',
              date_time: requestData?.requestTime || '',
              type: requestData?.requestDetailsList[0]?.type || '',
              plan_name: requestData?.requestPlanName || '',
            };
            this.assetOwnershipDetails.vlRequestDetails = data;
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  mapDealerName() {
    if (
      this.assetOwnershipDetails?.reportingState == dspConstants.Worklist.SUBSCRIBED &&
      !isEmpty(this.assetOwnershipDetails?.dealerCode)
    ) {
      if (hasIn(this.assetDealerInfo, this.assetOwnershipDetails?.dealerCode)) {
        this.assetOwnershipDetails.dealerName = this.assetDealerInfo[this.assetOwnershipDetails?.dealerCode];
      }
    }
    this.dealerData =
      !isEmpty(this.assetOwnershipDetails?.dealerName) && !isEmpty(this.assetOwnershipDetails?.dealerCode)
        ? this.assetOwnershipDetails?.dealerName + ' - ' + this.assetOwnershipDetails?.dealerCode
        : '-';
  }

  getTrimData(assetOwnerObj: any) {
    let deaderData = assetOwnerObj.dealerCustName + assetOwnerObj.dealerCustNo;
    if (deaderData.length > 50) {
      if (!isEmpty(assetOwnerObj?.dealerCustName?.trim()) && !isEmpty(assetOwnerObj?.dealerCustNo?.trim())) {
        return (assetOwnerObj.dealerCustName + ' - ' + assetOwnerObj.dealerCustNo).slice(0, 50) + '...';
      } else if (!isEmpty(assetOwnerObj?.dealerCustName?.trim())) {
        return assetOwnerObj.dealerCustName;
      } else if (!isEmpty(assetOwnerObj?.dealerCustNo?.trim())) {
        return assetOwnerObj.dealerCustNo;
      }
    } else {
      if (!isEmpty(assetOwnerObj?.dealerCustName?.trim()) && !isEmpty(assetOwnerObj?.dealerCustNo?.trim())) {
        return assetOwnerObj.dealerCustName + ' - ' + assetOwnerObj.dealerCustNo;
      } else if (!isEmpty(assetOwnerObj?.dealerCustName?.trim())) {
        return assetOwnerObj.dealerCustName;
      } else if (!isEmpty(assetOwnerObj?.dealerCustNo?.trim())) {
        return assetOwnerObj.dealerCustNo;
      } else return '-';
    }
  }

  getTrimCustomerData(assetOwnerObj: any) {
    let customerData = assetOwnerObj.ucidName + assetOwnerObj.ucid;
    if (customerData.length > 50) {
      if (!isEmpty(assetOwnerObj?.ucidName?.trim()) && !isEmpty(assetOwnerObj?.ucid?.trim())) {
        return (assetOwnerObj.ucidName + ' - ' + assetOwnerObj.ucid).slice(0, 50) + '...';
      } else if (!isEmpty(assetOwnerObj?.ucidName?.trim())) {
        return assetOwnerObj.ucidName;
      } else if (!isEmpty(assetOwnerObj?.ucid?.trim())) {
        return assetOwnerObj.ucid;
      }
    } else {
      if (!isEmpty(assetOwnerObj?.ucidName?.trim()) && !isEmpty(assetOwnerObj?.ucid?.trim())) {
        return assetOwnerObj.ucidName + ' - ' + assetOwnerObj.ucid;
      } else if (!isEmpty(assetOwnerObj?.ucidName?.trim())) {
        return assetOwnerObj.ucidName;
      } else if (!isEmpty(assetOwnerObj?.ucid?.trim())) {
        return assetOwnerObj.ucid;
      } else return '-';
    }
  }
}
