import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DspCommonService } from '../services/dsp-common.service';
import * as dspConstants from '../../app/shared/dspConstants';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss'],
})
export class PrivacyNoticeComponent {
  isChecked = false;
  dateYear = new Date().getFullYear();
  user: any;
  userRole: any;
  public subscription: Subscription = new Subscription();
  constructor(public router: Router, private dspCommonService: DspCommonService) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          router.navigate(['tos']);
        }
      }
    });
  }
  toggleCheckboxOption(event: any): void {
    if (event === true) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }

  privacyAgreeButton() {
    const payload = {
      isEulaAccepted: true,
      showTOS: false,
    };
    this.dspCommonService.acceptEula(payload).subscribe({
      next: (response) => {
        if (response) {
          this.dspCommonService.getUser().subscribe({
            next: (response) => {
              this.user = response;
              this.userRole = this.user?.roleName;
              if (isEmpty(this.userRole)) {
                this.router.navigate(['denied']).then(() => {
                  window.location.reload();
                });
              } else if (
                this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
                this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
                this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
              ) {
                this.router.navigate(['dft']).then(() => {
                  window.location.reload();
                });
              } else if (this.userRole === dspConstants.Common.DSP_FACTORY_USER) {
                this.router.navigate(['factoryuser']).then(() => {
                  window.location.reload();
                });
              } else {
                this.router.navigate(['/']).then(() => {
                  window.location.reload();
                });
              }
            },
          });
        }
      },
    });
  }

  privacyDisAgreeButton() {
    this.router.navigate(['tos']);
  }
}
