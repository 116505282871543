import { Component, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { UserAdminService } from '../../services/user-admin.service';
import { ConfigureAccessComponent } from '../configure-access/configure-access.component';

@Component({
  selector: 'dsp-next-gen-ui-user-admin-row-expansion',
  templateUrl: './user-admin-row-expansion.component.html',
  styleUrls: ['./user-admin-row-expansion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAdminRowExpansionComponent {
  configureAccessDrawerRef!: any;
  messageBar: any;
  constructor(
    private userAdminService: UserAdminService,
    private drawerService: DrawerService
  ) {}
  displayInfo: any = [];
  updatedData: any = [];
  @Input() tableData: any;
  @Input() isOpenConfigureAccess: boolean | undefined;
  @Output() expansionRowEvent = new EventEmitter();

  ngOnInit(): void {
    this.userAdminService.updateExpandRowDetailsEvent.subscribe((data: any) => {
      this.updatedData = data;
      this.updateExpandRowDetails();
    });

    this.getExpandRow(this.tableData);
  }
  updateExpandRowDetails() {
    this.updatedData = this.updateDSPName(this.updatedData);
    this.displayInfo.forEach((actualData: any) => {
      this.updatedData.forEach((updatedData: any) => {
        if (updatedData.appName === actualData.appName) {
          actualData.roleId = updatedData.roleId;
          actualData.roleName = updatedData.roleName;
          updatedData = {};
        }
      });
    });
  }
  getExpandRow(tableData: any) {
    const payload = {
      catRecId: this.tableData?.catRecId,
      profileType: this.tableData?.profileType,
    };
    this.userAdminService.getExpandRow(payload).subscribe((res: any) => {
      this.displayInfo = res;
      this.displayInfo = this.updateDSPName(this.displayInfo);
    });
  }
  updateDSPName(info: any) {
    info.forEach((data: any, index: any) => {
      if (data.appName === 'Dealer Services Portal') {
        info[index].appName = 'Dealer Services Portal (Web & Mobile)';
      }
    });
    return info;
  }
}
