import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { ShowallFilterComponent } from '../showall-filter/showall-filter.component';
import { SubFilterComponent } from '../sub-filter/sub-filter.component';

@Component({
  selector: 'dsp-next-gen-ui-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss']
})
export class AccordionListComponent {
  @Input() filterColumns:Array<any>=[];
  @Input() filterData:any={};
  @Output() fetchDataCallback = new EventEmitter();
  @Output() fetchSelectedValueCallback = new EventEmitter();
  
  constructor(
    private drawerService: DrawerService,
    public showAllContentRef: CcDrawerRef<ShowallFilterComponent, CcDrawerConfig>,
    public subFilterContentsRef: CcDrawerRef<SubFilterComponent, CcDrawerConfig>
  ) { }

  

  emitSubFilterData(subFilter:any,label:string){
    if(subFilter){
      this.fetchDataCallback.emit({subFilter,label});
    }
  }

  getSelectedFilter(event: any, filter: any,value:string) {
    if (event.checked) {
      filter.selectedValues.push(value);
      
    } else {
      filter.selectedValues.splice(filter.selectedValues.indexOf(value), 1);
    }
    this.fetchSelectedValueCallback.emit({filter});
  }

  showAllData(type: string, value:any,columns: any) {
    this.showAllContentRef = this.drawerService.openDrawer<ShowallFilterComponent, CcDrawerConfig>(
      ShowallFilterComponent,
      {
        data: {
          headerType: type,
          columns: columns,
          showallData:value,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
      this.showAllContentRef.afterClosed().subscribe(()=> {
        this.fetchSelectedValueCallback.emit(this.showAllContentRef.config.data.columns);
      })
  }

}
