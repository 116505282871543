import { Component, Inject } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core/modal';
import { LinksService } from '../../../services/links.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';

@Component({
  selector: 'dsp-next-gen-ui-quick-links-modal',
  templateUrl: './quick-links-modal.component.html',
  styleUrls: ['./quick-links-modal.component.scss'],
})
export class QuickLinksModalComponent {
  body: any;
  name: any;
  url: any;
  positive = true;
  negative = false;
  noUrlName = true;
  noUrlValue = true;
  urlChng = false;
  errorText = 'Minimum 3 characters';
  errorTextUrl = '';
  isFormSubmited = false;
  copyPasteApplied = false;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<QuickLinksModalComponent>,
    private linksService: LinksService,
    private store: Store<DSPAppState>,
    private messageBar: MessageBar
  ) {}

  urlNameChange(value: any) {
    if (value.value === '') {
      this.errorText = '';
      this.noUrlName = true;
      this.negative = true;
      this.positive = false;
    } else {
      if (value.value?.trim().length == 0 || value.minOrMaxLengthBound === true) {
        this.errorText = 'Minimum 3 characters';
        this.noUrlName = true;
        this.negative = true;
        this.positive = false;
      } else {
        this.noUrlName = false;
        this.errorText = '';
        this.positive = true;
        this.negative = false;
      }
    }

    if (!this.copyPasteApplied) {
      this.data.value1 = value.value?.trim();
    }
    this.name = value;
  }
  urlChange(value: any) {
    this.errorTextUrl = '';
    if (value.value === '') {
      this.noUrlValue = true;
      this.urlChng = true;
    } else {
      if (value.value?.trim().length == 0 || value.minOrMaxLengthBound === true) {
        this.noUrlValue = true;
        this.urlChng = true;
        this.errorTextUrl = 'Minimum 3 characters';
      } else {
        this.noUrlValue = false;
        this.urlChng = false;
      }
    }
    this.data.value2 = value.value?.trim();
    this.url = value;
  }
  validateKey($event: any) {
    this.copyPasteApplied = true;
    const clipboardData = $event.clipboardData;
    const pastedText = clipboardData?.getData('text');
    if (pastedText.length > 50) {
      this.data.value1 = pastedText.substring(0, 50).trim();
    } else {
      this.data.value1 = pastedText.trim();
    }
  }
  // isUrlValid(userInput: any) {
  //   const res = userInput.match(
  //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  //   );
  //   if (res == null) return false;
  //   else return true;
  // }

  checkDisableBtn() {
    return this.noUrlName || this.noUrlValue;
  }

  onClickCancel() {
    this.modalRef.close('Modal Closed');
  }

  onClickSubmit(_value: any) {
    this.isFormSubmited = true;
    const body = {
      title: this.name.value.trim(),
      url: this.url.value.trim(),
    };

    if (!this.data.quickLinks.find((obj: any) => obj.url === this.url.value)) {
      this.linksService.addQuickLink(body).subscribe({
        //this.store.dispatch(storeActions.createQuickLinksAction({ body }));
        //this.store.pipe(select(storeSelectors.createQuickLinks)).subscribe((data) => {
        next: (data: any) => {
          if (data) {
            setTimeout(() => {
              this.isFormSubmited = false;
              const messageText = `Quick link added successfully.`;
              this.showToastNotification(messageText, 'success');
            }, 1000);
            this.modalRef.close('add');
          }
        },
        error: (err) => {
          this.isFormSubmited = false;

          this.showToastNotification('Something went wrong. Please try again later', 'error');
        },
      });
    } else {
      this.isFormSubmited = false;
      this.showToastNotification('Quick link url already exists', 'error');
    }
  }

  showToastNotification(message: string, type: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    const enableCloseIcon = true;
    const toastbarRef = this.messageBar.open(message, type, undefined, config, enableCloseIcon);
  }
}
