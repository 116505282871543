import { INVOICECOUNTOVERLAYDATA, OVERLAYDATA } from './billing-constant';
import { DetailedInvoiceComponentAsset } from './components/detailed-invoice-asset/detailed-invoice-asset.component';
import { DetailedInvTooltipHighlightComponent } from './components/detailed-invoice-tooltip-highlight/detailed-inv-tooltip-highlight.component';

export const ColumnConfig: any = [
  {
    name: 'assetSerialNumber',
    title: 'Asset S/N',
    priority: 1,
    width: 140,
    isFrozen: true,
    isAlwaysMandatory: true,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvoiceComponentAsset,
  },
  {
    name: 'application',
    title: 'Application',
    width: 155,
    priority: 2,
    isFrozen: true,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'customer',
    title: 'Customer Name',
    width: 175,
    isFrozen: true,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'ucid',
    title: 'Customer ID',
    width: 150,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: INVOICECOUNTOVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'dcn',
    title: 'Dealer Customer Number',
    isFrozen: false,
    width: 210,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: INVOICECOUNTOVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'siteName',
    title: 'Site Name',
    isFrozen: false,
    width: 150,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'make',
    title: 'Asset Make',
    isFrozen: false,
    width: 160,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'assetModel',
    title: 'Asset Model',
    isFrozen: false,
    width: 160,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
  {
    name: 'subTotal',
    title: 'Line Item Total',
    isFrozen: false,
    width: 160,
    // minWidth: 110,
    // maxWidth: 120,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: INVOICECOUNTOVERLAYDATA,
    component: DetailedInvTooltipHighlightComponent,
  },
];
