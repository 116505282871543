import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core';
import { DspUtilsCommonService } from '../../../../services/dsp-utils-common.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/dashboard.service';
@Component({
  selector: 'dsp-next-gen-ui-cancellation-expanded',
  templateUrl: './cancellation-expanded.component.html',
  styleUrls: ['./cancellation-expanded.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancellationExpandedComponent implements OnInit {
  nodata = false;
  finalData: any;
  xAxis: any = [];
  cancellationChartData = {
    series: [
      {
        data: [],
        minPointLength: 5,
      },
    ],
  };

  cancellationChartConfig = {
    height: '430px',
    styledMode: false,
    WrapXaxisLabel: true,
    groupGap: 40,

    xAxis: {
      categories: this.xAxis,
      gridLineDashStyle: 'Dash', // values = 'Solid','ShortDash','ShortDot','ShortDashDot','ShortDashDotDot','Dot','Dash','LongDash','DashDot','LongDashDot','LongDashDotDot'
      gridLineWidth: 1, // width of grid
      gridLineColor: '#e2e2e2', // to apply gridline color
      useLabelHTML: true, // make it to true to apply the styles object
      align: 'left',
      reserveSpace: true,
      style: {
        width: '350px',
        // 'white-space': 'wrap',
        // overflow: 'hidden',
        // 'text-overflow': 'ellipsis',
      }, //  style object
    },
    columnWidth: 40,
    yAxis: { enabled: false, visible: false },
    legend: { enabled: false },
    //dataLabelsInside: true,
    tooltip: {
      useXAxisLabel: true,
      padding: 5,
      shared: false,
      useHTML: true,
      tooltipseperator: ' |',
      hideColor: true,
    },
    dataLabels: true,
    dataLabelsCrop: false,
    dataLabelsOverflow: 'allow',
    scrollbar: {
      max_Axis: 6,
      scrollButtonHide: true,
      scrollBarWidth: 4,
    },
  };
  map = new Map<string, [string]>();
  dspStoreData!: any;
  filterSelectedData: any;
  //dataToFilter: any;
  filterIcon = false;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private router: Router,
    private modalRef: CcModalRef<CancellationExpandedComponent>,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
    this.store.select('cancellationReasonDashboardFilter').subscribe((x) => {
      this.filterSelectedData = x.cancellationReasonDashboardFilter?.filter((res: any) => res.checked);
      //this.dataToFilter = x.cancellationReasonDashboardFilter;
      if (this.filterSelectedData.length) {
        this.filterIcon = true;
        this.mappingChartData(this.filterSelectedData);
      } else {
        this.filterIcon = false;
        let lastCancelReasonFromDash = this.dspUtilsCommonService.getValue(
          dspConstants.DspUtilsServiceVariables.CANCEL_REASONS
        );
        this.mappingChartData(lastCancelReasonFromDash);
      }
      let lastCancelReasonFromDash = this.dspUtilsCommonService.getValue(
        dspConstants.DspUtilsServiceVariables.CANCEL_REASONS
      );
      this.finalData = lastCancelReasonFromDash ? lastCancelReasonFromDash : lastCancelReasonFromDash;
      if (this.finalData && this.finalData?.length === 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
    });
  }
  closeModal() {
    this.modalRef.close();
  }
  cancellationChartClick(event: any) {
    sessionStorage.setItem('cancelReasonLabel', this.data.cancelReasonsArr.get(event.point.category));
    this.router.navigate(['/asset']);
    const element: HTMLElement = document.getElementsByClassName('arrow-position')[0] as HTMLElement;
    element.click();
    this.modalRef.close();
  }
  mappingChartData(chartData: any) {
    this.cancellationChartConfig.groupGap = 100;
    this.cancellationChartConfig.columnWidth = 26;
    this.cancellationChartConfig.height = window.innerHeight - 76 + 'px';
    const chartValue: any = [];
    const chartLabel: any = [];
    chartData.map((chartDataValue: any) => {
      chartValue.push(Number(chartDataValue.value));
      chartLabel.push(chartDataValue.label.replace(',', ''));
      this.map.set(chartDataValue.label.replace(',', ''), chartDataValue.label);
    });
    this.cancellationChartData = { series: [{ data: chartValue, minPointLength: 5 }] };
    this.cancellationChartConfig.xAxis.categories = [...new Set(chartLabel)];
    this.cdr.detectChanges();
  }
  openFilter(type?: string) {
    this.dashboardService.openFilterClickedCancellation.next(true);
  }
}
