export interface BasePlanList {
  label?: string;
  value?: string;
  grade?: any;
  isSubscribable?: boolean;
  price?: string;
  baseSubscription?: string;
}

export interface OptionalPlanList {
  label?: string;
  value?: string;
  isSubscribable?: boolean;
  price?: string;
  serviceName?: string;
  autoSelect?: boolean;
}

export interface ProductFamilyList {
  label?: string;
  value?: string;
}

export interface CountryList {
  label?: string;
  value?: string;
}

export interface AssetSubscription {
  custSubs?: any;
  dealerSubs?: any;
  catSubs?: any;
  custSubsPrev?: any;
  dealerSubsPrev?: any;
  catSubsPrev?: any;
  siteInfo?: any;
  promoInfo?: any;
  templateCheck?: any;
  prepayEligible?: boolean;
  prepaySelected?: boolean;
  activeContract?: boolean;
  contractPeriod?: any;
  subsTitle?: any;
  catLevel?: any;
  dealerLevel?: any;
  selectedCategory?: any;
  dealerCancelReason?: any;
  serviceName?: any;
}

export interface ApplicablePromoParam {
  key: keyof AssetSubscription;
  prevVal?: any;
  prevAddServ?: any;
}

export interface MultiLevelPlans {
  customer: Array<BasePlanList>;
  dealer: Array<BasePlanList>;
  cat: Array<BasePlanList>;
}

export interface ApplicableSubscriptionsType {
  level1: Array<any>;
  level2: Array<any>;
  level3: Array<any>;
}

export interface MineStarSiteSearchResultsType {
  id: string;
  label: string;
  value: any;
}

export interface ProfileBasedAdditionalServices {
  optionList: any;
  selectedCount: number;
  optionListPrev: any;
  chkbxValChng?: boolean;
}
export interface AssetSubscriptionFormDataType {
  isValidForm: boolean;
  expandedService?: string;
  isCustomerChange?: boolean;
  customer?: any;
  service: ServiceType;
  subscription: SubscriptionDetailsType;
  addOns: AddonDetailsType;
  prepay: PrepayDataType;
  catBillingData: BillingInfo;
  productFamily: ProductFamilyData;
  selectedCategory?: string;
  reportingState?: string;
  newPaymentDetails?: any;
  siteInfo: MineStarSiteData;
  previousSiteInfo?: MineStarSiteData;
  selectedSiteInfo: MineStarSiteData;
  cancelOptions?: any;
  catGradeEditSerialInfo?: any;
  enableCloseButton?: boolean;
  products?: any;
}

type ServiceType = {
  currentService: string;
  previousService: string;
  selectedService: string;
};

type SubscriptionDetailsType = {
  currentSubscription: SubscriptionData;
  previousSubscription: SubscriptionData;
  selectedSubscription: SubscriptionData;
};

export type SubscriptionData = {
  catLevel: string;
  dealerLevel: string;
  customerLevel: string;
};

type AddonDetailsType = {
  currentAddons: Array<string>;
  previousddons: Array<string>;
  selectedAddons: Array<string>;
};

type PrepayDataType = {
  currentPrepayData: {
    prepaySelected: boolean;
    contractStartDate: string;
    contractEndDate: string;
  };
  previousPrepayData: {
    prepaySelected: boolean;
    contractStartDate: string;
    contractEndDate: string;
  };
  selectedPrepayData: {
    prepaySelected: boolean;
    contractStartDate: string;
    contractEndDate: string;
  };
};

export type PrepayContractInfo = {
  contractStartDate: string;
  contractEndDate: string;
};

type BillingInfo = {
  billingGroupId: number | string;
};

type ProductFamilyData = {
  currentProductFamily: string;
  previousProductFamily: string;
  selectedProductFamily?: string;
};

export type MineStarSiteData = {
  siteName: string;
  siteId: string;
};

export interface SubsValidationApiPayload {
  assetId: string;
  commercialType: string;
  model: string;
  serviceSubscribed: string;
  prepay: boolean;
  contractStartDate: string;
  contractEndDate: string;
  catLevel: string[];
  customerLevel: string[];
  dealerLevel: string[];
  catPrevLevel: string;
  dealerPrevLevel: string;
  customerPrevLevel: string;
  requestingDealerCode: string;
  ucid: string;
  ucName: string;
  dealerCustomerId: string;
  dcn: string;
  updatedTimeStamp: string;
  billToParty: string;
}
