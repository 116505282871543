import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { CcDrawerRef } from '@cat-digital-workspace/shared-ui-core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetService } from '../../services/asset.service';
import { report } from 'process';
import { CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-report-type-drawer',
  templateUrl: './report-type-drawer.component.html',
  styleUrls: ['./report-type-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportTypeDrawerComponent implements OnInit {
  reportTypeOption: any;
  reportType: any;
  isFetchApiDataLoading = false;
  deviceFeaturesInfo: any;
  submitEnable = false;
  assetInfo: any;
  selectedReportType: any;
  isSubmitBtnClicked = false;

  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: { assetDetails: any },
    public sanitizer: DomSanitizer,
    public dialogRef: CcDrawerRef<ReportTypeDrawerComponent>,
    private store: Store<DSPAppState>,
    public assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.assetInfo = this.header.assetDetails ? cloneDeep(this.header.assetDetails) : {};
    this.store.select('dsp').subscribe((dsp) => {
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
    });
    this.reportTypeOption =
      this.deviceFeaturesInfo &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType] &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType].Report_Type;
    this.reportType = this.assetInfo.reportType
      ? this.assetInfo.reportType
      : this.reportTypeOption?.length && this.reportTypeOption[0];
  }

  saveChanges() {
    this.isSubmitBtnClicked = true;
    this.selectedReportType = this.reportType;
    const code = sessionStorage.getItem('dealer');
    const payload = {
      dealerCode: null,
      serialNumber: this.assetInfo.serialNumber,
      make: this.assetInfo.make,
      reportType: this.reportType,
      assetId: this.assetInfo.assetId,
    };
    if (code) {
      payload.dealerCode = JSON.parse(code).dealerCode;
    }
    this.assetService.updateReportType(payload).subscribe({
      next: (res: any) => {
        this.dialogRef.close(this.reportType);
      },
      error: () => {
        this.dialogRef.close(this.reportTypeOption[0]);
      },
    });
  }

  valueChange(event: any) {
    if (!this.assetInfo.reportType && event?.value == this.reportTypeOption[0]) {
      this.submitEnable = false;
    } else {
      this.submitEnable = this.assetInfo.reportType == event?.value ? false : true;
    }
    this.reportType = event?.value;
  }

  closeReportTypeModal() {
    if (this.isSubmitBtnClicked) {
      this.dialogRef.close(this.selectedReportType);
    } else {
      this.reportType = this.assetInfo.reportType
        ? this.assetInfo.reportType
        : this.reportTypeOption?.length && this.reportTypeOption[0];
      this.dialogRef.close(this.reportType);
    }
  }

  enableSubmit() {
    return !this.submitEnable;
  }
}
