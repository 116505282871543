import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { LegalModalComponent } from './legal-modal/legal-modal.component';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { CookiesModalComponent } from './cookies-modal/cookies-modal.component';
import { TermModalComponent } from './term-modal/term-modal.component';
import { QuickLinksModalComponent } from './quick-links-modal/quick-links-modal.component';
import { CcInputFieldModule } from '@cat-digital-workspace/shared-ui-core/input-field';
import { TermsofsaleModalComponent } from './termsofsale-modal/termsofsale-modal.component';
@NgModule({
  declarations: [
    FooterComponent,
    LegalModalComponent,
    PrivacyModalComponent,
    CookiesModalComponent,
    TermModalComponent,
    QuickLinksModalComponent,
    TermsofsaleModalComponent,
  ],
  imports: [CommonModule, CatButtonModule, CcInputFieldModule],
  exports: [FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class FooterModule {}
