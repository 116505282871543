import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as dspConstants from '../../../../../shared/dspConstants';
@Component({
  selector: 'dsp-next-gen-ui-minestar-product',
  templateUrl: './minestar-product.component.html',
  styleUrls: ['./minestar-product.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MinestarProductComponent {
  dspConstants!: any;
  @Output() redirectSite = new EventEmitter();
  @Input() siteName: any;
  @Output() siteInfo = new EventEmitter();
  @Output() siteChange = new EventEmitter();
  @Input() enableSearchOverlay: any;
  @Input() fetchSiteLoader: any;
  @Input() siteResults: any;
  @Output() handleListItemSelection = new EventEmitter();
  @Input() siteNotFound: any;
  @Input() listOfBasePlans: any;
  @Output() basePlanChange = new EventEmitter();
  @Input() listOfOptionalPlans: any;
  @Output() additionalServicesChng = new EventEmitter();
  @Input() applicableSubscriptions: any;
  @Input() applicableAddlSubscriptions: any;
  @Input() productFamily: any;
  @Input() listOfProductFamily: any;
  @Output() productFamilyChanged = new EventEmitter();
  @Input() manageAssetSubscriptionForm!: FormGroup;
  @Input() subsData!: any;
  @Input() promoLoader = false;
  @Input() isSiteNameLoading = false;
  @Input() modifiedProductFamily = true;
  @Input() customerPlan: any;
  @Input() userActiveFeatures: any;
  @Input() applicablePlansDetails: any;
  @Input() applicableAddOnPlansDetails: any;
  @Output() blurEvent = new EventEmitter();

  ngOnInit(): void {
    this.dspConstants = dspConstants;
  }

  redirectToSitePage() {
    this.redirectSite.emit();
  }
  get formControl() {
    return this.manageAssetSubscriptionForm?.controls;
  }

  getSiteReqInfo(event: any) {
    this.siteInfo.emit(event);
    // Need to check and update the below code changes.
    /* const siteValues: any = [];
    if (this.siteResults.length > 0) {
      this.siteResults.map((site: any) => {
        siteValues.push(site.value);
      });
    }
    if (
      event.errCode == 'noBound' &&
      event.value.length > 2 &&
      this.siteResults.length > 0 &&
      !siteValues.includes(event.value)
    ) {
      this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors({ incorrect: true });
    } else if (event.value.length > 2) {
      this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors(null);
    } */
  }

  onSiteChanged(sitename: any) {
    this.siteChange.emit(sitename);
  }
  listItemClick(item: any) {
    this.handleListItemSelection.emit({ label: item.label, value: item.value, id: item.id });
  }
  onBasePlanChange(event: any, level: string) {
    if (event.action !== 'onLoad') {
      this.basePlanChange.emit({ event, level });
    }
  }
  onOptionalPlanChange(event: any, prop: string) {
    this.additionalServicesChng.emit({ event, prop });
  }
  productFamilyChangedFn(event: any) {
    this.productFamilyChanged.emit(event);
  }

  onBlur(event: any) {
    this.blurEvent.emit(event);
  }
}
