import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as linkActions from '../actions/links.actions';
import { LinksService } from '../../services/links.service';
import { Links } from '../../models/links.interface';

@Injectable()
export class LinkEffects {

  constructor(
    private actions$: Actions,
    private linkService: LinksService
  ) {}

  getQuickLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(linkActions.loadQuickLinksAction),
      exhaustMap(() =>
        this.linkService.getQuickLinks().pipe(
          map((link: Links ) => {   
              return linkActions.loadLinksSuccessAction({link})
            }),
          catchError((error: any) => of(linkActions.loadLinksFailureAction(error))))
      )
    )
  );

  createLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(linkActions.createQuickLinksAction),
      switchMap((action) =>
        this.linkService.addQuickLink(action.body).pipe(
          map((data) => linkActions.createLinksSuccessAction({ successResponse: data })),
          catchError((error) => of(linkActions.createLinksFailureAction({ error: error })))
        )
      )
        );
  });
  

  deleteQuickLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(linkActions.deleteQuickLinksAction),
      switchMap((action) =>
        this.linkService.deleteQuickLink(action.body).pipe(
          map((data) => linkActions.DeleteLinksSuccessAction({ successResponse: data })),
          catchError((error) => of(
            linkActions.DeleteLinksFailureAction({ error: error })))
        )
      )
    );
  });

}

