<div class="cc-modal create-site">
  <div class="cc-modal-header header-first">
    <div class="cc-modal-title top-center-head">Create New Site</div>
    <div class="cc-modal-close" (click)="closeModel(false)">
      <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 17.3411L17 10.3411"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 17.3411L10 10.3411"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="13.5" cy="13.8411" r="12.5" stroke="black" stroke-width="2" />
      </svg>
    </div>
  </div>
  <div class="cc-modal-header header-second">
    <div class="tracker-div" id="tracker">
      <cc-progress-tracker
        #siteCreationTracker
        [activeStepIndex]="activeStepIndex"
        [trackerData]="trackerData"
        [isTheme]="isTheme"
        (changeTracker)="output($event)"
      >
      </cc-progress-tracker>
    </div>
  </div>

  <div class="cc-modal-content">
    <ng-container *ngIf="activeStepIndex === 0">
      <div class="reqField"><span class="bed-rock">*</span> Required Field</div>
      <div class="cc-grid" [formGroup]="createNewSiteForm">
        <div class="cc-col cc-lg-col--7 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
          <div class="cc-grid pad0">
            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
              <div class="create-site-label"><span class="bed-rock">*</span> Site Name</div>
              <cc-input-field
                id="siteName_id"
                [disabled]="false"
                [labelValue]="'Minimum three characters'"
                [inputtype]="'text'"
                [isTableInlineEdit]="true"
                [isTheme]="isTheme"
              >
                <input
                  input-field
                  formControlName="siteName"
                  class="input-field"
                  autocomplete="off"
                  type="text"
                  (valueChange)="checkSiteUniqueness()"
                  aria-labelledby="Enter any value"
                  aria-describedby="Enter any value"
                />

                <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                      fill="#DF3826"
                    />
                  </svg>
                </span>
              </cc-input-field>

              <span class="req-text">
                <ng-container *ngIf="formControl['siteName']?.touched">
                  <span *ngIf="formControl['siteName']?.errors?.['required']">Site Name cannot be empty</span>
                </ng-container>
                <span *ngIf="formControl['siteName']?.errors?.['minlength']"
                  >Site Name must contain atleast 3 characters</span
                >
                <span *ngIf="formControl['siteName']?.errors?.['siteNameExists']">Site name already exists</span>
              </span>
            </div>

            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
              <div class="create-site-label"><span class="bed-rock">*</span> Customer</div>
              <cc-input-field
                [labelValue]="'Search'"
                [inputtype]="'search'"
                [isDense]="true"
                [isTheme]="isTheme"
                [isLeading]="true"
                [value]="customer"
                (valueChange)="customer = $event.value; searchByCustomer($event)"
                [triggerSearchMinChar]="3"
                [enableSearchOverlay]="true"
                [disabled]="customerLoader"
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div class="cc-input-search-menu">
                  <div *ngIf="customerLoader" class="no-result load-container">
                    <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
                  </div>
                  <ng-container *ngIf="searchCustItems && searchCustItems.length > 0">
                    <cc-list [theme]="'secondary'">
                      <cc-list-item
                        *ngFor="let item of searchCustItems; let i = index"
                        class="list-item-pg"
                        (listItemClick)="listItemClick(i)"
                        (listItemEnter)="listItemClick(i)"
                      >
                        <div class="each-item">
                          <div class="txt-name">
                            <span class="label" [innerHTML]="item.label | highlightSearch: customer"></span>
                          </div>
                        </div>
                      </cc-list-item>
                    </cc-list>
                  </ng-container>
                </div>

                <input
                  input-field
                  #locationbox
                  class="input-field"
                  [value]="customer"
                  autocomplete="off"
                  formControlName="customer"
                  type="search"
                  [isFloating]="false"
                  aria-labelledby="Search"
                  aria-describedby="Search"
                  (valueChange)="customer = $event.value"
                  (focus)="onValue($event)"
                />
              </cc-input-field>

              <span class="req-text">
                <ng-container *ngIf="formControl['customer']?.touched">
                  <span *ngIf="formControl['customer']?.errors?.['required']"> Customer Name cannot be empty </span>
                </ng-container>
                <span *ngIf="formControl['customer']?.errors?.['minlength']">
                  Customer Name must contain atleast 3 characters
                </span>
                <span *ngIf="formControl['customer']?.errors?.['siteNameExists']">Customer already exists</span>
                <span *ngIf="customerNotFound">Customer cannot be found</span>
              </span>
            </div>
            <ng-container *ngIf="!isRemoveCwsIDAutoSearch">
              <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
                <div class="create-site-label">CWS ID</div>
                <cc-input-field
                  [labelValue]="'CWS ID'"
                  [inputtype]="'search'"
                  [isDense]="true"
                  [isTheme]="isTheme"
                  [isLeading]="true"
                  [value]="cwsid"
                  (valueChange)="cwsid = $event.value; onCwsidChange($event)"
                  [enableSearchOverlay]="false"
                  [disabled]="cwsIdLoader"
                >
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  <input
                    #cwsIdSearch
                    input-field
                    class="input-field"
                    [value]="cwsid"
                    [(ngModel)]="cwsid"
                    autocomplete="off"
                    formControlName="cwsid"
                    type="search"
                    [isFloating]="false"
                    aria-labelledby="Search"
                    aria-describedby="Search"
                    (valueChange)="cwsid = $event.value; onCwsidChange($event)"
                    [ngClass]="{ invalidCws: cwsIDExists === false }"
                  />
                </cc-input-field>

                <span class="req-text">
                  <ng-container *ngIf="formControl['cwsid']?.touched">
                    <span *ngIf="formControl['cwsid']?.errors?.['required']">CWS ID cannot be empty</span>
                    <span *ngIf="formControl['cwsid']?.errors?.['minlength']"
                      >CWS ID must contain atleast 3 characters</span
                    >
                  </ng-container>
                  <span
                    *ngIf="
                      !cwsIDExists &&
                      !formControl['cwsid']?.errors?.['required'] &&
                      !formControl['cwsid']?.errors?.['minlength']
                    "
                    >CWS ID cannot be found</span
                  >
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="isRemoveCwsIDAutoSearch">
              <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
                <div class="create-site-label">CWS ID</div>
                <cc-input-field
                  [labelValue]="'CWS ID'"
                  [inputtype]="'search'"
                  [isDense]="true"
                  [isTheme]="isTheme"
                  [isLeading]="true"
                  [value]="cwsid"
                  (valueChange)="cwsid = $event.value; onCwsidChange($event)"
                  [enableSearchOverlay]="false"
                  [disabled]="cwsIdLoader"
                >
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                        fill="black"
                      />
                    </svg>
                  </span>

                  <input
                    #cwsIdSearch
                    input-field
                    class="input-field"
                    [value]="cwsid"
                    [(ngModel)]="cwsid"
                    autocomplete="off"
                    formControlName="cwsid"
                    type="search"
                    [isFloating]="false"
                    aria-labelledby="Search"
                    aria-describedby="Search"
                    (blur)="onBlurEvent($event, cwsid)"
                    (keyup.enter)="handleUserSearch(cwsid)"
                    (valueChange)="cwsid = $event.value; onCwsidChange($event)"
                    [ngClass]="{ invalidCws: cwsIDExists === false }"
                  />
                </cc-input-field>

                <span class="req-text">
                  <ng-container *ngIf="formControl['cwsid']?.touched">
                    <span *ngIf="formControl['cwsid']?.errors?.['required']">CWS ID cannot be empty</span>
                  </ng-container>
                  <span *ngIf="formControl['cwsid']?.errors?.['minlength']"
                    >CWS ID must contain atleast 3 characters</span
                  >
                  <span
                    *ngIf="
                      !cwsIDExists &&
                      !formControl['cwsid']?.errors?.['required'] &&
                      !formControl['cwsid']?.errors?.['minlength']
                    "
                    >CWS ID cannot be found</span
                  >
                </span>
              </div>
            </ng-container>

            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
              <div class="create-site-label">Site Description</div>
              <cc-input-field
                id="site_desc_id"
                [disabled]="false"
                [labelValue]="'Enter Description'"
                [inputtype]="'text'"
                [value]="siteDescription"
                [isTableInlineEdit]="true"
                [maxlength]="255"
                [isTheme]="isTheme"
              >
                <input
                  input-field
                  formControlName="siteDescription"
                  [(ngModel)]="siteDescription"
                  class="input-field"
                  autocomplete="off"
                  type="text"
                  aria-labelledby="Enter any value"
                  aria-describedby="Enter any value"
                  [maxlength]="255"
                />
              </cc-input-field>
            </div>

            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
              <div class="create-site-label">Product</div>
              <cc-dropdown
                [id]="'product_id'"
                [label]="'Select'"
                [isTheme]="isTheme"
                [dataSource]="products"
                [placementPosition]="getPartOfControl('placementPosition')"
                (selectionChange)="onDropdownChange($event, 'product'); constructProductPlan($event)"
                formControlName="product"
                [scrollToSelected]="true"
                [selectionOnClose]="true"
              ></cc-dropdown>
            </div>

            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
              <div class="create-site-label">Plan</div>
              <cc-dropdown
                [id]="'plan_id'"
                [label]="'Select'"
                [isTheme]="isTheme"
                [dataSource]="planData"
                [placementPosition]="getPartOfControl('placementPosition')"
                (selectionChange)="onDropdownChange($event, 'plan'); constructService($event)"
                formControlName="plan"
                [scrollToSelected]="true"
                [selectionOnClose]="true"
                [disabled]="!isProductSelected"
              ></cc-dropdown>
            </div>

            <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"></div>

            <div
              *ngIf="addOnServices.length > 0"
              class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"
            >
              <div class="create-site-label">Optional Plans</div>
              <cc-dropdown
                class="addon-service-drop-down"
                [isTheme]="'2.0'"
                [id]="'singlerow-chip'"
                name="checkbox"
                [label]="'Select Optional Plans'"
                [dataSource]="addOnServices"
                [placementPosition]="getPartOfControl('placementPosition')"
                formControlName="additionalService"
                (selectionChange)="selectService($event)"
                [multiple]="true"
                [expanded]="'selected'"
                [singleRowChips]="false"
                [showClearAll]="false"
                [hideSelectAllLabel]="true"
                [dropdownLabels]="{ moreLabel: 'more' }"
                [multipleWithButton]="{ applyLabel: 'Apply', cancelLabel: 'Close' }"
                [scrollToSelected]="true"
                [closeOnCancel]="true"
              ></cc-dropdown>
            </div>
          </div>
        </div>

        <div class="cc-col cc-lg-col--5 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12" style="margin-top: 15px">
          <div class="create-site-label"><span class="bed-rock">*</span> Location</div>

          <div class="create-site-map">
            <cc-maps
              [context]="'FLEET_MAP_CONTEXT'"
              [enableAssetLocation]="true"
              [enableMapLocationAddress]="true"
              [assetMap]="true"
              [mapType]="mapType"
              [markerLocation]="originAsset"
              (mapLocationChange)="mapLocationChange($event)"
              [zoomLevel]="zoomLevel"
              [hideFooter]="true"
            >
              <div>
                <cc-input-field
                  id="location"
                  [disabled]="false"
                  [labelValue]="'Search City / State / Country'"
                  [inputtype]="'search'"
                  [isTheme]="isTheme"
                  [isDense]="true"
                  [value]="location"
                  (valueChange)="location = $event.value; searchLocation($event)"
                  [isLeading]="true"
                >
                  <span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <input
                    #mapSearchText
                    input-field
                    class="input-field"
                    [value]="location"
                    autocomplete="off"
                    formControlName="location"
                    type="search"
                    [isFloating]="false"
                    (valueChange)="location = $event.value"
                    placeholder=""
                    (focus)="onFocusMapEvent()"
                  />
                </cc-input-field>
                <span class="req-text">
                  <ng-container *ngIf="formControl['location']?.touched">
                    <span *ngIf="formControl['location']?.errors?.['required']">Site location cannot be empty</span>
                  </ng-container>
                  <span *ngIf="formControl['location']?.errors?.['minlength']"
                    >Site location must contain atleast 3 characters</span
                  >
                </span>
              </div>
            </cc-maps>
          </div>
        </div>
      </div>
      <div class="info-container">
        <div class="info-title">Dealer Action Required:</div>
        <ol class="ol-info">
          <li>
            The installation and use of this MineStar software requires the Dealer to have a Subscription Purchase
            Agreement which includes Attachment 1 – Software End User Agreement in place with the Customer prior to
            activating the technology for any Customer or Dealer’s commercial use. A copy of the executed MineStar
            Subscription Purchase Agreement must be emailed to
            <a style="color: #0088cc; text-decoration: none" href="mailto:MineStar_Subscription_Agreements@cat.com"
              >MineStar_Subscription_Agreements&#64;cat.com</a
            >
            before submitting your order.
          </li>
          <li>
            The software installation file is only authorized to be used with a valid executed MineStar Subscription
            Purchase Agreement.
          </li>
          <li>
            Caterpillar will perform regular audits to ensure all Dealers and Customers have valid executed MineStar
            Subscription Purchase Agreements on record.
          </li>
          <li>
            Templates for the MineStar Subscription Purchase Agreements can be found
            <a
              href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/cat-minestar-system-support/bronze-subscriptions.html"
              target="_blank"
              >Here</a
            >
          </li>
        </ol>
      </div>
    </ng-container>
    <ng-container *ngIf="payMentPreview && activeStepIndex > 1">
      <div>
        <div class="site-wraper">
          <div class="site-detail-label"><strong>SITE DETAILS</strong></div>
          <div class="site-table">
            <div class="site-col">
              <div><strong>Site Name</strong></div>
              <div style="word-break: break-all">{{ payMentPreview.siteName }}</div>
            </div>
            <div class="site-col">
              <div><strong>Site Location</strong></div>
              <div>{{ payMentPreview.siteLocation }}</div>
            </div>
            <div class="site-col">
              <div><strong>Dealer</strong></div>
              <div>{{ payMentPreview.dealerName }}:{{ payMentPreview.dealerCode }}</div>
            </div>
            <div class="site-col">
              <div><strong>Bill to Party</strong></div>
              <div>{{ payBillingDetails?.billToParty || 'Dealer' }}</div>
            </div>
            <div class="site-col">
              <div><strong>Ownership</strong></div>
              <div style="padding-top: 7px">
                <div style="word-break: break-all"><strong>Customer: </strong>{{ updateDetails('Customer') }}</div>
                <div style="word-break: break-all"><strong>Dealer Customer: </strong>{{ updateDetails('Dealer') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loader">
          <div class="spin-align">
            <div class="spinner"></div>
          </div>
          <div class="spin-text">
            <span>One moment. We are calculating your<br />plan charges...</span>
          </div>
          <div class="spin-text">
            <span><br />Will take a couple of seconds</span>
          </div>
        </div>
        <div *ngIf="!loader">
          <dsp-next-gen-ui-cat-billing
            [payBillingDetails]="payBillingDetails"
            [payMentPreview]="payMentPreview"
            [createSite]="true"
          ></dsp-next-gen-ui-cat-billing>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="cc-modal-footer">
    <button *ngIf="getValidButtonStatus('back')" cc-btn-secondary-outline (click)="backward()" [setTheme]="true">
      Back
    </button>

    <button
      [disabled]="getValidButtonStatus('save') || !cwsIDExists"
      cc-btn-secondary-outline
      (click)="createUpdateMineStarSite('saveSite')"
      [setTheme]="true"
      *ngIf="!isPaymentPage && activeStepIndex === 0"
    >
      Save
    </button>
    <button
      [disabled]="getValidButtonStatus('submit') || !cwsIDExists"
      cc-btn-primary
      (click)="forward(isPaymentPage)"
      [setTheme]="true"
    >
      {{ isPaymentPage ? 'Submit' : isPricingEligible ? 'Next' : 'Submit' }}
    </button>
  </div>
</div>
