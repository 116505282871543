<div>
  <span>
    {{ config?.title }}
    <cc-icon
      cc-tooltip
      class="licenseRequestIcon"
      [tooltipPosition]="'top-left'"
      [template]="tooltipTemplateForSmh"
      [appClass]="'-license-icon'"
      fontSet="icomoon"
      >info-tooltip</cc-icon
    >
  </span>
</div>
<ng-template #tooltipTemplateForSmh>
  <div class="license-info-header-container">
    <span class="license-info-wrapper">
      <cc-status-indicator [type]="'dot'" [statusType]="'success'"></cc-status-indicator>
      <p class="license-info-header-text"><b>INSTALLED :</b> {{ dspWorklistConstants.INSTALLED_STATUS_INFO }}</p>
    </span>
    <span class="license-info-wrapper">
      <cc-status-indicator [type]="'dot'" [statusType]="'info'"></cc-status-indicator>
      <p class="license-info-header-text"><b>SUCCESSFUL :</b> {{ dspWorklistConstants.SUCCESSFUL_STATUS_INFO }}</p>
    </span>
    <span class="license-info-wrapper">
      <cc-status-indicator [type]="'dot'" [statusType]="'warning'"></cc-status-indicator>
      <p class="license-info-header-text"><b>PENDING :</b> {{ dspWorklistConstants.PENDING_STATUS_INFO }}</p>
    </span>
    <span class="license-info-wrapper">
      <cc-status-indicator [type]="'dot'" [statusType]="'error'"></cc-status-indicator>
      <p class="license-info-header-text"><b>UNSUCCESSFUL :</b> {{ dspWorklistConstants.UNSUCCESSFUL_STATUS_INFO }}</p>
    </span>
  </div>
</ng-template>
