<ng-container *ngIf="rowData?.licenseStatus !== '-'; else noData">
  <div class="license-info-icon" *ngIf="rowData?.licenseStatus === dspWorklistConstants.INSTALLED">
    <cc-status-indicator [type]="'dot'" [statusType]="'success'"></cc-status-indicator>
    <span class="license-tooltip-text">{{ rowData?.licenseStatus }}</span>
  </div>
  <div class="license-info-icon" *ngIf="rowData?.licenseStatus === dspWorklistConstants.SUCCESSFUL">
    <cc-status-indicator [type]="'dot'" [statusType]="'info'"></cc-status-indicator>
    <span class="license-tooltip-text">{{ rowData?.licenseStatus }}</span>
  </div>
  <div class="license-info-icon" *ngIf="rowData?.licenseStatus === dspWorklistConstants.UNSUCCESSFUL">
    <cc-status-indicator [type]="'dot'" [statusType]="'error'"></cc-status-indicator>
    <span class="license-tooltip-text">{{ rowData?.licenseStatus }}</span>
  </div>
  <div class="license-info-icon" *ngIf="rowData?.licenseStatus === dspWorklistConstants.PENDING">
    <cc-status-indicator [type]="'dot'" [statusType]="'warning'"></cc-status-indicator>
    <span class="license-tooltip-text">{{ rowData?.licenseStatus }}</span>
    <cc-icon fontSet="icomoon" style="margin-left: 10px; cursor: pointer" (click)="onClickRefresh(rowData)"
      >refresh</cc-icon
    >
  </div>
</ng-container>

<ng-template #noData>
  <span>-</span>
</ng-template>
