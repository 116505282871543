<div class="cc-drawer">
  <div
    class="cc-drawer__header"
    [ngClass]="{
      'cc-drawer__withActions': headerContent === 'withActions'
    }"
  >
    <ng-container *ngIf="headerContent === 'withActions'" [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <div class="accord-info">
      <cc-accordion>
        <div *ngIf="nodata" class="no-results-found">
          <section class="img-container">
            <div class="img-container__img">
              <img alt="sourceimage" class="no-instance-image" src="../../../assets/images/nofilter.svg " />
            </div>
            <p class="no-results-text">No Filters Available</p>
          </section>
        </div>
        <!--<cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">Range&nbsp;&nbsp;</span>
            </div>
          </cc-expandable-header>

          <div style="word-break: break-all">
            <cc-radio-group (valueChange)="rangeOptionClick($event)" class="radio-group">
              <cc-radio-button
                *ngFor="let filter of rangeListFilterData"
                [value]="filter.rangeOption"
                [checked]="
                  savedFilters &&
                  savedFilters.rangeOption &&
                  savedFilters.rangeOption.indexOf(filter.rangeOption) !== -1
                    ? true
                    : filter.checked
                "
              >
                <span>{{ filter.rangeOption }}</span>
              </cc-radio-button>
            </cc-radio-group>
          </div>
          <div *ngIf="selectedRangeOption === 'Custom Range'">
            <cc-slider
              class="example-margin"
              [disabled]="false"
              [disableSliderCrossOver]="true"
              [max]="maxValue"
              [min]="1"
              [color]="'primary'"
              [minSelected]="minValue"
              [maxSelected]="midValue"
              [isRangeSlider]="true"
              [isPlusAdded]="false"
              [disabled]="false"
              (valueChange)="onRangeSliderValueGet($event)"
            ></cc-slider>
          </div>
        </cc-expandable>-->
        <ng-container *ngIf="isShowSubscribeFilterOnExpand">
          <cc-expandable *ngIf="!nodata" (opened)="openExpandMonth()" (closed)="selectedChipsMonth()">
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Month&nbsp;&nbsp;</span>
                <span class="selectedLabel" *ngIf="selectedMonthFilters && selectedMonthFilters.length > 0">
                  ( {{ selectedMonthFilters.length }} selected)
                </span>
              </div>
              <div class="chipList">
                <ng-container *ngFor="let filter of selectedMonthFilters">
                  <span
                    *ngIf="isAccordionClose"
                    class="chips"
                    [cc-chip]="filter"
                    (deleteItem)="onDeleteChip(filter)"
                    [type]="'cross'"
                  ></span>
                </ng-container>
              </div>
            </cc-expandable-header>
            <!-- <div class="filter-checkbox-label">
            <cc-checkbox
              [checked]="masterSelected"
              [indeterminate]="indeterminate"
              (toggleChange)="checkUncheckAllFilters($event)"
              >Select All</cc-checkbox
            >
          </div> -->
            <ng-container *ngFor="let filter of monthListFilterData?.slice(0, 8)">
              <div style="word-break: break-all">
                <cc-checkbox
                  *ngIf="savedFilters && savedFilters.month && savedFilters.month.indexOf(filter.month) !== -1"
                  [checked]="filter.checked"
                  [disabled]="false"
                  (toggleChange)="selectedMonthFilter($event, filter)"
                >
                  {{ filter.month }}
                </cc-checkbox>
                <cc-checkbox
                  *ngIf="!savedFilters.month || savedFilters.month.indexOf(filter.month) === -1"
                  [checked]="
                    (!savedFilters.month || savedFilters.month.indexOf(filter.month) === -1) && flag
                      ? false
                      : filter.checked
                  "
                  (toggleChange)="selectedMonthFilter($event, filter)"
                >
                  {{ filter.month }}
                </cc-checkbox>
              </div>
            </ng-container>
            <div
              class="category-show-all"
              *ngIf="monthListFilterData && monthListFilterData.length > 8"
              (click)="showAll('withActions', monthListFilterData, 'Month')"
            >
              Show All
            </div>
          </cc-expandable>
        </ng-container>
        <ng-container *ngIf="!isShowSubscribeFilterOnExpand">
          <cc-expandable *ngIf="!nodata">
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Month&nbsp;&nbsp;</span>
                <span class="selectedLabel" *ngIf="selectedMonthFilters && selectedMonthFilters.length > 0">
                  ( {{ selectedMonthFilters.length }} selected)
                </span>
              </div>
            </cc-expandable-header>
            <!-- <div class="filter-checkbox-label">
            <cc-checkbox
              [checked]="masterSelected"
              [indeterminate]="indeterminate"
              (toggleChange)="checkUncheckAllFilters($event)"
              >Select All</cc-checkbox
            >
          </div> -->
            <ng-container *ngFor="let filter of monthListFilterData?.slice(0, 8)">
              <div style="word-break: break-all">
                <cc-checkbox
                  *ngIf="savedFilters && savedFilters.month && savedFilters.month.indexOf(filter.month) !== -1"
                  [checked]="filter.checked"
                  [disabled]="false"
                  (toggleChange)="selectedMonthFilter($event, filter)"
                >
                  {{ filter.month }}
                </cc-checkbox>
                <cc-checkbox
                  *ngIf="!savedFilters.month || savedFilters.month.indexOf(filter.month) === -1"
                  [checked]="
                    (!savedFilters.month || savedFilters.month.indexOf(filter.month) === -1) && flag
                      ? false
                      : filter.checked
                  "
                  (toggleChange)="selectedMonthFilter($event, filter)"
                >
                  {{ filter.month }}
                </cc-checkbox>
              </div>
            </ng-container>
            <div
              class="category-show-all"
              *ngIf="monthListFilterData && monthListFilterData.length > 8"
              (click)="showAll('withActions', monthListFilterData, 'Month')"
            >
              Show All
            </div>
          </cc-expandable>
        </ng-container>
        <ng-container *ngIf="isShowSubscribeFilterOnExpand">
          <cc-expandable *ngIf="!nodata" (opened)="openExpandProduct()" (closed)="selectedChipsProuct()">
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Product&nbsp;&nbsp;</span>
                <span class="selectedLabel" *ngIf="selectedProductFilters && selectedProductFilters.length > 0">
                  ( {{ selectedProductFilters.length }} selected)
                </span>
              </div>
              <div class="chipList">
                <ng-container *ngFor="let filter of selectedProductFilters">
                  <span
                    *ngIf="isAccordionCloseProduct"
                    class="chips"
                    [cc-chip]="filter"
                    (deleteItem)="onDeleteChipProduct(filter)"
                    [type]="'cross'"
                  ></span>
                </ng-container>
              </div>
            </cc-expandable-header>
            <ng-container *ngFor="let filter of productListFilterData?.slice(0, 8)">
              <div style="word-break: break-all">
                <cc-checkbox
                  *ngIf="
                    savedFilters && savedFilters.application && savedFilters.application.indexOf(filter.product) !== -1
                  "
                  [checked]="filter.checked"
                  [disabled]="false"
                  (toggleChange)="selectedProductFilter($event, filter)"
                >
                  {{ filter.product }}
                </cc-checkbox>
                <cc-checkbox
                  *ngIf="!savedFilters.application || savedFilters.application.indexOf(filter.product) === -1"
                  [checked]="filter.checked"
                  [disabled]="disableCheckbox(filter)"
                  (toggleChange)="selectedProductFilter($event, filter)"
                >
                  {{ filter.product }}
                </cc-checkbox>
              </div>
            </ng-container>
            <div
              class="category-show-all"
              *ngIf="productListFilterData && productListFilterData.length > 8"
              (click)="showAll('withActions', productListFilterData, 'Product')"
            >
              Show All
            </div>
          </cc-expandable>
        </ng-container>
        <ng-container *ngIf="!isShowSubscribeFilterOnExpand">
          <cc-expandable *ngIf="!nodata">
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Product&nbsp;&nbsp;</span>
                <span class="selectedLabel" *ngIf="selectedProductFilters && selectedProductFilters.length > 0">
                  ( {{ selectedProductFilters.length }} selected)
                </span>
              </div>
            </cc-expandable-header>
            <ng-container *ngFor="let filter of productListFilterData?.slice(0, 8)">
              <div style="word-break: break-all">
                <cc-checkbox
                  *ngIf="
                    savedFilters && savedFilters.application && savedFilters.application.indexOf(filter.product) !== -1
                  "
                  [checked]="filter.checked"
                  [disabled]="false"
                  (toggleChange)="selectedProductFilter($event, filter)"
                >
                  {{ filter.product }}
                </cc-checkbox>
                <cc-checkbox
                  *ngIf="!savedFilters.application || savedFilters.application.indexOf(filter.product) === -1"
                  [checked]="filter.checked"
                  [disabled]="disableCheckbox(filter)"
                  (toggleChange)="selectedProductFilter($event, filter)"
                >
                  {{ filter.product }}
                </cc-checkbox>
              </div>
            </ng-container>
            <div
              class="category-show-all"
              *ngIf="productListFilterData && productListFilterData.length > 8"
              (click)="showAll('withActions', productListFilterData, 'Product')"
            >
              Show All
            </div>
          </cc-expandable>
        </ng-container>
      </cc-accordion>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button
      cc-btn-primary
      cc-btn-large
      class="primary button-opacity viewSitesBtn"
      [setTheme]="true"
      (click)="applyAllFilter()"
    >
      Apply
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <span class="cc-drawer__ellipsis filters-title"> Filters </span>
  <span class="filters-clear" (click)="clearFilters()">Clear All</span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal(clearAll)">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
