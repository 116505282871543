import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Common } from '../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import {
  MessageBar,
  DrawerService,
  MessageBarConfig,
  CcDrawerSize,
  CcDrawerConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { Observable, Subscription } from 'rxjs';
import { AssetSubscriptionFormDataType } from '../../../models/assets.interface';
import { dealerType } from '../../../models/shared.interface';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { LoaderService } from '../../../services/loader.service';
import { AssetService } from '../../assets/services/asset.service';
import { cloneDeep, isEmpty } from 'lodash-es';
import { DcnListComponent } from '../../assets/manage-asset-subscription/dcn-list/dcn-list.component';
import { PendingRequestFormData } from '../self-serve.interface';

@Component({
  selector: 'dsp-next-gen-ui-self-serve-select-ownership',
  templateUrl: './self-serve-select-ownership.component.html',
  styleUrl: './self-serve-select-ownership.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SelfServeSelectOwnershipComponent {
  @Input() rowData: any;
  @Input() pendingRequestFormData!: PendingRequestFormData;
  @Input() launchType: any;
  @Input() hasPartialOwnershipData: any;
  @Output() retrieveSyncedOwnership = new EventEmitter();
  @Input() syncEvents!: Observable<void>;
  dealer!: dealerType;
  syncEventsSubscription!: Subscription;
  assetOwners: any = [];
  datToastMsgConfig: any[] = [];
  edUrl = '';
  datUrl = '';
  systemError = Common.SYSTEM_ERROR;
  ownershipSystemError = Common.OWNERSHIP_SYSTEM_ERROR;
  noActiveSyncError = Common.NO_ACTIVE_SYNC_ERROR;
  selectedOwner!: any;
  dspConfig: any;
  dcnListDrawerRef: any;
  enableEquipmentData = false;
  dspStoreData!: any;
  enableOwnershipSync!: any;
  userActiveFeatures!: any;

  constructor(
    private store: Store<DSPAppState>,
    private messageBar: MessageBar,
    private assetService: AssetService,
    private loaderService: LoaderService,
    public assetDrawerService: AssetDrawerService,
    private drawerService: DrawerService,
    public changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.enableOwnershipSync = this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        ? this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.edUrl = dsp.edUrl?.data;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspConfig = dsp.dspConfig || {};
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.syncEventsSubscription = this.syncEvents?.subscribe(() => this.setAssetOwnershipData());
    this.setAssetOwnershipData();
  }

  enableSyncOwnership() {
    return this.assetService.validateOwnershipSync(this.userActiveFeatures, this.rowData, this.enableOwnershipSync);
  }

  initiateSync() {
    const ownershipSyncPayload = {
      assetIds: this.rowData.assetId,
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.initOwnershipSync(ownershipSyncPayload).subscribe({
      next: (result: any) => {
        this.rowData.ownershipDetails = [];
        this.retrieveSyncedOwnership.emit();
      },
      error: (err: any) => {
        if (err.status == 409) {
          this.showToastMessage(this.noActiveSyncError, 'info', undefined, true);
        } else {
          this.showToastMessage(this.ownershipSystemError, 'error', undefined, true);
        }
      },
    });
  }

  setAssetOwnershipData() {
    if (!isEmpty(this.rowData?.ownershipDetails) && !this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.assetOwners.forEach((owner: any) => {
        owner.disabled = false;
      });
      this.pendingRequestFormData.isValidForm = false;
    } else if (!isEmpty(this.rowData?.ownershipDetails) && this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.pendingRequestFormData.customer = this.selectedOwner[0];
      this.pendingRequestFormData.isValidForm = true;
      this.checkDatStatus(this.selectedOwner[0]);
    }
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }

  isOwnerSelected() {
    this.selectedOwner = this.rowData.ownershipDetails.filter((owner: any) => {
      return owner.checked;
    });

    return !isEmpty(this.selectedOwner) ? true : false;
  }
  valueChange(data: any) {
    const ownerChecked = cloneDeep(data?.checked);
    if (data && data !== 'None') {
      this.assetOwners.map((owner: any) => {
        if (data.ownershipId === owner.ownershipId && !ownerChecked) {
          owner.checked = true;
        } else {
          owner.checked = false;
        }
      });
      if (!ownerChecked) {
        if (this.checkDealerIsNotEmpty(data)) {
          this.pendingRequestFormData.customer = data;
          this.checkDatStatus(data);
        } else {
          this.pendingRequestFormData.isValidForm = false;
          this.fetchDCNList(data);
        }
      } else {
        this.pendingRequestFormData.customer = {};
        this.pendingRequestFormData.isValidForm = false;
      }
    }
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }

  fetchDCNList(owner: any) {
    this.assetDrawerService.getDCNListInfo(owner).subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.openDCNListDrawer(owner, response);
        } else {
          owner.disabled = true;
          owner.checked = false;
          const messageText = Common.DCN_ERROR;
          this.rowData.hideDCN = false;
          this.rowData = { ...this.rowData };
          this.showToastMessage(messageText, 'error', undefined, true);
          this.pendingRequestFormData.isValidForm = false;
        }
      },
      error: (_err: any) => {
        owner.checked = false;
        const messageText = Common.DCN_ERROR;
        this.showToastMessage(messageText, 'error', undefined, true);
        this.pendingRequestFormData.isValidForm = false;
      },
    });
  }
  openDCNListDrawer(owner: any, response: any) {
    this.dcnListDrawerRef = this.drawerService.openDrawer<DcnListComponent, CcDrawerConfig>(DcnListComponent, {
      data: {
        headerType: 'Select Dealer Customer',
        dcnList: response,
        owner: owner,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.dcnListDrawerRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.assetOwners.forEach((owner: any) => {
          if (owner.ownershipId === res.ownershipId) {
            owner.dealerCustNo = res.dealerCustNo;
            owner.dealerCustName = res.dealerCustName;
            owner.dealerCode = this.dealer.dealerCode;
            this.pendingRequestFormData.customer = owner;
            this.pendingRequestFormData.isValidForm = true;
            this.checkDatStatus(owner);
          }
        });
      } else {
        if (this.dcnListDrawerRef.config.data.owner) {
          this.dcnListDrawerRef.config.data.owner.checked = false;
          this.pendingRequestFormData.isValidForm = false;
        }
      }
      this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
    });
  }
  checkDealerDataLength(dealerCustNo: any, dealerCustName: any) {
    if (!isEmpty(dealerCustNo && dealerCustName)) {
      let dealerData = dealerCustNo + ' - ' + dealerCustName;
      if (dealerData.length >= 27) {
        dealerData = dealerData.slice(0, 26) + '...';
      }
      return 'Dealer Customer: ' + dealerData;
    } else {
      return;
    }
  }
  checkDealerIsNotEmpty(owner: any) {
    if (!isEmpty(owner.dealerCustNo && owner.dealerCustName)) return true;
    else return false;
  }
  checkCustomerDataLength(customerCode: any, customerName: any) {
    let customerData = customerCode + ' - ' + customerName;
    if (customerData.length >= 33) {
      customerData = customerData.slice(0, 32) + '...';
    }
    return customerData;
  }
  checkDatStatus(data: any) {
    if (data?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE') {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined authorization. In order to enable subscription management, authorize through the';
      this.showToastMessage(messageText, 'error', linkDetail);
      this.rowData.hideDCN = false;
      this.rowData = { ...this.rowData };
      this.pendingRequestFormData.isValidForm = false;
    } else {
      this.rowData.hideDCN = false;
      this.rowData = { ...this.rowData };
      this.pendingRequestFormData.isValidForm = true;
    }
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }

  showToastMessage(message: string, status: string, linkDetail: any, autoCloseNeeded?: boolean) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-pending-request',
    };

    if (autoCloseNeeded) {
      config.duration = 3000;
    }
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  ngOnDestroy() {
    this.syncEventsSubscription?.unsubscribe();
  }
}
