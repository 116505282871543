import { NEEDSREVIEWOVERLAYDATA, OVERLAYDATA } from './needs-review-constant';
import { AssetInfoComponent } from '../../../../apps/dsp-ui/src/app/components/assets/asset-table-details/asset-info/asset-info.component';
import { AssetProductPlanComponent } from '../../../../apps/dsp-ui/src/app/components/assets/asset-table-details/asset-product-plan/asset-product-plan.component';
import { HighlightSearchPipe } from '@cat-digital-workspace/shared-ui-core';
import { DisplayTimeZoneComponent } from 'apps/dsp-ui/src/app/shared/components/display-time-zone/display-time-zone.component';
import { AssetDatComponent } from '../../../../apps/dsp-ui/src/app/components/assets/asset-table-details/asset-dat/asset-dat.component';

export const ColumnConfig: any = [
  {
    name: 'serialNumber',
    title: 'Assets',
    priority: 1,
    width: 290,
    isFrozen: true,
    isAlwaysMandatory: true,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    component: AssetInfoComponent,
  },
  {
    name: 'eventDate',
    title: 'Event Date & Time (UTC)',
    width: 260,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    isFrozen: false,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: NEEDSREVIEWOVERLAYDATA,
    component: DisplayTimeZoneComponent,
  },
  {
    name: 'ucid',
    title: 'Customer ID',
    width: 170,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    component: AssetProductPlanComponent,
  },
  {
    name: 'ucidName',
    title: 'Customer Name',
    width: 170,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    component: AssetProductPlanComponent,
  },

  {
    name: 'dealerCustomerNumber',
    title: 'Dealer Customer Number',
    isFrozen: false,
    width: 220,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    render: (_column: any, rowdata: any) => {
      const highlightSearch = new HighlightSearchPipe();
      const config = ColumnConfig.find((config: any) => config?.name === _column);
      if (config && config.customData?.searchString) {
        return `<span>${highlightSearch.transform(
          rowdata.dealerCustomerNumber,
          config.customData?.searchString
        )}</span>`;
      } else {
        return rowdata.dealerCustomerNumber;
      }
    },
  },
  {
    name: 'dcnName',
    title: 'Dealer Customer',
    width: 200,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    component: AssetProductPlanComponent,
  },
  {
    name: 'deviceType',
    title: 'Device Type',
    width: 180,
    formControl: 'span',
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },
  {
    name: 'networkManagerSerialNumber',
    title: 'Device S/N',
    width: 180,
    isAlwaysMandatory: false,
    isFrozen: false,
    isVisible: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    render: (_column: any, rowdata: any) => {
      const highlightSearch = new HighlightSearchPipe();
      const config = ColumnConfig.find((config: any) => config?.name === _column);
      if (config && config.customData?.searchString) {
        return `<span>${highlightSearch.transform(
          rowdata.networkManagerSerialNumber,
          config.customData?.searchString
        )}</span>`;
      } else {
        return rowdata.networkManagerSerialNumber;
      }
    },
  },

  {
    name: 'cellularRadioType',
    title: 'Cellular Radio Type',
    width: 180,
    formControl: 'span',
    isAlwaysMandatory: false,
    isVisible: false,
    isFrozen: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },
  {
    name: 'satelliteRadioType',
    title: 'Satellite Radio Type',
    width: 180,
    formControl: 'span',
    isAlwaysMandatory: false,
    isVisible: false,
    isFrozen: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },
  {
    name: 'cellularRadioSerialNumber',
    title: 'Cellular Radio S/N',
    width: 180,
    isAlwaysMandatory: false,
    isVisible: false,
    isFrozen: false,
    sortingEnable: true,
    ascendingSort: false,
    render: (_column: any, rowdata: any) => {
      const highlightSearch = new HighlightSearchPipe();
      const config = ColumnConfig.find((config: any) => config?.name === _column);
      if (config && config.customData?.searchString) {
        return `<span>${highlightSearch.transform(
          rowdata.cellularRadioSerialNumber,
          config.customData?.searchString
        )}</span>`;
      } else {
        return rowdata.cellularRadioSerialNumber;
      }
    },
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
  },

  {
    name: 'satelliteRadioSerialNumber',
    title: 'Satellite Radio S/N',
    width: 180,
    isAlwaysMandatory: false,
    isVisible: false,
    isFrozen: false,
    sortingEnable: true,
    ascendingSort: false,
    sortWithOverlay: true,
    overlayData: OVERLAYDATA,
    render: (_column: any, rowdata: any) => {
      const highlightSearch = new HighlightSearchPipe();
      const config = ColumnConfig.find((config: any) => config?.name === _column);
      if (config && config.customData?.searchString) {
        return `<span>${highlightSearch.transform(
          rowdata.satelliteRadioSerialNumber,
          config.customData?.searchString
        )}</span>`;
      } else {
        return rowdata.satelliteRadioSerialNumber;
      }
    },
  },
];
