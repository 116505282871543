export const OVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort A to Z',
  },
  {
    name: 'dsc',
    title: 'Sort Z to A',
  },
];

export const COUNTOVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort Smallest to Largest',
  },
  {
    name: 'dsc',
    title: 'Sort Largest to Smallest',
  },
];
export const DetailedInvoiceColumnsList = {
  assetSerialNumber: [],
  application: [],
  customer: [],
  ucid: [],
  dcn: [],
  dcnName: [],
  siteName: [],
  make: [],
  assetModel: [],
};
export const INVOICECOUNTOVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort Smallest to Largest',
  },
  {
    name: 'dsc',
    title: 'Sort Largest to Smallest',
  },
];
