<div class="container" *ngIf="displayInfo?.creditMemos && displayInfo.creditMemos?.length > 0">
  <div class="header">
    <div class="item">Credit Memo</div>
    <div class="item">Credit Memo Date</div>
    <div class="item">Credit Memo Total</div>
  </div>
  <div class="content">
    <div class="item-row" *ngFor="let data of displayInfo?.creditMemos">
      <div class="item" style="display: flex; flex-direction: row">
        <span [innerHTML]="data.creditMemoNumber | highlightSearch: customCellSearch"></span>
        <span class="credit-memo-items download-container">
          <div
            class="menu-content"
            cc-menu
            [template]="singleSelectTemplate"
            [OverlayPosition]="'bottomright'"
            [label]="'Title'"
            *ngIf="!data?.isSelected; else downloadLoader"
          >
            <div
              class="ellipse-container"
              id="cc-menu1"
              cc-tooltip="Export"
              (click)="onClickMemo(data.creditMemoNumber)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.16626 6.66699H8.83293V12.3813H6.16626V6.66699Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.49935 16.667L10.8327 10.9527H4.16602L7.49935 16.667Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.58333 20H14.25C15.1208 20 15.8333 19.2742 15.8333 18.3871V7.95161C15.8333 7.51613 15.675 7.1129 15.3742 6.80645L9.15167 0.467742C8.85083 0.16129 8.455 0 8.0275 0H1.58333C0.7125 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.7125 20 1.58333 20ZM0.632324 1.61325C0.632324 1.08099 1.05982 0.645508 1.58232 0.645508H8.02649C8.27982 0.645508 8.51732 0.742282 8.69149 0.93583L14.914 7.27454C15.104 7.45196 15.199 7.69389 15.199 7.95196V18.3874C15.199 18.9197 14.7715 19.3552 14.249 19.3552H1.58232C1.05982 19.3552 0.632324 18.9197 0.632324 18.3874V1.61325Z"
                  fill="black"
                />
              </svg>
              <!-- <span class="file-arrow">
                <svg
                  _ngcontent-psg-c182=""
                  width="10"
                  height="22"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ng-star-inserted"
                >
                  <path
                    _ngcontent-psg-c182=""
                    d="M8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L8.64645 0.646447ZM5 5L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355L5 5ZM0.646446 1.35355C0.451184 1.15829 0.451184 0.841709 0.646446 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L0.646446 1.35355ZM9.35355 1.35355L5.35355 5.35355L4.64645 4.64645L8.64645 0.646447L9.35355 1.35355ZM4.64645 5.35355L0.646446 1.35355L1.35355 0.646447L5.35355 4.64645L4.64645 5.35355Z"
                    fill="#43485C"
                  ></path>
                </svg>
              </span> -->
            </div>
          </div>
          <ng-template #downloadLoader>
            <div class="loader-container">
              <div class="loader-container__label"></div>
              <div>
                <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                    fill="#43485C"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                    fill="#43485C"
                  />
                </svg>
              </div>
            </div>
          </ng-template>
        </span>
      </div>
      <div class="item">{{ data.creditMemoDate | date: 'MM/dd/yyyy' }}</div>
      <div class="item">{{ data.creditMemoAmount }}</div>
      <ng-template #singleSelectTemplate>
        <div class="cc-contextual-menu" id="cc-single-menu1">
          <cc-list [theme]="'secondary'" width="1000px">
            <cc-list-item *ngFor="let item of listItems" (listItemClick)="onListItemClick(data, item.label)">
              {{ item.label }}
            </cc-list-item>
          </cc-list>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="divider"></div>
