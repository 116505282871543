<div class="cc-drawer site-details">
  <div class="cc-drawer__header">
    <span class="cc-headerText cc-drawer__ellipsis"> Site Details </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon">
        <img alt="alt img text" class="cc-close-semi" src="../../assets/images/close-icon.png" (click)="closeModal()" />
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <dsp-next-gen-ui-site-info [siteData]="data" [displayType]="'info'"></dsp-next-gen-ui-site-info>
    <div *ngIf="subscribeAssetsData?.length > 0" class="table-container asset-detail-table">
      <cc-table
        [ngClass]="{ disable: isTableLoading }"
        aria-describedby="SubscribeAssets Table"
        [tableConfig]="subcribeAssetTableConfig"
        [tableData]="subscribeAssetsData"
        (ccTableEvents)="tableEvents($event)"
        [isDataLoading]="isTableLoading"
      >
        <cc-table-column
          name="serialNumber"
          title="Asset S/N"
          [minWidth]="300"
          [maxWidth]="300"
          [priority]="1"
          [sortWithOverlay]="true"
          [ascendingSort]="true"
          [sortingEnable]="true"
          [overlayData]="overlayData"
          [isVisible]="true"
          contentType="span"
        >
          <ng-template let-column="column" cc-table-header-template>
            <span class="header-cell-content">{{ column.title }}</span>
          </ng-template>
          <ng-template let-rowdata="rowdata" let-value="value" cc-table-cell-template>
            <span
              [toolTipCloseOnClick]="true"
              [toolTipInteractive]="true"
              [tooltipPosition]="'top'"
              [isEllipseEnabled]="true"
              cc-tooltip="{{ rowdata.serialNumber }}"
              class="action"
            >
              {{ rowdata.serialNumber }}
            </span>
          </ng-template>
        </cc-table-column>

        <cc-table-column
          name="productPlan"
          title="Product | Plan"
          [minWidth]="350"
          [maxWidth]="350"
          [priority]="2"
          [isVisible]="true"
          contentType="span"
        >
          <ng-template let-column="column" cc-table-header-template>
            <span class="header-cell-content">{{ column.title }}</span>
          </ng-template>

          <ng-template let-rowdata="rowdata" let-value="value" cc-table-cell-template>
            <div class="product-plan">
              <span
                *ngIf="rowdata.applicationName && rowdata.subscription"
                [toolTipCloseOnClick]="true"
                [toolTipInteractive]="true"
                [tooltipPosition]="'bottom'"
                [isEllipseEnabled]="true"
                cc-tooltip="{{ rowdata.applicationName }} | {{ rowdata.subscription?.split('|')[0]?.trim() }}"
                class="action"
                dspNextGenUiCopy
                [fullText]="rowdata.applicationName + '|' + rowdata.subscription?.split('|')[0]?.trim()"
                >{{ rowdata.applicationName }}<span> | </span>{{ rowdata.subscription?.split('|')[0]?.trim() }}</span
              >
              <span
                *ngIf="
                  isMultiProductWorkFlow &&
                  rowdata.applicationName &&
                  rowdata.subscription &&
                  rowdata.subscribedProducts &&
                  rowdata.subscribedProducts >= 2
                "
                ><a class="link" (click)="openDrawerInMultiSize(rowdata)"> See more </a></span
              >
            </div>
          </ng-template>
        </cc-table-column>

        <cc-table-column
          name="deviceType"
          title="Device Type"
          [minWidth]="300"
          [maxWidth]="300"
          [priority]="3"
          [isVisible]="true"
          [sortWithOverlay]="true"
          [ascendingSort]="true"
          [sortingEnable]="true"
          [overlayData]="overlayData"
          contentType="span"
        >
          <ng-template let-column="column" cc-table-header-template>
            <span class="header-cell-content">{{ column.title }}</span>
          </ng-template>
          <ng-template let-rowdata="rowdata" let-value="value" cc-table-cell-template>
            {{ rowdata.deviceType }}
          </ng-template>
        </cc-table-column>

        <cc-table-column
          name="actions"
          title="Actions"
          [minWidth]="200"
          [maxWidth]="200"
          [priority]="4"
          [isVisible]="true"
          [isPrimaryRight]="true"
          [isRowAction]="true"
          class="update-list"
          [rowActionData]="assetActionRowData"
        >
          <ng-template let-column="column" cc-table-header-template>
            <span class="header-cell-content">{{ column.title }}</span>
          </ng-template>
          <ng-template let-rowdata="rowdata" let-value="value" cc-table-cell-template> </ng-template>
        </cc-table-column>
      </cc-table>
    </div>
  </div>
</div>
