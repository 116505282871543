import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dsp-next-gen-ui-mobile-app-download',
  templateUrl: './mobile-app-download.component.html',
  styleUrls: ['./mobile-app-download.component.scss'],
})
export class MobileAppDownloadComponent implements OnInit {
  modalTitle = '';
  modalBodyMessage = '';
  modalFooterMessage = '';
  link = '';
  isDarkMode = false;

  constructor(
    public sanitizer: DomSanitizer,
    public modalRef: CcModalRef<MobileAppDownloadComponent>,
    @Inject(CC_MODAL_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.modalBodyMessage = this.modalData.message;
    this.modalTitle = this.modalData.title;
    this.link = this.modalData.link;
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.isDarkMode = true;
     
    }
  }

  RedirectToMobileDownload() {
    window.open(this.link, '_blank');
    return;
  }

  closeMobileDownloadModal() {
    this.modalRef.close();
  }
}
