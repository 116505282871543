import { Component, Inject, OnInit } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core/modal';

@Component({
  selector: 'dsp-next-gen-ui-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<ConfirmationModalComponent>
  ) {}

  ngOnInit() {}
  onClickCrossIcon() {
    this.modalRef.close();
  }
  onClickCancel() {
    this.modalRef.close('Modal Closed');
    // got to search service
  }
  onClickSubmit(btnType: string) {
    // Call the final activate api
    this.modalRef.close(btnType);
    // got to search service
  }
}
