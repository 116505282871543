<div class="accord-info">
  <cc-accordion>
    <cc-expandable
      *ngFor="let filter of filterColumns"
      [noExpandableContent]="filter.subFilters"
      (click)="emitSubFilterData(filter.subFilters, filter.label)"
    >
      <cc-expandable-header>
        <div class="expandable-header-title">
          <span class="title-display">{{ filter.label }} </span>
          <cc-icon
            *ngIf="filter.subFilters"
            class="drawer-icon"
            [ngClass]="filter.subFilters ? 'sub-filters' : ''"
            [iconName]="'accordion-expandable-icon'"
            [inline]="true"
          >
          </cc-icon>
        </div>
      </cc-expandable-header>
      <ng-container *ngFor="let data of filterData[filter.field]?.slice(0, 8)">
        <div style="word-break: break-all">
          <cc-checkbox
            [checked]="filter.selectedValues.indexOf(data) > -1"
            (toggleChange)="getSelectedFilter($event, filter, data)"
            >{{ data }}</cc-checkbox
          >
        </div>
      </ng-container>
      <ng-container *ngIf="filter.formControl === 'date'">
        <dsp-next-gen-ui-date-time></dsp-next-gen-ui-date-time>
      </ng-container>
      <div
        class="category-show-all"
        *ngIf="filterData[filter.field]?.length > 8"
        (click)="showAllData('withActions', filterData, filter)"
      >
        Show All
      </div>
    </cc-expandable>
  </cc-accordion>
</div>
