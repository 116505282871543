<div class="cc-grid">
  <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
    <div class="quick-links-header">
      Quick Links
      <span class="button">
        <button cc-btn-primary cc-btn-large [disabled]="disableLink" (click)="addLink()" [setTheme]="true">
          Add Link
        </button></span
      >
    </div>
  </div>
  <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 column-list">
    <div class="list">
      <div class="cc-grid">
        <span
          [toolTipCloseOnClick]="true"
          [toolTipInteractive]="true"
          [tooltipPosition]="'bottom'"
          id="regular-chips"
          class="chips"
          cc-tooltip=""
          cc-chip=""
          type="cross"
          [valid]="true"
          state="default"
          style="display: none"
        ></span>
        <ng-container *ngFor="let item of quickLinks; let i = index">
          <div class="cc-col cc-lg-col--4 cc-md-col-4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
            <div class="cc-cross-chip" id="listItem-{{ item.id }}">
              <div
                tabindex="1"
                class="cc-cross-chips"
                [toolTipCloseOnClick]="true"
                [toolTipInteractive]="true"
                [tooltipPosition]="'top'"
                [template]="titleTooltipTemplate"
                cc-tooltip="title"
              >
                <div class="cc-cross-chips-wrapper">
                  <span class="cc-cross-chips-text"
                    ><a (click)="openQuickLink(item)">{{ parseTitleName(item.title) }}</a></span
                  >
                </div>
                <span class="cc-cross-span" (click)="deleteItem(item.id)"
                  ><svg
                    class="cc-cross"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.7477 12.2248C10.0601 12.2248 12.7454 9.5845 12.7454 6.32759C12.7454 3.07067 10.0601 0.43042 6.7477 0.43042C3.43526 0.43042 0.75 3.07067 0.75 6.32759C0.75 9.5845 3.43526 12.2248 6.7477 12.2248Z"
                      fill="#777777"
                    />
                    <path d="M4.39692 4.34698L8.94806 8.89812" stroke="white" stroke-linecap="round" />
                    <path d="M4.47784 8.8223L8.95269 4.34724" stroke="white" stroke-linecap="round" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <ng-template #titleTooltipTemplate>
            <div [style]="item.title && item.title.length > 30 ? 'width: 240px; white-space: normal' : ''">
              <span>{{ item.title }}<br /></span>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="note-text cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
    <cc-icon [fontSet]="'icomoon'" [inline]="true" class="note-icon">info</cc-icon>
    <div class="note">
      <span *ngIf="isShowQuickLinks">Note: Limited to 30 links</span>
      <span *ngIf="!isShowQuickLinks">Note: You can only add up to 25 Links.</span>
    </div>
  </div>
</div>
