import { Component, OnInit, Inject } from '@angular/core';
import {
  CC_DRAWER_DATA,
  CcDrawerConfig,
  CcDrawerRef,
  CcModal,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { dealerType } from '../../../models/shared.interface';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../shared/shared';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { cloneDeep, hasIn, isEmpty, remove } from 'lodash-es';
import { AssetService } from '../services/asset.service';
import { AssetSubscriptionFormDataType } from '../../../models/assets.interface';
import { PaymentPreviewConfirmationComponent } from './payment-preview-confirmation/payment-preview-confirmation.component';
import { FilterAssetsV2Payload } from '../asset-drawer/asset-drawer-interface';
import * as dspConstants from '../../../shared/dspConstants';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-grade-control-system-asset-drawer',
  templateUrl: './grade-control-system-asset-drawer.component.html',
  styleUrls: ['./grade-control-system-asset-drawer.component.scss'],
})
export class GradeControlSystemAssetDrawerComponent implements OnInit {
  dealer!: dealerType;
  trackerIndex = 0;
  currentPageIndex = 0;
  selectedRowData: any;
  dynamicTrackerData: any;
  enableBackBtn = false;
  dynamicTrackerSubscribedData = [
    {
      label: 'Component',
      disabled: false,
      clickable: true,
    },
    {
      label: 'Request License',
      disabled: false,
      warning: false,
      clickable: true,
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: true,
    },
  ];
  dynamicTrackerUnsubscribedData = [
    {
      label: 'Select Ownership',
      disabled: false,
      clickable: true,
    },
    {
      label: 'Component',
      disabled: false,
      clickable: true,
    },
    {
      label: 'Request License',
      disabled: false,
      warning: false,
      clickable: true,
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: true,
    },
  ];
  showbars = true;
  assetFormSubscription!: any;
  selectedAssetSubscriptionFormData: AssetSubscriptionFormDataType = {
    isValidForm: false,
    expandedService: '',
    customer: {},
    service: {
      currentService: '',
      previousService: '',
      selectedService: '',
    },
    subscription: {
      currentSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      previousSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      selectedSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
    },
    addOns: {
      currentAddons: [],
      previousddons: [],
      selectedAddons: [],
    },
    prepay: {
      currentPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      previousPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      selectedPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
    },
    catBillingData: {
      billingGroupId: '',
    },
    productFamily: {
      currentProductFamily: '',
      previousProductFamily: '',
    },
    selectedCategory: '',
    siteInfo: {
      siteName: '',
      siteId: '',
    },
    previousSiteInfo: {
      siteName: '',
      siteId: '',
    },
    selectedSiteInfo: {
      siteName: '',
      siteId: '',
    },
    catGradeEditSerialInfo: {
      componentType: '',
      serialNumber: '',
      assetSerialNumber: '',
      dealerCode: '',
      model: '',
    },
  };
  launchType = '';
  componentRes: any;
  searchType: any;
  isEditFlow = true;
  setAssetOwnershipFlag = false;
  isAssetDealerInfoRetrieved!: boolean;
  setFilterAssetsFlag = true;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  assetDealerInfo: any;
  hasPartialOwnershipData = false;
  dspConfig: any;
  isOwnershipApiCallInProgress = false;
  partialOwnershipData: any = [];
  completeOwnershipData: any = [];
  subsPricingEligible = false;
  userActiveFeatures: any;
  searchAssetInfo: any;
  originalEditInfo: any;
  customerInfo: any;
  techLevel: any;
  componentArr!: any[];
  enableNextButton!: boolean;
  languageTranslations: any = [];
  dspStoreData: any;
  isShowPriceIfNoBilling = false;
  productFamilyCode: any;
  isLicenseMappingEnabled: any;
  selectedLicenseDetails: any;
  isCatGradeDemoEnabled: any;
  isCatGradeDemoExclusionEnabled: any;
  dspHelpUrl: any;
  constructor(
    public dialogRef: CcDrawerRef<GradeControlSystemAssetDrawerComponent, CcDrawerConfig>,
    public assetDrawerService: AssetDrawerService,
    @Inject(CC_DRAWER_DATA)
    public assetRowData: any,
    public assetService: AssetService,
    private modal: CcModal,
    public messageBar: MessageBar,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.dspHelpUrl = environment.redirectURL.helpDocUrl + '?language=en_US';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.dspConfig = dsp.dspConfig;
      this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
      this.languageTranslations = dsp.languageTranslations || {};
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.isLicenseMappingEnabled = this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        : false;
      this.isCatGradeDemoEnabled = this.dspStoreData?.featureFlagInfo?.isCatGradeDemoEnabled
        ? this.dspStoreData?.featureFlagInfo?.isCatGradeDemoEnabled
        : false;
      this.isCatGradeDemoExclusionEnabled = this.dspStoreData?.featureFlagInfo?.isCatGradeDemoExclusionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isCatGradeDemoExclusionEnabled
        : false;
    });

    this.dealer = fetchLoggedInDealerDetails();
    if (this.isCatGradeDemoEnabled && this.isCatGradeDemoExclusionEnabled) {
      this.getDemoEligibleDealer();
    }
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    this.dynamicTrackerData = this.dynamicTrackerUnsubscribedData;
    this.launchType = this.assetRowData.type;
    this.selectedRowData = this.assetRowData?.selectedAssetdetails
      ? cloneDeep(this.assetRowData?.selectedAssetdetails)
      : {};
    this.searchType = this.selectedRowData?.searchType || dspConstants.Worklist.GLOBAL_SEARCH;
    this.selectedAssetSubscriptionFormData.enableCloseButton = false;
    this.assetDrawerService.enableNextBtn.next(true);
    if (this.selectedRowData?.reportingState === 'Unsubscribed') {
      // this.loadAssetAndOwnershipData();
    }
    this.assetFormSubscription = this.assetService.assetSubscriptionFormEvent.subscribe(
      (data: AssetSubscriptionFormDataType) => {
        this.selectedAssetSubscriptionFormData = data;
        this.setNextDisabled();
        this.setCloseDisabled();
        this.setSubmitDisabled();
      }
    );

    if (this.selectedRowData?.reportingState === 'Subscribed') {
      this.dynamicTrackerData = this.dynamicTrackerSubscribedData;
      // this.dynamicTrackerData = this.dynamicTrackerUnsubscribedData;
    } else if (this.selectedRowData?.reportingState === 'Unsubscribed') {
      this.dynamicTrackerData = this.dynamicTrackerUnsubscribedData;
    }

    this.assetDrawerService.closeGradeControlDrawer.subscribe((data) => {
      if (data) {
        this.closeDrawer();
      }
    });
    this.assetDrawerService.enableNextBtn.subscribe((data: any) => {
      this.enableNextButton = data;
    });
    this.assetDrawerService.searchAssetInfo.subscribe((data: any) => {
      this.searchAssetInfo = data;
    });

    this.assetDrawerService.catGradeCustomerInfo.subscribe((data: any) => {
      this.customerInfo = data;
    });

    this.assetDrawerService.filteredPaymentPreviewData.subscribe((data: any) => {
      if (data) {
        this.originalEditInfo = data;
      }
    });

    this.assetDrawerService.selectedLicense.subscribe((data: any) => {
      this.selectedLicenseDetails = data;
      if (this.isLicenseMappingEnabled) {
        this.originalEditInfo = this.originalEditInfo?.map((elm: any) => {
          if (elm.serialNumber === this.selectedLicenseDetails?.rowInfo?.serialNumber) {
            return {
              ...elm,
              nextUpgradableLicenseDesc: this.selectedLicenseDetails.licenseType,
              nextUpgradableLicensePartNum: this.selectedLicenseDetails.licensePartNum,
            };
          }

          return elm;
        });
      }
    });

    this.assetDrawerService.productFamilyCode.subscribe((data: any) => {
      this.productFamilyCode = data;
    });
    this.techLevel = sessionStorage.getItem('techLevel');
    this.loadAssetAndOwnershipData();
    if (!this.subsPricingEligible) {
      const val = this.dynamicTrackerData;
      this.dynamicTrackerData = val.filter((ele: any) => ele.label != 'Payment Preview');
    }
  }
  closeDrawer() {
    this.dialogRef.close();
    sessionStorage.removeItem('catGradeEditTableData');
    sessionStorage.removeItem('validatedLicenseTableData');
    this.assetDrawerService.catGradeEditInfo.next({ editInfo: [] });
    this.assetDrawerService.isBackBtnClicked.next(false);
    this.assetDrawerService.enableNextBtn.next(true);
  }

  closeModal() {
    this.dialogRef.close();
  }

  onHorizontalTraker(event: any) {
    // this.horizontalIndex = event;
    // this.horizontalIndex1 = event;
  }

  setNextDisabled() {
    return !this.selectedAssetSubscriptionFormData.isValidForm;
  }

  setSubmitDisabled() {
    return !this.selectedAssetSubscriptionFormData.isValidForm;
  }

  onNext() {
    const { trackerIndex, currentPageIndex, dynamicTrackerData } = this;
    const isComponent = dynamicTrackerData[currentPageIndex].label === 'Component';
    this.trackerIndex = this.trackerIndex + 1;
    this.currentPageIndex = this.currentPageIndex + 1;
    if (this.trackerIndex === 1) {
      this.enableBackBtn = true;
    }
    if (dynamicTrackerData[currentPageIndex].label === 'Component') {
      this.assetDrawerService.isBackBtnClicked.next(false);
    }
  }

  setCloseDisabled() {
    return !this.selectedAssetSubscriptionFormData.enableCloseButton;
  }

  onBack() {
    this.trackerIndex = this.trackerIndex - 1;
    this.currentPageIndex = this.currentPageIndex - 1;
    this.assetDrawerService.isBackBtnClicked.next(true);
  }

  onSubmit() {
    const { trackerIndex, currentPageIndex, dynamicTrackerData } = this;
    if (!this.subsPricingEligible && dynamicTrackerData[currentPageIndex].label === 'Request License') {
      this.callSubmitLicense();
    } else {
      const modalRef = this.modal.openModal(PaymentPreviewConfirmationComponent, {
        type: 'semi-modal',
        width: '466px',
        height: '385px',
        hasBackdrop: true,
        closeOnEsc: false,
        hideHeader: false,
        hideFooter: false,
        disableMargin: true,
        disableBackdropClick: true,
      });
    }
  }

  callSubmitLicense() {
    this.componentArr = this.originalEditInfo?.map((res: any) => {
      const id: string = res.id ? res.id : '';
      return {
        id: id.toString(),
        componentType: res.type ? res.type : '-',
        ecmSerialNumber: res.serialNumber ? res.serialNumber : '',
        requestedLicense: res.nextUpgradableLicensePartNum ? res.nextUpgradableLicensePartNum : '',
      };
    });
    const payload = {
      assetSerialNumber: this.searchAssetInfo.serialNumber ? this.searchAssetInfo.serialNumber : '',
      make: this.searchAssetInfo.make ? this.searchAssetInfo.make : '',
      model: this.searchAssetInfo.model ? this.searchAssetInfo.model : '',
      techLevel: this.techLevel ? this.techLevel : '',
      dealerCode: fetchLoggedInDealerDetails().dealerCode,
      ucid: this.customerInfo.ucid ? this.customerInfo.ucid : '',
      ucidName: this.customerInfo.ucidName ? this.customerInfo.ucidName : '',
      dcnName: this.customerInfo.dealerCustName ? this.customerInfo.dealerCustName : '',
      dcnNumber: this.customerInfo.dealerCustNo ? this.customerInfo.dealerCustNo : '',
      components: this.componentArr ? this.componentArr : [],
      productFamily: this.productFamilyCode,
    };
    this.assetDrawerService.submitLicense(payload).subscribe({
      error: (error: any) => {
        let responseText = error?.error?.text;
        if (responseText === 'SUCCESS') {
          const messageText = 'License requested successfully.';
          this.showToastMessage(messageText, 'success');
          this.assetDrawerService.setGradeControlDrawer(true);
          this.assetDrawerService.enableNextBtn.next(true);
        } else {
          if (error.status === 404 || error.status === 400 || error.status === 500) {
            const linkDetail = [
              {
                label: 'Cat Digital Support',
                fn: () => {
                  window.open(
                    'https://cat-crmi.secure.force.com/CatConnectCase/?_ga=2.117467593.170777017.1674835347-192150537.1661439604'
                  );
                },
                class: 'cat-digital-support-hyperlink',
              },
            ];
            this.showErrorToastMessage('Request failed. Please try again later or contact', 'error', linkDetail);
            this.assetDrawerService.setGradeControlDrawer(true);
            this.assetDrawerService.enableNextBtn.next(true);
          }
          if (responseText === 'Zuora_Billing_Not_Supported') {
            responseText = this.languageTranslations?.Shared?.['WL_BILLING_NOT_SUPPORTED_MSG'];
            this.showToastMessage(responseText, 'error', 'container-overlay', 'manage-asset', true);
            this.assetDrawerService.setGradeControlDrawer(true);
            this.assetDrawerService.enableNextBtn.next(true);
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.assetFormSubscription?.unsubscribe();
  }

  loadAssetAndOwnershipData() {
    this.getFilteredAssets();
    if (this.searchType != dspConstants.Worklist.NO_DEVICE_SEARCH) {
      this.fetchOwnershipInfo();
    }
  }

  fetchOwnershipInfo() {
    const ownershipInfoPayload = {
      assetIds: [this.selectedRowData.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    let assetOwners: any[] = [];
    this.isOwnershipApiCallInProgress = true;
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (response: any) => {
        this.isOwnershipApiCallInProgress = false;
        if (response[this.selectedRowData.assetId]) {
          assetOwners = response[this.selectedRowData.assetId] as any;
          assetOwners = this.filterOwnershipRecords(assetOwners);
          assetOwners = this.checkDCNFlagCondition(assetOwners);
          if (this.dspStoreData?.featureFlagInfo?.isRemoveDCNCatGradeControl === true) {
            this.checkSelectOwnershipSection(assetOwners);
          }
          this.selectedRowData.ownershipDetails = assetOwners;
          if (this.isEditFlow) {
            this.setOwnershipData(assetOwners);
          }
        } else {
          this.selectedRowData.ownershipDetails = [];
        }
        this.setAssetOwnershipFlag = true;
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  checkDCNFlagCondition(assetOwners: any) {
    if (this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck === true) {
      this.checkOwnershipInfo(assetOwners);
      this.partialOwnershipData = sortOwnershipRecords(this.partialOwnershipData);
      this.completeOwnershipData = sortOwnershipRecords(this.completeOwnershipData);
      assetOwners = [{}];
      assetOwners = [...this.completeOwnershipData, ...this.partialOwnershipData];
    } else {
      assetOwners = sortOwnershipRecords(assetOwners);
    }
    return assetOwners;
  }

  checkOwnershipInfo(assetOwners: any) {
    assetOwners.forEach((owner: any) => {
      if (isEmpty(owner?.dealerCustNo) && isEmpty(owner?.dealerCustName)) {
        this.hasPartialOwnershipData = true;
        this.partialOwnershipData.push(owner);
      } else {
        this.completeOwnershipData.push(owner);
      }
    });
  }

  getFilteredAssets() {
    this.isOwnershipApiCallInProgress = true;
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.selectedRowData = Object.assign(this.selectedRowData, result);
          if (this.searchType == dspConstants.Worklist.NO_DEVICE_SEARCH) {
            this.setOwnershipInfoForNoDevice();
          }
          this.getDealerInfo();
          this.setFilterAssetsFlag = true;
        }
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.getDealerInfo();
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  setFilterAssetsPayload() {
    if (this.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make || '',
        serialNumber: this.selectedRowData?.serialNumber || '',
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make,
        serialNumber: this.selectedRowData?.serialNumber,
        flag: false,
      };
    }
  }

  setOwnershipInfoForNoDevice() {
    this.isOwnershipApiCallInProgress = false;
    let assetOwners: any[] = [];
    if (this.selectedRowData?.equipmentOwner.length > 0) {
      assetOwners = this.selectedRowData.equipmentOwner;
      assetOwners = this.filterOwnershipRecords(assetOwners);
      assetOwners = this.checkDCNFlagCondition(assetOwners);
      if (this.dspStoreData?.featureFlagInfo?.isRemoveDCNCatGradeControl === true) {
        this.checkSelectOwnershipSection(assetOwners);
      }

      this.selectedRowData.ownershipDetails = assetOwners;
      if (this.isEditFlow) {
        this.setOwnershipData(assetOwners);
      }
    } else {
      this.selectedRowData.ownershipDetails = [];
    }
    this.setAssetOwnershipFlag = true;
  }

  filterOwnershipRecords(assetOwners: any) {
    const loggedInDealerRecords = assetOwners?.filter((owner: any) => {
      return owner?.dealerCode == this.dealer?.dealerCode;
    });
    return loggedInDealerRecords;
  }

  checkSelectOwnershipSection(assetOwners: any) {
    if (!this.hasPartialOwnershipData) {
      if (assetOwners?.length === 1) {
        remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Ownership');
        this.setOwnershipData(assetOwners);
      }
    }
  }

  setOwnershipData(assetOwnershipInfo: any) {
    if (assetOwnershipInfo && !isEmpty(assetOwnershipInfo)) {
      // Customers data is available
      this.selectedAssetSubscriptionFormData.customer = assetOwnershipInfo[0];
    } else {
      // No customers are there and not subscribed at Multi-level
      this.selectedAssetSubscriptionFormData.customer = '';
    }
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  getDealerInfo() {
    if (
      !this.isAssetDealerInfoRetrieved &&
      this.selectedRowData?.reportingState &&
      isEmpty(this.selectedRowData?.dealerName)
    ) {
      const dealerInfoPayload = {
        assetIds: [this.selectedRowData?.assetId] || [],
        dealerCode: this.dealer?.dealerCode || '',
      };
      this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
        next: (result: any) => {
          if (result) {
            this.assetDealerInfo = result;
            this.isAssetDealerInfoRetrieved = true;
            this.mapDealerName();
          }
        },
        error: (err: any) => {
          this.assetDealerInfo = {};
          this.isAssetDealerInfoRetrieved = true;
          this.mapDealerName();
        },
      });
    }
  }

  mapDealerName() {
    if (
      this.selectedRowData?.reportingState == dspConstants.Worklist.SUBSCRIBED &&
      !isEmpty(this.selectedRowData?.dealerCode)
    ) {
      if (hasIn(this.assetDealerInfo, this.selectedRowData?.dealerCode)) {
        this.selectedRowData.dealerName = this.assetDealerInfo[this.selectedRowData?.dealerCode];
      }
    }
  }

  showToastMessage(
    message: string,
    status: string,
    hostType = 'container-overlay',
    selectorId = 'manage-asset',
    autoCloseNeeded?: boolean
  ) {
    const config: MessageBarConfig = {
      hostType: hostType,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: selectorId,
    };
    if (status == 'success') {
      config.duration = 3000;
      config.appOverlayClass = 'manageAsset-overlay';
    }
    if (autoCloseNeeded) {
      config.duration = 3000;
    }
    this.messageBar.open(message, status, undefined, config, true);
  }

  showErrorToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'device-status',
      appOverlayClass: 'cat-digital-support-hyperlink',
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }

  onClose() {
    this.closeDrawer();
  }

  getDemoEligibleDealer() {
    const dealerObj = {
      dealerCode: this.dealer?.dealerCode,
    };
    this.assetDrawerService.getDemoEligibleDealer(dealerObj).subscribe({
      next: (res: any) => {
        this.assetDrawerService.isDemoEligibleDealer.next(res);
      },
      error: (err: any) => {
        this.assetDrawerService.isDemoEligibleDealer.next(false);
      },
    });
  }

  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }
}
