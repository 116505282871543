import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { LinksService } from '../../../services/links.service';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal/confirmation-modal.component';
import { QuickLinksModalComponent } from '../../footer/quick-links-modal/quick-links-modal.component';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Observable } from 'rxjs';
import { Links } from '../../../models/links.interface';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
import * as dspConstants from '../../../shared/dspConstants';
import { PopupData } from '../../../models/popup.interface';

@Component({
  selector: 'dsp-next-gen-ui-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLinksComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<DSPAppState>,
    private service: LinksService,
    private modal: CcModal,
    private messagebar: MessageBar,
    private linkService: LinksService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.document.body.classList.add('quickLinks');
  }
  quickLinks: any;
  body: any;
  dspStoreData!: any;

  links$: Observable<Links> | undefined;
  disableLink = false;
  isShowQuickLinks: any;

  isCommonPopup = false;
  ngOnInit() {
    this.initialValues();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowQuickLinks = this.dspStoreData?.featureFlagInfo?.isShowQuickLinks
        ? this.dspStoreData?.featureFlagInfo?.isShowQuickLinks
        : false;
      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });
  }

  initialValues() {
    this.linkService.getQuickLinks().subscribe((res) => {
      if (this.isShowQuickLinks) {
        this.quickLinks = res;
        this.disableLink = this.quickLinks.length >= 30 ? true : false;
      } else {
        this.quickLinks = res;
        this.disableLink = this.quickLinks.length >= 25 ? true : false;
      }
    });
  }

  addLink() {
    const modalRef = this.modal.openModal(QuickLinksModalComponent, {
      type: 'semi-modal',
      width: '375px',
      data: {
        name1: 'URL Name',
        labelName1: 'Add URL Name (Min 3 char)',
        name2: 'URL',
        labelName2: 'Add URL (Min 3 char)',
        title: 'Add Link',
        value1: '',
        value2: '',
        multipleButtons: true,
        inputFields: true,
        negativeButtonAction: 'Cancel',
        positiveButtonAction: 'Add',
        quickLinks: this.quickLinks,
      },
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
      disableBackdropClick: true,
    });
    modalRef.afterClosed().subscribe((result: any) => {
      if (result === 'add') {
        this.initialValues();
      }
    });
  }

  deleteItem(event: any) {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: 'Confirm',
        messageArray: [dspConstants.Common.DELETE_QUICK_LINK_BODY_LINE1],
        showSecondaryButton: true,
        showDangerButton: true,
        dangerButtonText: dspConstants.PopupButtons.DELETE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
      };
      const modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        type: 'semi-modal',
        width: '466px',
        data: popupData,
        hasBackdrop: true,
        closeOnEsc: false,
        hideHeader: false,
        hideFooter: false,
        disableMargin: true,
        disableBackdropClick: true,
      });
      modalRef.afterClosed().subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.DELETE) {
          const element = document.getElementById(`listItem-${event}`) as HTMLElement;
          element.remove();
          this.linkService.deleteQuickLink(event).subscribe({
            //this.store.dispatch(storeActions.deleteQuickLinksAction({ body: event }));
            //this.store.pipe(select(storeSelectors.deleteQuickLinks)).subscribe((data) => {
            next: (data: any) => {
              const messageText = `Quick link deleted successfully.`;
              this.showToastNotification(messageText, 'success');
              setTimeout(() => {
                this.initialValues();
              }, 2000);
              modalRef.close();
            },
            error: (err) => {
              const messageText = `Something went wrong. Please try again later`;
              this.showToastNotification(messageText, 'error');
              modalRef.close();
            },
          });
        } else {
          modalRef.close();
        }
      });
    } else {
      const modalRef = this.modal.openModal(ConfirmationModalComponent, {
        type: 'semi-modal',
        width: '466px',
        data: {
          value: event,
          title: 'Confirm',
          messageLine1: 'Are you sure you want to delete this quick link?',
          messageLine2: '',
          inputFields: false,
          multipleButtons: true,
          negativeButtonAction: 'Cancel',
          positiveButtonAction: 'Delete',
        },
        hasBackdrop: true,
        closeOnEsc: false,
        hideHeader: false,
        hideFooter: false,
        disableMargin: true,
        disableBackdropClick: true,
      });
      modalRef.afterClosed().subscribe((result: any) => {
        if (result === 'Delete') {
          const element = document.getElementById(`listItem-${event}`) as HTMLElement;
          element?.remove();
          this.linkService.deleteQuickLink(event).subscribe({
            //this.store.dispatch(storeActions.deleteQuickLinksAction({ body: event }));
            //this.store.pipe(select(storeSelectors.deleteQuickLinks)).subscribe((data) => {
            next: (data: any) => {
              const messageText = `Quick link deleted successfully.`;
              this.showToastNotification(messageText, 'success');
              setTimeout(() => {
                this.initialValues();
              }, 2000);
              modalRef.close();
            },
            error: (err) => {
              const messageText = `Something went wrong. Please try again later`;
              this.showToastNotification(messageText, 'error');
              modalRef?.close();
            },
          });
        } else {
          modalRef.close();
        }
      });
    }
  }

  showToastNotification(message: string, status: string) {
    //const type = 'success';
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    const enableCloseIcon = true;
    const toastbarRef = this.messagebar.open(message, status, undefined, config, enableCloseIcon);
  }

  ngOnDestroy() {
    this.document.body.classList.remove('quickLinks');
  }
  openQuickLink(item: any) {
    const check = item.url.split('://');
    if (check.length == 2) window.open(item.url);
    else window.open('http://' + item.url);
  }
  parseTitleName(title: any) {
    title = title ? title.trim() : title;
    if (title && title.length > 30) {
      return `${title.substring(0, 30)}...`;
    } else {
      return title;
    }
  }
}
