<div class="site-product-plan-column">
  <div
    class="site-plan-product"
    *ngIf="rowData.status === 'Active'"
    [ngClass]="{ 'text-truncate': showSeeMoreOption() }"
    cc-tooltip
    [template]="showCustomToolTip ? product_plan_tooltip_1 : product_plan_tooltip_2"
    [isEllipseEnabled]="true"
    [tooltipPosition]="'top'"
    dspNextGenUiCopy
    [fullText]="parseProductPlan(rowData)"
  >
    <span [innerHTML]="parseProductPlan(rowData) | highlightSearch: searchString"></span>
    <a class="link" *ngIf="showSeeMoreOption()" (click)="openDrawerInMultiSize()"> See more </a>
  </div>
</div>
<ng-template #product_plan_tooltip_1>
  <div style="width: 400px; white-space: normal" [innerHTML]="parseProductPlan(rowData)"></div>
</ng-template>

<ng-template #product_plan_tooltip_2>
  <div style="width: 250px; white-space: normal" [innerHTML]="parseProductPlan(rowData)"></div>
</ng-template>
