<ng-template #factoryUser>
  <div class="chip-flexbox">
    <div class="chip-flex-item">
      <span
        id="chip17"
        cc-chip="Manual Entry"
        type="check"
        (selected)="toggleRadioGroup($event, 0, 'MANUAL_ENTRY')"
        [radio]="true"
        [state]="radioStates[0]"
        [largecheck]="true"
        [icons]="loadedManualIcon"
        [ngClass]="radioStates[0] === 'active' ? 'chip-field' : ''"
      ></span>
    </div>
    <div class="chip-flex-item">
      <span
        id="chip2S"
        cc-chip="Scan Entry"
        type="check"
        (selected)="toggleRadioGroup($event, 1, 'SCAN_ENTRY')"
        [radio]="true"
        [state]="radioStates[1]"
        [icons]="loadedScanIcon"
        [largecheck]="true"
        [ngClass]="radioStates[1] === 'active' ? 'chip-field' : ''"
      ></span>
    </div>
  </div>

  <div *ngIf="defaultEntry === 'SCAN_CODE' && showLoader" class="scan-device">
    <img src="../../../assets/images/scan_loader.gif" class="loadingCls" />

    <div class="scan-text">Scan your device</div>
  </div>
  <div class="scancode-content">
    <span class="invisibleScanTxt">
      <div contenteditable="true" id="editTableScanInput" (window:keydown)="onKeyEdit($event)">{{ barcode }}</div>
    </span>
  </div>
</ng-template>
<img src="../../../assets/images/scan_loader.gif" class="loadingCls hide" />
<img class="hide" src="../../../../../assets/icons/manual.svg" />
<img class="hide" src="../../../../../assets/icons/manual-selected.svg" />
<img class="hide" src="../../../../../assets/icons/scan.svg" />
<img class="hide" src="../../../../../assets/icons/scan-selected.svg" />
<div class="cc-drawer edit_asset_drawer">
  <div class="cc-drawer__header">
    <span class="cc-headerText edit-plan-header cc-drawer__ellipsis">Update Registration </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>

  <div class="cc-drawer__content">
    <form
      [formGroup]="editAssetForm"
      (ngSubmit)="updateAsssetByCandidateId('')"
      id="Update Registration"
      name="Update Registration"
    >
      <div class="info-row" *ngIf="isEnableProductMsgUpdate">
        <cc-icon [fontSet]="'icomoon'" [inline]="true" class="info-icon">info</cc-icon>
        <span class="info-text"
          >This action will create a new asset, and telematics history will not be available for previous make + serial
          number combinations.</span
        >
      </div>
      <div [ngClass]="isEnableProductMsgUpdate ? 'make-container' : ''"><span class="bed-rock"></span> Make</div>
      <div class="makeCls">
        <cc-input-field
          [labelValue]="'Search by Make Code/ Make Name'"
          [inputtype]="'search'"
          [isDense]="true"
          [isTheme]="'2.0'"
          id="cc-atom-search-s7"
          [value]="makeValue"
          (valueChange)="makeValue = $event.value; searchByMakeCodeName($event)"
          [triggerSearchMinChar]="1"
          [enableMobileOverlay]="true"
          [isLeading]="true"
          [enableSearchOverlay]="true"
        >
          <span class="searchIcon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                fill="black"
              />
            </svg>
          </span>
          <div class="cc-input-search-menu">
            <div class="no-result load-container" *ngIf="makeCodeLoader">
              <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
            </div>
            <ng-container *ngIf="searchMakeValue && searchMakeValue.length > 0">
              <cc-list [theme]="'secondary'">
                <cc-list-item
                  *ngFor="let item of searchMakeValue; let i = index"
                  class="list-item-pg"
                  (listItemClick)="listItemClick(i)"
                  (listItemEnter)="listItemClick(i)"
                >
                  <div class="each-item">
                    <div class="txt-name">
                      <span class="label" [innerHTML]="item.label | highlightSearch: makeValue"></span>
                    </div>
                  </div>
                </cc-list-item>
              </cc-list>
            </ng-container>
          </div>
          <input
            input-field
            #customerSearchBox
            class="input-field account-details-search"
            [value]="makeValue"
            autocomplete="off"
            type="search"
            [isFloating]="false"
            aria-labelledby="Search"
            aria-describedby="Search"
            (valueChange)="makeValue = $event.value"
            (focus)="onFocusEvent()"
            (click)="onCickEvent($event)"
            (blur)="onBlurEvent($event)"
          />
        </cc-input-field>
      </div>

      <br />
      <div *ngIf="!isEnableOnlyMakeFor3PDATA">
        <cc-radio-group class="list-radio-group">
          <cc-radio-button
            *ngFor="let candidateId of candidateProductIdList"
            [value]="candidateId.serialNumber"
            [checked]="candidateId.serialNumber === assetEditableData.serialNumber"
            (valueChange)="hideSerialNo(candidateId.serialNumber)"
          >
            {{ candidateId.serialNumber }}
          </cc-radio-button>
          <cc-radio-button [value]="false" [checked]="checkNewSerialNo()" (valueChange)="newSerialNoVal()">
            New Asset S/N
          </cc-radio-button>
        </cc-radio-group>
      </div>
      <br />
      <div
        *ngIf="userRole === dspUserRoleConstants.Common.DSP_FACTORY_USER && newSerialNo"
        [ngTemplateOutlet]="factoryUser"
      ></div>

      <div *ngIf="enableFields && newSerialNo">
        <div class="assetSnCls" [ngStyle]="{ color: newSerialNo ? 'black' : 'grey' }">
          <span class="bed-rock"></span> Asset S/N
        </div>
        <cc-input-field
          id="siteName_id"
          [disabled]="!newSerialNo"
          [labelValue]="'Enter Serial Number'"
          [inputtype]="'text'"
          [isTableInlineEdit]="true"
          [isTheme]="isTheme"
        >
          <input
            input-field
            formControlName="newAssetId"
            class="input-field"
            id="productIdInputText"
            autocomplete="off"
            type="text"
            (valueChange)="validateProductIdandMake()"
            (click)="setTextLength()"
            aria-labelledby="Enter any value"
            aria-describedby="Enter any value"
            [readonly]="readOnly"
          />
        </cc-input-field>
        <div
          id="errorMessage"
          *ngIf="
            (validateProductIdandMake() && serialNumberError) ||
            (errorMessageProd !== null &&
              errorMessageProd !== undefined &&
              errorMessageProd.length > 0 &&
              serialNumberError)
          "
          class="errorMsg"
        >
          {{ errorMessageProd }}
        </div>
      </div>
      <div id="errorMessage" *ngIf="validateProductIdandMake() && !serialNumberError" class="errorMsg">
        {{ errorMessageProd }}
      </div>
    </form>
  </div>

  <div class="cc-drawer__footer">
    <button id="cc-drawer-cancelBtn" cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer(false)">
      Cancel
    </button>

    <button
      id="cc-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      (click)="onSubmit()"
      [disabled]="isSubmitButtonDisabled()"
    >
      Submit
    </button>
  </div>
</div>
<ng-template #confirmationPopUpTemplateRef>
  <ng-container>
    <div class="cc-modal">
      <div class="cc-modal-header">
        <div class="cc-modal-title">Confirmation</div>
      </div>
      <div class="cc-modal-content warning-modal-content">
        <p class="text-display">{{ modalContentData }}</p>
      </div>

      <div class="cc-modal-footer">
        <button cc-btn-secondary cc-btn-large [setTheme]="true" class="cancel button-opacity" (click)="onCancel()">
          Cancel
        </button>
        <button cc-btn-primary cc-btn-large [setTheme]="true" class="delete button-opacity" (click)="handleSubmit()">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
