import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DspCommonService } from '../services/dsp-common.service';
import { isEmpty } from 'lodash-es';
import * as dspConstants from '../../app/shared/dspConstants';
import { Subscription } from 'rxjs';
import { DSPAppState } from '../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { fetchLoggedInDealerDetails } from '../shared/shared';
import { SharedService } from '../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],
})
export class TosComponent implements OnInit {
  isChecked = false;
  user: any;
  userRole: any;
  dateYear = new Date().getFullYear();
  public subscription: Subscription = new Subscription();
  dspStoreData!: any;
  dealerCode = 'TD00';
  dynamicUrl = environment.redirectURL.hostBaseUrl;

  constructor(
    public router: Router,
    private dspCommonService: DspCommonService,
    private store: Store<DSPAppState>,
    private sharedService: SharedService
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          router.navigate(['eula']);
        }
      }
    });
  }
  ngOnInit(): void {
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }

  privacyModal() {
    this.router.navigate(['privacyModal']);
    this.sharedService.setNavigationValue('privacyModal');
  }
  toggleCheckboxOption(event: any): void {
    if (event === true) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }
  tosAgreeButton(): void {
    this.router.navigate(['privacyNotice']);
  }
  tosDisAgreeButton(): void {
    this.router.navigate(['eula']);
  }
}
