import { Action, createReducer, on } from '@ngrx/store';
import * as QuickLinksActions from '../actions/links.actions';
import { LinksState, initiallinkState, initialcreateLinkState, initialDeleteLinkState, initialDeletelinkState } from '../state/link.state';

export const linksReducer = createReducer(
    initiallinkState, 
    on(QuickLinksActions.loadQuickLinksAction, (state)=> ({...state, isLoading:true})),
    on(QuickLinksActions.loadLinksSuccessAction, (state, result) => ({
        ...state,
        links: result.link, 
        isLoading: false, 
        isLoadingSuccess: true
    })),
    on(QuickLinksActions.loadLinksFailureAction, (state, error)=> ({
        ...state, 
        isLoading:false,
        isLoadingFailure: true,
        error: error
    })),
);

export const createLinksReducer = createReducer(
    initiallinkState, 
    on(QuickLinksActions.createQuickLinksAction, (state) => ({
        ...state,
      })),
    on(QuickLinksActions.createLinksSuccessAction, (state ,{ successResponse }) => ({
        ...state,
        links: successResponse, 
       
    })),
    on(QuickLinksActions.createLinksFailureAction, (state, error)=> ({
        ...state, 
        error: error
    })),
);

export const deleteLinksReducer = createReducer(
    initialDeletelinkState, 
    on(QuickLinksActions.deleteQuickLinksAction, (state,  body) =>({ ...state, body })),
    on(QuickLinksActions.DeleteLinksSuccessAction, (state ,{ successResponse }) => ({
        ...state,
        links: successResponse, 
       
    })),
    on(QuickLinksActions.DeleteLinksFailureAction, (state, error)=> ({
        ...state, 
        error: error
    })),
);

export function QuickLinkReducer(state: LinksState | undefined, action: Action): any {
  return linksReducer(state, action);
}

export function CreateQuickLinkReducer(state: initialcreateLinkState | undefined, action: Action): any {
    return createLinksReducer(state, action);
  }

export function DeleteQuickLinkReducer(state: initialDeleteLinkState | undefined, action: Action): any {
  return deleteLinksReducer(state, action);
  }