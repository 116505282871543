<ng-container>
  <div class="data-expand">
    <div class="display-block">
      <div *ngFor="let data of displayInfo">
        <span>{{ data.appName }}</span>
        <cc-status-indicator [type]="'dot'" [statusType]="'success'">
          <div class="display-value">
            <span>{{ data.roleName }}</span>
          </div>
        </cc-status-indicator>
      </div>
    </div>
  </div>
</ng-container>
