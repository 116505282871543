import { Component, OnInit } from '@angular/core';
import { accountData, userDetails } from './../accounts-interface';
import { DrawerService, CcDrawerConfig, CcDrawerSize } from '@cat-digital-workspace/shared-ui-core/drawer';
import { AdditionalCwsIdComponent } from './../additional-cws-id/additional-cws-id.component';
import { isEmpty } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-account-cws-id',
  templateUrl: './account-cws-id.component.html',
  styleUrls: ['./account-cws-id.component.scss'],
})
export class AccountCwsIDComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  addtionalcwsID: any;
  showTruncatedText = false;
  dspStoreData: any;
  constructor(private drawerService: DrawerService, private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
  }
  parseAccountCwsIDComponent(rowData: accountData) {
    let user = '';
    if (isEmpty(rowData.cwsId)) {
      user = rowData.users && rowData.users.length ? rowData.users[0].cwsId : '-';
    } else {
      user = rowData.cwsId ? rowData.cwsId : '-';
    }
    return user;
  }

  openDrawerInMultiSize(type: string, cwsIds: userDetails) {
    this.addtionalcwsID = this.drawerService.openDrawer<AdditionalCwsIdComponent, CcDrawerConfig>(
      AdditionalCwsIdComponent,
      {
        data: {
          headerType: type,
          listItems: cwsIds,
          searchString: this.searchString,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
}
