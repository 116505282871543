<ng-container>
  <div
    *ngIf="!showTruncatedText"
    class="account-dealer"
    [tooltipPosition]="'top'"
    [cc-tooltip]="rowData?.dcName"
    [isEnableTooltioOnEllipsis]="true"
  >
    <span [innerHTML]="rowData.dcName | highlightSearch: searchString"></span>
    <div [innerHTML]="rowData.dcn | highlightSearch: searchString"></div>
  </div>
  <div
    *ngIf="showTruncatedText"
    class="account-dealer"
    [tooltipPosition]="'top'"
    [cc-tooltip]="rowData?.dcName"
    [isEnableTooltioOnEllipsis]="true"
    dspNextGenUiCopy
    [fullText]="rowData?.dcName"
  >
    <span [innerHTML]="rowData.dcName | highlightSearch: searchString"></span>
    <div [innerHTML]="rowData.dcn | highlightSearch: searchString"></div>
  </div>
</ng-container>
<ng-container *ngIf="rowData.subsDtl && rowData.subsDtl.type === 'Dealer'">
  <!-- <span
    cc-tooltip
    [innerHTML]="rowData.dealerName + '-' + rowData.dealerCode | highlightSearch: searchString"
    class="account-dealer"
  >
  </span> -->
</ng-container>
