export interface getAccountsPayload {
  dealerCode: string;
  limit: number;
  offset: number;
  orderBy: string;
  searchText: string;
  sortBy: string;
  pageNo: number;
  subFilters: {
    ucid: [];
    ucidName: [];
    plan: [];
    product: [];
    salesReason: [];
    planType: [];
    planLevel: [];
  };
}
export interface getAccountsCountPayload {
  dealerCode: string;
  searchText: string;
  version: string;
  subFilters: {
    ucid: [];
    ucidName: [];
    plan: [];
    product: [];
    salesReason: [];
    planType: [];
    planLevel: [];
  };
}
export interface accountData {
  appName: string;
  planName: string;
  startDate: Date;
  ucid: string;
  ucName: string;
  appId: string;
  dealerCode: string;
  dealerName: string;
  subsDtl: subsDtlObject;
  isAutoRenewed: boolean;
  type: string;
  dcn: string;
  dcnName: string;
  orgSubsId: number;
  subscriptionCancelReason: string;
  endDate: Date;
  operation: string;
  isActive: boolean;
  isEditable: boolean;
  billToParty: string;
  enablePricing: boolean;
  users: userDetails[];
  cwsId: string;
  assetPrefix: string;
  catRecId: string;
  store: string;
  subscription: string;
  subscriptionTerm: string;
  purchaseToken: string;
  organizationName: string;
  organizationType: string;
  application: string;
  licensedType: string;
  userGroup: string;
  groupId: string;
  subscriptionId: string;
  subscriptionTypeId: number;
  salesReason: object;
  cvaNumber: number;
  salesDescription: string;
  planType: string;
  planLevel: string;
}

export interface subsDtlObject {
  pricing: any[];
  name: string;
}

export interface getAccountsResponseData {
  count: number;
  sites: accountData[];
}

export interface userDetails {
  catRecId: string;
  cwsId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  middleName: string;
  profile: string;
  roleName: string;
  superAdmin: boolean;
  userId: string;
  userOperation: string;
}

export interface getUsersSearchPayload {
  application: string;
  cwsId: string;
  email: string;
  firstName: string;
  lastName: string;
  plan: string;
  resultLimit: number;
  sortField: string;
  sortOrder: string;
  ucid?: string;
  assetPrefix: string;
}

export interface editPlanPayload {
  appName: string;
  licensedType: string;
  operation: string;
  orgSubsId: number;
  planName: string;
  subsDtl: object;
  type: string;
  users: Array<object>;
}

export interface AccountFormType {
  product?: string;
  plan?: string;
  accountType?: string;
  isValidForm?: boolean;
  customer?: customerType;
  dealer?: string;
  licenseType?: string;
  isValidLicenseType?: boolean;
  restrictUnlimitedUsers?: boolean;
  selectedUsers?: any;
  searchedUsers?: any;
  subscribedUsers?: any;
  currentUsers?: any;
  selectReason?: any;
  expirationDate?: any;
  startDate?: any;
  cvaNo?: any;
  selectReasonOtherDesc?: any;
  prefix?: any;
  selectedReasonOption?: any;
}

export interface customerType {
  ucid: string;
  ucName: string;
  dcn: string;
  dcName: string;
}
export interface getAccountsCountResponse {
  code: number;
  count: number;
  description: 'string';
}
export interface cancelReasonsType {
  label: string;
  value: any;
}
export interface AccountFieldNamesModal {
  customerAccount?: boolean;
  type?: boolean;
  prefix?: boolean;
  saleReason?: boolean;
  expirationDate?: boolean;
}
export interface ProductPlanInfoModal {
  productPlan?: boolean;
  dealerCode?: boolean;
  customerAccount?: boolean;
  ownership?: boolean;
  cwsId?: boolean;
  prefix?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  term?: boolean;
  autoRenew?: boolean;
  store?: boolean;
  saleReason?: boolean;
  dealerCustomerName?: boolean;
  customerName?: boolean;
}

export interface PLAN_TEMPLATE {
  actionType: string;
  accountType?: string;
  accountDetails?: AccountFieldNamesModal;
  headerTitle: string;
  productPlanInfo?: ProductPlanInfoModal;
  searchCws?: boolean;
  subscriptionNote?: boolean;
}

export const Plan_Template: PLAN_TEMPLATE = {
  actionType: '',
  accountType: '',
  accountDetails: {},
  headerTitle: '',
  productPlanInfo: {},
  searchCws: false,
  subscriptionNote: false,
};
