import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DspCommonService } from 'apps/dsp-ui/src/app/services/dsp-common.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';

@Component({
  selector: 'dsp-next-gen-ui-blocked-devices',
  templateUrl: './blocked-devices.component.html',
  styleUrls: ['./blocked-devices.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockedDevicesComponent implements OnInit, OnChanges {
  blockedDeviceType: any;
  deviceType: any;
  blockingDate: any;
  blockedDeviceTypeList: any;
  blockingMonth: any;
  blockingYear: any;
  pastTooltip: boolean = false;
  comparisonResult: any;
  futureTooltip: boolean = false;
  dspStoreData: any;
  @Input() rowData: any;
  is2GRetirement1b = false;
  effectiveDate: any;
  constructor(
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService,
    private dspCommonService: DspCommonService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.blockedDeviceType = this.dspStoreData.dspConfig.blockedDeviceTypes;
      this.is2GRetirement1b = this.dspStoreData?.featureFlagInfo?.is2GRetirement1b
        ? this.dspStoreData?.featureFlagInfo?.is2GRetirement1b
        : false;
    });
    this.deviceType = this.rowData?.deviceType;
    this.blockingDate = this.getBlockingDate(this.deviceType);
    this.setBlockingStatus();
  }
  ngOnChanges(): void {
    this.deviceType = this.rowData?.deviceType;
    this.blockingDate = this.getBlockingDate(this.deviceType);
    this.setBlockingStatus();
  }
  getBlockingDate(deviceType: string): string | null {
    let blockedDevices: Array<{ deviceType: string; blockingDate: string }>;
    try {
      blockedDevices = this.blockedDeviceType ? JSON.parse(this.blockedDeviceType) : null;
    } catch (error) {
      console.error('Invalid JSON string:', error);
      return null;
    }
    const blockedDevice = blockedDevices?.find((device) => device.deviceType === deviceType);
    //blockedDeviceTypeList - to display html block
    this.blockedDeviceTypeList = blockedDevice ? blockedDevice.deviceType : null;
    return blockedDevice ? blockedDevice.blockingDate : null;
  }
  setBlockingStatus() {
    let userPreferenceDate = this.userPreferenceService.getUserPreferenceCurrentDate();
    const dateToString = new Date(this.blockingDate);
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    this.effectiveDate = formatter.format(dateToString);
    this.blockingMonth = this.dspCommonService.getMonthString(dateToString);
    this.blockingYear = dateToString.getFullYear();
    if (userPreferenceDate >= this.blockingDate) {
      // past - blocked
      this.pastTooltip = true;

      this.comparisonResult = 'Plans restricted.';
    } else if (userPreferenceDate < this.blockingDate) {
      //future - block pending
      this.futureTooltip = true;

      this.comparisonResult = 'Plans will become restricted.';
    }
  }
}
