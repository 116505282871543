import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { isDate, isEmpty } from 'lodash-es';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  rootURL = '';
  notificationTypes!: any;
  refreshLandingPage!: any;
  @Output() reloadSubsetFormEvent = new EventEmitter<any>();
  private notificationSubject = new Subject<any>();
  notificationObservable = this.notificationSubject.asObservable();
  private getuserNotificationSubject = new Subject<any>();
  getuserNotificationObservable = this.getuserNotificationSubject.asObservable();
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.refreshLandingPage = new BehaviorSubject<boolean>(false);
  }

  getDealerInfo(rowData: any) {
    if (rowData) {
      const trimmedDealerCode = rowData?.dealerCode?.trim();
      const trimmedDealerName = rowData?.dealerName?.trim();
      if (trimmedDealerName && trimmedDealerCode) {
        return `${trimmedDealerCode} - ${trimmedDealerName}`;
      } else if (trimmedDealerName) {
        return trimmedDealerName;
      } else if (trimmedDealerCode) {
        return trimmedDealerCode;
      }
    }
    return '-';
  }
  searchUser(queryParams: any) {
    return this.http.get(this.rootURL + '/user/cwsid/', {
      params: queryParams,
    });
  }

  onboardUser(body: any) {
    return this.http.post(this.rootURL + '/user/onboard', body);
  }

  getFeatures() {
    return this.http.get(this.rootURL + '/user/featureToggle/configure', { responseType: 'json' });
  }

  updateFeatureStatus(body: any) {
    return this.http.post(this.rootURL + '/user/save/feature', body);
  }

  getDealerFeatures() {
    return this.http.get(this.rootURL + '/user/configurable/features');
  }

  getSubFeatures(feature: any) {
    return this.http.get(this.rootURL + `/user/dealer/feature/configure?feature=${feature}`);
  }

  removeDealer(feature: any) {
    return this.http.delete(this.rootURL + '/user/dealer/feature/configure', {
      params: { feature: feature.featureName, dealerCode: feature.dealerCode },
    });
  }

  searchDealer(searchText: any) {
    return this.http.get(this.rootURL + '/user/dealer/dealercode/dealername', {
      params: { searchText },
    });
  }

  reloadSubFeature(feature: any, event: any) {
    this.reloadSubsetFormEvent.emit({ feature, event });
  }

  addUpdateDealer(payload: any) {
    return this.http.post(this.rootURL + '/user/dealer/feature/configure', payload);
  }
  getUserNotifications(body: any) {
    return this.http.post(this.rootURL + '/usernotification/v2/getUserNotification', body);
  }

  getMessageNotificationList() {
    return this.http.get(this.rootURL + '/usernotification/getNotificationMessage');
  }
  updateNotificationFormData(notificationFormData: any) {
    this.notificationSubject.next(notificationFormData);
  }

  refreshTable(data: any) {
    this.getuserNotificationSubject.next(data);
  }

  createReleaseNotes(payload: any) {
    return this.http.post(this.rootURL + '/usernotification/createReleaseNotes', payload);
  }

  editReleaseNotes(payload: any) {
    return this.http.post(this.rootURL + '/usernotification/updateReleaseNotes', payload);
  }

  getDateTime(date: any, time: any) {
    const dateTime =
      date === ''
        ? this.getMomentConvertedDate(new Date()) +
          ' ' +
          this.getMomentConvertedTime(new Date().toTimeString().substr(0, 5))
        : this.getMomentConvertedDate(date) + ' ' + this.getMomentConvertedTime(time?.formattedTime);
    return dateTime;
  }

  getMomentConvertedDate(date: any) {
    const inputDate = moment(date);
    return inputDate.format('MM/DD/YYYY');
  }

  getMomentConvertedTime(time: any) {
    const inputTime = moment(time, 'HH:mm:ss');
    return inputTime.format('HH:mm:ss');
  }

  deleteUserNotification(releaseNotesId: any) {
    return this.http.delete(this.rootURL + '/usernotification/deleteReleaseNotes', { body: releaseNotesId });
  }

  getReleaseDetails() {
    return this.http.get(this.rootURL + '/usernotification/getReleaseDetails');
  }

  setDontShowPopup() {
    return this.http.get(this.rootURL + '/usernotification/setDontShowFlagForAdminConfig');
  }
}
