<div class="cc-modal new-accounts-create">
  <div class="cc-modal-header header-first">
    <div class="cc-modal-title top-center-head">{{ addPlanConfig?.headerTitle }}</div>
    <div class="cc-modal-close" (click)="closeModel()">
      <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 17.3411L17 10.3411"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 17.3411L10 10.3411"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="13.5" cy="13.8411" r="12.5" stroke="black" stroke-width="2" />
      </svg>
    </div>
  </div>
  <div class="cc-modal-header header-second">
    <div class="tracker-div" id="tracker">
      <cc-progress-tracker
        #accountCreationTracker
        [activeStepIndex]="trackerIndex"
        [trackerData]="dynamicTrackerData"
        [isTheme]="isTheme"
        (changeTracker)="progressTracker($event)"
      >
      </cc-progress-tracker>
    </div>
  </div>
  <div class="cc-modal-content modal-content-padding" id="new-plan-warning-element">
    <ng-container *ngIf="currentPageIndex === 0">
      <dsp-next-gen-ui-product-plan
        [createSubscriptionForm]="createSubscriptionForm"
        [selectedData]="selectedFormData"
        [productData]="productsList"
        [planData]="productPlansList"
        (fetchPlans)="fetchPlans()"
      ></dsp-next-gen-ui-product-plan>
    </ng-container>
    <ng-container *ngIf="currentPageIndex === 1">
      <div
        *ngIf="
          selectedFormData.plan === subscribeAccountsConst.VM ||
          selectedFormData.plan === subscribeAccountsConst.TT ||
          selectedFormData.plan === subscribeAccountsConst.SMA
        "
      >
        <dsp-next-gen-ui-account-details
          [selectedData]="selectedFormData"
          [salesData]="salesList"
          [addPlanConfig]="addPlanConfig"
        ></dsp-next-gen-ui-account-details>
        <ng-container
          *ngIf="
            (selectedFormData.plan === subscribeAccountsConst.VM && selectedFormData.customer?.ucid !== '') ||
            (selectedFormData.plan === subscribeAccountsConst.TT &&
              selectedFormData?.licenseType === subscribeAccountsConst.ULU)
          "
        >
          <dsp-next-gen-ui-subscription-note [planType]="selectedFormData.plan"></dsp-next-gen-ui-subscription-note>
        </ng-container>
      </div>
      <div *ngIf="selectedFormData.plan === subscribeAccountsConst.SIS_USB">
        <dsp-next-gen-ui-search-cws [selectedData]="selectedFormData"></dsp-next-gen-ui-search-cws>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        currentPageIndex === 2 &&
        (selectedFormData.plan === subscribeAccountsConst.TT || selectedFormData.plan === subscribeAccountsConst.SMA)
      "
    >
      <dsp-next-gen-ui-search-cws [selectedData]="selectedFormData"></dsp-next-gen-ui-search-cws>
      <ng-container *ngIf="selectedFormData.selectedUsers.length > 0">
        <dsp-next-gen-ui-subscription-note [planType]="selectedFormData.plan"></dsp-next-gen-ui-subscription-note>
      </ng-container>
    </ng-container>
  </div>
  <div class="cc-modal-footer">
    <button
      cc-btn-secondary-outline
      [setTheme]="true"
      [ngClass]="currentPageIndex === 0 ? 'hide-back-button' : ''"
      (click)="onPrevious()"
      class="footer-buttons"
    >
      Back
    </button>
    <button
      cc-btn-primary
      [setTheme]="true"
      [disabled]="!selectedFormData.isValidForm"
      (click)="onNext()"
      class="footer-buttons"
    >
      {{ updateSubmitButtonLabel() }}
    </button>
  </div>
</div>
