import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { CcDrawerRef, CcDrawerConfig, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { hasIn, isEmpty, isEqual } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent implements OnInit {
  deviceData: any;
  deviceInfo: any;
  baseConfigInfo: any;
  ConfigInfo: any;
  baseConfig: any;
  customerConfig: any;
  radioInfo: any;
  cellularRadio: any;
  satelliteRadio: any;
  wifiRadio: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isTimeZoneSelectionEnabled: any;
  showTruncatedText = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    private assetDrawerService: AssetDrawerService,
    public dialogRef: CcDrawerRef<DeviceInfoComponent, CcDrawerConfig>,
    @Inject(CC_DRAWER_DATA)
    public assetInfo: any,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.isTimeZoneSelectionEnabled = dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.showTruncatedText = dsp?.featureFlagInfo?.showTruncatedText
        ? dsp?.featureFlagInfo?.showTruncatedText
        : false;
    });
    this.getDeviceInformation();
  }
  getDeviceInformation() {
    const payload = { SerialNumber: this.assetInfo?.make + '|' + this.assetInfo?.serialNumber };
    this.assetDrawerService.getDeviceInfo(payload).subscribe({
      next: (response) => {
        this.deviceInfo = response;
        this.deviceData = this.deviceInfo?.devicemodel?.devices[0];
        this.ConfigInfo = this.deviceData?.deviceConfig;
        this.radioInfo = this.deviceInfo?.radioInfoList?.radios;
        this.assignConfig(this.ConfigInfo);
        this.assignRadio(this.radioInfo);
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  getColumnToolTipData() {
    if (this.deviceData?.gatewaySoftwarePartNumber?.length >= 30) {
      return this.deviceData?.gatewaySoftwarePartNumber;
    } else {
      return '';
    }
  }
  getIdToolTipData() {
    if (this.deviceData?.deviceId?.length >= 30) {
      return this.deviceData?.deviceId;
    } else {
      return '';
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  assignConfig(configData: any) {
    for (let i = 0; i < configData?.length; i++) {
      if (configData[i].configType == 'Base Config') {
        this.baseConfig = configData[i];
      } else if (configData[i].configType == 'Customer Config') {
        this.customerConfig = configData[i];
      }
    }
  }
  assignRadio(radioData: any) {
    for (let i = 0; i < radioData?.length; i++) {
      if (radioData[i].communicationMethod.toLocaleLowerCase() == 'cellular') {
        this.cellularRadio = radioData[i];
      } else if (radioData[i].communicationMethod.toLocaleLowerCase() == 'satellite') {
        this.satelliteRadio = radioData[i];
      } else if (radioData[i].communicationMethod.toLocaleLowerCase() == 'wifi') {
        this.wifiRadio = radioData[i];
      }
    }
  }
  closeModal() {
    this.dialogRef.close();
  }

  updateConfigInfo(configOption: string) {
    if (hasIn(this.baseConfig, configOption)) {
      const configInfo = this.baseConfig[configOption];
      return configInfo && !isEqual(configInfo, 'NA') ? configInfo : '-';
    } else {
      return '-';
    }
  }

  updateCustomerConfigInfo(configOption: string) {
    if (hasIn(this.customerConfig, configOption)) {
      const configInfo = this.customerConfig[configOption];
      return configInfo && !isEqual(configInfo, 'NA') ? configInfo : '-';
    } else {
      return '-';
    }
  }

  getConvertedTimeZone(dateTime: any) {
    if (this.isTimeZoneSelectionEnabled) {
      if (!isEmpty(dateTime) && !isEqual(dateTime, '-')) {
        return this.userPreferenceService.convertTimeToSelectedZone(dateTime, false);
      } else {
        return '-';
      }
    } else {
      return dateTime;
    }
  }
  getLocalTimeZone() {
    return this.isTimeZoneSelectionEnabled === true
      ? '(' + this.userPreferenceService.getLocalTimeZone() + ')'
      : '(UTC)';
  }
}
