<span class="site-panel-items filter-container">
  <span cc-tooltip="Filter">
    <cc-icon *ngIf="isFilterEmpty()" class="site-panel-filter-icon" (click)="openFilter('withActions')">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5176 1.43701H1.51758L7.10488 8.33522V17.437L11.9303 15.7125V8.33522L17.5176 1.43701Z"
          stroke="black"
        />
      </svg>
    </cc-icon>
    <svg
      class="filter-icon-checked"
      *ngIf="!isFilterEmpty()"
      (click)="openFilter('withActions')"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.427371 5.25H21.5726L14.4643 14.0261V22.5883L7.53571 25.0645V14.0261L0.427371 5.25ZM3.57263 6.75L9.03571 13.4948V22.9355L12.9643 21.5315V13.4948L18.4274 6.75H3.57263Z"
        fill="black"
      />
      <path
        d="M25.8335 7.00001C25.8335 10.2217 23.2218 12.8333 20.0002 12.8333C16.7785 12.8333 14.1668 10.2217 14.1668 7.00001C14.1668 3.77835 16.7785 1.16668 20.0002 1.16668C23.2218 1.16668 25.8335 3.77835 25.8335 7.00001Z"
        fill="white"
      />
      <path
        d="M27 7C27 10.866 23.866 14 20 14C16.134 14 13 10.866 13 7C13 3.13401 16.134 0 20 0C23.866 0 27 3.13401 27 7Z"
        fill="white"
      />
      <path
        d="M20.0002 1.16668C16.7694 1.16668 14.1668 3.76924 14.1668 7.00001C14.1668 10.2308 16.7694 12.8333 20.0002 12.8333C23.2309 12.8333 25.8335 10.2308 25.8335 7.00001C25.8335 3.76924 23.2309 1.16668 20.0002 1.16668ZM23.6797 5.56414L20.0002 9.51287C19.9104 9.60262 19.8207 9.69232 19.6412 9.69232C19.5515 9.69232 19.372 9.6026 19.372 9.6026L16.7694 7.08973C16.5899 6.91025 16.5899 6.64106 16.7694 6.46157C16.9489 6.28208 17.2181 6.28208 17.3976 6.46157L19.7309 8.70516L23.0515 5.11542C23.2309 4.93593 23.5002 4.93593 23.6797 5.11542C23.8591 5.11542 23.8591 5.38465 23.6797 5.56414Z"
        fill="#2595FF"
      />
    </svg>
  </span>
</span>
