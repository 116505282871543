import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-site-status',
  templateUrl: './site-status.component.html',
  styleUrls: ['./site-status.component.scss'],
})
export class SiteStatusComponent implements OnInit {
  public value: any;
  public rowData: any;

  // constructor() {}

  ngOnInit(): void {
    this.rowData = this.value;
  }
}
