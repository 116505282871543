import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdatePIDService {
  constructor() {}

  updatedPIDValue = new BehaviorSubject<object>({ serialNo: '', make: '' });

  updateSerialNo(newSerialNo: any, newMake: any) {
    this.updatedPIDValue.next({ serialNo: newSerialNo, make: newMake });
  }
}
