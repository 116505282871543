import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { PendingRequestFormData } from '../self-serve.interface';
import { AssetService } from '../../assets/services/asset.service';
import { Subscription } from 'rxjs';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Worklist } from '../../../shared/dspConstants';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Router } from '@angular/router';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import { UserPreferenceService } from '../../../user-preference.service';
import { SharedService } from '../../../shared.service';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { PlanPaymentComponent } from '../../assets/manage-asset-subscription/payment-preview/plan-payment/plan-payment.component';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-pending-request-payment-preview',
  templateUrl: './pending-request-payment-preview.component.html',
  styleUrls: ['./pending-request-payment-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingRequestPaymentPreviewComponent implements OnInit {
  @Input() rowData: any;
  @Input() pendingRequestFormData!: PendingRequestFormData;
  @Output() updateTrackerIndex = new EventEmitter();
  loader = true;
  newSubscriptionData!: any;
  oldSubscriptionData!: any;
  pendingRequestFormSubscription!: Subscription;
  footerMsg = '';
  userActiveFeatures!: any;
  globalInstance: any;
  seeMoreOverlayBasicDetails!: any;
  constructor(
    private assetService: AssetService,
    private store: Store<DSPAppState>,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService,
    private drawerService: DrawerService
  ) {}
  ngOnInit() {
    this.pendingRequestFormData.isValidForm = false;
    this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
    this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
    });
    setTimeout(() => {
      this.loader = false;
      this.pendingRequestFormData.isValidForm = true;
      this.updateTrackerIndex.emit();
    }, 2000);

    this.pendingRequestFormSubscription = this.assetService.pendingRequestFormEvent.subscribe(
      (data: PendingRequestFormData) => {
        this.pendingRequestFormData = data;
        this.newSubscriptionData = this.pendingRequestFormData.newSubscriptionDetails;
        this.oldSubscriptionData = this.pendingRequestFormData.oldSubscriptionDetails;
      }
    );
    this.setSeeMoreDetails();
  }

  setSeeMoreDetails() {
    this.seeMoreOverlayBasicDetails = {
      make: this.rowData?.make ? this.rowData.make : '',
      model: this.rowData?.model ? this.rowData.model : '',
      modelYear: this.rowData?.manufacturingYear ? this.rowData.manufacturingYear : '',
      serialNumber: this.rowData?.serialNumber ? this.rowData.serialNumber : '',
      engineSerialNumber: this.rowData?.engineSerialNo ? this.rowData.engineSerialNo : '',
      reportingState: this.rowData?.reportingState ? this.rowData.reportingState : '',
    };
  }

  getFooterMessage() {
    return this.updateFooterMessage(this.newSubscriptionData.paymentPreviewResponse);
  }

  updateFooterMessage(currentPaymentDetails: any) {
    let footerMsg;
    if (!currentPaymentDetails?.cancelNotificationMessage) {
      footerMsg = '';
    } else if (currentPaymentDetails.cancelNotificationMessage !== '') {
      footerMsg = this.newSubscriptionData.cancelNotificationMessage
        .split('<br/>')
        .join(' ')
        .replace('href="terms/tos"', 'onclick="termSaleModal()"')
        .replace(
          '"/assets/dsp_help_document/index.htm#t=BillingOverview.htm"',
          '"' + environment.redirectURL.helpDocUrl + 'article/Can-I-prepay-for-subscriptions?language=en_US"'
        );
    }
    // if promotion is there and promotion not eligible message also coming from API, we should hide the message.
    if (
      currentPaymentDetails?.new?.promotions?.length > 0 &&
      currentPaymentDetails.cancelNotificationMessage.indexOf(Worklist.PROMOTION_NOT_ELIGIBLE) != -1
    ) {
      footerMsg = currentPaymentDetails.cancelNotificationMessage
        .split('<br/>')
        .join(' ')
        .replace(Worklist.PROMOTION_NOT_ELIGIBLE, '');
    }
    const TOS_REGEX = new RegExp('By submitting your order, you are agreeing to our <a[^>]*>Terms of Sale</a>');
    if (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) {
      footerMsg = footerMsg.replace(TOS_REGEX, '');
    }
    return footerMsg;
  }

  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }

  openDrawer(subscriptionData: any, paymentType: any) {
    const applicationName = subscriptionData?.applicationName ? subscriptionData.applicationName : '';
    const basePlan = subscriptionData?.basePlan?.name ? subscriptionData.basePlan.name : '';

    this.drawerService.openDrawer<PlanPaymentComponent, CcDrawerConfig>(PlanPaymentComponent, {
      data: {
        rowData:
          paymentType === 'current'
            ? subscriptionData?.paymentPreviewResponse?.current
            : subscriptionData?.paymentPreviewResponse?.new,
        applicationName: applicationName,
        basePlan: basePlan,
        seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
  }

  displayAmount(amount: any) {
    if (amount === 'N/A' || isEmpty(amount)) {
      return '-';
    }
    return this.userPreferenceService.convertNumberFormat(amount, true);
  }

  getConvertedDate(expirationDate: any) {
    return !isEmpty(expirationDate) ? this.userPreferenceService.convertDate(expirationDate) : '';
  }
}
