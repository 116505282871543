import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CC_DRAWER_DATA,
  CcDrawerRef,
  CcModal,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { cloneDeep, findIndex, isEmpty, remove, some } from 'lodash-es';
import { Common, PopupButtons, ProgressTrackerLabels } from '../../shared/dspConstants';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { AssetDrawerService } from '../../services/asset-drawer.service';
import { DspUtilsCommonService } from '../../services/dsp-utils-common.service';
import { DSPAppState } from '../../store/state/dsp.state';
import { AssetService } from '../assets/services/asset.service';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
import { Worklist } from '../../shared/dspConstants';
import { FilterAssetsV2Payload } from '../assets/asset-drawer/asset-drawer-interface';
import {
  PendingRequestFormData,
  PlanData,
  PrepayData,
  PromotionData,
  SelfServeSubscriptionData,
  VLRequestData,
} from './self-serve.interface';
import { PaymentPreviewPayload } from '../assets/asset-interface';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';
import { SelfServeService } from '../../self-serve.service';
import { PopupData } from '../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../common-confirmation-popup/common-confirmation-popup.component';
import { DateFormattingService } from '../../services/date-formatting.service';

@Component({
  selector: 'dsp-next-gen-ui-manage-pending-request',
  templateUrl: './manage-pending-request.component.html',
  styleUrls: ['./manage-pending-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManagePendingRequestComponent implements OnInit {
  isTheme = '2.0';
  currentPageIndex = 0; // for UI components
  trackerLabel = ProgressTrackerLabels;
  trackerIndex = 0;
  trackerData = [
    {
      label: this.trackerLabel.PENDING_REQUEST,
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: this.trackerLabel.PAYMENT_PREVIEW,
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  dynamicTrackerData: any;
  launchType: any;
  gotAssetInfo = true;
  gotOwnershipInfo = true;
  gotRequestInfo = true;
  gotSubscriptionInfo = false;
  dspStoreData: any;
  selectedRowData: any;
  dealer = fetchLoggedInDealerDetails();
  searchType: any;
  telUserType: any;
  userObj: any;
  serviceMapping: any;
  systemError = Common.SYSTEM_ERROR;
  hasPartialOwnershipData!: boolean;
  pendingRequestFormData: PendingRequestFormData = {
    isValidForm: false,
    customer: {},
    vlRequestDetails: {},
    status: '',
  };
  pendingRequestFormSubscription!: Subscription;
  isEditFlow = false;
  b2cCustomers: any;
  getNewSubscriptionData = false;
  enableAutoSelectAddon: any;
  catalogMapping: any;
  oldSubscriptionDetails: any = [];
  modalRef: any;
  constructor(
    @Inject(CC_DRAWER_DATA)
    public assetRowData: any,
    public managePendingRequestContainerRef: CcDrawerRef<ManagePendingRequestComponent>,
    public assetService: AssetService,
    public assetDrawerService: AssetDrawerService,
    public selfServeService: SelfServeService,
    public messageBar: MessageBar,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    private needsReviewService: NeedsReviewService,
    public dspUtilsService: DspUtilsCommonService,
    private loaderService: LoaderService,
    private dateFormattingService: DateFormattingService
  ) {}
  ngOnInit() {
    this.dynamicTrackerData = cloneDeep(this.trackerData);
    this.launchType = this.assetRowData.type;
    this.selectedRowData = cloneDeep(this.assetRowData?.selectedAssetDetails);
    this.isEditFlow = this.selectedRowData.reportingState === Worklist.SUBSCRIBED ? true : false;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      // this.billingPopUpInfo = JSON.parse(dsp.dspConfig?.['billingPopUpInfo']) || [];
      // this.catOnlyAddons = JSON.parse(dsp.dspConfig?.['CAT_Only_Addon']) || [];
      // this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
      // this.userPreferences = dsp.userActiveFeatures?.userPreferences || [];
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.catalogMapping = dsp.deviceCatalog || {};
      this.serviceMapping = dsp.serviceMapping || {};
      this.enableAutoSelectAddon = dsp.dspConfig?.['enableAutoAddon']
        ? JSON.parse(dsp.dspConfig?.['enableAutoAddon'])
        : [];
      // this.languageTranslations = dsp.languageTranslations || {};
      // this.billingAccounts = dsp.billingAccounts?.accountDetails || [];
      // this.dspConfig = dsp.dspConfig || {};
      // this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
      //   ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
      //   : false;
      // this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
    });
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
      this.userObj = user?.user || {};
    });
    this.setAssetData();
    this.pendingRequestFormSubscription = this.assetService.pendingRequestFormEvent.subscribe(
      (data: PendingRequestFormData) => {
        this.pendingRequestFormData = data;
        if (this.assetRowData.isApproved) {
          this.pendingRequestFormData.status = Common.APPROVED;
          this.onNext();
        }
        this.handleProgressTrackerUpdate();
      }
    );
  }
  ngOnDestroy() {
    this.pendingRequestFormSubscription.unsubscribe();
  }
  handleProgressTrackerUpdate() {
    const requestStatus = this.pendingRequestFormData.status;
    if (requestStatus === Common.APPROVED) {
      const isPaymentScreenAdded = this.checkTrackerData(this.trackerLabel.PAYMENT_PREVIEW);
      const isEnterReasonScreenAdded = this.checkTrackerData(this.trackerLabel.ENTER_REASON);
      if (isEnterReasonScreenAdded) {
        remove(this.dynamicTrackerData, (row: any) => row.label === this.trackerLabel.ENTER_REASON);
      }

      if (!isPaymentScreenAdded) {
        const obj = {
          label: this.trackerLabel.PAYMENT_PREVIEW,
          disabled: false,
          clickable: false,
          warning: false,
          danger: false,
          description: '',
        };
        this.dynamicTrackerData.push(obj);
      }
    } else if (requestStatus === Common.DECLINED) {
      const isPaymentScreenAdded = this.checkTrackerData(this.trackerLabel.PAYMENT_PREVIEW);
      const isEnterReasonScreenAdded = this.checkTrackerData(this.trackerLabel.ENTER_REASON);
      if (isPaymentScreenAdded) {
        remove(this.dynamicTrackerData, (row: any) => row.label === this.trackerLabel.PAYMENT_PREVIEW);
      }

      if (!isEnterReasonScreenAdded) {
        const obj = {
          label: this.trackerLabel.ENTER_REASON,
          disabled: false,
          clickable: false,
          warning: false,
          danger: false,
          description: '',
        };
        this.dynamicTrackerData.push(obj);
      }
    }
  }

  checkTrackerData(labelValue: string) {
    return some(this.dynamicTrackerData, ['label', labelValue]);
  }

  setAssetData() {
    if (this.launchType == 'LANDING') {
      this.gotAssetInfo = false;
      this.gotOwnershipInfo = false;
      this.gotRequestInfo = false;
      this.loadAssetAndOwnershipData();
    } else if (this.launchType == 'DRAWER') {
      if (!this.selectedRowData.vlRequestDetails) {
        this.showToastMessage(this.systemError, 'error');
        this.gotRequestInfo = false;
        this.pendingRequestFormData.isValidForm = false;
      } else if (this.selectedRowData?.ownershipDetails?.length >= 1) {
        this.pendingRequestFormData.vlRequestDetails = this.selectedRowData.vlRequestDetails;
        this.selectedRowData.ownershipDetails = this.filterOwnershipRecords(this.selectedRowData.ownershipDetails);
        this.checkIfVLOwnershipRecordMatched(this.selectedRowData.ownershipDetails);
      }
    }
  }

  getBasePlans(obj: any) {
    let basePlanList: any = [];
    obj?.forEach((val: any) => {
      if (!isEmpty(val.type) && val.type === 'Base') {
        basePlanList.push(val.planName);
      }
    });

    return basePlanList;
  }

  getOptionalPlans(obj: any) {
    let optionalPlanList: any = [];
    obj?.forEach((val: any) => {
      if (!isEmpty(val.type) && val.type == 'Addon') {
        optionalPlanList.push(val.planName);
      }
    });

    return optionalPlanList;
  }

  loadAssetAndOwnershipData() {
    this.getFilteredAssets();
    this.fetchVLRequestDetails();
  }

  fetchVLRequestDetails() {
    this.selfServeService.getVLRequestDetails(this.selectedRowData.make, this.selectedRowData.serialNumber).subscribe({
      next: (res: any) => {
        if (res) {
          this.gotRequestInfo = true;
          this.constructVLRequestData(res);
        }
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error');
        this.gotRequestInfo = false;
        this.pendingRequestFormData.isValidForm = false;
      },
    });
  }

  constructVLRequestData(requestData: any) {
    const data: VLRequestData = {
      dealerCode: requestData?.dealerCode || '',
      ucid: requestData?.requcid || '',
      application: requestData?.requestApplicationName || '',
      name: requestData?.requestDetailsList?.length ? requestData?.requestDetailsList[0]?.name : '',
      email: requestData?.requestDetailsList?.length ? requestData?.requestDetailsList[0]?.email : '',
      phoneNumber: requestData?.requestDetailsList?.length ? requestData?.requestDetailsList[0]?.phoneNumber : '',
      date_time: requestData?.requestTime || '',
      type: requestData?.requestDetailsList?.length ? requestData?.requestDetailsList[0]?.type : '',
      plan_name: requestData?.requestPlanName || '',
    };
    this.selectedRowData.vlRequestDetails = data;
    this.pendingRequestFormData.vlRequestDetails = data;
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }

  checkForAutoSelectAddons(vlRequestDetails: any) {
    if (
      vlRequestDetails?.plan_name &&
      this.enableAutoSelectAddon?.length &&
      this.enableAutoSelectAddon.indexOf(vlRequestDetails.plan_name) > -1
    ) {
      //find list of eligible customer level optional plans
      let OptionalPlansObj =
        this.catalogMapping[this.selectedRowData.deviceType][vlRequestDetails.application]['Customer'][
          vlRequestDetails.plan_name
        ]['services']['All'];
      const ConditionalAddons =
        this.catalogMapping[this.selectedRowData.deviceType][vlRequestDetails.application]['Customer'][
          vlRequestDetails.plan_name
        ]['services']['ConditionalAddons']?.filter((n: any) => {
          return (
            !isEmpty(this.selectedRowData.getSupportedServicesList) &&
            this.selectedRowData.getSupportedServicesList?.indexOf(n?.serviceName) != -1 &&
            n?.isSubscribable
          );
        }) || [];
      OptionalPlansObj = OptionalPlansObj.concat(ConditionalAddons);

      let optionalPlanList: any = [];
      OptionalPlansObj?.forEach((plan: any) => {
        if (plan.isSubscribable) {
          optionalPlanList.push(plan.serviceName);
        }
      });

      const input = {
        dealerCode: this.dealer.dealerCode,
        ucid: this.pendingRequestFormData.customer?.ucid,
        productName: vlRequestDetails.application,
        make: this.selectedRowData.make,
        serialNumber: this.selectedRowData.serialNumber,
        deviceType: this.selectedRowData.deviceType,
        radioSerialNumber: this.selectedRowData.radioSerialNoList,
        cva: false,
        subscriptions: optionalPlanList,
      };
      const optionalPlanObj: any = '';
      this.assetService.getZeroPricedAddons(input).subscribe({
        next: (result: any) => {
          if (result?.length) {
            this.getNewPaymentDetails(vlRequestDetails, result);
          } else {
            this.getNewPaymentDetails(vlRequestDetails);
          }
          if (this.isEditFlow) {
            this.loadExistingData();
          }
        },
        error: (err: any) => {
          this.getNewPaymentDetails(vlRequestDetails);
          if (this.isEditFlow) {
            this.loadExistingData();
          }
        },
      });
    } else {
      this.getNewPaymentDetails(vlRequestDetails);
      if (this.isEditFlow) {
        this.loadExistingData();
      }
    }
  }

  loadExistingData() {
    // find existing subscription details and hit payment preview api

    //mock data
    // const subscribedPlans = this.assetService.getMockData().servicesSubscribedDTO;
    // this.pendingRequestFormData.oldSubscriptionDetails = [];
    // subscribedPlans?.forEach((obj: any) => {
    //   // each product
    //   const basePlanList = this.getBasePlans(obj.dealerLevel);
    //   const optionalPlanList = this.getOptionalPlans(obj.dealerLevel);
    //   this.getOldPaymentDetails(obj, basePlanList, optionalPlanList);
    // });

    //actual data
    this.assetService.getAssetDataSubscribedPlansEdit(this.selectedRowData).subscribe({
      next: (data: any) => {
        if (data) {
          const subscribedPlans = data.servicesSubscribedDTO;
          this.pendingRequestFormData.oldSubscriptionDetails = [];
          subscribedPlans.forEach((obj: any) => {
            // each product
            const basePlanList = this.getBasePlans(obj.dealerLevel);
            const optionalPlanList = this.getOptionalPlans(obj.dealerLevel);
            this.getOldPaymentDetails(obj, basePlanList, optionalPlanList);
          });
        }
      },
      error: (err: any) => {},
    });
  }
  constructNewSubscriptionData(requestData: any, paymentPreviewResponse: any, autoSelectAddons?: any) {
    const selfServeSubscriptionData: SelfServeSubscriptionData = {
      applicationName: requestData?.application || '',
      basePlan: {
        name: requestData?.plan_name || '',
        amount: paymentPreviewResponse?.new?.products
          ? this.assetDrawerService.getAmountFromPaymentPreview(
              paymentPreviewResponse?.new?.products,
              requestData.plan_name
            )
          : '',
      },
      prepay: false,
      prepayDates: {},
      monthlyRate: paymentPreviewResponse?.new?.monthlyRate || '',
      monthlyRateAfterDiscount: paymentPreviewResponse?.new?.monthlyRateAfterDiscount || '',
      cancelNotificationMessage: paymentPreviewResponse?.cancelNotificationMessage || '',
      paymentPreviewResponse: paymentPreviewResponse,
    };

    //loading auto selectable add-ons
    if (autoSelectAddons?.length) {
      let optionalPlanObj: any = [];
      autoSelectAddons.forEach((addon: any) => {
        const obj: PlanData = {
          name: addon,
          amount: this.assetDrawerService.getAmountFromPaymentPreview(paymentPreviewResponse?.new?.products, addon),
        };
        optionalPlanObj.push(obj);
      });

      selfServeSubscriptionData.optionalPlan = optionalPlanObj;
    }
    //loading promotions
    if (paymentPreviewResponse?.new?.promotions.length > 0) {
      const promotions = paymentPreviewResponse?.new?.promotions;
      const promoObj: any = [];
      promotions?.forEach((obj: any) => {
        const promotionObj: PromotionData = {
          name: obj.name,
          amount: obj.amount,
          end_date: obj.promotionEndDate,
        };
        promoObj.push(promotionObj);
      });
      selfServeSubscriptionData.promotion = promoObj;
    } else {
      selfServeSubscriptionData.promotion = [];
    }

    this.pendingRequestFormData.newSubscriptionDetails = selfServeSubscriptionData;
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }

  constructOldSubscriptionData(planObj: any, paymentPreviewResponse: any, basePlanList: any, optionalPlanList: any) {
    const selfServeSubscriptionData: SelfServeSubscriptionData = {
      applicationName: planObj.appName || '',
      basePlan:
        basePlanList.length > 0
          ? {
              name: basePlanList[0] || '',
              amount:
                paymentPreviewResponse?.current?.products && basePlanList[0]
                  ? this.assetDrawerService.getAmountFromPaymentPreview(
                      paymentPreviewResponse?.current?.products,
                      basePlanList[0]
                    )
                  : '',
            }
          : {},
      prepay: planObj.prepay,
      prepayDates: planObj.prepay
        ? this.getPreviousPrepayDates(paymentPreviewResponse?.current?.products, basePlanList[0])
        : {},
      monthlyRate: paymentPreviewResponse?.current?.monthlyRate || '',
      monthlyRateAfterDiscount: paymentPreviewResponse?.current?.monthlyRateAfterDiscount || '',
      cancelNotificationMessage: paymentPreviewResponse?.cancelNotificationMessage || '',
      paymentPreviewResponse: paymentPreviewResponse,
    };

    //loading optional Plans
    if (optionalPlanList?.length) {
      let optionalPlanObj: any = [];
      optionalPlanList?.forEach((addon: any) => {
        const obj: PlanData = {
          name: addon,
          amount:
            paymentPreviewResponse?.current?.products && basePlanList[0]
              ? this.assetDrawerService.getAmountFromPaymentPreview(paymentPreviewResponse?.current?.products, addon)
              : '',
        };
        optionalPlanObj.push(obj);
      });

      selfServeSubscriptionData.optionalPlan = optionalPlanObj;
    }
    //loading promotions
    if (paymentPreviewResponse?.current?.promotions.length > 0) {
      const promotions = paymentPreviewResponse?.current?.promotions;
      const promoObj: any = [];
      promotions?.forEach((obj: any) => {
        const promotionObj: PromotionData = {
          name: obj.name,
          amount: obj.amount,
          end_date: obj.promotionEndDate,
        };
        promoObj.push(promotionObj);
      });
      selfServeSubscriptionData.promotion = promoObj;
    } else {
      selfServeSubscriptionData.promotion = [];
    }
    this.oldSubscriptionDetails.push(selfServeSubscriptionData);
    this.updateOldSubscriptionDetails();
  }

  updateOldSubscriptionDetails() {
    this.pendingRequestFormData.oldSubscriptionDetails = this.oldSubscriptionDetails;
    this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
  }
  getPreviousPrepayDates(products: any, name: any) {
    let prepayData: any = {};
    products?.forEach((product: any) => {
      if (product.name === name) {
        prepayData = {
          contractEndDate: product.contractEndDate,
          contractStartDate: product.contractStartDate,
          duration: product.duration,
        };
      }
    });

    return prepayData;
  }

  getNewPaymentDetails(requestData: any, autoSelectAddons?: any) {
    const rowData = this.selectedRowData;
    const payload: PaymentPreviewPayload = {
      assetMake: rowData?.make || '',
      assetSerialNumber: rowData?.serialNumber || '',
      deviceId: '',
      deviceSerialNumber: rowData?.deviceSerialNumer || '',
      deviceType: rowData?.deviceType || '',
      dealerCode: rowData?.dealerCode || this.dealer?.dealerCode,
      ucid: this.pendingRequestFormData.customer?.ucid || '',
      customerName: this.pendingRequestFormData.customer?.ucidName || '',
      dcnName: this.pendingRequestFormData.customer?.dealerCustName || '',
      dcnNumber: this.pendingRequestFormData.customer?.dealerCustNo || '',
      contractEndDate: '',
      contractStartDate: '',
      applicationName: requestData?.application,
      siteName: '',
      subscriptions: [requestData?.plan_name],
      cva: this.pendingRequestFormData.customer?.cvaDetails?.cvaStatus == 'Available' ? true : false,
      prepay: false,
      isNewSubscription: true,
    };

    if (autoSelectAddons?.length) {
      autoSelectAddons.forEach((element: any) => {
        payload.subscriptions?.push(element);
      });
    }
    this.loaderService.show();
    this.assetDrawerService.getPaymentPreview(payload).subscribe({
      next: (res: any) => {
        setTimeout(() => {
          this.loaderService.hide();
        }, 1000);

        this.constructNewSubscriptionData(requestData, res, autoSelectAddons);
      },
      error: (err: any) => {
        setTimeout(() => {
          this.loaderService.hide();
          this.gotRequestInfo = false;
          this.pendingRequestFormData.isValidForm = false;
          this.showToastMessage(this.systemError, 'error');
        }, 1000);
      },
    });
  }

  getOldPaymentDetails(planObj: any, basePlanList: any, optionalPlanList: any) {
    const rowData = this.selectedRowData;
    const payload: PaymentPreviewPayload = {
      assetMake: rowData?.make || '',
      assetSerialNumber: rowData?.serialNumber || '',
      deviceId: '',
      deviceSerialNumber: rowData?.deviceSerialNumer || '',
      deviceType: rowData?.deviceType || '',
      dealerCode: rowData?.dealerCode || this.dealer?.dealerCode,
      ucid: this.pendingRequestFormData.customer?.ucid || '',
      customerName: this.pendingRequestFormData.customer?.ucidName || '',
      dcnName: this.pendingRequestFormData.customer?.dealerCustName || '',
      dcnNumber: this.pendingRequestFormData.customer?.dealerCustNo || '',
      contractEndDate: planObj.contractEndDate
        ? this.dateFormattingService.formatDate(new Date(planObj.contractEndDate), 'YYYY/MM/DD')
        : '',
      contractStartDate: planObj.contractStartDate
        ? this.dateFormattingService.formatDate(new Date(planObj.contractStartDate), 'YYYY/MM/DD')
        : '',
      applicationName: planObj.appName,
      siteName: '',
      subscriptions: [],
      cva: this.pendingRequestFormData.customer?.cvaDetails?.cvaStatus == 'Available' ? true : false,
      prepay: planObj.prepay,
      isViewSubscription: true,
    };
    this.loaderService.show();
    this.assetDrawerService.getPaymentPreview(payload).subscribe({
      next: (res: any) => {
        setTimeout(() => {
          this.loaderService.hide();
        }, 1000);
        this.constructOldSubscriptionData(planObj, res, basePlanList, optionalPlanList);
      },
      error: (err: any) => {
        setTimeout(() => {
          this.loaderService.hide();
          this.gotRequestInfo = false;
          this.pendingRequestFormData.isValidForm = false;
          this.showToastMessage(this.systemError, 'error');
        }, 1000);
      },
    });
  }
  updateTrackerIndex() {
    this.trackerIndex = this.currentPageIndex + 1;
  }

  getFilteredAssets() {
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.selectedRowData = Object.assign(this.selectedRowData, result);
          if (this.selectedRowData.reportingState == Worklist.SUBSCRIBED) {
            this.selectedRowData.applicationName =
              this.serviceMapping[this.selectedRowData?.dealerSubscriptionId].appName;
          }
          if (this.searchType == Worklist.NO_DEVICE_SEARCH) {
            this.setOwnershipInfoForNoDevice();
          } else {
            this.fetchOwnershipInfo();
          }
          this.gotAssetInfo = true;
        }
      },
      error: (_err: any) => {
        this.gotAssetInfo = false;
        this.showToastMessage(this.systemError, 'error');
        this.pendingRequestFormData.isValidForm = false;
      },
    });
  }
  setFilterAssetsPayload(): FilterAssetsV2Payload {
    if (this.searchType === Worklist.NO_DEVICE_SEARCH) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make || '',
        serialNumber: this.selectedRowData?.serialNumber || '',
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make,
        serialNumber: this.selectedRowData?.serialNumber,
        flag: false,
      };
    }
  }
  setOwnershipInfoForNoDevice() {
    let assetOwners: any[] = [];
    if (this.selectedRowData?.equipmentOwner.length > 0) {
      assetOwners = this.selectedRowData.equipmentOwner;
      assetOwners = this.filterOwnershipRecords(assetOwners);
      this.selectedRowData.ownershipDetails = assetOwners;
    }

    if (this.selectedRowData.ownershipDetails.length == 0 || this.selectedRowData?.equipmentOwner.length === 0) {
      //if no ownership record
      this.gotOwnershipInfo = false;
      this.pendingRequestFormData.customer = {};
      this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
    } else {
      this.pendingRequestFormData.customer = this.selectedRowData.ownershipDetails[0];
      this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
      this.checkForAutoSelectAddons(this.selectedRowData.vlRequestDetails);
      this.getApplicableSubscriptions();
    }
  }
  showToastMessage(
    message: string,
    status: string,
    hostType = 'container-overlay',
    selectorId = 'manage-pending-request'
  ) {
    const config: MessageBarConfig = {
      hostType: hostType,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: selectorId,
      duration: 3000,
    };
    if (status == 'success') {
      config.appOverlayClass = 'manageAsset-overlay';
    }
    this.messageBar.open(message, status, undefined, config, true);
  }

  checkIfVLOwnershipRecordMatched(assetOwners: any) {
    if (assetOwners.length) {
      this.gotOwnershipInfo = true;
      this.pendingRequestFormData.customer = this.selectedRowData.ownershipDetails[0];
      this.checkForAutoSelectAddons(this.selectedRowData.vlRequestDetails);
      this.getApplicableSubscriptions();
      this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
    } else {
      this.gotOwnershipInfo = false;
      this.pendingRequestFormData.customer = {};
      this.assetService.setPendingRequestFormData(this.pendingRequestFormData);
      this.showToastMessage(this.systemError, 'error');
      this.pendingRequestFormData.isValidForm = false;
    }
  }

  fetchOwnershipInfo() {
    const ownershipInfoPayload = {
      assetIds: [this.selectedRowData.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    let assetOwners: any[] = [];
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (response: any) => {
        if (response[this.selectedRowData.assetId]) {
          assetOwners = response[this.selectedRowData.assetId] as any;
          assetOwners = this.filterOwnershipRecords(assetOwners);
          this.selectedRowData.ownershipDetails = assetOwners;
          this.checkIfVLOwnershipRecordMatched(this.selectedRowData.ownershipDetails);
        } else {
          this.selectedRowData.ownershipDetails = [];
          this.checkIfVLOwnershipRecordMatched(this.selectedRowData.ownershipDetails);
        }
      },
      error: (_err: any) => {
        this.selectedRowData.ownershipDetails = [];
        this.checkIfVLOwnershipRecordMatched(this.selectedRowData.ownershipDetails);
      },
    });
  }
  filterOwnershipRecords(assetOwners: any): any {
    const VLOwnershipRecords = assetOwners?.filter((owner: any) => {
      return (
        owner?.dealerCode == this.selectedRowData.vlRequestDetails?.dealerCode &&
        owner?.ucid == this.selectedRowData.vlRequestDetails?.ucid
      );
    });
    return VLOwnershipRecords;
  }

  closeDrawer(value?: any) {
    if (value?.requestStatus === Common.APPROVED) {
      const managePendingRequestDrawerData = {
        requestStatus: Common.APPROVED,
        apiCallNeeded: this.getNewSubscriptionData,
        reportingState: this.pendingRequestFormData.reportingState,
        formData: this.pendingRequestFormData,
      };
      this.managePendingRequestContainerRef.close(managePendingRequestDrawerData);
    } else if (value?.requestStatus === Common.DECLINED) {
      const managePendingRequestDrawerData = {
        requestStatus: Common.DECLINED,
      };
      this.managePendingRequestContainerRef.close(managePendingRequestDrawerData);
    } else {
      this.managePendingRequestContainerRef.close();
    }
  }
  setNextDisabled() {
    return !this.pendingRequestFormData.isValidForm;
  }

  onNext() {
    // trackerIndex is for progress-tracker updates.
    // currentPageIndex is for components to load.
    const { trackerIndex, currentPageIndex } = this;
    if (this.currentPageIndex === this.dynamicTrackerData.length - 1) {
      //final api
      if (this.pendingRequestFormData.status === Common.DECLINED) {
        this.openDeclineConfirmationPopup();
      } else {
        if (this.isEditFlow) {
          this.getPopupMessage();
        } else {
          this.approveSubscription();
        }
      }
    } else {
      this.trackerIndex = trackerIndex + 1;
      this.currentPageIndex = currentPageIndex + 1;
      this.assetRowData.isApproved = false;
    }
  }

  getUpdatedTimeStamp(updatedTime = null) {
    return updatedTime ? updatedTime : null;
  }
  approveSubscription() {
    let PlanInfo: any = [];
    const BaseplanInfo = {
      planName: this.pendingRequestFormData.vlRequestDetails.plan_name,
      planType: 'Base',
    };
    //pushing base plans
    PlanInfo.push(BaseplanInfo);
    if (this.pendingRequestFormData.newSubscriptionDetails?.optionalPlan?.length) {
      //if any auto selected add-ons there
      let optionalPlanData = this.pendingRequestFormData.newSubscriptionDetails?.optionalPlan || [];
      optionalPlanData.forEach((element) => {
        let optionalPlanInfo = {
          planName: element.name,
          planType: 'Addon',
        };
        PlanInfo.push(optionalPlanInfo);
      });
    }
    const subscriptionData = {
      appId: this.getAppId(this.pendingRequestFormData.vlRequestDetails.application),
      selectedOrderId: 1,
      appName: this.pendingRequestFormData.vlRequestDetails.application || '',
      catLevel: PlanInfo,
      customerLevel: PlanInfo,
      dealerLevel: PlanInfo,
      prepay: false,
      contractStartDate: null,
      contractEndDate: null,
    };
    const payload = {
      assetId: `${this.selectedRowData.make}|${this.selectedRowData.serialNumber}`,
      commercialType: this.selectedRowData.deviceType,
      model: this.selectedRowData.model,
      siteId: '',
      prepay: false,
      contractStartDate: null,
      contractEndDate: null,
      servicesSubscribedMultiProductDto: [subscriptionData],
      catPrevLevel: '',
      dealerPrevLevel: '',
      customerPrevLevel: '',
      requestingDealerCode: this.dealer?.dealerCode,
      ucid: this.pendingRequestFormData.customer.ucid,
      ucName: this.pendingRequestFormData.customer.ucidName,
      dealerCustomerId: this.pendingRequestFormData.customer.dealerCustNo,
      dcn: this.pendingRequestFormData.customer.dealerCustNo,
      updatedTimeStamp: this.getUpdatedTimeStamp(this.selectedRowData?.updatedTime || null)
        ? this.selectedRowData.updatedTime
        : new Date().toJSON(),
      billToParty: this.checkB2CCustomer() ? Common.CUSTOMER : Common.DEALER,
      isSelfServe: true,
    };
    this.assetService.subscribeMultiProdAsset(payload).subscribe({
      error: (error: any) => {
        if (error.status === 202) {
          this.getNewSubscriptionData = true;
          this.pendingRequestFormData.reportingState = Worklist.SUBSCRIBED;
          this.assetRowData.selectedAssetDetails.isNewSubscription = true;
          const successResponse = 'Request approved successfully.';
          const selectorId = this.launchType == 'LANDING' ? 'container-id' : 'manage-pending-request';
          const hostType = this.launchType == 'LANDING' ? 'overlay' : 'container-overlay';
          this.showToastMessage(successResponse, 'success', hostType, selectorId);
          this.closeDrawer({
            requestStatus: Common.APPROVED,
            reportingState: this.pendingRequestFormData.reportingState,
            rowData: this.assetRowData.selectedAssetDetails,
            formData: this.pendingRequestFormData,
          });
        } else {
          this.getNewSubscriptionData = false;
          this.showToastMessage(this.systemError, 'error');
          this.closeDrawer();
        }
      },
    });
  }

  openDeclineConfirmationPopup() {
    const popupData: PopupData = {
      title: Common.CONFIRMATION_POPUP_TITLE,
      messageArray: ['Are you sure you want to decline the request for the selected asset?'],
      showCrossMark: false,
      showPrimaryButton: true,
      showSecondaryButton: true,
      primaryButtonText: PopupButtons.SUBMIT,
      secondaryButtonText: PopupButtons.CANCEL,
    };
    this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
      width: '534px',
      type: 'semi-modal',
      data: popupData,
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });

    this.modalRef.afterClosed().subscribe((result: any) => {
      if (result === PopupButtons.SUBMIT) {
        this.declineSubscription();
      }
    });
  }

  getPopupMessage() {
    let messageArray: any;
    if (
      (this.pendingRequestFormData.oldSubscriptionDetails &&
        this.pendingRequestFormData.oldSubscriptionDetails.length > 1) ||
      (this.pendingRequestFormData.oldSubscriptionDetails?.length == 1 &&
        this.pendingRequestFormData.oldSubscriptionDetails[0].applicationName !==
          this.pendingRequestFormData.newSubscriptionDetails?.applicationName &&
        !this.pendingRequestFormData.oldSubscriptionDetails[0].prepay)
    ) {
      //if existing asset having multi product subscription or Single product (diff)subscription without prepay --> Cancel the existing subscription and create new.
      messageArray = [
        'Current asset subscription may include optional plan(s) and/or other product(s), and will not be carried forward with this request. Do you want to continue?',
      ];
    } else if (
      this.pendingRequestFormData.oldSubscriptionDetails &&
      this.pendingRequestFormData.oldSubscriptionDetails.length == 1 &&
      this.pendingRequestFormData.oldSubscriptionDetails[0].prepay
    ) {
      //single product same/different with prepay --> Cancel the existing prepaid subscription and create new.
      messageArray = [
        'You are cancelling or modifying a prepaid fixed-term subscription contract. The prepayment associated to these services may be non-refundable. Do you want to continue?',
      ];
    }

    if (!isEmpty(messageArray)) {
      this.openApproveConfirmationPopup(messageArray);
    } else if (
      //single product same subscription without prepay --> just update the existing subscription
      this.pendingRequestFormData.oldSubscriptionDetails &&
      this.pendingRequestFormData.oldSubscriptionDetails.length == 1 &&
      this.pendingRequestFormData.oldSubscriptionDetails[0].applicationName ===
        this.pendingRequestFormData.newSubscriptionDetails?.applicationName
    ) {
      this.approveSubscription();
    }
  }

  openApproveConfirmationPopup(messageArray: any) {
    const popupData: PopupData = {
      title: Common.CONFIRMATION_POPUP_TITLE,
      messageArray: messageArray,
      showCrossMark: false,
      showPrimaryButton: true,
      showSecondaryButton: true,
      primaryButtonText: PopupButtons.SUBMIT,
      secondaryButtonText: PopupButtons.CANCEL,
    };
    this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
      width: '534px',
      type: 'semi-modal',
      data: popupData,
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });

    this.modalRef.afterClosed().subscribe((result: any) => {
      if (result === PopupButtons.SUBMIT) {
        this.approveSubscription();
      }
    });
  }

  declineSubscription() {
    const payload = {
      standardManufacturerCode: this.selectedRowData.make,
      serialNumber: this.selectedRowData.serialNumber,
      status: this.pendingRequestFormData.status,
      errorReason: 'Dealer Declined',
      dealerNotes: this.pendingRequestFormData.declineReason,
      dealerCode: this.dealer?.dealerCode,
      dealerCwsId: this.userObj?.cwsId,
      dealerEmail: this.userObj?.email,
      ucid: this.selectedRowData.vlRequestDetails?.ucid,
    };
    this.selfServeService.declineRequest(payload).subscribe({
      error: (err: any) => {
        if (err.status === 202) {
          const successResponse = 'Request declined successfully.';
          this.assetRowData.selectedAssetDetails.isNewSubscription = true;
          this.showToastMessage(successResponse, 'success', 'overlay', 'container-id');
          this.closeDrawer({ requestStatus: Common.DECLINED });
        } else {
          this.getNewSubscriptionData = false;
          this.showToastMessage(this.systemError, 'error');
          this.closeDrawer();
        }
      },
    });
  }

  onPrevious() {
    if (this.trackerIndex === 0) {
      this.closeDrawer();
    }
    const { currentPageIndex } = this;
    this.currentPageIndex = currentPageIndex - 1;
    this.trackerIndex = this.currentPageIndex;
    if (this.assetRowData.isApproved) {
      this.assetRowData.isApproved = false;
    }
    this.pendingRequestFormData.isValidForm = true;
  }
  updateSubmitButtonLabel() {
    if (this.currentPageIndex === this.dynamicTrackerData.length - 1) {
      return 'Submit';
    } else {
      return 'Next';
    }
  }
  updateCancelButtonLabel() {
    if (
      this.trackerIndex === 0
      // && this.launchType === 'LANDING' &&
      // this.assetRowData?.selectedAssetDetails?.reportingState !== 'Subscribed'
    ) {
      return 'Cancel';
    } else {
      return 'Back';
    }
  }

  getApplicableSubscriptions() {
    const assetData = {
      make: this.selectedRowData.make,
      serialNumber: this.selectedRowData.serialNumber,
      planIds: [],
      includePricing: true,
      deviceType: this.selectedRowData?.deviceType,
      dealerCode: this.dealer?.dealerCode,
      assetId: this.selectedRowData?.assetId,
      assetGuiId: this.selectedRowData.appId,
      isCVAAvailable: this.pendingRequestFormData.customer?.cvaDetails?.cvaStatus == 'Not Available' ? false : true,
    };
    this.assetDrawerService.getApplicableSubscriptions(assetData).subscribe({
      next: (result: any) => {
        this.selectedRowData.applicableSubscriptions = result?.applicableProductsDTOMap || {};
      },

      error: (err: any) => {},
    });
  }

  getAppId(appName: any) {
    for (const [key, value] of Object.entries(this.selectedRowData.applicableSubscriptions)) {
      const valueData: any = value;
      if (valueData?.applicationName === appName) {
        return valueData.applicationId;
      }
    }
  }

  checkB2CCustomer() {
    const customerInfo = this.pendingRequestFormData.customer;
    if (
      customerInfo !== 'None' &&
      findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo?.ucid]) !== -1 &&
      findIndex(this.b2cCustomers?.b2cSupportedApps, 'New VisionLink') !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
}
