<div class="account-action-menu">
  <cc-icon
    class="icon-size"
    cc-menu
    [template]="accountMenuOptions"
    [OverlayPosition]="'bottomright'"
    (click)="$event.stopPropagation()"
    [ngClass]="{
      disable: accountInfo?.subsDtl?.name === subscribeAccountsConst.VM && telUserType === subscribeAccountsConst.DEALER
    }"
  >
    row-action
  </cc-icon>
</div>
<ng-template #accountMenuOptions>
  <div class="group-info__options__list">
    <cc-list>
      <cc-list-item
        *ngIf="
          (accountInfo.type === subscribeAccountsConst.CUSTOMER &&
            accountInfo?.subsDtl?.name === subscribeAccountsConst.TT) ||
          (accountInfo?.subsDtl?.name === subscribeAccountsConst.SMA && accountInfo.store === 'DSP')
        "
        (listItemClick)="openEditDrawer(accountInfo, subscribeAccountsConst.OPERATIONTYPE_UPDATE)"
        class="group-info__options__list-item"
      >
        <span class="edit-plan">
          <cc-icon fontSet="icomoon">pencil</cc-icon>
          Edit Plan
        </span>
      </cc-list-item>

      <cc-list-item
        *ngIf="accountInfo?.subsDtl?.name !== subscribeAccountsConst.SMA"
        (listItemClick)="openEditDrawer(accountInfo, subscribeAccountsConst.OPERATIONTYPE_CANCEL)"
        class="group-info__options__list-item"
      >
        <span class="cancel-plan" [ngStyle]="{ color: '#DE2222' }">
          <cc-icon fontSet="icomoon">close</cc-icon>
          Cancel Plan
        </span>
      </cc-list-item>
      <cc-list-item
        *ngIf="accountInfo?.subsDtl?.name === subscribeAccountsConst.SMA && accountInfo.store === 'DSP'"
        (listItemClick)="openEditDrawer(accountInfo, subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL)"
        class="group-info__options__list-item"
      >
        <span class="cancel-plan" [ngStyle]="{ color: '#DE2222' }">
          <cc-icon fontSet="icomoon">close</cc-icon>
          Cancel Plan
        </span>
      </cc-list-item>
      <cc-list-item
        *ngIf="
          (accountInfo.type === subscribeAccountsConst.CUSTOMER ||
            accountInfo.type === subscribeAccountsConst.DEALER) &&
          accountInfo?.subsDtl?.name === subscribeAccountsConst.SMA
        "
        (listItemClick)="openEditDrawer(accountInfo, subscribeAccountsConst.OPERATIONTYPE_VIEW)"
        class="group-info-viewplan"
      >
        <span class="view-plan"> <cc-icon fontSet="icomoon">view-details</cc-icon> View Details </span>
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>
