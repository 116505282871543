<div class="cc-drawer edit-site-drawer">
  <div class="cc-drawer__header">
    <span class="cc-headerText cc-drawer__ellipsis"> Edit Site </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-modal-header header-second">
    <div class="tracker-div" id="tracker">
      <cc-progress-tracker
        #siteCreationTracker
        [activeStepIndex]="activeStepIndex"
        [trackerData]="dynamicTrackerData"
        [isTheme]="isTheme"
        (changeTracker)="output($event)"
      >
      </cc-progress-tracker>
    </div>
  </div>
  <div class="cc-drawer__content">
    <dsp-next-gen-ui-site-info [siteData]="list.siteInfo" [displayType]="'edit'"></dsp-next-gen-ui-site-info>
    <ng-container *ngIf="activeStepIndex === 0">
      <form [formGroup]="editSiteForm" class="edit-form">
        <div class="cc-grid">
          <ng-container *ngIf="isRemoveCwsIDAutoSearch">
            <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
              <div class="edit-label">Site Contact</div>
              <cc-input-field
                [disabled]="false"
                [labelValue]="'CWS ID'"
                [inputtype]="'text'"
                [isTableInlineEdit]="true"
                [isTheme]="isTheme"
                [value]="siteCwsId"
                [disabled]="isValidateCWSID"
                (valueChange)="siteCwsId = $event.value; onSiteCwsIdChange($event)"
              >
                <input
                  input-field
                  formControlName="cwsid"
                  class="input-field"
                  autocomplete="off"
                  type="text"
                  aria-labelledby="Enter any value"
                  aria-describedby="Enter any value"
                  [(ngModel)]="siteCwsId"
                  (keyup.enter)="handleUserSearch(siteCwsId)"
                  (blur)="onBlurEvent($event, siteCwsId)"
                  (valueChange)="siteCwsId = $event.value; onSiteCwsIdChange($event)"
                />

                <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                      fill="#DF3826"
                    />
                  </svg>
                </span>
              </cc-input-field>

              <span class="req-text">
                <ng-container *ngIf="formControl['cwsid']?.touched">
                  <span *ngIf="formControl['cwsid']?.errors?.['required']">CWS ID cannot be empty</span>
                </ng-container>
                <span *ngIf="formControl['cwsid']?.errors?.['minlength']">Please enter atleast 3 characters</span>
                <span
                  *ngIf="!cwsIDExists && !formControl['cwsid']?.errors?.['required'] && !formControl['cwsid']?.errors?.['minlength']"
                  >CWS ID cannot be found</span
                >
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!isRemoveCwsIDAutoSearch">
            <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
              <div class="edit-label">Site Contact</div>
              <cc-input-field
                [disabled]="false"
                [labelValue]="'CWS ID'"
                [inputtype]="'text'"
                [isTableInlineEdit]="true"
                [isTheme]="isTheme"
                [value]="siteCwsId"
                [disabled]="isValidateCWSID"
                (valueChange)="siteCwsId = $event.value; onSiteCwsIdChange($event)"
              >
                <input
                  input-field
                  formControlName="cwsid"
                  class="input-field"
                  autocomplete="off"
                  type="text"
                  aria-labelledby="Enter any value"
                  aria-describedby="Enter any value"
                  [(ngModel)]="siteCwsId"
                  (valueChange)="siteCwsId = $event.value; onSiteCwsIdChange($event)"
                />

                <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                      fill="#DF3826"
                    />
                  </svg>
                </span>
              </cc-input-field>

              <span class="req-text">
                <ng-container *ngIf="formControl['cwsid']?.touched">
                  <span *ngIf="formControl['cwsid']?.errors?.['required']">CWS ID cannot be empty</span>
                  <span *ngIf="formControl['cwsid']?.errors?.['minlength']">Please enter atleast 3 characters</span>
                </ng-container>
                <span *ngIf="!cwsIDExists">CWS ID cannot be found</span>
              </span>
            </div>
          </ng-container>
          <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
            <div class="edit-label">Site Description</div>
            <cc-input-field
              id="site_desc_id"
              [disabled]="false"
              [labelValue]="'Enter Description'"
              [inputtype]="'textarea'"
              [value]="siteDescription"
              [isTableInlineEdit]="true"
              [maxlength]="255"
              [isTheme]="isTheme"
              [isDense]="false"
              [isFloating]="false"
            >
              <textarea
                autosize
                [collapse]="true"
                [labelValue]=""
                [isFloating]="false"
                formControlName="siteDescription"
                [(ngModel)]="siteDescription"
                class="textarea-field"
                id="site-desc-text"
                aria-labelledby="Enter your message"
                aria-describedby="Enter your message"
                rows="2"
                [value]="siteDescription"
                [maxHeight]="50"
                [maxlength]="255"
                (valueChange)="siteDescription = $event.value; onSiteDescChange($event)"
              ></textarea>
            </cc-input-field>
          </div>

          <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
            <div class="edit-label">Product</div>
            <cc-dropdown
              [id]="'product_id'"
              [label]="'Select product'"
              [isTheme]="isTheme"
              [dataSource]="products"
              [placementPosition]="'bottom'"
              (selectionChange)="onDropdownChange($event, 'product'); constructProductPlan($event)"
              formControlName="product"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
            ></cc-dropdown>
          </div>

          <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
            <div class="edit-label">Plan</div>
            <cc-dropdown
              [id]="'plan_id'"
              [label]="'Select Plan'"
              [isTheme]="isTheme"
              [dataSource]="planData"
              [placementPosition]="'bottom'"
              (selectionChange)="onDropdownChange($event, 'plan'); constructService($event)"
              formControlName="plan"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
            ></cc-dropdown>
          </div>

          <div
            *ngIf="addOnServices.length > 0"
            class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
          >
            <div class="edit-label">Optional Plans</div>
            <cc-dropdown
              [isTheme]="'2.0'"
              [id]="'singlerow-chip'"
              name="checkbox"
              [label]="'Select Optional Plans'"
              [dataSource]="addOnServices"
              [placementPosition]="'top'"
              formControlName="additionalService"
              (selectionChange)="selectService($event)"
              [multiple]="true"
              [expanded]="'selected'"
              [singleRowChips]="false"
              [showClearAll]="false"
              [hideSelectAllLabel]="true"
              [dropdownLabels]="{ moreLabel: 'more' }"
              [multipleWithButton]="{ applyLabel: 'Apply', cancelLabel: 'Close' }"
              [scrollToSelected]="true"
              [closeOnCancel]="true"
            ></cc-dropdown>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="activeStepIndex > 1">
      <div>
        <div *ngIf="loaderValue" style="margin-top: 25px">
          <div class="site-Spin-align">
            <div class="siteSpinner"></div>
          </div>
          <div class="site-Spin-text">
            <span>One moment. We are calculating your<br />plan charges...</span>
          </div>
        </div>
        <div style="margin-top: 25px" *ngIf="!loaderValue">
          <dsp-next-gen-ui-cat-billing
            [currentBillingDetails]="currentBillingDetails"
            [newBillingDetails]="newBillingDetails"
            [isSitePricingEligible]="isPricingEligible"
            [currentPlanDisplay]="currentPlanDisplay"
            [newPlanDisplay]="newPlanDisplay"
            [editSite]="true"
          ></dsp-next-gen-ui-cat-billing>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="cc-drawer__footer">
    <button
      *ngIf="getValidButtonStatus('back')"
      id="cc-drawer-cancelBtn"
      cc-btn-secondary
      cc-btn-large
      [setTheme]="true"
      (click)="backward()"
    >
      Back
    </button>

    <button
      id="cc-drawer-cancelBtn"
      cc-btn-secondary
      cc-btn-large
      [setTheme]="true"
      [disabled]="!enableSaveButton() || !cwsIDExists"
      (click)="saveAndSubscribeSite('save')"
      *ngIf="
        (!isPaymentPage && activeStepIndex === 0) || (isPaymentPage && activeStepIndex === 0 && !isPricingEligible)
      "
    >
      Save
    </button>
    <button
      id="cc-drawer-saveBtn"
      [disabled]="!isPaymentLoaded"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      (click)="saveAndSubscribeSite('submit')"
    >
      {{ isPaymentPage ? 'Submit' : 'Next' }}
    </button>
  </div>
</div>
