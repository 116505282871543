<form [formGroup]="landingPageForm" class="adjustHeight" *ngIf="!isNumberConventionEnabled">
  <div [ngClass]="isTimeZoneSelectionEnabled === true ? 'orgModal' : 'modalBody'">
    <div class="cc-modal">
      <div class="cc-modal-header">
        <div class="cc-modal-title">{{ data.title }}</div>
        <div class="cc-drawer__close cc-close-icon cross-icon" (click)="onClickClose()">
          <cc-icon fontSet="icomoon" class="cross-size">cross</cc-icon>
        </div>
      </div>
      <div
        class="cc-modal-content"
        [ngClass]="isTimeZoneSelectionEnabled === true ? 'new-cc-modal-content' : 'cc-modal-content'"
      >
        <div class="user-info-wrap">
          <div class="user-prefix">{{ data.shortName }}</div>
          <div class="user-info">
            <div class="user-name">{{ data.userInfo.name }}</div>
            <div class="user-email">{{ data.userInfo.email }}</div>
          </div>
        </div>
        <div class="cc-grid" *ngIf="checkUserRole()">
          <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
            <div class="user-info-head">Landing Page</div>
            <div class="user-label">Choose your preferred landing page</div>
          </div>
          <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
            <div *ngIf="!isTimeZoneSelectionEnabled">Landing Page</div>
            <cc-dropdown
              [id]="'single-dropdown-validation-medium'"
              [label]="'Select Landing Page'"
              [isTheme]="'2.0'"
              [dataSource]="landingPages"
              [placementPosition]="'auto'"
              (selectionChange)="onDropdownChange($event, 'landingPage')"
              formControlName="landingPage"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
            ></cc-dropdown>
            <span
              class="req-text"
              *ngIf="
                          landingPageForm.get('landingPage')?.errors?.['required'] &&
                          landingPageForm.get('landingPage')?.touched"
              >You must select an option.</span
            >
          </div>
        </div>
        <div class="cc-grid" *ngIf="isTimeZoneSelectionEnabled">
          <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
            <div class="user-info-head">Time Zone</div>
            <div class="user-label">
              Billing and any dates selected in Dealer Services Portal will not be affected by this setting, and will
              remain in UTC
            </div>
          </div>
          <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
            <cc-dropdown
              [id]="'single-dropdown-validation-medium'"
              [label]="'Select Time Zones'"
              [typeahead]="true"
              [isTheme]="'2.0'"
              [dataSource]="listOfTimeZones"
              [placementPosition]="'auto'"
              (selectionChange)="onTimeZoneDropdownChange($event, 'timeZones')"
              formControlName="timeZones"
              [searchHelpText]="'Couldn´t find any time zone with '"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
            ></cc-dropdown>
          </div>
        </div>
      </div>
      <div class="cc-modal-footer" [class.btn-position]="isTimeZoneSelectionEnabled === true">
        <button cc-btn-secondary [setTheme]="true" (click)="onClickClose()" class="cc-btn cc-btn-secondary-outline">
          Cancel
        </button>
        <button
          cc-btn-primary
          type="submit"
          [disabled]="checkIfDisabled()"
          (click)="onSavePreference()"
          [setTheme]="true"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</form>
<form [formGroup]="landingPageForm" class="adjustHeight orgModal" *ngIf="isNumberConventionEnabled">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ data.title }}</div>
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="onClickClose()">
        <cc-icon fontSet="icomoon" class="cross-size">cross</cc-icon>
      </div>
    </div>
    <div class="cc-modal-content new-cc-modal-content">
      <div class="new-user-info-wrap">
        <div class="user-prefix">{{ data.shortName }}</div>
        <div class="user-info">
          <div class="user-name">{{ data.userInfo.name }}</div>
          <div class="user-email">{{ data.userInfo.email }}</div>
        </div>
      </div>
      <span class="details-title" *ngIf="checkUserRole()">General</span>
      <div class="cc-grid details-body" *ngIf="checkUserRole()">
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
          <div class="new-user-info-head">Landing Page</div>
          <div class="user-label">Choose your preferred landing page</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
          <cc-dropdown
            [id]="'single-dropdown-validation-medium'"
            [label]="'Select Landing Page'"
            [isTheme]="'2.0'"
            [dataSource]="landingPages"
            [placementPosition]="'auto'"
            (selectionChange)="onDropdownChange($event, 'landingPage')"
            formControlName="landingPage"
            [scrollToSelected]="true"
            [selectionOnClose]="true"
          ></cc-dropdown>
          <span
            class="req-text"
            *ngIf="
                          landingPageForm.get('landingPage')?.errors?.['required'] &&
                          landingPageForm.get('landingPage')?.touched"
            >You must select an option.</span
          >
        </div>
      </div>
      <span class="details-title">Numbers</span>
      <div class="cc-grid details-body" *ngIf="isNumberConventionEnabled">
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
          <div class="new-user-info-head">Number Convention</div>
          <div class="user-label">Choose your preferred number convention</div>
        </div>
        <div class="cc-col cc-lg-col--8 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
          <cc-radio-group
            class="radio space-between"
            aria-label="Select a Number Convention"
            [(ngModel)]="selectedNumberFormat"
            (valueChange)="onNumberFormatRadioChange($event, 'numberFormat')"
            formControlName="numberFormat"
            ngDefaultControl
          >
            <cc-radio-button [value]="'USD'">###,###.##</cc-radio-button>
            <cc-radio-button [value]="'EUR'">###.###,##</cc-radio-button>
          </cc-radio-group>
          <div class="labelbox space-between">
            <cc-input-field [disabled]="true" [labelValue]="'500,000.55'" [inputtype]="'text'" [width]="'150px'">
            </cc-input-field>
            <cc-input-field [disabled]="true" [labelValue]="'500.000,55'" [inputtype]="'text'" [width]="'150px'">
            </cc-input-field>
          </div>
          <div class="helptext space-between">
            <div class="new-user-label">You will see this look in your view</div>
            <div class="new-user-label">You will see this look in your view</div>
          </div>
        </div>
      </div>
      <span class="details-title">Time & Location</span>
      <div class="cc-grid details-body">
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
          <div class="new-user-info-head">Time Zone</div>
          <div class="user-label">
            Billing and any dates selected in Dealer Services Portal will not be affected by this setting, and will
            remain in UTC
          </div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
          <cc-dropdown
            [id]="'single-dropdown-validation-medium'"
            [label]="'Select Time Zones'"
            [typeahead]="true"
            [isTheme]="'2.0'"
            [dataSource]="listOfTimeZones"
            [placementPosition]="'auto'"
            (selectionChange)="onTimeZoneDropdownChange($event, 'timeZones')"
            formControlName="timeZones"
            [searchHelpText]="'Couldn´t find any time zone with '"
            [scrollToSelected]="true"
            [selectionOnClose]="true"
          ></cc-dropdown>
        </div>
      </div>
      <div *ngIf="isTimeFormatEnabled" class="cc-grid details-body time-format-main">
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--5 cc-sm-col--6 cc-xs-col--12">
          <div class="new-user-info-head">Date and Time Format</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--7 cc-sm-col--6 cc-xs-col--12">
          <div class="format-div">
            <div class="format-time">
              <span>Time Format</span>
              <div>
                <cc-radio-group
                  class="radio"
                  aria-label="Select a Number Convention"
                  [(ngModel)]="selectedTimeFormat"
                  (valueChange)="timeDateFormatRadioChange($event, 'timeFormat')"
                  formControlName="timeFormat"
                  ngDefaultControl
                >
                  <cc-radio-button *ngFor="let time of timeFormat" [value]="time.value">{{
                    time.label
                  }}</cc-radio-button>
                </cc-radio-group>
              </div>
            </div>
            <div class="format-time">
              <span>Date Format</span>
              <div>
                <cc-radio-group
                  class="radio"
                  aria-label="Select a Number Convention"
                  [(ngModel)]="selectedDateFormat"
                  (valueChange)="timeDateFormatRadioChange($event, 'dateFormat')"
                  formControlName="dateFormat"
                  ngDefaultControl
                >
                  <cc-radio-button *ngFor="let date of dateFormat" [value]="date.value">{{
                    date.label
                  }}</cc-radio-button>
                </cc-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cc-modal-footer btn-position">
      <button cc-btn-secondary [setTheme]="true" (click)="onClickClose()" class="cc-btn cc-btn-secondary-outline">
        Cancel
      </button>
      <button
        cc-btn-primary
        type="submit"
        [disabled]="checkIfDisabled()"
        (click)="onSavePreference()"
        [setTheme]="true"
      >
        Save Changes
      </button>
    </div>
  </div>
</form>
