<div class="rv-header">
  <div class="rv-header-main">
    <div class="rv-header-text">
      <span class="header-text">RECENTLY VIEWED</span>
    </div>
    <div class="rv-header-arrow" *ngIf="assetDetails?.length > 0">
      <div class="prev arrow-btn" (click)="previous()" [ngClass]="{ disable: validatePrevBtn() }">
        <cc-icon fontSet="icomoon" [ngClass]="'advSeachArrowLeft'">adv-search</cc-icon>
      </div>
      <div class="next arrow-btn" (click)="next()" [ngClass]="{ disable: validateNextBtn() }">
        <cc-icon fontSet="icomoon" [ngClass]="'advSeachArrowRight'"> adv-search</cc-icon>
      </div>
    </div>
  </div>
  <div class="rv-asset-main">
    <div class="cc-grid asset-main" *ngIf="assetDetails?.length > 0">
      <div
        class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4"
        *ngFor="let assetData of assetDetails"
      >
        <dsp-next-gen-ui-asset-card
          (refreshAssetsData)="refreshAssetsData()"
          [assetData]="assetData"
        ></dsp-next-gen-ui-asset-card>
      </div>
    </div>
    <div class="no-asset" *ngIf="assetDetails?.length == 0 && isRvAssets">
      <span>There are no recently viewed assets</span>
    </div>
  </div>
</div>
