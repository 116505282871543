import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'apps/dsp-ui/src/environments/environment';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { NeedsreviewPayload } from '../needs-review-interface';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';

@Injectable({
  providedIn: 'root',
})
export class NeedsReviewService {
  @Output() assetStatusChangeFromMenu = new EventEmitter();
  @Output() needsReviewAutoRefreshSubscriptionEvent = new EventEmitter();
  queueReason: any;
  dismissStatus: any;
  rootURL = '';
  assetStatusChangeFromTable: any;
  refreshNeedsReviewTable: any;
  dealer: any;
  status: string = '';
  categories: any;
  globalSearchText: string = '';
  selectedQueueReason: any;
  resetFilterData: any;
  enableNeedsReviewDismissOption: any;
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.dealer = fetchLoggedInDealerDetails();
    this.queueReason = new BehaviorSubject('');
    this.dismissStatus = new BehaviorSubject(false);
    this.assetStatusChangeFromTable = new Subject();
    this.refreshNeedsReviewTable = new BehaviorSubject(false);
    this.resetFilterData = new Subject();
    this.enableNeedsReviewDismissOption = new BehaviorSubject(false);
  }

  setResetFilterData(data: any) {
    this.resetFilterData.next(data);
  }

  getResetFilterData() {
    return this.resetFilterData.asObservable();
  }
  setNeedsReviewAutoRefreshSubscriptionData(data: any) {
    this.needsReviewAutoRefreshSubscriptionEvent.emit(data);
  }
  getReviewCategories(input?: any) {
    let url = this.rootURL;
    const payload = {
      dealerCode: this.dealer.dealerCode,
      searchText: input.searchText,
    };
    if (input?.searchText) {
      url = `/review/globalSearchReviewCategories`;
      return this.http.post(this.rootURL + url, payload);
    } else {
      url += `/review/getReviewCategories?dealerCode=${this.dealer.dealerCode}`;
      return this.http.get(url);
    }
  }

  fetchNeedsReviewData(inputData: NeedsreviewPayload): Observable<any> {
    return this.http.post(this.rootURL + '/review/fetchAssetsForReview', inputData);
  }

  fetchNeedsReviewCount(inputData: NeedsreviewPayload): Observable<any> {
    return this.http.post(this.rootURL + '/review/fetchAssetsCountForReview', inputData);
  }
  changeAssetNewStatus(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/review/changeNewStatus', payload);
  }
  statusChangeEvent(data: any, id: any) {
    data.queueReasonId = id;
    this.assetStatusChangeFromMenu.emit(data);
  }
  dismissAsset(inputData: any) {
    return this.http.post(this.rootURL + '/assetdata/dismissAsset', inputData);
  }
  exportReviewData(inputData: any) {
    return this.http.post(this.rootURL + '/review/export', inputData);
  }

  getReviewDataExportStatus(id: string) {
    return this.http.get(this.rootURL + '/assetdata/export/' + id);
  }

  downloadReviewData(fileId: string, queueReasonLabel: any) {
    return this.http.get(this.rootURL + `/assetdata/download/${fileId}?fileName=${queueReasonLabel}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getFetchColumnFilters(): Observable<any> {
    return this.http.get(this.rootURL + `/review/reviewColumnFilters?dealerCode=${this.dealer.dealerCode}`);
  }

  getFilterValues(inputData: any): Observable<any> {
    return this.http.post(this.rootURL + '/review/reviewColumnFilterValues', inputData);
  }

  filterAssetsForReview(inputData: NeedsreviewPayload): Observable<any> {
    return this.http.post(this.rootURL + '/review/filterAssetsForReview', inputData);
  }

  filterNeedsReviewCount(inputData: NeedsreviewPayload): Observable<any> {
    return this.http.post(this.rootURL + '/review/filterAssetsCountForReview', inputData);
  }

  getSearchNeedsReviewCount(inputData: any): Observable<any> {
    return this.http.post(this.rootURL + '/review/globalSearchCount', inputData);
  }

  getSearchNeedsReviewData(inputData: any): Observable<any> {
    return this.http.post(this.rootURL + '/review/globalSearch', inputData);
  }

  getStatus() {
    return this.status;
  }

  setStatus(status: string) {
    this.status = status;
  }

  getCategories() {
    return this.categories;
  }

  setCategories(catogories: any) {
    this.categories = catogories;
  }
}
