import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  CC_DRAWER_DATA,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { cloneDeep, isEmpty } from 'lodash-es';
import {
  GetTelemetryInfoPaylod,
  ServiceMeterInfo,
  LocationInfo,
  RequestRemoteConnectionCheckPayload,
  FilterAssetsV2Payload,
} from './asset-drawer-interface';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../shared/shared';
import * as dspConstants from '../../../shared/dspConstants';
import { ConfigureWifiComponent } from './configure-wifi/configure-wifi.component';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { Subscription, delay, expand, takeLast } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UpdatePIDService } from '../services/update-pid.service';
import { AssetService } from '../services/asset.service';
import { DeviceStatusComponent } from '../../device-status/device-status.component';

import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { UserPreferenceService } from '../../../user-preference.service';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import { DspCommonService } from '../../../services/dsp-common.service';
import { DATA_LAYER_OBJECT } from '../../../google-analyatics.interface';
import { SharedService } from '../../../shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dsp-next-gen-ui-asset-drawer',
  templateUrl: './asset-drawer.component.html',
  styleUrls: ['./asset-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDrawerComponent implements OnInit {
  dspUserRoleConstants = dspConstants;
  rowData: any;
  assetData!: any;
  assetDetailsData!: any;
  isTheme = '2.0';
  dealerAssetData: any;
  wifiToolTip = 'Configure Wi-Fi';
  connectionCheckToolTip = 'Last Check In:';
  settingsToolTip = 'Device Status';
  shmInfo!: ServiceMeterInfo;
  locationInfo!: LocationInfo;
  shmAndLocationInfo!: any;
  assetSubscriptionHistory: any;
  temp1: any = 'sdsdsd';
  serviceMeterReadingTimeArray!: string[];
  locationTimeArray!: string[];
  catalogMapping: any;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  psrUrl!: string;
  edUrl!: string;
  dspConfig: any;
  cvaInfo = {};
  refreshData = false;
  enableSmhLocationSection = false;
  ownershipSystemError = dspConstants.Common.OWNERSHIP_SYSTEM_ERROR;
  noActiveSyncError = dspConstants.Common.NO_ACTIVE_SYNC_ERROR;

  systemError = dspConstants.Common.SYSTEM_ERROR;
  hasPartialOwnershipData = false;
  partialOwnershipData: any = [];
  completeOwnershipData: any = [];
  isEnableForwardSlashInPID = false;

  listForConnectionSecurity = [
    'PLE702',
    'PLE601',
    'PLE631',
    'PLE602',
    'PLE642',
    'PLE643',
    'PLE632',
    'PLE702',
    'PLE742',
    'PLE743',
    'PLE732',
  ];
  showConnectionCheckIcon = false;
  showConfigureWifiIcon = false;
  showDeviceStatusIcon = false;
  showSmhData = false;
  showLocationData = false;
  wifiConfigurationRef: any;
  dspWorklistConstants = dspConstants.Worklist;
  public dealer: any;
  updateAssetDrawerEvent!: any;
  connectionCheck: any;
  assetConnectionCheck: Array<object> = [];
  connectionStatus: any = [];
  lastCheckinValue: any;
  storedValues: any;
  updatedSerialNo: any;
  updatedNewmake: any;
  serviceMapping: any;
  assetId: any;
  refreshLanding = false;
  searchType = '';
  isDeviceStatusAPIInProgress = true;
  enableCatGradeControl = false;
  queueReasonSubscription!: Subscription;
  selectedQueueReason: any;
  showDismissButton = true;
  enableNeedsReviewSection!: boolean;
  queueReasonNewCount: any;
  queueReasonId: any;
  // cvaProductEnabled = false;
  isTimeZoneSelectionEnabled: any;
  isNumberConventionEnabled: any;
  datUrl = '';
  datStatus = '';
  userRole!: string | null;
  isSoftwareUpdateEligible = false;
  remoteFlashCapabilityUrl: any;
  dspStoreData!: any;
  isRemoteFlashUpgradeVisible = false;
  isAssetLandingDatEnable = false;
  deviceInfo1A = false;
  isLicenseMappingEnabled: any;
  isTimeFormatEnabled: any;
  showAssetImage: any;
  showDefaultAssetImage = true;
  assetImageURL = '';
  isCatGradeControlAllDealer: any;
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  queueReasonList: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    public dialogRef: CcDrawerRef<AssetDrawerComponent>,
    public assetDrawerService: AssetDrawerService,
    public assetService: AssetService,
    private store: Store<DSPAppState>,
    private changeDetector: ChangeDetectorRef,
    private drawerService: DrawerService,
    private messageBar: MessageBar,
    private datepipe: DatePipe,
    private loaderService: LoaderService,
    private needsReviewService: NeedsReviewService,
    private userPreferenceService: UserPreferenceService,
    private dspCommonService: DspCommonService,
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      assetDetails: any;
    },
    private updatePIDService: UpdatePIDService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.userRole = this.dspUtilsCommonService.getValue('userRole');
    this.store.select('dsp').subscribe((dsp) => {
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspStoreData = dsp;
      this.catalogMapping = dsp.deviceCatalog;
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.serviceMapping = dsp.serviceMapping || {};
      this.psrUrl = dsp.psrUrl;
      this.edUrl = dsp.edUrl?.data;
      this.dspConfig = dsp.dspConfig;
      this.isRemoteFlashUpgradeVisible = this.dspStoreData?.featureFlagInfo?.isRemoteFlashUpgradeVisible
        ? this.dspStoreData?.featureFlagInfo?.isRemoteFlashUpgradeVisible
        : false;
      //this.cvaProductEnabled = dsp.featureFlagInfo.CVAProduct ? dsp.featureFlagInfo.CVAProduct : false;
      this.isTimeZoneSelectionEnabled = dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.isAssetLandingDatEnable = this.dspStoreData?.featureFlagInfo?.isAssetLandingDatEnable
        ? this.dspStoreData?.featureFlagInfo?.isAssetLandingDatEnable
        : false;

      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isEnableForwardSlashInPID = this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        ? this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
      this.showAssetImage = this.dspStoreData?.featureFlagInfo?.showAssetImage
        ? this.dspStoreData?.featureFlagInfo?.showAssetImage
        : false;
      this.isCatGradeControlAllDealer = this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        ? this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        : false;
      this.deviceInfo1A = this.dspStoreData?.featureFlagInfo?.deviceInfo1A
        ? this.dspStoreData?.featureFlagInfo?.deviceInfo1A
        : false;
      this.queueReasonList = JSON.parse(this.dspConfig.Queue_Reason_List);
    });
    this.needsReviewService.enableNeedsReviewDismissOption.subscribe((data: any) => {
      this.enableNeedsReviewSection = data;
    });
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
      this.showDismissButton = this.queueReasonList?.indexOf(this.selectedQueueReason) > -1 ? false : true;
      this.queueReasonNewCount = data.newCount;
      this.queueReasonId = data.dealerQueueReasonId;
    });
    this.updatePIDService.updatedPIDValue.subscribe((data: any) => {
      this.updatedSerialNo = data ? data.serialNo : '';
      this.updatedNewmake = data ? data.make : '';
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.getDealerFeatureByDealercode();
    this.rowData = this.header.assetDetails ? cloneDeep(this.header.assetDetails) : {};
    this.updatedSerialNo = this.rowData?.serialNumber || '';
    this.updatedNewmake = this.rowData?.make || '';
    this.assetId = this.header?.assetDetails?.assetId;
    this.searchType = this.rowData?.searchType || dspConstants.Worklist.GLOBAL_SEARCH;
    this.assetData = {};
    this.loadAssetData();
    this.assetDetailsData = this.assetData;
    this.assetDetailsData.applicationName = this.rowData?.applicationName;
    this.assetDetailsData.isMaskRequired = this.rowData?.isMaskRequired;
    this.assetData.psrUrl = this.psrUrl;
    this.assetData.edUrl = this.edUrl;
    this.assetData.customerAssetId = this.rowData?.customerAssetId;
    this.updateAssetDrawerEvent = this.assetDrawerService.assetDrawerEventEmitter.subscribe((data: any) => {
      this.assetDetailsData = data;
      this.updateAssetSubscriptionId();
      this.isSmhNeeded();
    });
    this.setDataLayer('Asset Details Page', this.selectedQueueReason, 'success', '');
    this.assetDrawerService.assetUnsuspensionFormEvent.subscribe((data: any) => {
      this.refreshData = data.dataChanged;
      this.assetDetailsData = { ...data.formData };
    });
    const savedConnectionStatus = sessionStorage['Assets'] ? JSON.parse(sessionStorage['Assets']) : [];
    this.getSavedConnectionCheckStatus(this.rowData.make, this.rowData.serialNumber, savedConnectionStatus);
    if (this.assetService?.getConnectionStatus()) {
      this.getSavedConnectionCheckStatus(
        this.rowData.make,
        this.rowData.serialNumber,
        this.assetService.getConnectionStatus()
      );
    }
  }
  setDataLayer(formName: any, selectedQueueReason: any, staus: any, errorMessage: any) {
    let isNeedsReview =
      this.rowData?.status === this.dspNeedsReviewConstants.Active ||
      this.rowData?.status === this.dspNeedsReviewConstants.New;
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: formName,
      formContent: this.rowData?.serialNumber + ' | ' + this.rowData?.deviceType,
      formContent2: isNeedsReview ? selectedQueueReason : '',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: staus,
      formFieldCausingError: errorMessage,
      formLocation: isNeedsReview ? 'Needs Review Page' : 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
  getAssetImageURL() {
    const params = {
      make: this.updatedNewmake,
      serialNumber: this.updatedSerialNo,
    };
    this.assetDrawerService?.getAssetImageURL(params)?.subscribe({
      next: (res: any) => {
        if (res) {
          if (res?.imageURL && !res?.isImageId) {
            this.assetImageURL = res.imageURL;
            this.showDefaultAssetImage = false;
          } else if (res?.imageURL && res?.isImageId) {
            this.getAssetAmage_API(res?.imageURL);
          }
        }
      },
      error: (err: any) => {
        this.showDefaultAssetImage = true;
      },
    });
  }

  getAssetAmage_API(API_URL: any) {
    this.assetDrawerService.getAssetImageContent(API_URL).subscribe({
      next: (res: any) => {
        const blob = new Blob([res]);
        const srcBlob = URL.createObjectURL(blob);
        this.assetImageURL = srcBlob;
        this.showDefaultAssetImage = false;
      },
      error: (err: any) => {
        this.showDefaultAssetImage = true;
      },
    });
  }
  setFilterAssetsPayload() {
    if (
      this.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH ||
      (this.rowData?.isHomeSearch && this.assetId == null)
    ) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.updatedNewmake,
        serialNumber: this.updatedSerialNo,
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.updatedNewmake,
        serialNumber: this.updatedSerialNo,
        flag: false,
      };
    }
  }

  checkHeaderDetails() {
    this.isWifiConfigurationNeeded();
    this.isConnectionCheckIconNeeded();
    this.isDeviceStatusNeeded();
    this.isSmhNeeded();
  }
  checkForCatGradeNeeded() {
    if (this.isCatGradeControlAllDealer) {
      this.isCatGradeControlNeededForAllDealer();
    } else {
      this.isCatGradeControlNeeded();
    }
  }

  loadOwnershipData() {
    if (this.searchType == dspConstants.Worklist.NO_DEVICE_SEARCH || this.assetId == null) {
      this.setOwnershipDataForNoDevice();
    } else {
      this.loaderService.show();
      this.getAssetOwnership();
    }
  }

  checkForReportingState(assetData: any) {
    if (assetData.dealerSubscriptionId != null && !assetData.suspended) {
      return dspConstants.Worklist.SUBSCRIBED;
    } else if (assetData.dealerSubscriptionId == null && !assetData.suspended) {
      return dspConstants.Worklist.UNSUBSCRIBED;
    }
    return dspConstants.Worklist.SUSPENDED;
  }

  getDealerFeatureByDealercode() {
    const catGradeObj = {
      feature: 'CAT GRADE CONTROL',
      dealerCode: fetchLoggedInDealerDetails().dealerCode,
    };
    this.loaderService.show();
    this.assetDrawerService.getCatGradeControlByDealercode(catGradeObj).subscribe(
      (res: any) => {
        this.loaderService.hide();
        if (res) {
          if (res.featureName === 'CAT GRADE CONTROL') {
            this.enableCatGradeControl = true;
          }
        }
      },
      (err: any) => {
        this.loaderService.hide();
        if (err.status === 404) {
          this.enableCatGradeControl = false;
        }
      }
    );
  }
  getApplicableAPI(isCVAStatus: any) {
    let assetData = {
      make: this.rowData?.make,
      serialNumber: this.rowData?.serialNumber,
      planIds: [],
      includePricing: true,
      deviceType: this.rowData?.deviceType === null ? 'NO_DEVICE' : this.rowData?.deviceType,
      dealerCode: this.dealer?.dealerCode,
      assetId: this.rowData?.assetId,
      assetGuiId: this.rowData?.appId,
      isCVAAvailable: isCVAStatus,
    };
    this.assetDrawerService.getApplicableSubscriptions(assetData).subscribe({
      next: (result: any) => {
        this.assetDrawerService.getapplicableList = result;
      },
    });
  }
  getFilteredAssets() {
    this.assetDrawerService.getCvaStatus(this.rowData?.make, this.rowData?.serialNumber).subscribe((res: any) => {
      this.getApplicableAPI(res?.cvaStatus);
    });
    this.getAssetImageURL();
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.assetData = Object.assign(this.assetData, result);

          this.assetData.searchType = this.searchType;
          this.assetData.reportingState = this.checkForReportingState(this.assetData);
          if (this.assetData.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.assetData.applicationName = this.serviceMapping[this.assetData?.dealerSubscriptionId]?.appName;
          }
          this.assetData.getSupportedServicesList = this.rowData?.getSupportedServicesList;
          this.assetData.isFsmSupportService = this.rowData?.isFsmSupportService;
          this.assetData.dealerInfo = this.rowData?.dealerInfo;
          this.assetDetailsData = { ...this.assetData };
          this.assetDrawerService.searchAssetInfo.next(this.assetDetailsData);
          this.enableSmhLocationSection = true;
          this.assetId = result.assetId;
          this.loadOwnershipData();
          this.checkHeaderDetails();
        }
      },
      error: (_err: any) => {
        this.assetData.reportingState = this.rowData?.reportingState || '';
        this.checkHeaderDetails();
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  syncOwnershipDatStatus() {
    if (this.rowData.digitalAuthorization === 'Available') {
      this.datStatus = 'AGREE';
    } else if (this.rowData.digitalAuthorization === 'Declined') {
      this.datStatus = 'DECLINE';
    } else if (this.rowData.digitalAuthorization === 'Not Available') {
      this.datStatus = 'UNAVAILABLE';
    } else {
      this.datStatus = 'SYSTEM ERROR';
    }
    if (
      this.datStatus !== 'SYSTEM ERROR' &&
      this.assetDetailsData?.ownershipDetails[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== 'UNREACHABLE'
    ) {
      if (
        this.datStatus !== this.assetDetailsData.ownershipDetails[0].digitalAuthorizationDetails.CATDigitalAuthStatus
      ) {
        const syncDatOwnershipPayload = {
          assetId: this.rowData.assetId,
          dealerCode: this.dealer?.dealerCode,
        };
        this.assetService.syncOwnershipDATStatus(syncDatOwnershipPayload).subscribe(
          (res: any) => {},
          (error: any) => {
            let responseText = error?.error?.text;
            if (responseText === 'Success') {
              this.refreshLanding = true;
            }
          }
        );
      }
    }
  }

  getSupportedServices() {
    if (isEmpty(this.assetData.getSupportedServicesList)) {
      const supportedServicesPayload = { guiID: this.assetData?.appId || '' };
      this.loaderService.show();
      this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
        next: (result: any) => {
          this.loaderService.hide();
          if (result?.length > 0) {
            this.assetData.isCatGradeSupportService = result?.includes(dspConstants.Worklist.CAT_GRADE_CONTROL);
            this.assetDrawerService.updateCatGradeControl(this.assetData.isCatGradeSupportService);
          }
        },
        error: (err: any) => {
          this.loaderService.hide();
        },
      });
    } else {
      this.loaderService.hide();
      this.assetData.isCatGradeSupportService = this.assetData.getSupportedServicesList?.includes(
        dspConstants.Worklist.CAT_GRADE_CONTROL
      );
      this.assetDrawerService.updateCatGradeControl(this.assetData.isCatGradeSupportService);
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  openDeviceStatus() {
    this.drawerService.openDrawer<DeviceStatusComponent, CcDrawerConfig>(DeviceStatusComponent, {
      data: { type: 'DRAWER', assetInfo: this.rowData },
      enableBlackBackDrop: true,
      id: 'deviceStatusDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }

  setOwnershipDataForNoDevice() {
    this.assetData.cvaInfo = {};
    let assetOwners = [];
    if (this.assetData?.equipmentOwner?.length > 0) {
      assetOwners = this.assetData.equipmentOwner;
      assetOwners = sortOwnershipRecords(assetOwners);
      this.assetData.ownershipDetails = assetOwners;
      this.assetDetailsData = { ...this.assetData };
      this.getCvaInfo();
      this.checkDATDeclined();
    } else {
      this.assetData.ownershipDetails = [];
      this.assetDetailsData = { ...this.assetData };
    }
  }

  getAssetOwnership() {
    this.assetData.cvaInfo = {};
    let assetOwners = [];
    const ownershipInfoPayload = {
      assetIds: [this.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (result: any) => {
        this.loaderService.hide();
        if (!isEmpty(result[this.assetId])) {
          assetOwners = result[this.assetId] as any;
          assetOwners = sortOwnershipRecords(assetOwners);
          this.assetData.ownershipDetails = assetOwners;
          this.assetDetailsData = { ...this.assetData };
          this.getCvaInfo();
          this.checkDATDeclined();
        } else {
          this.assetData.ownershipDetails = [];
          this.assetDetailsData = { ...this.assetData };
        }
        this.checkForCatGradeNeeded();
        if (this.isAssetLandingDatEnable === true) {
          this.syncOwnershipDatStatus();
        }
      },
      error: (_err: any) => {
        this.loaderService.hide();
        this.showToastMessage(this.systemError, 'error');
        this.checkForCatGradeNeeded();
      },
    });
  }
  checkOwnershipInfo(assetOwners: any) {
    assetOwners.forEach((owner: any) => {
      if (isEmpty(owner?.dealerCustNo) && isEmpty(owner?.dealerCustName)) {
        this.hasPartialOwnershipData = true;

        this.partialOwnershipData.push(owner);
      } else {
        this.completeOwnershipData.push(owner);
      }
    });
  }
  getCvaInfo() {
    // CVA Details
    if (this.assetData?.ownershipDetails && this.assetData?.ownershipDetails.length > 0) {
      if (this.dspStoreData?.featureFlagInfo?.CVAEnabled === true) {
        let foresightLink = '';
        let availableCvaCount;
        this.assetData?.ownershipDetails?.filter((owner: any) => {
          foresightLink =
            owner.cvaDetails && owner.cvaDetails.catForesightLink ? owner.cvaDetails.catForesightLink : '';
        });

        //if (this.cvaProductEnabled) {
        if (this.assetData?.ownershipDetails[0]?.cvaDetails) {
          availableCvaCount = this.assetData?.ownershipDetails[0]?.cvaDetails?.cvaCount;
          this.cvaInfo = {
            cvaStatus: availableCvaCount > 0 ? 'Available' : 'Not Available',
            cvaCount: availableCvaCount,
            catForesightLink: foresightLink,
          };
        }
        // } else {
        //   availableCvaCount = 0;
        //   availableCvaCount = this.assetData?.ownershipDetails[0]?.cvaDetails?.cvaCount || 0 + availableCvaCount;
        //   this.cvaInfo = {
        //     cvaStatus: availableCvaCount > 0 ? 'Available' : 'Not Available',
        //     cvaCount: availableCvaCount,
        //     catForesightLink: foresightLink,
        //   };
        // }
      } else {
        this.assetData?.ownershipDetails?.forEach((owner: any) => {
          if (owner && owner.dealerCode === this.dealer.dealerCode) {
            this.cvaInfo = owner.cvaDetails;
          }
        });
      }
    }
    this.assetData.cvaInfo = this.cvaInfo;
    this.assetDetailsData = { ...this.assetData };
  }

  closeDrawer() {
    if (this.assetDetailsData?.isNewSubscription || this.refreshLanding || this.refreshData) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
    this.assetService.setThirdPartySerialNumber('');
    this.assetService.setThirdPartymake('');
    window.localStorage.removeItem('thirdPartyApi');
  }
  getLocationAndSmhInfo() {
    const payload: GetTelemetryInfoPaylod = {
      make: this.updatedNewmake,
      serialNumber: this.updatedSerialNo,
    };
    this.assetDrawerService.getLocationAndSmhInfo(payload).subscribe({
      next: (result: any) => {
        const response = result;
        this.shmAndLocationInfo = response;
        if (this.isNumberConventionEnabled) {
          this.shmAndLocationInfo.serviceMeterReading = this.userPreferenceService.convertNumberFormat(
            this.shmAndLocationInfo?.serviceMeterReading,
            true
          );
        }
        this.serviceMeterReadingTimeArray = response?.serviceMeterReadingTime?.split(' ');
        this.locationTimeArray = response?.gpsAddressTime?.split(' ');
      },
      error: (err: any) => {},
    });
  }
  getServiceMeterReadingTime() {
    if (this.isTimeZoneSelectionEnabled) {
      let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
        this.serviceMeterReadingTimeArray[0] + ' ' + this.serviceMeterReadingTimeArray[1],
        true
      );
      if (this.isTimeFormatEnabled) {
        convertedTime =
          this.userPreferenceService.convertDateTime(
            convertedTime?.split(' ')[0] + ' ' + convertedTime?.split(' ')[1]
          ) +
          ' ' +
          this.userPreferenceService.getLocalTimeZone();
      }
      return convertedTime;
    } else {
      return this.serviceMeterReadingTimeArray[0] + ' ' + this.serviceMeterReadingTimeArray[1] + ' UTC';
    }
  }
  getLocationTime() {
    if (this.isTimeZoneSelectionEnabled) {
      let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
        this.locationTimeArray[0] + ' ' + this.locationTimeArray[1],
        true
      );
      if (this.isTimeFormatEnabled) {
        convertedTime =
          this.userPreferenceService.convertDateTime(
            convertedTime?.split(' ')[0] + ' ' + convertedTime?.split(' ')[1]
          ) +
          ' ' +
          this.userPreferenceService.getLocalTimeZone();
      }
      return convertedTime;
    } else {
      return this.locationTimeArray[0] + ' ' + this.locationTimeArray[1] + ' UTC';
    }
  }
  isConnectionCheckIconNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Connection Check') > -1 &&
      this.deviceFeaturesInfo &&
      this.deviceFeaturesInfo[this.assetData?.deviceType]?.Connection_Check &&
      !this.assetData?.suspended &&
      (!this.deviceFeaturesInfo[this.assetData?.deviceType]?.Radio_Check ||
        (this.deviceFeaturesInfo[this.assetData?.deviceType]?.Radio_Check &&
          (this.assetData?.radio1SerialNo || this.assetData?.radio2SerialNo)))
    ) {
      if (
        this.listForConnectionSecurity?.indexOf(this.assetData?.deviceType) > -1 &&
        !this.assetData?.enableConnectionCheck
      ) {
        this.showConnectionCheckIcon = false;
      } else {
        this.showConnectionCheckIcon = true;
        this.loaderService.show();
        this.lastCheckinValue = this.getLastCheckInStatus(this.rowData.make, this.rowData.serialNumber);
      }
    }
  }

  isCatGradeControlNeeded() {
    if (
      this.userActiveFeatures?.features &&
      // this.userActiveFeatures?.features?.indexOf('Cat Grade Control') !== -1 &&
      this.userActiveFeatures?.features?.indexOf('Subscription Management') !== -1 &&
      (this.userActiveFeatures?.features?.indexOf('Read and Write') !== -1 ||
        this.userActiveFeatures?.features?.indexOf('Manage - All Plans') !== -1) &&
      this.assetDetailsData?.reportingState !== this.dspWorklistConstants.SUSPENDED &&
      this.enableCatGradeControl &&
      this.isCatGradeMaskingRequired()
    ) {
      this.getSupportedServices();
    }
  }

  isCatGradeControlNeededForAllDealer() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Subscription Management') !== -1 &&
      (this.userActiveFeatures?.features?.indexOf('Read and Write') !== -1 ||
        this.userActiveFeatures?.features?.indexOf('Manage - All Plans') !== -1) &&
      this.assetDetailsData?.reportingState !== this.dspWorklistConstants.SUSPENDED &&
      this.isCatGradeMaskingRequired()
    ) {
      this.getSupportedServices();
    }
  }

  isWifiConfigurationNeeded() {
    this.showConfigureWifiIcon = this.rowData?.showConfigureWifiIcon ? true : false;
  }

  isDeviceStatusNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Device Status') > -1 &&
      this.assetData?.enableSubscription &&
      this.assetData?.isTelematicsRegistered &&
      this.deviceFeaturesInfo &&
      !this.deviceFeaturesInfo[this.assetData?.deviceType]?.Disable_Device_Status &&
      !this.assetData?.suspended &&
      !this.assetDetailsData?.isMaskRequired
    ) {
      this.showDeviceStatusIcon = true;
    }
  }

  isCatGradeMaskingRequired() {
    if (
      (this.assetData.reportingState === dspConstants.Worklist.SUBSCRIBED ||
        this.assetData.reportingState === dspConstants.Worklist.UNSUBSCRIBED) &&
      this.assetData?.ownershipDetails
    ) {
      return !isEmpty(this.assetData?.ownershipDetails)
        ? this.checkIfLoggedInOwnerThereForCatGrade(this.assetData?.ownershipDetails)
        : true;
    } else {
      return false;
    }
  }

  checkIfLoggedInOwnerThereForCatGrade(ownershipDetailsArr: any) {
    let isRecordFound = false;
    const assetSearchType = sessionStorage.getItem('assetSearchType');
    //Changed below elseif condition as part of BugId:1513170
    ownershipDetailsArr?.forEach((owner: any) => {
      if (owner?.dealerCode === this.dealer?.dealerCode) {
        isRecordFound = true;
      } else if (
        owner?.dealerCode !== this.dealer?.dealerCode &&
        assetSearchType === 'otherDealerSearch' &&
        this.assetData.reportingState === dspConstants.Worklist.UNSUBSCRIBED
      ) {
        isRecordFound = true;
      } else if (
        owner?.dealerCode !== this.dealer?.dealerCode &&
        assetSearchType === 'otherDealerSearch' &&
        this.assetData.reportingState === dspConstants.Worklist.SUBSCRIBED
      ) {
        isRecordFound = false;
      }
    });

    return isRecordFound;
  }

  openWifiConfiguration() {
    this.wifiConfigurationRef = this.drawerService.openDrawer<ConfigureWifiComponent, CcDrawerConfig>(
      ConfigureWifiComponent,
      {
        data: {
          titleNames: 'Configure Wifi',
          data: this.rowData,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }

  isSmhNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Location_SMH') > -1 &&
      (this.assetData?.dealerSubscriptionId || this.assetData?.dealerCode || this.assetDetailsData?.dealerCode) &&
      this.assetData?.enableSubscription &&
      !this.assetDetailsData?.isMaskRequired
    ) {
      if (
        !this.assetData?.suspended &&
        this.deviceFeaturesInfo &&
        this.assetData?.deviceType &&
        !this.deviceFeaturesInfo[this.assetData?.deviceType]?.DISABLE_SMH
      ) {
        this.showSmhData = true;
      }
      if (!this.assetData?.suspended) {
        this.showLocationData = true;
      }

      if (this.showSmhData || this.showLocationData) {
        this.getLocationAndSmhInfo();
      }
    }
  }

  checkConnectionStatus(make: any, serialNumber: any) {
    const payload = { eq_make: make, eq_sn: serialNumber };
    this.assetDrawerService
      .getConnectionCheckStatus(payload)
      .pipe(
        expand((result: any) => {
          if (
            result &&
            result.remote_connection &&
            result.remote_connection[0] &&
            result.remote_connection[0].connection_status === 'pending'
          ) {
            this.connectionStatus = result.remote_connection;
            this.assetConnectionCheck.push(this.connectionStatus);
            this.assetService.setConnectionStatus(this.connectionStatus);
            sessionStorage.setItem('Assets', JSON.stringify(this.assetConnectionCheck));
            return this.assetDrawerService.getConnectionCheckStatus(payload).pipe(delay(30000));
          } else {
            this.connectionStatus = result && result.remote_connection;
            sessionStorage.setItem('Assets', '');
            this.changeDetector.detectChanges();
            return '';
          }
        }),
        takeLast(1)
      )
      .subscribe({
        next: (result: any) => {
          if (Object.keys(result).length > 0) {
            this.connectionStatus = result && result.remote_connection;

            if (this.connectionStatus[0].connection_status == 'success') {
              this.assetConnectionCheck.push(this.connectionStatus);
              this.loaderService.show();
              this.lastCheckinValue = this.getLastCheckInStatus(make, serialNumber);
            } else {
              this.assetConnectionCheck.push(this.connectionStatus);
            }
            this.assetService.setConnectionStatus(this.connectionStatus);
            this.changeDetector.detectChanges();
          }
        },
        error: () => {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
          this.showToastMessage(this.systemError, 'error');
        },
      });
  }

  checkConnection(make: any, serialNumber: any) {
    const payload: RequestRemoteConnectionCheckPayload = {
      eq_make: make,
      eq_sn: serialNumber,
    };
    this.assetDrawerService.getConnectionCheckInfo(payload).subscribe({
      next: (result: any) => {
        if (
          result &&
          result.remote_connection &&
          result.remote_connection[0] &&
          result.remote_connection[0].status_code == 0
        ) {
          this.checkConnectionStatus(make, serialNumber);
        } else {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
        }
      },
      error: () => {
        this.connectionStatus = [
          {
            eq_make: make,
            eq_sn: serialNumber,
            connection_status: 'failure',
          },
        ];
        this.assetConnectionCheck.push(this.connectionStatus);
        this.assetService.setConnectionStatus(this.connectionStatus);
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  getSavedConnectionCheckStatus(make: any, serialNumber: any, status: Array<any>) {
    if (status?.length > 0) {
      status?.forEach((value: any) => {
        value.forEach((val: any) => {
          if (val.eq_make === make && val.eq_sn === serialNumber) {
            this.connectionStatus = [val];
            if (this.connectionStatus && this.connectionStatus[0]?.connection_status === 'pending') {
              this.checkConnectionStatus(make, serialNumber);
            }
          }
        });
      });
    }
  }

  getLastCheckInStatus(make: any, serialNumber: any) {
    const payloadObj = {
      make: make,
      serialNumber: serialNumber,
    };
    const deviceStatusPayload = this.isEnableForwardSlashInPID ? payloadObj : make + '/' + serialNumber;
    this.dspCommonService.getDeviceStatus(deviceStatusPayload).subscribe({
      next: (result: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.loaderService.hide();
        this.changeDetector.detectChanges();
        if (result?.lastCheckIn) {
          const lastCheckinDateAndTimeArray = result?.lastCheckIn?.split(' ');
          if (this.isTimeZoneSelectionEnabled) {
            let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
              lastCheckinDateAndTimeArray[0] + ' ' + lastCheckinDateAndTimeArray[1],
              true
            );
            let lastCheckIn = convertedTime;
            if (this.isTimeFormatEnabled) {
              this.lastCheckinValue =
                this.userPreferenceService.convertDateTime(
                  lastCheckIn?.split(' ')[0] + ' ' + lastCheckIn?.split(' ')[1]
                ) +
                ' ' +
                this.userPreferenceService.getLocalTimeZone();
            } else {
              this.lastCheckinValue = lastCheckIn;
            }
          } else {
            this.lastCheckinValue = `${lastCheckinDateAndTimeArray[0]}  ${lastCheckinDateAndTimeArray[1]} ${lastCheckinDateAndTimeArray[2]}`;
          }
        }
      },

      error: (err: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.loaderService.hide();
        this.changeDetector.detectChanges();
        this.lastCheckinValue = '';
      },
    });
    return this.lastCheckinValue || '';
  }

  getColor(status: any) {
    switch (status) {
      case 'success': {
        return 'green';
      }
      case 'pending': {
        return 'amber icon';
      }
      case 'failure': {
        return 'red';
      }
      default: {
        return '2595ff';
      }
    }
  }
  reloadOnSync(data: any) {
    const ownershipSyncPayload = {
      assetIds: this.assetId,
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.initOwnershipSync(ownershipSyncPayload).subscribe({
      next: (result: any) => {
        this.loadAssetData();
        this.refreshLanding = true;
        this.setDataLayer('Ownership Sync', '', 'success', '');
      },
      error: (err: any) => {
        if (err.status == 409) {
          this.setDataLayer('Ownership Sync', '', 'failed', this.noActiveSyncError);
          this.showToastMessage(this.noActiveSyncError, 'info');
        } else {
          this.setDataLayer('Ownership Sync', '', 'failed', this.ownershipSystemError);
          this.showToastMessage(this.ownershipSystemError, 'error');
        }
      },
    });
  }
  refreshAssetDrawer(data: any) {
    this.rowData.serialNumber = data.updatedSerialNumber?.split('|')[1];
    this.assetData.ownershipDetails = [];
    this.assetData.callDealerInfoAPI = true;
    this.assetDetailsData = { ...this.assetData };
    this.loadAssetData();
    this.refreshLanding = true;
  }

  loadAssetData() {
    this.getFilteredAssets();
    this.getSubscriptionHistory();
    this.getRemoteService();
  }

  updateAssetSubscriptionId() {
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    if (this.assetDetailsData.serialNumber === filteredAssetsPayloadObj.serialNumber) {
      this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
        next: (result: any) => {
          if (!isEmpty(result)) {
            this.assetData = Object.assign(this.assetDetailsData, {
              catSubscriptionId: result?.catSubscriptionId,
              customerSubscriptionId: result?.customerSubscriptionId,
              dealerSubscriptionId: result?.dealerSubscriptionId,
              siteId: result?.siteId,
              productFamily: result?.productFamily,
              updatedTime: result?.updatedTime,
              contractEndDate: result?.contractEndDate,
              contractStartDate: result?.contractStartDate,
              prepay: result?.prepay,
              equipmentOwner: result?.equipmentOwner,
              assetId: result?.assetId,
              nickName: result?.nickName ? result.nickName : '-',
              customerAssetId: result?.customerAssetId ? result.customerAssetId : '-',
              showSyncBtn: result?.showSyncBtn ? result.showSyncBtn : false,
              showPendingRequestChip: false,
              callDealerInfoAPI: true,
            });
            this.assetId = result?.assetId;
            this.assetDetailsData = { ...this.assetData };
            this.loadOwnershipData();
            this.getSubscriptionHistory();
          }
        },
        error: (_err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  getSubscriptionHistory() {
    const subHistoryPayload = {
      serialNumber: this.updatedSerialNo,
      make: this.updatedNewmake,
      offset: 1,
      limit: 100,
    };
    this.assetDrawerService.getAssetSubscriptionHistory(subHistoryPayload).subscribe({
      next: (result: any) => {
        if (result?.changeEventLogGroup) {
          this.rowData.nextCursor = result.next;
          this.rowData.offset = subHistoryPayload.offset;
          this.rowData.limit = subHistoryPayload.limit;
          this.assetSubscriptionHistory = { assetData: this.rowData, subscriptionHistory: result.changeEventLogGroup };
        }
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
        this.assetSubscriptionHistory = { assetData: this.rowData, subscriptionHistory: {} };
      },
    });
  }
  customUserCheck() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.toString()?.indexOf('Subscription Management') > -1 &&
      this.userActiveFeatures?.features?.toString()?.indexOf('Read Only') <= -1 &&
      this.userActiveFeatures?.features?.toString()?.indexOf('View Only') <= -1 &&
      this.userActiveFeatures?.features?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') <= -1
    ) {
      return false;
    } else {
      return true;
    }
  }
  showDATToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  checkDATDeclined() {
    if (
      this.assetDetailsData?.ownershipDetails?.length === 1 &&
      this.assetDetailsData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE'
    ) {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
      this.showDATToastMessage(messageText, 'error', linkDetail);
    }
  }

  dismissAssetFromNeedsReview() {
    const dismissAssetPayload = {
      radioSharingConfirmation: null,
      dealer: this.dealer?.dealerCode,
      id: this.assetData.make + '|' + this.assetData.serialNumber,
      Selected_QueueReason: this.selectedQueueReason,
      version: 'v2',
    };
    this.needsReviewService.dismissAsset(dismissAssetPayload).subscribe({
      next: (result: any) => {
        if (result?.status == 'Success') {
          const messageText = 'Asset dismissed';
          this.showToastMessage(messageText, 'success');
          this.dialogRef.close(true);
          setTimeout(() => {
            const evt = { status: true, newCount: this.queueReasonNewCount };
            this.needsReviewService.dismissStatus.next(evt);
          }, 500);
        }
      },
      error: (err: any) => {
        const messageText = 'There was an error processing your request.Please contact Cat Digital Support';
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  getRemoteService() {
    const remoteServicePayloadObj = {
      make: this.updatedNewmake,
      serialNumber: this.updatedSerialNo,
    };
    this.assetDrawerService.getRemoteServiceCapability(remoteServicePayloadObj).subscribe(
      (data: any) => {
        this.isSoftwareUpdateEligible = data.isSoftwareUpdateEligible;

        this.remoteFlashCapabilityUrl = data.remoteFlashCapabilityUrl;
      },
      (err: any) => {
        this.isSoftwareUpdateEligible = false;
      }
    );
  }

  remoteServiceClick() {
    window.open(this.remoteFlashCapabilityUrl, '_blank');
  }
}
