import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dspNextGenUiCopy]',
})
export class CopyDirective {
  @Input() fullText: string | undefined;

  constructor(private el: ElementRef) {}
  @HostListener('copy', ['$event'])
  onCopy(event: any) {
    if (this.fullText) {
      let clipboardText = window.getSelection()?.toString();
      const element = this.el.nativeElement;
      const isEllipsisActive = element.offsetWidth < element.scrollWidth;
      if (isEllipsisActive) {
        if (clipboardText && clipboardText?.length > 2) {
          event.preventDefault();
          const clipoardDta = event.clipboardData;
          clipoardDta?.setData('text', this.fullText);
        }
      } else if (element?.nodeName === 'CC-SIDE-NAV') {
        if (clipboardText && clipboardText?.length > 2) {
          event.preventDefault();
          const clipoardDta = event.clipboardData;
          clipoardDta?.setData('text', this.fullText);
        }
      }
    }
  }
}
