import { Component, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { observeOn } from 'rxjs/operators';

@Component({
  selector: 'dsp-next-gen-ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoading$: Observable<boolean> = this.isLoading.asObservable().pipe(observeOn(asyncScheduler));
  constructor(private loaderService: LoaderService) {}
}
