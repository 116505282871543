import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { CommonMainFiltersComponent } from '../common-main-filters/common-main-filters.component';
import { UserPreferenceService } from '../../../user-preference.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { isEmpty } from 'lodash-es';
import { CommonFilterService } from '../../../services/common-filter.service';

@Component({
  selector: 'dsp-next-gen-ui-common-main-filters-icon',
  templateUrl: './common-main-filters-icon.component.html',
  styleUrls: ['./common-main-filters-icon.component.scss'],
})
export class CommonMainFiltersIconComponent {
  @Input() titleNames: any;
  @Input() tableData: any;
  @Input() customData: any;
  @Input() columnNames: any;
  @Input() pageLabel: any;
  @Input() invoiceNumber: any;
  @Input() queueReasonId: any;
  @Input() status: any;
  @Output() loadChangeEmit = new EventEmitter<any>();
  filterContentsRef: any;
  savedFilters: any;
  filterResponse: any;
  dspStoreData: any;
  isTimeZoneSelectionEnabled: any;
  filtersSaved: any;
  constructor(
    private drawerService: DrawerService,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService,
    private commonFilterService: CommonFilterService
  ) {}
  ngOnInit(): void {
    this.commonFilterService.isFilterSavedEmptySubject.subscribe((data) => {
      if (data !== 'true') {
        this.queueReasonId = data;
      }
      this.savedFiltersFromSession();
      this.isFilterEmpty();
    });
    this.savedFiltersFromSession();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
    });
  }

  openFilter(headerType: any) {
    this.filterResponse = [];
    this.savedFilters = this.filterContentsRef?.config?.data?.savedFilters || {};
    if (this.isTimeZoneSelectionEnabled) {
      let localTimeZone = this.userPreferenceService.getLocalTimeZone();
      this.columnNames?.map((column: any) => {
        if (column.label.includes('Date & Time')) {
          column.label = column?.label?.split('(')[0] + '(' + localTimeZone + ')';
        }
      });
    }
    this.filterContentsRef = this.drawerService.openDrawer<CommonMainFiltersComponent, CcDrawerConfig>(
      CommonMainFiltersComponent,
      {
        data: {
          headerType: headerType,
          titleNames: this.titleNames,
          filters: this.filterResponse,
          savedFilters: this.savedFilters,
          tableData: this.tableData,
          customData: this.customData,
          columnNames: this.columnNames,
          pageLabel: this.pageLabel,
          invoiceNumber: this.invoiceNumber,
          queueReasonId: this.queueReasonId,
          status: this.status,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.filterContentsRef.afterClosed().subscribe((res: any) => {
      if (res?.data) {
        this.loadChangeEmit.emit(res?.data);
      }
      this.savedFiltersFromSession();
    });
  }

  isFilterEmpty() {
    return isEmpty(this.filtersSaved);
  }

  savedFiltersFromSession() {
    this.filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      this.invoiceNumber,
      this.queueReasonId
    );
  }
}
