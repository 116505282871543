import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';

import { clone, cloneDeep, isEmpty } from 'lodash-es';
@Component({
  selector: 'dsp-next-gen-ui-detailed-inv-tooltip-highlight',
  templateUrl: './detailed-inv-tooltip-highlight.component.html',
  styleUrls: ['./detailed-inv-tooltip-highlight.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailedInvTooltipHighlightComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  public customData: any;
  isTimeFormatEnabled: any;
  isTimeZoneSelectionEnabled: any;
  public detailArray = [
    'duration',
    'billingStartDate',
    'billingEndDate',
    'promotionName',
    'promotionEndDate',
    'prepay',
    'subTotal',
    'discount',
    'discount',
    'taxAmount',
    'lineItemTotal',
  ];
  isAmount: any;
  isNumberConventionEnabled: any;
  dspStoreData: any;
  showTruncatedText = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.customData = this.columnWithHeader?.customData;
    this.isAmount = this.customData?.isAmount;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
    this.cdr.detectChanges();
    this.convertDateFormat();
    if (this.isTimeZoneSelectionEnabled) {
      this.userPreferenceService.dateTimeObservable.subscribe((res: any) => {
        this.convertDateFormat();
      });
    }
  }
  convertDateFormat() {
    if (this.isTimeFormatEnabled) {
      let dateColumns = ['billingEndDate', 'billingStartDate', 'promotionEndDate'];
      if (dateColumns.includes(this.columnWithHeader.name) && !isEmpty(this.rowData[this.columnWithHeader.name])) {
        let data = cloneDeep(this.rowData[this.columnWithHeader.name]);
        data = data.split(','); // just checking for comma seperated values
        if (data.length == 1) {
          //if no comma seperated strings
          this.rowData[this.columnWithHeader.name] = this.userPreferenceService.convertDate(data[0]);
        } else {
          //if there are comma seperted strings
          let newData = cloneDeep(data);
          newData.forEach((element: any) => {
            let convertedValue = this.userPreferenceService.convertDate(element);
            data = data.map((item: any) => {
              return item === element ? convertedValue : item;
            });
          });
          this.rowData[this.columnWithHeader.name] = data.join(',');
        }
      }
    }
  }
  getColumnData() {
    if (this.isAmount) {
      return this.rowData && this.rowData[this.columnWithHeader.name]
        ? this.convertNumberFormat(this.rowData[this.columnWithHeader.name])
        : '-';
    }

    return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '-';
  }

  getColumnToolTipData() {
    if (this.isAmount) {
      return this.rowData && this.rowData[this.columnWithHeader.name]
        ? this.convertNumberFormat(this.rowData[this.columnWithHeader.name])
        : '';
    }
    return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
  }

  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }
}
