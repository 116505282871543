<div class="user-admin-main">
  <div class="user-admin-wrapper">
    <span class="page-title">Search Users</span>
    <span class="site-panel-items download-container">
      <div [ngClass]="{ 'disable-menu': isDataLoading || disableExportIcon }" *ngIf="!showLoader; else downloadLoader">
        <div class="ellipse-container" id="cc-menu1" cc-tooltip="Export" (click)="exportUser()">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.16626 6.66699H8.83293V12.3813H6.16626V6.66699Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.49935 16.667L10.8327 10.9527H4.16602L7.49935 16.667Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.58333 20H14.25C15.1208 20 15.8333 19.2742 15.8333 18.3871V7.95161C15.8333 7.51613 15.675 7.1129 15.3742 6.80645L9.15167 0.467742C8.85083 0.16129 8.455 0 8.0275 0H1.58333C0.7125 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.7125 20 1.58333 20ZM0.632324 1.61325C0.632324 1.08099 1.05982 0.645508 1.58232 0.645508H8.02649C8.27982 0.645508 8.51732 0.742282 8.69149 0.93583L14.914 7.27454C15.104 7.45196 15.199 7.69389 15.199 7.95196V18.3874C15.199 18.9197 14.7715 19.3552 14.249 19.3552H1.58232C1.05982 19.3552 0.632324 18.9197 0.632324 18.3874V1.61325Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <ng-template #downloadLoader>
        <div class="loader-container">
          <div class="loader-container__label"></div>
          <div>
            <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                fill="#43485C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                fill="#43485C"
              />
            </svg>
          </div>
        </div>
      </ng-template>
    </span>

    <form [formGroup]="userAdminForm">
      <div class="input-field-container">
        <div class="input-field-width1">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isDense]="true"
            [isTheme]="isTheme"
            [labelValue]="'CWS ID (Min 3 char)'"
            [disabled]="isValidateCWSID"
          >
            <input
              input-field
              formControlName="cwsid"
              class="input-field"
              autocomplete="off"
              type="text"
              required
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
              (blur)="onBlurCwsIdEvent($event)"
            />
          </cc-input-field>
          <span class="req-text">
            <span
              *ngIf="
                formControl['cwsid'].errors?.['minlength'] &&
                (formControl['cwsid'].touched || formControl['cwsid'].dirty)
              "
              >Minimum 3 characters</span
            >
          </span>
        </div>
        <span class="user-label-or">or</span>
        <div class="input-field-width2">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isTheme]="isTheme"
            [isDense]="true"
            [labelValue]="'Last Name (Min 3 char)'"
          >
            <input
              input-field
              formControlName="lastName"
              class="input-field"
              autocomplete="off"
              type="text"
              required
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
              (blur)="onBlurLastNameEvent($event)"
            />
          </cc-input-field>
          <span class="req-text">
            <span
              *ngIf="
                formControl['lastName'].errors?.['minlength'] &&
                (formControl['lastName'].touched || formControl['lastName'].dirty)
              "
              >Minimum 3 characters</span
            >
          </span>
        </div>
        <div (click)="toggleAdvancedSearch()" class="advance-search-wrapper">
          <label class="adv-search-label">Advanced Search</label>
          <div>
            <span [class]="showAdvancedSearch ? 'arrow up' : 'arrow down'"></span>
          </div>
        </div>
      </div>
      <div *ngIf="showAdvancedSearch" class="input-field-container adv-search-container">
        <div class="input-field-width1">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isDense]="true"
            [isTheme]="isTheme"
            [labelValue]="'First Name'"
          >
            <input
              input-field
              formControlName="firstName"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
              (input)="onInputChange($event)"
            />
          </cc-input-field>
          <!-- <span class="req-text">
            <span
              *ngIf="
            formControl['firstName'].errors?.['minlength'] &&
            (formControl['firstName'].touched || formControl['firstName'].dirty)
          "
              >Minimum 3 characters</span
            >
          </span> -->
        </div>
        <div class="input-field-width2">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isDense]="true"
            [isTheme]="isTheme"
            [labelValue]="'Middle Name'"
          >
            <input
              input-field
              formControlName="middleName"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
              (input)="onInputChange($event)"
            />
          </cc-input-field>
          <!-- <span class="req-text">
            <span
              *ngIf="
            formControl['middleName'].errors?.['minlength'] &&
            (formControl['middleName'].touched || formControl['middleName'].dirty)
          "
              >Minimum 3 characters</span
            >
          </span> -->
        </div>
        <div class="input-field-width3">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isDense]="true"
            [isTheme]="isTheme"
            [labelValue]="'Email ID'"
          >
            <input
              input-field
              formControlName="email"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
            />
          </cc-input-field>
          <span class="req-text">
            <span
              *ngIf="
                formControl['email'].errors?.['pattern'] && (formControl['email'].touched || formControl['email'].dirty)
              "
              >Enter valid email ID</span
            >
          </span>
        </div>
        <div class="input-field-width2">
          <cc-input-field
            id="cc-atom-textField"
            [inputtype]="'text'"
            [isDense]="true"
            [isTheme]="isTheme"
            [labelValue]="'Organization'"
          >
            <input
              input-field
              formControlName="organisation"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter Text (Optional)"
              aria-describedby="Enter Text (Optional)"
              (input)="onInputChange($event)"
            />
          </cc-input-field>
          <!-- <span class="req-text">
            <span
              *ngIf="
            formControl['organisation'].errors?.['minlength'] &&
            (formControl['organisation'].touched || formControl['organisation'].dirty)
          "
              >Minimum 3 characters</span
            >
          </span> -->
        </div>
      </div>
      <div class="button-container">
        <button cc-btn-primary cc-btn-large [setTheme]="true" [disabled]="disableSearchBtn" (click)="onSearchUser()">
          Search
        </button>
        <button
          cc-btn-secondary-outline
          cc-btn-large
          [setTheme]="true"
          [disabled]="disableClearAllBtn"
          (click)="clearAll()"
        >
          Clear All
        </button>
      </div>
    </form>
  </div>

  <div
    class="user-admin-table"
    *ngIf="enableUserAdminTable"
    [ngClass]="{
      'table-content': !isDataLoading,
      'scroll-view': userAdminTableData.length > 0 && isEnableUserAdminTableSettings,
    }"
  >
    <cc-table
      #myTable
      aria-describedby="UserAdmin Table"
      [tableConfig]="isEnableUserAdminTableSettings ? userAdminTableConfigNew : userAdminTableConfig"
      [tableData]="userAdminTableData"
      [isDataLoading]="isDataLoading"
      (ccTableEvents)="tableEvents($event)"
      #cctable="cctable"
      [ngClass]="{ 'user-admin-table-settings': isEnableUserAdminTableSettings, 'disable-menu': isDataLoading }"
    >
      <div
        *ngIf="isEnableUserAdminTableSettings"
        class="control-panel__item cc-table-pag-header-left"
        [ngClass]="{
          'disable-menu': isDataLoading,
        }"
      >
        <div #settingIcon class="control-panel__item-icon settings-icon">
          <span cc-tooltip="Settings" class="settings-icon-span" (click)="openSettingDrawer(settingPanelDesktop)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.1907 11.125H18.6252V8.875H16.19C16.0332 8.22475 15.7767 7.23925 15.4377 6.6835L17.1597 4.96225L15.038 2.8405L13.3152 4.5625C12.7602 4.2235 11.7755 3.96775 11.1252 3.811V1.375H8.87524V3.811C8.22499 3.96775 7.23949 4.2235 6.68449 4.5625L4.96174 2.8405L2.83999 4.96225L4.56274 6.68425C4.22374 7.23925 3.96724 8.22475 3.80974 8.875H1.37524V11.125H3.80974C3.96724 11.7752 4.22374 12.7607 4.56274 13.3157L2.83999 15.0385L4.96174 17.1595L6.68374 15.4368C7.23949 15.7773 8.22499 16.033 8.87524 16.1905V18.625H11.1252V16.1905C11.7755 16.033 12.761 15.7765 13.3152 15.4375L15.0387 17.1595L17.1597 15.0385L15.4377 13.3157C15.7767 12.7607 16.0332 11.7752 16.1907 11.125Z"
                stroke="#43485C"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.0002 13.375C11.8642 13.375 13.3752 11.864 13.3752 10C13.3752 8.13604 11.8642 6.625 10.0002 6.625C8.13628 6.625 6.62524 8.13604 6.62524 10C6.62524 11.864 8.13628 13.375 10.0002 13.375Z"
                stroke="#43485C"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
        <ng-template #settingPanelDesktop>
          <div class="control-panel__item-options">
            <cat-digital-workspace-table-settings
              [cancel]="closeSettingDrawer"
              [setTableSettings]="tableSettingsPanelConfig"
              [isDisableFrozenTab]="false"
              [isApply]="isTableSettingsApply"
              [isClose]="isTableSettingClose"
              (closeTableSettingsEvent)="tableSettingsClose($event)"
              (closeTableDataEvent)="closeTableSettingsData($event)"
              [isDrawer]="true"
              [closeOniconClick]="false"
              [settingsPanelConfig]="settingsPanelConfig"
              (saveTableSettingsEvent)="saveSettings($event)"
              [isOutsideClick]="isSettingsOutSideClicked"
              (outsideClickDataEvent)="outsideClickDataEvent($event)"
              [isFrozendragdisabled]="true"
            >
            </cat-digital-workspace-table-settings>
          </div>
        </ng-template>
      </div>

      <!-- Row Detail Template -->
      <ng-container *ngIf="!isEnableUserAdminTableSettings">
        <ng-container *ngFor="let config of columnConfig">
          <cc-table-column
            [name]="config.name"
            [title]="config.title"
            [minWidth]="config.width"
            [priority]="config.priority"
            [isVisible]="config.isVisible"
            [sortingEnable]="config.sortingEnable"
            [ascendingSort]="config.ascendingSort"
            [sortWithOverlay]="config.sortWithOverlay"
            [overlayData]="config.overlayData"
            [makeActive]="config.makeActive"
            contentType="span"
            [disableSelfFoldableRow]="config.name === 'profileType' ? true : false"
          >
            <ng-template let-column="column" cc-table-header-template>
              <span class="header-cell-content">{{ config.title }}</span>
            </ng-template>
            <ng-template let-rowIndex="rowIndex" let-rowdata="rowdata" cc-table-cell-template>
              <div
                *ngIf="config.name !== 'profileType'"
                [cc-tooltip]="getColumnTooltipData(rowdata[config.name], config.title, rowdata)"
                [innerHTML]="getColumnData(rowdata[config.name], config.title)"
                class="text-truncate"
                [tooltipPosition]="'top'"
                dspNextGenUiCopy
                [fullText]="rowdata[config.name]"
              ></div>

              <div class="action-btn" *ngIf="config.name === 'profileType'">
                <button
                  cc-btn-tertiary
                  cc-btn-large
                  [setTheme]="true"
                  [disabled]="
                    rowdata.catRecId === loggedInUserDetails.federationIdentifier &&
                    (loggedInUserDetails.roleName !== 'DSP Administrator' ||
                      loggedInUserDetails.roleName !== 'Super Administrator')
                  "
                  (click)="configureAccess($event, rowdata)"
                >
                  Configure Access
                </button>
              </div>
            </ng-template>
          </cc-table-column>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isEnableUserAdminTableSettings">
        <cc-expandable-row-detail [rowHeight]="rowHeight">
          <ng-template cc-expandable-row-detail-template let-rowdata="rowdata">
            <dsp-next-gen-ui-user-admin-row-expansion
              [tableData]="rowdata"
              [isOpenConfigureAccess]="isOpenConfigAccess"
            >
            </dsp-next-gen-ui-user-admin-row-expansion>
          </ng-template>
        </cc-expandable-row-detail>
      </ng-container>

      <cc-table-skeleton *ngIf="isDataLoading">
        <ng-template cc-table-skeleton-template>
          <cc-skeleton-loader [height]="250" [animation]="true">
            <svg:rect x="7" y="5" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="5" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="5" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="5" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="5" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="5" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="18" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="18" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="18" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="18" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="18" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="18" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="31" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="31" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="31" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="31" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="31" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="31" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="44" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="44" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="44" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="44" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="44" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="44" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="57" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="57" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="57" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="57" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="57" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="57" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="70" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="70" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="70" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="70" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="70" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="70" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="83" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="83" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="83" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="83" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="83" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="83" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="96" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="96" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="96" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="96" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="96" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="96" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="109" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="109" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="109" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="109" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="109" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="109" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="122" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="122" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="122" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="122" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="122" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="122" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="135" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="135" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="135" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="135" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="135" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="135" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="148" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="148" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="148" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="148" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="148" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="148" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="161" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="161" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="161" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="161" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="161" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="161" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="174" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="174" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="174" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="174" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="174" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="174" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="189" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="189" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="189" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="189" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="189" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="189" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="7" y="202" rx="1" ry="1" width="68" height="2.5" />
            <svg:rect x="90" y="202" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="165" y="202" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="240" y="202" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="315" y="202" rx="1" ry="1" width="60" height="2.5" />
            <svg:rect x="390" y="202" rx="1" ry="1" width="50" height="2.5" />

            <svg:rect x="0" y="13" width="540" height="0.5" />
            <svg:rect x="0" y="26" width="540" height="0.5" />
            <svg:rect x="0" y="39" width="540" height="0.5" />
            <svg:rect x="0" y="52" width="540" height="0.5" />
            <svg:rect x="0" y="65" width="540" height="0.5" />
            <svg:rect x="0" y="78" width="540" height="0.5" />
            <svg:rect x="0" y="91" width="540" height="0.5" />
            <svg:rect x="0" y="104" width="540" height="0.5" />
            <svg:rect x="0" y="117" width="540" height="0.5" />
            <svg:rect x="0" y="130" width="540" height="0.5" />
            <svg:rect x="0" y="143" width="540" height="0.5" />
            <svg:rect x="0" y="156" width="540" height="0.5" />
            <svg:rect x="0" y="169" width="540" height="0.5" />
            <svg:rect x="0" y="182" width="540" height="0.5" />
          </cc-skeleton-loader>
        </ng-template>
      </cc-table-skeleton>
    </cc-table>
    <div *ngIf="(!userAdminTableData || userAdminTableData.length === 0) && !isDataLoading && enableUserAdminTable">
      <div class="no-results-found">
        <img src="../../../assets/images/no_results_found.png" alt="no results found" class="no-result-found-img" />
        <span>No Results Found</span>
      </div>
    </div>
  </div>
</div>
