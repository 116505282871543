import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  @Output() updateExpandRowDetailsEvent = new EventEmitter();
  rootURL = '';
  dealer: any;
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getBillinSummary(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/zuoraBilling/getInvoiceSummary', payload);
  }
  getOutstandingBalance(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/zuoraBilling/outstandingBalance', payload);
  }

  exportPDFInvoiceDetails(payload: any) {
    return this.http.post(
      this.rootURL +
        `/zuoraBilling/exportInvoiceDetailsPDF?invoiceNumber=${payload.invoiceNumber}&dealerCode=${payload.dealerCode}`,
      {}
    );
  }
  exportAssetData(inputData: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/v2/export', inputData);
  }

  getAssetDataExportStatus(id: string) {
    return this.http.get(this.rootURL + '/zuoraBilling/export/' + id);
  }

  downloadAssetData(fileId: string) {
    return this.http.get(this.rootURL + '/zuoraBilling/download/' + fileId, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  exportExcelDetailedInvoiceDetails(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/exportDetailedInvoicestoExcel', payload);
  }

  exportExcelInvoiceDetails(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/exportInvoiceDetailsToExcel', payload);
  }

  getAppRoleInfo(payload: any) {
    return this.http.post(this.rootURL + '/user/appRoleInfo', payload);
  }
  getAppUserOnboard(payload: any) {
    return this.http.post(this.rootURL + '/user/appUserOnboard', payload);
  }
  getApplicationRoleInfo(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/roleinfo', {
      params: queryParams,
    });
  }
  getAppFeaturesByAppProfile(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/rolefeaturebyappprofile', {
      params: queryParams,
    });
  }
  getActiveFeatures(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/features/active', {
      params: queryParams,
    });
  }
  getuserDealerSearch(payload: any) {
    return this.http.post(this.rootURL + '/user/userDealerSearch', payload);
  }

  getDetailsLineItem(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/getInvoiceDetailsLineItems', payload);
  }
  getsubmitBillingInquiry(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/submitBillingInquiry ', payload);
  }
  getDetailedBillingInvoice(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/getDetailedInvoices', payload);
  }
  getDetailedInvoicesCount(payload: any) {
    return this.http.post(this.rootURL + '/zuoraBilling/getDetailedInvoicesCount', payload);
  }
  exportCreditMemoPDF(url: string, inputData: any) {
    return this.http.post(this.rootURL + url, null, { params: inputData });
  }

  exportCreditMemoExcel(url: string, inputData: any) {
    return this.http.post(this.rootURL + url, inputData);
  }
  getUser(): Observable<any> {
    return this.http.get(this.rootURL + '/user/getUserDetails').pipe(
      map((user) => user || []),
      catchError((error) => throwError(() => error))
    );
  }
  getDetailedInvoiceFilterColumns(dealerCode: string) {
    return this.http.get(this.rootURL + `/zuoraBilling/detailedInvoiceFilterColumns?dealerCode=${dealerCode}`);
  }
  getDetailedInvoiceColumnValues(input: any) {
    return this.http.post(this.rootURL + `/zuoraBilling/detailedInvoiceFilterColumnValues`, input);
  }
}
