import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import * as dspConstants from '../../.././../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-license-info-header',
  templateUrl: './license-info-header.component.html',
  styleUrls: ['./license-info-header.component.scss'],
})
export class LicenseInfoHeaderComponent implements OnInit {
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;
  dspStoreData!: any;
  dspWorklistConstants = dspConstants.Worklist;
  constructor(private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.config = this.columnWithHeader;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }
}
