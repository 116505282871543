<div class="cc-drawer expand-history-drawer">
  <div class="cc-drawer__header">
    <div class="cc-drawer__header-container">
      <span class="cc-headerText cc-drawer__ellipsis expand-view-header-text">Subscription History</span>
      <span class="cc-drawer__withsubHeader-item cc-drawer__ellipsis">
        Make: {{ assetData?.make }} &nbsp;&nbsp;
        <span class="header-pipe"
          ><svg width="1" height="12" viewBox="0 0 1 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="13" stroke="#DDDEDF" />
          </svg>
        </span>
        &nbsp;&nbsp; Model: {{ assetData?.model }} &nbsp;&nbsp;
        <span class="header-pipe"
          ><svg width="1" height="12" viewBox="0 0 1 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="13" stroke="#DDDEDF" />
          </svg>
        </span>
        &nbsp;&nbsp; Asset S/N: {{ assetData?.serialNumber }}
      </span>
    </div>
    <div class="cc-drawer__headerAction cc-drawer__withsubHeader-action">
      <span class="export-icon-alignment">
        <div
          class="menu-content"
          cc-menu
          [template]="singleSelectTemplate"
          OverlayPosition="bottom"
          [label]="'Title'"
          *ngIf="!showLoader; else downloadLoader"
          (closed)="toggleExportIcon()"
          (click)="toggleExportIcon()"
        >
          <div
            [ngClass]="isXLDownloadEnabled === true ? '' : 'ellipse-container'"
            id="cc-menu1"
            cc-tooltip="Export"
            (click)="onListItemClick()"
          >
            <svg
              width="15"
              [ngClass]="isXLDownloadEnabled === true ? 'svg-export' : ''"
              height="20"
              viewBox="0 0 15 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.84143 6.66699H8.36775V12.3813H5.84143V6.66699Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.10467 16.667L10.2626 10.9527H3.94678L7.10467 16.667Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 20H13.5C14.325 20 15 19.2742 15 18.3871V7.95161C15 7.51613 14.85 7.1129 14.565 6.80645L8.67 0.467742C8.385 0.16129 8.01 0 7.605 0H1.5C0.675 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.675 20 1.5 20ZM0.599044 1.61325C0.599044 1.08099 1.00404 0.645508 1.49904 0.645508H7.60404C7.84404 0.645508 8.06904 0.742282 8.23404 0.93583L14.129 7.27454C14.309 7.45196 14.399 7.69389 14.399 7.95196V18.3874C14.399 18.9197 13.994 19.3552 13.499 19.3552H1.49904C1.00404 19.3552 0.599044 18.9197 0.599044 18.3874V1.61325Z"
                fill="black"
              />
            </svg>
            <cc-icon
              *ngIf="isXLDownloadEnabled === true"
              fontSet="icomoon"
              [ngClass]="isExport ? 'exportArrowDown' : 'exportArrowUp'"
            >
              adv-search
            </cc-icon>
          </div>
        </div>
        <ng-template #downloadLoader>
          <div class="loader-container">
            <div class="loader-container__label"></div>
            <div>
              <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                  fill="#43485C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                  fill="#43485C"
                />
              </svg>
            </div>
          </div>
        </ng-template>
      </span>
      <span class="align-close">
        <cc-icon fontSet="icomoon" class="cc-drawer__close close-icon color-change" (click)="closeDrawer()"
          >cross</cc-icon
        >
      </span>
    </div>
  </div>
  <div (scroll)="onScroll($event)" class="cc-drawer__content">
    <table>
      <cc-accordion id="expandedAssetHistory">
        <div *ngFor="let transactionId of fetchTransactionIds()">
          <cc-expandable
            (opened)="handleExpandEvent(transactionId)"
            [expanded]="subscriptionHistoryTableData[0]?.transactionId === transactionId"
          >
            <cc-expandable-header>
              <span class="title-display">{{ updateTransactionType(transactionId) }}</span>
            </cc-expandable-header>
            <cc-table
              #expandedHistoryTableRef
              aria-describedby="Expanded Asset History Table"
              [tableConfig]="expandedAssetHistoryTableConfig"
              [tableData]="subscriptionHistoryTableData"
            ></cc-table>
          </cc-expandable>
        </div>
      </cc-accordion>
    </table>
  </div>
  <div class="cc-drawer__footer expand-footer-style">
    <div class="expand-history-footer">
      <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
      <h5>{{ expandHistoryFooterMessage }}</h5>
    </div>
  </div>
</div>

<ng-template #singleSelectTemplate>
  <div class="cc-contextual-menu" id="cc-single-menu1" *ngIf="isXLDownloadEnabled">
    <cc-list [theme]="'secondary'" width="1000px">
      <cc-list-item *ngFor="let item of listItems" (listItemClick)="onListItemClick(item.label)">
        {{ item.label }}
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>
