import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  Renderer2,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { TermModalComponent } from '../footer/term-modal/term-modal.component';
import { TermsofsaleModalComponent } from '../footer/termsofsale-modal/termsofsale-modal.component';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import { SharedService } from '../../shared.service';
import { UserPreferenceService } from '../../user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-cat-billing',
  templateUrl: './cat-billing.component.html',
  styleUrls: ['./cat-billing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CatBillingComponent implements OnInit, AfterViewInit, OnChanges {
  notificationMessage: any;
  globalInstance: any;
  @Input() payMentPreview!: any;
  @Input() payBillingDetails!: any;
  @Input() cancelPlan!: any;
  @Input() editSite!: any;
  @Input() createSite!: any;
  @Input() currentBillingDetails!: any;
  @Input() newBillingDetails!: any;
  @Input() isSitePricingEligible!: any;
  @Input() currentPlanDisplay: any;
  @Input() newPlanDisplay: any;
  @Input() cancelPlanDetails: any;
  userActiveFeatures: any;
  isNumberConventionEnabled: any;
  isTimeFormatEnabled: any;
  constructor(
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>,
    private sharedService: SharedService,
    private userPreferenceService: UserPreferenceService
  ) {
    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
    });
  }

  ngOnInit(): void {
    let selectedMessage = '';
    if (this.cancelPlan || this.createSite) {
      selectedMessage = this.payBillingDetails?.cancelNotificationMessage;
    } else if (this.editSite) {
      selectedMessage = this.newBillingDetails?.cancelNotificationMessage;
    }
    this.notificationMessage = this.updatePaymentNotificationMessage(selectedMessage || '');
  }

  ngOnChanges(): void {
    let selectedMessage = '';
    if (this.cancelPlan || this.createSite) {
      selectedMessage = this.payBillingDetails?.cancelNotificationMessage;
    } else if (this.editSite) {
      selectedMessage = this.newBillingDetails?.cancelNotificationMessage;
    }
    this.notificationMessage = this.updatePaymentNotificationMessage(selectedMessage || '');
  }

  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }

  updatePaymentNotificationMessage(notificationMessage: string) {
    let updatedMessage = notificationMessage
      .split('<br/>')
      .join(' ')
      .replace('href="terms/tos"', 'onclick="termSaleModal()"')
      .replace(
        '"/assets/dsp_help_document/index.htm#t=BillingOverview.htm"',
        '"' + environment.redirectURL.helpDocUrl + 'article/Can-I-prepay-for-subscriptions?language=en_US"'
      );
    const TOS_REGEX = new RegExp('By submitting your order, you are agreeing to our <a[^>]*>Terms of Sale</a>');
    if (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) {
      updatedMessage = updatedMessage.replace(TOS_REGEX, '');
    }
    return updatedMessage;
  }

  termSaleModal() {
    sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('termSaleModal', '_blank');
  }
  getPromotionDate(promotionDate: any) {
    if (this.isTimeFormatEnabled) {
      return this.userPreferenceService.convertDate(promotionDate);
    }
    return promotionDate;
  }
  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }
}
