<div class="subscription-details" *ngIf="newSubscriptionData || oldSubscriptionData">
  <!-- Edit Flow -->
  <div *ngIf="oldSubscriptionData && oldSubscriptionData.length">
    <div class="section-line">
      <span class="section-heading current"> Current </span>
    </div>

    <div class="accordion" *ngFor="let subscriptionData of oldSubscriptionData">
      <cc-accordion [multi]="true">
        <cc-expandable [expanded]="true">
          <cc-expandable-header>
            <div class="application-name">
              <span> {{ subscriptionData?.applicationName }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>
                <div class="CellHead priceCell" *ngIf="subscriptionData.prepay">
                  <div class="headerCell">Contract Start Date</div>
                </div>
                <div class="CellHead priceCell" *ngIf="subscriptionData.prepay">
                  <div class="headerCell">Contract End Date</div>
                </div>

                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>

              <div class="Row" *ngIf="subscriptionData?.basePlan?.name">
                <div class="Cell">
                  <div class="total-plan">{{ subscriptionData.basePlan.name }}</div>
                </div>
                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractStartDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractStartDate) }}</div>
                </div>
                <div
                  class="Cell priceCell"
                  *ngIf="subscriptionData.prepay && subscriptionData.prepayDates.contractEndDate"
                >
                  <div class="total-plan">{{ getConvertedDate(subscriptionData.prepayDates.contractEndDate) }}</div>
                </div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(subscriptionData.basePlan.amount) }}</div>
                </div>
              </div>

              <div class="Row" *ngFor="let plan of subscriptionData.optionalPlan?.slice(0, 1) || []; let i = index">
                <div class="Cell">
                  <div class="total-plan">{{ plan.name }}</div>
                </div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(plan.amount) }}</div>
                </div>
              </div>
              <div *ngIf="subscriptionData?.optionalPlan?.length > 1" class="Cell">
                <a class="link total-plan-link" (click)="openDrawer(subscriptionData, 'current')"> See more </a>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
  </div>

  <!-- Create Flow -->
  <div *ngIf="newSubscriptionData">
    <div class="section-line">
      <span class="section-heading new"> New </span>
    </div>
    <div class="accordion">
      <cc-accordion [multi]="true">
        <cc-expandable [expanded]="true">
          <cc-expandable-header>
            <div class="application-name">
              <span> {{ newSubscriptionData?.applicationName }}</span>
            </div>
          </cc-expandable-header>
          <div class="rowTbl">
            <div class="Table">
              <div class="Row rowHead">
                <div class="CellHead">
                  <div class="headerCell">Plan</div>
                </div>

                <div class="CellHead priceCell">
                  <div class="headerCell">Price</div>
                </div>
              </div>

              <div class="Row">
                <div class="Cell">
                  <div class="total-plan">{{ newSubscriptionData.basePlan.name }}</div>
                </div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(newSubscriptionData.basePlan.amount) }}</div>
                </div>
              </div>
              <div class="Row" *ngFor="let plan of newSubscriptionData?.optionalPlan?.slice(0, 1) || []; let i = index">
                <div class="Cell">
                  <div class="total-plan">{{ plan.name }}</div>
                </div>

                <div class="Cell priceCell">
                  <div class="total-plan">{{ displayAmount(plan.amount) }}</div>
                </div>
              </div>
              <div *ngIf="newSubscriptionData?.optionalPlan?.length > 1" class="Cell">
                <a class="link total-plan-link" (click)="openDrawer(newSubscriptionData, 'new')"> See more </a>
              </div>
              <div class="Row promotion-applied-text">Promotion(s) Applied</div>
              <!-- if promotion there -->
              <div *ngIf="newSubscriptionData.promotion.length > 0">
                <div class="Row promotion-section" *ngFor="let promo of newSubscriptionData.promotion">
                  <div class="Cell">
                    <div class="total-plan">{{ promo.name }}</div>
                  </div>

                  <div class="Cell priceCell">
                    <div class="total-plan">{{ displayAmount(promo.amount) }}</div>
                  </div>
                </div>
              </div>

              <!-- if no promotion there -->
              <div class="Row promotion-section" *ngIf="newSubscriptionData.promotion.length === 0">
                <div class="Cell">
                  <div class="total-plan">
                    Not available
                    <cc-icon
                      [template]="noPromoTooltipTemplate"
                      [cc-tooltip]="''"
                      [interactive]="true"
                      [appClass]="'-promo-info-tool-tip'"
                      class="info-message-icon-size"
                      [tooltipPosition]="'bottom'"
                      >info-tooltip</cc-icon
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </cc-expandable>
      </cc-accordion>
    </div>
  </div>
</div>

<ng-template #noPromoTooltipTemplate>
  <div class="promo-tooltip-body">
    <span
      >Asset does not currently meet eligibility criteria for promotion. If the asset is not on a contract and it
      becomes eligible by the end of the month, it will be re-evaluated prior to your next invoice. Promotions may
      require dealer submission of customer sale date.
      <a [href]="dspHelpUrl" target="_blank" style="color: #2595ff; text-decoration: underline; font-weight: 400"
        >Click here</a
      >
      for more information.</span
    >
  </div>
</ng-template>
