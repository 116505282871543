<div class="home-main">
  <div class="home-search-container">
    <img src="../../../assets/images/home.png" class="home-img" />
    <div class="salutation">
      {{ greetingFunction() + ', ' + (loggedInUser?.firstName ? loggedInUser?.firstName : loggedInUser?.lastName) }}
    </div>
    <div class="home-header">
      <div class="home-search">
        <cc-input-field
          [labelValue]="'Search Asset / Engine / Device / Radio S/N / Customer (Min 3 Char)'"
          [inputtype]="'search'"
          isTheme="2.0"
          id="cc-atom-search-s7"
          [value]="searchValue?.value"
          [searchMenuHeader]="mobileHeader"
          [triggerSearchMinChar]="3"
          (valueChange)="onSearchChange($event)"
          [enableMobileOverlay]="false"
          [isPageControl]="true"
          [isLeading]="true"
          [maxlength]="200"
          [helpText]="searchHelpText"
          [disabled]="isDataLoading"
        >
          <span class="searchIcon"> </span>
          <input
            #homeSearchBox
            input-field
            [formControl]="searchValue"
            class="input-field input-search-width"
            [value]="searchValue.value"
            autocomplete="off"
            [isFloating]="false"
            [maxlength]="200"
            type="search"
            aria-labelledby="Search Help Text"
            aria-describedby="Search Help Text"
            (focus)="onFocusEvent()"
            (valueChange)="onSearchChange($event)"
            (keydown.enter)="
              searchValue?.value?.trim().length > 0 && searchValue?.value?.trim().length <= 2 ? displayErrorMsg() : '';
              searchValue?.value?.trim().length > 2 ? getSearchDetails(true) : ''
            "
          />
        </cc-input-field>
        <span class="searchButton" (click)="searchValue?.value?.trim().length > 2 ? getSearchDetails(true) : ''">
          <a
            cc-btn-primary
            cc-btn-large
            class="primary button-opacity"
            [setTheme]="true"
            [disabled]="!enableSearch || isTableLoading"
            ><svg width="20" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                fill="black"
              />
            </svg>
          </a>
        </span>
      </div>
      <div class="home-sub-header" *ngIf="!hideAdvanceSearch && showTable">
        Unable to find your asset?
        <a class="click-here-link" (click)="openAdvancedSearch()"> Click Here </a>
      </div>
    </div>
  </div>
  <div *ngIf="!showTable && showRecentlyViewedAssets">
    <dsp-next-gen-ui-home-recently-viewed></dsp-next-gen-ui-home-recently-viewed>
  </div>
  <div class="home-table-container" *ngIf="showTable">
    <dsp-next-gen-ui-home-table
      [homeSearchVal]="fullSearchTxt"
      [noResultInAdvanceSearch]="noResultInAdvanceSearch"
      [toggleSearch]="toggleSearch"
      (isSkeletonLoading)="isSkeletonLoading($event)"
    ></dsp-next-gen-ui-home-table>
  </div>
</div>
<ng-template #advance_search_template>
  <ng-container>
    <div class="cc-modal advance-search-template">
      <div class="cc-modal-header">
        <div class="cc-modal-title">Asset Search</div>
        <div (click)="closeModel()" class="cc-modal-close">
          <img class="cc-close-semi" src="/assets/icons/closeSemi.svg" [alt]="'closeIcon'" />
        </div>
      </div>
      <div class="cc-modal-content warning-modal-content">
        <form [formGroup]="formGroupAssetSearch">
          <div class="inputs">
            <div class="advanceSearchField">
              <div class="advanceSearchHeader"><span class="red">* </span>Asset Serial Number</div>
              <cc-input-field
                [labelValue]="'Enter Full S/N'"
                [inputtype]="'search'"
                [isLeading]="true"
                [isDense]="true"
                [isTheme]="'2.0'"
                [value]="serialNumber"
                (valueChange)="serialNumChange($event)"
                [searchMenuHeader]="mobileHeader"
                [enableMobileOverlay]="true"
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <input
                  input-field
                  formControlName="serialNum"
                  class="input-field input-search-width"
                  [value]="serialNumber"
                  [isFloating]="false"
                  autocomplete="off"
                  type="search"
                  aria-labelledby="Search"
                  aria-describedby="Search"
                  (valueChange)="serialNumber = $event.value"
                />
              </cc-input-field>
              <span class="req-text asset-serial-number-error">
                <ng-container *ngIf="formGroupAssetSearch.controls['serialNum']?.touched">
                  <span *ngIf="formGroupAssetSearch.controls['serialNum']?.errors?.['required']">
                    Serial Number cannot be empty
                  </span>
                </ng-container>
                <span *ngIf="formGroupAssetSearch.controls['serialNum']?.errors?.['minlength']">
                  Please enter at least 3 characters
                </span>
              </span>
            </div>
            <div class="advanceSearchField">
              <div class="advanceSearchHeader"><span class="red">* </span>Make code / Name</div>
              <cc-input-field
                [labelValue]="'Enter Make Code / Name'"
                [inputtype]="'search'"
                [isLeading]="true"
                [isDense]="true"
                [isTheme]="'2.0'"
                [reset]="false"
                [value]="make"
                [searchMenuHeader]="mobileHeader"
                [triggerSearchMinChar]="3"
                [enableSearchOverlay]="true"
                (valueChange)="makeValueChange($event)"
                [disabled]="makeLoader"
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div id="customer-dropdown" class="cc-input-search-menu">
                  <div *ngIf="makeLoader" class="no-result load-container"></div>
                  <ng-container *ngIf="makecodes && makecodes.length > 0">
                    <cc-list [theme]="'secondary'">
                      <cc-list-item
                        *ngFor="let item of makecodes; let i = index"
                        class="list-item-pg"
                        (listItemClick)="listItemClick(i)"
                        (listItemEnter)="listItemClick(i)"
                      >
                        <div class="each-item">
                          <div class="txt-name">
                            <span
                              class="label"
                              [innerHTML]="item.makeCode + '/' + item.makeName | highlightSearch: make"
                            ></span>
                          </div>
                        </div>
                      </cc-list-item>
                    </cc-list>
                  </ng-container>
                </div>
                <input
                  input-field
                  class="input-field"
                  [value]="make"
                  [isFloating]="false"
                  autocomplete="off"
                  type="search"
                  formControlName="make"
                  aria-labelledby="Search product"
                  aria-describedby="Search product"
                  (valueChange)="make = $event.value"
                />
              </cc-input-field>
              <span class="req-text makecode-error">
                <ng-container *ngIf="formGroupAssetSearch.controls['make']?.touched">
                  <span *ngIf="formGroupAssetSearch.controls['make']?.errors?.['required']">
                    Make Code/Name cannot be empty
                  </span>
                </ng-container>
                <span *ngIf="formGroupAssetSearch.controls['make']?.errors?.['minlength']">
                  Please enter at least 3 characters
                </span>
                <span *ngIf="formGroupAssetSearch.controls['make']?.errors?.['make-not-found']"
                  >Make Code/Name not found</span
                >
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="cc-modal-footer">
        <button
          cc-btn-secondary
          cc-btn-large
          [setTheme]="true"
          class="cancel button-opacity"
          (click)="onCancel()"
          type="cancel"
        >
          Cancel
        </button>
        <button
          cc-btn-primary
          cc-btn-large
          [setTheme]="true"
          class="delete button-opacity"
          [disabled]="
            !formGroupAssetSearch.valid || make === '' || serialNumber.trim().length <= 2 || !isListItemSelected
          "
          (click)="handleAdvanceDeviceSearch()"
        >
          Search
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
