import { Component, OnInit } from '@angular/core';
import { siteData } from '../sites-interface';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-site-name',
  templateUrl: './site-name.component.html',
  styleUrls: ['./site-name.component.scss'],
})
export class SiteNameComponent implements OnInit {
  public value: any;
  public rowData: any;
  public customData: any;
  public columnWithHeader: any;
  public searchString = '';
  dspStoreData: any;
  showTruncatedText = false;
  constructor(private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.customData = this.columnWithHeader?.customData;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
  }

  parseSiteNameData(rowData: siteData) {
    const siteName = `${rowData.name}`;
    if (siteName && siteName.length > 35) {
      return `${siteName.substring(0, 35)}...`;
    } else {
      return siteName;
    }
  }
}
