import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { DashboardService } from './services/dashboard.service';
import { loggedInUser } from '../../store/selectors/user-info.selector';
import { select, Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
import { DspUtilsCommonService } from '../../services/dsp-utils-common.service';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../../shared/confirmation-popup.guard';
import { Router } from '@angular/router';
import { AssetService } from '../assets/services/asset.service';
import { SharedService } from '../../shared.service';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, CanComponentDeactivate {
  dspStoreData!: any;
  userDealerCode: any;
  constructor(
    private dashboardService: DashboardService,
    private store: Store<DSPAppState>,
    private dspUtilsCommonService: DspUtilsCommonService,
    private router: Router,
    private assetService: AssetService,
    private sharedService: SharedService
  ) {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }
  loggedUser: any;
  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  isConnectivitySummary = [];
  isCancellationReasons = [];
  isSubscribedAssetTrend = [];
  isNeedsReview = [];
  activeModal: any;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsCommonService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsCommonService.isAllowedNavigation();
  }

  ngOnInit() {
    this.loggedUser = this.dspUtilsCommonService.getValue('userRole');
    this.store.select('user').subscribe((user) => {
      this.userDealerCode = user.user?.dealerCode || '';
    });
    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({ dealerCode: this.userDealerCode, updateUserDetails: '', siteId: 'DSP' , templateName: 'DSP Dashboard Page'});
    }
    this.dashboardService.getAvailableWidgets(this.dealerCode).subscribe({
      next: (response: any) => {
        this.isConnectivitySummary = response.filter((user: any) => {
          return user.name === 'ConnectivitySummary';
        });
        this.isCancellationReasons = response.filter((user: any) => {
          return user.name === 'CancelReasons';
        });
        this.isSubscribedAssetTrend = response.filter((user: any) => {
          return user.name === 'Subscribed Asset Trend';
        });
        this.isNeedsReview = response.filter((user: any) => {
          return user.name === 'QueueReasons';
        });
      },
      error: (error) => {},
    });
  }
}
