import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewInit,
  Renderer2,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { AssetService } from '../../services/asset.service';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { TermModalComponent } from '../../../footer/term-modal/term-modal.component';
import * as dspConstants from '../../../../shared/dspConstants';
import { TermsofsaleModalComponent } from '../../../footer/termsofsale-modal/termsofsale-modal.component';
import { Router } from '@angular/router';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelSubscriptionComponent implements OnInit, AfterViewInit {
  @Input() rowData: any;
  @Input() subsPricingEligible!: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Output() updateTrackerIndex = new EventEmitter();
  cancelDetails: any = [];
  paymentDetails: any;
  footerMessage: any;
  selectedOption: any;
  globalInstance: any;
  showWarning = false;
  userActiveFeatures: any;
  isPaymentPreviewMessage = false;
  isChinaDealer: boolean = false;
  dealer: any;

  constructor(
    private assetDrawerService: AssetDrawerService,
    public modal: CcModal,
    private assetService: AssetService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();

    this.store.select('dsp').subscribe((dsp) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isPaymentPreviewMessage = dsp?.featureFlagInfo?.isPaymentPreviewMessage
        ? dsp?.featureFlagInfo?.isPaymentPreviewMessage
        : false;
    });
    this.cancelPage();
    if (this.isPaymentPreviewMessage && !this.subsPricingEligible) {
      this.paymentPage();
    }
    if (this.subsPricingEligible) {
      this.paymentPage();
    }
    this.selectedAssetSubscriptionFormData.isValidForm = false;
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  //cancelReasonsApi
  cancelPage() {
    this.assetDrawerService.cancelreasons().subscribe({
      next: (response: any) => {
        this.showWarning = true;
        this.setSelectedCancelReason(response);
        this.cancelDetails = this.filterCancelReasonFn(response);
      },
    });
  }

  filterCancelReasonFn(cancelReasons: any) {
    let filteredCancelReason = cancelReasons;
    if (this.rowData?.deviceType == 'NO_DEVICE' || this.rowData?.deviceType == null) {
      filteredCancelReason = cancelReasons?.filter((element: any) => {
        return (
          element.reason.toLowerCase() != dspConstants.Worklist.Device_Impacted_reason.toLowerCase() &&
          !element.isSuspended
        );
      });
    }
    return filteredCancelReason;
  }

  setSelectedCancelReason(cancelReasonList: any) {
    const { cancelOptions } = this.selectedAssetSubscriptionFormData;
    if (cancelReasonList && cancelOptions?.reason !== '') {
      const selectedReason = cancelReasonList.find(
        (cancelOption: any) => cancelOption?.reason === cancelOptions?.reason
      );
      if (selectedReason) {
        this.selectedOption = selectedReason?.reason;
        this.selectedAssetSubscriptionFormData.isValidForm = true;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
    }
  }

  //getPaymentPreviewApi
  paymentPage() {
    const getPaymentPage = {
      applicationName: this.rowData?.applicationName,
      assetMake: this.rowData?.make || '',
      assetSerialNumber: this.rowData?.serialNumber || '',
      contractEndDate: '',
      contractStartDate: '',
      customerName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dcnName: this.selectedAssetSubscriptionFormData.customer.dealerCustName,
      dcnNumber: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dealerCancelReason: 'Price',
      dealerCode: this.selectedAssetSubscriptionFormData?.customer?.dealerCode,
      deviceId: '',
      deviceSerialNumber: '',
      deviceType: this.rowData?.deviceType || '',
      isNewSubscription: true,
      prepay: false,
      ucid: this.selectedAssetSubscriptionFormData?.customer?.ucid || '',
    };
    this.assetDrawerService.getPaymentPreview(getPaymentPage).subscribe({
      next: (response: any) => {
        this.paymentDetails = response;
        this.footerMessage = this.paymentDetails.cancelNotificationMessage
          .split('<br/>')
          .join(' ')
          .replace('href="terms/tos"', 'onclick="termSaleModal()"');
        const TOS_REGEX = new RegExp('When you submitted your order, you agreed to our <a[^>]*>Terms of Sale</a>');
        if (
          (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) ||
          this.userActiveFeatures?.indexOf('Contract Visualization') === -1
        ) {
          this.footerMessage = this.footerMessage.replace(TOS_REGEX, '');
        }
      },
    });
  }
  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }

  //selectOptionFromRadioButton
  handleCancelReasonSelection(option: any) {
    this.updateTrackerIndex.emit();
    this.selectedAssetSubscriptionFormData.isValidForm = true;
    this.selectedAssetSubscriptionFormData.cancelOptions = option;
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
}
