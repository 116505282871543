import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty } from 'lodash-es';
import { AssetService } from '../../services/asset.service';
import { dealerType } from '../../../../models/shared.interface';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../../shared/shared';
import * as dspConstants from '../../../../shared/dspConstants';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { LoaderService } from '../../../../services/loader.service';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { DcnListComponent } from '../../manage-asset-subscription/dcn-list/dcn-list.component';

@Component({
  selector: 'dsp-next-gen-ui-select-ownership-menu',
  templateUrl: './select-ownership-menu.component.html',
  styleUrls: ['./select-ownership-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectOwnershipMenuComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() launchType: any;
  @Input() hasPartialOwnershipData: any;
  @Output() retrieveSyncedOwnership = new EventEmitter();
  dealer!: dealerType;
  assetOwners: any = [];
  datToastMsgConfig: any[] = [];
  edUrl = '';
  datUrl = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  ownershipSystemError = dspConstants.Common.OWNERSHIP_SYSTEM_ERROR;
  noActiveSyncError = dspConstants.Common.NO_ACTIVE_SYNC_ERROR;
  selectedOwner!: any;
  dspConfig: any;
  dcnListDrawerRef: any;
  enableEquipmentData = false;
  replaceEDUrl!: any;
  dspStoreData!: any;
  enableOwnershipSync!: any;
  userActiveFeatures!: any;
  isdatExcludedProductCheck = false;
  constructor(
    private messageBar: MessageBar,
    private assetService: AssetService,
    private loaderService: LoaderService,
    public assetDrawerService: AssetDrawerService,
    private drawerService: DrawerService,
    public changeDetector: ChangeDetectorRef,
    public store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.enableOwnershipSync = this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        ? this.dspStoreData?.featureFlagInfo?.showOwnershipSync
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.edUrl = dsp.edUrl?.data;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspConfig = dsp.dspConfig || {};
      this.replaceEDUrl = this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        ? this.dspStoreData?.featureFlagInfo?.replaceEDUrl
        : false;
      this.isdatExcludedProductCheck = dsp?.featureFlagInfo?.datExcludedProductCheck
        ? dsp?.featureFlagInfo?.datExcludedProductCheck
        : false;
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.selectedAssetSubscriptionFormData.isValidForm = false;
    this.setAssetOwnershipData();
    this.setEnableEquipmentData();
  }
  enableSyncOwnership() {
    return this.assetService.validateOwnershipSync(this.userActiveFeatures, this.rowData, this.enableOwnershipSync);
  }
  /**
   * This function initiates the synchronization of asset ownership.
   * It performs the following steps:
   * 1. Constructs the payload for ownership synchronization with asset IDs and dealer code.
   * 2. Calls the assetDrawerService to initiate the ownership synchronization.
   * 3. On success, emits an event to retrieve the synced ownership.
   * 4. On error, shows a toast message based on the error status:
   *    - If the error status is 409, shows an informational message indicating no active sync.
   *    - For other errors, shows an error message indicating a system error.
   *
   * @returns {void}
   */
  initiateSync() {
    const ownershipSyncPayload = {
      assetIds: this.rowData.assetId,
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.initOwnershipSync(ownershipSyncPayload).subscribe({
      next: (result: any) => {
        this.retrieveSyncedOwnership.emit();
      },
      error: (err: any) => {
        if (err.status == 409) {
          this.showToastMessage(this.noActiveSyncError, 'info', undefined, true);
        } else {
          this.showToastMessage(this.ownershipSystemError, 'error', undefined, true);
        }
      },
    });
  }
  /**
   * This function sets the enableEquipmentData flag based on feature flag information.
   * It performs the following steps:
   * 1. Checks if either the enableDCNSelection or isDCNCatGradeControlCheck feature flags are enabled in dspStoreData.
   * 2. If either feature flag is enabled, sets the enableEquipmentData flag to true.
   *
   * @returns {void}
   */
  setEnableEquipmentData() {
    if (
      this.dspStoreData?.featureFlagInfo?.enableDCNSelection ||
      this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck
    ) {
      this.enableEquipmentData = true;
    }
  }
  /**
   * This function sets the asset ownership data based on the ownership details and various conditions.
   * It performs the following steps:
   * 1. Checks if ownership details are available and no owner is selected.
   *    - If true, sets the asset owners and enables them.
   *    - Depending on the launch type and feature flags, selects the first owner and updates the subscription form data.
   * 2. Checks if ownership details are available and an owner is selected.
   *    - If true, sets the asset owners and updates the subscription form data with the selected owner.
   * 3. Checks if ownership details are empty.
   *    - If true, sets the customer data to an empty object and marks the form as invalid.
   * 4. Sets the asset subscription form data with the updated information.
   *
   * @returns {void}
   */
  setAssetOwnershipData() {
    if (!isEmpty(this.rowData?.ownershipDetails) && !this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.assetOwners.forEach((owner: any) => {
        owner.disabled = false;
      });
      if (
        this.launchType !== 'cat-grade-control' &&
        !this.dspStoreData?.featureFlagInfo?.enableDCNSelection &&
        !this.hasPartialOwnershipData
      ) {
        this.rowData.ownershipDetails[0].checked = true;
        this.selectedAssetSubscriptionFormData.customer = this.assetOwners[0];
        this.checkDatStatus(this.assetOwners[0]);
      } else if (
        this.launchType === 'cat-grade-control' &&
        !this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck &&
        !this.hasPartialOwnershipData
      ) {
        this.rowData.ownershipDetails[0].checked = true;
        this.selectedAssetSubscriptionFormData.customer = this.assetOwners[0];
        this.checkDatStatus(this.assetOwners[0]);
      }
      // this.rowData.ownershipDetails[0].checked = true;
    } else if (!isEmpty(this.rowData?.ownershipDetails) && this.isOwnerSelected()) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.selectedAssetSubscriptionFormData.customer = this.selectedOwner[0];
      this.checkDatStatus(this.selectedOwner[0]);
    }
    // added below if block to disable next if there is no ownership data
    else if (isEmpty(this.rowData?.ownershipDetails)) {
      this.selectedAssetSubscriptionFormData.customer = {};
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    else if (!isEmpty(this.rowData?.ownershipDetails) && this.rowData?.isNoneSelected) {
      this.assetOwners = this.rowData.ownershipDetails;
      this.selectedAssetSubscriptionFormData.customer = 'None';
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    } else if (isEmpty(this.rowData?.ownershipDetails)) {
      this.rowData.isNoneSelected = true;
      this.selectedAssetSubscriptionFormData.customer = 'None';
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    }
    */
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * This function checks if an owner is selected from the ownership details.
   * It performs the following steps:
   * 1. Filters the ownership details to find the selected owner(s) based on the 'checked' property.
   * 2. Returns true if any owner is selected, otherwise returns false.
   *
   * @returns {boolean} - A flag indicating whether an owner is selected.
   */
  isOwnerSelected() {
    this.selectedOwner = this.rowData.ownershipDetails.filter((owner: any) => {
      return owner.checked;
    });

    return !isEmpty(this.selectedOwner) ? true : false;
  }
  /**
   * This function handles changes in the selected asset owner and updates the subscription form data accordingly.
   * It performs the following steps:
   * 1. Dismisses any active message bar.
   * 2. Clones the checked status of the selected owner.
   * 3. If the selected data is valid and not 'None':
   *    - Updates the checked status of the asset owners.
   *    - Clears the list of products and subscription data if the owner is unchecked.
   *    - Updates the customer data in the subscription form if the owner is checked.
   *    - Checks the dealer status and fetches the DCN list if necessary.
   * 4. If the selected data is 'None', updates the form data accordingly (commented out for specific feature).
   * 5. Sets the updated asset subscription form data.
   *
   * @param {any} data - The selected owner data.
   * @returns {void}
   */
  valueChange(data: any) {
    this.messageBar.dismiss();
    const ownerChecked = cloneDeep(data?.checked);
    if (data && data !== 'None') {
      this.assetOwners.map((owner: any) => {
        if (data.ownershipId === owner.ownershipId && !ownerChecked) {
          owner.checked = true;
        } else {
          owner.checked = false;

          this.assetService.listOfProducts = '';
          this.assetService.listOfSubsData = '';
          this.selectedAssetSubscriptionFormData.customer = '';
        }
      });
      // this.rowData.isNoneSelected = false;
      if (!ownerChecked) {
        this.selectedAssetSubscriptionFormData.customer = data;
        if (this.checkDealerIsNotEmpty(data)) {
          this.checkDatStatus(data);
        } else {
          if (
            this.launchType === 'cat-grade-control' &&
            this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck
          ) {
            this.fetchDCNList(data);
          } else if (
            this.launchType !== 'cat-grade-control' &&
            this.dspStoreData?.featureFlagInfo?.enableDCNSelection
          ) {
            this.fetchDCNList(data);
          } else {
            this.checkDatStatus(data);
          }
        }
      } else {
        this.selectedAssetSubscriptionFormData.customer = {};
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      }
      this.resetAssetFormDataMultiProduct();
    }
    /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    else if (data === 'None') {
      if (this.rowData?.isNoneSelected) {
        this.rowData.isNoneSelected = false;
        this.selectedAssetSubscriptionFormData.customer = {};
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      } else {
        this.rowData.isNoneSelected = true;
        this.assetOwners.map((owner: any) => {
          owner.checked = false;
        });
        this.selectedAssetSubscriptionFormData.customer = 'None';
        this.selectedAssetSubscriptionFormData.isValidForm = true;
      }
    }
    */
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  resetAssetFormDataMultiProduct() {
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      productObj.service.currentService = '';
      productObj.service.previousService = '';
      productObj.service.selectedService = '';
      productObj.subscription.currentSubscription.catLevel = '';
      productObj.subscription.currentSubscription.dealerLevel = '';
      productObj.subscription.currentSubscription.customerLevel = '';
      productObj.subscription.previousSubscription.customerLevel = '';
      productObj.subscription.previousSubscription.dealerLevel = '';
      productObj.subscription.previousSubscription.catLevel = '';
      productObj.subscription.selectedSubscription.customerLevel = '';
      productObj.subscription.selectedSubscription.dealerLevel = '';
      productObj.subscription.selectedSubscription.catLevel = '';
      productObj.addOns.currentAddons = [];
      productObj.addOns.previousddons = [];
      productObj.addOns.selectedAddons = [];
      productObj.prepay.currentPrepayData.prepaySelected = false;
      productObj.prepay.currentPrepayData.contractStartDate = '';
      productObj.prepay.currentPrepayData.contractEndDate = '';
      productObj.prepay.previousPrepayData.prepaySelected = false;
      productObj.prepay.previousPrepayData.contractStartDate = '';
      productObj.prepay.previousPrepayData.contractEndDate = '';
      productObj.prepay.selectedPrepayData.prepaySelected = false;
      productObj.prepay.selectedPrepayData.contractStartDate = '';
      productObj.prepay.selectedPrepayData.contractEndDate = '';
      productObj.catBillingData.billingGroupId = '';
      productObj.productFamily.currentProductFamily = '';
      productObj.productFamily.previousProductFamily = '';
      productObj.selectedCategory = '';
      productObj.reportingState = '';
      productObj.newPaymentDetails = '';
      productObj.siteInfo.siteId = '';
      productObj.siteInfo.siteName = '';
      productObj.cancelOptions = '';
      productObj.expandedService = '';
    }
  }
  /**
   * This function fetches the DCN (Dealer Contact Number) list for a given owner.
   * It performs the following steps:
   * 1. Calls the assetDrawerService to get the DCN list information for the owner.
   * 2. On success, checks if the response contains any DCN entries:
   *    - If DCN entries are found, opens the DCN list drawer with the owner and response data.
   *    - If no DCN entries are found, disables and unchecks the owner, shows an error toast message, and checks the DAT status.
   * 3. On error, shows an error toast message and checks the DAT status.
   *
   * @param {any} owner - The owner for whom the DCN list is being fetched.
   * @returns {void}
   */
  fetchDCNList(owner: any) {
    this.assetDrawerService.getDCNListInfo(owner).subscribe({
      next: (response: any) => {
        if (response.length > 0) {
          this.openDCNListDrawer(owner, response);
        } else {
          owner.disabled = true;
          owner.checked = false;
          const messageText = dspConstants.Common.DCN_ERROR;
          this.showToastMessage(messageText, 'error', undefined, true);
          this.checkDatStatus(owner);
        }
      },
      error: (_err: any) => {
        const messageText = dspConstants.Common.DCN_ERROR;
        this.showToastMessage(messageText, 'error', undefined, true);
        this.checkDatStatus(owner);
      },
    });
  }
  /**
   * This function opens the DCN (Dealer Contact Number) list drawer for a given owner.
   * It performs the following steps:
   * 1. Opens a drawer using the drawerService with the provided owner and response data.
   * 2. Configures the drawer with specific settings such as header type, backdrop, and size.
   * 3. Subscribes to the afterClosed event of the drawer to handle the response:
   *    - If a response is received, updates the asset owner's details with the selected DCN information.
   *    - If no response is received, unchecks the owner.
   * 4. Checks the DAT status of the owner.
   *
   * @param {any} owner - The owner for whom the DCN list is being fetched.
   * @param {any} response - The response data containing the DCN list.
   * @returns {void}
   */
  openDCNListDrawer(owner: any, response: any) {
    this.dcnListDrawerRef = this.drawerService.openDrawer<DcnListComponent, CcDrawerConfig>(DcnListComponent, {
      data: {
        headerType: 'Select Dealer Customer',
        dcnList: response,
        owner: owner,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.dcnListDrawerRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.assetOwners.forEach((owner: any) => {
          if (owner.ownershipId === res.ownershipId) {
            owner.dealerCustNo = res.dealerCustNo;
            owner.dealerCustName = res.dealerCustName;
            owner.dealerCode = this.dealer.dealerCode;
          }
        });
      } else {
        if (this.dcnListDrawerRef.config.data.owner) {
          this.dcnListDrawerRef.config.data.owner.checked = false;
        }
      }

      this.checkDatStatus(owner);
    });
  }
  /**
   * This function checks the length of dealer data and formats it accordingly.
   * It performs the following steps:
   * 1. Checks if both dealerCustNo and dealerCustName are not empty.
   * 2. Concatenates dealerCustNo and dealerCustName with a hyphen.
   * 3. If the concatenated string is 27 characters or longer, truncates it and adds '...'.
   * 4. Returns the formatted dealer data string.
   * 5. If either dealerCustNo or dealerCustName is empty, returns undefined.
   *
   * @param {any} dealerCustNo - The dealer customer number.
   * @param {any} dealerCustName - The dealer customer name.
   * @returns {string|undefined} - The formatted dealer data string or undefined.
   */
  checkDealerDataLength(dealerCustNo: any, dealerCustName: any) {
    if (!isEmpty(dealerCustNo && dealerCustName)) {
      let dealerData = dealerCustNo + ' - ' + dealerCustName;
      if (dealerData.length >= 27) {
        dealerData = dealerData.slice(0, 26) + '...';
      }
      return 'Dealer Customer: ' + dealerData;
    } else {
      return;
    }
  }
  /**
   * This function checks if the dealer customer number and dealer customer name are not empty.
   * It performs the following steps:
   * 1. Checks if both owner.dealerCustNo and owner.dealerCustName are not empty.
   * 2. Returns true if both are not empty.
   * 3. Returns false if either is empty.
   *
   * @param {any} owner - The owner object containing dealer customer information.
   * @returns {boolean} - True if both dealerCustNo and dealerCustName are not empty, false otherwise.
   */
  checkDealerIsNotEmpty(owner: any) {
    if (!isEmpty(owner.dealerCustNo && owner.dealerCustName)) return true;
    else return false;
  }
  /**
   * This function checks the length of customer data and formats it accordingly.
   * It performs the following steps:
   * 1. Concatenates customerCode and customerName with a hyphen.
   * 2. If the concatenated string is 33 characters or longer, truncates it and adds '...'.
   * 3. Returns the formatted customer data string.
   *
   * @param {any} customerCode - The customer code.
   * @param {any} customerName - The customer name.
   * @returns {string} - The formatted customer data string.
   */
  checkCustomerDataLength(customerCode: any, customerName: any) {
    let customerData = customerCode + ' - ' + customerName;
    if (customerData.length >= 33) {
      customerData = customerData.slice(0, 32) + '...';
    }
    return customerData;
  }
  /**
   * This function checks the DAT (Digital Authorization Tool) status of the provided data.
   * It performs the following steps:
   * 1. Checks if the digital authorization status is 'DECLINE':
   *    - If declined, shows an error toast message with a link to Cat Digital Support and sets the form as invalid.
   * 2. Checks if dealer customer number and name are empty, and if certain feature flags are enabled:
   *    - If empty and the DCN selection feature is enabled, sets the form as invalid.
   *    - If empty and the DCN Cat Grade Control check feature is enabled, sets the form as invalid.
   * 3. If none of the above conditions are met, sets the form as valid.
   *
   * @param {any} data - The data object containing digital authorization and dealer customer information.
   * @returns {void}
   */
  checkDatStatus(data: any) {
    if (data?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE') {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
      this.showToastMessage(messageText, 'error', linkDetail);
      if (this.isdatExcludedProductCheck) {
        this.selectedAssetSubscriptionFormData.isValidForm = true;
      } else {
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      }
    } else if (
      isEmpty(data?.dealerCustNo && data?.dealerCustName) &&
      this.dspStoreData?.featureFlagInfo?.enableDCNSelection &&
      this.launchType !== 'cat-grade-control'
    ) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else if (
      isEmpty(data?.dealerCustNo && data?.dealerCustName) &&
      this.dspStoreData?.featureFlagInfo?.isDCNCatGradeControlCheck &&
      this.launchType === 'cat-grade-control'
    ) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    }
  }
  /**
   * This function shows a toast message with the provided details.
   * It performs the following steps:
   * 1. Configures the message bar with specific settings such as position and host selector.
   * 2. If autoCloseNeeded is true, sets the duration for the message bar.
   * 3. Opens the message bar with the provided message, status, link details, and configuration.
   *
   * @param {string} message - The message to be displayed in the toast.
   * @param {string} status - The status of the message (e.g., 'error', 'success').
   * @param {any} linkDetail - The link details to be included in the message.
   * @param {boolean} [autoCloseNeeded] - Optional parameter to determine if the message should auto-close.
   * @returns {void}
   */
  showToastMessage(message: string, status: string, linkDetail: any, autoCloseNeeded?: boolean) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
    };

    if (autoCloseNeeded) {
      config.duration = 3000;
    }
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  setAssetSubscriptionFormData(formData: AssetSubscriptionFormDataType) {
    this.assetService.setAssetSubscriptionFormData(formData);
  }
}
