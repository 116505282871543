<div class="config-details-body">
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2">
      <div class="column1">
        Asset S/N <br /><br />
        <span class="column-additional">
          <span class="column-content">{{ configData.assetSerialNumber }}</span>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">
      <div class="column1">
        Current Device <br />
        S/N
        <span class="column-additional">
          <span class="column-content">{{ configData.deviceSerialNumber }}</span>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2">
      <div class="column1">
        Device Type <br />
        <br />
        <span class="column-additional">
          <span class="column-content">{{ configData.deviceType }}</span>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5">
      <div class="column1">
        Current PL444 <br />
        Parameter Configuration
        <span class="column-additional">
          <span class="column-content">{{ configData.currentConfigFile }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
