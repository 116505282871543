<div id="unauthorizedContainer">
  <h1 class="headline-bold">Unauthorized: You do not have permission to view the page you requested</h1>

  <!--<div class="denied">
    <a
      href="{{ env }}"
      cc-btn-primary=""
      cc-btn-large=""
      class="primary button-opacity cc-btn cc-btn-primary-2 cc-btn-large-2"
      aria-disabled="false"
      >Retry</a
    >
  </div>-->
</div>
