export const VIEWBYLIST = [
  { label: 'Assets', value: 'asset' },
  { label: 'Needs Review', value: 'needsreview' },
  { label: 'Subscription State', value: 'subscriptionstate' },
];

export const LISTITEMS = [
  {
    label: 'Download Excel',
    selected: true,
  },
  {
    label: 'Download CSV',
  },
];

export const OVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort A to Z',
  },
  {
    name: 'dsc',
    title: 'Sort Z to A',
  },
];

export const ASSETCOUNTOVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort Smallest to Largest',
  },
  {
    name: 'dsc',
    title: 'Sort Largest to Smallest',
  },
];
export const ASSETDATEOVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort Oldest to Newest',
  },
  {
    name: 'dsc',
    title: 'Sort Newest to Oldest ',
  },
];
export const ASSETYESOVERLAYDATA = [
  {
    name: 'dsc',
    title: ' Sort YES',
  },
  {
    name: 'asc',
    title: 'Sort NO',
  },
];

export const DATEANDTIME = [
  {
    name: 'None',
    checked: false,
  },
  {
    name: 'Today',
    checked: false,
  },
  {
    name: 'Yesterday',
    checked: false,
  },
  {
    name: 'Last 7 Days',
    checked: false,
  },
  {
    name: 'Last 30 Days',
    checked: false,
  },
  { name: 'Custom Range', checked: false },
];

export const PROMOTIONCONTRACTDATEANDTIME = [
  { name: 'None', checked: false },
  { name: 'Today', checked: false },
  { name: 'Custom Range', checked: false },
];
export const RADIOTYPECOLUMNS = {
  Cellular: 'cellularRadioType',
  Satellite: 'satelliteRadioType',
};
export const DFTASSETFILTERCOLUMN = [
  { label: 'Asset Make', id: 1, selectedValues: [], showChipLabel: true, isDefault: true },
  { label: 'Asset Model', id: 2, selectedValues: [], showChipLabel: true, isDefault: true },
  {
    label: 'Product | Plan',
    subFilters: [
      { label: 'Product', id: 5, selectedValues: [], isDefault: true, field: 'applicationName' },
      { label: 'Plan', id: 6, selectedValues: [], isDefault: true, field: 'applicationPlan' },
    ],
    isDefault: true,
    showChiplabel: true,
  },
  { label: 'Customer ID', id: 7, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Customer Name', id: 8, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Dealer Customer Number', id: 9, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Dealer Customer', id: 10, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Device Type', id: 11, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Device Hardware P/N', id: 12, showChiplabel: true, selectedValues: [], isDefault: false },
  { label: 'Device Software P/N', id: 13, showChiplabel: true, selectedValues: [], isDefault: false },
  {
    label: 'Cellular Radio Type',
    id: 14,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Satellite Radio Type',
    id: 31,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Cellular Radio Hardware P/N',
    id: 15,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Cellular Radio Software P/N',
    id: 16,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  { label: 'Subscription State', id: 4, selectedValues: [], showChiplabel: true, isDefault: true },
  {
    label: 'Satellite Radio Hardware P/N',
    id: 17,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Satellite Radio Software P/N',
    id: 18,
    selectedValues: [],
    isDefault: false,
    showChiplabel: true,
  },
  {
    label: 'Dealer Plan Date & Time (UTC)',
    id: 20,
    showChiplabel: true,
    isDefault: false,
    selectedValues: [],
    formControl: 'date',
  },
  { label: 'Dealer Plan', showChiplabel: true, id: 21, selectedValues: [], isDefault: false },
  {
    label: 'Customer Plan  Date & Time (UTC)',
    id: 22,
    isDefault: false,
    showChiplabel: true,
    selectedValues: [],
    formControl: 'date',
    field: 'customerSubscriptionStartDate',
  },
  { label: 'Customer Plan', id: 23, showChiplabel: true, isDefault: false, selectedValues: [] },
  {
    label: 'Ownership Type',
    id: 30,
    showChiplabel: true,
    isDefault: false,
    selectedValues: [],
  },
];
export const ASSETFILTERCOLUMN = [
  { label: 'Asset Make', id: 1, selectedValues: [], showChipLabel: true, isDefault: true },
  { label: 'Asset Model', id: 2, selectedValues: [], showChipLabel: true, isDefault: true },
  { label: 'Subscription State', id: 4, selectedValues: [], showChiplabel: true, isDefault: true },
  {
    label: 'Product | Plan',
    subFilters: [
      { label: 'Product', id: 5, selectedValues: [], isDefault: true, field: 'applicationName' },
      { label: 'Plan', id: 6, selectedValues: [], isDefault: true, field: 'applicationPlan' },
    ],
    isDefault: true,
    showChiplabel: true,
  },
  { label: 'Customer ID', id: 7, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Customer Name', id: 8, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Dealer Customer Number', id: 9, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Dealer Customer', id: 10, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Device Type', id: 11, showChiplabel: true, selectedValues: [], isDefault: true },
  { label: 'Device Hardware P/N', id: 12, showChiplabel: true, selectedValues: [], isDefault: false },
  { label: 'Device Software P/N', id: 13, showChiplabel: true, selectedValues: [], isDefault: false },
  {
    label: 'Cellular Radio Type',
    id: 14,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Satellite Radio Type',
    id: 31,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Cellular Radio Hardware P/N',
    id: 15,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },

  {
    label: 'Cellular Radio Software P/N',
    id: 16,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Satellite Radio Hardware P/N',
    id: 17,
    selectedValues: [],
    showChiplabel: true,
    isDefault: false,
  },
  {
    label: 'Satellite Radio Software P/N',
    id: 18,
    selectedValues: [],
    isDefault: false,
    showChiplabel: true,
  },

  {
    label: 'Dealer Plan Date & Time (UTC)',
    id: 20,
    showChiplabel: true,
    isDefault: false,
    selectedValues: [],
    formControl: 'date',
  },
  { label: 'Dealer Plan', showChiplabel: true, id: 21, selectedValues: [], isDefault: false },
  {
    label: 'Customer Plan  Date & Time(UTC)',
    id: 22,
    isDefault: false,
    showChiplabel: true,
    selectedValues: [],
    formControl: 'date',
    field: 'customerSubscriptionStartDate',
  },
  { label: 'Customer Plan', id: 23, showChiplabel: true, isDefault: false, selectedValues: [] },
  { label: 'Promotion Name', id: 24, showChiplabel: true, isDefault: false, selectedValues: [] },
  {
    label: 'Promotion Expiration Date',
    id: 25,
    isDefault: false,
    showChiplabel: true,
    selectedValues: [],
    formControl: 'promtionContractDate',
  },
  { label: 'Prepaid Contract', id: 26, isDefault: false, showChiplabel: true, selectedValues: [] },
  {
    label: 'Contract  End Date',
    id: 27,
    isDefault: false,
    showChiplabel: true,
    selectedValues: [],
    formControl: 'promtionContractDate',
  },

  { label: 'Sales Date', id: 28, isDefault: false, showChiplabel: true, selectedValues: [], formControl: 'date' },
  { label: 'Sales Type', id: 29, isDefault: false, showChiplabel: true, selectedValues: [] },
  {
    label: 'Ownership Type',
    id: 30,
    showChiplabel: true,
    isDefault: false,
    selectedValues: [],
  },
  { label: 'Last Cancellation Reason', id: 31, showChiplabel: true, isDefault: false, selectedValues: [] },
  {
    label: 'Last Cancellation Reason Date & Time(UTC)',
    id: 32,
    showChiplabel: true,
    isDefault: false,
    selectedValues: [],
    formControl: 'date',
  },
];

export const AssetDefaultData = {
  offset: 0,
  limit: 20,
  dealerCode: '',
  displayColumns: [],
  sortColumn: 'serialNumber',
  sortOrder: 'asc',
  filter: {},
  operation: 'fetch',
  userType: '',
};
export const AssetColumnsList = {
  make: [],
  model: [],
  modelYear: [],
  deviceType: [],
  networkManagerFirmwarePartNumber: [],
  networkManagerHardwarePartNumber: [],
  cellularRadioHardwarePartNumber: [],
  cellularRadioFirmwarePartNumber: [],
  satelliteRadioHardwarePartNumber: [],
  satelliteRadioFirmwarePartNumber: [],
  ucid: [],
  ucidName: [],
  dealerCustomerNumber: [],
  dcnName: [],
  reportingState: [],
  applicationPlan: [],
  applicationName: [],
  dealerSubscription: [],
  customerSubscriptionStartDate: [],
  dealerSubscriptionStartDate: [],
  customerSubscription: [],
  soldDate: [],
  productSubscriptionStartDate: [],
  promotionName: [],
  ownerShipType: [],
  promotionExpirationDate: [],
  contractEndDate: [],
  saleType: [],
  lastCancelReason: [],
  cancelReasonCreatedTimestamp: [],
  prepaidContract: [],
  cellularRadioType: [],
  satelliteRadioType: [],
  ucidNameWithId: [],
  dcnWithName: [],
  dealerCodeName: [],
};
