<div class="cc-drawer asset-unsuspension-drawer">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis title-display"> Request Unsuspension </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon close-display" (click)="closeDrawer(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="cc-grid" [formGroup]="unsuspensionRequestForm">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="device-input-label">Location</div>

        <cc-dropdown
          [isTheme]="'2.0'"
          [label]="'Select Location'"
          [dataSource]="listOfCountry"
          [appOverlayClass]="'app-overlay-class'"
          (selectionChange)="onDropdownChange($event, 'countryName')"
          formControlName="countryName"
          [search]="true"
          [triggerSearchMinChar]="3"
          [searchFilterType]="'wildcard'"
          [searchplaceholder]="'Search'"
          [searchHelpText]="'Couldn´t find any Location with'"
          [scrollToSelected]="true"
        ></cc-dropdown>
      </div>

      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 unsuspension-reason">
        <cc-input-field
          id="site_desc_id"
          [disabled]="false"
          [labelValue]="'Enter the Unsuspension reason'"
          [inputtype]="'text'"
          [value]="unsuspensionDescription"
          [isTableInlineEdit]="true"
          [maxlength]="256"
          [isTheme]="'2.0'"
        >
          <input
            input-field
            formControlName="unsuspensionDescription"
            [(ngModel)]="unsuspensionDescription"
            class="input-field"
            autocomplete="off"
            type="text"
            aria-labelledby="Enter any value"
            aria-describedby="Enter any value"
            [maxlength]="256"
          />
        </cc-input-field>
      </div>
      <div class="align-data">
        <div>
          <cc-icon [fontSet]="'icomoon'" [inline]="true" class="icon">info</cc-icon>
        </div>

        <div class="note-display">Asset location and reason entered will be recorded with this request.</div>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer(false)">Cancel</button>
    <button
      [disabled]="getValidButtonStatus()"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      (click)="onSubmitUnsuspensionRequest()"
    >
      Submit
    </button>
  </div>
</div>
