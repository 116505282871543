<div
  cc-tooltip
  [template]="template5"
  [innerHTML]="getColumnData() | highlightSearch: searchString"
  [ngClass]="{
    'billing-tooltip':
      detailArray.indexOf(columnWithHeader.name) !== -1 || columnWithHeader.name === 'subscriptionName',
  }"
  class="detailed-invoice"
  [tooltipPosition]="
    detailArray.indexOf(columnWithHeader.name) !== -1
      ? columnWithHeader.name === 'lineItemTotal'
        ? 'left'
        : 'bottom'
      : columnWithHeader.name === 'subscriptionName'
        ? 'right'
        : 'top'
  "
  [isEllipseEnabled]="true"
  dspNextGenUiCopy
  [fullText]="rowData[columnWithHeader.name]"
></div>
<ng-template #template5>
  <div style="width: 134px; white-space: normal">
    {{ getColumnData() }}
  </div>
</ng-template>
