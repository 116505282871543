<div
  class="flex"
  [tooltipPosition]="'top'"
  [cc-tooltip]="parseAccountCwsIDComponent(rowData)"
  [isEllipseEnabled]="true"
  [innerHTML]="parseAccountCwsIDComponent(rowData) | highlightSearch: searchString"
  dspNextGenUiCopy
  [fullText]="parseAccountCwsIDComponent(rowData)"
></div>

<div>
  <a class="link" *ngIf="rowData.users?.length > 1" (click)="openDrawerInMultiSize('drawerContent', rowData.users)">
    See more
  </a>
</div>
