<div class="line-chart">
  <div class="header">
    <div class="left-header">Subscribed Assets Trend</div>
    <div class="right-header">
      <div class="icons" (click)="expandedSubAssetTrendView('withActions')">
        <cc-icon fontSet="icomoon" cc-tooltip="Expand" [tooltipPosition]="'top'">fullscreen</cc-icon>
      </div>

      <div class="icons">
        <cc-icon *ngIf="!filterIcon" fontSet="icomoon" (click)="openFilter('withActions')">filter</cc-icon>
        <img
          class="filter-icon-checked"
          *ngIf="filterIcon"
          src="../../../../../assets/images/filter-applied.svg"
          (click)="openFilter('withActions')"
        />
      </div>
    </div>
  </div>
  <div *ngIf="subscribedAssetTrend && subscribedAssetTrend.length && !chartSpinner">
    <cc-bar-chart
      [chartColor]="['#0078d6', '#e56c00', '#9826de', '#006618']"
      [chartId]="100"
      [chartData]="chart100OriginalData"
      [chartConfig]="subAssetTrendTableConfig"
      [chartType]="'line'"
      [responsiveConfig]="chartResponsiveConfig"
    ></cc-bar-chart>
  </div>

  <dsp-next-gen-ui-spot-loader *ngIf="chartSpinner"></dsp-next-gen-ui-spot-loader>
  <div class="no-results-found" *ngIf="!chartSpinner && subscribedAssetTrend && subscribedAssetTrend.length === 0">
    <img src="../../../../../assets/images/no-data.png" width="198" height="132" />

    <p class="no-results-text">No data to display</p>
  </div>
</div>
