<div [ngClass]="appClass">
  <div class="footer-info">
    <div class="links">
      <div (click)="legalModal()">Legal<span>-</span></div>
      <div (click)="privacyModal()">Privacy<span>-</span></div>
      <div class="ot-sdk-show-settings" role="button" tabindex="0" onclick="window.OneTrust.ToggleInfoDisplay()">
        Cookie Settings
      </div>
      <span>&nbsp;-&nbsp;</span>
      <div (click)="termSaleModal()" *ngIf="userActiveFeatures && userActiveFeatures.indexOf('TOS') !== -1">
        Terms of Sale<span>-</span>
      </div>
      <div (click)="quickLink()">Quick Links<span *ngIf="showDSPV1Link">-</span></div>
    </div>
    <div class="copy">Caterpillar © {{ dateYear }}. All Rights Reserved.</div>
  </div>
</div>
