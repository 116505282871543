import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'dsp-next-gen-ui-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionExpiredComponent {
  constructor(public authService: AuthService) {}
  logout() {
    this.authService.logoutSession();
  }
}
