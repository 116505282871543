export const appRoutes = [
  {
    name: 'Home',
    pathName: 'home',
    component: 'typeof HomeComponent',
    isDefault: true,
    path: 'home',
    iconText: 'home',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 48,
    displayOrder: 1,
    children: [],
    id: 'home',
  },
  {
    name: 'Dashboard',
    pathName: 'dashboard',
    component: 'typeof DashboardComponent',
    isDefault: false,
    path: 'dashboard',
    iconText: 'dashboard',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 38,
    displayOrder: 2,
    children: [],
    id: 'dashboard',
  },
  {
    name: 'Needs Review',
    pathName: 'needsreview',
    component: 'typeof NeedsReviewComponent',
    isDefault: false,
    path: 'needsreview',
    iconText: 'needsreview',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 33,
    displayOrder: 3,
    children: [],
    id: 'needsreview',
  },
  {
    name: 'Manage',
    pathName: 'manage',
    component: 'typeof ManageComponent',
    isDefault: false,
    path: '/manage',
    iconText: 'manage',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 34,
    displayOrder: 4,
    id: 'manage',
    children: [
      {
        name: 'Assets',
        pathName: 'asset',
        component: 'typeof AssetsComponent',
        isDefault: false,
        path: '/asset',
        iconText: 'assets',
        fontSet: 'icomoon',
        activeClass: '',
        level: 2,
        maxLength: 20,
        disabled: false,
        menuId: 35,
        displayOrder: 1,
        id: 'assets',
        children: [
          {
            name: 'Manual Device Registration',
            pathName: 'asset/deviceRegistration',
            component: 'typeof AssetsComponent',
            isDefault: false,
            path: '/asset/deviceRegistration',
            iconText: '',
            fontSet: 'icomoon',
            activeClass: '',
            level: 2,
            maxLength: 20,
            disabled: false,
            menuId: 35,
            displayOrder: 1,
            children: [],
            id: 'deviceRegistration',
          },
        ],
      },
      {
        name: 'Sites',
        pathName: 'sites',
        component: 'typeof SitesComponent',
        isDefault: false,
        path: 'sites',
        iconText: 'sites',
        fontSet: 'icomoon',
        activeClass: '',
        level: 2,
        maxLength: 20,
        disabled: false,
        menuId: 36,
        displayOrder: 2,
        children: [],
        id: 'sites',
      },
      {
        name: 'Accounts',
        pathName: 'accounts',
        component: 'typeof AccountsComponent',
        isDefault: false,
        path: 'accounts',
        iconText: 'accounts',
        fontSet: 'icomoon',
        activeClass: '',
        level: 2,
        maxLength: 20,
        disabled: false,
        menuId: 37,
        displayOrder: 3,
        children: [],
        id: 'accounts',
      },
    ],
  },
  {
    name: 'Billing',
    pathName: 'billing',
    component: 'typeof ManageComponent',
    isDefault: false,
    path: 'billing',
    iconText: 'billing',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 42,
    displayOrder: 5,
    children: [],
    id: 'billing',
  },
  {
    name: 'User Administration',
    pathName: 'admin',
    component: 'typeof AdminComponent',
    isDefault: false,
    path: 'admin',
    iconText: 'admin',
    fontSet: 'icomoon',
    activeClass: '',
    level: 1,
    maxLength: 20,
    disabled: false,
    menuId: 39,
    displayOrder: 7,
    children: [],
    id: 'admin',
  },
];
