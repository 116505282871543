<div class="site-ownership-name-column" *ngIf="!isChangeOwnership">
  <div
    class="site-ownership-name"
    cc-tooltip
    [template]="ucid_tooltip"
    [isEllipseEnabled]="true"
    [tooltipPosition]="'top'"
  >
    <span [innerHTML]="rowData.ucidName | highlightSearch: searchString"></span> <span *ngIf="rowData.ucid"> | </span>
    <span [innerHTML]="rowData.ucid | highlightSearch: searchString"></span> <span *ngIf="rowData.dcnName"> | </span>
    <span [innerHTML]="rowData.dcnName | highlightSearch: searchString"></span> <span *ngIf="rowData.dcn"> | </span>
    <span [innerHTML]="rowData.dcn | highlightSearch: searchString"></span>
  </div>
  <ng-template #ucid_tooltip>
    <div style="width: 275px; white-space: normal">
      <div>
        <span class="customer-text">Customer: </span>
        <span>{{ updateCustomerName(rowData.ucid, rowData.ucidName) }}<br /></span>
      </div>
      <div>
        <span class="customer-text">Dealer Customer: </span>
        <span>{{ updateCustomerName(rowData.dcn, rowData.dcnName) }}<br /></span>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="isChangeOwnership">
  <div class="sites-columns">
    <div
      *ngIf="!showTruncatedText"
      class="sites-columns"
      [tooltipPosition]="'top'"
      [isEllipseEnabled]="true"
      cc-tooltip="siteCustomer"
      [template]="template2"
    >
      <span [innerHTML]="rowData[columnName] || '-' | highlightSearch: searchString"></span>
    </div>
    <div
      *ngIf="showTruncatedText"
      class="sites-columns"
      [tooltipPosition]="'top'"
      [isEllipseEnabled]="true"
      cc-tooltip="siteCustomer"
      [template]="template2"
      dspNextGenUiCopy
      [fullText]="rowData[columnName] ? rowData[columnName] : '-'"
    >
      <span [innerHTML]="rowData[columnName] || '-' | highlightSearch: searchString"></span>
    </div>
    <ng-template #template2>
      <div style="width: 220px; white-space: normal">
        <span> {{ rowData[columnName] }}</span>
      </div>
    </ng-template>
  </div>
</div>
