<div class="cc-drawer">
  <div class="cc-drawer__header">
    <ng-container [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <div *ngIf="!showTruncatedText" class="accord-info">
      <cc-accordion>
        <div class="header-title">
          <span class="title-display">DEVICE INFORMATION&nbsp;&nbsp;</span>
        </div>
        <div style="word-break: break-all"></div>
        <div class="ecm-table">
          <span class="ecm-key">S/N</span><span class="ecm-value">{{ this.deviceData?.serialNumber || '-' }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Type</span><span class="ecm-value">{{ this.deviceData?.commercialType || '-' }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">ID</span
          ><span [cc-tooltip]="getIdToolTipData()" class="ecm-value">{{ this.deviceData?.deviceId || '-' }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Software P/N</span
          ><span class="ecm-value">{{ this.deviceData?.softwarePartNumber || '-' }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Hardware P/N</span
          ><span class="ecm-value">{{ this.deviceData?.hardwarePartNumber || '-' }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Gateway Software P/N</span
          ><span [cc-tooltip]="getColumnToolTipData()" class="ecm-value">{{
            this.deviceData?.gatewaySoftwarePartNumber || '-'
          }}</span>
        </div>
        <div class="header-title">
          <span class="title-display">CONFIGURATION&nbsp;&nbsp;</span>
        </div>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">Base Configuration&nbsp;&nbsp;</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div style="word-break: break-all"></div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">P/N</span><span class="ecm-value">{{ updateConfigInfo('configPartNumber') }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Description</span
              ><span class="ecm-value">{{ updateConfigInfo('configDescription') }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Date & Time {{ getLocalTimeZone() }}</span
              ><span class="ecm-value">{{ getConvertedTimeZone(updateConfigInfo('configDateTime')) }}</span>
            </div>
          </ng-container>
        </cc-expandable>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">Customer Configuration&nbsp;&nbsp;</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div style="word-break: break-all"></div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">P/N</span
              ><span class="ecm-value">{{ updateCustomerConfigInfo('configPartNumber') }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Description</span
              ><span class="ecm-value">{{ updateCustomerConfigInfo('configDescription') }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Date & Time {{ getLocalTimeZone() }}</span
              ><span class="ecm-value">{{ getConvertedTimeZone(updateCustomerConfigInfo('configDateTime')) }}</span>
            </div>
          </ng-container>
        </cc-expandable>
        <ng-container>
          <div class="header-title">
            <span class="title-display">RADIO INFORMATION&nbsp;&nbsp;</span>
          </div>
          <div style="word-break: break-all"></div>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Cellular Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div style="word-break: break-all"></div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">S/N</span
                ><span class="ecm-value">{{ this.cellularRadio?.serialNumber || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Radio Type</span
                ><span class="ecm-value">{{ this.cellularRadio?.commercialType || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Hardware P/N</span
                ><span class="ecm-value">{{ this.cellularRadio?.hardwarePartNumber || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Software P/N</span
                ><span class="ecm-value">{{ this.cellularRadio?.softwarePartNumber || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">MEID</span><span class="ecm-value">{{ this.cellularRadio?.meid || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Sim Card ID</span
                ><span class="ecm-value">{{ this.cellularRadio?.iccid || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">IMEI</span><span class="ecm-value">{{ this.cellularRadio?.imei || '-' }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">IMSI</span><span class="ecm-value">{{ this.cellularRadio?.imsi || '-' }}</span>
              </div>
            </ng-container>
          </cc-expandable>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Satellite Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div>
                <div style="word-break: break-all"></div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">S/N</span
                  ><span class="ecm-value">{{ this.satelliteRadio?.serialNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Radio Type</span
                  ><span class="ecm-value">{{ this.satelliteRadio?.commercialType || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Hardware P/N</span
                  ><span class="ecm-value">{{ this.satelliteRadio?.hardwarePartNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Software P/N</span
                  ><span class="ecm-value">{{ this.satelliteRadio?.softwarePartNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">MEID</span><span class="ecm-value">{{ this.satelliteRadio?.meid || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Sim Card ID</span
                  ><span class="ecm-value">{{ this.satelliteRadio?.iccid || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMEI</span><span class="ecm-value">{{ this.satelliteRadio?.imei || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMSI</span><span class="ecm-value">{{ this.satelliteRadio?.imsi || '-' }}</span>
                </div>
              </div>
            </ng-container>
          </cc-expandable>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Wi-Fi Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div>
                <div style="word-break: break-all"></div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">S/N</span
                  ><span class="ecm-value">{{ this.wifiRadio?.serialNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Radio Type</span
                  ><span class="ecm-value">{{ this.wifiRadio?.commercialType || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Hardware P/N</span
                  ><span class="ecm-value">{{ this.wifiRadio?.hardwarePartNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Software P/N</span
                  ><span class="ecm-value">{{ this.wifiRadio?.softwarePartNumber || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">MEID</span><span class="ecm-value">{{ this.wifiRadio?.meid || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Sim Card ID</span
                  ><span class="ecm-value">{{ this.wifiRadio?.iccid || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMEI</span><span class="ecm-value">{{ this.wifiRadio?.imei || '-' }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMSI</span><span class="ecm-value">{{ this.wifiRadio?.imsi || '-' }}</span>
                </div>
              </div>
            </ng-container>
          </cc-expandable>
        </ng-container>
      </cc-accordion>
    </div>
    <div *ngIf="showTruncatedText" class="accord-info">
      <cc-accordion>
        <div class="header-title">
          <span class="title-display">DEVICE INFORMATION&nbsp;&nbsp;</span>
        </div>
        <div style="word-break: break-all"></div>
        <div class="ecm-table">
          <span class="ecm-key">S/N</span
          ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.deviceData?.serialNumber || '-'">{{
            this.deviceData?.serialNumber || '-'
          }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Type</span
          ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.deviceData?.commercialType || '-'">{{
            this.deviceData?.commercialType || '-'
          }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">ID</span
          ><span
            [cc-tooltip]="getIdToolTipData()"
            class="ecm-value"
            dspNextGenUiCopy
            [fullText]="this.deviceData?.deviceId || '-'"
            >{{ this.deviceData?.deviceId || '-' }}</span
          >
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Software P/N</span
          ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.deviceData?.softwarePartNumber || '-'">{{
            this.deviceData?.softwarePartNumber || '-'
          }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Hardware P/N</span
          ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.deviceData?.hardwarePartNumber || '-'">{{
            this.deviceData?.hardwarePartNumber || '-'
          }}</span>
        </div>
        <div class="ecm-table">
          <span class="ecm-key">Gateway Software P/N</span
          ><span
            [cc-tooltip]="getColumnToolTipData()"
            class="ecm-value"
            dspNextGenUiCopy
            [fullText]="this.deviceData?.gatewaySoftwarePartNumber || '-'"
            >{{ this.deviceData?.gatewaySoftwarePartNumber || '-' }}</span
          >
        </div>
        <div class="header-title">
          <span class="title-display">CONFIGURATION&nbsp;&nbsp;</span>
        </div>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">Base Configuration&nbsp;&nbsp;</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div style="word-break: break-all"></div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">P/N</span
              ><span class="ecm-value" dspNextGenUiCopy [fullText]="updateConfigInfo('configPartNumber')">{{
                updateConfigInfo('configPartNumber')
              }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Description</span
              ><span class="ecm-value" dspNextGenUiCopy [fullText]="updateConfigInfo('configDescription')">{{
                updateConfigInfo('configDescription')
              }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Date & Time {{ getLocalTimeZone() }}</span
              ><span
                class="ecm-value"
                dspNextGenUiCopy
                [fullText]="getConvertedTimeZone(updateConfigInfo('configDateTime'))"
                >{{ getConvertedTimeZone(updateConfigInfo('configDateTime')) }}</span
              >
            </div>
          </ng-container>
        </cc-expandable>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">Customer Configuration&nbsp;&nbsp;</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div style="word-break: break-all"></div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">P/N</span
              ><span class="ecm-value" dspNextGenUiCopy [fullText]="updateCustomerConfigInfo('configPartNumber')">{{
                updateCustomerConfigInfo('configPartNumber')
              }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Description</span
              ><span class="ecm-value" dspNextGenUiCopy [fullText]="updateCustomerConfigInfo('configDescription')">{{
                updateCustomerConfigInfo('configDescription')
              }}</span>
            </div>
            <div class="ecm-table bgcolour">
              <span class="ecm-key">Date & Time {{ getLocalTimeZone() }}</span
              ><span
                class="ecm-value"
                dspNextGenUiCopy
                [fullText]="getConvertedTimeZone(updateCustomerConfigInfo('configDateTime'))"
                >{{ getConvertedTimeZone(updateCustomerConfigInfo('configDateTime')) }}</span
              >
            </div>
          </ng-container>
        </cc-expandable>
        <ng-container>
          <div class="header-title">
            <span class="title-display">RADIO INFORMATION&nbsp;&nbsp;</span>
          </div>
          <div style="word-break: break-all"></div>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Cellular Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div style="word-break: break-all"></div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">S/N</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.serialNumber || '-'">{{
                  this.cellularRadio?.serialNumber || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Radio Type</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.commercialType || '-'">{{
                  this.cellularRadio?.commercialType || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Hardware P/N</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.hardwarePartNumber || '-'">{{
                  this.cellularRadio?.hardwarePartNumber || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Software P/N</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.softwarePartNumber || '-'">{{
                  this.cellularRadio?.softwarePartNumber || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">MEID</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.meid || '-'">{{
                  this.cellularRadio?.meid || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">Sim Card ID</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.iccid || '-'">{{
                  this.cellularRadio?.iccid || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">IMEI</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.imei || '-'">{{
                  this.cellularRadio?.imei || '-'
                }}</span>
              </div>
              <div class="ecm-table bgcolour">
                <span class="ecm-key">IMSI</span
                ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.cellularRadio?.imsi || '-'">{{
                  this.cellularRadio?.imsi || '-'
                }}</span>
              </div>
            </ng-container>
          </cc-expandable>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Satellite Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div>
                <div style="word-break: break-all"></div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">S/N</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.serialNumber || '-'">{{
                    this.satelliteRadio?.serialNumber || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Radio Type</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.commercialType || '-'">{{
                    this.satelliteRadio?.commercialType || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Hardware P/N</span
                  ><span
                    class="ecm-value"
                    dspNextGenUiCopy
                    [fullText]="this.satelliteRadio?.hardwarePartNumber || '-'"
                    >{{ this.satelliteRadio?.hardwarePartNumber || '-' }}</span
                  >
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Software P/N</span
                  ><span
                    class="ecm-value"
                    dspNextGenUiCopy
                    [fullText]="this.satelliteRadio?.softwarePartNumber || '-'"
                    >{{ this.satelliteRadio?.softwarePartNumber || '-' }}</span
                  >
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">MEID</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.meid || '-'">{{
                    this.satelliteRadio?.meid || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Sim Card ID</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.iccid || '-'">{{
                    this.satelliteRadio?.iccid || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMEI</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.imei || '-'">{{
                    this.satelliteRadio?.imei || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMSI</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.satelliteRadio?.imsi || '-'">{{
                    this.satelliteRadio?.imsi || '-'
                  }}</span>
                </div>
              </div>
            </ng-container>
          </cc-expandable>
          <cc-expandable>
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">Wi-Fi Radio&nbsp;&nbsp;</span>
              </div>
            </cc-expandable-header>
            <ng-container>
              <div>
                <div style="word-break: break-all"></div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">S/N</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.serialNumber || '-'">{{
                    this.wifiRadio?.serialNumber || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Radio Type</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.commercialType || '-'">{{
                    this.wifiRadio?.commercialType || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Hardware P/N</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.hardwarePartNumber || '-'">{{
                    this.wifiRadio?.hardwarePartNumber || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Software P/N</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.softwarePartNumber || '-'">{{
                    this.wifiRadio?.softwarePartNumber || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">MEID</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.meid || '-'">{{
                    this.wifiRadio?.meid || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">Sim Card ID</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.iccid || '-'">{{
                    this.wifiRadio?.iccid || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMEI</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.imei || '-'">{{
                    this.wifiRadio?.imei || '-'
                  }}</span>
                </div>
                <div class="ecm-table bgcolour">
                  <span class="ecm-key">IMSI</span
                  ><span class="ecm-value" dspNextGenUiCopy [fullText]="this.wifiRadio?.imsi || '-'">{{
                    this.wifiRadio?.imsi || '-'
                  }}</span>
                </div>
              </div>
            </ng-container>
          </cc-expandable>
        </ng-container>
      </cc-accordion>
    </div>
  </div>
</div>
<ng-template #oneLineHeader>
  <span class="cc-drawer__ellipsis Textedit"> Device and Radio Information </span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close device-info-close" (click)="closeModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
