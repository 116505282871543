<div class="cls-pay-preview">
  <div *ngIf="cancelPlan">
    <div class="payPreviewBar">
      <div class="payPreviewLabel">CURRENT</div>
    </div>
    <div *ngIf="!isSitePricingEligible" class="rowTblCls row-padding">
      <div class="Table">
        <div class="Row estimateTotal">
          <div class="Cell productCell">
            <div class="headerCell">Product | Plan</div>
          </div>
        </div>
        <div class="Row" *ngFor="let cancelPlan of cancelPlanDetails">
          <div>
            <div class="total-plan">{{ cancelPlan }}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isSitePricingEligible" class="rowTblCls row-padding">
      <div class="Table">
        <div class="Row estimateTotal">
          <div class="Cell productCell">
            <div class="headerCell">Product | Plan</div>
          </div>
          <div class="Cell promotionCell">
            <div class="headerCell">Promotion Expiration Date</div>
          </div>
          <div class="Cell priceCell">
            <div class="headerCell">Price (Monthly)</div>
          </div>
        </div>
        <div class="Row" *ngFor="let curProdObj of payBillingDetails?.current?.products || []">
          <div class="Cell productCell">
            <div class="total-plan">{{ curProdObj.name }}</div>
          </div>
          <div class="Cell promotionCell">
            <div></div>
          </div>
          <div class="Cell priceCell">
            <div class="total-plan">{{ convertNumberFormat(curProdObj.amount) }}</div>
          </div>
        </div>
        <div class="Row" *ngFor="let curProdObj of payBillingDetails?.current?.promotions || []">
          <div class="Cell productCell">
            <div class="total-plan">{{ curProdObj.name }}</div>
          </div>
          <div class="Cell promotionCell">
            <div class="content-padding">{{ getPromotionDate(curProdObj.promotionEndDate) || '' }}</div>
          </div>
          <div class="Cell priceCell">
            <div class="total-plan">{{ convertNumberFormat(curProdObj.amount) }}</div>
          </div>
        </div>
      </div>
      <div class="Table" *ngIf="payBillingDetails?.current">
        <div class="Row cancel-plan-total">
          <div class="Cell">
            <div class="total-plan">Estimated Total</div>
          </div>
          <div class="Cell priceCell">
            <div class="total-plan">
              {{ convertNumberFormat(payBillingDetails?.current?.monthlyRateAfterDiscount) || '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="editSite">
    <div *ngIf="currentBillingDetails && isSitePricingEligible">
      <div class="payPreviewBar">
        <div class="payPreviewLabel">CURRENT</div>
      </div>
      <div class="rowTblCls row-padding">
        <div class="Table">
          <div class="Row estimateTotal">
            <div class="Cell productCell">
              <div class="headerCell">Product | Plan</div>
            </div>
            <div class="Cell promotionCell">
              <div class="headerCell">Promotion Expiration Date</div>
            </div>
            <div class="Cell priceCell">
              <div class="headerCell">Price (Monthly)</div>
            </div>
          </div>
          <div class="Row" *ngFor="let curProdObj of currentBillingDetails?.current?.products || []">
            <div class="Cell productCell">
              <div class="total-plan">{{ curProdObj.name }}</div>
            </div>
            <div class="Cell promotionCell">
              <div></div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">{{ convertNumberFormat(curProdObj.amount) }}</div>
            </div>
          </div>
          <div class="Row" *ngFor="let curProdObj of currentBillingDetails?.current?.promotions || []">
            <div class="Cell productCell">
              <div class="total-plan">{{ curProdObj.name }}</div>
            </div>
            <div class="Cell promotionCell">
              <div class="content-padding">{{ getPromotionDate(curProdObj.promotionEndDate) || '' }}</div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">{{ convertNumberFormat(curProdObj.amount) }}</div>
            </div>
          </div>
        </div>
        <div class="Table" *ngIf="currentBillingDetails?.current">
          <div class="Row cancel-plan-total">
            <div class="Cell">
              <div class="total-plan"><strong>Estimated Total</strong></div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">
                {{ convertNumberFormat(currentBillingDetails?.current?.monthlyRateAfterDiscount) || '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isSitePricingEligible">
      <div class="payPreviewBar">
        <div class="payPreviewLabel">CURRENT</div>
      </div>
      <div class="rowTblCls row-padding">
        <div class="Table">
          <div class="Row estimateTotal">
            <div class="Cell productCell">
              <div class="headerCell">Product | Plan</div>
            </div>
          </div>
          <div class="Row" *ngFor="let currentDetails of currentPlanDisplay">
            <div>
              <div class="total-plan">{{ currentDetails }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="newBillingDetails && isSitePricingEligible">
      <div class="payPreviewBar">
        <div class="payPreviewLabel">NEW</div>
      </div>
      <div class="rowTblCls row-padding">
        <div class="Table">
          <div class="Row estimateTotal">
            <div class="Cell productCell">
              <div class="headerCell">Product | Plan</div>
            </div>
            <div class="Cell promotionCell">
              <div class="headerCell">Promotion Expiration Date</div>
            </div>
            <div class="Cell priceCell">
              <div class="headerCell">Price (Monthly)</div>
            </div>
          </div>
          <div class="Row" *ngFor="let newProdObj of newBillingDetails?.new?.products || []">
            <div class="Cell productCell">
              <div class="total-plan">{{ newProdObj.name }}</div>
            </div>
            <div class="Cell promotionCell">
              <div></div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">{{ convertNumberFormat(newProdObj.amount) }}</div>
            </div>
          </div>
          <div class="Row" *ngFor="let newProdObj of newBillingDetails?.new?.promotions || []">
            <div class="Cell productCell">
              <div class="total-plan">{{ newProdObj.name }}</div>
            </div>
            <div class="Cell promotionCell">
              <div class="content-padding">{{ getPromotionDate(newProdObj.promotionEndDate) || '' }}</div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">{{ convertNumberFormat(newProdObj.amount) }}</div>
            </div>
          </div>
        </div>
        <div class="Table" *ngIf="newBillingDetails?.new">
          <div class="Row cancel-plan-total">
            <div class="Cell">
              <div class="total-plan"><strong>Estimated Total</strong></div>
            </div>
            <div class="Cell priceCell">
              <div class="total-plan">
                {{ convertNumberFormat(newBillingDetails?.new?.monthlyRateAfterDiscount) || '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isSitePricingEligible">
      <div class="payPreviewBar">
        <div class="payPreviewLabel">NEW</div>
      </div>
      <div class="rowTblCls row-padding">
        <div class="Table">
          <div class="Row estimateTotal">
            <div class="Cell productCell">
              <div class="headerCell">Product | Plan</div>
            </div>
          </div>
          <div class="Row" *ngFor="let newDetails of newPlanDisplay">
            <div>
              <div class="total-plan">{{ newDetails }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-message" *ngIf="newBillingDetails?.cancelNotificationMessage && isSitePricingEligible">
      <div class="payment-message-info-icon">
        <img src="../../../assets/images/new-info-icon.svg" />
      </div>
      <div class="cancelNote1" [innerHTML]="notificationMessage || ''"></div>
    </div>
  </div>
  <div *ngIf="createSite">
    <div class="rowTblCls row-margin">
      <div *ngIf="payBillingDetails" class="Table">
        <div class="rowTblCls">
          <div class="Table create-site subTable">
            <div class="Row estimateTotal">
              <div class="Cell">
                <div class="headerCell">Product | Plan</div>
              </div>
              <div class="Cell promotionCell">
                <div class="headerCell">Promotion Expiration Date</div>
              </div>

              <div class="Cell priceCell">
                <div class="headerCell">Price(Monthly)</div>
              </div>
            </div>
            <div class="Row" *ngFor="let NewProdObj of payBillingDetails?.new?.products">
              <div class="Cell planCell">
                <div class="content-padding">{{ NewProdObj.name || '' }}</div>
              </div>

              <div class="Cell promotionCell">
                <div></div>
              </div>
              <div class="Cell priceCell">
                <div class="content-padding">{{ convertNumberFormat(NewProdObj.amount) || '' }}</div>
              </div>
            </div>
            <div class="Row" *ngFor="let NewProdObj of payBillingDetails?.new?.promotions">
              <div class="Cell planCell">
                <div class="content-padding">{{ NewProdObj.name || '' }}</div>
              </div>

              <div class="Cell promotionCell">
                <div class="content-padding">{{ getPromotionDate(NewProdObj.promotionEndDate) || '' }}</div>
              </div>
              <div class="Cell priceCell">
                <div class="content-padding">{{ convertNumberFormat(NewProdObj.amount) || '' }}</div>
              </div>
            </div>
          </div>
          <div class="Table" *ngIf="payBillingDetails?.new">
            <div class="Row estimateTotal estimation-height">
              <div class="Cell">
                <div class="content-padding12"><strong>ESTIMATED TOTAL</strong></div>
              </div>
              <div class="Cell promotionCell">
                <div></div>
              </div>
              <div class="Cell priceCell">
                <div class="content-padding12">
                  {{ convertNumberFormat(payBillingDetails?.new?.monthlyRateAfterDiscount) || '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Row" *ngIf="payBillingDetails?.cancelNotificationMessage">
      <div class="cellicon">
        <cc-icon [fontSet]="'icomoon'" [inline]="true" class="icon">info</cc-icon>
      </div>
      <div class="cancelNoteCell">
        <div class="cancelNote" [innerHTML]="sanitizer.bypassSecurityTrustHtml(notificationMessage || '')"></div>
      </div>
    </div>
  </div>
</div>
