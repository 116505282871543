import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isLoading = new Subject<boolean>();
  timer = timer(100);
  noOfConcurrentCalls = 0;

  show() {
    this.noOfConcurrentCalls++;
    this.isLoading.next(true);
  }

  hide() {
    this.noOfConcurrentCalls--;
    this.timer.subscribe(() => {
      if (this.noOfConcurrentCalls <= 0) {
        this.noOfConcurrentCalls = 0;
        this.isLoading.next(false);
      }
    });
  }
}
