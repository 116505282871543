import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { DspCommonService } from '../../../services/dsp-common.service';
import { SitesService } from '../../../services/sites.service';
import { debounce, isArray, filter, isNil, isEmpty, cloneDeep } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { UserPreferenceService } from '../../../user-preference.service';
interface autoLoadData {
  label: string;
  value: any;
}
@Component({
  selector: 'dsp-next-gen-ui-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditSiteComponent implements OnInit, AfterViewInit {
  public itemClicked: any;
  modalRef: any;
  currentTableConfig = {
    height: '400',
    expandable: false,
    footer: true,
    columnConfig: [],
  };

  public positiondata = [];
  public selectedReason = [];
  isValidateCWSID = false;
  prevSearchCWSID = '';
  currentTableData = [
    {
      prodcutName: 'JWJ01355 - CAT 320 FIT',
      prodcutExpDate: 'Asset On',
      price: '4950.00',
    },
    {
      prodcutName: 'JWJ01356 - CAT 320 FIT',
      prodcutExpDate: 'No Reporting',
      price: '1500.00',
    },
    {
      prodcutName: 'JWJ01355 - CAT 320 FIT',
      prodcutExpDate: 'Asset On',
      price: '-1125.00',
    },
    {
      prodcutName: 'JWJ01356 - CAT 320 FIT',
      prodcutExpDate: 'No Reporting',
      price: '-375.00',
    },
  ];
  editSiteForm!: FormGroup;
  isTheme = '2.0';
  siteDescription = '';
  products: autoLoadData[] = [];
  planData: autoLoadData[] = [];
  addOnServices: autoLoadData[] = [];
  billToParty = '';
  isPricingEligible = false;
  isNonB2C = true;
  b2cCustomers: any;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  mineStarDetails: any;
  selectedPlan: any;
  selectedAddonServices: any;
  cwsIDExists = true;
  currentBillingDetails: any;
  newBillingDetails: any;
  currentPlanDisplay: any;
  newPlanDisplay: any;
  isPaymentLoaded = false;
  siteCwsId = '';
  paymentPreviewLoading = false;
  loaderValue = false;
  userActiveFeatures!: any;
  surfaceAddon = false;
  isRemoveCwsIDAutoSearch = false;
  dspStoreData!: any;
  isNumberConventionEnabled: any;
  activeStepIndex = 0;
  dynamicTrackerData: any;
  trackerData = [
    {
      label: 'Account and Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  billingTrackerData = [
    {
      label: 'Account and Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  isPaymentPage = false;
  checkPaymentPreview = false;
  isPlanChanged = false;
  isOptionalPlanApplied = false;
  isPlanRetained = false;
  selectedOptionalPlans: any = [];
  optionalPlanList: any = [];
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    public dialogRef: CcDrawerRef<EditSiteComponent>,
    @Inject(CC_DRAWER_DATA) public list: any,
    public modal: CcModal,
    private apiService: DspCommonService,
    private messageBar: MessageBar,
    private formBuilder: FormBuilder,
    private sitesService: SitesService,
    public sanitizer: DomSanitizer,
    private store: Store<DSPAppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.b2cCustomers = this.list.b2cCustomers;
    this.mineStarDetails = this.list.mineStarDetails;
    this.siteCwsId = this.list.siteInfo.cwsId;
    this.siteDescription = this.list.siteInfo.description;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;

      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isRemoveCwsIDAutoSearch = this.dspStoreData?.featureFlagInfo?.isRemoveCwsIDAutoSearch
        ? this.dspStoreData?.featureFlagInfo?.isRemoveCwsIDAutoSearch
        : false;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
    });
    if (this.list.siteInfo.status !== 'Active') {
      this.list.siteInfo.planName = null;
    }
    this.editSiteForm = this.formBuilder.group({
      cwsid: [this.list.siteInfo.name, [Validators.minLength(3)]],
      siteDescription: [this.list.siteInfo.description],
      product: [this.list.siteInfo.product],
      plan: [this.list.siteInfo.planName],
      additionalService: [[]],
    });
    this.isPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;

    if (this.isPricingEligible) {
      this.dynamicTrackerData = this.trackerData;
    } else {
      this.dynamicTrackerData = this.billingTrackerData;
    }

    this.surfaceAddon =
      this.userActiveFeatures && this.userActiveFeatures?.indexOf('Surface & Material Management') > -1;
    const latitude = '';
    const longitude = '';
    this.sitesService
      .getMapInfo({
        key: 'AkBeaUitImCsJQi6z9wMXhQsFN78E0IsNcLTiFlV6hBQuQDR2gw6K0ClI_0U79P0',
        query: `${this.list.siteInfo.latitude ? this.list.siteInfo.latitude : latitude},${
          this.list.siteInfo.longitude ? this.list.siteInfo.longitude : longitude
        }`,
      })
      .subscribe({
        next: (response: any) => {
          this.list.siteInfo.mapLocationInfo = response.resourceSets[0].resources.length
            ? response.resourceSets[0].resources[0].name
            : '-';
        },
        error: (errorResponse) => {},
      });
    this.constructProducts(this.mineStarDetails);
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  get formControl() {
    return this.editSiteForm.controls;
  }
  constructService({ currentSelection }: any) {
    this.addOnServices = [];
    if (currentSelection[0].value.baseService !== this.list.siteInfo.plan) {
      this.editSiteForm.get('additionalService')?.setValue([]);
    }
    this.isPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      currentSelection[0].value.enableContractVisualization;

    this.addOnServices =
      currentSelection[0] &&
      currentSelection[0].value.addOnServices.map((obj: any) => {
        return {
          label:
            this.isPricingEligible && this.isNonB2C && obj.makePrice.Default
              ? obj.addOnService + ' - ' + (this.convertNumberFormat(obj.makePrice.Default) || '')
              : obj.addOnService,
          value: obj.addOnService,
        };
      });
    if (!this.surfaceAddon) {
      this.addOnServices = this.addOnServices.filter((ele: any) => {
        ele.label == 'Surface & Material Management' && !this.surfaceAddon;
      });
    }
  }

  constructProducts(productObj: any) {
    for (const k in productObj) {
      if (k !== 'type') this.products.push({ label: k, value: productObj[k] });
    }

    if (this.products.length) {
      this.editSiteForm.get('product')?.setValue(this.products[0].label);
      const product = isArray(this.editSiteForm.value.product)
        ? this.editSiteForm.value.product[0].label
        : this.editSiteForm.value.product;
      if (
        this.b2cCustomers &&
        this.b2cCustomers.b2cSupportedApps &&
        this.b2cCustomers.billDirectCustomers &&
        this.b2cCustomers.b2cSupportedApps.indexOf(product) != -1 &&
        this.b2cCustomers.billDirectCustomers.indexOf(this.list.siteInfo.ucid) != -1
      ) {
        this.isNonB2C = false;
      }
      this.setSavedPlanDetails();
      this.setSavedAddonDetails();
    }
  }

  setSavedAddonDetails() {
    this.addOnServices = [];
    this.selectedAddonServices = [];
    this.isPricingEligible =
      (this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.selectedPlan?.enableContractVisualization) ||
      false;
    if (this.selectedPlan?.addOnServices) {
      this.addOnServices = this.selectedPlan.addOnServices.map((obj: any) => {
        const addon = {
          label:
            this.isPricingEligible && this.isNonB2C && obj.makePrice.Default
              ? obj.addOnService + ' - ' + (this.convertNumberFormat(obj.makePrice.Default) || '')
              : obj.addOnService,
          value: obj.addOnService,
        };
        if (this.list.siteInfo.planName?.split('|').includes(obj.addOnService)) {
          this.selectedAddonServices.push(addon);
        }
        return addon;
      });
      if (!this.surfaceAddon) {
        this.addOnServices = this.addOnServices.filter((ele: any) => {
          ele.label == 'Surface & Material Management' && !this.surfaceAddon;
        });
      }
    }
    if (this.selectedAddonServices.length > 0) {
      this.editSiteForm.get('additionalService')?.setValue(cloneDeep(this.selectedAddonServices));
    }
  }

  setSavedPlanDetails() {
    this.planData = [];
    this.selectedPlan = {};
    let savedPlan;
    this.planData = this.products[0].value.map((obj: any) => {
      this.isPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 && obj.enableContractVisualization;
      const planLabel =
        this.isPricingEligible && this.isNonB2C && obj.makePrice.Default
          ? obj.baseService + ' - ' + (this.convertNumberFormat(obj.makePrice.Default) || '')
          : obj.baseService;
      if (this.list.siteInfo.planName?.split('|')[0] == obj.baseService) {
        this.selectedPlan = obj;
        savedPlan = { label: planLabel, value: obj };
      }
      return { label: planLabel, value: obj };
    });
    if (savedPlan) {
      this.editSiteForm.patchValue({ ['plan']: [savedPlan] });
    }
  }

  // searchCWSID() {
  //   this.editSiteForm.controls['cwsid'].valueChanges
  //     .pipe(
  //       debounceTime(500),
  //       switchMap((id) => {
  //         if (!id || id.length <= 2 || id.length > 10) {
  //           return of(id);
  //         }
  //         const bodyReq = { cwsid: id };
  //         return this.apiService.checkCWSID(bodyReq);
  //       })
  //     )
  //     .subscribe({
  //       next: (res: any) => {
  //         this.cwsIDExists = true;
  //       },
  //       error: (err) => {
  //         if (err.status === 202 || err.status === 200) {
  //           this.cwsIDExists = true;
  //         } else {
  //           this.cwsIDExists = false;
  //         }
  //       },
  //     });
  // }

  constructProductPlan({ currentSelection }: any) {
    const product = this.editSiteForm.value.product ? this.editSiteForm.value.product[0].label : 'None';
    if (
      this.b2cCustomers &&
      this.b2cCustomers.b2cSupportedApps &&
      this.b2cCustomers.billDirectCustomers &&
      this.b2cCustomers.b2cSupportedApps.indexOf(product) != -1 &&
      this.b2cCustomers.billDirectCustomers.indexOf(this.list.siteInfo.ucid) != -1
    ) {
      this.isNonB2C = false;
    }
    this.planData = [];
    this.planData = currentSelection[0].value.map((obj: any) => {
      this.isPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 && obj.enableContractVisualization;
      return {
        label:
          this.isPricingEligible && this.isNonB2C
            ? obj.baseService + ' - ' + this.convertNumberFormat(obj.makePrice.Default)
            : obj.baseService,
        value: obj,
      };
    });
  }

  onDropdownChange({ currentSelection }: any, prop: string) {
    this.editSiteForm.patchValue({
      [prop]: currentSelection,
    });
    if (prop === 'plan') {
      currentSelection?.forEach((data: any) => {
        if (data.value.baseService != this.list.siteInfo.plan) {
          if (this.isPricingEligible) {
            this.isPaymentLoaded = true;
            this.isPlanRetained = true;
            this.isPaymentPage = false;
          } else {
            this.isPaymentPage = true;
            this.isPaymentLoaded = true;
          }
        } else if (data.value.baseService === this.list.siteInfo.plan && this.list.siteInfo.status !== 'Active') {
          this.isPaymentLoaded = true;
        } else {
          this.isPaymentLoaded = false;
        }
      });
    } else if (prop === 'product') {
      if (this.editSiteForm.value.product === currentSelection && this.isPricingEligible) {
        this.isPaymentLoaded = false;
        this.isPaymentPage = false;
      } else if (this.editSiteForm.value.product === currentSelection && !this.isPricingEligible) {
        this.isPaymentPage = true;
        this.isPaymentLoaded = false;
      }
    }

    // if (this.editSiteForm.value.plan === this.list.siteInfo.planName) {
    //   this.isPaymentLoaded = true;
    // }
    this.editSiteForm.get('additionalService')?.setValue(cloneDeep(this.selectedAddonServices));
  }

  selectService(_$event: any) {
    this.selectedOptionalPlans = [];
    this.optionalPlanList = [];
    if (_$event.action === 'Close') {
      if (_$event.currentSelection.length > 0) {
        if (this.isOptionalPlanApplied) {
          this.isPaymentLoaded = true;
        } else {
          this.isPaymentLoaded = false;
        }
      } else {
        this.isOptionalPlanApplied = false;
      }
    } else if (_$event.action === 'Apply') {
      this.selectedOptionalPlans = this.list.siteInfo.addOnServices;
      this.optionalPlanList = _$event.source.CcDropdownData;
      if (_$event.currentSelection.length > 0) {
        const res = _$event.currentSelection;
        let currentSelection: any[] = [];
        res.forEach((data: any) => {
          currentSelection.push(data.value);
        });
        const final = this.compareArrays(this.selectedOptionalPlans, currentSelection);

        this.isPaymentLoaded = final;
        this.isOptionalPlanApplied = final;
      } else {
        if (this.isPlanRetained) {
          this.isPaymentLoaded = true;
        } else {
          if (this.selectedOptionalPlans.length > 0) {
            const result = this.checkValueExists(this.optionalPlanList, this.selectedOptionalPlans);
            if (result) {
              this.isPaymentLoaded = true;
            } else {
              this.isPaymentLoaded = false;
            }
          } else {
            this.isPaymentLoaded = false;
          }
        }
        this.isOptionalPlanApplied = false;
      }
    }
  }

  checkValueExists(firstArr: any, secondArr: any) {
    return secondArr.some((value: any) => firstArr.some((item: any) => item.data.value === value && !item.selected));
  }
  compareArrays(array1: any[], array2: any[]) {
    array1.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    array2.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
    let result: any;
    if (JSON.stringify(array1) === JSON.stringify(array2)) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  getPartOfControl(prop: string) {
    return this.editSiteForm.get(prop)?.value[0].value;
  }
  closeDrawer(refresh = false) {
    this.dialogRef.close(refresh);
    this.isPaymentLoaded = false;
  }

  saveAndSubscribeSite(type: string) {
    this.loaderValue = true;
    const product = isArray(this.editSiteForm.value.product)
      ? this.editSiteForm.value.product[0].label
      : this.editSiteForm.value.product;
    const ucid = this.list.siteInfo.ucid;
    if (this.billToParty == '') {
      this.billToParty =
        this.list.b2cCustomers &&
        this.list.b2cCustomers.b2cSupportedApps &&
        this.list.b2cCustomers.billDirectCustomers &&
        this.list.b2cCustomers.b2cSupportedApps.indexOf(product) != -1 &&
        this.list.b2cCustomers.billDirectCustomers.indexOf(ucid) != -1
          ? 'customer'
          : 'dealer';
    }
    let selectedSubscription = this.editSiteForm.value.plan
      ? this.editSiteForm.value.plan[0].value.baseService + '|'
      : 'None';
    if (this.addOnServices.length > 0 && this.editSiteForm.value.additionalService) {
      this.editSiteForm.value.additionalService.forEach((addon: any) => {
        selectedSubscription += addon.value + '|';
      });
    }
    const customerLevels =
      selectedSubscription != 'None'
        ? selectedSubscription.substring(0, selectedSubscription.length - 1)
        : selectedSubscription;

    const sitePayLoad = {
      name: this.list.siteInfo.name,
      description: this.editSiteForm.value.siteDescription,
      latitude: this.list.siteInfo.latitude,
      longitude: this.list.siteInfo.longitude,
      admin: this.editSiteForm.value.cwsid,
      siteId: this.list.siteInfo.guid,
      ucid: this.list.siteInfo.ucid,
      dcName: this.list.siteInfo.dcnName,
      ucName: this.list.siteInfo.ucidName,
      dcn: this.list.siteInfo.dcn,
      billToParty: this.billToParty,
      status: 'Active',
      dealerCode: this.dealerCode,
      requestingDealerCode: this.dealerCode,
      customerLevel: customerLevels.split('|'),
      customerPrevLevel: null,
      serviceSubscription: customerLevels,
      cancelReason: '',
      organization: 'Customer',
      organizationType: 'Customer',
      level: 'Customer',
      associatedOrganization: 'Customer',
      associatedOrganizationType: 'Customer',
      origin: 'Owned',
      orgType: 'Customer',
    };

    if (this.list.siteInfo.planName != customerLevels && customerLevels !== 'None' && type === 'submit') {
      if (type === 'submit' && !this.isPaymentPage) {
        this.isPaymentPage = true;
        this.loaderValue = true;
        this.isPaymentLoaded = false;
        this.activeStepIndex = !this.isPricingEligible ? 0 : 2;
        this.paymentPreviewLoading = true;
        // payment preview call
        if (!isEmpty(this.list.siteInfo.planName)) {
          const previousSubscriptionPreviewBody = {
            assetMake: '',
            assetSerialNumber: '',
            deviceId: '',
            deviceSerialNumber: '',
            deviceType: '',
            dealerCode: this.dealerCode,
            dcnNumber: this.list.siteInfo.dcn,
            contractEndDate: '',
            contractStartDate: '',
            siteName: this.list.siteInfo.name,
            applicationName: product,
            isViewSubscription: true,
            ucid: this.list.siteInfo.ucid,
          };
          if (this.isPricingEligible) {
            this.apiService.getPaymentPreview(previousSubscriptionPreviewBody).subscribe({
              next: (res: any) => {
                const currentBillingDetails = !isNil(res) ? res : this.updatePreviousBillingDetails();
                this.currentBillingDetails = { ...currentBillingDetails };
                this.paymentPreviewLoading = false;
              },
              error: (_err) => {
                this.isPaymentLoaded = false;
                this.paymentPreviewLoading = false;
                const messageText = `There was an error while processing your request. Please contact product support.`;
                this.showToastMessage(messageText, 'error');
                this.loaderValue = false;
              },
            });
          } else {
            this.paymentPreviewLoading = false;
            this.loaderValue = false;
            this.currentPlanDisplay = [];
            this.currentPlanDisplay.push(this.list.siteInfo.plan);
            this.currentPlanDisplay = [...this.currentPlanDisplay, ...this.list.siteInfo.addOnServices];
          }
        }
        const currentSubscriptionPreviewBody = {
          assetMake: '',
          assetSerialNumber: '',
          deviceId: '',
          deviceSerialNumber: '',
          deviceType: '',
          dealerCode: this.dealerCode,
          dcnNumber: this.list.siteInfo.dcn,
          contractEndDate: '',
          contractStartDate: '',
          siteName: this.list.siteInfo.name,
          applicationName: product,
          subscriptions: customerLevels.split('|'),
          isNewSubscription: true,
          ucid: this.list.siteInfo.ucid,
        };
        if (this.isPricingEligible) {
          this.apiService.getPaymentPreview(currentSubscriptionPreviewBody).subscribe({
            next: (res: any) => {
              this.paymentPreviewLoading = false;
              this.newBillingDetails = { ...res };
              this.isPaymentLoaded = true;
              this.loaderValue = false;
            },
            error: (_err) => {
              this.isPaymentLoaded = false;
              this.paymentPreviewLoading = false;
              const messageText = `There was an error while processing your request. Please contact product support.`;
              this.showToastMessage(messageText, 'error');
              this.loaderValue = false;
            },
          });
        } else {
          this.isPaymentLoaded = true;
          this.paymentPreviewLoading = false;
          this.loaderValue = false;
          this.newPlanDisplay = currentSubscriptionPreviewBody.subscriptions;
        }
      } else {
        // update subscription
        this.loaderValue = false;
        this.isPaymentLoaded = true;
        this.activeStepIndex = !this.isPricingEligible ? 0 : 2;
        this.apiService.createSiteSubscription(sitePayLoad).subscribe({
          next: (_res: any) => {
            const messageText = `Plan updated successfully.`;
            this.showToastMessage(messageText, 'success');
            this.closeDrawer(true);
          },
          error: (_err) => {
            this.closeDrawer(false);
            const messageText = `There was an error while processing your request. Please contact product support.`;
            this.showToastMessage(messageText, 'error');
          },
        });
      }
    } else if (
      this.editSiteForm.value.cwsid != this.list.siteInfo.cwsId ||
      this.editSiteForm.value.siteDescription != this.list.siteInfo.description
    ) {
      // save site
      this.apiService.saveSiteDetail(sitePayLoad).subscribe({
        next: (_res: any) => {
          this.closeDrawer(true);
          const messageText = `Site updated successfully.`;
          this.showToastMessage(messageText, 'success');
          this.loaderValue = false;
        },
        error: (_err) => {
          this.closeDrawer(false);
          const messageText = `There was an error while processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
          this.loaderValue = false;
        },
      });
    }
  }

  updatePreviousBillingDetails() {
    const previousBillDetails = {
      billToParty: 'Dealer',
      cancelNotificationMessage: '',
      new: {},
      current: {
        promotions: [],
        monthlyRateAfterDiscount: '',
        monthlyRate: '',
        terminationFee: [],
        products: this.addProductsData(),
      },
      errorMessage: null,
    };
    previousBillDetails.current.monthlyRateAfterDiscount = this.fetchEstimatedTotal(
      previousBillDetails.current.products
    );
    return previousBillDetails;
  }

  addProductsData() {
    const productsData: any = [];
    const previousPlanData = filter(this.mineStarDetails.MineStar, ['baseService', this.list.siteInfo.plan]);
    previousPlanData.forEach((plan: any) => {
      const product = {
        duration: null,
        amount: plan.makePrice.Default || '',
        promotionEndDate: null,
        contractStartDate: '',
        name: plan.baseService,
        contractEndDate: null,
        description: null,
        type: 'Subscription',
      };
      productsData.push(product);
      if (this.list.siteInfo.addOnServices?.length > 0 && plan?.addOnServices?.length > 0) {
        plan.addOnServices.forEach((addOn: any) => {
          const productObj = {
            duration: null,
            amount: addOn.makePrice.Default || '',
            promotionEndDate: null,
            contractStartDate: '',
            name: addOn.addOnService,
            contractEndDate: null,
            description: null,
            type: 'Subscription',
          };
          productsData.push(product);
        });
      }
    });
    return productsData;
  }

  fetchEstimatedTotal(products: any) {
    let totalPrice = 0;
    products.forEach((product: any) => {
      totalPrice += parseInt(product.amount);
    });
    return `${totalPrice}.00 USD`;
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  openDeleteGroupModal(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '466px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }

  onBlurEvent(event: any, item: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (event?.type === 'blur') {
        this.handleUserSearch(item);
      }
    }
  }
  handleUserSearch(item: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (item.length > 2 && item.length < 11) {
        this.validateCWSID(item);
      }
    }
  }
  onSiteCwsIdChange(event: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (event.value === '' && this.cwsIDExists === false) {
        this.cwsIDExists = true;
      }
      // this.isPaymentLoaded = false;
    } else {
      if (event.value === '' && this.cwsIDExists === false) {
        this.cwsIDExists = true;
      } else if (event.value.length > 2 && event.value.length < 11) {
        this.validateCWSID(event.value);
      }
      this.isPaymentLoaded = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  validateCWSID = debounce((cwsId: string) => {
    this.isValidateCWSID = true;
    this.checkForValidCwsId(cwsId);
  }, 200);

  checkForValidCwsId(cwsId: string) {
    const bodyReq = { cwsid: cwsId };
    if (this.prevSearchCWSID !== cwsId) {
      this.prevSearchCWSID = cwsId;
      this.apiService.checkCWSID(bodyReq).subscribe({
        next: (_res: any) => {
          this.isValidateCWSID = false;
          this.cwsIDExists = true;
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          this.isValidateCWSID = false;
          if (err.status === 202 || err.status === 200) {
            this.cwsIDExists = true;
            this.isPlanChanged = true;
            this.changeDetectorRef.detectChanges();
          } else {
            this.cwsIDExists = false;
            this.changeDetectorRef.detectChanges();

            this.editSiteForm.controls['cwsid'].setErrors({ incorrect: true });
          }
        },
      });
    } else {
      this.isValidateCWSID = false;
    }
  }

  onSiteDescChange(_event: any) {
    // this.isPaymentLoaded = false;
    if (this.editSiteForm.value.siteDescription === '' && this.isOptionalPlanApplied) {
      this.isPlanChanged = false;
    } else if (this.editSiteForm.value.siteDescription === '' && this.isPaymentLoaded) {
      this.isPlanChanged = false;
    } else {
      if (this.list.siteInfo.description !== this.editSiteForm.value.siteDescription && this.isPaymentLoaded) {
        this.isPlanChanged = true;
      } else if (this.list.siteInfo.description === this.editSiteForm.value.siteDescription && this.isPaymentLoaded) {
        this.isPlanChanged = false;
      } else {
        this.isPaymentLoaded = false;
      }
    }
  }

  enableSaveButton() {
    this.isPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    if (this.isPaymentLoaded && !this.isPlanChanged) {
      return false;
    } else if (this.cwsIDExists && (this.list.siteInfo.cwsId || '') !== (this.editSiteForm.value.cwsid || '')) {
      return true;
    } else if ((this.list.siteInfo.description || '') !== (this.editSiteForm.value.siteDescription || '')) {
      return true;
    } else if (this.selectedPlan?.baseService !== this.editSiteForm.value?.plan?.[0]?.value?.baseService) {
      return true;
    } else if (
      !this.equalsCheck(this.editSiteForm.value?.additionalService, this.selectedAddonServices) &&
      this.isOptionalPlanApplied
    ) {
      return true;
    } else if (!this.isPricingEligible) {
      this.isPaymentPage = true;
      return false;
    } else {
      return false;
    }
  }
  equalsCheck = (a: any, b: any) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };
  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }

  output(event: any) {
    if (event === 0) {
      this.backward();
    }
    if (event === 1) {
      this.validateFormDetails() && (this.activeStepIndex = 2);
    } else {
      this.activeStepIndex = event;
    }
    const scrollToElement = document.getElementById('tracker') as HTMLElement;
    scrollToElement.scrollTo(111 * this.activeStepIndex, 0);
  }

  validateFormDetails() {
    return this.validateMandatoryFields() && this.editSiteForm.value.product && this.editSiteForm.value.plan;
  }

  getValidButtonStatus(buttonType: any) {
    this.isPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    let returnStatus = false;
    if (buttonType == 'back') {
      if (this.isPricingEligible) {
        returnStatus = this.isPaymentPage;
      } else {
        returnStatus = !this.isPaymentPage;
      }
    } else if (buttonType == 'save') {
      if (this.cwsIDExists && this.editSiteForm.value.cwsid.length >= 3) {
        return true;
      }
      // if (
      //   this.cwsIDExists &&
      //   (this.list.siteInfo.cwsId || '') !== (this.editSiteForm.value.cwsid || '') &&
      //   this.editSiteForm.value.cwsid.length >= 3
      // ) {
      //   return true;
      // } else if ((this.list.siteInfo.description || '') !== (this.editSiteForm.value.siteDescription || '')) {
      //   return true;
      // }
    } else if (buttonType == 'submit') {
      if (this.isPaymentPage) {
        returnStatus = !this.checkPaymentPreview;
      } else {
        returnStatus = !(
          this.validateMandatoryFields() &&
          this.editSiteForm.value.product &&
          this.editSiteForm.value.plan
        );
      }
    }
    return returnStatus;
  }

  validateMandatoryFields() {
    return this.editSiteForm.controls['cwsid'].status === 'VALID';
  }

  backward() {
    this.activeStepIndex = 0;
    this.isPaymentPage = false;
  }
}
