import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { CountryList } from '../../../../../app/models/assets.interface';
import { fetchLoggedInDealerDetails } from '../../../../../app/shared/shared';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetService } from '../../services/asset.service';
import { COUNTRY } from '../configure-wifi/country-code';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
@Component({
  selector: 'dsp-next-gen-ui-asset-unsuspension',
  templateUrl: './asset-unsuspension.component.html',
  styleUrls: ['./asset-unsuspension.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetUnsuspensionComponent implements OnInit {
  public locationData = COUNTRY;
  unsuspensionRequestForm!: FormGroup;
  dealer: any;
  selectedAssetDetails: any;
  countryName = '';
  unsuspensionDescription = '';
  certifiedCountries = [];
  countryData: any;
  listOfCountry: Array<CountryList> = [];
  constructor(
    private fb: FormBuilder,
    private store: Store<DSPAppState>,
    public dialogRef: CcDrawerRef<AssetUnsuspensionComponent, CcDrawerConfig>,
    private assetService: AssetService,
    private messageBar: MessageBar,
    private sharedService: SharedService,
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      data: any;
    }
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.countryData = dsp.certifiedCountries || [];
      delete this.countryData.type;
      this.certifiedCountries = Object.values(this.countryData);
    });
    this.certifiedCountries.forEach((val) => {
      const Obj: CountryList = {
        label: val,
        value: val,
      };
      this.listOfCountry.push(Obj);
    });
    this.dealer = fetchLoggedInDealerDetails();

    this.unsuspensionRequestForm = this.fb.group({
      countryName: [''],
      unsuspensionDescription: [''],
    });
    this.selectedAssetDetails = this.header.data;
  }

  onDropdownChange({ currentSelection }: any, prop: string) {
    this.unsuspensionRequestForm.patchValue({
      [prop]: currentSelection,
    });
  }
  getValidButtonStatus() {
    if (
      this.unsuspensionRequestForm.controls['countryName'].value.length != 0 &&
      this.unsuspensionRequestForm.value.unsuspensionDescription
    )
      return false;
    else return true;
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      appOverlayClass: 'unsuspension-overlay',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  onSubmitUnsuspensionRequest() {
    const assetUnsuspensionPayLoad = {
      deviceType: this.selectedAssetDetails?.deviceType || '',
      make: this.selectedAssetDetails?.make || '',
      requestingDealerCode: this.dealer?.dealerCode,
      serialNumber: this.selectedAssetDetails?.serialNumber || '',
      unsuspendLocation: this.unsuspensionRequestForm.value.countryName[0]?.value || '',
      unsuspendReason: this.unsuspensionRequestForm.value.unsuspensionDescription,
    };
    this.assetService.unsuspendAsset(assetUnsuspensionPayLoad).subscribe({
      error: (err) => {
        if (err && err.status == 202) {
          this.closeDrawer(true);
          const messageText = `Unsuspension Successful.`;
          this.setDataLayer('success', messageText);
          this.showToastMessage(messageText, 'success');
        } else if (err && err.status === 504) {
          this.closeDrawer(false);
          const messageText = `Error processing request. Please retry After some time or contact Cat Digital Support.`;
          this.showToastMessage(messageText, 'error');
          this.setDataLayer('failed', messageText);
        } else {
          this.closeDrawer(false);
          const messageText = `This asset is not eligible to be unsuspended at this time. For more information, review Help or contact Cat Digital Support.`;
          this.showToastMessage(messageText, 'error');
          this.setDataLayer('failed', messageText);
        }
      },
    });
  }
  closeDrawer(respMsg: any) {
    this.dialogRef.close(respMsg);
  }
  setDataLayer(status: any, message: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Request Unsuspension',
      formContent: this.selectedAssetDetails?.serialNumber + ' | ' + this.selectedAssetDetails?.deviceType,
      formContent2: '',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: message,
      formLocation: 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
}
