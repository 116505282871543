import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { ChartFilters, savedFilters, subscribeAssetTrendFilters } from '../../entities/entity';
import {
  setChart1000FilterItem,
  setInitialTableItem,
  setSelectedFilterItem,
} from '../../actions/dashboard-needs-review.actions';

export interface subscribedAssetTrendFilterState {
  subsasst: subscribeAssetTrendFilters;
}
export interface selectedFilterstate {
  filterData: savedFilters;
}
export interface Chart1000Filterstate {
  chartData: ChartFilters;
}

export const initialSATSState: subscribedAssetTrendFilterState = {
  subsasst: {
    subAssetTrendTableConfiguration: {
      title: '',
      height: window.devicePixelRatio === 1 ? '430' : '430',

      xAxis: {
        title: '',
        categories: [''],
      },
      yAxis: {
        title: '',
        min: 0,
      },

      tooltip: {
        enabled: true,
        padding: 5,
        shared: true,
        useHTML: true,
        enableHeader: true,
        tooltipseperator: ' |',
        //valueSuffix: 'Hrs',
        enableSort: true,
        descendingSort: true,
      },
      dataLabels: false,

      scrollbar: {
        max_Axis: 5,
        scrollButtonHide: true,
      },
      marginTop: 30,
      marker: {
        enabled: true,
        fill: false,
        pointEnabled: true,
      },
    },
  },
};
export const savedSATSState: selectedFilterstate = {
  filterData: {
    subsTrendsavedFilterData: {
      range: '',
      month: '',
      application: '',
    },
  },
};
export const chartSATSState: Chart1000Filterstate = {
  chartData: {
    chart1000FilterData: [
      {
        name: '',
        data: '',
      },
    ],
  },
};

export function cloneFilters(state: subscribedAssetTrendFilterState): subscribeAssetTrendFilters {
  return cloneDeep(state.subsasst);
}
export function cloneSavedFilters(state: selectedFilterstate): savedFilters {
  return cloneDeep(state.filterData);
}
export function clonechartFilters(state: Chart1000Filterstate): ChartFilters {
  return cloneDeep(state.chartData);
}
export const subscribeAssetsaveReducer = createReducer(
  savedSATSState,

  on(setSelectedFilterItem, (state, action): selectedFilterstate => {
    return { ...state, filterData: action.filterData };
  })
);
export const subscribeChartReducer = createReducer(
  chartSATSState,

  on(setChart1000FilterItem, (state, action): Chart1000Filterstate => {
    return { ...state, chartData: action.chartData };
  })
);

export const subscribeAssetTrendReducer = createReducer(
  initialSATSState,

  on(setInitialTableItem, (state, action): subscribedAssetTrendFilterState => {
    return { ...state, subsasst: action.subsasst };
  })
);
