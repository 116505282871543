<div class="cc-drawer" *ngIf="headerContent === 'drawerContent'">
  <div class="cc-drawer__header">
    <span class="text"> CWS IDs </span>
    <cc-icon fontSet="icomoon" class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()"
      >cross</cc-icon
    >
  </div>

  <div class="cc-drawer__content cwsid-block" *ngIf="headerContent === 'drawerContent'">
    <cc-list>
      <cc-list-item (listItemClick)="onListItemCLick(item)" class="list-item-pg" *ngFor="let item of listItems">
        <span
          class="name"
          [innerHTML]="(item?.firstName ? item.firstName : '') + ' ' + (item.lastName ? item.lastName : '')"
        ></span>
        <span class="id" [innerHTML]="item.cwsId | highlightSearch: searchString"></span>
      </cc-list-item>
    </cc-list>
  </div>
</div>
<div class="cc-drawer" *ngIf="headerContent === 'view-detail-cwsid-content'">
  <div class="cc-drawer__header">
    <ng-container [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>

  <div class="cc-drawer__content view-detail-cwsid-block">
    <cc-list>
      <cc-list-item (listItemClick)="onListItemCLick(item)" class="list-item-pg" *ngFor="let item of listItems">
        <span class="name" [innerHTML]="item.cwsId"></span>
      </cc-list-item>
    </cc-list>
  </div>
</div>
<ng-template #oneLineHeader>
  <cc-icon class="drilldown-header__back" (click)="backToViewDetails()">back-arrow</cc-icon>
  <span class="cc-drawer__ellipsis title-display"> {{ 'CWS ID' }} </span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon close-display" (click)="closeCwsIdDrawer()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
