<div class="cat-grade-edit-sno">
  <dsp-next-gen-ui-cat-grade-asset-details
    [rowData]="rowData"
    [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
    [showDealerInfo]="true"
  ></dsp-next-gen-ui-cat-grade-asset-details>
</div>
<div class="cat-grade-table" *ngIf="catGradeTableData?.length">
  <cc-table [tableConfig]="catGradeEditTableConfig" [tableData]="catGradeTableData"></cc-table>
</div>
<div class="info-container cat-grade-edit-sno" *ngIf="catGradeTableData?.length && isDemoEligibleDealer">
  <div class="info-row">
    <img src="..\assets\images\info-icon.svg" class="info-icon-style" />A demo license may be available for one or more
    components. Each demo license enables a single use 30-day trial at no extra cost. When a demo license expires, the
    component will revert to the original license purchased, and the demo license will no longer be selectable as an
    option.
  </div>
  <div class="info-row">
    <span class="indent">
      For questions on activating a Cat Grade license, please see the&nbsp;<a
        (click)="helpCenterLink()"
        target="_blank"
        class="dsp-help-hyperlink"
        >DSP Help Center.</a
      >
    </span>
  </div>
</div>
<div class="info-container cat-grade-edit-sno" *ngIf="!loader && !isDemoEligibleDealer">
  <div class="info-row-help-center">
    <img src="..\assets\images\info-icon.svg" class="info-icon-style" /> For questions on activating a Cat Grade
    license, please see the&nbsp;<a (click)="helpCenterLink()" target="_blank" class="dsp-help-hyperlink"
      >DSP Help Center.</a
    >
  </div>
</div>
