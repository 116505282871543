import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
  TableConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { hasIn, isEmpty } from 'lodash-es';
import { AssetDrawerService } from '../../../../../services/asset-drawer.service';
import { fetchLoggedInDealerDetails } from '../../../../../shared/shared';
import { DSPAppState } from '../../../../../store/state/dsp.state';
import { LISTITEMS } from '../../../asset-constants';
import { ExpandedAssetHistoryColumnConfig } from './expanded-history-column-config';
import * as dspConstants from '../../../../../shared/dspConstants';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { ExpandedDetailsComponent } from './expanded-details/expanded-details.component';
@Component({
  selector: 'dsp-next-gen-ui-expanded-asset-history',
  templateUrl: './expanded-asset-history.component.html',
  styleUrls: ['./expanded-asset-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpandedAssetHistoryComponent implements OnInit {
  dealer: any;
  fileType: any;
  downloadedFileName = '';
  isExport = false;
  public nextCursor: any;
  loader = false;
  offset = 1;
  limit: any = 100;
  isLoading: any;
  isXLDownloadEnabled: any;
  dspStoreData!: any;
  isTimeFormatEnabled: any;
  isAssetHistoryOwnershipAdded: any;
  constructor(
    public dialogRef: CcDrawerRef<ExpandedAssetHistoryComponent, CcDrawerConfig>,
    @Inject(CC_DRAWER_DATA)
    public subscriptionHistory: any,
    private messageBar: MessageBar,
    private assetDrawerService: AssetDrawerService,
    private store: Store<DSPAppState>,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService,
    private dspUtilsCommonService: DspUtilsCommonService
  ) {}
  expandedAssetHistoryTableConfig: any = {
    height: window.innerHeight - 185,
    stickyHeader: true,
    expandable: false,
    footer: false,
    isSelfFoldable: true,
    isBulkAction: false,
    isSortEnable: false,
    dragDisabled: true,
    isGroupedTable: true,
    isTheme: '2.0',
    isBulkActionOpenOnSingleClick: false,
    columnConfig: ExpandedAssetHistoryColumnConfig,
  };
  subscriptionHistoryTableData: any[] = [];
  showLoader!: boolean;
  listItems = LISTITEMS;
  telUserType!: any;
  assetData!: any;
  expandHistoryFooterMessage =
    'If all history is greater than 12 months, only events from the last day of activity are displayed';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isTimeZoneSelectionEnabled = false;
  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.assetData = this.subscriptionHistory?.assetData || {};
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isXLDownloadEnabled = this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        ? this.dspStoreData?.featureFlagInfo?.isXLDownloadEnabled
        : false;
      this.isTimeZoneSelectionEnabled = dsp.featureFlagInfo.isTimeZoneSelectionEnabled
        ? dsp.featureFlagInfo.isTimeZoneSelectionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isAssetHistoryOwnershipAdded = dsp?.featureFlagInfo?.isAssetHistoryOwnershipAdded
        ? dsp?.featureFlagInfo?.isAssetHistoryOwnershipAdded
        : false;
    });
    const { subscriptionHistory } = this.subscriptionHistory;
    this.subscriptionHistoryTableData = this.setTransactionLevelData(Object.keys(subscriptionHistory || {})[0]);
    this.loaderService.hide();
    this.limit = this.assetData.limit;
    this.offset = this.assetData.offset;
    this.nextCursor = this.assetData.nextCursor;
    this.addNewColumns();
  }
  addNewColumns() {
    if (this.isAssetHistoryOwnershipAdded) {
      this.expandedAssetHistoryTableConfig?.columnConfig?.map((column: any) => {
        if (column.title === 'Dealer Name & Code') {
          column.title = 'Dealer';
        }
      });
      let newCustomerColumn = [
        {
          name: 'dealerCustomerName',
          title: 'Dealer Customer',
          width: 160,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: false,
          ascendingSort: false,
          sortWithOverlay: false,
          overlayData: '',
          component: ExpandedDetailsComponent,
        },
        {
          name: 'ucidName',
          title: 'Customer',
          width: 160,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: false,
          ascendingSort: false,
          sortWithOverlay: false,
          overlayData: '',
          component: ExpandedDetailsComponent,
        },
      ];
      newCustomerColumn.map((data: any) => {
        if (!this.isItemExists('name', data.name)) {
          this.expandedAssetHistoryTableConfig?.columnConfig?.splice(
            5,
            0,
            this.dspUtilsCommonService.addNewColumnToConfig(
              this.expandedAssetHistoryTableConfig.columnConfig,
              data.name,
              data.title,
              data.width,
              '',
              data.isAlwaysMandatory,
              data.isVisible,
              data.sortingEnable,
              data.sortWithOverlay,
              data.overlayData,
              data.width,
              data.component,
              data.Frozen
            )
          );
        }
      });
    }
  }
  isItemExists(propertyName: string, value: any): boolean {
    return this.expandedAssetHistoryTableConfig?.columnConfig?.some((item: any) => item[propertyName] === value);
  }
  fetchTransactionIds() {
    const { subscriptionHistory } = this.subscriptionHistory;
    return Object.keys(subscriptionHistory || {});
  }

  setTransactionLevelData(transactionId: string) {
    const { subscriptionHistory } = this.subscriptionHistory;
    const updatedSubscriptionData = this.updateDealerInfo(subscriptionHistory?.[transactionId] || []);
    return hasIn(subscriptionHistory, transactionId) ? updatedSubscriptionData : [];
  }

  updateDealerInfo(eventData: any) {
    if (hasIn(eventData[0], 'dealerInfo')) {
      return eventData;
    }
    eventData.forEach((transaction: any) => {
      if (transaction.dealerName || transaction.dealerCode) {
        transaction.dealerInfo = `${transaction.dealerName} - ${transaction.dealerCode}`;
      } else {
        transaction.dealerInfo = '';
      }
    });
    return eventData;
  }

  closeDrawer() {
    this.dialogRef.close();
  }

  onListItemClick(value?: any) {
    if (this.isXLDownloadEnabled) {
      const item = value?.split(' ');
      this.fileType = item[1];
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        operation: 'exportCsv',
        sortColumn: 'serialNumber',
        sortOrder: 'asc',
        serialNumber: this.subscriptionHistory?.assetData?.serialNumber,
        make: this.subscriptionHistory?.assetData?.make,
        userType: this.telUserType,
      };
      const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
      this.showToastMessage(message, 'success');

      switch (this.fileType) {
        case 'Excel':
          this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.xlsx';
          inputData.operation = 'exportExcel';
          this.getExportID(inputData);
          break;
        case 'CSV':
          this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
          inputData.operation = 'exportCsv';
          this.getExportID(inputData);
          break;
        default:
          break;
      }
    } else {
      // const item = value.split(' ');
      // this.fileType = item[1];
      const inputData = {
        dealerCode: this.dealer?.dealerCode,
        operation: 'exportCsv',
        sortColumn: 'serialNumber',
        sortOrder: 'asc',
        serialNumber: this.subscriptionHistory?.assetData?.serialNumber,
        make: this.subscriptionHistory?.assetData?.make,
        userType: this.telUserType,
      };
      const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
      this.showToastMessage(message, 'success');
      this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
      inputData.operation = 'exportCsv';
      this.getExportID(inputData);
      // switch (this.fileType) {
      //   case 'Excel':
      //     this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.xlsx';
      //     inputData.operation = 'exportExcel';
      //     this.getExportID(inputData);
      //     break;
      //   case 'CSV':
      //     this.downloadedFileName = inputData.make + '_' + inputData.serialNumber + '.csv';
      //     inputData.operation = 'exportCsv';
      //     this.getExportID(inputData);
      //     break;
      //   default:
      //     break;
      // }
    }
  }

  getExportID(data: any) {
    this.showLoader = true;
    this.assetDrawerService.getExportID(data).subscribe({
      next: (result: any) => {
        this.getExportStatus(result.exportID);
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      },
    });
  }

  getExportStatus(id: string) {
    // api call for export the asset history
    this.assetDrawerService.getExportStatus(id).subscribe({
      next: (result: any) => {
        if (result?.status == 'Completed') {
          const fileId = result?.fileId;
          this.downloadHistoryFile(fileId);
        } else if (result?.status === 'Pending') {
          setTimeout(() => {
            this.getExportStatus(id);
          }, 1000);
        }
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      },
    });
  }

  downloadHistoryFile(id: string) {
    // api call for dowloading history file
    this.assetDrawerService.downloadHistoryFile(id).subscribe({
      next: (response: any) => {
        this.createDefaultAnchorElement(response);
        this.showLoader = false;
      },
      error: (err: any) => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      },
    });
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  createDefaultAnchorElement(data: any) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const csvUrl = URL.createObjectURL(data);
    a.href = csvUrl;
    a.download = this.downloadedFileName;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  updateTransactionType(transaction: string) {
    const customerLevel =
      this.subscriptionHistory.subscriptionHistory?.[transaction].find(
        (transaction: any) => transaction.subscriptionLevel === dspConstants.Common.CUSTOMER
      ) || {};
    if (!isEmpty(customerLevel)) {
      return this.getHeaderString(customerLevel);
    } else if (isEmpty(customerLevel) && this.subscriptionHistory?.subscriptionHistory?.[transaction].length > 0) {
      return this.getHeaderString(this.subscriptionHistory.subscriptionHistory?.[transaction][0]);
    }
    return '';
  }
  timeStampUTC(timeStampValue: any) {
    const date = new Date(timeStampValue);
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = date.getUTCSeconds();
    const utcDateString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second
      .toString()
      .padStart(2, '0')}`;
    return utcDateString;
  }

  getHeaderString(transaction: any) {
    if (this.isTimeZoneSelectionEnabled) {
      if (!isEmpty(transaction.convertedEventTimeStamp)) {
        let dateTime = this.userPreferenceService.convertTimeToSelectedZone(
          transaction.convertedEventTimeStamp?.split('|')[0] + ' ' + transaction.convertedEventTimeStamp?.split('|')[1],
          false
        );
        if (this.isTimeFormatEnabled) {
          return `${transaction?.eventName} |
          ${this.userPreferenceService.convertDate(dateTime?.split(' ')[0])} |
          ${this.userPreferenceService.convertTime(
            dateTime?.split(' ')[1]
          )} ${this.userPreferenceService.getLocalTimeZone()}`;
        }
        return `${transaction?.eventName} |
        ${dateTime.split(' ')[0]} |
        ${dateTime.split(' ')[1]} ${this.userPreferenceService.getLocalTimeZone()}`;
      } else {
        return '';
      }
    } else {
      return `${transaction?.eventName} | 
      ${new Date(transaction?.eventTimeStamp).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        hour12: false,
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })} |
      ${this.timeStampUTC(transaction?.eventTimeStamp)} UTC`;
    }
  }

  handleExpandEvent(transactionId: string) {
    const selectedLevelData = this.setTransactionLevelData(transactionId);
    this.subscriptionHistoryTableData = [...selectedLevelData];
  }
  toggleExportIcon() {
    this.isExport = !this.isExport;
  }
  public emitInfiniteScrollEvent(): void {
    if (this.nextCursor) {
      this.loader = true;
      this.offset = this.offset + 1;
      this.isLoading = true;
      this.getSubscriptionHistory();
    } else {
      this.loader = false;
      this.isLoading = false;
    }
  }
  disable_scroll() {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  }
  onScroll(event: any) {
    if (
      Math.round(event.target?.scrollTop) + 1 >=
      Math.round(event.target?.scrollHeight - event.target?.offsetHeight)
    ) {
      this.disable_scroll();
      this.emitInfiniteScrollEvent();
      this.cdr.detectChanges();
    }
  }

  getSubscriptionHistory() {
    const subHistoryPayload = {
      serialNumber: this.assetData.serialNumber,
      make: this.assetData.make,
      offset: this.offset,
      limit: this.limit,
    };

    this.assetDrawerService.getAssetSubscriptionHistory(subHistoryPayload).subscribe({
      next: (result: any) => {
        if (result?.changeEventLogGroup) {
          this.subscriptionHistory.subscriptionHistory = {
            ...this.subscriptionHistory.subscriptionHistory,
            ...result?.changeEventLogGroup,
          };
          const subscriptionhistory = this.setTransactionLevelData(Object.keys(result?.changeEventLogGroup || {})[0]);
          this.subscriptionHistoryTableData = [...this.subscriptionHistoryTableData, ...subscriptionhistory];
        }
        this.nextCursor = result.next;
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
        this.subscriptionHistoryTableData = [];
      },
    });
  }
}
