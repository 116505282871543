import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  MessageBar,
  MessageBarConfig,
  TableConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { Observable, Subscription, distinctUntilChanged, fromEvent } from 'rxjs';
import { UserAdminService } from '../../services/user-admin.service';
import { OVERLAYDATA } from '../../user-admin-constant';
import { UserAdminPayload } from '../../user-admin-interface';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import * as dspConstants from 'apps/dsp-ui/src/app/shared/dspConstants';
import { ConfigureAccessComponent } from '../configure-access/configure-access.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { TablesettingService } from 'apps/dsp-ui/src/app/services/tablesetting.service';
import { UserAdminValueComponent } from './user-admin-value/user-admin-value.component';
import { TableSettingsCloseConfirmationComponent } from 'apps/dsp-ui/src/app/components/table-settings-close-confirmation/table-settings-close-confirmation.component';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { CommonConfirmationPopupComponent } from 'apps/dsp-ui/src/app/components/common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from 'apps/dsp-ui/src/app/models/popup.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-user-admin-main',
  templateUrl: './user-admin-main.component.html',
  styleUrls: ['./user-admin-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserAdminMainComponent implements OnInit {
  isOpenConfigAccess = false;
  isTheme = '2.0';
  userAdminForm: FormGroup = new FormGroup({});
  defaultHeight = 317;
  isValidateCWSID = false;
  @ViewChild('myTable', { static: false }) tableRef: any;
  @ViewChild('settingPanel') settingPanelElement!: ElementRef;
  @ViewChild('settingIcon') settingIconElement!: ElementRef;
  userAdminTableCustomData: any = {};
  isAssetWifiAccessNeeded = false;
  isSettingsOutSideClicked = false;
  columnConfig: any = [
    {
      name: 'cwsid',
      title: 'CWS ID',
      priority: '1',
      width: 130,
      isFrozen: false,
      makeActive: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      formControl: 'span',
    },
    {
      name: 'lastName',
      title: 'Last Name',
      priority: 2,
      width: 60,
      formControl: 'span',
      isAlwaysMandatory: false,
      isVisible: true,
      isFrozen: false,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
    },
    {
      name: 'firstName',
      title: 'First Name',
      priority: 3,
      width: 60,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      formControl: 'span',
    },
    {
      name: 'middleName',
      title: 'Middle Name',
      priority: 4,
      width: 70,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      formControl: 'span',
    },

    {
      name: 'emailAddress',
      title: 'Email ID',
      isFrozen: false,
      width: 140,
      formControl: 'span',
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      priority: 5,
    },
    {
      name: 'organization',
      title: 'Organization',
      width: 130,
      priority: 6,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      formControl: 'span',
    },
    {
      name: 'profileType',
      title: '',
      width: 120,
      formControl: 'span',
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      priority: 7,
    },
    {
      name: 'nestedRow',
      title: '',
      width: 30,
      formControl: 'span',
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      priority: 8,
    },
  ];
  columnConfigNew: any = [
    {
      name: 'cwsid',
      title: 'CWS ID',
      width: 220,
      isFrozen: true,
      makeActive: false,
      isAlwaysMandatory: true,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      component: UserAdminValueComponent,
      customData: this.userAdminTableCustomData,
    },

    {
      name: 'lastName',
      title: 'Last Name',
      width: 154,
      component: UserAdminValueComponent,
      isAlwaysMandatory: false,
      isVisible: true,
      isFrozen: false,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
    },
    {
      name: 'firstName',
      title: 'First Name',
      width: 141,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      component: UserAdminValueComponent,
    },

    {
      name: 'emailAddress',
      title: 'Email ID',
      isFrozen: false,
      width: 202,
      component: UserAdminValueComponent,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
    },
    {
      name: 'dealerServicesPortal',
      title: 'Dealer Services Portal (Web & Mobile)',
      isFrozen: false,
      width: 289,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      overlayData: OVERLAYDATA,
      component: UserAdminValueComponent,
    },
    {
      name: 'thirdPartyApiIntegrationTool',
      title: '3rd Party API Integration Tool',
      isFrozen: false,
      width: 167,
      component: UserAdminValueComponent,
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      overlayData: OVERLAYDATA,
    },
    {
      name: 'assetWifiAccess',
      title: 'Asset Wi- Fi Access',
      isFrozen: false,
      width: 157,
      component: UserAdminValueComponent,
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      overlayData: OVERLAYDATA,
    },

    {
      name: 'operatorManagementSystem',
      title: 'Operation Management System',
      isFrozen: false,
      width: 197,
      component: UserAdminValueComponent,
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: false,
      ascendingSort: false,
      sortWithOverlay: false,
      overlayData: OVERLAYDATA,
    },
    {
      name: 'middleName',
      title: 'Middle Name',
      width: 158,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      component: UserAdminValueComponent,
    },
    {
      name: 'organization',
      title: 'Organization',
      width: 155,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: false,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      component: UserAdminValueComponent,
    },
  ];
  tableSettingsPanelConfig!: ITableSettings;
  settingsPanelConfig = {
    settingsPanelHeader: 'Table Settings',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  cwsIdControl: any;
  lastNameControl: any;
  disableSearchBtn = true;
  disableClearAllBtn = true;
  rowHeight = 100;
  userAdminTableConfig: TableConfig = {
    height: window.innerHeight - this.defaultHeight,
    stickyHeader: true,
    expandable: true,
    footer: true,
    isPaginationEnable: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [7],
      advanced: false,
      activePage: 1,
      defaultPageSize: 7,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
    },

    isSortEnable: true,
    columnConfig: [],
    nestedcolumnConfig: [],
    dragDisabled: true,
    isTheme: '2.0',
    expandableHeaderTitle: 'ASSET NAME',
  };
  userAdminTableConfigNew: TableConfig = {
    height: window.innerHeight - 360,
    stickyHeader: true,
    expandable: false,
    footer: true,
    isPaginationEnable: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [7],
      advanced: false,
      activePage: 1,
      defaultPageSize: 7,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
    },

    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    isEnableSettingsPanel: false,
    columnConfig: this.columnConfigNew,
  };
  systemError = dspConstants.Common.SYSTEM_ERROR;
  userAdminTableData: any = [];
  isDataLoading = false;
  enableUserAdminTable = false;
  userAdminAPIPayload: UserAdminPayload = {
    cwsid: '',
    firstName: '',
    email: '',
    organisation: '',
    sortOrder: 'ASC',
    sortField: 'lastName',
    offset: 0,
    resultLimit: 100,
    middleName: '',
    lastName: '',
  };
  cachedData: any;
  nextCursor: any;
  prevCursor: any;
  pageDetails: any;
  emailControl: any;
  firstNameControl: any;
  middleNameControl: any;
  settingDrawerRef: any;
  orgControl: any;
  showAdvancedSearch = false;
  configureAccessDrawerRef: any;
  loggedInUserDetails!: any;
  showLoader = false;
  disableExportIcon = false;
  dspConfig: any;
  showTruncatedText = false;
  isEnableUserAdminTableSettings = false;
  modalRef: any;
  isTableSettingsApply = false;
  isTableSettingClose = false;
  isCloseEvent = false;
  dspStoreData!: any;
  isCommonPopup = false;
  userDealerCode: any;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private userAdminService: UserAdminService,
    private messageBar: MessageBar,
    private drawerService: DrawerService,
    private store: Store<DSPAppState>,
    private renderer: Renderer2,
    private router: Router,
    private tableSettingService: TablesettingService,
    public ccModal: CcModal,
    public dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService
  ) {}
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsCommonService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsCommonService.isAllowedNavigation();
  }
  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.loggedInUserDetails = (dsp && dsp?.userDetails) || {};
      this.dspConfig = dsp.dspConfig;
      this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
        ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
        : false;
      this.isEnableUserAdminTableSettings = this.dspStoreData?.featureFlagInfo?.isEnableUserAdminTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isEnableUserAdminTableSettings
        : false;
      this.isAssetWifiAccessNeeded = this.dspStoreData?.featureFlagInfo?.isAssetWifiAccessNeeded
        ? this.dspStoreData?.featureFlagInfo?.isAssetWifiAccessNeeded
        : false;

      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });

    this.store.select('user').subscribe((user) => {
      this.userDealerCode = user?.user?.dealerCode || '';
    });

    if (this.isEnableUserAdminTableSettings) {
      this.updateCustomData();
      this.tableSettingService.userAdminObservable.subscribe((res) => {
        this.createSettingsPanelConfig();
      });
      this.fetchSettingDetails();
    }

    this.userAdminForm = this.formBuilder.group({
      cwsid: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      firstName: [''],
      middleName: [''],
      email: ['', Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,}$')],
      organisation: [''],
    });

    this.cwsIdControl = this.userAdminForm.get('cwsid');
    this.lastNameControl = this.userAdminForm.get('lastName');
    this.emailControl = this.userAdminForm.get('email');
    this.firstNameControl = this.userAdminForm.get('firstName');
    this.middleNameControl = this.userAdminForm.get('middleName');
    this.orgControl = this.userAdminForm.get('organisation');
    
    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP User Admin Page',
      });
    }

    this.firstNameControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.disableExportIcon = true;
      } else {
        if (
          this.middleNameControl.value?.trim().length > 0 ||
          this.orgControl.value?.trim().length > 0 ||
          this.cwsIdControl.value?.trim().length > 0 ||
          this.lastNameControl.value?.trim().length > 0 ||
          this.emailControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }
      }
    });

    this.middleNameControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.disableExportIcon = true;
      } else {
        if (
          this.firstNameControl.value?.trim().length > 0 ||
          this.orgControl.value?.trim().length > 0 ||
          this.cwsIdControl.value?.trim().length > 0 ||
          this.lastNameControl.value?.trim().length > 0 ||
          this.emailControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }
      }
    });

    this.orgControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.disableExportIcon = true;
      } else {
        if (
          this.middleNameControl.value?.trim().length > 0 ||
          this.firstNameControl.value?.trim().length > 0 ||
          this.cwsIdControl.value?.trim().length > 0 ||
          this.lastNameControl.value?.trim().length > 0 ||
          this.emailControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }
      }
    });

    this.cwsIdControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.disableClearAllBtn = false;
      this.disableExportIcon = true;
      if (value?.trim().length >= 3) {
        this.lastNameControl.clearValidators();
        this.lastNameControl.updateValueAndValidity();
        this.disableSearchBtn = false;
        if (this.lastNameControl.value?.trim()) {
          this.lastNameControl.setValidators(null);
          this.lastNameControl.updateValueAndValidity();
        }
        if (this.cwsIdControl.value?.length >= 3 && this.emailControl?.value?.length > 0) {
          this.emailControl.updateValueAndValidity();
          this.disableSearchBtn = this.emailControl.invalid;
        }
      } else {
        if (
          this.middleNameControl.value?.trim().length > 0 ||
          this.firstNameControl.value?.trim().length > 0 ||
          this.orgControl.value?.trim().length > 0 ||
          this.lastNameControl.value?.trim().length > 0 ||
          this.emailControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }

        if (this.lastNameControl.value?.trim()?.length >= 3 && this.cwsIdControl.value?.trim() === '') {
          this.cwsIdControl.clearValidators();
          this.cwsIdControl.updateValueAndValidity();
          this.disableSearchBtn = false;
        } else {
          if (this.lastNameControl.value?.trim()?.length >= 3 && this.cwsIdControl.value?.trim().length >= 1) {
            this.cwsIdControl.clearValidators();
            this.cwsIdControl.updateValueAndValidity();
            this.disableSearchBtn = false;
          } else {
            this.cwsIdControl.setValidators([Validators.required, Validators.minLength(3)]);
            this.cwsIdControl.updateValueAndValidity();
            this.disableSearchBtn = true;
          }
          if (this.lastNameControl.value?.trim() === '' && this.cwsIdControl.value?.trim() === '') {
            this.disableClearAllBtn = true;
            this.cwsIdControl.clearValidators();
            this.cwsIdControl.updateValueAndValidity();
          }
        }
      }
      this.cdr.detectChanges();
    });

    this.lastNameControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.disableClearAllBtn = false;
      this.disableExportIcon = true;
      if (value?.trim().length >= 3) {
        this.cwsIdControl.clearValidators();
        this.cwsIdControl.updateValueAndValidity();
        this.disableSearchBtn = false;
        // this.disableExportIcon = false;
        if (this.cwsIdControl.value?.trim()) {
          this.cwsIdControl.setValidators(null);
          this.cwsIdControl.updateValueAndValidity();
        }
        if (this.lastNameControl.value?.length >= 3 && this.emailControl?.value?.length > 0) {
          this.emailControl.updateValueAndValidity();
          this.disableSearchBtn = this.emailControl.invalid;
        }
      } else {
        if (
          this.middleNameControl.value?.trim().length > 0 ||
          this.firstNameControl.value?.trim().length > 0 ||
          this.orgControl.value?.trim().length > 0 ||
          this.cwsIdControl.value?.trim().length > 0 ||
          this.emailControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }

        if (this.cwsIdControl.value?.trim()?.length >= 3 && this.lastNameControl.value?.trim() === '') {
          this.lastNameControl.clearValidators();
          this.lastNameControl.updateValueAndValidity();
          this.disableSearchBtn = false;
          // this.disableExportIcon = false;
        } else {
          if (this.cwsIdControl.value?.trim()?.length >= 3 && this.lastNameControl.value?.trim()?.length >= 1) {
            this.lastNameControl.clearValidators();
            this.lastNameControl.updateValueAndValidity();
            this.disableSearchBtn = false;
          } else {
            this.lastNameControl.setValidators([Validators.required, Validators.minLength(3)]);
            this.lastNameControl.updateValueAndValidity();
            this.disableSearchBtn = true;
          }

          if (this.lastNameControl.value?.trim() === '' && this.cwsIdControl.value?.trim() === '') {
            this.disableClearAllBtn = true;
            this.lastNameControl.clearValidators();
            this.lastNameControl.updateValueAndValidity();
          }
        }
      }
      this.cdr.detectChanges();
    });

    this.emailControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      if (value?.length > 0) {
        this.disableExportIcon = true;
        if (this.cwsIdControl.value?.length >= 3 || this.lastNameControl.value?.length >= 3) {
          this.emailControl.updateValueAndValidity();
          this.disableSearchBtn = this.emailControl.invalid;
        }
      }
      if (value === '') {
        if (this.cwsIdControl.value?.length >= 3 || this.lastNameControl.value?.length >= 3) {
          this.disableSearchBtn = false;
        }

        if (
          this.middleNameControl.value?.trim().length > 0 ||
          this.orgControl.value?.trim().length > 0 ||
          this.cwsIdControl.value?.trim().length > 0 ||
          this.lastNameControl.value?.trim().length > 0 ||
          this.firstNameControl.value?.trim().length > 0
        ) {
          this.disableExportIcon = true;
        } else {
          this.disableExportIcon = false;
        }
      }
      this.cdr.detectChanges();
    });
    if (this.isEnableUserAdminTableSettings) {
      this.createSettingsPanelConfig();
    }
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
  }
  onBlurCwsIdEvent(event: any) {
    if (event?.type === 'blur' && this.cwsIdControl.value?.length == 0) {
      this.cwsIdControl.clearValidators();
      this.cwsIdControl.updateValueAndValidity();
    }
  }
  onBlurLastNameEvent(event: any) {
    if (event?.type === 'blur' && this.lastNameControl.value?.length == 0) {
      this.lastNameControl.clearValidators();
      this.lastNameControl.updateValueAndValidity();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
  }

  updateCustomData() {
    this.userAdminTableCustomData.refreshTable = () => this.getUserDetails();
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }

  get formControl() {
    return this.userAdminForm.controls;
  }

  onInputChange(event: any) {
    if (event && event?.target?.value?.length >= 1) {
      this.disableClearAllBtn = false;
    }
    if (this.cwsIdControl?.value?.length >= 3 || this.lastNameControl?.value?.length >= 3) {
      this.disableSearchBtn = false;
    }
  }

  getColumnData(data: any, titleName: any) {
    switch (titleName) {
      case 'CWS ID': {
        return data && data?.length > 10 ? `${data.substring(0, 10)}...` : data;
      }
      case 'Last Name': {
        return data && data?.length > 12 ? `${data.substring(0, 12)}...` : data;
      }
      case 'First Name': {
        return data && data?.length > 12 ? `${data.substring(0, 12)}...` : data;
      }
      case 'Middle Name': {
        return data && data?.length > 12 ? `${data.substring(0, 12)}...` : data;
      }
      case 'Email ID': {
        return data && data?.length > 12 ? `${data.substring(0, 12)}...` : data;
      }
      case 'Organization': {
        return data && data?.length > 12 ? `${data.substring(0, 12)}...` : data;
      }
    }
  }
  getColumnTooltipData(data: any, titleName: any, rowdata: any) {
    switch (titleName) {
      /** As per the BUG 40879 adding the tooltip for CWS ID */
      case 'CWS ID': {
        return data && data?.length > 10 ? data : '';
      }
      case 'Last Name': {
        return data && data?.length > 12 ? data : '';
      }
      case 'First Name': {
        return data && data?.length > 12 ? data : '';
      }
      case 'Middle Name': {
        return data && data?.length > 12 ? data : '';
      }
      case 'Email ID': {
        return data && data?.length > 12 ? data : '';
      }
      case 'Organization': {
        return data && data?.length > 12 ? data : '';
      }
    }
  }
  clearAll() {
    this.userAdminForm.reset();
    this.cwsIdControl.value = '';
    this.lastNameControl.value = '';
    this.disableSearchBtn = true;
    this.disableClearAllBtn = true;
    this.enableUserAdminTable = false;
    this.cachedData = [];
    this.nextCursor = '';
    this.prevCursor = '';
    this.userAdminAPIPayload = {
      cwsid: '',
      firstName: '',
      email: '',
      organisation: '',
      sortOrder: 'ASC',
      sortField: 'lastName',
      offset: 0,
      resultLimit: 100,
      middleName: '',
      lastName: '',
    };
    this.disableExportIcon = false;
  }

  tableEvents(event: any) {
    this.adjustTableHeight(event);
    if (
      event.eventType === 'PAGE_CHANGE_EVENT' &&
      this.isPageChanged(event) &&
      this.pageDetails?.pageNumber !== event.range?.pageNumber
    ) {
      this.pageDetails = event.range;
      let offset = 0;
      if (this.nextCursor) {
        const url = new URLSearchParams(this.nextCursor);
        offset = Number(url.get('offset'));

        if (event.range?.pageNumber === event.range?.numberOfPages && this.userAdminAPIPayload.offset !== offset) {
          this.userAdminAPIPayload.offset = offset;
          this.getUserDetails();
        }
      }
      if (this.prevCursor) {
        const url = new URLSearchParams(this.prevCursor);
        offset = Number(url.get('offset'));

        if (event.range?.pageNumber !== event.range?.numberOfPages && this.userAdminAPIPayload.offset !== offset) {
          this.userAdminAPIPayload.offset = offset;
          this.getUserDetails();
        }
      }
      this.setAutoHeight();
      setTimeout(() => {
        this.adjustTableHeight({ eventType: 'PAGE_CHANGE_EVENT' });
      }, 50);
    } else if (event.eventType === 'SORTING') {
      if (
        this.userAdminAPIPayload.sortOrder != event?.event ||
        this.userAdminAPIPayload.sortField != event?.data?.name
      ) {
        this.userAdminAPIPayload.sortField =
          event?.data?.name === 'emailAddress' ? 'email' : event?.data?.name || 'lastName';
        this.userAdminAPIPayload.sortOrder = event?.event || 'DESC';
        this.cachedData = [];
        this.userAdminAPIPayload.offset = 0;
        this.getUserDetails(true);
      }
    }
    if (!event.isExpanded) {
      this.isOpenConfigAccess = false;
    }
  }

  adjustTableHeight(event: any) {
    if (
      (event.eventType === 'SCROLL_EVENT' ||
        event.eventType === 'WINDOW_RESIZE' ||
        event.eventType === 'PAGE_CHANGE_EVENT') &&
      this.tableRef
    ) {
      const tableRef = this.tableRef?.table?.nativeElement;
      if (this.userAdminTableData?.length && this.renderer && tableRef) {
        if (!this.isEnableUserAdminTableSettings) {
          let adjustHeight = window.innerHeight - 320 + 'px';
          if (this.showAdvancedSearch) adjustHeight = window.innerHeight - 390 + 'px';
          this.renderer.setStyle(tableRef, 'height', adjustHeight);
          this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
        } else {
          let adjustHeight = window.innerHeight - 360 + 'px';
          if (this.showAdvancedSearch) adjustHeight = window.innerHeight - 430 + 'px';
          this.renderer.setStyle(tableRef, 'height', adjustHeight);
          this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
        }
      } else {
        this.renderer.setStyle(tableRef, 'height', '124px');
      }
    }
  }

  setAutoHeight() {
    const tableRef = this.tableRef?.table?.nativeElement;
    if (this.renderer && tableRef) {
      this.renderer.setStyle(tableRef, 'height', 'auto');
    }
  }
  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.userAdminAPIPayload.offset ||
      pageData?.range?.pageSize !== this.userAdminAPIPayload.resultLimit
    );
  }

  onSearchUser() {
    this.setAutoHeight();
    this.userAdminAPIPayload = {
      cwsid: '',
      firstName: '',
      email: '',
      organisation: '',
      sortOrder: 'ASC',
      sortField: 'lastName',
      offset: 0,
      resultLimit: 100,
      middleName: '',
      lastName: '',
    };
    //this.userAdminAPIPayload.offset = 0;
    this.cachedData = [];
    this.pageDetails = {};
    const userCount = {
      pageSizeOptions: [7],
      advanced: false,
      activePage: 1,
      defaultPageSize: 7,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
    };
    if (!this.isEnableUserAdminTableSettings) {
      this.userAdminTableConfig = {
        ...this.userAdminTableConfig,
        pagination: userCount,
      };
    } else {
      this.userAdminTableConfigNew = {
        ...this.userAdminTableConfigNew,
        pagination: userCount,
      };
    }
    this.enableUserAdminTable = false;
    setTimeout(() => {
      this.enableUserAdminTable = true;
    });
    this.cdr.detectChanges();
    this.getUserDetails();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    if (!this.isEnableUserAdminTableSettings) {
      this.defaultHeight = this.showAdvancedSearch ? 387 : 317;
      this.userAdminTableConfig = {
        ...this.userAdminTableConfig,
        //height: window.innerHeight - this.defaultHeight,
      };
    } else {
      this.defaultHeight = this.showAdvancedSearch ? 430 : 360;
      this.userAdminTableConfigNew = {
        ...this.userAdminTableConfigNew,
        //height: window.innerHeight - this.defaultHeight,
      };
    }

    this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
    // this.enableUserAdminTable = false;
    // setTimeout(() => {
    //   this.enableUserAdminTable = true;
    // });
    // this.cdr.detectChanges();
  }

  getUserDetails(isSorting?: boolean) {
    if (this.userAdminForm.value) {
      const payload = {
        cwsid:
          this.userAdminForm?.controls['cwsid']?.value && this.userAdminForm?.controls['cwsid']?.value !== null
            ? this.userAdminForm?.controls['cwsid']?.value.trim()
            : '',
        email:
          this.userAdminForm?.controls['email']?.value && this.userAdminForm?.controls['email']?.value !== null
            ? this.userAdminForm?.controls['email']?.value.trim()
            : '',
        firstName:
          this.userAdminForm?.controls['firstName']?.value && this.userAdminForm?.controls['firstName']?.value !== null
            ? this.userAdminForm?.controls['firstName']?.value.trim()
            : '',
        lastName:
          this.userAdminForm?.controls['lastName']?.value && this.userAdminForm?.controls['lastName']?.value !== null
            ? this.userAdminForm?.controls['lastName']?.value.trim()
            : '',
        middleName:
          this.userAdminForm?.controls['middleName']?.value &&
          this.userAdminForm?.controls['middleName']?.value !== null
            ? this.userAdminForm?.controls['middleName']?.value.trim()
            : '',
        organisation:
          this.userAdminForm?.controls['organisation']?.value &&
          this.userAdminForm?.controls['organisation']?.value !== null
            ? this.userAdminForm?.controls['organisation']?.value.trim()
            : '',
      };
      this.isValidateCWSID = true;

      Object.assign(this.userAdminAPIPayload, payload);

      this.enableUserAdminTable = true;
      this.isDataLoading = true;
      if (this.isEnableUserAdminTableSettings) {
        this.cachedData = [];
      }
      this.setAutoHeight();
      this.userAdminTableData = [];
      this.userAdminService.getUserSearch(this.userAdminAPIPayload).subscribe({
        next: (res: any) => {
          this.isDataLoading = false;

          this.isValidateCWSID = false;

          if (this.nextCursor) {
            this.cachedData.push(...res.users);

            const needsReviewCount = {
              pageSizeOptions: [7],
              advanced: false,
              activePage: isSorting ? 1 : this.pageDetails ? this.pageDetails.pageNumber : 1,
              defaultPageSize: 7,
              setTheme: true,
              enableDropdownWithPrefix: false,
              paginationLabelText: 'Viewing',
            };
            if (!this.isEnableUserAdminTableSettings) {
              this.userAdminTableConfig = {
                ...this.userAdminTableConfig,
                pagination: needsReviewCount,
              };
            } else {
              this.userAdminTableConfigNew = {
                ...this.userAdminTableConfigNew,
                pagination: needsReviewCount,
              };
            }
            this.userAdminTableData = this.cachedData.map((userItem: any) => {
              return {
                ...userItem,
                isNestedTable: this.isEnableUserAdminTableSettings ? false : true,
                isSelfFoldable: this.isEnableUserAdminTableSettings ? false : true,
                isExpandable: this.isEnableUserAdminTableSettings ? false : true,
              };
            });
          } else {
            this.isValidateCWSID = false;

            this.cachedData = res.users;

            this.userAdminTableData = this.cachedData.map((userItem: any) => {
              return {
                ...userItem,
                isNestedTable: this.isEnableUserAdminTableSettings ? false : true,
                isSelfFoldable: this.isEnableUserAdminTableSettings ? false : true,
                isExpandable: this.isEnableUserAdminTableSettings ? false : true,
              };
            });
            //  this.cachedData = this.userAdminTableData;
          }

          this.emptyColumnDataCheck();
          this.nextCursor = res.next;
          this.prevCursor = res?.prev;
          setTimeout(() => {
            this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
          }, 50);
          this.cdr.detectChanges();
        },
        error: (_err: any) => {
          this.isValidateCWSID = false;

          if (_err.status === 500) {
            this.showToastMessage(this.systemError, 'error');
          }
          this.isDataLoading = false;
          this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
        },
      });
    }
  }

  showToastMessage(message: string, status: string) {
    if (status === 'error') {
      this.isDataLoading = false;
    }
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  emptyColumnDataCheck() {
    this.userAdminTableData = this.userAdminTableData.map((user: any) => {
      const attributes = Object.keys(user);
      if (!this.isEnableUserAdminTableSettings) {
        const formattedAttr = ['firstName', 'lastName', 'middleName', 'emailAddress', 'organization'];
        attributes.forEach((item) => {
          if (formattedAttr.indexOf(item) > -1) {
            user[item] = user[item] ? user[item] : '-';
          }
        });
      } else {
        const formattedAttr = [
          'firstName',
          'lastName',
          'middleName',
          'emailAddress',
          'organization',
          'dealerServicesPortal',
          'thirdPartyApiIntegrationTool',
          'assetWifiAccess',
          'operatorManagementSystem',
        ];
        attributes.forEach((item) => {
          if (formattedAttr.indexOf(item) > -1) {
            user[item] = user[item] ? user[item] : '-';
          }
        });
      }
      return user;
    });
  }

  configureAccess(evt: any, rowData: any) {
    evt.preventDefault();
    this.isOpenConfigAccess = true;
    const payload = {
      catRecId: rowData?.catRecId,
      profileType: rowData?.profileType,
    };
    this.userAdminService.getExpandRow(payload).subscribe((res: any) => {
      const displayInfo = res;
      displayInfo.forEach((data: any, index: any) => {
        if (data.appName === 'Dealer Services Portal') {
          displayInfo[index].appName = 'Dealer Services Portal (Web & Mobile)';
        }
      });

      this.configureAccessDrawerRef = this.drawerService.openDrawer<ConfigureAccessComponent, CcDrawerConfig>(
        ConfigureAccessComponent,
        {
          data: {
            headerType: 'Configure Access',
            rowData: rowData,
            expandRowDetails: displayInfo,
          },
          enableBlackBackDrop: true,
          id: 'multiSizeDrawer',
          disableBackdropClick: true,
          drawerSize: CcDrawerSize.SMALL,
        }
      );
      this.configureAccessDrawerRef.afterClosed().subscribe((res: any) => {
        if (res) {
        }
      });
    });
  }

  exportUser() {
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.showLoader = true;
    this.userAdminService.getUserExport().subscribe(
      (res: any) => {
        if (res) {
          this.showLoader = false;
          const filename = res.metadata['fileName'][0];
          const fileExtension = res.metadata['fileExtension'][0];
          const blobObject = new Blob(['\ufeff' + res.entity], { type: fileExtension });
          this.downloadCSV(blobObject, filename);
        }
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
      }
    );
  }

  downloadCSV(csv: any, filename: any) {
    this.showLoader = false;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const csvUrl = URL.createObjectURL(csv);
    a.href = csvUrl;
    a.download = filename;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }

  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };

  createSettingsPanelConfig() {
    if (this.userAdminTableConfigNew.columnConfig) {
      const trimmedTableConfig = this.userAdminTableConfigNew.columnConfig.map((config: any) => {
        if (config.isFrozen === undefined) {
          config.isFrozen = false;
        }
        return {
          title: config.title ? config.title : '',
          name: config.name ? config.name : '',
          isFrozen: config.isFrozen ? config.isFrozen : false,
          isVisible: config.isVisible ? config.isVisible : false,
          isAlwaysMandatory: config.isAlwaysMandatory ? config.isAlwaysMandatory : false,
        };
      });
      this.tableSettingsPanelConfig = {
        features: trimmedTableConfig,
        visibilityToggle: false,
        ITableSettingsPanelTooltip: {
          columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
        },
      };
    }
  }

  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      const originalConfig = JSON.parse(JSON.stringify(this.userAdminTableConfigNew));
      const updatedTableConfig: any = [];
      event.features.forEach((config, index) => {
        const data = originalConfig.columnConfig.find(
          (dataObj: { name: string }) => config.name.indexOf(dataObj.name) !== -1
        );
        if (data) {
          data.isVisible = config.isVisible;
          data.isFrozen = config.isFrozen;
          data.priority = index + 1;
          data.title = config.title;
          switch (data.name) {
            case 'cwsid':
              data.component = UserAdminValueComponent;
              data.width = 220;
              data.customData = this.userAdminTableCustomData;
              break;
            case 'lastName':
              data.component = UserAdminValueComponent;
              data.width = 154;
              break;
            case 'firstName':
              data.component = UserAdminValueComponent;
              data.width = 141;
              break;
            case 'middleName':
              data.component = UserAdminValueComponent;
              data.width = 158;
              break;
            case 'emailAddress':
              data.component = UserAdminValueComponent;
              data.width = 202;
              break;
            case 'organization':
              data.component = UserAdminValueComponent;
              data.width = 155;
              break;
            case 'dealerServicesPortal':
              data.component = UserAdminValueComponent;
              data.width = 289;
              break;
            case 'thirdPartyApiIntegrationTool':
              data.component = UserAdminValueComponent;
              data.width = 167;
              break;
            case 'assetWifiAccess':
              data.component = UserAdminValueComponent;
              data.width = 157;
              break;
            case 'operatorManagementSystem':
              data.component = UserAdminValueComponent;
              data.width = 197;
              break;
            default:
              break;
          }
        }
        updatedTableConfig.push(data);
      });
      originalConfig.columnConfig = updatedTableConfig;
      this.userAdminTableConfigNew = { ...originalConfig };
      // this.emptyColumnDataCheck();
      this.tableSettingService.updateTableSettings('users', updatedTableConfig);
      this.createSettingsPanelConfig();
      this.cdr.detectChanges();
    }
    this.isCloseEvent = false;
  }

  fetchSettingDetails() {
    this.tableSettingService.getTableSettings('users', this.userAdminTableConfigNew);
    if (this.userAdminTableConfigNew.columnConfig && this.isAssetWifiAccessNeeded) {
      this.userAdminTableConfigNew.columnConfig = [
        ...this.userAdminTableConfigNew.columnConfig.filter((config: any) => config.name !== 'assetWifiAccess'),
      ];
    }
  }

  ngOnDestroy() {}

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalConfig = JSON.parse(JSON.stringify(this.userAdminTableConfigNew));
    let isConfirmationPopupNeeded = false;

    for (let i = 0; event.features.length > i; i++) {
      const data = originalConfig.columnConfig.find(
        (dataObj: { name: string }) => event?.features[i]?.name === dataObj?.name
      );
      const indexOfOriginalColumnConfig = originalConfig.columnConfig.indexOf(data);
      if (data) {
        if (typeof data.isFrozen === 'undefined') data.isFrozen = false;
        if (
          data.isVisible !== event.features[i].isVisible ||
          data.isFrozen !== event.features[i].isFrozen ||
          indexOfOriginalColumnConfig !== i
        ) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
        showCrossMark: true,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
      };
      this.modalRef = this.ccModal?.openModal(CommonConfirmationPopupComponent, {
        width: '534px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CROSS) {
          this.isCloseEvent = false;
        }
      });
    } else {
      this.modalRef = this.ccModal?.openModal(TableSettingsCloseConfirmationComponent, {
        width: '534px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === false) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else {
          this.isCloseEvent = false;
        }
      });
    }
  }
}
