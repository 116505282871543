import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CcDrawerConfig,
  CcDrawerSize,
  CCTooltip,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { DeviceInfoComponent } from '../device-info/device-info.component';
import { RequestRemoteConnectionCheckPayload } from '../asset-drawer-interface';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { delay, expand, takeLast } from 'rxjs';
import { isEmpty } from 'lodash-es';

import { AssetService } from '../../services/asset.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DspCommonService } from 'apps/dsp-ui/src/app/services/dsp-common.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { UpdatePIDService } from '../../services/update-pid.service';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from 'apps/dsp-ui/src/app/shared/shared';
import { ConfigureWifiComponent } from '../configure-wifi/configure-wifi.component';
import { DeviceStatusComponent } from '../../../device-status/device-status.component';
import { ParameterConfigUploadComponent } from '../parameter-config-upload/parameter-config-upload.component';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { PartNumberAttachmentComponent } from '../part-number-attachment/part-number-attachment.component';
import { ReportTypeDrawerComponent } from '../report-type-drawer/report-type-drawer.component';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';

@Component({
  selector: 'dsp-next-gen-ui-device-details',
  templateUrl: './device-details.component.html',
  styleUrl: './device-details.component.scss',
})
export class DeviceDetailsComponent implements OnInit, OnChanges {
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() assetDetails: any;
  @Input() rowData: any;
  @Input() assetId: any;
  deviceInfoRef: any;
  connectionStatus: any = [];
  assetConnectionCheck: Array<object> = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;
  lastCheckinValue: any;
  isEnableForwardSlashInPID = false;
  userRole!: string | null;
  datUrl = '';
  dspStoreData!: any;
  isDeviceStatusAPIInProgress = true;
  isTimeZoneSelectionEnabled: any;
  isTimeFormatEnabled: any;
  showConnectionCheckIcon = false;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  assetData!: any;
  updatedSerialNo: any;
  updatedNewmake: any;
  assetImageURL = '';
  showDefaultAssetImage = true;
  searchType = '';
  dealer: any;
  serviceMapping: any;
  assetDetailsData!: any;
  enableSmhLocationSection = false;
  cvaInfo = {};
  isCatGradeControlAllDealer: any;
  dspWorklistConstants = dspConstants.Worklist;
  isAssetLandingDatEnable = false;
  enableCatGradeControl = false;
  datStatus = '';
  refreshLanding = false;
  listForConnectionSecurity = [
    'PLE702',
    'PLE601',
    'PLE631',
    'PLE602',
    'PLE642',
    'PLE643',
    'PLE632',
    'PLE702',
    'PLE742',
    'PLE743',
    'PLE732',
  ];
  showConfigureWifiIcon = false;
  wifiConfigurationRef: any;
  wifiToolTip = 'Configure Wi-Fi';
  showDeviceStatusIcon = false;
  settingsToolTip = 'Device Status';
  deviceDetails: any;
  assetInfo: any;
  configUploadDrawer!: any;
  showParameterConfigUpload = false;
  featuresToggleMap: any;
  isChinaSupportedDevice: any = false;
  connectionCheckToolTip = 'Device Impacted by GPS Time Rollover';
  isReplaceInfoIcon = false;
  showPartNumber = false;
  editPartNumber = false;
  partNumberRef: any;
  roleFlag = false;
  reportTypeRef: any;

  blockingDate: any;
  deviceBlockStatus: any;
  blockingMonth: any;
  blockingYear: any;
  blockedDeviceTypesList: any;
  deviceInfo1C = false;
  blockedDevicesList: any;
  constructor(
    private drawerService: DrawerService,
    public assetDrawerService: AssetDrawerService,
    public assetService: AssetService,
    private changeDetector: ChangeDetectorRef,
    private loaderService: LoaderService,
    private messageBar: MessageBar,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private userPreferenceService: UserPreferenceService,
    private updatePIDService: UpdatePIDService,
    private sharedService: SharedService
  ) {}
  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.assetInfo = this.assetDetails;
    this.userRole = this.dspUtilsCommonService.getValue('userRole');
    this.store.select('dsp').subscribe((dsp) => {
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.dspStoreData = dsp;
      this.serviceMapping = dsp.serviceMapping || {};
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.blockedDeviceTypesList = dsp.dspConfig.blockedDeviceTypes;

      this.isTimeZoneSelectionEnabled = dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.isEnableForwardSlashInPID = this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        ? this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
      this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isCatGradeControlAllDealer = this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        ? this.dspStoreData?.featureFlagInfo?.isCatGradeControlAllDealer
        : false;
      this.isAssetLandingDatEnable = this.dspStoreData?.featureFlagInfo?.isAssetLandingDatEnable
        ? this.dspStoreData?.featureFlagInfo?.isAssetLandingDatEnable
        : false;
      this.isChinaSupportedDevice = this.dspStoreData?.featureFlagInfo?.isChinaSupportedDeviceFeature
        ? this.dspStoreData?.featureFlagInfo?.isChinaSupportedDeviceFeature
        : false;
      this.isReplaceInfoIcon = this.dspStoreData?.featureFlagInfo?.isReplaceInfoIcon
        ? this.dspStoreData?.featureFlagInfo?.isReplaceInfoIcon
        : false;
      this.deviceInfo1C = this.dspStoreData?.featureFlagInfo?.deviceInfo1C
        ? this.dspStoreData?.featureFlagInfo?.deviceInfo1C
        : false;
    });
    this.updatePIDService.updatedPIDValue.subscribe((data: any) => {
      this.updatedSerialNo = data ? data.serialNo : '';
      this.updatedNewmake = data ? data.make : '';
    });
    this.updatedSerialNo = this.rowData?.serialNumber || '';
    this.updatedNewmake = this.rowData?.make || '';
    this.searchType = this.rowData?.searchType || dspConstants.Worklist.GLOBAL_SEARCH;
    this.assetData = this.assetDetails;
    this.assetDetailsData = { ...this.assetData };
    this.loadAssetData();
    const savedConnectionStatus = sessionStorage['Assets'] ? JSON.parse(sessionStorage['Assets']) : [];
    this.getSavedConnectionCheckStatus(this.rowData?.make, this.rowData?.serialNumber, savedConnectionStatus);
    if (this.assetService?.getConnectionStatus()) {
      this.getSavedConnectionCheckStatus(
        this.rowData?.make,
        this.rowData?.serialNumber,
        this.assetService.getConnectionStatus()
      );
    }
    this.checkForPartNumber();
  }
  ngOnChanges(): void {
    this.assetInfo = this.assetDetails;
    this.checkForParameterConfigUpload();
    this.checkForPartNumber();
  }
  loadAssetData() {
    this.deviceInfo1C ? (this.blockingDate = this.getBlockingDate(this.assetInfo.deviceType)) : '';
    this.checkHeaderDetails();
    this.checkForParameterConfigUpload();
    this.deviceInfo1C ? this.setDeviceStatus() : '';
  }

  getBlockingDate(deviceType: string): string | null {
    let blockedDevices: Array<{ deviceType: string; blockingDate: string }>;
    try {
      blockedDevices = this.blockedDeviceTypesList ? JSON.parse(this.blockedDeviceTypesList) : null;
    } catch (error) {
      console.error('Invalid JSON string:', error);
      return null;
    }
    const blockedDevice = blockedDevices?.find((device) => device.deviceType === deviceType);
    let deviceList = blockedDevices.map((x) => x.deviceType);
    this.blockedDevicesList = deviceList.join(', ').replace(/,([^,]*)$/, ' and$1');
    return blockedDevice ? blockedDevice.blockingDate : null;
  }

  setDeviceStatus() {
    let userPreferenceDate = this.userPreferenceService.getUserPreferenceCurrentDate();
    const dateToString = new Date(this.blockingDate);
    this.blockingMonth = this.dspCommonService.getMonthString(dateToString);
    this.blockingYear = dateToString.getFullYear();

    if (userPreferenceDate >= this.blockingDate) {
      // past - blocked
      this.deviceBlockStatus = 'blocked';
    } else if (userPreferenceDate < this.blockingDate) {
      //future - block pending
      this.deviceBlockStatus = 'block pending';
    }
  }

  checkHeaderDetails() {
    this.isWifiConfigurationNeeded();
    this.isConnectionCheckIconNeeded();
    this.isDeviceStatusNeeded();
  }

  isConnectionCheckIconNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Connection Check') > -1 &&
      this.deviceFeaturesInfo &&
      this.deviceFeaturesInfo[this.assetData?.deviceType]?.Connection_Check &&
      !this.assetData?.suspended &&
      (!this.deviceFeaturesInfo[this.assetData?.deviceType]?.Radio_Check ||
        (this.deviceFeaturesInfo[this.assetData?.deviceType]?.Radio_Check &&
          (this.assetData?.radio1SerialNo || this.assetData?.radio2SerialNo)))
    ) {
      if (
        this.listForConnectionSecurity?.indexOf(this.assetData?.deviceType) > -1 &&
        !this.assetData?.enableConnectionCheck
      ) {
        this.showConnectionCheckIcon = false;
      } else {
        this.showConnectionCheckIcon = true;
        this.loaderService.show();
        this.lastCheckinValue = this.getLastCheckInStatus(this.rowData.make, this.rowData.serialNumber);
      }
    }
  }
  getSavedConnectionCheckStatus(make: any, serialNumber: any, status: Array<any>) {
    if (status?.length > 0) {
      status?.forEach((value: any) => {
        value.forEach((val: any) => {
          if (val.eq_make === make && val.eq_sn === serialNumber) {
            this.connectionStatus = [val];
            if (this.connectionStatus && this.connectionStatus[0]?.connection_status === 'pending') {
              this.checkConnectionStatus(make, serialNumber);
            }
          }
        });
      });
    }
  }
  openDeviceInfo() {
    this.deviceInfoRef = this.drawerService.openDrawer<DeviceInfoComponent, CcDrawerConfig>(DeviceInfoComponent, {
      data: this.assetDetails,
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.deviceInfoRef.afterClosed();
  }
  checkConnection(make: any, serialNumber: any) {
    const payload: RequestRemoteConnectionCheckPayload = {
      eq_make: make,
      eq_sn: serialNumber,
    };
    this.assetDrawerService.getConnectionCheckInfo(payload).subscribe({
      next: (result: any) => {
        if (
          result &&
          result.remote_connection &&
          result.remote_connection[0] &&
          result.remote_connection[0].status_code == 0
        ) {
          this.checkConnectionStatus(make, serialNumber);
        } else {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
          this.setDataLayer('connection-check', 'failed', this.systemError);
        }
      },
      error: () => {
        this.connectionStatus = [
          {
            eq_make: make,
            eq_sn: serialNumber,
            connection_status: 'failure',
          },
        ];
        this.assetConnectionCheck.push(this.connectionStatus);
        this.assetService.setConnectionStatus(this.connectionStatus);
        this.showToastMessage(this.systemError, 'error');
        this.setDataLayer('connection-check', 'failed', this.systemError);
      },
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  checkConnectionStatus(make: any, serialNumber: any) {
    const payload = { eq_make: make, eq_sn: serialNumber };
    this.assetDrawerService
      .getConnectionCheckStatus(payload)
      .pipe(
        expand((result: any) => {
          if (
            result &&
            result.remote_connection &&
            result.remote_connection[0] &&
            result.remote_connection[0].connection_status === 'pending'
          ) {
            this.connectionStatus = result.remote_connection;
            this.assetConnectionCheck.push(this.connectionStatus);
            this.assetService.setConnectionStatus(this.connectionStatus);
            sessionStorage.setItem('Assets', JSON.stringify(this.assetConnectionCheck));
            return this.assetDrawerService.getConnectionCheckStatus(payload).pipe(delay(30000));
          } else {
            this.connectionStatus = result && result.remote_connection;
            sessionStorage.setItem('Assets', '');
            this.changeDetector.detectChanges();
            return '';
          }
        }),
        takeLast(1)
      )
      .subscribe({
        next: (result: any) => {
          if (Object.keys(result).length > 0) {
            this.connectionStatus = result && result.remote_connection;

            if (this.connectionStatus[0].connection_status == 'success') {
              this.assetConnectionCheck.push(this.connectionStatus);
              this.loaderService.show();
              this.lastCheckinValue = this.getLastCheckInStatus(make, serialNumber);
            } else {
              this.assetConnectionCheck.push(this.connectionStatus);
            }
            this.setDataLayer('connection-check', 'sucess', '');
            this.assetService.setConnectionStatus(this.connectionStatus);
            this.changeDetector.detectChanges();
          }
        },
        error: () => {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.setDataLayer('connection-check', 'failed', this.systemError);
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
          this.showToastMessage(this.systemError, 'error');
        },
      });
  }
  getLastCheckInStatus(make: any, serialNumber: any) {
    const payloadObj = {
      make: make,
      serialNumber: serialNumber,
    };
    const deviceStatusPayload = this.isEnableForwardSlashInPID ? payloadObj : make + '/' + serialNumber;
    this.dspCommonService.getDeviceStatus(deviceStatusPayload).subscribe({
      next: (result: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.loaderService.hide();
        this.changeDetector.detectChanges();
        if (result?.lastCheckIn) {
          const lastCheckinDateAndTimeArray = result?.lastCheckIn?.split(' ');
          if (this.isTimeZoneSelectionEnabled) {
            let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
              lastCheckinDateAndTimeArray[0] + ' ' + lastCheckinDateAndTimeArray[1],
              true
            );
            let lastCheckIn = convertedTime;
            if (this.isTimeFormatEnabled) {
              this.lastCheckinValue =
                this.userPreferenceService.convertDateTime(
                  lastCheckIn?.split(' ')[0] + ' ' + lastCheckIn?.split(' ')[1]
                ) +
                ' ' +
                this.userPreferenceService.getLocalTimeZone();
            } else {
              this.lastCheckinValue = lastCheckIn;
            }
          } else {
            this.lastCheckinValue = `${lastCheckinDateAndTimeArray[0]}  ${lastCheckinDateAndTimeArray[1]} ${lastCheckinDateAndTimeArray[2]}`;
          }
        }
      },

      error: (err: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.loaderService.hide();
        this.changeDetector.detectChanges();
        this.lastCheckinValue = '';
      },
    });
    return this.lastCheckinValue || '';
  }
  getColor(status: any) {
    switch (status) {
      case 'success': {
        return 'green';
      }
      case 'pending': {
        return 'amber icon';
      }
      case 'failure': {
        return 'red';
      }
      default: {
        return '2595ff';
      }
    }
  }
  isWifiConfigurationNeeded() {
    this.showConfigureWifiIcon = this.rowData?.showConfigureWifiIcon ? true : false;
  }
  openWifiConfiguration() {
    this.wifiConfigurationRef = this.drawerService.openDrawer<ConfigureWifiComponent, CcDrawerConfig>(
      ConfigureWifiComponent,
      {
        data: {
          titleNames: 'Configure Wifi',
          data: this.rowData,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  isDeviceStatusNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Device Status') > -1 &&
      this.assetData?.enableSubscription &&
      this.assetData?.isTelematicsRegistered &&
      this.deviceFeaturesInfo &&
      !this.deviceFeaturesInfo[this.assetData?.deviceType]?.Disable_Device_Status &&
      !this.assetData?.suspended &&
      !this.assetDetailsData?.isMaskRequired
    ) {
      this.showDeviceStatusIcon = true;
    }
  }
  openDeviceStatus() {
    this.drawerService.openDrawer<DeviceStatusComponent, CcDrawerConfig>(DeviceStatusComponent, {
      data: { type: 'DRAWER', assetInfo: this.rowData },
      enableBlackBackDrop: true,
      id: 'deviceStatusDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }
  deviceInformation() {
    this.deviceDetails = this.assetInfo?.deviceSerialNumer;
    if (this.assetInfo?.radioSerialNoList?.length > 0) {
      this.deviceDetails = this.deviceDetails + ' / ';
      this.assetInfo?.radioSerialNoList.forEach((info: any) => {
        this.deviceDetails = this.deviceDetails + info;
        this.assetInfo?.radioSerialNoList?.indexOf(info) < this.assetInfo?.radioSerialNoList?.length - 1
          ? (this.deviceDetails += ' / ')
          : '';
      });
    }
    return this.deviceDetails;
  }
  openParameterConfigUploadDrawer() {
    this.configUploadDrawer = this.drawerService.openDrawer<ParameterConfigUploadComponent, CcDrawerConfig>(
      ParameterConfigUploadComponent,
      {
        data: {
          headerType: 'drawerContent',
          data: this.assetInfo,
        },
        enableBlackBackDrop: true,
        closeOnEsc: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.MEDIUM,
      }
    );
  }
  checkForParameterConfigUpload() {
    if (
      this.assetInfo?.deviceType &&
      this.deviceFeaturesInfo &&
      !this.assetInfo?.suspended &&
      this.featuresToggleMap?.features?.pL444ParameterConfigUpload &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType] &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType]['PL444_Parameter_Config_Upload'] &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('PL444 Parameter Config Upload') !== -1 &&
      this.assetInfo.ownershipDetails &&
      this.assetInfo.ownershipDetails.length > 0 &&
      this.checkIfLoggedInOwnerThere(this.assetInfo.ownershipDetails)
    ) {
      this.showParameterConfigUpload = true;
    }
  }
  checkIfLoggedInOwnerThere(ownershipDetailsArr: any) {
    let isRecordFound = false;
    ownershipDetailsArr?.forEach((owner: any) => {
      if (
        owner?.dealerCode === this.dealer?.dealerCode ||
        (isEmpty(owner?.dealerCode) && (owner?.ucid || owner?.ucidName))
      ) {
        isRecordFound = true;
      }
    });
    return isRecordFound;
  }
  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }
  copyEvent(event: any, deviceDetails: any) {
    const clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEventFunc(event, clipboardText, ':', deviceDetails, 37, 'Asset Details');
  }
  deviceInfoTrimmed() {
    const deviceInfoDetail = this.deviceInformation();
    if (deviceInfoDetail?.length >= 34) {
      return `${deviceInfoDetail?.substring(0, 34)}...`;
    } else {
      return deviceInfoDetail;
    }
  }
  checkForPartNumber() {
    if (
      this.assetInfo?.deviceType &&
      this.deviceFeaturesInfo &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType] &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType]['Part_Number']
    ) {
      this.showPartNumber = true;
      this.checkIfPartNumberEditable();
    }
  }
  checkIfPartNumberEditable() {
    if (
      this.assetInfo?.deviceType &&
      !this.assetInfo?.suspended &&
      this.assetInfo?.enableSubscription &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Product ID') !== -1 &&
      this.isMaskingRequired()
    ) {
      this.editPartNumber = true;
    }
  }
  isMaskingRequired() {
    if (
      (this.assetInfo?.reportingState === dspConstants?.Worklist?.SUBSCRIBED ||
        this.assetInfo?.reportingState === dspConstants?.Worklist?.UNSUBSCRIBED) &&
      this.assetInfo?.ownershipDetails
    ) {
      return !isEmpty(this.assetInfo?.ownershipDetails)
        ? this.checkIfLoggedInOwnerThere(this.assetInfo?.ownershipDetails)
        : true;
    } else {
      return false;
    }
  }
  updatePartNumber() {
    this.partNumberRef = this.drawerService.openDrawer<PartNumberAttachmentComponent, CcDrawerConfig>(
      PartNumberAttachmentComponent,
      {
        data: this.assetInfo,
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  updateReportType() {
    this.reportTypeRef = this.drawerService.openDrawer<ReportTypeDrawerComponent, CcDrawerConfig>(
      ReportTypeDrawerComponent,
      {
        data: {
          assetDetails: this.assetInfo,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.reportTypeRef.afterClosed().subscribe((response: boolean) => {
      this.assetInfo.reportType = response;
    });
  }

  setDataLayer(formName: any, status: any, message: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: formName,
      formContent: this.rowData?.serialNumber + ' | ' + this.rowData?.deviceType,
      formContent2: '',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: message,
      formLocation: 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
}
