import { createAction, props } from '@ngrx/store';
import { CreateLinks, deleteLinks } from '../../models/links.interface';

export enum QuickLinks {
    LOAD_QUICK_LINKS = '[links API] Invoke API',
    QUICK_LINKS__FAILURE = '[links API] links API Failure',
    QUICK_LINKS_SUCCESS = '[links API] links API Success',
    ADD_QUICK_LINKS = '[create links] create API',
    ADD_LINKS__FAILURE = '[create links] create API Failure',
    ADD_LINKS_SUCCESS = '[create links] create API Success',
    DELETE_QUICK_LINKS = '[delete links] delete API',
    DELETE_LINKS__FAILURE = '[delete links] delete API Failure',
    DELETE_LINKS_SUCCESS = '[delete links] delete API Success',
}

export const loadQuickLinksAction = createAction(
    QuickLinks.LOAD_QUICK_LINKS
);
   
export const loadLinksFailureAction = createAction(
    QuickLinks.QUICK_LINKS__FAILURE,
  props<{ error: string }>()
);
  
export const loadLinksSuccessAction = createAction(
    QuickLinks.QUICK_LINKS_SUCCESS,
  props<any>()
);

export const createQuickLinksAction = createAction(
    QuickLinks.ADD_QUICK_LINKS,
    props<{ body: any }>()
);
   
export const createLinksFailureAction = createAction(
    QuickLinks.ADD_LINKS__FAILURE,
  props<{ error: string }>()
);
  
export const createLinksSuccessAction = createAction(
    QuickLinks.ADD_LINKS_SUCCESS,
    props<{ successResponse: CreateLinks }>()
);

export const deleteQuickLinksAction = createAction(
    QuickLinks.DELETE_QUICK_LINKS,
    props<{ body: any }>()
);
   
export const DeleteLinksFailureAction = createAction(
    QuickLinks.DELETE_LINKS__FAILURE,
  props<{ error: string }>()
);
  
export const DeleteLinksSuccessAction = createAction(
    QuickLinks.DELETE_LINKS_SUCCESS,
    props<{ successResponse: deleteLinks }>()
);