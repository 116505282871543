import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CC_DRAWER_DATA,
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
  DrawerService,
  ITableSettingsPanelConfig,
  MessageBar,
  MessageBarConfig,
  TableConfig,
  HighlightSearchPipe,
} from '@cat-digital-workspace/shared-ui-core';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { invoiceDetailData, getDetailedInvoiceResponseData } from '../../billing-interface';
import { BillingService } from '../../services/billing.service';
import { FormControl, FormControlName, Validators } from '@angular/forms';
import { BillingApplicationDetailedViewComponent } from '../billing-application-detailed-view/billing-application-detailed-view.component';
import { BillingInquiryComponent } from '../billing-inquiry/billing-inquiry.component';
import { debounce } from 'lodash-es';
import { ColumnConfig } from '../../detailed-invoice-column-config';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { DetailedInvTooltipHighlightComponent } from '../detailed-invoice-tooltip-highlight/detailed-inv-tooltip-highlight.component';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import * as dspConstants from '../../../../../../apps/dsp-ui/src/app/shared/dspConstants';
import { TablesettingService } from '../../../../../../apps/dsp-ui/src/app/services/tablesetting.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { CommonFilterService } from 'apps/dsp-ui/src/app/services/common-filter.service';
@Component({
  selector: 'dsp-next-gen-ui-detailed-invoice-asset',
  templateUrl: './detailed-invoice-asset.component.html',
  styleUrls: ['./detailed-invoice-asset.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailedInvoiceComponentAsset implements OnInit {
  @Input() detailedInvoice: any;
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  public customData: any;
  public detailArray = [
    'duration',
    'billingStartDate',
    'billingEndDate',
    'promotionName',
    'promotionEndDate',
    'prepay',
    'subTotal',
    'discount',
    'discount',
    'taxAmount',
    'lineItemTotal',
  ];
  dealerCode: any;
  appdetailRef: any;

  constructor(private cdr: ChangeDetectorRef, private drawerService: DrawerService) {
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
  }

  ngOnInit(): void {
    this.rowData = this.value;

    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.customData = this.columnWithHeader?.customData;
    this.cdr.detectChanges();
  }
  navigateDetail(event: any) {
    const billinginquiry = sessionStorage.getItem('inquiryNumber');
    this.appdetailRef = this.drawerService.openDrawer<BillingApplicationDetailedViewComponent, CcDrawerConfig>(
      BillingApplicationDetailedViewComponent,
      {
        data: {
          headerType: 'drawerContent',
          appDetail: this.rowData,
          invoiceNumber: billinginquiry,
          invoiceDealerCode: this.dealerCode,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.appdetailRef.afterClosed().subscribe((response: boolean) => {});
  }

  getColumnData() {
    return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '-';
  }

  getColumnToolTipData() {
    return this.rowData && this.rowData[this.columnWithHeader.name] ? this.rowData[this.columnWithHeader.name] : '';
  }
}
