<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content">
      <p class="modalMessage1">
        The payment associated with the grade control hardware license activation may be non-refundable. Do you want to
        continue?
      </p>
    </div>
    <div class="cc-modal-footer modalfooterStyle">
      <button [setTheme]="true" cc-btn-secondary-outline (click)="onClickCancel()" style="width: 78px; height: 40px">
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="onClickSubmit()" style="width: 78px; height: 40px">
        Submit
      </button>
    </div>
  </div>
</div>
