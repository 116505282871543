<span class="grade-control-panel-items download-container">
  <div class="menu-content" cc-menu [label]="'Title'">
    <div
      class="ellipse-container"
      id="cc-menu1"
      cc-tooltip="Export"
      (click)="onExportClick()"
      [ngClass]="
        rowData?.licenseStatus !== dspWorklistConstants.SUCCESSFUL &&
        rowData?.licenseStatus !== dspWorklistConstants.INSTALLED
          ? 'disable'
          : 'enable'
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="18"
        fill="currentColor"
        class="bi bi-file-earmark-arrow-down"
        viewBox="0 0 16 16"
      >
        <path
          d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"
        />
        <path
          d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
        />
      </svg>
    </div>
  </div>
  <ng-template #downloadLoader>
    <div class="loader-container">
      <div class="loader-container__label"></div>
      <div>
        <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
            fill="#43485C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
            fill="#43485C"
          />
        </svg>
      </div>
    </div>
  </ng-template>
</span>
