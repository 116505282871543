<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ data.title }}</div>
    </div>
    <div class="cc-modal-content">
      <p class="modalMessage1">{{ data.messageLine1 }}</p>
      <p class="modalMessage2">{{ data.messageLine2 }}</p>
    </div>
    <div class="cc-modal-footer modalfooterStyle">
      <button
        *ngIf="data.multipleButtons"
        [setTheme]="true"
        cc-btn-secondary-outline
        (click)="onClickCancel()"
      >
        {{ data.negativeButtonAction }}
      </button>
      <button cc-btn-danger [setTheme]="true" (click)="onClickSubmit(data.positiveButtonAction)">
        {{ data.positiveButtonAction }}
      </button>
    </div>
  </div>
</div>
