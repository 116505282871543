<div class="cc-drawer edit-part-number-drawer">
  <div class="cc-drawer__header pndrawer-header-title">
    <span class="cc-drawer__ellipsis pnheader-title">
      {{ updatePartNumberDrawerHeaderTitle }}
    </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="handlePartNumberDrawerClose()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="cc-grid">
      <p class="pnSubHeader">Enter Attachment P/N</p>
    </div>
    <div class="cc-grid pn-grid-padding">
      <cc-input-field
        id="partNumberInput1"
        [inputtype]="'number'"
        [value]="firstPartNumber"
        [isDense]="true"
        [isTheme]="'2.0'"
        class="pn-text-field"
      >
        <input
          id="partNumberInput1"
          #partNumberInput1
          input-field
          class="input-field pn-text-style"
          autocomplete="off"
          type="text"
          [ngModel]="firstPartNumber"
          (ngModelChange)="firstPartNumber = $event?.toUpperCase() || ''"
          aria-labelledby="Enter P/N Number"
          aria-describedby="Enter P/N Number"
          maxlength="3"
          (keyup)="focusToNext(firstPartNumber, 3)"
          [dspNextGenUiInputRestriction]="noSpecialChars"
        />
      </cc-input-field>
      <span class="symblCls">-</span>
      <cc-input-field
        id="partNumberInput2"
        [inputtype]="'number'"
        [value]="secondPartNumber"
        [isDense]="true"
        [isTheme]="'2.0'"
        class="pn-text-field"
      >
        <input
          id="partNumberInput2"
          #partNumberInput2
          input-field
          class="input-field pn-text-style"
          autocomplete="off"
          type="text"
          [ngModel]="secondPartNumber"
          (ngModelChange)="secondPartNumber = $event?.toUpperCase() || ''"
          aria-labelledby="Enter P/N Number"
          aria-describedby="Enter P/N Number"
          maxlength="4"
          [dspNextGenUiInputRestriction]="noSpecialChars"
        />
      </cc-input-field>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button
      cc-btn-secondary
      cc-btn-large
      [setTheme]="true"
      class="partNumberDrawerFooterButtons"
      (click)="handlePartNumberDrawerClose()"
    >
      Cancel
    </button>
    <button
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      class="partNumberDrawerFooterButtons"
      [disabled]="!enableSavePartNumber()"
      (click)="handlePartNumberUpdate()"
    >
      Submit
    </button>
  </div>
</div>
