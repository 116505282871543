import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectorRef,
  OnChanges,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
} from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CCTooltip } from '@cat-digital-workspace/shared-ui-core';
import { AssetSnInfoComponent } from '../asset-sn-info/asset-sn-info.component';
import { DeviceInfoComponent } from '../device-info/device-info.component';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { ParameterConfigUploadComponent } from '../parameter-config-upload/parameter-config-upload.component';
import { PartNumberAttachmentComponent } from '../part-number-attachment/part-number-attachment.component';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { DomSanitizer } from '@angular/platform-browser';
import { isEmpty } from 'lodash-es';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { EditAssetSerialNumberComponent } from '../edit-asset-serial-number/edit-asset-serial-number.component';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { ReportTypeDrawerComponent } from '../report-type-drawer/report-type-drawer.component';
import { GradeControlSystemAssetDrawerComponent } from '../../grade-control-system-asset-drawer/grade-control-system-asset-drawer.component';
import { SharedService } from '../../../../shared.service';
import { Router } from '@angular/router';
// import { ReportTypeDrawerComponent } from '../report-type-drawer/report-type-drawer.component';
@Component({
  selector: 'dsp-next-gen-ui-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDetailsComponent implements OnInit, OnChanges {
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() assetDetails: any;
  @Output() refreshDrawerOnPID = new EventEmitter();
  assetInfo: any;
  cvaStatus: any;
  cvaCount: any;
  psrUrl: any;
  ecmInfoRef: any;
  deviceInfoRef: any;
  assetNumber: any;
  deviceInfo: any;
  configUploadDrawer!: any;
  partNumberRef: any;
  showParameterConfigUpload = false;
  showPartNumber = false;
  editPartNumber = false;
  editAssetSNNumber = false;
  showPSR = false;
  catalogMapping: any;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  connectionCheckToolTip = 'Device Impacted by GPS Time Rollover';
  ecmToolTip = 'ECM Information';
  deviceToolTip = 'Device Information';
  siteDetails: any;
  gpsRolloverMessage = '';
  cvaUrl = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  editAssetSerialNumber: any;
  dealer: any;
  featuresToggleMap: any;
  reportTypeRef: any;
  roleFlag = false;
  enableCatGradeCtrl = false;
  deviceDetails: any;
  isReplaceInfoIcon = false;
  dspStoreData!: any;
  isChinaSupportedDevice: any = false;
  isShowDeviceTypeInfoFlag = false;
  isShowAssetIdOnLanding = false;
  showTruncatedText = false;
  cvaIconColor = false;
  showAssetId = true;
  cvaProductEnabled = false;
  isDealerCustomerAssetID = false;
  dspData: any;
  deviceInfo1A = false;
  constructor(
    private drawerService: DrawerService,
    private assetDrawerService: AssetDrawerService,
    private store: Store<DSPAppState>,
    private changeDetector: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private messageBar: MessageBar,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspData = dsp;
      this.catalogMapping = dsp.deviceCatalog;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.dspStoreData = dsp.dspConfig;
      this.cvaProductEnabled = dsp.featureFlagInfo.CVAProduct ? dsp.featureFlagInfo.CVAProduct : false;
      this.isDealerCustomerAssetID = dsp.featureFlagInfo.isDealerCustomerAssetID
        ? dsp.featureFlagInfo.isDealerCustomerAssetID
        : false;
    });
    this.isChinaSupportedDevice = this.dspData?.featureFlagInfo?.isChinaSupportedDeviceFeature
      ? this.dspData?.featureFlagInfo?.isChinaSupportedDeviceFeature
      : false;
    this.isShowDeviceTypeInfoFlag = this.dspData?.featureFlagInfo?.isShowDeviceTypeInfoFlag
      ? this.dspData?.featureFlagInfo?.isShowDeviceTypeInfoFlag
      : false;
    this.isShowAssetIdOnLanding = this.dspData?.featureFlagInfo?.showAssetIDOnLanding
      ? this.dspData?.featureFlagInfo?.showAssetIDOnLanding
      : false;
    this.isReplaceInfoIcon = this.dspData?.featureFlagInfo?.isReplaceInfoIcon
      ? this.dspData?.featureFlagInfo?.isReplaceInfoIcon
      : false;
    this.showTruncatedText = this.dspData?.featureFlagInfo?.showTruncatedText
      ? this.dspData?.featureFlagInfo?.showTruncatedText
      : false;
    this.cvaIconColor = this.dspData?.featureFlagInfo?.CVAIconColor
      ? this.dspData?.featureFlagInfo?.CVAIconColor
      : false;
    this.deviceInfo1A = this.dspData?.featureFlagInfo?.deviceInfo1A
      ? this.dspData?.featureFlagInfo?.deviceInfo1A
      : false;
    this.assetDrawerService.enableCatGradeControl.subscribe((data: any) => {
      this.enableCatGradeCtrl = data;
    });
    if (['/asset'].includes(this.router.url)) {
      this.showAssetId = true;
    } else if (['/needsreview'].includes(this.router.url)) {
      this.showAssetId = false;
    }
    this.updateCvaData();
    this.checkForParameterConfigUpload();
    this.checkForPartNumber();
    this.checkForPSR();
    this.updateSiteDetails();
    this.gpsRolloverMessage = `Device requires update due to GPS Time Rollover issue. Please review support documentation on <a target="_blank" href="https://catdealer.com" style="color: #2595ff; text-decoration: underline">catdealer.com</a> for further instructions before managing subscriptions.`;
    this.canEditAssetSNNumber();
  }

  updateSiteDetails() {
    if (!isEmpty(this.assetInfo?.siteId)) {
      this.getSiteDetails(this.assetInfo.siteId);
    }
  }

  ngOnChanges(): void {
    this.updateCvaData();
    this.checkForParameterConfigUpload();
    this.checkForPartNumber();
    this.checkForPSR();
    this.assetNumber = this.assetInfo?.serialNumber || '';
    this.updateSiteDetails();
    this.canEditAssetSNNumber();
  }

  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }

  updateCvaData() {
    this.assetInfo = this.assetDetails;
    this.psrUrl =
      this.assetInfo?.psrUrl?.url + '?make=' + this.assetInfo?.make + '&serialNumber=' + this.assetInfo?.serialNumber;
    this.cvaCount = this.assetInfo?.cvaInfo?.cvaCount;
    this.cvaStatus = this.assetInfo?.cvaInfo?.cvaStatus;
    this.cvaUrl = this.assetInfo?.cvaInfo?.catForesightLink || '';
  }

  isMaskingRequired() {
    if (
      (this.assetInfo?.reportingState === dspConstants?.Worklist?.SUBSCRIBED ||
        this.assetInfo?.reportingState === dspConstants?.Worklist?.UNSUBSCRIBED) &&
      this.assetInfo?.ownershipDetails
    ) {
      return !isEmpty(this.assetInfo?.ownershipDetails)
        ? this.checkIfLoggedInOwnerThere(this.assetInfo?.ownershipDetails)
        : true;
    } else {
      return false;
    }
  }

  checkIfLoggedInOwnerThere(ownershipDetailsArr: any) {
    let isRecordFound = false;
    ownershipDetailsArr?.forEach((owner: any) => {
      if (
        owner?.dealerCode === this.dealer?.dealerCode ||
        (isEmpty(owner?.dealerCode) && (owner?.ucid || owner?.ucidName))
      ) {
        isRecordFound = true;
      }
    });

    return isRecordFound;
  }
  checkForParameterConfigUpload() {
    if (
      this.assetInfo?.deviceType &&
      this.deviceFeaturesInfo &&
      !this.assetInfo?.suspended &&
      this.featuresToggleMap?.features?.pL444ParameterConfigUpload &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType] &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType]['PL444_Parameter_Config_Upload'] &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('PL444 Parameter Config Upload') !== -1 &&
      this.assetInfo.ownershipDetails &&
      this.assetInfo.ownershipDetails.length > 0 &&
      this.checkIfLoggedInOwnerThere(this.assetInfo.ownershipDetails)
    ) {
      this.showParameterConfigUpload = true;
    }
  }
  checkForPartNumber() {
    if (
      this.assetInfo?.deviceType &&
      this.deviceFeaturesInfo &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType] &&
      this.deviceFeaturesInfo[this.assetInfo?.deviceType]['Part_Number']
    ) {
      this.showPartNumber = true;
      this.checkIfPartNumberEditable();
    }
  }

  getSiteDetails(siteId: string) {
    if (isEmpty(this.assetDetails?.siteDetails) && !this.assetDetails?.isSiteDetailsRetrieved) {
      this.assetDetails.isSiteDetailsRetrieved = true;
      this.assetDrawerService.getSitesById({ id: siteId }).subscribe({
        next: (response: any) => {
          this.siteDetails = response;
          this.assetDetails.siteDetails = { ...this.siteDetails };
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    } else {
      this.siteDetails = !isEmpty(this.assetDetails?.siteDetails) ? this.assetDetails?.siteDetails : this.siteDetails;
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  checkForPSR() {
    if (
      !this.assetInfo?.suspended &&
      this.assetInfo?.psrUrl?.flag &&
      this.assetInfo?.deviceType &&
      !this.deviceFeaturesInfo[this.assetInfo?.deviceType]?.DISABLE_PSR
    ) {
      this.showPSR = true;
    }
  }
  checkIfPartNumberEditable() {
    if (
      this.assetInfo?.deviceType &&
      !this.assetInfo?.suspended &&
      this.assetInfo?.enableSubscription &&
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Product ID') !== -1 &&
      this.isMaskingRequired()
    ) {
      this.editPartNumber = true;
    }
  }

  openEcmInfo() {
    this.ecmInfoRef = this.drawerService.openDrawer<AssetSnInfoComponent, CcDrawerConfig>(AssetSnInfoComponent, {
      data: {
        assetInfo: this.assetInfo,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.ecmInfoRef.afterClosed();
  }
  openDeviceInfo() {
    this.deviceInfoRef = this.drawerService.openDrawer<DeviceInfoComponent, CcDrawerConfig>(DeviceInfoComponent, {
      data: this.assetInfo,
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
    this.deviceInfoRef.afterClosed();
  }

  openParameterConfigUploadDrawer() {
    this.configUploadDrawer = this.drawerService.openDrawer<ParameterConfigUploadComponent, CcDrawerConfig>(
      ParameterConfigUploadComponent,
      {
        data: {
          headerType: 'drawerContent',
          data: this.assetInfo,
        },
        enableBlackBackDrop: true,
        closeOnEsc: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.MEDIUM,
      }
    );
  }

  onClickCatGrade() {
    this.partNumberRef = this.drawerService.openDrawer<GradeControlSystemAssetDrawerComponent, CcDrawerConfig>(
      GradeControlSystemAssetDrawerComponent,
      {
        data: {
          type: 'cat-grade-control',
          selectedAssetdetails: this.assetInfo ? this.assetInfo : {},
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
        closeOnEsc: false,
      }
    );
  }

  updatePartNumber() {
    this.partNumberRef = this.drawerService.openDrawer<PartNumberAttachmentComponent, CcDrawerConfig>(
      PartNumberAttachmentComponent,
      {
        data: this.assetInfo,
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  openDrawerInMultiSize(assetInfo: any) {
    this.editAssetSerialNumber = this.drawerService.openDrawer<EditAssetSerialNumberComponent, CcDrawerConfig>(
      EditAssetSerialNumberComponent,
      {
        data: {
          rowData: assetInfo,
          isDrawer: true,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.editAssetSerialNumber?.afterClosed().subscribe((res: any) => {
      if (res) {
        this.refreshDrawerOnPID.emit(res);
      }
    });
  }
  canEditAssetSNNumber() {
    this.editAssetSNNumber = false;
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Configure Product ID') !== -1 &&
      this.assetInfo?.deviceType &&
      !this.deviceFeaturesInfo[this.assetInfo?.deviceType]?.Disable_Product_ID &&
      this.assetInfo.reportingState == 'Unsubscribed' &&
      this.assetInfo?.deviceType !== 'PRINTUP' &&
      this.assetInfo?.deviceType !== 'NO_DEVICE' &&
      ((!isEmpty(this.assetInfo?.ownershipDetails) &&
        this.checkIfLoggedInOwnerThere(this.assetInfo?.ownershipDetails)) ||
        isEmpty(this.assetInfo?.ownershipDetails))
    ) {
      this.editAssetSNNumber = true;
    }
  }

  updateReportType() {
    this.reportTypeRef = this.drawerService.openDrawer<ReportTypeDrawerComponent, CcDrawerConfig>(
      ReportTypeDrawerComponent,
      {
        data: {
          assetDetails: this.assetInfo,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.reportTypeRef.afterClosed().subscribe((response: boolean) => {
      this.assetInfo.reportType = response;
    });
  }
  deviceInformation() {
    this.deviceDetails = this.assetInfo?.deviceSerialNumer;
    if (this.assetInfo?.radioSerialNoList?.length > 0) {
      this.deviceDetails = this.deviceDetails + ' / ';
      this.assetInfo?.radioSerialNoList.forEach((info: any) => {
        this.deviceDetails = this.deviceDetails + info;
        this.assetInfo?.radioSerialNoList?.indexOf(info) < this.assetInfo?.radioSerialNoList?.length - 1
          ? (this.deviceDetails += ' / ')
          : '';
      });
    }
    return this.deviceDetails;
  }
  deviceInfoTrimmed() {
    const deviceInfoDetail = this.deviceInformation();
    if (deviceInfoDetail?.length >= 34) {
      return `${deviceInfoDetail?.substring(0, 34)}...`;
    } else {
      return deviceInfoDetail;
    }
  }
  siteInfoTrimmed() {
    const deviceInfoDetail = this.siteDetails?.name;
    if (deviceInfoDetail?.length > 34) {
      return `${deviceInfoDetail.substring(0, 34)}...`;
    } else {
      return deviceInfoDetail;
    }
  }
  cvaURLClick() {
    window.open(this.cvaUrl, '_blank');
  }
  copyEvent(event: any, deviceDetails: any) {
    const clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEventFunc(event, clipboardText, ':', deviceDetails, 37, 'Asset Details');
  }
  getAssetId() {
    if (
      !isEmpty(this.assetInfo?.customerAssetId) &&
      !isEmpty(this.assetInfo?.nickName) &&
      this.assetInfo?.customerAssetId !== '-' &&
      this.assetInfo?.nickName !== '-'
    ) {
      return this.assetInfo?.customerAssetId + ' / ' + this.assetInfo?.nickName;
    } else if (!isEmpty(this.assetInfo?.customerAssetId) && this.assetInfo?.customerAssetId !== '-') {
      return this.assetInfo?.customerAssetId;
    } else if (!isEmpty(this.assetInfo?.nickName) && this.assetInfo?.nickName !== '-') {
      return this.assetInfo?.nickName;
    }
    return '-';
  }
}
