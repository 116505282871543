<div class="mobile-app-download-modal">
  <div class="cc-modal">
    <div class="cc-modal-header" [ngClass]="isDarkMode === true ? 'darkMode' : ''">
      <div class="cc-modal-title" [ngClass]="isDarkMode === true ? 'darkMode' : ''">{{ modalTitle }}</div>
    </div>

    <div class="cc-modal-content" [ngClass]="isDarkMode ? 'darkMode' : ''" id="action-required-message">
      {{ modalBodyMessage }}
    </div>

    <div class="cc-modal-footer" [ngClass]="isDarkMode ? 'darkMode' : ''">
      <button
        cc-btn-secondary
        cc-btn-large
        class="cancelButton"
        type="submit"
        [setTheme]="true"
        (click)="closeMobileDownloadModal()"
      >
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large class="downloadButton" [setTheme]="true" (click)="RedirectToMobileDownload()">
        Download
      </button>
    </div>
  </div>
</div>
