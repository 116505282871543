import { Component, Inject } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef} from '@cat-digital-workspace/shared-ui-core/modal';

@Component({
  selector: 'dsp-next-gen-ui-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss']
})
export class CookiesModalComponent  {

  constructor(@Inject(CC_MODAL_DATA) public data: any,
  private modalRef: CcModalRef<CookiesModalComponent>) { }

  

  onClickClose() {
    this.modalRef.close();
  }

}
