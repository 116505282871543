import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { fetchLoggedInDealerDetails } from '../shared/shared';
import { includes } from 'lodash-es';
import { dealerType } from '../models/shared.interface';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { catchError } from 'rxjs/operators';
import * as dspConstants from '../shared/dspConstants';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(requestToHandle: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token') || '';

    const dealer: dealerType = fetchLoggedInDealerDetails();

    let headers: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + token);

    if (
      !includes(requestToHandle.url, 'fedlogin') &&
      !includes(requestToHandle.url, '/assetIcons/v1/assetIcons/iconIds/')
    ) {
      headers = headers.set('x-dealer-code', dealer.dealerCode);
      headers = headers.set('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate');
    }
    if (
      !includes(requestToHandle.url, 'fedlogin') &&
      includes(requestToHandle.url, '/dashboard/getSubscriptionAssetTrend')
    ) {
      headers = headers.set('Accept', 'application/trend-v1+json');
    }

    if (includes(requestToHandle.url, 'user/getUserSearch')) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }

    const authReq = requestToHandle.clone({ headers });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorObj = error?.error || {};
        const statusText =
          errorObj?.statusText && typeof errorObj?.statusText === 'string' ? errorObj?.statusText?.toLowerCase() : '';
        const errorText = errorObj?.error && typeof errorObj?.error === 'string' ? errorObj?.error?.toLowerCase() : '';
        switch (error?.status) {
          case 400:
            if (statusText === 'invalid_grant' || errorText === 'invalid_grant') {
              this.router.navigate(['session-expired']);
            }
            break;
          case 401:
            if (
              statusText.toLowerCase().indexOf(dspConstants.Common.TOKEN_EXPIRED) >= 0 ||
              statusText.toLowerCase().indexOf(dspConstants.Common.TOKEN_NOT_FOUND) >= 0 ||
              statusText.toLowerCase().indexOf(dspConstants.Common.INVALID_TOKEN) >= 0 ||
              statusText.toLowerCase().indexOf(dspConstants.Common.INVALID_TOKEN_) >= 0
            ) {
              this.router.navigate(['session-expired']);
            } else {
              this.router.navigate(['denied']);
            }
            break;
        }
        return throwError(error);
      })
    );
  }
}
