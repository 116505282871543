<div class="cc-drawer dcnlist-drawer">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis title-display"> {{ this.header.headerType }}</span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon close-display" (click)="closeDrawer(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="basic-radio">
      <cc-radio-group (valueChange)="dcnOptionClick($event)">
        <cc-radio-button *ngFor="let dcn of dcnlist" [value]="dcn" [checked]="dcn.checked">
          {{ dcn.organizationName + ' - ' + dcn.organization }}</cc-radio-button
        >
      </cc-radio-group>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer(false)">Cancel</button>
    <button cc-btn-primary cc-btn-large [setTheme]="true" [disabled]="disableSubmitBtn" (click)="handleSubmit()">
      Submit
    </button>
  </div>
</div>
