<div class="account-details-form">
  <ng-container>
    <div class="cc-grid">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="modal-content-header">{{ pageHeaderTitle }}</div>
      </div>
    </div>
    <form [formGroup]="accountDetailsForm" id="account-details-container">
      <div class="cc-grid customer-field">
        <div
          *ngIf="fieldNames?.customerAccount"
          class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"
        >
          <div class="create-site-label">Customer Account</div>
          <cc-input-field
            [labelValue]="'Search'"
            [inputtype]="'search'"
            [isDense]="true"
            [width]="'400px'"
            [isTheme]="'2.0'"
            [isLeading]="true"
            [value]="customer"
            (valueChange)="customer = $event.value; searchByCustomer($event)"
            [triggerSearchMinChar]="3"
            [enableSearchOverlay]="true"
            [helpText]="searchHelpText"
            [disabled]="customerLoader"
          >
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                  fill="black"
                />
              </svg>
            </span>
            <div class="cc-input-search-menu">
              <div class="no-result load-container" *ngIf="customerLoader">
                <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
              </div>
              <ng-container *ngIf="searchCustItems && searchCustItems.length > 0">
                <cc-list [theme]="'secondary'">
                  <cc-list-item
                    *ngFor="let item of searchCustItems; let i = index"
                    class="list-item-pg"
                    (listItemClick)="listItemClick(i)"
                    (listItemEnter)="listItemClick(i)"
                  >
                    <div class="each-item">
                      <div class="txt-name">
                        <span class="label" [innerHTML]="item.label | highlightSearch: customer"></span>
                      </div>
                    </div>
                  </cc-list-item>
                </cc-list>
              </ng-container>
            </div>
            <input
              input-field
              #customerSearchBox
              class="input-field account-details-search"
              [value]="customer"
              autocomplete="off"
              formControlName="customerData"
              type="search"
              [isFloating]="false"
              aria-labelledby="Search"
              aria-describedby="Search"
              (valueChange)="customer = $event.value"
              (focus)="onFocusEvent()"
              (click)="onClickEvent($event)"
              (blur)="onBlurEvent($event)"
            />
          </cc-input-field>
          <span *ngIf="customerNotFound" class="req-text">
            <span>Customer not found</span>
          </span>
          <span *ngIf="formControl['customerData']?.errors?.['minlength']" class="req-text">
            <span> Customer Account must contain atleast 3 characters </span>
          </span>
        </div>
        <div
          *ngIf="fieldNames?.prefix"
          class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"
        >
          <div class="create-site-label">Prefix</div>
          <cc-input-field
            [labelValue]="'Search'"
            [inputtype]="'search'"
            [isDense]="true"
            [width]="'400px'"
            [isTheme]="'2.0'"
            [isLeading]="true"
            [value]="prefix"
            (valueChange)="prefix = $event.value; searchByPrefix($event)"
            [triggerSearchMinChar]="1"
            [enableSearchOverlay]="enableSearchOverlay"
            [helpText]="prefixSearchHelpText"
            [disabled]="!prefixLoader"
          >
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
                  fill="black"
                />
              </svg>
            </span>
            <div class="cc-input-search-menu">
              <div class="no-result load-container" *ngIf="prefixLoading">
                <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
              </div>
              <ng-container *ngIf="searchPrefixItems && searchPrefixItems.length > 0">
                <cc-list [theme]="'secondary'">
                  <cc-list-item
                    *ngFor="let item of searchPrefixItems; let i = index"
                    class="list-item-pg"
                    (listItemClick)="prefixListItemClick(i)"
                  >
                    <div class="each-item">
                      <div class="txt-name">
                        <span class="label" [innerHTML]="item.label | highlightSearch: prefix"></span>
                      </div>
                    </div>
                  </cc-list-item>
                </cc-list>
              </ng-container>
            </div>
            <input
              input-field
              #prefixSearchBox
              class="input-field account-details-search"
              [value]="prefix"
              autocomplete="off"
              formControlName="prefix"
              type="search"
              [isFloating]="false"
              aria-labelledby="Search"
              aria-describedby="Search"
              [maxlength]="3"
              (valueChange)="prefix = $event.value"
              (focus)="onFocusPrefixEvent($event)"
              (click)="onClickPrefixEvent($event)"
              (blur)="onBlurPrefixEvent($event)"
            />
          </cc-input-field>
          <span class="req-text" *ngIf="formControl['prefix']?.errors?.['minlength'] || prefixNotFound">
            <span *ngIf="formControl['prefix']?.errors?.['minlength']">
              Serial number prefix must contain atleast 1 character
            </span>
            <span *ngIf="prefixNotFound">Serial number prefix invalid</span>
          </span>
        </div>
        <div
          *ngIf="fieldNames?.saleReason"
          class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"
        >
          <div class="create-site-label">Sale Reason</div>
          <div style="width: 401px; margin-top: 8px">
            <cc-dropdown
              [isTheme]="'2.0'"
              [id]="'product-dropDown'"
              [label]="'Select'"
              [dataSource]="salesData"
              [placementPosition]="'bottom'"
              formControlName="saleReason"
              [typeahead]="true"
              [searchHelpText]="'Couldn´t find any product'"
              [typeaheadmanualinput]="true"
              [scrollToSelected]="true"
              [search]="true"
              [disabled]="!salesReasonLoader"
              (selectionChange)="selctReasonChange($event)"
            >
            </cc-dropdown>
          </div>
        </div>
        <div
          *ngIf="fieldNames?.expirationDate"
          class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12"
        >
          <div class="create-site-label">Expiration Date</div>
          <div class="pickerCls">
            <cc-datepicker
              [isTheme]="isTwoPoint0"
              [dateFormat]="dateFormat"
              [disabled]="true"
              [isDense]="true"
              [id]="'disabled_dp'"
              [ngClass]="{ dateDisableCls: !edateDisabled, nondisabled_dp: edateDisabled }"
              [inputFieldConfig]="inputFieldConfig6"
              [min]="minStartEndDate"
              formControlName="expirydate"
              (dateChange)="onDateChange($event)"
            ></cc-datepicker>
          </div>
        </div>
        <div *ngIf="enableCVAfield" class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
          <div class="create-site-label-required">CVA Number</div>
          <cc-input-field
            id="site_desc_id"
            [disabled]="!salesReasonLoader"
            [labelValue]="'Enter CVA Number'"
            [inputtype]="'text'"
            [value]="cva"
            [isTableInlineEdit]="true"
            [maxlength]="25"
            [isTheme]="isTheme"
          >
            <input
              input-field
              formControlName="cva"
              [(ngModel)]="cva"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter any value"
              aria-describedby="Enter any value"
              [maxlength]="25"
              (valueChange)="onChangeCVA($event)"
              dspNextGenUiNumericOnly
            />
            <span class="cc-inline-input-error-icon" cc-tooltip="Type Only Numbers">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                  fill="#DF3826"
                />
              </svg>
            </span>
          </cc-input-field>
          <span class="req-text">
            <ng-container *ngIf="formControl['cva']?.touched">
              <span *ngIf="formControl['cva']?.errors?.['required']">CVA Number cannot be empty</span>
            </ng-container>
          </span>
        </div>

        <div *ngIf="selectReasonField" class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
          <cc-input-field
            id="site_desc_id"
            [disabled]="!salesReasonLoader"
            [labelValue]="'Enter Your Sale Reason'"
            [inputtype]="'text'"
            [isTableInlineEdit]="true"
            [maxlength]="250"
            [isTheme]="'2.0'"
            [value]="saleReasonDescription"
          >
            <input
              input-field
              formControlName="saleReasonField"
              [(ngModel)]="saleReasonDescription"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter any value"
              aria-describedby="Enter any value"
              [maxlength]="250"
              (valueChange)="onChangeOtherDesc($event)"
            />
          </cc-input-field>
        </div>
        <div *ngIf="fieldNames?.type" class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12">
          <div class="create-site-label">Type</div>
          <div style="width: 430px; padding-top: 5px">
            <cc-dropdown
              [isTheme]="'2.0'"
              [id]="'users-type-dropDown'"
              [label]="'Select'"
              [dataSource]="finalLicenseType"
              [placementPosition]="'bottom'"
              formControlName="licensedTypeData"
              (selectionChange)="licenseTypeDropdownChange($event)"
              [scrollToSelected]="true"
              [typeahead]="true"
              [searchHelpText]="'Couldn´t find any product'"
              [typeaheadmanualinput]="true"
              [search]="true"
              [disabled]="!isListItemSelected"
            >
            </cc-dropdown>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <!-- <div class="cc-modal-footer" *ngIf="selectedData?.plan === 'Service Media Access'">
    <button cc-btn-secondary-outline [setTheme]="true" class="footer-buttons">Back</button>
    <button cc-btn-primary [setTheme]="true" class="footer-buttons">Next</button>
  </div> -->
</div>
