import { ActionReducerMap } from '@ngrx/store';
import { DSPAppState } from '../state/dsp.state';
import { DspCommonReducer } from './dspcommon.reducer';
import { QuickLinkReducer } from './links.reducers';
import { UserDetailReducer, DealerDetailReducer } from './user-info.reducers';
import { needsReviewDashFilterReducer } from '../../components/dashboard/needsReview/reducers/dashboard-needs-review.reducers';
import { CancellationReasonDashFilterReducer } from '../../components/dashboard/cancellation-reason/reducers/cancellation-reason.reducers';
import {
  subscribeAssetTrendReducer,
  subscribeAssetsaveReducer,
  subscribeChartReducer,
} from '../../components/dashboard/subscribed-asset-trend/reducers/dashboard-subscribeasset.reducers';

export const reducers: ActionReducerMap<DSPAppState> = {
  user: UserDetailReducer,
  dealerList: DealerDetailReducer,
  link: QuickLinkReducer,
  dsp: DspCommonReducer,
  needsReviewDashboardFilter: needsReviewDashFilterReducer,
  cancellationReasonDashboardFilter: CancellationReasonDashFilterReducer,
  subAssetTrendTableConfiguration: subscribeAssetTrendReducer,
  subsTrendsavedFilterData: subscribeAssetsaveReducer,
  chart1000FilterData: subscribeChartReducer,
};
