<div class="select-plan-content">
  <div class="select-plan-body">
    <div class="cc-grid">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <span class="asset-title">ASSET DETAILS</span>
        <span class="asset-title ownership">ASSET OWNERSHIP</span>
      </div>
      <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">
        <div class="column1">
          <span class="asset-details-title">Asset S/N:</span>
          <span class="asset-details-value">{{ rowData?.serialNumber }}</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Make/Model:</span>
          <span class="asset-details-value">{{ rowData?.make }}&sol;{{ rowData?.model }}</span>
        </div>
      </div>
      <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5 column-last">
        <div class="column1">
          <span class="asset-details-title">Dealer:</span>
          <span class="asset-details-value" *ngIf="rowData?.dealerName && rowData?.dealerCode">{{
            rowData?.dealerName + ': ' + rowData?.dealerCode
          }}</span>
          <span class="asset-details-value" *ngIf="!(rowData?.dealerName && rowData?.dealerCode) && !showDealerInfo"
            >-</span
          >
          <span class="asset-details-value" *ngIf="!(rowData?.dealerName && rowData?.dealerCode) && showDealerInfo">{{
            this.dealer?.dealerName + ': ' + this.dealer?.dealerCode || '-'
          }}</span>
        </div>
        <div class="column1">
          <span class="asset-details-title">Bill to Party:</span>
          <span class="asset-details-value">{{ billToParty || '-' }}</span>
        </div>
      </div>
      <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
        <div class="column1" (copy)="copyEvent($event, ownershipData?.ucidName, ownershipData?.ucid)">
          <span class="asset-details-title">Customer:</span>
          <span
            *ngIf="ownershipData?.ucidName?.length > 0; else else_content"
            class="asset-details-value"
            cc-tooltip
            [tooltipPosition]="'top'"
            [disabled]="this.customerInfoWithoutTrim?.length > 20 ? false : true"
            [template]="customerInfoTooltipTemplate"
            [innerHTML]="dealerCustomerData(ownershipData?.ucid, ownershipData?.ucidName)"
          ></span>
        </div>
        <div class="column1" (copy)="copyEvent($event, ownershipData?.dealerCustName, ownershipData?.dealerCustNo)">
          <span class="asset-details-title">Dealer Customer:</span>
          <span
            *ngIf="ownershipData?.dealerCustName?.length > 0; else else_content"
            class="asset-details-value"
            cc-tooltip
            [disabled]="this.dealerCustomerInfoWithoutTrim?.length > 20 ? false : true"
            [tooltipPosition]="'top'"
            [template]="dealerCustomerInfoTooltipTemplate"
            [innerHTML]="dealerCustomerData(ownershipData?.dealerCustNo, ownershipData?.dealerCustName)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #else_content>{{ '-' }}</ng-template>
<ng-template #customerInfoTooltipTemplate>
  <div
    [style]="ownershipData.ucidName && ownershipData.ucidName.length > 15 ? 'width: 250px; white-space: normal' : ''"
  >
    <span class="customer-text">Customer: </span>
    <span>{{ updateName(ownershipData.ucidName, ownershipData.ucid) }}<br /></span>
  </div>
</ng-template>
<ng-template #dealerCustomerInfoTooltipTemplate>
  <div
    [style]="
      ownershipData.dealerCustName && ownershipData.dealerCustName.length > 15
        ? 'width: 250px; white-space: normal'
        : ''
    "
  >
    <span class="customer-text">Dealer Customer: </span>
    <span>{{ updateName(ownershipData.dealerCustName, ownershipData.dealerCustNo) }}<br /></span>
  </div>
</ng-template>
