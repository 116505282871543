import { Component, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CCTooltip } from '@cat-digital-workspace/shared-ui-core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-asset-dat',
  templateUrl: './asset-dat.component.html',
  styleUrls: ['./asset-dat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDatComponent {
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  datStatus = '';
  datUrl = '';
  public rowData: any;
  public value: any;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  ngOnInit(): void {
    this.rowData = this.value;
    this.datUrl = environment.redirectURL.datUrl;
  }
  checkDATStatus() {
    const CATDigitalAuthStatus = this.rowData?.digitalAuthorization || '';
    switch (CATDigitalAuthStatus) {
      case 'Available': {
        return 'icon-green';
      }
      case 'Declined': {
        return 'icon-red';
      }
      case 'Not Available': {
        return 'icon-grey';
      }
      default: {
        return 'icon-amber'; // for UNREACHABLE state
      }
    }
  }

  updateDATBadge() {
    const CATDigitalAuthStatus = this.rowData?.digitalAuthorization || '';
    switch (CATDigitalAuthStatus) {
      case 'Declined': {
        return 'dat-badge-declined';
      }
      case 'Not Available': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, all other states
      }
    }
  }

  updateDatStatus() {
    const CATDigitalAuthStatus = this.rowData?.digitalAuthorization || '';
    if (CATDigitalAuthStatus == 'Available') {
      this.datStatus = 'AGREE';
      return `AVAILABLE`;
    } else if (CATDigitalAuthStatus == 'Declined') {
      this.datStatus = 'DECLINE';
      return `DECLINED`;
    } else if (CATDigitalAuthStatus == 'Not Available') {
      this.datStatus = 'UNAVAILABLE';
      return `NOT AVAILABLE`;
    } else if (CATDigitalAuthStatus == '-') {
      return (this.datStatus = this.rowData?.digitalAuthorization);
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `SYSTEM ERROR`;
    }
  }

  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }

  datClick() {
    window.open(this.datUrl, '_blank');
  }
}
