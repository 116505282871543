<div
  class="details-body"
  [ngClass]="
    planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER ||
    planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_CUSTOMER
      ? 'viewdetail-style'
      : 'details-style'
  "
>
  <div class="cc-grid cancel" *ngIf="planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_CANCEL">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>PRODUCT AND PLAN</span>
      </div>
    </div>
    <div
      *ngIf="productPlanNames.productPlan"
      class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5"
    >
      <div class="column1">
        Product | Plan <br />

        <span>
          <div
            style="margin-top: 8px !important"
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (accountDataInput?.appName?.trim() + accountDataInput?.subsDtl?.name?.trim()).length > 28
                ? accountDataInput?.appName + ' | ' + accountDataInput?.subsDtl?.name
                : null
            }}"
            (copy)="copyEventForProductPlan($event, accountDataInput.appName, accountDataInput?.subsDtl?.name)"
          >
            <span class="column-content">{{
              productPlanInfo(accountDataInput?.appName, accountDataInput?.subsDtl?.name)
            }}</span>
          </div>
        </span>
      </div>
    </div>
    <div
      *ngIf="productPlanNames.dealerCode"
      class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2"
    >
      <div class="column1">
        Dealer Code <br />
        <span class="column-additional" dspNextGenUiCopy [fullText]="accountDataInput.dealerCode">
          <span class="column-content">{{ accountDataInput.dealerCode }}</span>
        </span>
      </div>
    </div>
    <div
      *ngIf="productPlanNames.ownership"
      class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5"
    >
      <div class="column1">
        Ownership <br />
        <span class="column-additional">
          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            cc-tooltip="{{
              (accountDataInput?.ucid?.trim() + accountDataInput?.ucName?.trim()).length > 22
                ? accountDataInput?.ucid + ' - ' + accountDataInput?.ucName
                : null
            }}"
            (copy)="copyEventForCustomer($event, accountDataInput.ucid, accountDataInput.ucName)"
          >
            Customer:
            <span class="column-content">{{ customerInfo(accountDataInput.ucid, accountDataInput.ucName) }}</span>
          </div>

          <div
            class="text-truncate"
            [toolTipInteractive]="true"
            [toolTipCloseOnClick]="true"
            [tooltipPosition]="'left'"
            cc-tooltip="{{
              (accountDataInput?.dcn?.trim() + accountDataInput?.dcName?.trim()).length > 11
                ? accountDataInput?.dcn + ' - ' + accountDataInput?.dcName
                : null
            }}"
            (copy)="copyEventForDCName($event, accountDataInput.dcn, accountDataInput.dcName)"
          >
            Dealer Customer:
            <span class="column-content">{{ dealerInfo(accountDataInput.dcn, accountDataInput.dcName) }}</span>
          </div>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5" *ngIf="productPlanNames.cwsId">
      <div class="column1">
        CWS ID <br />
        <span class="column-additional">
          <span class="column-content">{{ accountDataInput.cwsId }}</span>
        </span>
      </div>
    </div>
  </div>

  <div
    class="cc-grid edit"
    *ngIf="
      planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_UPDATE ||
      planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL
    "
  >
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span
          *ngIf="
            accountDataInput.licensedType === subscribeAccountsConst.LU &&
            accountDataInput?.subsDtl?.name === subscribeAccountsConst.TT
          "
          >{{ limitedHeading }}
        </span>
        <span
          *ngIf="
            accountDataInput.licensedType === subscribeAccountsConst.ULU &&
            accountDataInput?.subsDtl?.name === subscribeAccountsConst.TT
          "
          >{{ unlimitedHeading }}
        </span>
        <span *ngIf="accountDataInput?.subsDtl?.name === subscribeAccountsConst.SMA">{{ unlimitedHeading }} </span>
      </div>
    </div>
    <!-- <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">

    </div> -->

    <ng-container>
      <div class="product-plan-details">
        <div class="column1" *ngIf="productPlanNames.productPlan">
          Product | Plan <br />
          <span class="column-additional">
            <span
              [toolTipInteractive]="true"
              [toolTipCloseOnClick]="true"
              cc-tooltip="Product_Plan"
              [tooltipPosition]="'bottom'"
              [isEllipseEnabled]="true"
            >
              <span class="column-content">
                {{ accountDataInput?.appName }}
                | {{ accountDataInput?.subsDtl ? accountDataInput?.subsDtl?.name : '' }}
              </span>
              <!-- <ng-template #product_plan_tooltip>
                <span
                  >{{ accountDataInput?.appName }} |
                  {{ accountDataInput?.subsDtl ? accountDataInput?.subsDtl?.name : '' }}<br
                /></span>
              </ng-template> -->
            </span>
          </span>
        </div>
        <div class="column1" *ngIf="productPlanNames.prefix">
          Prefix <br />
          <span class="column-additional">
            <span class="column-content">{{ accountDataInput?.assetPrefix || '-' }}</span>
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.startDate">
          Start Date <br />
          <span class="column-additional">
            <span
              class="column-content"
              *ngIf="accountDataInput?.startDate?.length > 0; else else_content"
              cc-tooltip
              [tooltipPosition]="'top'"
              [template]="parseEndDatepInfoTooltipTemplate"
              [innerHTML]="parseEndDate(accountDataInput?.startDate)"
            >
            </span>
          </span>
        </div>
        <div class="column1" *ngIf="productPlanNames.endDate">
          End Date <br />
          <span class="column-additional">
            <span
              class="column-content"
              *ngIf="accountDataInput?.endDate?.length > 0; else else_content"
              cc-tooltip
              [tooltipPosition]="'top'"
              [template]="parseEndDatepInfoTooltipTemplate"
              [innerHTML]="parseEndDate(accountDataInput?.endDate)"
            >
            </span>
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.term">
          Term <br />
          <span class="column-additional">
            <span class="column-content" *ngIf="accountDataInput.store === 'DSP'">{{ 'N/A' }}</span>

            <span
              class="column-content"
              *ngIf="accountDataInput.store !== 'DSP'"
              cc-tooltip
              [tooltipPosition]="'top'"
              [template]="termInfoTooltipTemplate"
              >{{ accountDataInput.subscriptionTerm || 'N/A' }}
            </span>
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.autoRenew">
          Auto Renew <br />
          <span class="column-additional">
            <span class="column-content" *ngIf="accountDataInput.store === 'DSP'">{{ 'N/A' }}</span>
            <span
              class="column-content"
              *ngIf="accountDataInput.store !== 'DSP' && accountDataInput?.isAutoRenewed === false"
              >{{ 'OFF' }}</span
            >
            <span
              class="column-content"
              *ngIf="accountDataInput.store !== 'DSP' && accountDataInput?.isAutoRenewed === true"
              >{{ 'ON' }}</span
            >
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.store">
          Store <br />
          <span class="column-additional">
            <span class="column-content" *ngIf="accountDataInput.type === 'Dealer'">{{ 'DSP' }}</span>
            <span
              class="column-content"
              *ngIf="
                (accountDataInput.type === 'Customer' || accountDataInput.type === 'User') && accountDataInput.store;
                else else_content
              "
              cc-tooltip
              [tooltipPosition]="'top'"
              [template]="storeInfoTooltipTemplate"
              >{{ accountDataInput.store || '-' }}
            </span>
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.saleReason">
          Sale Reason <br />
          <span class="column-additional">
            <span
              class="column-content sale_reason"
              *ngIf="accountDataInput.salesReason; else else_content"
              cc-tooltip
              [tooltipPosition]="'top'"
              [template]="saleReasonInfoTooltipTemplate"
              >{{ accountDataInput.salesReason || '-' }}
            </span>
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.dealerCode">
          Dealer Code <br />
          <span class="column-additional">
            <span [toolTipInteractive]="true" [toolTipCloseOnClick]="true" [tooltipPosition]="'bottom'">
              <span class="column-content">{{ accountDataInput.dealerCode }}</span> </span
            ><br />
          </span>
        </div>

        <div class="column1" *ngIf="productPlanNames.customerAccount">
          Customer Account <br />
          <span class="column-additional">
            <span
              [toolTipInteractive]="true"
              [toolTipCloseOnClick]="true"
              cc-tooltip="Customer"
              [tooltipPosition]="'bottom'"
              [isEllipseEnabled]="true"
            >
              <span class="column-content">
                {{ accountDataInput.ucid }}
                - {{ accountDataInput.ucName }}
              </span> </span
            ><br />
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="cc-grid view" *ngIf="planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>ACCOUNT DETAILS</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
      <div class="detail-column-header">
        <span> Dealer Customer</span> <br />
        <div
          class="column-content"
          cc-tooltip="dealer_name_tooltip"
          [toolTipInteractive]="true"
          [toolTipCloseOnClick]="true"
          [tooltipPosition]="'top'"
          [template]="dealer_name_tooltip"
        >
          <div [innerHTML]="parseUcNameData(accountDataInput.dealerName)"></div>

          <div>{{ '-' }}{{ accountDataInput.dealerCode }}</div>
        </div>
      </div>
    </div>
    <ng-template #dealer_name_tooltip>
      <div *ngIf="accountDataInput.dealerName.length > 19" style="width: 220px; white-space: normal">
        <span> {{ accountDataInput.dealerName }} - {{ accountDataInput.dealerCode }}</span>
      </div>
    </ng-template>
    <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
      <div class="detail-column-header">
        <span> Customer </span>
        <br />
        <div
          class="column-content"
          cc-tooltip
          [toolTipInteractive]="true"
          [toolTipCloseOnClick]="true"
          [tooltipPosition]="'top'"
          [disabled]="accountDataInput.ucName.length > 19 ? false : true"
          [template]="customer_name_tooltip"
        >
          <div [innerHTML]="parseUcNameData(accountDataInput.ucName)"></div>

          <div>{{ '-' }}{{ accountDataInput.ucid }}</div>
        </div>
      </div>
    </div>
    <ng-template #customer_name_tooltip>
      <div *ngIf="accountDataInput.ucName.length > 19" style="width: 234px; white-space: normal">
        <span> {{ accountDataInput.ucName }} - {{ accountDataInput.ucid }}</span>
      </div>
    </ng-template>
    <div class="cc-col cc-lg-col--3 cc-md-col--3 cc-xl-col--3 cc-sm-col--3 cc-xs-col--3">
      <div class="detail-column-header">
        Prefix <br />
        <span class="column-content">{{ accountDataInput.assetPrefix || '-' }}</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5">
      <div class="detail-column-header">
        Product | Plan <br />
        <span class="view-detail-product-plan-column">
          <span class="column-content">{{ accountDataInput?.appName }} | {{ accountDataInput?.subsDtl?.name }}</span>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--4">
      <div class="detail-column-header">
        CWS ID <br />
        <div class="column-content">
          <div [innerHTML]="parseAccountCwsIDComponent(accountDataInput)"></div>
          <div>
            <a
              class="account-link"
              *ngIf="accountDataInput.users?.length > 1"
              (click)="openDrawerInMultiSize('drawerContent', accountDataInput.users)"
            >
              See more
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cc-grid view" *ngIf="planConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_CUSTOMER">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
      <div class="details-title">
        <span>ACCOUNT DETAILS</span>
        <cc-icon cc-tooltip [template]="tooltipTemplate" [tooltipPosition]="'top'" class="view-details-icon-size"
          >info-tooltip</cc-icon
        >
        <ng-template #tooltipTemplate>
          <div
            [style]="
              viewSubsTooltipContent.length > 0
                ? 'height: 62px;
            width: 156.6316375732422px;
            left: -0.5px;
            top: 0px;
            border-radius: 5px;
             white-space: normal'
                : ''
            "
          >
            <span>{{ viewSubsTooltipContent }}<br /></span>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5">
      <div class="detail-column-header">
        <span> Customer </span> <br />
        <div
          class="column-content"
          cc-tooltip
          [toolTipInteractive]="true"
          [toolTipCloseOnClick]="true"
          [tooltipPosition]="'top'"
          [disabled]="accountDataInput.ucName.length > 19 ? false : true"
          [template]="customer1_name_tooltip"
        >
          <div [innerHTML]="parseUcNameData(accountDataInput.ucName)"></div>

          <div *ngIf="accountDataInput?.ucid">{{ '-' }}{{ accountDataInput.ucid }}</div>
        </div>
      </div>
    </div>

    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5">
      <div class="detail-column-header">
        Product | Plan <br />
        <span class="view-detail-product-plan-column">
          <span class="column-content">{{ accountDataInput?.appName }} | {{ accountDataInput?.subsDtl?.name }}</span>
        </span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2">
      <div class="detail-column-header">
        Prefix <br />

        <span class="column-content">{{ accountDataInput.assetPrefix || '-' }}</span>
      </div>
    </div>
    <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
      <div class="detail-column-header">
        CWS ID <br />
        <div class="column-content">
          <div [innerHTML]="parseAccountCwsIDComponent(accountDataInput)"></div>
          <div>
            <a
              class="account-link"
              *ngIf="accountDataInput.users?.length > 1"
              (click)="openDrawerInMultiSize('drawerContent', accountDataInput.users)"
            >
              See more
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #else_content
    ><span class="column-content">{{ '-' }}</span></ng-template
  >
  <ng-template #productPlanInfoTooltipTemplate>
    <div
      [style]="
        accountDataInput?.subsDtl?.name && accountDataInput?.subsDtl?.name.length > 15
          ? 'width: 220px; white-space: normal;'
          : ''
      "
    >
      <span>{{ accountDataInput?.appName }} | {{ accountDataInput?.subsDtl?.name }}<br /></span>
    </div>
  </ng-template>
  <ng-template #parseEndDatepInfoTooltipTemplate>
    <div [style]="accountDataInput?.endDate ? 'width: 100px; white-space: normal;' : ''">
      <span>{{ parseEndDate(accountDataInput?.endDate) }}<br /></span>
    </div>
  </ng-template>
  <ng-template #termInfoTooltipTemplate>
    <div [style]="accountDataInput?.subscriptionTerm ? 'width: 100px; white-space: normal;' : ''">
      <span>{{ accountDataInput?.subscriptionTerm }}<br /></span>
    </div>
  </ng-template>
  <ng-template #storeInfoTooltipTemplate>
    <div [style]="accountDataInput?.store ? 'width: 100px; white-space: normal;' : ''">
      <span>{{ accountDataInput?.store }}<br /></span>
    </div>
  </ng-template>
  <ng-template #saleReasonInfoTooltipTemplate>
    <div [style]="accountDataInput?.salesReason ? 'width: 220px; white-space: normal;' : ''">
      <span>{{ accountDataInput?.salesReason }}<br /></span>
    </div>
  </ng-template>

  <ng-template #customer1_name_tooltip>
    <div [style]="accountDataInput.ucName.length > 19 ? 'width: 185px; white-space: normal;' : ''">
      <span> {{ getCustomerTooltipData(accountDataInput.ucName, accountDataInput.ucid) }}</span>
    </div>
  </ng-template>
</div>
