import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { DspCommonService } from '../../../services/dsp-common.service';
import { HomeService } from '../services/home.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as dspConstants from '../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import {
  getAssetsCountPayload,
  getAssetsPayload,
  getCustomersCountPayload,
  getCustomersPayload,
} from '../home.interface';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetDrawerComponent } from '../../assets/asset-drawer/asset-drawer.component';
import { AssetService } from '../../assets/services/asset.service';
import { ManagePlanComponent } from '../../assets/manage-plan/manage-plan.component';
import { ManageAssetSubscriptionComponent } from '../../assets/manage-asset-subscription/manage-asset-subscription.component';
import { EditAssetSerialNumberComponent } from '../../assets/asset-drawer/edit-asset-serial-number/edit-asset-serial-number.component';
import { DeviceStatusComponent } from '../../device-status/device-status.component';
import { ConfigureWifiComponent } from '../../assets/asset-drawer/configure-wifi/configure-wifi.component';
import { isEmpty } from 'lodash-es';
import { ManagePendingRequestComponent } from '../../manage-pending-request/manage-pending-request.component';
import { getFeaturesToggleMapping } from '../../../store/actions/dspcommon.action';

@Component({
  selector: 'dsp-next-gen-ui-home-table',
  templateUrl: './home-table.component.html',
  styleUrls: ['./home-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeTableComponent implements OnInit, OnChanges {
  @Input() noResultInAdvanceSearch!: string;
  @Input() homeSearchVal!: string;
  @Input() toggleSearch!: boolean;
  @Output() isSkeletonLoading = new EventEmitter();
  private destroy = new Subject();
  assetDrawerRef!: any;
  editAssetSerialNumber!: any;
  wifiConfigurationRef!: any;
  focussedItem: { id: string; focus: boolean } | undefined;
  dspWorklistConstants = dspConstants.Worklist;
  dspHomeConstants = dspConstants.Home;
  tabsList: any = [];
  // public tabsList = [
  //   { label: 'Assets', value: 'assets-tab' },
  //   { label: 'Customers', value: 'customers-tab' },
  // ];
  dspStoreData: any;
  radioStates: any;
  public connectionStatus: any = [];
  assetConnectionCheck: Array<object> = [];
  lastCheckinValue: any;
  currentSearchValue = '';
  public selectedTab = 'Assets';
  isVirtualScrollTriggered = false;
  isVirtualScrollTriggeredCustomer = false;
  assetsScrollPosition = 0;
  customersScrollPosition = 0;
  assetsCount: any = { count: 0 };
  assetsData: { assets: any[] } = { assets: [] };
  customerData: { customers: any[] } = { customers: [] };
  assetIds: number[] = [];
  hasFetchedCount = false;
  hasFetchedCustomerCount = false;
  dealer: any;
  dealerCode: any;
  getAssetSearchPayload: getAssetsPayload = {
    loggedInDealerCode: '',
    searchText: '',
    limit: 20,
    offset: 0,
    sortColumn: 'serialNumber',
    sortOrder: 'asc',
  };
  getAssetSearchCountPayload: getAssetsCountPayload = {
    loggedInDealerCode: '',
    searchText: '',
  };
  getCustomerSearchPayload: getCustomersPayload = {
    loggedInDealerCode: '',
    searchText: '',
    limit: 20,
    offset: 0,
    sortColumn: 'ucidName',
    sortOrder: 'asc',
  };
  getCustomerSearchCountPayload: getCustomersCountPayload = {
    loggedInDealerCode: '',
    searchText: '',
  };
  customerCount: any = {};
  isAssetLoading = false;
  isCustomerLoading = false;
  featureSupportMapping: any;
  offset = 0;
  limit = 20;
  basicSortingTableConfig1 = {
    height: '400',
    stickyHeader: false,
    expandable: false,
    isSortEnable: false,
    isTheme: '2.0',
  };
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isMultiProductWorkFlowEnabled = false;
  manageAssetPlanDrawerRef: any;
  searchAssetAPICompleted = false;
  searchAssetCountAPICompleted = false;
  searchCustomerAPICompleted = false;
  searchCustomerCountAPICompleted = false;
  isSecondRowLoading = false;
  queueReasonList: any;
  isSelfServeSubscription: any;
  mySubscription: Subscription | undefined;
  featuresToggleMap: any;
  deviceFeaturesInfo: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private homeService: HomeService,
    private dspCommonService: DspCommonService,
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar,
    private drawerService: DrawerService,
    public router: Router,
    private assetService: AssetService
  ) {}
  ngOnInit(): void {
    this.radioStates = ['active', 'default'];
    this.selectedTab = 'Assets';
    this.getFeaturesToggleMapping();
  }
  setPayloadValues() {
    this.getAssetSearchPayload.loggedInDealerCode = this.dealerCode;
    this.getAssetSearchCountPayload.loggedInDealerCode = this.dealerCode;
    this.getCustomerSearchPayload.loggedInDealerCode = this.dealerCode;
    this.getCustomerSearchCountPayload.loggedInDealerCode = this.dealerCode;
    this.getAssetSearchPayload.offset = 0;
    this.getCustomerSearchPayload.offset = 0;
    this.getAssetSearchPayload.searchText = this.homeSearchVal.trim();
    this.getAssetSearchCountPayload.searchText = this.homeSearchVal.trim();
    this.getCustomerSearchPayload.searchText = this.homeSearchVal.trim();
    this.getCustomerSearchCountPayload.searchText = this.homeSearchVal.trim();
  }

  goToAssetsPage(item: any) {
    this.homeService.setServiceData(item);
    const navigationExtras: NavigationExtras = {
      state: {
        currentPage: 'Home',
      },
    };
    this.router.navigate(['/asset'], navigationExtras);
    const element: HTMLElement = document.getElementsByClassName('arrow-position')[0] as HTMLElement;
    element.click();
  }
  ngOnChanges(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.featureSupportMapping = this.dspStoreData.featureSupportMapping;
      this.isMultiProductWorkFlowEnabled = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
      this.isSelfServeSubscription = this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        ? this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        : false;
      this.queueReasonList = dsp.dspConfig?.Queue_Reason_List ? JSON.parse(dsp.dspConfig.Queue_Reason_List) : [];
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
    });
    this.tabsList = [
      { label: 'Assets', value: 'Assets' },
      { label: 'Customers', value: 'Customers' },
    ];
    this.dealer = fetchLoggedInDealerDetails();
    this.dealerCode = this.dealer.dealerCode;
    this.setPayloadValues();
    if (this.noResultInAdvanceSearch.toUpperCase() === 'TRUE') {
      this.assetsData.assets = [];
      this.customerData.customers = [];
      this.assetsCount = { count: 0 };
      this.customerCount = { count: 0 };
      this.setChipCount();
    } else if (this.noResultInAdvanceSearch === '') {
      this.isAssetLoading = true;
      this.isCustomerLoading = true;
      this.isSkeletonLoading.emit(this.isAssetLoading);
      this.handleMessageChange();
      this.setChipCount();
    } else {
      this.setChipCount();
    }
    this.getFeaturesToggleMapping();
  }
  handleMessageChange(): void {
    this.searchAssetAPICompleted = false;
    this.searchAssetCountAPICompleted = false;
    this.searchCustomerAPICompleted = false;
    this.searchCustomerCountAPICompleted = false;
    this.assetsData.assets = [];
    this.customerData.customers = [];
    this.assetsCount = { count: 0 };
    this.customerCount = { count: 0 };
    this.hasFetchedCount = false;
    this.hasFetchedCustomerCount = false;
    this.getAssets();
    this.getCustomers();
  }
  checkIfAPICompleted() {
    if (
      (this.searchAssetAPICompleted && this.searchAssetCountAPICompleted) ||
      (this.searchCustomerAPICompleted && this.searchCustomerCountAPICompleted)
    ) {
      this.setChipCount();
    }
  }
  getAssets() {
    this.homeService.getAssets(this.getAssetSearchPayload).subscribe({
      next: (assets: any) => {
        this.searchAssetAPICompleted = true;
        const response = assets?.assets;
        this.assetsData.assets = [...this.assetsData.assets, ...response];
        if (!this.hasFetchedCount) {
          this.getAssetSearchCount();
          this.hasFetchedCount = true;
        }
        if (this.isVirtualScrollTriggered) {
          this.isAssetLoading = false;
        }
        this.isVirtualScrollTriggered = false;
        this.getAssetsApplicableMenus();
        this._changeDetectorRef.detectChanges();
      },
      error: (err: any) => {
        this.searchAssetAPICompleted = true;
        this.assetsData.assets = [];
        this.assetsCount = { count: 0 };
        this.isAssetLoading = false;
        this.showToastMessage(this.systemError, 'error');
        this.checkIfAPICompleted();
      },
    });
  }
  getAssetSearchCount() {
    this.homeService.getAssetsCount(this.getAssetSearchCountPayload).subscribe(
      (assetsCount: any) => {
        this.searchAssetCountAPICompleted = true;
        this.assetsCount = assetsCount;
        this.isAssetLoading = false;
        this.checkIfAPICompleted();
      },
      (error: any) => {
        if (this.assetsData?.assets) {
          this.assetsCount = { count: this.assetsData?.assets?.length ? this.assetsData?.assets?.length : 0 };
        } else {
          this.showToastMessage(this.systemError, 'error');
          this.assetsData.assets = [];
          this.assetsCount = {
            count: 0,
          };
        }
        this.isAssetLoading = false;
        this.searchAssetCountAPICompleted = true;
        this.checkIfAPICompleted();
      }
    );
  }
  onScrollAssets(event: any) {
    this.isVirtualScrollTriggered = true;
    const scrollPosition = event.target.scrollTop + event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    this.assetsScrollPosition = event.target.scrollTop;
    if (
      scrollPosition >= scrollHeight - 10 &&
      this.assetsData.assets.length < this.assetsCount.count &&
      !this.isAssetLoading &&
      this.selectedTab == 'Assets'
    ) {
      this.loadMoreAssets();
    } else {
      this.isVirtualScrollTriggered = false;
    }
  }
  onScrollCustomers(event: any) {
    this.isVirtualScrollTriggeredCustomer = true;
    const scrollPosition = event.target.scrollTop + event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    this.customersScrollPosition = event.target.scrollTop;
    if (
      scrollPosition >= scrollHeight - 10 &&
      this.customerData.customers.length < this.customerCount.count &&
      !this.isCustomerLoading &&
      this.selectedTab == 'Customers'
    ) {
      this.loadMoreCustomers();
    } else {
      this.isVirtualScrollTriggeredCustomer = false;
    }
  }
  loadMoreCustomers() {
    this.isVirtualScrollTriggeredCustomer = true;
    this.getCustomerSearchPayload.offset += this.getCustomerSearchPayload.limit;
    this.getCustomers();
  }
  loadMoreAssets() {
    this.isVirtualScrollTriggered = true;
    this.getAssetSearchPayload.offset += this.getAssetSearchPayload.limit;
    this.getAssets();
  }
  getCustomers() {
    this.homeService.getCustomers(this.getCustomerSearchPayload).subscribe(
      (customers: any) => {
        this.searchCustomerAPICompleted = true;
        const response = customers?.customers;
        this.customerData.customers = [...this.customerData.customers, ...response];
        if (!this.hasFetchedCustomerCount) {
          this.getCustomerSearchCount();
          this.hasFetchedCustomerCount = true;
        }
        if (this.isVirtualScrollTriggeredCustomer) {
          this.isCustomerLoading = false;
        }
        this.isVirtualScrollTriggeredCustomer = false;
      },
      (error: any) => {
        this.searchCustomerAPICompleted = true;
        this.isCustomerLoading = false;
        this.customerData.customers = [];
        this.customerCount = { count: 0 };
        this.showToastMessage(this.systemError, 'error');
        this.checkIfAPICompleted();
      }
    );
  }
  getCustomerSearchCount() {
    this.homeService.getCustomerCount(this.getCustomerSearchCountPayload).subscribe(
      (customerCount: any) => {
        this.searchCustomerCountAPICompleted = true;
        this.customerCount = customerCount;
        this.isCustomerLoading = false;
        this.checkIfAPICompleted();
      },
      (error: any) => {
        if (this.customerData?.customers) {
          this.customerCount = {
            count: this.customerData?.customers?.length ? this.customerData?.customers?.length : 0,
          };
        } else {
          this.showToastMessage(this.systemError, 'error');
          this.customerData.customers = [];
          this.customerCount = {
            count: 0,
          };
        }
        this.isCustomerLoading = false;
        this.searchCustomerCountAPICompleted = true;
        this.checkIfAPICompleted();
      }
    );
  }
  getAssetsApplicableMenus() {
    this.isSecondRowLoading = true;
    if (this.assetsData?.assets.length > 0) {
      this.assetIds = this.assetsData?.assets?.map((asset: { assetId: any }) => asset.assetId);
      const payload = {
        loggedInDealerCode: this.dealerCode,
        assetIds: this.assetIds,
      };
      this.homeService.checkValidOwnership(payload).subscribe({
        next: (result: any) => {
          setTimeout(() => {
            this.isSecondRowLoading = false;
          }, 3000);
          this.assetsData?.assets?.forEach((asset: any) => {
            const assetId = asset.assetId.toString();
            if (result[assetId]) {
              this.getSupportedServices(asset, result[assetId]);
              this.fetchDealerDetails(asset, assetId);
            }
          });
        },
        error: (_err: any) => {
          const messageText = 'There was an error processing your request.Please contact Cat Digital Support';
          this.showToastMessage(messageText, 'error');
        },
      });
    }
  }

  fetchDealerDetails(asset: any, assetId: any) {
    const dealerInfoPayload = {
      assetIds: [assetId] || [],
      dealerCode: this.dealer?.dealerCode || '',
    };
    this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
      next: (result: any) => {
        if (result) {
          asset.dealerInfo = result;
          asset.isCustRequestedSubscription =
            this.assetService.checkIfReasonListExists(this.queueReasonList, result[assetId]) || false;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  getSupportedServices(asset: any, validOwnershipRes: any) {
    const supportedServicesPayload = { guiID: asset?.appId || '' };
    this.assetDrawerService.getSupportedServices(supportedServicesPayload).subscribe({
      next: (result: any) => {
        let data: any[] = [];
        if (result?.length > 0) {
          asset.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
          result?.forEach((val: any) => {
            if (this.featureSupportMapping && this.featureSupportMapping[val]) {
              data = data.concat(this.featureSupportMapping[val]);
            }
          });
        }
        asset.getSupportedServicesList = data;
        this.homeService.getScrollableMenu(asset, validOwnershipRes);
      },
      error: (err: any) => {
        this.homeService.getScrollableMenu(asset, validOwnershipRes);
      },
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  changeSelected(event: any, selectedTabName: any, i: number) {
    this.selectedTab = selectedTabName;
    this.radioStates.forEach((item: any, index: any) => {
      this.radioStates[index] = i === index && event ? 'active' : 'default';
    });
  }
  tableEvents(event: any) {}
  /**
   * Handles the scroll event of the element.
   * Triggers an action when the scroll reaches the bottom of the container.
   * @param {Event} event - The scroll event object.
   * @returns {void}
   */

  /**
   * Disables scrolling.
   */
  disable_scroll() {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  }
  /**
   * Enable scrolling.
   * This function removes the event listener that prevents scrolling.
   */
  enable_scroll() {
    document.ontouchmove = function (_e) {
      return true;
    };
  }
  /**
   * Emit an event when the scroll reaches the bottom of the container for infinite scrolling.
   */
  public emitInfiniteScrollEvent(): void {
    this.offset = this.offset + 1;
  }
  setChipCount() {
    if (!this.isAssetLoading && !this.isCustomerLoading) {
      this.tabsList?.forEach((tab: any) => {
        if (tab.label == this.dspHomeConstants.ASSETS) {
          tab.value = tab.label + ' (' + this.assetsCount?.count + ') ';
        } else if (tab.label == this.dspHomeConstants.CUSTOMERS) {
          tab.value = tab.label + ' (' + this.customerCount?.count + ') ';
        }
      });
      if (this.assetsData?.assets?.length > 0 || (this.assetsCount?.count == 0 && this.customerCount?.count == 0)) {
        this.radioStates = ['active', 'default'];
        this.selectedTab = this.tabsList[0].label;
      } else if (this.customerData?.customers?.length > 0 && this.noResultInAdvanceSearch === '') {
        this.radioStates = ['default', 'active'];
        this.selectedTab = this.tabsList[1].label;
      } else {
        this.radioStates = ['active', 'default'];
        this.selectedTab = this.tabsList[0].label;
      }
    } else {
      this.tabsList?.forEach((tab: any) => {
        if (tab.label == this.dspHomeConstants.ASSETS) {
          tab.value = tab.label;
        } else if (tab.label == this.dspHomeConstants.CUSTOMERS) {
          tab.value = tab.label;
        }
      });
    }
    this.isSkeletonLoading.emit(this.isAssetLoading);
  }
  openAssetDrawer(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: rowData,
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.assetDrawerRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.getAssetSearchPayload = {
          loggedInDealerCode: this.dealerCode,
          searchText: this.homeSearchVal,
          limit: 20,
          offset: 0,
          sortColumn: 'serialNumber',
          sortOrder: 'asc',
        };
        this.getAssetSearchCountPayload = {
          loggedInDealerCode: this.dealerCode,
          searchText: this.homeSearchVal,
        };
        this.hasFetchedCount = false;
        this.isAssetLoading = true;
        this.assetsData.assets = [];
        this.getAssets();
      }

      // let savedConnectionStatus = this.assetService.getConnectionStatus();
      // this.getSavedConnectionCheckStatus(rowData.make, rowData.serialNumber, savedConnectionStatus);
      // if (response) {
      //   this.customData.refreshTable();
      // }
    });
  }

  openManagePlan(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    if (rowData.isCustRequestedSubscription && this.isSelfServeSubscription) {
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePendingRequestComponent, CcDrawerConfig>(
        ManagePendingRequestComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: rowData ? rowData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: true,
          id: 'multiSizeDrawer',
          disableBackdropClick: false,
          drawerSize: CcDrawerSize.LARGE,
        }
      );

      this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
        if (response) {
          this.managePlanAfterClosed();
        }
      });
    } else {
      if (this.isMultiProductWorkFlowEnabled) {
        this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePlanComponent, CcDrawerConfig>(
          ManagePlanComponent,
          {
            data: {
              type: 'LANDING',
              selectedAssetDetails: rowData ? rowData : {},
            },
            enableBlackBackDrop: true,
            closeOnEsc: true,
            id: 'multiSizeDrawer',
            disableBackdropClick: false,
            drawerSize: CcDrawerSize.LARGE,
          }
        );
        this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
          if (response) {
            this.managePlanAfterClosed();
          }
        });
      } else {
        this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManageAssetSubscriptionComponent, CcDrawerConfig>(
          ManageAssetSubscriptionComponent,
          {
            data: {
              type: 'LANDING',
              selectedAssetDetails: rowData ? rowData : {},
            },
            enableBlackBackDrop: true,
            closeOnEsc: false,
            id: 'multiSizeDrawer',
            disableBackdropClick: true,
            drawerSize: CcDrawerSize.LARGE,
          }
        );
        this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
          if (response) {
            this.managePlanAfterClosed();
          }
        });
      }
    }
  }

  managePlanAfterClosed() {
    this.getAssetSearchPayload = {
      loggedInDealerCode: this.dealerCode,
      searchText: this.homeSearchVal,
      limit: 20,
      offset: 0,
      sortColumn: 'serialNumber',
      sortOrder: 'asc',
    };
    this.getAssetSearchCountPayload = {
      loggedInDealerCode: this.dealerCode,
      searchText: this.homeSearchVal,
    };
    this.hasFetchedCount = false;
    this.isAssetLoading = true;
    this.assetsData.assets = [];
    this.getAssets();
  }
  openEditAssetSerialNumber(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.editAssetSerialNumber = this.drawerService.openDrawer<EditAssetSerialNumberComponent, CcDrawerConfig>(
      EditAssetSerialNumberComponent,
      {
        data: {
          rowData: rowData,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.editAssetSerialNumber.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getAssetSearchPayload = {
          loggedInDealerCode: this.dealerCode,
          searchText: this.homeSearchVal,
          limit: 20,
          offset: 0,
          sortColumn: 'serialNumber',
          sortOrder: 'asc',
        };
        this.getAssetSearchCountPayload = {
          loggedInDealerCode: this.dealerCode,
          searchText: this.homeSearchVal,
        };
        this.hasFetchedCount = false;
        this.isAssetLoading = true;
        this.assetsData.assets = [];
        this.getAssets();
      }
    });
  }
  openDeviceStatus(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.drawerService.openDrawer<DeviceStatusComponent, CcDrawerConfig>(DeviceStatusComponent, {
      data: { type: 'LANDING', assetInfo: rowData },
      enableBlackBackDrop: true,
      id: 'deviceStatusDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }
  openWifiConfiguration(rowData: any) {
    this.saveRecentlyViewAssets(rowData);
    this.wifiConfigurationRef = this.drawerService.openDrawer<ConfigureWifiComponent, CcDrawerConfig>(
      ConfigureWifiComponent,
      {
        data: {
          titleNames: 'Configure Wifi',
          data: rowData,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  getCustomerData(item: any) {
    if (!isEmpty(item?.ucidName) && !isEmpty(item?.ucid)) {
      return item.ucidName + ' - ' + item.ucid;
    } else if (!isEmpty(item?.ucid)) {
      return item.ucid;
    } else if (!isEmpty(item?.ucidName)) {
      return item.ucidName;
    } else return '-';
  }
  getDealerCustomerData(item: any) {
    if (!isEmpty(item?.dcnName) && !isEmpty(item?.dealerCustomerNumber)) {
      return item.dcnName + ' - ' + item.dealerCustomerNumber;
    } else if (!isEmpty(item?.dcnName)) {
      return item.dcnName;
    } else if (!isEmpty(item?.dealerCustomerNumber)) {
      return item.dealerCustomerNumber;
    } else return '-';
  }
  getFeaturesToggleMapping() {
    if (isEmpty(this.dspStoreData?.featuresToggleMapping)) {
      this.assetService.getFeaturesToggleMapping().subscribe({
        next: (result: any) => {
          this.store.dispatch(getFeaturesToggleMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  saveRecentlyViewAssets(rowData: any) {
    let payload = {
      make: rowData?.make,
      serialNumber: rowData?.serialNumber,
      loggedInDealerCode: this.dealerCode,
      assetId: rowData?.assetId,
    };
    this.homeService.saveRecentlyViewAssets(payload).subscribe({
      next: (response: any) => {},
      error: (err: any) => {},
    });
  }
  // getSavedConnectionCheckStatus(make: any, serialNumber: any, status: Array<any>) {
  //   if (status?.length > 0) {
  //     status?.forEach((value: any) => {
  //       value.forEach((val: any) => {
  //         if (val.eq_make === make && val.eq_sn === serialNumber) {
  //           this.connectionStatus = [val];
  //           this._changeDetectorRef.detectChanges();
  //           if (this.connectionStatus && this.connectionStatus[0]?.connection_status === 'pending') {
  //             this.checkConnectionStatus(make, serialNumber);
  //           }
  //         }
  //       });
  //     });
  //   }
  // }
  // checkConnectionStatus(make: any, serialNumber: any) {
  //   const payload = { eq_make: make, eq_sn: serialNumber };
  //   this.assetService
  //     .getConnectionCheckStatus(payload)
  //     .pipe(
  //       expand((result: any) => {
  //         if (
  //           result &&
  //           result.remote_connection &&
  //           result.remote_connection[0] &&
  //           result.remote_connection[0].connection_status === 'pending'
  //         ) {
  //           this.connectionStatus = result.remote_connection;
  //           this.assetConnectionCheck.push(this.connectionStatus);
  //           sessionStorage.setItem('Assets', JSON.stringify(this.assetConnectionCheck));
  //           this.assetService.setConnectionStatus(this.connectionStatus);
  //           return this.assetService.getConnectionCheckStatus(payload).pipe(delay(30000));
  //         } else {
  //           this.connectionStatus = result && result.remote_connection;
  //           sessionStorage.setItem('Assets', '');
  //           this._changeDetectorRef.detectChanges();
  //           return '';
  //         }
  //       }),
  //       takeLast(1)
  //     )
  //     .subscribe({
  //       next: (result: any) => {
  //         if (Object.keys(result).length > 0) {
  //           this.connectionStatus = result && result.remote_connection;

  //           if (this.connectionStatus[0].connection_status == 'success') {
  //             this.assetConnectionCheck.push(this.connectionStatus);
  //             this.lastCheckinValue = this.getLastCheckInStatus(make, serialNumber);
  //           } else {
  //             this.assetConnectionCheck.push(this.connectionStatus);
  //           }

  //           this.assetService.setConnectionStatus(this.connectionStatus);
  //           this._changeDetectorRef.detectChanges();
  //         }
  //       },
  //       error: () => {
  //         this.connectionStatus = [
  //           {
  //             eq_make: make,
  //             eq_sn: serialNumber,
  //             connection_status: 'failure',
  //           },
  //         ];
  //         this.assetConnectionCheck.push(this.connectionStatus);
  //         this.assetService.setConnectionStatus(this.connectionStatus);
  //         this.showToastMessage(this.systemError, 'error');
  //       },
  //     });
  // }
  // getLastCheckInStatus(make: any, serialNumber: any) {
  //   const payloadObj = {
  //     make: make,
  //     serialNumber: serialNumber,
  //   };
  //   const deviceStatusPayload = this.isEnableForwardSlashInPID ? payloadObj : make + '/' + serialNumber;
  //   this.dspCommonService.getDeviceStatus(deviceStatusPayload).subscribe({
  //     next: (result: any) => {
  //       this.isDeviceStatusAPIInProgress = false;
  //       this.changeDetector.detectChanges();
  //       this.lastCheckinInfo = result;
  //       if (this.lastCheckinInfo?.lastCheckIn) {
  //         this.lastCheckinDateAndTimeArray = this.lastCheckinInfo?.lastCheckIn?.split(' ');
  //         if (this.isTimeZoneSelectionEnabled) {
  //           let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
  //             this.lastCheckinDateAndTimeArray[0] + ' ' + this.lastCheckinDateAndTimeArray[1],
  //             true
  //           );
  //           let lastCheckIn = convertedTime;
  //           if (this.isTimeFormatEnabled) {
  //             this.lastCheckinValue =
  //               this.userPreferenceService.convertDateTime(
  //                 lastCheckIn?.split(' ')[0] + ' ' + lastCheckIn?.split(' ')[1]
  //               ) +
  //               ' ' +
  //               convertedTime?.split(' ')[2];
  //           } else {
  //             this.lastCheckinValue = lastCheckIn;
  //           }
  //         } else {
  //           this.lastCheckinValue = `${this.lastCheckinDateAndTimeArray[0]}  ${this.lastCheckinDateAndTimeArray[1]} ${this.lastCheckinDateAndTimeArray[2]}`;
  //         }
  //         this.changeDetector.detectChanges();
  //       }
  //     },

  //     error: (err: any) => {
  //       this.isDeviceStatusAPIInProgress = false;
  //       this.changeDetector.detectChanges();
  //       this.lastCheckinValue = '';
  //     },
  //   });
  //   return this.lastCheckinValue || '';
  // }
}
