import { Component, Input, OnInit } from '@angular/core';
import { Common, OrgSubs } from 'apps/dsp-ui/src/app/shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-subscription-note',
  templateUrl: './subscription-note.component.html',
  styleUrls: ['./subscription-note.component.scss'],
})
export class SubscriptionNoteComponent implements OnInit {
  @Input() planType: any;
  subscribeAccountsConst: any;

  // constructor() { }
  ngOnInit(): void {
    this.subscribeAccountsConst = { ...OrgSubs, ...Common };
  }
}
