import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'convertOwnership' })
export class OwnershipFormat implements PipeTransform {
  transform(ownership: any): any {
    switch (ownership) {
      case 'X': {
        return 'Unknown';
      }
      case 'R': {
        return 'Rented';
      }
      case 'O': {
        return 'Owned';
      }
      case 'L': {
        return 'Leased';
      }
      case 'N': {
        return 'Inventory';
      }
      default: {
        return '-';
      }
    }
  }
}
