import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CC_MODAL_DATA,
  CcModalRef,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { BillingService } from '../../services/billing.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';

@Component({
  selector: 'dsp-next-gen-ui-billing-inquiry',
  templateUrl: './billing-inquiry.component.html',
  styleUrls: ['./billing-inquiry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BillingInquiryComponent implements OnInit {
  billingInquiryForm: FormGroup = new FormGroup({});
  isTheme = '2.0';
  dialogRef: any;
  billingForm: any;
  user: any;
  userName: any;
  dealerCode: any;
  billingInquiryPayload: any;
  inquiryType: any;

  constructor(
    @Inject(CC_MODAL_DATA) public data: any,

    private formBuilder: FormBuilder,
    private billingService: BillingService,
    private drawerService: DrawerService,
    private messageBar: MessageBar,
    private changeDetector: ChangeDetectorRef,

    private modalRef: CcModalRef<BillingInquiryComponent>
  ) {}

  ngOnInit() {
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
    this.billingInquiryForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(1)]],
      creditMemo: ['', Validators.required],
      emailID: ['', Validators.required],
      contactName: [''],
    });
    this.getUser();
    this.billingInquiryForm.controls['creditMemo']?.setValue(this.data.invoiceNo);
  }

  closeModel() {
    this.modalRef.close();
  }

  get formControl() {
    return this.billingInquiryForm?.controls;
  }

  getUser() {
    this.billingService.getUser().subscribe({
      next: (response) => {
        this.user = response;
        this.billingInquiryForm.controls['contactName']?.setValue(this.user.name);

        this.billingInquiryForm.controls['emailID']?.setValue(this.user.email);
        this.changeDetector.detectChanges();
      },
    });
  }
  submitInquiry() {
    if (this.billingInquiryForm.value.creditMemo?.includes('CX')) {
      this.inquiryType = 'CreditMemo';
    } else if (this.billingInquiryForm.value.creditMemo?.includes('INX')) {
      this.inquiryType = 'Invoice';
    } else {
      this.inquiryType = 'Invoice';
    }
    this.billingInquiryPayload = {
      description: this.billingInquiryForm.value.description,
      inquiryNumber: this.billingInquiryForm.value.creditMemo,
      contactName: this.billingInquiryForm.value.contactName,
      email: this.billingInquiryForm.value.emailID,
      contactPhone: '',
      contactMethod: '',
      contactTime: '',
      timeZone: '',
      dealerCode: this.dealerCode,
      inquiryType: this.inquiryType,
    };

    this.billingService.getsubmitBillingInquiry(this.billingInquiryPayload).subscribe({
      error: (err: any) => {
        if (err.status === 202) {
          const messageText =
            'You have successfully submitted the billing inquiry. We will contact you shortly with an update.';
          this.showToastMessage(messageText, 'success');
          this.closeModel();
        } else {
          this.closeModel();
          const messageText = 'Sorry, there was an error in submitting the billing Inquiry. Please try again later.';
          this.showToastMessage(messageText, 'error');
        }
      },
    });
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'billing-inquiry',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
}
