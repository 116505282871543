import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-license-eligiblity-header',
  templateUrl: './license-eligiblity-header.component.html',
  styleUrls: ['./license-eligiblity-header.component.scss'],
})
export class LicenseEligiblityHeaderComponent implements OnInit {
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;
  constructor() {}

  ngOnInit(): void {
    this.rowData = this.value;
    this.config = this.columnWithHeader;
  }
}
