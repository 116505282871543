<div id="sessionExpiredContainer">
  <h1 class="headline-bold">Your session has expired. Please login to proceed further.</h1>

  <div class="expired">
    <a
      cc-btn-primary=""
      cc-btn-large=""
      class="primary button-opacity cc-btn cc-btn-primary-2 cc-btn-large-2"
      aria-disabled="false"
      (click)="logout()"
      >Login</a
    >
  </div>
</div>
