<div class="basic-radio">
  <cc-radio-group
    [(ngModel)]="resetButton"
    (valueChange)="dateOptionClick($event)"
    *ngIf="selectedFormControl !== 'promtionContractDate'"
  >
    <cc-radio-button
      *ngFor="let days of dayslist"
      [value]="days.name"
      [checked]="fdate && fdate[0] && fdate[0].value === days.name ? true : days.checked"
    >
      {{ days.name }}</cc-radio-button
    >
  </cc-radio-group>
  <cc-radio-group
    [(ngModel)]="resetButton"
    (valueChange)="dateOptionClick($event)"
    *ngIf="selectedFormControl === 'promtionContractDate'"
  >
    <cc-radio-button
      *ngFor="let days of promotionExpiryDaysList"
      [value]="days.name"
      [checked]="fdate && fdate[0] && fdate[0].value === days.name ? true : days.checked"
    >
      {{ days.name }}</cc-radio-button
    >
  </cc-radio-group>
</div>
<div *ngIf="resetButton === 'Custom Range'">
  <div class="dateformat">Start Date</div>
  <div class="pickerCls" *ngIf="selectedFormControl !== 'promtionContractDate'">
    <cc-datepicker
      [timeZoneOffset]="timeZoneOffset"
      [isTheme]="isTwoPoint0"
      [dateFormat]="dateFormat"
      [id]="'basic_dp_no_end_date'"
      [inputFieldConfig]="inputFieldConfig6"
      [value]="setStartDate"
      [max]="maxStartEndDate"
      (dateChange)="onStartEndDateChange($event, 'start')"
      (datepickerStateChange)="onDatepickerOpen($event)"
    ></cc-datepicker>
  </div>
  <div class="pickerCls" *ngIf="selectedFormControl === 'promtionContractDate'">
    <cc-datepicker
      [timeZoneOffset]="timeZoneOffset"
      [isTheme]="isTwoPoint0"
      [dateFormat]="dateFormat"
      [id]="'basic_dp_no_end_date'"
      [inputFieldConfig]="inputFieldConfig6"
      [value]="setStartDate"
      [min]="minStartEndPromoContractDate"
      (dateChange)="onStartEndDateChange($event, 'start')"
      (datepickerStateChange)="onDatepickerOpen($event)"
    ></cc-datepicker>
  </div>
  <div class="dateformat">End Date</div>
  <div class="pickerCls" *ngIf="selectedFormControl !== 'promtionContractDate'">
    <cc-datepicker
      [timeZoneOffset]="timeZoneOffset"
      [isTheme]="isTwoPoint0"
      [dateFormat]="dateFormat"
      [id]="'basic_dp_no_end_date'"
      [inputFieldConfig]="inputFieldConfig6"
      [value]="setEndDate"
      [min]="setStartDate"
      [max]="maxStartEndDate"
      (dateChange)="onStartEndDateChange($event, 'end')"
      (datepickerStateChange)="onDatepickerOpen($event)"
      [ngClass]="{ disableClass: disablePicker }"
    ></cc-datepicker>
  </div>
  <div class="pickerCls" *ngIf="selectedFormControl === 'promtionContractDate'">
    <cc-datepicker
      [timeZoneOffset]="timeZoneOffset"
      [isTheme]="isTwoPoint0"
      [dateFormat]="dateFormat"
      [id]="'basic_dp_no_end_date'"
      [inputFieldConfig]="inputFieldConfig6"
      [value]="setEndDate"
      [min]="setStartDate"
      (dateChange)="onStartEndDateChange($event, 'end')"
      (datepickerStateChange)="onDatepickerOpen($event)"
      [ngClass]="{ disableClass: disablePicker }"
    ></cc-datepicker>
  </div>
</div>
