import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CcSpotLoadersComponent } from './spot-loader.component';
import { CatIconModule } from '@cat-digital-workspace/shared-ui-core/icon';
@NgModule({
  declarations: [CcSpotLoadersComponent],
  imports: [CommonModule, CatIconModule],
  exports: [CcSpotLoadersComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SpotLoaderModule {}
