import { Action, createReducer, on } from '@ngrx/store';
import * as DSPCommonActionTypes from '../actions/dspcommon.action';

export const initialAssetDeviceState = {
  deviceCatalog: {},
  deviceFeaturesInfo: {},
  userActiveFeatures: {},
  dspConfig: {},
  edUrl: {},
  psrUrl: {},
  featureSupportMapping: {},
  languageTranslations: {},
  serviceMapping: {},
  billDirectCustomers: {},
  featuresToggleMapping: {},
  billingAccounts: {},
  productFamily: {},
  certifiedCountries: [],
  dealerSubfeature: [],
  userDetails: {},
  minestarServiceDetails: {},
  siteCancelReasons: {},
  accountCancelReasons: {},
  featureFlagInfo: {},
  multiProductFeatureFlagInfo: {},
  getAssetDataSubscribedPlansEditResponse: {},
  getListOfProducts: {},
};

export const dspCommonReducer = createReducer(
  initialAssetDeviceState,
  on(DSPCommonActionTypes.setDeviceCatalogueInfo, (state, result) => ({
    ...state,
    deviceCatalog: result,
  })),
  on(DSPCommonActionTypes.getDeviceFeaturesInfo, (state, result) => ({
    ...state,
    deviceFeaturesInfo: result,
  })),
  on(DSPCommonActionTypes.userActiveFeatures, (state, result) => ({
    ...state,
    userActiveFeatures: result,
  })),
  on(DSPCommonActionTypes.getDSPConfig, (state, result) => ({
    ...state,
    dspConfig: result,
  })),
  on(DSPCommonActionTypes.getEquipmentDataUrl, (state, result) => ({
    ...state,
    edUrl: result,
  })),
  on(DSPCommonActionTypes.getPSRUrl, (state, result) => ({
    ...state,
    psrUrl: result,
  })),
  on(DSPCommonActionTypes.getFeatureSupportMapping, (state, result) => ({
    ...state,
    featureSupportMapping: result,
  })),
  on(DSPCommonActionTypes.getLanguageTranslations, (state, result) => ({
    ...state,
    languageTranslations: result,
  })),
  on(DSPCommonActionTypes.getSubscriptionServiceMapping, (state, result) => ({
    ...state,
    serviceMapping: result,
  })),
  on(DSPCommonActionTypes.getBillDirectCustomers, (state, result) => ({
    ...state,
    billDirectCustomers: result,
  })),
  on(DSPCommonActionTypes.getFeaturesToggleMapping, (state, result) => ({
    ...state,
    featuresToggleMapping: result,
  })),
  on(DSPCommonActionTypes.getBillingAccounts, (state, result) => ({
    ...state,
    billingAccounts: result || [],
  })),
  on(DSPCommonActionTypes.getProductFamily, (state, result) => ({
    ...state,
    productFamily: result,
  })),
  on(DSPCommonActionTypes.getCertifiedCountries, (state, result) => ({
    ...state,
    certifiedCountries: result,
  })),
  on(DSPCommonActionTypes.getDealerSubFeature, (state, result) => ({
    ...state,
    dealerSubfeature: result,
  })),
  on(DSPCommonActionTypes.getUserDetails, (state, result) => ({
    ...state,
    userDetails: result,
  })),
  on(DSPCommonActionTypes.getFeatureFlagInfo, (state, result) => ({
    ...state,
    featureFlagInfo: result,
  })),
  on(DSPCommonActionTypes.getMinestarServiceDetails, (state, result) => ({
    ...state,
    minestarServiceDetails: result,
  })),
  on(DSPCommonActionTypes.getSiteCancelReasonDetails, (state, result) => ({
    ...state,
    siteCancelReasons: result,
  })),
  on(DSPCommonActionTypes.getAccountCancelReasonDetails, (state, result) => ({
    ...state,
    accountCancelReasons: result,
  })),
  on(DSPCommonActionTypes.getSalesReason, (state, result) => ({
    ...state,
    salesReason: result,
  })),
  on(DSPCommonActionTypes.getMultiProductFeatureFlagInfo, (state, result) => ({
    ...state,
    multiProductFeatureFlagInfo: result,
  })),
  on(DSPCommonActionTypes.getAssetDataSubscribedPlansEditResponse, (state, result) => ({
    ...state,
    getAssetDataSubscribedPlansEditResponse: result,
  })),
  on(DSPCommonActionTypes.getListOfProducts, (state, result) => ({
    ...state,
    getListOfProducts: result,
  }))
);

export function DspCommonReducer(state: any, action: Action): any {
  return dspCommonReducer(state, action);
}
