<div class="cards asset-history-card">
  <cc-card>
    <cc-card-content>
      <div>
        <div class="header" style="width: 100%; overflow: hidden">
          <div class="header-text">
            <span class="header-text-alignment">SUBSCRIPTION HISTORY</span>
            <span
              class="expand-icon-alignment"
              [ngStyle]="{
                'pointer-events': isSubscriptionHistoryEmpty ? 'none' : 'auto',
                opacity: isSubscriptionHistoryEmpty ? '0.5' : '1'
              }"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                (click)="openExpandedHistory()"
              >
                <path d="M1.00073 5.89382V1H6.01268" stroke="black" />
                <path d="M16.9998 12.1062L16.9998 17L11.9878 17" stroke="black" />
                <path d="M6.01197 17L1 17L1 12.1062" stroke="black" />
                <path d="M11.988 1L17 1L17 5.89381" stroke="black" />
              </svg>
            </span>

            <span
              class="export-icon-alignment"
              [ngStyle]="{
                'pointer-events': isSubscriptionHistoryEmpty ? 'none' : 'auto',
                opacity: isSubscriptionHistoryEmpty ? '0.5' : '1'
              }"
            >
              <div
                class="menu-content"
                cc-menu
                [template]="singleSelectTemplate"
                OverlayPosition="bottom"
                [label]="'Title'"
                *ngIf="!showLoader; else downloadLoader"
                (closed)="toggleExportIcon()"
                (click)="toggleExportIcon()"
              >
                <div class="ellipse-container" id="cc-menu1" cc-tooltip="Export" (click)="onListItemClick()">
                  <svg
                    width="15"
                    height="20"
                    class="svg-export"
                    viewBox="0 0 15 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.84143 6.66699H8.36775V12.3813H5.84143V6.66699Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.10467 16.667L10.2626 10.9527H3.94678L7.10467 16.667Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.5 20H13.5C14.325 20 15 19.2742 15 18.3871V7.95161C15 7.51613 14.85 7.1129 14.565 6.80645L8.67 0.467742C8.385 0.16129 8.01 0 7.605 0H1.5C0.675 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.675 20 1.5 20ZM0.599044 1.61325C0.599044 1.08099 1.00404 0.645508 1.49904 0.645508H7.60404C7.84404 0.645508 8.06904 0.742282 8.23404 0.93583L14.129 7.27454C14.309 7.45196 14.399 7.69389 14.399 7.95196V18.3874C14.399 18.9197 13.994 19.3552 13.499 19.3552H1.49904C1.00404 19.3552 0.599044 18.9197 0.599044 18.3874V1.61325Z"
                      fill="black"
                    />
                  </svg>
                  <!-- <cc-icon fontSet="icomoon" [ngClass]="isExport ? 'exportArrowDown' : 'exportArrowUp'">
                    adv-search
                  </cc-icon> -->
                </div>
              </div>
              <ng-template #downloadLoader>
                <div class="loader-container">
                  <div class="loader-container__label"></div>
                  <div class="history-loader">
                    <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                        fill="#43485C"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                        fill="#43485C"
                      />
                    </svg>
                  </div>
                </div>
              </ng-template>
            </span>
          </div>
        </div>
        <div>
          <div class="asset-drawer-table-container" style="padding-top: 0px !important; overflow-x: hidden">
            <div class="history-tl-container">
              <ul class="tl">
                <li class="tl-item" *ngFor="let history of subscriptionHistory; let i = index">
                  <div style="width: 100%; overflow: hidden">
                    <div class="item-title" style="width: 80%">
                      <div class="date-div" [ngClass]="{ 'date-div': i === 0, 'date-div-grey': i !== 0 }">
                        {{
                          isTimeZoneSelectionEnabled
                            ? getConvertedDateFromTimestamp(history?.convertedEventTimeStamp, 'date')
                            : getDateFromTimestamp(history.eventTimeStamp)
                        }}
                      </div>
                      <div style="margin-left: 95px; font-weight: 600">{{ history.eventName }}</div>
                    </div>
                    <div class="his-utc">
                      {{
                        isTimeZoneSelectionEnabled
                          ? getConvertedDateFromTimestamp(history?.convertedEventTimeStamp, 'time')
                          : getTimeFromTimestamp(history.eventTimeStamp)
                      }}
                    </div>
                  </div>
                  <div style="margin-left: 5px; margin-top: 5px">
                    <table>
                      <tbody>
                        <tr>
                          <td class="his-key">Product(s):</td>
                          <td
                            class="his-value"
                            *ngIf="history.product?.length && !showTruncatedText && isMultiProductWorkFlowEnabled"
                            cc-tooltip="{{ history.product?.length > 22 ? history.product : null }}"
                            [tooltipPosition]="'top'"
                          >
                            {{
                              history.product?.length > 22 ? (history.product | slice: 0:22) + '...' : history.product
                            }}
                          </td>

                          <td
                            class="his-value"
                            *ngIf="history.product?.length && !showTruncatedText && !isMultiProductWorkFlowEnabled"
                            cc-tooltip="{{ history.product?.length > 22 ? getPrimaryProduct(history.product) : null }}"
                            [tooltipPosition]="'top'"
                          >
                            {{
                              getPrimaryProduct(history.product)?.length > 22
                                ? (getPrimaryProduct(history.product) | slice: 0:22) + '...'
                                : getPrimaryProduct(history.product)
                            }}
                          </td>
                          <td
                            class="his-value"
                            *ngIf="history.product?.length && showTruncatedText && isMultiProductWorkFlowEnabled"
                            cc-tooltip="{{ history.product?.length > 22 ? history.product : null }}"
                            [tooltipPosition]="'top'"
                            (copy)="copyEvent($event, history.product)"
                          >
                            {{
                              history.product?.length > 22 ? (history.product | slice: 0:22) + '...' : history.product
                            }}
                          </td>
                          <td
                            class="his-value"
                            *ngIf="history.product?.length && showTruncatedText && !isMultiProductWorkFlowEnabled"
                            cc-tooltip="{{ history.product?.length > 22 ? getPrimaryProduct(history.product) : null }}"
                            [tooltipPosition]="'top'"
                            (copy)="copyEvent($event, getPrimaryProduct(history.product))"
                          >
                            {{
                              getPrimaryProduct(history.product)?.length > 22
                                ? (getPrimaryProduct(history.product) | slice: 0:22) + '...'
                                : getPrimaryProduct(history.product)
                            }}
                          </td>
                          <td class="his-value" *ngIf="!history.product">-</td>
                        </tr>
                        <tr>
                          <td class="his-key">From:</td>
                          <td
                            class="his-value"
                            *ngIf="history.fromSub?.length && !showTruncatedText"
                            cc-tooltip="{{ history.fromSub?.length > 22 ? history.fromSub : null }}"
                            [tooltipPosition]="'top'"
                          >
                            {{
                              history.fromSub?.length > 22 ? (history.fromSub | slice: 0:22) + '...' : history.fromSub
                            }}
                          </td>
                          <td
                            class="his-value"
                            *ngIf="history.fromSub?.length && showTruncatedText"
                            cc-tooltip="{{ history.fromSub?.length > 22 ? history.fromSub : null }}"
                            [tooltipPosition]="'top'"
                            (copy)="copyEvent($event, history.fromSub)"
                          >
                            {{
                              history.fromSub?.length > 22 ? (history.fromSub | slice: 0:22) + '...' : history.fromSub
                            }}
                          </td>
                          <td class="his-value" *ngIf="!history.fromSub">-</td>
                        </tr>
                        <tr>
                          <td class="his-key">To:</td>
                          <td
                            class="his-value"
                            *ngIf="history.toSub?.length && !showTruncatedText"
                            cc-tooltip="{{ history.toSub?.length > 22 ? history.toSub : null }}"
                            [tooltipPosition]="'top'"
                          >
                            {{ history.toSub?.length > 22 ? (history.toSub | slice: 0:22) + '...' : history.toSub }}
                          </td>
                          <td
                            class="his-value"
                            *ngIf="history.toSub?.length && showTruncatedText"
                            cc-tooltip="{{ history.toSub?.length > 22 ? history.toSub : null }}"
                            [tooltipPosition]="'top'"
                            (copy)="copyEvent($event, history.toSub)"
                          >
                            {{ history.toSub?.length > 22 ? (history.toSub | slice: 0:22) + '...' : history.toSub }}
                          </td>
                          <td class="his-value" *ngIf="!history.toSub">-</td>
                        </tr>
                        <tr>
                          <td class="his-key">{{ isAssetHistoryOwnershipAdded ? 'Dealer' : 'Dealer Name & Code' }}:</td>
                          <td
                            class="his-value"
                            [cc-tooltip]="checkDealerDataLength(history.dealerName, history.dealerCode)"
                            [isEllipseEnabled]="true"
                            [tooltipPosition]="'top'"
                            *ngIf="
                              !isDealerNameEmpty(history.dealerName) && history.dealerName?.length && !showTruncatedText
                            "
                          >
                            {{ checkDealerDataLength(history.dealerName, history.dealerCode) }}
                          </td>
                          <td
                            class="his-value"
                            cc-tooltip="{{ showToolTipData(history.dealerName, history.dealerCode) }}"
                            [isEllipseEnabled]="false"
                            [tooltipPosition]="'top'"
                            (copy)="copyEventForDealer($event, history.dealerName, history.dealerCode)"
                            *ngIf="
                              !isDealerNameEmpty(history.dealerName) && history.dealerName?.length && showTruncatedText
                            "
                          >
                            {{ checkDealerDataLength(history.dealerName, history.dealerCode) }}
                          </td>
                          <td class="his-value" *ngIf="!history.dealerName">-</td>
                        </tr>
                        <tr *ngIf="isAssetHistoryOwnershipAdded">
                          <td class="his-key">Customer:</td>
                          <td
                            class="his-value"
                            cc-tooltip="{{ showToolTipData(history.ucidName, history.ucid) }}"
                            [isEllipseEnabled]="false"
                            [tooltipPosition]="'top'"
                            (copy)="copyEventForDealer($event, history.ucidName, history.ucid)"
                          >
                            {{ checkDealerDataLength(history.ucidName, history.ucid) }}
                          </td>
                        </tr>
                        <tr *ngIf="isAssetHistoryOwnershipAdded">
                          <td class="his-key">Dealer Customer:</td>
                          <td
                            class="his-value"
                            cc-tooltip="{{ showToolTipData(history.dealerCustomerName, history.dealerCustomerNo) }}"
                            [isEllipseEnabled]="false"
                            [tooltipPosition]="'top'"
                            (copy)="copyEventForDealer($event, history.dealerCustomerName, history.dealerCustomerNo)"
                          >
                            {{ checkDealerDataLength(history.dealerCustomerName, history.dealerCustomerNo) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="his-key">User Name:</td>
                          <td
                            class="his-value"
                            *ngIf="history.createdByUserName?.length && !showTruncatedText"
                            cc-tooltip="{{ history.createdByUserName?.length > 22 ? history.createdByUserName : null }}"
                            [tooltipPosition]="'top'"
                          >
                            {{
                              history.createdByUserName?.length > 22
                                ? (history.createdByUserName | slice: 0:22) + '...'
                                : history.createdByUserName
                            }}
                          </td>
                          <td
                            class="his-value"
                            *ngIf="history.createdByUserName?.length && showTruncatedText"
                            cc-tooltip="{{ history.createdByUserName?.length > 22 ? history.createdByUserName : null }}"
                            [tooltipPosition]="'top'"
                            (copy)="copyEvent($event, history.createdByUserName)"
                          >
                            {{
                              history.createdByUserName?.length > 22
                                ? (history.createdByUserName | slice: 0:22) + '...'
                                : history.createdByUserName
                            }}
                          </td>
                          <td class="his-value" *ngIf="!history.createdByUserName">-</td>
                        </tr>
                        <tr>
                          <td class="his-key">User CWS ID:</td>
                          <td class="his-value" *ngIf="history.createdByUserCwsId">{{ history.createdByUserCwsId }}</td>
                          <td class="his-value" *ngIf="!history.createdByUserCwsId">-</td>
                        </tr>
                        <tr class="last-row">
                          <td class="his-key">User Type:</td>
                          <td class="his-value" *ngIf="history.userType">{{ history.userType }}</td>
                          <td class="his-value" *ngIf="!history.userType">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="isSubscriptionHistoryEmpty">
              <img class="no-results-notfound-image" src="..\assets\images\subscription-history-empty.svg" />
              <p class="no-history-text">No data to display</p>
            </div>
          </div>
        </div>
      </div>
    </cc-card-content>
  </cc-card>
</div>
<ng-template #singleSelectTemplate>
  <div class="cc-contextual-menu" id="cc-single-menu1" *ngIf="isXLDownloadEnabled">
    <cc-list [theme]="'secondary'" width="1000px">
      <cc-list-item *ngFor="let item of listItems" (listItemClick)="onListItemClick(item.label)">
        {{ item.label }}
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>
