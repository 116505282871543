<div class="asset-select-plan-section">
  <form [formGroup]="manageAssetSubscriptionForm" id="set-service-container">
    <dsp-next-gen-ui-manage-asset-details
      [rowData]="rowData"
      [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
      [subsPricingEligible]="subsPricingEligible"
      [showDealerInfo]="true"
    ></dsp-next-gen-ui-manage-asset-details>
    <dsp-next-gen-ui-blocked-devices [rowData]="rowData"></dsp-next-gen-ui-blocked-devices>
    <div class="cc-grid" *ngIf="rowData.reportingState === 'Subscribed'">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="select-plan-section">CURRENT PLAN(S):</div>
        <cc-accordion class="alignmentChange" [multi]="true" [cc-accordion-open-all]="true">
          <div *ngFor="let service of currentServices">
            <cc-expandable
              (closed)="closeService(service.name)"
              (opened)="handleServiceChange(service.name)"
              [disabled]="service.disabled || !rowData.enableSubscription"
              [expanded]="service.expand"
            >
              <cc-expandable-header>
                <div class="expandable-header-title">
                  <span
                    [ngStyle]="{
                      color: !rowData.enableSubscription ? '#cccccc' : '#000000',
                    }"
                    >{{ service.name }}</span
                  >
                </div>
              </cc-expandable-header>
              <section *ngIf="subsCategory?.Single_Level_Profile_AddOn_FSM?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-single-level-plan
                  [listOfBasePlans]="listOfBasePlans"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  [autoSelectItem]="autoSelectItem"
                  [subsData]="subs"
                  (basePlanChange)="onBasePlanChange($event)"
                  (OptionalPlanChange)="onOptionalPlanChange($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  (togglePrepay)="togglePrepay($event)"
                  [startDateFieldConfig]="startDateFieldConfig"
                  [endDateFieldConfig]="endDateFieldConfig"
                  [multiYearConfig]="multiYearConfig"
                  (prepayDateChange)="onDateChange($event)"
                  [promoLoader]="promoLoader"
                ></dsp-next-gen-ui-single-level-plan>
              </section>

              <!-- Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
              <section *ngIf="subsCategory?.Multi_Level_Profile_AddOn_FSM?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-multi-level-plan
                  [multiLevelPlans]="multiLevelPlans"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  (multiBasePlanChange)="onBasePlanChange($event)"
                  (multiOptionalPlanChange)="onOptionalPlanChange($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  [subsData]="subs"
                  [promoLoader]="promoLoader"
                  [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
                ></dsp-next-gen-ui-multi-level-plan>
              </section> -->
              <section *ngIf="subsCategory?.Single_Level_Minestar?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-minestar-plan
                  (redirectSite)="redirectToSitePage()"
                  [siteName]="siteName"
                  [userActiveFeatures]="userActiveFeatures"
                  (siteInfo)="getSiteReqInfo($event)"
                  (siteChange)="onSiteChanged(siteName)"
                  [enableSearchOverlay]="enableSearchOverlay"
                  [siteResults]="siteResults"
                  (handleListItemSelection)="listItemClick($event)"
                  [isSiteNameLoading]="isSiteNameLoading"
                  [siteNotFound]="siteNotFound"
                  [listOfBasePlans]="listOfBasePlans"
                  (basePlanChange)="onBasePlanChange($event)"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  (additionalServicesChng)="onOptionalPlanChange($event)"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [productFamily]="productFamily"
                  [customerPlan]="customerPlan"
                  [listOfProductFamily]="listOfProductFamily"
                  [modifiedProductFamily]="modifiedProductFamily"
                  (productFamilyChanged)="productFamilyChangedFn($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  [subsData]="subs"
                  [promoLoader]="promoLoader"
                  [modifiedProductFamily]="modifiedProductFamily"
                  (blurEvent)="onBlurEvent($event)"
                ></dsp-next-gen-ui-minestar-plan>
              </section>
            </cc-expandable>
          </div>
        </cc-accordion>
      </div>
    </div>
    <div class="cc-grid" *ngIf="listOfService?.length > 0">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="select-plan-section">SELECT PLAN(S):</div>
        <cc-accordion style="margin-left: -10px" [cc-accordion-collapse-all]="isResetService">
          <div *ngFor="let service of listOfService">
            <cc-expandable
              (closed)="closeService(service.name)"
              (opened)="handleServiceChange(service.name)"
              [expanded]="service.expand"
              [disabled]="
                service.disabled ||
                isCancelView ||
                rowData.reportingState === 'Subscribed' ||
                !rowData.enableSubscription ||
                (this.rowData?.ownershipDetails?.length === 1 &&
                  this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE')
              "
            >
              <cc-expandable-header>
                <div class="expandable-header-title">
                  <span
                    [ngStyle]="{
                      color:
                        service.disabled ||
                        isCancelView ||
                        rowData.reportingState === 'Subscribed' ||
                        !rowData.enableSubscription ||
                        (this.rowData?.ownershipDetails?.length === 1 &&
                          this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus ===
                            'DECLINE')
                          ? '#cccccc'
                          : '#000000',
                    }"
                    >{{ service.name }}</span
                  >
                </div>
              </cc-expandable-header>
              <section *ngIf="subsCategory?.Single_Level_Profile_AddOn_FSM?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-single-level-plan
                  [listOfBasePlans]="listOfBasePlans"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  [autoSelectItem]="autoSelectItem"
                  [subsData]="subs"
                  (basePlanChange)="onBasePlanChange($event)"
                  (OptionalPlanChange)="onOptionalPlanChange($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  (togglePrepay)="togglePrepay($event)"
                  [startDateFieldConfig]="startDateFieldConfig"
                  [endDateFieldConfig]="endDateFieldConfig"
                  [multiYearConfig]="multiYearConfig"
                  (prepayDateChange)="onDateChange($event)"
                  [promoLoader]="promoLoader"
                ></dsp-next-gen-ui-single-level-plan>
              </section>
              <!-- Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
              <section *ngIf="subsCategory?.Multi_Level_Profile_AddOn_FSM?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-multi-level-plan
                  [multiLevelPlans]="multiLevelPlans"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  (multiBasePlanChange)="onBasePlanChange($event)"
                  (multiOptionalPlanChange)="onOptionalPlanChange($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  [subsData]="subs"
                  [promoLoader]="promoLoader"
                  [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
                ></dsp-next-gen-ui-multi-level-plan>
              </section> -->
              <section *ngIf="subsCategory?.Single_Level_Minestar?.indexOf(service.name) !== -1">
                <dsp-next-gen-ui-minestar-plan
                  (redirectSite)="redirectToSitePage()"
                  [siteName]="siteName"
                  (siteInfo)="getSiteReqInfo($event)"
                  (siteChange)="onSiteChanged(siteName)"
                  [userActiveFeatures]="userActiveFeatures"
                  [enableSearchOverlay]="enableSearchOverlay"
                  [siteResults]="siteResults"
                  (handleListItemSelection)="listItemClick($event)"
                  [siteNotFound]="siteNotFound"
                  [listOfBasePlans]="listOfBasePlans"
                  (basePlanChange)="onBasePlanChange($event)"
                  [listOfOptionalPlans]="listOfOptionalPlans"
                  (additionalServicesChng)="onOptionalPlanChange($event)"
                  [applicableAddlSubscriptions]="applicableAddlSubscriptions"
                  [applicableSubscriptions]="applicableSubscriptions"
                  [productFamily]="productFamily"
                  [listOfProductFamily]="listOfProductFamily"
                  (productFamilyChanged)="productFamilyChangedFn($event)"
                  [manageAssetSubscriptionForm]="manageAssetSubscriptionForm"
                  [subsData]="subs"
                  [promoLoader]="promoLoader"
                  [isSiteNameLoading]="isSiteNameLoading"
                  [modifiedProductFamily]="modifiedProductFamily"
                  (blurEvent)="onBlurEvent($event)"
                ></dsp-next-gen-ui-minestar-plan>
              </section>
            </cc-expandable>
            <div *ngIf="isCancelView" class="accordian-bottom"></div>
          </div>
        </cc-accordion>
      </div>
    </div>
  </form>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 algin-select-plan-footer">
      <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
      <div class="colorChange" [innerHTML]="infoMessageManageAsset || ''"></div>
    </div>
    <div
      *ngIf="showOtoMessage"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 align-select-plan-footer-oto"
    >
      <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
      <div>{{ otoMessage }}</div>
    </div>
    <div
      *ngIf="!isChinaDealer"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
      style="font-size: 12px; padding-left: 15px"
    >
      Equipment Insights, Remote Asset Monitoring, Remote Fleet Vision and Marine Concierge Plus are automatically
      bundled with VisionLink by default. See
      <a (click)="helpCenterLink()" target="_blank" class="dsp-help-hyperlink">DSP Help Center</a> for details on
      specific plan included.
    </div>
    <div
      *ngIf="selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE' && isdatExcludedProductCheck"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
      style="font-size: 12px; padding-left: 15px"
    >
      Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take
      action through the
      <a (click)="datClick()" target="_blank" class="dsp-help-hyperlink">Digital Authorization Tool</a>
    </div>
    <div
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 align-select-plan-footer-notes"
      *ngIf="rowData.notes?.length > 0"
    >
      <img src="../../../assets/images/info-group.svg" class="info-icon-style" />&nbsp;
      <span class="colorChange"> {{ rowData.notes[0]?.displayMessage || '' }} </span>
    </div>
  </div>
</div>

<ng-template #shared_to_template2>
  <div class="popup-template">
    <div>
      <strong> Dealer Action Required: </strong>
    </div>
    <div>
      <p>
        There is a cost associated with the subscription of each asset to VIMS Transmitter. This cost will be invoiced
        per asset on a monthly basis to the dealer. For details about the subscription cost review the VIMS Transmitter
        Price List
        <a
          href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/health/connectivity.html#accordion-89057a14fd-item-ab94f1ffaa-button"
          style="text-decoration: underline"
          target="_blank"
          >Here</a
        >
      </p>
      <p>
        The installation and use of the MineStar™ technologies require the Dealer to execute a MineStarTM Caterpillar
        Dealer Subscription Purchase Agreement which includes Attachment 1 – Software End User Agreement. Templates for
        the MineStarTM Caterpillar Dealer Subscription Purchase Agreements can be found on
        <a
          href="https://catdealer.com/en/products/technology/minestar-solutions-surface/cat-minestar-system-support/bronze-subscriptions.html"
          style="text-decoration: underline"
          target="_blank"
          >Here</a
        >
      </p>
    </div>
  </div>
</ng-template>
<ng-template #b2cCustWarningTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>
        {{ languageTranslations?.Worklist?.WL_WARNING_MSG_CONTENT }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeB2cCustWarningMsgPopup(true)">
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeB2cCustWarningMsgPopup(false)">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #catGradeConnectivityPopup>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>
        {{ languageTranslations?.Worklist?.WL_CAT_GRADE }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeCatGradeConnectivityPopup()">OK</button>
    </div>
  </div>
</ng-template>
<!-- 
<ng-template #subscriptionResetPopup>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>Current selected plans will be disregarded and new selections will apply.</p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeSubscriptionResetPopup()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeSubscriptionResetPopup()">Reset</button>
    </div>
  </div>
</ng-template> -->
