<div class="pending-request-details">
  <div class="body">
    <div class="cc-grid">
      <div class="{{ ccGrid.GRID_FOUR }} border-right">
        <span class="asset-title">ASSET DETAILS</span>
        <div class="column-container">
          <div class="column">
            <span class="asset-details-title">Asset S/N:</span>
            <span class="asset-details-value">{{ rowData?.serialNumber }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Make/Model:</span>
            <span class="asset-details-value">{{ rowData?.make }}&sol;{{ rowData?.model }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Dealer:</span>
            <span class="asset-details-value" *ngIf="rowData?.dealerName && rowData?.dealerCode">{{
              rowData?.dealerName + ': ' + rowData?.dealerCode
            }}</span>
            <span class="asset-details-value" *ngIf="!(rowData?.dealerName && rowData?.dealerCode)">{{
              this.dealer?.dealerName + ': ' + this.dealer?.dealerCode || '-'
            }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Device Type:</span>
            <span class="asset-details-value">{{ rowData?.deviceType }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Device Information:</span>
            <span
              class="asset-details-value"
              [disabled]="this.deviceInfoWithoutTrim?.length > 15 ? false : true"
              [innerHTML]="getDeviceInformation() || '-'"
            ></span>
          </div>
          <div class="column">
            <span class="asset-details-title">Bill to Party:</span>
            <span class="asset-details-value">{{ billToParty || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="{{ ccGrid.GRID_FOUR }} border-right">
        <span class="asset-title">ASSET OWNERSHIP</span>
        <ng-container class="column-container">
          <div class="column">
            <span class="asset-details-title">Customer:</span>
            <span
              *ngIf="ownershipData?.ucidName?.length > 0 || ownershipData?.ucid?.length > 0; else else_content"
              class="asset-details-value"
              [disabled]="this.customerInfoWithoutTrim?.length > 20 ? false : true"
              [innerHTML]="dealerCustomerData(ownershipData?.ucid, ownershipData?.ucidName)"
            ></span>
          </div>
          <div class="column">
            <span class="asset-details-title">Dealer Customer:</span>
            <span
              *ngIf="
                (ownershipData?.dealerCustName?.length > 0 || ownershipData?.dealerCustNo?.length > 0) &&
                  !this.rowData.hideDCN;
                else else_content
              "
              class="asset-details-value"
              [disabled]="this.dealerCustomerInfoWithoutTrim?.length > 20 ? false : true"
              [innerHTML]="dealerCustomerData(ownershipData?.dealerCustNo, ownershipData?.dealerCustName)"
            ></span>
          </div>
          <div class="column">
            <span class="asset-details-title">Digital Authorization:</span>
            <span class="icon-size" *ngIf="ownershipData; else else_content">
              <cc-icon
                fontSet="icomoon"
                [interactive]="true"
                [tooltipPosition]="'bottom'"
                [appClass]="'-dat-tool-tip'"
                [ngClass]="checkDATStatus()"
                cc-tooltip
                [template]="tooltipTemplateForDAT"
                >{{ updateDATBadge() }}
              </cc-icon>
              <span class="asset-details-value">{{ updateDatStatus() }}</span>
            </span>
          </div>
          <div class="column">
            <span *ngIf="ownershipData?.cvaDetails" class="asset-details-title">CVA Status:</span>
            <cc-icon
              *ngIf="ownershipData?.cvaDetails?.cvaStatus.toUpperCase() === 'AVAILABLE'"
              cc-tooltip="CVA Status"
              [template]="tooltipTemplateForCva"
              [appClass]="'-cva-tool-tip'"
              [interactive]="true"
              class="icon-CVA-Available11 cva-status-icon cva-color-avail-mp"
            >
            </cc-icon>
            <cc-icon
              *ngIf="ownershipData?.cvaDetails?.cvaStatus.toUpperCase() === 'NOT AVAILABLE'"
              cc-tooltip="CVA Status"
              [tooltipPosition]="'bottom'"
              [interactive]="true"
              [appClass]="'-cva-tool-tip'"
              [template]="tooltipTemplateForCva"
              class="icon-CVA-NA11 cva-status-icon cva-color-na-mp"
            ></cc-icon>
            <span *ngIf="ownershipData?.cvaDetails" class="cva-change">{{
              ownershipData?.cvaDetails?.cvaStatus.toUpperCase()
            }}</span>
            <span class="cva-change" *ngIf="cvaStatus === 'Available'">{{ '- ' + cvaCount }}</span>
          </div>
        </ng-container>
      </div>
      <div class="{{ ccGrid.GRID_FOUR }}">
        <span class="asset-title">REQUEST DETAILS</span>
        <ng-container class="column-container">
          <div class="column">
            <span class="asset-details-title">Source Application:</span>
            <span class="asset-details-value">{{ rowData.vlRequestDetails?.application }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Requestor Name:</span>
            <span class="asset-details-value">{{ rowData.vlRequestDetails?.name }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Requestor Email:</span>
            <span class="asset-details-value">{{ rowData.vlRequestDetails?.email }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Requestor Phone Number:</span>
            <span class="asset-details-value">{{ rowData.vlRequestDetails?.phoneNumber }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Request Date & Time:</span>
            <span class="asset-details-value">{{ getUpdatedTime() }}</span>
          </div>
          <div class="column">
            <span class="asset-details-title">Request Type:</span>
            <span class="asset-details-value">{{ rowData.vlRequestDetails?.type }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #else_content>{{ '-' }}</ng-template>
<ng-template #tooltipTemplateForDAT>
  <div class="dat-tooltip-header">
    Digital Authorization <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="dat-tooltip-body">
    <span *ngIf="datStatus === 'AGREE'"
      >Customer authorization on file - No further action required. Click to review in
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'DECLINE'"
      >Customer authorization declined - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'UNAVAILABLE'"
      >Customer authorization unavailable - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'SYSTEM ERROR'"
      >Unable to get authorization details - Please retry later or contact Cat Digital support
    </span>
  </div>
</ng-template>
<ng-template #tooltipTemplateForCva>
  <div class="cva-tooltip-header">
    CVA Status
    <cc-icon [appClass]="'-cva-tool-tip'" class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="cva-tooltip-body">
    <span *ngIf="cvaStatus === 'Available'"
      >One or more active CVAs are associated to this asset. These CVAs may or may not be associated to your dealership.
      <!-- <a
        href="{{ cvaUrl }}"
        target="_blank"
        style="color: #2595ff; text-decoration: underline; font-weight: 400; cursor: pointer"
        >Click here</a
      > -->
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>
      to review in Cat Foresight</span
    >
    <span *ngIf="cvaStatus === 'Not Available'"
      >There are no active CVAs recorded for this asset.
      <span (click)="cvaURLClick()" class="cvaURL">Click here</span>
      to review in Cat Foresight
    </span>
  </div>
</ng-template>
