<div class="asset-select-plan-section">
  <dsp-next-gen-ui-asset-details-card
    [hideBillToPartyOwnership]="hideBillToPartyOwnership"
    [rowData]="rowData"
    [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
  ></dsp-next-gen-ui-asset-details-card>
  <form [formGroup]="billingSubscriptionForm">
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12 dd-alignment">
      <div class="service-list-options-label">Account name or description</div>
      <cc-dropdown
        [id]="'optional-plan-dropdown-medium'"
        class="manage-asset-dropdown"
        name="optional-dropdown"
        [label]="'Select account description'"
        [dataSource]="accountNames"
        [mobileHeader]="'Asset Service Optional dropdown'"
        [placementPosition]="'bottom'"
        [isTheme]="'2.0'"
        (selectionChange)="onBillingDropdownChange($event, 'accountName')"
        formControlName="selectedAccountName"
        [selectionOnClose]="true"
        [isReOrder]="false"
        [multiple]="false"
      >
      </cc-dropdown>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12 dd-alignment">
      <div class="service-list-options-label">Account Number</div>
      <cc-dropdown
        [id]="'optional-plan-dropdown-medium'"
        class="manage-asset-dropdown"
        name="optional-dropdown"
        [label]="'Select account number'"
        [dataSource]="accountNumbers"
        [mobileHeader]="'Asset Service Optional dropdown'"
        [placementPosition]="'bottom'"
        [isTheme]="'2.0'"
        (selectionChange)="onBillingDropdownChange($event, 'accountNumber')"
        formControlName="selectedAccountNumber"
        [selectionOnClose]="true"
        [isReOrder]="false"
        [multiple]="false"
      >
      </cc-dropdown>
    </div>
    <div class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12 dd-alignment">
      <div class="service-list-options-label">Account Contact</div>
      <cc-dropdown
        [id]="'optional-plan-dropdown-medium'"
        class="manage-asset-dropdown"
        name="optional-dropdown"
        [label]="'Select account contact'"
        [dataSource]="accountContacts"
        [mobileHeader]="'Asset Service Optional dropdown'"
        [placementPosition]="'bottom'"
        [isTheme]="'2.0'"
        (selectionChange)="onBillingDropdownChange($event, 'accountContact')"
        formControlName="selectedAccountContact"
        [selectionOnClose]="true"
        [isReOrder]="false"
        [multiple]="false"
      >
      </cc-dropdown>
    </div>
  </form>
</div>
