import { Component, Inject } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef, CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { CookiesModalComponent } from '../cookies-modal/cookies-modal.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'dsp-next-gen-ui-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent {
  public subscription: Subscription = new Subscription();
  browserRefresh = false;


  constructor(private modal: CcModal, public router: Router) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['legalModal']);
        }
      }
      });
  }
}
