<div class="cc-modal group-info__modal">
  <div class="cc-modal-header">
    <div class="cc-modal-title">{{ modalTitle }}</div>
    <div class="cc-modal-close" (click)="closePopupModal()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 9L9 1"
          stroke="#43485C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M9 9L1 1"
          stroke="#43485C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
  </div>
  <div class="cc-modal-content group-info__content modal-content">
    <p>{{ modalBodyLine1 }}</p>
    <p>{{ modalBodyLine2 }}</p>
    <p></p>
  </div>

  <div class="cc-modal-footer group-info__footer">
    <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closePopupModal(false)">Cancel Changes</button>
    <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeSettingsModal()">Save & Close</button>
  </div>
</div>
