export interface GetTelemetryInfoPaylod {
  make: string;
  serialNumber: string;
}
export class LocationInfo {
  address: any;
  gpsAddressTime: any;
  latLongText: any;
  latitude: any;
  longitude: any;
}

export class ServiceMeterInfo {
  serviceMeterReading: any = '';
  serviceMeterReadingTime: any = '';
}

export class RequestRemoteConnectionCheckPayload {
  eq_make: any;
  eq_sn: any;
}

export class RequestRemoteConnectionCheckStatusResponse {
  characterization: any;
  connection_status: any;
  eq_make: any;
  eq_sn: any;
  status_code: any;
  status_message: any;
}
export class GetConfigStatusPayload {
  make: any;
  serialNumber: any;
}
export class GetConfigFileUploadListPayload {
  type: any;
}
export class WifiConfigurationPayload {
  make: any;
  serialNumber: any;
}

export class WifiConfigurationResponse {
  channel: any;
  country_code: any;
  macAddress: any;
  status: any;
  status_code: any;
  status_message: any;
  wifiConfigResponse: any;
}
export class ConfigInfo {
  assetSerialNumber: any;
  deviceSerialNumber: any;
  deviceType: any;
  currentConfigFile: any;
}

export class Config {
  fileName: any;
  fileTypeId: any;
  id: any;
  selected?: boolean;
}

export class UploadConfigFilePayload {
  make?: string;
  serialNumber?: string;
  hash?: string;
  fileName?: string;
  type?: string;
}

export interface FilterAssetsV2Payload {
  serialNumber?: string;
  make?: string;
  dealerCode?: string;
  flag?: boolean;
  isOwnershipInfoReq?: boolean;
}
