import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { siteData } from './../sites-interface';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'dsp-next-gen-ui-additional-product-plan',
  templateUrl: './additional-product-plan.component.html',
  styleUrls: ['./additional-product-plan.component.scss'],
})
export class AdditionalProductPlanComponent {
  rowData: any;
  addonServicesList: any;
  planName: any;
  index = 1;
  seemoreOverlayUpdate = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }

  constructor(
    public dialogRef: CcDrawerRef<AdditionalProductPlanComponent>,
    private store: Store<DSPAppState>,
    @Inject(CC_DRAWER_DATA) private list: { rowData: siteData }
  ) {}

  ngOnInit(): void {
    this.rowData = this.list.rowData;
    this.addonServicesList = this.rowData ? this.rowData.planName.split('|') : [];
    this.planName = this.addonServicesList.shift();

    this.store.select('dsp').subscribe((dsp) => {
      this.seemoreOverlayUpdate = dsp.featureFlagInfo.seeMoreOverlayUpdate
        ? dsp.featureFlagInfo.seeMoreOverlayUpdate
        : false;
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
