<div class="asset-action-menu">
  <cc-icon
    class="icon-size"
    cc-menu
    [template]="assetMenuOptions"
    [ngClass]="{
      'enabled-class': isEnabled,
      'disabled-class': !isEnabled || disableMenu
    }"
    [OverlayPosition]="'bottomright'"
    (click)="$event.stopPropagation()"
    [overlayPanelClass]="'asset-page'"
  >
    row-action
  </cc-icon>
</div>

<ng-template #assetMenuOptions>
  <div class="group-info__options__list">
    <cc-list>
      <cc-list-item
        *ngIf="
          assetInfo?.reportingState === dspWorklistConstants.SUSPENDED ||
          assetInfo.isMaskRequired ||
          (userActiveFeatures?.features?.indexOf('Subscription Management') > -1 &&
            (userActiveFeatures?.features?.indexOf('View Only') > -1 ||
              userActiveFeatures?.features?.indexOf('Read Only') > -1 ||
              userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
          dspData?.featureFlagInfo?.isShowManagePlanIcon
            ? false
            : true
        "
        (click)="openManagePlan()"
        class="group-info__options__list-item"
        ><div class="cancel-plan">
          <span> Manage Plan </span>
        </div>
      </cc-list-item>
      <cc-list-item
        *ngIf="checkPIDIsApplicable(assetInfo)"
        (click)="openDrawerInMultiSize(assetInfo)"
        class="group-info__options__list-item"
      >
        <span> Update Registration </span>
      </cc-list-item>
      <cc-list-item
        *ngIf="showDeviceStatusIcon"
        class="group-info__options__list-item delete-site"
        (listItemClick)="openDeviceStatus()"
      >
        <span> Device Status </span>
      </cc-list-item>
      <cc-list-item
        *ngIf="showConfigureWifiIcon"
        (click)="openWifiConfiguration()"
        class="group-info__options__list-item"
        ><div class="cancel-plan">
          <span> Configure Wi-Fi </span>
        </div>
      </cc-list-item>
      <cc-list-item
        *ngIf="
          (assetInfo?.status === dspNeedsReviewConstants.Active || assetInfo?.status === dspNeedsReviewConstants.New) &&
          (userActiveFeatures?.features?.indexOf('View Only') > -1 ||
          userActiveFeatures?.features?.indexOf('Read Only') > -1 ||
          userActiveFeatures?.features?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1
            ? false
            : true)
        "
        (click)="dismissAsset()"
        class="group-info__options__list-item"
        ><div class="cancel-plan">
          <span> Dismiss </span>
        </div>
      </cc-list-item>
      <cc-list-item
        *ngIf="
          (assetInfo?.status === dspNeedsReviewConstants.Active || assetInfo?.status === dspNeedsReviewConstants.New) &&
          isSelfServeSubscription &&
          isCustRequestedSubscription
        "
        (click)="approveRequest()"
        class="group-info__options__list-item"
        ><div class="cancel-plan">
          <span> Approve Request </span>
        </div>
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>
