<div class="cc-drawer configure-access-drawer">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis title-display"> Configure Access</span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon close-display" (click)="closeDrawer(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="user-details" [formGroup]="configureAccessForm">
      <div class="user-name" *ngIf="this.rowData?.profileType !== 'Dealer'">
        {{ rowDataFirstName }} {{ rowDataLastName }}
      </div>

      <div *ngIf="this.rowData?.profileType === 'Dealer'" class="dealer-block">
        <div class="user-name">{{ rowData?.firstName }} {{ rowData?.lastName }}</div>
        <div
          *ngIf="
            (this.rowData?.profileType === 'Dealer' && configureAccessForm.controls['selectDealer'].value !== '') ||
            ((configureAccessForm.value.list1[0].label === 'None' ||
              configureAccessForm.value.list1[0].label === undefined) &&
              (configureAccessForm.value.list2[0].label === 'None' ||
                configureAccessForm.value.list2[0].label === undefined) &&
              (configureAccessForm.value.list3[0].label === 'None' ||
                configureAccessForm.value.list3[0].label === undefined) &&
              this.rowData?.profileType === 'Dealer' &&
              !dealerChange &&
              updatedDspAccess === 'None')
          "
        >
          <div class="row">
            <div class="dealerSelection">Select Dealer</div>
            <cc-dropdown
              [id]="'list4-dropDown'"
              [label]="'Select'"
              [isTheme]="'2.0'"
              [placementPosition]="'bottom'"
              [dataSource]="userSelect"
              (selectionChange)="onDropdownChange($event, 'selectDealer')"
              formControlName="selectDealer"
              [typeahead]="true"
              [typeaheadmanualinput]="true"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
            ></cc-dropdown>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="dropdownLabelList.includes('3rd Party API Integration Tool')">
        <div class="list-label">3rd Party API Integration Tool</div>
        <cc-dropdown
          [id]="'list1-dropDown'"
          [label]="'Select access'"
          [isTheme]="'2.0'"
          [placementPosition]="'bottom'"
          [dataSource]="thirdPartyList"
          (selectionChange)="onDropdownChange($event, 'list1')"
          formControlName="list1"
          [scrollToSelected]="true"
          [selectionOnClose]="true"
          [disabled]="
            this.rowData?.profileType === 'Dealer' &&
            configureAccessForm.controls['selectDealer'].value === '' &&
            (configureAccessForm.value.list1[0].label === 'None' ||
              configureAccessForm.value.list1[0].label === undefined) &&
            (configureAccessForm.value.list2[0].label === 'None' ||
              configureAccessForm.value.list2[0].label === undefined) &&
            (configureAccessForm.value.list3[0].label === 'None' ||
              configureAccessForm.value.list3[0].label === undefined) &&
            !dealerChange &&
            updatedDspAccess === 'None'
          "
        ></cc-dropdown>
      </div>
      <div class="row" *ngIf="dropdownLabelList.includes('Asset Wi-Fi Access')">
        <div class="list-label">Asset Wi-Fi Access</div>
        <cc-dropdown
          [id]="'list2-dropDown'"
          [label]="'Select access'"
          [isTheme]="isTheme"
          [placementPosition]="'bottom'"
          [dataSource]="wifiAccessList"
          (selectionChange)="onDropdownChange($event, 'list2')"
          formControlName="list2"
          [scrollToSelected]="true"
          [selectionOnClose]="true"
          [disabled]="
            this.rowData?.profileType === 'Dealer' &&
            configureAccessForm.controls['selectDealer'].value === '' &&
            (configureAccessForm.value.list1[0].label === 'None' ||
              configureAccessForm.value.list1[0].label === undefined) &&
            (configureAccessForm.value.list2[0].label === 'None' ||
              configureAccessForm.value.list2[0].label === undefined) &&
            (configureAccessForm.value.list3[0].label === 'None' ||
              configureAccessForm.value.list3[0].label === undefined) &&
            !dealerChange &&
            updatedDspAccess === 'None'
          "
        ></cc-dropdown>
      </div>
      <div class="row" *ngIf="dropdownLabelList.includes('Operator Management System')">
        <div class="list-label">Operator Management System</div>
        <cc-dropdown
          [id]="'list3-dropDown'"
          [label]="'Select access'"
          [isTheme]="isTheme"
          [placementPosition]="'bottom'"
          [dataSource]="operatorManagementList"
          (selectionChange)="onDropdownChange($event, 'list3')"
          formControlName="list3"
          [scrollToSelected]="true"
          [selectionOnClose]="true"
          [disabled]="
            this.rowData?.profileType === 'Dealer' &&
            configureAccessForm.controls['selectDealer'].value === '' &&
            (configureAccessForm.value.list1[0].label === 'None' ||
              configureAccessForm.value.list1[0].label === undefined) &&
            (configureAccessForm.value.list2[0].label === 'None' ||
              configureAccessForm.value.list2[0].label === undefined) &&
            (configureAccessForm.value.list3[0].label === 'None' ||
              configureAccessForm.value.list3[0].label === undefined) &&
            !dealerChange &&
            updatedDspAccess === 'None'
          "
        ></cc-dropdown>
      </div>
      <div class="dsp-block" *ngIf="dropdownLabelList.includes('Dealer Services Portal (Web & Mobile)')">
        <div class="dsp-list-label">Dealer Services Portal (Web & Mobile)</div>
        <div class="dsp-list-value">
          <cc-status-indicator class="dotallign" [type]="'dot'" [statusType]="'success'">
            <div class="display-value">{{ updatedDspAccess }}</div>

            <button
              cc-btn-tertiary
              cc-btn-large
              [setTheme]="true"
              class="configure-access-redirect"
              [disabled]="
                this.rowData?.profileType === 'Dealer' &&
                configureAccessForm.controls['selectDealer'].value === '' &&
                (configureAccessForm.value.list1[0].label === 'None' ||
                  configureAccessForm.value.list1[0].label === undefined) &&
                (configureAccessForm.value.list2[0].label === 'None' ||
                  configureAccessForm.value.list2[0].label === undefined) &&
                (configureAccessForm.value.list3[0].label === 'None' ||
                  configureAccessForm.value.list3[0].label === undefined) &&
                !dealerChange &&
                updatedDspAccess === 'None'
              "
              (click)="dspConfigureAccess()"
            >
              Configure Access
            </button>
          </cc-status-indicator>
        </div>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer(false)">Cancel</button>
    <button
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="!enableSaveButton()"
      (click)="onSaveConfigureAccess()"
    >
      Save
    </button>
  </div>
</div>
