<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title cursor-changes">{{ data.title }}</div>
      <div class="cc-modal-close">
        <img class="cc-close-semi" src="/assets/icons/closeSemi.svg" [alt]="'closeIcon'" (click)="onNoClick()" />
      </div>
    </div>
    <div class="cc-modal-content">
      <div *ngIf="nodata" class="no-results-found-expanded">
        <img src="../../../../../assets/images/no-data-big.png" height="264" />
        <p class="no-results-text">No data to display</p>
      </div>
      <div class="expanded-donut expanded-donut-chart-wrapper">
        <cc-donut-chart
          *ngIf="!nodata"
          [chartData]="data.chartData"
          [chartConfig]="data.config"
          [donutSize]="donutSizeOption"
          (seriesClick)="connectivityChartClick($event)"
        ></cc-donut-chart>
      </div>
    </div>
  </div>
</div>
