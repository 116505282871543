import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  constructor(private loaderService: LoaderService, public router: Router) {
    this.urlsToNotUse = [
      '/org/customerDtls',
      '/dashboard/deleteQuickLink',
      '/dashboard/addQuickLink',
      '/zuoraBilling/billToDirectCustomers',
      '/servicecatalog/getMinestarServiceDetails',
      '/filters/columnValues?',
      '/assetsearch/v2/fetchAssetLandingFilters',
      '/assetdata/export/',
      '/assetdata/dealerFleet/export',
      '/assetdata/download/',
      'zuoraPreview/getPromotionName',
      '/zuoraPreview/getPaymentPreview',
      '/equipment/requestRemoteConnectionCheckStatus',
      '/assetdata/v2/fetchDealerFleet',
      '/assetdata/v2/fetchDealerFleetCount',
      '/review/fetchAssetsCountForReview',
      '/review/fetchAssetsForReview',
      '/review/changeNewStatus',
      '/user/getMyBillingAccounts',
      '/site/v2/getSitesCount',
      '/site/v2/getSites',
      '/org/v2/subscriptionsV2',
      '/org/subscriptions/count',
      'org/validatePrefix',
      '/review/reviewColumnFilterValues',
      '/changeeventlog/export',
      '/changeeventlog/export/',
      '/changeeventlog/download/',
      '/review/globalSearchCount',
      '/review/globalSearch',
      '/dealerqueue/getWorkListQueue',
      '/dashboard/getCancelReasonCount',
      '/dashboard/getTrendWidgetDetalis',
      '/dashboard/getSubscriptionAssetTrend',
      '/dashboard/connectivitySummary',
      '/makecodes/getMakeCodes',
      '/assetsearch/v2/search',
      '/assetsearch/v3/search',
      '/assetsearch/getassetsearchcount',
      '/zuoraBilling/detailedInvoiceFilterColumnValues',
      '/zuoraBilling/getDetailedInvoicesCount',
      '/zuoraBilling/getDetailedInvoices',
      '/zuoraBilling/exportInvoiceDetailsToExcel',
      '/zuoraBilling/exportInvoiceDetailsPDF',
      '/zuoraBilling/exportCreditMemoDetailsPDF',
      '/zuoraBilling/exportCreditMemoDetailsToExcel',
      '/zuoraBilling/exportDetailedInvoicestoExcel',
      '/factory/assetColumnValues',
      '/factory/assetCount',
      '/factory/fetchAssets',
      '/site/getSitesForWorkList',
      '/zuoraBilling/v2/export',
      '/zuoraBilling/export/',
      '/zuoraBilling/download/',
      '/catGradeControl/validateSerialNumber',
      '/zuoraPreview/v2/getPaymentPreview',
      '/user/getUserSearch',
      '/user/featureToggle/configure',
      '/user/configurable/features',
      '/user/dealer/feature/configure',
      '/catGradeControl/getUpgradeLicenceDetails',
      '/usernotification/v2/getUserNotification',
      '/usernotification/getNotificationMessage',
      '/usernotification/setDontShowFlagForAdminConfig',
      '/usernotification/deleteReleaseNotes',
      '/home/assetSearch',
      '/home/assetSearchCount',
      '/home/customerSearch',
      '/home/customerSearchCount',
      '/home/checkValidOwnership',
    ];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      this.isValidRequestForInterceptor(request.url) &&
      !['/legalModal', '/privacyModal', '/termSaleModal'].includes(this.router.url)
    )
      this.loaderService.show();

    return next.handle(request).pipe(finalize(() => this.loaderService.hide()));
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    for (const address of this.urlsToNotUse) {
      if (new RegExp(address).test(requestUrl)) {
        return false;
      }
    }
    return true;
  }
}
