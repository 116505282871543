import { Component } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent {
  

}
