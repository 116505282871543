<div class="cc-modal-header header-first">
  <div class="header-title">Dealer Services Portal | Terms of Sale</div>
</div>
<div class="legal-page-content header-second">
  <div>
    <p><strong>DEALER SERVICES PORTAL TERMS OF SALE</strong></p>
    <p><strong>1. General.</strong></p>
    <p>
      <strong
        >THESE TERMS CONTAIN VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS,
        LIMITATIONS, AND EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY. IN ADDITION. THESE TERMS CONTAIN
        MANDATORY ARBITRATION OF DISPUTES PROVISIONS IN SECTION 21(g) THAT REQUIRE THE USE OF ARBITRATION ON AN
        INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.</strong
      >
    </p>
    <p>
      These terms of sale (these “Terms”) govern your purchase and resale of Caterpillar online service products
      (<strong>“Digital Services”</strong>) offered from time to time for sale on
      <a target="_blank" href="{{ dynamicUrl }}" style="color: #2595ff">{{ dynamicUrl }}</a> (the
      <strong>“Site”</strong>).
    </p>
    <p>
      This is an agreement between the authorized Caterpillar dealer you represent (<strong>“Dealer”</strong> or
      <strong>“you”</strong>) and Caterpillar Inc., a Delaware corporation with offices located at 100 N.E. Adams St.,
      Peoria, IL 61629 or, where applicable, our subsidiary with which you have entered a Sales and Service Agreement
      and that makes one or more of the Digital Services available to you (<strong>“Caterpillar,” “we,” “us,”</strong>
      or <strong>“our”</strong>).
    </p>
    <p>
      Caterpillar and its Affiliates are willing to sell Digital Services to you solely upon your acceptance of all of
      these Terms without modification.
    </p>
    <p>
      Due to the telecommunication service license requirement in the People’s Republic of China (<strong
        >“PRC,”</strong
      >
      excluding Hong Kong, Macao and Taiwan for the purpose of these Terms), Digital Services will be provided in PRC
      through a third party vendor holding the required licenses (<strong>“Licensed Vendor”</strong>). Therefore, these
      Terms do NOT apply to China dealers. The sales of Digital Services to China dealers shall be subject to a separate
      agreement which will be entered into by and between such Licensed Vendor and each dealer in due course.
    </p>
    <p>
      <strong
        >BY CLICKING ON “OK,” “ACCEPT,” “AGREE,” “SUBMIT,” OR ANY BUTTON INDICATING ACCEPTANCE OF THESE TERMS OR BY
        PLACING AN ORDER FOR DIGITAL SERVICES FROM THE SITE, YOU AGREE TO BE BOUND BY THESE TERMS AND ARE REPRESENTING
        THAT YOU (a) ARE OF LEGAL AGE TO ENTER INTO THESE TERMS, (b) ARE AUTHORIZED TO PURCHASE DIGITAL SERVICES, (c)
        ARE LAWFULLY ABLE TO ENTER INTO THESE TERMS, AND (d) HAVE THE LEGAL AUTHORITY TO BIND THE AUTHORIZED CATERPILLAR
        DEALER YOU REPRESENT. IF YOU DO NOT AGREE TO THESE TERMS OR ANY SUBSEQUENT MODIFICATION, DO NOT PURCHASE ANY
        DIGITAL SERVICES FROM THE SITE.</strong
      >
    </p>
    <p>
      <strong
        >YOU MAY NOT ORDER OR OBTAIN DIGITAL SERVICES FROM THE SITE IF YOU (a) DO NOT AGREE TO THESE TERMS, (b) ARE NOT
        THE OLDER OF (i) AT LEAST 18 YEARS OF AGE OR (ii) THE LEGAL AGE IN YOUR STATE TO FORM A BINDING CONTRACT WITH
        US, OR (c) ARE PROHIBITED FROM ACCESSING OR USING THE SITE OR ANY OF THE SITE’S CONTENTS OR DIGITAL SERVICES BY
        APPLICABLE LAW.</strong
      >
    </p>
    <p>
      These Terms are subject to change by us without prior written notice at any time, in Caterpillar’s sole
      discretion. Any changes to these Terms will be in effect as of the “Last Updated Date” referenced on the Site. You
      should review these Terms prior to purchasing any Digital Services that are available through the Site. Your
      purchase of Digital Services through the Site after the“Last Updated Date” will constitute your acceptance of and
      agreement to such changes.
    </p>
  </div>
  <div>
    <p><strong>2. Definitions.</strong> The following capitalized terms will have the meanings set forth below:</p>
    <p>
      (a)&nbsp;<strong>“Affiliate” </strong>means any legal entity that controls, is controlled by or is under common
      control with a party hereto, where “control” means ownership of more than 50% of the equity or other interests
      entitled to vote in the election of directors or corresponding managing authority of the Affiliate; provided that,
      if the percentage of control permitted under applicable Laws is below the foregoing percentage, then such
      percentage will apply.
    </p>
    <p>
      (b)&nbsp;<strong>“Customer”</strong> means any individual or legal entity (other than you or your Affiliates) that
      obtains Digital Services for use as an end user, and not for distribution or resale.
    </p>
    <p>
      (c)&nbsp;<strong>“Customer Data”</strong> means (i) all Digital Services Information that a Customer submits or
      otherwise makes available to us under a Customer End User Agreement, and (ii) all Digital Services Information
      that a Customer submits or otherwise makes available to you in connection with a Customer End User Agreement or
      under a separate agreement that provides you with the right to share such information with us.
    </p>
    <p>
      (d)&nbsp;<strong>“Customer End User Agreement”</strong> means the applicable Caterpillar end user agreement
      entered into with a Customer that is used to grant a right and license to the applicable Digital Services to that
      Customer. You may not revise a Customer End User Agreement in any way without Caterpillar’s prior written consent.
    </p>
    <p>
      (e)&nbsp;<strong>“Data Governance Statement” </strong>means the data governance policy located at
      <a
        href="https://www.caterpillar.com/en/legal-notices/data-governance-statement.html"
        target="_blank"
        style="color: #2595ff"
        >https://www.caterpillar.com/en/legal-notices/data-governance-statement.html</a
      >
      or at such other location as designated by Caterpillar from time to time.
    </p>
    <p>
      (f)&nbsp;<strong>“Dealer End User Agreement”</strong> means our then-current Dealer End User Agreement for the
      applicable Digital Service. For the avoidance of doubt, a Dealer End User Agreement may be an Offering Schedule
      under the Master Dealer Technology Services Agreement, between Dealer and Caterpillar.
    </p>
    <p>
      (g)&nbsp;<strong>“Digital Services Information”</strong> means all information collected, transmitted, and
      processed in connection with Digital Services relating to, among other things, Registered Assets and their use and
      operation, as detailed in the Data Governance Statement.
    </p>
    <p>
      (h)&nbsp;<strong>“Global Privacy Statement”</strong> means the global data privacy statement located at
      <a href="http://www.caterpillar.com/dataprivacy" target="_blank" style="color: #2595ff"
        >http://www.caterpillar.com/dataprivacy</a
      >
      or at such other location as designated by Caterpillar from time to time.
    </p>
    <p>
      (i)&nbsp;<strong>“Laws”</strong> means all national, state, provincial, and local laws, rules, regulations,
      directives, statutes, orders, judgements, decrees, rulings, enforceable regulatory guidance, and guidance of
      self-regulatory bodies or organizations.
    </p>
    <p>
      (j) <strong>“Registered Assets”</strong> means machines or equipment owned, leased, or otherwise controlled by a
      Customer that may be enrolled in Customer’s name in Digital Services under a Subscription in accordance with the
      policies and procedures made available by us.
    </p>
    <P>
      (k)&nbsp;<strong>“Subscription”</strong> means, as applicable, (i) a term-based right to access and use Digital
      Services with respect to a Registered Asset that is enrolled for use as part of the Digital Service, (ii) a
      term-based right to access and use Digital Services at an organizational level, which right may be limited to a
      fixed number of Registered Assets or authorized users as detailed in the applicable order for Digital Services, or
      (iii) a right, granted in exchange for a non-recurring payment, to access and use Digital Services in accordance
      with the applicable End User Agreement, that is not term-based and shall not auto-renew.
    </P>
    <p>
      (l)&nbsp;<strong>“Trademarks”</strong> means Caterpillar’s trademarks, trade names, and service marks related to
      the Digital Service and such other marks as Caterpillar may designate from time to time in writing.
    </p>
  </div>
  <div>
    <p>
      <strong>3. Order Acceptance and Cancellation; Resale Authorization. </strong>
    </p>
    <p>
      (a)&nbsp;<span style="text-decoration: underline">Order.</span> Your order is an offer to buy, under these Terms,
      all Digital Services listed in your order. All orders must be accepted by us, or we will not be obligated to sell
      the Digital Services to you. We may choose not to accept orders at our sole discretion, even after we send you a
      confirmation with your order number and details of the Digital Services that you have ordered.
    </p>
    <p>
      (b)&nbsp;<span style="text-decoration: underline">Resale Authorization.</span> Subject to these Terms and pursuant
      to the Sales and Service Agreement between you and Caterpillar (the
      <strong>“Sales and Service Agreement”</strong>), we grant to you the non-exclusive right to resell and, pursuant
      to the terms of the applicable Customer End User Agreement, make available to Customers the Digital Services
      included in your order. Unless otherwise expressly agreed to by us in writing, your Affiliates are not granted any
      rights to sell, make available, or market Digital Services.
    </p>
    <p>
      (c)&nbsp;<span style="text-decoration: underline">Subscriptions.</span> For a Customer to enroll a Registered
      Asset in Digital Services, a Subscription for enrollment of such Registered Asset must have been purchased by the
      Customer from you or directly from us. For a Customer to access and use Digital Services at an organizational
      level, an organizational-level Subscription must have been purchased by Customer from you or directly from us. All
      such Subscriptions will be subject to the terms and conditions of the applicable Customer End User Agreement.
    </p>
    <p>
      (d)&nbsp;<span style="text-decoration: underline">Representations.</span> By placing an order with us, you (i)
      represent and warrant that contemporaneously with the sale to a Customer of the Subscription covered by the order
      you will cause Customer to enter into a Customer End User Agreement with us, and (ii) agree to pay us all fees
      associated with the applicable Subscription. A Customer’s failure to pay you will not relieve you of your payment
      obligations to us under these Terms.
    </p>
    <p>
      (e)&nbsp;<span style="text-decoration: underline">Discontinuance.</span> We may cease making any Digital Services
      available (including for resale by dealers) (i) upon 30 days’ written notice, if we determine that market demand
      no longer warrants continuing to make available such Digital Services, or (ii) immediately, to comply with any
      applicable Laws or a safety related issue. In addition, we may limit any Digital Services’ availability at any
      time, in whole or in part, to any person, geographic area, or jurisdiction, if we reasonably determine that a
      legal or regulatory reason no longer permits the continued making available of Digital Services to such person or
      in such geographic area or jurisdiction. If we cease to make Digital Services available to a Customer, we also
      will suspend or cancel billing to you for that Customer’s Subscriptions to the affected Digital Services.
    </p>
    <p>
      (f)&nbsp;<span style="text-decoration: underline">Audit Rights.</span> During the period for which any Digital
      Offerings included in your order remain active and for two years thereafter, (i) you will maintain complete and
      accurate books, documents, records, papers, and other information and materials related to the transactions
      covered by these Terms (“Records”), and (ii) will provide to us all Records reasonably requested by us within 30
      days of such request. If an audit of such Records reveals material non-compliance with these Terms by you, then
      you will correct such non-compliance and reimburse us for all reasonable fees and expenses incurred by us in
      connection with the audit.
    </p>
  </div>
  <div>
    <p>
      <strong>4. Prices and Payment Terms. </strong>
    </p>
    <p>
      (a)&nbsp;<span style="text-decoration: underline">Pricing.</span> All prices, discounts, and promotions posted on
      the Site or Caterpillar’s Product Link™ Parts and Service Guide (located on<a
        href="https://catdealer.com"
        target="_blank"
        style="color: #2595ff"
      >
        https://catdealer.com</a
      >
      or at such other location as designated by Caterpillar from time to time) are subject to change without notice.
      The price charged for a Subscription will be the price advertised on the Site or Caterpillar’s Product Link Parts
      and Service Guide, as applicable, at the time the order is placed, subject to the terms of any promotions or
      discounts that may be applicable. The price charged will be clearly stated in your order confirmation. We strive
      to display accurate price information, however, we may, on occasion, make inadvertent typographical errors,
      inaccuracies, or omissions related to pricing. We reserve the right to correct any errors, inaccuracies, or
      omissions at any time and to cancel any orders arising from such occurrences. If the price of a Subscription
      increases, we will provide you not less than 30 days’ prior notice through any reasonable means designated by us;
      provided, however, said notice is not required if the increase in the price of a Subscription is due to the
      termination or expiration of a promotion or discount, for whatever reason. Pricing charged to you for a renewal
      term of a Subscription will be in accordance with our pricing in effect as of the commencement of the renewal
      term.
    </p>
    <p>
      (b)&nbsp;<span style="text-decoration: underline">Billing.</span> We may invoice you on a monthly, quarterly, or
      annual basis in arrears or in advance, depending on the type of Subscription purchased, as specified on the Site.
      All payments made by you to us are nonrefundable, except to the extent otherwise provided by us, prohibited by
      applicable Laws, or set forth in Section 12(b). Except to the extent set forth in Section 12(b) or required by
      applicable Laws, under no circumstances will we be obligated to issue a credit to you, including in the event of
      an expiration, termination, disablement, or cancellation of a Subscription.
    </p>
    <p>
      (c)&nbsp;<span style="text-decoration: underline">Payment.</span> You will pay all amounts payable under these
      Terms when due, including all fees for Subscriptions that you purchase for resale. We will issue invoices to you.
      Each invoice will identify the fees payable by you in accordance with the pricing applicable to each Subscription.
      Payments must be remitted to the account identified, and are due on the date and in the currency stated, in the
      invoice or as otherwise set forth in Caterpillar’s Treasury Policies.
    </p>
    <p>
      (d)&nbsp;<span style="text-decoration: underline">Late Payment.</span> Any payments not received by the due date
      will accrue late charges in accordance with Caterpillar’s Treasury Policies, or the maximum rate permitted by
      applicable Laws, whichever is lower. In addition, if we do not receive any payments by the due date, we may,
      without waiving any other rights or remedies to which we may be entitled, take any one or more of the following
      actions: (i) not accept additional orders from you; (ii) suspend or terminate our provision of Digital Services
      for applicable Customers in connection with the unpaid Subscriptions; and (iii) seek collection of all amounts
      due, including reasonable attorney or legal fees, expenses, and costs of collection. We will have no liability to
      you or to any Customer for any such suspension or termination or for any refusal of additional orders. Except to
      the extent such restriction is prohibited by applicable Laws, you may not withhold payment or take deductions from
      any invoice amount, including for returns, rebates, price adjustments, billing errors, shipping claims, handling
      fees, allowances, remittance costs, and other charges.
    </p>
    <p>
      (e)&nbsp;<span style="text-decoration: underline">Taxes.</span> You will be responsible, as required under
      applicable Laws, for identifying and paying all taxes and other governmental fees and charges (and any penalties,
      interest, and other additions thereto) that are imposed on you upon or with respect to the transactions and
      payments under these Terms. All fees payable by you are exclusive of applicable taxes and duties, including VAT,
      HST/GST, excise taxes, sales and transactions taxes, and gross receipts tax (<strong>“Indirect Taxes”</strong>).
      We may charge, and you will pay, all Indirect Taxes that we are legally obligated or allowed to collect from you.
      You will provide all information to us as reasonably required to determine whether we are obligated to collect
      Indirect Taxes from you under any applicable Laws. We will not collect, and you will not pay, any Indirect Tax for
      which you furnish us a properly completed exemption certificate or a direct payment permit certificate for which
      you may claim an available exemption from such Indirect Tax. All payments made by you to us under these Terms will
      be made free and clear of any withholding or deduction for taxes. If any taxes (for example, international
      withholding taxes) are required to be withheld on any payment, you will pay additional amounts as necessary so
      that the net amount received by us is equal to the amount then due and payable under these Terms. All such taxes
      will be added to your total price, and will be itemized in your order confirmation.
    </p>
  </div>
  <div>
    <p>
      <strong>5. Contract Period and Renewal.</strong>
      The contract period for each fixed-term Subscription included in your order will be for the period specified in
      your order, which shall align with the fixed-term Subscription periods offered for the applicable Digital Service
      as described in the Site (<strong>“Contract Period”</strong>). Each Subscription will automatically renew as
      specified in your order unless Caterpillar or you provide written notice to the other of its intent not to renew
      in accordance with the Customer End User Agreement or Dealer End User Agreement, as applicable. If for any reason
      a fixed-term Subscription is terminated prior to the end of the applicable Contract Period and the order covering
      such Subscription specified that a cancellation fee applies to early termination of such Subscription, you will
      pay to us a cancellation fee in an amount calculated as specified in such order.
    </p>
  </div>
  <div>
    <p>
      <strong> 6. New Releases of Digital Services. </strong>
    </p>
    <p>
      (a) We may, at any time, release new Digital Services or modify, upgrade, or release a new version of a Digital
      Service, or any portion of its features and functions. If such modification, upgrade, or other new version results
      in a material degradation of the performance or functionality of such Digital Service as a whole, Customer may
      terminate the affected Subscriptions in accordance with the applicable Customer End User Agreement. Unless
      otherwise expressly and separately agreed by us, any new Digital Services or new version of a Digital Service will
      be subject to the terms of the Customer End User Agreement for such Digital Service.
    </p>
    <p>
      (b) Certain features and functions of Digital Services may be made available based on the specific configuration
      of Registered Assets or only for certain Subscription levels. We reserve the right to introduce new features or
      functionality to Digital Services for Customers’ Subscription levels. Subject to Customer’s termination rights
      upon our implementation of changes to the terms and conditions of Subscriptions or the Customer End User Agreement
      (as set forth in the Customer End User Agreement), you may be required to pay additional fees to us to allow
      Customers to access or use such newly added features or functionality.
    </p>
  </div>
  <div>
    <p>
      7.&nbsp;<strong> Third-Party Offerings. </strong>If the Digital Service you have ordered includes any
      non-Caterpillar-branded software or services that are provided by third parties (“<strong
        >Third-Party Offerings</strong
      >”), your right to resell, making available, and market such Third-Party Offerings will be subject to separate
      terms and conditions required by the third party or us (as applicable), as such terms and conditions may be
      updated from time to time. We assume no responsibility or liability for any Third-Party Offerings. We may, to the
      extent permitted by applicable Laws, change the prices charged to you for Third-Party Offerings and may add,
      modify, or remove Third-Party Offerings at any time with reasonable notice to you.
    </p>
  </div>
  <div>
    <p>
      8.&nbsp;<strong> Subscription Management.</strong> Where specified by us, you may be required to perform certain
      functions associated with the activation, support, and management of Subscriptions. We may update the processes
      and tools needed to perform those functions in our reasonable discretion, and you may be required to implement
      such updates to continue performing such functions.
    </p>
  </div>
  <div>
    <p>
      9.&nbsp;<strong> Limitations.</strong> These Terms only authorize you to sell and make available Digital Services
      directly to Customers pursuant to these Terms and the Sales and Service Agreement. As set forth in greater detail
      in Section 12(a), to access or use any Digital Services, you must first enter into a Dealer End User Agreement
      with respect to such Digital Services. You may not sell, make available, or market Digital Services through
      additional tiers of distribution without our express, written authorization, and then only in accordance with
      specific terms and conditions under a separate agreement with us.
    </p>
  </div>
  <div>
    <p>
      <strong>10. Your Conduct and Restrictions.</strong>
    </p>
    <p>
      (a)<span style="text-decoration: underline"> Your Conduct.</span> You will at all times conduct your activities in
      a professional and competent manner. With respect to all Digital Services you order under these Terms, you will:
      (i) use commercially reasonable efforts to service and support Customers in accordance with the provisions of
      these Terms; (ii) use commercially reasonable efforts to maintain the good name and reputation of both Caterpillar
      and the Digital Services; (iii) not engage in any illegal, false, or deceptive acts or practices with respect to
      your business activities; and (iv) use commercially reasonable efforts to notify us about any known or suspected
      violations of the terms and conditions of the Customer End User Agreement by a Customer.
    </p>
    <p>
      (b)<span style="text-decoration: underline"> Product Warranties.</span> Your instructions to Customers on access
      to or use of Digital Services must be consistent with the warranties set forth in the applicable Customer End User
      Agreement. Other than as set forth in the applicable Customer End User Agreement, you will not make any
      representation, condition, or warranty about Digital Services.
    </p>
    <p>
      (c)<span style="text-decoration: underline"> Marketing and Promotions.</span> You will only use promotional and
      marketing materials relating to Digital Services that comply with our brand and marketing guidelines (<strong
        >“Marketing Materials”</strong
      >) and in accordance with your written agreements with us governing your use of our trademarks. You will be solely
      responsible for all costs related to the Marketing Materials.
    </p>
    <p>
      (d)<span style="text-decoration: underline"> Restrictions.</span> Except as expressly provided in these Terms or
      to the extent such restriction is expressly prohibited by applicable Laws, you may not (i) reproduce, modify,
      adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute, or otherwise exploit
      any portion of (or any use of) Digital Services, or (ii) reverse engineer, decompile or disassemble any portion of
      Digital Services.
    </p>
  </div>
  <div>
    <p>
      <strong>11. Negotiation of Pricing and Payment Terms.</strong> You have discretion to negotiate and set pricing
      and payment terms and conditions with respect to sale of Subscriptions to Customers. Your negotiation of those
      terms will not be subject to our review or approval in any way; provided that such terms must not be inconsistent
      with the applicable Customer End User Agreement.
    </p>
  </div>
  <div>
    <p><strong>12. Customer End User Agreement and Dealer End User Agreement.</strong></p>
    <p>
      (a) Your right to sell and make available Subscriptions to a Customer is conditioned on such Customer entering
      into a Customer End User Agreement with Caterpillar for the applicable Digital Services contemporaneously with
      your selling or otherwise making available the Subscription to the Customer. With respect to particular Digital
      Services, we may also condition your right to sell and make available Subscriptions on your entering into a Dealer
      End User Agreement with us for the applicable Digital Services. Except to the extent prohibited by applicable
      Laws, we may change the terms and conditions of any Customer End User Agreement or Dealer End User Agreement or
      any then-current Customer’s or your Subscription in accordance with the terms set forth in the applicable Customer
      End User Agreement or Dealer End User Agreement. We will notify you of any such change in accordance with Section
      21(h) at least 30 days prior to the change going into effect.
    </p>
    <p>
      (b) If a Customer End User Agreement provides that you will refund to Customer for any reason any fees paid by
      Customer to Dealer for the purchase of Subscriptions, you will refund to Customer all such amounts when due. Upon
      written notice by you to us detailing the circumstances of such refund, we will promptly reimburse you for refunds
      you made to Customer in accordance with the Customer End User Agreement, provided that in no event will we be
      obligated to reimburse you for a refund made for a Subscription in excess of the amount paid by you to us for the
      relevant period of such Subscription to which the reimbursements relate.
    </p>
    <p>
      (c) If Customer is a state or local government entity in the United States or a government entity (whether
      national, federal, state, provincial, or local) outside of the United States and the application of terms
      governing access to or use of the Digital Services by such entity set forth in the Customer End User Agreement
      (e.g., Section may be titled “Government Entity Rights and Obligations”) to any particular facts or circumstances
      is invalid or unenforceable with respect to such entity, the parties will negotiate in good faith a valid, legal,
      and enforceable substitute addendum to the Customer End User Agreement in place of such terms that most closely
      reflects the original intent of the parties with respect to the parties’ economic and business interests. All
      other provisions in the Customer End User Agreement will remain in full force and effect in such jurisdiction and
      will be liberally construed to carry out the intentions of the parties hereto as nearly as may be possible.
    </p>
  </div>
  <div>
    <p>
      <strong>13. Support.</strong> You will provide support services to Customers for Digital Services you order
      pursuant to these Terms in accordance with any guidelines designated by us from time to time or as otherwise
      reasonably determined by us. You will be responsible and the main point of contact for Customers for all
      operational or technical support questions related to Digital Services you order pursuant to these Terms. In
      addition, we may ask you to also provide support pursuant to these Terms for those Customers who have obtained
      rights to use a Digital Services in the context of a Registered Asset which the Customer has brought into your
      service territory. If a Customer contacts us directly for support, we, at our sole discretion, may offer Customer
      support or redirect Customer to you. You will promptly investigate and report to us all complaints received by you
      about Digital Services. In handling any complaints regarding Digital Services, you will use commercially
      reasonable efforts to maintain and promote good public relations for us.
    </p>
  </div>
  <div>
    <p>
      <strong>14. Data Protection.</strong>
    </p>
    <p>
      (a)<span style="text-decoration: underline"> Data Rights.</span> We may collect, use, share, transfer, disclose,
      and otherwise process Customer’s Digital Services Information, including System Data, Operations Data, Personal
      Information, and Device Location Information, as defined and described in the Data Governance Statement, the
      Global Privacy Statement, and the specific Privacy Notice for the applicable Digital Service (collectively, the
      “<strong>Data Governance Documents</strong>”). The Data Governance Documents may change from time to time, and you
      agree to review them with regularity and care. If the Data Governance Documents change, we will notify you by
      indicating in accordance with Section 21(h) or by other reasonable means. Specific information collected,
      transmitted, and processed may change over time and vary based on, among other things, the Registered Assets, the
      type of Digital Services, and as detailed in the Data Governance Documents. Digital Services Information may be
      transmitted to and processed in countries that have different data protection laws than in the country in which
      Customer or you have your respective principal place of business.
    </p>
    <p>
      (b)<span style="text-decoration: underline"> Your Obligations Generally.</span> You will (i) comply with all
      applicable Laws regarding privacy and data security with respect to all Customer Data, including Personal
      Information therein, (ii) not share Customer Data with any third party other than Caterpillar or its Affiliates or
      their respective licensors, service providers, suppliers, and distributors, unless authorized to do so by such
      Customer, (iii) provide a copy of all applicable Data Governance Documents to each Customer and cause each
      Customer to acknowledge receipt of such Data Governance Documents contemporaneously with the sale of Digital
      Services to such Customer, and (iv) take all actions necessary to grant to us the right to collect, use, and share
      Customer Data as contemplated by the Data Governance Documents.
    </p>
    <p>
      (c)<span style="text-decoration: underline"> Sharing of Customer Data.</span> You represent and warrant that, to
      the extent you provide any data to Caterpillar or its Affiliates, you have obtained the right to disclose and
      share such data with Caterpillar and its Affiliates and each of their respective licensors, service providers,
      suppliers, and distributors, including all required consents, in accordance with the Data Governance Documents.
    </p>
    <p>
      <span style="text-decoration: underline"> (d) Digital Services Information and Customer Data.</span>
    </p>
    <p>
      (i) The security, privacy, and data protection commitments made by us in any Customer End User Agreement only
      apply to Digital Services provided by us and not to any products or services provided by you.
    </p>
    <p>
      (ii) You may only use Digital Services Information in accordance with and for the purposes specified in the Dealer
      End User Agreement for the applicable Digital Services. If you have not entered into a Dealer End User Agreement
      or the Dealer End User Agreement is no longer in effect, you may not use Digital Services Information for any
      reason. Additionally, you may only access, disclose, and share Digital Services Information to law enforcement
      agencies, other government or public authorities or individuals to the extent required by applicable Laws.
    </p>
    <p>
      (iii) If either party or any of its Affiliates receive any order, demand, warrant, or any other document
      requesting or purporting to compel the production of Personal Information in Customer Data (including, for
      example, by oral questions, interrogatories, requests for information or documents in legal proceedings,
      subpoenas, civil investigative demands, or other similar processes), such party or its Affiliate will promptly
      notify the applicable Customer (to the extent permissible under applicable Laws), so that such Customer may, at
      its own expense, exercise such rights as it may have under applicable Laws to prevent or limit such disclosure.
      Notwithstanding the foregoing, such party or its Affiliate (as applicable) will exercise commercially reasonable
      efforts to prevent and limit any such disclosure and to otherwise preserve the confidentiality of Personal
      Information in such Customer Data and will cooperate with Customer with respect to any action taken with respect
      to such request, complaint, order, or other document, including to obtain an appropriate protective order or other
      reliable assurance that confidential treatment will be accorded to such Personal Information.
    </p>
    <p>
      (iv) Each party will use reasonable organizational, technical, and administrative measures to protect Digital
      Services Information it receives or collects, and it will protect such Digital Services Information in transit
      when it shares Digital Services Information with the other party and its Affiliates and each of their respective
      licensors, service providers, suppliers, or distributors.
    </p>
  </div>
  <div>
    <p>
      <strong> 15. Limited Warranty. </strong>
    </p>
    <p>
      (a)<span style="text-decoration: underline"> Warranty.</span> Our warranties with respect to each Digital Service
      are set forth in the applicable Customer End User Agreement or Dealer End User Agreement, as applicable.
    </p>
    <p>
      (b)<span style="text-decoration: underline"> Disclaimer.</span> EXCEPT AS PROVIDED FOR IN SECTION 15(a),
      CATERPILLAR AND ITS AFFILIATES, AND EACH OF THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, SUPPLIERS, AND
      DISTRIBUTORS (THE “<strong>CATERPILLAR PARTIES</strong>”) PROVIDE NO CONDITIONS OR WARRANTIES, WHETHER EXPRESS,
      IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WARRANTIES AND CONDITIONS OF MERCHANTABILITY OR FITNESS FOR A
      PARTICULAR PURPOSE IN CONNECTION WITH THESE TERMS. WITHOUT LIMITING THE FOREGOING, NONE OF THE CATERPILLAR PARTIES
      PROVIDE ANY WARRANTY THAT ANY DIGITAL SERVICES WILL BE FREE FROM ERRORS OR INTERRUPTION (INCLUDING INTERRUPTIONS
      DUE TO CYBERATTACKS OR MALICIOUS CODE OR OTHERWISE) OR BE COMPATIBLE WITH ANY HARDWARE OR SOFTWARE NOT EXPLICITLY
      SPECIFIED IN THE DOCUMENTATION FOR THE APPLICABLE SUBSCRIPTION. THE CATERPILLAR PARTIES DISCLAIM ANY
      RESPONSIBILITY FOR ANY HARM RESULTING FROM ACCESS TO OR USE OF DIGITAL SERVICES. SOME JURISDICTIONS DO NOT ALLOW
      THE EXCLUSION OF EXPRESS OR IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG SUCH WARRANTIES LAST, SO THE EXCLUSIONS
      OR LIMITATIONS IN THIS SECTION 15(b) MAY NOT APPLY. THE FOREGOING EXCLUSIONS AND LIMITATIONS ARE NOT INTENDED TO
      APPLY TO DEATH OR BODILY INJURY TO THE EXTENT DIRECTLY CAUSED BY A CATERPILLAR PARTY’S GROSS NEGLIGENCE OR WILLFUL
      MISCONDUCT. DIGITAL SERVICES ARE NOT INTENDED TO PROVIDE ANY EMERGENCY, MISSION CRITICAL, OR SAFETY RELATED
      FUNCTIONALITY. WE DO NOT GUARANTEE ANY RESULTS OR THE ACCURACY OF ANY RESULTS THAT YOU MAY OBTAIN FROM DIGITAL
      SERVICES.
    </p>
  </div>
  <div>
    <p>
      <strong>16. Indemnification.</strong>
    </p>
    <p>
      (a) You will defend, indemnify, and hold harmless Caterpillar and its Affiliates, and each of their respective
      licensors, service providers, suppliers, and distributors (other than you) and each of their respective employees,
      officers, directors, and representatives (individually and collectively, the<strong>
        “Caterpillar Indemnified Party(ies)”</strong
      >) from and against any claims, damages, losses, liabilities, costs and expenses (including reasonable attorneys’
      fees) (<strong>“Losses”</strong>) arising out of or relating to any third-party claim, suit, action, or proceeding
      (<strong>“Claim”</strong>) concerning (i) the marketing, resale, or making available of Digital Services by you in
      a manner not authorized by these Terms and the Sales and Service Agreement; (ii) a breach by you of any of your
      obligations, representations, or warranties in Sections 3, 6-10, 12, 14, 17-19, 21(k), and 21(l); (iii)
      noncompliance by you with any Laws, including with respect to the obtaining of any permits, licenses, or other
      government authorizations; (iv) false or misleading sales, marketing, or promotional materials, terms, warranties,
      or related information regarding Digital Services provided by you or your failure to meet any promises,
      warranties, policies, or guidelines that you provided in relation to Digital Services; (v) the infringement or
      misappropriation of any intellectual property rights or other proprietary rights, including rights of privacy or
      publicity, of any third party by any promotional or marketing materials developed or created by or on behalf of
      you; (vi) taxes, fees, interest, or penalties imposed on us as a consequence of your resale of Digital Services;
      or (vii) any dispute between you and any Customer, except to the extent that such dispute directly results from a
      breach by us of such Customer’s Customer End User Agreement.
    </p>
    <p>
      (b) The Caterpillar Indemnified Party will promptly notify you of any Claim subject to this Section 16; provided,
      however, that any such failure to notify will not relieve you of your indemnification obligations under this
      Section 16 unless, and only to the extent that, you can demonstrate that you were prejudiced by such failure. The
      Caterpillar Indemnified Party will give you reasonable assistance in connection with and full control and
      authority over the defense and settlement of the applicable Claim, except that: (i) no settlement requiring the
      Caterpillar Indemnified Party to admit liability or to pay any money or otherwise take on any obligation may be
      made without the prior written consent of the Caterpillar Indemnified Party; and (ii) the Caterpillar Indemnified
      Party may join in the defense with the Caterpillar Indemnified Party’s own counsel at the Caterpillar Indemnified
      Party’s own cost and expense.
    </p>
  </div>
  <div>
    <p>
      <strong>17. Intellectual Property.</strong>
    </p>
    <p>
      (a) Except as expressly granted herein, neither party is granted any rights or licenses, whether express or
      implied, under the other party’s intellectual property rights. Digital Services are licensed, not sold. References
      in these Terms to the “purchase” or “sale” of Subscriptions refer to the licenses to access and use Digital
      Services hereunder. Nothing in these Terms or otherwise will be deemed to grant to you an ownership interest in
      any of the intellectual property rights in or to any of Digital Services.
    </p>
    <p>
      (b) Your right to indicate to the public that it is an authorized reseller of Digital Services and to use the
      Trademarks shall be subject to your written agreements with Caterpillar governing your use of the Trademarks.
    </p>
  </div>
  <div>
    <p>
      <strong>18. Confidentiality.</strong>
    </p>
    <p>
      (a) <strong>“Confidential information”</strong> means all nonpublic information or material disclosed by
      Caterpillar or its Affiliates, or any of their respective licensors, service providers, or suppliers or any other
      third party performing services on behalf of any of the foregoing, that (i) is designated as proprietary,
      confidential, or with similar designation, (ii) is disclosed orally or in other intangible form, identified at the
      time of disclosure as proprietary, confidential, or with similar designation and is reduced to written summary to
      indicate it that it is confidential within 30 days of disclosure thereof, or (iii) is given the nature of the
      disclosure or receipt of the information or material or the circumstances surrounding the disclosure or receipt of
      the information or material, you should reasonably understand to be confidential. Digital Services, including
      their structure, sequence and organization, together with all reports and results generated by Digital Services
      (except to the extent such reports and results are provided to Customers as part of their authorized use of
      Digital Services), are Confidential Information of Caterpillar or its Affiliates, or any of their licensors,
      service providers, suppliers or distributors. These Terms and the Customer End User Agreements are Caterpillar’s
      Confidential Information; provided, however, that you may disclose the standard form of the applicable Customer
      End User Agreement to Customers or prospective Customers who: (A) have a need to know such Confidential
      Information in order to fulfill the purposes of these Terms; and (B) have been instructed that all such
      Confidential Information is to be handled in strict confidence.
    </p>
    <p>
      (b) You agree (i) to protect Confidential Information from unauthorized dissemination and use; (ii) to use
      Confidential Information only for the performance of the transactions contemplated by these Terms and the exercise
      of any rights under these Terms; (iii) not to disclose any Confidential Information to any of your employees,
      agents, contractors, or any other individuals except to your employees and contractors who are under
      confidentiality obligations no less restrictive than the requirements of this Section 18(b); and (iv) not to
      remove or destroy any proprietary or confidential legends or markings placed upon or contained within the
      Confidential Information. You will not be liable for disclosure of Confidential Information which: (A) you already
      knew without an obligation to maintain the information as confidential; (B) you received from a third party
      without breach of an obligation of confidentiality owed to the other party; (C) you independently developed; or
      (D) becomes publicly known through no wrongful act by you.
    </p>
    <p>
      (c) Confidential Information may be disclosed as required by a judicial or other governmental order, provided that
      you will: (i) give reasonable notice of the order to us to allow us a reasonable opportunity to seek a protective
      order or otherwise prevent or restrict its disclosure; or (ii) obtain written assurance from the entity issuing
      the order that it will protect the Confidential Information to the extent allowed under applicable Laws.
    </p>
    <p>
      (d) You agree that, due to the unique nature of Confidential Information, the unauthorized disclosure or use of
      Confidential Information may cause irreparable harm and significant injury to us, the extent of which will be
      difficult to ascertain and for which there will be no adequate remedy at law. Accordingly, you agree that we may
      seek an injunction or equitable relief to enjoin breach or threatened breach of this Section 18 without the
      necessity of posting any bond or other security.
    </p>
  </div>
  <div>
    <p><strong>19. Limitations of Liability.</strong></p>
    <p>
      (a) EXCEPT FOR THE PARTIES’ RESPECTIVE OBLIGATIONS UNDER SECTION 3(b) (RESALE AUTHORIZATION), SECTION 3(d)
      (REPRESENTATIONS), SECTION 3(f) (AUDIT RIGHTS), 4 (PRICES AND PAYMENT TERMS), SECTION 5 (CONTRACT PERIOD AND
      RENEWAL), SECTION 10 (LIMITATIONS), SECTION 12 (CUSTOMER END USER AGREEMENT AND DEALER END USER AGREEMENT),
      SECTION 14 (DATA PROTECTION), SECTION 16 (INDEMNIFICATION), SECTION 17 (INTELLECTUAL PROPERTY), OR SECTION 19
      (CONFIDENTIALITY), (i) NONE OF THE CATERPILLAR PARTIES WILL BE LIABLE TO YOU, NOR WILL YOU OR ANY OF YOUR
      AFFILIATES, LICENSORS, SERVICE PROVIDERS, SUBCONTRACTORS, OR SUPPLIERS BE LIABLE TO US, UNDER ANY CAUSE OF ACTION
      OR THEORY OF LIABILITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, FOR ANY (A) INDIRECT, INCIDENTAL,
      SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, (B) LOSS OF PROFITS, REVENUES, DATA, CUSTOMERS, OPPORTUNITIES,
      BUSINESS, ANTICIPATED SAVINGS OR GOODWILL, OR (C) UNAVAILABILITY OF ANY OR ALL OF DIGITAL SERVICES. THE FOREGOING
      LIMITATIONS WILL NOT APPLY TO THE EXTENT SUCH DISCLAIMERS ARE PROHIBITED BY APPLICABLE LAWS, AND (ii) EXCEPT TO
      THE EXTENT PROHIBITED BY APPLICABLE LAWS, THE AGGREGATE LIABILITY OF THE CATERPILLAR PARTIES, ON THE ONE HAND, OR
      YOU AND YOUR AFFILIATES, LICENSORS, SERVICE PROVIDERS, SUBCONTRACTORS, OR SUPPLIERS, ON THE OTHER HAND, UNDER OR
      IN CONNECTION WITH THESE TERMS WILL NOT EXCEED THE TOTAL AMOUNTS PAID BY YOU TO US IN CONNECTION WITH THE DIGITAL
      OFFERINGS PURCHASED BY YOU UNDER THESE TERMS DURING THE TWELVE (12) MONTHS PRECEDING THE DATE OF LAST CAUSE OF
      ACTION TO ARISE HEREUNDER. FOR CLARITY, THE FOREGOING CONSTITUTES A CUMULATIVE CAP THAT IS APPLIED TO ALL CAUSES
      OF ACTION HEREUNDER AND IS NOT TO BE APPLIED ON A CLAIM-BY-CLAIM BASIS.
    </p>
    <p>
      (b) NOTHING IN THESE TERMS EXCLUDES OR LIMITS A PARTY’S LIABILITY TO THE OTHER PARTY FOR: (i) DEATH OR BODILY
      INJURY DIRECTLY CAUSED BY A PARTY’S GROSS NEGLIGENCE; OR (ii) FRAUD OR WILLFUL MISCONDUCT.
    </p>
  </div>
  <div>
    <p><strong>20. Termination.</strong></p>
    <p>
      (a)<span style="text-decoration: underline"> Termination for Convenience.</span> Either party may terminate these
      Terms at any time, with or without cause, by giving the other party not less than 90 days’ written notice. Subject
      to any cancellation fee due under Section 5, neither party will have to pay the other party any costs or damages
      resulting solely from termination of these Terms.
    </p>
    <p>
      (b)<span style="text-decoration: underline"> Termination for Cause.</span> We may terminate these Terms
      immediately upon notice if (i) you commit a felony or engage in any unlawful or unfair business practice, (ii)
      there is a material change in or transfer of your management, ownership, control, or business operations or you
      become affiliated, through common management, ownership, or control, with any third party without our prior
      written consent, (iii) your actions expose or threaten to expose us to any liability, obligation or violation of
      applicable Laws, (iv) we determine use of Digital Services by Customers has become impractical or unfeasible for
      any legal or regulatory reason, or (v) in order to comply with applicable Laws or requests of governmental
      entities.
    </p>
    <p>
      (c)<span style="text-decoration: underline"> Termination for Breach.</span> Either Party may terminate these Terms
      upon written notice to the other party if the other party is in material breach of these Terms and does not cure
      such breach within thirty (30) days after delivery of a written notice of such breach by the non-breaching party
      stating its intent to terminate. In addition, (a) Caterpillar may immediately terminate or suspend these Terms if
      Dealer is in breach of any other agreement with Caterpillar and, to the extent such agreement allows for cure
      prior to a termination, such breach is not timely cured in accordance with such agreement, and (b) these Terms
      shall immediately and automatically terminate if any of the distribution agreements between Dealer and Caterpillar
      (or its Affiliates) expires, is terminated or otherwise no longer in effect.
    </p>
    <p>
      (d)<span style="text-decoration: underline"> Automatic Termination.</span> In addition to the foregoing, these
      Terms will terminate automatically if (i) a petition in bankruptcy is filed by or against you, (ii) any action is
      taken by or against you under any applicable Laws, the purpose or effect of which is or may be to relieve you in
      any manner from any of your debts, or to extend the time of payment thereof, (iii) if you make an assignment for
      the benefit of creditors or make any conveyance of any of your property which, in our opinion, may be to the
      detriment of your creditors, (iv) if a receiver or trustee is appointed with authority to take possession of your
      property or of any part thereof, (v) you become insolvent or cease to operate in the ordinary course; or (vi) the
      Sales and Service Agreement expires, is terminated or is otherwise no longer in full force and effect for any
      reason.
    </p>
    <p>
      (e)<span style="text-decoration: underline"> Effect of Termination.</span> Upon termination of these Terms, (i)
      all rights granted under these Terms to you will cease and you will immediately pay us all amounts due under these
      Terms; (ii) you will immediately provide to us such information and cooperation as is reasonably required to
      ensure a smooth transition of Subscriptions, including provision of Customer contact information; and (iii) upon
      our written request, you will immediately assign all of your agreements with Customers with respect to Digital
      Services, either to us or our designees, in our sole discretion. We will not be liable to you for any termination
      compensation or indemnity or other liability (e.g., for loss of resellership or of any business opportunity) upon
      termination under this Section 20 for any reason.
    </p>
    <p>
      (f)<span style="text-decoration: underline"> Survival.</span> Sections 3(f) (Audit Rights), 4(b) (Billing), 4(c)
      (Payment), 14 (Data Protection), 15(e) (Limited Warranty), 16 (Indemnification), 17 (Intellectual Property), 19
      (Limitations of Liability), 20(e) (Effect of Termination), 20(f) (Survival), and 21 (Miscellaneous) will survive
      any termination of these Terms.
    </p>
  </div>
  <div>
    <p><strong>21. Miscellaneous.</strong></p>
    <p>
      (a)<span style="text-decoration: underline"> Force Majeure.</span> Neither Caterpillar, its Affiliates, or any of
      Caterpillar’s or its Affiliates’ respective licensors, service providers, subcontractors, suppliers, or
      distributors, nor you, any of your Affiliates, or any of your or your Affiliates’ respective service providers or
      subcontractors will be responsible for any delays or failures to perform any obligation under these Terms to the
      extent that such delays or failures result from any cause beyond such person or entity’s reasonable control,
      including fires, blockages, embargoes, explosion, earthquake, storms or other elements of nature, acts of
      terrorism, wars, epidemics, government requirements, civil or military authorities, acts of God, strikes, labor
      disputes or other industrial disturbances, systemic electrical, telecommunications, or other utility failures.
    </p>
    <p>
      (b)<span style="text-decoration: underline"> Assignment.</span> Neither party hereto may assign or transfer these
      Terms or its rights or obligations under these Terms, whether by contract or by operation of law, without the
      prior consent of the other party; provided that we may assign or transfer these Terms or its rights or obligations
      under these Terms without your consent to our Affiliates or in connection with a merger, consolidation, corporate
      reorganization, or sale of all or substantially all of our business or assets or of the business or assets
      associated with our Digital Services business. Subject to the preceding sentence, the rights and liabilities of
      the parties hereto will bind, and inure to the benefit of each of the parties’ respective assignees and successors
      and is binding on the parties and their successors and assignees. Any attempted assignment other than in
      accordance with this Section 21(b) will be null and void.
    </p>
    <p>
      (c)<span style="text-decoration: underline"> Third-Party Beneficiaries. </span> These Terms do not create any
      third-party beneficiary rights in any individual or entity that is not a party to these Terms.
    </p>
    <p>
      (d)<span style="text-decoration: underline"> Relationship. </span>Caterpillar and you are independent parties for
      all purposes relating to these Terms. You do not have the power to bind Caterpillar. You do not have the power to
      change any terms, conditions, warranties, or covenants made by Caterpillar. You do not have the power to give any
      person or entity any rights that Caterpillar has not previously authorized in writing. The relationship of the
      parties under these Terms will not constitute a partnership or joint venture for any purpose. Caterpillar and you
      do not have any agency, franchise or fiduciary relationship.
    </p>
    <p>
      (e)<span style="text-decoration: underline"> Waiver.</span> No waiver of any breach of these Terms will be a
      waiver of any other breach, and any waiver must be in writing and signed by an authorized representative of the
      waiving party.
    </p>
    <p>(f)<span style="text-decoration: underline"> Governing Law.</span></p>
    <p>
      (i) These Terms (including the arbitration provisions set forth in Section 21(g)) will be governed by and
      interpreted in accordance with the Laws of the State of New York and the federal Laws of the United States,
      without reference to conflict of the principles of said laws.
    </p>
    <p>
      (ii) These Terms will not be governed by the United Nations Convention on Contracts for the International Sale of
      Goods and/or its implementation and/or successor legislation and/or regulations, the application of which is
      expressly excluded.
    </p>
    <p>(g) <span style="text-decoration: underline">Arbitration.</span></p>
    <p>
      (i) All disputes, claims, and controversies relating to or arising out of these Terms (collectively,
      <strong>“Dispute”</strong>) will be resolved by binding arbitration, rather than in court. The Federal Arbitration
      Act applies to the arbitration of such Disputes.
    </p>
    <p>
      (ii) Arbitration will be administered by the American Arbitration Association (AAA) in accordance with the AAA’s
      Commercial Arbitration Rules and Mediation Procedures. The arbitration rules specified in this subsection are
      referred to in this Section 21(g) as the <strong>“Rules”</strong>.
    </p>
    <p>
      (iii) Either party may commence the arbitration process called for by these Terms by filing a written demand for
      arbitration with the applicable arbitration organization and delivering a copy of such demand to the other party
      to these Terms in accordance with the notice provision of these Terms. In no event will demand for arbitration be
      made or permitted after the date when the institution of legal or equitable proceedings based on such Dispute
      would be barred by the applicable statute of limitations. The place of arbitration will be Chicago, Illinois,
      United States. The arbitration will be conducted in English.
    </p>
    <p>
      (iv) Except to the extent prohibited under applicable Laws, you agree that any dispute resolution proceedings will
      be conducted only on an individual basis and not in a class, consolidated, or representative action and that the
      parties are waiving the right to a trial by jury. If for any reason a claim proceeds in court rather than in
      arbitration, each party waives any right to a jury trial.
    </p>
    <p>
      (v) If the Dispute is for an amount less than US$100,000, the arbitration will be heard in front of a single
      arbitrator, and if the Dispute is for an amount of US$100,000 or more, then the Dispute will be heard by a panel
      of three arbitrators. If the Dispute is to be heard in front of a single arbitrator, the parties will attempt to
      mutually agree on the identity of the arbitrator, or if no such agreement can be reached within 30 days of the
      commencement of the arbitration proceedings, the applicable arbitration organization will appoint such arbitrator
      in accordance with the Rules. If the Dispute is to be heard in front of a panel of three arbitrators, each party
      will nominate one arbitrator from a list of arbitrators provided by the applicable arbitration organization, and
      the two party-nominated arbitrators will select the third arbitrator who will serve as chairman. Notwithstanding
      anything to the contrary herein, either party may seek injunctive relief in a court of competent jurisdiction to
      prevent irreparable harm from occurring at any time. Each party will bear its own cost of prosecuting or defending
      the arbitration (excluding any attorneys’ or other professional fees), and the parties will split the arbitrators’
      fees and the applicable arbitration organization’s administrative costs, regardless of the outcome. The provisions
      of this Section 21(g) and judgment upon the award rendered by the arbitrator may be enforced by any court of
      competent jurisdiction. The arbitrators will render their decision as soon as reasonably possible after its
      appointment and must follow the terms of these Terms.
    </p>
    <p>
      (vi) This agreement to arbitrate will be specifically enforceable in any court having jurisdiction thereof. Any
      award issued by the arbitrator pursuant to any arbitration will be final and binding upon the parties, and
      judgment may be entered upon in accordance with applicable Laws in any court of competent jurisdiction.
    </p>
    <p>
      (h) Notices. Any notice, request, demand or other communication required or permitted hereunder will be in
      writing, will reference these Terms and, unless otherwise provided by applicable Laws, notices will be deemed to
      be properly given: (i) when delivered personally; (ii) seven days after having been sent by registered or
      certified mail, return receipt requested or postage prepaid; (iii) two business days after deposit with a private
      industry express courier, with written confirmation of receipt; or (iv) if sent by email, when the sender receives
      an automated message confirming delivery. All notices will be sent to the address set forth below (or to such
      other address as may be designated by a party by giving written notice to the other party pursuant to this Section
      21(h)).
    </p>
    <table>
      <tr>
        <td
          style="
            width: 202.5pt;
            border: solid windowtext 1pt;
            background: #d9d9d9;
            padding: 0cm 5.4pt 0cm 5.4pt;
            height: 16.6pt;
          "
        >
          <strong>Notice for Caterpillar</strong>
        </td>
        <td
          style="
            width: 202.5pt;
            border: solid windowtext 1pt;
            background: #d9d9d9;
            padding: 0cm 5.4pt 0cm 5.4pt;
            height: 16.6pt;
          "
        >
          <strong>Notice for You</strong>
        </td>
      </tr>
      <tr>
        <td
          style="
            width: 202.5pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0cm 5.4pt 0cm 5.4pt;
            text-align: center;
            vertical-align: top;
          "
        >
          Email
          <a href="mailto:CatDigitalSupport@cat.com" target="_blank" style="color: #2595ff"
            >CatDigitalSupport&#64;cat.com</a
          >
        </td>
        <td
          style="
            width: 202.5pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0cm 5.4pt 0cm 5.4pt;
            text-align: center;
            vertical-align: top;
          "
        >
          Contact information as set forth in Caterpillar’s Dealer Directory, which may be updated as described at
          <a href="https://catdealer.com/en/home/contacts/dealer-directory.html" target="_blank" style="color: #2595ff"
            >https://catdealer.com/en/home/contacts/dealer-directory.html</a
          >
        </td>
      </tr>
    </table>
    <p>
      (i)<span style="text-decoration: underline"> Language. </span>These Terms are in the English language only, which
      language will be controlling in all respects, and all versions hereof in any other language will not be binding on
      the parties hereto. All communications and notices to be made or given pursuant to these Terms will be in the
      English language.
    </p>
    <p>
      (j)<span style="text-decoration: underline"> Currency Control.</span> You represent and warrant that no currency
      control Laws prevent the payment to us of any sums due under these Terms. If any such Laws come into effect such
      that payment in United States Dollars is not permitted, you will notify us immediately, and if so instructed by
      us, will deposit all monies due to us to our account or the account of our Affiliate in a local bank of our
      choice.
    </p>
    <p>
      (k)<span style="text-decoration: underline"> Ethical Business Conduct.</span> In carrying out your
      responsibilities under these Terms, you agree to comply with the following obligations:
    </p>
    <p>
      (i) You agree that you will comply at all times during the term of these Terms, with all applicable anti-bribery,
      anti-corruption, anti-money laundering, fraud, and trade laws, rules, regulations, directives, or statutes of
      every jurisdiction in which you conduct business, as may be amended from time to time (the
      <strong> “Ethical Business Conduct Laws”</strong>), including but not limited to: (A) the U.S. Foreign Corrupt
      Practices Act (15 U.S.C. §§78dd-1, et seq.); (B) the UK Bribery Act 2010; (C) the U.S. Export Administration
      Regulations (15 CFR Chapter VII); (D) regulations administered by the U.S. Treasury Department Office of Foreign
      Assets Control (31 CFR Chapter V) and the U.S. Department of State, as well as all applicable Executive Orders
      that have or may be enacted relating to sanctions programs administered by the U.S. Treasury Department’s Office
      of Foreign Assets Controls and the U.S. Department of State; and (E) the U.S. International Traffic in Arms
      Regulations (22 CFR Chapter I, Subchapter M).
    </p>
    <p>
      (ii) You, your Affiliates, and the respective employees, consultants, contractors, and agents of you and your
      Affiliates will not, at any time during the term of these Terms, make, offer or promise to make, or agree to make
      any loan, gift, donation, payment, or transfer of any other thing of value, whether in cash or in kind, directly
      or indirectly, for a corrupt purpose, to or for the benefit of any person or class of persons in a position of
      trust at a public or private entity, including but not limited to: (A) the employees, agents, or officials of: (1)
      government or public agencies, (2) public international organizations or bodies, or (3) entities owned or
      controlled by a government agency, or public or private entity; (B) political parties, party officials, or
      candidates for political office; or (C) judicial officers, in order to obtain or retain business or to secure any
      improper advantage in connection with any business activity related to us, or any of our wholly or partially owned
      Affiliates.
    </p>
    <p>
      (iii) You represent and warrant that none of your or your Affiliates’ employees, consultants, contractors, or
      agents (including but not limited to officers, directors, stockholders, and representatives) is a foreign official
      or is related to a foreign official (as defined in 15 U.S.C. §§ 78dd-1(f)(1) and 78dd-3(f)(2)), except to the
      extent previously disclosed to us. If any of your or your Affiliates’ employees, consultants, contractors, or
      agents or relatives thereof becomes a foreign official during the term of these Terms, you will immediately notify
      us in accordance with Section 21(h).
    </p>
    <p>
      (iv) You represent and warrant that you have read, understand, accept, and will abide by Caterpillar’s Worldwide
      Code of Conduct, as may be amended or revised from time to time by Caterpillar. You will make all of your and your
      Affiliates’ employees, consultants, contractors, and agents (including but not limited to officers, directors,
      stockholders, and representatives) aware of and ensure their compliance with Caterpillar’s Worldwide Code of
      Conduct.
    </p>
    <p>
      (v) We will have the right to audit or investigate, or to have our authorized representatives conduct audits or
      investigations, of your business, books and records, to ascertain the extent of your compliance or noncompliance
      with the Ethical Business Conduct Laws and this Section 21(k). If we exercise our right of audit or investigation
      hereunder, we will deliver written notice to you and will be solely responsible for the costs of conducting such
      audit or investigation. Upon such notice, you will make your personnel, books and records available to us at your
      primary office location.
    </p>
    <p>
      (vi) You agree that business conduct or any activity non-compliant with any of the provisions under this Section
      21(k) will constitute an event providing just cause for us to terminate these Terms.
    </p>
    <p>
      (l)<span style="text-decoration: underline"> Import and Export Compliance.</span> You agree that you will not use
      or otherwise export, re-export, transfer, or release, whether oral, visual, or deemed to be an export or reexport,
      or otherwise (collectively, <strong>“export”</strong>), except as authorized by United States Laws and the Laws of
      the jurisdictions in which Digital Services were accessed or used or any Registered Assets are located. In
      particular, but without limitation, Digital Services (including any Digital Services Information) may not be
      exported (including by accessing Digital Services), directly or indirectly: (i) to any person or entity listed or
      deemed to be a blocked, prohibited or trade-restricted person or party by the U.S. Commerce Department, U.S.
      Treasury Department, or U.S. Department of State by operation of law or otherwise; (ii) for any purpose or use
      prohibited by the U.S. government, such as for nuclear, chemical, or biological weapons production or
      proliferation; or (iii) to any destination or transit point subject to comprehensive sanctions by the U.S.
      government, as may be amended from time to time, without having obtained the required U.S. authorizations prior to
      such export. You represent and warrant that you are not located in any such country or on any such list or deemed
      to be on such list.
    </p>
    <p>
      (m)<span style="text-decoration: underline"> Compliance with Laws.</span> Without limiting any of Caterpillar’s or
      your other commitments hereunder, Caterpillar and you each agree to comply in all material respects with all Laws
      applicable to the performance of and exercise of each of Caterpillar’s and your respective rights and obligations
      under these Terms. Your compliance with your obligations hereunder will extend to Laws that apply to sale, making
      available and marketing of Subscriptions for Digital Services, as well as any other of its activities hereunder.
      You agree that you have, and will maintain, at your own expense, all permits, licenses, consents, and approvals
      that apply to sale, making available and marketing of Subscriptions for Digital Services, including for you to
      otherwise exercise your rights and perform your other obligations as set forth in these Terms (collectively, the
      <strong>“Permits”</strong>); provided that if obtaining any of the foregoing Permits is our responsibility under
      applicable Laws and applicable Laws do not permit you to obtain such Permits on our behalf, we will obtain such
      Permits at our own expense.
    </p>
    <p>
      (n)<span style="text-decoration: underline"> Severability.</span> If the application of any provision of these
      Terms or portion thereof to any particular facts or circumstances will be held to be invalid or unenforceable by
      an arbitration panel or a court of competent jurisdiction, the parties will negotiate in good faith a valid,
      legal, and enforceable substitute provision that most closely reflects the original intent of the parties with
      respect to the parties’ economic and business interests, and all other provisions hereof will remain in full force
      and effect in such jurisdiction and will be liberally construed in order to carry out the intentions of the
      parties hereto as nearly as may be possible. Such invalidity, illegality, or unenforceability will not affect the
      validity, legality, or enforceability of such provision in any other jurisdiction.
    </p>
    <p>
      (o)<span style="text-decoration: underline"> Construction.</span> As used herein: (i) the terms “include” and
      “including” are meant to be inclusive and will be deemed to mean “include without limitation” or “including
      without limitation;” (ii) the word “or” is disjunctive, but not necessarily exclusive; (iii) words used herein in
      the singular, where the context so permits, will be deemed to include the plural and vice versa; (iv) references
      to “dollars” or “$” will be to United States Dollars unless otherwise specified herein; and (v) unless otherwise
      specified, all references to days, months, or years will be deemed to be preceded by the word “calendar,” and
      “business days” will mean Monday through Friday, exclusive of Caterpillar observed holidays. The headings of these
      Terms are intended solely for convenience of reference and will be given no effect in the interpretation or
      construction of these Terms.
    </p>
    <p>
      (p)
      <span style="text-decoration: underline"> Electronic Signature and Disclosure Consent Notice.</span> You agree to
      the use of electronic documents and records in connection with the transactions contemplated by these Terms and
      all future documents and records in connection with Digital Services including this electronic signature and
      disclosure notice and that this use satisfies any requirement that we provide you these documents and their
      content in writing. You have the right to receive a paper copy of all documents and records. You may (i) obtain a
      paper copy of any document or record (free of charge), (ii) withdraw your consent to the use of electronic
      documents and records, or (iii) update your contact information by contacting us. To receive or access electronic
      documents and records, you must have the following equipment and software: (A) a device that is capable of
      accessing the Internet; (B) Chrome 80, Edge 90 or Safari 5 or the respective higher Internet browsers; and (C)
      software that permits you to receive and access Portable Document Format or “PDF” files, such as Adobe Acrobat
      Reader 8.0 or higher. To retain documents and records, your device must have the ability to download and store PDF
      files. Your access to this page verifies that its system and device meet the above receipt, access, and retention
      requirements.
    </p>
    <p>
      (q)<span style="text-decoration: underline"> Order of Precedence.</span> If a provision of these Terms, or a
      portion thereof, conflicts with a provision of the Dealer End User Agreement, or a portion thereof, then the
      provision or the portion thereof that gives the broadest rights to and/or is most protective of Caterpillar will
      control.
    </p>
    <p>
      (r)<span style="text-decoration: underline"> Entire Agreement.</span> These Terms, including all documents
      attached hereto or referenced herein, constitutes the full and entire understanding of the parties with respect to
      the subject matter hereof and supersedes any prior or contemporaneous understandings, agreements, or
      communications with respect to the subject matter of these Terms. These Terms may only be changed as expressly
      provided herein or in a signed, separate written agreement executed by the parties hereto.
    </p>
    <span><strong>Last Updated Date: 16 August 2022</strong></span>
  </div>
</div>
<div class="cc-modal-footer button-container">
  <div class="footer-left">
    <div style="margin-top: 8px">
      <cc-checkbox
        style="padding-left: 15px"
        [isTheme]="'2.0'"
        (ngModelChange)="toggleCheckboxOption($event)"
        [ngModel]="isChecked"
      >
        <span class="textStyle">I have read and agree to the Dealer Services Portal Terms of Sale</span>
      </cc-checkbox>
    </div>
    <div class="copyRight-container">
      <p style="padding-left: 24px">Copyright &copy; {{ dateYear }}. All Rights Reserved.</p>
    </div>
  </div>
  <div>
    <div class="button-position">
      <button
        cc-btn-secondary
        cc-btn-large
        [setTheme]="true"
        class="cancel button-opacity"
        (click)="tosDisAgreeButton()"
      >
        Disagree
      </button>
      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        [disabled]="!isChecked"
        class="delete button-opacity"
        (click)="tosAgreeButton()"
      >
        Agree
      </button>
    </div>
  </div>
</div>
