<div class="dealer-action-required-modal">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title modal-title">{{ modalTitle }}</div>
    </div>
    <div class="cc-modal-content dealer">
      <div
        class="modal-body-content"
        id="action-required-message"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(modalBodyMessage || '')"
      ></div>
      <div class="modal-body-footer">
        <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(modalFooterMessage || '')"></div>
      </div>
    </div>
    <div class="cc-modal-footer">
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeDealerActionModal('i agree')">I Agree</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeDealerActionModal('exit')">Exit</button>
    </div>
  </div>
</div>
