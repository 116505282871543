import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core/modal';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'dsp-next-gen-ui-expanded-view-sub-asset-trend',
  templateUrl: './expanded-view-sub-asset-trend.component.html',
  styleUrls: ['./expanded-view-sub-asset-trend.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpandedViewSubAssetTrendComponent implements OnInit {
  chartColorMap = [];
  nodata: any;
  filtersSaved: any;
  savedChartData: any;
  savedConfig: any;
  filterIcon: boolean = false;
  dspStoreData!: any;

  chart100OriginalData = {
    series: [
      {
        name: '',
        data: [],
      },
    ],
  };
  chartResponsiveConfig = {
    type: 'line',
    legendPlacement: 'top',
    height: 430,
  };
  filterSelectedData: any;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,

    private modalRef: CcModalRef<ExpandedViewSubAssetTrendComponent>,
    private store: Store<DSPAppState>,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
    this.store.select('subsTrendsavedFilterData').subscribe((x: any) => {
      this.filtersSaved = x.filterData.subsTrendsavedFilterData;
      if (this.filtersSaved?.month?.length > 0 || this.filtersSaved?.application?.length > 0) {
        this.filterIcon = true;
      } else {
        this.filterIcon = false;
      }
    });

    this.store.select('chart1000FilterData').subscribe((x) => {
      this.savedChartData = x.chartData.chart1000FilterData;
      this.chart100OriginalData.series = [];
      this.savedChartData.forEach((item: any) => {
        const obj = {
          name: item.name,
          data: item.data,
        };
        this.chart100OriginalData.series.push(obj);
      });
    });
    this.store.select('subAssetTrendTableConfiguration').subscribe((x) => {
      this.savedConfig = x.subsasst.subAssetTrendTableConfiguration;
      this.cdr.detectChanges();
    });

    this.nodata = this.data.noData;
    this.data.config.height = window.innerHeight - 76 + 'px';
  }
  openFilter(type?: string) {
    this.cdr.detectChanges();
    this.dashboardService.openSubscribeTrendClicked.next(true);
  }
  onNoClick() {
    this.modalRef.close();
  }
  closeModel() {
    this.modalRef.close();
  }

  /***Commented the below function, as we are not using it */
  // getChartColor() {
  //   const chartColorMap = [this.data.chartColorInit[0].color];
  //   const application = this.data.chartData;
  //   if (application !== undefined && application !== null) {
  //     const app = application.series;
  //     app.forEach((val: any) => {
  //       if (val && val.name !== '') {
  //         const colorArray = this.data.chartColorInit.filter((chartCol: any) => chartCol.label === val.name);
  //         if (colorArray !== undefined) {
  //           chartColorMap.push(colorArray[0].color);
  //         }
  //       }
  //     });
  //   }
  //   return chartColorMap;
  // }
}
