<div class="basic-radio">
  <div class="reason-label">PLEASE SELECT THE CANCELLATION REASON:</div>
  <cc-radio-group aria-label="Select cancel option" class="canceloption cancelreason" [(ngModel)]="selectedOption">
    <cc-radio-button
      *ngFor="let option of cancelDetails"
      [value]="option?.reason"
      class="canceloption cancelreason"
      (valueChange)="selectedOption = option?.reason; handleCancelReasonSelection(option)"
    >
      <div class="making">
        <span class="reason">{{ option.reason }}</span
        ><br />
        <span class="description">{{ option.description }}</span>
      </div>
    </cc-radio-button>
  </cc-radio-group>
  <div class="Row" *ngIf="showWarning && subsPricingEligible && this.footerMessage">
    <div class="cancelNoteCell">
      <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
      <div class="cancelNote" [innerHTML]="footerMessage"></div>
    </div>
  </div>
</div>
