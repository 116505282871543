import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { cloneDeep, isEmpty } from 'lodash-es';
import {
  CC_DRAWER_DATA,
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  CcModal,
  DrawerService,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { AssetService } from '../../../components/assets/services/asset.service';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import * as dspConstants from '../../dspConstants';
import { CommonSubFilterComponent } from '../common-sub-filter/common-sub-filter.component';
import { CommonShowallFilterComponent } from '../common-showall-filter/common-showall-filter.component';
import { FilterCloseConfirmationComponent } from '../../../components/filter-close-confirmation/filter-close-confirmation.component';
import { CommonFilterService } from '../../../services/common-filter.service';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { PopupData } from '../../../models/popup.interface';
import { CommonConfirmationPopupComponent } from '../../../components/common-confirmation-popup/common-confirmation-popup.component';
import { HomeService } from '../../../components/home/services/home.service';

@Component({
  selector: 'dsp-next-gen-ui-common-main-filters',
  templateUrl: './common-main-filters.component.html',
  styleUrls: ['./common-main-filters.component.scss'],
})
export class CommonMainFiltersComponent implements OnInit, OnDestroy {
  @ViewChild('globalFilterSearchBox') globalFilterSearchBox!: ElementRef;
  eventsSubject: Subject<void> = new Subject<void>();
  headerContent: string | undefined;
  searchHelpText: any;
  nextCursor: any;
  searchString: any;
  mobileHeader: any;
  accordionListColumns: any;
  expandDropdown = false;
  isAccordionClosed: any = {};
  filterColumns: any;
  columnsVisible: any;
  filterColumData: any;
  isDataLoading = false;
  selectedFilters: any = {};
  previousSelectedFilters: any = {};
  modalRef: any;
  viewButtonClicked = false;
  globalSearchInput: any;
  globalSearchType: any;
  globalSortColumn: any;
  isCustomRangeFilterSelected = false;
  dateArray: any = [];
  dateWithQueueReasonId: any = [];
  dateWithInvoiceNumber: any = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  headerTitle = '';
  isCommonPopup = false;
  isSearchFilterEnabled = false;
  private cancelUnsubscribe: Subject<void> = new Subject();
  payload: any;
  userRole: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      choosedFilters: any;
      headerType: string;
      tableData: any;
      titleNames: Array<string>;
      savedFilters: any;
      customData: any;
      columnNames: any;
      subFilterClearAllClicked: any;
      pageLabel: any;
      invoiceNumber: any;
      queueReasonId: any;
      status: any;
    },
    private assetService: AssetService,
    public homeService: HomeService,
    public dialogRef: CcDrawerRef<CommonMainFiltersComponent, CcDrawerConfig>,
    public showAllContentRef: CcDrawerRef<CommonShowallFilterComponent, CcDrawerConfig>,
    private messageBar: MessageBar,
    public modal: CcModal,
    public subFilterContentsRef: CcDrawerRef<CommonSubFilterComponent, CcDrawerConfig>,
    private drawerService: DrawerService,
    private commonFilterService: CommonFilterService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp: any) => {
      this.userRole = dsp?.userDetails?.roleName;
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
      this.isSearchFilterEnabled = dsp?.featureFlagInfo?.isSearchFilterEnabled
        ? dsp?.featureFlagInfo?.isSearchFilterEnabled
        : false;
    });
    if (this.header.pageLabel == 'needsReview' || this.header.pageLabel == 'billing') {
      this.headerTitle = 'assets';
    } else {
      this.headerTitle = this.header.pageLabel;
    }
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.accordionListColumns = [];
    this.globalSearchInput = this.globalSearchInput = this.header?.customData?.searchString
      ? this.header.customData.searchString
      : '';
    this.globalSearchType = this.header?.customData?.searchType ? this.header.customData.searchType : '';
    this.globalSortColumn = this.header?.customData?.sortColumn ? this.header.customData.sortColumn : 'serialNumber';
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.header.pageLabel + 'Filters',
      'selectedFIlters',
      this.header.pageLabel,
      this.header.invoiceNumber,
      this.header.queueReasonId
    );
    this.header.savedFilters = filtersSaved;
    this.accordionListColumns = this.header?.columnNames;
    this.setColumnVisibility(this.header?.columnNames);
    this.selectedFilters = this.header?.savedFilters;
    this.previousSelectedFilters = cloneDeep(this.header?.savedFilters);
    const dateArray = sessionStorage.getItem(this.header?.pageLabel + 'dateFilters');
    const dateWithQueueReasonId = sessionStorage.getItem(this.header?.queueReasonId + 'dateFilters');
    this.dateWithQueueReasonId = dateWithQueueReasonId ? JSON.parse(dateWithQueueReasonId) : [];
    const dateWithInvoiceNumber = sessionStorage.getItem(this.header?.invoiceNumber + 'dateFilters');
    this.dateWithInvoiceNumber = dateWithInvoiceNumber ? JSON.parse(dateWithInvoiceNumber) : [];
    this.dateArray = dateArray ? JSON.parse(dateArray) : [];
    if (!this.header.invoiceNumber) {
      this.header.invoiceNumber = '';
    }
    if (!this.header.queueReasonId) {
      this.header.queueReasonId = '';
    }
    if (!this.header.status) {
      this.header.status = '';
    }
    this.accordionListColumns?.forEach((item: any) => {
      if (this.isAccordionClosed[item.field]) {
        this.isAccordionClosed[item.field] = true;
      } else {
        this.isAccordionClosed = {
          ...this.isAccordionClosed,
          [item.field]: true,
        };
      }
    });
    this.dialogRef?.onBackdropClick?.subscribe((result: any) => {
      this.closeModal();
    });
  }
  /**
   * Clear all selected filters and reset the filter state to its initial state.
   */
  clearFilters() {
    this.selectedFilters = {};
    this.dateArray = [];
    this.dateWithQueueReasonId = [];
    this.dateWithInvoiceNumber = [];
    this.header.savedFilters = {};
    this.accordionListColumns?.forEach((item: any) => {
      if (item?.subFilters) {
        item.subFilters?.forEach((element: any) => {
          element.selectedValues = [];
        });
      } else {
        item.selectedValues = [];
      }
    });
    this.assetService.setResetFilterData(true);
    this.eventsSubject.next();
  }
  checkUndefined(previousSelected: any, savedFilters: any) {
    let isReturn = true;
    isReturn = Object.values(savedFilters).every((x) => !isEmpty(x));
    if (!isReturn && isEmpty(previousSelected)) {
      isReturn = false;
    } else {
      isReturn = true;
    }
    return isReturn;
  }
  /**
   * Closes the filter dialog when cross icon is clicked.
   */
  closeModal(close?: any) {
    const dateFiltersString = sessionStorage.getItem(this.header?.pageLabel + 'dateFilters');
    const dateArray = dateFiltersString ? JSON.parse(dateFiltersString) : [];
    const areArraysEqual = (arr1: any, arr2: any) => JSON.stringify(arr1.sort()) !== JSON.stringify(arr2.sort());
    const isDateChanged = areArraysEqual(dateArray, this.dateArray);
    if (
      (JSON.stringify(this.previousSelectedFilters) !== JSON.stringify(this.header?.savedFilters) || isDateChanged) &&
      !this.viewButtonClicked &&
      this.checkUndefined(this.previousSelectedFilters, this.header?.savedFilters)
    ) {
      if (this.isCommonPopup) {
        const popupData: PopupData = {
          title: dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_TITLE,
          messageArray: [dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
          showCrossMark: true,
          showPrimaryButton: true,
          showSecondaryButton: true,
          primaryButtonText: dspConstants.PopupButtons.APPLY_CLOSE,
          secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
        };

        this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
          width: '524px',
          type: 'semi-modal',
          data: popupData,
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === dspConstants.PopupButtons.APPLY_CLOSE) {
            this.loadAssets();
          } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
            this.dialogRef.close({ data: close });
          }
        });
      } else {
        this.modalRef = this.modal.openModal(FilterCloseConfirmationComponent, {
          width: '524px',
          type: 'semi-modal',
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === true) {
            this.loadAssets();
          } else if (result === false) {
            this.dialogRef.close({ data: close });
          }
        });
      }
    } else {
      this.header.savedFilters = this.selectedFilters;
      this.dialogRef.close({ data: close });
    }
  }
  /**
   * Set the date filer for a specific column.
   * @param {Object} obj - Object containing filter date information.
   * @param {string} columnName - Name of the column for which filter date is being set.
   * @returns {void}
   * @constant filteredDateColumn - This array contains the filtered column data from the accordionListColumns array based on the provided column name.
   */
  setFilterDate(obj: any, columnName: any) {
    this.isCustomRangeFilterSelected = false;
    const filteredDateColumn = this.accordionListColumns?.filter((item: any) => item.field == columnName);
    this.accordionListColumns?.map((column: any) => {
      if (column.field === columnName) {
        column.selectedValues = obj.value;
      }
    });
    if (filteredDateColumn.length) {
      if (obj.option === 'Custom Range') {
        this.isCustomRangeFilterSelected = true;
        let isTwoDatesSelected = false;
        //checking if both start & end dates are selected.
        if (obj.value.length) {
          obj.value?.forEach((dateVal: any) => {
            isTwoDatesSelected = dateVal.length != 0;
          });
        }
        if (isTwoDatesSelected) {
          filteredDateColumn[0].selectedValues = obj.value;
        }
      } else {
        filteredDateColumn[0].selectedValues = obj.value;
      }
      this.pushToArray(this.dateArray, { id: columnName, value: obj.option, dateVal: obj.value });
      this.pushToArray(this.dateWithQueueReasonId, { id: columnName, value: obj.option, dateVal: obj.value });
      this.pushToArray(this.dateWithInvoiceNumber, { id: columnName, value: obj.option, dateVal: obj.value });
    }
  }
  /**
   * Pushes an object to an array if it does not already exist, or updates its value if it exists.
   * @param {Array} arr - The array to which the object will be pushed.
   * @param {Object} obj - The object to be pushed or updated in the array.
   */
  pushToArray(arr: any, obj: any) {
    const index = arr?.findIndex((e: any) => e.id === obj.id);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
  }
  /**
   * Opens the sub-filter accordion based on the provided subFilter data and label.
   * @param {Array} subFilter - Array containing sub-filter data
   * @param {string} label - Label to be displayed in the sub-filter accordion
   * @constant newCondition - contains the currently selected filters
   * @constant selectedAssetsFilters - contains the currently selected filters
   */
  openSubFilterAccordion(subFilter: any, label: any) {
    const filterChosen: any = '';
    const newCondition = Object.keys(this.selectedFilters).length == 0 ? {} : this.selectedFilters;
    const selectedAssetsFilters = filterChosen ? JSON.parse(filterChosen) : newCondition;
    if (subFilter && selectedAssetsFilters) {
      for (const key in selectedAssetsFilters) {
        subFilter.forEach((item: any) => {
          if (item.field == key) {
            item.selectedValues = selectedAssetsFilters[key];
          }
        });
      }
      this.openSubFilter({ subFilter, label });
    }
  }
  /**
   * Opens the subfilter drawer with the specified subfilter item.
   * @param {object} subfilterItem - Object containing subfilter data.
   * @param {string} subfilterItem.label - Label of the subfilter.
   * @param {array} subfilterItem.subFilter - Array of subfilters.
   */
  openSubFilter(subfilterItem: any) {
    this.subFilterContentsRef = this.drawerService.openDrawer<CommonSubFilterComponent, CcDrawerConfig>(
      CommonSubFilterComponent,
      {
        data: {
          headerType: 'withActions',
          title: subfilterItem.label,
          columnNames: subfilterItem.subFilter,
          choosedFilters: this.header.choosedFilters,
          savedFilters: this.header.savedFilters,
          subFilterData: this.filterColumData,
          customData: this.header.customData,
          showSearchBox: '',
          ref: this.dialogRef,
          pageLabel: this.header.pageLabel,
          filterColumData: this.filterColumData,
          accordionListColumns: this.accordionListColumns,
          invoiceNumber: this.header.invoiceNumber,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.subFilterContentsRef.afterClosed().subscribe((res) => {
      if (res) {
        this.accordionListColumns?.forEach((col: any) => {
          if (col.subFilters) {
            col.subFilters.forEach((subItem: { field: string | number; selectedValues: any }) => {
              if (res?.data?.length && res?.data[0].field == subItem.field) {
                col.subFilters = res.data;
              }
            });
            col.subFilters.forEach((subItem: { field: string | number; selectedValues: any }) => {
              if (subItem.selectedValues && subItem.selectedValues.length) {
                this.selectedFilters[subItem.field] = subItem.selectedValues;
              } else {
                delete this.selectedFilters[subItem.field];
                delete this.header.savedFilters[subItem.field];
              }
            });
          }
        });
        Object.assign(this.header.savedFilters, this.selectedFilters);
      }
    });
  }
  assignToTop(columnName: any) {
    if (
      this.filterColumData &&
      this.filterColumData[columnName] &&
      this.filterColumData[columnName]?.length >= 1 &&
      this.header.savedFilters[columnName] &&
      this.header.savedFilters[columnName]?.length >= 1
    ) {
      if (this.filterColumData[columnName][0]) {
        // this.filterColumData[columnName][0].sort(
        //   (a: any, b: any) =>
        //     this.header.savedFilters[columnName].indexOf(b) - this.header.savedFilters[columnName].indexOf(a)
        // );
        this.header.savedFilters[columnName].forEach((item: any) => {
          const index = this.filterColumData[columnName][0].indexOf(item);
          this.filterColumData[columnName][0]?.splice(index, 1);
          this.filterColumData[columnName][0].unshift(item);
        });
      }
    }
  }
  /**
   * Loads filter's column data based on the provided parameters.
   * @param {any} subFilter - Sub filter data
   * @param {any} columnName - Name of the column
   * @param {any} label - Label associated with the column
   * @param {any} formControl - Type of form control (e.g., 'date', 'promotionContractDate')
   */
  loadFilterColumnData(subFilter: any, columnName: any, label: any, formControl: any) {
    if (this.isAccordionClosed[columnName]) {
      this.isAccordionClosed[columnName] = false;
    } else {
      this.isAccordionClosed = {
        ...this.isAccordionClosed,
        [columnName]: false,
      };
    }
    if (subFilter) {
      this.isDataLoading = false;
    } else {
      if (
        Object.keys(this.header.savedFilters)?.length &&
        this.accordionListColumns?.length &&
        this.accordionListColumns?.filter((item: any) => item.field == columnName)[0].selectedValues.length == 0 &&
        this.header.savedFilters[columnName]
      ) {
        this.accordionListColumns.filter((item: any) => item.field == columnName)[0].selectedValues =
          this.header.savedFilters[columnName];
      }
      /** Added below if condition to retain selected filter on clicking the barchart in dashboard page */
      if (
        Object.keys(this.header.savedFilters).length &&
        this.accordionListColumns?.length &&
        this.accordionListColumns?.filter((item: any) => item.field == columnName)[0].selectedValues.length !== 0 &&
        this.header.savedFilters[columnName]
      ) {
        this.accordionListColumns.filter((item: any) => item.field == columnName)[0].selectedValues =
          this.header.savedFilters[columnName];
      }
      if (
        formControl != 'date' &&
        formControl != 'promotionContractDate'
        // (Object.keys(this.header.savedFilters).length == 0 ||
        //   (Object.keys(this.header.savedFilters).length &&
        //     (this.accordionListColumns.filter((item) => item.field == columnName)[0].selectedValues.length ||
        //       (this.assetFilterColumData[columnName][0] && this.assetFilterColumData[columnName][0].length == 0) ||
        //       this.assetFilterColumData[columnName].length == 0))
        //       )
      ) {
        this.setFilterColumnData(columnName, '');
      } else if (
        (formControl == 'date' || formControl == 'promotionContractDate') &&
        Object.keys(this.header.savedFilters).length &&
        this.header.savedFilters[columnName]
      ) {
        // const data: any = sessionStorage.getItem(this.header.pageLabel + 'dateFilters');
        this.eventsSubject.next(this.dateArray);
      }
    }
  }
  /**
   * Fetches filter column data based on the provided column name and search text.
   * @param {string} columnName - The name of the column for which data is to be fetched.
   * @param {string} searchText - The search text used for filtering data.
   */
  setFilterColumnData(columnName: any, searchText: any) {
    const obj: any = ['reportingState'];
    this.accordionListColumns?.forEach(function (item: any) {
      if (item.field && !item.subFilters && item.isDefault) {
        obj.push(item.field);
      }
    });

    const assetKeys = Object?.keys(this.filterColumData);
    assetKeys.map((key: any) => {
      if (!this.filterColumData[key]) {
        this.filterColumData[key] = [];
      }
    });
    this.isDataLoading = true;
    if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
      const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: searchText,
        searchType: 'globalSearch',
        sortColumn: columnName,
        sortOrder: 'asc',
        operation: searchText?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: columnName,
        dealerQueueReasonId: this.header.queueReasonId ? this.header.queueReasonId : null,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: 0,
        limit: 100,
        selectedProducts: [],
        invoiceNumber: this.header.invoiceNumber,
        isHomeSearch: true,
        filter: {
          ucidNameWithId: custSearchpayload,
          dcnWithName: dcnSearchpayload,
        },
      };
    } else if (
      this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
      this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
    ) {
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchText: searchText,
        searchType: this.globalSearchType,
        sortColumn: columnName,
        sortOrder: 'asc',
        operation: searchText?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: columnName,
        dealerQueueReasonId: this.header.queueReasonId ? this.header.queueReasonId : null,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: 0,
        limit: 100,
        selectedProducts: [],
        invoiceNumber: this.header.invoiceNumber,
      };
    } else {
      this.payload = {
        dealerCode: this.dealerCode,
        globalSearchInput: this.globalSearchInput,
        searchText: searchText,
        searchType: this.globalSearchType,
        sortColumn: columnName,
        sortOrder: 'asc',
        operation: searchText?.length > 0 ? 'searchFilter' : 'filter',
        searchColumn: columnName,
        dealerQueueReasonId: this.header.queueReasonId ? this.header.queueReasonId : null,
        status: '',
        pageLabel: this.header.pageLabel,
        offset: 0,
        limit: 100,
        selectedProducts: [],
        invoiceNumber: this.header.invoiceNumber,
      };
    }
    this.commonFilterService.getFilterAccordianList(this.payload).subscribe({
      // this.assetService.getAssetFilterColumnData(payload).subscribe({
      next: (columnData: any) => {
        let columnValues = [];
        this.isDataLoading = false;
        if (columnData?.length > 0) {
          this.nextCursor = columnData[0]?.next;
          columnValues = columnData[0][columnName];
          columnValues = columnValues.filter((item: string) => !isEmpty(item?.trim()));
          this.filterColumData[columnName] = [...[columnValues]];
        }
      },
      error: (_err: any) => {
        this.nextCursor = null;
        this.isDataLoading = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  /**
   * Opens a showall drawer to display all data for a specific filter.
   * @param {string} type - 'withActions'.
   * @param {any} value - Data to display in the showall drawer.
   * @param {any} columns - Column name of the data.
   * @return {void}
   * @constant prop - used to extract the value of the field property from the columns object nested within the data property of the config object belonging to showAllContentRef.
   * @constant filterChosen - used to restore previously saved filter selections or state
   * @constant newCondition - stores the currently selectedFilters
   */
  showAllData(type: string, value: any, columns: any) {
    this.showAllContentRef = this.drawerService.openDrawer<CommonShowallFilterComponent, CcDrawerConfig>(
      CommonShowallFilterComponent,
      {
        data: {
          headerType: type,
          headerTitle: columns.label,
          columns: columns,
          showallData: value,
          selectedRadioFilters: this.selectedFilters,
          subFilter: false,
          customData: this.header.customData,
          nextCursor: this.nextCursor,
          pageLabel: this.header.pageLabel,
          invoiceNumber: this.header.invoiceNumber,
          queueReasonId: this.header.queueReasonId,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.showAllContentRef.afterClosed().subscribe((res) => {
      const prop = this.showAllContentRef?.config?.data?.columns?.field;
      const filterChosen = this.commonFilterService.getItemFromSessionStorage(
        this.header.pageLabel + 'Filters',
        'selectedFIlters',
        this.header.pageLabel,
        this.header.invoiceNumber,
        this.header.queueReasonId
      );
      const newCondition = Object.keys(this.selectedFilters).length == 0 ? {} : this.selectedFilters;
      this.selectedFilters = newCondition;
      this.setShowAllSelectionValue(prop, this.showAllContentRef?.config?.data?.columns?.selectedValues);
      this.header.savedFilters = this.selectedFilters;
      if (this.accordionListColumns && this.header.savedFilters) {
        for (const key in this.header.savedFilters) {
          this.accordionListColumns?.forEach((item: any) => {
            if (item.field == key) {
              item.selectedValues = this.header.savedFilters[key];
            }
          });
        }
      }
    });
  }
  /**
   * Sets the selected values for the "Show All" filter.
   * @param {any} prop - The field name for which the selected values are being set.
   * @param {any} values - The values to set for the specified field.
   */
  setShowAllSelectionValue(prop: any, values: any) {
    if (this.selectedFilters[prop]) {
      this.selectedFilters[prop] = values;
    } else if (values?.length > 0) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [prop]: values,
      };
    }
  }
  /**
   * Display a toast message with specified content and status.
   * @param {string} message - The message content to display in the toast.
   * @param {string} status - The status of the message, e.g., 'error', 'success'.
   * @returns {void}
   * @constant config - used to define configuration options for displaying toast messages using the MessageBar component
   */
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  /**
   * Adds a value to the selected filters array under a given property.
   * @param {string} prop - The property under which the value will be stored in the selected filters object.
   * @param {any} propVal - The current value of the property in the selected filters object.
   * @param {any} value - The value to add to the selected filters array under the specified property.
   */
  addSelectionToArray(prop: string, propVal: any, value: any) {
    if (this.selectedFilters[prop]) {
      this.selectedFilters[prop] = this.selectedFilters[prop].concat(value);
    } else {
      this.selectedFilters = {
        ...this.selectedFilters,
        [prop]: propVal.concat(value),
      };
    }
    this.selectedFilters[prop] = this.selectedFilters[prop].filter(function (value: any, index: any, array: any) {
      return array.indexOf(value) === index;
    });
  }
  /**
   * Remove a value from the selectedFilters array for a given property.
   * @param {string} prop - The property/key in the selectedFilters object.
   * @param {any} value - The value to be removed from the selectedFilters array.
   */
  removeSelectionFromArray(prop: any, value: any) {
    const index = this.selectedFilters[prop]?.indexOf(value);
    if (index !== -1 && this.selectedFilters[prop].length && this.selectedFilters[prop].length > 1) {
      this.selectedFilters[prop].splice(index, 1);
    } else if (index !== -1 && this.selectedFilters[prop].length && this.selectedFilters[prop].length == 1) {
      if (Object.keys(this.selectedFilters)?.length === 1) {
        this.selectedFilters = {};
      } else {
        delete this.selectedFilters[prop];
      }
    }
  }
  /**
   * Load assets based on selected filters.
   * Saves selected filters to session storage.
   * Closes the modal after loading assets.
   */
  loadAssets() {
    this.viewButtonClicked = true;
    this.accordionListColumns.forEach((col: any) => {
      if (col.subFilters) {
        col.subFilters.forEach((subItem: { field: string | number; selectedValues: any }) => {
          if (subItem.selectedValues && subItem.selectedValues.length) {
            this.selectedFilters[subItem.field] = subItem.selectedValues;
          }
        });
      } else {
        if (col.selectedValues && col.selectedValues.length) {
          this.selectedFilters[col.field] = col.selectedValues;
        } else {
          delete this.selectedFilters[col.field];
        }
      }
    });
    if (this.selectedFilters && Object.keys(this.selectedFilters).length !== 0) {
      this.commonFilterService.setItemFromSessionStorage(
        this.header.pageLabel + 'Filters',
        cloneDeep(this.accordionListColumns),
        'filtersDetails',
        this.header.pageLabel,
        this.header.invoiceNumber,
        this.header.queueReasonId
      );

      this.commonFilterService.setItemFromSessionStorage(
        this.header.pageLabel + 'Filters',
        cloneDeep(this.selectedFilters),
        'selectedFIlters',
        this.header.pageLabel,
        this.header.invoiceNumber,
        this.header.queueReasonId
      );
    } else {
      this.commonFilterService.removeItemFromSessionStorage(
        this.header.pageLabel + 'Filters',
        'selectedFIlters',
        this.header.pageLabel,
        this.header.invoiceNumber,
        this.header.queueReasonId
      );
      this.commonFilterService.removeItemFromSessionStorage(
        this.header.pageLabel + 'Filters',
        'filtersDetails',
        this.header.pageLabel,
        this.header.invoiceNumber,
        this.header.queueReasonId
      );
    }
    if (this.dateArray?.length >= 1) {
      sessionStorage.setItem(this.header.pageLabel + 'dateFilters', JSON.stringify(this.dateArray));
      sessionStorage.setItem(this.header.queueReasonId + 'dateFilters', JSON.stringify(this.dateWithQueueReasonId));
      sessionStorage.setItem(this.header.invoiceNumber + 'dateFilters', JSON.stringify(this.dateWithInvoiceNumber));
    } else {
      sessionStorage.removeItem(this.header.pageLabel + 'dateFilters');
      sessionStorage.removeItem(this.header.queueReasonId + 'dateFilters');
      sessionStorage.removeItem(this.header.invoiceNumber + 'dateFilters');
    }
    this.header.savedFilters = this.selectedFilters;
    if (this.selectedFilters) {
      if (this.header.pageLabel == 'sites' || this.header.pageLabel == 'accounts') {
        if (this.selectedFilters['applicationPlan']) {
          this.selectedFilters['plan'] = this.selectedFilters['applicationPlan'];
          delete this.selectedFilters['applicationPlan'];
        }
        if (this.selectedFilters['applicationName']) {
          this.selectedFilters['product'] = this.selectedFilters['applicationName'];
          delete this.selectedFilters['applicationName'];
        }
      }
      if (this.header.pageLabel == 'sites' && this.selectedFilters['statusSet']) {
        this.selectedFilters['status'] = this.selectedFilters['statusSet'];
        delete this.selectedFilters['statusSet'];
      }
      this.closeModal(this.selectedFilters);
    } else {
      this.closeModal([]);
    }
  }
  /**
   * Handle selection of a filter value.
   * Updates the selectedFilters object accordingly.
   * @param {any} event - The event object containing information about the checkbox toggle event.
   * @param {any} filterList - The filter list object contains column names.
   * @param {any} filterValue - The value of the filter being selected.
   * @constant prop -  used to store the value of the field property from the subfilterList
   * @constant propVal - used to hold selected filter values for a specific property during the process of updating the selectedFilters object.
   * @constant activeFilter - retrieves the selected filters stored in the session storage corresponding to the page label.
   */
  getSelectedFilter($event: any, filterList: any, filterValue: any) {
    const propVal: any = [];
    const prop = filterList.field;
    // const activeFilter = this.commonFilterService.getItemFromSessionStorage(
    //   this.header.pageLabel + 'Filters',
    //   'selectedFIlters',
    //   this.header.pageLabel,
    //   this.header.invoiceNumber,
    //   this.header.queueReasonId
    // );
    // if (Object.keys(this.selectedFilters).length == 0) {
    //   this.selectedFilters = activeFilter;
    // }
    if ($event.checked) {
      if (filterList.selectedValues) filterList.selectedValues.push(filterValue);
      this.addSelectionToArray(prop, propVal, filterValue);
    } else {
      if (filterList.selectedValues.length) {
        filterList.selectedValues.splice(filterList.selectedValues.indexOf(filterValue), 1);
      }
      this.removeSelectionFromArray(prop, filterValue);
      // if (Object.keys(this.selectedFilters).length === 0) {
      //   this.clearFilters();
      // }
    }
    this.header.savedFilters = this.selectedFilters;
  }

  /**
   * Set the visibility of columns based on the provided column names.
   * @param {any} columnNames - The list of column names to set visibile for.
   * @constant filterData - retrieves the selected filters stored in the session storage corresponding to the page label.
   */
  setColumnVisibility(columnNames: any) {
    const filterData = this.commonFilterService.getItemFromSessionStorage(
      this.header.pageLabel + 'Filters',
      'filtersDetails',
      this.header.pageLabel,
      this.header.invoiceNumber,
      this.header.queueReasonId
    );
    if (filterData) {
      this.accordionListColumns = Object.keys(filterData).length > 0 ? filterData : columnNames;
    } else {
      if (Object.keys(this.header.savedFilters).length == 0) {
        this.accordionListColumns?.forEach((item: any) => {
          if (item?.subFilters) {
            item.subFilters?.forEach((element: any) => {
              element.selectedValues = [];
            });
          } else {
            item.selectedValues = [];
          }
        });
      }
    }
    this.filterColumData = this.accordionListColumns?.reduce((r: any, e: any) => {
      if (e.subFilters && e.subFilters?.length > 0) {
        e.subFilters.reduce((a: any, e: any) => {
          r[e.field] = [];
          return r;
        });
      }
      r[e.field] = [];
      return r;
    }, {});
    if (this.accordionListColumns) {
      this.accordionListColumns?.forEach((item: any) => {
        if (item?.selectedValues?.length > 0) {
          if (!this.header.savedFilters[item.field]) {
            item.selectedValues = [];
          }
        }
      });
    }
    if (this.accordionListColumns && this.header.savedFilters) {
      for (const key in this.header.savedFilters) {
        this.accordionListColumns?.forEach((item: any) => {
          if (item.field == key) {
            item.selectedValues = this.header.savedFilters[key];
          }
        });
      }
    }
  }

  accordionClosed(columnName: any) {
    if (this.isAccordionClosed[columnName]) {
      this.isAccordionClosed[columnName] = true;
    } else {
      this.isAccordionClosed = {
        ...this.isAccordionClosed,
        [columnName]: true,
      };
    }
  }

  onDeleteChip($event: any, filterList: any, filterValue: any) {
    const prop = filterList.field;
    if (filterList?.selectedValues?.length) {
      filterList?.selectedValues?.splice(filterList?.selectedValues?.indexOf(filterValue), 1);
    }
    this.removeSelectionFromArray(prop, filterValue);
    // if (Object.keys(this.selectedFilters).length === 0) {
    //   this.clearFilters();
    // }
    this.header.savedFilters = this.selectedFilters;
  }
  filterDateToString(date: any) {
    const dateValue = date?.dateVal;
    if (date?.value !== 'Today' && date?.value !== 'Yesterday') {
      let returnStringValue = '';
      returnStringValue =
        this.removeTimeFromDatetime(dateValue[0]) + ' to ' + this.removeTimeFromDatetime(dateValue[1]);
      return returnStringValue;
    } else {
      return dateValue[0];
    }
  }

  removeTimeFromDatetime(datetimeString: string): string {
    const date = new Date(datetimeString);
    const formattedDate = `${date?.getFullYear()}-${String(date?.getMonth() + 1).padStart(2, '0')}-${String(
      date?.getDate()
    ).padStart(2, '0')}`;
    return formattedDate;
  }
  ngOnDestroy(): void {}
}
