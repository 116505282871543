<div class="cc-drawer">
  <div class="cc-drawer__content dealer-plan-block">
    <cc-list>
      <cc-list-item *ngFor="let ownershipData of data; let i = index" class="ownership-list">
        <div class="list-count">
          <span class="list-number">{{ i + 1 }}</span>
        </div>
        <div class="ownership-details">
          <div
            class="name text-truncate"
            cc-tooltip="{{ ownershipData.custToolTipData }}"
            [tooltipPosition]="'top'"
            tooltipWidth="320px"
            [isEllipseEnabled]="true"
            dspNextGenUiCopy
            [fullText]="ownershipData.custToolTipData"
          >
            <strong> Customer</strong>: {{ getTrimCustomerData(ownershipData) }}
          </div>
          <div
            class="name text-truncate"
            cc-tooltip="{{ ownershipData.dealerToolTipData }}"
            [tooltipPosition]="'top'"
            tooltipWidth="320px"
            [isEllipseEnabled]="true"
            dspNextGenUiCopy
            [fullText]="ownershipData.dealerToolTipData"
          >
            <strong>Dealer Customer</strong>:
            <span>{{ getTrimData(ownershipData) }} </span>
          </div>
          <div class="name">
            <strong>Ownership Type</strong>:&nbsp;<span *ngIf="ownershipData?.ownershipType">{{
              ownershipData.ownershipType | convertOwnership
            }}</span>
          </div>
          <div class="name">
            <strong>Digital Authorization</strong>:&nbsp;
            <span class="icon-size" *ngIf="ownershipData.dealerCode === dealer?.dealerCode; else else_content">
              <cc-icon
                fontSet="icomoon"
                [interactive]="true"
                [tooltipPosition]="'top'"
                [template]="tooltipTemplateForDAT"
                [appClass]="'-dat-tool-tip'"
                [ngClass]="checkDATStatus(ownershipData)"
                cc-tooltip
                >{{ updateDATBadge(ownershipData) }}
              </cc-icon>
              <span class="asset-details-value">{{ updateDatStatus(ownershipData) }}</span>
            </span>
          </div>
        </div>
      </cc-list-item>
    </cc-list>
  </div>
</div>
<ng-template #tooltipTemplateForDAT>
  <div class="dat-tooltip-header">
    Digital Authorization Tool <cc-icon class="close" fontSet="icomoon" (click)="closeTooltip()">cross</cc-icon>
  </div>
  <div class="dat-tooltip-body">
    <span *ngIf="datStatus === 'AGREE'"
      >Customer authorization on file - No further action required. Click to review in
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'DECLINE'"
      >Customer authorization declined - Click to review and take action through the
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'UNAVAILABLE'"
      >Customer authorization unavailable - Click to review and take action through the
      <span (click)="datClick()" class="dat-hyperlink">Digital Authorization Tool</span>
    </span>
    <span *ngIf="datStatus === 'SYSTEM ERROR'"
      >Unable to get authorization details - Please retry later or contact Cat Digital support
    </span>
  </div>
</ng-template>
<ng-template #else_content>{{ '-' }}</ng-template>
