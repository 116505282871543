import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  rootURL = '';
  dealer: any;
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getExpandRow(queryParams: any) {
    return this.http.get(this.rootURL + '/user/appinfo', {
      params: queryParams,
    });
  }

  getUserSearch(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/user/getUserSearch', payload);
  }
}
// {{APIHOST}}/user/appinfo
