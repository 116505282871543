import { Component, Input, OnInit } from '@angular/core';
import { CcModalRef, MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { ConfirmationModalComponent } from 'apps/dsp-ui/src/app/confirmation-modal/confirmation-modal/confirmation-modal.component';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';

@Component({
  selector: 'dsp-next-gen-ui-payment-preview-confirmation',
  templateUrl: './payment-preview-confirmation.component.html',
  styleUrls: ['./payment-preview-confirmation.component.scss'],
})
export class PaymentPreviewConfirmationComponent implements OnInit {
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  searchAssetInfo: any;
  originalEditInfo: any;
  componentArr!: any[];
  techLevel: any;
  customerInfo: any;
  productFamilyCode: any;

  constructor(
    public assetDrawerService: AssetDrawerService,
    private modalRef: CcModalRef<ConfirmationModalComponent>,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.assetDrawerService.searchAssetInfo.subscribe((data: any) => {
      this.searchAssetInfo = data;
    });

    this.assetDrawerService.catGradeCustomerInfo.subscribe((data: any) => {
      this.customerInfo = data;
    });

    this.assetDrawerService.productFamilyCode.subscribe((data: any) => {
      this.productFamilyCode = data;
    });

    this.assetDrawerService.paymentPreviewConfirmationData.subscribe((data: any) => {
      this.originalEditInfo = data;
    });
    this.assetDrawerService.catGradeEditInfo.subscribe((data: any) => {
      // this.originalEditInfo = data.editInfo;
      // this.techLevel = data.techLevel;
    });
    this.techLevel = sessionStorage.getItem('techLevel');
  }
  onClickCancel() {
    this.modalRef.close('Modal Closed');
  }

  onClickSubmit() {
    this.componentArr = this.originalEditInfo?.map((res: any) => {
      const id: string = res.id ? res.id : '';
      return {
        id: id.toString(),
        componentType: res.type ? res.type : '-',
        ecmSerialNumber: res.serialNumber ? res.serialNumber : '',
        requestedLicense: res.nextUpgradableLicensePartNum ? res.nextUpgradableLicensePartNum : '',
        // productFamily: this.productFamilyCode,
      };
    });
    const payload = {
      assetSerialNumber: this.searchAssetInfo.serialNumber ? this.searchAssetInfo.serialNumber : '',
      make: this.searchAssetInfo.make ? this.searchAssetInfo.make : '',
      model: this.searchAssetInfo.model ? this.searchAssetInfo.model : '',
      techLevel: this.techLevel ? this.techLevel : '',
      dealerCode: fetchLoggedInDealerDetails().dealerCode,
      ucid: this.customerInfo.ucid ? this.customerInfo.ucid : '',
      ucidName: this.customerInfo.ucidName ? this.customerInfo.ucidName : '',
      dcnName: this.customerInfo.dealerCustName ? this.customerInfo.dealerCustName : '',
      dcnNumber: this.customerInfo.dealerCustNo ? this.customerInfo.dealerCustNo : '',
      components: this.componentArr ? this.componentArr : [],
      productFamily: this.productFamilyCode,
    };

    this.assetDrawerService.submitLicense(payload).subscribe(
      (res: any) => {
        if (res === 'SUCCESS') {
          const messageText = 'License requested successfully.';
          this.showToastMessage(messageText, 'success');
          this.onClickCancel();
          this.assetDrawerService.setGradeControlDrawer(true);
          this.assetDrawerService.selectedLicense.next('');
        } else {
          const messageText = 'There is an error.Please try again later.';
          this.showToastMessage(messageText, 'error');
          this.onClickCancel();
          this.assetDrawerService.setGradeControlDrawer(true);
          this.assetDrawerService.selectedLicense.next('');
        }
      },
      (err: any) => {
        if (err.status === 404 || err.status === 400 || err.status === 500) {
          const linkDetail = [
            {
              label: 'Cat Digital Support',
              fn: () => {
                window.open(
                  'https://cat-crmi.secure.force.com/CatConnectCase/?_ga=2.117467593.170777017.1674835347-192150537.1661439604'
                );
              },
              class: 'cat-digital-support-hyperlink',
            },
          ];
          this.showErrorToastMessage('Request failed. Please try again later or contact', 'error', linkDetail);
          this.onClickCancel();
          this.assetDrawerService.setGradeControlDrawer(true);
          this.assetDrawerService.selectedLicense.next('');
        } else if (err.status === 201) {
          const messageText = 'License requested successfully.';
          this.showToastMessage(messageText, 'success');
          this.onClickCancel();
          this.assetDrawerService.setGradeControlDrawer(true);
          this.assetDrawerService.selectedLicense.next('');
        }
      }
    );
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  showErrorToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'device-status',
      appOverlayClass: 'cat-digital-support-hyperlink',
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }
}
