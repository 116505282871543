<div class="site-action-menu">
  <cc-icon
    class="icon-size"
    cc-menu
    [template]="siteMenuOptions"
    [OverlayPosition]="'bottomright'"
    (click)="$event.stopPropagation()"
  >
    row-action
  </cc-icon>
</div>
<ng-template #siteMenuOptions>
  <div class="group-info__options__list">
    <cc-list>
      <cc-list-item (listItemClick)="handleEditSiteOption()" class="group-info__options__list-item">
        <span>
          <cc-icon fontSet="icomoon">pencil</cc-icon>
          Edit Site
        </span>
      </cc-list-item>
      <cc-list-item
        *ngIf="siteInfo?.status === 'InActive'"
        (listItemClick)="handleDeleteSiteOption()"
        class="group-info__options__list-item delete-site"
      >
        <span [ngStyle]="{ color: '#DE2222' }">
          <cc-icon fontSet="icomoon">trash</cc-icon>
          Delete Site
        </span>
      </cc-list-item>
      <cc-list-item *ngIf="siteInfo?.status === 'Active'" class="group-info__options__list-item"
        ><div class="cancel-plan">
          <span
            class="cancelPlanIcon"
            [ngStyle]="{ color: '#DE2222' }"
            [ngClass]="siteInfo?.assetCount! > 0 ? 'disabled' : ''"
            (click)="siteInfo?.assetCount! > 0 ? false : handleCancelPlanOption(siteInfo)"
          >
            <cc-icon fontSet="icomoon">close</cc-icon>
            Cancel Plan
          </span>
          <span *ngIf="siteInfo?.assetCount! > 0" class="info-icon">
            <span cc-tooltip="" [tooltipPosition]="'top-left'" [template]="toolTipContent">
              <cc-icon fontSet="icomoon">info-tooltip</cc-icon>
            </span>
          </span>
        </div>
        <ng-template #toolTipContent>
          <div class="tooltopContent">You can't cancel the plan</div>
          <div class="tooltopContent">with active asset</div>
        </ng-template>
      </cc-list-item>
    </cc-list>
  </div>
</ng-template>

<ng-template #deleteSiteTemplateRef>
  <ng-container>
    <div class="cc-modal">
      <div class="cc-modal-header">
        <div class="cc-modal-title">Confirm</div>
      </div>

      <div class="cc-modal-content warning-modal-content">
        <p>Are you sure that you want to delete this site?</p>
      </div>
      <div class="cc-modal-footer">
        <button
          cc-btn-secondary
          cc-btn-large
          [setTheme]="true"
          class="cancel button-opacity"
          (click)="onCancel()"
        >
          Cancel
        </button>
        <button
          cc-btn-danger
          cc-btn-large
          [setTheme]="true"
          class="delete button-opacity"
          (click)="handleDeleteSite(siteInfo)"
        >
          Delete
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
