import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormattingService {
  customContractDateFn(contractDate: any) { 
    contractDate = this.formatDate(new Date(contractDate), 'YYYY/MM/DD');

    return contractDate;
  }
  formatDate(value: any, type: any) {
    const date_value = new Date(value);
    const dfmt = {
      date: ('0' + date_value.getDate()).slice(-2),
      month: ('0' + (date_value.getMonth() + 1)).slice(-2),
      year: date_value.getFullYear(),
    };
    switch (type) {
      case 'YYYY/MM/DD':
        return dfmt.year + '/' + dfmt.month + '/' + dfmt.date;
      case 'MM/DD/YYYY':
        return dfmt.month + '/' + dfmt.date + '/' + dfmt.year;
      default:
        return dfmt.date + '/' + dfmt.month + '/' + dfmt.year;
    }
  }
  iosDateConstraint(dateVal: any) {
    const splitDate1 = dateVal.split('/');
    const month1 = splitDate1[0];
    const day1 = splitDate1[1];
    const year1 = splitDate1[2];
    return year1 + '/' + month1 + '/' + day1;
  }
}
