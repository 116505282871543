<div class="sale-name-column-align">
  <div
    class="sale-dealer"
    [tooltipPosition]="'top'"
    [isEnableTooltioOnEllipsis]="true"
    cc-tooltip="accountSales"
    [template]="template2"
  >
    <span [innerHTML]="rowData.salesReason"></span>
  </div>

  <ng-template #template2>
    <div style="width: 220px; white-space: normal">
      <span> {{ rowData.salesReason }}</span>
    </div>
  </ng-template>
</div>
