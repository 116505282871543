import { createSelector } from '@ngrx/store';
import { DSPAppState } from '../state/dsp.state';
import { UserState, DealerListState } from '../state/user-info.state';

const selectUserState = (state: DSPAppState) => state.user;

export const loggedInUser = createSelector(selectUserState, (state: UserState) => state);

const selectDealerListState = (state: DSPAppState) => state.dealerList;

export const getDealerList = createSelector(
  selectDealerListState,
  (state: DealerListState) => state.dealer
);
