import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerConfig, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core';

@Component({
  selector: 'dsp-next-gen-ui-dcn-list',
  templateUrl: './dcn-list.component.html',
  styleUrls: ['./dcn-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DcnListComponent implements OnInit {
  dcnlist: any;
  disableSubmitBtn = true;
  selectedDCN: any;
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      dcnList: any;
      owner: any;
    },
    public dialogRef: CcDrawerRef<DcnListComponent, CcDrawerConfig>
  ) {}
  ngOnInit(): void {
    this.dcnlist = this.header.dcnList || [];
    this.dcnlist.forEach((item: any) => {
      item.checked = false;
    });
  }
  handleSubmit() {
    this.closeDrawer(this.selectedDCN);
  }
  dcnOptionClick(event: any) {
    this.selectedDCN = {
      ownershipId: this.header.owner?.ownershipId,
      dealerCustNo: event.value.organization,
      dealerCustName: event.value.organizationName,
    };
    event.value.checked = true;
    this.disableSubmitBtn = false;
  }
  closeDrawer(val: any) {
    if (val) {
      this.dialogRef.close(val);
    } else {
      this.dialogRef.close();
    }
  }
}
