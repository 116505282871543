<div class="search-result-container" #searchCwsResults>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--12" [formGroup]="sortingForm">
      <div class="side-divs">
        <div class="resultsTable-header">
          <p class="table-header">Search Results ({{ searchedUsers ? searchedUsers.length : 0 }})</p>
          <ng-container>
            <cc-dropdown
              class="sort-options"
              [isTheme]="'2.0'"
              cc-dropdown-medium
              name="checkbox"
              [prefixLabel]="'Sort By'"
              [dataSource]="pageNumber"
              formControlName="sortingInSearchData"
              (selectionChange)="searchSort()"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
              [selected]="true"
            >
            </cc-dropdown>
          </ng-container>
        </div>
        <div class="resultsTable-container">
          <div *ngIf="searchedUsers && searchedUsers.length === 0">
            <img class="no-results-found-image" src="..\assets\images\no-results-cwsid-search.svg" />
            <p class="no-results-text">No results to display</p>
          </div>
          <div *ngFor="let user of searchedUsers">
            <div class="user-results-entity">
              <div class="user-details-part">
                <p class="result-font-style1">{{ user.cwsId }} - {{ user.firstName }} {{ user.lastName }}</p>
                <p class="result-font-style2">{{ user.email }}</p>
              </div>
              <div class="subscription-flag">
                <div *ngIf="user.isSubscribed" class="flexDisplay">
                  <div class="subscribed-ellipse-icon">
                    <img src="..\assets\images\subscibed-ellipse.png" />
                    <span class="subscribedText">Subscribed</span>
                  </div>
                </div>
              </div>
              <div class="add-subscription">
                <button [disabled]="disableAddUser(user)" style="border: none; background-color: inherit">
                  <img
                    *ngIf="!disableAddUser(user)"
                    cc-tooltip="Add"
                    [tooltipPosition]="'top'"
                    src="..\assets\images\add-user-icon-enabled.svg"
                    (click)="handleAddUser(user)"
                  />
                  <img *ngIf="disableAddUser(user)" src="..\assets\images\add-user-icon-disabled.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--12" [formGroup]="sortingForm">
      <div class="side-divs">
        <div class="resultsTable-header">
          <p class="table-header">Selected Users ({{ selectedUsers ? selectedUsers.length : 0 }})</p>
          <ng-container>
            <cc-dropdown
              class="sort-options"
              [isTheme]="'2.0'"
              cc-dropdown-medium
              name="checkbox"
              [prefixLabel]="'Sort By'"
              [dataSource]="pageNumber"
              formControlName="sortingInSelectedData"
              (selectionChange)="selectSort()"
              [scrollToSelected]="true"
              [selectionOnClose]="true"
              [selected]="true"
            >
            </cc-dropdown>
          </ng-container>
          <span class="all-text" *ngIf="selectedUsers && selectedUsers.length > 0" (click)="removeAllUsers()">
            Remove All ({{ selectedUsers.length }})
            <cc-icon class="remove-all-icon" fontSet="icomoon">remove-all</cc-icon>
          </span>
        </div>
        <div class="resultsTable-container">
          <div *ngIf="selectedUsers && selectedUsers.length === 0">
            <img class="no-results-found-image" src="..\assets\images\no-results-cwsid-search.svg" />
            <p class="no-results-text">No users selected</p>
          </div>
          <div *ngFor="let user of selectedUsers">
            <div class="user-results-entity">
              <div class="user-details-part">
                <p class="result-font-style1">{{ user.cwsId }} - {{ user.firstName }} {{ user.lastName }}</p>
                <p class="result-font-style2">{{ user.email || user.emailAddress }}</p>
              </div>
              <div class="subscription-flag">
                <div *ngIf="updateSubscribedStatus(user)" class="flexDisplay">
                  <div class="subscribed-ellipse-icon">
                    <img src="..\assets\images\subscibed-ellipse.png" />
                    <span class="subscribedText">Subscribed</span>
                  </div>
                </div>
              </div>
              <img
                cc-tooltip="Remove"
                [tooltipPosition]="'right'"
                src="..\assets\images\remove_icon.svg"
                (click)="handleRemoveUser(user)"
                style="margin-left: auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--0"></div>
  </div>
  <div class="cc-grid" *ngIf="selectedUsers && selectedUsers.length > 0">
    <p class="info-text-style" [ngStyle]="{ paddingBottom: parentType === 'edit-plan' ? '50px' : '20px' }">
      <cc-icon [fontSet]="'icomoon'" [inline]="true" class="icon">info</cc-icon>
      Note: You can only add up to 25 users. Please create a new subscription for additional users.
    </p>
  </div>
</div>
