import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-config-info',
  templateUrl: './config-info.component.html',
  styleUrls: ['./config-info.component.scss'],
})
export class ConfigInfoComponent implements OnInit {
  @Input() assetData: any;
  configData: any;
  ngOnInit(): void {
    this.configData = this.assetData ? this.assetData : {};
  }
}
