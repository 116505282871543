<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="text"> Product | Plan </span>
    <cc-icon fontSet="icomoon" class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()"
      >cross</cc-icon
    >
  </div>
  <div *ngIf="seemoreOverlayUpdate" class="site-container">
    <div class="site-name">{{ rowData ? rowData.name : '' }}</div>
  </div>

  <div class="cc-drawer__content filters-drawer" *ngIf="seemoreOverlayUpdate">
    <cc-accordion>
      <div>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ rowData ? rowData.product : '' }}</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div class="cc-drawer__content product-block">
              <cc-list>
                <cc-list-item class="" style="padding-bottom: 10px">
                  <div class="name">Plan</div>
                  <div class="left-border-style">
                    <span class="planName"> {{ planName }} </span>
                  </div>
                </cc-list-item>
                <cc-list-item
                  class="cc-drawer__content addon"
                  *ngIf="addonServicesList.length > 0"
                  style="padding-bottom: 0"
                >
                  <div class="name">Optional Plans</div>
                  <div *ngFor="let service of addonServicesList">
                    <div class="left-border-style">
                      <span class="planName"> {{ service }} </span>
                    </div>
                  </div>
                </cc-list-item>
              </cc-list>
            </div>
          </ng-container>
        </cc-expandable>
      </div>
    </cc-accordion>
  </div>
  <div class="cc-drawer__content product-block" *ngIf="!seemoreOverlayUpdate">
    <div class="old-overlay">
      <cc-list>
        <cc-list-item class="product">
          <span> {{ rowData ? rowData.product : '' }} </span>
        </cc-list-item>
        <cc-list-item class="plan" style="padding-bottom: 0">
          <div class="name">Plan</div>
          <div class="left-border-style">
            <span class="planName"> {{ planName }} </span>
          </div>
        </cc-list-item>
        <cc-list-item>
          <div *ngIf="addonServicesList.length > 0" class="cc-drawer__content addon">
            <span class="name"> Optional Plans</span><br />
            <cc-list *ngFor="let service of addonServicesList">
              <cc-list-item>
                <div class="left-border-style">
                  <span class="planName"> {{ service }} </span>
                </div>
              </cc-list-item>
            </cc-list>
          </div>
        </cc-list-item>
      </cc-list>
    </div>
  </div>
</div>
