<div class="pending-request-section">
  <!-- Details Section -->
  <dsp-next-gen-ui-pending-request-asset-details [rowData]="rowData" [pendingRequestFormData]="pendingRequestFormData">
  </dsp-next-gen-ui-pending-request-asset-details>
  <!-- Subscription Details Section -->
  <dsp-next-gen-ui-pending-request-subscription-details
    [rowData]="rowData"
    [pendingRequestFormData]="pendingRequestFormData"
  >
  </dsp-next-gen-ui-pending-request-subscription-details>
  <!-- Approve/Decline Section -->
  <dsp-next-gen-ui-approve-request [pendingRequestFormData]="pendingRequestFormData"></dsp-next-gen-ui-approve-request>
</div>
