import { Component, EventEmitter, HostListener, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerSize,
  DrawerService,
  CcDrawerRef,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';

import { UserAdminService } from '../../services/user-admin.service';
import { UserOnboardDefaultData } from './user-onboard-constants';
import { ConfigureDspAdminDrawerComponent } from '../configure-dsp-admin-drawer/configure-dsp-admin-drawer.component';
import { CUSTOMACCESS } from '../../user-admin-constant';

@Component({
  selector: 'dsp-next-gen-ui-configure-access',
  templateUrl: './configure-access.component.html',
  styleUrls: ['./configure-access.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureAccessComponent implements OnInit {
  @Output() updateExpandRowDetailsEvent = new EventEmitter<any>();
  isTheme = '2.0';
  configureAccessForm!: FormGroup;
  roles = [];
  userSelect: any = [];
  selectedUserSelect: any;
  userSelectChanged = false;

  thirdPartyList = [];
  wifiAccessList = [];
  operatorManagementList = [];
  dspList = [];
  appRoleInfoResponse: any = [];
  dealerList: any = [];
  expandRowDetails: any = [];
  dropdownLabelList: any = [];
  selectedThirdParty: any;
  selectedWifiAccess: any;
  selectedOperatorManagement: any;
  selectedDspAccess: any;
  updatedDspAccess: any;
  updatedDspAccessObj: any = {};
  thirdPartyValueChanged = false;
  wifiAccessValueChanged = false;
  operatorManagementValueChanged = false;
  DspValueChanged = false;
  updateExpandRowDetails = [{}];
  userOnBoardAPIPayload: any;
  UserOnboardResp: any;
  map = new Map<string, [string, Number, Number]>();
  index = 0;
  rowDataFirstName = '';
  rowDataLastName = '';
  public value: any;
  public rowData: any;
  public customData: any;
  public columnWithHeader: any;
  configureAccessDrawerRef!: any;
  dealerChange = false;
  secondaryDealerCode: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer(false);
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      rowData: any;
      expandRowDetails: any;
    },
    private formBuilder: FormBuilder,
    public dialogRef: CcDrawerRef<ConfigureAccessComponent, CcDrawerConfig>,
    private userAdminService: UserAdminService,
    private drawerService: DrawerService,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.expandRowDetails = this.header.expandRowDetails;
    this.rowData = this.header.rowData;
    this.rowDataFirstName = this.rowData?.firstName === '-' ? '' : this.rowData?.firstName;
    this.rowDataLastName = this.rowData?.lastName === '-' ? '' : this.rowData?.lastName;
    this.expandRowDetails?.map((ele: any) => {
      this.dropdownLabelList.push(ele.appName);
      this.map.set(ele.appName, [ele.roleName, ele.appId, ele.roleId]);
    });
    this.configureAccessForm = this.formBuilder.group({
      list1: [this.map.get('3rd Party API Integration Tool')?.[0] || ''],
      list2: [this.map.get('Asset Wi-Fi Access')?.[0] || ''],
      list3: [this.map.get('Operator Management System')?.[0] || ''],
      selectDealer: '',
    });
    if (this.rowData?.profileType === 'Dealer') {
      this.getuserDealerSearch();
    }

    this.setSavedAccessDetails();
    this.loadAppRoleInfoData();
  }
  get formControl() {
    return this.configureAccessForm.controls;
  }
  setSavedAccessDetails() {
    const Obj1 = {
      label: this.map.get('3rd Party API Integration Tool')?.[0],
      value: this.map.get('3rd Party API Integration Tool')?.[2],
    };
    this.selectedThirdParty = Obj1;
    this.configureAccessForm?.patchValue({ ['list1']: [Obj1] });
    const Obj2 = {
      label: this.map.get('Asset Wi-Fi Access')?.[0],
      value: this.map.get('Asset Wi-Fi Access')?.[2],
    };
    this.selectedWifiAccess = Obj2;
    this.configureAccessForm?.patchValue({ ['list2']: [Obj2] });
    const Obj3 = {
      label: this.map.get('Operator Management System')?.[0],
      value: this.map.get('Operator Management System')?.[2],
    };
    this.selectedOperatorManagement = Obj3;
    this.configureAccessForm?.patchValue({ ['list3']: [Obj3] });
    this.selectedDspAccess = this.map.get('Dealer Services Portal (Web & Mobile)')?.[0];
    this.updatedDspAccess = this.selectedDspAccess;
  }
  loadAppRoleInfoData() {
    const appRoleInfoPayload = [];
    if (this.map.get('3rd Party API Integration Tool')?.[1]) {
      appRoleInfoPayload.push({
        appId: this.map.get('3rd Party API Integration Tool')?.[1] || null,
        profileName: this.rowData?.profileType,
      });
    }
    if (this.map.get('Asset Wi-Fi Access')?.[1]) {
      appRoleInfoPayload.push({
        appId: this.map.get('Asset Wi-Fi Access')?.[1] || null,
        profileName: this.rowData?.profileType,
      });
    }
    if (this.map.get('Operator Management System')?.[1]) {
      appRoleInfoPayload.push({
        appId: this.map.get('Operator Management System')?.[1] || null,
        profileName: this.rowData?.profileType,
      });
    }

    this.userAdminService.getAppRoleInfo(appRoleInfoPayload).subscribe({
      next: (response: any) => {
        this.appRoleInfoResponse = response;
        this.assignDropdownValues();
      },
      error: (errorResponse: any) => {},
    });
  }

  getuserDealerSearch() {
    const payload = { cwsId: this.rowData?.cwsid };

    this.userAdminService.getuserDealerSearch(payload).subscribe({
      next: (res: any) => {
        this.dealerList = res;
        this.userSelect = this.dealerList?.results.map((item: any) => ({
          label: item.dealerCode + '-' + item.dealerName,
          value: item.dealerCode + '-' + item.dealerName,
        }));
      },
      error: (error: any) => {
        if (error.status === 404) {
          const results = {
            label: this.rowData?.dealerCode,
            value: this.rowData?.organization,
          };
          this.dealerList.push(results);
          this.userSelect = this.dealerList.map((item: any) => ({
            label: item.label + '-' + item.value,
            value: item.value,
          }));
        }
      },
    });
  }
  assignDropdownValues() {
    if (this.appRoleInfoResponse.length > 0) {
      this.appRoleInfoResponse.forEach((obj: any) => {
        if (obj.appId === this.map.get('3rd Party API Integration Tool')?.[1]) {
          this.thirdPartyList = obj.roles.map((role: any) => ({
            label: role.roleName,
            value: role.id,
          }));
        } else if (obj.appId === this.map.get('Asset Wi-Fi Access')?.[1]) {
          this.wifiAccessList = obj.roles.map((role: any) => ({
            label: role.roleName,
            value: role.id,
          }));
        } else if (obj.appId === this.map.get('Operator Management System')?.[1]) {
          this.operatorManagementList = obj.roles.map((role: any) => ({
            label: role.roleName,
            value: role.id,
          }));
        } else if (obj.appId === this.map.get('Dealer Services Portal (Web & Mobile)')?.[1]) {
          this.dspList = obj.roles.map((role: any) => ({
            label: role.roleName,
            value: role.id,
          }));
        }
      });
    }
  }

  onDropdownChange({ currentSelection }: any, prop: string) {
    if (this.configureAccessForm.valid) {
      if (this.configureAccessForm.controls['selectDealer'].value) {
        this.dealerChange = true;
        const dealerArr = this.configureAccessForm.controls['selectDealer'].value[0]?.value.split('-');
        this.secondaryDealerCode = dealerArr ? dealerArr[0] : '';
      }
    }
    this.configureAccessForm.patchValue({
      [prop]: currentSelection,
    });
    this.checkRoleChange();
  }
  checkRoleChange() {
    if (this.selectedThirdParty?.label !== this.configureAccessForm?.value?.list1?.[0]?.label) {
      this.thirdPartyValueChanged = true;
    } else {
      this.thirdPartyValueChanged = false;
    }
    if (this.selectedWifiAccess.label !== this.configureAccessForm?.value?.list2?.[0]?.label) {
      this.wifiAccessValueChanged = true;
    } else {
      this.wifiAccessValueChanged = false;
    }
    if (this.selectedOperatorManagement.label !== this.configureAccessForm?.value?.list3?.[0]?.label) {
      this.operatorManagementValueChanged = true;
    } else {
      this.operatorManagementValueChanged = false;
    }
  }
  dspConfigureAccess() {
    this.configureAccessDrawerRef = this.drawerService.openDrawer<ConfigureDspAdminDrawerComponent, CcDrawerConfig>(
      ConfigureDspAdminDrawerComponent,
      {
        data: {
          headerType: 'drawerContent',
          appId: this.map.get('Dealer Services Portal (Web & Mobile)')?.[1],
          roleName:
            this.updatedDspAccessObj && this.updatedDspAccessObj.roleName
              ? this.updatedDspAccessObj.roleName
              : this.map.get('Dealer Services Portal (Web & Mobile)')?.[0],
          roleId:
            this.updatedDspAccessObj && this.updatedDspAccessObj.roleId
              ? this.updatedDspAccessObj.roleId
              : this.map.get('Dealer Services Portal (Web & Mobile)')?.[2],
          catRecId: this.rowData?.catRecId,
          profileType: this.rowData?.profileType,
          dealerCode: this.rowData?.dealerCode,
          affiliationCode: this.rowData?.affiliationCode,
        },

        enableBlackBackDrop: true,
        closeOnEsc: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );
    this.configureAccessDrawerRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.updatedDspAccessObj = response;
        this.updatedDspAccess = this.updatedDspAccessObj.roleName;
        if (
          this.selectedDspAccess?.toUpperCase() === CUSTOMACCESS &&
          this.updatedDspAccessObj?.roleName?.toUpperCase() === CUSTOMACCESS
        ) {
          if (this.updatedDspAccessObj.selectedFeatures === this.updatedDspAccessObj.defaultFeatures) {
            this.DspValueChanged = false;
          } else {
            this.DspValueChanged = true;
          }
        } else {
          if (this.selectedDspAccess !== this.updatedDspAccess) {
            this.DspValueChanged = true;
          } else {
            this.DspValueChanged = false;
          }
        }
      }
    });
  }
  enableSaveButton() {
    if (this.selectedThirdParty?.label !== this.configureAccessForm?.value?.list1[0]?.label) {
      return true;
    } else if (this.selectedWifiAccess.label !== this.configureAccessForm?.value?.list2[0]?.label) {
      return true;
    } else if (this.selectedOperatorManagement.label !== this.configureAccessForm?.value?.list3[0]?.label) {
      return true;
    } else if (this.selectedDspAccess !== this.updatedDspAccess) {
      return true;
    } else if (
      this.selectedDspAccess?.toUpperCase() === CUSTOMACCESS &&
      this.updatedDspAccess?.toUpperCase() === CUSTOMACCESS
    ) {
      if (this.updatedDspAccessObj.selectedFeatures === this.updatedDspAccessObj.defaultFeatures) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  onSaveConfigureAccess() {
    this.userOnBoardAPIPayload = [{}];
    this.index = 0;
    if (this.thirdPartyValueChanged) {
      this.userOnBoardAPIPayload[this.index] = {
        application: '3rd Party API Integration Tool',
        catrecId: this.rowData?.catRecId,
        cwsId: this.rowData?.cwsid,
        dealerCode: this.rowData?.dealerCode,
        dealerName: '',
        email: this.rowData?.emailAddress,
        existingRoleName: this.selectedThirdParty.label,
        features: [],
        firstName: this.rowData?.firstName == '-' ? '' : this.rowData?.firstName,
        lastName: this.rowData?.lastName == '-' ? '' : this.rowData?.lastName,
        middleName: this.rowData?.middleName == '-' ? '' : this.rowData?.middleName,
        organization: this.rowData?.organization,
        profile: this.rowData?.profileType,
        roleName: this.configureAccessForm?.value?.list1[0]?.label,
        roleId: this.configureAccessForm?.value?.list1[0]?.value,
        ucid: this.rowData?.ucid,
        ucidName: this.rowData?.ucName,
        cellularPhoneNumber: this.rowData?.cellularPhoneNumber,
        telePhoneNumber: this.rowData?.telePhoneNumber,
      };
      this.updateExpandRowDetails[this.index] = {
        appid: Number(this.map.get('3rd Party API Integration Tool')?.[1]),
        appName: '3rd Party API Integration Tool',
        roleId: this.configureAccessForm?.value?.list1[0]?.value,
        roleName: this.configureAccessForm?.value?.list1[0]?.label,
      };
      this.index = this.index + 1;
    }
    if (this.wifiAccessValueChanged) {
      this.userOnBoardAPIPayload[this.index] = {
        application: 'Asset Wi-Fi Access',
        catrecId: this.rowData?.catRecId,
        cwsId: this.rowData?.cwsid,
        dealerCode: this.rowData?.dealerCode,
        dealerName: '',
        email: this.rowData?.emailAddress,
        existingRoleName: this.selectedWifiAccess.label,
        features: [],
        firstName: this.rowData?.firstName == '-' ? '' : this.rowData?.firstName,
        lastName: this.rowData?.lastName == '-' ? '' : this.rowData?.lastName,
        middleName: this.rowData?.middleName == '-' ? '' : this.rowData?.middleName,
        organization: this.rowData?.organization,
        profile: this.rowData?.profileType,
        roleName: this.configureAccessForm?.value?.list2[0]?.label,
        roleId: this.configureAccessForm?.value?.list2[0]?.value,
        ucid: this.rowData?.ucid,
        ucidName: this.rowData?.ucName,
        cellularPhoneNumber: this.rowData?.cellularPhoneNumber,
        telePhoneNumber: this.rowData?.telePhoneNumber,
      };
      this.updateExpandRowDetails[this.index] = {
        appid: Number(this.map.get('Asset Wi-Fi Access')?.[1]),
        appName: 'Asset Wi-Fi Access',
        roleId: this.configureAccessForm?.value?.list2[0]?.value,
        roleName: this.configureAccessForm?.value?.list2[0]?.label,
      };
      this.index = this.index + 1;
    }
    if (this.operatorManagementValueChanged) {
      this.userOnBoardAPIPayload[this.index] = {
        application: 'Operator Management System',
        catrecId: this.rowData?.catRecId,
        cwsId: this.rowData?.cwsid,
        dealerCode: this.rowData?.dealerCode,
        dealerName: '',
        email: this.rowData?.emailAddress,
        existingRoleName: this.selectedOperatorManagement.label,
        features: [],
        firstName: this.rowData?.firstName == '-' ? '' : this.rowData?.firstName,
        lastName: this.rowData?.lastName == '-' ? '' : this.rowData?.lastName,
        middleName: this.rowData?.middleName == '-' ? '' : this.rowData?.middleName,
        organization: this.rowData?.organization,
        profile: this.rowData?.profileType,
        roleName: this.configureAccessForm?.value?.list3[0]?.label,
        roleId: this.configureAccessForm?.value?.list3[0]?.value,
        ucid: this.rowData?.ucid,
        ucidName: this.rowData?.ucName,
        cellularPhoneNumber: this.rowData?.cellularPhoneNumber,
        telePhoneNumber: this.rowData?.telePhoneNumber,
      };
      this.updateExpandRowDetails[this.index] = {
        appid: Number(this.map.get('Operator Management System')?.[1]),
        appName: 'Operator Management System',
        roleId: this.configureAccessForm?.value?.list3[0]?.value,
        roleName: this.configureAccessForm?.value?.list3[0]?.label,
      };
      this.index = this.index + 1;
    }
    if (this.DspValueChanged || this.dealerChange) {
      this.userOnBoardAPIPayload[this.index] = {
        application: 'Dealer Services Portal',
        catrecId: this.rowData?.catRecId,
        cwsId: this.rowData?.cwsid,
        dealerCode: this.rowData?.dealerCode,
        catAltAuthOrgCode: this.secondaryDealerCode ? this.secondaryDealerCode : '',
        dealerName: '',
        email: this.rowData?.emailAddress,
        existingRoleName: this.selectedDspAccess,
        features: this.updatedDspAccessObj?.selectedFeatures,
        firstName: this.rowData?.firstName == '-' ? '' : this.rowData?.firstName,
        lastName: this.rowData?.lastName == '-' ? '' : this.rowData?.lastName,
        middleName: this.rowData?.middleName == '-' ? '' : this.rowData?.middleName,
        organization: this.rowData?.organization,
        profile: this.rowData?.profileType,
        roleName: this.updatedDspAccess,
        roleId: this.updatedDspAccessObj?.roleId,
        ucid: this.rowData?.ucid,
        ucidName: this.rowData?.ucName,
        cellularPhoneNumber: this.rowData?.cellularPhoneNumber,
        telePhoneNumber: this.rowData?.telePhoneNumber,
      };
      this.updateExpandRowDetails[this.index] = {
        appid: Number(this.map.get('Dealer Services Portal (Web & Mobile)')?.[1]),
        appName: 'Dealer Services Portal',
        roleId: this.updatedDspAccessObj?.roleId,
        roleName: this.updatedDspAccess,
      };
      this.index = this.index + 1;
    }

    this.userAdminService.getAppUserOnboard(this.userOnBoardAPIPayload).subscribe({
      next: (response: any) => {
        this.UserOnboardResp = response;
        this.closeDrawer(true);
        const message = 'User Account Settings Changed.';
        this.showToastMessage(message, 'success');
      },
      error: (errorResponse: any) => {
        this.closeDrawer(false);
        const message = 'Internal error.Please contact customer support';
        this.showToastMessage(message, 'error');
      },
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  closeDrawer(val: any) {
    if (val) {
      this.updateExpandRowDetails = [...this.updateExpandRowDetails];
      this.dialogRef.close(val);
      this.userAdminService.updateExpandRowDetails(this.updateExpandRowDetails);
    } else {
      this.dialogRef.close();
    }
  }
}
