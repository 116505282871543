import { CreateLinks, deleteLinks, Links } from '../../models/links.interface';

export interface LinksState {
    links: Links;
    isLoading?: boolean;
    isLoadingSuccess?: boolean;
    isLoadingFailure?: boolean;
    error?: any
}

export interface initialcreateLinkState {
    links: CreateLinks;
    isLoading?: boolean;
    isLoadingSuccess?: boolean;
    isLoadingFailure?: boolean;
    error?: any
}

export interface initialDeleteLinkState {
    links: deleteLinks;
    isLoading?: boolean;
    isLoadingSuccess?: boolean;
    isLoadingFailure?: boolean;
    error?: any
}

export const initiallinkState: LinksState = {
    links: {},
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
}

export const initialcreatelinkState: initialcreateLinkState = {
    links: {},
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
}
export const initialDeletelinkState: initialDeleteLinkState = {
    links: {},
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
}