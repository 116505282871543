<div *ngIf="isLicenseMappingEnabled">
  <form [formGroup]="licenseEligiblityForm" *ngIf="!isNoLicenseUpgradable; else noData">
    <cc-dropdown
      [isTheme]="'2.0'"
      [id]="'product-dropDown'"
      [label]="'Select Eligiblity'"
      [dataSource]="licenseData"
      [placementPosition]="'bottom'"
      (selectionChange)="eligiblityDropdownChange($event, rowData)"
      formControlName="licenseEligiblity"
      [typeahead]="true"
      [typeaheadmanualinput]="true"
      [scrollToSelected]="true"
      [search]="true"
    >
    </cc-dropdown>
  </form>
</div>

<div *ngIf="!isLicenseMappingEnabled">
  <span class="license-tooltip-text">{{ rowData?.nextUpgradableLicenseDesc }}</span>
</div>

<ng-template #noData>
  <span class="license-tooltip-text">{{ licenseEligiblityStatus }}</span>
</ng-template>
