import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fetchLoggedInDealerDetails } from '../shared/shared';
import { DSPAppState } from '../store/state/dsp.state';

@Injectable({
  providedIn: 'root',
})
export class CommunicationModeService {
  telUserType: any;
  dealer: any;
  constructor(private store: Store<DSPAppState>) {
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user.telUserType;
      this.dealer = fetchLoggedInDealerDetails();
    });
  }

  isDualModeSubFn(currScope: any) {
    let serviceVal =
      currScope.deviceType && currScope.catalogMapping[currScope.deviceType]
        ? currScope.catalogMapping[currScope.deviceType][currScope.selectedService]
        : undefined;
    if (serviceVal) {
      serviceVal = { ...serviceVal[this.telUserType || 'Customer'][currScope.selectedSubscription] };
    }
    return (serviceVal && serviceVal['showCommunicationMode']) || false;
  }

  isCommModeDisabledForDealerFn(currScope: any) {
    let serviceVal =
      currScope.deviceType && currScope.catalogMapping[currScope.deviceType]
        ? currScope.catalogMapping[currScope.deviceType][currScope.selectedService]
        : undefined;
    if (serviceVal) {
      serviceVal = { ...serviceVal[this.telUserType || 'Customer'][currScope.selectedSubscription] };
    }

    let isCommModeDisabledForDealer;
    if (serviceVal && serviceVal['isSubscribable'] && serviceVal['isSubscribable'] == false) {
      isCommModeDisabledForDealer = true;
    } else {
      const dealerInfo = this.dealer;
      if (currScope.dealerCode == null || dealerInfo.dealerCode == currScope.dealerCode) {
        isCommModeDisabledForDealer = false;
      } else {
        isCommModeDisabledForDealer = true;
      }
    }

    return isCommModeDisabledForDealer;
  }
}
