import { AuthConfig } from 'angular-oauth2-oidc';

import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { AppConfigService } from '../services/app-config.service';
import { environment } from '../../environments/environment';
import * as CONFIG_LOCAL from '../../assets/config/app-config.json';
import * as CONFIG_INT from '../../assets/config/app-config.int.json';
import * as CONFIG_QA from '../../assets/config/app-config.qa.json';
import * as CONFIG_QA_DSP_SIDEB from '../../assets/config/app-config.qa.dsp.sideb.json';
import * as CONFIG_QA2_DSP_SIDEB from '../../assets/config/app-config.qa2.dsp.sideb.json';
import * as CONFIG_QA2 from '../../assets/config/app-config.qa2.json';
import * as CONFIG_STAGE from '../../assets/config/app-config.stage.json';
import * as CONFIG_PROD from '../../assets/config/app-config.prod.json';
import * as CONFIG_PROD_SIDEB from '../../assets/config/app-config.prod.sideb.json';

export const clientid = 'dealer_service_portal_ac_client';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.redirectURL.loginUrl,

  skipIssuerCheck: true,

  // URL of the SPA to redirect the user to after login
  redirectUri: environment.redirectURL.hostBaseUrl,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: clientid,

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile',

  // ^^ Please note that offline_access is not needed for silent refresh
  // At least when using idsvr, this even prevents silent refresh
  // as idsvr ALWAYS prompts the user for consent when this scope is
  // requested

  // This is needed for silent refresh (refreshing tokens w/o a refresh_token)
  // **AND** for logging in with a popup
  silentRefreshRedirectUri: `${window.location.origin}/`,

  useSilentRefresh: false,

  showDebugInformation: true,

  // sessionChecksEnabled: true,

  timeoutFactor: 0.95,
  // disablePKCI: true,

  clearHashAfterLogin: false,

  customQueryParams: {
    pfidpadapterid: 'OAuthAdapterCCDS',
  },
  //logoutUrl: environment.redirectURL.logoutUrl + clientid,
};

export const logoutEndPoint = '/idp/startSLO.ping';

const env = environment.env;

const config_file =
  env === 'local'
    ? CONFIG_LOCAL
    : env === 'int'
    ? CONFIG_INT
    : env === 'qa'
    ? CONFIG_QA
    : env === 'qa_dsp_sideb'
    ? CONFIG_QA_DSP_SIDEB
    : env === 'qa2_dsp_sideb'
    ? CONFIG_QA2_DSP_SIDEB
    : env === 'qa2'
    ? CONFIG_QA2
    : env === 'beta'
    ? CONFIG_STAGE
    : env === 'dsp_sideb'
    ? CONFIG_PROD_SIDEB
    : env === 'prod'
    ? CONFIG_PROD
    : CONFIG_LOCAL;

export function configPreference() {
  const appConfigService = new AppConfigService();

  appConfigService.loadConfig(config_file);

  return appConfigService;
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const postLogoutURL = `${config_file.msalConfig.auth.authority}/oauth2/v2.0/authorize?client_id=${config_file.msalConfig.auth.clientId}&scope=${config_file.msalAngularConfig.consentScopes}&redirect_uri=${config_file.msalConfig.auth.redirectUri}&client-request-id=e7609e08-14cb-42be-96e1-449800c50a42&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.39.0&client_info=1&code_challenge=wbJbSx-grS4evPREffDRcP1ubZDU3ssk6Gy9oc1jXeE&code_challenge_method=S256&nonce=4cce60c8-b485-41ed-aa18-e87b87e4e10e&state=eyJpZCI6IjJhZjg0MThjLTc0M2ItNGI5Zi05MDc4LTUyYmQ1NmQyOGVkZiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D&setPingLegacyClient_id=dealerserviceportal_ac_client&application=/OneCat
`;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config_file.msalConfig.auth.clientId,
      redirectUri: environment.redirectURL.hostBaseUrl,
      postLogoutRedirectUri: environment.redirectURL.hostBaseUrl,
      authority: config_file.msalConfig.auth.authority,
      navigateToLoginRequestUrl: true,
      knownAuthorities: ['signin.cat.com'],

      //https://signin.cat.com/4f0f19d0-f44c-4a03-b8cb-ab327bd2b12b/b2c_1a_p2_v1_signin_nonprod/oauth2/v2.0/authorize?
      //client_id=37290157-3468-487e-bd23-201422d067c5
      //&scope=openid%2037290157-3468-487e-bd23-201422d067c5%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2F
      //&client-request-id=e7609e08-14cb-42be-96e1-449800c50a42&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.39.0&client_info=1&code_challenge=wbJbSx-grS4evPREffDRcP1ubZDU3ssk6Gy9oc1jXeE&code_challenge_method=S256&nonce=4cce60c8-b485-41ed-aa18-e87b87e4e10e&state=eyJpZCI6IjJhZjg0MThjLTc0M2ItNGI5Zi05MDc4LTUyYmQ1NmQyOGVkZiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D&setPingLegacyClient_id=dealerserviceportal_ac_client&application=/OneCat

      //https://signin.cat.com/4f0f19d0-f44c-4a03-b8cb-ab327bd2b12b/b2c_1a_p2_v1_signin_nonprod/oauth2/v2.0/authorize?client_id=37290157-3468-487e-bd23-201422d067c5&scope=openid%2037290157-3468-487e-bd23-201422d067c5%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2F&client-request-id=dfab811b-e110-48b3-84b6-38e0dd089a75&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.39.0&client_info=1&code_challenge=NYvwdzppgLHNfhEBkOfdEe2JyLaqeWnaJ-MgkjNC3Zg&code_challenge_method=S256&nonce=91295b01-136e-454b-b0d1-fad32b61b3dd&state=eyJpZCI6IjRlZGIzYjQ2LWUxOTMtNGViYy05ZjA1LTJiMDc1YzM1ZTBjMCIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D&setPingLegacyClient_id=dealerserviceportal_ac_client&application=/OneCat
      // clientId: '37290157-3468-487e-bd23-201422d067c5',
      // redirectUri: environment.redirectURL.hostBaseUrl,
      // postLogoutRedirectUri: environment.redirectURL.hostBaseUrl,
      // authority: 'https://signin.cat.com/tfp/4f0f19d0-f44c-4a03-b8cb-ab327bd2b12b/B2C_1A_P2_V1_SignIn_NonProd/',
      // navigateToLoginRequestUrl: true,
      // knownAuthorities: ['signin.cat.com'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
  });
}
