<div class="cc-drawer">
    <div class="cc-drawer__header" [ngClass]="{
        'cc-drawer__withActions': headerContent === 'withActions'
      }">
        <ng-container *ngIf="headerContent === 'withActions'" [ngTemplateOutlet]="oneLineHeader"></ng-container>
    </div>
    <div class="cc-drawer__content filters-drawer">
        <dsp-next-gen-ui-accordion-list 
        [filterColumns]="columnNames" 
        [filterData]="filterData"
        ></dsp-next-gen-ui-accordion-list>
    </div>
    <div class="cc-drawer__footer">
        <button cc-btn-secondary cc-btn-medium [setTheme]="true" class="applyChangesBtn" (click)="applyFilters()">
            Apply Changes
        </button>
    </div>
</div>
<ng-template #oneLineHeader>
    <cc-icon class="drilldown-header__back" (click)="closeModal()">back-arrow</cc-icon>
    <span class="cc-drawer__ellipsis filters-title"> {{title}} </span>
    <span class="filters-clear" (click)="clearFilters()">Clear All</span>
    <div class="cc-drawer__headerAction">
        <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeAllModal()">
            <cc-icon fontSet="icomoon">cross</cc-icon>
        </div>
    </div>
</ng-template>