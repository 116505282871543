import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelfServeService {
  disableLoaderInterceptor = new Subject();
  enableLoaderInterceptor = new Subject();
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getVLRequestDetails(make: string, serialNumber: string) {
    return this.http.get(
      this.rootURL +
        '/telematicsRequests/getTelematicsSubscriptionRequest?make=' +
        make +
        '&serialNumber=' +
        serialNumber
    );
  }

  approveVLRequest() {}

  declineRequest(payload: any) {
    return this.http.post(this.rootURL + '/telematicsRequests/saveDealerDeclineRequest', payload);
  }

  disableInterceptor() {
    this.disableLoaderInterceptor.next(true);
  }

  enableInterceptor() {
    this.enableLoaderInterceptor.next(true);
  }
}
