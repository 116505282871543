<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis filters-title"> {{ title }} </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>

  <div class="" [ngClass]="seemoreOverlayUpdate ? 'asset-container-overlay' : 'asset-container'">
    <div
      [ngClass]="seemoreOverlayUpdate ? 'header-content-overlay asset-info-overlay' : 'header-content asset-info'"
      class=""
      [cc-tooltip]="getColumnToolTipData()"
      [tooltipPosition]="'top'"
      [isEnableTooltioOnEllipsis]="true"
    >
      <span *ngIf="!seemoreOverlayUpdate">{{ data.serialNumber }}</span
      ><span *ngIf="!seemoreOverlayUpdate">&nbsp;{{ parseAssetNameData(data) }}</span>
      <span *ngIf="seemoreOverlayUpdate">{{ getAssetSerialNumber(data) }}</span>
      <div *ngIf="seemoreOverlayUpdate" class="asset-make">{{ parseAssetNameData(data) }}</div>
    </div>
    <div class="" [ngClass]="seemoreOverlayUpdate ? 'status-content-overlay' : 'status-content'">
      <cc-icon [ngClass]="data.reportingState" style="font-size: 15px; padding-right: 15px">{{
        data.reportingState | lowercase
      }}</cc-icon>
      <span class="status-text">{{ data.reportingState }}</span>
    </div>
  </div>

  <div class="cc-drawer__content filters-drawer">
    <div class="cc-drawer">
      <div class="cc-drawer__content dealer-plan-block">
        <cc-list>
          <cc-list-item *ngFor="let product of planList; let i = index" class="ownership-list">
            <div class="list-count">
              <span class="list-number">{{ i + 1 }}</span>
            </div>
            <div class="ownership-details">
              <div
                class="name text-truncate"
                [cc-tooltip]="product.appName"
                [isEnableTooltioOnEllipsis]="true"
                [tooltipPosition]="'top'"
              >
                <strong> Product Name</strong>: {{ product.appName }}
              </div>
              <div
                class="name text-truncate"
                [cc-tooltip]="
                  isTimeZoneSelectionEnabled
                    ? dateTimeValue(product.dateTime)
                    : assetService.convertTimeStampToData(product.dateTime, true)
                "
                [isEnableTooltioOnEllipsis]="true"
                [tooltipPosition]="'top'"
              >
                <strong>Date & Time({{ isTimeZoneSelectionEnabled ? localTimeZone : 'UTC' }}):</strong>
                {{
                  isTimeZoneSelectionEnabled
                    ? dateTimeValue(product.dateTime)
                    : assetService.convertTimeStampToData(product.dateTime, true)
                }}
              </div>
            </div>
          </cc-list-item>
        </cc-list>
      </div>
    </div>
  </div>
</div>
