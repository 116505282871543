import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NeedsReviewService } from '../../services/needs-review.service';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { isEmpty } from 'lodash-es';
export interface NeedsReviewChipsData {
  value: string;
  label: string;
  isChecked: boolean;
}

@Component({
  selector: 'dsp-next-gen-ui-needs-review-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsListComponent implements OnInit {
  selected: any;
  _dataSource: any;
  queueReasonSubscription: any;
  selectedQueueReason: any;
  @Input() reviewCount: any;
  @Input() set dataSource(data: NeedsReviewChipsData[]) {
    this._dataSource = data;
  }

  // Emits an array of the VALUE property from the provided NeedsReviewChipsData Input
  @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private needsReviewService: NeedsReviewService,
    private sharedService: SharedService
  ) {}
  ngOnInit() {
    let status = this.needsReviewService.getStatus();
    if (status && status === 'Dismissed') {
      this.selected = ['default', 'active'];
    } else {
      this.selected = ['active', 'default'];
    }
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.dealerQueueReason;
    });
  }
  /**
   *
   * @description Called only in the HTML template when a chip selection changes
   */
  changeSelected(event: any, value: string, i: number) {
    this.selected?.forEach((item: any, index: number) => {
      this.selected[index] = i === index && event ? 'active' : 'default';
    });
    this.selectionChanged.emit([value]);
  }

  trackByValue(index: number, chip: NeedsReviewChipsData) {
    return chip.value;
  }
}
