import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Renderer2,
  HostListener,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
  DrawerService,
  MessageBar,
  MessageBarConfig,
  TableConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';

import { BillingService } from '../../services/billing.service';
import { COUNTOVERLAYDATA, OVERLAYDATA } from '../../billing-constant';
import { BillingPayload } from '../../billing-interface';
import * as dspConstants from 'apps/dsp-ui/src/app/shared/dspConstants';
import { BillingInquiryComponent } from '../billing-inquiry/billing-inquiry.component';
import { debounce } from 'lodash-es';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-billing-main',
  templateUrl: './billing-main.component.html',
  styleUrls: ['./billing-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BillingMainComponent {
  @ViewChild('billingSearchBox') billingSearchBox!: ElementRef;
  @ViewChild('myTable1') tableRef: any;
  isOpenConfigAccess = false;
  isTheme = '2.0';
  billingForm: FormGroup = new FormGroup({});
  defaultHeight = 160;
  showLoader = false;
  searchValue: FormControl = new FormControl('', {
    validators: [Validators.minLength(3)],
    updateOn: 'change', //blur
  });
  columnConfig: any = [
    {
      name: 'invoiceNumber',
      title: 'Invoice',
      priority: 1,
      width: 80,
      isFrozen: true,
      isAlwaysMandatory: true,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: OVERLAYDATA,
      formControl: 'span',
    },
    {
      name: 'invoicePeriod',
      title: 'Invoice Period',
      priority: 2,
      width: 60,
      formControl: 'span',
      isAlwaysMandatory: false,
      isVisible: true,
      isFrozen: false,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: COUNTOVERLAYDATA,
    },
    {
      name: 'invoiceAmount',
      title: 'Invoice Total',
      priority: 3,
      width: 60,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: COUNTOVERLAYDATA,
      formControl: 'span',
    },
    {
      name: 'outstandingAmount',
      title: 'Outstanding Balance',
      priority: 4,
      width: 80,
      isFrozen: false,
      isAlwaysMandatory: false,
      isVisible: true,
      sortingEnable: true,
      ascendingSort: false,
      sortWithOverlay: true,
      overlayData: COUNTOVERLAYDATA,
      formControl: 'span',
    },
  ];

  cwsIdControl: any;
  lastNameControl: any;
  disableSearchBtn = true;
  disableClearAllBtn = true;
  rowHeight = 100;
  billingTableConfig: TableConfig = {
    height: window.innerHeight - this.defaultHeight,
    stickyHeader: true,
    expandable: true,
    footer: true,
    isPaginationEnable: false,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [7],
      advanced: false,
      activePage: 1,
      defaultPageSize: 7,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
    },

    isSortEnable: true,
    columnConfig: [],
    nestedcolumnConfig: [],
    dragDisabled: true,
    isTheme: '2.0',
    expandableHeaderTitle: 'ASSET NAME',
  };
  systemError = dspConstants.Common.SYSTEM_ERROR;
  billingTableData: any = [];
  isDataLoading = false;
  enablebillingTable = true;
  billingAPIPayload: any = {
    sortOrder: 'DESC',
    sortColumn: 'invoiceNumber',
  };
  mobileHeader = 'Search User';
  cachedData: any;
  nextCursor: any;
  pageDetails: any;
  emailControl: any;
  showAdvancedSearch = false;
  configureAccessDrawerRef: any;
  searchHelpText = '';
  downloadedFileName = '';
  downloadOptionType: any;
  fileType: any;
  listItems = [
    {
      label: 'Download PDF',
      selected: true,
    },
    {
      label: 'Download Excel',
    },
  ];
  dealer: any;
  showDetailedInvoice: any = false;
  invoiceDetails: any;
  rowData: any;
  prevSearchMain: any;
  searchString: any = '';
  modalRef: any;
  selectedData: any;
  userActiveFeatures: any;
  enableSearch = false;
  searchDataLoaded = false;
  showBillingParent = false;
  invoiceParentDealerCode = '';
  isWhitespaceString = false;
  dspStoreData!: any;
  isRemoveOutstandingBalance!: any;
  isNumberConventionEnabled: any;
  userDealerCode: any;
  constructor(
    private dspUtilsService: DspUtilsCommonService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private billingService: BillingService,
    private messageBar: MessageBar,
    private drawerService: DrawerService,
    public modal: CcModal,
    public renderer: Renderer2,
    public router: Router,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService
  ) {}
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsService.isAllowedNavigation();
  }
  ngOnInit() {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('user').subscribe((user) => {
      this.userDealerCode = user.user?.dealerCode || '';
    });
    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP Billing Page',
      });
    }
    this.store.select('dsp').subscribe((dsp: { userActiveFeatures: any }) => {
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];

      if (this.userActiveFeatures?.indexOf('disablePdfReport') > -1) {
        this.listItems = this.listItems.filter((item) => item.label !== 'Download PDF');
      }
    });
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.isRemoveOutstandingBalance = this.dspStoreData?.featureFlagInfo?.isRemoveOutstandingBalance
        ? this.dspStoreData?.featureFlagInfo?.isRemoveOutstandingBalance
        : false;
      this.isNumberConventionEnabled = this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        : false;
    });
    if (this.isRemoveOutstandingBalance) {
      this.columnConfig = this.dspUtilsService.removeColumnFromConfig(this.columnConfig, 'outstandingAmount');
    }
    this.getBillingDetails();
    this.cdr.detectChanges();
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }
  openDetailedInvoice(value: any) {
    this.showDetailedInvoice = true;
    this.invoiceDetails = value;
    sessionStorage.setItem('inquiryNumber', this.invoiceDetails.invoiceNumber);
  }
  clearAll() {
    this.billingForm.reset();
    this.disableSearchBtn = true;
    this.disableClearAllBtn = true;
    this.enablebillingTable = false;
    this.cachedData = [];
    this.nextCursor = '';
  }

  onCickEvent(event: any) {
    if (event.target.value === '') {
      this.searchHelpText = 'Search Invoice # (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }

  onBlurEvent(event: any) {
    if (event.type === 'blur') this.searchHelpText = '';
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.value.length || 0;
      this.billingSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  onSearchChange(event: any) {
    this.isWhitespaceString = false;
    const regex = new RegExp('^\\s*$');
    this.isWhitespaceString = regex.test(event.value);
    if (event.value.trim().length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value !== '' && this.searchHelpText !== '') {
      this.searchHelpText = '';
    }
    if (event.value.length > 2 && this.prevSearchMain != this.searchValue?.value) {
      // sessionStorage.removeItem('reviewFilters');
      // sessionStorage.removeItem('reviewFilterData');
      // sessionStorage.removeItem('reviewDateFilters');
      // this.needsReviewService.selectedQueueReason = null;
      // this.savedFilters = {};
      this.searchValue.setValue(event.value);
    } else if (event.value === '') {
      if (!this.isDataLoading) {
        this.searchHelpText = 'Find Invoice # (Min 3 Char)';
      } else {
        this.searchHelpText = '';
      }
      this.searchValue.setValue('');
      if (this.searchDataLoaded) {
        this.getBillingDetails();
        this.searchDataLoaded = false;
      }
      this.searchString = '';
    }

    this.prevSearchMain = this.searchValue?.value;
  }

  getbillingSearch = debounce(() => {
    if (this.searchValue?.value.length > 2) {
      this.isDataLoading = true;
    }
    this.getBillingDetails();
    // this.searchChange.next(this.searchValue.value);
  }, 1000);
  adjustTableHeight(event: any) {
    if ((event.eventType === 'SCROLL_EVENT' || event.eventType === 'WINDOW_RESIZE') && this.tableRef) {
      const tableRef = this.tableRef?.table?.nativeElement;

      if (this.billingTableData.length && this.renderer && tableRef) {
        const adjustHeight = window.innerHeight - 185 + 'px';
        this.renderer.setStyle(tableRef, 'height', adjustHeight);
        this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
      }
    }
  }
  tableEvents(event: any) {
    if (event.eventType === 'PAGE_CHANGE_EVENT' && this.isPageChanged(event)) {
      this.pageDetails = event.range;
      let offset = 0;
      if (this.nextCursor) {
        const url = new URLSearchParams(this.nextCursor);
        offset = Number(url.get('offset'));

        if (event.range?.pageNumber === event.range?.numberOfPages && this.billingAPIPayload.offset !== offset) {
          this.billingAPIPayload.offset = offset;
          this.getBillingDetails();
        }
      }
      // } else {
      //   this.enablebillingTable = false;
      //   setTimeout(() => {
      //     this.enablebillingTable = true;
      //   });
      //   this.cdr.detectChanges();
      // }
    } else if (event.eventType === 'SORTING' && event?.event !== 'filterby') {
      const data = JSON.parse(JSON.stringify(this.billingTableData));
      if (event?.data?.name == 'invoicePeriod') {
        this.billingTableData = data.sort(this.compareValues('invoiceNumber', event.event));
      } else if (event?.data?.name == 'invoiceAmount') {
        data.forEach((element: any) => {
          element.parsedInvAmount = parseFloat(element.invoiceAmount);
        });
        this.billingTableData = data.sort(this.compareValues('parsedInvAmount', event.event));
      } else if (event?.data?.name == 'outstandingAmount') {
        data.forEach((element: any) => {
          element.parsedOutstandingAmount = element.outstandingAmount ? parseFloat(element.outstandingAmount) : null;
        });
        this.billingTableData = data.sort(this.compareValues('parsedOutstandingAmount', event.event));
        if (event.event == 'DESC') {
          const toIndex = this.billingTableData?.length;
          const index = this.billingTableData.findIndex(
            (x: { outstandingAmount: any }) => x.outstandingAmount === false
          );
          if (index != -1) {
            const newVal = this.billingTableData.splice(index, 1);
            this.billingTableData.unshift(newVal[0]);
          }
        }
      } else {
        this.billingTableData = data.sort(this.compareValues(event.data.name, event.event));
      }
    }
    if (!event.isExpanded) {
      this.isOpenConfigAccess = false;
    }
  }
  compareValues(key: any, order = 'ASC') {
    return function (a: any, b: any) {
      if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
        // property doesn't exist on either object
        return 0;
      }
      if (a[key] === null && order == 'ASC') {
        return 1;
      }
      if (b[key] === null && order == 'ASC') {
        return -1;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'DESC' ? comparison * -1 : comparison;
    };
  }
  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.billingAPIPayload.offset ||
      pageData?.range?.pageSize !== this.billingAPIPayload.resultLimit
    );
  }
  dataLoaded() {
    this.searchDataLoaded = true;
  }

  getBillingDetails(isSearch = false) {
    const currentMonth = moment()?.format('MMMM').toLocaleLowerCase();
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    const fromDate = currentMonth + '-' + currentDate.getFullYear();
    const toDate = currentMonth + '-' + new Date().getFullYear();
    const input = {
      parentRequestData: {
        dealerCode: this.dealer?.dealerCode,
        fromDate: fromDate,
        toDate: toDate,
      },
      childRequestData: {
        pageLimit: 100,
        pageOffset: 1,
        sortColumn: this.billingAPIPayload.sortColumn,
        sortOrder: this.billingAPIPayload.sortOrder,
        totalSessions: 0,
        searchText: this.searchValue.value.trim(),
      },
    };

    this.enablebillingTable = true;
    this.isDataLoading = true;
    this.billingTableData = [];
    if (this.isRemoveOutstandingBalance) {
      this.billingService.getBillinSummary(input).subscribe(
        (invoiceSummary: any) => {
          this.searchString = this.searchValue.value.trim();
          this.isDataLoading = false;
          if (invoiceSummary.parentDealerCode) {
            this.showBillingParent = true;
            this.invoiceParentDealerCode = invoiceSummary.parentDealerCode;
          }
          this.billingTableData = invoiceSummary.results;
          this.billingTableData = this.billingTableData.map((userItem: any) => {
            return {
              ...userItem,
              isNestedTable: true,
              isSelfFoldable: true,
              isExpandable: true,
              detailRow: isSearch,
            };
          });
          this.emptyColumnDataCheck();
          // this.enablebillingTable = false;
          // setTimeout(() => {
          //   this.enablebillingTable = true;
          // });
          if (this.billingTableData.length === 0) {
            const tableRef = this.tableRef?.table?.nativeElement;
            if (this.renderer && tableRef) {
              this.renderer.setStyle(tableRef, 'height', '130px');
            }
          } else {
            this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          const message = 'There was an error processing your request.';
          this.showToastMessage(message, 'error');
          this.isDataLoading = false;
        }
      );
    } else {
      forkJoin([
        this.billingService.getBillinSummary(input),
        this.billingService.getOutstandingBalance(input),
      ]).subscribe(
        ([invoiceSummary, outStandingBal]: any) => {
          this.searchString = this.searchValue.value.trim();
          this.isDataLoading = false;
          if (invoiceSummary.parentDealerCode) {
            this.showBillingParent = true;
            this.invoiceParentDealerCode = invoiceSummary.parentDealerCode;
          }
          this.addOutstandingBalance(invoiceSummary.results, outStandingBal.results);
          this.billingTableData = this.billingTableData.map((userItem: any) => {
            return {
              ...userItem,
              isNestedTable: true,
              isSelfFoldable: true,
              isExpandable: true,
              detailRow: isSearch,
            };
          });
          this.emptyColumnDataCheck();
          // this.enablebillingTable = false;
          // setTimeout(() => {
          //   this.enablebillingTable = true;
          // });
          if (this.billingTableData.length === 0) {
            const tableRef = this.tableRef?.table?.nativeElement;
            if (this.renderer && tableRef) {
              this.renderer.setStyle(tableRef, 'height', '130px');
            }
          } else {
            this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
          }
          this.cdr.detectChanges();
        },
        (err: any) => {
          const message = 'There was an error processing your request.';
          this.showToastMessage(message, 'error');
          this.isDataLoading = false;
        }
      );
    }
  }
  addOutstandingBalance(arr1: any[], arr2: any[]) {
    let arr2Map = arr2.reduce((acc, curr) => {
      acc[curr.invoiceNumber] = curr;
      return acc;
    }, {});
    this.billingTableData = arr1.map((d) => {
      d.outstandingAmount = arr2Map[d.invoiceNumber]
        ? arr2Map[d.invoiceNumber].outstandingAmount + ' ' + arr2Map[d.invoiceNumber].currency
        : false;
      return d;
    });
  }

  showToastMessage(message: string, status: string) {
    if (status === 'error') {
      this.isDataLoading = false;
    }
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  emptyColumnDataCheck() {
    this.billingTableData = this.billingTableData.map(function (user: any) {
      let attributes = Object.keys(user);
      let formattedAttr = ['firstName', 'lastName', 'middleName', 'emailAddress', 'organization'];
      attributes.forEach((item) => {
        if (formattedAttr.indexOf(item) > -1) {
          user[item] = user[item] ? user[item] : '-';
        }
      });
      return user;
    });
  }

  onListItemClick(value: any, rowData: any) {
    this.rowData = rowData;
    this.billingTableData.map((item: any) => {
      if (this.rowData.invoiceNumber === item.invoiceNumber) {
        item['isSelected'] = true;
      }
    });
    this.cdr.detectChanges();
    const item = value.split(' ');
    this.fileType = item[1];
    this.uploadFile(this.fileType, rowData);
  }

  downloadOptionClick(event: any) {
    this.downloadOptionType = event.value;
  }

  uploadFile(type: string, rowData: any) {
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.showLoader = true;
    switch (type) {
      case 'PDF':
        this.downloadedFileName = '.pdf';

        this.exportPDFData(rowData);
        break;
      case 'Excel':
        this.downloadedFileName = '.xlsx';

        this.exportExcelData(rowData);
        break;
      default:
        break;
    }
  }

  billingInquiryForm() {
    this.modalRef = this.modal.openModal(BillingInquiryComponent, {
      width: '466px',
      type: 'semi-modal',

      isAutoHeightModalContent: true,
      disableBackdropClick: true,
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
    });
  }

  exportPDFData(row: any) {
    const input = {
      invoiceNumber: this.rowData.invoiceNumber,
      dealerCode: this.dealer?.dealerCode,
    };
    this.billingService.exportPDFInvoiceDetails(input).subscribe(
      (downloadResp: any) => {
        this.createDefaultAnchorElement(downloadResp, 'pdf');

        this.showLoader = false;
        this.billingTableData.map((item: any) => {
          if (row.invoiceNumber === item.invoiceNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
        // this.needsReviewSharedService.showLoader = false;
        // this.needsReviewSharedService.downloadCompleted.next(false);
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.billingTableData.map((item: any) => {
          if (row.invoiceNumber === item.invoiceNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
        // this.needsReviewSharedService.showLoader = false;
        // this.needsReviewSharedService.downloadCompleted.next(false);
      }
    );
  }
  exportExcelData(row: any) {
    const input = {
      invoiceNumber: this.rowData.invoiceNumber,
      invoiceDealerCode: this.dealer?.dealerCode,
      filterData: { customer: 'All', make: 'All', application: 'All', model: 'All' },
      searchText: '',
      pageOffset: 1,
      pageLimit: 100,
      sortColumn: 'customer',
      sortOrder: 'ASC',
      operation: 'exportExcel',
    };
    this.billingService.exportExcelInvoiceDetails(input).subscribe(
      (downloadResp: any) => {
        this.createDefaultAnchorElement(downloadResp, 'excel');

        this.showLoader = false;
        this.billingTableData.map((item: any) => {
          if (row.invoiceNumber === item.invoiceNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      },
      () => {
        const message = 'Download failed. Please try again';
        this.showToastMessage(message, 'error');
        this.showLoader = false;
        this.billingTableData.map((item: any) => {
          if (row.invoiceNumber === item.invoiceNumber) {
            item['isSelected'] = false;
          }
        });
        this.cdr.detectChanges();
      }
    );
  }

  createDefaultAnchorElement(data: any, format: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    var base64str, fileName, type;
    if (format === 'pdf') {
      base64str = data.pdfContent;
      fileName = data.filename;
      type = 'application/pdf';
    } else if (format === 'excel') {
      base64str = data.entity;
      fileName = data.metadata.fileName[0];
      type = data.metadata['content-type'][0];
    }

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    var blob = new Blob([view], { type: type });
    const csvUrl = URL.createObjectURL(blob);
    a.href = csvUrl;
    a.download = fileName + this.downloadedFileName;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, false);
    }
    return number;
  }
}
