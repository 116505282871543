<div class="cards asset-ownership-card">
  <cc-card>
    <cc-card-content>
      <div>
        <div class="header">
          <div class="header-text">
            <span class="header-text-alignment">
              ASSET OWNERSHIP
              <span *ngIf="enableSyncOwnership()" cc-tooltip="Sync Ownership" (click)="initiateSync()">
                <svg xmlns="http://www.w3.org/2000/svg" width="15px" fill="#0078d6" viewBox="0 0 512 512">
                  <path
                    d="M440.7 12.6l4 82.8A247.2 247.2 0 0 0 255.8 8C134.7 8 33.9 94.9 12.3 209.8A12 12 0 0 0 24.1 224h49.1a12 12 0 0 0 11.7-9.3 175.9 175.9 0 0 1 317-56.9l-101.5-4.9a12 12 0 0 0 -12.6 12v47.4a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0 -12-12h-47.4a12 12 0 0 0 -12 12.6zM255.8 432a175.6 175.6 0 0 1 -146-77.8l101.8 4.9a12 12 0 0 0 12.6-12v-47.4a12 12 0 0 0 -12-12H12a12 12 0 0 0 -12 12V500a12 12 0 0 0 12 12h47.4a12 12 0 0 0 12-12.6l-4.2-82.6A247.2 247.2 0 0 0 255.8 504c121.1 0 221.9-86.9 243.6-201.8a12 12 0 0 0 -11.8-14.2h-49.1a12 12 0 0 0 -11.7 9.3A175.9 175.9 0 0 1 255.8 432z"
                  />
                </svg>
              </span>
            </span>
            <span
              class="ed-alignment"
              *ngIf="
                userActiveFeatures &&
                userActiveFeatures?.toString()?.indexOf('Subscription Management') > -1 &&
                (userActiveFeatures?.toString()?.indexOf('Read Only') === -1 ||
                  userActiveFeatures?.toString()?.indexOf('View Only') === -1 ||
                  userActiveFeatures?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') === -1)
              "
            >
              <a
                cc-btn-tertiary
                cc-btn-large
                *ngIf="!replaceEDUrl"
                [setTheme]="true"
                href="{{ edUrl }}"
                target="_blank"
              >
                Equipment Data
              </a>
              <a cc-btn-tertiary cc-btn-large *ngIf="replaceEDUrl" [setTheme]="true" href="{{ edUrl }}" target="_blank">
                Update Ownership
              </a>
            </span>
          </div>
        </div>
        <div class="asset-ownership-details-container">
          <div [ngClass]="showScroll(assetOwnerData)" *ngIf="!isOwnershipEmpty()">
            <div class="detail-tiles">
              <span>Dealer:</span>
              <span class="detail-value">{{ dealerData || '-' }}</span>
            </div>
            <div class="cust-details">
              <span class="cust-details-body">Customer Details</span>
            </div>
            <div class="cust-desc">
              <div *ngIf="!(assetOwnerData === '' || assetOwnerData === 'undefined' || assetOwnerData === null)">
                <div *ngFor="let assetOwnerObj of assetOwnerData">
                  <div class="detail-tiles">
                    <span>Customer:</span>
                    <span class="detail-value-datStatus" style="margin-left: 5px">{{
                      getTrimCustomerData(assetOwnerObj)
                    }}</span>
                  </div>
                  <div class="detail-tiles" *ngIf="!showTruncatedText">
                    <span>Dealer Customer:</span>
                    <span
                      class="detail-value"
                      cc-tooltip="{{
                        assetOwnerObj.dealerCustName?.length + assetOwnerObj.dealerCustNo?.length > 50
                          ? assetOwnerObj.dealerCustName + ' - ' + assetOwnerObj.dealerCustNo
                          : null
                      }}"
                      [tooltipPosition]="'top'"
                      tooltipWidth="300px"
                      >{{ getTrimData(assetOwnerObj) }}</span
                    >
                  </div>
                  <div
                    class="detail-tiles"
                    *ngIf="showTruncatedText"
                    (copy)="copyEvent($event, assetOwnerObj.dealerCustName, assetOwnerObj.dealerCustNo)"
                  >
                    <span>Dealer Customer:</span>
                    <span
                      class="detail-value"
                      cc-tooltip="{{
                        assetOwnerObj.dealerCustName?.length + assetOwnerObj.dealerCustNo?.length > 50
                          ? assetOwnerObj.dealerCustName + ' - ' + assetOwnerObj.dealerCustNo
                          : null
                      }}"
                      [tooltipPosition]="'top'"
                      tooltipWidth="300px"
                      >{{ getTrimData(assetOwnerObj) }}</span
                    >
                  </div>
                  <div class="detail-tiles">
                    <span>Ownership Type:</span>
                    <span class="detail-value">{{ assetOwnerObj.ownershipType | convertOwnership }}</span>
                  </div>
                  <div class="detail-tiles" *ngIf="assetOwnerObj.dealerCode === dealer?.dealerCode">
                    <span>Digital Authorization:</span>
                    <span class="icon-size">
                      <cc-icon
                        *ngIf="assetOwnerObj?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== ''"
                        fontSet="icomoon"
                        [tooltipPosition]="'bottom'"
                        [interactive]="true"
                        [appClass]="'-dat-tool-tip'"
                        [ngClass]="checkDATStatus(assetOwnerObj?.digitalAuthorizationDetails?.CATDigitalAuthStatus)"
                        cc-tooltip
                        [template]="tooltipTemplateForDAT"
                        >{{
                          updateDATBadge(assetOwnerObj?.digitalAuthorizationDetails?.CATDigitalAuthStatus || '')
                        }}</cc-icon
                      >
                      <img
                        *ngif="assetOwnerObj?.digitalAuthorizationDetails?.CATDigitalAuthStatus === ''"
                        src="..\assets\images\danger.svg"
                      />
                    </span>
                    <span class="detail-value-datStatus capitalize">{{
                      updateDatStatus(assetOwnerObj?.digitalAuthorizationDetails?.CATDigitalAuthStatus)
                    }}</span>
                    <span> </span>
                  </div>
                  <div class="detail-tiles" *ngIf="assetOwnerObj?.dealerCode !== dealer?.dealerCode">
                    <span>Digital Authorization:</span>
                    <span class="detail-value">-</span>
                  </div>
                  <div *ngIf="isBorderShow" class="cust-list-border"></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isOwnershipEmpty()">
            <div class="no-owner-image">
              <img src="..\assets\images\no-ownership.svg" style="height: 142px; width: 212px" />
            </div>
            <div class="addownertext">
              <div class="addownertext">Add Owner</div>
              <div class="addcustomertext" *ngIf="!replaceEDUrl">Please add customer in Equipment Data</div>
              <div class="addcustomertext" *ngIf="replaceEDUrl">Please add ownership information</div>
            </div>
          </div>
        </div>
      </div>
    </cc-card-content>
  </cc-card>
</div>
<ng-template #tooltipTemplateForDAT>
  <div class="dat-tooltip-header">
    Digital Authorization <cc-icon class="close" fontSet="icomoon" (click)="closeDATTooltip()">cross</cc-icon>
  </div>
  <div class="dat-tooltip-body">
    <span *ngIf="datStatus === 'AGREE'"
      >Customer authorization on file - No further action required. Click to review in
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'DECLINE'"
      >Customer authorization declined - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'UNAVAILABLE'"
      >Customer authorization unavailable - Click to review and take action through the
      <a href="{{ datUrl }}" target="_blank" style="color: #2595ff; font-weight: 400">Digital Authorization Tool</a>
    </span>
    <span *ngIf="datStatus === 'SYSTEM ERROR'"
      >Unable to get authorization details - Please retry later or contact Cat Digital support
    </span>
  </div>
</ng-template>
