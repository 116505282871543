import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CCTooltip, MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { isEmpty } from 'lodash-es';
import { dealerType } from '../../../../models/shared.interface';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../../shared/shared';
import * as dspConstants from '../../../../shared/dspConstants';
import { FilterAssetsV2Payload } from '../../asset-drawer/asset-drawer-interface';
import { AssetService } from '../../services/asset.service';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';

interface Ownership {
  ucid: string;
  ucidName: string;
  dcn: string;
  dcnName: string;
  ownershipType: string;
}

@Component({
  selector: 'dsp-next-gen-ui-ownership-details',
  templateUrl: './ownership-details.component.html',
  styleUrls: ['./ownership-details.component.scss'],
})
export class OwnershipDetailsComponent implements OnInit {
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() ownershipData: any = {};
  data: Array<any> = [];
  dealer!: dealerType;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  datStatus = '';
  datUrl = '';
  dspStoreData: any;
  constructor(
    public assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar,
    public assetService: AssetService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.datUrl = environment.redirectURL.datUrl;
    this.dealer = fetchLoggedInDealerDetails();
    if (this.ownershipData?.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH) {
      this.getFilteredAssets();
    } else {
      if (this.ownershipData.ownerData) {
        this.data = this.ownershipData.ownerData;
        this.syncOwnershipDatStatus();
      } else {
        this.fetchAssetOwnership();
      }
    }
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }

  syncOwnershipDatStatus() {
    if (this.ownershipData.digitalAuthorization === 'Available') {
      this.datStatus = 'AGREE';
    } else if (this.ownershipData.digitalAuthorization === 'Declined') {
      this.datStatus = 'DECLINE';
    } else if (this.ownershipData.digitalAuthorization === 'Not Available') {
      this.datStatus = 'UNAVAILABLE';
    } else {
      this.datStatus = 'SYSTEM ERROR';
    }
    if (
      this.datStatus !== 'SYSTEM ERROR' &&
      this.ownershipData?.ownerData[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== 'UNREACHABLE'
    ) {
      if (this.datStatus !== this.ownershipData?.ownerData[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus) {
        const syncDatOwnershipPayload = {
          assetId: this.ownershipData.assetId,
          dealerCode: this.dealer?.dealerCode,
        };
        this.assetService.syncOwnershipDATStatus(syncDatOwnershipPayload).subscribe(
          (res: any) => {},
          (error: any) => {
            let responseText = error?.error?.text;
            if (responseText === 'Success') {
              this.assetDrawerService.isPlanDetailsDrawerClosed.next(true);
            }
          }
        );
      }
    }
  }

  getFilteredAssets() {
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = {
      dealerCode: this.dealer.dealerCode,
      flag: true,
      isOwnershipInfoReq: true,
      make: this.ownershipData?.make || '',
      serialNumber: this.ownershipData?.serialNumber || '',
    };
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          const assetdata = result;
          if (this.ownershipData?.searchType == dspConstants.Worklist.NO_DEVICE_SEARCH) {
            this.setOwnershipDataForNoDevice(assetdata);
          }
        }
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
        this.data = [];
      },
    });
  }

  getTrimData(assetOwnerObj: any) {
    if (assetOwnerObj) {
      const trimmedDealerName = assetOwnerObj.dealerCustName?.trim();
      const trimmedDealerid = assetOwnerObj.dealerCustNo?.trim();
      if (!isEmpty(trimmedDealerName) && !isEmpty(trimmedDealerid)) {
        return `${trimmedDealerName} - ${trimmedDealerid}`;
      } else if (!isEmpty(trimmedDealerName)) {
        return trimmedDealerName;
      } else if (!isEmpty(trimmedDealerid)) {
        return trimmedDealerid;
      }
    }
    return '-';
  }

  getTrimCustomerData(assetOwnerObj: any) {
    if (assetOwnerObj) {
      const trimmedUcidName = assetOwnerObj.ucidName?.trim();
      const trimmedUcid = assetOwnerObj.ucid?.trim();
      if (!isEmpty(trimmedUcidName) && !isEmpty(trimmedUcid)) {
        return `${trimmedUcidName} - ${trimmedUcid}`;
      } else if (!isEmpty(trimmedUcidName)) {
        return trimmedUcidName;
      } else if (!isEmpty(trimmedUcid)) {
        return trimmedUcid;
      }
    }
    return '-';
  }
  setOwnershipDataForNoDevice(assetData: any) {
    let assetOwners = [];
    if (assetData?.equipmentOwner?.length > 0) {
      assetOwners = assetData.equipmentOwner;
      assetOwners = sortOwnershipRecords(assetOwners);
      if (assetOwners && assetOwners.length) {
        assetOwners.forEach((data: any) => {
          data.custToolTipData = data.ucidName && data.ucid ? data.ucidName + ' - ' + data.ucid : '-';
          data.dealerToolTipData =
            data.dealerCustName && data.dealerCustNo ? data.dealerCustName + '-' + data.dealerCustNo : '-';
        });
      }
      assetData.ownershipDetails = assetOwners;
      this.data = assetOwners;
    } else {
      this.data = [];
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  fetchAssetOwnership() {
    let assetOwners = [];
    const ownershipInfoPayload = {
      assetIds: [this.ownershipData.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (result: any) => {
        if (!isEmpty(result[this.ownershipData.assetId])) {
          assetOwners = result[this.ownershipData.assetId] as any;
          assetOwners = sortOwnershipRecords(assetOwners);
          if (assetOwners && assetOwners.length) {
            assetOwners.forEach((data: any) => {
              data.custToolTipData = data.ucidName && data.ucid ? data.ucidName + ' - ' + data.ucid : '-';
              data.dealerToolTipData =
                data.dealerCustName && data.dealerCustNo ? data.dealerCustName + '-' + data.dealerCustNo : '-';
            });
          }
          this.ownershipData.ownerData = assetOwners;
          this.data = assetOwners;
        } else {
          this.data = [];
        }
        this.syncOwnershipDatStatus();
      },
      error: (_err: any) => {
        const errorMessage = 'Error occured while fetching data from Asset Ownership Info API.';
        this.showToastMessage(errorMessage, 'error');
        this.data = [];
      },
    });
  }

  checkDATStatus(ownershipData: any) {
    const { CATDigitalAuthStatus } = ownershipData.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'AGREE': {
        return 'icon-green';
      }
      case 'DECLINE': {
        return 'icon-red';
      }
      case 'UNAVAILABLE': {
        return 'icon-grey';
      }
      default: {
        return 'icon-amber'; // for UNREACHABLE state
      }
    }
  }

  updateDATBadge(ownershipData: any) {
    const { CATDigitalAuthStatus } = ownershipData.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'DECLINE': {
        return 'dat-badge-declined';
      }
      case 'UNAVAILABLE': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, all other states
      }
    }
  }

  updateDatStatus(ownershipData: any) {
    const { CATDigitalAuthStatus } = ownershipData.digitalAuthorizationDetails || '';
    if (CATDigitalAuthStatus == 'AGREE') {
      this.datStatus = 'AGREE';
      return `AVAILABLE`;
    } else if (CATDigitalAuthStatus == 'DECLINE') {
      this.datStatus = 'DECLINE';
      return `DECLINED`;
    } else if (CATDigitalAuthStatus == 'UNAVAILABLE') {
      this.datStatus = 'UNAVAILABLE';
      return `NOT AVAILABLE`;
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `SYSTEM ERROR`;
    }
  }

  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }

  datClick() {
    window.open(this.datUrl, '_blank');
  }
}
