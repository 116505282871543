<div class="account-name-column-align" *ngIf="!isChangeOwnership">
  <div
    class="account-dealer"
    [tooltipPosition]="'top'"
    [isEllipseEnabled]="true"
    cc-tooltip="accountCustomer"
    [template]="template2"
  >
    <span [innerHTML]="rowData.ucName | highlightSearch: searchString"></span>
    <div [innerHTML]="rowData.ucid | highlightSearch: searchString"></div>
  </div>
  <dsp-next-gen-ui-account-action-menus [accountInfo]="rowData" (fetchAccountsCallback)="customData.fetchAccounts()">
  </dsp-next-gen-ui-account-action-menus>
  <ng-template #template2>
    <div style="width: 220px; white-space: normal">
      <span> {{ rowData.ucName }}</span>
    </div>
  </ng-template>
</div>
<div *ngIf="isChangeOwnership">
  <div class="account-name-column-align">
    <div
      *ngIf="!showTruncatedText"
      class="account-dealer"
      [tooltipPosition]="'top'"
      [isEllipseEnabled]="true"
      cc-tooltip="accountCustomer"
      [template]="template2"
    >
      <span [innerHTML]="rowData[columnName] || '-' | highlightSearch: searchString"></span>
    </div>
    <div
      *ngIf="showTruncatedText"
      class="account-dealer"
      [tooltipPosition]="'top'"
      [isEllipseEnabled]="true"
      cc-tooltip="accountCustomer"
      [template]="template2"
      dspNextGenUiCopy
      [fullText]="rowData[columnName] ? rowData[columnName] : '-'"
    >
      <span [innerHTML]="rowData[columnName] || '-' | highlightSearch: searchString"></span>
    </div>
    <ng-template #template2>
      <div style="width: 220px; white-space: normal">
        <span> {{ rowData[columnName] }}</span>
      </div>
    </ng-template>
    <dsp-next-gen-ui-account-action-menus
      *ngIf="columnName == 'ucid'"
      [accountInfo]="rowData"
      (fetchAccountsCallback)="customData.fetchAccounts()"
    >
    </dsp-next-gen-ui-account-action-menus>
  </div>
</div>
