import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAccountsCountPayload, getAccountsPayload, getUsersSearchPayload } from '../accounts/accounts-interface';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  rootURL = '';

  private filterNotify = new Subject<any>();
  filterChanges = this.filterNotify.asObservable();

  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getAccounts(body: getAccountsPayload) {
    return this.http.post(this.rootURL + '/org/v2/subscriptionsV2', body);
  }

  getFilters(dealerCode: string) {
    return this.http.get(this.rootURL + `/subscriptions/getFilters/${dealerCode}`);
  }

  getUsersSearch(reqBody: getUsersSearchPayload) {
    return this.http.post(this.rootURL + '/user/getUserWithSubscriptionStatus', reqBody);
  }

  getAccountFilters(dealerCode: string) {
    return this.http.get(this.rootURL + `/org/accounts/getFilters/${dealerCode}`);
  }
  getAccountsCount(body: getAccountsCountPayload) {
    return this.http.post(this.rootURL + '/org/v2/subscriptions/count', body);
  }

  updateFilter(data: any) {
    if (data) {
      this.filterNotify.next(data);
    }
  }

  accountsExport(payload: any) {
    return this.http.post(this.rootURL + '/org/exportToCsv', payload);
  }
}
