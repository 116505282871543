import { Component, OnInit, Input, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CCTooltip, MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { DSPAppState } from '../../../../store/state/dsp.state';
import * as dspConstants from '../../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { findIndex, isEmpty, isNil } from 'lodash-es';
@Component({
  selector: 'dsp-next-gen-ui-manage-asset-details',
  templateUrl: './manage-asset-details.component.html',
  styleUrls: ['./manage-asset-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageAssetDetailsComponent implements OnInit {
  @Input() rowData: any;
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() hideBillToPartyOwnership!: boolean;
  @Input() showDealerInfo!: boolean;
  @Input() subsPricingEligible!: boolean;
  ownershipData: any;
  serviceMapping: any;
  datUrl = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  dealer: any;
  b2cCustomers!: any;
  billToParty!: string;
  cvaCount!: any;
  cvaStatus!: any;
  cvaUrl!: any;
  datStatus = '';
  deviceInfoWithoutTrim!: any;
  customerInfoWithoutTrim!: any;
  dealerCustomerInfoWithoutTrim!: any;
  cvaIconColor = false;
  cvaProductEnabled = false;

  constructor(
    private store: Store<DSPAppState>,
    public sanitizer: DomSanitizer,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.deviceInfoWithoutTrim = '';
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.serviceMapping = dsp.serviceMapping || {};
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.cvaIconColor = dsp?.featureFlagInfo?.CVAIconColor ? dsp?.featureFlagInfo?.CVAIconColor : false;
      this.cvaProductEnabled = dsp.featureFlagInfo.CVAProduct ? dsp.featureFlagInfo.CVAProduct : false;
    });
    this.mapOwnershipData();
    this.billToParty = this.checkForB2CCustomers();
  }

  checkForB2CCustomers() {
    const ucid = this.ownershipData?.ucid || '';
    return findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', ucid]) !== -1 ? 'Customer' : 'Dealer';
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  mapOwnershipData() {
    const customer = this.selectedAssetSubscriptionFormData?.customer;
    this.cvaCount = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaCount;
    this.cvaStatus = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaStatus;
    this.cvaUrl = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.catForesightLink;
    this.customerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.ucidName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.ucid;
    this.dealerCustomerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustNo;

    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }

  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }
  checkDATStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'AGREE': {
        return 'icon-green';
      }
      case 'DECLINE': {
        return 'icon-red';
      }
      case 'UNAVAILABLE': {
        return 'icon-grey';
      }
      default: {
        return 'icon-amber'; // for UNREACHABLE state
      }
    }
  }

  updateDatStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    if (CATDigitalAuthStatus == 'AGREE') {
      this.datStatus = 'AGREE';
      return `AVAILABLE`;
    } else if (CATDigitalAuthStatus == 'DECLINE') {
      this.datStatus = 'DECLINE';
      return `DECLINED`;
    } else if (CATDigitalAuthStatus == 'UNAVAILABLE') {
      this.datStatus = 'UNAVAILABLE';
      return `NOT AVAILABLE`;
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `SYSTEM ERROR`;
    }
  }

  updateDATBadge() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'DECLINE': {
        return 'dat-badge-declined';
      }
      case 'UNAVAILABLE': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, all other states
      }
    }
  }

  dealerCustomerData(id: string, name: string) {
    const trimmedName = name?.trim();
    const trimmedId = id?.trim();
    if (trimmedName && trimmedId) {
      return `${trimmedName} - ${trimmedId}`;
    } else if (trimmedName) {
      return trimmedName;
    } else if (trimmedId) {
      return trimmedId;
    }
    return '-';
  }

  updateName(name: string, id: string) {
    const ID = isNil(id) ? '' : id;
    const NAME = isNil(name) ? '' : name;
    return `${NAME} - ${ID}`;
  }

  getDeviceInformation() {
    if (isEmpty(this.rowData?.deviceSerialNumer) && isEmpty(this.rowData?.radioSerialNoList)) {
      return '-';
    }
    let deviceVal = !isEmpty(this.rowData?.deviceSerialNumer) ? this.rowData.deviceSerialNumer : '';
    if (!isEmpty(this.rowData?.deviceSerialNumer) && !isEmpty(this.rowData?.radioSerialNoList)) {
      deviceVal += ' / ';
    }
    if (this.rowData?.radioSerialNoList?.length > 0) {
      const length = this.rowData?.radioSerialNoList?.length;
      for (let i = 0; i < length; i++) {
        if (i == length - 1) {
          deviceVal += this.rowData?.radioSerialNoList[i] + '';
        } else {
          deviceVal += this.rowData?.radioSerialNoList[i] + ' / ';
        }
      }
      return this.checkForTrim(deviceVal);
    } else {
      return this.checkForTrim(this.rowData?.deviceSerialNumer);
    }
  }

  checkForTrim(value: string) {
    this.deviceInfoWithoutTrim = value;
    return value;
  }

  cvaURLClick() {
    window.open(this.cvaUrl, '_blank');
  }
}
