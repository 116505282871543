import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { AuthInterceptor } from './interceptor/http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { UserInfoEffects } from './store/effects/user-info.effects';
import { environment } from '../environments/environment';
import { SideNavModule } from '@cat-digital-workspace/shared-ui-core/side-nav';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { WorklistComponent } from './components/worklist/worklist.component';
import { AdministrationComponent } from './components/administration/administration.component';
import { QuickLinksComponent } from './components/quick-links/quick-links/quick-links.component';
import { FooterModule } from './components/footer/footer.module';
import { OrganizationComponent } from './components/UserSetting/organization/organization.component';
import { LinkEffects } from './store/effects/links.effects';
import { UserPreferenceComponent } from './components/user-preference/user-preference.component';
import { AdminComponent } from './components/admin/admin.component';
import { ManageComponent } from './components/manage/manage.component';
import { UseradminComponent } from './components/useradmin/useradmin.component';
import { UserAdminComponent } from './components/user-admin/user-admin.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DateFormatPipe } from './shared/date-format.pipe';
import { DeviceStatusComponent } from './components/device-status/device-status.component';
import { BrowserModule } from '@angular/platform-browser';
import { UserAdminRowExpansionComponent } from './components/user-admin/user-admin-row-expansion/user-admin-row-expansion.component';
import { SharedModule } from './shared/shared.module';
import { SpinnerModule } from './filters/spinner/spinner.module';
import { MobileAppDownloadComponent } from './components/mobile-app-download/mobile-app-download.component';
import { FilterCloseConfirmationComponent } from './components/filter-close-confirmation/filter-close-confirmation.component';
import { AuthGuard } from './shared/auth.guard';
import { ConfirmationPopupGuard } from './shared/confirmation-popup.guard';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { EulaComponent } from './eula/eula.component';
import { TosComponent } from './tos/tos.component';
import { WhatfixScriptService } from './services/whatfix-script.service';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { FilterCloseDashboardComponent } from './components/filter-close-dashboard/filter-close-dashboard.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { TableSettingsCloseConfirmationComponent } from './components/table-settings-close-confirmation/table-settings-close-confirmation.component';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { CommonConfirmationPopupComponent } from './components/common-confirmation-popup/common-confirmation-popup.component';
import { ManagePendingRequestComponent } from './components/manage-pending-request/manage-pending-request.component';
import { PendingRequestPaymentPreviewComponent } from './components/manage-pending-request/pending-request-payment-preview/pending-request-payment-preview.component';
import { PendingRequestComponent } from './components/manage-pending-request/pending-request/pending-request.component';
import { EnterReasonComponent } from './components/manage-pending-request/enter-reason/enter-reason.component';
import { PendingRequestAssetDetailsComponent } from './components/manage-pending-request/pending-request/pending-request-asset-details/pending-request-asset-details.component';
import { PendingRequestSubscriptionDetailsComponent } from './components/manage-pending-request/pending-request/pending-request-subscription-details/pending-request-subscription-details.component';
import { ApproveRequestComponent } from './components/manage-pending-request/pending-request/approve-request/approve-request.component';

@NgModule({
  declarations: [
    AppComponent,
    SubscriptionsComponent,
    WorklistComponent,
    AdministrationComponent,
    QuickLinksComponent,
    OrganizationComponent,
    UserPreferenceComponent,
    ManageComponent,
    UseradminComponent,
    UserAdminComponent,
    UserProfileComponent,
    DeviceStatusComponent,
    UserAdminRowExpansionComponent,
    AdminComponent,
    MobileAppDownloadComponent,
    FilterCloseConfirmationComponent,
    SessionExpiredComponent,
    EulaComponent,
    TosComponent,
    ConfigurationComponent,
    FilterCloseDashboardComponent,
    PrivacyNoticeComponent,
    TableSettingsCloseConfirmationComponent,
    CommonConfirmationPopupComponent,
    ManagePendingRequestComponent,
    PendingRequestComponent,
    PendingRequestAssetDetailsComponent,
    PendingRequestSubscriptionDetailsComponent,
    ApproveRequestComponent,
    PendingRequestPaymentPreviewComponent,
    PendingRequestComponent,
    EnterReasonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    SideNavModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([UserInfoEffects, LinkEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    FooterModule,
    SpinnerModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is Nt open
    }),
    SharedModule,
  ],
  providers: [
    LoaderService,
    DatePipe,
    DateFormatPipe,
    ConfirmationPopupGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: 'Navigator', useValue: navigator },
    { provide: 'Window', useValue: window },
    MsalService,
    MsalBroadcastService,
    WhatfixScriptService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(private WhatfixScriptService: WhatfixScriptService) {}
}
