import { AfterViewInit, ChangeDetectorRef, Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { Donut_Chart_Data } from '../dashboard-mock-data';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { ConnectivitySummaryExpandedViewComponent } from './connectivity-summary-expanded-view/connectivity-summary-expanded-view.component';
import { Router } from '@angular/router';
import { ccDonutSize } from '@cat-digital-workspace/shared-charts';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-connectivity-summary',
  templateUrl: './connectivity-summary.component.html',
  styleUrls: ['./connectivity-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectivitySummaryComponent implements OnInit, AfterViewInit {
  //dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  el: any;
  donutSizeOption: ccDonutSize = 'large';
  extraClass = window.devicePixelRatio;
  //Chart configuration
  chartConfig = {
    title: '',
    width: 600,
    height: '75%',
    totalFormat: true,
    marginLeft: window.devicePixelRatio === 1.5 ? -280 : -200,
    legend: {
      enabled: true,
      defaultLegend: [],
      lablesWithValue: ' | ',
    },
    tooltip: {
      shared: false,
      tooltipseperator: ' |',
    },
    donutConfig: {
      innerSize: '50%',
      size: 100,
      y: 10,
      total: 90,
      totalText: 'Total',
      useX: true,
      x: window.devicePixelRatio === 1.5 ? -145 : -100,
    },
    thousandsSep: ',',
    decimalPoint: ',',
  };
  //Chart data
  donutChartData = {
    series: [
      {
        name: 'Category',
        data: [{}],
      },
    ],
  };
  responseData: any;
  chartSpinner: any;

  constructor(
    private renderer: Renderer2,
    public dashboardService: DashboardService,
    private router: Router,
    private modal: CcModal,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.chartSpinner = true;
    this.dashboardService.getConnectivitySummary(this.dealerCode).subscribe({
      next: (data: any) => {
        this.chartSpinner = false;
        const chart_data: Donut_Chart_Data[] = [];
        this.responseData = data;
        if (this.responseData.length > 0) {
          this.responseData = this.responseData.sort((a: any, b: any) => {
            const labelOne = a.label.toLowerCase();
            const labelTwo = b.label.toLowerCase();
            return labelOne < labelTwo ? -1 : labelOne > labelTwo ? 1 : 0;
          });

          let isDataAvailable = false;
          this.responseData.forEach((element: any) => {
            if (element.label && element.count > 0) {
              const chart_obj = { name: '', y: 0 };
              chart_obj.name = element.label;
              chart_obj.y = element.count;
              chart_data.push(chart_obj);
              isDataAvailable = true;
            }
          });
          this.donutChartData.series[0].data = chart_data;
          if (!isDataAvailable) {
            this.chartSpinner = false;
            this.responseData = [];
          }
        }
      },
      error: (error: any) => {
        this.chartSpinner = false;
        this.responseData = [];
      },
    });
  }
  ngAfterViewInit() {
    const div = this.renderer.createElement('div');
    const text = this.renderer.createText('Total');
    this.el = document.getElementsByClassName('highcharts-title')[2];
    this.renderer.appendChild(div, text);
    if (this.el && this.el.childNodes && this.el.childNodes.length)
      this.renderer.insertBefore(this.el, div, this.el.childNodes[0]);
    // if (window.innerWidth === 1790) {
    //   this.renderer.setStyle(document.getElementsByClassName('donut-chart-wrapper')[0], 'height', '468px');
    // } else if (window.innerHeight === 1200) {
    //   this.renderer.setStyle(document.getElementsByClassName('donut-chart-wrapper')[0], 'height', '500px');
    // } else {
    //   this.renderer.setStyle(document.getElementsByClassName('donut-chart-wrapper')[0], 'height', '400px');
    // }
  }
  expandedDonutView() {
    this.cdr.detectChanges();

    const modalRef = this.modal.openModal(ConnectivitySummaryExpandedViewComponent, {
      type: 'semi-modal',
      width: '52%',
      data: {
        title: 'Connectivity Summary',
        config: this.chartConfig,
        chartData: this.donutChartData,
      },
      panelClass: ['connect-expand'],
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: true,
      disableBackdropClick: true,
    });
    modalRef.afterClosed().subscribe((result: any) => {
      this.chartConfig.height = '75%';
    });
  }

  connectivityChartClick(event: any) {
    if (event.point.name === 'Available for subscription') {
      sessionStorage.setItem('reportingState', JSON.stringify({ reportingState: ['Unsubscribed'] }));
    } else {
      sessionStorage.setItem('reportingState', JSON.stringify({ reportingState: [event.point.name] }));
    }
    this.router.navigate(['/asset']);

    const element: HTMLElement = document.getElementsByClassName('arrow-position')[0] as HTMLElement;
    element.click();
  }
}
