import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getSitesCountPayload, getSitesPayload } from '../components/sites/sites-interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  public errorText = 'There was an error while processing your request. Please try again after sometime';
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getSiteDetails(id: any) {
    return this.http.post(this.rootURL + '/site/getSiteDetails', id);
  }
  getAssetInfo(id: any) {
    return this.http.post(this.rootURL + '/equipment/getAssetInformation', id);
  }
  getSubscriptionServiceMap() {
    return this.http.get(this.rootURL + '/subscribe/subscriptionservicemap');
  }
  getSites(body: getSitesPayload) {
    return this.http.post(this.rootURL + '/site/v2/getSites', body);
  }
  getSitesCount(body: getSitesCountPayload) {
    return this.http.post(this.rootURL + '/site/v2/getSitesCount', body);
  }
  getSiteServiceCatalog() {
    return this.http.post(this.rootURL + '/site/getServiceInfo', {});
  }
  getMineStar(queryParams: any) {
    return this.http.get(this.rootURL + '/servicecatalog/getMinestarServiceDetails', {
      params: queryParams,
    });
  }
  getMapInfo(payLoad: any) {
    return this.http.post(this.rootURL + '/Map/getMapInfo', payLoad);
  }

  sitesExport(payload: any) {
    return this.http.post(this.rootURL + '/site/siteExport', payload);
  }
}
