<div class="cwsid">
  <span
    [cc-tooltip]="rowData[columnTitle]"
    class="text-truncate"
    [tooltipPosition]="'top'"
    tooltipWidth="200px"
    [isEllipseEnabled]="true"
    dspNextGenUiCopy
    [fullText]="rowData[columnTitle] ? rowData[columnTitle] : '-'"
    >{{ rowData[columnTitle] ? rowData[columnTitle] : '-' }}</span
  >
  <span
    *ngIf="columnTitle === 'cwsid'"
    [ngClass]="
      rowData.catRecId === loggedInUserDetails.federationIdentifier &&
      (loggedInUserDetails.roleName !== 'DSP Administrator' || loggedInUserDetails.roleName !== 'Super Administrator')
        ? 'configure-access-disable'
        : 'configure-access-icon'
    "
    ><cc-icon cc-tooltip="Configure Access" [tooltipPosition]="'top'" (click)="configureAccess($event, rowData)">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.904 14.6857L21 14.0564L19.5054 11.4586L18.3995 12.0982C17.9909 11.7485 17.5226 11.4687 16.9946 11.2789V10H14.0054V11.2789C13.4873 11.4687 13.019 11.7385 12.6005 12.0982L11.4946 11.4586L10 14.0564L11.096 14.6857C10.9864 15.2652 10.9864 15.7149 11.096 16.3243L10 16.9536L11.4946 19.5514L12.6005 18.9118C13.0091 19.2615 13.4774 19.5413 14.0054 19.7211V21H16.9946V19.7211C17.5127 19.5313 17.981 19.2615 18.3995 18.9118L19.5054 19.5514L21 16.9536L19.904 16.3243C20.0136 15.7349 20.0136 15.2751 19.904 14.6857Z"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M15.4999 17.5042C16.6005 17.5042 17.4927 16.6096 17.4927 15.506C17.4927 14.4024 16.6005 13.5078 15.4999 13.5078C14.3993 13.5078 13.5071 14.4024 13.5071 15.506C13.5071 16.6096 14.3993 17.5042 15.4999 17.5042Z"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M9.5 9.94881C11.9853 9.94881 14 7.94555 14 5.47441C14 3.00326 11.9853 1 9.5 1C7.01472 1 5 3.00326 5 5.47441C5 7.94555 7.01472 9.94881 9.5 9.94881Z"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path d="M9 12C3.5 12 1 17.8412 1 21H9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
      </svg> </cc-icon
  ></span>
</div>
