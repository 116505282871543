/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import * as dspConstants from '../../../../shared/dspConstants';
import { cloneDeep } from 'lodash-es';
import {
  Config,
  ConfigInfo,
  GetConfigFileUploadListPayload,
  GetConfigStatusPayload,
  UploadConfigFilePayload,
} from '../asset-drawer-interface';
import { Store } from '@ngrx/store';
import { PopupData } from '../../../../models/popup.interface';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { CommonConfirmationPopupComponent } from '../../../common-confirmation-popup/common-confirmation-popup.component';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-parameter-config-upload',
  templateUrl: './parameter-config-upload.component.html',
  styleUrls: ['./parameter-config-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ParameterConfigUploadComponent implements OnInit {
  headerContent: string | undefined;
  headerData: any;
  assetData!: ConfigInfo;
  configStatus: any;
  selectedConfig!: any;
  configSelected!: boolean;
  uploadConfigModalRef!: CcModalRef<any, any>;
  configFiles: Array<Config> = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isCommonPopup = false;
  dealer: any;
  constructor(
    public dialogRef: CcDrawerRef<ParameterConfigUploadComponent>,
    public assetDrawerService: AssetDrawerService,
    public modal: CcModal,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private sharedService: SharedService,
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      data: any;
    }
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp: any) => {
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
    });
    this.headerData = this.header.data ? cloneDeep(this.header.data) : {};
    this.getConfigStatus();
    this.assetData = {
      assetSerialNumber: this.headerData?.serialNumber,
      deviceSerialNumber: this.headerData?.deviceSerialNumer,
      deviceType: this.headerData?.deviceType,
      currentConfigFile: this.configStatus?.fileName,
    };
    this.getConfigFiles();
  }

  getConfigStatus() {
    const getConfigStatusObj: GetConfigStatusPayload = {
      make: this.headerData?.make,
      serialNumber: this.headerData?.serialNumber,
    };
    this.assetDrawerService.getConfigStatus(getConfigStatusObj).subscribe({
      next: (response: any) => {
        this.configStatus = response;
        this.assetData.currentConfigFile = this.configStatus?.fileName || 'N/A';
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  getConfigFiles() {
    const getConfigFileUploadListObj: GetConfigFileUploadListPayload = {
      type: this.headerData?.deviceType,
    };

    this.assetDrawerService.getConfigFileUploadList(getConfigFileUploadListObj).subscribe({
      next: (response: any) => {
        this.configFiles = response?.configs || [];
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  closeDrawer() {
    this.dialogRef.close();
  }
  selectedFile(event: any, selectedValue: any) {
    if (event) {
      this.configSelected = true;
      this.configFiles.forEach((config: any) => {
        if (config.id !== selectedValue.id) {
          config.selected = false;
        } else {
          config.selected = true;
          this.selectedConfig = config;
        }
      });
    } else {
      this.configSelected = false;
    }
  }
  openUploadFileConfigModal(content: any) {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.CONFIRM_POPUP_TITLE,
        messageArray: [dspConstants.Common.PL444_PARAMETER_CONFIGURATION___BODY_LINE1],
        showCrossMark: false,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SUBMIT,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
      };

      this.uploadConfigModalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '466px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.uploadConfigModalRef.afterClosed().subscribe((res: any) => {
        if (res === dspConstants.PopupButtons.SUBMIT) {
          this.uploadFileConfig();
        }
      });
    } else {
      this.uploadConfigModalRef = this.modal.openModal(content, {
        width: '466px',
        type: 'semi-modal',
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.uploadConfigModalRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.uploadFileConfig();
        }
      });
    }
  }

  closeUploadFileConfigModal(value: boolean) {
    this.uploadConfigModalRef.close(value);
  }
  uploadFileConfig() {
    const uploadConfigFileObj: UploadConfigFilePayload = {
      fileName: this.selectedConfig.fileName,
      hash: this.selectedConfig.id,
      make: this.headerData.make,
      serialNumber: this.headerData?.serialNumber,
      type: this.headerData?.deviceType,
    };
    this.assetDrawerService.pushConfigFileUploadList(uploadConfigFileObj).subscribe({
      next: (response: any) => {
        this.closeDrawer();
        if (response.status === 'Success') {
          const message =
            'Parameter configuration file has been queued for transmission to the device at next connection.';
          this.setDataLayer('success', '');
          this.showToastMessage(message, 'success');
        } else {
          const message =
            'Parameter configuration file upload has failed. Please retry or contact Cat Digital Support.';
          this.setDataLayer('failed', message);
          this.showToastMessage(message, 'error');
        }
      },
      error: (_err: any) => {
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  setDataLayer(status: any, message: any) {
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'PL444 Parameter Config Upload',
      formContent: this.headerData?.serialNumber + ' | ' + this.headerData?.deviceType,
      formContent2: '',
      formAdditionalContent: this.dealer?.dealerCode,
      formStatus: status,
      formFieldCausingError: message,
      formLocation: 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }
}
