import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Inject } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { AdditionalPlanPaymentDetailsComponent } from '../additional-plan-payment-details/additional-plan-payment-details.component';
import { ManageAssetSubscriptionComponent } from '../../manage-asset-subscription/manage-asset-subscription.component';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { TermModalComponent } from '../../../footer/term-modal/term-modal.component';
import { PaymentPreviewPayload, PaymentPreviewProduct } from '../../asset-interface';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { CcDrawerRef, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty } from 'lodash-es';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { AssetUnsuspensionComponent } from '../asset-unsuspension/asset-unsuspension.component';
import { DateFormattingService } from '../../../../services/date-formatting.service';
import { TermsofsaleModalComponent } from '../../../footer/termsofsale-modal/termsofsale-modal.component';
import { ManagePlanComponent } from '../../manage-plan/manage-plan.component';
import { LoaderService } from 'apps/dsp-ui/src/app/services/loader.service';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Router } from '@angular/router';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { FilterAssetsV2Payload } from '../asset-drawer-interface';
import { AssetService } from '../../services/asset.service';
import { ManagePendingRequestComponent } from '../../../manage-pending-request/manage-pending-request.component';
import {
  PendingRequestFormData,
  PlanData,
  SelfServeSubscriptionData,
} from '../../../manage-pending-request/self-serve.interface';
@Component({
  selector: 'dsp-next-gen-ui-asset-plan-payment-details',
  templateUrl: './asset-plan-payment-details.component.html',
  styleUrls: ['./asset-plan-payment-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetPlanPaymentDetailsComponent implements OnInit {
  manageAssetPlanDrawerRef!: any;
  requestUnsuspensionDrawerRef!: any;
  dealer: any;
  @Input() selectedAssetDetails: any;
  @Output() closeAssetDrawer = new EventEmitter();
  @Output() getSubscriptionHistory = new EventEmitter();
  reportingState: any;
  assetPlanPaymentDetails: any;
  applicationName: any;
  newApplicationName: any;
  addtionalPlanDetails: any;
  planTooltip: any;
  opTooltip: any;
  newPlanTooltip: any;
  newOpTooltip: any;
  customerPlan!: any;
  dealerPlan!: any;
  catPlan!: any;
  custSubs!: any;
  dealerSubs!: any;
  catSubs!: any;
  custSubsTooltip: any;
  dealerSubsTooltip: any;
  catSubsTooltip: any;
  newCustSubs!: any;
  newDealerSubs!: any;
  newCatSubs!: any;
  newCustSubsTooltip: any;
  newDealerSubsTooltip: any;
  newCatSubsTooltip: any;
  deviceFeaturesInfo!: any;
  dspConfig!: any;
  basePlan!: any;
  optionalPlans: any[] = [];
  multiLevelServices!: any;
  showunsuspensionBtn!: boolean;
  showInfoIcon = true;
  plans: any[] = [];
  plansWithoutTrim: any[] = [];
  newPlans: any[] = [];
  newPlansWithoutTrim: any[] = [];
  newOptionalPlans: any[] = [];
  serviceMapping!: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  dspWorklistConstants = dspConstants.Worklist;
  siteDetails: any;
  isSiteDetailsRetrieved!: any;
  isViewSubscription!: boolean;
  subsPricingEligible = false;
  isNewSubscription!: boolean;
  isSuspendedForLocationUnauthorized!: boolean;
  paymentList: any[] = [];
  userActiveFeatures: any;
  paymentMessage: any;
  globalInstance: any;
  telUserType: any;
  dspStoreData!: any;
  isNumberConventionEnabled: any;
  isTimeFormatEnabled: any;
  isMultiProductWorkFlow = true;
  filterassetv2res: any;
  subscribedPlansJson: any;
  newSubscribedPlansJson: any[] = [];

  isShowPriceIfNoBilling = false;
  seeMoreOverlayBasicDetails: any;
  isShowManagePlanIcon = false;

  thirdPartySerialNo: any;
  isCcatSearch = false;
  isPaymentPreviewMessage = false;
  thirdPartymake: any;
  isChinaDealer = false;
  isSelfServeSubscription = false;

  constructor(
    public modal: CcModal,
    private assetDrawerService: AssetDrawerService,
    public manageAssetPlanContainerRef: CcDrawerRef<ManageAssetSubscriptionComponent>,
    private drawerService: DrawerService,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    private dateFormattingService: DateFormattingService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private sharedService: SharedService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private userPreferenceService: UserPreferenceService,
    private assetService: AssetService,
    private changeDetector: ChangeDetectorRef,

    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      assetDetails: any;
    }
  ) {}
  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.multiLevelServices = [];
      this.serviceMapping = dsp.serviceMapping;
      this.dspConfig = dsp.dspConfig;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isMultiProductWorkFlow = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.isCcatSearch = this.dspStoreData?.featureFlagInfo?.isCcatSearch
        ? this.dspStoreData?.featureFlagInfo?.isCcatSearch
        : false;
      this.isShowManagePlanIcon = this.dspStoreData?.featureFlagInfo?.isShowManagePlanIcon
        ? this.dspStoreData?.featureFlagInfo?.isShowManagePlanIcon
        : false;
      this.isPaymentPreviewMessage = this.dspStoreData?.featureFlagInfo?.isPaymentPreviewMessage
        ? this.dspStoreData?.featureFlagInfo?.isPaymentPreviewMessage
        : false;
    });

    if (this.isMultiProductWorkFlow) {
      this.assetService.getAssetDataSubscribedPlansEdit(this.header.assetDetails).subscribe((data: any) => {
        this.subscribedPlansJson = data;
        this.selectedAssetDetails.subscribedPlansJson = this.subscribedPlansJson;
        this.setOptionalPlans();
      });
    }
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });

    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    this.thirdPartySerialNo = this.assetService.getThirdPartySerialNumber();
    this.thirdPartymake = this.assetService.getThirdPartyMake();
  }

  thirdParty(): void {
    if (
      this.selectedAssetDetails?.reportingState === this.dspWorklistConstants.SUSPENDED ||
      this.selectedAssetDetails.isMaskRequired ||
      (this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures?.indexOf('View Only') > -1 ||
          this.userActiveFeatures?.indexOf('Read Only') > -1 ||
          this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
      !this.isShowManagePlanIcon
        ? false
        : true
    ) {
      this.managePlan();
    }
  }

  ngOnChanges(): void {
    if (this.selectedAssetDetails) {
      this.seeMoreOverlayBasicDetails = {
        make: this.selectedAssetDetails?.make ? this.selectedAssetDetails?.make : '',
        model: this.selectedAssetDetails?.model ? this.selectedAssetDetails?.model : '',
        modelYear: this.selectedAssetDetails?.manufacturingYear ? this.selectedAssetDetails?.manufacturingYear : '',
        serialNumber: this.selectedAssetDetails?.serialNumber ? this.selectedAssetDetails?.serialNumber : '',
        engineSerialNumber: this.selectedAssetDetails?.engineSerialNo ? this.selectedAssetDetails?.engineSerialNo : '',
        reportingState: this.selectedAssetDetails.reportingState ? this.selectedAssetDetails.reportingState : '',
      };
    }
    if (this.selectedAssetDetails?.reportingState && this.selectedAssetDetails?.ownershipDetails) {
      this.reportingState = this.selectedAssetDetails.reportingState;
      this.isViewSubscription = true;
      if (this.isNewSubscription) {
        this.isViewSubscription = false;
      }
      this.loadPlans();
      this.updateSuspensionTooltip();
      if (this.isMultiProductWorkFlow && this.reportingState == dspConstants.Worklist.SUBSCRIBED) {
        this.assetService.getAssetDataSubscribedPlansEdit(this.selectedAssetDetails).subscribe((data: any) => {
          this.subscribedPlansJson = data;
          this.selectedAssetDetails.subscribedPlansJson = this.subscribedPlansJson;
          this.setOptionalPlans();
          this.multiProductPaymentDetails();
        });
      } else {
        if (this.selectedAssetDetails?.siteId && this.selectedAssetDetails?.siteId !== null) {
          this.getSiteDetails(this.selectedAssetDetails.siteId);
        } else if (this.reportingState == dspConstants.Worklist.SUBSCRIBED) {
          this.paymentPayload();
        }
      }

      this.showUnsuspensionBtn(this.selectedAssetDetails);
      if (this.isCcatSearch) {
        if (this.thirdPartymake && this.thirdPartySerialNo) {
          this.thirdPartySerialNo = '';
          this.thirdPartymake = '';
          this.thirdParty();
        }
      }
    }
    console.log(this.selectedAssetDetails, 'sad');
  }

  setFilterAssetsPayload() {
    return {
      dealerCode: this.dealer?.dealerCode || '',
      make: this.header?.assetDetails?.make,
      serialNumber: this.header?.assetDetails?.serialNumber,
      flag: false,
    };
  }
  getOptionalPlanLength(customerLevel: any) {
    let count = 0;
    customerLevel.find((item: any) => {
      if (item?.type == 'Addon' || (item.planName && !item.type)) {
        count++;
      }
    });
    return count;
  }
  setOptionalPlans() {
    this.subscribedPlansJson?.servicesSubscribedDTO.forEach((app: any) => {
      app.optionalPlans = [];
      const catOrDealer = this.telUserType == 'CAT' ? app?.catLevel : app?.dealerLevel;
      catOrDealer?.find((item: any) => {
        if (item?.type == 'Addon' || (item.planName && !item.type)) {
          app.optionalPlans.push(item);
        }
      });
    });
  }
  doesPromoExistForAppName(promotions: any) {
    if (!promotions || !promotions.length) {
      return false;
    } else {
      return true;
    }
  }
  minestarCheck(plan: any) {
    if (plan?.appName === dspConstants.Worklist.MINESTAR) {
      return true;
    } else {
      return false;
    }
  }
  vlCheck(plan: any) {
    if (plan === dspConstants.Worklist.VISION_LINK || plan === dspConstants.Worklist.NEW_VISION_LINK) {
      return true;
    } else {
      return false;
    }
  }
  getSiteDetails(siteId: string) {
    if (isEmpty(this.selectedAssetDetails?.siteDetails) && !this.isSiteDetailsRetrieved) {
      this.isSiteDetailsRetrieved = true;
      this.assetDrawerService.getSitesById({ id: siteId }).subscribe({
        next: (response: any) => {
          this.siteDetails = response;
          this.selectedAssetDetails.siteDetails = { ...this.siteDetails };
          this.paymentPayload();
        },
        error: (err: any) => {
          this.siteDetails = this.selectedAssetDetails.siteDetails = {};
          this.paymentPayload();
          this.showToastMessage(this.systemError, 'error');
        },
      });
    } else {
      this.siteDetails = this.selectedAssetDetails?.siteDetails || {};
      this.paymentPayload();
    }
  }

  loadPlans() {
    /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    const customerSubId = this.selectedAssetDetails?.customerSubscriptionId || '';
    this.customerPlan = this.serviceMapping[customerSubId.toString()]?.subscriptionLevel || '';
    const custSubs = this.customerPlan.split('|');
    this.custSubs = custSubs[0];
    const catSubId = this.selectedAssetDetails?.catSubscriptionId || '';
    this.catPlan = this.serviceMapping[catSubId.toString()]?.subscriptionLevel || '';
    const catSubs = this.catPlan.split('|');
    this.catSubs = catSubs[0];
    const dealerSubs = this.dealerPlan.split('|');
    this.dealerSubs = dealerSubs[0];
    */
    const dealerSubId = this.selectedAssetDetails?.dealerSubscriptionId || '';
    this.dealerPlan = this.serviceMapping[dealerSubId.toString()]?.subscriptionLevel || '';
    const catSubId = this.selectedAssetDetails?.catSubscriptionId || '';
    this.catPlan = this.serviceMapping[catSubId.toString()]?.subscriptionLevel || '';
  }
  paymentPayload() {
    const subId =
      this.selectedAssetDetails?.customerSubscriptionId ||
      this.selectedAssetDetails?.dealerSubscriptionId ||
      this.selectedAssetDetails?.catSubscriptionId ||
      '';
    const getPaymentPayload: any = {
      assetMake: this.selectedAssetDetails?.make || '',
      assetSerialNumber: this.selectedAssetDetails?.serialNumber || '',
      deviceId: '',
      deviceSerialNumber: this.selectedAssetDetails?.deviceSerialNumer || '',
      deviceType: this.selectedAssetDetails?.deviceType || '',
      dealerCode: this.dealer?.dealerCode,
      ucid: this.selectedAssetDetails?.ownershipDetails[0]?.ucid || '',
      customerName: this.selectedAssetDetails?.ownershipDetails[0]?.ucidName || '',
      dcnName: this.selectedAssetDetails?.ownershipDetails[0]?.dealerCustName || '',
      dcnNumber: this.selectedAssetDetails?.ownershipDetails[0]?.dealerCustNo || '',
      contractStartDate: this.selectedAssetDetails?.contractStartDate
        ? this.dateFormattingService.formatDate(new Date(this.selectedAssetDetails?.contractStartDate), 'YYYY/MM/DD')
        : null,
      contractEndDate: this.selectedAssetDetails?.contractEndDate
        ? this.dateFormattingService.formatDate(new Date(this.selectedAssetDetails?.contractEndDate), 'YYYY/MM/DD')
        : null,
      applicationName: this.serviceMapping[subId.toString()]?.appName || '',
      siteName: this.handleSiteNameAdd(),
      prepay: this.selectedAssetDetails?.prepay,
      productFamily: this.selectedAssetDetails?.productFamily || null,
      isViewSubscription: true,
    };
    if (this.isShowPriceIfNoBilling) {
      getPaymentPayload.subsPricingEligible = this.subsPricingEligible;
    }
    if (this.isNewSubscription) {
      this.isViewSubscription = false;
    }
    this.applicationName = getPaymentPayload.applicationName;
    if (this.isViewSubscription) {
      this.loaderService.show();
      this.assetDrawerService.getPaymentPreview(getPaymentPayload).subscribe({
        next: (response: any) => {
          this.assetPlanPaymentDetails = response;
          this.selectedAssetDetails.paymentDetails = this.assetPlanPaymentDetails;
          this.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
          this.mapBaseAndOptionalPlanFromLandingPage();
          this.checkForTrim();
          this.loaderService.hide();
        },
        error: (_err: any) => {
          this.loaderService.hide();
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  updateFooterMessage(currentPaymentDetails: any) {
    let footerMsg;
    const msg = currentPaymentDetails.paymentDetailsMessage
      ? currentPaymentDetails.paymentDetailsMessage
      : currentPaymentDetails.cancelNotificationMessage;
    if (!msg) footerMsg = '';
    if (msg !== '') {
      footerMsg = msg
        ?.split('<br/>')
        ?.join(' ')
        ?.replace(
          '"/assets/dsp_help_document/index.htm#t=BillingOverview.htm"',
          '"' + environment.redirectURL.helpDocUrl + 'article/Can-I-prepay-for-subscriptions?language=en_US"'
        )
        ?.replace('href="terms/tos"', 'onclick="termSaleModal()"');
    }
    // if promotion is there and promotion not eligible message also coming from API, we should hide the message.
    if (
      currentPaymentDetails?.current?.promotions?.length > 0 &&
      msg.indexOf(dspConstants.Worklist.PROMOTION_NOT_ELIGIBLE) != -1
    ) {
      footerMsg = msg.split('<br/>').join(' ').replace(dspConstants.Worklist.PROMOTION_NOT_ELIGIBLE, '');
    }
    const TOS_REGEX = new RegExp('This order is subject to our <a[^>]*>Terms of Sale</a>');
    if (
      (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) ||
      this.userActiveFeatures?.indexOf('Contract Visualization') === -1
    ) {
      footerMsg = footerMsg?.replace(TOS_REGEX, '');
    }
    return footerMsg;
  }
  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        window.open('termSaleModal', '_blank');
      }
    });
  }

  handleSiteNameAdd() {
    if (this.selectedAssetDetails?.siteId && this.selectedAssetDetails?.siteId !== null) {
      return this.siteDetails?.name;
    } else {
      return '';
    }
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  showUnsuspensionBtn(asset: any) {
    if (asset?.ownershipDetails?.length > 0) {
      asset.ownershipDetails.forEach((ownershipInfo: any) => {
        if (
          asset.suspended &&
          !asset.enableGpsWarning &&
          !asset.disableSuspendCancelReason &&
          ownershipInfo.digitalAuthorizationDetails.CATDigitalAuthStatus != 'DECLINE' &&
          asset.deviceType &&
          this.deviceFeaturesInfo &&
          this.deviceFeaturesInfo[asset.deviceType] &&
          Object.keys(this.deviceFeaturesInfo[asset.deviceType]).indexOf('SHOW_UNSUSPENSION') > -1 &&
          this.deviceFeaturesInfo[asset.deviceType].SHOW_UNSUSPENSION == true &&
          ownershipInfo.dealerCode == this.dealer.dealerCode
        ) {
          this.showunsuspensionBtn = true;
        } else if (ownershipInfo.dealerCode != this.dealer.dealerCode) {
          this.showInfoIcon = false;
        }
      });
    }
  }
  updateSuspensionTooltip() {
    if (
      this.selectedAssetDetails.disableSuspendCancelReason ||
      (this.selectedAssetDetails?.ownershipDetails[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus != 'DECLINE' &&
        this.selectedAssetDetails?.enableGpsWarning &&
        this.selectedAssetDetails?.enableSubscription)
    ) {
      this.isSuspendedForLocationUnauthorized = true;
    } else {
      this.isSuspendedForLocationUnauthorized = false;
    }
  }
  requestUnsuspension() {
    this.requestUnsuspensionDrawerRef = this.drawerService.openDrawer<AssetUnsuspensionComponent, CcDrawerConfig>(
      AssetUnsuspensionComponent,
      {
        data: {
          headerType: 'Request Unsuspension',
          data: this.selectedAssetDetails,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.requestUnsuspensionDrawerRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.selectedAssetDetails.reportingState = dspConstants.Worklist.UNSUBSCRIBED;
        this.selectedAssetDetails.suspended = false;
        this.assetDrawerService.setAssetUnsuspensionFormData(this.selectedAssetDetails, true);
        this.getSubscriptionHistory.emit();
      }
    });
  }
  checkForTrim() {
    this.plansWithoutTrim = cloneDeep(this.plans);
    this.plans.forEach((product: PaymentPreviewProduct, i: number) => {
      if (product?.type == 'Base Plan') {
        if (product?.name.length > 45) {
          this.planTooltip = product?.name;
          product.name = product?.name.slice(0, 45) + '…';
        }
      }
      if (product?.type == 'Optional Plan') {
        if (i === 1) {
          if (this.optionalPlans[0]?.name === product?.name && product?.name.length > 32) {
            this.opTooltip = product?.name;
            product.name = product?.name.slice(0, 32) + '…';
          } else {
            this.opTooltip = '';
          }
        }
      }
    });
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
    if (this.custSubs?.length > 32) {
      this.custSubsTooltip = cloneDeep(this.custSubs);
      this.custSubs = this.custSubs.slice(0, 32) + '…';
    }
    if (this.dealerSubs?.length > 32) {
      this.dealerSubsTooltip = cloneDeep(this.dealerSubs);
      this.dealerSubs = this.dealerSubs.slice(0, 32) + '…';
    }
    if (this.catSubs?.length > 32) {
      this.catSubsTooltip = cloneDeep(this.catSubs);
      this.catSubs = this.catSubs.slice(0, 32) + '…';
    }
    */
  }

  checkForTrimNewPlans() {
    this.newPlansWithoutTrim = cloneDeep(this.newPlans);
    this.newPlans.forEach((product: PaymentPreviewProduct, i: number) => {
      if (product.type == 'Base Plan') {
        if (product?.name?.length > 45) {
          this.newPlanTooltip = product.name;
          product.name = product.name.slice(0, 45) + '…';
        } else {
          this.newPlanTooltip = '';
        }
      }
      if (product?.type == 'Optional Plan') {
        if (i === 1) {
          if (this.newOptionalPlans[0]?.name === product?.name && product?.name.length > 32) {
            this.newOpTooltip = product?.name;
            product.name = product?.name.slice(0, 32) + '…';
          } else {
            this.newOpTooltip = '';
          }
        }
      }
    });
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
    if (this.newCustSubs?.length > 32) {
      this.newCustSubsTooltip = cloneDeep(this.newCustSubs);
      this.newCustSubs = this.newCustSubs.slice(0, 32) + '…';
    }
    if (this.newDealerSubs?.length > 32) {
      this.newDealerSubsTooltip = cloneDeep(this.newDealerSubs);
      this.newDealerSubs = this.newDealerSubs.slice(0, 32) + '…';
    }
    if (this.newCatSubs?.length > 32) {
      this.newCatSubsTooltip = cloneDeep(this.newCatSubs);
      this.newCatSubs = this.newCatSubs.slice(0, 32) + '…';
    }
  */
  }
  mapBaseAndOptionalPlanFromLandingPage() {
    let plans: any;
    if (this.telUserType === dspConstants.Common.CAT) {
      plans = this.catPlan?.split('|');
    } else {
      plans = this.dealerPlan?.split('|');
    }
    this.plans = [];
    for (let i = 0; i < plans?.length; i++) {
      let planObj: any = {
        name: plans[i],
      };
      if (this.assetPlanPaymentDetails?.current?.products?.length > 0) {
        this.assetPlanPaymentDetails?.current?.products?.filter((plan: PaymentPreviewProduct) => {
          if (plans[i] == plan?.name) {
            planObj = Object.assign(planObj, plan);
          }
        });
      }
      if (i == 0) {
        planObj.type = 'Base Plan';
      } else {
        planObj.type = 'Optional Plan';
        this.optionalPlans.push(planObj);
      }
      this.plans.push(planObj);
    }
  }
  getAmountFromPaymentPreview(planName: any) {
    const planObj = this.assetPlanPaymentDetails?.current?.products?.filter((value: PaymentPreviewProduct) => {
      return value.name == planName;
    });
    return planObj?.amount;
  }

  managePlanClicked() {
    if (this.selectedAssetDetails.showPendingRequestChip) {
      //pending request flow
      this.managePendingRequest();
    } else {
      //usual subscription flow
      this.managePlan();
    }
  }
  managePlan() {
    this.isSelfServeSubscription = false;
    this.selectedAssetDetails.currentPlan = this.plans;
    if (this.isMultiProductWorkFlow) {
      this.assetService.listOfProducts = '';
      this.assetService.listOfSubsData = '';
      this.assetService.listOfAddmorePlanDetails = '';
      this.assetService.selectPlanMenuDetails.addMorePlanClicked = false;

      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePlanComponent, CcDrawerConfig>(
        ManagePlanComponent,
        {
          data: {
            type: 'DRAWER',
            selectedAssetDetails: this.selectedAssetDetails,
          },
          enableBlackBackDrop: true,
          closeOnEsc: false,
          id: 'multiSizeDrawer',
          disableBackdropClick: true,
          drawerSize: CcDrawerSize.LARGE,
        }
      );

      this.manageAssetPlanDrawerRef.afterClosed().subscribe((managePlanDrawerData: any) => {
        if (managePlanDrawerData?.apiCallNeeded) {
          this.selectedAssetDetails.reportingState = managePlanDrawerData?.reportingState;
          this.selectedAssetDetails.isAssetDealerInfoRetrieved = false;
          this.selectedAssetDetails.dealerCode = !isEmpty(this.selectedAssetDetails?.dealerCode)
            ? this.selectedAssetDetails.dealerCode
            : this.dealer?.dealerCode;
          this.selectedAssetDetails.suspended = false;
          this.selectedAssetDetails.productFamily =
            managePlanDrawerData.formData?.products[
              dspConstants.Worklist.MINESTAR
            ]?.productFamily?.currentProductFamily;
          this.selectedAssetDetails.prepay =
            managePlanDrawerData.formData?.products[
              dspConstants.Worklist.NEW_VISION_LINK
            ]?.prepay?.currentPrepayData?.prepaySelected;
          this.selectedAssetDetails.isNewSubscription = true;
          this.selectedAssetDetails.isSiteDetailsRetrieved = false;
          this.selectedAssetDetails.siteId =
            managePlanDrawerData.formData?.products[dspConstants.Worklist.MINESTAR]?.siteInfo?.siteId;
          if (this.checkSiteNameChangedMultiProduct(managePlanDrawerData?.formData)) {
            this.selectedAssetDetails.siteDetails = {};
          }
          if (this.selectedAssetDetails.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.isNewSubscription = true;
            this.getNewSubscriptionDataForMultiProduct(managePlanDrawerData?.formData);
          } else if (this.selectedAssetDetails.reportingState == dspConstants.Worklist.SUSPENDED) {
            this.selectedAssetDetails.suspended = true;
            this.selectedAssetDetails.dealerCode = '';
            this.isNewSubscription = false;
          } else {
            this.selectedAssetDetails.dealerCode = '';
            this.isNewSubscription = false;
          }
          this.assetDrawerService.updateAssetDetails(this.selectedAssetDetails);
        }

        if (managePlanDrawerData?.closeAssetDrawer) {
          this.closeAssetDrawer.emit();
        }
        this.thirdPartySerialNo = '';
        this.thirdPartymake = '';
        this.assetService.setThirdPartySerialNumber('');
        this.assetService.setThirdPartymake('');
        window.localStorage.removeItem('thirdPartyApi');
      });
    } else {
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManageAssetSubscriptionComponent, CcDrawerConfig>(
        ManageAssetSubscriptionComponent,
        {
          data: {
            type: 'DRAWER',
            selectedAssetDetails: this.selectedAssetDetails,
          },
          enableBlackBackDrop: true,

          closeOnEsc: false,
          id: 'multiSizeDrawer',
          disableBackdropClick: true,
          drawerSize: CcDrawerSize.LARGE,
        }
      );

      this.manageAssetPlanDrawerRef.afterClosed().subscribe((managePlanDrawerData: any) => {
        if (managePlanDrawerData?.apiCallNeeded) {
          this.selectedAssetDetails.reportingState = managePlanDrawerData?.reportingState;
          this.selectedAssetDetails.isAssetDealerInfoRetrieved = false;
          this.selectedAssetDetails.dealerCode = !isEmpty(this.selectedAssetDetails.dealerCode)
            ? this.selectedAssetDetails.dealerCode
            : this.dealer?.dealerCode;
          this.selectedAssetDetails.suspended = false;
          this.selectedAssetDetails.productFamily = managePlanDrawerData.formData?.productFamily?.currentProductFamily;
          this.selectedAssetDetails.prepay = managePlanDrawerData.formData?.prepay?.currentPrepayData?.prepaySelected;
          this.selectedAssetDetails.isNewSubscription = true;
          this.selectedAssetDetails.applicationName = managePlanDrawerData.formData?.service?.currentService;
          this.selectedAssetDetails.isSiteDetailsRetrieved = false;
          this.selectedAssetDetails.siteId = managePlanDrawerData.formData?.selectedSiteInfo?.siteId;
          if (this.checkSiteNameChanged(managePlanDrawerData?.formData)) {
            this.selectedAssetDetails.siteDetails = {};
          }
          if (this.selectedAssetDetails.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.isNewSubscription = true;
            this.getNewSubscriptionData(managePlanDrawerData?.formData);
          } else if (this.selectedAssetDetails.reportingState == dspConstants.Worklist.SUSPENDED) {
            this.selectedAssetDetails.suspended = true;
            this.selectedAssetDetails.dealerCode = '';
            this.isNewSubscription = false;
          } else {
            this.selectedAssetDetails.dealerCode = '';
            this.isNewSubscription = false;
          }
          this.assetDrawerService.updateAssetDetails(this.selectedAssetDetails);
        }

        if (managePlanDrawerData?.closeAssetDrawer) {
          this.closeAssetDrawer.emit();
        }
        this.thirdPartySerialNo = '';
        this.thirdPartymake = '';
        this.assetService.setThirdPartySerialNumber('');
        this.assetService.setThirdPartymake('');
        window.localStorage.removeItem('thirdPartyApi');
      });
    }
  }

  managePendingRequest() {
    this.isSelfServeSubscription = true;
    this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePendingRequestComponent, CcDrawerConfig>(
      ManagePendingRequestComponent,
      {
        data: {
          type: 'DRAWER',
          selectedAssetDetails: this.selectedAssetDetails,
        },
        enableBlackBackDrop: true,
        closeOnEsc: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );

    this.manageAssetPlanDrawerRef.afterClosed().subscribe((res: any) => {
      if (res) {
        if (res.requestStatus === dspConstants.Common.APPROVED) {
          this.selectedAssetDetails.reportingState = res?.reportingState;
          this.selectedAssetDetails.isAssetDealerInfoRetrieved = false;
          this.selectedAssetDetails.dealerCode = !isEmpty(this.selectedAssetDetails?.dealerCode)
            ? this.selectedAssetDetails.dealerCode
            : this.dealer?.dealerCode;
          this.selectedAssetDetails.suspended = false;
          this.selectedAssetDetails.prepay = res.formData.newSubscriptionDetails?.prepay || false;
          this.selectedAssetDetails.applicationName = res.formData?.newSubscriptionDetails?.applicationName || '';
          this.selectedAssetDetails.isNewSubscription = true;
          this.selectedAssetDetails.isSiteDetailsRetrieved = false;
          if (this.selectedAssetDetails.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.isNewSubscription = true;
            this.getNewSubscriptionData(res?.formData, true);
          }

          this.assetDrawerService.updateAssetDetails(this.selectedAssetDetails);
        } else {
          this.closeAssetDrawer.emit();
        }
      }
    });
  }
  checkSiteNameChanged(formData: any) {
    return formData?.selectedSiteInfo?.siteId !== formData?.previousSiteInfo?.siteId;
  }
  checkSiteNameChangedMultiProduct(formData: any) {
    return (
      formData?.products[dspConstants?.Worklist?.MINESTAR]?.selectedSiteInfo?.siteId !==
      formData?.products[dspConstants?.Worklist?.MINESTAR]?.previousSiteInfo?.siteId
    );
  }
  openTermsOfSale() {
    sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('termSaleModal', '_blank');
  }

  openDrawerInMultiSize() {
    this.addtionalPlanDetails = this.drawerService.openDrawer<AdditionalPlanPaymentDetailsComponent, CcDrawerConfig>(
      AdditionalPlanPaymentDetailsComponent,
      {
        data: {
          rowData: this.plansWithoutTrim,
          applicationName: this.applicationName,
          searchType: this.selectedAssetDetails.searchType,
          showRatePlans: !this.selectedAssetDetails.isMaskRequired,
          multiProductEnabled: this.dspConfig.isMultiProductEnabled,
          seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: false,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }

  openDrawerInMultiSizeWithNewPlans() {
    this.addtionalPlanDetails = this.drawerService.openDrawer<AdditionalPlanPaymentDetailsComponent, CcDrawerConfig>(
      AdditionalPlanPaymentDetailsComponent,
      {
        data: {
          rowData: this.newPlansWithoutTrim,
          applicationName: this.newApplicationName,
          searchType: this.selectedAssetDetails.searchType,
          showRatePlans: !this.selectedAssetDetails?.isMaskRequired,
          seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: false,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }

  displayAmount(amount: any) {
    if (this.selectedAssetDetails?.isMaskRequired || amount === 'N/A' || isEmpty(amount)) {
      return '-';
    }
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(amount, true);
    }
    return amount;
  }
  getConvertedDate(expirationDate: any) {
    if (this.isTimeFormatEnabled) {
      return !isEmpty(expirationDate) ? this.userPreferenceService.convertDate(expirationDate) : '';
    }
    return expirationDate;
  }
  getNewSubscriptionData(formData: any, isSelfServeSubscription = false) {
    if (isSelfServeSubscription) {
      let localFormData: PendingRequestFormData = formData;
      let newSubscriptionData: SelfServeSubscriptionData = localFormData.newSubscriptionDetails
        ? localFormData.newSubscriptionDetails
        : {};
      const basePlan = newSubscriptionData.basePlan?.name || '';
      let newPlans: any = [];
      newPlans.push(basePlan);
      //check for optional plans
      if (newSubscriptionData.optionalPlan && newSubscriptionData.optionalPlan.length >= 1) {
        newSubscriptionData.optionalPlan.forEach((plan: PlanData) => {
          newPlans.push(plan.name);
        });
      }

      const allCurrentSubscriptions = cloneDeep(newPlans);
      const payloadObj: PaymentPreviewPayload = {
        assetMake: this.selectedAssetDetails?.make || '',
        assetSerialNumber: this.selectedAssetDetails?.serialNumber || '',
        deviceId: '',
        deviceSerialNumber: this.selectedAssetDetails?.deviceSerialNumer || '',
        deviceType: this.selectedAssetDetails?.deviceType || '',
        dealerCode: formData?.customer?.dealerCode,
        ucid: localFormData?.customer?.ucid || '',
        customerName: localFormData?.customer?.ucidName || '',
        dcnName: localFormData?.customer?.dealerCustName || '',
        dcnNumber: localFormData?.customer?.dealerCustNo || '',
        contractEndDate: newSubscriptionData.prepayDates?.contractEndDate,
        contractStartDate: newSubscriptionData.prepayDates?.contractStartDate,
        applicationName: newSubscriptionData.applicationName,
        siteName: this.selectedAssetDetails?.siteName || '',
        subscriptions: allCurrentSubscriptions,
        cva: this.selectedAssetDetails?.cvaInfo?.cvaStatus == 'Available' ? true : false,
        prepay: newSubscriptionData.prepay || false,
        isNewSubscription: true,
        productFamily: this.selectedAssetDetails?.productFamily || null,
      };
      this.newApplicationName = payloadObj.applicationName;
      this.newCustSubs = this.newDealerSubs = this.newCatSubs = basePlan;
      if (isEmpty(localFormData?.newSubscriptionDetails?.paymentPreviewResponse?.new)) {
        this.loaderService.show();
        this.assetDrawerService.getPaymentPreview(payloadObj).subscribe({
          next: (response: any) => {
            this.loaderService.hide();
            this.assetPlanPaymentDetails = response;
            this.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
            this.mapNewBaseAndOptionalPlan(newPlans);
            this.checkForTrimNewPlans();
          },
          error: (_err: any) => {
            this.loaderService.hide();
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else {
        this.assetPlanPaymentDetails = localFormData.newSubscriptionDetails?.paymentPreviewResponse;
        this.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
        this.mapNewBaseAndOptionalPlan(newPlans);
        this.checkForTrimNewPlans();
      }
    } else {
      const currentSubscription =
        formData?.subscription?.currentSubscription?.customerLevel ||
        formData?.subscription?.currentSubscription?.dealerLevel ||
        formData?.subscription?.currentSubscription?.catLevel;
      let newPlans: Array<string> = [];
      newPlans.push(currentSubscription);
      if (formData?.addOns?.currentAddons?.length >= 1) {
        newPlans = newPlans.concat(formData?.addOns?.currentAddons);
      }
      let allCurrentSubscriptions: any = [];
      allCurrentSubscriptions.push(currentSubscription);
      if (formData?.addOns?.currentAddons?.length >= 1) {
        allCurrentSubscriptions = allCurrentSubscriptions.concat(formData?.addOns?.currentAddons);
      }

      const payloadObj: PaymentPreviewPayload = {
        assetMake: this.selectedAssetDetails?.make || '',
        assetSerialNumber: this.selectedAssetDetails?.serialNumber || '',
        deviceId: '',
        deviceSerialNumber: this.selectedAssetDetails?.deviceSerialNumer || '',
        deviceType: this.selectedAssetDetails?.deviceType || '',
        dealerCode: formData?.customer?.dealerCode,
        ucid: formData?.customer?.ucid || '',
        customerName: formData?.customer?.ucidName || '',
        dcnName: formData?.customer?.dealerCustName || '',
        dcnNumber: formData?.customer?.dealerCustNo || '',
        contractEndDate: formData?.prepay?.currentPrepayData?.contractEndDate,
        contractStartDate: formData?.prepay?.currentPrepayData?.contractStartDate,
        applicationName: formData?.service?.currentService,
        siteName: this.selectedAssetDetails?.siteName || '',
        subscriptions: allCurrentSubscriptions,
        cva: this.selectedAssetDetails?.cvaInfo?.cvaStatus == 'Available' ? true : false,
        prepay: formData?.prepay?.currentPrepayData?.prepaySelected,
        isNewSubscription: true,
        productFamily: this.selectedAssetDetails?.productFamily || null,
      };
      this.newApplicationName = payloadObj.applicationName;
      this.newCustSubs = formData?.subscription?.currentSubscription?.customerLevel || '';
      this.newDealerSubs = formData?.subscription?.currentSubscription?.dealerLevel || '';
      this.newCatSubs = formData?.subscription?.currentSubscription?.catLevel || '';

      if (isEmpty(formData?.newPaymentDetails?.new)) {
        this.loaderService.show();
        this.assetDrawerService.getPaymentPreview(payloadObj).subscribe({
          next: (response: any) => {
            this.loaderService.hide();
            this.assetPlanPaymentDetails = response;
            this.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
            this.mapNewBaseAndOptionalPlan(newPlans);
            this.checkForTrimNewPlans();
          },
          error: (_err: any) => {
            this.loaderService.hide();
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else {
        this.assetPlanPaymentDetails = formData?.newPaymentDetails;
        this.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
        this.mapNewBaseAndOptionalPlan(newPlans);
        this.checkForTrimNewPlans();
      }
    }
  }
  getNewSubscriptionDataForMultiProduct(formData: any) {
    this.newSubscribedPlansJson = [];
    for (const [productName, productValue] of Object.entries(formData?.products)) {
      const product: any = productValue;
      const allCurrentSubscriptions: any = [];
      if (
        (product?.subscription?.currentSubscription?.catLevel != '' ||
          product?.subscription?.previousSubscription?.catLevel != '') &&
        product?.subscription?.currentSubscription?.catLevel != 'None'
      ) {
        const app: any = {
          appName: '',
          plans: [],
          paymentMessage: '',
        };
        app.appName = productName;
        if (product?.subscription?.currentSubscription?.customerLevel != '') {
          const plan: any = {
            planName: product?.subscription?.currentSubscription?.customerLevel,
            type: 'Base',
            amount: 0.0,
          };
          app.plans.push(plan);
          allCurrentSubscriptions.push(product?.subscription?.currentSubscription?.customerLevel);
        }
        if (
          product?.subscription?.selectedSubscription?.customerLevel == '' &&
          product?.subscription?.currentSubscription?.customerLevel == '' &&
          product?.addOns?.currentAddons.length >= 1
        ) {
          const plan: any = {
            planName: product?.subscription?.previousSubscription?.customerLevel,
            type: 'Base',
            amount: 0.0,
          };
          app.plans.push(plan);
          allCurrentSubscriptions.push(product?.subscription?.previousSubscription?.customerLevel);
        }
        if (product?.addOns?.currentAddons.length >= 1) {
          product?.addOns?.currentAddons.forEach((addOnPlan: any) => {
            const plan: any = {
              planName: addOnPlan,
              type: 'Addon',
              amount: 0.0,
            };
            app.plans.push(plan);
            allCurrentSubscriptions.push(addOnPlan);
          });
        }
        if (isEmpty(product?.newPaymentDetails)) {
          this.loaderService.show();
          const payloadObj: any = {
            assetMake: this.selectedAssetDetails?.make || '',
            assetSerialNumber: this.selectedAssetDetails?.serialNumber || '',
            deviceId: '',
            deviceSerialNumber: this.selectedAssetDetails?.deviceSerialNumer || '',
            deviceType: this.selectedAssetDetails?.deviceType || '',
            dealerCode: formData?.customer?.dealerCode,
            ucid: formData?.customer?.ucid || '',
            customerName: formData?.customer?.ucidName || '',
            dcnName: formData?.customer?.dealerCustName || '',
            dcnNumber: formData?.customer?.dealerCustNo || '',
            contractEndDate: product?.prepay?.currentPrepayData?.contractEndDate
              ? this.dateFormattingService.formatDate(
                  new Date(product?.prepay?.currentPrepayData?.contractEndDate),
                  'YYYY/MM/DD'
                )
              : null,
            contractStartDate: product?.prepay?.currentPrepayData?.contractStartDate
              ? this.dateFormattingService.formatDate(
                  new Date(product?.prepay?.currentPrepayData?.contractStartDate),
                  'YYYY/MM/DD'
                )
              : null,
            applicationName: productName,
            siteName: this.selectedAssetDetails?.siteName || '',
            subscriptions: allCurrentSubscriptions,
            cva: this.selectedAssetDetails?.cvaInfo?.cvaStatus == 'Available' ? true : false,
            prepay: product?.prepay?.currentPrepayData?.prepaySelected
              ? product?.prepay?.currentPrepayData?.prepaySelected
              : false,
            isNewSubscription: true,
            productFamily: this.selectedAssetDetails?.productFamily || null,
          };
          this.assetDrawerService.getPaymentPreview(payloadObj).subscribe({
            next: (response: any) => {
              this.loaderService.hide();
              product.newPaymentDetails = response;
              this.paymentMessage = this.updateFooterMessage(product.newPaymentDetails);

              this.mapNewPlansMultiProduct(app, product);
            },
            error: (_err: any) => {
              this.loaderService.hide();
              this.showToastMessage(this.systemError, 'error');
            },
          });
        } else {
          this.mapNewPlansMultiProduct(app, product);
        }
      }
    }
  }
  mapNewPlansMultiProduct(app: any, product: any) {
    app.prepay = product?.prepay?.currentPrepayData?.prepaySelected;
    app.contractStartDate = product?.newPaymentDetails?.new?.products
      ? product?.newPaymentDetails?.new?.products[0]?.contractStartDate
      : product?.newPaymentDetails?.current?.products[0]?.contractStartDate;
    app.contractEndDate = product?.newPaymentDetails?.new?.products
      ? product?.newPaymentDetails?.new?.products[0]?.contractEndDate
      : product?.newPaymentDetails?.current?.products[0]?.contractEndDate;
    app.duration = product?.newPaymentDetails?.new?.products
      ? product?.newPaymentDetails?.new?.products[0]?.duration
      : product?.newPaymentDetails?.current?.products[0]?.duration;
    app.paymentMessage = this.updateFooterMessage(product?.newPaymentDetails);
    app.monthlyRateAfterDiscount = product?.newPaymentDetails?.new?.products
      ? product?.newPaymentDetails?.new?.monthlyRateAfterDiscount
      : product?.newPaymentDetails?.current?.monthlyRateAfterDiscount;
    const amount = product?.newPaymentDetails?.new?.products
      ? product?.newPaymentDetails?.new?.products
      : product?.newPaymentDetails?.current?.products;
    this.addAmountToPlans(app.plans, amount);
    const promotion = product?.newPaymentDetails?.new?.promotions
      ? product?.newPaymentDetails?.new?.promotions
      : product?.newPaymentDetails?.current?.promotions;
    if (promotion != null) {
      app.promotions = promotion;
    }
    this.newSubscribedPlansJson.push(app);
  }

  mapNewBaseAndOptionalPlan(plans: Array<string>) {
    this.newPlans = [];
    this.newOptionalPlans = [];
    for (let i = 0; i < plans.length; i++) {
      let planObj: any = {
        name: plans[i],
      };
      if (this.assetPlanPaymentDetails?.new?.products?.length > 0) {
        this.assetPlanPaymentDetails?.new?.products?.filter((plan: PaymentPreviewProduct) => {
          if (plans[i] == plan?.name) {
            planObj = Object.assign(planObj, plan);
          }
        });
      }
      if (i == 0) {
        planObj.type = 'Base Plan';
      } else {
        planObj.type = 'Optional Plan';
        this.newOptionalPlans.push(planObj);
      }
      this.newPlans.push(planObj);
    }
  }

  multiProductPaymentDetails() {
    this.subscribedPlansJson?.servicesSubscribedDTO.forEach((app: any) => {
      let prepayOption;
      if (this.vlCheck(app?.appName)) {
        prepayOption = app?.prepay;
      } else {
        prepayOption = false;
      }
      const getMultiProductPaymentPayload: any = {
        assetMake: this.selectedAssetDetails?.make || '',
        assetSerialNumber: this.selectedAssetDetails?.serialNumber || '',
        deviceId: '',
        deviceSerialNumber: this.selectedAssetDetails?.deviceSerialNumer || '',
        deviceType: this.selectedAssetDetails?.deviceType || '',
        dealerCode: this.dealer?.dealerCode,
        ucid: this.selectedAssetDetails?.ownershipDetails[0]?.ucid || '',
        customerName: this.selectedAssetDetails?.ownershipDetails[0]?.ucidName || '',
        dcnName: this.selectedAssetDetails?.ownershipDetails[0]?.dealerCustName || '',
        dcnNumber: this.selectedAssetDetails?.ownershipDetails[0]?.dealerCustNo || '',
        contractStartDate:
          app?.contractStartDate && this.vlCheck(app?.appName)
            ? this.dateFormattingService.formatDate(new Date(app?.contractStartDate), 'YYYY/MM/DD')
            : null,
        contractEndDate:
          app?.contractEndDate && this.vlCheck(app?.appName)
            ? this.dateFormattingService.formatDate(new Date(app?.contractEndDate), 'YYYY/MM/DD')
            : null,
        duration:
          this.selectedAssetDetails?.subscribedPlansJson?.duration && this.vlCheck(app?.appName)
            ? this.selectedAssetDetails?.subscribedPlansJson?.duration
            : null,
        applicationName: app?.appName || '',
        siteName: this.handleSiteNameAdd(),
        prepay: prepayOption,
        productFamily:
          this.selectedAssetDetails?.subscribedPlansJson?.productFamily && this.minestarCheck(app)
            ? this.selectedAssetDetails?.subscribedPlansJson?.productFamily
            : null,
        isViewSubscription: true,
      };

      this.multiProductPaymentPayload(getMultiProductPaymentPayload, app);
    });
  }

  multiProductPaymentPayload(getMultiProductPaymentPayload: any, app: any) {
    if (this.isNewSubscription) {
      this.isViewSubscription = false;
    }
    if (this.isViewSubscription) {
      this.loaderService.show();
      this.assetDrawerService.getPaymentPreview(getMultiProductPaymentPayload).subscribe({
        next: (response: any) => {
          this.loaderService.hide();
          this.assetPlanPaymentDetails = response;
          this.selectedAssetDetails.paymentDetails = this.assetPlanPaymentDetails;
          app.monthlyRateAfterDiscount = this.assetPlanPaymentDetails?.current?.monthlyRateAfterDiscount;
          app.paymentMessage = this.updateFooterMessage(this.assetPlanPaymentDetails);
          this.addAmountToPlans(app.customerLevel, this?.assetPlanPaymentDetails?.current?.products);
          this.addAmountToPlans(
            this.telUserType == 'CAT' ? app?.catLevel : app?.dealerLevel,
            this?.assetPlanPaymentDetails?.current?.products
          );
          app.promotions = response?.current?.promotions;
          app.contractStartDate = response?.current?.products[0]?.contractStartDate;
          app.contractEndDate = response?.current?.products[0]?.contractEndDate;
          app.duration = response?.current?.products[0]?.duration;
        },
        error: (_err: any) => {
          this.loaderService.hide();
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }

  addAmountToPlans(plans: any, products: any) {
    if (products && plans) {
      for (let i = 0; i < plans?.length; i++) {
        for (let j = 0; j < products?.length; j++) {
          if (plans[i]?.planName == products[j]?.name) {
            plans[i].amount = products[j].amount;
            break;
          }
        }
      }
    }
  }

  openDrawerInMultiSizeForMultiProduct(appName: any, products: any) {
    this.addtionalPlanDetails = this.drawerService.openDrawer<AdditionalPlanPaymentDetailsComponent, CcDrawerConfig>(
      AdditionalPlanPaymentDetailsComponent,
      {
        data: {
          rowData: products,
          applicationName: appName,
          searchType: this.selectedAssetDetails?.searchType,
          showRatePlans: !this.selectedAssetDetails?.isMaskRequired,
          isMultiProductWorkFlow: this.isMultiProductWorkFlow,
          seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: false,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }

  checkForPlanTrim(name: any) {
    let planNameWithTrim: any;
    if (name.length > 32) {
      planNameWithTrim = name.slice(0, 32) + '…';
      return planNameWithTrim;
    } else {
      return name;
    }
  }

  copyEvent(event: any, planDetails: any, fieldName: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEventFunc(event, clipboardText, ':', planDetails, 33, fieldName);
  }
  customUserCheck() {
    if (
      this.userActiveFeatures &&
      this.userActiveFeatures?.toString()?.indexOf('Subscription Management') > -1 &&
      this.userActiveFeatures?.toString()?.indexOf('Read Only') <= -1 &&
      this.userActiveFeatures?.toString()?.indexOf('View Only') <= -1 &&
      this.userActiveFeatures?.toString()?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') <= -1
    ) {
      return false;
    } else {
      return true;
    }
  }
}
