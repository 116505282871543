<div>
  <!-- {{ dealer.dealerCode }} -->
  <div class="img">
    <img class="no-results-notfound-image" src="..\assets\images\child-dealer.svg" />
  </div>

  <div *ngIf="!isRemoveBillingInquiryButton">
    <div class="para">
      Billing for this dealership is included within digital invoices issued to parent dealer
      {{ invoiceParentDealerCode }}. For further information, please contact your parent dealer or
      <span class="line">submit a request using the DSP Billing form.</span>
    </div>
    <div class="billing-inquiry">
      <a cc-btn-primary cc-btn-large class="primary button-opacity" [setTheme]="true" (click)="billingInquiryForm()">
        Billing Inquiry</a
      >
    </div>
  </div>
  <div *ngIf="isRemoveBillingInquiryButton">
    <div class="para">
      Billing for this dealership is included within digital invoices issued to parent dealer
      {{ invoiceParentDealerCode }}. For further information, please contact your parent dealer.
    </div>
  </div>
</div>
