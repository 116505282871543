<div class="dealer-action-required-modal">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title modal-title">Select Cancellation Reason</div>
      <div class="cancel-icon">
        <span class="state-pipe">|</span>
        <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeCancelAllPopUp(false)">
          <cc-icon fontSet="icomoon">cross</cc-icon>
        </div>
      </div>
    </div>
    <div class="cc-modal-content cancel-modal">
      <div class="modal-body-content" id="action-required-message">
        <div class="basic-radio" *ngIf="cancelDetails?.length > 0">
          <div class="reason-label">PLEASE SELECT THE CANCELLATION REASON:</div>
          <cc-radio-group
            aria-label="Select cancel option"
            class="canceloption cancelreason"
            [(ngModel)]="selectedOption"
          >
            <cc-radio-button
              *ngFor="let option of cancelDetails"
              [value]="option?.reason"
              class="canceloption cancelreason"
              (valueChange)="selectedOption = option?.reason; handleCancelReasonSelection(option)"
            >
              <div class="making">
                <span class="reason">{{ option.reason }}</span
                ><br />
                <span class="description">{{ option.description }}</span>
              </div>
            </cc-radio-button>
          </cc-radio-group>
          <div class="alert-msg" *ngIf="showWarning && subsPricingEligible && this.footerMessage">
            <span><img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" /></span>
            <span class="cancelNote" [innerHTML]="footerMessage"></span>
          </div>
          <!-- <div class="Row" *ngIf="showWarning && subsPricingEligible && this.footerMessage">
            <div class="cancelNoteCell">
              <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
              <div class="cancelNote" [innerHTML]="footerMessage"></div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-body-footer"></div>
    </div>
    <div class="cc-modal-footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeCancelAllPopUp(false)">Cancel</button>
      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        (click)="closeCancelAllPopUp(true)"
        [disabled]="submitDisable"
      >
        Submit
      </button>
    </div>
  </div>
</div>
