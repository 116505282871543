<div id="mainFilterCls" class="cc-drawer">
  <div
    class="cc-drawer__header"
    [ngClass]="{
      'cc-drawer__withActions': headerContent === 'withActions'
    }"
  >
    <ng-container *ngIf="headerContent === 'withActions'" [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <cc-accordion>
      <div *ngFor="let filterList of accordionListColumns">
        <cc-expandable
          [id]="filterList.field"
          [expanded]="filterList.subFilters ? false : expandDropdown ? true : false"
          [noExpandableContent]="filterList.subFilters"
          (click)="openSubFilterAccordion(filterList.subFilters, filterList.label)"
          (opened)="
            loadFilterColumnData(filterList.subFilters, filterList.field, filterList.label, filterList.formControl)
          "
          (closed)="accordionClosed(filterList.field)"
        >
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ filterList.label }}</span>
              <span class="selectedLabel" *ngIf="filterList && header && header.savedFilters">
                <span
                  *ngIf="filterList.label === 'Product | Plan' && filterList.subFilters[0].selectedValues.length > 0"
                >
                  ( {{ filterList.subFilters[0].selectedValues.length }} selected )</span
                >
                <span *ngIf="!filterList.subFilters && filterList.selectedValues.length > 0">
                  (
                  {{
                    filterList.formControl === 'date' || filterList.formControl === 'promotionContractDate'
                      ? 1
                      : filterList.selectedValues.length
                  }}
                  selected )</span
                >
              </span>
              <div
                class="chipList"
                *ngIf="
                  selectedFilters[filterList.field] &&
                  filterList.formControl !== 'date' &&
                  filterList.formControl !== 'promotionContractDate'
                "
              >
                <ng-container *ngFor="let filter of selectedFilters[filterList.field]">
                  <span
                    *ngIf="isAccordionClosed[filterList.field]"
                    class="chips"
                    [cc-chip]="filterList.label === 'Ownership Type' ? (filter | convertOwnership) : filter"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipText]="filter.length < 100 ? filter : ''"
                    (deleteItem)="onDeleteChip($event, filterList, filter)"
                    [type]="'cross'"
                  ></span>
                  <ng-template #tooltipTemplate>
                    <div
                      [ngClass]="{
                        'tooltip-truncate': filter.length > 100
                      }"
                    >
                      {{ filter }}
                    </div>
                  </ng-template>
                </ng-container>
              </div>

              <ng-container *ngFor="let filter of dateWithQueueReasonId">
                <div
                  class="chipList"
                  *ngIf="
                    filter.id === filterList.field &&
                    filter.dateVal[0] &&
                    (filterList.formControl === 'date' || filterList.formControl === 'promotionContractDate') &&
                    header.pageLabel === 'needsReview'
                  "
                >
                  <span
                    *ngIf="isAccordionClosed[filterList.field]"
                    class="chips"
                    [cc-chip]="filterDateToString(filter)"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipText]="filterDateToString(filter).length < 100 ? filterDateToString(filter) : ''"
                    [type]="'cross'"
                  ></span>
                  <ng-template #tooltipTemplate>
                    <div
                      [ngClass]="{
                        'tooltip-truncate': filterDateToString(filter).length > 100
                      }"
                    >
                      {{ filterDateToString(filter) }}
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngFor="let filter of dateArray">
                <div
                  class="chipList"
                  *ngIf="
                    filter.id === filterList.field &&
                    filter.dateVal[0] &&
                    (filterList.formControl === 'date' || filterList.formControl === 'promotionContractDate') &&
                    header.pageLabel !== 'needsReview' &&
                    header.pageLabel !== 'billing'
                  "
                >
                  <span
                    *ngIf="isAccordionClosed[filterList.field]"
                    class="chips"
                    [cc-chip]="filterDateToString(filter)"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipText]="filterDateToString(filter).length < 100 ? filterDateToString(filter) : ''"
                    [type]="'cross'"
                  ></span>
                  <ng-template #tooltipTemplate>
                    <div
                      [ngClass]="{
                        'tooltip-truncate': filterDateToString(filter).length > 100
                      }"
                    >
                      {{ filterDateToString(filter) }}
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngFor="let filter of dateWithInvoiceNumber">
                <div
                  class="chipList"
                  *ngIf="
                    filter.id === filterList.field &&
                    filter.dateVal[0] &&
                    (filterList.formControl === 'date' || filterList.formControl === 'promotionContractDate') &&
                    header.pageLabel === 'billing'
                  "
                >
                  <span
                    *ngIf="isAccordionClosed[filterList.field]"
                    class="chips"
                    [cc-chip]="filterDateToString(filter)"
                    [tooltipTemplate]="tooltipTemplate"
                    [tooltipText]="filterDateToString(filter).length < 100 ? filterDateToString(filter) : ''"
                    [type]="'cross'"
                  ></span>
                  <ng-template #tooltipTemplate>
                    <div
                      [ngClass]="{
                        'tooltip-truncate': filterDateToString(filter).length > 100
                      }"
                    >
                      {{ filterDateToString(filter) }}
                    </div>
                  </ng-template>
                </div>
              </ng-container>
              <cc-icon
                *ngIf="filterList.subFilters"
                class="drawer-icon"
                [ngClass]="filterList.subFilters ? 'sub-filters' : ''"
                [iconName]="'accordion-expandable-icon'"
                [inline]="true"
              >
              </cc-icon>
            </div>
          </cc-expandable-header>
          <div
            *ngIf="
              !filterList.formControl &&
              !isDataLoading &&
              filterColumData &&
              filterColumData[filterList.field] &&
              filterColumData[filterList.field][0] &&
              filterColumData[filterList.field][0].length
            "
          >
            <ng-container *ngFor="let filter of filterColumData[filterList.field][0].slice(0, 8)">
              <div class="data-display">
                <cc-checkbox
                  [checked]="filterList.selectedValues.indexOf(filter) > -1"
                  (toggleChange)="getSelectedFilter($event, filterList, filter)"
                >
                  <span
                    class="text-wrap"
                    [innerHTML]="
                      filterList.label === 'Ownership Type'
                        ? (filter | convertOwnership)
                        : (filter | highlightSearch: searchString)
                    "
                  ></span>
                </cc-checkbox>
              </div>
            </ng-container>
          </div>
          <div
            class="spinnerCls"
            *ngIf="
              !filterList.subFilters &&
              filterList.formControl !== 'date' &&
              filterList.formControl !== 'promotionContractDate' &&
              filterColumData &&
              filterColumData[filterList.field] &&
              (filterColumData[filterList.field].length === 0 ||
                (filterColumData[filterList.field][0] && filterColumData[filterList.field][0].length === 0)) &&
              !isDataLoading
            "
          >
            No Data Available
          </div>
          <ng-container *ngIf="filterList.formControl === 'date' || filterList.formControl === 'promotionContractDate'">
            <dsp-next-gen-ui-common-date-time
              [events]="eventsSubject.asObservable()"
              [dateId]="filterList.field"
              [selectedFormControl]="filterList.formControl"
              (dateFilterValue)="setFilterDate($event, filterList.field)"
              [pageLabel]="header.pageLabel"
              [queueReasonId]="header.queueReasonId"
            ></dsp-next-gen-ui-common-date-time>
          </ng-container>
          <div
            class="category-show-all"
            *ngIf="
              !isDataLoading &&
              filterColumData &&
              filterColumData[filterList.field] &&
              filterColumData[filterList.field][0] &&
              filterColumData[filterList.field][0].length > 8 &&
              filterList.formControl !== 'date' &&
              filterList.formControl !== 'promotionContractDate'
            "
            (click)="showAllData('withActions', filterColumData, filterList)"
          >
            Show All
          </div>
          <div
            class="spinnerCls"
            *ngIf="
              isDataLoading && filterList.formControl !== 'date' && filterList.formControl !== 'promotionContractDate'
            "
          >
            <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
          </div>
        </cc-expandable>
      </div>
    </cc-accordion>
  </div>
  <div class="cc-drawer__footer">
    <button
      cc-btn-primary
      cc-btn-large
      style="width: 100% !important"
      class="primary button-opacity viewAssetsBtn"
      [setTheme]="true"
      [disabled]="false"
      (click)="loadAssets()"
    >
      <span>
        View
        {{ headerTitle | titlecase }}
      </span>
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <span class="cc-drawer__ellipsis filters-title"> Filters </span>
  <span class="filters-clear" (click)="clearFilters()">Clear All</span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal(false)">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
