import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  TemplateRef,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import {
  CcDrawerRef,
  CcModal,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
  modalType,
} from '@cat-digital-workspace/shared-ui-core';
import { cloneDeep, findIndex, hasIn, includes, isEmpty, remove, some } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AssetSubscriptionFormDataType, PrepayContractInfo, SubscriptionData } from '../../../models/assets.interface';
import { dealerType } from '../../../models/shared.interface';
import { AssetDrawerService } from '../../../services/asset-drawer.service';
import { fetchLoggedInDealerDetails, sortOwnershipRecords } from '../../../shared/shared';
import { FilterAssetsV2Payload } from '../asset-drawer/asset-drawer-interface';
import { AssetService } from '../services/asset.service';
import * as dspConstants from '../../../shared/dspConstants';
import { DSPAppState } from '../../../store/state/dsp.state';
import { PaymentPreviewMenuComponent } from './payment-preview-menu/payment-preview-menu.component';
import { DealerActionRequiredComponent } from './dealer-action-required/dealer-action-required.component';
import { SelectCancellationReasonMenuComponent } from './select-cancellation-reason-menu/select-cancellation-reason-menu.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { AssetAgreementStatus } from '../asset-interface';
import { forEach } from '@angular-devkit/schematics';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import { CancelAllPlansComponent } from './cancel-all-plans/cancel-all-plans.component';
import { getAssetDataSubscribedPlansEditResponse } from '../../../store/actions/dspcommon.action';

@Component({
  selector: 'dsp-next-gen-ui-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManagePlanComponent implements OnInit, OnDestroy {
  @ViewChild('b2cCustWarningTemplate') b2cCustWarningTemplate!: TemplateRef<any>;
  @ViewChild(PaymentPreviewMenuComponent) paymentPreview!: PaymentPreviewMenuComponent;
  @ViewChild(SelectCancellationReasonMenuComponent) cancelSubscription!: SelectCancellationReasonMenuComponent;
  @ViewChild('cancelTemplateSuspendRef') cancelSubscriptionModalSuspendTemplate!: TemplateRef<any>;
  @ViewChild('cancelTemplatePrepayRef') cancelSubscriptionModalPrepayTemplate!: TemplateRef<any>;
  @ViewChild('prePayApplyRef') prePayApplyTemplate!: TemplateRef<any>;
  @ViewChild('EC520TemplateRef') EC520MessageTemplate!: TemplateRef<any>;
  @ViewChild('ccsMessage') ccsMessage!: TemplateRef<any>;
  isTheme = '2.0';
  dealer!: dealerType;
  modalType: modalType = 'semi-modal';
  currentPageIndex = 0; // for UI components
  trackerIndex = 0; // for progress tracker
  isNextDisabled = true;
  isBillingToShow = false;
  isEditFlow = false;
  cancelScreen = false;
  assetDealerInfo: any;
  cancelAllPlansSelected = false;
  billingTypeStatus: any;
  catLevelStruct: any = [{}];
  customerLevelStruct: any = [{}];
  dealerLevelStruct: any = [{}];
  multiProductServiceSubscribed: any = [{}];
  multiProductValidation: any = [{}];
  isAssetDealerInfoRetrieved!: boolean;
  trackerData = [
    {
      label: 'Select Ownership',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Select Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  dynamicTrackerData: any;
  disableNext = true;
  launchType = '';
  selectedRowData: any;
  assetAgreementStatus!: AssetAgreementStatus;
  products: any = {
    service: {
      currentService: '',
      previousService: '',
      selectedService: '',
    },
    appId: '',
    subscription: {
      currentSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      previousSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      selectedSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
    },
    addOns: {
      currentAddons: [],
      previousddons: [],
      selectedAddons: [],
    },
    prepay: {
      currentPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      previousPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      selectedPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
    },
    catBillingData: {
      billingGroupId: '',
    },
    productFamily: {
      currentProductFamily: '',
      previousProductFamily: '',
      selectedProductFamily: '',
    },
    selectedCategory: '',
    siteInfo: {
      siteName: '',
      siteId: '',
    },
    previousSiteInfo: {
      siteName: '',
      siteId: '',
    },
    reportingState: '',
    selectedSiteInfo: {
      siteName: '',
      siteId: '',
    },
    expandedService: '',
    isFeatureMsgSupported: '',
  };
  productList: any;
  selectedAssetSubscriptionFormData: any = {
    cancelReasonSelected: false,
    isValidForm: false,
    customer: {},
    products: {
      MineStar: cloneDeep(this.products),
      'Equipment Insights': cloneDeep(this.products),
      'New VisionLink': cloneDeep(this.products),
      'Cat Remote Fleet Vision': cloneDeep(this.products),
      'VIMS Transmitter': cloneDeep(this.products),
      'China Fleet View': cloneDeep(this.products),
      'Remote Asset Monitoring': cloneDeep(this.products),
    },
  };

  systemError = dspConstants.Common.SYSTEM_ERROR;
  setFilterAssetsFlag = true;
  setAssetOwnershipFlag = true;
  b2cCustomers: any = {};
  assetFormSubscription!: any;
  billingPopUpInfo: any = [];
  isDealerActionRequiredPopupAgreed = false;
  userActiveFeatures: any;
  userPreferences: any;
  catalogMapping: any;
  serviceMapping: any;
  assetId: any;
  subsPricingEligible = false;
  ec520Message = '';
  languageTranslations: any = [];
  telUserType = '';
  billingAccounts: any;
  warningModalRef!: any;
  ccsWarningMessage = '';
  ucidConsolidatedWarningMessage = '';
  catOnlyAddons: any = [];
  getNewSubscriptionData = false;
  isRedirectToSitePage!: boolean;
  modalRef: any;
  prepayOption: any;
  searchType: any;
  dspConfig: any;
  hasPartialOwnershipData = false;
  partialOwnershipData: any = [];
  completeOwnershipData: any = [];
  addOnsMulti: any = [];
  selectedValues: any = [];
  isOwnershipApiCallInProgress = false;
  isNonBillableCust = true;
  noneSelected = false;
  showB2CWarningPopupInPaymentPreview = false;
  dspStoreData: any;
  isShowPriceIfNoBilling = false;
  storedData: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public assetRowData: any,
    public manageAssetPlanContainerRef: CcDrawerRef<ManagePlanComponent>,
    public assetService: AssetService,
    public assetDrawerService: AssetDrawerService,
    public messageBar: MessageBar,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    private needsReviewService: NeedsReviewService,
    public dspUtilsService: DspUtilsCommonService
  ) {
    this.getApplicableSubscriptions();
  }
  /**
   * getApplicableSubscriptions()--> this is used to call the getApplicableSubscriptions API to fetch the response.
   */
  getApplicableSubscriptions() {
    this.assetDrawerService.getListOfProduct().subscribe({
      next: (result: any) => {
        this.productList = result?.products;
        for (const item of result?.products) {
          this.selectedAssetSubscriptionFormData.products[item.name] = cloneDeep(this.products);
        }
      },
      error: (err: any) => {},
    });
  }
  ngOnInit(): void {
    this.assetService.listOfProducts = '';
    this.assetService.listOfSubsData = '';
    this.assetService.minstarSiteNameDisabled = '';
    this.assetService.lastUpdatedTime = '';
    this.assetService.listOfPricingDetails = {
      priceDetails: '',
      onloadData: '',
    };
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.billingPopUpInfo = JSON.parse(dsp.dspConfig?.['billingPopUpInfo']) || [];
      this.catOnlyAddons = JSON.parse(dsp.dspConfig?.['CAT_Only_Addon']) || [];
      this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
      this.userPreferences = dsp.userActiveFeatures?.userPreferences || [];
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.catalogMapping = dsp.deviceCatalog || {};
      this.serviceMapping = dsp.serviceMapping || {};
      this.languageTranslations = dsp.languageTranslations || {};
      this.billingAccounts = dsp.billingAccounts?.accountDetails || [];
      this.dspConfig = dsp.dspConfig || {};
      this.showB2CWarningPopupInPaymentPreview = dsp?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
        ? dsp?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
        : false;
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.storedData = this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        ? this.dspStoreData?.getAssetDataSubscribedPlansEditResponse
        : {};
    });
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    if (!this.subsPricingEligible) {
      const val = this.trackerData;
      this.trackerData = val.filter((ele) => ele.label != 'Payment Preview');
    }
    this.dynamicTrackerData = cloneDeep(this.trackerData);
    this.dealer = fetchLoggedInDealerDetails();
    this.launchType = this.assetRowData.type;
    this.selectedRowData = this.assetRowData?.selectedAssetDetails
      ? cloneDeep(this.assetRowData.selectedAssetDetails)
      : {};
    this.searchType = this.selectedRowData?.searchType || dspConstants.Worklist.GLOBAL_SEARCH;

    this.setEditSubscriptionChanges();
    this.setAssetData();
    this.ccsWarningMessage = this.languageTranslations?.Worklist?.WL_CCS_MESSAGE || '';
    this.ucidConsolidatedWarningMessage = this.languageTranslations?.Worklist?.WL_CONSOLIDATED_MESSAGE || '';
    this.assetFormSubscription = this.assetService.assetSubscriptionFormEvent.subscribe(
      (data: AssetSubscriptionFormDataType) => {
        this.selectedAssetSubscriptionFormData = data;
        this.handleProgressTrackerUpdate();
      }
    );
    this.getAssetAgreement();
  }

  /**
   * Updates the progress tracker based on the current subscription state of products.
   *
   * This method processes the selectedAssetSubscriptionFormData to identify which products
   * are subscribed and which are unsubscribed. It then checks the customer level for each
   * subscribed product and updates the dynamicTrackerData accordingly. If all subscribed
   * products have a customer level of 'None', it evaluates whether to add or remove the
   * 'Payment Preview' and 'Select Cancellation Reason' steps from the tracker.
   *
   * If the cancellation screen is active and no cancellation reason is selected,
   * it triggers the loadCancellationScreen method.
   *
   * Additionally, it handles the logic for managing the dynamic tracker data based on
   * the eligibility for subscription pricing and the current state of the progress tracker.
   */
  handleProgressTrackerUpdate() {
    let unsubscribeData: any = [];
    let newSubscribedFormData: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (productObj.reportingState === 'Subscribed') {
        newSubscribedFormData[key] = value;
      } else {
        unsubscribeData.push(value);
      }
    }
    let index2 = 0;
    for (const [key, value] of Object.entries(newSubscribedFormData)) {
      let productValue: any = value;
      if (productValue?.subscription?.currentSubscription?.customerLevel === 'None') {
        index2++;
        if (index2 === Object.entries(newSubscribedFormData).length) {
          if (
            unsubscribeData.some(
              (item: any) =>
                item.subscription?.currentSubscription?.customerLevel !== '' &&
                item.subscription?.currentSubscription?.customerLevel !== 'None'
            )
          ) {
            const isPaymentAdded = this.checkTrackerData('Payment Preview');
            if (!isPaymentAdded && this.checkTrackerData('Select Cancellation Reason')) {
              remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Cancellation Reason');
              if (this.subsPricingEligible) {
                const paymentPreviewObject = {
                  label: 'Payment Preview',
                  disabled: false,
                  clickable: false,
                  warning: false,
                  danger: false,
                  description: '',
                };
                this.dynamicTrackerData.push(paymentPreviewObject);
              }
            }
          } else {
            const isPaymentAdded = this.checkTrackerData('Payment Preview');
            const isCancelAdded = this.checkTrackerData('Select Cancellation Reason');
            if (isPaymentAdded) {
              remove(this.dynamicTrackerData, (row: any) => row.label === 'Payment Preview');
            }
            if (!isCancelAdded) {
              const cancelPageObj = {
                label: 'Select Cancellation Reason',
                disabled: false,
                clickable: false,
                warning: false,
                danger: false,
                description: '',
              };
              this.dynamicTrackerData.push(cancelPageObj);
            }
          }
        }
      } else {
        const isPaymentAdded = this.checkTrackerData('Payment Preview');
        if (!isPaymentAdded && this.checkTrackerData('Select Cancellation Reason')) {
          remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Cancellation Reason');
          if (this.subsPricingEligible) {
            const paymentPreviewObject = {
              label: 'Payment Preview',
              disabled: false,
              clickable: false,
              warning: false,
              danger: false,
              description: '',
            };
            this.dynamicTrackerData.push(paymentPreviewObject);
          }
        }
      }
    }
    if (this.cancelScreen == true && this.selectedAssetSubscriptionFormData.cancelReasonSelected === false) {
      this.loadCancellationScreen();
    } else if (this.cancelScreen == false) {
      // need to add
    }
  }
  /**
   * Updates the ownership tracker data based on the edit flow state.
   *
   * If the current flow is an edit flow and the 'Select Ownership' step
   * is present in the dynamic tracker data, this method removes that step
   * from the tracker. This is useful for maintaining the correct flow of
   * the ownership selection process during editing.
   */
  updateOwnershipTrackerData() {
    this.isEditFlow &&
      this.checkTrackerData('Select Ownership') &&
      remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Ownership');
  }
  /**
   * Checks if a specific label exists in the dynamic tracker data.
   *
   * This method searches through the `dynamicTrackerData` array to see if an entry
   * with the given label value exists. It uses the `some` function for efficient
   * checking, returning true if at least one matching entry is found.
   *
   * This is useful for determining the flow of the application, allowing conditional
   * logic based on the presence of specific tracker steps.
   *
   * @param {string} labelValue - The label to search for in the dynamic tracker data.
   * @returns {boolean} - Returns true if the label exists, false otherwise.
   */
  checkTrackerData(labelValue: string) {
    return some(this.dynamicTrackerData, ['label', labelValue]);
  }
  /**
   * Checks if the current addons include any that are classified as "cat-only".
   *
   * This method takes an array of addon strings as input and determines whether
   * any of these addons are present in the `catOnlyAddons` array. It uses the
   * `some` method to efficiently check for at least one matching entry.
   *
   * This is useful for validating addon selections and ensuring that only
   * relevant addons are processed in contexts where "cat-only" addons are
   * applicable.
   *
   * @param {string[]} currentAddons - An array of addon strings to check against
   *                                    the cat-only addons.
   * @returns {boolean} - Returns true if at least one cat-only addon is found,
   *                      otherwise returns false.
   */
  hasCatOnlyAddOns(currentAddons: string[]) {
    return currentAddons.some((addOn) => this.catOnlyAddons.includes(addOn));
  }

  ngOnDestroy(): void {
    this.assetFormSubscription?.unsubscribe();
  }
  /**
   * Sets the edit flow for subscription changes based on the asset's reporting state.
   *
   * This method checks if the selected asset's reporting state is 'Subscribed'.
   * If it is, it sets the `isEditFlow` flag to true, indicating that the system
   * is in an editing mode. Additionally, it removes the 'Select Ownership'
   * step from the `dynamicTrackerData` to reflect the current editing context.
   *
   * This adjustment helps maintain the correct flow in the user interface by
   * ensuring that irrelevant steps are no longer presented to the user during
   * the edit process.
   */
  setEditSubscriptionChanges() {
    if (this.assetRowData?.selectedAssetDetails?.reportingState == 'Subscribed') {
      this.isEditFlow = true;
      remove(this.dynamicTrackerData, (row: any) => {
        return row.label === 'Select Ownership';
      });
    }
  }
  /**
   * Sets asset data based on the current launch type and flow state.
   *
   * This method performs different actions depending on the value of `launchType`
   * and whether the application is in edit mode:
   *
   * - If the `launchType` is 'LANDING':
   *   - It sets flags for filtering and ownership to false and loads asset and
   *     ownership data.
   *
   * - If the application is in edit flow:
   *   - It retrieves dealer information and sets the ownership data using
   *     the selected asset's ownership details.
   *
   * - If the `launchType` is 'DRAWER':
   *   - It checks if there are ownership details available and processes them.
   *     This includes filtering ownership records and checking certain conditions
   *     based on feature flags. If applicable, it also checks the select ownership
   *     section and clones the relevant ownership details into `selectedRowData`.
   *
   * This method ensures that the asset data is accurately loaded and processed
   * based on the context of the application, allowing for dynamic adjustments
   * in the user interface.
   */
  setAssetData() {
    if (this.launchType == 'LANDING') {
      this.setFilterAssetsFlag = false;
      this.setAssetOwnershipFlag = false;
      this.loadAssetAndOwnershipData();
    } else if (this.isEditFlow) {
      this.getDealerInfo();
      this.setOwnershipData(this.assetRowData.selectedAssetDetails?.ownershipDetails || {});
    } else if (this.launchType == 'DRAWER') {
      if (this.assetRowData?.selectedAssetDetails?.ownershipDetails?.length > 0) {
        this.assetRowData.selectedAssetDetails.ownershipDetails = this.filterOwnershipRecords(
          this.assetRowData.selectedAssetDetails.ownershipDetails
        );
        this.assetRowData.selectedAssetDetails.ownershipDetails = this.checkDCNFlagCondition(
          this.assetRowData.selectedAssetDetails.ownershipDetails
        );
        if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership) {
          this.checkSelectOwnershipSection(this.assetRowData.selectedAssetDetails.ownershipDetails);
        }
        this.selectedRowData.ownershipDetails = cloneDeep(this.assetRowData.selectedAssetDetails.ownershipDetails);
      }
    }
  }
  /**
   * Constructs the payload for filtering asset data based on the search type.
   *
   * Returns an object with dealer code, make, and serial number. The payload
   * also includes flags based on whether the search type is `NO_DEVICE_SEARCH`
   * to indicate if ownership information is required.
   *
   * @returns {Object} - The payload for filtering assets.
   */
  setFilterAssetsPayload() {
    if (this.searchType === dspConstants.Worklist.NO_DEVICE_SEARCH) {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make || '',
        serialNumber: this.selectedRowData?.serialNumber || '',
        isOwnershipInfoReq: true,
        flag: true,
      };
    } else {
      return {
        dealerCode: this.dealer?.dealerCode || '',
        make: this.selectedRowData?.make,
        serialNumber: this.selectedRowData?.serialNumber,
        flag: false,
      };
    }
  }
  /**
   * Retrieves filtered assets based on the current search criteria.
   *
   * This method initiates an API call to fetch filtered assets using the
   * constructed payload. If the call is successful, it updates the selected
   * row data and sets related properties based on the result. In case of
   * an error, it resets the loading state and displays an error message.
   */
  getFilteredAssets() {
    this.isOwnershipApiCallInProgress = true;
    const filteredAssetsPayloadObj: FilterAssetsV2Payload = this.setFilterAssetsPayload();
    this.assetDrawerService.filteredAssets(filteredAssetsPayloadObj).subscribe({
      next: (result: any) => {
        if (!isEmpty(result)) {
          this.selectedRowData = Object.assign(this.selectedRowData, result);
          if (this.selectedRowData.reportingState == dspConstants.Worklist.SUBSCRIBED) {
            this.selectedRowData.applicationName =
              this.serviceMapping[this.selectedRowData?.dealerSubscriptionId].appName;
          }
          if (this.searchType == dspConstants.Worklist.NO_DEVICE_SEARCH) {
            this.setOwnershipInfoForNoDevice();
          }
          this.getDealerInfo();
          this.setFilterAssetsFlag = true;
        }
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.getDealerInfo();
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  /**
   * Retrieves dealer information for the selected asset if not already retrieved.
   *
   * This method checks if dealer info has been fetched and if the dealer name
   * is empty. If conditions are met, it makes an API call to get dealer info
   * and updates the relevant properties. In case of an error, it resets the
   * dealer info and still maps the dealer name.
   */
  getDealerInfo() {
    if (
      !this.isAssetDealerInfoRetrieved &&
      this.selectedRowData?.reportingState &&
      isEmpty(this.selectedRowData?.dealerName)
    ) {
      const dealerInfoPayload = {
        assetIds: [this.selectedRowData?.assetId] || [],
        dealerCode: this.dealer?.dealerCode || '',
      };
      this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
        next: (result: any) => {
          if (result) {
            this.assetDealerInfo = result;
            this.isAssetDealerInfoRetrieved = true;
            this.mapDealerName();
          }
        },
        error: (err: any) => {
          this.assetDealerInfo = {};
          this.isAssetDealerInfoRetrieved = true;
          this.mapDealerName();
        },
      });
    }
  }
  /**
   * Maps the dealer name to the selected row data based on the dealer code.
   *
   * This method checks if the selected asset is subscribed and has a dealer code.
   * If so, it assigns the corresponding dealer name from the asset dealer info
   * to the selected row data.
   */
  mapDealerName() {
    if (
      this.selectedRowData?.reportingState == dspConstants.Worklist.SUBSCRIBED &&
      !isEmpty(this.selectedRowData?.dealerCode)
    ) {
      if (hasIn(this.assetDealerInfo, this.selectedRowData?.dealerCode)) {
        this.selectedRowData.dealerName = this.assetDealerInfo[this.selectedRowData?.dealerCode];
      }
    }
  }
  /**
   * Loads filtered asset and ownership data based on the current search type.
   *
   * This method retrieves filtered assets and, if the search type is not
   * `NO_DEVICE_SEARCH`, also fetches ownership information.
   */
  loadAssetAndOwnershipData() {
    this.getFilteredAssets();
    if (this.searchType != dspConstants.Worklist.NO_DEVICE_SEARCH) {
      this.fetchOwnershipInfo();
    }
  }
  /**
   * Sets ownership information for assets with no device.
   *
   * This method processes the equipment owner data, applying filters and conditions.
   * It updates the selected row's ownership details and sets relevant flags.
   * If in edit flow, it also updates the ownership data accordingly.
   */
  setOwnershipInfoForNoDevice() {
    this.isOwnershipApiCallInProgress = false;
    let assetOwners: any[] = [];
    if (this.selectedRowData?.equipmentOwner.length > 0) {
      assetOwners = this.selectedRowData.equipmentOwner;
      assetOwners = this.filterOwnershipRecords(assetOwners);
      assetOwners = this.checkDCNFlagCondition(assetOwners);
      if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership) {
        this.checkSelectOwnershipSection(assetOwners);
      }
      this.selectedRowData.ownershipDetails = assetOwners;
      if (this.isEditFlow) {
        this.setOwnershipData(assetOwners);
      }
    } else {
      this.selectedRowData.ownershipDetails = [];
    }
    this.setAssetOwnershipFlag = true;
  }
  /**
   * This function checks the DCN flag condition and processes asset ownership data accordingly.
   *
   * @param {any} assetOwners - The asset ownership data to be processed.
   * @returns {any} - The processed asset ownership data.
   */
  checkDCNFlagCondition(assetOwners: any) {
    if (this.dspStoreData?.featureFlagInfo?.enableDCNSelection) {
      this.checkOwnershipInfo(assetOwners);
      this.partialOwnershipData = sortOwnershipRecords(this.partialOwnershipData);
      this.completeOwnershipData = sortOwnershipRecords(this.completeOwnershipData);
      assetOwners = [{}];
      assetOwners = [...this.completeOwnershipData, ...this.partialOwnershipData];
    } else {
      assetOwners = sortOwnershipRecords(assetOwners);
    }
    return assetOwners;
  }
  /**
   * This function checks and processes the asset ownership section based on the presence of partial ownership data.
   *
   * @param {any} assetOwners - The asset ownership data to be processed.
   */
  checkSelectOwnershipSection(assetOwners: any) {
    if (!this.hasPartialOwnershipData) {
      if (assetOwners?.length === 1) {
        remove(this.dynamicTrackerData, (row: any) => row.label === 'Select Ownership');
        this.setOwnershipData(assetOwners);
      }
    }
  }
  /**
   * This function fetches the ownership information for a selected asset.
   * It constructs a payload with asset IDs and dealer code, makes an API call to retrieve the ownership data,
   * processes the data, and updates the relevant fields in the system.
   */
  fetchOwnershipInfo() {
    const ownershipInfoPayload = {
      assetIds: [this.selectedRowData.assetId],
      dealerCode: this.dealer.dealerCode,
    };
    let assetOwners: any[] = [];
    this.isOwnershipApiCallInProgress = true;
    this.assetDrawerService.getAssetOwnershipInfo(ownershipInfoPayload).subscribe({
      next: (response: any) => {
        this.isOwnershipApiCallInProgress = false;
        if (response[this.selectedRowData.assetId]) {
          assetOwners = response[this.selectedRowData.assetId] as any;
          assetOwners = this.filterOwnershipRecords(assetOwners);
          assetOwners = this.checkDCNFlagCondition(assetOwners);
          if (this.dspStoreData?.featureFlagInfo?.isRemoveSelectOwnership) {
            this.checkSelectOwnershipSection(assetOwners);
          }
          this.selectedRowData.ownershipDetails = assetOwners;
          if (this.isEditFlow) {
            this.setOwnershipData(assetOwners);
          }
        } else {
          this.selectedRowData.ownershipDetails = [];
        }
        this.setAssetOwnershipFlag = true;
      },
      error: (_err: any) => {
        this.isOwnershipApiCallInProgress = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }
  /**
   * This function checks the ownership information of assets and categorizes them into partial or complete ownership data.
   *
   * @param {any} assetOwners - The asset ownership data to be processed.
   */
  checkOwnershipInfo(assetOwners: any) {
    assetOwners.forEach((owner: any) => {
      if (isEmpty(owner?.dealerCustNo)) {
        this.hasPartialOwnershipData = true;
        this.partialOwnershipData.push(owner);
      } else {
        if (!isEmpty(owner?.ucid)) this.completeOwnershipData.push(owner);
      }
    });
  }
  /**
   * This function filters the asset ownership records to include only those that belong to the logged-in dealer
   * or have an empty dealer code.
   *
   * @param {any} assetOwners - The asset ownership data to be filtered.
   * @returns {any} - The filtered asset ownership data.
   */
  filterOwnershipRecords(assetOwners: any) {
    const loggedInDealerRecords = assetOwners?.filter((owner: any) => {
      return owner?.dealerCode == this.dealer?.dealerCode || isEmpty(owner?.dealerCode);
    });
    return loggedInDealerRecords;
  }
  /**
   * This function sets the ownership data for the selected asset subscription form.
   * It updates the customer information based on the provided asset ownership info.
   *
   * @param {any} assetOwnershipInfo - The asset ownership information to be processed.
   */
  setOwnershipData(assetOwnershipInfo: any) {
    if (assetOwnershipInfo && !isEmpty(assetOwnershipInfo)) {
      // Customers data is available
      this.selectedAssetSubscriptionFormData.customer = assetOwnershipInfo[0];
    } else {
      // No customers are there and not subscribed at Multi-level
      this.selectedAssetSubscriptionFormData.customer = '';
    }
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  /**
   * This function displays a toast message with the specified configuration.
   *
   * @param {string} message - The message to be displayed in the toast.
   * @param {string} status - The status of the message (e.g., 'success', 'error').
   * @param {string} [hostType='container-overlay'] - The type of host for the message bar.
   * @param {string} [selectorId='manage-asset'] - The ID of the host selector.
   */
  showToastMessage(message: string, status: string, hostType = 'container-overlay', selectorId = 'manage-asset') {
    const config: MessageBarConfig = {
      hostType: hostType,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: selectorId,
      duration: 3000,
    };
    if (status == 'success') {
      config.appOverlayClass = 'manageAsset-overlay';
    }
    this.messageBar.open(message, status, undefined, config, true);
  }
  /**
   * This function closes the asset management drawer based on the launch type and other conditions.
   * It prepares the necessary data to be passed when closing the drawer.
   */
  closeDrawer() {
    if (this.launchType == 'DRAWER') {
      const managePlanDrawerData = {
        closeAssetDrawer: this.isRedirectToSitePage,
        apiCallNeeded: this.getNewSubscriptionData,
        reportingState: this.selectedAssetSubscriptionFormData.reportingState,
        formData: this.selectedAssetSubscriptionFormData,
      };
      this.manageAssetPlanContainerRef.close(managePlanDrawerData);
    } else if (this.launchType == 'LANDING' && this.getNewSubscriptionData) {
      const managePlanDrawerData = {
        closeAssetDrawer: this.isRedirectToSitePage,
        apiCallNeeded: this.getNewSubscriptionData,
        reportingState: this.selectedAssetSubscriptionFormData.reportingState,
        formData: this.selectedAssetSubscriptionFormData,
      };
      this.manageAssetPlanContainerRef.close(managePlanDrawerData);
    } else {
      this.manageAssetPlanContainerRef.close(this.getNewSubscriptionData);
    }
  }

  redirectToSitePage(event: any) {
    this.isRedirectToSitePage = true;
    this.closeDrawer();
  }
  /**
   * This function opens a cancellation popup modal and handles the user's response.
   *
   * @param {any} response - The response data to be passed to the cancellation modal.
   */
  cancelPopUP(response: any) {
    const cancellationScreen = this.modal.openModal(CancelAllPlansComponent, {
      width: '750px',
      type: this.modalType,
      panelClass: ['test'],
      disableBackdropClick: true,
      disableMargin: true,
      closeOnEsc: true,
      data: {
        cancelReasonResponse: response,
        selectedRowDataValue: this.selectedRowData,
        subsPricingEligibleValue: this.subsPricingEligible,
        selectedAssetSubscriptionFormDataValue: this.selectedAssetSubscriptionFormData,
      },
    });
    cancellationScreen.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.cancelAllPlansSelected = true;
        this.submitCancelReason();
      } else if (result === false) {
        this.cancelScreen = false;
        this.selectedAssetSubscriptionFormData.cancelReasonSelected = false;
        this.selectedAssetSubscriptionFormData.cancelOptions = {};
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        this.assetService.isCancelAll.next(true);
      }
    });
  }
  /**
   * This function loads the cancellation screen by fetching cancellation reasons and opening the cancellation popup.
   *
   * @param {number} [modalIndex=0] - The index of the modal to be loaded (default is 0).
   */
  loadCancellationScreen(modalIndex = 0) {
    let cancellationScreen: any;
    this.assetDrawerService.cancelreasons().subscribe({
      next: (response: any) => {
        this.cancelPopUP(response);
      },
    });
  }

  loadCancelScreen(value: any) {
    this.cancelScreen = value;
  }
  /**
   * This function handles the logic for progressing to the next step in a multi-step process.
   * It updates the tracker index and current page index, and performs specific actions based on the current step.
   */
  onNext() {
    // trackerIndex is for progress-tracker updates.
    // currentPageIndex is for components to load.
    const { trackerIndex, currentPageIndex, dynamicTrackerData } = this;
    const isSelectCancelReason = dynamicTrackerData[currentPageIndex].label === 'Select Cancellation Reason';
    if (isSelectCancelReason) {
      this.submitCancelReason();
    } else if (this.currentPageIndex === this.dynamicTrackerData.length - 1 && this.subsPricingEligible) {
      this.validatePrePayData();
    } else if (dynamicTrackerData[currentPageIndex].label === 'Select Plan' && this.checkAllNone()) {
      this.handlePricingActionPopup();
    } else {
      this.trackerIndex = trackerIndex + 1;
      this.currentPageIndex = currentPageIndex + 1;
    }
  }
  /**
   * This function checks the subscription levels of products in the selected asset subscription form data.
   * It iterates through each product to determine if any product has a current subscription level that is not 'None'.
   *
   * The function performs the following steps:
   * 1. Initializes a counter `noneAll` to zero.
   * 2. Iterates over each product in the `selectedAssetSubscriptionFormData.products` object.
   * 3. For each product, it checks if the current or previous subscription has a category level (`catLevel`).
   * 4. If the current subscription's category level is not 'None', it increments the `noneAll` counter.
   * 5. After iterating through all products, it checks the value of `noneAll`.
   * 6. Returns `true` if `noneAll` is greater than zero, indicating that at least one product has a current subscription level that is not 'None'.
   * 7. Returns `false` if `noneAll` is zero, indicating that all products have a current subscription level of 'None'.
   *
   * @returns {boolean} - Returns true if at least one product has a current subscription level that is not 'None', otherwise false.
   */
  checkAllNone() {
    let noneAll = 0;
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (
        productObj.subscription.currentSubscription.catLevel ||
        productObj.subscription.previousSubscription.catLevel
      ) {
        if (productObj.subscription.currentSubscription.catLevel !== 'None') {
          noneAll++;
        }
      }
    }
    if (noneAll > 0) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * This function validates the prepay data for the selected asset subscription form.
   * It performs the following steps:
   * 1. Initializes arrays and objects to hold popup types and filtered subscription data.
   * 2. Iterates through the products in the subscription form data to filter relevant products.
   * 3. Checks various conditions to determine the appropriate actions, such as opening modals or proceeding with subscription combinations.
   * 4. If no specific actions are required, it proceeds with the subscription combination.
   *
   * @returns {void}
   */
  validatePrePayData() {
    const submitPopUpType: any = [];
    let newSelectedAssetSubscriptionFormData: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (
        productObj.subscription.currentSubscription.catLevel ||
        productObj.subscription.previousSubscription.catLevel
      ) {
        newSelectedAssetSubscriptionFormData[key] = value;
      }
    }

    for (const [key, value] of Object.entries(newSelectedAssetSubscriptionFormData)) {
      let productObj: any = value;
      if (
        (this.assetRowData.selectedAssetDetails.reportingState === 'Unsubscribed' &&
          productObj?.prepay.currentPrepayData.prepaySelected === true) ||
        (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
          productObj?.prepay.currentPrepayData.prepaySelected === true &&
          productObj?.prepay.previousPrepayData.prepaySelected === false)
      ) {
        submitPopUpType.push(this.prePayApplyTemplate);
      } else if (
        (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
          productObj?.prepay.currentPrepayData.prepaySelected === true &&
          productObj?.prepay.previousPrepayData.prepaySelected === true &&
          productObj?.prepay.selectedPrepayData.prepaySelected === true) ||
        (this.assetRowData.selectedAssetDetails.reportingState === 'Subscribed' &&
          productObj?.prepay.currentPrepayData.prepaySelected === false &&
          productObj?.prepay.previousPrepayData.prepaySelected === true)
      ) {
        submitPopUpType.push(this.cancelSubscriptionModalPrepayTemplate);
      } else if (
        this.ec520Message &&
        !productObj?.addOns.previousddons.includes('Surface Guidance') &&
        productObj?.productFamily?.currentProductFamily &&
        productObj?.productFamily?.currentProductFamily !== 'None'
      ) {
        submitPopUpType.unshift(this.EC520MessageTemplate);
      }
    }
    if (submitPopUpType.length === 0) {
      if (this.showB2CWarningPopupInPaymentPreview) {
        if (this.showB2CWarningPopup()) {
          this.openB2CWarningPopup(this.b2cCustWarningTemplate);
        } else {
          this.SubscriptionCombination();
        }
      } else {
        this.SubscriptionCombination();
      }
    } else {
      this.openCancelPlanModal(submitPopUpType);
    }
  }
  /**
   * This function handles the submission of the cancellation reason for the selected asset subscription.
   * It performs the following steps:
   * 1. Checks if the prepay option is selected for specific products and sets the `prepayOption` flag accordingly.
   * 2. Determines the appropriate action based on the cancellation command and the `prepayOption` flag.
   * 3. Opens the corresponding modal or closes the cancellation modal based on the conditions.
   *
   * @returns {void}
   */
  submitCancelReason() {
    if (
      this.selectedAssetSubscriptionFormData?.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
        ?.previousPrepayData?.prepaySelected === true ||
      this.selectedAssetSubscriptionFormData?.products[dspConstants.Worklist.VISION_LINK]?.prepay?.previousPrepayData
        ?.prepaySelected === true
    ) {
      this.prepayOption = true;
    } else {
      this.prepayOption = false;
    }

    if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand === 'Suspend') {
      this.openCancelPlanModalCancelReason(this.cancelSubscriptionModalSuspendTemplate);
    } else if (this.prepayOption) {
      this.openCancelPlanModalCancelReason(this.cancelSubscriptionModalPrepayTemplate);
    } else if (!this.prepayOption) {
      this.closeCancelSubscriptionModalCancelReason(true);
    }
  }
  /**
   * This function checks the prepay option and handles the cancellation process accordingly.
   * It performs the following steps:
   * 1. Checks if the subscription command is 'Suspend' and if the prepay option is selected.
   * 2. If both conditions are met, it closes the current modal and opens the prepay cancellation modal after a delay.
   * 3. If the conditions are not met, it closes the cancellation modal with a confirmation.
   *
   * @returns {void}
   */
  checkPrepay() {
    if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand === 'Suspend' && this.prepayOption) {
      this.modalRef.close();
      setTimeout(() => {
        this.openCancelPlanModalCancelReason(this.cancelSubscriptionModalPrepayTemplate);
      }, 1000);
    } else {
      this.closeCancelSubscriptionModalCancelReason(true);
    }
  }
  /**
   * This function opens a modal for canceling a plan with a specific reason.
   * It performs the following steps:
   * 1. Opens a modal with the provided content and configuration settings.
   * 2. Sets the modal reference to `this.modalRef` for further actions.
   *
   * @param {any} content - The content to be displayed in the modal.
   * @returns {void}
   */
  openCancelPlanModalCancelReason(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '524px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }
  /**
   * This function closes the cancellation subscription modal and handles the cancellation process based on the provided parameter.
   * It performs the following steps:
   * 1. If the parameter is true, it calls the `SubscriptionCombination` method and closes the modal.
   * 2. If the parameter is false, it resets the cancellation screen and form data, updates the asset subscription form data, and closes the modal.
   *
   * @param {boolean} [param=false] - A flag to determine the action to be taken when closing the modal.
   * @returns {void}
   */
  closeCancelSubscriptionModalCancelReason(param = false) {
    if (param) {
      this.SubscriptionCombination();
      this.modalRef?.close();
    } else {
      this.cancelScreen = false;
      this.selectedAssetSubscriptionFormData.cancelReasonSelected = false;
      this.selectedAssetSubscriptionFormData.cancelOptions = {};
      this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      this.modalRef.close();
    }
  }
  /**
   * This function opens a series of cancellation plan modals sequentially.
   * It performs the following steps:
   * 1. Opens a modal with the specified content and configuration settings.
   * 2. Subscribes to the `afterClosed` event of the modal to handle the result.
   * 3. If the result is true, it increments the modal index and either opens the next modal or proceeds with the subscription combination.
   * 4. If the result is false, it performs no action (placeholder for potential future logic).
   *
   * @param {any} content - The array of content to be displayed in the modals.
   * @param {number} [modalIndex=0] - The index of the modal to be loaded (default is 0).
   * @returns {void}
   */
  openCancelPlanModal(content: any, modalIndex = 0) {
    this.modalRef = this.modal.openModal(content[modalIndex], {
      width: '524px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.modalRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        ++modalIndex;
        if (modalIndex === content.length) {
          this.SubscriptionCombination();
        } else {
          this.openCancelPlanModal(content, modalIndex);
        }
      } else if (result === false) {
        //
      }
    });
  }

  /**
   * This function closes the cancellation subscription modal and passes a parameter to indicate the result.
   * It performs the following steps:
   * 1. If the parameter is true, it closes the modal and passes `true` as the result.
   * 2. If the parameter is false, it closes the modal and passes `false` as the result.
   *
   * @param {boolean} [param=false] - A flag to determine the result to be passed when closing the modal.
   * @returns {void}
   */
  closeCancelSubscriptionModal(param = false) {
    if (param) {
      this.modalRef.close(true);
    } else {
      this.modalRef.close(false);
    }
  }
  /**
   * This function closes the EC520 popup modal and passes a parameter to indicate the result.
   * It performs the following steps:
   * 1. If the parameter is true, it closes the modal and passes `true` as the result.
   * 2. If the parameter is false, it closes the modal and passes `false` as the result.
   *
   * @param {boolean} [param=false] - A flag to determine the result to be passed when closing the modal.
   * @returns {void}
   */
  closeEC520PopupModal(param = false) {
    if (param) {
      this.modalRef.close(true);
    } else {
      this.modalRef.close(false);
    }
  }
  // Using this function, to get the checked icon in the progress-tracker
  updateTrackerIndex() {
    this.trackerIndex = this.currentPageIndex + 1;
  }
  /**
   * This function handles the logic for navigating to the previous step in a multi-step process.
   * It performs the following steps:
   * 1. Triggers a back button press event in the asset service.
   * 2. Checks if the current step is the first step and the launch type is 'LANDING', and closes the drawer if true.
   * 3. Decrements the current page index if the cancel all plans option is not selected.
   * 4. Updates the tracker index to match the current page index.
   * 5. Resets the cancel screen flag if the tracker index is zero.
   * 6. Resets the cancel all plans selected flag.
   *
   * @returns {void}
   */
  onPrevious() {
    this.assetService.backButtonPress({ isBackButtonPressed: true });
    if (this.trackerIndex === 0 && this.launchType === 'LANDING') {
      this.closeDrawer();
    }
    const { currentPageIndex } = this;
    if (this.cancelAllPlansSelected === false) {
      this.currentPageIndex = currentPageIndex - 1;
    }
    this.trackerIndex = this.currentPageIndex;
    if (this.trackerIndex == 0) {
      this.cancelScreen = false;
    }
    this.cancelAllPlansSelected = false;
  }
  /**
   * This function opens a B2C warning popup modal with the specified content.
   * It performs the following steps:
   * 1. Opens a modal with the provided content and configuration settings.
   * 2. Sets the modal reference to `this.modalRef` for further actions.
   *
   * @param {any} content - The content to be displayed in the modal.
   * @returns {void}
   */
  openB2CWarningPopup(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }
  /**
   * This function closes the B2C warning popup modal and handles the user's confirmation response.
   * It performs the following steps:
   * 1. If the user confirms (isConfirmed is true), it closes the modal and proceeds with the subscription combination.
   * 2. If the user does not confirm (isConfirmed is false), it simply closes the modal.
   *
   * @param {boolean} isConfirmed - A flag indicating whether the user confirmed the action.
   * @returns {void}
   */
  closeB2CWarningPopup(isConfirmed: boolean) {
    if (isConfirmed) {
      this.modal.close();
      this.SubscriptionCombination();
    } else {
      this.modal.close();
    }
  }

  showB2CWarningPopup() {
    this.checkPriceForB2C();
    return this.telUserType == dspConstants.Common.DEALER && !this.isNonBillableCust && !this.noneSelected;
  }
  /**
   * This function checks the price for B2C customers and determines if the customer is non-billable.
   * It performs the following steps:
   * 1. Retrieves valid customer information for the asset.
   * 2. Filters products based on their category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 3. Iterates over the filtered products and checks the price for B2C customers using dspUtilsService.
   * 4. If the price check fails for any product, sets isNonBillableCust to false and checks if the customer level is 'None', setting noneSelected to true if so.
   * 5. Returns the value of isNonBillableCust, indicating whether the customer is non-billable.
   *
   * @returns {boolean} - A flag indicating whether the customer is non-billable.
   */
  checkPriceForB2C() {
    const customerInfo = this.getValidCustomerForAsset();
    let SelectedAssetSubscriptionForm: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObj: any = value;
      if (
        productObj.subscription.currentSubscription.catLevel &&
        productObj.subscription.currentSubscription.catLevel !== 'None'
      ) {
        SelectedAssetSubscriptionForm[key] = value;
      }
    }

    for (const [key, value] of Object.entries(SelectedAssetSubscriptionForm)) {
      let productObj: any = value;
      if (
        this.dspUtilsService.checkPriceForB2C(this.b2cCustomers, customerInfo, productObj.service.currentService) ===
        false
      ) {
        this.isNonBillableCust = false;
        if (productObj.subscription.currentSubscription.customerLevel === 'None') {
          this.noneSelected = true;
        }
        return this.isNonBillableCust;
      }
    }
    return this.isNonBillableCust;
  }
  /**
   * This function checks if a customer is a B2C customer for a given application.
   * It performs the following steps:
   * 1. Retrieves customer information and the selected service for the given application.
   * 2. Checks if the customer information is valid and if the customer is in the list of billDirectCustomers.
   * 3. Checks if the selected service is supported for B2C customers.
   * 4. Returns true if both conditions are met, otherwise returns false.
   *
   * @param {any} [applicationName] - The name of the application to check.
   * @returns {boolean} - A flag indicating whether the customer is a B2C customer for the given application.
   */
  checkB2CCustomer(applicationName?: any) {
    const customerInfo = this.selectedAssetSubscriptionFormData.customer;
    const selectedService = this.selectedAssetSubscriptionFormData.products[applicationName]?.service?.currentService;
    if (
      customerInfo !== 'None' &&
      findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo?.ucid]) !== -1 &&
      findIndex(this.b2cCustomers?.b2cSupportedApps, selectedService) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * This function restricts the selection of add-ons if no FSM (Feature Message Support) is available.
   * It performs the following steps:
   * 1. Filters products based on their current or previous category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 2. Iterates over the filtered products and checks if FSM is supported and if the service is not in the supported services list.
   * 3. Returns true if both conditions are met for any product, otherwise returns false.
   *
   * @returns {boolean} - A flag indicating whether the selection of add-ons should be restricted.
   */
  restrictAddOnSelectionIfNoFSM() {
    let newForm: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObject: any = value;
      if (
        (productObject.subscription.currentSubscription.catLevel ||
          productObject.subscription.previousSubscription.catLevel) &&
        productObject.subscription.currentSubscription.catLevel !== 'None'
      ) {
        newForm[key] = value;
      }
    }
    for (const [key, value] of Object.entries(newForm)) {
      let productValue: any = value;
      const currentSubscriptionFSMCheck = productValue?.isFeatureMsgSupported;
      const supportedServiceListCheck =
        this.selectedRowData?.getSupportedServicesList?.indexOf(
          productValue.subscription.currentSubscription.customerLevel
        ) === -1;
      if (currentSubscriptionFSMCheck && supportedServiceListCheck) {
        return true;
      }
    }
    return false;
  }
  /**
   * This function handles the pricing action popup and performs the following steps:
   * 1. Checks if add-on selection should be restricted due to lack of FSM (Feature Message Support).
   *    - If restricted, shows an error toast message and exits the function.
   * 2. Initializes an array to store billing types and retrieves necessary data from the current context.
   * 3. Filters products based on their current or previous category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 4. Iterates over the filtered products and performs various checks to determine the appropriate billing type.
   * 5. Updates the asset agreement status based on the checks performed.
   *
   * @returns {void}
   */
  handlePricingActionPopup() {
    if (this.restrictAddOnSelectionIfNoFSM()) {
      const inValidSubscriptionMessage = this.languageTranslations.Worklist.WL_RESTRICT_ADDON_SELECTION_IF_NO_FSM;
      return this.showToastMessage(inValidSubscriptionMessage, 'error');
    }
    const billingType: any = [];
    const { billingPopUpInfo, userActiveFeatures, userPreferences } = this;
    let newAssetSubscriptionFormData: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObject: any = value;
      if (
        (productObject.subscription.currentSubscription.catLevel ||
          productObject.subscription.previousSubscription.catLevel) &&
        productObject.subscription.currentSubscription.catLevel !== 'None'
      ) {
        newAssetSubscriptionFormData[key] = value;
      }
    }
    for (const [key, value] of Object.entries(newAssetSubscriptionFormData)) {
      const product: any = value;
      const { service, productFamily } = product;
      const { currentAddons, previousddons } = product.addOns;
      const initialAddonSubscribed: any = previousddons;
      const profileBasedAdditionalServices: any = currentAddons;
      this.setSubsPricingEligibility(service.currentService);
      const billingPopUpCheck =
        userActiveFeatures && userPreferences && userPreferences?.length > 0 && billingPopUpInfo;
      const isSubcribeServiceAtLevel = this.checkPopupEnableAtLevel(service.currentService, product);
      const isEquipEnable = this.checkIfBillingPopupToBeShown(initialAddonSubscribed, profileBasedAdditionalServices);
      if (
        billingPopUpCheck &&
        userActiveFeatures.indexOf('MinestarPaymentInfo') > -1 &&
        userPreferences[0].showMinestarPaymentInfo &&
        billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
        service.currentService == dspConstants.Worklist.MINESTAR &&
        isSubcribeServiceAtLevel
      ) {
        billingType.push('MineStar');
      }
      if (
        billingPopUpCheck &&
        userActiveFeatures.indexOf('HEIPaymentInfo') > -1 &&
        userPreferences[0].showHeiPaymentInfo &&
        ((billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
          service.currentService.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS) > -1 &&
          isSubcribeServiceAtLevel) ||
          isEquipEnable)
      ) {
        billingType.push('HEI');
      } else if (
        (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY) > -1 &&
          profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY) === -1) ||
        (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR) > -1 &&
          profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR) === -1) ||
        (initialAddonSubscribed.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI) > -1 &&
          profileBasedAdditionalServices.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI) === -1)
      ) {
        this.assetAgreementStatus.isHeiAgreed = false;
      }
      if (
        billingPopUpCheck &&
        userActiveFeatures.indexOf('VIMSPaymentInfo') > -1 &&
        userPreferences[0].showVimsPaymentInfo &&
        ((billingPopUpInfo.indexOf(service.currentService as string) > -1 &&
          service.currentService == dspConstants.Worklist.VIMS_TRANSMITTER &&
          isSubcribeServiceAtLevel) ||
          (!(initialAddonSubscribed.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1) &&
            profileBasedAdditionalServices.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1))
      ) {
        billingType.push('VIMS');
      } else if (
        initialAddonSubscribed.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) > -1 &&
        profileBasedAdditionalServices.indexOf(dspConstants.Worklist.VIMS_TRANSMITTER) === -1
      ) {
        this.assetAgreementStatus.isVimsAgreed = false;
      }
    }
    let index = 0;
    for (const [key, value] of Object.entries(newAssetSubscriptionFormData)) {
      const products: any = value;
      if (
        this.subsPricingEligible &&
        products?.productFamily?.currentProductFamily &&
        products?.productFamily?.currentProductFamily !== 'None'
      ) {
        this.validateAssetProductFamily(() => {
          this.showBillingModal(billingType);
        });
        break;
      } else if (index === Object.entries(newAssetSubscriptionFormData).length - 1) {
        this.showBillingModal(billingType);
      }
      index++;
    }
  }
  /**
   * This function checks if a popup should be enabled at a specific level for a given service and product.
   * It performs the following steps:
   * 1. Retrieves the current and previous subscription details from the product.
   * 2. Checks if the selected service is one of the specified services (EQUIPMENT_INSIGHTS, VIMS_TRANSMITTER, MINESTAR).
   * 3. Checks if the current customer level is valid and different from the previous customer level.
   * 4. Returns true if both conditions are met, otherwise returns false.
   *
   * @param {string} selectedService - The service to check.
   * @param {any} product - The product containing subscription details.
   * @returns {boolean} - A flag indicating whether the popup should be enabled at the specified level.
   */
  checkPopupEnableAtLevel(selectedService: string, product: any) {
    const { currentSubscription, previousSubscription } = product.subscription;
    if (
      (selectedService == dspConstants.Worklist.EQUIPMENT_INSIGHTS ||
        selectedService == dspConstants.Worklist.VIMS_TRANSMITTER ||
        selectedService == dspConstants.Worklist.MINESTAR) &&
      currentSubscription.customerLevel !== 'None' &&
      currentSubscription.customerLevel !== previousSubscription.customerLevel
    ) {
      return true;
    }
    return false;
  }
  /**
   * This function checks if a billing popup should be shown based on the initial and profile-based additional services.
   * It performs the following steps:
   * 1. Defines a list of equipment add-ons to check against.
   * 2. Iterates over the profile-based additional services to see if any of them are in the equipment add-on list but not in the initial add-ons subscribed.
   * 3. Sets a flag to true if any such service is found, indicating that the billing popup should be shown.
   * 4. Returns the flag indicating whether the billing popup should be shown.
   *
   * @param {any} initialAddonSubscribed - The list of initially subscribed add-ons.
   * @param {any} profileBasedAdditionalServices - The list of profile-based additional services.
   * @returns {boolean} - A flag indicating whether the billing popup should be shown.
   */
  checkIfBillingPopupToBeShown(initialAddonSubscribed: any, profileBasedAdditionalServices: any) {
    const equipAddOnList = [
      dspConstants.Worklist.EQUIPMENT_INSIGHTS,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_UI_ONLY,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_CAT_CELLULAR,
      dspConstants.Worklist.EQUIPMENT_INSIGHTS_WI_FI,
    ];
    let isPopupToBeShown = false;
    profileBasedAdditionalServices.forEach((item: any) => {
      if (equipAddOnList.indexOf(item) > -1 && initialAddonSubscribed.indexOf(item) == -1) {
        isPopupToBeShown = true;
      }
    });
    return isPopupToBeShown;
  }
  /**
   * This function sets the subscription pricing eligibility based on the user's active features.
   * It performs the following steps:
   * 1. Checks if the user has the 'Contract Visualization' and 'Subscription Management' features enabled.
   * 2. Ensures that the user does not have 'View Only', 'Read Only', or 'Manage - Limited Plans (DSP Mobile Only)' features enabled.
   * 3. Sets the subsPricingEligible flag to true if both conditions are met, otherwise sets it to false.
   *
   * @param {any} usrOptedService - The service opted by the user.
   * @returns {void}
   */
  setSubsPricingEligibility(usrOptedService: any) {
    // const deviceServiceCatalog = this.catalogMapping[this.selectedRowData?.deviceType]
    //   ? this.catalogMapping[this.selectedRowData?.deviceType][usrOptedService]
    //   : undefined;
    this.subsPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
      (this.userActiveFeatures?.indexOf('View Only') == -1 ||
        this.userActiveFeatures?.indexOf('Read Only') == -1 ||
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1);
    // ((deviceServiceCatalog && deviceServiceCatalog['enableContractVisualization']) || false)
  }
  /**
   * This function validates the asset product family by fetching the EC250 serial number.
   * It performs the following steps:
   * 1. Constructs a request payload with the make and serial number of the selected row data.
   * 2. Calls the assetService to fetch the EC250 serial number.
   * 3. If the response does not contain an EC520 ECM serial number, sets an appropriate message.
   * 4. Executes the callback function after processing the response or handling an error.
   *
   * @param {any} callback - The callback function to execute after validation.
   * @returns {void}
   */
  validateAssetProductFamily(callback: any) {
    const requestPayload = {
      make: this.selectedRowData.make,
      serialNumber: this.selectedRowData.serialNumber,
    };
    this.assetService.fetchEC250SerailNumber(requestPayload).subscribe({
      next: (res: any) => {
        if (res && !res.ec520ECMSerialNumber) {
          this.ec520Message = this.languageTranslations?.Worklist?.WL_EC520_Message;
          this.ec520Message = this.ec520Message?.replace('S/N', 'serial number');
        }
        callback();
      },
      error: () => {
        this.ec520Message = '';
        callback();
      },
    });
  }
  /**
   * This function shows the billing modal based on the provided billing type.
   * It performs the following steps:
   * 1. Checks if the billingType array is not empty.
   *    - If not empty, calls the loadBillingNoticePop method with the billingType.
   *    - If empty, calls the validateCCSMessage method.
   *
   * @param {any} billingType - The array containing billing types.
   * @returns {void}
   */
  showBillingModal(billingType: any) {
    if (billingType.length !== 0) {
      this.loadBillingNoticePop(billingType);
    } else {
      this.validateCCSMessage();
    }
  }
  /**
   * This function checks if an agreement popup is needed based on the billing type and modal index.
   * It performs the following steps:
   * 1. Checks the billing type at the specified modal index.
   * 2. Returns the agreement status for the corresponding billing type:
   *    - 'VIMS': Returns the VIMS agreement status.
   *    - 'HEI': Returns the HEI agreement status.
   *    - 'MineStar': Returns the MineStar agreement status.
   * 3. If the billing type does not match any of the specified types, returns undefined.
   *
   * @param {any} billingType - The array containing billing types.
   * @param {number} [modalIndex=0] - The index of the modal to check (default is 0).
   * @returns {boolean|undefined} - The agreement status for the specified billing type, or undefined if not applicable.
   */
  checkIfAgreementPopupNeeded(billingType: any, modalIndex = 0) {
    if (billingType[modalIndex] == 'VIMS') {
      return this.assetAgreementStatus?.isVimsAgreed;
    } else if (billingType[modalIndex] == 'HEI') {
      return this.assetAgreementStatus?.isHeiAgreed;
    } else if (billingType[modalIndex] == 'MineStar') {
      return this.assetAgreementStatus?.isMinestarAgreed;
    }

    return;
  }
  /**
   * This function loads the billing notice popup and handles the user's response.
   * It performs the following steps:
   * 1. Opens a modal for the dealer action required component with the specified billing type.
   * 2. Subscribes to the modal's afterClosed event to handle the user's response.
   *    - If the user agrees ('i agree'), sets the agreement flag, updates the billing type status, and either validates the CCS message or loads the next billing notice popup.
   *    - If the user exits ('exit'), sets the agreement flag to false and updates the asset agreement status based on the billing type.
   *
   * @param {any} billingType - The array containing billing types.
   * @param {number} [modalIndex=0] - The index of the modal to load (default is 0).
   * @returns {void}
   */
  loadBillingNoticePop(billingType: any, modalIndex = 0) {
    const dealerActionRequiredModalRef = this.modal.openModal(DealerActionRequiredComponent, {
      width: '750px',
      type: this.modalType,
      panelClass: ['test'],
      disableMargin: true,
      disableBackdropClick: true,
      data: {
        languageTranslations: this.languageTranslations,
        billingType: billingType[modalIndex],
      },
    });
    dealerActionRequiredModalRef.afterClosed().subscribe((result: any) => {
      if (result === 'i agree') {
        this.isDealerActionRequiredPopupAgreed = true;
        this.billingTypeStatus = billingType;
        ++modalIndex;
        if (modalIndex === billingType.length) {
          this.validateCCSMessage();
        } else {
          this.loadBillingNoticePop(billingType, modalIndex);
        }
      } else if (result === 'exit') {
        this.isDealerActionRequiredPopupAgreed = false;
        if (billingType[modalIndex] == 'VIMS') {
          this.assetAgreementStatus.isVimsAgreed = false;
        }
        if (billingType[modalIndex] == 'HEI') {
          this.assetAgreementStatus.isHeiAgreed = false;
        }
        if (billingType[modalIndex] == 'MineStar') {
          this.assetAgreementStatus.isMinestarAgreed = false;
        }
      }
    });
  }

  validateCCSMessage() {
    const ccsEnabled = this.isB2CDealer();
    if (this.telUserType === dspConstants.Common.DEALER && ccsEnabled) {
      this.handleWarningMessage(this.ccsMessage);
    } else {
      //Removed checkUcidConsolidated function as part of cleanup feature - post row and china migration(558481)
      this.validateSubscriptionCombination();
    }
  }

  handleWarningMessage(content: any) {
    this.warningModalRef = this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.warningModalRef.afterClosed().subscribe((_result: any) => {
      //Removed checkUcidConsolidated function as part of cleanup feature - post row and china migration(558481)
      this.validateSubscriptionCombination();
    });
  }

  handleClose() {
    this.warningModalRef.close();
  }
  /**
   * This function checks if the asset belongs to a B2C customer and if CAT CONNECT SUPPORT is enabled when logged in as a Dealer.
   * It performs the following steps:
   * 1. Filters products based on their current category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 2. Iterates over the filtered products and checks if the customer information matches the B2C customer criteria.
   * 3. Returns true if the customer is a B2C customer with CAT CONNECT SUPPORT enabled, otherwise returns false.
   *
   * @returns {boolean} - A flag indicating whether the asset belongs to a B2C customer with CAT CONNECT SUPPORT enabled.
   */
  isB2CDealer() {
    // Check if asset belongs to b2C and CAT CONNECT SUPPORT is enabled when logged in as Dealer
    let newformObject: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObject: any = value;
      if (
        productObject.subscription.currentSubscription.catLevel &&
        productObject.subscription.currentSubscription.catLevel !== 'None'
      ) {
        newformObject[key] = value;
      }
    }
    for (const [key, value] of Object.entries(newformObject)) {
      let productValue: any = value;
      const { currentService } = productValue.service;
      const customerInfo = this.getValidCustomerForAsset();
      if (
        customerInfo &&
        customerInfo.length == 1 &&
        customerInfo[0].ucid &&
        this.b2cCustomers &&
        this.b2cCustomers.ucids &&
        this.b2cCustomers.applns &&
        this.b2cCustomers.ucids.indexOf(customerInfo[0].ucid) != -1 &&
        this.b2cCustomers.applns.indexOf(currentService) != -1
      ) {
        const filteredCCS = this.b2cCustomers.ccsCustomers.filter(
          (customer: any) => customer.ucid === customerInfo[0].ucid
        );
        return filteredCCS.length > 0;
      }
    }
    return false;
  }

  getValidCustomerForAsset() {
    const { customer } = this.selectedAssetSubscriptionFormData;
    return this.dspUtilsService.getValidCustomerForAsset(customer);
  }
  /**
   * This function validates the subscription combination for an asset.
   * It performs the following steps:
   * 1. Updates the asset's last updated time.
   * 2. Filters products based on their current or previous category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 3. Iterates over the filtered products to prepare a validation payload.
   * 4. Constructs a payload with asset details and subscription information.
   *
   * @returns {void}
   */
  validateSubscriptionCombination() {
    this.assetRowData.selectedAssetDetails.updatedTime = this.assetService.lastUpdatedTime
      ? this.assetService.lastUpdatedTime
      : this.assetRowData.updatedTime;
    this.multiProductValidation = [];
    let newSelectedAssetSubscriptionForm: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      let productObject: any = value;
      if (
        (productObject.subscription.currentSubscription.catLevel ||
          productObject.subscription.previousSubscription.catLevel) &&
        productObject.subscription.currentSubscription.catLevel !== 'None'
      ) {
        newSelectedAssetSubscriptionForm[key] = value;
      }
    }
    for (const [key, value] of Object.entries(newSelectedAssetSubscriptionForm)) {
      const products: any = value;
      this.getplansInPayload(products);
      this.multiProductValidation.push({
        appId: products?.appId,
        appName: products.service.selectedService || key,
        catLevel: this.catLevelStruct,
        customerLevel: this.customerLevelStruct,
        dealerLevel: this.dealerLevelStruct,
      });
    }
    let payload: any = {
      assetId: `${this.assetRowData.selectedAssetDetails.make}|${this.assetRowData.selectedAssetDetails.serialNumber}`,
      commercialType: this.assetRowData.selectedAssetDetails.deviceType,
      model: this.assetRowData.selectedAssetDetails.model,
      siteId: '',
      prepay: this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
        .currentPrepayData.prepaySelected
        ? this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
            .currentPrepayData.prepaySelected
        : this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay.currentPrepayData
              .prepaySelected
          ? this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay.currentPrepayData
              .prepaySelected
          : false,
      contractStartDate: this.formatContractDates(
        'contractStartDate',
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay.currentPrepayData
          .contractStartDate,
        dspConstants.Worklist.NEW_VISION_LINK
      )
        ? this.formatContractDates(
            'contractStartDate',
            this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
              .currentPrepayData.contractStartDate,
            dspConstants.Worklist.NEW_VISION_LINK
          )
        : this.formatContractDates(
              'contractStartDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractStartDate,
              dspConstants.Worklist.VISION_LINK
            )
          ? this.formatContractDates(
              'contractStartDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractStartDate,
              dspConstants.Worklist.VISION_LINK
            )
          : null,
      contractEndDate: this.formatContractDates(
        'contractEndDate',
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay.currentPrepayData
          .contractEndDate,
        dspConstants.Worklist.NEW_VISION_LINK
      )
        ? this.formatContractDates(
            'contractEndDate',
            this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
              .currentPrepayData.contractEndDate,
            dspConstants.Worklist.NEW_VISION_LINK
          )
        : this.formatContractDates(
              'contractEndDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractEndDate,
              dspConstants.Worklist.VISION_LINK
            )
          ? this.formatContractDates(
              'contractEndDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractEndDate,
              dspConstants.Worklist.VISION_LINK
            )
          : null,
      servicesSubscribedMultiProductDto: this.multiProductValidation,
      catPrevLevel: '',
      dealerPrevLevel: '',
      customerPrevLevel: '',
      requestingDealerCode: this.dealer?.dealerCode,
      ucid: this.selectedAssetSubscriptionFormData.customer.ucid,
      ucName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dealerCustomerId: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dcn: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      updatedTimeStamp: this.getUpdatedTimeStamp(this.assetRowData?.selectedAssetDetails?.updatedTime || null)
        ? this.assetRowData.selectedAssetDetails.updatedTime
        : new Date().toJSON(),
      billToParty: this.checkB2CCustomer() ? dspConstants.Common.CUSTOMER : dspConstants.Common.DEALER,
    };
    this.assetService.validateMultiProdSubscriptionSet(payload).subscribe({
      error: (error: any) => {
        let responseText = error?.error?.text;
        if (responseText === 'Success') {
          if (this.subsPricingEligible) {
            this.currentPageIndex = this.currentPageIndex + 1;
            this.trackerIndex = this.currentPageIndex;
          } else {
            this.SubscriptionCombination();
          }
        } else {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          if (responseText === 'Zuora_Billing_Not_Supported') {
            responseText = this.languageTranslations?.Shared?.['WL_BILLING_NOT_SUPPORTED_MSG'];
            this.showToastMessage(responseText, 'error');
          } else if (!isEmpty(responseText)) {
            this.showToastMessage(responseText, 'error');
          } else {
            this.showToastMessage(this.systemError, 'error');
          }
        }
      },
    });
  }
  /**
   * This function formats contract dates based on the provided date option, prepay flag, and application name.
   * It performs the following steps:
   * 1. Checks if the prepay flag is false; if so, returns null.
   * 2. Retrieves the selected date range from the current prepay data for the specified application and date option.
   * 3. Returns the selected date range if it is not empty, otherwise returns null.
   *
   * @param {keyof PrepayContractInfo} dateOption - The date option to format (e.g., 'contractStartDate', 'contractEndDate').
   * @param {boolean} prepayFlag - A flag indicating whether prepay is selected.
   * @param {any} [applicationName] - The name of the application to retrieve the date for.
   * @returns {string|null} - The formatted date range or null if not applicable.
   */
  formatContractDates(dateOption: keyof PrepayContractInfo, prepayFlag: boolean, applicationName?: any) {
    if (!prepayFlag) return null;
    const selectedDateRange =
      this.selectedAssetSubscriptionFormData.products[applicationName].prepay.currentPrepayData[dateOption] || '';
    return !isEmpty(selectedDateRange) ? selectedDateRange : null;
  }

  getUpdatedTimeStamp(updatedTime = null) {
    return updatedTime ? updatedTime : null;
  }
  // updateTrackerIndexFor(index: any) {
  //   this.paymentPreviewpStepIndex = index;
  // }
  setNextDisabled() {
    return !this.selectedAssetSubscriptionFormData.isValidForm;
  }

  setBackDisabled() {
    return false; // for now returning it as false
  }

  updateSubmitButtonLabel() {
    if (this.currentPageIndex === this.dynamicTrackerData.length - 1) {
      return 'Submit';
    } else {
      return 'Next';
    }
  }
  /**
   * This function updates the label of the cancel button based on certain conditions.
   * It performs the following steps:
   * 1. Checks if the tracker index is 0, the launch type is 'LANDING', and the reporting state of the selected asset is not 'Subscribed'.
   *    - If all conditions are met, returns 'Cancel'.
   *    - Otherwise, returns 'Back'.
   *
   * @returns {string} - The label for the cancel button ('Cancel' or 'Back').
   */
  updateCancelButtonLabel() {
    if (
      this.trackerIndex === 0 &&
      this.launchType === 'LANDING' &&
      this.assetRowData?.selectedAssetDetails?.reportingState !== 'Subscribed'
    ) {
      return 'Cancel';
    } else {
      return 'Back';
    }
  }
  /**
   * This function retrieves the asset agreement status for the selected asset.
   * It performs the following steps:
   * 1. Sets the assetId from the selected row data.
   * 2. Calls the assetService to get the asset agreement status.
   * 3. On success, updates the assetAgreementStatus with the response data.
   * 4. On error, logs the error to the console and sets the assetAgreementStatus to an empty object.
   *
   * @returns {void}
   */
  getAssetAgreement() {
    this.assetId = this.selectedRowData?.assetId;

    this.assetService.getAssetAgreement(this.assetId).subscribe({
      next: (res: any) => {
        this.assetAgreementStatus = res as AssetAgreementStatus;
      },
      error: (error: any) => {
        this.assetAgreementStatus = {};
      },
    });
  }
  /**
   * This function saves the asset agreement status based on the billing type status.
   * It performs the following steps:
   * 1. Checks if there are any billing types in the billingTypeStatus array.
   * 2. Updates the asset agreement status based on the billing types:
   *    - If the first billing type is 'VIMS', sets isVimsAgreed to true.
   *    - If the first billing type is 'HEI', sets isHeiAgreed to true and also sets isVimsAgreed to true if the second billing type is 'VIMS'.
   *    - If the first billing type is 'MineStar', iterates over the billing types and sets the corresponding agreement statuses to true.
   * 3. Calls the submitAssetAgreement method to submit the updated asset agreement status.
   *
   * @returns {void}
   */
  saveAssetAgreement() {
    if (this.billingTypeStatus?.length > 0) {
      if (this.billingTypeStatus[0] == 'VIMS') {
        this.assetAgreementStatus.isVimsAgreed = true;
      }
      if (this.billingTypeStatus[0] == 'HEI') {
        if (this.billingTypeStatus.length > 1 && this.billingTypeStatus[1] == 'VIMS')
          this.assetAgreementStatus.isVimsAgreed = true;
        this.assetAgreementStatus.isHeiAgreed = true;
      }
      if (this.billingTypeStatus[0] == 'MineStar') {
        this.billingTypeStatus.forEach((billingType: any) => {
          if (billingType == 'MineStar') this.assetAgreementStatus.isMinestarAgreed = true;
          if (billingType == 'HEI') this.assetAgreementStatus.isHeiAgreed = true;
          if (billingType == 'VIMS') this.assetAgreementStatus.isVimsAgreed = true;
        });
      }
    }
    this.submitAssetAgreement();
  }

  cancelAssetAgreement() {
    this.assetAgreementStatus.isVimsAgreed = this.assetAgreementStatus.isVimsAgreed
      ? false
      : this.assetAgreementStatus.isVimsAgreed;
    this.assetAgreementStatus.isHeiAgreed = this.assetAgreementStatus.isHeiAgreed
      ? false
      : this.assetAgreementStatus.isHeiAgreed;
    this.assetAgreementStatus.isMinestarAgreed = this.assetAgreementStatus.isMinestarAgreed
      ? false
      : this.assetAgreementStatus.isMinestarAgreed;
    this.submitAssetAgreement();
  }

  submitAssetAgreement() {
    this.assetService.saveAssetAgreement(this.assetAgreementStatus).subscribe({
      next: (res: any) => {},
      error: (err: any) => {},
    });
  }
  /**
   * This function prepares the subscription plans payload for a given product.
   * It performs the following steps:
   * 1. Initializes arrays for catLevel, customerLevel, and dealerLevel structures.
   * 2. Formats and adds the base subscription data for catLevel, customerLevel, and dealerLevel to their respective arrays.
   * 3. Checks if there are any current add-ons for the product and adds them to the payload.
   *
   * @param {any} product - The product for which the subscription plans payload is being prepared.
   * @returns {void}
   */
  getplansInPayload(product: any) {
    this.catLevelStruct = [];
    this.customerLevelStruct = [];
    this.dealerLevelStruct = [];
    const catLevel = this.formatSubscriptionsData('catLevel', product, 'Base', '');
    if (catLevel?.length > 0) {
      this.catLevelStruct.push(catLevel[0]);
    }
    const customerLevel = this.formatSubscriptionsData('customerLevel', product, 'Base', '');
    if (customerLevel?.length > 0) {
      this.customerLevelStruct.push(customerLevel[0]);
    }
    const dealerLevel = this.formatSubscriptionsData('dealerLevel', product, 'Base', '');
    if (dealerLevel?.length > 0) {
      this.dealerLevelStruct.push(dealerLevel[0]);
    }
    if (product.addOns.currentAddons?.length > 0) {
      this.pushAddon(product.addOns.currentAddons, product);
    }
  }
  /**
   * This function processes and pushes add-ons to the request payload.
   * It performs the following steps:
   * 1. Iterates over the current add-ons.
   * 2. Checks if the add-on is not already present in the current add-ons list.
   * 3. If the add-on is not present, calls the pushToRquestAddon method to add it to the request payload.
   *
   * @param {any} currentAddons - The list of current add-ons.
   * @param {any} [product] - The product associated with the add-ons (optional).
   * @returns {void}
   */
  pushAddon(currentAddons: any, product?: any) {
    currentAddons.forEach((addOn: string) => {
      if (!currentAddons.some((item: any) => item.planName === addOn)) this.pushToRquestAddon(addOn, product);
    });
  }
  /**
   * This function formats and pushes add-on subscription data to the respective level structures.
   * It performs the following steps:
   * 1. Formats the subscription data for the catLevel, customerLevel, and dealerLevel based on the provided add-on and product.
   * 2. Adds the formatted data to the respective level structures if the data is not empty.
   *
   * @param {any} addOn - The add-on to be processed.
   * @param {any} product - The product associated with the add-on.
   * @returns {void}
   */
  pushToRquestAddon(addOn: any, product: any) {
    const catLevel = this.formatSubscriptionsData('catLevel', product, 'Addon', addOn);
    if (catLevel?.length > 0) {
      this.catLevelStruct.push(catLevel[0]);
    }
    const customerLevel = this.formatSubscriptionsData('customerLevel', product, 'Addon', addOn);
    if (customerLevel?.length > 0) {
      this.customerLevelStruct.push(customerLevel[0]);
    }
    const dealerLevel = this.formatSubscriptionsData('dealerLevel', product, 'Addon', addOn);
    if (dealerLevel?.length > 0) {
      this.dealerLevelStruct.push(dealerLevel[0]);
    }
  }
  /**
   * This function formats subscription data for a given product, type, and add-on.
   * It performs the following steps:
   * 1. Determines the current subscription based on the catLevel.
   * 2. Checks if the current subscription or current add-ons are not empty.
   * 3. Filters the selected subscriptions based on the selected level and type.
   * 4. Returns the formatted subscription data based on the type (Base or Addon) and the presence of the add-on.
   *
   * @param {keyof SubscriptionData} selectedLevel - The subscription level to format (e.g., 'catLevel', 'customerLevel', 'dealerLevel').
   * @param {any} product - The product containing subscription details.
   * @param {any} type - The type of subscription (e.g., 'Base', 'Addon').
   * @param {any} addon - The add-on to be processed.
   * @returns {Array} - The formatted subscription
   * */
  formatSubscriptionsData(selectedLevel: keyof SubscriptionData, product: any, type: any, addon: any) {
    const currentSubscription = product.subscription.currentSubscription.catLevel
      ? product.subscription.currentSubscription
      : product.subscription.previousSubscription;
    const { currentAddons } = product.addOns;
    if (!isEmpty(currentSubscription[selectedLevel]) || !isEmpty(currentAddons)) {
      let selectedSubscriptions = [currentSubscription[selectedLevel], ...currentAddons];
      if (selectedLevel !== 'catLevel') {
        selectedSubscriptions = selectedSubscriptions.filter(
          (subscription) => !includes(this.catOnlyAddons, subscription)
        );
      }
      if (
        (currentSubscription.catLevel || currentSubscription.customerLevel || currentSubscription.dealerLevel) ===
        'None'
      ) {
        return [];
      } else {
        if (
          type == 'Base' &&
          selectedSubscriptions.indexOf(product.subscription.currentSubscription.customerLevel) !== -1
        ) {
          return [
            {
              planName: product.subscription.currentSubscription.customerLevel
                ? product.subscription.currentSubscription.customerLevel
                : product.subscription.previousSubscription.customerLevel,
              planType: 'Base',
            },
          ];
        } else if (type == 'Addon' && selectedSubscriptions.indexOf(addon) !== -1) {
          return [
            {
              planName: addon,
              planType: 'Addon',
            },
          ];
        } else {
          return [];
        }
      }
    } else {
      return [];
    }
  }
  /**
   * This function validates and prepares the subscription combination for an asset.
   * It performs the following steps:
   * 1. Updates the asset's last updated time.
   * 2. Initializes an array for multi-product service subscriptions.
   * 3. Filters products based on their current or previous category level (catLevel) to include only those with a valid catLevel that is not 'None'.
   * 4. Iterates over the filtered products to prepare a subscription payload.
   * 5. Constructs a payload with asset details and subscription information.
   *
   * @returns {void}
   */
  SubscriptionCombination() {
    this.assetRowData.selectedAssetDetails.updatedTime = this.assetService.lastUpdatedTime
      ? this.assetService.lastUpdatedTime
      : this.assetRowData.updatedTime;
    this.multiProductServiceSubscribed = [];
    let newSelectedAssetSubscriptionFormData: any = {};
    for (const [key, value] of Object.entries(this.selectedAssetSubscriptionFormData.products)) {
      if (this.cancelAllPlansSelected && key == dspConstants.Worklist.NEW_VISION_LINK) {
        this.selectedAssetSubscriptionFormData.products[key] = cloneDeep(this.products);
      }
      let productObj: any = value;
      if (
        (productObj.subscription.currentSubscription.catLevel ||
          productObj.subscription.previousSubscription.catLevel) &&
        productObj.subscription.currentSubscription.catLevel !== 'None' &&
        this.cancelAllPlansSelected === false
      ) {
        newSelectedAssetSubscriptionFormData[key] = value;
      }
    }
    let selectedOrderId = 0;
    for (const [key, value] of Object.entries(newSelectedAssetSubscriptionFormData)) {
      selectedOrderId++;
      const products: any = value;
      this.getplansInPayload(products);
      this.multiProductServiceSubscribed.push({
        appId: products?.appId,
        selectedOrderId: selectedOrderId,
        appName: products.service.selectedService || key,
        catLevel: this.catLevelStruct,
        customerLevel: this.customerLevelStruct,
        dealerLevel: this.dealerLevelStruct,
        prepay: this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.prepaySelected
          ? this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.prepaySelected
          : false,
        contractStartDate: this.formatContractDates(
          'contractStartDate',
          this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.contractStartDate,
          dspConstants.Worklist.NEW_VISION_LINK
        )
          ? this.formatContractDates(
              'contractStartDate',
              this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.contractStartDate,
              dspConstants.Worklist.NEW_VISION_LINK
            )
          : null,
        contractEndDate: this.formatContractDates(
          'contractEndDate',
          this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.contractEndDate,
          dspConstants.Worklist.NEW_VISION_LINK
        )
          ? this.formatContractDates(
              'contractEndDate',
              this.selectedAssetSubscriptionFormData.products[key]?.prepay.currentPrepayData.contractEndDate,
              dspConstants.Worklist.NEW_VISION_LINK
            )
          : null,
      });
    }
    let payload: any = {
      assetId: `${this.assetRowData.selectedAssetDetails.make}|${this.assetRowData.selectedAssetDetails.serialNumber}`,
      commercialType: this.assetRowData.selectedAssetDetails.deviceType,
      model: this.assetRowData.selectedAssetDetails.model,
      siteId: this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].siteInfo?.siteId
        ? this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].siteInfo?.siteId
        : this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR].previousSiteInfo?.siteId ||
          '',
      // included in product level - check whether we need here or not
      prepay: this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
        .currentPrepayData.prepaySelected
        ? this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
            .currentPrepayData.prepaySelected
        : this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay.currentPrepayData
              .prepaySelected
          ? this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay.currentPrepayData
              .prepaySelected
          : false,
      contractStartDate: this.formatContractDates(
        'contractStartDate',
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay.currentPrepayData
          .contractStartDate,
        dspConstants.Worklist.NEW_VISION_LINK
      )
        ? this.formatContractDates(
            'contractStartDate',
            this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
              .currentPrepayData.contractStartDate,
            dspConstants.Worklist.NEW_VISION_LINK
          )
        : this.formatContractDates(
              'contractStartDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractStartDate,
              dspConstants.Worklist.VISION_LINK
            )
          ? this.formatContractDates(
              'contractStartDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractStartDate,
              dspConstants.Worklist.VISION_LINK
            )
          : null,
      contractEndDate: this.formatContractDates(
        'contractEndDate',
        this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay.currentPrepayData
          .contractEndDate,
        dspConstants.Worklist.NEW_VISION_LINK
      )
        ? this.formatContractDates(
            'contractEndDate',
            this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.NEW_VISION_LINK]?.prepay
              .currentPrepayData.contractEndDate,
            dspConstants.Worklist.NEW_VISION_LINK
          )
        : this.formatContractDates(
              'contractEndDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractEndDate,
              dspConstants.Worklist.VISION_LINK
            )
          ? this.formatContractDates(
              'contractEndDate',
              this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.VISION_LINK]?.prepay
                .currentPrepayData.contractEndDate,
              dspConstants.Worklist.VISION_LINK
            )
          : null,
      servicesSubscribedMultiProductDto: this.multiProductServiceSubscribed,
      catPrevLevel: '',
      dealerPrevLevel: '',
      customerPrevLevel: '',
      requestingDealerCode: this.dealer?.dealerCode,
      ucid: this.selectedAssetSubscriptionFormData.customer.ucid,
      ucName: this.selectedAssetSubscriptionFormData.customer.ucidName,
      dealerCustomerId: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      dcn: this.selectedAssetSubscriptionFormData.customer.dealerCustNo,
      updatedTimeStamp: this.getUpdatedTimeStamp(this.assetRowData?.selectedAssetDetails?.updatedTime || null)
        ? this.assetRowData.selectedAssetDetails.updatedTime
        : new Date().toJSON(),
      billToParty: this.checkB2CCustomer() ? dspConstants.Common.CUSTOMER : dspConstants.Common.DEALER,
    };
    const subsCancelReason = this.selectedAssetSubscriptionFormData.cancelOptions?.reason
      ? this.selectedAssetSubscriptionFormData.cancelOptions.reason
      : '';
    if (subsCancelReason !== '') {
      payload.catCancelReason = payload.dealerCancelReason = payload.customerCancelReason = subsCancelReason;
    }
    if (
      this.selectedAssetSubscriptionFormData.products[dspConstants.Worklist.MINESTAR]?.productFamily
        ?.currentProductFamily !== ''
    ) {
      payload.productFamily =
        this.selectedAssetSubscriptionFormData.products[
          dspConstants.Worklist.MINESTAR
        ]?.productFamily?.currentProductFamily;
    }
    if (this.launchType == 'LANDING') {
      payload.updatedTimeStamp = '';
    }
    this.assetService.subscribeMultiProdAsset(payload).subscribe({
      error: (error: any) => {
        if (error.status === 202) {
          this.getNewSubscriptionData = true;
          this.needsReviewService.setNeedsReviewAutoRefreshSubscriptionData(true);
          this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.SUBSCRIBED;
          const successResponse = 'Plan added successfully.';
          const successResponseForCancelSubs = 'Plan cancelled successfully.';
          const successResponseForEditSubs = 'Plan updated successfully.';
          const selectorId = this.launchType == 'LANDING' ? 'container-id' : 'manage-asset';
          const hostType = this.launchType == 'LANDING' ? 'overlay' : 'container-overlay';
          if (subsCancelReason !== '') {
            if (this.selectedAssetSubscriptionFormData.cancelOptions.subscriptionCommand == 'Suspend') {
              this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.SUSPENDED;
            } else {
              this.selectedAssetSubscriptionFormData.reportingState = dspConstants.Worklist.UNSUBSCRIBED;
            }
            this.showToastMessage(successResponseForCancelSubs, 'success', hostType, selectorId);
          } else if (this.isEditFlow) {
            this.saveAssetAgreement();
            this.showToastMessage(successResponseForEditSubs, 'success', hostType, selectorId);
          } else this.showToastMessage(successResponse, 'success', hostType, selectorId);

          if (!this.isEditFlow && this.isDealerActionRequiredPopupAgreed && subsCancelReason == '')
            this.saveAssetAgreement();
          else if (subsCancelReason !== '') {
            this.cancelAssetAgreement();
          }
          delete this.storedData[this.assetId];
          this.store.dispatch(getAssetDataSubscribedPlansEditResponse(this.storedData));
        } else {
          this.getNewSubscriptionData = false;
          this.showToastMessage(this.systemError, 'error');
        }
        this.closeDrawer();
      },
    });
  }
}
