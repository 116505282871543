/* eslint-disable no-case-declarations */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  isObject,
  isEmpty,
  cloneDeep,
  isArray,
  map,
  groupBy,
  first,
  size,
  hasIn,
  findIndex,
  isEqual,
  debounce,
} from 'lodash-es';
import { DSPAppState } from '../../../../store/state/dsp.state';
import * as dspConstants from '../../../../shared/dspConstants';
import { DspCommonService } from '../../../../services/dsp-common.service';
import { AssetDrawerService } from '../../../..//services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { DateFormattingService } from '../../../../services/date-formatting.service';
import { CommunicationModeService } from '../../../../services/communication-mode.service';
import { SubscriptionChangeService } from '../../../../services/subscription-change.service';
import { CatBillingService } from '../../../../services/catBillingService';
import { LoaderService } from '../../../../services/loader.service';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import {
  ApplicablePromoParam,
  BasePlanList,
  OptionalPlanList,
  MultiLevelPlans,
  ProductFamilyList,
  ApplicableSubscriptionsType,
  MineStarSiteSearchResultsType,
  AssetSubscriptionFormDataType,
  ProfileBasedAdditionalServices,
  SubscriptionData,
  MineStarSiteData,
  AssetSubscription,
} from '../../../../models/assets.interface';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { Subscription } from 'rxjs';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectPlanComponent implements OnInit {
  @ViewChild('b2cCustWarningTemplate') b2cCustWarningTemplate!: TemplateRef<any>;
  @ViewChild('catGradeConnectivityPopup') catGradeConnectivityPopup!: TemplateRef<any>;
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() isAutoSelectItemsRejected: any;
  @Output() redirectToSite = new EventEmitter();
  @Output() loadCancelScreen = new EventEmitter();
  @Output() updateTrackerIndexFor = new EventEmitter();
  @Output() updateTrackerIndex = new EventEmitter();
  datUrl = '';
  infoMessageManageAsset = `Telematics hardware must have current firmware and configurations to function properly. <a href="https://catdealer.com/en/bt/digital-business-strategy/digital-vision/dealer-services-portal/service-technicians-toolbox.html#tabs-ff82e23862-item-612755e470-tab" target="_blank" style="color:#2595FF; text-decoration:none; font-weight:400">More info</a>`;
  catGradeConnectivityMessage =
    'Grade Connectivity enables a cellular data stream for certain Grade functionality. Be aware that other subscriptions or registrations may be necessary for desired functionality. Grade Connectivity enables wireless streaming of Virtual Reference Station (VRS) Global Connectivity Satellite System (GNSS) corrections from a credentialed IP or web address for achieving a Real Time Kinematic fixed position, streaming of GNSS corrections from an Internet Based Satellite System (IBSS) associated with a registered Trimble Connected Community (TCC) account and asset, or transfer of files to and from a registered TCC asset.';
  manageAssetSubscriptionForm!: FormGroup;
  currentAssetSubscription!: FormGroup;
  selectSiteForm!: FormGroup;
  listOfService: any;
  catalogMapping: any;
  singleLevelServicesList: any = [];
  multiLevelServiceList: any = [];
  isSiteFound: any = [];
  list!: any[];
  isCancelView = false;
  listOfBasePlans!: Array<BasePlanList>;
  listOfProductFamily: Array<ProductFamilyList> = [];
  listOfOptionalPlans!: Array<OptionalPlanList>;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  multiLevelPlans: MultiLevelPlans = {
    customer: [],
    dealer: [],
    cat: [],
  };
  subs: AssetSubscription = {
    subsTitle: '',
    catLevel: {},
    dealerLevel: {},
    selectedCategory: '',
    dealerCancelReason: '',
    siteInfo: {},
    promoInfo: {
      promoValue: [],
      showPromoValue: true,
      showPromoLoad: false,
    },
    templateCheck: {
      custSubs: {
        disableServiceCheck: false,
      },
      dealerSubs: {
        disableServiceCheck: false,
      },
      catSubs: {
        disableServiceCheck: false,
      },
      addOns: {
        disableBtnCheck: false,
        disableAddOnListCheck: false,
      },
      prepayDisableCheck: false,
      prepayDisplayCheck: false,
      promotionDisplayCheck: true,
      contractDisplayCheck: true,
      showCatRfvWarning: false,
    },
    prepayEligible: false,
    prepaySelected: false,
    activeContract: false,
  };
  customerPlan = 'None';
  dealerPlan = 'None';
  catPlan = 'None';
  isPrepay: any;
  selectedAddOnOptions: Array<any> = [];
  subsInfo: any;
  profileBasedAdditionalServices: ProfileBasedAdditionalServices = {
    optionList: {},
    selectedCount: 0,
    optionListPrev: {},
    chkbxValChng: false,
  };
  addOnOptions: any;
  selectedService: any;
  productFamily: any = {
    selectedProductFamily: 'None',
    productFamilyList: [{ subscription: '', productFamily: [] }],
    selectedAddOnProductFamilyList: [],
  };
  modifiedProductFamily = true;
  inheritanceFlag = {
    dealerInherited: false,
    catInherited: false,
  };
  initialAddonSubscribed = [];
  isRatePlanExist = true;
  subsGradeChangeStatus = {
    Upgraded: 'Upgraded',
    Downgraded: 'Downgraded',
    NoChange: '',
  };
  subscriptionLevelCategory = {
    selectedCategory: '',
  };
  subsCategory: any;
  featureSupportedMapping: any;
  serviceMapping: any;
  dspConfig: any;
  userActiveFeatures: any;
  userPreferences: any;
  cvaEnabledList: any;
  featureSupportMsgEnabledList: any;
  applicableAddlSubscriptions: any;
  mockApplicableAddlSubscription: any;
  isNonBillableCust: any;
  subsPricingEligible!: boolean;
  RFEligibleList = [
    'Software Update Recommended',
    'Remote Software Download Failed',
    'Software Restore Failed',
    'Remote Flash Failed',
    'Telematics Update Recommended',
    'Equipment Updates Recommended',
    'Remote Flash Recommended for Equipment',
    'Remote Flash Recommended for Telematics',
  ];
  applicableSubscriptions: ApplicableSubscriptionsType = { level1: [], level2: [], level3: [] };
  primarySubscriptionService: any;
  telUserType: any;
  dealer: any;
  b2cCustomers: any;
  isContractPeriodChanged!: boolean;
  supportedServicesForAsset: any;
  isFsmSupportService: any;
  isFsmsupporteBaseSub: any;
  showOtoMessage = false;
  otoMessage: any;
  isDspAppMock: any;
  promo: any = { promoParams: {}, promoRetrieved: true };
  deviceFeaturesInfo: any;
  languageTranslations: any;
  changedServices: any;
  featuresToggleMapping: any;
  isNoticeOk: any = false;
  ccsEnabled: any;
  makeCodeSrch: any;
  ccsSubscription: any;
  ccsAlertFlag: any;
  selectedBillingAccount: any;
  commModes = ['Dual Mode', 'Satellite - 4 Hours', 'Cellular- Hourly'];
  dualModeInfo: any = {
    isDualModeSub: false,
    commModeSelected: this.commModes[0],
    isCommModeDisabledForDealer: true,
  };
  orderByFld = 'grade';
  selectedDate = new Date();
  currentYear = this.selectedDate.getFullYear();
  currentMonth = this.selectedDate.getMonth();
  currentDate = this.selectedDate.getDate();
  dateFormat = { value: 'mmm dd, yyyy', label: 'mmm dd, yyyy' };
  dateFieldConfig = {
    disabled: false,
    labelValue: '',
    width: '170px',
    isLeading: true,
    isTrailing: false,
    ariaDescribedBy: 'Select Date',
    required: true,
  };
  minDate = new Date(this.currentYear, this.currentMonth, this.currentDate - 9);
  maxDate = new Date(this.currentYear + 1, this.currentMonth, this.currentDate);
  datepickerStatus = { isValidDate: true, isValidFormat: true };
  contractStartDate = new Date();
  contractEndDate = new Date();
  setPrepay = false;
  siteName = '';
  siteValue = '';
  enableSearchOverlay = true;
  siteResults: MineStarSiteSearchResultsType[] = [];
  isListItemSelected = false;
  siteNotFound = false;
  showPromotion = false;
  isWarningMsgDisplayed!: any;
  isSuperAdmin!: any;
  featuresToggleMap!: any;
  billingAccounts: any[] = [];
  pFamilyList: any;
  PFamilyListData: any;
  cwsId!: any;
  dspConstants!: any;
  loadingPaymentPreview: any;
  isResetService = false;
  startDateFieldConfig!: any;
  endDateFieldConfig!: any;
  multiYearConfig = { yearsPerPage: 9, previousYearCount: 3 };
  currentServices: any[] = [];
  isOpen = true;
  catGradeModalRef: any;
  promoLoader = false;
  isSiteNameLoading = false;
  previousSiteSearch = '';
  isProductFamilyActive = false;
  promotionAPICallData!: Subscription;
  isPromotionAPICallCompleted = false;
  showCATGradePopup = false;
  enableAutoSelectAddon: any = [];
  autoSelectItem!: OptionalPlanList[];
  listOfOptionalPlansToShow: any;
  fetchedAutoAddons = false;
  showB2CWarningPopupInPaymentPreview = false;
  isNumberConventionEnabled: any;
  dspStoreData: any;
  isShowPriceIfNoBilling = false;
  dspHelpUrl = '';
  isChinaDealer = true;
  datExcludedProducts: any = [];
  isdatExcludedProductCheck = false;
  selectedDigitalAuthDetails: any;
  constructor(
    public formBuilder: FormBuilder,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private assetDrawerService: AssetDrawerService,
    private dateFormattingService: DateFormattingService,
    private assetService: AssetService,
    private commModeFactoryService: CommunicationModeService,
    public subscriptionChangeService: SubscriptionChangeService,
    private messageBar: MessageBar,
    private catBillingService: CatBillingService,
    private router: Router,
    private modal: CcModal,
    private loaderService: LoaderService,
    public changeDetector: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService,
    public dspUtilsService: DspUtilsCommonService
  ) {
    this.startDateFieldConfig = {
      disabled: true,
      labelValue: 'Select Date',
      width: '180px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Select Date',
      required: false,
    };
    this.endDateFieldConfig = {
      disabled: false,
      labelValue: 'Select Date',
      width: '180px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Select Date',
      required: false,
    };
  }
  updateTime() {
    this.assetService.getUpdatedTimeStamp(this.rowData?.assetId).subscribe((res: any) => {
      this.assetService.lastUpdatedTime = res.updatedTimeStampForUi;
    });
  }
  ngOnInit(): void {
    this.assetDrawerService.datExcludedProducts.subscribe((data: any) => {
      this.datExcludedProducts = data;
    });

    this.updateTime();
    this.dspHelpUrl =
      environment.redirectURL.helpDocUrl +
      'article/How-do-I-subscribe-multiple-products-on-the-same-asset?language=en_US';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.singleLevelServicesList = JSON.parse(dsp.dspConfig?.['Single_Level_Profile_AddOn_FSM']) || [];
      this.multiLevelServiceList = [];
      this.catalogMapping = dsp.deviceCatalog || {};
      this.featureSupportedMapping = dsp.featureSupportMapping || {};
      this.serviceMapping = dsp.serviceMapping || {};
      this.dspConfig = dsp.dspConfig || {};
      this.userActiveFeatures = dsp.userActiveFeatures?.features || {};
      this.enableAutoSelectAddon = JSON.parse(dsp.dspConfig?.['enableAutoAddon']) || [];
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo || {};
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.languageTranslations = dsp.languageTranslations || {};
      this.featuresToggleMap = dsp.featuresToggleMapping || {};
      this.billingAccounts = dsp.billingAccounts || [];
      this.userPreferences = dsp.userActiveFeatures?.userPreferences;
      this.pFamilyList = dsp.productFamily || {};
      delete this.pFamilyList.type;
      this.PFamilyListData = Object.keys(this.pFamilyList).map((key) => this.pFamilyList[key]);
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isShowPriceIfNoBilling = dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        ? dsp?.featureFlagInfo?.isShowPriceIfNoBilling
        : false;
      this.isdatExcludedProductCheck = dsp?.featureFlagInfo?.datExcludedProductCheck
        ? dsp?.featureFlagInfo?.datExcludedProductCheck
        : false;
    });

    this.selectedAssetSubscriptionFormData.isValidForm = false; // Need to update based on Next and Previous actions.
    const { reportingState } = this.rowData;
    this.rowData.cvaInfo = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails;
    const { customer } = this.selectedAssetSubscriptionFormData;
    const selectedOwnership = this.selectedAssetSubscriptionFormData.customer.ownershipId;

    if (reportingState === 'Subscribed' && !isEmpty(customer)) {
      this.updateTrackerIndex.emit();
      this.checkDatStatus();
    }
    this.selectedDigitalAuthDetails = this.getDigitalAUthorizationDetails(
      this.rowData?.ownershipDetails?.length > 0 && this.rowData?.ownershipDetails,
      selectedOwnership
    );
    if (this.selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE') {
      const linkDetail = [
        {
          label: 'Digital Authorization Tool',
          fn: () => {
            window.open(this.datUrl);
          },
        },
      ];
      const messageText =
        'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
      this.showDATToastMessage(messageText, 'error', linkDetail);
    }
    // if (
    //   this.rowData?.ownershipDetails?.length === 1 &&
    //   this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE'
    // ) {
    //   const linkDetail = [
    //     {
    //       label: 'Digital Authorization Tool',
    //       fn: () => {
    //         window.open(this.datUrl);
    //       },
    //     },
    //   ];
    //   const messageText =
    //     'Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take action through the';
    //   this.showDATToastMessage(messageText, 'error', linkDetail);
    // }
    this.updateTrackerIndexFor.emit(1);
    this.manageAssetSubscriptionForm = this.formBuilder.group({
      customerPlan: [''],
      dealerPlan: [''],
      catPlan: [''],
      addOnOptions: [''],
      selectedProductFamily: [''],
      mineStarSiteName: ['', [Validators.minLength(3)]],
      // mineStarSiteName: [this.updateSelectedSiteName(), [Validators.minLength(3)]],
    });
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
      this.cwsId = user.user?.cwsId || '';
      this.isSuperAdmin = user.user?.superAdministratorUser || '';
    });
    this.showB2CWarningPopupInPaymentPreview = this.dspStoreData?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
      ? this.dspStoreData?.featureFlagInfo?.showB2CWarningPopupInPaymentPreview
      : false;
    this.dealer = fetchLoggedInDealerDetails();
    this.dspConstants = dspConstants;
    this.subsCategory = {
      //for subscription levels/categories
      Single_Level_Minestar: [dspConstants.Worklist.MINESTAR],
      Single_Level_Ecalegacy: [dspConstants.Worklist.ECA_LEGACY],
      Subscription_Remoteflash: [dspConstants.Worklist.REMOTE_FLASH],
      Subscription_Remotetroubleshoot: [dspConstants.Worklist.REMOTE_TROUBLESHOOT],
      Single_Level_Profile_AddOn_FSM: this.singleLevelServicesList,
      Multi_Level_Profile_AddOn_FSM: this.multiLevelServiceList,
    };
    if (this.isShowPriceIfNoBilling) {
      this.subsPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        this.userActiveFeatures?.indexOf('View Only') == -1 &&
        this.userActiveFeatures?.indexOf('Read Only') == -1 &&
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1;
    } else {
      this.subsPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    }
    if (!isEmpty(this.rowData.deviceType)) {
      const validateOnRes = () => {
        this.validateServiceOnLoad();
      };
      this.getSupportedServicesFn(validateOnRes);
    }
    this.setAssetFormData();
    this.assetSubscriptionFormChange();
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.getDealerParentAndChildfeatures();
  }

  getDigitalAUthorizationDetails(ownershipArr: any, selectedOwnershipId: any) {
    if (ownershipArr && ownershipArr.length > 0) {
      const selectedItem = ownershipArr?.find((item: any) => item.ownershipId === selectedOwnershipId);
      return selectedItem ? selectedItem?.digitalAuthorizationDetails : null;
    }
  }

  updateSelectedSiteName() {
    const { siteName, siteId } = this.selectedAssetSubscriptionFormData.siteInfo;
    if (siteName !== '') {
      this.previousSiteSearch = siteName;
      return [
        {
          id: siteId,
          label: siteName,
          value: siteName,
        },
      ];
    }
    return '';
  }

  setAssetFormData() {
    const { applicationName } = this.rowData;
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const loggedInUserSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    const { currentSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    if (loggedInUserSubscriptionId && applicationName) {
      this.selectedAssetSubscriptionFormData.service.currentService = applicationName;
      this.selectedAssetSubscriptionFormData.service.previousService = applicationName;
      if (
        currentSubscription.catLevel === 'None' ||
        currentSubscription.customerLevel === 'None' ||
        currentSubscription.dealerLevel === 'None'
      ) {
        this.isCancelView = true;
      }
    }
    if (loggedInUserSubscriptionId && currentSubscription.customerLevel === '') {
      const currentSubscriptionBasePlanData = hasIn(this.serviceMapping, loggedInUserSubscriptionId)
        ? this.serviceMapping?.[loggedInUserSubscriptionId]?.baseSubscription || ''
        : '';
      if (currentSubscriptionBasePlanData) {
        const subLevels: any = ['customerLevel', 'dealerLevel', 'catLevel'];
        subLevels.forEach((subLevel: keyof SubscriptionData) => {
          this.selectedAssetSubscriptionFormData.subscription.currentSubscription[subLevel] =
            currentSubscriptionBasePlanData;
          this.selectedAssetSubscriptionFormData.subscription.previousSubscription[subLevel] =
            this.setPreviousSubscriptionBasePlan(subLevel, currentSubscriptionBasePlanData);
        });
      }
      const currentSubscriptionAddonData = hasIn(this.serviceMapping, loggedInUserSubscriptionId)
        ? this.serviceMapping?.[loggedInUserSubscriptionId]?.additionalService || []
        : [];
      if (currentSubscriptionAddonData?.length > 0) {
        const subscribedAddonsData: any = [];
        currentSubscriptionAddonData?.forEach((additionalPlan: any) => {
          subscribedAddonsData.push(additionalPlan);
        });
        this.selectedAssetSubscriptionFormData.addOns.currentAddons = subscribedAddonsData;
        this.selectedAssetSubscriptionFormData.addOns.previousddons =
          this.setPreviousSubscriptionAddon(subscribedAddonsData);
      }
      if (applicationName === dspConstants.Worklist.MINESTAR) {
        this.setMineStarInfo();
      }
      const { prepay, contractStartDate, contractEndDate } = this.rowData;
      if (prepay && !isEmpty(contractStartDate) && !isEmpty(contractEndDate)) {
        this.addPrepayDates();
      }
    }
  }

  setPreviousSubscriptionBasePlan(subLevel: keyof SubscriptionData, currentSubscriptionBasePlan: any) {
    const { reportingState, customerSubscriptionId } = this.rowData;
    const { previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    if (previousSubscription[subLevel] !== '') {
      return previousSubscription[subLevel];
    }
    if (reportingState === dspConstants.Worklist.SUBSCRIBED && customerSubscriptionId) {
      return currentSubscriptionBasePlan;
    }
  }

  setPreviousSubscriptionAddon(scribedAddonsData: any) {
    const { reportingState, customerSubscriptionId } = this.rowData;
    const { previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (previousddons.length !== 0) {
      return previousddons;
    }
    if (reportingState === dspConstants.Worklist.SUBSCRIBED && customerSubscriptionId) {
      return scribedAddonsData;
    }
  }

  setProductFamilyOption(productFamilyType: string) {
    const { productFamily } = this.rowData;
    if (!productFamily) {
      return '';
    }
    if (productFamilyType === 'currentProductFamily') {
      const { currentProductFamily } = this.selectedAssetSubscriptionFormData.productFamily;
      return currentProductFamily !== '' ? currentProductFamily : productFamily;
    }
    if (productFamilyType === 'previousProductFamily') {
      return productFamily;
    }
  }

  setMineStarInfo() {
    const { siteId, productFamily } = this.rowData;
    if (siteId) {
      const getSitesPayload = {
        id: this.rowData?.siteId,
      };
      this.assetService.getSitesById(getSitesPayload).subscribe({
        next: (result: any) => {
          this.subs.siteInfo.listedSites = [result];
          this.subs.siteInfo.siteVal = result;
          this.subs.siteInfo.isValidSite = true;
          this.rowData.siteVal = cloneDeep(this.subs?.siteInfo?.siteVal);
          this.selectedAssetSubscriptionFormData.siteInfo.siteId = result.id;
          this.selectedAssetSubscriptionFormData.siteInfo.siteName = result.name;
          (this.selectedAssetSubscriptionFormData.previousSiteInfo as MineStarSiteData).siteId = result.id;
          (this.selectedAssetSubscriptionFormData.previousSiteInfo as MineStarSiteData).siteName = result.name;
          this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
    if (productFamily) {
      this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily =
        this.setProductFamilyOption('currentProductFamily');
      this.selectedAssetSubscriptionFormData.productFamily.previousProductFamily =
        this.setProductFamilyOption('previousProductFamily');
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    }
  }

  setAssetSubscriptionFormData(formData: AssetSubscriptionFormDataType) {
    this.assetService.setAssetSubscriptionFormData(formData);
  }

  checkDatStatus() {
    const ownershipData =
      this.rowData?.ownershipDetails?.filter((owner: any) => {
        return owner.checked;
      }) || [];
    if (!isEmpty(ownershipData) && ownershipData[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== 'AGREE') {
      const messageText = `Customer has declined authorization. In order to enable subscription management, authorize through the  Digital Authorization Tool`;
      this.showToastMessage(messageText, 'error');
    }
  }

  get formControl() {
    return this.manageAssetSubscriptionForm.controls;
  }

  setFormValue(formControlType: string, formControlValue: any, serviceLevel: keyof SubscriptionData) {
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription[serviceLevel] = formControlValue;
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    const selectedFormLevelInfo = this.fetchSelectedFormLevel(formControlType);
    let selectedLevelData = [],
      filteredsubscriptionData = [];
    if (!isEmpty(selectedFormLevelInfo)) {
      selectedLevelData = this.applicableSubscriptions[
        selectedFormLevelInfo as keyof ApplicableSubscriptionsType
      ]?.filter((subcription: any) => subcription.baseSubscription === formControlValue);
      filteredsubscriptionData = selectedLevelData.map((plan: any) => ({
        label: this.showBaseRatePlan(plan)
          ? plan?.baseSubscription + ' - ' + this.convertNumberFormat(plan?.price)
          : plan?.baseSubscription,
        value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' - ' + plan?.price : plan?.baseSubscription,
        grade: plan?.grade,
        isSubscribable: plan?.isSubscribable,
        price: plan?.price,
        baseSubscription: plan?.baseSubscription,
      }));
      this.manageAssetSubscriptionForm.get(formControlType)?.patchValue(filteredsubscriptionData);
    }
  }

  fetchSelectedFormLevel(formControlType: string) {
    switch (formControlType) {
      case 'customerPlan':
        return dspConstants.AssetSubscriptionConstants.Level1;
      case 'dealerPlan':
        return dspConstants.AssetSubscriptionConstants.Level2;
      case 'catPlan':
        return dspConstants.AssetSubscriptionConstants.Level3;
      default:
        return '';
    }
  }

  fetchSubscriptionLevelMap(subscriptionProperty: any) {
    switch (subscriptionProperty) {
      case 'custSubs':
        return this.customerPlan;
      case 'dealerSubs':
        return this.dealerPlan;
      case 'catSubs':
        return this.catPlan;
      default:
        return this.customerPlan;
    }
  }

  assetSubscriptionFormChange() {
    this.manageAssetSubscriptionForm.get('customerPlan')?.valueChanges.subscribe((value) => {
      if (size(value) > 0) {
        this.customerPlan = value[0].baseSubscription;
      }
    });
    this.manageAssetSubscriptionForm.get('dealerPlan')?.valueChanges.subscribe((value) => {
      if (size(value) > 0) {
        this.dealerPlan = value[0].baseSubscription;
      }
    });
    this.manageAssetSubscriptionForm.get('catPlan')?.valueChanges.subscribe((value) => {
      if (size(value) > 0) {
        this.catPlan = value[0].baseSubscription;
      }
    });
  }

  triggerValChange(triggerCheck: null | string = null) {
    const { selectedCategory } = this.subscriptionLevelCategory;
    if (
      this.promo.promoRetrieved &&
      !isEmpty(this.selectedService) &&
      hasIn(this.subsCategory, selectedCategory) &&
      (!isEqual(triggerCheck, '') || !this.subsPricingEligible)
    ) {
      const subsInfo = this.getAllSubsInfoFn(this.rowData.deviceType, this.selectedService);
      // DAT status check
      const datStatusResult = this.validateDatCheck();
      // Subscription updated check.
      const isSubscriptionChanged = this.validateSubscriptionChange(subsInfo);
      // Prepay updated check.
      const isPrepayChanged = this.validatePrepayChange(subsInfo, isSubscriptionChanged);
      this.selectedAssetSubscriptionFormData.isValidForm =
        datStatusResult && (isSubscriptionChanged || isPrepayChanged);
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
    const isAutoAddonSubsSelected =
      this.enableAutoSelectAddon?.length && this.enableAutoSelectAddon.indexOf(this.customerPlan) > -1;
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    if (
      (!savedSubscriptionId ||
        (savedSubscriptionId && this.serviceMapping[savedSubscriptionId].baseSubscription != this.customerPlan)) &&
      isAutoAddonSubsSelected &&
      !this.fetchedAutoAddons
    ) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else {
      if (this.enableAutoSelectAddon?.length && this.enableAutoSelectAddon.indexOf(this.customerPlan) > -1) {
        this.loaderService.hide();
      }
    }
    //For minestar product family check
    this.checkMinestarProductFamily();
    // Update the parent component isValidForm condition and call the subscribe function
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.checkIfSelectionChange();
  }
  checkMinestarProductFamily() {
    const { productFamily, service } = this.selectedAssetSubscriptionFormData;
    if (
      service.currentService === 'MineStar' &&
      this.isProductFamilyActive === true &&
      productFamily.currentProductFamily == '' &&
      productFamily.previousProductFamily == ''
    ) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
    if (!this.subs.siteInfo.isValidSite && service.currentService === 'MineStar') {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
  }
  validateDatCheck() {
    if (this.validateDatStatus()) {
      return true;
    } else {
      return false;
    }
  }

  validateDatStatus() {
    const selectedCustomerDetails = this.getValidCustomerForAsset();
    return (
      size(selectedCustomerDetails) === 1 && selectedCustomerDetails[0]?.dealerCode === this.dealer.dealerCode
      //  &&
      // selectedCustomerDetails[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus !== 'DECLINE'
    );
  }

  validateSubscriptionChange(subsInfo: any) {
    const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (
      (this.selectedService === dspConstants.Worklist.MINESTAR && this.validateMinestarChanges(subsInfo)) ||
      currentSubscription.catLevel !== previousSubscription.catLevel ||
      currentSubscription.dealerLevel !== previousSubscription.dealerLevel ||
      currentSubscription.customerLevel !== previousSubscription.customerLevel ||
      !isEqual(currentAddons, previousddons) ||
      (this.rowData?.siteId && subsInfo?.siteId && !isEqual(this.rowData?.siteId, subsInfo?.siteId))
    ) {
      return true;
    } else {
      return false;
    }
  }

  validatePrepayChange(subsInfo: any, isSubscriptionChanged: any) {
    const { currentPrepayData, previousPrepayData } = this.selectedAssetSubscriptionFormData.prepay;
    if (
      previousPrepayData.prepaySelected !== currentPrepayData.prepaySelected ||
      (currentPrepayData.prepaySelected && previousPrepayData.contractEndDate !== currentPrepayData.contractEndDate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateMinestarChanges(subsInfo: any) {
    const { rowData } = this;
    if (this.subscriptionChangeService.isProductFamilyChangedFn(this, rowData, subsInfo)) {
      return (
        rowData.productFamily &&
        rowData.selectedProductFamily !== 'None' &&
        rowData.productFamily !== rowData.selectedProductFamily
      );
    } else if (this.subs && this.subs.siteInfo && !this.subs.siteInfo.isValidSite) {
      return false;
    }
  }

  listAssetProductFamilyFn() {
    if (this.PFamilyListData && this.PFamilyListData.length) {
      this.productFamily.productFamilyList = this.PFamilyListData;
      if (
        this.rowData?.customerSubscriptionId &&
        this.rowData?.productFamily &&
        this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily == ''
      ) {
        this.validateAddOnForProductFamily();
      }
    }
  }

  redirectToSitePage() {
    this.redirectToSite.emit(true);
    this.router.navigateByUrl('/sites');
  }
  getGradeFromSubscriptionServiceMap(subscription: any, addOnServices: any) {
    const serviceMap: any = this.serviceMapping;
    return Object.keys(serviceMap).reduce(function (obj: any, currValue: any) {
      if (
        serviceMap[currValue].baseSubscription &&
        serviceMap[currValue].baseSubscription == subscription &&
        addOnServices.length == serviceMap[currValue].additionalService.length
      ) {
        const addLength = addOnServices.filter(function (val: any) {
          return serviceMap[currValue].additionalService.indexOf(val) != -1;
        }).length;
        if (addLength == addOnServices.length) {
          obj.push(serviceMap[currValue]);
        }
      }
      return obj;
    }, []);
  }
  isB2CDealer() {
    // Check if asset belongs to b2C and CAT CONNECT SUPPORT is enabled when logged in as Dealer
    const customerInfo = this.getValidCustomerForAsset();
    if (
      customerInfo &&
      customerInfo.length == 1 &&
      customerInfo[0].ucid &&
      this.b2cCustomers &&
      this.b2cCustomers.ucids &&
      this.b2cCustomers.applns &&
      this.b2cCustomers.ucids.indexOf(customerInfo[0].ucid) != -1 &&
      this.b2cCustomers.applns.indexOf(this.selectedService) != -1
    ) {
      const filteredCCS = this.b2cCustomers.ccsCustomers.filter(
        (customer: any) => customer.ucid === customerInfo[0].ucid
      );
      return filteredCCS.length > 0;
    }
    return false;
  }

  getValidCustomerForAsset() {
    const { customer } = this.selectedAssetSubscriptionFormData;
    return this.dspUtilsService.getValidCustomerForAsset(customer);
  }
  toCheckPopupEnableAtLevel(selectedService: any, infoSubs: any) {
    if (
      (selectedService == dspConstants.Worklist.EQUIPMENT_INSIGHTS ||
        selectedService == dspConstants.Worklist.VIMS_TRANSMITTER) &&
      ((infoSubs.catSubs.subscriptionLevel !== 'None' &&
        infoSubs.catSubs.subscriptionLevel !== infoSubs.catSubsPrev.subscriptionLevel) ||
        (infoSubs.dealerSubs.subscriptionLevel !== 'None' &&
          infoSubs.dealerSubs.subscriptionLevel !== infoSubs.dealerSubsPrev.subscriptionLevel) ||
        (infoSubs.custSubs.subscriptionLevel !== 'None' &&
          infoSubs.custSubs.subscriptionLevel !== infoSubs.custSubsPrev.subscriptionLevel))
    ) {
      return true;
    }
    return false;
  }
  onDatePickerStatusEx1($event: any) {
    this.datepickerStatus = { isValidDate: $event.isValidDate, isValidFormat: $event.isValidFormat };
  }

  onDateChange(dateValue: any) {
    const { event, contractDateType } = dateValue;
    if (contractDateType === 'contractStartDate') {
      this.subs.contractPeriod.startDate = event;
    } else if (contractDateType === 'contractEndDate') {
      this.subs.contractPeriod.endDate = event;
      this.contractEndDateChange();
    }
  }

  setServicelevel(serviceLabel: string, serviceType: string) {
    return this.subsCategory && this.subsCategory[serviceType]?.indexOf(serviceLabel) > -1;
  }

  validateServiceOnLoad() {
    //loading list of services on template load
    this.listOfService = [];
    if (this.getOptedService()) {
      this.addNoneSubscription();
    }
  }
  sortPlansBasedOnGrade(arr: any) {
    //sorting plans based on grade.
    arr?.sort((a: BasePlanList, b: BasePlanList) => {
      return a.grade - b.grade;
    });

    return arr;
  }
  setBasePlans() {
    const { selectedCategory } = this.subscriptionLevelCategory;
    if (
      selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR ||
      selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      this.listOfBasePlans = [];
      this.applicableSubscriptions?.level1?.forEach((plan: any) => {
        this.listOfBasePlans.push({
          label: this.showBaseRatePlan(plan)
            ? plan?.baseSubscription + ' - ' + this.convertNumberFormat(plan?.price)
            : plan?.baseSubscription,
          value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' - ' + plan?.price : plan?.baseSubscription,
          grade: plan?.grade,
          isSubscribable: plan?.isSubscribable,
          price: plan?.price,
          baseSubscription: plan?.baseSubscription,
        });
      });
      if (this.listOfBasePlans?.length > 1) {
        this.listOfBasePlans = this.sortPlansBasedOnGrade(this.listOfBasePlans);
      }
    } /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
    else if (selectedCategory === dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM) {
      this.multiLevelPlans = {
        customer: [],
        dealer: [],
        cat: [],
      };
      this.applicableSubscriptions?.level1?.forEach((plan: any) => {
        this.multiLevelPlans.customer.push({
          label: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          grade: plan?.grade,
          isSubscribable: plan?.isSubscribable,
          price: plan?.price,
          baseSubscription: plan?.baseSubscription,
        });
      });
      if (this.multiLevelPlans?.customer?.length > 1) {
        this.multiLevelPlans.customer = this.sortPlansBasedOnGrade(this.multiLevelPlans.customer);
      }
      this.applicableSubscriptions?.level2?.forEach((plan: any) => {
        this.multiLevelPlans.dealer.push({
          label: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          grade: plan?.grade,
          isSubscribable: plan?.isSubscribable,
          price: plan?.price,
          baseSubscription: plan?.baseSubscription,
        });
      });
      if (this.multiLevelPlans?.dealer?.length > 1) {
        this.multiLevelPlans.dealer = this.sortPlansBasedOnGrade(this.multiLevelPlans.dealer);
      }
      this.applicableSubscriptions?.level3?.forEach((plan: any) => {
        this.multiLevelPlans.cat.push({
          label: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' ~ ' + plan?.price : plan?.baseSubscription,
          grade: plan?.grade,
          isSubscribable: plan?.isSubscribable,
          price: plan?.price,
          baseSubscription: plan?.baseSubscription,
        });
      });
      if (this.multiLevelPlans?.cat?.length > 1) {
        this.multiLevelPlans.cat = this.sortPlansBasedOnGrade(this.multiLevelPlans.cat);
      }
    }*/
  }

  setOptionalPlans() {
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    this.fetchedAutoAddons = false;
    this.listOfOptionalPlans = [];
    this.applicableAddlSubscriptions?.forEach((plan: any) => {
      this.listOfOptionalPlans.push({
        label: this.showOptionalRatePlan(plan)
          ? plan?.serviceName + ' - ' + this.convertNumberFormat(plan?.price)
          : plan?.serviceName,
        value: this.showOptionalRatePlan(plan) ? plan?.serviceName + ' - ' + plan?.price : plan?.serviceName,
        isSubscribable: plan?.isSubscribable,
        price: plan?.price,
        serviceName: plan?.serviceName,
        autoSelect: false,
      });
    });
    if (
      currentAddons.length == 0 &&
      this.listOfOptionalPlans.length &&
      this.enableAutoSelectAddon?.length &&
      this.enableAutoSelectAddon.indexOf(this.customerPlan) > -1
    ) {
      let custInfo = { dcnNumber: '', dcnName: '', ucid: '', customerName: '' };
      const validCustForAsset = this.getValidCustomerForAsset();
      if (validCustForAsset?.length == 1) {
        custInfo = {
          dcnNumber: validCustForAsset[0]?.dealerCustNo,
          dcnName: validCustForAsset[0]?.dealerCustName,
          ucid: validCustForAsset[0]?.ucid,
          customerName: validCustForAsset[0]?.ucidName,
        };
      }
      const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
      const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
      this.listOfOptionalPlansToShow = [];
      this.listOfOptionalPlans?.forEach((plans: any) => this.listOfOptionalPlansToShow.push(plans.serviceName));
      const input = {
        dealerCode: this.dealer.dealerCode,
        ucid: custInfo?.ucid,
        productName: this.selectedService,
        make: this.rowData.make,
        serialNumber: this.rowData.serialNumber,
        deviceType: this.rowData.deviceType,
        radioSerialNumber: this.rowData.radioSerialNoList,
        cva: false,
        subscriptions: this.listOfOptionalPlansToShow,
      };
      this.loaderService.show();
      this.selectedAssetSubscriptionFormData.isValidForm = false;
      if (!this.isAutoSelectItemsRejected) {
        this.assetService.getZeroPricedAddons(input).subscribe({
          next: (result: any) => {
            this.loaderService.hide();
            this.selectedAssetSubscriptionFormData.isValidForm = true;

            if (
              !savedSubscriptionId ||
              (savedSubscriptionId && this.serviceMapping[savedSubscriptionId].baseSubscription != this.customerPlan)
            ) {
              this.fetchedAutoAddons = true;
              this.selectedAssetSubscriptionFormData.isValidForm = true;
              this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              this.listOfOptionalPlans.forEach(function (obj) {
                if (result?.length && result.indexOf(obj.serviceName) != -1) obj.autoSelect = true;
              });
              this.autoSelectItem = this.listOfOptionalPlans.filter((resObj: any) => resObj.autoSelect);
              const optionalPlanEvent = {
                prop: 'addOnOptions',
                event: { action: 'checkbox', currentSelection: this.autoSelectItem },
              };
              if (this.autoSelectItem.length) {
                this.onOptionalPlanChange(optionalPlanEvent);
              } else {
                this.loaderService.hide();
              }
            }
          },
          error: (err: any) => {
            this.loaderService.hide();
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
            this.fetchedAutoAddons = true;
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else {
        this.fetchedAutoAddons = true;
        this.selectedAssetSubscriptionFormData.isValidForm = true;
        this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
    } else {
      this.loaderService.hide();
      this.listOfOptionalPlans.forEach((obj: any) => {
        if (currentAddons?.length && currentAddons.indexOf(obj?.serviceName) != -1) obj.autoSelect = true;
      });
      this.autoSelectItem = this.listOfOptionalPlans.filter((resObj: any) => resObj.autoSelect);
      this.fetchedAutoAddons = this.autoSelectItem.length > 0 ? true : false;
    }
  }

  showBaseRatePlan(plan: any) {
    const { customerLevel } = this.selectedAssetSubscriptionFormData.subscription.previousSubscription;
    return this.subsPricingEligible &&
      this.isNonBillableCust &&
      plan?.baseSubscription != customerLevel &&
      plan?.baseSubscription != 'None' &&
      plan?.price
      ? true
      : false;
  }
  showOptionalRatePlan(plan: any) {
    return this.subsPricingEligible &&
      this.isNonBillableCust &&
      !this.profileBasedAdditionalServices?.optionListPrev[plan.serviceName] &&
      plan?.price
      ? true
      : false;
  }

  setSubscriptionDataSource(
    selectedLevel: keyof ApplicableSubscriptionsType | string,
    selectPlan: keyof MultiLevelPlans | string
  ) {
    const updatedSubscriptionDataSource = this.applicableSubscriptions[
      selectedLevel as keyof ApplicableSubscriptionsType
    ].map((plan: any) => ({
      label: this.showBaseRatePlan(plan)
        ? plan?.baseSubscription + ' - ' + this.convertNumberFormat(plan?.price)
        : plan?.baseSubscription,
      value: this.showBaseRatePlan(plan) ? plan?.baseSubscription + ' - ' + plan?.price : plan?.baseSubscription,
      grade: plan?.grade,
      isSubscribable: plan?.isSubscribable,
      price: plan?.price,
      baseSubscription: plan?.baseSubscription,
    }));
    this.multiLevelPlans[selectPlan as keyof MultiLevelPlans] =
      this.sortPlansBasedOnGrade(updatedSubscriptionDataSource);
  }

  addNoneSubscription() {
    if (isEmpty(this.rowData.deviceType)) {
      return;
    }
    const addNoneAttr =
      this.rowData?.deviceType && this.catalogMapping[this.rowData?.deviceType]
        ? this.catalogMapping[this.rowData?.deviceType][this.selectedService || dspConstants.Worklist.VISION_LINK]
        : {};
    if (addNoneAttr) {
      const subTypes = Object.keys(addNoneAttr);
      for (let i = 0; i < subTypes.length; i++) {
        if (
          addNoneAttr[subTypes[i]] &&
          isObject(addNoneAttr[subTypes[i]]) &&
          Object.keys(addNoneAttr[subTypes[i]]).length > 0
        ) {
          addNoneAttr[subTypes[i]]['None'] = { grade: '0', baseSubscription: 'None', isSubscribable: true };
        }
      }
    }
  }
  getOptedService() {
    this.selectedService = '';
    if (!this.rowData?.deviceType || !this.catalogMapping?.[this.rowData?.deviceType]) {
      return false;
    }
    //Services Dropdown Listing
    let serviceList =
      this.rowData.deviceType && this.catalogMapping[this.rowData.deviceType]
        ? Object.keys(this.catalogMapping[this.rowData.deviceType])
        : []; //loads values for service drop-down
    serviceList = serviceList?.filter((item: any) => {
      return this.checkForSubscribables(item);
    });
    // Default Service
    const subId =
      this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId || this.rowData?.catSubscriptionId;
    if (this.serviceMapping && subId) {
      if (this.serviceMapping[subId.toString()]) {
        this.selectedService = this.serviceMapping[subId.toString()].appName;
      }
    } else if (this.rowData?.deviceType?.indexOf('PLG') == 0) {
      this.selectedService = dspConstants.Worklist.PRODUCT_LINK_GENERATION;
    }
    this.currentServices = [];
    if (this.rowData.reportingState === 'Subscribed') {
      const currentService = this.selectedService;
      serviceList = serviceList.filter(function (value) {
        return value !== currentService;
      });
      this.currentServices.push(currentService);
      this.selectedAssetSubscriptionFormData.service.currentService = currentService;
    }
    this.listOfService = serviceList;
    //Remote Asset Monitoring
    if (this.listOfService?.includes(dspConstants.Worklist.REMOTE_ASSET_MONITORING)) {
      this.isFsmsupporteBaseSub = this.checkIfFSMExistForService(
        dspConstants.Worklist.REMOTE_ASSET_MONITORING,
        this.rowData?.deviceType
      );
      if (!this.rowData?.isFsmSupportService) {
        this.listOfService = this.listOfService?.filter((service: any) => {
          return service != dspConstants.Worklist.REMOTE_ASSET_MONITORING;
        });
        if (!this.isFsmsupporteBaseSub) {
          this.listOfService?.push(dspConstants.Worklist.REMOTE_ASSET_MONITORING);
        }
        if (
          this.serviceMapping[this.rowData?.customerSubscriptionId] &&
          this.listOfService.indexOf(dspConstants.Worklist.REMOTE_ASSET_MONITORING) == -1
        ) {
          this.listOfService?.push(dspConstants.Worklist.REMOTE_ASSET_MONITORING);
        }
      }
    }
    if (this.listOfService?.length >= 1) {
      // sorting services to display in ascending order.
      this.listOfService = this.listOfService.sort((a: any, b: any) => {
        return a.localeCompare(b);
      });
      this.listOfService = this.listOfService.map((service: string) => ({
        disabled: this.disableServiceCheck(service),
        name: service,
        expand: this.expandServiceCheck(service),
      }));
    }
    if (!isEmpty(this.currentServices)) {
      this.currentServices = this.currentServices.map((service: string) => ({
        disabled: false,
        name: service,
        expand: this.expandServiceCheck(service),
      }));
    }
    return true;
  }

  expandServiceCheck(serviceName: any) {
    // To expand the already expanded service/product in Select Plan.
    const { expandedService } = this.selectedAssetSubscriptionFormData;
    if (!isEmpty(expandedService) && expandedService === serviceName) {
      return true;
    } else {
      return false;
    }
  }

  disableServiceCheck(service: string) {
    if (this.selectedAssetSubscriptionFormData.customer === 'None') {
      return !(
        service === dspConstants.Worklist.EQUIPMENT_INSIGHTS || service === dspConstants.Worklist.VIMS_TRANSMITTER
      );
    } else {
      if (
        this.rowData?.ownershipDetails?.length === 1 &&
        this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus === 'DECLINE'
      ) {
        if (this.datExcludedProducts.includes(service)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  checkForSubscribables(subSelected: any) {
    //for checking isSubscribable flag of base plans under each service

    if (
      subSelected === dspConstants.Worklist.REMOTE_FLASH ||
      subSelected === dspConstants.Worklist.REMOTE_TROUBLESHOOT ||
      (subSelected === dspConstants.Worklist.CAT_REMOTE_FLEET_VISION &&
        this.checkIfFSMExistForService(subSelected, this.rowData?.deviceType))
    ) {
      return true;
    }
    const dataHldr: any = [];
    const subsProfile = dspConstants.Common.CUSTOMER;
    const mapInfo = this.catalogMapping[this.rowData?.deviceType][subSelected]
      ? cloneDeep(this.catalogMapping[this.rowData?.deviceType][subSelected][subsProfile])
      : '';
    const getAllKeys = Object.keys(mapInfo);
    getAllKeys.forEach((val: any) => {
      if (
        this.featureSuprtMsgDeviceValidation(mapInfo[val], subSelected, subsProfile.toLowerCase() + 'SubsriptionId')
      ) {
        dataHldr.push(mapInfo[val]);
      }
    });
    //Check for subscribable with true or matched base subcriptions other than 'None'.
    let valid = false;
    const subId =
      this.rowData?.customerSubscriptionId || this.rowData?.catSubscriptionId || this.rowData?.dealerSubscriptionId;
    for (let optCnt = 0, lengthopt = dataHldr.length; optCnt < lengthopt; optCnt++) {
      if (
        //for unsubscribed
        (dataHldr[optCnt]?.isSubscribable && dataHldr[optCnt]?.baseSubscription !== 'None') ||
        //for subscribed
        (subId &&
          this.serviceMapping[subId]?.baseSubscription === dataHldr[optCnt]?.baseSubscription &&
          dataHldr[optCnt]?.baseSubscription !== 'None')
      ) {
        valid = true;
        break;
      }
    }
    return valid;
  }
  checkIfFSMExistForService(subSelected: any, deviceType: any) {
    const subsProfile = dspConstants.Common.CUSTOMER;
    const mapInfo = this.catalogMapping[deviceType][subSelected]
      ? { ...this.catalogMapping[deviceType][subSelected][subsProfile] }
      : {};

    const getAllKeys = Object.keys(mapInfo);
    let isFeatureMsgSupported = false;
    getAllKeys.forEach((val) => {
      if (Object.keys(mapInfo[val]).indexOf('isFeatureMsgSupported') != -1 && mapInfo[val]?.isFeatureMsgSupported) {
        isFeatureMsgSupported = true;
      }
    });
    return isFeatureMsgSupported;
  }
  featureSuprtMsgDeviceValidation(mapInfoVal: any, subsSelected: any, subsKey: any) {
    //for toggling isSubscribable flag based on isFeatureMsgSupported flag of base plans
    this.getCvaEnabledList();
    const validateOnRes = (list: any[] = [], _subsSelected?: any, subsKeyparam?: any) => {
      if (mapInfoVal?.isFeatureMsgSupported) {
        if (this.dspStoreData?.featureFlagInfo?.CVAEnabled === true) {
          mapInfoVal.isSubscribable = this.checkIfCVAExists(mapInfoVal, mapInfoVal?.baseSubscription, list);
        } else {
          if (list?.indexOf(mapInfoVal?.baseSubscription) == -1 && !(subsKeyparam && this.rowData?.subsKey)) {
            mapInfoVal.isSubscribable = false;
          }
        }
      }
    };
    this.getSupportedServicesFn(validateOnRes, subsSelected, subsKey);
    const newSubscribable =
      this.serviceMapping[this.rowData?.customerSubscriptionId]?.baseSubscription == mapInfoVal?.baseSubscription &&
      !mapInfoVal?.isSubscribable
        ? true
        : false;
    return mapInfoVal?.isSubscribable ? true : newSubscribable;
  }

  checkIfCVAExists(mapInfoVal: any, val: any, list: any = []) {
    if (!isEmpty(list) && list?.indexOf(val) != -1) {
      if (this.cvaEnabledList?.indexOf(val) != -1) {
        // for base subscription with FSM & with CVA
        return this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
      } else {
        // for base subscription with FSM & without CVA
        return mapInfoVal?.isSubscribable ? true : false;
      }
    } else {
      if (this.cvaEnabledList?.indexOf(val) != -1) {
        // for add-on with CVA (which is not there in supported servicesList)
        return this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
      } else {
        const newSubscribable =
          this.serviceMapping[this.rowData?.customerSubscriptionId]?.baseSubscription == mapInfoVal?.baseSubscription &&
          !mapInfoVal?.isSubscribable
            ? true
            : false;
        return mapInfoVal?.isSubscribable ? true : newSubscribable;
      }
    }
  }
  getCvaEnabledList() {
    this.cvaEnabledList = this.featureSupportedMapping[dspConstants.Worklist.IS_CVA_ENABLED]
      ? this.featureSupportedMapping[dspConstants.Worklist.IS_CVA_ENABLED]
      : [];
    this.featureSupportMsgEnabledList = [];
    if (this.featureSupportedMapping) {
      const fsmArray = Object.keys(this.featureSupportedMapping);
      fsmArray.forEach((key: any) => {
        if (isArray(this.featureSupportedMapping[key])) {
          this.featureSupportedMapping[key]?.forEach((val: any) => {
            this.featureSupportMsgEnabledList?.push(val);
          });
        }
      });
    }
  }
  getSupportedServicesFn(callback?: any, subSelected?: any, subsKey?: any) {
    if (this.rowData?.appId) {
      if (!this.rowData?.getSupportedServicesList) {
        this.assetDrawerService.getSupportedServices({ guiID: this.rowData.appId }).subscribe({
          next: (result: any) => {
            let data: any[] = [];
            if (result?.length > 0) {
              this.rowData.isFsmSupportService = result?.includes(dspConstants.Worklist.PRODUCT_LINK_GENERATION);
              result?.forEach((val: any) => {
                if (this.featureSupportedMapping && this.featureSupportedMapping[val]) {
                  data = data.concat(this.featureSupportedMapping[val]);
                }
              });
            }
            this.rowData.getSupportedServicesList = data;
            callback(data, subSelected, subsKey);
          },
          error: (err: any) => {
            this.showToastMessage(this.systemError, 'error');
            callback();
          },
        });
      } else {
        callback(this.rowData?.getSupportedServicesList || [], subSelected, subsKey);
      }
    } else {
      callback();
    }
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
      duration: 3000,
      maxMessages: 2,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  closeService(serviceInfo: any) {
    if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
      this.promotionAPICallData.unsubscribe();
    }
    const { reportingState, customerSubscriptionId, prepay } = this.rowData;
    const { selectedCategory } = this.selectedAssetSubscriptionFormData;
    if (serviceInfo === this.selectedService) {
      this.selectedAssetSubscriptionFormData.expandedService = '';
      if (reportingState === dspConstants.Worklist.SUBSCRIBED && customerSubscriptionId) {
        // On close of accordion during "Edit", Reset the formData to previous form data value.
        const { previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
        const { previousddons } = this.selectedAssetSubscriptionFormData.addOns;
        const { previousSiteInfo } = this.selectedAssetSubscriptionFormData;
        this.selectedAssetSubscriptionFormData.subscription.currentSubscription.catLevel =
          previousSubscription.catLevel;
        this.selectedAssetSubscriptionFormData.subscription.currentSubscription.dealerLevel =
          previousSubscription.dealerLevel;
        this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel =
          previousSubscription.customerLevel;
        this.selectedAssetSubscriptionFormData.addOns.currentAddons = previousddons;
        if (selectedCategory === dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR) {
          this.selectedAssetSubscriptionFormData.siteInfo.siteId = previousSiteInfo?.siteId || '';
          this.selectedAssetSubscriptionFormData.siteInfo.siteName = previousSiteInfo?.siteName || '';
        }
        if (prepay) {
          this.resetToSelectedPrepayData();
        } else {
          this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = false;
          this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractStartDate = '';
          this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractEndDate = '';
        }
        this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      } else {
        this.resetValues();
      }
    } else {
      if (reportingState === dspConstants.Worklist.UNSUBSCRIBED) {
        this.resetValues();
      }
    }
    this.isCancelView = false;
    this.loadCancelScreen.emit(false);
    this.resetAssetSubscriptionFormData(true);
    this.triggerValChange('');
  }
  resetValues() {
    this.selectedAssetSubscriptionFormData.service.currentService = '';
    this.selectedAssetSubscriptionFormData.selectedCategory = '';
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription = {
      catLevel: '',
      customerLevel: '',
      dealerLevel: '',
    };
    this.selectedAssetSubscriptionFormData.addOns.currentAddons = [];
    this.selectedAssetSubscriptionFormData.siteInfo.siteId = '';
    this.selectedAssetSubscriptionFormData.siteInfo.siteName = '';
    this.applicableSubscriptions.level1 = [];
    this.applicableSubscriptions.level2 = [];
    this.applicableSubscriptions.level3 = [];
    this.applicableAddlSubscriptions = [];
    this.mockApplicableAddlSubscription = [];
    this.listOfBasePlans = [];
    this.listOfOptionalPlans = [];
    this.customerPlan = 'None';
    this.dealerPlan = 'None';
    this.catPlan = 'None';
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  resetToSelectedPrepayData() {
    // If it is prepaid subscribed asset, Reset to subscribed prepaid data.
    const { prepaySelected, contractEndDate, contractStartDate } =
      this.selectedAssetSubscriptionFormData.prepay.previousPrepayData;
    this.isRatePlanExist = this.checkIfRatePlanExists();
    if (this.subsPricingEligible && this.isRatePlanExist && prepaySelected) {
      this.subs.templateCheck.prepayDisplayCheck = true;
      this.subs.templateCheck.prepayDisableCheck = true;
      this.subs.contractPeriod.isDisabled = true;
      this.endDateFieldConfig.disabled = true;
      this.changeDetector.detectChanges();
      this.subs.prepaySelected = true;
      this.subs.contractPeriod.startDate = contractStartDate;
      this.subs.contractPeriod.endDate = contractEndDate;
      this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
      this.subs.contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
        this.subs?.contractPeriod?.startDate
      );
    }
  }

  handlePrepayFormData() {
    const { prepay, contractStartDate, contractEndDate } = this.rowData;
    const { contractEndDate: currentContractEndDate } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (
      prepay &&
      !isEmpty(contractStartDate) &&
      !isEmpty(contractEndDate) &&
      currentContractEndDate === '' &&
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel ===
        this.selectedAssetSubscriptionFormData.subscription.previousSubscription.customerLevel &&
      JSON.stringify(currentAddons) === JSON.stringify(previousddons)
    ) {
      this.addPrepayDates();
    }
    if (this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel === 'None') {
      this.subs.prepaySelected = false;
    }
  }

  addPrepayDates() {
    const { prepay, contractStartDate, contractEndDate } = this.rowData;
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = prepay;
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected = prepay;
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractStartDate =
      this.dateFormattingService.formatDate(new Date(contractStartDate), 'YYYY/MM/DD');
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractEndDate =
      this.dateFormattingService.formatDate(new Date(contractEndDate), 'YYYY/MM/DD');
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractStartDate =
      this.dateFormattingService.formatDate(new Date(contractStartDate), 'YYYY/MM/DD');
    this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractEndDate =
      this.dateFormattingService.formatDate(new Date(contractEndDate), 'YYYY/MM/DD');
    this.endDateFieldConfig.disabled = true;
  }

  handleServiceChange(serviceSelected: string) {
    setTimeout(() => {
      this.messageBar.dismiss();
      const { currentService, selectedService } = this.selectedAssetSubscriptionFormData.service;
      this.selectedService = serviceSelected;
      this.selectedAssetSubscriptionFormData.expandedService = serviceSelected;
      this.rowData.dynamicPricingList = [];
      this.applicableAddlSubscriptions = [];
      this.mockApplicableAddlSubscription = [];
      this.isNonBillableCust = true;
      this.isAutoSelectItemsRejected = true;
      this.setSubscriptionLevelCategory(serviceSelected);
      this.setSubsPricingEligibility(serviceSelected);
      this.setContractDefaults();
      this.setIfPrepayIsEligible();
      this.handlePrepayFormData();
      if (currentService !== '' && currentService === serviceSelected) {
        this.setSelectedSubscription(serviceSelected);
      } else if (selectedService != '' && selectedService === serviceSelected) {
        this.updatePreviouslySelectedSubscription(serviceSelected);
      } else {
        this.changeServiceType(serviceSelected);
      }
    });
  }

  setSelectedSubscription(serviceOpted: string) {
    this.setSubscriptionLevelCategory(serviceOpted);
    this.setSubsPricingEligibility(serviceOpted);
    this.isContractPeriodChanged = false;
    const { selectedCategory } = this.selectedAssetSubscriptionFormData;
    const { customerLevel } = this.selectedAssetSubscriptionFormData.subscription.currentSubscription;
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    const { siteName, siteId } = this.selectedAssetSubscriptionFormData.siteInfo;
    const { currentProductFamily } = this.selectedAssetSubscriptionFormData.productFamily;
    if (selectedCategory === dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR && siteName !== '') {
      this.siteName = siteName;
      this.subs.siteInfo.siteVal = { name: siteName };
      this.subs.siteInfo.siteId = siteId;
      this.subs.siteInfo.isValidSite = true;
      this.manageAssetSubscriptionForm.get('mineStarSiteName')?.patchValue(siteName);
      this.previousSiteSearch = siteName;
      setTimeout(() => {
        this.resetOverlayBox();
      });
      if (currentProductFamily !== '') {
        const selectedProductFamily: ProductFamilyList = {
          label: currentProductFamily,
          value: currentProductFamily,
        };
        this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue([selectedProductFamily]);
      }
    }
    this.addNoneSubscription();
    const subsValue = this.setSubsValues('', '', this.telUserType || dspConstants.Common.CUSTOMER);
    this.validatePricingFn(subsValue).then((response: any) => {
      this.applicableSubscriptions.level1 = response;
      this.setBasePlans();
      setTimeout(() => {
        this.setSelectedSubs('custSubs', customerLevel);
        if (customerLevel === 'None' || customerLevel === '') {
          if (this.rowData.reportingState === 'Subscribed') {
            this.loadCancelScreen.emit(true);
            this.setTemplateCondt();
            this.triggerValChange();
          } else {
            this.selectedAssetSubscriptionFormData.isValidForm = false;
          }
          return;
        }
        if (customerLevel !== '') {
          this.listAssetProductFamilyFn();
          this.setAddonOptions(currentAddons);
          this.setPrepayData();
          this.setTemplateCondt();
        }
        if (this.subs?.templateCheck?.showCatRfvWarning) {
          const message = this.languageTranslations['Worklist']?.WL_NO_FATURE_SUPPORT_IN_ONBOARD;
          this.showToastMessage(message, 'error');
        }
      });
    });
  }

  updatePreviouslySelectedSubscription(serviceOpted: string) {
    this.selectedAssetSubscriptionFormData.service.currentService =
      this.selectedAssetSubscriptionFormData.service.selectedService;
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription = cloneDeep(
      this.selectedAssetSubscriptionFormData.subscription.selectedSubscription
    );
    this.selectedAssetSubscriptionFormData.addOns.currentAddons = cloneDeep(
      this.selectedAssetSubscriptionFormData.addOns.selectedAddons
    );
    this.setSubscriptionLevelCategory(serviceOpted);
    this.setSubsPricingEligibility(serviceOpted);
    this.isContractPeriodChanged = false;
    const { selectedCategory } = this.selectedAssetSubscriptionFormData;
    const { customerLevel } = this.selectedAssetSubscriptionFormData.subscription.selectedSubscription;
    const { selectedAddons } = this.selectedAssetSubscriptionFormData.addOns;
    const { siteName, siteId } = this.selectedAssetSubscriptionFormData.selectedSiteInfo;
    const { selectedProductFamily } = this.selectedAssetSubscriptionFormData.productFamily;
    if (selectedCategory === dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR && siteName !== '') {
      this.siteName = siteName;
      this.subs.siteInfo.siteVal = { name: siteName };
      this.subs.siteInfo.siteId = siteId;
      this.subs.siteInfo.isValidSite = true;
      this.manageAssetSubscriptionForm.get('mineStarSiteName')?.patchValue(siteName);
      this.previousSiteSearch = siteName;
      setTimeout(() => {
        this.resetOverlayBox();
      });
      if (selectedProductFamily !== '') {
        const selectedProductFamilyData: ProductFamilyList = {
          label: selectedProductFamily,
          value: selectedProductFamily,
        };
        this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue([selectedProductFamilyData]);
      }
    }
    this.addNoneSubscription();
    const subsValue = this.setSubsValues('', '', this.telUserType || dspConstants.Common.CUSTOMER);
    this.validatePricingFn(subsValue).then((response: any) => {
      this.applicableSubscriptions.level1 = response;
      this.setBasePlans();
      setTimeout(() => {
        this.setSelectedSubs('custSubs', customerLevel);
        if (customerLevel === 'None' || customerLevel === '') {
          if (this.rowData.reportingState === 'Subscribed') {
            this.loadCancelScreen.emit(true);
            this.setTemplateCondt();
            this.triggerValChange();
          } else {
            this.selectedAssetSubscriptionFormData.isValidForm = false;
          }
          return;
        }
        if (customerLevel !== '') {
          this.listAssetProductFamilyFn();
          this.setAddonOptions(selectedAddons);
          this.setPreviousPrepayData();
          this.setTemplateCondt();
        }
        if (this.subs?.templateCheck?.showCatRfvWarning) {
          const message = this.languageTranslations['Worklist']?.WL_NO_FATURE_SUPPORT_IN_ONBOARD;
          this.showToastMessage(message, 'error');
        }
      });
    });
  }

  setPrepayData() {
    const { prepaySelected, contractEndDate, contractStartDate } =
      this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    this.isRatePlanExist = this.checkIfRatePlanExists();
    if (this.subsPricingEligible && this.isRatePlanExist && prepaySelected) {
      this.subs.prepaySelected = true;
      this.subs.contractPeriod.startDate = contractStartDate;
      this.subs.contractPeriod.endDate = contractEndDate;
      this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
      this.subs.contractPeriod.isDisabled = this.setPrepayStatus();
      this.endDateFieldConfig = { ...this.endDateFieldConfig, disabled: this.setPrepayStatus() };
      this.subs.contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
        this.subs?.contractPeriod?.startDate
      );
    } else {
      this.subs.prepaySelected = false;
      this.setContractDefaults();
    }
  }

  setPreviousPrepayData() {
    const { prepaySelected, contractEndDate, contractStartDate } =
      this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData;
    this.isRatePlanExist = this.checkIfRatePlanExists();
    if (this.subsPricingEligible && this.isRatePlanExist && prepaySelected) {
      this.subs.prepaySelected = true;
      this.subs.contractPeriod.startDate = contractStartDate;
      this.subs.contractPeriod.endDate = contractEndDate;
      this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
      this.subs.contractPeriod.isDisabled = this.setPreviousPrepayStatus();
      this.endDateFieldConfig = { ...this.endDateFieldConfig, disabled: this.setPreviousPrepayStatus() };
      this.subs.contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
        this.subs?.contractPeriod?.startDate
      );
    } else {
      this.subs.prepaySelected = false;
      this.setContractDefaults();
    }
  }

  setPrepayStatus() {
    const { prepay } = this.rowData;
    const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (!prepay) return false;
    return !(
      previousSubscription.customerLevel !== currentSubscription.customerLevel || !isEqual(previousddons, currentAddons)
    );
  }

  setPreviousPrepayStatus() {
    const { prepay } = this.rowData;
    const { selectedSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    const { selectedAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (!prepay) return false;
    return !(
      previousSubscription.customerLevel !== selectedSubscription.customerLevel ||
      !isEqual(previousddons, selectedAddons)
    );
  }
  updateAddonData(supportedServicesList: any, currentAddons: any) {
    // const { prepaySelected } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    this.profileBasedAdditionalServices = { optionList: {}, selectedCount: 0, optionListPrev: {}, chkbxValChng: false };
    this.applicableAddlSubscriptions = [];
    this.mockApplicableAddlSubscription = [];
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const subsLevel: keyof AssetSubscription = this.getProfileBasedSubsLevel(loggedInProfile);
    const catalogAddOns = {
      ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][loggedInProfile][
        this.fetchSubscriptionLevelMap(subsLevel)
      ],
    };
    const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    const serviceVal = savedSubscriptionId && this.serviceMapping[savedSubscriptionId];
    let filterMappedService =
      catalogAddOns &&
      catalogAddOns.services &&
      catalogAddOns.services['All'] &&
      catalogAddOns.services['All']?.filter((e: any) => {
        if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
          this.getCvaEnabledList();
          if (this.cvaEnabledList?.indexOf(e.serviceName) != -1) {
            e.isSubscribable = this.checkIfCVAExists(
              catalogAddOns,
              e.serviceName,
              this.rowData?.getSupportedServicesList
            );
          }
        }
        const isAddonSubscribable =
          e.isSubscribable || (!e.isSubscribable && serviceVal?.additionalService?.indexOf(e.serviceName) > -1);
        return isAddonSubscribable;
      });

    filterMappedService = this.getSelectedConditionalAddOnServ(
      'All',
      catalogAddOns,
      this.rowData?.getSupportedServicesList
    );

    const { previousddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (filterMappedService?.length > 0) {
      this.validatePricingFn(filterMappedService, true).then((response: any) => {
        this.applicableAddlSubscriptions = response;
        this.mockApplicableAddlSubscription = response;
        if (currentAddons.length > 0 || previousddons.length > 0) {
          this.applicableAddlSubscriptions.forEach((applicableAddon: any) => {
            if (currentAddons.includes(applicableAddon.serviceName)) {
              this.profileBasedAdditionalServices.optionList[applicableAddon.serviceName] = true;
            }
          });
          this.profileBasedAdditionalServices.selectedCount = size(
            Object.keys(this.profileBasedAdditionalServices.optionList)
          );
          if (previousddons?.length > 0) {
            for (const addOn of previousddons) {
              this.profileBasedAdditionalServices.optionListPrev[addOn] = true;
              if (addOn === dspConstants.Worklist.CAT_GRADE_CONNECTIVITY) {
                this.showCATGradePopup = true;
              }
            }
          }
          this.setOptionalPlans();
          setTimeout(() => {
            this.setAddonFormData();
            this.validateAddOnForProductFamily();
            // if (prepaySelected) {
            //   this.setPrepayData();
            // }
            this.triggerValChange();
            this.fetchApplicablePromo(this.selectedService);
          });
        } else {
          this.setOptionalPlans();
          this.triggerValChange();
          this.fetchApplicablePromo(this.selectedService);
        }
      });
    } else {
      this.triggerValChange();
      this.fetchApplicablePromo(this.selectedService);
      this.applicableAddlSubscriptions = [];
    }
  }

  fetchApplicablePromo(serviceOpted: string) {
    //Setting Grade details for Customer Subscription
    const { contractEndDate } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    const customerOptionGrade = this.getGradeValue(this.customerPlan, '', dspConstants.Common.CUSTOMER);
    const prevcustomerOptionGrade = this.getGradeValue(
      '',
      this.rowData?.customerSubscriptionId,
      dspConstants.Common.CUSTOMER
    );
    const subsDowngradeUpgradeStatus = this.checkSubsUpgradeDowngradeStatus(
      customerOptionGrade,
      prevcustomerOptionGrade
    );
    if (this.subsPricingEligible && this.checkSubscriptionChange()) {
      const isContractPeriodUpdated = contractEndDate !== '';
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(serviceOpted, '', '', isContractPeriodUpdated, subsDowngradeUpgradeStatus);
      this.setIsNewSubscription();
    }
  }

  changeServiceType(serviceOpted: any) {
    const subId =
      this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId || this.rowData?.catSubscriptionId;
    this.selectedAssetSubscriptionFormData.service.currentService = serviceOpted;
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.applicableAddlSubscriptions = [];
    this.subs.promoInfo.showPromoValue = false;
    if (!subId) {
      this.resetAssetSubscriptionFormData();
    }
    this.triggerValChange('');
    this.serviceTypeSelected(serviceOpted, subId);
    this.setSubscriptionLevels();
  }

  setDefaultFormData() {
    const { customerPlan, dealerPlan, catPlan } = this;
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (!isEmpty(customerPlan) && !isEqual(customerPlan, 'None')) {
      this.setFormValue('customerPlan', customerPlan, 'customerLevel');
    }
    if (!isEmpty(dealerPlan) && !isEqual(dealerPlan, 'None')) {
      this.setFormValue('dealerPlan', dealerPlan, 'dealerLevel');
    }
    if (!isEmpty(catPlan) && !isEqual(catPlan, 'None')) {
      this.setFormValue('catPlan', catPlan, 'catLevel');
    }
    if (!isEmpty(currentAddons)) {
      this.setAddonFormData();
    }
  }

  setAddonFormData() {
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (!isEmpty(this.applicableAddlSubscriptions)) {
      const subscribedAddons: any = [];
      this.applicableAddlSubscriptions?.forEach((plan: any) => {
        if (currentAddons.includes(plan.serviceName)) {
          subscribedAddons.push({
            label: this.showOptionalRatePlan(plan)
              ? plan?.serviceName + ' - ' + this.convertNumberFormat(plan?.price)
              : plan?.serviceName,
            value: this.showOptionalRatePlan(plan) ? plan?.serviceName + ' - ' + plan?.price : plan?.serviceName,
            isSubscribable: plan?.isSubscribable,
            price: plan?.price,
            serviceName: plan?.serviceName,
          });
        }
      });
      this.manageAssetSubscriptionForm.get('addOnOptions')?.patchValue(subscribedAddons);
    }
  }

  serviceTypeSelected(serviceOpted: any, subId?: any) {
    this.setServiceExist();
    this.applicableSubscriptions.level1 = [];
    this.applicableSubscriptions.level2 = [];
    this.applicableSubscriptions.level3 = [];
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
        this.primarySubscriptionService = serviceOpted;
        const serviceVal = this.rowData?.customerSubscriptionId
          ? this.serviceMapping[this.rowData?.customerSubscriptionId.toString()]
          : '';
        if (this.rowData?.customerSubscriptionId && serviceVal) {
          this.primarySubscriptionService = serviceVal?.appName;
        }
        this.setSavedSiteInfo(true);
        this.addNoneSubscription();
        this.validatePricingFn(this.setSubsValues('', '', this.telUserType || dspConstants.Common.CUSTOMER)).then(
          (response: any) => {
            this.applicableSubscriptions.level1 = response;
            this.setBasePlans();
            const optedSubsData = this.getOptedSubs(this.rowData?.customerSubscriptionId);
            if (optedSubsData !== 'None') {
              this.setSelectedSubs('custSubs', this.getOptedSubs(this.rowData?.customerSubscriptionId));
            }
          }
        );
        break;
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        this.addNoneSubscription();
        this.validatePricingFn(this.setSubsValues('', '', this.telUserType || dspConstants.Common.CUSTOMER)).then(
          (response: any) => {
            this.applicableSubscriptions.level1 = response;
            this.setBasePlans();
            this.setSavedContractPeriod();
            this.setTemplateCondt();
            if (this.subs?.templateCheck?.showCatRfvWarning) {
              const message = this.languageTranslations['Worklist']?.WL_NO_FATURE_SUPPORT_IN_ONBOARD;
              this.showToastMessage(message, 'error');
            }
            const optedSubsData = this.getOptedSubs(this.rowData?.customerSubscriptionId);
            if (optedSubsData !== 'None') {
              this.setSelectedSubs('custSubs', optedSubsData);
            }
            if (subId) {
              this.setDefaultFormData();
            }
          }
        );
        break;
      /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
      case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        this.addNoneSubscription();
        // Level 1
        this.applicableSubscriptions.level1 = cloneDeep(this.setSubsValues('', '', dspConstants.Common.CUSTOMER));
        // Level 2
        const dealerSubsValues = this.setSubsValues(
          this.rowData?.customerSubscriptionId,
          this.getGradeValue('', this.rowData?.customerSubscriptionId, dspConstants.Common.DEALER),
          dspConstants.Common.DEALER
        );
        this.validatePricingFn(dealerSubsValues).then((response: any) => {
          this.applicableSubscriptions.level2 = response;
          this.setBasePlans();
          if (subId) {
            this.setDefaultFormData();
          }
        });
        // Level 3
        const catSubsValues = this.setSubsValues(
          this.rowData?.dealerSubscriptionId,
          this.getGradeValue('', this.rowData.dealerSubscriptionId, dspConstants.Common.CAT),
          dspConstants.Common.CAT
        );
        this.applicableSubscriptions.level3 = cloneDeep(catSubsValues);
        break; 
      */
    }
  }

  setAddonOptions(currentAddons: any) {
    const validateOnRes = (list: any[] = [], _subsSelected?: any, _subsKey?: any) => {
      this.updateAddonData(list || [], currentAddons);
      // Need to check and update
      // this.setDualModeInfo();
    };
    this.getSupportedServicesFn(validateOnRes);
  }

  checkSubscriptionChange() {
    if (this.rowData.reportingState === 'Subscribed') {
      const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
      const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
      const { prepaySelected: currentPrepayOption } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
      const { prepaySelected: previousPrepayOption } = this.selectedAssetSubscriptionFormData.prepay.previousPrepayData;
      return (
        currentSubscription.customerLevel !== previousSubscription.customerLevel ||
        !isEqual(currentAddons, previousddons) ||
        currentPrepayOption !== previousPrepayOption
      );
    }
    return true;
  }

  resetAssetSubscriptionFormData(formControlValuesOnly = false) {
    if (!formControlValuesOnly) {
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription = {
        catLevel: '',
        customerLevel: '',
        dealerLevel: '',
      };
    }
    Object.keys(this.manageAssetSubscriptionForm.controls).forEach((control: string) => {
      this.manageAssetSubscriptionForm.get(control)?.patchValue([]);
    });
    this.manageAssetSubscriptionForm.get('addOnOptions')?.patchValue([]);
    this.manageAssetSubscriptionForm.get('mineStarSiteName')?.patchValue('');
    this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue('');
  }

  setSubscriptionLevelCategory(serviceOpted: any) {
    const subsCategory = this.subsCategory;
    this.subscriptionLevelCategory.selectedCategory = '';
    for (const level in subsCategory) {
      // eslint-disable-next-line no-prototype-builtins
      if (subsCategory?.hasOwnProperty(level) && subsCategory[level]?.indexOf(serviceOpted) != -1) {
        this.subscriptionLevelCategory.selectedCategory = level;
        this.selectedAssetSubscriptionFormData.selectedCategory = level;
        break;
      }
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  setSubsPricingEligibility(usrOptedService: any) {
    const deviceServiceCatalog = this.catalogMapping?.[this.rowData?.deviceType]
      ? this.catalogMapping?.[this.rowData?.deviceType][usrOptedService]
      : undefined;

    this.subsPricingEligible =
      this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
      this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
      (this.userActiveFeatures?.indexOf('View Only') == -1 ||
        this.userActiveFeatures?.indexOf('Read Only') == -1 ||
        this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1) &&
      ((deviceServiceCatalog && deviceServiceCatalog['enableContractVisualization']) || false);
  }
  setContractDefaults() {
    this.subs.contractPeriod = {
      startDate: this.dateFormattingService.formatDate(new Date(), 'YYYY/MM/DD'),
      endDate: this.dateFormattingService.formatDate(
        new Date(new Date().setDate(new Date().getDate() + 364)),
        'YYYY/MM/DD'
      ),
      minEndDate: new Date(new Date().setDate(new Date().getDate() + 364)),
      maxEndDate: new Date(new Date().setDate(new Date().getDate() + 1824)),
      datepickerMode: 'day',
      isDisabled: !this.rowData?.enableSubscription || this.rowData?.suspended || this.CheckIfNotSubscribableAsset(),
      promoParams: {},
      shadowStartDate: '-',
      showContractForReadOnlyApp: this.setContractEligibilityForReadOnlyApps('-'),
    };
    this.rowData.billInfoHeight =
      this.subsPricingEligible &&
      (this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.LEVEL3_ADDON_SELECTION ||
        this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.LEVEL3_NO_ADDON_SELECTION)
        ? true
        : false;
    this.endDateFieldConfig.disabled = false;
    // this.setPrepayFormData();
  }

  contractEndDateChange() {
    if (this.subs?.contractPeriod?.shadowStartDate == '-') {
      this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
    }
    this.subs.contractPeriod.endDate = this.dateFormattingService.formatDate(
      this.subs?.contractPeriod?.endDate,
      'YYYY/MM/DD'
    );
    this.isContractPeriodChanged = true;
    this.setPrepayFormData();
    if (
      this.selectedService !== dspConstants.Worklist.MINESTAR ||
      (this.selectedService == dspConstants.Worklist.MINESTAR && this.subs.siteInfo.isValidSite)
    ) {
      const promoParams = this.subs?.contractPeriod?.promoParams;
      if (!promoParams?.subs) {
        promoParams.subs = this.selectedService;
      }
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(promoParams?.subs, promoParams?.selOpt, promoParams?.promoValChng, true);
      this.setIsNewSubscription();
      this.triggerValChange();
    }
  }

  setPrepayFormData() {
    if (this.subs?.contractPeriod?.endDate && this.subs?.contractPeriod?.startDate) {
      this.selectedAssetSubscriptionFormData.prepay.currentPrepayData = {
        contractEndDate: this.subs.contractPeriod.endDate,
        contractStartDate: this.subs.contractPeriod.startDate,
        prepaySelected: true,
      };
      this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData = {
        contractEndDate: this.subs.contractPeriod.endDate,
        contractStartDate: this.subs.contractPeriod.startDate,
        prepaySelected: true,
      };
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  setSavedContractPeriod() {
    this.isRatePlanExist = this.checkIfRatePlanExists();
    if (
      this.subsPricingEligible &&
      this.isRatePlanExist &&
      this.rowData?.contractStartDate &&
      this.rowData?.contractEndDate &&
      (this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId)
    ) {
      this.subs.contractPeriod.startDate = this.dateFormattingService.formatDate(
        new Date(this.rowData?.contractStartDate),
        'MM/DD/YYYY'
      );
      this.subs.contractPeriod.endDate = this.dateFormattingService.formatDate(
        new Date(this.rowData?.contractEndDate),
        'MM/DD/YYYY'
      );
      this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
      this.subs.contractPeriod.isDisabled = true;
      this.endDateFieldConfig.disabled = true;
      this.subs.contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
        this.subs?.contractPeriod?.startDate
      );
      this.subs.prepaySelected = !!this.rowData?.prepay;
    } else {
      this.subs.prepaySelected = false;
      this.setContractDefaults();
    }
  }
  checkIfRatePlanExists() {
    let baseSubPrice, subscriptionBasePrice;
    const deviceTypeForRP = this.catalogMapping?.[this.rowData?.deviceType];
    if (deviceTypeForRP && deviceTypeForRP[this.selectedService]) {
      if (
        this.dealerPlan != 'None' &&
        deviceTypeForRP[this.selectedService][dspConstants.Common.DEALER][this.dealerPlan]
      ) {
        baseSubPrice = deviceTypeForRP[this.selectedService][dspConstants.Common.DEALER][this.dealerPlan].makePrice;
      } else if (
        this.customerPlan != 'None' &&
        deviceTypeForRP[this.selectedService][dspConstants.Common.CUSTOMER][this.customerPlan]
      ) {
        baseSubPrice = deviceTypeForRP[this.selectedService][dspConstants.Common.CUSTOMER][this.customerPlan].makePrice;
      }
      subscriptionBasePrice = this.setSubPriceOnMake(baseSubPrice);
    } else {
      subscriptionBasePrice = null;
    }
    if (subscriptionBasePrice == null || subscriptionBasePrice == 0) {
      return false;
    } else {
      return true;
    }
  }
  setSubPriceOnMake(baseSubPrice: any) {
    const makeOfAsset = this.rowData.make;
    if (baseSubPrice != null && baseSubPrice[makeOfAsset]) {
      baseSubPrice = parseInt(baseSubPrice[makeOfAsset]);
      return baseSubPrice;
    } else if (baseSubPrice != null && baseSubPrice['Default']) {
      baseSubPrice = parseInt(baseSubPrice['Default']);
      return baseSubPrice;
    } else if (baseSubPrice != null && parseInt(baseSubPrice['CAT']) != 0) {
      baseSubPrice = parseInt(baseSubPrice['CAT']);
      return baseSubPrice;
    } else if (baseSubPrice != null && parseInt(baseSubPrice['NON-CAT']) != 0) {
      baseSubPrice = parseInt(baseSubPrice['NON-CAT']);
      return baseSubPrice;
    }
  }
  CheckIfNotSubscribableAsset() {
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
    ) {
      subscriptionDetail = this.applicableSubscriptions?.level2?.filter((subscription: any) => {
        return subscription?.baseSubscription === this.dealerPlan;
      });
    } else { 
    const subscriptionDetail = this.applicableSubscriptions?.level1?.filter((subscription: any) => {
      return subscription?.baseSubscription === this.customerPlan;
    });
    } */
    const subscriptionDetail = this.applicableSubscriptions?.level1?.filter((subscription: any) => {
      return subscription?.baseSubscription === this.customerPlan;
    });
    return !(subscriptionDetail?.length > 0 && subscriptionDetail[0]?.isSubscribable);
  }
  setContractEligibilityForReadOnlyApps(contractStartDate: any) {
    let showContractForReadOnlyApp = true;
    const parseContractReadonlyApps = this.dspConfig?.contractReadonlyApps
      ? JSON.parse(this.dspConfig?.contractReadonlyApps?.replace(/[‘’']/g, '"'))
      : [];

    if (parseContractReadonlyApps?.indexOf(this.selectedService) != -1 && contractStartDate == '-') {
      showContractForReadOnlyApp = false;
    }
    return showContractForReadOnlyApp;
  }

  setSelectedSubs(subscriptionLevel: keyof AssetSubscription, val: any) {
    this.subs[subscriptionLevel] = { subscriptionLevel: val };
    //Set Subscription Values to all drop-down models
    switch (subscriptionLevel) {
      case 'custSubs':
        this.customerPlan = val;
        this.setFormValue('customerPlan', val, 'customerLevel');
        break;
      case 'dealerSubs':
        this.dealerPlan = val;
        this.setFormValue('dealerPlan', val, 'dealerLevel');
        break;
      case 'catSubs':
        this.catPlan = val;
        this.setFormValue('catPlan', val, 'catLevel');
        break;
      default:
        break;
    }
  }

  getOptedSubs(subId: any) {
    // filter selected subs for model
    if (subId) {
      const dataHldr = this.serviceMapping[subId];
      if (dataHldr) {
        return dataHldr?.baseSubscription;
      } else {
        return 'None';
      }
    } else {
      return 'None';
    }
  }
  setSavedSiteInfo(isApiFetchAllowed: boolean) {
    if (this.rowData?.customerSubscriptionId) {
      if (this.rowData?.siteVal) {
        this.subs.siteInfo.listedSites = [this.rowData?.siteVal];
        this.subs.siteInfo.siteVal = cloneDeep(this.rowData?.siteVal);
      } else if (this.rowData?.siteId && isApiFetchAllowed) {
        const getSitesPayload = {
          id: this.rowData?.siteId,
        };
        this.assetService.getSitesById(getSitesPayload).subscribe({
          next: (result: any) => {
            this.subs.siteInfo.listedSites = [result];
            this.subs.siteInfo.siteVal = result;
            this.rowData.siteVal = cloneDeep(this.subs?.siteInfo?.siteVal);
          },
          error: (err: any) => {
            this.showToastMessage(this.systemError, 'error');
          },
        });
      }
    } else {
      this.subs.siteInfo.listedSites = [];
      this.subs.siteInfo.siteVal = { name: '' };
    }
    this.subs.siteInfo.siteId = this.rowData?.siteId;
    this.subs.siteInfo.isValidSite = this.rowData?.siteId ? true : false;
  }

  setSubsValues(subIdFlag: any, conditionFlag: any, subsProfile: any) {
    let dataHldr: any = [];
    const subSelected = this.selectedService || dspConstants.Worklist.VISION_LINK;
    const mapInfo = this.catalogMapping?.[this.rowData?.deviceType][subSelected]
      ? { ...this.catalogMapping?.[this.rowData?.deviceType][subSelected][subsProfile] }
      : {};
    if (!mapInfo) {
      return;
    }
    const getAllKeys = Object.keys(mapInfo);
    getAllKeys?.forEach((val: any) => {
      if (
        this.featureSuprtMsgDeviceValidation(mapInfo[val], subSelected, subsProfile.toLowerCase() + 'SubscriptionId')
      ) {
        if (subIdFlag) {
          if (parseInt(conditionFlag) <= parseInt(mapInfo[val].grade)) {
            dataHldr.push(mapInfo[val]);
          }
        } else if (mapInfo[val].isFeatureMsgSupported) {
          if (
            this.rowData.getSupportedServicesList?.indexOf(mapInfo[val].baseSubscription) == -1 &&
            (this.serviceMapping[this.rowData?.customerSubscriptionId]?.baseSubscription ==
              mapInfo[val]?.baseSubscription ||
              this.serviceMapping[this.rowData?.dealerSubscriptionId]?.baseSubscription ==
                mapInfo[val]?.baseSubscription)
          ) {
            dataHldr.push(mapInfo[val]);
          } else if (this.rowData?.getSupportedServicesList?.indexOf(mapInfo[val]?.baseSubscription) != -1) {
            dataHldr.push(mapInfo[val]);
          }
          // Added as part of CVA Feature - When there is no FSM Bit mapped for the base and Base Subscription is with isFeatureMsgSupported:true
          else if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
            if (
              this.rowData?.getSupportedServicesList?.indexOf(mapInfo[val]?.baseSubscription) == -1 &&
              this.featureSupportMsgEnabledList?.indexOf(mapInfo[val]?.baseSubscription) == -1
            ) {
              dataHldr.push(mapInfo[val]);
            }
          }
        } else {
          dataHldr.push(mapInfo[val]);
        }
      }
    });
    if (dataHldr?.length == 1 && dataHldr[0].baseSubscription == 'None') {
      dataHldr = [];
    }
    return dataHldr;
  }

  validatePricingFn(array: any, doNoValidateB2C?: any) {
    const promise: Promise<any> = new Promise((resolve, reject) => {
      this.b2cValidationCheck(doNoValidateB2C);
      if (this.subsPricingEligible) {
        const dynPriceArray = array?.filter((arr: any) => {
          return arr.dynamicPricing;
        });
        const staticPriceArray = array?.filter((arr: any) => {
          return !arr.dynamicPricing;
        });
        if (this.rowData?.make == dspConstants.Common.CAT) {
          array = this.iterateForPriceFn(array, dspConstants.Common.CAT);
        } else {
          array = this.iterateForPriceFn(array, 'NON-CAT');
        }
        if (this.dspConfig?.DynamicPricing?.toLowerCase() == 'true') {
          if (dynPriceArray?.length > 0) {
            const filteredSubscriptions = this.returnSubscriptionNames(dynPriceArray, 'baseSubscription');
            const filteredAddOns = this.returnSubscriptionNames(dynPriceArray, 'serviceName');
            const validCustomer = this.getValidCustomerForAsset();
            const servInputParams: any = {
              dealerCode: validCustomer?.length == 1 ? validCustomer[0].dealerCode : this.dealer?.dealerCode,
              ucid: validCustomer?.length == 1 ? validCustomer[0].ucid : '',
              productName: this.selectedService,
              make: this.rowData.make,
              serialNumber: this.rowData.serialNumber,
              deviceType: this.rowData.deviceType,
            };
            if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
              servInputParams.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
            }
            let key: any;
            if (filteredSubscriptions?.length > 0) {
              servInputParams.subscriptions = filteredSubscriptions;
              key = 'baseSubscription';
            } else if (filteredAddOns?.length > 0) {
              servInputParams.subscriptions = filteredAddOns;
              key = 'serviceName';
            }
            if (this.rowData?.dynamicPricingList?.length == 0) {
              this.assetService.getDynamicPricingDetails(servInputParams).subscribe({
                next: (result: any) => {
                  this.rowData.dynamicPricingList = result;
                  const responseObj = result;
                  array.forEach((obj: any) => {
                    const keyVal = obj[key];
                    if (responseObj[keyVal]) {
                      const dynPriceObj = responseObj[keyVal];
                      obj.price = dynPriceObj?.price + ' ' + dynPriceObj?.currency;
                    }
                  });

                  resolve(array);
                },
                error: (err: any) => {
                  this.showToastMessage(this.systemError, 'error');
                  resolve(array);
                },
              });
            } else {
              array.forEach((obj: any) => {
                const keyVal = obj[key];
                if (this.rowData?.dynamicPricingList[keyVal]) {
                  const dynPriceObj = this.rowData?.dynamicPricingList[keyVal];
                  obj.price = dynPriceObj?.price + ' ' + dynPriceObj?.currency;
                } else {
                  if (this.rowData?.make == dspConstants.Common.CAT) {
                    array = this.iterateForPriceFn(array, dspConstants.Common.CAT);
                  } else {
                    array = this.iterateForPriceFn(array, 'NON-CAT');
                  }
                }
              });
              resolve(array);
            }
          } else {
            resolve(array);
          }
        } else {
          resolve(array);
          // return promise;
        }
      } else {
        resolve(array);
      }
    });
    return promise;
  }

  iterateForPriceFn(arr: any, make: any) {
    arr.forEach((obj: any) => {
      if (obj.makePrice) {
        if (obj?.makePrice[make]) {
          obj.price = obj.makePrice[make];
        } else if (obj?.makePrice?.Default) {
          obj.price = obj.makePrice.Default;
        }
      }
    });
    return arr;
  }
  b2cValidationCheck(doNoValidateB2C: boolean) {
    if (doNoValidateB2C) {
      // B2C validation not required for AddOns
    } else {
      this.checkPriceForB2C();
    }
  }

  checkPriceForB2C() {
    const customerInfo = this.getValidCustomerForAsset();
    this.isNonBillableCust = this.dspUtilsService.checkPriceForB2C(
      this.b2cCustomers,
      customerInfo,
      this.selectedService
    );
  }

  returnSubscriptionNames(dynPriceArray: any, key: any) {
    const array: any[] = [];
    for (let i = 0; i < dynPriceArray.length; i++) {
      if (dynPriceArray[i][key]) {
        array.push(dynPriceArray[i][key]);
      }
    }
    return array;
  }
  setServiceExist() {
    this.rowData.noService =
      this.rowData?.isTelematicsRegistered == true &&
      !this.rowData?.dealerCode &&
      !this.rowData?.owernshipDetails &&
      !this.rowData.assetType;
  }

  setSubscriptionLevels(isResetFlag?: boolean) {
    this.initialAddonSubscribed = [];
    if (isResetFlag) {
      this.serviceTypeSelected(this.selectedService);
      this.subs.prepaySelected = !!this.rowData?.prepay;
      this.subs.activeContract =
        !this.rowData?.prepay && this.rowData?.contractStartDate && this.rowData?.contractEndDate ? true : false;
      this.isContractPeriodChanged = false;
      this.triggerValChange('');
    }
    /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature. 
   if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
    ) {
      const custSubsVal = this.getOptedSubs(this.rowData?.customerSubscriptionId);
      const dealerSubsVal = this.getOptedSubs(this.rowData?.dealerSubscriptionId);
      const catSubsVal = this.getOptedSubs(this.rowData?.catSubscriptionId);
      if (custSubsVal == dealerSubsVal) {
        this.inheritanceFlag.dealerInherited = true;
      }
      if (dealerSubsVal == catSubsVal) {
        this.inheritanceFlag.catInherited = true;
      }
      custSubsVal !== 'None' && this.setSelectedSubs('custSubs', custSubsVal);
      dealerSubsVal !== 'None' && this.setSelectedSubs('dealerSubs', dealerSubsVal);
      catSubsVal !== 'None' && this.setSelectedSubs('catSubs', catSubsVal);
      custSubsVal !== 'None' && this.setSelectedSubs('custSubsPrev', custSubsVal);
      dealerSubsVal !== 'None' && this.setSelectedSubs('dealerSubsPrev', dealerSubsVal);
      catSubsVal !== 'None' && this.setSelectedSubs('catSubsPrev', catSubsVal);
      this.populateProfileLvlAddnlServices();
    } else */
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      const custSubsData = this.rowData?.customerSubscriptionId
        ? this.serviceMapping[this.rowData?.customerSubscriptionId?.toString()]?.baseSubscription
        : 'None';
      if (custSubsData !== 'None') {
        this.setSelectedSubs('custSubs', custSubsData);
        this.setSelectedSubs('custSubsPrev', custSubsData);
      }
      if (
        this.selectedService == dspConstants.Worklist.VISION_LINK ||
        this.selectedService == dspConstants.Worklist.NEW_VISION_LINK
      ) {
        if (this.rowData?.customerSubscriptionId) {
          const validateOnRes = (list: any[] = [], _subsSelected?: any, _subsKey?: any) => {
            if (list) {
              this.supportedServicesForAsset = list;
              this.populateProfileLvlAddnlServices(list, isResetFlag);
            } else {
              this.populateProfileLvlAddnlServices(list, isResetFlag);
            }
            this.setDualModeInfo();
          };
          this.getSupportedServicesFn(validateOnRes);
        } else {
          this.populateProfileLvlAddnlServices();
          this.setDualModeInfo();
        }
      } else {
        this.populateProfileLvlAddnlServices();
      }
    } else if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR
    ) {
      this.setSavedSiteInfo(false);
      const custSubsData = this.rowData?.customerSubscriptionId
        ? this.serviceMapping[this.rowData?.customerSubscriptionId?.toString()]?.baseSubscription
        : 'None';
      if (custSubsData !== 'None') {
        this.setSelectedSubs('custSubs', custSubsData);
        this.setSelectedSubs('custSubsPrev', custSubsData);
      }
      this.populateCustomerLvlAddnlServices(true);
      this.listAssetProductFamilyFn();
      if (this.rowData.customerSubscriptionId) {
        if (this.rowData.productFamily) {
          this.rowData.productFamily.selectedProductFamily = this.rowData.productFamily;
        }
      }
      this.validateAddOnForProductFamily();
    }
    if (this.selectedService != dspConstants.Worklist.VISION_LINK) {
      this.setDualModeInfo();
    }
    this.setSavedContractPeriod();
    this.setTemplateCondt();
  }

  setTemplateCondt() {
    const isSubscriptionDisabled =
      this.userActiveFeatures?.indexOf('Subscription Management') == -1 ||
      (this.userActiveFeatures?.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures?.indexOf('View Only') > -1 ||
          this.userActiveFeatures?.indexOf('Read Only') > -1 ||
          this.userActiveFeatures?.indexOf('Manage -  Limited Plans (DSP Mobile Only)') > -1)) ||
      this.rowData?.suspended ||
      !this.rowData?.enableSubscription;
    const subscriptionCatalogVal = this.catalogMapping?.[this.rowData?.deviceType][this.selectedService];
    const profile = this.telUserType || dspConstants.Common.CUSTOMER;
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      this.subs.templateCheck.custSubs.disableServiceCheck = isSubscriptionDisabled;
      this.subs.templateCheck.addOns.disableBtnCheck = isSubscriptionDisabled;
      this.subs.templateCheck.addOns.disableAddOnListCheck = !(
        subscriptionCatalogVal &&
        subscriptionCatalogVal[profile][this.customerPlan] &&
        subscriptionCatalogVal[profile][this.customerPlan]['isSubscribable']
      );
      this.subs.templateCheck.prepayDisableCheck = this.customerPlan == 'None' || this.subs?.contractPeriod?.isDisabled;
      this.subs.templateCheck.prepayDisplayCheck =
        this.subs?.prepayEligible &&
        ((this.isRatePlanExist && this.isNonBillableCust && !this.subs?.activeContract) || this.customerPlan == 'None');
      this.subs.templateCheck.promotionDisplayCheck =
        this.subsPricingEligible &&
        this.customerPlan != 'None' &&
        this.customerPlan != '' &&
        (this.checkSubscriptionChange() || !!this.isContractPeriodChanged) &&
        this.rowData?.enableSubscription &&
        !this.rowData?.suspended;
      this.subs.templateCheck.contractDisplayCheck =
        this.subsPricingEligible &&
        this.customerPlan != 'None' &&
        this.featuresToggleMap?.contractPeriodSupportedApplications?.indexOf(this.selectedService) != -1 &&
        (this.subs?.activeContract || (this.subs?.prepayEligible && this.subs?.prepaySelected)) &&
        this.isRatePlanExist &&
        this.isNonBillableCust;
      this.subs.templateCheck.showCatRfvWarning =
        (this.selectedService?.indexOf(dspConstants.Worklist.CAT_REMOTE_FLEET_VISION) != -1 ||
          this.selectedService?.indexOf(dspConstants.Worklist.MARINE_CONCIERGE_PLUS) != -1) &&
        this.applicableSubscriptions?.level1.length == 0 &&
        this.applicableSubscriptions?.level2.length == 0 &&
        this.applicableSubscriptions?.level3.length == 0 &&
        this.checkIfFSMExistForService(this.selectedService, this.rowData?.deviceType);
    } /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
      else if (
      this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
    ) {
      this.subs.templateCheck.custSubs.disableServiceCheck = this.subs.templateCheck.dealerSubs.disableServiceCheck =
        isSubscriptionDisabled || (subscriptionCatalogVal && subscriptionCatalogVal['isDisableInUi']);
      this.subs.templateCheck.catSubs.disableServiceCheck =
        isSubscriptionDisabled ||
        (this.billingAccounts?.length == 0 && !this.isSuperAdmin) ||
        (subscriptionCatalogVal && subscriptionCatalogVal['isDisableInUi']);
      this.subs.templateCheck.addOns.disableBtnCheck = isSubscriptionDisabled;
      this.subs.templateCheck.addOns.disableAddOnListCheck = !(
        subscriptionCatalogVal &&
        subscriptionCatalogVal[profile][this.customerPlan] &&
        subscriptionCatalogVal[profile][this.customerPlan]['isSubscribable']
      );
      this.subs.templateCheck.prepayDisableCheck = this.customerPlan == 'None' || this.subs?.contractPeriod?.isDisabled;
      this.subs.templateCheck.promotionDisplayCheck =
        this.subsPricingEligible &&
        this.dealerPlan != 'None' &&
        (this.subs?.dealerSubsPrev?.subscriptionLevel != this.dealerPlan ||
          this.profileBasedAdditionalServices?.chkbxValChng ||
          this.isContractPeriodChanged) &&
        this.rowData?.enableSubscription &&
        !this.rowData?.suspended;
      this.subs.templateCheck.contractDisplayCheck =
        this.subsPricingEligible &&
        this.dealerPlan != 'None' &&
        this.featuresToggleMap?.contractPeriodSupportedApplications?.indexOf(this.selectedService) != -1 &&
        (this.subs?.activeContract || (this.subs?.prepayEligible && this.subs?.prepaySelected)) &&
        this.isNonBillableCust &&
        this.subs?.contractPeriod?.showContractForReadOnlyApp === true;
    }*/ else if (
      this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR
    ) {
      this.subs.templateCheck.promotionDisplayCheck =
        this.userActiveFeatures.indexOf('Subscription Management') > -1 &&
        (this.userActiveFeatures.indexOf('View Only') == -1 ||
          this.userActiveFeatures.indexOf('Read Only') == -1 ||
          this.userActiveFeatures.indexOf('Manage -  Limited Plans (DSP Mobile Only)') == -1) &&
        this.subsPricingEligible &&
        this.customerPlan != 'None' &&
        this.customerPlan != '' &&
        this.rowData?.enableSubscription &&
        !this.rowData?.suspended &&
        (this.checkSubscriptionChange() || this.isContractPeriodChanged);
    }
    this.isPrepayVisible(this.rowData);
    this.subs.templateCheck.prepayDisplayCheck =
      this.isPrepay == undefined ? this.subs.templateCheck.prepayDisplayCheck : this.isPrepay;
  }
  isPrepayVisible(equipmentObj: any) {
    if (
      this.deviceFeaturesInfo &&
      equipmentObj.deviceType &&
      this.deviceFeaturesInfo[equipmentObj.deviceType] &&
      Object.keys(this.deviceFeaturesInfo[equipmentObj.deviceType]).indexOf('Prepay') > -1 &&
      !this.deviceFeaturesInfo[equipmentObj.deviceType].Prepay
    ) {
      this.isPrepay = false;
    }
  }
  populateProfileLvlAddnlServices(supportedServices?: any[], isResetFlag?: boolean) {
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const subsLevel: keyof AssetSubscription = this.getProfileBasedSubsLevel(loggedInProfile);
    this.profileBasedAdditionalServices = { optionList: {}, selectedCount: 0, optionListPrev: {}, chkbxValChng: false };
    this.applicableAddlSubscriptions = [];
    const subscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    if (
      subscriptionId &&
      this.serviceMapping[subscriptionId] &&
      this.fetchSubscriptionLevelMap(subsLevel) === this.serviceMapping[subscriptionId]?.baseSubscription
    ) {
      if (
        this.selectedService == dspConstants.Worklist.VISION_LINK ||
        this.selectedService == dspConstants.Worklist.NEW_VISION_LINK
      ) {
        this.setVisionLinkAddnlServices(supportedServices, isResetFlag);
      } else {
        this.setProfileLvlAddnlServices(subsLevel);
      }
    } else {
      if (
        this.selectedService == dspConstants.Worklist.VISION_LINK ||
        this.selectedService == dspConstants.Worklist.NEW_VISION_LINK
      ) {
        const serviceVal = {
          ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][loggedInProfile][
            this.fetchSubscriptionLevelMap(subsLevel)
          ],
        };
        if (serviceVal.baseSubscription != 'None') {
          if (this.enableAutoSelectAddon?.length && this.enableAutoSelectAddon.indexOf(this.customerPlan) > -1) {
            this.fetchedAutoAddons = false;
            this.loaderService.show();
          }
          this.validatePricingFn(this.getSelectedConditionalAddOnServ('All', serviceVal, supportedServices)).then(
            (response: any) => {
              this.applicableAddlSubscriptions = response;
              this.setOptionalPlans();
              this.mapDualModeAddons(serviceVal, supportedServices);
            }
          );
        }
      } else {
        this.mapAddOnServices('All');
      }
    }
  }

  populateCustomerLvlAddnlServices(conditionAddonFlag: any) {
    this.profileBasedAdditionalServices = { optionList: {}, selectedCount: 0, optionListPrev: {}, chkbxValChng: false };
    this.applicableAddlSubscriptions = [];
    if (
      this.rowData.customerSubscriptionId &&
      this.serviceMapping[this.rowData.customerSubscriptionId] &&
      this.customerPlan === this.serviceMapping[this.rowData.customerSubscriptionId].baseSubscription
    ) {
      this.setCustomerLvlAddnlServices(conditionAddonFlag);
    } else {
      this.mapAddOnServices('All', conditionAddonFlag);
    }
  }

  setCustomerLvlAddnlServices(conditionAddonFlag: any) {
    this.profileBasedAdditionalServices = { optionList: [], selectedCount: 0, optionListPrev: [], chkbxValChng: false };
    this.applicableAddlSubscriptions = [];
    if (
      this.rowData.customerSubscriptionId &&
      this.customerPlan &&
      this.customerPlan != 'None' &&
      this.catalogMapping?.[this.rowData.deviceType] &&
      this.catalogMapping?.[this.rowData.deviceType][this.selectedService][
        this.telUserType || dspConstants.Common.CUSTOMER
      ][this.customerPlan]
    ) {
      this.mapAddOnServices('All', conditionAddonFlag);
      const dataHldr = this.serviceMapping[this.rowData?.customerSubscriptionId.toString()];
      this.profileBasedAdditionalServices.selectedCount = this.getSelectedAddonCnt(dataHldr);
      this.initialAddonSubscribed = dataHldr.additionalService;
      dataHldr.additionalService.forEach((val: any) => {
        const findDupes =
          this.applicableAddlSubscriptions.filter((data: any) => {
            return data.serviceName == val && data.isSubscribable;
          }) || [];
        if (findDupes.length == 0) {
          this.applicableAddlSubscriptions.push({ serviceName: val });
        }
        this.profileBasedAdditionalServices.optionList[val] = true;
        this.profileBasedAdditionalServices.optionListPrev[val] = true;
      });
    } else {
      this.applicableAddlSubscriptions = [];
    }
  }

  validateAddOnForProductFamily() {
    if (this.subscriptionChangeService.checkValidProductFamily(this)) {
      //scope
      this.showAssetProductFamilyFn();
    } else {
      this.resetProductFamilyListFn();
    }
  }

  showAssetProductFamilyFn() {
    this.isProductFamilyActive = true;
    if (this.productFamily.selectedAddOnProductFamilyList?.length == 0) {
      this.productFamily.selectedAddOnProductFamilyList = cloneDeep(
        this.productFamily.productFamilyList[0]?.productFamily
      );
      this.listOfProductFamily = [];
      this.productFamily.selectedAddOnProductFamilyList.forEach((val: any) => {
        const Obj: ProductFamilyList = {
          label: val,
          value: val,
        };
        this.listOfProductFamily.push(Obj);
      });
      this.fetchApplicablePromo(this.selectedService);
    }
    if (
      this.rowData.customerSubscriptionId &&
      this.rowData.productFamily &&
      this.productFamily?.selectedAddOnProductFamilyList?.length > 0
    ) {
      if (this.subscriptionChangeService.isAddonChanged(this)) {
        const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
        for (const addOn of currentAddons) {
          if (previousddons.indexOf(addOn)) {
            this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily =
              this.selectedAssetSubscriptionFormData.productFamily.previousProductFamily;
            break;
          }
        }

        this.modifiedProductFamily = true;
      } else {
        if (this.rowData.productFamily) {
          this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue(this.rowData.productFamily);
        }
        this.modifiedProductFamily = false;
      }
    }
  }

  resetProductFamilyListFn() {
    this.isProductFamilyActive = false;
    this.productFamily.selectedAddOnProductFamilyList = [];
    const { currentAddons } = this.selectedAssetSubscriptionFormData.addOns;
    if (currentAddons?.length > 0) {
      if (currentAddons.indexOf('Surface Guidance') == -1) {
        this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily = '';
      }
    } else if (currentAddons?.length == 0 && this.customerPlan != 'None') {
      this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily = '';
    }

    //
    this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue('');
    this.productFamily.selectedProductFamily = 'None';
  }

  setVisionLinkAddnlServices(supportedServices?: any, isResetFlag?: any, _serviceType?: any) {
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const subsLevel: keyof AssetSubscription = this.getProfileBasedSubsLevel(loggedInProfile);
    const subscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    if (
      this.rowData?.customerSubscriptionId &&
      this.fetchSubscriptionLevelMap(subsLevel) &&
      this.fetchSubscriptionLevelMap(subsLevel) != 'None'
    ) {
      const serviceVal = {
        ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][loggedInProfile][this.customerPlan],
      };
      this.validatePricingFn(this.getSelectedConditionalAddOnServ('All', serviceVal, supportedServices)).then(
        (response: any) => {
          this.applicableAddlSubscriptions = response;
          this.setOptionalPlans();
        }
      );
      const dataHldr = this.serviceMapping[subscriptionId.toString()];
      const dualModeScope = {
        catalogMapping: this.catalogMapping,
        deviceType: this.rowData?.deviceType,
        selectedService: this.selectedService,
        selectedSubscription: this.fetchSubscriptionLevelMap(subsLevel),
      };
      //For Dual Mode Devices
      if (this.commModeFactoryService.isDualModeSubFn(dualModeScope)) {
        //Get All Applicable Subscriptions
        const dualModeSubs = this.setVisionLinkAddnlServices(supportedServices, false, 'All');
        let satelliteSubs = this.getSelectedConditionalAddOnServ('Satellite', serviceVal, supportedServices);
        const cellularSubs = this.getSelectedConditionalAddOnServ('Cellular', serviceVal, supportedServices);
        if (supportedServices?.indexOf(dspConstants.Worklist.ADVANCED_PRODUCTIVITY) != -1) {
          satelliteSubs = satelliteSubs?.filter((subs: any) => {
            return subs?.serviceName != dspConstants.Worklist.ADVANCED_PRODUCTIVITY;
          });
        }
        if (supportedServices?.indexOf(dspConstants.Worklist.CURRENT_ADVANCED_PRODUCTIVITY) != -1) {
          satelliteSubs = satelliteSubs?.filter((subs: any) => {
            return subs?.serviceName != dspConstants.Worklist.CURRENT_ADVANCED_PRODUCTIVITY;
          });
        }
        if (supportedServices?.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS) != -1) {
          satelliteSubs = satelliteSubs?.filter((subs: any) => {
            return subs?.serviceName != dspConstants.Worklist.EQUIPMENT_INSIGHTS;
          });
        }
        //Get Subscribed Subscriptions
        const subscribedAdditionalServices = dataHldr?.subscriptionLevel?.split('|');
        const subscribedServices2 = subscribedAdditionalServices?.slice(1);
        const subscribedServices: any[] = [];
        subscribedServices2?.forEach((item: any) => {
          const objectPerSub: any = {};
          objectPerSub.serviceName = item;
          subscribedServices.push(objectPerSub);
        });
        if (
          this.intersectionByObjectProperty(subscribedServices, dualModeSubs, 'serviceName')?.length ==
          subscribedServices?.length
        ) {
          this.validatePricingFn(dualModeSubs).then((response: any) => {
            this.applicableAddlSubscriptions = response;
            this.setOptionalPlans();
            this.rowData.selectedCommunicationMode = dspConstants.Worklist.DUAL_MODE;
          });
        } else if (
          this.intersectionByObjectProperty(subscribedServices, satelliteSubs, 'serviceName')?.length ==
          subscribedServices?.length
        ) {
          this.validatePricingFn(satelliteSubs).then((response: any) => {
            this.applicableAddlSubscriptions = response;
            this.setOptionalPlans();
            this.rowData.selectedCommunicationMode = dspConstants.Worklist.SATELLITE_HOURS;
          });
        } else if (
          this.intersectionByObjectProperty(subscribedServices, cellularSubs, 'serviceName')?.length ==
          subscribedServices?.length
        ) {
          this.validatePricingFn(cellularSubs).then((response: any) => {
            this.applicableAddlSubscriptions = response;
            this.setOptionalPlans();
            this.rowData.selectedCommunicationMode = dspConstants.Worklist.CELLULAR_HOURLY;
          });
        }
        // Update Communication Mode - As Per Subscribed Subscriptions
        this.updateAdditionalServicesFn(this.rowData.selectedCommunicationMode, false);
        if (serviceVal?.isSubscribable == false) {
          if (dataHldr?.additionalService?.indexOf(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED) != -1) {
            this.rowData.selectedCommunicationMode = dspConstants.Worklist.CELLULAR_HOURLY;
          }
          if (dataHldr?.additionalService?.indexOf(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED) != -1) {
            this.rowData.selectedCommunicationMode = dspConstants.Worklist.SATELLITE_HOURS;
          }
        }
        //Remove 'Cellular/Satellite Radio Suspended' from subscribed services now
        if (dataHldr?.additionalService?.indexOf(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED) != -1) {
          dataHldr?.additionalService?.splice(
            dataHldr?.additionalService?.indexOf(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED),
            1
          );
        }
        if (dataHldr?.additionalService?.indexOf(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED) != -1) {
          dataHldr?.additionalService?.splice(
            dataHldr?.additionalService?.indexOf(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED),
            1
          );
        }
      }

      this.profileBasedAdditionalServices.selectedCount = this.getSelectedAddonCnt(dataHldr);
      if (dataHldr?.additionalService?.length > 0) {
        this.initialAddonSubscribed = dataHldr.additionalService;
        dataHldr?.additionalService?.forEach((val: any) => {
          const findDupes =
            this.applicableAddlSubscriptions?.filter((addon: any) => {
              return addon?.serviceName == val && addon?.isSubscribable;
            }) || [];
          if (findDupes?.length == 0) {
            this.applicableAddlSubscriptions?.push({ serviceName: val });
          }
          this.profileBasedAdditionalServices.optionList[val] = true;
          this.profileBasedAdditionalServices.optionListPrev[val] = true;
          if (isResetFlag) {
            this.profileBasedAdditionalServices.optionList = cloneDeep(
              this.profileBasedAdditionalServices?.optionListPrev
            );
          }
        });
      } else {
        this.profileBasedAdditionalServices.optionList = cloneDeep(this.profileBasedAdditionalServices?.optionListPrev);
      }
      this.applicableAddlSubscriptions?.sort();
      this.setOptionalPlans();
    }
  }
  getSelectedAddonCnt(dataHldr: any) {
    const addonCnt = dataHldr.additionalService ? dataHldr.additionalService?.length : 0;
    return addonCnt;
  }

  updateAdditionalServicesFn(commModeSelected: any, isCommunicationModeChanged: any) {
    const selectedCommuncationMode = commModeSelected;
    const serviceVal = {
      ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][
        this.telUserType || dspConstants.Common.CUSTOMER
      ][this.customerPlan],
    };

    if (selectedCommuncationMode == dspConstants.Worklist.SATELLITE_HOURS) {
      if (serviceVal) {
        this.validatePricingFn(serviceVal.services['Satellite']).then((response) => {
          this.applicableAddlSubscriptions = response;
        });
      } else {
        this.applicableAddlSubscriptions = [];
      }
      const remIndx = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
        return obj?.serviceName == dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED;
      });
      if (remIndx > -1) {
        this.applicableAddlSubscriptions?.splice(remIndx, 1);
      }
    }
    if (selectedCommuncationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
      if (serviceVal) {
        this.validatePricingFn(serviceVal.services['Cellular']).then((response) => {
          this.applicableAddlSubscriptions = response;
        });
      } else {
        this.applicableAddlSubscriptions = [];
      }
      const remIndx = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
        return obj?.serviceName == dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED;
      });
      if (remIndx > -1) {
        this.applicableAddlSubscriptions?.splice(remIndx, 1);
      }
    }
    if (selectedCommuncationMode == dspConstants.Worklist.DUAL_MODE) {
      if (serviceVal) {
        this.validatePricingFn(serviceVal.services['All']).then((response) => {
          this.applicableAddlSubscriptions = response;
        });
      } else {
        this.applicableAddlSubscriptions = [];
      }
    }

    if (this.supportedServicesForAsset?.length > 0) {
      const dualModeSubs = this.getSelectedConditionalAddOnServ('All', serviceVal, this.supportedServicesForAsset);
      let satelliteSubs = this.getSelectedConditionalAddOnServ('Satellite', serviceVal, this.supportedServicesForAsset);
      const cellularSubs = this.getSelectedConditionalAddOnServ('Cellular', serviceVal, this.supportedServicesForAsset);

      if (this.supportedServicesForAsset?.indexOf(dspConstants.Worklist.ADVANCED_PRODUCTIVITY) != -1) {
        satelliteSubs = satelliteSubs?.filter((subs: any) => {
          return subs?.serviceName != dspConstants.Worklist.ADVANCED_PRODUCTIVITY;
        });
      }

      if (this.supportedServicesForAsset?.indexOf(dspConstants.Worklist.CURRENT_ADVANCED_PRODUCTIVITY) != -1) {
        satelliteSubs = satelliteSubs?.filter((subs: any) => {
          return subs?.serviceName != dspConstants.Worklist.CURRENT_ADVANCED_PRODUCTIVITY;
        });
      }

      if (this.supportedServicesForAsset?.indexOf(dspConstants.Worklist.EQUIPMENT_INSIGHTS) != -1) {
        satelliteSubs = satelliteSubs?.filter((subs: any) => {
          return subs?.serviceName != dspConstants.Worklist.EQUIPMENT_INSIGHTS;
        });
      }

      if (selectedCommuncationMode == dspConstants.Worklist.DUAL_MODE) {
        this.validatePricingFn(dualModeSubs).then((response: any) => {
          this.applicableAddlSubscriptions = response;
          this.setOptionalPlans();
          this.rowData.selectedCommunicationMode = dspConstants.Worklist.DUAL_MODE;
        });
      } else if (selectedCommuncationMode == dspConstants.Worklist.SATELLITE_HOURS) {
        this.validatePricingFn(satelliteSubs).then((response: any) => {
          this.applicableAddlSubscriptions = response;
          this.setOptionalPlans();
          this.rowData.selectedCommunicationMode = dspConstants.Worklist.SATELLITE_HOURS;
        });
      } else if (selectedCommuncationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
        this.validatePricingFn(cellularSubs).then((response: any) => {
          this.applicableAddlSubscriptions = response;
          this.setOptionalPlans();
          this.rowData.selectedCommunicationMode = dspConstants.Worklist.CELLULAR_HOURLY;
        });
      }
    }

    if (isCommunicationModeChanged) {
      const assetSubsInfo = this.getAllSubsInfoFn(this.rowData?.deviceType, this.selectedService);
      const baseSubscriptionCAT = first(assetSubsInfo?.catLevel);
      const baseSubscriptionDealer = first(assetSubsInfo?.dealerLevel);
      const baseSubscriptionCustomer = first(assetSubsInfo?.customerLevel);
      assetSubsInfo.catLevel = [];
      assetSubsInfo.dealerLevel = [];
      assetSubsInfo.customerLevel = [];
      assetSubsInfo.catLevel?.push(baseSubscriptionCAT);
      assetSubsInfo.dealerLevel?.push(baseSubscriptionDealer);
      assetSubsInfo.customerLevel?.push(baseSubscriptionCustomer);
      let promoSubs = baseSubscriptionCAT;
      this.profileBasedAdditionalServices = { optionList: {}, selectedCount: 0, optionListPrev: {} };

      if (selectedCommuncationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
        const addonSubscription = dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED;
        assetSubsInfo.catLevel?.push(addonSubscription);
        assetSubsInfo.dealerLevel?.push(addonSubscription);
        assetSubsInfo.customerLevel?.push(addonSubscription);
        promoSubs = addonSubscription;
        this.profileBasedAdditionalServices.optionList[addonSubscription] = true;
      }
      if (selectedCommuncationMode == dspConstants.Worklist.SATELLITE_HOURS) {
        const addonSubscription = dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED;
        assetSubsInfo.catLevel?.push(addonSubscription);
        assetSubsInfo.dealerLevel?.push(addonSubscription);
        assetSubsInfo.customerLevel?.push(addonSubscription);
        promoSubs = addonSubscription;
        this.profileBasedAdditionalServices.optionList[addonSubscription] = true;
      }

      if (selectedCommuncationMode == dspConstants.Worklist.DUAL_MODE) {
        //Dummy Subscription Added - To Previous List
        this.profileBasedAdditionalServices.optionListPrev[dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED] = true;
      }
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(dspConstants.Worklist.VISION_LINK, promoSubs, true);
      this.setIsNewSubscription();

      if (this.rowData?.make && this.rowData?.serialNumber) {
        const apiKey = this.rowData?.make + '|' + this.rowData?.serialNumber;
        if (this.changedServices != undefined) {
          if (this.changedServices?.indexOf(apiKey) == -1) this.changedServices?.push(apiKey);
        } else {
          this.changedServices = [];
          this.changedServices.push(apiKey);
        }
      }
      this.triggerValChange();
    }
    //Repopulate Additional Services Count to 0 on a Mode Change
    this.profileBasedAdditionalServices = { optionList: {}, selectedCount: 0, optionListPrev: {} };
  }

  onSiteChanged(siteName: any) {
    this.isSiteFound = this.subs?.siteInfo?.listedSites.filter((site: any) => {
      return site.name === siteName;
    });

    if (!this.isSiteFound || (this.isSiteFound && this.isSiteFound.length > 0)) {
      this.subs.siteInfo.isValidSite = true;
      this.subs.siteInfo.siteId = this.isSiteFound[0].id;
      if (
        this.customerPlan !== 'None' &&
        (!this.rowData.siteVal ||
          (this.rowData.siteVal?.name &&
            this.subs.siteInfo.siteVal?.name &&
            this.rowData.siteVal.name != this.subs.siteInfo.siteVal.name) ||
          (!this.subs.siteInfo.siteVal.name && this.rowData.siteVal.name != this.subs.siteInfo.siteVal.name))
      ) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(this.selectedService);
        this.setIsNewSubscription();
        this.triggerValChange();
      } else {
        this.triggerValChange('');
      }
    } else {
      this.subs.siteInfo.isValidSite = false;
      this.triggerValChange('');
    }
  }

  onBlurEvent(event: any) {
    if (event?.type === 'blur') {
      const { errors } = this.manageAssetSubscriptionForm.controls['mineStarSiteName'];
      if (this.previousSiteSearch !== '' && this.siteResults?.length > 0 && isEmpty(errors)) {
        this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors({ incorrect: true });
        this.selectedAssetSubscriptionFormData.isValidForm = false;
      }
    }
  }

  getSiteReqInfo(event: any) {
    this.triggerValChange('');
    this.siteName = event?.value || '';
    if (event.value.length > 2 && this.previousSiteSearch !== this.siteName) {
      this.previousSiteSearch = cloneDeep(this.siteName);
      this.siteNotFound = false;
      this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors(null);
      const siteSearchParam = {
        name: event.value,
        dealerCode: this.dealer.dealerCode,
      };
      if (!this.siteResults.length) this.isSiteNameLoading = true;
      this.fetchSiteDetails(siteSearchParam, event);
    } else if (event.value === '') {
      this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors(null);
      this.previousSiteSearch = '';
      this.isListItemSelected = false;
      this.isSiteNameLoading = false;
      this.selectedAssetSubscriptionFormData.siteInfo = {
        siteId: '',
        siteName: '',
      };
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  fetchSiteDetails = debounce((siteSearchParam: any, event: any) => {
    if (this.siteName === siteSearchParam.name && this.siteName?.length > 2) {
      this.isSiteNameLoading = true;
      this.siteResults = [];
      this.changeDetector.detectChanges();
      this.getSeachTextSiteNames(siteSearchParam);
    }
  }, 1000);

  getSeachTextSiteNames(siteSearchParam: any) {
    this.handleSiteSearchOverlay();
    if (this.rowData?.ownershipDetails) {
      siteSearchParam.ucid = this.rowData?.ownershipDetails[0]?.ucid;
    }

    this.assetService.getSitesForWorkList(siteSearchParam).subscribe({
      next: (result: any) => {
        this.isSiteNameLoading = false;
        if (result.sites?.length > 0) {
          this.siteNotFound = false;
          this.subs.siteInfo.isValidSite = 'true';
          this.subs.siteInfo.listedSites = result.sites;
          this.getSitesList(result.sites);
          this.handleSiteSearchInputFocus(siteSearchParam?.name || '');
        } else if (result.sites.length == 0) {
          this.siteNotFound = true;
          this.resetOverlayBox();
        }
      },
      error: (err: any) => {
        this.siteNotFound = true;
        this.isSiteNameLoading = false;
        this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors({ siteNotFoundError: true });
        this.resetOverlayBox();
        if (err.status !== 404) {
          this.showToastMessage(this.systemError, 'error');
        }
      },
    });
  }

  getSitesList(siteSearchResults: any) {
    for (const site of siteSearchResults) {
      this.siteResults.push({
        id: site.id,
        label: site.name,
        value: site.name,
      });
    }
  }

  handleSiteSearchInputFocus(searchText: string) {
    const mineStarSearchInput = document.getElementById('minestarSiteSearch') as HTMLInputElement | null;
    if (mineStarSearchInput) {
      setTimeout(() => {
        mineStarSearchInput?.focus();
        // mineStarSearchInput?.setSelectionRange(searchText.length, searchText.length);
      });
    }
  }

  handleSiteSearchOverlay() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef?.length && overlayRef[0]?.style?.display == 'none') {
      overlayRef[0].style.removeProperty('display');
    }
  }

  listItemClick(site: any) {
    const { label, value, id } = site;
    this.isListItemSelected = true;
    this.siteName = value;
    this.siteValue = value;
    this.subs.siteInfo.siteVal = { name: label };
    this.selectedAssetSubscriptionFormData.siteInfo = { siteName: label, siteId: id };
    this.selectedAssetSubscriptionFormData.selectedSiteInfo = { siteName: label, siteId: id };

    this.previousSiteSearch = value;
    this.manageAssetSubscriptionForm.controls['mineStarSiteName'].setErrors(null);
    this.onSiteChanged(this.siteName);
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.siteResults = [];
    this.changeDetector.detectChanges();
  }

  productFamilyChangedFn(event: any) {
    if (event.currentSelection && event.currentSelection[0]) {
      this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily = event.currentSelection[0].label;
    }
    this.triggerValChange();
  }

  updateNoResults() {
    this.siteNotFound = true;
    this.resetOverlayBox();
  }

  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;
    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }

  setIfPrepayIsEligible() {
    this.subs.prepayEligible = false;
    this.subs.activeContract =
      !this.rowData?.prepay && this.rowData?.contractStartDate && this.rowData?.contractEndDate ? true : false;
    if (
      this.userActiveFeatures?.indexOf(dspConstants.Worklist.PREPAY) != -1 &&
      this.featuresToggleMap?.prepaySupportedApplications?.indexOf(this.selectedService) != -1 &&
      this.featuresToggleMap?.contractPeriodSupportedApplications?.indexOf(this.selectedService) != -1 &&
      this.subsPricingEligible &&
      this.isNonBillableCust
    ) {
      this.subs.prepayEligible = true;
      this.subs.prepaySelected = !!this.rowData?.prepay;
    }
  }

  togglePrepay(event: any) {
    const prepayChecked = event?.checked;
    this.setContractDefaults();
    if (prepayChecked) {
      const { contractEndDate } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
      this.subs.prepaySelected = true;
      if (contractEndDate === '') {
        this.setPrepayFormData();
      } else {
        this.subs.contractPeriod.endDate = contractEndDate;
        this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = true;
        this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData.prepaySelected = true;
        this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
      this.subs.contractPeriod.shadowStartDate = this.subs.contractPeriod.startDate;
      if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
        this.promotionAPICallData.unsubscribe();
      }
      this.getApplicablePromo(this.selectedService, '', '', true);
      return;
    }
    if (!prepayChecked) {
      const { prepaySelected: previousPrepaySelected } =
        this.selectedAssetSubscriptionFormData.prepay.previousPrepayData;
      this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = false;
      this.selectedAssetSubscriptionFormData.prepay.selectedPrepayData.prepaySelected = false;
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      if (previousPrepaySelected) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(this.selectedService);
      } else {
        this.isContractPeriodChanged = false;
        this.setTemplateCondt();
        if (this.subs.templateCheck.promotionDisplayCheck) {
          if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
            this.promotionAPICallData.unsubscribe();
          }
          this.getApplicablePromo(this.selectedService);
        } else {
          this.triggerValChange();
        }
      }
      this.subs.prepaySelected = false;
      return;
    }
  }

  getApplicablePromo(
    subs?: any,
    selOpt?: any,
    promoValChng?: any,
    isContractPeriodUpdated?: any,
    downgradeUpgradeStatus?: any
  ) {
    //For getting applicable promo for billing enabled privileges
    this.showOtoMessage = false;
    const param: ApplicablePromoParam = {
      key: 'custSubs',
      prevVal: this.getOptedSubs(this.rowData?.customerSubscriptionId),
      prevAddServ: this.profileBasedAdditionalServices?.optionListPrev,
    };
    const selectedSubsServVal = [];
    selectedSubsServVal.push(this.customerPlan);

    if (!this.subsPricingEligible && !this.isDspAppMock) {
      return;
    }

    this.subs.contractPeriod.promoParams = {
      subs: subs,
      selOpt: selOpt,
      promoValChng: promoValChng,
    };

    const customerInfo = this.getValidCustomerForAsset();
    if (
      customerInfo?.length == 1 &&
      customerInfo[0].ucid &&
      findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', customerInfo[0].ucid]) !== -1 &&
      this.b2cCustomers?.b2cSupportedApps?.indexOf(this.selectedService) !== -1
    ) {
      this.subs.templateCheck.prepayDisplayCheck = false;
      this.subs.promoInfo.showPromoValue = false;
      this.subs.promoInfo.showPromoLoad = false;
    } else {
      this.subs.templateCheck.promotionDisplayCheck = true;
      this.subs.promoInfo.showPromoValue = true;
      this.subs.promoInfo.showPromoLoad = true;
    }

    /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.

    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
    ) {
      if (this.rowData?.dealerSubscriptionId || this.dealerPlan == this.catPlan) {
        param.key = 'dealerSubs';
        param.prevVal = this.getOptedSubs(this.rowData?.dealerSubscriptionId);
        const selectedSubsServValConst = [];
        selectedSubsServValConst.push(this.dealerPlan);
      }
      if (this.rowData?.catSubscriptionId || this.catPlan != this.dealerPlan) {
        param.key = 'catSubs';
        param.prevVal = this.getOptedSubs(this.rowData?.catSubscriptionId);
        const selectedSubsServValVariable = [];
        selectedSubsServValVariable.push(this.catPlan);
      }
      param.prevAddServ = this.profileBasedAdditionalServices?.optionListPrev;
      if (!isEmpty(this.profileBasedAdditionalServices?.optionList)) {
        Object.keys(this.profileBasedAdditionalServices.optionList).forEach((key: any) => {
          if (this.profileBasedAdditionalServices?.optionList[key]) {
            selectedSubsServVal.push(key);
          }
        });
      }
      this.profileBasedAdditionalServices.chkbxValChng =
        this.checkIfAddnlServChanged(param.prevAddServ, this.profileBasedAdditionalServices?.optionList) ||
        promoValChng;
    }*/
    if (this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR) {
      if (!isEmpty(this.profileBasedAdditionalServices?.optionList)) {
        Object.keys(this.profileBasedAdditionalServices.optionList).forEach((key: any) => {
          if (this.profileBasedAdditionalServices.optionList[key]) {
            selectedSubsServVal.push(key);
          }
        });
      }
      this.profileBasedAdditionalServices.chkbxValChng =
        this.checkIfAddnlServChanged(param.prevAddServ, this.profileBasedAdditionalServices.optionList) || promoValChng;
    }
    if (
      this.subscriptionLevelCategory.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM
    ) {
      if (!isEmpty(this.profileBasedAdditionalServices?.optionList)) {
        Object.keys(this.profileBasedAdditionalServices.optionList).forEach((key: any) => {
          if (this.profileBasedAdditionalServices?.optionList[key]) {
            selectedSubsServVal.push(key);
          }
        });
      }
      this.profileBasedAdditionalServices.chkbxValChng =
        this.checkIfAddnlServChanged(param.prevAddServ, this.profileBasedAdditionalServices.optionList) || promoValChng;
    }
    if (this.fetchSubscriptionLevelMap(param?.key || '') != 'None') {
      let custInfo = { dcnNumber: '', dcnName: '', ucid: '', customerName: '' };
      if (this.customerPlan != 'None') {
        const validCustForAsset = this.getValidCustomerForAsset();
        if (validCustForAsset?.length == 1) {
          custInfo = {
            dcnNumber: validCustForAsset[0]?.dealerCustNo,
            dcnName: validCustForAsset[0]?.dealerCustName,
            ucid: validCustForAsset[0]?.ucid,
            customerName: validCustForAsset[0]?.ucidName,
          };
        }
      }
      const params: any = {
        assetSerialNumber: this.rowData?.serialNumber,
        assetMake: this.rowData?.make,
        deviceId: '',
        deviceSerialNumber: this.rowData?.deviceSerialNumer,
        deviceType: this.rowData?.deviceType,
        dcnNumber: custInfo?.dcnNumber,
        dcnName: custInfo?.dcnName,
        ucid: custInfo?.ucid,
        customerName: custInfo?.customerName,
        dealerCode: this.dealer?.dealerCode,
        applicationName: subs,
        siteName: this.subs?.siteInfo?.siteVal?.name || '',
        subscriptions: selectedSubsServVal,
      };
      if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
        params.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
      }
      this.subs.activeContract =
        !this.rowData?.prepay &&
        this.rowData?.contractStartDate &&
        this.rowData?.contractEndDate &&
        downgradeUpgradeStatus != this.subsGradeChangeStatus?.Downgraded;
      this.setContractPeriodForPromotions(params, isContractPeriodUpdated);
      params.prepay = this.subs?.prepaySelected;
      const dualModeScope = {
        catalogMapping: this.catalogMapping,
        deviceType: this.rowData?.deviceType,
        selectedService: this.selectedService,
        selectedSubscription: this.customerPlan,
      };
      if (this.commModeFactoryService.isDualModeSubFn(dualModeScope)) {
        if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
          if (params?.subscriptions?.indexOf(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED) == -1)
            params?.subscriptions?.push(dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED);
        }
        if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.SATELLITE_HOURS) {
          if (params?.subscriptions?.indexOf(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED) == -1)
            params?.subscriptions?.push(dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED);
        }
      }
      this.promo.promoRetrieved = false;
      this.promo.promoParams = params;
      this.promoLoader = true;
      this.triggerValChange();
      if (this.subs.promoInfo.showPromoValue) {
        this.subs.promoInfo.promoValue = [];
        this.isPromotionAPICallCompleted = false;
        this.promotionAPICallData = this.assetService.getPromotions(params).subscribe({
          next: (res: any) => {
            this.isPromotionAPICallCompleted = true;
            this.promoLoader = false;
            this.subs.promoInfo.showPromoLoad = true;
            this.promo.promoRetrieved = true;
            this.triggerValChange();
            if (hasIn(res, 'applyOto') || hasIn(res, 'tudrImpact')) {
              this.subs.promoInfo.showPromoLoad = false;
              this.subs.promoInfo.promoValue = res?.subscriptionRatePlan;
              if (
                this.deviceFeaturesInfo[this.rowData.deviceType]?.OTO_CHARGE_APPLICABLE &&
                this.isNonBillableCust &&
                Object.prototype.hasOwnProperty.call(res, 'applyOTO') &&
                Object.prototype.hasOwnProperty.call(res, 'tudrImpact')
              ) {
                if (res?.applyOTO && res?.tudrImpact) {
                  this.otoMessage = this.languageTranslations['Worklist'].WL_OTO_APPLICABLE_MSG;
                  this.showOtoMessage = true;
                } else if (!res?.applyOTO && res?.tudrImpact) {
                  this.otoMessage = this.languageTranslations['Worklist'].WL_OTO_NOT_APPLICABLE_MSG;
                  this.showOtoMessage = true;
                } else {
                  this.showOtoMessage = false;
                }
                this.promo.promoParams.applyOTO = res?.applyOTO;
                this.promo.promoParams.tudrImpact = res?.tudrImpact;
              }
            } else {
              this.showOtoMessage = false;
              this.subs.promoInfo.promoValue = [];
              this.subs.promoInfo.showPromoLoad = false;
            }
          },
          error: (_err: any) => {
            this.isPromotionAPICallCompleted = true;
            this.promoLoader = false;
            this.subs.promoInfo.showPromoLoad = false;
            this.showToastMessage(this.systemError, 'error');
          },
        });
      } else {
        this.subs.promoInfo.showPromoLoad = false;
        this.promo.promoRetrieved = true;
        this.triggerValChange();
      }
    } else {
      this.subs.promoInfo.promoValue = [];
      this.subs.promoInfo.showPromoLoad = false;
      this.promo.promoParams = {};
    }
  }

  getCurrentBillingDetails() {
    if (this.rowData.customerSubscriptionId || this.rowData.dealerSubscriptionId) {
      const pymtPreviewParams = this.setPaymentPreviewParams(this.subs?.custSubsPrev?.subscriptionLevel);
      const reqObj = {
        requestKey: this.rowData.make + '|' + this.rowData.serialNumber,
        requestType: 'current',
      };
    }
  }

  setPaymentPreviewParams(currCustSubs: any) {
    let custInfo = { dcnNumber: '', dcnName: '', ucid: '', customerName: '' };
    const validCustForAsset = this.getValidCustomerForAsset();
    if (validCustForAsset && validCustForAsset.length == 1) {
      custInfo = {
        dcnNumber: this ? validCustForAsset[0].dealerCustomerNumber : validCustForAsset[0].dealerCustNo,
        dcnName: this ? validCustForAsset[0].dealerCustomerName : validCustForAsset[0].dealerCustName,
        ucid: validCustForAsset[0].ucid,
        customerName: validCustForAsset[0].ucidName,
      };
    }
    return {
      assetSerialNumber: this.rowData.serialNumber ? this.rowData.serialNumber : '',
      assetMake: this.rowData.make ? this.rowData.make : '',
      deviceId: '',
      deviceSerialNumber: this.rowData.deviceSerialNumer ? this.rowData.deviceSerialNumber : '',
      deviceType: this.rowData.deviceType ? this.rowData.deviceType : '',
      dcnNumber: custInfo.dcnNumber,
      dcnName: custInfo.dcnName,
      ucid: (currCustSubs && currCustSubs != 'None') || this.customerPlan !== 'None' ? custInfo.ucid : '',
      customerName: custInfo.customerName,
      /* applicationName:$scope.usrOptedService, */
      dealerCode: this.rowData.dealerCode ? this.rowData.dealerCode : '',
      siteName: this.rowData.siteVal ? this.rowData.siteVal.name : '',
      contractStartDate: this.rowData.contractStartDate
        ? this.dateFormattingService.formatDate(new Date(this.rowData.contractStartDate), 'YYYY/MM/DD')
        : null,
      contractEndDate: this.rowData.contractEndDate
        ? this.dateFormattingService.formatDate(new Date(this.rowData.contractEndDate), 'YYYY/MM/DD')
        : null,
      prepay: !!this.rowData.prepay,
      productFamily: this.rowData.productFamily,
      isViewSubscription: true,
    };
  }

  getPaymentPreviewDetailsFn() {
    const dataParams = this.promo.promoParams;
    if (!dataParams.isViewSubscription) {
      dataParams.isNewSubscription = true;
    }
    if (dataParams.contractPeriod == '0') {
      delete dataParams.autoRenew;
    }
    if (hasIn(this.promo?.promoParams, 'applyOTO') && hasIn(this.promo?.promoParams, 'tudrImpact')) {
      delete dataParams.applyOTO;
      delete dataParams.tudrImpact;
      if (dataParams.isNewSubscription) {
        if (this.promo.promoParams.applyOTO && this.promo.promoParams.tudrImpact) {
          dataParams.applyOTO = true;
        } else {
          dataParams.applyOTO = false;
        }
      }
    }
    const reqObj = {
      requestKey:
        this.promo.subscriptionType == 'site'
          ? dataParams.siteName
          : this.rowData.make + '|' + this.rowData.serialNumber,
      requestType: dataParams.isViewSubscription ? 'current' : 'new',
    };

    if (this.promo.promoParams.isViewSubscription || this.promo.promoParams.isNewSubscription == false) {
      const currPayDetail = this.catBillingService.getCurrentPayDetail(reqObj.requestKey);
      const isPayDetailView = this.promo.promoParams.isViewSubscription ? true : false;
    }
  }

  setIsNewSubscription() {
    if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
      this.promo.promoParams.cva = this.rowData?.cvaInfo?.cvaStatus == dspConstants.Worklist.AVAILABLE ? true : false;
    }
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        this.promo.promoParams.isNewSubscription = !this.rowData?.customerSubscriptionId;
        break;
      /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
      case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        this.promo.promoParams.isNewSubscription = !this.rowData?.dealerSubscriptionId;
        break;*/
    }
  }
  checkIfAddnlServChanged(prevOptionList: any, currOptionList: any) {
    let isAddnlServChanged = false;
    for (const option in currOptionList) {
      if (
        Object.prototype.hasOwnProperty.call(currOptionList, option) &&
        ((currOptionList[option] && !prevOptionList[option]) || (!currOptionList[option] && prevOptionList[option]))
      ) {
        isAddnlServChanged = true;
        break;
      }
    }
    return isAddnlServChanged;
  }

  setContractPeriodForPromotions(params: any, isContractPeriodUpdated: any) {
    if (
      this.subsPricingEligible &&
      (this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId) &&
      !isContractPeriodUpdated
    ) {
      if (
        this.subscriptionChangeService.isSubscriptionChanged(this.subs) ||
        this.profileBasedAdditionalServices?.chkbxValChng
      ) {
        if (this.subs?.prepayEligible && !this.subs?.activeContract) {
          this.subs.prepaySelected = false;
          this.setContractDefaults();
          params.contractStartDate = null;
          params.contractEndDate = null;
        } else if (this.subs.activeContract) {
          this.setUpgradedContractPeriod();
          params.contractStartDate = this.dateFormattingService.customContractDateFn(
            this.subs?.contractPeriod?.startDate
          );
          params.contractEndDate = this.dateFormattingService.customContractDateFn(this.subs.contractPeriod?.endDate);
        }
      } else if ((this.subs?.prepayEligible && !!this.rowData?.prepay) || this.subs?.activeContract) {
        this.setSavedContractPeriod();
        params.contractStartDate = this.dateFormattingService.formatDate(
          new Date(this.rowData?.contractStartDate),
          'YYYY/MM/DD'
        );
        params.contractEndDate = this.dateFormattingService.formatDate(
          new Date(this.rowData?.contractEndDate),
          'YYYY/MM/DD'
        );
      } else {
        this.subs.prepaySelected = false;
        params.contractStartDate = null;
        params.contractEndDate = null;
      }
    } else if (
      this.subsPricingEligible &&
      this.subs.contractPeriod?.shadowStartDate != '-' &&
      isContractPeriodUpdated
    ) {
      params.contractStartDate = this.dateFormattingService.customContractDateFn(this.subs.contractPeriod.startDate);
      params.contractEndDate = this.dateFormattingService.customContractDateFn(this.subs.contractPeriod.endDate);
    } else {
      this.subs.prepaySelected = false;
      params.contractStartDate = null;
      params.contractEndDate = null;
    }
  }
  setUpgradedContractPeriod() {
    this.subs.contractPeriod.startDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
    this.subs.contractPeriod.endDate = this.dateFormattingService.formatDate(
      new Date(this.rowData?.contractEndDate),
      'MM/DD/YYYY'
    );
    this.subs.contractPeriod.shadowStartDate = this.dateFormattingService.formatDate(new Date(), 'MM/DD/YYYY');
    this.subs.contractPeriod.isDisabled = true;
    this.endDateFieldConfig.disabled = true;
    this.subs.contractPeriod.showContractForReadOnlyApp = this.setContractEligibilityForReadOnlyApps(
      this.subs?.contractPeriod?.startDate
    );
  }
  getAllSubsInfoFn(deviceType: any, selectedService: any) {
    //assigned this non-view function to scope for inheritance
    const paramInfo: any = {};
    if (!this.rowData.make) {
      this.rowData.make = this.rowData.catMake;
    }
    paramInfo.assetId = this.rowData?.make + '|' + this.rowData?.serialNumber;
    paramInfo.commercialType = deviceType;
    paramInfo.model = this?.rowData?.model || '';
    paramInfo.serviceSubscribed = selectedService;
    paramInfo.prepay = this.subs?.prepaySelected;
    if (this.subsPricingEligible && this.subs?.contractPeriod?.shadowStartDate != '-') {
      const param: ApplicablePromoParam = {
        key: 'custSubs',
        prevVal: this.getOptedSubs(this.rowData?.customerSubscriptionId),
      };
      /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
      if (
        this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM
      ) {
        param.key = 'dealerSubs';
        param.prevVal = this.getOptedSubs(this.rowData?.dealerSubscriptionId);
      } else*/
      if (this.fetchSubscriptionLevelMap(param?.key || '') == param?.prevVal && this.rowData?.contractStartDate) {
        paramInfo.contractStartDate = this.updateContractStartDate();
      } else {
        paramInfo.contractStartDate = this.dateFormattingService.customContractDateFn(
          this.subs?.contractPeriod?.startDate
        );
      }
      paramInfo.contractEndDate = this.dateFormattingService.customContractDateFn(this.subs?.contractPeriod?.endDate);
    } else {
      paramInfo.contractStartDate = null;
      paramInfo.contractEndDate = null;
    }
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
      case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        let catSubsTmp_MultiLevel: any[] = [];
        const catDataInfo_MultiLevel: any[] = [];
        let custDataInfo_MultiLevel: any[] = [];
        let dealerDataInfo_MultiLevel: any[] = [];
        catSubsTmp_MultiLevel = this.getSubsInfobyProfile(dspConstants.Common.CAT);
        if (loggedInProfile == dspConstants.Common.CAT) {
          catSubsTmp_MultiLevel = cloneDeep(catDataInfo_MultiLevel);
          if (catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.OBA) > -1) {
            catSubsTmp_MultiLevel.splice(catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.OBA), 1);
          }
          if (catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.VSD) > -1) {
            catSubsTmp_MultiLevel.splice(catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.VSD), 1);
          }
          if (catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.VED) > -1) {
            catSubsTmp_MultiLevel.splice(catSubsTmp_MultiLevel.indexOf(dspConstants.Worklist.VED), 1);
          }
          custDataInfo_MultiLevel = cloneDeep(catSubsTmp_MultiLevel);
          dealerDataInfo_MultiLevel = cloneDeep(catSubsTmp_MultiLevel);
        } else {
          custDataInfo_MultiLevel = this.getSubsInfobyProfile(dspConstants.Common.CUSTOMER);
          dealerDataInfo_MultiLevel = this.getSubsInfobyProfile(dspConstants.Common.DEALER);
        }
        catDataInfo_MultiLevel.unshift(this.catPlan);
        custDataInfo_MultiLevel.unshift(this.customerPlan);
        dealerDataInfo_MultiLevel.unshift(this.dealerPlan);
        paramInfo.catLevel = this.catPlan == 'None' ? [] : catDataInfo_MultiLevel;
        paramInfo.customerLevel = this.customerPlan == 'None' ? [] : custDataInfo_MultiLevel;
        paramInfo.dealerLevel = this.dealerPlan == 'None' ? [] : dealerDataInfo_MultiLevel;
        paramInfo.catLevelId =
          paramInfo.catPrevLevelId =
          paramInfo.customerLevelId =
          paramInfo.customerPrevLevelId =
          paramInfo.dealerLevelId =
          paramInfo.dealerPrevLevelId =
            '';
        break;*/
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
        paramInfo.productFamily = this.rowData.productFamily ? this.rowData.productFamily : null;
        paramInfo.siteId = this.customerPlan == 'None' ? null : this.subs?.siteInfo?.siteId || null;
        break;
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        let catSubsTmp_SingleLevel: any[] = [];
        let catDataInfo_SingleLevel: any[] = [];
        let custDataInfo_SingleLevel: any[] = [];
        let dealerDataInfo_SingleLevel: any[] = [];
        catDataInfo_SingleLevel = this.getSubsInfobyProfile(dspConstants.Common.CAT);
        if (loggedInProfile == dspConstants.Common.CAT) {
          catSubsTmp_SingleLevel = cloneDeep(catDataInfo_SingleLevel);
          if (catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.OBA) > -1) {
            catSubsTmp_SingleLevel.splice(catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.OBA), 1);
          }
          if (catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.VSD) > -1) {
            catSubsTmp_SingleLevel.splice(catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.VSD), 1);
          }
          if (catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.VED) > -1) {
            catSubsTmp_SingleLevel.splice(catSubsTmp_SingleLevel.indexOf(dspConstants.Worklist.VED), 1);
          }
          custDataInfo_SingleLevel = cloneDeep(catSubsTmp_SingleLevel);
          dealerDataInfo_SingleLevel = cloneDeep(catSubsTmp_SingleLevel);
        } else {
          custDataInfo_SingleLevel = this.getSubsInfobyProfile(dspConstants.Common.CUSTOMER);
          dealerDataInfo_SingleLevel = this.getSubsInfobyProfile(dspConstants.Common.DEALER);
        }
        catDataInfo_SingleLevel.unshift(this.customerPlan);
        custDataInfo_SingleLevel.unshift(this.customerPlan);
        dealerDataInfo_SingleLevel.unshift(this.customerPlan);
        paramInfo.catLevel = this.customerPlan == 'None' ? [] : catDataInfo_SingleLevel;
        paramInfo.customerLevel = this.customerPlan == 'None' ? [] : custDataInfo_SingleLevel;
        paramInfo.dealerLevel = this.customerPlan == 'None' ? [] : dealerDataInfo_SingleLevel;
        paramInfo.customerPrevLevel = paramInfo.dealerPrevLevel = paramInfo.catPrevLevel = '';
        break;
    }

    return paramInfo;
  }

  getSubsInfobyProfile(loggedInProfile: any) {
    const dataInfo: any = [];
    let savedSubscriptionChanged = false;
    const subsLevel: keyof AssetSubscription = this.getProfileBasedSubsLevel(loggedInProfile);
    const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    const serviceVal = savedSubscriptionId && this.serviceMapping[savedSubscriptionId];
    const mappedAddons = this.getMappedCatalogAddons(loggedInProfile, subsLevel);
    if (
      savedSubscriptionId &&
      this.serviceMapping[savedSubscriptionId] &&
      this.fetchSubscriptionLevelMap(subsLevel) != this.serviceMapping[savedSubscriptionId]?.baseSubscription
    ) {
      savedSubscriptionChanged = true;
    }
    if (mappedAddons?.length > 0) {
      mappedAddons?.forEach((val: any) => {
        const service = this.checkSelectedAddonInProfile(val.serviceName);
        if (
          service.selectedAddon ||
          (!savedSubscriptionChanged &&
            !service.AddonExit &&
            serviceVal?.additionalService?.indexOf(val.serviceName) > -1)
        ) {
          dataInfo.push(val?.serviceName);
        }
      });
    }
    return dataInfo;
  }
  checkSelectedAddonInProfile(serviceName: any) {
    const obj = { AddonExit: false, selectedAddon: false };
    const getKeys = Object.keys(this.profileBasedAdditionalServices?.optionList);
    if (getKeys?.length > 0) {
      getKeys.forEach((val: any) => {
        if (val == serviceName && this.profileBasedAdditionalServices?.optionList[val]) {
          obj.selectedAddon = true;
        } else if (val == serviceName && !this.profileBasedAdditionalServices?.optionList[val]) {
          obj.AddonExit = true;
        }
      });
    }
    return obj;
  }
  getMappedCatalogAddons(loggedInProfile: any, subsLevel: keyof AssetSubscription) {
    let mappedAddons;
    const supportedServicesList = this.rowData?.getSupportedServicesList || [];
    const catalogAddOns = {
      ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][loggedInProfile][
        this.fetchSubscriptionLevelMap(subsLevel)
      ],
    };
    if (
      this.selectedService == dspConstants.Worklist.VISION_LINK ||
      this.selectedService == dspConstants.Worklist.NEW_VISION_LINK
    ) {
      if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.SATELLITE_HOURS) {
        mappedAddons = this.getSelectedConditionalAddOnServ('Satellite', catalogAddOns, supportedServicesList);
      } else if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
        mappedAddons = this.getSelectedConditionalAddOnServ('Cellular', catalogAddOns, supportedServicesList);
      } else {
        mappedAddons = this.getSelectedConditionalAddOnServ('All', catalogAddOns, supportedServicesList);
      }
    } else {
      mappedAddons = catalogAddOns && catalogAddOns.services && catalogAddOns.services['All'];
    }
    return mappedAddons;
  }
  updateContractStartDate() {
    return this.subs?.contractPeriod?.startDate &&
      this.rowData?.contractStartDate != this.subs?.contractPeriod?.startDate
      ? this.dateFormattingService.formatDate(new Date(this.subs.contractPeriod.startDate), 'YYYY/MM/DD')
      : this.dateFormattingService.formatDate(new Date(this.rowData.contractStartDate), 'YYYY/MM/DD');
  }
  intersectionByObjectProperty(a: any, b: any, propertyName: any) {
    let c = [];
    for (const m in a) {
      for (const n in b) {
        if (a[m][propertyName] == b[n][propertyName]) c.push(a[m]);
      }
    }
    c = map(
      groupBy(c, function (item: any) {
        return item[propertyName];
      }),
      function (item2: any) {
        return item2[0];
      }
    );
    return c;
  }
  mapAddOnServices(communicationType: any, isConditionalAddonSupported?: any) {
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const subsLevel: keyof AssetSubscription = this.getProfileBasedSubsLevel(loggedInProfile);
    const catalogAddOns = {
      ...this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][loggedInProfile][
        this.fetchSubscriptionLevelMap(subsLevel)
      ],
    };
    const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    const serviceVal = savedSubscriptionId && this.serviceMapping[savedSubscriptionId];
    let filterMappedService =
      catalogAddOns &&
      catalogAddOns.services &&
      catalogAddOns.services[communicationType] &&
      catalogAddOns.services[communicationType]?.filter((e: any) => {
        if (this.dspStoreData?.featureFlagInfo?.CVAEnabled == true) {
          this.getCvaEnabledList();
          if (this.cvaEnabledList?.indexOf(e.serviceName) != -1) {
            e.isSubscribable = this.checkIfCVAExists(
              catalogAddOns,
              e.serviceName,
              this.rowData?.getSupportedServicesList
            );
          }
        }
        const isAddonSubscribable =
          e.isSubscribable || (!e.isSubscribable && serviceVal?.additionalService?.indexOf(e.serviceName) > -1);
        return isAddonSubscribable;
      });
    if (isConditionalAddonSupported) {
      filterMappedService = this.getSelectedConditionalAddOnServ(
        'All',
        catalogAddOns,
        this.rowData?.getSupportedServicesList
      );
    }
    if (filterMappedService?.length > 0) {
      this.validatePricingFn(filterMappedService, true).then((response: any) => {
        this.applicableAddlSubscriptions = response;
        this.setOptionalPlans();
      });
    } else {
      this.applicableAddlSubscriptions = [];
    }
  }
  getSelectedConditionalAddOnServ(serviceType: any, subscription: any, supportedServices: any[] = []) {
    let servicesList;
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const savedSubscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    const serviceVal = savedSubscriptionId && this.serviceMapping[savedSubscriptionId];
    if (subscription?.services) {
      servicesList = subscription.services[serviceType] || [];
      const filteredServices =
        subscription?.services['ConditionalAddons']?.filter((n: any) => {
          return !isEmpty(supportedServices) && supportedServices?.indexOf(n?.serviceName) != -1 && n?.isSubscribable;
        }) || [];
      servicesList = servicesList.concat(filteredServices);
    }

    const filterMappedServices = servicesList?.filter((e: any) => {
      const isAddonSubscribable =
        e.isSubscribable || (!e.isSubscribable && serviceVal?.additionalService?.indexOf(e.serviceName) > -1);

      return isAddonSubscribable;
    });

    return filterMappedServices || [];
  }
  getProfileBasedSubsLevel(loggedInProfile: any) {
    let subsLevelVal: keyof AssetSubscription;
    if (
      this.subscriptionLevelCategory?.selectedCategory ==
        dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM ||
      this.subscriptionLevelCategory?.selectedCategory == dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR
    ) {
      subsLevelVal = 'custSubs';
    } else {
      const newSubs = loggedInProfile == dspConstants.Common.DEALER ? 'dealerSubs' : 'catSubs';
      subsLevelVal = loggedInProfile == dspConstants.Common.CUSTOMER ? 'custSubs' : newSubs;
    }
    return subsLevelVal;
  }
  setProfileLvlAddnlServices(subsLevel: keyof AssetSubscription) {
    const loggedInProfile = this.telUserType || dspConstants.Common.CUSTOMER;
    const subscriptionId = this.rowData[loggedInProfile.toLowerCase() + 'SubscriptionId'];
    if (
      subscriptionId &&
      this.fetchSubscriptionLevelMap(subsLevel) &&
      this.fetchSubscriptionLevelMap(subsLevel) != 'None'
    ) {
      this.mapAddOnServices('All');
      const dataHldr = this.serviceMapping[subscriptionId];
      this.profileBasedAdditionalServices.selectedCount = this.getSelectedAddonCnt(dataHldr);
      this.initialAddonSubscribed = dataHldr?.additionalService;
      dataHldr?.additionalService?.forEach((val: any) => {
        this.profileBasedAdditionalServices.optionList[val] = true;
        this.profileBasedAdditionalServices.optionListPrev[val] = true;
      });
    } else {
      this.applicableAddlSubscriptions = [];
    }
  }
  mapDualModeAddons(serviceVal: any, supportedServices: any) {
    const dualModeScope = {
      catalogMapping: this.catalogMapping,
      deviceType: this.rowData.deviceType,
      selectedService: this.selectedService,
      selectedSubscription: this.customerPlan,
    };
    if (this.commModeFactoryService.isDualModeSubFn(dualModeScope)) {
      if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.SATELLITE_HOURS) {
        this.validatePricingFn(this.getSelectedConditionalAddOnServ('Satellite', serviceVal, supportedServices)).then(
          (response: any) => {
            this.applicableAddlSubscriptions = response;
            const remIndx1 = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
              return obj?.serviceName == dspConstants.Worklist.CURRENT_ADVANCED_PRODUCTIVITY;
            });
            if (remIndx1 > -1) {
              this.applicableAddlSubscriptions?.splice(remIndx1, 1);
            }
            const remIndx2 = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
              return obj?.serviceName == dspConstants.Worklist.ADVANCED_PRODUCTIVITY;
            });
            if (remIndx2 > -1) {
              this.applicableAddlSubscriptions?.splice(remIndx2, 1);
            }
            const remIndx = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
              return obj?.serviceName == dspConstants.Worklist.CELLULAR_RADIO_SUSPENDED;
            });
            if (remIndx > -1) {
              this.applicableAddlSubscriptions?.splice(remIndx, 1);
            }
            this.setOptionalPlans();
          }
        );
      }
      if (this.rowData?.selectedCommunicationMode == dspConstants.Worklist.CELLULAR_HOURLY) {
        this.validatePricingFn(this.getSelectedConditionalAddOnServ('Cellular', serviceVal, supportedServices)).then(
          (response: any) => {
            this.applicableAddlSubscriptions = response;
            const remIndx = this.applicableAddlSubscriptions?.findIndex((obj: any) => {
              return obj?.serviceName == dspConstants.Worklist.SATELLITE_RADIO_SUSPENDED;
            });
            if (remIndx > -1) {
              this.applicableAddlSubscriptions?.splice(remIndx, 1);
            }
            this.setOptionalPlans();
          }
        );
      }
    }
  }
  setDualModeInfo() {
    const dualModeScope = {
      catalogMapping: this.catalogMapping,
      deviceType: this.rowData.deviceType,
      selectedService: this.selectedService,
      selectedSubscription: this.customerPlan,
      dealerCode: this.dealer?.dealerCode,
    };
    const isDualModeSub = this.commModeFactoryService.isDualModeSubFn(dualModeScope);

    const dualModeInfo = {
      isDualModeSub: isDualModeSub,
      commModeSelected: this.rowData?.selectedCommunicationMode || this.commModes[0],
      isCommModeDisabledForDealer: this.commModeFactoryService.isCommModeDisabledForDealerFn(dualModeScope),
    };
    this.dualModeSubscriptionSelected(this.rowData?.assetId, { dualModeInfo: dualModeInfo });
  }

  dualModeSubscriptionSelected(_assetId: any, object: any) {
    const dualModeInfo = object;
    dualModeInfo.commModeSelected = dualModeInfo?.commModeSelected || this.dualModeInfo?.commModeSelected;
    this.dualModeInfo = cloneDeep(dualModeInfo);
  }

  changeLvl1Type(serviceOpted: any) {
    if (
      this.rowData.reportingState === dspConstants.Worklist.UNSUBSCRIBED &&
      this.selectedAssetSubscriptionFormData.service.selectedService != '' &&
      this.selectedAssetSubscriptionFormData.service.selectedService !==
        this.selectedAssetSubscriptionFormData.expandedService
    ) {
      const message = 'Current selected plans will be disregarded and new selections will apply.';
      this.showToastMessage(message, 'error');
    }
    this.selectedAssetSubscriptionFormData.service.selectedService = serviceOpted;
    this.selectedAssetSubscriptionFormData.service.currentService = serviceOpted;
    if (this.dspConfig?.enableAddonDynamicPricing?.toLowerCase() == 'true') {
      this.rowData.dynamicPricingList = [];
    }
    this.isWarningMsgDisplayed = false;
    if (
      !this.customerPlan &&
      this.rowData?.customerSubscriptionId &&
      this.serviceMapping[this.rowData?.customerSubscriptionId]
    ) {
      this.customerPlan = this.serviceMapping[this.rowData?.customerSubscriptionId]?.baseSubscription;
      this.setFormValue(
        'customerPlan',
        this.serviceMapping[this.rowData?.customerSubscriptionId]?.baseSubscription,
        'customerLevel'
      );
    }
    this.checkDisplayWarningMessageHandler(serviceOpted, this.customerPlan, (data: any) =>
      this.subscriptionSettingsLevel1(data)
    );
    // }
    this.setTemplateCondt();
  }

  /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
  changeLvl2Type(serviceOpted: any) {
    if (this.dspConfig?.enableAddonDynamicPricing?.toLowerCase() == 'true') {
      this.rowData.dynamicPricingList = [];
    }
    this.isWarningMsgDisplayed = false;
    this.checkDisplayWarningMessageHandler(serviceOpted, this.dealerPlan, (data: any) =>
      this.subscriptionSettingsLevel2(data)
    );
  }

  changeLvl3Type(serviceOpted: any) {
    if (this.dspConfig?.enableAddonDynamicPricing?.toLowerCase() == 'true') {
      this.rowData.dynamicPricingList = [];
    }
    this.isWarningMsgDisplayed = false;
    this.checkDisplayWarningMessageHandler(serviceOpted, this.catPlan, () => this.subscriptionSettingsLevel3());
  }
*/
  checkDisplayWarningMessageHandler(serviceOpted: any, selectedSubscription: any = null, subsSettingFn: any = null) {
    const checkDisplayWarningMessage = this.deviceFeaturesInfo && this.deviceFeaturesInfo[this.rowData?.deviceType];
    if (!this.isWarningMsgDisplayed && checkDisplayWarningMessage?.Display_Warning_Message?.length > 0) {
      const displayWarningMessagelist = checkDisplayWarningMessage?.Display_Warning_Message[0][serviceOpted];
      if (
        displayWarningMessagelist?.length > 0 &&
        ((selectedSubscription && this.isDisplayWarningExistForSubs(displayWarningMessagelist, selectedSubscription)) ||
          (!selectedSubscription && this.isDisplayWarningExistForSubs(displayWarningMessagelist, this.customerPlan)) ||
          this.isDisplayWarningExistForSubs(displayWarningMessagelist, this.dealerPlan) ||
          this.isDisplayWarningExistForSubs(displayWarningMessagelist, this.catPlan))
      ) {
        // alert($rootScope.languageTranslations['WL_DISPLAY_WARN_MSG'], function(){
        this.isWarningMsgDisplayed = true;
        subsSettingFn(serviceOpted);
      } else if (subsSettingFn) {
        subsSettingFn(serviceOpted);
      }
    } else if (subsSettingFn) {
      subsSettingFn(serviceOpted);
    }
  }

  isDisplayWarningExistForSubs(displayWarningMessagelist: any, selectedSubscription: any) {
    return displayWarningMessagelist?.indexOf(selectedSubscription) != -1;
  }
  subscriptionSettingsLevel1(serviceOpted: any) {
    const checkGradeForPrivilege = this.catalogMapping?.[this.rowData?.deviceType];
    if (
      checkGradeForPrivilege[this.selectedService] &&
      checkGradeForPrivilege[this.selectedService][dspConstants.Common.CUSTOMER] &&
      !checkGradeForPrivilege[this.selectedService][dspConstants.Common.CUSTOMER][this.customerPlan]
    ) {
      this.subsInfo = '';
      this.subs.promoInfo.showPromoValue = false;
      this.applicableAddlSubscriptions = [];
      const disableContractPeriodForExSubs = '';
      this.setContractPeriodEditEligible(disableContractPeriodForExSubs);
      const message = this.languageTranslations['Worklist']?.WL_EXCLUDED_SUBS_MSG || '';
      this.showToastMessage(message, 'error');
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    } else {
      //Setting Grade details for Customer Subscription
      const customerOptionGrade = this.getGradeValue(this.customerPlan, '', dspConstants.Common.CUSTOMER);
      const prevcustomerOptionGrade = this.getGradeValue(
        '',
        this.rowData?.customerSubscriptionId,
        dspConstants.Common.CUSTOMER
      );
      const subsDowngradeUpgradeStatus = this.checkSubsUpgradeDowngradeStatus(
        customerOptionGrade,
        prevcustomerOptionGrade
      );

      const filteredCustomerList = this.getValidCustomerForAsset(); // Moved here to avoid visionlink supported service call

      switch (this.subscriptionLevelCategory?.selectedCategory) {
        case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
          this.setContractPeriodEditEligible(subsDowngradeUpgradeStatus);
          this.populateCustomerLvlAddnlServices(true);
          if (this.rowData.customerSubscriptionId) {
            if (this.rowData.productFamily) {
              this.productFamily.selectedProductFamily = this.rowData.productFamily;
            }
          }
          this.validateAddOnForProductFamily();
          if (this.subs.siteInfo.isValidSite && this.customerPlan !== 'None') {
            this.triggerValChange();
          } else {
            this.triggerValChange('');
          }
          if (this.rowData.customerSubscriptionId && this.customerPlan == 'None') {
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          }
          if (this.rowData.customerSubscriptionId && this.customerPlan == 'None') {
            if (this.rowData.productFamily) {
              this.showAssetProductFamilyFn();
            }
            this.manageAssetSubscriptionForm.get('selectedProductFamily')?.patchValue([]);
          }
          break;
        case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
          if (this.selectedService == dspConstants.Worklist.VISION_LINK) {
            delete this.rowData?.selectedCommunicationMode;
            const visionLinkSubsStatus = !this.isRatePlanExist ? '' : subsDowngradeUpgradeStatus;
            this.setContractPeriodEditEligible(visionLinkSubsStatus);
            const validateOnRes = (list: any[] = []) => {
              if (list) {
                this.supportedServicesForAsset = list;
                this.populateProfileLvlAddnlServices(list);
              } else {
                this.populateProfileLvlAddnlServices();
              }
              this.setDualModeInfo();
            };
            this.getSupportedServicesFn(validateOnRes);
          } else if (this.selectedService == dspConstants.Worklist.NEW_VISION_LINK) {
            this.setContractPeriodEditEligible(subsDowngradeUpgradeStatus);
            const validateOnRes = (list: any[] = []) => {
              if (list) {
                this.supportedServicesForAsset = list;
                this.populateProfileLvlAddnlServices(list);
              } else {
                this.populateProfileLvlAddnlServices();
              }
            };
            this.getSupportedServicesFn(validateOnRes);
          } else {
            this.setContractPeriodEditEligible(subsDowngradeUpgradeStatus);
            this.populateProfileLvlAddnlServices();
          }
          if (this.customerPlan !== 'None') {
            this.triggerValChange();
          }
          break;
        /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
        case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
          let dealerOptionGrade = this.getGradeValue(this.dealerPlan, '', dspConstants.Common.DEALER);
          const catOptionGrade = this.getGradeValue(this.catPlan, '', dspConstants.Common.CAT);
          const prevDealerOptionGrade = this.getGradeValue(
            '',
            this.rowData.dealerSubscriptionId,
            dspConstants.Common.DEALER
          );
          const prevCatOptionGrade = this.getGradeValue('', this.rowData.catSubscriptionId, dspConstants.Common.CAT);
          if (this.inheritanceFlag.dealerInherited || customerOptionGrade >= prevDealerOptionGrade) {
            this.dealerPlan = cloneDeep(
              filter(this.applicableSubscriptions.level1 as any, ['baseSubscription', this.customerPlan])[0]
                ?.baseSubscription
            );
            this.setFormValue('dealerPlan', this.dealerPlan, 'dealerLevel');
          } else {
            this.setSelectedSubs('dealerSubs', this.getOptedSubs(this.rowData.dealerSubscriptionId));
          }
          dealerOptionGrade = this.getGradeValue(this.dealerPlan, '', dspConstants.Common.DEALER);

          if (this.inheritanceFlag.catInherited || dealerOptionGrade >= prevCatOptionGrade) {
            this.catPlan = cloneDeep(
              filter(this.applicableSubscriptions.level1 as any, ['baseSubscription', this.dealerPlan])[0]
                ?.baseSubscription
            );
            this.setFormValue('catPlan', this.catPlan, 'catLevel');
            this.populateProfileLvlAddnlServices();
          } else {
            this.setSelectedSubs('catSubs', this.getOptedSubs(this.rowData.catSubscriptionId));
          }
          this.validatePricingFn(this.setSubsValues(true, customerOptionGrade, dspConstants.Common.DEALER)).then(
            (response: any) => {
              this.applicableSubscriptions.level2 = response;
              this.setSubscriptionDataSource(
                dspConstants.AssetSubscriptionConstants.Level2,
                dspConstants.AssetSubscriptionConstants.Dealer
              );
              if (this.customerPlan !== 'None') {
                this.triggerValChange();
              }
            }
          );
          this.applicableSubscriptions.level3 = this.setSubsValues(true, dealerOptionGrade, dspConstants.Common.CAT);
          this.setSubscriptionDataSource(
            dspConstants.AssetSubscriptionConstants.Level3,
            dspConstants.AssetSubscriptionConstants.CAT
          );
          this.setContractPeriodEditEligible(subsDowngradeUpgradeStatus);
          break;*/
        default:
          break;
      }
      if (
        !this.showB2CWarningPopupInPaymentPreview &&
        this.telUserType == dspConstants.Common.DEALER &&
        !this.isNonBillableCust &&
        this.customerPlan != 'None'
      ) {
        this.b2cCustWarningMsg(this.b2cCustWarningTemplate);
      }
      if (this.subsPricingEligible) {
        if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
          this.promotionAPICallData.unsubscribe();
        }
        this.getApplicablePromo(serviceOpted, '', '', '', subsDowngradeUpgradeStatus);
        this.setIsNewSubscription();
      }

      if (this.selectedService != dspConstants.Worklist.VISION_LINK) {
        this.setDualModeInfo();
      }
    }
  }

  b2cCustWarningMsg(content: any) {
    this.modal.openModal(content, {
      width: '600px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }

  openCatGradeConnectivityPopup(content: any) {
    this.catGradeModalRef = this.modal.openModal(content, {
      width: '600px',

      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    // this.showCATGradePopup = false;
  }

  closeCatGradeConnectivityPopup() {
    this.catGradeModalRef.close();
  }

  closeB2cCustWarningMsgPopup(resetFlag: boolean) {
    if (!resetFlag) {
      this.modal.close();
    } else {
      this.isResetService = true;
      this.modal.close();
    }
  }

  /* Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
subscriptionSettingsLevel2(serviceOpted: any) {
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        const dealerOptionGrade = this.getGradeValue(this.dealerPlan, '', dspConstants.Common.DEALER);
        const prevDealerOptionGrade = this.getGradeValue(
          '',
          this.rowData?.dealerSubscriptionId,
          dspConstants.Common.DEALER
        );
        const catOptionGrade = this.getGradeValue(this.catPlan, '', dspConstants.Common.CAT);
        const subsDowngradeUpgradeStatus = this.checkSubsUpgradeDowngradeStatus(
          dealerOptionGrade,
          prevDealerOptionGrade
        );
        this.applicableSubscriptions.level3 = this.setSubsValues(true, dealerOptionGrade, dspConstants.Common.CAT);
        this.setSubscriptionDataSource(
          dspConstants.AssetSubscriptionConstants.Level3,
          dspConstants.AssetSubscriptionConstants.CAT
        );
        if (this.inheritanceFlag.catInherited || dealerOptionGrade >= catOptionGrade) {
          this.catPlan = cloneDeep(
            filter(this.applicableSubscriptions.level2 as any, ['baseSubscription', this.dealerPlan])[0]
              ?.baseSubscription
          );
          this.setFormValue('catPlan', this.catPlan, 'catLevel');
          this.populateProfileLvlAddnlServices();
        } else {
          this.setSelectedSubs('catSubs', this.getOptedSubs(this.rowData?.catSubscriptionId));
        }
        this.setContractPeriodEditEligible(subsDowngradeUpgradeStatus);
        this.getApplicablePromo(serviceOpted);
        this.setIsNewSubscription();
        this.triggerValChange();
        break;
      default:
        break;
    }
  }

  subscriptionSettingsLevel3() {
    switch (this.subscriptionLevelCategory?.selectedCategory) {
      case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        this.populateProfileLvlAddnlServices();
        this.triggerValChange();
        break;
      default:
        break;
    }
  }
*/
  setContractPeriodEditEligible(subsDowngradeUpgradeStatus: any) {
    this.isRatePlanExist = this.checkIfRatePlanExists();
    if (this.subsPricingEligible && this.isRatePlanExist) {
      if (
        this.rowData?.contractStartDate &&
        this.rowData?.contractEndDate &&
        (this.rowData?.customerSubscriptionId || this.rowData?.dealerSubscriptionId) &&
        ((this.dealerPlan === '' && this.subs?.custSubsPrev?.subscriptionLevel != this.customerPlan) ||
          this.subs?.dealerSubsPrev?.subscriptionLevel != this.dealerPlan)
      ) {
        if (this.subs?.prepayEligible && !this.subs?.activeContract) {
          this.subs.prepaySelected = false;
          this.setContractDefaults();
        } else {
          if (subsDowngradeUpgradeStatus && subsDowngradeUpgradeStatus == this.subsGradeChangeStatus.Upgraded) {
            this.setUpgradedContractPeriod();
          } else if (
            !subsDowngradeUpgradeStatus ||
            (subsDowngradeUpgradeStatus && subsDowngradeUpgradeStatus == this.subsGradeChangeStatus.Downgraded)
          ) {
            this.setContractDefaults();
          }
        }
      } else {
        this.setSavedContractPeriod();
      }
    } else {
      this.subs.prepaySelected = false;
      this.setContractDefaults();
    }
  }
  getGradeValue(getGradeUsingSubLbl: any, getGradeUsingSubId: any, privilegeType: any) {
    if (getGradeUsingSubLbl) {
      if (getGradeUsingSubLbl && getGradeUsingSubLbl != 'None') {
        return this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][privilegeType][getGradeUsingSubLbl]
          ?.grade;
      } else {
        return '0';
      }
    } else {
      if (getGradeUsingSubId && this.serviceMapping && this.serviceMapping[getGradeUsingSubId]) {
        const subscriptionLabel = this.serviceMapping[getGradeUsingSubId]?.baseSubscription;
        return this.catalogMapping?.[this.rowData?.deviceType][this.selectedService][privilegeType][subscriptionLabel]
          ?.grade;
      } else {
        return '0';
      }
    }
  }

  checkSubsUpgradeDowngradeStatus(currSubs: any, prevSubs: any) {
    if (prevSubs != 0 && currSubs > prevSubs) {
      return this.subsGradeChangeStatus?.Upgraded;
    } else if (currSubs < prevSubs) {
      return this.subsGradeChangeStatus?.Downgraded;
    } else {
      return '';
    }
  }

  onBasePlanChange(basePlanEvent: any) {
    this.showCATGradePopup = false;
    this.isAutoSelectItemsRejected = false;
    const { event, level } = basePlanEvent;
    if (
      basePlanEvent.event.currentSelection[0].value == 'None' &&
      this.selectedAssetSubscriptionFormData.service.currentService ===
        this.selectedAssetSubscriptionFormData.service.previousService &&
      this.rowData.reportingState === dspConstants.Worklist.SUBSCRIBED
    ) {
      this.isCancelView = true;
      this.selectedAssetSubscriptionFormData.isValidForm = true;
      this.loadCancelScreen.emit(true);
    } else if (
      basePlanEvent.event.currentSelection[0].value == 'None' &&
      this.rowData.reportingState === dspConstants.Worklist.UNSUBSCRIBED
    ) {
      this.isCancelView = false;
      this.selectedAssetSubscriptionFormData.isValidForm = false;
      this.loadCancelScreen.emit(false);
    } else {
      this.isCancelView = false;
      this.loadCancelScreen.emit(false);
    }
    if (event.action !== 'onLoad') {
      this.showOtoMessage = false;
      const selectedSubscription = event?.currentSelection[0]?.baseSubscription || '';
      this.setBasePlanFormData(selectedSubscription, level);
      this.resetAdditionalPlans();
      this.resetCurrentPrepayPlans();
      switch (level) {
        case dspConstants.Worklist.CUSTOMERLEVEL:
          this.customerPlan = selectedSubscription;
          this.changeLvl1Type(this.selectedService);
          break;
        /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
        case dspConstants.Worklist.DEALERLEVEL:
          this.dealerPlan = selectedSubscription;
          this.changeLvl2Type(this.selectedService);
          break;
        case dspConstants.Worklist.CATLEVEL:
          this.catPlan = selectedSubscription;
          this.changeLvl3Type(this.selectedService);
          break;
        */
        default:
          break;
      }
    }
  }
  resetCurrentPrepayPlans() {
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected = false;
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractStartDate = '';
    this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractEndDate = '';
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }
  setBasePlanFormData(selectedSubscription: any, level: keyof SubscriptionData) {
    switch (this.subscriptionLevelCategory.selectedCategory) {
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_PROFILE_ADDON_FSM:
        this.updateCurrentSubscription(selectedSubscription);
        this.updateSelectedSubscription(selectedSubscription);
        break;
      /*Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
       case dspConstants.subsLevelCategory.MULTI_LEVEL_PROFILE_ADDON_FSM:
        // Need to check once and update the below commented lines
        // for (const element in this.selectedAssetSubscriptionFormData.subscription.currentSubscription) {
        //   this.selectedAssetSubscriptionFormData.subscription.currentSubscription[element as keyof SubscriptionData] =
        //     '';
        // }
        this.selectedAssetSubscriptionFormData.subscription.currentSubscription[level] = selectedSubscription;
        break; */
      case dspConstants.subsLevelCategory.SINGLE_LEVEL_MINESTAR:
        this.updateCurrentSubscription(selectedSubscription);
        this.updateSelectedSubscription(selectedSubscription);
        break;
      default:
        break;
    }
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  updateCurrentSubscription(selectedSubscription: any) {
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.catLevel = selectedSubscription;
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.dealerLevel = selectedSubscription;
    this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel = selectedSubscription;
  }

  updateSelectedSubscription(selectedSubscription: any) {
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.catLevel = selectedSubscription;
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.dealerLevel = selectedSubscription;
    this.selectedAssetSubscriptionFormData.subscription.selectedSubscription.customerLevel = selectedSubscription;
  }

  resetAdditionalPlans() {
    const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
    if (currentSubscription.customerLevel == previousSubscription.customerLevel) {
      this.selectedAssetSubscriptionFormData.addOns.currentAddons =
        this.selectedAssetSubscriptionFormData.addOns.previousddons;
      const { previousddons } = this.selectedAssetSubscriptionFormData.addOns;
      if (previousddons?.length > 0) {
        for (const addOn of previousddons) {
          this.profileBasedAdditionalServices.optionListPrev[addOn] = true;
        }
      }
      this.applicableAddlSubscriptions = this.mockApplicableAddlSubscription;
      this.setAddonFormData();
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    } else {
      this.manageAssetSubscriptionForm.get('addOnOptions')?.patchValue([]);
      this.selectedAssetSubscriptionFormData.addOns.currentAddons = [];
      this.selectedAssetSubscriptionFormData.addOns.selectedAddons = [];
      this.subs.contractPeriod.isDisabled = false;
      this.endDateFieldConfig.disabled = false;
      this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    }
  }

  onOptionalPlanChange(optionalPlanEvent: any) {
    const { event, prop } = optionalPlanEvent;
    this.manageAssetSubscriptionForm.patchValue({
      [prop]: event?.currentSelection,
    });
    this.profileBasedAdditionalServices.optionList = {};
    event.currentSelection?.forEach((option: any) => {
      this.profileBasedAdditionalServices.optionList[option.serviceName] = true;
    });
    this.selectedAssetSubscriptionFormData.addOns.currentAddons = Object.keys(
      this.profileBasedAdditionalServices.optionList
    );
    this.selectedAssetSubscriptionFormData.addOns.selectedAddons = Object.keys(
      this.profileBasedAdditionalServices.optionList
    );
    if (isEmpty(this.profileBasedAdditionalServices.optionList)) {
      this.showCATGradePopup = false;
    }
    this.resetCurrentPrepayPlans();
    this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    this.additionalServicesChngFn(this.selectedService);
  }

  additionalServicesChngFn(subscriptionLevel: any) {
    if (
      Object.keys(this.profileBasedAdditionalServices.optionList).indexOf(
        dspConstants.Worklist.CAT_GRADE_CONNECTIVITY
      ) !== -1
    ) {
      if (!this.showCATGradePopup) {
        this.showCATGradePopup = true;
        this.openCatGradeConnectivityPopup(this.catGradeConnectivityPopup);
      }
    } else {
      this.showCATGradePopup = false;
    }

    this.profileBasedAdditionalServices.selectedCount = size(
      Object.keys(this.profileBasedAdditionalServices.optionList)
    );
    if (this.rowData?.make && this.rowData?.serialNumber) {
      const apiKey = this.rowData?.make + '|' + this.rowData?.serialNumber;
      if (this.changedServices != undefined) {
        if (this.changedServices?.indexOf(apiKey) === -1) this.changedServices?.push(apiKey);
      } else {
        this.changedServices = [];
        this.changedServices.push(apiKey);
      }
    }
    this.validateAddOnForProductFamily();
    if (this.promotionAPICallData && !this.isPromotionAPICallCompleted) {
      this.promotionAPICallData.unsubscribe();
    }
    this.getApplicablePromo(subscriptionLevel, '');
    this.setIsNewSubscription();
    if (
      this.selectedService != dspConstants.Worklist.MINESTAR ||
      (this.selectedService == dspConstants.Worklist.MINESTAR && this.subs.siteInfo.isValidSite)
    ) {
      this.triggerValChange();
    }
    this.checkDisplayWarningMessageHandler(this.selectedService); // To show any warning message
    this.setTemplateCondt(); // to set any template condition
  }

  checkIfSelectionChange() {
    if (this.rowData.reportingState === 'Subscribed') {
      const { currentAddons, previousddons } = this.selectedAssetSubscriptionFormData.addOns;
      const { currentService, previousService } = this.selectedAssetSubscriptionFormData.service;
      const { currentSubscription, previousSubscription } = this.selectedAssetSubscriptionFormData.subscription;
      const { currentPrepayData, previousPrepayData } = this.selectedAssetSubscriptionFormData.prepay;
      const { siteInfo, previousSiteInfo } = this.selectedAssetSubscriptionFormData;
      if (
        JSON.stringify(currentAddons) === JSON.stringify(previousddons) &&
        currentService === previousService &&
        currentSubscription.catLevel === previousSubscription.catLevel &&
        currentSubscription.dealerLevel === previousSubscription.dealerLevel &&
        currentSubscription.customerLevel === previousSubscription.customerLevel &&
        (siteInfo.siteId === previousSiteInfo?.siteId || '')
      ) {
        if (previousPrepayData.prepaySelected && !currentPrepayData.prepaySelected) {
          this.resetToSelectedPrepayData();
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        }
      }
    }
  }
  showDATToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }

  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }

  getDealerParentAndChildfeatures() {
    const dealerObj = {
      feature: 'China',
      dealerCode: this.dealer?.dealerCode,
    };
    this.assetDrawerService.getDealerParentAndChildfeatures(dealerObj).subscribe({
      next: (res: any) => {
        if (res.featureName === 'China') {
          this.isChinaDealer = true;
        }
      },
      error: (err: any) => {
        if (err.status === 404) {
          this.isChinaDealer = false;
        }
      },
    });
  }

  datClick() {
    window.open(this.datUrl, '_blank');
  }
}
