import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Form, NgForm } from '@angular/forms';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { AssetService } from '../../services/asset.service';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-inline-edit-component',
  templateUrl: './cat-grade-inline-edit-component.component.html',
  styleUrls: ['./cat-grade-inline-edit-component.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CatGradeInlineEditComponentComponent implements OnInit {
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;

  serialNoBlurred = false;
  // selectedAssetSubscriptionFormData: AssetSubscriptionFormDataType = {}
  selectedAssetSubscriptionFormData: AssetSubscriptionFormDataType = {
    isValidForm: false,
    expandedService: '',
    customer: {},
    service: {
      currentService: '',
      previousService: '',
      selectedService: '',
    },
    subscription: {
      currentSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      previousSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
      selectedSubscription: {
        catLevel: '',
        dealerLevel: '',
        customerLevel: '',
      },
    },
    addOns: {
      currentAddons: [],
      previousddons: [],
      selectedAddons: [],
    },
    prepay: {
      currentPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      previousPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
      selectedPrepayData: {
        prepaySelected: false,
        contractStartDate: '',
        contractEndDate: '',
      },
    },
    catBillingData: {
      billingGroupId: '',
    },
    productFamily: {
      currentProductFamily: '',
      previousProductFamily: '',
    },
    selectedCategory: '',
    siteInfo: {
      siteName: '',
      siteId: '',
    },
    previousSiteInfo: {
      siteName: '',
      siteId: '',
    },
    selectedSiteInfo: {
      siteName: '',
      siteId: '',
    },
    catGradeEditSerialInfo: {
      componentType: '',
      serialNumber: '',
      assetSerialNumber: '',
      dealerCode: '',
      model: '',
    },
  };
  @ViewChild('catInlineEditForm') catInlineEditForm!: NgForm;
  assetFormSubscription!: any;
  originalEditInfo!: any[];
  editedRowData: any;
  originalCustomerInfo: any;
  noMatchingComponent: any = [];
  catGradeEditInfoSubscription!: Subscription;
  pattern = /^[0-9]{4}[A-Za-z][0-9]{3}[A-Za-z0-9]{2}$/;
  enableSameSerialNoErr = false;

  constructor(
    public assetService: AssetService,
    private assetDrawerService: AssetDrawerService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.rowData = this.value ? this.value : '';
    this.config = this.columnWithHeader ? this.columnWithHeader : '';
    this.assetFormSubscription = this.assetService.assetSubscriptionFormEvent.subscribe(
      (data: AssetSubscriptionFormDataType) => {
        this.selectedAssetSubscriptionFormData = data;
      }
    );

    this.catGradeEditInfoSubscription = this.assetDrawerService.catGradeEditInfo.subscribe((data: any) => {
      this.originalEditInfo = data.editInfo;
    });
    this.assetDrawerService.catGradeCustomerInfo.subscribe((data: any) => {
      this.originalCustomerInfo = data;
    });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // && this.catInlineEditForm?.form.value.serialNo !== ''
        this.selectedAssetSubscriptionFormData.customer = this.originalCustomerInfo;
        if (this.catInlineEditForm.form.valid) {
          const result = this.originalEditInfo.every((item) => !item.ecmSerialNumber);
          if (result) {
            this.selectedAssetSubscriptionFormData.isValidForm = false;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          }
        } else {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.selectedAssetSubscriptionFormData.customer = this.originalCustomerInfo;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        }
      });
    });
  }

  onBlurSerialNo(event: any, rowData: any) {
    this.pattern = /^[0-9]{4}[A-Za-z][0-9]{3}[A-Za-z0-9]{2}$/;
    this.editedRowData = rowData;
    if (this.catInlineEditForm?.valid && this.catInlineEditForm?.value.serialNo) {
      let matchingComponent = this.originalEditInfo.find(
        (item: any) => item.id === this.editedRowData.id && item.id && this.editedRowData !== ''
      );
      if (matchingComponent) {
        matchingComponent.ecmSerialNumber = this.editedRowData.ecmSerialNumber;
        matchingComponent = [...this.originalEditInfo];
        const result = this.originalEditInfo.every((item) => !item.ecmSerialNumber || item.ecmSerialNumber === '-');
        if (result) {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        } else {
          const result = this.originalEditInfo.filter(
            (item: any) =>
              item.ecmSerialNumber && item.ecmSerialNumber !== '-' && !this.pattern.test(item.ecmSerialNumber)
          );
          const duplicateGnss = this.originalEditInfo.some(
            (item) => item.isDuplicate && item.componentType === 'GNSS Receiver'
          );
          if (result.length > 0 || duplicateGnss) {
            this.selectedAssetSubscriptionFormData.isValidForm = false;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          }
        }

        this.assetDrawerService.catGradeEditTableInfo.next(matchingComponent);
        this.assetDrawerService.catGradeEditInfo.next({ editInfo: matchingComponent });
      } else {
        if (this.editedRowData.ecmSerialNumber !== '') {
          // const ecmSerialNoExists = this.originalEditInfo.some(
          //   (item) => item.ecmSerialNumber === this.editedRowData.ecmSerialNumber && item.com
          // );
          // if (!ecmSerialNoExists) {
          //   this.originalEditInfo.push(this.editedRowData);
          // }
          let result = this.originalEditInfo.find((item) => item.index === this.editedRowData.index);
          if (result) {
            result.ecmSerialNumber = this.editedRowData.ecmSerialNumber;
            result = [...this.originalEditInfo];
          }
          const filteredResult = this.originalEditInfo.filter(
            (item: any) =>
              item.ecmSerialNumber && item.ecmSerialNumber !== '-' && !this.pattern.test(item.ecmSerialNumber)
          );
          const duplicateGnss = this.originalEditInfo.some(
            (item) => item.isDuplicate && item.componentType === 'GNSS Receiver'
          );
          if (filteredResult.length > 0 || duplicateGnss) {
            this.selectedAssetSubscriptionFormData.isValidForm = false;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          }

          this.assetDrawerService.catGradeEditTableInfo.next(result);
          this.assetDrawerService.catGradeEditInfo.next({ editInfo: result });
          this.assetDrawerService.isBackBtnClicked.next(false);
        }
      }
    } else {
      let matchingComponent = this.originalEditInfo.find((item: any) => item.index === this.editedRowData.index);
      if (matchingComponent) {
        matchingComponent.ecmSerialNumber = this.editedRowData.ecmSerialNumber;
        matchingComponent.status = false;
        matchingComponent = [...this.originalEditInfo];
      }

      const filteredResult = this.originalEditInfo.filter(
        (item: any) => item.ecmSerialNumber && item.ecmSerialNumber !== '-' && !this.pattern.test(item.ecmSerialNumber)
      );

      const duplicateGnss = this.originalEditInfo.some(
        (item) => item.isDuplicate && item.componentType === 'GNSS Receiver'
      );

      const result = this.originalEditInfo.every(
        (item) => !item.ecmSerialNumber || item.ecmSerialNumber === null || item.ecmSerialNumber === '-'
      );
      this.assetDrawerService.catGradeEditTableInfo.next(matchingComponent);
      this.assetDrawerService.catGradeEditInfo.next({ editInfo: matchingComponent });
      if (filteredResult.length > 0 || (filteredResult.length > 0 && duplicateGnss) || result || duplicateGnss) {
        this.selectedAssetSubscriptionFormData.isValidForm = false;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      } else {
        this.selectedAssetSubscriptionFormData.isValidForm = true;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }
    }
  }

  onFocusSerialNo(event: any, rowData: any) {
    this.pattern = /^[0-9]{4}[A-Za-z][0-9]{3}[A-Za-z0-9]{2}$/;
    this.editedRowData = rowData;
    if (this.catInlineEditForm?.valid && this.catInlineEditForm?.value.serialNo) {
      let matchingComponent = this.originalEditInfo.find((item: any) => item.index === this.editedRowData.index);
      if (matchingComponent) {
        matchingComponent.ecmSerialNumber = this.editedRowData.ecmSerialNumber;
        matchingComponent = [...this.originalEditInfo];
      }
      const result = this.originalEditInfo.every((item) => !item.ecmSerialNumber || item.ecmSerialNumber === '-');
      if (result) {
        this.selectedAssetSubscriptionFormData.isValidForm = false;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      } else {
        const result = this.originalEditInfo.filter(
          (item: any) =>
            item.ecmSerialNumber && item.ecmSerialNumber !== '-' && !this.pattern.test(item.ecmSerialNumber)
        );

        const serialNumbers = this.originalEditInfo
          .filter((item) => item.componentType === 'GNSS Receiver')
          .map((item) => item.ecmSerialNumber);

        const currentSerialNumber = rowData.ecmSerialNumber;
        if (currentSerialNumber !== '') {
          rowData.isDuplicate =
            rowData.componentType === 'GNSS Receiver' &&
            serialNumbers?.filter((sn) => sn === currentSerialNumber).length > 1;
        } else {
          rowData.isDuplicate = false;
        }

        const duplicateGnss = this.originalEditInfo.some(
          (item) => item.isDuplicate && item.componentType === 'GNSS Receiver'
        );

        if (
          result.length > 0 ||
          (result.length > 0 && !rowData.isDuplicate) ||
          rowData.isDuplicate ||
          (duplicateGnss && rowData.componentType === 'Grade Control ECM')
        ) {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        } else {
          this.selectedAssetSubscriptionFormData.isValidForm = true;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        }

        if (!rowData.isDuplicate && duplicateGnss && rowData.componentType !== 'Grade Control ECM') {
          this.assetDrawerService.updateCatGradeRowData(false);
        } else {
          let matchingComponent = this.originalEditInfo.find((item: any) => item.index === this.editedRowData.index);
          if (matchingComponent) {
            matchingComponent.isDuplicate = rowData.isDuplicate;
            matchingComponent = [...this.originalEditInfo];
          }
        }
      }
    } else {
      const currentSerialNumber = rowData.ecmSerialNumber;

      let matchingComponent = this.originalEditInfo.find((item: any) => item.index === this.editedRowData.index);
      if (matchingComponent) {
        matchingComponent.ecmSerialNumber = this.editedRowData.ecmSerialNumber;
        matchingComponent.status = false;
        matchingComponent = [...this.originalEditInfo];
      }

      const filteredResult = this.originalEditInfo.filter(
        (item: any) => item.ecmSerialNumber && item.ecmSerialNumber !== '-' && !this.pattern.test(item.ecmSerialNumber)
      );

      const serialNumbers = this.originalEditInfo
        .filter((item) => item.componentType === 'GNSS Receiver')
        .map((item) => item.ecmSerialNumber);
      if (currentSerialNumber !== '') {
        rowData.isDuplicate =
          rowData.componentType === 'GNSS Receiver' &&
          serialNumbers?.filter((sn) => sn === currentSerialNumber).length > 1;
      } else {
        rowData.isDuplicate = false;
      }

      const result = this.originalEditInfo.every(
        (item) => !item.ecmSerialNumber || item.ecmSerialNumber === null || item.ecmSerialNumber === '-'
      );

      const duplicateGnss = this.originalEditInfo.some(
        (item) => item.isDuplicate && item.componentType === 'GNSS Receiver'
      );

      if (
        filteredResult.length > 0 ||
        (filteredResult.length > 0 && !rowData.isDuplicate) ||
        result ||
        (duplicateGnss && rowData.componentType === 'Grade Control ECM')
      ) {
        this.selectedAssetSubscriptionFormData.isValidForm = false;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      } else {
        this.selectedAssetSubscriptionFormData.isValidForm = true;
        this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
      }

      if (!rowData.isDuplicate && duplicateGnss && rowData.componentType !== 'Grade Control ECM') {
        this.originalEditInfo.forEach((item: any) => (item.isDuplicate = false));
        this.assetDrawerService.updateCatGradeRowData(false);
      }
    }
  }

  ngOnDestroy() {
    if (this.catGradeEditInfoSubscription) {
      this.catGradeEditInfoSubscription.unsubscribe();
    }
  }
}
