import { Component, OnInit } from '@angular/core';
import { SiteDetailsComponent } from '../site-details/site-details/site-details.component';
import { siteData } from '../sites-interface';
import {
  CcDrawerConfig,
  CcDrawerPosition,
  CcDrawerRef,
  CcDrawerSize,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';

@Component({
  selector: 'dsp-next-gen-ui-site-asset-count',
  templateUrl: './site-asset-count.component.html',
  styleUrls: ['./site-asset-count.component.scss'],
})
export class SiteAssetCountComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  siteDetailsRef: any;
  constructor(private drawerService: DrawerService) {}

  ngOnInit(): void {
    this.rowData = this.value;
  }

  viewAssetDetails(data: siteData) {
    this.siteDetailsRef = this.drawerService.openDrawer<SiteDetailsComponent, CcDrawerConfig>(SiteDetailsComponent, {
      data: data,
      disableBackdropClick: false,
      position: CcDrawerPosition.RIGHT,
      drawerSize: CcDrawerSize.LARGE,
      id: 'listDrawer',
    });

    this.siteDetailsRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.columnWithHeader.customData.fetchSites();
      }
    });
  }
}
