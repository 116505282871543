<div class="cc-drawer" id="configure-wifi" [formGroup]="configureWiFiFormGroup">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis filters-title"> Configure Wi-Fi </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content configure-wifi-container">
    <div class="config-header-info" [ngClass]="currentStatus?.colorCode">
      <div class="config-header">
        <cc-icon class="wifi-header-icons wifi" [ngClass]="currentStatus?.colorCode">wifi</cc-icon>
        <div class="wifi-header-sub">
          <div class="status-text">{{ statusMessage }}</div>
          <div *ngIf="currentStatus?.subStatusMessage" class="subStatusMessage">
            {{ currentStatus.subStatusMessage }}
          </div>
        </div>

        <div
          *ngIf="
            currentStatus?.statusCode === '-1' ||
            currentStatus?.statusCode === 'Pending' ||
            currentStatus?.statusCode === '-3'
          "
          class="refresh-text"
          (click)="refreshConnection()"
        >
          <span>Refresh</span>
        </div>
      </div>
    </div>
    <div>
      <div class="pb-20">
        <div class="device-input-label pb-10">SSID</div>
        <cc-input-field
          id="cc-atom-textField"
          [value]="configureWiFiFormGroup.value.ssid"
          [inputtype]="'text'"
          [isTableInlineEdit]="true"
          [isTheme]="isTheme"
          [disabled]="disableFormField"
        >
          <input
            input-field
            formControlName="ssid"
            [value]="configureWiFiFormGroup.value.ssid"
            class="input-field"
            autocomplete="off"
            type="text"
            maxlength="32"
            placeholder="Enter SSID"
            aria-labelledby="Enter Text (Optional)"
            aria-describedby="Enter Text (Optional)"
          />
        </cc-input-field>
      </div>
      <div class="pb-20">
        <div class="device-input-label pb-10">Password</div>
        <cc-input-field
          id="cc-atom-textField"
          [value]="configureWiFiFormGroup.value.password"
          [inputtype]="'text'"
          [isTheme]="isTheme"
          [isTableInlineEdit]="true"
          [disabled]="
            (configureWiFiFormGroup.value.type && configureWiFiFormGroup.value.type === 'ACCESS_POINT') ||
            disableFormField
          "
        >
          <input
            input-field
            formControlName="password"
            [value]="configureWiFiFormGroup.value.password"
            class="input-field"
            autocomplete="off"
            type="password"
            maxlength="63"
            placeholder="Enter password"
            aria-labelledby="Enter Text (Optional)"
            aria-describedby="Enter Text (Optional)"
          />
        </cc-input-field>
        <span
          *ngIf="
            (configureWiFiFormGroup.controls['password']?.touched &&
              configureWiFiFormGroup.controls['password']?.errors?.['required']) ||
              (configureWiFiFormGroup.controls['password']?.errors?.['minlength'] &&
                configureWiFiFormGroup.value.password !== '');
            else showNormalText
          "
          style="color: red"
          >Password should be a minimum of 8 characters</span
        >
        <ng-template #showNormalText>
          <span>Password should be a minimum of 8 characters</span>
        </ng-template>
      </div>
      <div class="pb-20">
        <div class="device-input-label pb-10">Location</div>

        <cc-dropdown
          [isTheme]="'2.0'"
          [label]="'Select Location'"
          [dataSource]="locationData"
          [appOverlayClass]="'app-overlay-class'"
          formControlName="country_code"
          [search]="true"
          [triggerSearchMinChar]="3"
          [searchFilterType]="'wildcard'"
          [searchplaceholder]="'Search Location'"
          [searchHelpText]="'Couldn´t find any Location with'"
          [scrollToSelected]="true"
          [disabled]="disableFormField"
          [scrollToSelected]="true"
        ></cc-dropdown>
      </div>
      <div class="pb-20">
        <div class="pb-10">Interface Configuration Type & Network Entry Security</div>
        <cc-radio-group (valueChange)="ToAccessPoint($event)" formControlName="type">
          <cc-radio-button value="CLIENT_WITH_DHCP" [disabled]="disableFormField"
            >Client with DHCP (WPA2 PSK)
          </cc-radio-button>
          <cc-radio-button value="ACCESS_POINT" [disabled]="disableFormField" *ngIf="!disableAccessPoint">
            Access Point (EAP TLS)
          </cc-radio-button>
        </cc-radio-group>
      </div>

      <div class="display-content">
        <img src="..\assets\images\info-icon.svg" class="info-icon-style" />

        <div>
          Devices must be certified by country before Wi-Fi data transmission is permitted. It is your responsibility to
          verify certification status on

          <p class="note-display">
            <a href="https://catdealer.com/plcertification" target="_blank">https://catdealer.com/plcertification</a>
            before programming the Wi-Fi country code.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer connect-btn" style="text-align: right">
    <button
      *ngIf="!enableDisconnectBtn"
      [disabled]="!configureWiFiFormGroup.valid"
      cc-btn-primary
      cc-btn-large
      class="primary button-opacity viewAssetsBtn"
      [setTheme]="true"
      (click)="connectWiFi()"
    >
      Connect
    </button>
    <button
      *ngIf="enableDisconnectBtn"
      cc-btn-primary
      cc-btn-large
      class="primary button-opacity viewAssetsBtn"
      [setTheme]="true"
      (click)="openModal(disconnectWifiModal)"
    >
      Disconnect
    </button>
  </div>
</div>

<ng-template #disconnectWifiRef>
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>

    <div class="cc-modal-content">
      <p>
        This action will result in clearing your Wi-Fi settings. You will need to reset your Wi-Fi. Are you sure you
        want to continue?
      </p>
    </div>
    <div class="cc-modal-footer">
      <button cc-btn-secondary-outline (click)="clear()">Cancel</button>
      <button cc-btn-primary (click)="disconnectWiFi()">Confirm</button>
    </div>
  </div>
</ng-template>
