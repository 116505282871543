import { Component, OnInit, ViewChild, Inject, ViewEncapsulation, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { DspCommonService } from '../../../services/dsp-common.service';
import { CcModalRef, CC_MODAL_DATA, CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { ILatLng, MapType } from '@cat-digital-workspace/shared-maps';
import { debounce, isNumber, isEmpty, includes } from 'lodash-es';
import { Subscription } from 'rxjs';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { UserPreferenceService } from '../../../user-preference.service';

interface autoLoadData {
  label: string;
  value: any;
}

@Component({
  selector: 'dsp-next-gen-ui-create-new-site',
  templateUrl: './create-new-site.component.html',
  styleUrls: ['./create-new-site.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateNewSiteComponent implements OnInit {
  @ViewChild('siteCreationTracker') siteCreationTracker: any;
  @ViewChild('cwsIdSearch') cwsIdSearch!: ElementRef;
  @ViewChild('mapSearchText') mapSearchText!: ElementRef;
  createNewSiteForm!: FormGroup;

  isTheme = '2.0';
  customer = '';
  customerUCID: any;
  cwsid = '';
  siteDescription = '';
  billToParty = '';
  latitude: any = '';
  longitude: any = '';
  searchCustItems: autoLoadData[] = [];
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  products: autoLoadData[] = [];
  planData: autoLoadData[] = [];
  addOnServices: autoLoadData[] = [];
  cwsIDExists = true;
  customerLoader = false;
  cwsIdLoader = false;
  siteNameExists = false;
  isPaymentPage = false;
  location = '';
  isPricingEligible = false;
  isProductSelected = false;
  isNonB2C = true;
  payBillingDetails: any;
  payMentPreview: any;
  trackerData = [
    {
      label: 'Account and Plan',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
    {
      label: 'Payment Preview',
      disabled: false,
      clickable: false,
      warning: false,
      danger: false,
      description: '',
    },
  ];
  availableType = [
    { label: 'street', value: 'roadmap' },
    { label: 'hybrid', value: 'hybrid' },
    { label: 'satellite', value: 'satellite' },
    { label: 'terrain', value: 'terrain' },
  ];
  dealerFeature: any;
  originAsset!: ILatLng;
  mapType = MapType.TERRAIN;
  inputValue1 = '';
  @ViewChild('locationbox') locationbox!: ElementRef;
  //customerWidth = '400px';
  prevSearchCustomer = '';
  zoomLevel = 9;
  activeStepIndex = 0;
  isListItemSelected = false;
  cwsidSearchText = '';
  customerNotFound = false;
  checkPaymentPreview = false;
  isRemoveCwsIDAutoSearch = false;
  paymentPreviewAPIData!: Subscription;
  isPaymentPreviewAPICompleted = false;
  userActiveFeatures: any;
  loader = false;
  dspStoreData!: any;
  isNumberConventionEnabled: any;

  constructor(
    private fb: FormBuilder,
    private messageBar: MessageBar,
    private apiService: DspCommonService,
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<CreateNewSiteComponent>,
    private modal: CcModal,
    private changeDetectorRef: ChangeDetectorRef,
    private userPreferenceService: UserPreferenceService,
    private store: Store<DSPAppState>
  ) {
    //this.customerWidth = window.devicePixelRatio === 1.5 ? '325px' : '400px';
  }

  ngOnInit(): void {
    this.activeStepIndex = 0;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;

      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isRemoveCwsIDAutoSearch = this.dspStoreData?.featureFlagInfo?.isRemoveCwsIDAutoSearch
        ? this.dspStoreData?.featureFlagInfo?.isRemoveCwsIDAutoSearch
        : false;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
    });
    this.createNewSiteForm = this.fb.group({
      siteName: [
        '',
        {
          validators: [Validators.required, Validators.minLength(3)],
          updateOn: 'change', //blur
        },
      ], //['', [Validators.required, Validators.minLength(3)],],
      customer: ['', [Validators.required, Validators.minLength(3)]],
      cwsid: ['', [Validators.minLength(3)]], //Validators.required,
      siteDescription: [''],
      product: [null],
      plan: [null],
      additionalService: [null],
      location: ['', [Validators.required, Validators.minLength(3)]],
    });
    this.isPricingEligible = this.userActiveFeatures?.indexOf('Contract Visualization') > -1;
    if (!this.isPricingEligible) {
      const val = this.trackerData;
      this.trackerData = val.filter((ele) => ele.label == 'Account and Plan');
    }
    this.constructProducts(this.data.mineStarDetails);
  }

  get formControl() {
    return this.createNewSiteForm.controls;
  }

  constructService({ currentSelection }: any) {
    this.addOnServices = [];
    if (currentSelection[0]) {
      this.isPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 &&
        currentSelection[0].value.enableContractVisualization;
      const surfaceAddon =
        this.userActiveFeatures && this.userActiveFeatures?.indexOf('Surface & Material Management') > -1;
      this.addOnServices = currentSelection[0].value.addOnServices.map((obj: any) => {
        return {
          label:
            this.isPricingEligible && this.isNonB2C && obj.makePrice.Default
              ? obj.addOnService + ' - ' + (this.convertNumberFormat(obj.makePrice.Default) || '')
              : obj.addOnService,
          value: obj.addOnService,
        };
      });
      if (!surfaceAddon) {
        this.addOnServices = this.addOnServices.filter((ele: any) => {
          ele.label == 'Surface & Material Management' && !surfaceAddon;
        });
      }
    }
  }

  constructProducts(productObj: any) {
    for (const k in productObj) {
      if (k !== 'type') {
        this.products.push({ label: k, value: productObj[k] });
      }
    }
  }

  constructProductPlan({ currentSelection }: any) {
    this.isProductSelected = true;
    const product = this.createNewSiteForm.value.product ? this.createNewSiteForm.value.product[0].label : 'None';
    const ucid = this.createNewSiteForm.value.customer != '' && this.customerUCID ? this.customerUCID.ucid : '';
    if (
      this.data.b2cCustomers &&
      this.data.b2cCustomers.b2cSupportedApps &&
      this.data.b2cCustomers.billDirectCustomers &&
      this.data.b2cCustomers.b2cSupportedApps.indexOf(product) != -1 &&
      this.data.b2cCustomers.billDirectCustomers.indexOf(ucid) != -1
    ) {
      this.isNonB2C = false;
    }
    this.planData = [];
    this.planData = currentSelection[0].value.map((obj: any) => {
      this.isPricingEligible =
        this.userActiveFeatures?.indexOf('Contract Visualization') > -1 && obj.enableContractVisualization;
      return {
        label:
          this.isPricingEligible && this.isNonB2C && obj.makePrice.Default
            ? obj.baseService + ' - ' + (this.convertNumberFormat(obj.makePrice.Default) || '')
            : obj.baseService,
        value: obj,
      };
    });
  }

  onDropdownChange({ currentSelection }: any, prop: string) {
    this.createNewSiteForm.patchValue({
      [prop]: currentSelection,
      ['additionalService']: '',
    });
  }

  getPartOfControl(prop: string) {
    return this.createNewSiteForm.get(prop)?.value[0].value;
  }

  searchByCustomer(event: any) {
    if (this.prevSearchCustomer != this.customer) {
      this.prevSearchCustomer = this.customer;
      this.customerNotFound = false;
      if (event.value && event.value.length >= 3 && !this.isListItemSelected) {
        this.searchCustItems = [];
        this.createNewSiteForm.controls['customer'].setErrors(null);
        const searchParam = { dealerCode: this.dealerCode, searchText: event.value };
        this.fetchCustomerDetails(searchParam);
      } else if (event.value === '') {
        this.createNewSiteForm.patchValue({ customer: '' });
        this.createNewSiteForm.controls['customer'].setErrors({ required: true });
        this.revertNameExistsError('siteName');
        this.customerUCID = '';
        this.customerLoader = false;
        this.customerNotFound = false;
        this.isListItemSelected = false;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  fetchCustomerDetails = debounce((searchParam: any) => {
    if (this.customer === searchParam.searchText && this.customer?.length > 2) {
      this.customerLoader = true;
      this.changeDetectorRef.detectChanges();
      this.getSeachTextCustomerDetails(searchParam);
    }
  }, 1000);

  getSeachTextCustomerDetails(searchParam: any) {
    this.apiService.getCustomers(searchParam).subscribe({
      next: (res: any) => {
        const dealerCustomer = res.dealerCustomer ? res.dealerCustomer : false;
        if (dealerCustomer && dealerCustomer.length > 0) {
          this.searchCustItems = dealerCustomer.map(function (item: any) {
            return {
              label: item.ucName + ' - ' + item.ucid + ', ' + item.name + ' - ' + item.dealerCustomerNumber,
              value: item,
            };
          });
        }
        if (dealerCustomer.length == 0) {
          this.updateNoResults();
        }
        this.customerLoader = false;
        this.changeDetectorRef.markForCheck();
      },
      error: (_err) => {
        this.customerLoader = false;
      },
    });
  }

  searchLocation(event: any) {
    if (event.value === '') {
      this.latitude = '';
      this.longitude = '';
      this.originAsset = '' as any;
      this.createNewSiteForm.patchValue({ location: '' });
      this.createNewSiteForm.controls['location'].setErrors({ required: true });
    }
  }

  selectService($event: any) {}

  handleUserSearch(item: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (this.cwsidSearchText !== item && item.length > 2 && item.length < 11) {
        this.validateCWSID(item);
      }
    }
  }
  onBlurEvent(event: any, item: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (event?.type === 'blur') {
        this.handleUserSearch(item);
      }
    }
  }

  onCwsidChange(event: any) {
    if (this.isRemoveCwsIDAutoSearch) {
      if (event.value === '' && this.cwsIDExists === false) {
        this.cwsIDExists = true;
        this.cwsidSearchText = '';
      }
    } else {
      if (event.value === '' && this.cwsIDExists === false) {
        this.cwsIDExists = true;
        this.cwsidSearchText = '';
      } else if (this.cwsidSearchText !== event.value && event.value.length > 2 && event.value.length < 11) {
        this.validateCWSID(event.value);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  validateCWSID = debounce((cwsId: string) => {
    this.cwsIdLoader = true;
    this.checkForValidCwsId(cwsId);
  }, 200);

  checkForValidCwsId(cwsId: string) {
    const bodyReq = { cwsid: cwsId };
    this.cwsidSearchText = cwsId;
    this.apiService.checkCWSID(bodyReq).subscribe({
      next: (_res: any) => {
        this.cwsIDExists = true;
        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        if (err.status === 202 || err.status === 200) {
          this.cwsIDExists = true;
        } else {
          this.cwsIDExists = false;
        }
        this.cwsIdLoader = false;
        this.changeDetectorRef.detectChanges();
      },
    });
  }

  getValidButtonStatus(buttonType: any) {
    let returnStatus = false;
    if (buttonType == 'back') {
      returnStatus = this.isPaymentPage;
    } else if (buttonType == 'save') {
      returnStatus = !(
        this.validateMandatoryFields() &&
        (this.createNewSiteForm.value.product == null || this.createNewSiteForm.value.plan == null)
      );
    } else if (buttonType == 'submit') {
      if (this.isPaymentPage) {
        returnStatus = !this.checkPaymentPreview;
      } else {
        returnStatus = !(
          this.validateMandatoryFields() &&
          this.createNewSiteForm.value.product &&
          this.createNewSiteForm.value.plan
        );
      }
    }
    return returnStatus;
  }

  validateMandatoryFields() {
    return (
      this.createNewSiteForm.controls['siteName'].status === 'VALID' &&
      this.createNewSiteForm.controls['customer'].status === 'VALID' &&
      !isEmpty(this.customerUCID) &&
      this.createNewSiteForm.controls['location'].status === 'VALID' &&
      isNumber(this.originAsset?.lat) &&
      isNumber(this.originAsset?.lng)
    );
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  listItemClick(index: number) {
    this.createNewSiteForm.controls['customer'].setErrors(null);
    this.isListItemSelected = true;
    this.customer = this.searchCustItems[index]['label'];
    this.createNewSiteForm.patchValue({ customer: this.customer || '' });
    this.createNewSiteForm.value.customer = this.customer;
    this.createNewSiteForm.controls['customer'].setValue(this.customer);
    this.customerUCID = this.searchCustItems[index]['value'];
    this.checkSiteUniqueness();
  }

  checkSiteUniqueness() {
    const siteName = this.formControl['siteName'].value;
    const ucid = this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucid : '';
    const siteObj = { siteName: siteName, ucid: ucid };
    if (siteName && siteName.length >= 3 && ucid != '') {
      this.apiService.validateSite(siteObj).subscribe({
        next: (res: any) => {
          if (res.isExist) {
            const messageText = `The site name and customer combination already exists. Please choose a new Site name or Customer.`;
            this.showToastMessage(messageText, 'error');
            this.createNewSiteForm.controls['siteName'].setErrors({ siteNameExists: true });
            this.createNewSiteForm.controls['customer'].setErrors({ siteNameExists: true });
          } else {
            this.revertNameExistsError('siteName');
            this.revertNameExistsError('customer');
          }
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          const messageText = `There was an error while processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
          this.changeDetectorRef.detectChanges();
        },
      });
    }
  }

  revertNameExistsError(type: string) {
    if (includes(Object.keys(this.createNewSiteForm.controls[type]['errors'] || []), 'siteNameExists')) {
      this.createNewSiteForm.controls[type].setErrors(null);
    }
  }

  createUpdateMineStarSite(action: any) {
    const product = this.createNewSiteForm.value.product ? this.createNewSiteForm.value.product[0].label : 'None';
    const ucid = this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucid : '';
    if (this.billToParty == '') {
      this.billToParty =
        this.data.b2cCustomers &&
        this.data.b2cCustomers.b2cSupportedApps &&
        this.data.b2cCustomers.billDirectCustomers &&
        this.data.b2cCustomers.b2cSupportedApps.indexOf(product) != -1 &&
        this.data.b2cCustomers.billDirectCustomers.indexOf(ucid) != -1
          ? 'customer'
          : 'dealer';
    }
    let selectedSubscription = this.createNewSiteForm.value.plan
      ? this.createNewSiteForm.value.plan[0].value.baseService + '|'
      : 'None';
    if (this.createNewSiteForm.value.additionalService) {
      this.createNewSiteForm.value.additionalService.forEach((addon: any) => {
        selectedSubscription += addon.value + '|';
      });
    }

    if (this.createNewSiteForm.valid) {
      const customerLevels =
        selectedSubscription != 'None'
          ? selectedSubscription.substring(0, selectedSubscription.length - 1)
          : selectedSubscription;
      const sitePayLoad = {
        name: this.createNewSiteForm.value.siteName,
        description: this.createNewSiteForm.value.siteDescription,
        latitude: this.latitude,
        longitude: this.longitude,
        admin: this.cwsid,
        siteId: '',
        ucid: this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucid : '',
        dcName: this.createNewSiteForm.value.customer != '' ? this.customerUCID.name : '',
        ucName: this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucName : '',
        dcn: this.createNewSiteForm.value.customer != '' ? this.customerUCID.dealerCustomerNumber : '',
        billToParty: this.billToParty,
        status: 'Active',
        dealerCode: this.dealerCode,
        requestingDealerCode: this.dealerCode,
        customerLevel: customerLevels.split('|'),
        serviceSubscription: customerLevels,
        cancelReason: '',
        organization: 'Customer',
        organizationType: 'Customer',
        level: 'Customer',
        associatedOrganization: 'Customer',
        associatedOrganizationType: 'Customer',
        origin: 'Owned',
        orgType: 'Customer',
        cwsid: this.cwsid,
      };
      if (action == 'saveSite') {
        this.apiService.saveSiteDetail(sitePayLoad).subscribe({
          next: (_res: any) => {
            this.closeModel(true);
            const messageText = `Site saved successfully.`;
            this.showToastMessage(messageText, 'success');
          },
          error: (err) => {
            this.closeModel(false);
            const messageText = `There was an error while processing your request. Please contact product support.`;
            this.showToastMessage(messageText, 'error');
          },
        });
      } else {
        this.apiService.createSiteSubscription(sitePayLoad).subscribe({
          next: (_res: any) => {
            this.closeModel(true);
            const messageText = `Site plan added successfully.`;
            this.showToastMessage(messageText, 'success');
          },
          error: (err) => {
            this.closeModel(false);
            const messageText = `There was an error while processing your request. Please contact product support.`;
            this.showToastMessage(messageText, 'error');
          },
        });
      }
    } else {
      this.validateAllFields(this.createNewSiteForm);
    }
  }

  closeModel(res: any) {
    this.modalRef.close(res);
  }

  output(event: any) {
    if (event === 0) {
      this.backward();
    }
    if (event === 1) {
      this.validateFormDetails() && (this.activeStepIndex = 2);
    } else {
      this.activeStepIndex = event;
    }
    const scrollToElement = document.getElementById('tracker') as HTMLElement;
    scrollToElement.scrollTo(111 * this.activeStepIndex, 0);
  }

  validateFormDetails() {
    return this.validateMandatoryFields() && this.createNewSiteForm.value.product && this.createNewSiteForm.value.plan;
  }

  showSitePaymentPreview() {
    this.loader = true;
    let selectedSubscription = this.createNewSiteForm.value.plan
      ? this.createNewSiteForm.value.plan[0].value.baseService + '|'
      : 'None';
    if (this.createNewSiteForm.value.additionalService) {
      this.createNewSiteForm.value.additionalService.forEach((addon: any) => {
        selectedSubscription += addon.value + '|';
      });
    }
    const customerLevels =
      selectedSubscription != 'None'
        ? selectedSubscription.substring(0, selectedSubscription.length - 1)
        : selectedSubscription;
    const PreviewPayLoad = {
      assetMake: '',
      assetSerialNumber: '',
      deviceId: '',
      deviceSerialNumber: '',
      deviceType: '',
      dealerCode: this.dealerCode,
      ucid: this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucid : '',
      customerName: this.createNewSiteForm.value.customer != '' ? this.customerUCID.ucName : '',
      dcnName: this.createNewSiteForm.value.customer != '' ? this.customerUCID.name : '',
      dcnNumber: this.createNewSiteForm.value.customer != '' ? this.customerUCID.dealerCustomerNumber : '',
      contractEndDate: '',
      contractStartDate: '',
      siteName: this.createNewSiteForm.value.siteName,
      applicationName: this.createNewSiteForm.value.product[0].label,
      subscriptions: customerLevels.split('|'),
      isNewSubscription: true,
    };
    this.payMentPreview = {
      dealerCode: this.dealerCode || '',
      ucid: this.customerUCID.ucid,
      customerName: this.customerUCID.ucName,
      dcnName: this.customerUCID.name,
      dealerName: JSON.parse(this.dealerStringObj)?.dealerName || '',
      dcnNumber: this.customerUCID.dealerCustomerNumber,
      siteName: this.createNewSiteForm.value.siteName,
      applicationName: this.createNewSiteForm.value.product[0].label,
      isNewSubscription: true,
      subscriptionType: 'Site',
      siteLocation: this.createNewSiteForm.value.location,
    };
    this.payBillingDetails = null;
    this.isPaymentPreviewAPICompleted = false;
    this.paymentPreviewAPIData = this.apiService.getPaymentPreview(PreviewPayLoad).subscribe({
      next: (res: any) => {
        this.isPaymentPreviewAPICompleted = true;
        if (!res.new || (!res.errorMessage && res.errorMessage?.length > 0)) {
          this.checkPaymentPreview = false;
          const messageText = `There was an error while processing your subscription billing request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
          this.loader = false;
        } else {
          this.payBillingDetails = res;
          this.checkPaymentPreview = true;
          this.loader = false;
        }
        this.changeDetectorRef.detectChanges();
      },
      error: (_err: any) => {
        this.isPaymentPreviewAPICompleted = true;
        this.checkPaymentPreview = false;
        const messageText = `There was an error while processing your subscription billing request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
        this.changeDetectorRef.detectChanges();
        this.loader = false;
      },
    });
  }

  forward(fromPayment: any) {
    if (this.createNewSiteForm.valid) {
      if (fromPayment || !this.isPricingEligible) {
        this.createUpdateMineStarSite('create');
      } else {
        if (this.isPricingEligible) {
          this.showSitePaymentPreview();
          this.siteCreationTracker.onNextClick();
          this.resetTrackerScroll();
          this.isPaymentPage = true;
        } else {
          this.createUpdateMineStarSite('create');
        }
      }
      this.activeStepIndex = !this.isPricingEligible ? 0 : 2;
    } else {
      this.validateAllFields(this.createNewSiteForm);
    }
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      if (field != 'cwsid') {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.validateAllFields(control);
        }
      }
    });
  }

  backward() {
    if (this.paymentPreviewAPIData && !this.isPaymentPreviewAPICompleted) {
      this.paymentPreviewAPIData.unsubscribe();
    }
    this.isPaymentPage = false;
    this.checkPaymentPreview = false;
    this.siteCreationTracker.onPreviousClick();
    this.activeStepIndex = 0;
    this.resetTrackerScroll();
  }
  resetTrackerScroll() {
    const scrollToElement = document.getElementById('tracker') as HTMLElement;
    scrollToElement.scrollTo(111 * this.activeStepIndex, 0);
  }

  onValue(_event: any) {
    setTimeout(() => {
      if (this.customerNotFound) {
        this.resetOverlayBox();
      }
      const dealerLength = this.customer.length;
      this.locationbox.nativeElement.setSelectionRange(dealerLength, dealerLength);
    }, 100);
  }

  mapLocationChange(event: any) {
    this.zoomLevel = 12;
    this.location = event.address;
    this.createNewSiteForm.patchValue({ location: event.address });
    this.latitude = event.location.lat;
    this.longitude = event.location.lng;
    this.originAsset = { lat: this.latitude, lng: this.longitude };
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.cwsid?.length || 0;
      this.cwsIdSearch?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  onFocusMapEvent() {
    setTimeout(() => {
      const searchLength = this.location?.length || 0;
      this.mapSearchText?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  updateDetails(dataType: string) {
    if (dataType === 'Customer') {
      return `${this.payMentPreview?.customerName || ''} - ${this.payMentPreview?.ucid || ''}`;
    } else if (dataType === 'Dealer') {
      return `${this.payMentPreview?.dcnName || ''} - ${this.payMentPreview?.dcnNumber || ''}`;
    } else {
      return '';
    }
  }

  updateNoResults() {
    this.createNewSiteForm.controls['customer'].setErrors({ incorrect: true });
    this.customerNotFound = true;
    this.resetOverlayBox();
  }

  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.display = 'none';
    }
  }

  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }
}
