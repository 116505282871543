import { Component, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'dsp-next-gen-ui-spot-loader',
  templateUrl: './spot-loader.component.html',
  styleUrls: ['./spot-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CcSpotLoadersComponent {
  setTheme = false;
  constructor() {}
}
