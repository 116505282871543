import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OneTrustService {
  public loaded$: BehaviorSubject<boolean>;
  public onConsentChanged$: Subject<string>;

  constructor(@Inject('Window') readonly window: any) {
    this.loaded$ = this.pollForOneTrustSDK();
    this.onConsentChanged$ = new Subject();
    this._OnConsentChanged();
    this.pollForOneTrustSDK();
  }

  public onLoad(): Observable<boolean> {
    return this.loaded$.asObservable().pipe(filter((loaded) => !!loaded));
  }

  public OnConsentChanged(): Observable<string> {
    return this.onConsentChanged$.asObservable();
  }

  public getOneTrustActiveGroups(): string {
    return this.loaded$.getValue() ? this.window['OnetrustActiveGroups'] : '';
  }

  public RejectAll(): string {
    return this.loaded$.getValue() ? this.window['OneTrust'].RejectAll() : '';
  }

  private _OnConsentChanged(): void {
    this.onLoad().subscribe(() => {
      this.window['OneTrust'].OnConsentChanged(() => {
        this.onConsentChanged$.next(this.getOneTrustActiveGroups());
      });
    });
  }

  private pollForOneTrustSDK(): BehaviorSubject<boolean> {
    const INTERVAL = 500;
    const TIMEOUT = 15000;
    const subject = new BehaviorSubject(false);
    timer(0, INTERVAL)
      .pipe(
        take(Math.floor(TIMEOUT / INTERVAL)),
        filter((_) => !!this.window['OneTrust']),
        first()
      )
      .subscribe((val) => {
        subject.next(true);
      });
    return subject;
  }
}
