import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  TemplateRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { TableSettingsCloseConfirmationComponent } from '../table-settings-close-confirmation/table-settings-close-confirmation.component';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { TableConfig } from '@cat-digital-workspace/shared-ui-core/cc-table';
import {
  CcDrawerConfig,
  CcDrawerPosition,
  CcDrawerSize,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { CreateNewSiteComponent } from './create-new-site/create-new-site.component';
import { SiteDetailsComponent } from './site-details/site-details/site-details.component';
import { SitesService } from '../../services/sites.service';
import { DspCommonService } from '../../services/dsp-common.service';
import {
  getSitesPayload,
  getSitesCountPayload,
  getSitesResponseData,
  siteData,
  getSitesCountResponse,
} from './sites-interface';
import { debounce, isEmpty, isNil } from 'lodash-es';
import { FiltersService } from '../../services/filters.service';
import { SiteNameComponent } from './site-name/site-name.component';
import { OwnershipNameComponent } from './ownership-name/ownership-name.component';
import { SiteStatusComponent } from './site-status/site-status.component';
import { SiteAssetCountComponent } from './site-asset-count/site-asset-count.component';
import { SiteProductPlanComponent } from './site-product-plan/site-product-plan.component';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { Observable, Subscription, forkJoin, fromEvent } from 'rxjs';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import {
  getMinestarServiceDetails,
  getSiteCancelReasonDetails,
  getSubscriptionServiceMapping,
} from '../../store/actions/dspcommon.action';
import * as dspConstants from '../../shared/dspConstants';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { TablesettingService } from '../../services/tablesetting.service';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
import { CommonFilterService } from '../../services/common-filter.service';
import { DspUtilsCommonService } from '../../services/dsp-utils-common.service';
import { CommonConfirmationPopupComponent } from '../common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from '../../models/popup.interface';
import { SharedService } from '../../shared.service';

type dealerType = {
  dealerCity: string;
  dealerCode: string;
  dealerCountry: string;
  dealerEDURL: string;
  dealerEmail: string;
  dealerName: string;
  dealerPhone: string;
  dealerState: string;
  dealerStreet: string;
  dealerZipCode: string;
};

@Component({
  selector: 'dsp-next-gen-ui-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitesComponent implements OnInit, OnDestroy {
  @ViewChild('siteSearchBox') siteSearchBox!: ElementRef;
  @ViewChild('settingPanel') settingPanelElement!: ElementRef;
  @ViewChild('settingIcon') settingIconElement!: ElementRef;
  @ViewChild('sitesTableRef') tableRef: any;
  public dealer: dealerType = {
    dealerCity: '',
    dealerCode: '',
    dealerCountry: '',
    dealerEDURL: '',
    dealerEmail: '',
    dealerName: '',
    dealerPhone: '',
    dealerState: '',
    dealerStreet: '',
    dealerZipCode: '',
  };
  public dealerCode = '';
  public filterResponse: object = {};
  filterContentsRef: any;
  settingDrawerRef: any;
  enableSearch = false;
  searchDataLoaded = false;
  errorText = 'Please enter atleast 3 characters';
  negative = false;
  isSettingVisible = false;
  drawerSize = { value: 'SMALL', label: 'SMALL' };
  isTheme = '2.0';
  searchHelpText = '';
  width = '280px';
  searchValue = '';
  mobileHeader = 'Search User';
  isGlobalSearchDisabled = false;
  isHighlightSearch!: any;
  overlayData = [
    {
      name: 'asc',
      title: 'Sort A to Z',
    },
    {
      name: 'dsc',
      title: 'Sort Z to A',
    },
  ];
  assetCountOverlayData = [
    {
      name: 'asc',
      title: 'Sort Smallest to Largest',
    },
    {
      name: 'dsc',
      title: 'Sort Largest to Smallest',
    },
  ];
  tableSettingsPanelConfig!: ITableSettings;
  settingsPanelConfig = {
    settingsPanelHeader: 'Table Settings',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  subcribeSitesTableConfig: any = {};
  sitesTableData: siteData[] = [];
  updatedTableConfig: any = [];

  siteServiceInfo: any;
  savedFilters: any;
  b2cCustomers: any;
  mineStarDetails: any;
  isSettingsOutSideClicked = false;
  isSaveTableSettings = '';
  expandable = false;
  label = '';
  viewLabel = '';
  headerLabel = '';
  range: any;
  pageSize = 20;
  activePage = 1;
  isTableLoading = false;
  fetchSitesAPIPayload: getSitesPayload = {
    sortBy: 'createTime',
    orderBy: 'DESC',
    limit: 20,
    offset: 0,
    dealerCode: 'TD00',
    searchText: '',
    subFilters: {
      dcn: [],
      dcnName: [],
      plan: [],
      product: [],
      siteName: [],
      status: [],
      ucid: [],
      ucidName: [],
      ucidNameWithId: [],
      dcnWithName: [],
    },
  };
  fetchSitesCountAPIPayload: getSitesCountPayload = {
    dealerCode: 'TD00',
    searchText: '',
    subFilters: {
      dcn: [],
      dcnName: [],
      plan: [],
      product: [],
      siteName: [],
      status: [],
      ucid: [],
      ucidName: [],
      ucidNameWithId: [],
      dcnWithName: [],
    },
  };
  siteTableCustomData: any = {
    b2cCustomers: {},
    mineStarDetails: {},
    searchString: '',
    searchType: dspConstants.Worklist.GLOBAL_SEARCH,
  };
  totalSitesCount = 0;
  fetchSitesAPICallData!: Subscription;
  fetchCountAPICallData!: Subscription;
  isFetchSitesAPICallCompleted!: boolean;
  isFetchCountAPICallCompleted!: boolean;
  dspStoreData: any;
  columnsName: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  minestarServiceDetails = {};
  profile = '';
  observable!: Subscription;
  pageLabel = 'sites';
  modalRef: any;
  isTableSettingsApply = false;
  isTableSettingClose = false;
  isRemovePaginationSearch = false;
  isCloseEvent = false;
  isChangeOwnership: any;
  showLoader = false;
  searchTextValue: any;
  isMaintainPagination = false;
  isCommonPopup = false;
  userDealerCode: any;
  constructor(
    public modal: CcModal,
    private drawerService: DrawerService,
    private siteService: SitesService,
    private dspCommonService: DspCommonService,
    private filterService: FiltersService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private store: Store<DSPAppState>,
    private messageBar: MessageBar,
    private tableSettingService: TablesettingService,
    private commonFilterService: CommonFilterService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService
  ) {
    this.subcribeSitesTableConfig = {
      height: window.innerHeight - 185,
      stickyHeader: true,
      expandable: false,
      isSelfFoldable: true,
      isBulkAction: false,
      isSortEnable: true,
      dragDisabled: true,
      isTheme: '2.0',
      footer: true,
      isPaginationEnable: true,
      isEnableHeaderPagination: true,
      showFooterPaginationOnlyOnScroll: false,
      pagination: {
        pageSizeOptions: [20, 40, 60, 80, 100],
        advanced: false,
        activePage: 1,
        defaultPageSize: 20,
        setTheme: true,
        enableDropdownWithPrefix: false,
        paginationLabelText: 'Viewing',
        totalNumberOfRecords: this.totalSitesCount,
      },
      isEnableSettingsPanel: false,
      columnConfig: [
        {
          name: 'name',
          title: 'Site Name',
          priority: 1,
          width: 200,
          isFrozen: true,
          isAlwaysMandatory: true,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: SiteNameComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'ownership',
          title: 'Ownership',
          width: 300,
          priority: 2,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: false,
          ascendingSort: false,
          sortWithOverlay: false,
          overlayData: [],
          component: OwnershipNameComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'productPlan',
          title: 'Product | Plan',
          width: 180,
          minWidth: 180,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: false,
          ascendingSort: false,
          sortWithOverlay: false,
          overlayData: [],
          component: SiteProductPlanComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'status',
          title: 'Status',
          width: 180,
          minWidth: 180,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: SiteStatusComponent,
        },
        {
          name: 'assetCount',
          title: 'Assets',
          isFrozen: false,
          width: 110,
          minWidth: 110,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.assetCountOverlayData,
          component: SiteAssetCountComponent,
          customData: this.siteTableCustomData,
        },
      ],
    };
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsCommonService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsCommonService.isAllowedNavigation();
  }
  ngOnInit(): void {
    this.commonFilterService.getFilterColumn(this.pageLabel).subscribe((response: any) => {
      this.columnsName = response;
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.mineStarDetails = dsp?.minestarServiceDetails || {};
      this.siteTableCustomData.mineStarDetails = this.mineStarDetails;
      this.isSaveTableSettings = this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        : false;
      this.isHighlightSearch = this.dspStoreData?.featureFlagInfo?.isHighlightSearch
        ? this.dspStoreData?.featureFlagInfo?.isHighlightSearch
        : false;
      this.isChangeOwnership = this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        ? this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        : false;
      this.isRemovePaginationSearch = this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        ? this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        : false;
      this.isMaintainPagination = this.dspStoreData?.featureFlagInfo?.isMaintainPagination
        ? this.dspStoreData?.featureFlagInfo?.isMaintainPagination
        : false;
      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });
    this.store.select('user').subscribe((user) => {
      this.profile = user?.user?.telUserType || 'CAT';
      this.userDealerCode = user.user?.dealerCode || '';
    });
    this.observable = this.tableSettingService.sitesObservable.subscribe((res) => {
      this.createSettingsPanelConfig();
    });

    this.fetchSettingDetails();
    this.getSiteCancelReasons();
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
    this.setLoggedInDealerInfo(this.dealerCode);
    this.getFilters();
    if (this.isChangeOwnership) {
      let newCustomerColumn = [
        {
          name: 'dcnName',
          title: 'Dealer Customer Name',
          width: 220,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: false,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: OwnershipNameComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'dcn',
          title: 'Dealer Customer Number',
          width: 250,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: false,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.assetCountOverlayData,
          component: OwnershipNameComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'ucidName',
          title: 'Customer Name',
          width: 200,
          priority: 3,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: OwnershipNameComponent,
          customData: this.siteTableCustomData,
        },
        {
          name: 'ucid',
          title: 'Customer ID',
          width: 200,
          priority: 2,
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.assetCountOverlayData,
          component: OwnershipNameComponent,
          customData: this.siteTableCustomData,
        },
      ];
      this.subcribeSitesTableConfig.columnConfig = this.dspUtilsCommonService.removeColumnFromConfig(
        this.subcribeSitesTableConfig.columnConfig,
        'ownership'
      );
      newCustomerColumn.map((data: any) => {
        this.subcribeSitesTableConfig.columnConfig.splice(
          1,
          0,
          this.dspUtilsCommonService.addNewColumnToConfig(
            this.subcribeSitesTableConfig.columnConfig,
            data.name,
            data.title,
            data.width,
            '',
            data.isAlwaysMandatory,
            data.isVisible,
            data.sortingEnable,
            data.sortWithOverlay,
            data.overlayData,
            data.width,
            data.component,
            data.Frozen
          )
        );
      });
    }
    if (this.isSaveTableSettings) {
      let configSaved: any = sessionStorage.getItem('tableSettings');
      configSaved = configSaved ? JSON.parse(configSaved) : null;
      configSaved = configSaved?.sites;
      this.subcribeSitesTableConfig.columnConfig = configSaved
        ? configSaved
        : this.subcribeSitesTableConfig.columnConfig;
    }
    this.updateComponent();
    this.initialLoad();
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      '',
      ''
    );
    Object.keys(filtersSaved).forEach((key) => {
      if (key === 'applicationName') {
        filtersSaved['product'] = filtersSaved[key];
        delete filtersSaved[key];
      } else if (key === 'applicationPlan') {
        filtersSaved['plan'] = filtersSaved[key];
        delete filtersSaved[key];
      }
    });
    this.fetchSitesAPIPayload.subFilters = this.fetchSitesCountAPIPayload.subFilters = filtersSaved ? filtersSaved : {};
    this.updateCustomData();
    if (!this.isMaintainPagination) {
      this.fetchSitesNCount(true);

      this.createSettingsPanelConfig();
    }

    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP Sites Page',
      });
    }

    this.loadSubscriptionServiceMap();
    this.getMinestarServiceDetails();
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
  }
  getMinestarServiceDetails() {
    if (isEmpty(this.dspStoreData?.minestarServiceDetails)) {
      this.siteService
        .getMineStar({
          organization: this.dealerCode,
          organizationType: 'Dealer',
        })
        .subscribe((res) => {
          this.store.dispatch(getMinestarServiceDetails(res));
        });
    }
  }
  updateColumnConfigCustomData() {
    this.subcribeSitesTableConfig.columnConfig.forEach((config: any) => {
      config.customData = this.siteTableCustomData;
    });
  }
  ngOnDestroy() {
    this.observable.unsubscribe();
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }
  fetchSettingDetails() {
    this.isTableLoading = true;

    this.tableSettingService.getTableSettings('sites', this.subcribeSitesTableConfig);
  }
  loadSubscriptionServiceMap() {
    if (isEmpty(this.dspStoreData?.serviceMapping)) {
      this.siteService.getSubscriptionServiceMap().subscribe({
        next: (result: any) => {
          this.store.dispatch(getSubscriptionServiceMapping(result));
        },
        error: (err: any) => {
          this.showToastMessage(this.systemError, 'error');
        },
      });
    }
  }
  updateCustomData() {
    this.siteTableCustomData.fetchSites = () => this.fetchSitesNCount(true);
  }

  showToastMessage(message: string, status: string) {
    if (status === 'error') {
      this.isTableLoading = false;
    }
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 5000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  @HostListener('document:click', ['$event'])
  documentClick = ($event: any) => {
    const isClickedOnGearIcon =
      this.settingIconElement && this.settingIconElement?.nativeElement.contains($event.target);
    const isClickedOnSettingPanel =
      this.settingIconElement && this.settingPanelElement?.nativeElement.contains($event.target);
    if (!(isClickedOnGearIcon || isClickedOnSettingPanel)) {
      this.closeSettingPanel();
    }
  };
  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
  }
  updateComponent() {
    this.subcribeSitesTableConfig.columnConfig?.map((data: any) => {
      switch (data.title) {
        case 'Site Name':
          data.component = SiteNameComponent;
          data.width = 200;
          data.minWidth = 200;
          break;
        case 'Ownership':
          data.component = OwnershipNameComponent;
          data.width = 300;
          break;
        case 'Customer ID':
          data.component = OwnershipNameComponent;
          data.width = 200;
          data.minWidth = 200;
          break;
        case 'Customer Name':
          data.component = OwnershipNameComponent;
          data.width = 220;
          data.minWidth = 220;
          break;
        case 'Product | Plan':
          data.component = SiteProductPlanComponent;
          data.width = 180;
          data.minWidth = 180;
          break;
        case 'Status':
          data.component = SiteStatusComponent;
          data.width = 180;
          data.minWidth = 180;
          break;
        case 'Dealer Customer Number':
          data.component = OwnershipNameComponent;
          data.width = 220;
          data.minWidth = 220;
          break;
        case 'Dealer Customer Name':
          data.component = OwnershipNameComponent;
          data.width = 220;
          data.minWidth = 220;
          break;
        case 'Assets':
          data.component = SiteAssetCountComponent;
          data.width = 110;
          data.minWidth = 110;
          break;
        default:
          break;
      }
    });
  }

  setLoggedInDealerInfo(dealerCode: string) {
    this.fetchSitesAPIPayload.dealerCode = dealerCode;
    this.fetchSitesCountAPIPayload.dealerCode = dealerCode;
  }

  initialLoad() {
    this.dspCommonService.getBillDirectCustomers({ dealerCode: this.dealerCode || 'TD' }).subscribe((res) => {
      this.b2cCustomers = res;
      this.siteTableCustomData.b2cCustomers = res;
    });
  }
  dataLoaded() {
    this.searchDataLoaded = true;
  }

  fetchSitesNCount(callPaginationApi: boolean) {
    this.sitesTableData = [];
    this.setAutoHeight();
    this.isTableLoading = true;
    this.isFetchSitesAPICallCompleted = false;
    this.isGlobalSearchDisabled = true;

    if (callPaginationApi) {
      if (this.isRemovePaginationSearch) {
        this.siteTableCustomData.searchString = this.searchDataLoaded ? this.searchTextValue : '';
        this.fetchSitesAPIPayload.searchText = this.searchDataLoaded ? this.searchTextValue : '';
        this.fetchSitesCountAPIPayload.searchText = this.searchDataLoaded ? this.searchTextValue : '';
      }
      this.fetchSitesAPICallData = forkJoin([
        this.siteService.getSitesCount(this.fetchSitesCountAPIPayload),
        this.siteService.getSites(this.fetchSitesAPIPayload),
      ]).subscribe(([siteCount, siteResponse]) => {
        this.isFetchSitesAPICallCompleted = true;
        if (siteResponse) {
          this.fetchSiteResponse(siteResponse);
        }

        this.isFetchCountAPICallCompleted = true;
        if (siteCount) {
          this.fetchSiteCount(siteCount);
        }
      });
    } else {
      this.fetchSitesAPICallData = this.siteService.getSites(this.fetchSitesAPIPayload).subscribe({
        next: (siteResponse) => {
          this.isFetchSitesAPICallCompleted = true;
          if (siteResponse) {
            this.fetchSiteResponse(siteResponse);
          }
        },
      });
    }
  }

  fetchSiteResponse(siteResponse: any) {
    this.sitesTableData = (siteResponse as getSitesResponseData)?.sites.map((obj) => ({
      ...obj,
      product: obj.product || 'MineStar',
      productPlan: this.updateProductPlanData(obj.product || 'MineStar', obj.planName),
      plan: isNil(obj.planName) ? '' : this.updatePlan(obj.planName),
      addOnServices: isNil(obj.planName) ? [] : this.updateAddOn(obj.planName),
    }));
    this.sitesTableData.forEach((siteDataParam: any) => {
      siteDataParam.subscriptionTypeId = 5521;
      siteDataParam.savedSubscription = siteDataParam.planName;
    });
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
    this.isTableLoading = false;
    this.isGlobalSearchDisabled = false;
  }

  fetchSiteCount(siteCount: any) {
    this.totalSitesCount = (siteCount as getSitesCountResponse)?.count;
    this.subcribeSitesTableConfig = this.updatePaginationCount(this.totalSitesCount, this.subcribeSitesTableConfig);
    this.sitesTableData = [...this.sitesTableData];
    this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
  }

  setAutoHeight() {
    const tableRef = this.tableRef?.table?.nativeElement;
    if (this.renderer && tableRef) {
      this.renderer.setStyle(tableRef, 'height', 'auto');
    }
  }

  closeSettingPanel = () => {
    this.isSettingVisible = false;
    return false;
  };

  toggleSetting = () => {
    this.isSettingVisible = !this.isSettingVisible;
  };

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }

  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };
  updateProductPlanData(productName: string, planName: string) {
    return isNil(planName) ? '' : `${productName}|${planName}`;
  }

  updatePlan(planName: string) {
    const addonServicesList = planName.split('|');
    return addonServicesList.shift() || '';
  }

  updateAddOn(planName: string) {
    const addonServicesList = planName.split('|');
    addonServicesList.shift();
    return addonServicesList;
  }

  updatePaginationCount(totalCount: number, tableConfig: TableConfig) {
    if (tableConfig && tableConfig?.pagination) {
      tableConfig.pagination.totalNumberOfRecords = totalCount;
      tableConfig.pagination.activePage = 1;
    }
    return tableConfig;
  }

  tableEvents(event: any) {
    if (event) {
      this.adjustTableHeight(event);
      if (event.eventType === 'SORTING') {
        if (
          this.fetchSitesAPIPayload.orderBy != event?.event ||
          this.fetchSitesAPIPayload.sortBy != event?.data?.name
        ) {
          this.fetchSitesAPIPayload.sortBy = event?.data?.name || 'createTime';
          this.fetchSitesAPIPayload.orderBy = event?.event || 'DESC';
          this.fetchSitesNCount(false);
        }
      } else if (event.eventType === 'PAGE_CHANGE_EVENT' && this.isPageChanged(event)) {
        this.isTableLoading = true;
        if (event?.range?.pageSize !== this.fetchSitesAPIPayload.limit) {
          this.subcribeSitesTableConfig.pagination.activePage = 1;
        }
        this.fetchSitesAPIPayload.offset = event.range?.startIndex || 0;
        this.fetchSitesAPIPayload.limit = event.range?.pageSize || 20;
        if (this.isMaintainPagination) {
          if (this.fetchSitesAPIPayload.limit != this.subcribeSitesTableConfig.pagination.defaultPageSize) {
            const tableConfig =
              this.updatedTableConfig.length > 0 ? this.updatedTableConfig : this.subcribeSitesTableConfig.columnConfig;
            this.tableSettingService.updateTableSettings(this.pageLabel, tableConfig, this.fetchSitesAPIPayload.limit);
            this.subcribeSitesTableConfig.pagination.defaultPageSize = this.fetchSitesAPIPayload.limit;
          }
        }
        this.fetchSitesNCount(false);
      }
    }
  }

  adjustTableHeight(event: any) {
    if ((event.eventType === 'SCROLL_EVENT' || event.eventType === 'WINDOW_RESIZE') && this.tableRef) {
      const tableRef = this.tableRef?.table?.nativeElement;

      if (this.sitesTableData?.length) {
        const adjustHeight = window.innerHeight - 185 + 'px';
        this.renderer.setStyle(tableRef, 'height', adjustHeight);
        this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
      }
    }
  }

  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.fetchSitesAPIPayload.offset ||
      pageData?.range?.pageSize !== this.fetchSitesAPIPayload.limit
    );
  }

  changePageRange(range: any) {
    this.range = this.sitesTableData.slice(range.startIndex, range.endIndex + 1);
    this.headerLabel = `${range.startIndex + 1} - ${range.endIndex + 1} of ${this.sitesTableData.length}`;
    this.label = `Viewing ${this.headerLabel}`;
  }

  createNewSite() {
    const modalRef = this.modal.openModal(CreateNewSiteComponent, {
      type: this.isTheme === '1.0' ? 'semi-modal' : 'full-modal',
      backdropClass: 'cc-modal-full-window-backdrop',
      panelClass: 'cc-modal-full-window',
      data: {
        b2cCustomers: this.b2cCustomers,
        mineStarDetails: this.mineStarDetails,
      },
    });
    modalRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.fetchSitesNCount(true);
      }
    });
  }

  getFilters() {
    this.filterService.getFilters(this.dealerCode).subscribe((response: any) => {
      if (response) {
        this.filterResponse = response;
      }
    });
  }

  loadChangeEmit(res: any) {
    this.savedFilters = this.filterContentsRef?.config?.data?.savedFilters || {};
    this.fetchSitesAPIPayload.subFilters = res;
    this.fetchSitesCountAPIPayload.subFilters = res;
    this.fetchSitesNCount(true);
  }
  viewAssetDetails(data: siteData) {
    this.drawerService.openDrawer<SiteDetailsComponent, CcDrawerConfig>(SiteDetailsComponent, {
      data: data,
      disableBackdropClick: false,
      position: CcDrawerPosition.RIGHT,
      drawerSize: CcDrawerSize.MEDIUM,
      id: 'listDrawer',
    });
  }

  onSiteSearchChange(event: any) {
    if (event.value.trim().length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value.length > 0 && event.value.length < 3) {
      this.negative = true;
      this.errorText = 'Please enter atleast 3 characters';
    } else {
      this.negative = false;
    }
    if (event.value !== '' && this.searchHelpText !== '') {
      this.searchHelpText = '';
    } else if (event.value.trim().length > 2 && event.value.trim() !== this.fetchSitesAPIPayload.searchText) {
      this.searchValue = event.value;
      if (this.isRemovePaginationSearch) {
        this.searchTextValue = event.value.trim();
      } else {
        this.siteTableCustomData.searchString = event.value.trim();
        this.fetchSitesAPIPayload.searchText = event.value.trim();
        this.fetchSitesCountAPIPayload.searchText = event.value.trim();
      }
      this.fetchSitesAPIPayload.offset = 0;
    } else if (event.value === '') {
      if (event?.target?.value === '') {
        if (this.isChangeOwnership) {
          this.searchHelpText = 'Find Site Name / Customer / Dealer Customer / Product | Plan (Min 3 Char)';
        } else {
          this.searchHelpText = 'Find Site Name / Ownership / Product | Plan (Min 3 Char)';
        }
      }
      this.searchValue = '';
      this.siteTableCustomData.searchString = '';
      if (this.isRemovePaginationSearch) {
        this.searchTextValue = '';
      }
      if (this.searchDataLoaded) {
        this.fetchSitesAPIPayload.searchText = '';
        this.fetchSitesCountAPIPayload.searchText = '';
        this.fetchSitesAPIPayload.offset = 0;
        this.fetchSitesNCount(true);
        this.searchDataLoaded = false;
      } else {
        this.fetchSitesAPIPayload.searchText = '';
        this.fetchSitesCountAPIPayload.searchText = '';
        this.fetchSitesAPIPayload.offset = 0;
      }
    }
    if (this.isHighlightSearch) {
      this.updateColumnConfigCustomData();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getSitesSearch = debounce(() => {
    this.fetchSitesNCount(true);
  }, 1000);

  onCickEvent(event: any) {
    if (event?.target?.value === '') {
      this.searchHelpText = 'Find Site name/Ownership/Product|Plan (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }

  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }

  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue?.length || 0;
      this.siteSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  createSettingsPanelConfig() {
    if (this.subcribeSitesTableConfig.columnConfig) {
      const trimmedTableConfig = this.subcribeSitesTableConfig?.columnConfig
        ?.filter((obj: any) => obj)
        ?.map((config: any) => {
          if (config.isFrozen === undefined) {
            config.isFrozen = false;
          }
          return {
            title: config.title ? config.title : '',
            name: config.name ? config.name : '',
            isFrozen: config.isFrozen ? config.isFrozen : false,
            isVisible: config.isVisible ? config.isVisible : false,
            isAlwaysMandatory: config.isAlwaysMandatory ? config.isAlwaysMandatory : false,
          };
        });
      this.tableSettingsPanelConfig = {
        features: trimmedTableConfig,
        visibilityToggle: false,
        ITableSettingsPanelTooltip: {
          columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
        },
      };
    }
    if (this.isMaintainPagination) {
      this.fetchSitesAPIPayload.limit = this.subcribeSitesTableConfig.pagination.defaultPageSize;

      this.fetchSitesNCount(true);
    }
    this.subcribeSitesTableConfig = { ...this.subcribeSitesTableConfig };
  }

  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      const originalConfig = JSON.parse(JSON.stringify(this.subcribeSitesTableConfig));
      this.updatedTableConfig = [];
      event.features.forEach((config, index) => {
        const data = originalConfig.columnConfig.find((dataObj: { name: string }) => config.name === dataObj.name);
        if (data) {
          data.isVisible = config.isVisible;
          data.isFrozen = config.isFrozen;
          data.priority = index + 1;
          data.title = config.title;
          switch (data.title) {
            case 'Site Name':
              data.component = SiteNameComponent;
              data.width = 200;
              break;
            case 'Ownership':
              data.component = OwnershipNameComponent;
              data.width = 300;
              break;
            case 'Customer ID':
              data.component = OwnershipNameComponent;
              data.width = 200;
              data.minWidth = 200;
              break;
            case 'Customer Name':
              data.component = OwnershipNameComponent;
              data.width = 200;
              data.minWidth = 200;
              break;
            case 'Product | Plan':
              data.component = SiteProductPlanComponent;
              data.width = 180;
              data.minWidth = 180;
              break;
            case 'Status':
              data.component = SiteStatusComponent;
              data.width = 180;
              data.minWidth = 180;
              break;
            case 'Dealer Customer Number':
              data.component = OwnershipNameComponent;
              data.width = 220;
              data.minWidth = 220;
              break;
            case 'Dealer Customer Name':
              data.component = OwnershipNameComponent;
              data.width = 200;
              data.minWidth = 200;
              break;
            case 'Assets':
              data.component = SiteAssetCountComponent;
              data.width = 110;
              data.minWidth = 110;
              break;
            default:
              break;
          }
        }
        this.updatedTableConfig.push(data);
      });
      originalConfig.columnConfig = this.updatedTableConfig;
      this.subcribeSitesTableConfig = { ...originalConfig };
      if (this.isMaintainPagination) {
        this.tableSettingService.updateTableSettings(
          this.pageLabel,
          this.updatedTableConfig,
          this.fetchSitesAPIPayload.limit
        );
      } else {
        this.tableSettingService.updateTableSettings(this.pageLabel, this.updatedTableConfig);
      }
      this.createSettingsPanelConfig();
      this.cdr.detectChanges();
    }
    this.isCloseEvent = false;
  }

  getSiteCancelReasons() {
    if (isEmpty(this.dspStoreData?.siteCancelReasons)) {
      const payload = {
        profile: this.profile,
      };
      this.dspCommonService.getCancelReason(payload).subscribe({
        next: (result: any) => {
          if (result.length > 0) {
            result.forEach((element: any) => {
              element.label = element.reason;
              element.value = element.reason;
            });
            this.store.dispatch(getSiteCancelReasonDetails({ siteCancelReasons: result }));
          }
        },
        error: (err: any) => {
          this.store.dispatch(getSiteCancelReasonDetails({ siteCancelReasons: [] }));
        },
      });
    }
  }
  savedFilterCheck() {
    if (this.savedFilters !== undefined) {
      if (Object.keys(this.savedFilters).every((key) => this.savedFilters[key].length === 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalConfig: any = {};
    let isConfirmationPopupNeeded = false;
    Object.assign(originalConfig, this.subcribeSitesTableConfig);
    for (let i = 0; event?.features?.length > i; i++) {
      const data = originalConfig.columnConfig.find(
        (dataObj: { name: string }) => event?.features[i]?.name === dataObj?.name
      );
      const indexOfOriginalColumnConfig = originalConfig.columnConfig.indexOf(data);
      if (data) {
        if (typeof data.isFrozen === 'undefined') data.isFrozen = false;
        if (
          data.isVisible !== event.features[i].isVisible ||
          data.isFrozen !== event.features[i].isFrozen ||
          indexOfOriginalColumnConfig !== i
        ) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
        showCrossMark: true,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
      };
      this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '534px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CROSS) {
          this.isCloseEvent = false;
        }
      });
    } else {
      this.modalRef = this.modal.openModal(TableSettingsCloseConfirmationComponent, {
        width: '534px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === false) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else {
          this.isCloseEvent = false;
        }
      });
    }
  }

  exportSubscription() {
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.showLoader = true;
    this.siteService.sitesExport(this.fetchSitesAPIPayload).subscribe(
      (res: any) => {
        if (res) {
        }
      },
      (err: any) => {
        if (err.status === 200) {
          this.showLoader = false;
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
          const day = ('0' + currentDate.getDate()).slice(-2);
          const fileName = `MANAGE_SITES_${year}_${month}_${day}.csv`;
          const xmlResponse = err.error.text;
          const BOM = '\uFEFF';
          const blob = new Blob([BOM + xmlResponse], { type: 'text/csv;charset=utf-8' });
          this.downloadCSV(blob, fileName);
        } else {
          const message = 'Download failed. Please try again';
          this.showToastMessage(message, 'error');
          this.showLoader = false;
        }
      }
    );
  }

  downloadCSV(csv: any, filename: any) {
    this.showLoader = false;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const csvUrl = URL.createObjectURL(csv);
    a.href = csvUrl;
    a.download = filename;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
}
