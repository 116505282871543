<div class="asset-select-plan-section" *ngIf="!showPlans">
  <form [formGroup]="manageAssetSubscriptionForm" id="set-service-container">
    <dsp-next-gen-ui-asset-details-card
      [rowData]="rowData"
      [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
      [subsPricingEligible]="subsPricingEligible"
      [showDealerInfo]="true"
    ></dsp-next-gen-ui-asset-details-card>
    <!-- <div class="header-blocker" *ngIf="blockedDeviceTypeList">
      <div class="header-text-plan-payment-block">
        <span class="header-text-alignment">
          <span
            ><img src="../../../assets/images/info-group.svg" class="info-icon-style info-group" />&nbsp;{{
              comparisonResult
            }}
            <cc-icon
              [tooltipPosition]="'bottom'"
              [interactive]="true"
              cc-tooltip
              [template]="tooltipTemplateFor2G"
              aria-label="help"
              tabindex="0"
              role="complementary"
              class="info-icon-style-block"
            >
              asset-info1
            </cc-icon>
          </span>
        </span>
      </div>
    </div> -->
    <dsp-next-gen-ui-blocked-devices [rowData]="rowData"></dsp-next-gen-ui-blocked-devices>

    <div class="cc-grid" *ngIf="rowData.reportingState === 'Subscribed'">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="header">
          <div class="select-plan-section">CURRENT PLAN(S):</div>
          <div class="cancel-plan-button">
            <button
              cc-btn-danger
              cc-btn-medium
              [setTheme]="true"
              (click)="cancelAllPlans()"
              style="background-color: #de2222"
            >
              Cancel All Plan(s)
            </button>
          </div>
        </div>
        <cc-accordion class="alignmentChange" [multi]="true" [cc-accordion-open-all]="true">
          <div *ngFor="let service of listOfProducts; let productIndex = index">
            <div *ngIf="service?.isSubscribed === true">
              <cc-expandable
                (closed)="closeService(service.applicationName)"
                (opened)="handleServiceChange(service.applicationName)"
                [expanded]="service.expand"
                [isToggle]="checkOpenedAccordian(service.applicationName)"
                [disabled]="service.disabled || !rowData.enableSubscription"
              >
                <cc-expandable-header>
                  <div class="expandable-header-title">
                    <span
                      [ngStyle]="{
                        color: !rowData.enableSubscription ? '#cccccc' : '#000000',
                      }"
                      >{{ service.applicationName }}</span
                    >
                  </div>
                </cc-expandable-header>
                <section
                  *ngIf="
                    subsCategory?.Single_Level_Profile_AddOn_FSM?.indexOf(service.applicationName) !== -1 &&
                    listOfBasePlans &&
                    listOfBasePlans[service.applicationName]
                  "
                >
                  <dsp-next-gen-ui-single-level-product
                    [listOfBasePlans]="listOfBasePlans[service.applicationName]"
                    [applicablePlansDetails]="service.applicablePlansDTOs"
                    [applicableAddOnPlansDetails]="applicableAddOnPlansDetails"
                    [listOfOptionalPlans]="listOfOptionalPlans[service.applicationName]"
                    [autoSelectItem]="autoSelectItem"
                    [subsData]="subsData[service.applicationName]"
                    (basePlanChange)="onBasePlanChange($event, service)"
                    (OptionalPlanChange)="onOptionalPlanChange($event, service)"
                    [manageAssetSubscriptionForm]="getControls(productIndex, service.applicationName)"
                    (togglePrepay)="togglePrepay($event, service.applicationName)"
                    [startDateFieldConfig]="startDateFieldConfig"
                    [endDateFieldConfig]="endDateFieldConfig"
                    [multiYearConfig]="multiYearConfig"
                    (prepayDateChange)="onDateChange($event, service.applicationName)"
                    [promoLoader]="promoLoader[service.applicationName]"
                  ></dsp-next-gen-ui-single-level-product>
                </section>
                <section
                  *ngIf="
                    subsCategory?.Single_Level_Minestar?.indexOf(service.applicationName) !== -1 &&
                    listOfBasePlans &&
                    listOfBasePlans[service.applicationName]
                  "
                >
                  <dsp-next-gen-ui-minestar-product
                    (redirectSite)="redirectToSitePage()"
                    [siteName]="siteName"
                    [userActiveFeatures]="userActiveFeatures"
                    (siteInfo)="getSiteReqInfo($event, service)"
                    (siteChange)="onSiteChanged(siteName, service)"
                    [enableSearchOverlay]="enableSearchOverlay"
                    [siteResults]="siteResults"
                    (handleListItemSelection)="listItemClick($event, service)"
                    [isSiteNameLoading]="isSiteNameLoading"
                    [siteNotFound]="siteNotFound"
                    [listOfBasePlans]="listOfBasePlans[service.applicationName]"
                    (basePlanChange)="onBasePlanChange($event, service)"
                    [listOfOptionalPlans]="listOfOptionalPlans[service.applicationName]"
                    (additionalServicesChng)="onOptionalPlanChange($event, service)"
                    [applicableAddOnPlansDetails]="applicableAddOnPlansDetails"
                    [applicablePlansDetails]="service.applicablePlansDTOs"
                    [productFamily]="productFamily"
                    [customerPlan]="this.minstarSiteNameDisabled[service.applicationName]"
                    [listOfProductFamily]="listOfProductFamily"
                    [modifiedProductFamily]="modifiedProductFamily"
                    (productFamilyChanged)="productFamilyChangedFn($event, service)"
                    [manageAssetSubscriptionForm]="getControls(productIndex, service.applicationName)"
                    [subsData]="subsData[service.applicationName]"
                    [promoLoader]="promoLoader[service.applicationName]"
                    (blurEvent)="onBlurEvent($event, service.applicationName)"
                  ></dsp-next-gen-ui-minestar-product>
                </section>
              </cc-expandable>
            </div>
          </div>
        </cc-accordion>
      </div>
    </div>
    <div class="cc-grid" *ngIf="isSelectPlansRequired()">
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="select-plan-section">SELECT PLAN(S):</div>
        <div class="add-more-button" *ngIf="rowData.reportingState !== 'Subscribed'">
          <!-- <a
            id="add-more-btn"
            cc-btn-medium
            cc-btn-tertiary
            [disabled]="enableAddMoreBtn"
            (click)="addMorePlan()"
            [setTheme]="true"
            >Add More Plan(s)</a
          > -->
        </div>
        <cc-accordion style="margin-left: -10px" [cc-accordion-collapse-all]="isResetService" [multi]="true">
          <div formArrayName="products" *ngFor="let service of listOfProducts; let productIndex = index">
            <div *ngIf="service?.isSubscribed === false">
              <cc-expandable
                (closed)="closeService(service.applicationName)"
                (opened)="handleServiceChange(service.applicationName, 'cc-accordion-child-' + service.applicationId)"
                [expanded]="service.expand"
                [isToggle]="checkOpenedAccordian(service.applicationName)"
                [disabled]="service.disabled || !rowData.enableSubscription"
                [id]="'cc-accordion-child-' + service.applicationId"
              >
                <cc-expandable-header>
                  <div style="display: flex">
                    <div
                      class="expandable-header-title cc-col cc-lg-col--10 cc-md-col--10 cc-xl-col--10 cc-sm-col--10 cc-xs-col--10"
                    >
                      <span
                        [ngStyle]="{
                          color:
                            service?.disabled ||
                            isCancelView ||
                            rowData.reportingState === 'Subscribed' ||
                            !rowData.enableSubscription ||
                            (this.rowData?.ownershipDetails?.length === 1 &&
                              this.rowData?.ownershipDetails?.[0]?.digitalAuthorizationDetails?.CATDigitalAuthStatus ===
                                'DECLINE')
                              ? '#cccccc !important'
                              : '#000000 !important',
                        }"
                        >{{ service.applicationName }}</span
                      >
                    </div>
                    <!-- <div
                      *ngIf="rowData.reportingState !== 'Subscribed'"
                      style="margin: 0px !important"
                      class="reset-btn cc-col cc-lg-col--2 cc-md-col--2 cc-xl-col--2 cc-sm-col--2 cc-xs-col--2"
                    >
                      <a
                        id="reset-btn"
                        cc-btn-medium
                        cc-btn-tertiary
                        [setTheme]="true"
                        (click)="resetCall($event, service.applicationName)"
                        [disabled]="!service.reset"
                        >Reset</a
                      >
                    </div> -->
                  </div>
                </cc-expandable-header>
                <section
                  *ngIf="
                    subsCategory?.Single_Level_Profile_AddOn_FSM?.indexOf(service.applicationName) !== -1 &&
                    listOfBasePlans &&
                    listOfBasePlans[service.applicationName]
                  "
                >
                  <dsp-next-gen-ui-single-level-product
                    [listOfBasePlans]="listOfBasePlans[service.applicationName]"
                    [applicablePlansDetails]="service.applicablePlansDTOs"
                    [applicableAddOnPlansDetails]="applicableAddOnPlansDetails"
                    [listOfOptionalPlans]="listOfOptionalPlans[service.applicationName]"
                    [autoSelectItem]="autoSelectItem"
                    [subsData]="subsData[service.applicationName]"
                    (basePlanChange)="onBasePlanChange($event, service)"
                    (OptionalPlanChange)="onOptionalPlanChange($event, service)"
                    [manageAssetSubscriptionForm]="getControls(productIndex, service.applicationName)"
                    (togglePrepay)="togglePrepay($event, service.applicationName)"
                    [startDateFieldConfig]="startDateFieldConfig"
                    [endDateFieldConfig]="endDateFieldConfig"
                    [multiYearConfig]="multiYearConfig"
                    (prepayDateChange)="onDateChange($event, service.applicationName)"
                    [promoLoader]="promoLoader[service.applicationName]"
                  ></dsp-next-gen-ui-single-level-product>
                </section>

                <section
                  *ngIf="
                    subsCategory?.Single_Level_Minestar?.indexOf(service.applicationName) !== -1 &&
                    listOfBasePlans &&
                    listOfBasePlans[service.applicationName]
                  "
                >
                  <dsp-next-gen-ui-minestar-product
                    (redirectSite)="redirectToSitePage()"
                    [siteName]="siteName"
                    (siteInfo)="getSiteReqInfo($event, service)"
                    (siteChange)="onSiteChanged(siteName, service)"
                    [userActiveFeatures]="userActiveFeatures"
                    [enableSearchOverlay]="enableSearchOverlay"
                    [siteResults]="siteResults"
                    (handleListItemSelection)="listItemClick($event, service)"
                    [siteNotFound]="siteNotFound"
                    [listOfBasePlans]="listOfBasePlans[service.applicationName]"
                    (basePlanChange)="onBasePlanChange($event, service)"
                    [listOfOptionalPlans]="listOfOptionalPlans[service.applicationName]"
                    (additionalServicesChng)="onOptionalPlanChange($event, service)"
                    [applicableAddOnPlansDetails]="applicableAddOnPlansDetails"
                    [applicablePlansDetails]="service.applicablePlansDTOs"
                    [productFamily]="productFamily"
                    [listOfProductFamily]="listOfProductFamily"
                    (productFamilyChanged)="productFamilyChangedFn($event, service)"
                    [manageAssetSubscriptionForm]="getControls(productIndex, service.applicationName)"
                    [subsData]="subsData[service.applicationName]"
                    [promoLoader]="promoLoader[service.applicationName]"
                    [isSiteNameLoading]="isSiteNameLoading"
                    [modifiedProductFamily]="modifiedProductFamily"
                    (blurEvent)="onBlurEvent($event, service.applicationName)"
                    [customerPlan]="this.minstarSiteNameDisabled[service.applicationName]"
                  ></dsp-next-gen-ui-minestar-product>
                </section>
              </cc-expandable>
            </div>
            <div *ngIf="isCancelView" class="accordian-bottom"></div>
          </div>
        </cc-accordion>
      </div>
    </div>
  </form>
  <div class="cc-grid-1" *ngIf="checkIfProductsAvailable()">
    <div><img class="no-products" src="..\assets\images\no_products.svg" /></div>
    <div>
      No products available for selection. This may occur due to conditions such as device ineligibility, requirement
      for CVA, or restriction of administration due a product not offered in your territory.
    </div>
  </div>
  <div class="cc-grid">
    <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 algin-select-plan-footer">
      <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
      <div class="colorChange" [innerHTML]="infoMessageManageAsset || ''"></div>
    </div>
    <div
      *ngIf="showOtoMessage"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 align-select-plan-footer-oto"
    >
      <img src="..\assets\images\info-icon.svg" class="info-icon-style" />
      <div>{{ otoMessage }}</div>
    </div>
    <div
      *ngIf="!isChinaDealer"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
      style="font-size: 12px; padding-left: 15px"
    >
      Equipment Insights, Remote Asset Monitoring, Remote Fleet Vision and Marine Concierge Plus are automatically
      bundled with VisionLink by default. See
      <a (click)="helpCenterLink()" target="_blank" class="dsp-help-hyperlink">DSP Help Center</a> for details on
      specific plan included.
    </div>
    <div
      *ngIf="selectedDigitalAuthDetails?.CATDigitalAuthStatus === 'DECLINE' && isdatExcludedProductCheck"
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12"
      style="font-size: 12px; padding-left: 15px"
    >
      Customer has declined Caterpillar Digital Authorization. In order to activate plans on impacted products, take
      action through the
      <a (click)="datClick()" target="_blank" class="dsp-help-hyperlink">Digital Authorization Tool</a>
    </div>
    <div
      class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12 align-select-plan-footer-notes"
      *ngIf="rowData.notes?.length > 0"
    >
      <div class="colorChange">{{ rowData.notes[0]?.displayMessage || '' }}</div>
    </div>
  </div>
</div>

<ng-template #shared_to_template2>
  <div class="popup-template">
    <div>
      <strong> Dealer Action Required: </strong>
    </div>
    <div>
      <p>
        There is a cost associated with the subscription of each asset to VIMS Transmitter. This cost will be invoiced
        per asset on a monthly basis to the dealer. For details about the subscription cost review the VIMS Transmitter
        Price List
        <a
          href="https://catdealer.com/en/products/digital-products-technology/minestar-solutions/minestar-solutions-surface/health/connectivity.html#accordion-89057a14fd-item-ab94f1ffaa-button"
          style="text-decoration: underline"
          target="_blank"
          >Here</a
        >
      </p>
      <p>
        The installation and use of the MineStar� technologies require the Dealer to execute a MineStarTM Caterpillar
        Dealer Subscription Purchase Agreement which includes Attachment 1 � Software End User Agreement. Templates for
        the MineStarTM Caterpillar Dealer Subscription Purchase Agreements can be found on
        <a
          href="https://catdealer.com/en/products/technology/minestar-solutions-surface/cat-minestar-system-support/bronze-subscriptions.html"
          style="text-decoration: underline"
          target="_blank"
          >Here</a
        >
      </p>
    </div>
  </div>
</ng-template>
<ng-template #b2cCustWarningTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>
        {{ languageTranslations?.Worklist?.WL_WARNING_MSG_CONTENT }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeB2cCustWarningMsgPopup(true)">
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeB2cCustWarningMsgPopup(false)">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #catGradeConnectivityPopup>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>
        {{ languageTranslations?.Worklist?.WL_CAT_GRADE }}
      </p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeCatGradeConnectivityPopup()">OK</button>
    </div>
  </div>
</ng-template>
<!-- 
<ng-template #subscriptionResetPopup>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>
    <div class="cc-modal-content group-info__content">
      <p>Current selected plans will be disregarded and new selections will apply.</p>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeSubscriptionResetPopup()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closeSubscriptionResetPopup()">Reset</button>
    </div>
  </div>
</ng-template> -->
<ng-template #selectPlanpopUp>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>

    <div class="cc-modal-content group-info__content">
      <!-- <p>
        {{ this.compatipleMessage }} <span *ngIf="successOrFailureMessages.length == 1"> is</span>
        <span *ngIf="successOrFailureMessages.length > 1">are</span> incompatible with
        {{
          this.lastSelectedBaseAndOption.planName !== 'None'
            ? this.lastSelectedBaseAndOption.planName
            : this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName]
                .subscription?.previousSubscription?.catLevel
        }}
        and will be reset on
        {{
          this.lastSelectedBaseAndOption.planName !== 'None'
            ? this.lastSelectedBaseAndOption.planName
            : this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName]
                .subscription?.previousSubscription?.catLevel
        }}
        selection. Do you confirm
        {{
          this.lastSelectedBaseAndOption.planName !== 'None'
            ? this.lastSelectedBaseAndOption.planName
            : this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName]
                .subscription?.previousSubscription?.catLevel
        }}
        selection?
      </p> -->
      <div>
        {{
          this.lastSelectedBaseAndOption.planName !== 'None'
            ? this.lastSelectedBaseAndOption.planName
            : this.selectedAssetSubscriptionFormData.products[this.lastSelectedBaseAndOption.apllicationName]
                .subscription?.previousSubscription?.catLevel
        }}
        is incompatible with the existing plan(s) on the asset. if you continue, the following existing plans will be
        removed. Do you want to continue?
        <div class="padding-compatible">
          <div *ngFor="let item of filterPopupData(successOrFailureMessages)" style="display: flex">
            <cc-status-indicator [type]="'dot'" style="align-items: center !important"> </cc-status-indicator>
            {{ item.subscription ? item.subscription : item.addon }}
          </div>
        </div>
      </div>
    </div>
    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="CompatibleCancel()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="CompatibleConfirm()">Continue</button>
    </div>
  </div>
</ng-template>
<!-- <ng-template #tooltipTemplateFor2G>
  <div *ngIf="pastTooltip">
    <span class="info-tooltip">
      A device on this asset is impacting the availability of plans. Effective {{ blockingDate }}
    </span>
  </div>
  <div *ngIf="futureTooltip">
    <span class="info-tooltip">
      Plans that require a device will become unavailable on this asset. Effective {{ blockingMonth }}
      {{ blockingYear }}</span
    >
  </div>
</ng-template> -->
