<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="cc-headerText cc-drawer__ellipsis"> Cancel Plan </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <dsp-next-gen-ui-site-info [siteData]="list.siteInfo" [displayType]="'cancel'"></dsp-next-gen-ui-site-info>
    <div>
      <h3 *ngIf="isPricingEligible" class="payment-detail">PAYMENT DETAILS</h3>
      <dsp-next-gen-ui-cat-billing
        [payBillingDetails]="paymentDetails"
        [payMentPreview]="paymentPreview"
        [cancelPlanDetails]="cancelPlanDetails"
        [isSitePricingEligible]="isPricingEligible"
        [cancelPlan]="true"
      ></dsp-next-gen-ui-cat-billing>
      <div class="drawerpadding">
        <h4>Cancellation Reason</h4>
        <div>
          <cc-dropdown
            [id]="'dropdown-placement'"
            name="checkbox"
            [label]="'Select'"
            [dataSource]="cancelReasons"
            [placementPosition]="'top'"
            [scrollToSelected]="true"
            [appOverlayClass]="'app-overlay-class'"
            [isTheme]="'2.0'"
            (selectionChange)="onDropdownChange($event, 'placementPosition')"
          ></cc-dropdown>
        </div>
        <div class="Row" *ngIf="isPricingEligible && !isPaymentPreviewMessage">
          <div class="cancelNoteCell">
            <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
            <div class="cancelNote" [innerHTML]="paymentDetails?.cancelNotificationMessage"></div>
          </div>
        </div>
        <div class="Row" *ngIf="isPaymentPreviewMessage">
          <div class="cancelNoteCell">
            <img class="gps-status-warning" src="..\assets\images\GPS_WARNING.svg" />
            <div class="cancelNote" [innerHTML]="cancelNotificationMessage"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button id="cc-drawer-cancelBtn" cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer()">
      Cancel
    </button>
    <button
      id="cc-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="selectedReason === ''"
      (click)="openDeleteGroupModal(deleteGroupModalTemplate)"
    >
      Submit
    </button>
  </div>
</div>

<ng-template #templateRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirm</div>
    </div>

    <div class="cc-modal-content group-info__content">
      <p>Are you sure that you want to cancel this plan?</p>
    </div>

    <div class="cc-modal-footer group-info__footer">
      <button
        cc-btn-secondary
        cc-btn-large
        [setTheme]="true"
        class="cancel button-opacity"
        (click)="closeDeleteGroupModal()"
      >
        Cancel
      </button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" class="delete button-opacity" (click)="deleteGroup()">
        Submit
      </button>
    </div>
  </div>
</ng-template>
