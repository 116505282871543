import { Component, OnInit, Inject, ViewChild, TemplateRef, ViewEncapsulation, HostListener } from '@angular/core';
import {
  CcDrawerRef,
  CcModal,
  CC_DRAWER_DATA,
  MessageBar,
  MessageBarConfig,
} from '@cat-digital-workspace/shared-ui-core';
import { DspCommonService } from '../../services/dsp-common.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../store/state/dsp.state';
import * as dspConstants from '../../shared/dspConstants';
import { UserPreferenceService } from '../../user-preference.service';
import { DATA_LAYER_OBJECT } from '../../google-analyatics.interface';
import { SharedService } from '../../shared.service';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeviceStatusComponent implements OnInit {
  @ViewChild('lastSmhMessageRef') lastSmhMessageTemplate!: TemplateRef<any>;
  dealer: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    public deviceStatusContainerRef: CcDrawerRef<DeviceStatusComponent>,
    public modalRef: CcDrawerRef<DeviceStatusComponent>,
    public dspCommonService: DspCommonService,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    @Inject(CC_DRAWER_DATA)
    public rowData: any,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService,
    private needsReviewService: NeedsReviewService
  ) {}
  deviceDetails: any;
  lastMessage: any;
  params: any;
  deviceFeaturesInfo: any;
  enableRequestSmhButton = true;
  showSmhTable = true;
  languageTranslations: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  deviceStatusError = false;
  deviceSmhMessage = '';
  statusErrorMessage =
    "Error processing your request. Please try again later or contact support at <a style='color: #0088cc;text-decoration: underline;' href='mailto:CatDigitalSupport@cat.com'>CatDigitalSupport@cat.com</a>";
  showStatusErrorMessage = false;
  launchType = '';
  isReplaceInfoIcon = false;
  dspStoreData!: any;
  isTimeZoneSelectionEnabled: any;
  dspData: any;
  localTimeZone: any;
  isNumberConventionEnabled: any;
  isTimeFormatEnabled: any;
  isEnableForwardSlashInPID = false;
  payloadObj: any;
  queueReasonSubscription: any;
  selectedQueueReason: any;
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspData = dsp;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo || {};
      this.languageTranslations = dsp.languageTranslations || {};
      this.dspStoreData = dsp.dspConfig;
      this.isTimeZoneSelectionEnabled = dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? dsp?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isEnableForwardSlashInPID = dsp?.featureFlagInfo?.isEnableForwardSlashInPID
        ? dsp?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
    });
    this.isReplaceInfoIcon = this.dspData?.featureFlagInfo?.isReplaceInfoIcon
      ? this.dspData?.featureFlagInfo?.isReplaceInfoIcon
      : false;
    this.launchType = this.rowData?.type;
    this.payloadObj = {
      make: this.rowData?.assetInfo?.make,
      serialNumber: this.rowData?.assetInfo?.serialNumber,
    };
    this.params = this.isEnableForwardSlashInPID
      ? this.payloadObj
      : this.rowData?.assetInfo?.make + '/' + this.rowData?.assetInfo?.serialNumber;
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.label;
    });
    this.dealer = fetchLoggedInDealerDetails();
    this.getDeviceStatus();
    this.getLastMessage();
    this.checkIfSMHNeeded();
    this.getLocalTimeZone();
  }
  getLocalTimeZone() {
    this.localTimeZone = this.userPreferenceService.getLocalTimeZone();
  }
  checkIfSMHNeeded() {
    const deviceTypeVal = this.deviceFeaturesInfo?.[this.rowData?.assetInfo?.deviceType];
    let isDisabled: any;
    if (Object.prototype.hasOwnProperty.call(deviceTypeVal, 'DISABLE_SMH')) {
      isDisabled = deviceTypeVal.DISABLE_SMH;
    } else {
      isDisabled = false;
    }
    if (isDisabled) {
      this.showSmhTable = false;
      this.enableRequestSmhButton = false;
    }
  }

  getDeviceStatus() {
    this.dspCommonService.getDeviceStatus(this.params).subscribe({
      next: (response: any) => {
        this.setDataLayer('success', '');
        if (this.isTimeZoneSelectionEnabled) {
          this.deviceDetails = response;
          if (this.deviceDetails?.lastCheckIn) {
            let lastCheckIn = response?.lastCheckIn
              ? this.userPreferenceService.convertTimeToSelectedZone(
                  response?.lastCheckIn.split(' ')[0] + ' ' + response?.lastCheckIn.split(' ')[1],
                  true
                )
              : '';
            if (this.isTimeFormatEnabled) {
              this.deviceDetails.lastCheckIn =
                this.userPreferenceService.convertDateTime(
                  lastCheckIn?.split(' ')[0] + ' ' + lastCheckIn?.split(' ')[1]
                ) +
                ' ' +
                lastCheckIn?.split(' ')[2];
            } else {
              this.deviceDetails.lastCheckIn = lastCheckIn;
            }
          }
          if (this.deviceDetails?.lastHttpTransmission) {
            let lastHttpTransmission = response?.lastHttpTransmission
              ? this.userPreferenceService.convertTimeToSelectedZone(
                  response?.lastHttpTransmission.split(' ')[0] + ' ' + response?.lastHttpTransmission.split(' ')[1],
                  true
                )
              : '';
            if (this.isTimeFormatEnabled) {
              this.deviceDetails.lastHttpTransmission =
                this.userPreferenceService.convertDateTime(
                  lastHttpTransmission?.split(' ')[0] + ' ' + lastHttpTransmission?.split(' ')[1]
                ) +
                ' ' +
                lastHttpTransmission?.split(' ')[2];
            } else {
              this.deviceDetails.lastHttpTransmission = lastHttpTransmission;
            }
          }
          if (this.deviceDetails?.lastSaberTransmission) {
            let lastSaberTransmission = response?.lastCheckIn
              ? this.userPreferenceService.convertTimeToSelectedZone(
                  response?.lastSaberTransmission.split(' ')[0] + ' ' + response?.lastSaberTransmission.split(' ')[1],
                  true
                )
              : '';
            if (this.isTimeFormatEnabled) {
              this.deviceDetails.lastSaberTransmission =
                this.userPreferenceService.convertDateTime(
                  lastSaberTransmission?.split(' ')[0] + ' ' + lastSaberTransmission?.split(' ')[1]
                ) +
                ' ' +
                lastSaberTransmission?.split(' ')[2];
            } else {
              this.deviceDetails.lastSaberTransmission = lastSaberTransmission;
            }
          }

          if (this.deviceDetails?.deviceStatusEventDetailsList) {
            this.deviceDetails?.deviceStatusEventDetailsList?.map((deviceStatusDetail: any) => {
              deviceStatusDetail.eventDateTime = deviceStatusDetail?.eventDateTime
                ? this.userPreferenceService.convertTimeToSelectedZone(deviceStatusDetail?.eventDateTime, false)
                : '';
              deviceStatusDetail.statusDateTime = deviceStatusDetail?.statusDateTime
                ? this.userPreferenceService.convertTimeToSelectedZone(deviceStatusDetail?.statusDateTime, false)
                : '';
              if (this.isTimeFormatEnabled) {
                deviceStatusDetail.eventDateTime = this.userPreferenceService.convertDateTime(
                  deviceStatusDetail.eventDateTime
                );
                deviceStatusDetail.statusDateTime = this.userPreferenceService.convertDateTime(
                  deviceStatusDetail.statusDateTime
                );
              }
            });
          }
        } else {
          this.deviceDetails = response;
        }
        this.deviceStatusError = false;
        this.showStatusErrorMessage = false;
      },
      error: (err: any) => {
        this.setDataLayer('failed', this.systemError);
        this.showSmhTable = false;
        this.deviceStatusError = true;
        this.showStatusErrorMessage = true;
      },
    });
  }

  setDataLayer(status: any, failedReason: any) {
    let isNeedsReview =
      this.rowData?.assetInfo?.status === this.dspNeedsReviewConstants.Active ||
      this.rowData?.assetInfo?.status === this.dspNeedsReviewConstants.New;

    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Device Status',
      formContent: this.rowData?.assetInfo?.serialNumber + ' | ' + this.rowData?.assetInfo?.deviceType,
      formContent2: isNeedsReview ? this.selectedQueueReason : '',
      formAdditionalContent: this.dealer?.dealerCode + ' | ' + 'Asset Action Menu Clicked',
      formStatus: status,
      formFieldCausingError: failedReason,
      formLocation: isNeedsReview ? 'Needs Review Page' : 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }

  getLastMessage() {
    this.dspCommonService.getLastMessage(this.params).subscribe({
      next: (response: any) => {
        if (response?.serviceMeterReadings?.length > 0) {
          if (this.isTimeZoneSelectionEnabled) {
            this.lastMessage = response;
            this.lastMessage.eventDateTime = this.lastMessage.eventDateTime
              ? this.userPreferenceService.convertTimeToSelectedZone(this.lastMessage.eventDateTime, false)
              : '';
            if (this.isTimeFormatEnabled) {
              this.lastMessage.eventDateTime = this.lastMessage.eventDateTime
                ? this.userPreferenceService.convertDateTime(this.lastMessage.eventDateTime)
                : '';
            }
          } else {
            this.lastMessage = response;
          }
        } else {
          this.setErroMessage();
        }
      },
      error: (err: any) => {
        this.showToastMessage(this.systemError, 'error', undefined);
      },
    });
  }

  setErroMessage() {
    if (this.deviceDetails?.deviceStatusEventDetailsList.length === 0 && this.deviceStatusError) {
      this.showSmhTable = false;
    } else {
      this.deviceSmhMessage = this.languageTranslations?.Worklist?.WL_LAST_SMH_ERRORMSG;
    }
  }

  requestSmhMessage() {
    this.dspCommonService.requestSmhMessage(this.params).subscribe({
      next: (response: any) => {
        if (response.code === 200) {
          this.showToastMessage(
            'Request sent successfully. It may take some time for the device to respond.',
            'success',
            undefined
          );
        } else {
          const linkDetail = [
            {
              label: 'Cat Digital Support',
              fn: () => {
                window.open(
                  'https://cat-crmi.secure.force.com/CatConnectCase/?_ga=2.117467593.170777017.1674835347-192150537.1661439604'
                );
              },
            },
          ];
          this.showToastMessage('Request failed. Please try again later or contact', 'error', linkDetail);
        }
      },
      error: (err: any) => {
        const linkDetail = [
          {
            label: 'Cat Digital Support',
            fn: () => {
              window.open(
                'https://cat-crmi.secure.force.com/CatConnectCase/?_ga=2.117467593.170777017.1674835347-192150537.1661439604'
              );
            },
          },
        ];
        this.showToastMessage('Request failed. Please try again later or contact', 'error', linkDetail);
      },
    });
  }
  openLastSmhPopup(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '455px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }
  closePopUp() {
    this.modalRef.close();
  }
  showToastMessage(message: string, status: string, linkDetail: any) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'device-status',
      duration: 3000,
    };
    this.messageBar.open(message, status, linkDetail, config, true);
  }
  closeDrawer() {
    this.messageBar.dismiss();
    this.deviceStatusContainerRef.close();
  }

  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, false);
    }
    return number;
  }
}
