<div class="cc-drawer">
  <div class="cc-drawer__header asset-drawer-header">
    <div class="asset-header-data">
      <div class="asset-drawer-header-mainText">
        <ng-container *ngIf="showAssetImage">
          <div class="asset-image" *ngIf="showDefaultAssetImage">
            <img
              class="default-asset-image"
              src="../../../../assets/images/default_asset_image.png"
              alt="no results found"
            />
          </div>
          <div *ngIf="!showDefaultAssetImage">
            <img class="asset-image" src="{{ assetImageURL }}" alt="no results found" />
          </div>
        </ng-container>

        <div class="head-style">
          {{ assetData?.serialNumber ? assetData?.serialNumber : '' }}
          {{ assetData?.engineSerialNo ? ' | ' + assetData?.engineSerialNo : '' }}
          {{ assetData?.make ? ' | ' + assetData?.make : '' }}
          {{ assetData?.model ? ' | ' + assetData?.model : '' }}
          {{ assetData?.manufacturingYear ? ' | ' + assetData?.manufacturingYear : '' }}
        </div>
        <div class="header-icons" *ngIf="!deviceInfo1A">
          <ng-container *ngIf="connectionStatus?.length === 0">
            <cc-icon
              cc-tooltip
              [template]="connectionCheckTooltip"
              [appClass]="'-connection-check-tooltip'"
              [tooltipPosition]="'bottom'"
              class="asset-header-icons"
              *ngIf="showConnectionCheckIcon"
              (click)="checkConnection(rowData.make, rowData.serialNumber)"
              >connection-check
            </cc-icon>
          </ng-container>
          <ng-container *ngFor="let status of connectionStatus">
            <ng-container *ngIf="showConnectionCheckIcon && status.eq_sn === rowData.serialNumber">
              <cc-icon
                cc-tooltip
                [template]="connectionCheckTooltip"
                [appClass]="'-connection-check-tooltip'"
                [tooltipPosition]="'bottom'"
                (click)="checkConnection(rowData.make, rowData.serialNumber)"
                *ngIf="status.connection_status === 'success' || status.connection_status === 'failure'"
                class="connection-check"
                [style.color]="getColor(status.connection_status)"
                >connection-check
              </cc-icon>
              <dsp-next-gen-ui-spinner
                cc-tooltip
                [template]="connectionCheckTooltip"
                [tooltipPosition]="'bottom'"
                *ngIf="status.connection_status === 'pending'"
                class="drawer-pending"
              >
              </dsp-next-gen-ui-spinner>
            </ng-container>
          </ng-container>
          <ng-template #connectionCheckTooltip>
            <ng-container *ngFor="let status of connectionStatus">
              <div>Check Connection</div>
              <span *ngIf="status.connection_status === 'success'" class="lastcheckin-tooltip"
                >Successful Check-In: {{ lastCheckinValue || 'Not Available' }}
              </span>
              <span *ngIf="status.connection_status === 'pending'" class="lastcheckin-tooltip">In Progress</span>
              <span *ngIf="status.connection_status === 'failure'" class="lastcheckin-tooltip"
                >Failed Check-In: {{ lastCheckinValue || 'Not Available' }}
              </span>
            </ng-container>

            <span *ngIf="connectionStatus.length === 0">
              <div>Check Connection</div>
              Last Check-In:
            </span>
            <dsp-next-gen-ui-spinner *ngIf="isDeviceStatusAPIInProgress" class="status-pending">
            </dsp-next-gen-ui-spinner>
            <span *ngIf="this.lastCheckinValue !== '' && !isDeviceStatusAPIInProgress && connectionStatus.length === 0">
              {{ lastCheckinValue }}
            </span>
            <span *ngIf="this.lastCheckinValue === '' && !isDeviceStatusAPIInProgress && connectionStatus.length === 0">
              Not Available
            </span>
          </ng-template>
          <span *ngIf="showConnectionCheckIcon && showConfigureWifiIcon" class="pipe">|</span>
          <cc-icon
            [cc-tooltip]="wifiToolTip"
            (click)="openWifiConfiguration()"
            class="asset-header-icons wifi"
            *ngIf="showConfigureWifiIcon"
            >wifi</cc-icon
          >
          <span *ngIf="(showConnectionCheckIcon || showConfigureWifiIcon) && showDeviceStatusIcon" class="pipe">|</span>
          <img
            [cc-tooltip]="settingsToolTip"
            class="asset-header-icons device-status"
            [tooltipPosition]="'right'"
            *ngIf="showDeviceStatusIcon"
            src="..\assets\images\settings.svg"
            (click)="openDeviceStatus()"
          />
        </div>
      </div>
      <div>
        <div
          class="asset-drawer-header-subtext"
          *ngIf="
            enableSmhLocationSection &&
            !assetDetailsData?.isMaskRequired &&
            assetDetailsData?.reportingState !== dspWorklistConstants.UNSUBSCRIBED
          "
        >
          <div>
            <span class="smh-data" *ngIf="showSmhData && shmAndLocationInfo?.serviceMeterReading"
              >{{ shmAndLocationInfo?.serviceMeterReading }}
              <cc-icon
                cc-tooltip
                class="smhLocationIcon"
                [template]="tooltipTemplateForSmh"
                [tooltipPosition]="'bottom'"
                [appClass]="'-smh-icon'"
                fontSet="icomoon"
                >info-tooltip</cc-icon
              >
            </span>
            <span
              *ngIf="
                (showSmhData && !shmAndLocationInfo?.serviceMeterReading) ||
                (!showSmhData && assetDetailsData?.isMaskRequired)
              "
              class="smh-data"
              >Hours Unavailable
            </span>
          </div>
          <div>
            <span class="location-data" *ngIf="showLocationData && shmAndLocationInfo?.address"
              >{{ shmAndLocationInfo?.address }}
              <cc-icon
                cc-tooltip
                class="smhLocationIcon"
                [template]="tooltipTemplateForLocation"
                [tooltipPosition]="'bottom'"
                [appClass]="'-location-icon'"
                fontSet="icomoon"
                >info-tooltip</cc-icon
              >
            </span>
            <span
              *ngIf="
                (showLocationData && !shmAndLocationInfo?.address) ||
                (!showLocationData && assetDetailsData?.isMaskRequired)
              "
              class="location-data"
              >Location Unavailable
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="userRole !== dspUserRoleConstants.Common.DSP_FACTORY_USER">
        <div class="software-update-banner-container" *ngIf="isSoftwareUpdateEligible && isRemoteFlashUpgradeVisible">
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 8C0 12.418 3.581 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0C3.581 0 0 3.582 0 8Z"
                fill="#E56C00"
              />
              <path
                d="M8 10C7.448 10 7 10.448 7 11C7 11.552 7.448 12 8 12C8.552 12 9 11.552 9 11C9 10.448 8.552 10 8 10Z"
                fill="white"
              />
              <rect x="9" y="9" width="2" height="5" rx="1" transform="rotate(180 9 9)" fill="white" />
            </svg>
          </div>
          <span class="info-icon-text"
            >Software Update Available - Access
            <span (click)="remoteServiceClick()" class="remote-service-hyperlink">Remote Service</span>
            application to initiate flash.</span
          >
        </div>
      </ng-container>
    </div>
    <div class="cc-drawer__headerAction">
      <div class="reportingState">
        <div *ngIf="assetDetailsData?.reportingState === dspWorklistConstants.SUBSCRIBED">
          <cc-icon class="reportingStateIcon subscribed">subscribed</cc-icon>
          <span class="reportingStateText">{{ dspWorklistConstants.SUBSCRIBED }}</span>
        </div>
        <div *ngIf="assetDetailsData?.reportingState === dspWorklistConstants.UNSUBSCRIBED">
          <cc-icon class="reportingStateIcon unsubscribed">unsubscribed</cc-icon>
          <span class="reportingStateText">{{ dspWorklistConstants.UNSUBSCRIBED }}</span>
        </div>
        <div *ngIf="assetDetailsData?.reportingState === dspWorklistConstants.SUSPENDED">
          <cc-icon class="reportingStateIcon suspended">suspended</cc-icon>
          <span class="reportingStateText">{{ dspWorklistConstants.SUSPENDED }}</span>
        </div>
        <div><span class="state-pipe">|</span></div>
      </div>
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content asset-drawer-content" id="asset-drawer-container" *ngIf="!customUserCheck()">
    <div class="needs-review-dismiss-container" *ngIf="enableNeedsReviewSection">
      <div class="detail-tiles">
        <span>Needs Review Category:</span>
        <span class="detail-value">{{ selectedQueueReason }}</span>
      </div>

      <span>
        <button
          *ngIf="showDismissButton"
          cc-btn-secondary
          cc-btn-large
          [setTheme]="true"
          (click)="dismissAssetFromNeedsReview()"
        >
          Dismiss from Needs Review
        </button>
      </span>
    </div>

    <div class="cc-grid assets-cards-grid">
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
        <div class="drawer-cards-column">
          <div class="drawer-cards-padding">
            <dsp-next-gen-ui-asset-details
              [assetDetails]="assetDetailsData"
              (refreshDrawerOnPID)="refreshAssetDrawer($event)"
            ></dsp-next-gen-ui-asset-details>
          </div>
          <div
            class="drawer-cards-padding"
            *ngIf="assetData?.deviceType && assetData?.deviceType !== '-' && deviceInfo1A"
          >
            <dsp-next-gen-ui-device-details
              [assetDetails]="assetDetailsData"
              [rowData]="rowData"
              [assetId]="assetId"
              (refreshDrawerOnPID)="refreshAssetDrawer($event)"
            ></dsp-next-gen-ui-device-details>
          </div>
          <div class="drawer-cards-padding drawer-cards-height">
            <dsp-next-gen-ui-asset-history [assetSubscriptionHistory]="assetSubscriptionHistory">
            </dsp-next-gen-ui-asset-history>
          </div>
        </div>
      </div>
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
        <div class="drawer-cards-column">
          <div class="drawer-cards-padding">
            <dsp-next-gen-ui-asset-ownership
              [assetOwnershipDetails]="assetDetailsData"
              (refreshSyncedData)="reloadOnSync($event)"
            ></dsp-next-gen-ui-asset-ownership>
          </div>
          <div class="drawer-cards-padding drawer-cards-height">
            <dsp-next-gen-ui-asset-plan-payment-details
              [selectedAssetDetails]="assetDetailsData"
              (closeAssetDrawer)="closeDrawer()"
              (getSubscriptionHistory)="getSubscriptionHistory()"
            ></dsp-next-gen-ui-asset-plan-payment-details>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content asset-drawer-content" id="asset-drawer-container" *ngIf="customUserCheck()">
    <div class="cc-grid dft-assets-cards-grid">
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
        <div class="drawer-cards-column">
          <div class="asset-details-section">
            <dsp-next-gen-ui-asset-details
              [assetDetails]="assetDetailsData"
              (refreshDrawerOnPID)="refreshAssetDrawer($event)"
            ></dsp-next-gen-ui-asset-details>
          </div>
          <div
            class="drawer-cards-padding"
            *ngIf="
              assetData?.deviceType &&
              assetData?.deviceType !== 'NO_DEVICE' &&
              assetData?.deviceType !== '-' &&
              deviceInfo1A
            "
          >
            <dsp-next-gen-ui-device-details
              [assetDetails]="assetDetailsData"
              [rowData]="rowData"
              [assetId]="assetId"
              (refreshDrawerOnPID)="refreshAssetDrawer($event)"
            ></dsp-next-gen-ui-device-details>
          </div>
          <div class="asset-ownership-section">
            <dsp-next-gen-ui-asset-ownership
              [assetOwnershipDetails]="assetDetailsData"
              (refreshSyncedData)="reloadOnSync($event)"
            ></dsp-next-gen-ui-asset-ownership>
          </div>
        </div>
      </div>
      <div class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--6">
        <div class="drawer-cards-column">
          <div class="asset-details-section">
            <dsp-next-gen-ui-asset-plan-payment-details
              [selectedAssetDetails]="assetDetailsData"
              (closeAssetDrawer)="closeDrawer()"
              (getSubscriptionHistory)="getSubscriptionHistory()"
            ></dsp-next-gen-ui-asset-plan-payment-details>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipTemplateForSmh>
  <div style="width: 114px; white-space: normal; color: #6e7880">Last Updated :</div>
  <div>
    {{ getServiceMeterReadingTime() }}
  </div>
</ng-template>
<ng-template #tooltipTemplateForLocation>
  <div style="width: 114px; white-space: normal; color: #6e7880">Last Updated :</div>
  <div>{{ getLocationTime() }}</div>
</ng-template>
