import { Component, Input } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-no-results-found',
  templateUrl: './no-results-found.component.html',
  styleUrls: ['./no-results-found.component.scss'],
})
export class NoResultsFoundComponent {
  @Input() noDataMessage = '';
  @Input() isDFT = false;
}
