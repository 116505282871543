import { Component, OnInit, ViewEncapsulation, Inject, HostListener } from '@angular/core';
import { CcDrawerRef, CcDrawerConfig, CC_DRAWER_DATA } from '@cat-digital-workspace/shared-ui-core/drawer';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
@Component({
  selector: 'dsp-next-gen-ui-asset-sn-info',
  templateUrl: './asset-sn-info.component.html',
  styleUrls: ['./asset-sn-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetSnInfoComponent implements OnInit {
  assetData: any;
  ecmInfo: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    public dialogRef: CcDrawerRef<AssetSnInfoComponent, CcDrawerConfig>,
    @Inject(CC_DRAWER_DATA)
    public header: {
      assetInfo: any;
    },
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.assetData = this.header.assetInfo ? this.header.assetInfo : {};
    this.getEcmData();
  }
  getEcmData() {
    const payload = { SerialNumber: this.assetData?.make + '|' + this.assetData?.serialNumber };
    this.assetDrawerService.getEcmInfo(payload).subscribe({
      next: (response: any) => {
        this.ecmInfo = response?.ecms || [];
      },
      error: (err: any) => {
        if (err && err.status != 404) {
          this.showToastMessage(this.systemError, 'error');
        }
      },
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  closeModal() {
    this.dialogRef.close();
  }
}
