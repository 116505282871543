import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import * as dspConstants from '../../.././../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-license-info',
  templateUrl: './license-info.component.html',
  styleUrls: ['./license-info.component.scss'],
})
export class LicenseInfoComponent implements OnInit {
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;
  dspStoreData!: any;
  isCatgradeHeliosSyncEnabled = false;
  dspWorklistConstants = dspConstants.Worklist;
  constructor(private assetDrawerService: AssetDrawerService, private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value ? this.value : '';
    this.config = this.columnWithHeader ? this.columnWithHeader : '';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isCatgradeHeliosSyncEnabled = this.dspStoreData?.featureFlagInfo?.isCatgradeHeliosSyncEnabled
        ? this.dspStoreData?.featureFlagInfo?.isCatgradeHeliosSyncEnabled
        : false;
    });
  }

  onClickRefresh(rowData: any) {
    const refreshGradeObj = {
      serialNumber: rowData?.ecmSerialNumber ? rowData.ecmSerialNumber : rowData.serialNumber,
      licenseRequested: rowData?.licenseRequested ? rowData.licenseRequested : rowData.currentLicensePartNum,
    };
    this.assetDrawerService.refreshLicenseDetails(refreshGradeObj).subscribe(
      (res) => {
        if (res) {
          //Call helios api from UI side when iscatgradeheliossyncEnabled is false
          if (!this.isCatgradeHeliosSyncEnabled) {
            if (res?.status.toUpperCase() === this.dspWorklistConstants.SUCCESSFUL) {
              this.getHeliosUpdatedLicenseStatus(res);
            } else {
              this.emitDataAfterRefresh(res);
            }
          } else {
            const refreshObj = {
              id: res.id,
              serialNumber: res.serialNumber ? res.serialNumber : '',
              currentLicensePartNumber: res.currentLicensePartNumber ? res.currentLicensePartNumber : '',
              currentDescription: res.currentDescription ? res.currentDescription : '',
              eligibleLicensePartNumber: res.eligibleLicensePartNumber ? res.eligibleLicensePartNumber : '',
              eligibleDescription: res.eligibleDescription ? res.eligibleDescription : '',
              status: res.status ? res.status : '',
            };
            this.assetDrawerService.isRefreshBtnClicked.next(refreshObj);
          }
        }
      },
      (err: any) => {
        // if (err.status === 404) {
        //   const refreshObj = {
        //     id: 15,
        //     serialNumber: '0433J526A2',
        //     currentLicensePartNumber: '598-2193',
        //     currentDescription: 'Cat Assist with ARO',
        //     eligibleLicensePartNumber: '540-8953',
        //     eligibleDescription: 'Cat Grade 3D with Assist - TTT',
        //     licenseStaus: 'SUCCESSFUL',
        //   };
        //   this.assetDrawerService.isRefreshBtnClicked.next(refreshObj);
        // }
      }
    );
  }
  emitDataAfterRefresh(res: any) {
    const refreshObj = {
      id: res.id,
      serialNumber: res.serialNumber ? res.serialNumber : '',
      currentLicensePartNumber: res.currentLicensePartNumber ? res.currentLicensePartNumber : '',
      currentDescription: res.currentDescription ? res.currentDescription : '',
      eligibleLicensePartNumber: res.eligibleLicensePartNumber ? res.eligibleLicensePartNumber : '',
      eligibleDescription: res.eligibleDescription ? res.eligibleDescription : '',
      status: res.status ? res.status : '',
    };
    this.assetDrawerService.isRefreshBtnClicked.next(refreshObj);
  }
  getHeliosUpdatedLicenseStatus(res: any) {
    const catGradeObj = {
      serialNumber: this.rowData && this.rowData.assetSerialNumber ? this.rowData.assetSerialNumber : '',
      make: this.rowData && this.rowData.make ? this.rowData.make : '',
    };
    this.assetDrawerService.getHeliosGradeControlResponse(catGradeObj).subscribe({
      next: (response: any) => {
        const updatedLicenseStatus = response;
        updatedLicenseStatus.map((updatedObj: any) => {
          if (updatedObj?.ecmSerialNumber === this.rowData.ecmSerialNumber) {
            const refreshObj = {
              id: updatedObj.id,
              serialNumber: updatedObj.ecmSerialNumber ? updatedObj.ecmSerialNumber : '',
              status: updatedObj.licenseStatus ? updatedObj.licenseStatus : '',
            };
            this.assetDrawerService.isRefreshBtnClicked.next(refreshObj);
          }
        });
      },
      error: (error: any) => {
        this.emitDataAfterRefresh(res);
      },
    });
  }
}
