import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { DATEANDTIME, PROMOTIONCONTRACTDATEANDTIME } from '../../../components/assets/asset-constants';
import { AssetService } from '../../../components/assets/services/asset.service';
import { CommonFilterService } from '../../../services/common-filter.service';

@Component({
  selector: 'dsp-next-gen-ui-common-date-time',
  templateUrl: './common-date-time.component.html',
  styleUrls: ['./common-date-time.component.scss'],
})
export class CommonDateTimeComponent {
  @Input() selectedFormControl: any;
  @Input() dateId: any;
  @Input() events!: Observable<any>;
  @Input() pageLabel: any;
  @Input() queueReasonId: any = '';
  @Output() dateFilterValue = new EventEmitter<string>();
  public eventsSubscription: Subscription = new Subscription();
  dayslist = DATEANDTIME;
  promotionExpiryDaysList = PROMOTIONCONTRACTDATEANDTIME;
  timeZoneOffset = new Date().getTimezoneOffset();

  isTwoPoint0 = true;
  dateFormat = 'mmm dd, yyyy';
  inputFieldConfig6: any;
  minStartEndDate: Date;
  maxStartEndDate: Date;
  minStartEndPromoContractDate: Date;
  startTime: any;
  endTime: any;
  maxEndDate: any;
  startDateVal: any;
  endDateVal: any;
  errorMsg: any;
  selectedDateOption: any;
  startDateBuffr: any;
  endDateBuffr: any;
  dateVal: any;
  consumedDate: any = [];
  dateObj: any;
  fdate: any;
  setStartDate: any;
  setEndDate: any;
  tempEndDateBuffer: any;
  disablePicker = true;

  resetButton: any;
  constructor(private assetService: AssetService, private commonFilterService: CommonFilterService) {
    this.inputFieldConfig6 = {
      disabled: false,
      labelValue: 'Select Date',
      width: '240px',
      isLeading: true,
      isTrailing: false,
      ariaDescribedBy: 'Start Date',
      required: true,
    };

    const selectedDate = new Date();
    this.startTime = '00:00:00';
    this.endTime = '23:59:59:993';
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();
    const currentDate = selectedDate.getDate();

    this.minStartEndDate = new Date(currentYear, currentMonth, currentDate);
    this.maxStartEndDate = new Date(currentYear, currentMonth, currentDate);
    this.minStartEndPromoContractDate = new Date(currentYear, currentMonth, currentDate);
  }

  ngOnInit(): void {
    this.dayslist = DATEANDTIME;
    this.promotionExpiryDaysList = PROMOTIONCONTRACTDATEANDTIME;
    this.eventsSubscription = this.events?.subscribe((data: any) => {
      if (data && data.length) {
        this.fdate = data.filter((option: any) => option.id == this.dateId);
        if (this.fdate.length && this.fdate[0].value == 'Custom Range') {
          this.selectedDateOption = 'Custom Range';
          this.resetButton = 'Custom Range';
          this.setStartDate = new Date(this.fdate[0].dateVal[0]);
          this.setEndDate = new Date(this.fdate[0].dateVal[1]);
          if (this.setEndDate) {
            this.disablePicker = false;
          }
        }
      } else {
        this.fdate = {};
      }
    });
    this.assetService.getResetFilterData().subscribe((response: any) => {
      if (response == true) {
        this.resetButton = '';
        this.setStartDate = '';
        this.setEndDate = '';
        this.disablePicker = true;
      }
    });
  }

  formatDate(value: any) {
    if (value) {
      return (
        value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2) + '-' + ('0' + value.getDate()).slice(-2)
      );
    } else {
      return;
    }
  }
  getRange(dayRange: any) {
    let today: any = new Date();
    let tdays: any = new Date();
    tdays.setDate(tdays.getDate() - dayRange);
    tdays = this.formatDate(tdays);
    this.startDateBuffr = tdays;
    if (dayRange == 1) {
      today.setDate(today.getDate() - dayRange);
      today = this.formatDate(today);
      today = today + ' ' + this.endTime;
    } else {
      today = this.formatDate(today);
      today = today + ' ' + this.endTime;
    }
    this.endDateBuffr = today;
  }
  getCustomRange(startVal: any, endDateVal: any) {
    this.dateVal = [];
    let startDateSelected = '';
    this.startDateBuffr = this.formatDate(startVal);
    this.startDateBuffr = this.startDateBuffr + ' ' + this.startTime;
    this.tempEndDateBuffer = this.formatDate(endDateVal);
    if (this.startDateBuffr === undefined || null) {
      this.startDateBuffr = this.tempEndDateBuffer;
    } else if (this.tempEndDateBuffer === undefined || null) {
      this.tempEndDateBuffer = this.startDateBuffr;
    }
    this.endDateBuffr = this.tempEndDateBuffer + ' ' + this.endTime;
    if (!startVal)
      startDateSelected = this.setStartDate ? this.formatDate(this.setStartDate) + ' ' + this.startTime : '';
    else startDateSelected = startVal ? this.formatDate(startVal) + ' ' + this.startTime : '';

    this.dateVal.push(startDateSelected, endDateVal ? this.formatDate(endDateVal) + ' ' + this.endTime : '');
    this.dateObj = {
      value: this.dateVal,
      option: this.selectedDateOption ? this.selectedDateOption : 'Custom Range',
    };
    this.dateFilterValue.emit(this.dateObj);
  }
  dateOptionClick($event: any) {
    this.selectedDateOption = $event.value;
    this.dateVal = [];
    switch (this.selectedDateOption) {
      case 'None':
        this.dateVal = [];
        break;
      case 'Today':
        this.getRange(0);
        this.dateVal.push(this.startDateBuffr);
        this.dateVal.push(this.endDateBuffr);
        break;
      case 'Yesterday':
        this.getRange(1);
        this.dateVal.push(this.startDateBuffr);
        this.dateVal.push(this.endDateBuffr);
        break;
      case 'Last 7 Days':
        this.getRange(6);
        this.dateVal.push(this.startDateBuffr);
        this.dateVal.push(this.endDateBuffr);
        break;
      case 'Last 30 Days':
        this.getRange(29);
        this.dateVal.push(this.startDateBuffr);
        this.dateVal.push(this.endDateBuffr);
        break;
      case 'Custom Range':
        this.dateVal = [];
        this.startDateVal = '';
        this.endDateVal = '';
        this.setStartDate = '';
        this.setEndDate = '';
        this.disablePicker = true;
        break;
    }
    this.resetButton = this.selectedDateOption;
    this.dateObj = {
      value: this.dateVal,
      option: this.selectedDateOption,
    };
    this.dateFilterValue.emit(this.dateObj);
  }
  onStartEndDateChange($event: any, type: string) {
    let currentYear = 0;
    let currentMonth = 0;
    let currentDate = 0;
    if (moment) {
      currentYear = moment($event).year();
      currentMonth = moment($event).month();
      currentDate = moment($event).date();
    }
    if (type === 'start') {
      this.startDateVal = new Date(currentYear, currentMonth, currentDate);
      this.minStartEndDate = this.startDateVal;
      this.setStartDate = new Date(currentYear, currentMonth, currentDate);
      this.maxEndDate = new Date(currentYear, currentMonth + 6, currentDate);
      this.endDateVal = '';
      this.setEndDate = '';
    } else {
      this.endDateVal = new Date(currentYear, currentMonth, currentDate);
      this.setEndDate = this.endDateVal;
    }
    if (this.minStartEndDate) {
      this.disablePicker = false;
    }
    if (this.startDateVal && !this.endDateVal) {
      this.endDateVal = this.startDateVal;
    }
    this.getCustomRange(this.startDateVal, this.endDateVal);
  }

  onDatepickerOpen($event: any) {}
}
