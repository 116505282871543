import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { findIndex, isEmpty, isNil } from 'lodash-es';
import { AssetService } from '../../services/asset.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-asset-details',
  templateUrl: './cat-grade-asset-details.component.html',
  styleUrls: ['./cat-grade-asset-details.component.scss'],
})
export class CatGradeAssetDetailsComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() showDealerInfo!: boolean;
  billToParty!: string;
  ownershipData: any;
  b2cCustomers!: any;
  cvaCount!: any;
  cvaStatus!: any;
  cvaUrl!: any;
  showTruncatedText = false;

  customerInfoWithoutTrim!: any;
  dealerCustomerInfoWithoutTrim!: any;
  dealer: any;
  assetFormSubscription!: any;

  constructor(
    private store: Store<DSPAppState>,
    public assetService: AssetService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.showTruncatedText = dsp?.featureFlagInfo?.showTruncatedText
        ? dsp?.featureFlagInfo?.showTruncatedText
        : false;
    });
    this.assetFormSubscription = this.assetService.assetSubscriptionFormEvent.subscribe(
      (data: AssetSubscriptionFormDataType) => {
        this.selectedAssetSubscriptionFormData = data;
        this.mapOwnershipData();
        this.billToParty = this.checkForB2CCustomers();
      }
    );
  }

  checkForB2CCustomers() {
    const ucid = this.ownershipData?.ucid || '';
    const selectedService = this.selectedAssetSubscriptionFormData.service.currentService;
    return findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', ucid]) !== -1 &&
      findIndex(this.b2cCustomers?.b2cSupportedApps, selectedService) !== -1
      ? 'Customer'
      : 'Dealer';
  }

  mapOwnershipData() {
    const customer = this.selectedAssetSubscriptionFormData?.customer;
    this.cvaCount = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaCount;
    this.cvaStatus = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaStatus;
    this.cvaUrl = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.catForesightLink;
    this.customerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.ucidName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.ucid;
    this.dealerCustomerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustNo;

    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }

  updateName(name: string, id: string) {
    const ID = isNil(id) ? '' : id;
    const NAME = isNil(name) ? '' : name;
    return `${NAME} - ${ID}`;
  }

  dealerCustomerData(id: string, name: string) {
    const ownershipData = `${isNil(name) ? '' : name} - ${isNil(id) ? '' : id}`;
    if (ownershipData && ownershipData.length > 20) {
      return `${ownershipData.substring(0, 20)}...`;
    } else {
      return ownershipData;
    }
  }
  copyEvent(event: any, dealerCustName: string, dealerCustNo: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(event, clipboardText, ':', dealerCustName, dealerCustNo, 23, 'Grade Asset Ownership');
  }
}
