<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="cc-drawer__ellipsis filters-title"> {{ title }} </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="" [ngClass]="seemoreOverlayUpdate ? 'asset-container-overlay' : 'asset-container'">
    <div
      [ngClass]="seemoreOverlayUpdate ? 'header-content-overlay asset-info-overlay' : 'header-content asset-info'"
      class=""
      [cc-tooltip]="getColumnToolTipData()"
      [tooltipPosition]="'top'"
      [isEnableTooltioOnEllipsis]="true"
    >
      <span *ngIf="!seemoreOverlayUpdate">{{ data.serialNumber }}</span
      ><span *ngIf="!seemoreOverlayUpdate">&nbsp;{{ parseAssetNameData(data) }}</span>
      <span *ngIf="seemoreOverlayUpdate">{{ getAssetSerialNumber(data) }}</span>
      <div *ngIf="seemoreOverlayUpdate" class="asset-make">{{ parseAssetNameData(data) }}</div>
    </div>
    <div class="" [ngClass]="seemoreOverlayUpdate ? 'status-content-overlay' : 'status-content'">
      <cc-icon [ngClass]="data.reportingState" style="font-size: 15px; padding-right: 15px">{{
        data.reportingState | lowercase
      }}</cc-icon>
      <span class="status-text">{{ data.reportingState }}</span>
    </div>
  </div>
  <div class="cc-drawer__content filters-drawer" *ngIf="columnData.name === 'cellularRadioType'">
    <cc-list>
      <cc-list-item class="radiotype"> {{ data.cellularRadioType }} </cc-list-item>
      <cc-list-item class="radiotype"> {{ data.satelliteRadioType }} </cc-list-item>
    </cc-list>
  </div>

  <div
    class="cc-drawer__content filters-drawer"
    *ngIf="
      isMultiProductWorkFlow === true &&
      (columnData.name === 'application' ||
        columnData.name === 'dealerSubscription' ||
        columnData.name === 'customerSubscription')
    "
  >
    <div class="expand-toggle" (click)="toggle(expandDropdown)">
      <label class="expand-label">{{ expandDropdown ? 'Collapse All' : 'Expand All' }}</label>
      <div>
        <span [class]="accordionLabel === 'Collapse All' ? 'pointer up' : 'pointer down'"></span>
      </div>
    </div>
    <cc-accordion *ngFor="let plan of subscribedPlansData">
      <div
        *ngIf="
          columnData.name === 'application' ||
          columnData.name === 'dealerSubscription' ||
          columnData.name === 'customerSubscription'
        "
      >
        <cc-expandable
          [expanded]="this.expanDropDown[plan.appName]"
          (opened)="closeService(plan.appName, 'open')"
          (closed)="closeService(plan.appName, 'close')"
        >
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ plan.appName }}</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div class="cc-drawer__content product-block">
              <cc-list>
                <cc-list-item class="plan" style="padding-bottom: 0">
                  <div
                    class="plan-header"
                    *ngIf="
                      (this.telUserType == 'CAT' && plan?.catLevel.length > 0) ||
                      (this.telUserType != 'CAT' && plan?.dealerLevel.length > 0)
                    "
                  >
                    Base Plan
                  </div>
                  <div
                    *ngFor="
                      let level of this.telUserType == 'CAT' && columnData.name === 'application'
                        ? plan?.catLevel
                        : plan?.dealerLevel
                    "
                  >
                    <div class="left-border-style" *ngIf="level.type === 'Base'">
                      <span class="planName"> {{ level.planName }} </span>
                    </div>
                  </div>
                </cc-list-item>
                <cc-list-item
                  class="plan"
                  *ngIf="
                    getOptionalPlanReturn(
                      this.telUserType == 'CAT' && columnData.name === 'application'
                        ? plan?.catLevel
                        : plan?.dealerLevel
                    )
                  "
                  style="padding-bottom: 0"
                >
                  <div class="plan-header">Optional Plan</div>
                  <div
                    *ngFor="
                      let level of this.telUserType == 'CAT' && columnData.name === 'application'
                        ? plan?.catLevel
                        : plan?.dealerLevel
                    "
                  >
                    <div class="left-border-style" *ngIf="level.type === 'Addon' || (level.planName && !level.type)">
                      <span class="planName"> {{ level.planName }} </span>
                    </div>
                  </div>
                </cc-list-item>
              </cc-list>
            </div>
          </ng-container>
        </cc-expandable>
      </div>
    </cc-accordion>
  </div>

  <div *ngIf="isMultiProductWorkFlow === false">
    <div class="cc-drawer__content filters-drawer" *ngIf="columnData.name === 'application'">
      <cc-accordion>
        <cc-expandable>
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="title-display">{{ data.applicationName }}</span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div class="cc-drawer__content product-block">
              <cc-list>
                <cc-list-item class="plan" style="padding-bottom: 0">
                  <div class="plan-header">Base Plan</div>
                  <div class="left-border-style">
                    <span class="planName"> {{ data.applicationPlan?.split('|')[0] }} </span>
                  </div>
                </cc-list-item>
                <cc-list-item class="plan" style="padding-bottom: 0">
                  <div class="plan-header">Optional Plan</div>
                  <div
                    class="left-border-style"
                    *ngFor="let subscription of data.applicationPlan?.split('|')?.slice(1, data.applicationPlan.length)"
                  >
                    <span class="planName"> {{ subscription }} </span>
                  </div>
                </cc-list-item>
              </cc-list>
            </div>
          </ng-container>
        </cc-expandable>
      </cc-accordion>
    </div>
  </div>
  <div
    class="cc-drawer__content"
    *ngIf="
      columnData.name === 'ucidName' ||
      columnData.name === 'dcnName' ||
      columnData.name === 'ucid' ||
      columnData.name === 'dealerCustomerNumber'
    "
  >
    <dsp-next-gen-ui-ownership-details [ownershipData]="data"></dsp-next-gen-ui-ownership-details>
  </div>
</div>
