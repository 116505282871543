<div class="site-name-column-align">
  <span
    *ngIf="!showTruncatedText"
    class="sites-name"
    cc-tooltip
    [template]="name_tooltip"
    [isEllipseEnabled]="true"
    [tooltipPosition]="'top'"
  >
    <span [innerHTML]="rowData.name | highlightSearch: searchString"></span>
  </span>
  <span
    *ngIf="showTruncatedText"
    class="sites-name"
    cc-tooltip
    [template]="name_tooltip"
    [isEllipseEnabled]="true"
    [tooltipPosition]="'top'"
    dspNextGenUiCopy
    [fullText]="rowData.name"
  >
    <span [innerHTML]="rowData.name | highlightSearch: searchString"></span>
  </span>
  <ng-template #name_tooltip>
    <div style="width: 175px; white-space: normal" [innerHTML]="rowData?.name"></div>
  </ng-template>
  <dsp-next-gen-ui-site-action-menus
    [siteInfo]="rowData"
    [b2cCustomers]="customData.b2cCustomers || {}"
    [mineStarDetails]="customData.mineStarDetails || {}"
    (fetchSitesCallback)="customData.fetchSites()"
  ></dsp-next-gen-ui-site-action-menus>
</div>
