<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="text"> Product | Plan </span>
    <cc-icon fontSet="icomoon" class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()"
      >cross</cc-icon
    >
  </div>

  <div class="asset-container" *ngIf="seemoreOverlayUpdate">
    <div
      class="header-content asset-info"
      [cc-tooltip]="getColumnToolTipData()"
      [tooltipPosition]="'top'"
      [isEnableTooltioOnEllipsis]="true"
    >
      <span>{{ getAssetSerialNumber(seeMoreOverlayDetails) }}</span>
      <div class="asset-make">{{ parseAssetNameData(seeMoreOverlayDetails) }}</div>
    </div>
    <div class="status-content">
      <cc-icon [ngClass]="seeMoreOverlayDetails.reportingState" style="font-size: 15px; padding-right: 15px">{{
        seeMoreOverlayDetails.reportingState | lowercase
      }}</cc-icon>
      <span class="status-text">{{ seeMoreOverlayDetails.reportingState }}</span>
    </div>
  </div>

  <div class="cc-drawer__content product-block">
    <cc-list>
      <cc-list-item class="product">
        <span class="name-title">Product</span>

        <div class="left-border-style">
          <span class="planName"> {{ applicationName }} </span>
        </div>
      </cc-list-item>
      <cc-list-item class="product">
        <span class="name-title">Base Plan</span>
        <ng-container *ngFor="let product of paymentData">
          <div *ngIf="product?.productType === 'Base Plan'" class="left-border-style">
            <span class="planName">{{ product?.name }}&nbsp;-&nbsp;{{ convertNumberFormat(product?.amount) }}</span>
          </div>
        </ng-container>
      </cc-list-item>

      <cc-list-item class="product">
        <div *ngIf="this.paymentData?.length > 1" class="cc-drawer__content addon">
          <span class="name-title">Optional Plan</span>
          <ng-container *ngFor="let product of paymentData">
            <div *ngIf="product?.productType === 'Optional Plan'" class="left-border-style">
              <span class="planName" *ngIf="product?.amount"
                >{{ product?.name }}&nbsp;-&nbsp;{{ convertNumberFormat(product?.amount) }}</span
              >
              <span class="planName" *ngIf="!product?.amount">{{ product?.name }}</span>
            </div>
          </ng-container>
        </div>
      </cc-list-item>
    </cc-list>
  </div>
</div>
