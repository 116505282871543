import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import {
  CC_DRAWER_DATA,
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  CcModal,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core';
import { isEmpty } from 'lodash-es';
import { AssetService } from '../../../components/assets/services/asset.service';
import * as _ from 'lodash';
import { CommonShowallFilterComponent } from '../common-showall-filter/common-showall-filter.component';
import { FilterCloseConfirmationComponent } from '../../../components/filter-close-confirmation/filter-close-confirmation.component';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { CommonFilterService } from '../../../services/common-filter.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { CommonConfirmationPopupComponent } from '../../../components/common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from '../../../models/popup.interface';
import * as dspConstants from '../../../shared/dspConstants';
import { HomeService } from '../../../components/home/services/home.service';

@Component({
  selector: 'dsp-next-gen-ui-common-sub-filter',
  templateUrl: './common-sub-filter.component.html',
  styleUrls: ['./common-sub-filter.component.scss'],
})
export class CommonSubFilterComponent implements OnInit {
  @ViewChild('subFilterSearchBox') globalFilterSearchBox!: ElementRef;
  headerContent: string | undefined;
  title: string | undefined;
  columnNames: any = {};
  filterData: any = {};
  searchString = '';
  accordionListColumns: any;
  subFilterData: any;
  nextCursor: any;
  isDataLoading: any;
  expandDropdown = false;
  selectedFilters: any = {};
  modalRef: any;
  SearchFilterData: any;
  dealerStringObj: any = sessionStorage.getItem('dealer');
  dealerCode = JSON.parse(this.dealerStringObj)?.dealerCode || '';
  globalSearchInput: any;
  globalSearchType: any;
  globalSortColumn: any;
  noSearchResults: any;
  dspCommonService: any;
  applyFiltersPayload: any = {};
  previousColumnNames: any;
  columnDataObject: any;
  isAccordionClosed: any = {};
  isCommonPopup = false;
  payload: any;
  isSearchFilterEnabled = false;
  private cancelUnsubscribe: Subject<void> = new Subject();
  userRole: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeAllModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      title: string;
      columnNames: object;
      subFilterData: object;
      savedFilters: any;
      choosedFilters: any;
      customData: any;
      showSearchBox: any;
      ref: any;
      subFilterClearAllClicked: any;
      pageLabel?: any;
      filterColumData: any;
      accordionListColumns: any;
      listCount?: any;
      invoiceNumber?: any;
      queueReasonId?: any;
      status?: any;
    },
    public dialogRef: CcDrawerRef<CommonSubFilterComponent, CcDrawerConfig>,
    public showAllContentRef: CcDrawerRef<CommonShowallFilterComponent, CcDrawerConfig>,
    private assetService: AssetService,
    public modal: CcModal,
    private drawerService: DrawerService,
    private commonFilterService: CommonFilterService,
    private store: Store<DSPAppState>,
    public homeService: HomeService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.userRole = dsp?.userDetails?.roleName;
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp?.featureFlagInfo?.isCommonPopup : false;
      this.isSearchFilterEnabled = dsp?.featureFlagInfo?.isSearchFilterEnabled
        ? dsp?.featureFlagInfo?.isSearchFilterEnabled
        : false;
    });
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.title = this.header.title ? this.header.title : '';
    this.columnNames = this.header.columnNames ? this.header.columnNames : {};
    this.filterData = this.header.subFilterData ? this.header.subFilterData : {};
    this.globalSearchInput = this.globalSearchInput = this.header?.customData?.searchString
      ? this.header.customData.searchString
      : '';
    this.globalSearchType = this.header.customData?.searchType ? this.header.customData.searchType : '';
    this.globalSortColumn = this.header.customData?.sortColumn ? this.header.customData.sortColumn : 'serialNumber';
    this.header.subFilterClearAllClicked = false;
    this.previousColumnNames = _.cloneDeep(this.columnNames);
    this.accordionListColumns = this.header.accordionListColumns;
    this.selectedFilters = this.header.savedFilters;
    if (!this.header.invoiceNumber) {
      this.header.invoiceNumber = '';
    }
    if (!this.header.queueReasonId) {
      this.header.queueReasonId = '';
    }
    if (!this.header.status) {
      this.header.status = '';
    }
    if (this.columnNames && Array.isArray(this.columnNames)) {
      this.columnNames?.forEach((item: any) => {
        if (this.isAccordionClosed[item.field]) {
          this.isAccordionClosed[item.field] = true;
        } else {
          this.isAccordionClosed = {
            ...this.isAccordionClosed,
            [item.field]: true,
          };
        }
      });
    }
    if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
      const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
      this.payload = {
        offset: 0,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchType: 'globalSearch',
        sortColumn: 'applicationName',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: 'applicationName',
        searchText: '',
        limit: 100,
        selectedProducts: [],
        selectedPlans:
          this.selectedFilters && this.selectedFilters['applicationPlan']
            ? this.selectedFilters['applicationPlan']
            : [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        isHomeSearch: true,
        filter: {
          ucidNameWithId: custSearchpayload,
          dcnWithName: dcnSearchpayload,
        },
      };
    } else if (
      this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
      this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
    ) {
      this.payload = {
        offset: 0,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchType: this.globalSearchType,
        sortColumn: 'applicationName',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: 'applicationName',
        searchText: '',
        limit: 100,
        selectedProducts: [],
        selectedPlans:
          this.selectedFilters && this.selectedFilters['applicationPlan']
            ? this.selectedFilters['applicationPlan']
            : [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
      };
    } else {
      this.payload = {
        offset: 0,
        globalSearchInput: this.globalSearchInput,
        searchType: this.globalSearchType,
        sortColumn: 'applicationName',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: 'applicationName',
        searchText: '',
        limit: 100,
        selectedProducts: [],
        selectedPlans:
          this.selectedFilters && this.selectedFilters['applicationPlan']
            ? this.selectedFilters['applicationPlan']
            : [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
      };
    }

    this.commonFilterService.getFilterAccordianList(this.payload).subscribe({
      next: (columnData: any) => {
        this.columnDataObject = columnData;
      },
      error: (err: any) => {
        this.nextCursor = null;
        this.isDataLoading = false;
      },
    });

    this.dialogRef?.onBackdropClick?.subscribe((result: any) => {
      this.closeAllModal();
    });
  }
  /**
   * Saves the selected filters to `header.savedFilters` and closes the drawer.
   * @return {void}
   */
  applyFilters() {
    // this.header.choosedFilters = this.selectedFilters;
    this.header.savedFilters = this.selectedFilters;
    this.columnNames.forEach((item: any) => {
      if (item.field === 'applicationPlan') {
        if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
          const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
          this.applyFiltersPayload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: 'globalSearch',
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: item?.selectedValues,
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
            isHomeSearch: true,
            filter: {
              ucidNameWithId: custSearchpayload,
              dcnWithName: dcnSearchpayload,
            },
          };
        } else if (
          this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
          this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
        ) {
          this.applyFiltersPayload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: item?.selectedValues,
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        } else {
          this.applyFiltersPayload = {
            offset: 0,
            globalSearchInput: this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: item?.selectedValues,
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        }
      }
    });
    this.setFilterColumnData('applicationName', '', this.applyFiltersPayload);

    this.dialogRef.close(this.header.savedFilters);
  }
  /**
   * Clear all selected filters and reset the filter state.
   */
  clearFilters() {
    this.selectedFilters = {};
    this.header.savedFilters = {};
    this.accordionListColumns?.forEach((item: any) => {
      if (item?.subFilters) {
        item.subFilters?.forEach((element: any) => {
          element.selectedValues = [];
        });
      }
    });
    this.columnNames.forEach((element: any) => {
      element.selectedValues = [];
    });
    this.header.subFilterClearAllClicked = true;
  }
  /**
   * closes the drawer when back arrow is clicked.
   * @return {void}
   */
  closeModal() {
    this.header.savedFilters = this.selectedFilters;
    this.header.columnNames = this.previousColumnNames;
    this.dialogRef.close({ data: this.header.columnNames });
  }
  /**
   * closes the drawer when cross icon is clicked.
   * @return {void}
   */
  closeAllModal() {
    if (JSON.stringify(this.previousColumnNames) !== JSON.stringify(this.columnNames)) {
      if (this.isCommonPopup) {
        const popupData: PopupData = {
          title: dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_TITLE,
          messageArray: [dspConstants.Common.FILTER_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
          showCrossMark: true,
          showPrimaryButton: true,
          showSecondaryButton: true,
          primaryButtonText: dspConstants.PopupButtons.APPLY_CLOSE,
          secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
        };
        this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
          width: '524px',
          type: 'semi-modal',
          data: popupData,
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === dspConstants.PopupButtons.APPLY_CLOSE) {
            this.applyFilters();
          } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
            this.closeModal();
          }
        });
      } else {
        this.modalRef = this.modal.openModal(FilterCloseConfirmationComponent, {
          width: '524px',
          type: 'semi-modal',
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });
        this.modalRef.afterClosed().subscribe((result: any) => {
          if (result === true) {
            this.applyFilters();
          } else if (result === false) {
            this.closeModal();
          }
        });
      }
    } else {
      this.closeModal();
    }
  }
  /**
   * Handle selection of a filter value.
   * Updates the selectedFilters object accordingly.
   * @param {any} event - The event object containing information about the checkbox toggle event.
   * @param {any} subfilterList - The sub-filter list object contains column names.
   * @param {any} filterValue - The value of the filter being selected.
   * @constant prop -  used to store the value of the field property from the subfilterList
   * @constant propVal - used to hold selected filter values for a specific property during the process of updating the selectedFilters object.
   * @constant activeFilter - retrieves the selected filters stored in the session storage corresponding to the page label.
   * @constant index - used to find the index of a specific filterValue within the array stored in selectedFilters[prop].
   * @constant payload -  used to store various parameters needed for fetching filter column data.
   */
  getSelectedFilter(event: any, subfilterList: any, filterValue: any) {
    const prop = subfilterList.field;
    const propVal: any = [];
    if (event.checked) {
      subfilterList.selectedValues.push(filterValue);
      if (this.selectedFilters[prop]) {
        this.selectedFilters[prop] = this.selectedFilters[prop].concat(filterValue);
      } else {
        this.selectedFilters = {
          ...this.selectedFilters,
          [prop]: propVal.concat(filterValue),
        };
      }
    } else {
      subfilterList.selectedValues.splice(subfilterList.selectedValues?.indexOf(filterValue), 1);
      const index = this.selectedFilters[prop]?.indexOf(filterValue);
      if (index !== -1 && this.selectedFilters[prop] && this.selectedFilters[prop].length > 1) {
        this.selectedFilters[prop].splice(index, 1);
      } else if (index !== -1 && this.selectedFilters[prop] && this.selectedFilters[prop].length == 1) {
        delete this.selectedFilters[prop];
      }
      if (this.columnDataObject && this.columnDataObject?.length > 0) {
        const keyToRemove = Object.keys(this.columnDataObject[0])?.filter((key) => {
          return this.columnDataObject[0][key]?.length === 1 && this.columnDataObject[0][key][0] === filterValue;
        });
        keyToRemove.forEach((item: any) => {
          if (item) {
            this.columnNames[0]?.selectedValues?.splice(this.columnNames[0]?.selectedValues?.indexOf(item), 1);
            this.selectedFilters['applicationName']?.splice(this.selectedFilters['applicationName']?.indexOf(item), 1);
          }
        });
      }
    }
    if (subfilterList.field == 'applicationName') {
      if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
        const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: 'globalSearch',
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
          isHomeSearch: true,
          filter: {
            ucidNameWithId: custSearchpayload,
            dcnWithName: dcnSearchpayload,
          },
        };
      } else if (
        this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
        this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
      ) {
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      } else {
        this.payload = {
          offset: 0,
          globalSearchInput: this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      }

      this.setFilterColumnData('applicationPlan', '', this.payload);
    } else if (subfilterList.field == 'applicationPlan') {
      if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
        const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: 'globalSearch',
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
          isHomeSearch: true,
          filter: {
            ucidNameWithId: custSearchpayload,
            dcnWithName: dcnSearchpayload,
          },
        };
      } else if (
        this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
        this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
      ) {
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      } else {
        this.payload = {
          offset: 0,
          globalSearchInput: this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      }
      this.setFilterColumnData('applicationName', '', this.payload);
    }
    // this.header.savedFilters = this.selectedFilters;
  }
  /**
   * Opens a showall drawer to display all data for a specific filter.
   * @param {string} type - 'withActions'.
   * @param {any} value - Data to display in the showall drawer.
   * @param {any} columns - Column name of the data.
   * @return {void}
   * @constant prop - used to extract the value of the field property from the columns object nested within the data property of the config object belonging to showAllContentRef.
   * @constant filterChosen - used to restore previously saved filter selections or state
   * @constant newCondition - stores the currently selectedFilters
   */
  showAllData(type: string, value: any, columns: any) {
    this.showAllContentRef = this.drawerService.openDrawer<CommonShowallFilterComponent, CcDrawerConfig>(
      CommonShowallFilterComponent,
      {
        data: {
          headerType: type,
          headerTitle: columns.label,
          columns: columns,
          showallData: value,
          selectedRadioFilters: this.selectedFilters,
          subFilter: false,
          customData: this.header.customData,
          nextCursor: this.nextCursor,
          pageLabel: this.header.pageLabel,
          invoiceNumber: this.header.invoiceNumber,
          queueReasonId: this.header.queueReasonId,
          status: '',
          columnDataObject: this.columnDataObject,
          mainFilterColumnNames: this.columnNames,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.showAllContentRef.afterClosed().subscribe((res) => {
      const prop = this.showAllContentRef.config.data.columns.field;
      const newCondition = Object.keys(this.selectedFilters).length == 0 ? {} : this.selectedFilters;
      this.selectedFilters = newCondition;
      this.setShowAllSelectionValue(prop, this.showAllContentRef.config.data.columns.selectedValues);
      this.header.savedFilters = this.selectedFilters;
      if (this.columnNames && this.header.savedFilters) {
        for (const key in this.header.savedFilters) {
          this.columnNames.forEach((item: any) => {
            if (item.field == key) {
              item.selectedValues = this.header.savedFilters[key];
            }
          });
        }
      }
      if (prop == 'applicationName') {
        if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
          const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
          this.payload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: 'globalSearch',
            sortColumn: 'applicationPlan',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationPlan',
            searchText: '',
            limit: 100,
            selectedProducts: this.selectedFilters[prop],
            selectedPlans: [],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
            isHomeSearch: true,
            filter: {
              ucidNameWithId: custSearchpayload,
              dcnWithName: dcnSearchpayload,
            },
          };
        } else if (
          this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
          this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
        ) {
          this.payload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationPlan',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationPlan',
            searchText: '',
            limit: 100,
            selectedProducts: this.selectedFilters[prop],
            selectedPlans: [],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        } else {
          this.payload = {
            offset: 0,
            globalSearchInput: this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationPlan',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationPlan',
            searchText: '',
            limit: 100,
            selectedProducts: this.selectedFilters[prop],
            selectedPlans: [],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        }

        this.setFilterColumnData('applicationPlan', '', this.payload);
      } else if (prop == 'applicationPlan') {
        if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
          const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
          this.payload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: 'globalSearch',
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: this.selectedFilters[prop],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
            isHomeSearch: true,
            filter: {
              ucidNameWithId: custSearchpayload,
              dcnWithName: dcnSearchpayload,
            },
          };
        } else if (
          this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
          this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
        ) {
          this.payload = {
            offset: 0,
            globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: this.selectedFilters[prop],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        } else {
          this.payload = {
            offset: 0,
            globalSearchInput: this.globalSearchInput,
            searchType: this.globalSearchType,
            sortColumn: 'applicationName',
            operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
            sortOrder: 'asc',
            dealerCode: this.dealerCode,
            searchColumn: 'applicationName',
            searchText: '',
            limit: 100,
            selectedProducts: [],
            selectedPlans: this.selectedFilters[prop],
            invoiceNumber: this.header.invoiceNumber,
            dealerQueueReasonId: this.header.queueReasonId,
            status: '',
            pageLabel: this.header.pageLabel,
          };
        }

        this.setFilterColumnData('applicationName', '', this.payload);
      }

      this.selectedFilters['applicationName']?.filter(
        (value: any, index: any) => this.selectedFilters['applicationName']?.indexOf(value) === index
      );
    });
  }
  /**
   * Sets the selected values for the "Show All" filter.
   * @param {any} prop - The field name for which the selected values are being set.
   * @param {any} values - The values to set for the specified field.
   */
  setShowAllSelectionValue(prop: any, values: any) {
    if (this.selectedFilters[prop]) {
      this.selectedFilters[prop] = values;
    } else {
      this.selectedFilters = {
        ...this.selectedFilters,
        [prop]: values,
      };
    }
  }
  /**
   * Load sub-filter column data based on the provided column name and selected values.
   * @param {string} columnName - The name of the column for which data is being loaded.
   * @param {any} selectedValues - The selected values for the column.
   * @constant payload - used to configure parameters for making a request to retrieve sub-filter column data
   */
  loadSubFiltercolumnData(columnName: any, selectedValues: any) {
    if (this.isAccordionClosed[columnName]) {
      this.isAccordionClosed[columnName] = false;
    } else {
      this.isAccordionClosed = {
        ...this.isAccordionClosed,
        [columnName]: false,
      };
    }
    let productArrayFilter: any;
    // this.assignToTop(columnName);
    this.accordionListColumns?.forEach((item: any) => {
      if (item?.subFilters) {
        item.subFilters?.forEach((element: any) => {
          if (element.field == 'applicationName' && element.selectedValues?.length) {
            productArrayFilter = element.selectedValues;
          }
        });
      }
    });
    if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
      const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
      this.payload = {
        offset: 0,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchType: 'globalSearch',
        sortColumn: columnName,
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: columnName,
        searchText: '',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        limit: 100,
        displayColumns: [],
        selectedProducts:
          columnName === 'applicationPlan' && productArrayFilter && productArrayFilter.length
            ? productArrayFilter
            : columnName == 'applicationPlan' &&
                this.selectedFilters['applicationName'] &&
                this.selectedFilters['applicationName'].length > 0
              ? this.selectedFilters['applicationName']
              : [],
        selectedPlans: [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
        isHomeSearch: true,
        filter: {
          ucidNameWithId: custSearchpayload,
          dcnWithName: dcnSearchpayload,
        },
      };
    } else if (
      this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
      this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
    ) {
      this.payload = {
        offset: 0,
        globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
        searchType: this.globalSearchType,
        sortColumn: columnName,
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: columnName,
        searchText: '',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        limit: 100,
        displayColumns: [],
        selectedProducts:
          columnName === 'applicationPlan' && productArrayFilter && productArrayFilter.length
            ? productArrayFilter
            : columnName == 'applicationPlan' &&
                this.selectedFilters['applicationName'] &&
                this.selectedFilters['applicationName'].length > 0
              ? this.selectedFilters['applicationName']
              : [],
        selectedPlans: [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
      };
    } else {
      this.payload = {
        offset: 0,
        globalSearchInput: this.globalSearchInput,
        searchType: this.globalSearchType,
        sortColumn: columnName,
        sortOrder: 'asc',
        dealerCode: this.dealerCode,
        searchColumn: columnName,
        searchText: '',
        operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
        limit: 100,
        displayColumns: [],
        selectedProducts:
          columnName === 'applicationPlan' && productArrayFilter && productArrayFilter.length
            ? productArrayFilter
            : columnName == 'applicationPlan' &&
                this.selectedFilters['applicationName'] &&
                this.selectedFilters['applicationName'].length > 0
              ? this.selectedFilters['applicationName']
              : [],
        selectedPlans: [],
        invoiceNumber: this.header.invoiceNumber,
        dealerQueueReasonId: this.header.queueReasonId,
        status: '',
        pageLabel: this.header.pageLabel,
      };
    }
    this.setFilterColumnData(columnName, '', this.payload);
  }
  assignToTop(columnName: any) {
    if (
      this.subFilterData &&
      this.subFilterData[columnName] &&
      this.subFilterData[columnName]?.length >= 1 &&
      this.selectedFilters &&
      this.selectedFilters[columnName]?.length >= 1
    ) {
      this.subFilterData[columnName][0].sort(
        (a: any, b: any) => this.selectedFilters[columnName].indexOf(b) - this.selectedFilters[columnName].indexOf(a)
      );
    }
  }

  /**
   * Fetches filter column data based on the provided column name, search text, and payload.
   * @param {any} columnName - The name of the column for which filter data is fetched.
   * @param {any} _searchText - The search text used to filter data.
   * @param {any} payload - Payload containing parameters for fetching filter data.
   * @returns {any} Returns the fetched filter column data.
   */
  setFilterColumnData(columnName: any, _searchText: any, payload: any) {
    this.isDataLoading = true;
    if (this.header?.filterColumData?.columnName?.length > 0) {
      this.header.filterColumData[columnName] = [];
    }
    this.commonFilterService.getFilterAccordianList(payload).subscribe({
      // this.assetService.getAssetFilterColumnData(payload).subscribe({
      next: (columnData: any) => {
        if (this.header?.filterColumData?.hasOwnProperty(columnName) == false) {
          this.header.filterColumData[columnName] = [];
        }
        if (columnName != 'applicationPlan' && payload?.selectedPlans?.length === 0) {
          if (!isEmpty(columnData)) {
            this.nextCursor = columnData[0]?.next;
            let columnValues = columnData[0][columnName];
            //Commented below regex as the filter data 'My.Cat.Com' are removed from this regex
            // const regex3 = new RegExp('^(?!(\\s|-))[-a-zA-Z0-9\\s]*[^\\s-]$');
            columnValues = columnValues?.filter((item: string) => !isEmpty(item?.trim()));
            this.header.filterColumData[columnName] = [...[columnValues]];
            this.subFilterData = this.header.filterColumData;
          }
        } else if (columnName != 'applicationPlan' && payload?.selectedPlans?.length > 0) {
          this.columnDataObject = columnData;
          Object.keys(this.columnDataObject[0]).forEach((key) => {
            if (!this.columnNames[0].selectedValues.includes(key)) {
              this.columnNames[0].selectedValues.push(key);
            }
          });
        } else {
          this.nextCursor = columnData[0]?.next;
          let arr: any = [];
          columnData.forEach((eachObj: any) => {
            for (const key in eachObj as any) {
              if (key && key != 'next') {
                arr = [...arr, ...eachObj[key]];
              }
            }
          });
          const columnValues = arr;
          this.header.filterColumData[columnName] = [...[columnValues]];
          this.subFilterData = this.header.filterColumData;
          this.isDataLoading = false;
        }
        if (columnName === 'applicationPlan') {
          const index = this.columnNames.findIndex((obj: any) => obj.field === 'applicationPlan');
          const setSubFilterPlanData = new Set(this.subFilterData[columnName][0]);
          const filteredArray = this.columnNames[index].selectedValues.filter((value: any) =>
            setSubFilterPlanData.has(value)
          );
          this.columnNames[index].selectedValues.length = 0;
          this.columnNames[index].selectedValues.push(...filteredArray);
        }
        if (columnData.length == 0) {
          this.noSearchResults = true;
        }
        this.isDataLoading = false;
        // this.assignToTop(columnName);

        return this.subFilterData;
      },
      error: (err: any) => {
        this.nextCursor = null;
        this.isDataLoading = false;
      },
    });
    this.columnNames[0].selectedValues = this.columnNames[0].selectedValues.filter(
      (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      }
    );
  }

  accordionClosed(columnName: any) {
    if (this.isAccordionClosed[columnName]) {
      this.isAccordionClosed[columnName] = true;
    } else {
      this.isAccordionClosed = {
        ...this.isAccordionClosed,
        [columnName]: true,
      };
    }
  }

  onDeleteChip($event: any, subfilterList: any, filterValue: any) {
    const prop = subfilterList?.field;
    subfilterList?.selectedValues?.splice(subfilterList?.selectedValues?.indexOf(filterValue), 1);
    const index = this.selectedFilters[prop]?.indexOf(filterValue);
    if (index !== -1 && this.selectedFilters[prop] && this.selectedFilters[prop].length > 1) {
      this.selectedFilters[prop].splice(index, 1);
    } else if (index !== -1 && this.selectedFilters[prop] && this.selectedFilters[prop].length == 1) {
      delete this.selectedFilters[prop];
    }
    if (this.columnDataObject && this.columnDataObject?.length > 0) {
      const keyToRemove = Object.keys(this.columnDataObject[0])?.filter((key) => {
        return this.columnDataObject[0][key]?.length === 1 && this.columnDataObject[0][key][0] === filterValue;
      });
      keyToRemove.forEach((item: any) => {
        if (item) {
          this.columnNames[0]?.selectedValues?.splice(this.columnNames[0]?.selectedValues?.indexOf(item), 1);
          this.selectedFilters['applicationName']?.splice(this.selectedFilters['applicationName']?.indexOf(item), 1);
        }
      });
    }
    if (subfilterList?.field == 'applicationName') {
      if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
        const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: 'globalSearch',
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
          isHomeSearch: true,
          filter: {
            ucidNameWithId: custSearchpayload,
            dcnWithName: dcnSearchpayload,
          },
        };
      } else if (
        this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
        this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
      ) {
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      } else {
        this.payload = {
          offset: 0,
          globalSearchInput: this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationPlan',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationPlan',
          searchText: '',
          limit: 100,
          selectedProducts: subfilterList.selectedValues,
          selectedPlans: [],
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      }
      this.setFilterColumnData('applicationPlan', '', this.payload);
    } else if (subfilterList?.field == 'applicationPlan') {
      if (sessionStorage.getItem('fromPage') === dspConstants.Worklist.HOME) {
        const [custSearchpayload, dcnSearchpayload] = this.commonFilterService.homePageFilterPayload();
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: 'globalSearch',
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
          isHomeSearch: true,
          filter: {
            ucidNameWithId: custSearchpayload,
            dcnWithName: dcnSearchpayload,
          },
        };
      } else if (
        this.userRole === dspConstants.Common.DSP_CUSTOM_ACCESS ||
        this.userRole === dspConstants.Common.DSP_ADMINISTRATOR
      ) {
        this.payload = {
          offset: 0,
          globalSearchInput: this.isSearchFilterEnabled ? '' : this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      } else {
        this.payload = {
          offset: 0,
          globalSearchInput: this.globalSearchInput,
          searchType: this.globalSearchType,
          sortColumn: 'applicationName',
          operation: this.searchString?.length > 2 ? 'searchFilter' : 'filter',
          sortOrder: 'asc',
          dealerCode: this.dealerCode,
          searchColumn: 'applicationName',
          searchText: '',
          limit: 100,
          selectedProducts: [],
          selectedPlans: subfilterList.selectedValues,
          invoiceNumber: this.header.invoiceNumber,
          dealerQueueReasonId: this.header.queueReasonId,
          status: '',
          pageLabel: this.header.pageLabel,
        };
      }

      this.setFilterColumnData('applicationName', '', this.payload);
    }
  }

  noDataAvailableCondition(subfilterList: any) {
    if (subfilterList.field === 'applicationName') {
      return (
        (!this.isDataLoading &&
          this.subFilterData &&
          this.subFilterData[subfilterList.field] &&
          (this.subFilterData[subfilterList.field].length == 0 ||
            (this.subFilterData[subfilterList.field].length > 0 &&
              this.subFilterData[subfilterList.field][0]?.length === 0))) ||
        (!this.isDataLoading && !this.subFilterData)
      );
    } else if (subfilterList.field === 'applicationPlan') {
      return (
        (!this.isDataLoading &&
          this.subFilterData &&
          this.subFilterData[subfilterList?.field] &&
          (this.subFilterData[subfilterList.field].length == 0 ||
            (this.subFilterData[subfilterList.field].length > 0 &&
              this.subFilterData[subfilterList.field][0]?.length === 0))) ||
        (!this.isDataLoading && !this.subFilterData)
      );
    }
  }
}
