<div class="cc-modal">
  <div class="cc-modal-header">
    <div class="cc-modal-title">{{ data.title }}</div>
    <div *ngIf="data.showCrossMark" class="cc-modal-close" (click)="closeModal(dspConstants.PopupButtons.CROSS)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 9L9 1"
          stroke="#43485C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M9 9L1 1"
          stroke="#43485C"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>
  </div>
  <div class="cc-modal-content">
    <ng-container *ngFor="let message of data.messageArray">
      <p class="modalMessage">{{ message }}</p>
    </ng-container>
  </div>
  <div class="cc-modal-footer modalfooterStyle">
    <button
      *ngIf="data.showSecondaryButton"
      [setTheme]="true"
      cc-btn-secondary-outline
      (click)="closeModal(data.secondaryButtonText)"
    >
      {{ data.secondaryButtonText }}
    </button>
    <button
      *ngIf="data.showPrimaryButton"
      [setTheme]="true"
      cc-btn-primary
      (click)="closeModal(data.primaryButtonText)"
    >
      {{ data.primaryButtonText }}
    </button>
    <button *ngIf="data.showDangerButton" cc-btn-danger [setTheme]="true" (click)="closeModal(data.dangerButtonText)">
      {{ data.dangerButtonText }}
    </button>
  </div>
</div>
