import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dsp-next-gen-ui-account-prefix',
  templateUrl: './account-prefix.component.html',
  styleUrls: ['./account-prefix.component.scss']
})
export class AccountPrefixComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
  }
}
