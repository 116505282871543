<div class="cc-drawer accounts-drawer">
  <div class="cc-drawer__header">
    <span class="cc-headerText edit-plan-header cc-drawer__ellipsis"> {{ editPlanConfig?.headerTitle }} </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div
    *ngIf="isDataAvailable"
    class="cc-drawer__content"
    id="edit-plan-container"
    [ngClass]="
      editPlanConfig?.headerTitle !== this.subscribeAccountsConst.VIEW_HEADER ? 'view-drawer' : 'edit-cancel-drawer'
    "
  >
    <dsp-next-gen-ui-account-product-plan-info
      [accountData]="rowData"
      [planConfig]="editPlanConfig"
    ></dsp-next-gen-ui-account-product-plan-info>
    <form
      [formGroup]="accountDetailsForm"
      id="account-details-container"
      *ngIf="editPlanConfig?.actionType === this.subscribeAccountsConst.OPERATIONTYPE_UPDATE"
    >
      <div class="cc-grid customer-field">
        <div
          *ngIf="editPlanConfig?.accountDetails?.type"
          class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12 styleDropDown"
        >
          <div class="typeLabel">Type</div>
          <div style="width: 345 px; padding-top: 5px">
            <cc-dropdown
              [isTheme]="'2.0'"
              [id]="'single-dropdown'"
              [label]="'Select'"
              [dataSource]="finalLicenseType"
              [placementPosition]="'bottom'"
              formControlName="licenseType"
              (selectionChange)="licenseTypeDropdownChange($event, changeLicenseType)"
              [scrollToSelected]="true"
            >
            </cc-dropdown>
          </div>
        </div>
        <div
          *ngIf="editPlanConfig?.accountDetails?.expirationDate"
          class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--12"
        >
          <div class="search-users-header">SELECT END DATE</div>
          <div class="create-site-label subtitle-label">Expiration Date</div>
          <div class="pickerCls">
            <cc-datepicker
              [isTheme]="isTwoPoint0"
              [dateFormat]="dateFormat"
              [inputFieldConfig]="inputFieldConfig6"
              [min]="minStartEndDate"
              formControlName="expirydate"
              (dateChange)="onDateChange($event)"
              [isDense]="true"
            ></cc-datepicker>
          </div>
        </div>

        <div
          *ngIf="editPlanConfig?.accountDetails?.saleReason"
          class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12"
        >
          <div class="search-users-header">SELECT SALE REASON</div>
          <div class="create-site-label subtitle-label">Sale Reason</div>
          <div>
            <cc-dropdown
              [isTheme]="'2.0'"
              [id]="'product-dropDown'"
              [label]="''"
              [dataSource]="salesData"
              [placementPosition]="'bottom'"
              formControlName="saleReason"
              [typeahead]="true"
              [searchHelpText]="'Couldn´t find any product'"
              [typeaheadmanualinput]="true"
              [scrollToSelected]="true"
              [search]="true"
              (selectionChange)="selctReasonChange($event)"
            >
            </cc-dropdown>
          </div>
        </div>
        <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--12"></div>
        <div *ngIf="enableCVAfield" class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12">
          <div class="create-site-label-required">CVA Number</div>
          <cc-input-field
            id="site_desc_id"
            [disabled]="false"
            [labelValue]="'Enter CVA Number'"
            [inputtype]="'text'"
            [value]="cva"
            [isTableInlineEdit]="true"
            [maxlength]="25"
            [isTheme]="isTheme"
          >
            <input
              input-field
              formControlName="cva"
              [(ngModel)]="cva"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter any value"
              aria-describedby="Enter any value"
              [maxlength]="25"
              (valueChange)="onChangeCVA($event)"
              dspNextGenUiNumericOnly
            />
            <span class="cc-inline-input-error-icon" cc-tooltip="Type Only Numbers">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
                  fill="#DF3826"
                />
              </svg>
            </span>
          </cc-input-field>
          <span class="req-text">
            <ng-container *ngIf="formControl['cva']?.touched">
              <span *ngIf="formControl['cva']?.errors?.['required']">CVA Number cannot be empty</span>
            </ng-container>
          </span>
        </div>

        <div *ngIf="selectReasonField" class="cc-col cc-lg-col--4 cc-md-col--4 cc-xl-col--4 cc-sm-col--4 cc-xs-col--12">
          <cc-input-field
            id="site_desc_id"
            [disabled]="false"
            [labelValue]="'Enter Your Sale Reason'"
            [inputtype]="'text'"
            [isTableInlineEdit]="true"
            [maxlength]="250"
            [isTheme]="'2.0'"
            [value]="saleReasonDescription"
          >
            <input
              input-field
              formControlName="saleReasonField"
              [(ngModel)]="saleReasonDescription"
              class="input-field"
              autocomplete="off"
              type="text"
              aria-labelledby="Enter any value"
              aria-describedby="Enter any value"
              [maxlength]="250"
              (valueChange)="onChangeOtherDesc($event)"
            />
          </cc-input-field>
        </div>
      </div>
    </form>
    <div class="edit-plan">
      <dsp-next-gen-ui-search-cws
        *ngIf="editPlanConfig.searchCws"
        [selectedData]="selectedData"
        class="edit-plan"
        [parentType]="'edit-plan'"
      ></dsp-next-gen-ui-search-cws>
      <dsp-next-gen-ui-subscription-note
        *ngIf="editPlanConfig.subscriptionNote"
        [planType]="selectedData.plan"
        class="styleDropDown"
      >
      </dsp-next-gen-ui-subscription-note>
    </div>
    <div
      class="cc-grid cancel-plan"
      *ngIf="
        editPlanConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_CANCEL ||
        editPlanConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL
      "
    >
      <div class="cc-col cc-lg-col--12 cc-md-col--12 cc-xl-col--12 cc-sm-col--12 cc-xs-col--12">
        <div class="details-title">
          <span>SELECT CANCELLATION REASON</span>
        </div>
      </div>
      <div class="cc-col cc-lg-col--5 cc-md-col--5 cc-xl-col--5 cc-sm-col--5 cc-xs-col--5 cancel-dropdown">
        <div class="cancel-dropdown-label">Cancellation Reason</div>
        <cc-dropdown
          [id]="'dropdown-placement'"
          name="checkbox"
          [label]="'Select'"
          [typeahead]="true"
          [dataSource]="cancelReasons"
          [scrollToSelected]="true"
          [appOverlayClass]="'app-overlay-class'"
          [isTheme]="'2.0'"
          (selectionChange)="onDropdownChange($event)"
        ></cc-dropdown>
      </div>
    </div>
    <div
      class="accord-info"
      *ngIf="
        editPlanConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER ||
        editPlanConfig?.actionType === subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_CUSTOMER
      "
    >
      <div class="view-accounts-table">
        <span class="view-accounts-key">Start Date</span
        ><span class="view-accounts-value">{{ parseDate(rowData?.startDate) }}</span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Expiration Date</span
        ><span class="view-accounts-value">{{ parseDate(rowData?.endDate) }}</span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Term</span
        ><span *ngIf="rowData.store === 'DSP'" class="view-accounts-value">{{ 'N/A' }}</span>
        <span *ngIf="rowData.store !== 'DSP'" class="view-accounts-value">{{ rowData.subscriptionTerm || '-' }}</span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Auto Renew</span>
        <span *ngIf="rowData.store === 'DSP'" class="view-accounts-value">{{ 'N/A' }}</span>
        <span *ngIf="rowData.store !== 'DSP' && !rowData?.isAutoRenewed" class="view-accounts-value">{{ 'OFF' }}</span>
        <span *ngIf="rowData.store !== 'DSP' && rowData?.isAutoRenewed" class="view-accounts-value">{{ 'ON' }} </span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Store</span>
        <span *ngIf="rowData.store === 'DSP'" class="view-accounts-value">{{ 'DSP' }} </span>
        <span *ngIf="rowData.store !== 'DSP'" class="view-accounts-value">{{ rowData?.store || '-' }} </span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Sale Reason</span
        ><span class="view-accounts-value">{{ rowData.salesReason || '-' }} </span>
      </div>
      <div class="view-accounts-table">
        <span class="view-accounts-key">Status</span
        ><span class="view-accounts-value" *ngIf="!rowData?.isActive">{{ 'InActive' }}</span>
        <span class="view-accounts-value" *ngIf="rowData?.isActive">{{ 'Active' }} </span>
      </div>
    </div>
  </div>

  <div
    class="cc-drawer__footer"
    *ngIf="
      editPlanConfig?.actionType !== subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER &&
      editPlanConfig?.actionType !== subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_CUSTOMER
    "
  >
    <button id="cc-drawer-cancelBtn" cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closeDrawer()">
      Cancel
    </button>

    <button
      id="cc-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="!enableEditActionButton()"
      (click)="editAccount(removeUsersWarningTemplate, cancelPlanModalTemplate)"
    >
      <!-- [disabled] logic needs to be revisited (enableSubmit() function) -->
      Submit
    </button>
  </div>
</div>

<ng-template #licenseChangeTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ 'Confirmation' }}</div>
    </div>

    <div class="cc-modal-content group-info__content" *ngIf="selectedLicenseType === 'Limited Users'">
      <p>Requires Technician Toolbox 2.5 version or higher installed in end user computer.</p>
      <p>Converting to Limited Users will cancel the current subscription. Do you want to continue?</p>
    </div>

    <div class="cc-modal-content group-info__content" *ngIf="selectedLicenseType === 'Unlimited Users'">
      <p>Converting to Unlimited Users will result in removal of the existing user list. Do you want to continue?</p>
    </div>

    <div class="cc-modal-footer group-info__footer">
      <button
        cc-btn-secondary
        cc-btn-large
        [setTheme]="true"
        class="cancel button-opacity"
        (click)="closeLicenseChangeModal()"
      >
        Cancel
      </button>

      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        class="delete button-opacity"
        (click)="updateLicenseTypeToUnlimited()"
        *ngIf="selectedLicenseType === 'Unlimited Users'"
      >
        Submit
      </button>
      <button
        cc-btn-primary
        cc-btn-large
        [setTheme]="true"
        class="delete button-opacity"
        (click)="updateLicenseTypeToLimited()"
        *ngIf="selectedLicenseType === 'Limited Users'"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
<ng-template #removeUsersWarningTemplate>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirm</div>
    </div>

    <div class="cc-modal-content group-info__content">
      <p>Are you sure that you want to remove the selected users from the plan?</p>
    </div>

    <div class="cc-modal-footer group-info__footer">
      <button
        cc-btn-secondary
        cc-btn-large
        [setTheme]="true"
        class="cancel button-opacity"
        (click)="closeRemoveUsersWarningModal(false)"
      >
        Cancel
      </button>

      <button
        cc-btn-danger
        cc-btn-large
        [setTheme]="true"
        class="delete button-opacity"
        (click)="closeRemoveUsersWarningModal(true)"
      >
        Delete
      </button>
    </div>
  </div>
</ng-template>
<ng-template #cancelTemplateRef>
  <div class="cc-modal group-info__modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">Confirmation</div>
    </div>

    <div class="cc-modal-content group-info__content modal-content">
      <p>Are you sure that you want to cancel this plan?</p>
    </div>

    <div class="cc-modal-footer group-info__footer">
      <button cc-btn-secondary cc-btn-large [setTheme]="true" (click)="closePlanCancelModal()">Cancel</button>
      <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="cancelSubscription()">Submit</button>
    </div>
  </div>
</ng-template>
