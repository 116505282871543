import { createReducer, on } from '@ngrx/store';
import { CancellationReasonFilter } from '../../entities/entity';
import { setSavedCancellationReason } from '../../actions/dashboard-needs-review.actions';

export interface CancellationReasonFilterState {
  cancellationReasonDashboardFilter: CancellationReasonFilter[];
}
export const initialCRState: CancellationReasonFilterState = {
  cancellationReasonDashboardFilter: [
    {
      id: 0,
      label: '',
      navigateTo: '',
      scopeParams: {
        filterValue: '',
        filterBy: '',
      },
      value: '',
    },
  ],
};

export const CancellationReasonDashFilterReducer = createReducer(
  initialCRState,
  on(setSavedCancellationReason, (state, action): CancellationReasonFilterState => {
    return { ...state, cancellationReasonDashboardFilter: action.cancelReason };
  })
);
