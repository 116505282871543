import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';
import { authCodeFlowConfig } from './auth-config';

import { MSALInstanceFactory, configPreference } from './auth-config';
import { MSAL_INSTANCE } from '@azure/msal-angular';
import { AppConfigService } from '../services/app-config.service';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [OAuthModule.forRoot()],
  providers: [
    AuthService,
    { provide: AuthConfig, useValue: authCodeFlowConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: AppConfigService, useFactory: configPreference },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initialAuthService: AuthService) => () => initialAuthService.initAuth(),
      deps: [AuthService],
      multi: true,
    },

    { provide: AppConfigService, useFactory: configPreference },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
  ],
})
export class AuthModule {}
