import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DspUtilsCommonService } from './dsp-utils-common.service';
import { Subject } from 'rxjs';
import { HomeService } from '../components/home/services/home.service';
import { isEmpty } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class CommonFilterService {
  public isFilterSavedEmptySubject = new Subject<string>();
  rootURL: any;

  constructor(
    private http: HttpClient,
    private dspUtilsCommonService: DspUtilsCommonService,
    public homeService: HomeService
  ) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getFilterColumn(pageLabel: any) {
    const payload = 'pageLabel=' + pageLabel;
    return this.http.get(this.rootURL + '/filters/columnNames?' + payload);
  }
  getFilterAccordianList(payload: any) {
    return this.http.post(this.rootURL + '/filters/columnValues?', payload);
  }
  setItemFromSessionStorage(
    key: any,
    value: any,
    getItem: any,
    pageLabel?: any,
    invoiceNumber?: any,
    queueReason?: any
  ) {
    const getSessionStorage = sessionStorage.getItem(getItem);
    const filterDetails = getSessionStorage ? JSON.parse(getSessionStorage) : {};
    if (pageLabel == 'billing' && invoiceNumber) {
      let invoiceNumberDetails = filterDetails[key] ? filterDetails[key] : {};
      invoiceNumberDetails[invoiceNumber] = value;
      filterDetails[key] = invoiceNumberDetails;
    } else if (pageLabel == 'needsReview' && queueReason) {
      let queueReasonId = filterDetails[key] ? filterDetails[key] : {};
      queueReasonId[queueReason] = value;
      filterDetails[key] = queueReasonId;
    } else {
      filterDetails[key] = value;
    }

    sessionStorage.setItem(getItem, JSON.stringify(filterDetails));
  }
  getItemFromSessionStorage(key: any, getItem: any, pageLabel?: any, invoiceNumber?: any, queueReason?: any) {
    const getSessionStorage = sessionStorage.getItem(getItem);
    const filterDetails = getSessionStorage ? JSON.parse(getSessionStorage) : {};
    if (!filterDetails[key] && getItem == 'selectedFIlters') {
      return {};
    } else {
      if (filterDetails && filterDetails[key] && pageLabel == 'billing' && invoiceNumber) {
        if (filterDetails[key][invoiceNumber]) {
          return filterDetails[key][invoiceNumber];
        } else {
          return {};
        }
      } else if (filterDetails && filterDetails[key] && pageLabel == 'needsReview' && queueReason) {
        if (filterDetails[key][queueReason]) {
          return filterDetails[key][queueReason];
        } else {
          return {};
        }
      } else {
        return filterDetails[key];
      }
    }
  }
  removeItemFromSessionStorage(key: any, getItem: any, pageLabel?: any, invoiceNumber?: any, queueReason?: any) {
    const getSessionStorage = sessionStorage.getItem(getItem);
    const filterDetails = getSessionStorage ? JSON.parse(getSessionStorage) : {};

    if (pageLabel == 'billing') {
      if (filterDetails[key] && filterDetails[key][invoiceNumber]) {
        delete filterDetails[key][invoiceNumber];
      }
    } else if (pageLabel == 'needsReview') {
      if (filterDetails[key] && filterDetails[key][queueReason]) {
        delete filterDetails[key][queueReason];
      }
    } else {
      delete filterDetails[key];
    }
    sessionStorage.setItem(getItem, JSON.stringify(filterDetails));
    if (Object.keys(filterDetails).length == 0) {
      sessionStorage.removeItem(getItem);
    }
  }

  homePageFilterPayload() {
    const data = this.homeService.getServiceData();
    const ucid = this.getCustomerData(data);
    const dealerCust = this.getDealerCustomerData(data);
    const custSearchpayload = [];
    const dcnSearchpayload = [];
    if (!isEmpty(ucid)) {
      custSearchpayload.push(ucid);
    }
    if (!isEmpty(dealerCust)) {
      dcnSearchpayload.push(dealerCust);
    }
    return [custSearchpayload, dcnSearchpayload];
  }
  getCustomerData(item: any) {
    if (!isEmpty(item?.ucidName) && !isEmpty(item?.ucid)) {
      return item.ucidName + ' - ' + item.ucid;
    } else if (!isEmpty(item?.ucid)) {
      return item.ucid;
    } else if (!isEmpty(item?.ucidName)) {
      return item.ucidName;
    }
  }
  getDealerCustomerData(item: any) {
    if (!isEmpty(item?.dcnName) && !isEmpty(item?.dealerCustomerNumber)) {
      return item.dcnName + ' - ' + item.dealerCustomerNumber;
    } else if (!isEmpty(item?.dcnName)) {
      return item.dcnName;
    } else if (!isEmpty(item?.dealerCustomerNumber)) {
      return item.dealerCustomerNumber;
    }
  }
}
