import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssetService } from '../../services/asset.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as dspConstants from '../../../../shared/dspConstants';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { find } from 'lodash-es';
@Component({
  selector: 'dsp-next-gen-ui-asset-cat-billing',
  templateUrl: './asset-cat-billing.component.html',
  styleUrls: ['./asset-cat-billing.component.scss'],
})
export class AssetCatBillingComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() dynamicTrackerData: any;
  @Input() currentPageIndex = 0;
  @Output() updateTrackerIndex = new EventEmitter();
  @Input() hideBillToPartyOwnership!: boolean;
  billingSubscriptionForm!: FormGroup;
  accountArray: any = [];
  accountNames: any = [];
  accountNumbers: any = [];
  accountContacts: any = [];
  billingAccounts: any = [];
  systemError = dspConstants.Common.SYSTEM_ERROR;

  constructor(
    private assetService: AssetService,
    private formBuilder: FormBuilder,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.billingAccounts = dsp.billingAccounts?.accountDetails || [];
      this.setAccounts();
    });
    this.billingSubscriptionForm = this.formBuilder.group({
      selectedAccountName: [''],
      selectedAccountNumber: [''],
      selectedAccountContact: [''],
    });
    const { billingGroupId } = this.selectedAssetSubscriptionFormData.catBillingData;
    if (billingGroupId !== '') {
      this.setSelectedFormData(billingGroupId);
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
    this.setAssetFormData(this.selectedAssetSubscriptionFormData);
  }

  setSelectedFormData(billingGroupId: string | number) {
    const selectedBillingGroup = find(this.billingAccounts, ['billingGroupId', billingGroupId]);
    if (selectedBillingGroup) {
      this.billingSubscriptionForm.controls['selectedAccountName'].setValue(
        selectedBillingGroup?.accountDescription || ''
      );
      this.billingSubscriptionForm.controls['selectedAccountNumber'].setValue(
        selectedBillingGroup?.accountNumber || ''
      );
      this.billingSubscriptionForm.controls['selectedAccountContact'].setValue(selectedBillingGroup?.adminId || '');
      this.selectedAssetSubscriptionFormData.isValidForm = true;
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
    }
  }

  setAccounts() {
    const { billingAccounts } = this;
    if (billingAccounts?.length > 0) {
      this.accountArray = billingAccounts;
      this.accountNames = billingAccounts.map((data: any) => ({
        label: data.accountDescription,
        value: data.accountDescription,
        billingGroupId: data.billingGroupId,
      }));
      this.accountNumbers = billingAccounts.map((data: any) => ({
        label: data.accountNumber,
        value: data.accountNumber,
        billingGroupId: data.billingGroupId,
      }));
      this.accountContacts = billingAccounts.map((data: any) => ({
        label: data.adminId,
        value: data.adminId,
        billingGroupId: data.billingGroupId,
      }));
    }
  }

  onBillingDropdownChange(event: any, type: any) {
    let selectedIndex: any;
    if (type == 'accountName') {
      selectedIndex = this.accountArray
        .map((val: any) => val.accountDescription)
        .indexOf(event.currentSelection[0].label);
    }
    if (type == 'accountNumber') {
      selectedIndex = this.accountArray.map((val: any) => val.accountNumber).indexOf(event.currentSelection[0].label);
    }
    if (type == 'accountContact') {
      selectedIndex = this.accountArray.map((val: any) => val.adminId).indexOf(event.currentSelection[0].label);
    }
    this.billingSubscriptionForm.controls['selectedAccountName'].setValue(this.accountNames[selectedIndex].label);
    this.billingSubscriptionForm.controls['selectedAccountNumber'].setValue(this.accountNumbers[selectedIndex].label);
    this.billingSubscriptionForm.controls['selectedAccountContact'].setValue(this.accountContacts[selectedIndex].label);
    this.selectedAssetSubscriptionFormData.catBillingData.billingGroupId = event.currentSelection[0].billingGroupId;
    this.selectedAssetSubscriptionFormData.isValidForm = true;
    this.setAssetFormData(this.selectedAssetSubscriptionFormData);
    if (this.currentPageIndex === this.dynamicTrackerData?.length - 1) {
      this.updateTrackerIndex.emit();
    }
  }

  setAssetFormData(assetFormData: AssetSubscriptionFormDataType) {
    this.assetService.setAssetSubscriptionFormData(assetFormData);
  }
}
