import { Component, Inject } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../shared/dspConstants';
@Component({
  selector: 'dsp-next-gen-ui-common-confirmation-popup',
  templateUrl: './common-confirmation-popup.component.html',
  styleUrls: ['./common-confirmation-popup.component.scss'],
})
export class CommonConfirmationPopupComponent {
  dspConstants: any;
  constructor(@Inject(CC_MODAL_DATA) public data: any, private modalRef: CcModalRef<CommonConfirmationPopupComponent>) {
    this.dspConstants = dspConstants;
  }

  ngOnInit() {}

  closeModal(result?: any) {
    this.modalRef.close(result);
  }
}
