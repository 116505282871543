import { Component, OnInit, Inject, ChangeDetectorRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Dealer } from '../../../models/user-info.interface';
import { DspCommonService } from '../../../services/dsp-common.service';
import { DealerSubfeatureService } from '../../../services/dealerSubfeature.service';
import { debounce } from 'lodash-es';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import * as dspConstants from '../../../shared/dspConstants';
import { SharedService } from '../../../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationComponent implements OnInit {
  orgFormGroup!: FormGroup;
  selectedDealer!: Dealer;
  filterDealerList: Array<any> = [];
  filtered: Array<any> = [];
  searchDealer = '';
  isSaveBtnActive = false;
  showSameDealerCodeError = false;
  dealer = fetchLoggedInDealerDetails();
  isDealer: any;
  dealerSearchText = '';
  @ViewChild('searchbox') searchBox!: ElementRef;
  @ViewChild('selDealer') selDealer!: ElementRef;
  noRecords = false;
  prevSearchDealer = '';
  searchDataLoaded = false;
  dealerSelected = false;
  isValidateDealercode = false;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private router: Router,
    private messageBar: MessageBar,
    private modalRef: CcModalRef<OrganizationComponent>,
    private fb: FormBuilder,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dealerService: DealerSubfeatureService,
    private dspUtilsCommonService: DspUtilsCommonService,
    private sharedService: SharedService
  ) {}

  triggerSearchFunction = debounce(() => {
    this.onSearchTextChange();
  }, 1000);

  ngOnInit(): void {
    this.orgFormGroup = this.initializeForm();
    this.isDealer = this.data.isDealer;
  }

  private initializeForm() {
    return this.fb.group({
      searchDealer: [this.searchDealer, []],
    });
  }
  clearSessionStorageData() {
    sessionStorage.removeItem('dealerQueueReason');
    sessionStorage.removeItem('cancelReasonLabel');
    sessionStorage.removeItem('reportingState');
    sessionStorage.removeItem('initialChartData');
    sessionStorage.removeItem('reviewinitialChartData');
    sessionStorage.removeItem('cancellationinitialChartData');
    sessionStorage.removeItem('cancellationfilters');
    sessionStorage.removeItem('cancellationsavedFilterData');
    sessionStorage.removeItem('reviewsavedFilterData');
    sessionStorage.removeItem('reviewfilters');
    sessionStorage.removeItem('reviewFilters');
    this.dspUtilsCommonService.resetValues('lineChartLabels');
    sessionStorage.removeItem('productList');
    this.dspUtilsCommonService.resetValues('rangeCount');
    this.dspUtilsCommonService.resetValues('monthList');
    this.dspUtilsCommonService.resetValues(dspConstants.DspUtilsServiceVariables.CANCEL_REASONS);
    sessionStorage.removeItem('DefaultLineChartValues');
    sessionStorage.removeItem('subAssetTrendTableConfig');
    sessionStorage.removeItem('chart100OriginalData');
    sessionStorage.removeItem('clearMonth');
    sessionStorage.removeItem('lineChartFilters');
    sessionStorage.removeItem('cancellationfilters');
    sessionStorage.removeItem('cancellationsavedFilterData');
    sessionStorage.removeItem('reviewFilterData');
    sessionStorage.removeItem('reviewDateFilters');
    sessionStorage.removeItem('filterData');
    sessionStorage.removeItem('assetFilters');
    sessionStorage.removeItem('dateFilters');
    sessionStorage.removeItem('factoryUserFilters');
    sessionStorage.removeItem('assetColumn');
    sessionStorage.removeItem('Assets');
    sessionStorage.removeItem('sortedColumn');
    sessionStorage.removeItem('sortedOrder');
    sessionStorage.removeItem('sitesFilters');
    sessionStorage.removeItem('columnConfig');
    sessionStorage.removeItem('accountFilters');
    sessionStorage.removeItem('accountColumnConfig');
    sessionStorage.removeItem('billingFilters');
    sessionStorage.removeItem('billingFilterData');
    sessionStorage.removeItem('inquiryNumber');
    sessionStorage.removeItem('detailedInvoiceColumnConfig');
    this.sharedService.resetNavigationValue();
    sessionStorage.removeItem('lastCancelReason');
    sessionStorage.removeItem('selectedFIlters');
    sessionStorage.removeItem('filtersDetails');
    this.dspCommonService.removeDateFiltersFromSessionStorage();
  }
  onSaveDealer(_event: any) {
    this.clearSessionStorageData();
    if (this.selectedDealer) {
      sessionStorage['dealer'] = JSON.stringify(this.selectedDealer);
      this.dealerService.updateDealerSubfeature(true);
      window.location.reload();
      this.modalRef.close();
      const messageText = `Changes saved successfully.`;
      this.showToastNotification(messageText);
    }
  }

  showToastNotification(message: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, 'success', undefined, config, true);
  }

  searchDealerbyCode() {
    if (this.searchDataLoaded && this.searchDealer.length === 0) {
      this.dealerSelected = false;
      this.onSearchTextChange();
    } else {
      if (!this.dealerSelected || this.prevSearchDealer != this.searchDealer) {
        this.triggerSearchFunction();
      }
    }
  }

  onSearchTextChange() {
    if (this.prevSearchDealer != this.searchDealer) {
      this.prevSearchDealer = this.searchDealer;
      this.orgFormGroup = this.initializeForm();
      this.noRecords = false;
      this.showSameDealerCodeError = false;
      if (this.searchDealer && this.searchDealer.length >= 3) {
        this.isValidateDealercode = true;

        if (this.selectedDealer && this.selectedDealer.dealerCode != this.searchDealer) {
          this.isSaveBtnActive = false;
        }
        this.dealerSearchText = this.searchDealer;
        const body = {
          dealerCode: this.dealerSearchText,
          dealerName: this.dealerSearchText,
          offSet: 25,
          resultLimit: 25,
          sortField: 'dealerName',
          sortOrder: 'ASC',
          doNotCallApi: false,
        };
        this.filterDealerList = [];
        this.filtered = [];
        this.dspCommonService.getDealer(body).subscribe({
          next: (res) => {
            if (res && res.dealer && res.dealer.length > 0) {
              this.isValidateDealercode = false;

              this.filterDealerList = res.dealer;
              this.enableOverlayBox();
              for (let i = 0; i < this.filterDealerList.length; i++) {
                if (
                  this.filterDealerList[i].dealerCode != this.dealer.dealerCode &&
                  !this.filtered.find((obj) => {
                    return obj.dealerCode === this.filterDealerList[i].dealerCode;
                  })
                ) {
                  this.filtered.push(this.filterDealerList[i]);
                }
              }

              if (!this.filtered.length) {
                this.noRecordsFound(true);
              } else {
                this.applyHighlight(this.dealerSearchText, this.filtered);
                this.changeDetectorRef.markForCheck();
              }
            } else {
              this.isValidateDealercode = false;

              this.noRecordsFound(false);
            }
          },
          error: (err) => {},
        });
      } else if (this.searchDealer === '') {
        this.filterDealerList = [];
        this.orgFormGroup = this.initializeForm();
        this.noRecords = false;
        this.showSameDealerCodeError = false;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  noRecordsFound(sameDealer: boolean) {
    this.filterDealerList = [];
    this.resetOverlayBox();
    //this.selDealer?.nativeElement.focus();
    if (sameDealer) {
      this.showSameDealerCodeError = true;
    } else if (!sameDealer) {
      this.orgFormGroup = this.fb.group({
        searchDealer: [this.searchDealer, [Validators.required, Validators.minLength(100)]],
      });
      this.noRecords = true;
    }
    this.searchBox.nativeElement.focus();
    this.changeDetectorRef.markForCheck();
  }
  applyHighlight(inputvalue: any, filterRecords: any) {
    this.filterDealerList = filterRecords.map((items: any) => {
      const item = { ...items };
      if (item.dealerCode && item.dealerName) {
        let itemStr = item.dealerName + ' - ' + item.dealerCode;
        const nameStr = item.dealerName;
        const nameIdx = itemStr.toLowerCase().indexOf(inputvalue.toLowerCase());
        const tokenLen = inputvalue.length;
        const codeStr = item.dealerCode;
        const codeIdx = codeStr.toLowerCase().indexOf(inputvalue.toLowerCase());
        if (nameIdx >= 0 && tokenLen > 0) {
          itemStr =
            `${nameStr.substring(0, nameIdx)}<span class="highlight">${nameStr.substring(
              nameIdx,
              nameIdx + tokenLen
            )}</span>` + `${nameStr.substring(nameIdx + tokenLen)}`;
          if (codeIdx >= 0 && tokenLen > 0) {
            itemStr =
              itemStr +
              ' - ' +
              `${codeStr.substring(0, codeIdx)}` +
              `<span class="highlight">${codeStr.substring(codeIdx, codeIdx + tokenLen)}</span>` +
              `${codeStr.substring(codeIdx + tokenLen)}`;
          } else {
            itemStr = itemStr + ' - ' + item.dealerCode;
          }
        }
        item.innerDealer = itemStr;
      }
      return item;
    });
  }

  listItemClick(_currentInput: any, index: any) {
    this.searchDealer = this.filterDealerList[index].dealerCode;
    this.selectedDealer = this.filterDealerList[index];
    this.isSaveBtnActive = true;
    this.dealerSelected = true;
    this.prevSearchDealer = this.searchDealer;
  }
  onFocusInput() {
    if (this.noRecords) {
      setTimeout(() => {
        this.resetOverlayBox();
      }, 0);
    }
  }
  enableOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.display = 'block';
      overlayRef[0].style.maxHeight = '155px';
    }
  }
  resetOverlayBox() {
    const overlayRef = document.getElementsByClassName('cc-dropdown-overlay') as HTMLCollectionOf<HTMLElement>;

    if (overlayRef.length) {
      overlayRef[0].style.boxShadow = 'none';
    }
  }
  onValue(_event: any) {
    setTimeout(() => {
      const dealerLength = this.searchDealer.length;
      this.searchBox.nativeElement.setSelectionRange(dealerLength, dealerLength);
    }, 100);
  }

  onClickClose() {
    this.modalRef.close();
  }

  enableDealerListOverlay() {
    return this.filterDealerList?.length > 0 && this.searchDealer?.length >= 3;
  }
}
