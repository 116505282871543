export const COUNTRY = [
  {
    label: 'Afghanistan',
    value: 'AFG',
  },
  {
    label: 'Aland Island',
    value: 'ALA',
  },
  {
    label: 'Albania',
    value: 'ALB',
  },
  {
    label: 'Algeria',
    value: 'DZA',
  },
  {
    label: 'American Samoa',
    value: 'ASM',
  },
  {
    label: 'Andorra',
    value: 'AND',
  },
  {
    label: 'Angola',
    value: 'AGO',
  },
  {
    label: 'Anguilla',
    value: 'AIA',
  },
  {
    label: 'Antarctic Territories',
    value: 'ATA',
  },
  {
    label: 'Antigua & Barbuda',
    value: 'ATG',
  },
  {
    label: 'Argentina',
    value: 'ARG',
  },
  {
    label: 'Armenia',
    value: 'ARM',
  },
  {
    label: 'Aruba',
    value: 'ABW',
  },
  {
    label: 'Ascension Island',
    value: 'ASC',
  },
  {
    label: 'Australia',
    value: 'AUS',
  },
  {
    label: 'Austria',
    value: 'AUT',
  },
  {
    label: 'Azerbaijan',
    value: 'AZE',
  },
  {
    label: 'Bahamas',
    value: 'BHS',
  },
  {
    label: 'Bahrain',
    value: 'BHR',
  },
  {
    label: 'Bangladesh',
    value: 'BGD',
  },
  {
    label: 'Barbados',
    value: 'BRB',
  },
  {
    label: 'Belarus',
    value: 'BLR',
  },
  {
    label: 'Belgium',
    value: 'BEL',
  },
  {
    label: 'Belize',
    value: 'BLZ',
  },
  {
    label: 'Benin',
    value: 'BEN',
  },
  {
    label: 'Bermuda',
    value: 'BMU',
  },
  {
    label: 'Bhutan',
    value: 'BTN',
  },
  {
    label: 'Bolivia',
    value: 'BOL',
  },
  {
    label: 'Bonaire, Sint Eustatius, Saba',
    value: 'BES',
  },
  {
    label: 'Bosnia/Herzegovina',
    value: 'BIH',
  },
  {
    label: 'Botswana',
    value: 'BWA',
  },
  {
    label: 'Brazil',
    value: 'BRA',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IOT',
  },
  {
    label: 'British Virgin Islands',
    value: 'VGB',
  },
  {
    label: 'Brunei',
    value: 'BRN',
  },
  {
    label: 'Bulgaria',
    value: 'BGR',
  },
  {
    label: 'Burkina Faso',
    value: 'BFA',
  },
  {
    label: 'Burundi',
    value: 'BDI',
  },
  {
    label: 'Cabo Verde',
    value: 'CPV',
  },
  {
    label: 'Cambodia',
    value: 'KHM',
  },
  {
    label: 'Cameroon',
    value: 'CMR',
  },
  {
    label: 'Canada',
    value: 'CAN',
  },
  {
    label: 'Cayman Islands',
    value: 'CYM',
  },
  {
    label: 'The Central African Republic (CAR)',
    value: 'CAF',
  },
  {
    label: 'Chad',
    value: 'TCD',
  },
  {
    label: 'Chile',
    value: 'CHL',
  },
  {
    label: 'China',
    value: 'CHN',
  },
  {
    label: 'Christmas Island',
    value: 'CXR',
  },
  {
    label: 'Colombia',
    value: 'COL',
  },
  {
    label: 'Comoros',
    value: 'COM',
  },
  {
    label: 'Republic of the Congo',
    value: 'COG',
  },
  {
    label: ' Democratic Republic of the Congo (DRC ',
    value: 'COD',
  },
  {
    label: 'Cook Island',
    value: 'COK',
  },
  {
    label: 'Costa Rica',
    value: 'CRI',
  },
  {
    label: 'Croatia',
    value: 'HRV',
  },
  {
    label: 'Cuba',
    value: 'CUB',
  },
  {
    label: 'Curacao',
    value: 'CUW',
  },
  {
    label: 'Cyprus',
    value: 'CYP',
  },
  {
    label: 'Czech Republic',
    value: 'CZE',
  },
  {
    label: 'Denmark',
    value: 'DNK',
  },
  {
    label: 'Djibouti',
    value: 'DJI',
  },
  {
    label: 'Dominica',
    value: 'DMA',
  },
  {
    label: 'Dominican Republic',
    value: 'DOM',
  },
  {
    label: 'East Timor aka Timor Leste',
    value: 'TLS',
  },
  {
    label: 'Ecuador',
    value: 'ECU',
  },
  {
    label: 'Egypt',
    value: 'EGY',
  },
  {
    label: 'El Salvador',
    value: 'SLV',
  },
  {
    label: 'Eritrea',
    value: 'ERI',
  },
  {
    label: 'Estonia',
    value: 'EST',
  },
  {
    label: 'Ethiopia',
    value: 'ETH',
  },
  {
    label: 'Faroe Islands',
    value: 'FRO',
  },
  {
    label: 'Fiji',
    value: 'FJI',
  },
  {
    label: 'Finland',
    value: 'FIN',
  },
  {
    label: 'France',
    value: 'FRA',
  },
  {
    label: 'French Guiana',
    value: 'GUF',
  },
  {
    label: 'French Polynesia (aka Tahiti)',
    value: 'PYF',
  },
  {
    label: 'Futuna Island',
    value: 'WLF',
  },
  {
    label: 'Gabon',
    value: 'GAB',
  },
  {
    label: 'Gambia',
    value: 'GMB',
  },
  {
    label: 'Georgia',
    value: 'GEO',
  },
  {
    label: 'Germany',
    value: 'DEU',
  },
  {
    label: 'Ghana',
    value: 'GHA',
  },
  {
    label: 'Gibraltar',
    value: 'GIB',
  },
  {
    label: 'Greece',
    value: 'GRC',
  },
  {
    label: 'Greenland',
    value: 'GRL',
  },
  {
    label: 'Grenada',
    value: 'GRD',
  },
  {
    label: 'Guadeloupe',
    value: 'GLP',
  },
  {
    label: 'Guam',
    value: 'GUM',
  },
  {
    label: 'Guatemala',
    value: 'GTM',
  },
  {
    label: 'Guernsey',
    value: 'GGY',
  },
  {
    label: 'Guinea (aka Guinea-Conakry aka Rep of Guinea)',
    value: 'GNK',
  },
  {
    label: 'Guinea Bissau (aka Rep of Guinea Bissau)',
    value: 'GNB',
  },
  {
    label: 'Guinea Equatorial aka Rep of Eq Guinea',
    value: 'GGQ',
  },
  {
    label: 'Guyana',
    value: 'GUY',
  },
  {
    label: 'Haiti',
    value: 'HTI',
  },
  {
    label: 'Honduras',
    value: 'HND',
  },
  {
    label: 'Hong Kong',
    value: 'HKG',
  },
  {
    label: 'Hungary',
    value: 'HUN',
  },
  {
    label: 'Iceland',
    value: 'ISL',
  },
  {
    label: 'India',
    value: 'IND',
  },
  {
    label: 'Indonesia',
    value: 'IDN',
  },
  {
    label: 'Iran',
    value: 'IRN',
  },
  {
    label: 'Iraq',
    value: 'IRQ',
  },
  {
    label: 'Ireland',
    value: 'IRL',
  },
  {
    label: 'Isle of Man',
    value: 'IMN',
  },
  {
    label: 'Israel',
    value: 'ISR',
  },
  {
    label: 'Italy',
    value: 'ITA',
  },
  {
    label: "Ivory Coast (aka Cote D'ivoire)",
    value: 'CIV',
  },
  {
    label: 'Jamaica',
    value: 'JAM',
  },
  {
    label: 'Japan',
    value: 'JPN',
  },
  {
    label: 'Jersey',
    value: 'JEY',
  },
  {
    label: 'Jordan',
    value: 'JOR',
  },
  {
    label: 'Kazakhstan',
    value: 'KAZ',
  },
  {
    label: 'Kenya',
    value: 'KEN',
  },
  {
    label: 'Kiribati',
    value: 'KIR',
  },
  {
    label: 'Korea (North)',
    value: 'PRK',
  },
  {
    label: 'Korea (South)',
    value: 'KOR',
  },
  {
    label: 'Kosovo',
    value: 'KIR',
  },
  {
    label: 'Kuwait',
    value: 'KWT',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KGZ',
  },
  {
    label: 'Laos',
    value: 'LAO',
  },
  {
    label: 'Latvia',
    value: 'LVA',
  },
  {
    label: 'Lebanon',
    value: 'LBN',
  },
  {
    label: 'Lesotho',
    value: 'LSO',
  },
  {
    label: 'Liberia',
    value: 'LBR',
  },
  {
    label: 'Libya',
    value: 'LBY',
  },
  {
    label: 'Liechtenstein',
    value: 'LIE',
  },
  {
    label: 'Lithuania',
    value: 'LTU',
  },
  {
    label: 'Luxembourg',
    value: 'LUX',
  },
  {
    label: 'Macau (Macao)',
    value: 'MAC',
  },
  {
    label: 'Macedonia',
    value: 'MKD',
  },
  {
    label: 'Madagascar',
    value: 'MDG',
  },
  {
    label: 'Malawi',
    value: 'MWI',
  },
  {
    label: 'Malaysia',
    value: 'MYS',
  },
  {
    label: 'Maldives',
    value: 'MDV',
  },
  {
    label: 'Mali',
    value: 'MLI',
  },
  {
    label: 'Malta',
    value: 'MLT',
  },
  {
    label: 'Marshall Islands, Rep of (assoc state of US)',
    value: 'MHL',
  },
  {
    label: 'Martinique',
    value: 'MTQ',
  },
  {
    label: 'Mauritania',
    value: 'MRT',
  },
  {
    label: 'Mauritius',
    value: 'MUS',
  },
  {
    label: 'Mayotte',
    value: 'MYT',
  },
  {
    label: 'Mexico',
    value: 'MEX',
  },
  {
    label: 'Micronesia',
    value: 'FSM',
  },
  {
    label: 'Miquelon',
    value: 'SPM',
  },
  {
    label: 'Moldova',
    value: 'MDA',
  },
  {
    label: 'Monaco',
    value: 'MCO',
  },
  {
    label: 'Mongolia',
    value: 'MNG',
  },
  {
    label: 'Montenegro',
    value: 'MNE',
  },
  {
    label: 'Montserrat',
    value: 'MSR',
  },
  {
    label: 'Morocco',
    value: 'MAR',
  },
  {
    label: 'Mozambique',
    value: 'MOZ',
  },
  {
    label: 'Myanmar (Burma)',
    value: 'MMR',
  },
  {
    label: 'Namibia',
    value: 'NAM',
  },
  {
    label: 'Nepal',
    value: 'NPL',
  },
  {
    label: 'Netherlands',
    value: 'NLD',
  },
  {
    label: 'New Caledonia',
    value: 'NCL',
  },
  {
    label: 'New Zealand',
    value: 'NZL',
  },
  {
    label: 'Nicaragua',
    value: 'NIC',
  },
  {
    label: 'Niger',
    value: 'NER',
  },
  {
    label: 'Nigeria',
    value: 'NGA',
  },
  {
    label: 'Niue Island',
    value: 'NIU',
  },
  {
    label: 'Norfolk Island',
    value: 'NFK',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MNP',
  },
  {
    label: 'Norway',
    value: 'NOR',
  },
  {
    label: 'Oman',
    value: 'OMN',
  },
  {
    label: 'Pakistan',
    value: 'PAK',
  },
  {
    label: 'Pakistan (Kashmir Region)',
    value: 'PAK',
  },
  {
    label: 'Palestine (Gaza Strip)',
    value: 'PSG',
  },
  {
    label: 'Palestine (West Bank)',
    value: 'PSE',
  },
  {
    label: 'Panama',
    value: 'PAN',
  },
  {
    label: 'Papua New Guinea',
    value: 'PNG',
  },
  {
    label: 'Paraguay',
    value: 'PRY',
  },
  {
    label: 'Peru',
    value: 'PER',
  },
  {
    label: 'Philippines',
    value: 'PHL',
  },
  {
    label: 'Pitcarn Islands',
    value: 'PCN',
  },
  {
    label: 'Poland',
    value: 'POL',
  },
  {
    label: 'Portugal',
    value: 'PRT',
  },
  {
    label: 'Puerto Rico',
    value: 'PRI',
  },
  {
    label: 'Qatar',
    value: 'QAT',
  },
  {
    label: 'Reunion',
    value: 'REU',
  },
  {
    label: 'Romania',
    value: 'ROU',
  },
  {
    label: 'Russia',
    value: 'RUS',
  },
  {
    label: 'Rwanda',
    value: 'RWA',
  },
  {
    label: 'Samoa',
    value: 'WSM',
  },
  {
    label: 'San Marino',
    value: 'SMR',
  },
  {
    label: 'Sao Tome & Principe',
    value: 'STP',
  },
  {
    label: 'Saudi Arabia',
    value: 'SAU',
  },
  {
    label: 'Senegal',
    value: 'SEN',
  },
  {
    label: 'Serbia',
    value: 'SRB',
  },
  {
    label: 'Seychelles',
    value: 'SYC',
  },
  {
    label: 'Sierra Leone',
    value: 'SLE',
  },
  {
    label: 'Singapore',
    value: 'SGP',
  },
  {
    label: 'Slovakia',
    value: 'SVK',
  },
  {
    label: 'Slovenia',
    value: 'SVN',
  },
  {
    label: 'Solomon Islands',
    value: 'SLB',
  },
  {
    label: 'Somalia',
    value: 'SOM',
  },
  {
    label: 'South Africa',
    value: 'ZAF',
  },
  {
    label: 'South Georgia & Sandwich Islands',
    value: 'SGS',
  },
  {
    label: 'Spain',
    value: 'ESP',
  },
  {
    label: 'Sri Lanka',
    value: 'LKA',
  },
  {
    label: 'St. Barts',
    value: 'CSX',
  },
  {
    label: 'St. Helena',
    value: 'SJO',
  },
  {
    label: 'St. Kitts & Nevis',
    value: 'KNA',
  },
  {
    label: 'St. Lucia',
    value: 'LCA',
  },
  {
    label: 'St. Martin (French)',
    value: 'MAF',
  },
  {
    label: 'St. Pierre',
    value: 'SPM',
  },
  {
    label: 'St. Vincent & Grenadines',
    value: 'VCT',
  },
  {
    label: 'Sudan (Sudan North)',
    value: 'SDN',
  },
  {
    label: 'Sudan South',
    value: 'SSD',
  },
  {
    label: 'Suriname',
    value: 'SUR',
  },
  {
    label: 'Svalbard & Jan Mayen Islands',
    value: 'SJM',
  },
  {
    label: 'Swaziland',
    value: 'SWZ',
  },
  {
    label: 'Sweden',
    value: 'SWE',
  },
  {
    label: 'Switzerland',
    value: 'CHE',
  },
  {
    label: 'Syria',
    value: 'SYR',
  },
  {
    label: 'Taiwan',
    value: 'TWN',
  },
  {
    label: 'Tajikistan',
    value: 'TJK',
  },
  {
    label: 'Tanzania',
    value: 'TZA',
  },
  {
    label: 'Thailand',
    value: 'THA',
  },
  {
    label: 'Togo',
    value: 'TGO',
  },
  {
    label: 'Tonga',
    value: 'TON',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TTO',
  },
  {
    label: 'Tunisia',
    value: 'TUN',
  },
  {
    label: 'Turkiye',
    value: 'TUR',
  },
  {
    label: 'Turkmenistan',
    value: 'TKM',
  },
  {
    label: 'Turks & Caicos',
    value: 'TCA',
  },
  {
    label: 'Tuvalu (Islands)',
    value: 'TUV',
  },
  {
    label: 'Uganda',
    value: 'UGA',
  },
  {
    label: 'Ukraine',
    value: 'UKR',
  },
  {
    label: 'Ukraine - Crimea Region',
    value: 'UKR',
  },
  {
    label: 'United Arab Emirates',
    value: 'ARE',
  },
  {
    label: 'United Kingdom',
    value: 'GBR',
  },
  {
    label: 'United States',
    value: 'USA',
  },
  {
    label: 'United States Virgin Islands',
    value: 'UVI',
  },
  {
    label: 'Uruguay',
    value: 'URY',
  },
  {
    label: 'Uzbekistan',
    value: 'UZB',
  },
  {
    label: 'Vanuatu',
    value: 'VUT',
  },
  {
    label: 'Vatican City',
    value: 'VTI',
  },
  {
    label: 'Venezuela',
    value: 'VEN',
  },
  {
    label: 'Vietnam',
    value: 'VNM',
  },
  {
    label: 'Wallis',
    value: 'WLF',
  },
  {
    label: 'Western Sahara',
    value: 'ESH',
  },
  {
    label: 'Yemen',
    value: 'YEM',
  },
  {
    label: 'Zambia',
    value: 'ZMB',
  },
  {
    label: 'Zimbabwe',
    value: 'ZWE',
  },
];
