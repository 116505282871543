<span
  class="productplan-dealer"
  *ngIf="!showTruncatedText"
  cc-tooltip
  [isEllipseEnabled]="true"
  [innerHTML]="
    rowData.appName?.substr(0, 20) + ' | ' + rowData.subsDtl.name?.substr(0, 20) | highlightSearch: searchString
  "
  [tooltipPosition]="'top'"
>
</span>
<span
  class="productplan-dealer"
  *ngIf="showTruncatedText"
  cc-tooltip
  [isEllipseEnabled]="true"
  [innerHTML]="
    rowData.appName?.substr(0, 20) + ' | ' + rowData.subsDtl.name?.substr(0, 20) | highlightSearch: searchString
  "
  [tooltipPosition]="'top'"
  dspNextGenUiCopy
  [fullText]="rowData.appName + ' | ' + rowData.subsDtl.name"
>
</span>
