<div class="cc-drawer manage-pending-request-drawer" id="manage-pending-request">
  <div class="cc-drawer__header" id="manage-pending-request">
    <svg
      class="cc-drawer__leadingIcon"
      (click)="closeDrawer()"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 5.89258H1"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.3961L1 5.89139L6 1.38672"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="cc-headerText cc-drawer__ellipsis"> Manage Plan </span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="manage-pending-request-tracker" id="manage-pending-request-tracker" ng>
      <cc-progress-tracker
        #managePendingRequestTracker
        [activeStepIndex]="trackerIndex"
        [trackerData]="dynamicTrackerData"
        [isTheme]="isTheme"
      >
      </cc-progress-tracker>
    </div>
    <div *ngIf="gotAssetInfo && gotOwnershipInfo && gotRequestInfo">
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === trackerLabel.PENDING_REQUEST">
        <div class="pending-request">
          <dsp-next-gen-ui-pending-request
            [rowData]="selectedRowData"
            (updateTrackerIndex)="updateTrackerIndex()"
            [pendingRequestFormData]="pendingRequestFormData"
          >
          </dsp-next-gen-ui-pending-request>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === trackerLabel.PAYMENT_PREVIEW">
        <div class="payment-preview">
          <dsp-next-gen-ui-pending-request-payment-preview
            [rowData]="selectedRowData"
            (updateTrackerIndex)="updateTrackerIndex()"
            [pendingRequestFormData]="pendingRequestFormData"
          ></dsp-next-gen-ui-pending-request-payment-preview>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === trackerLabel.ENTER_REASON">
        <div class="enter-reason">
          <dsp-next-gen-ui-enter-reason
            [rowData]="selectedRowData"
            (updateTrackerIndex)="updateTrackerIndex()"
            [pendingRequestFormData]="pendingRequestFormData"
          ></dsp-next-gen-ui-enter-reason>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button id="asset-manage-drawer-cancelBtn" cc-btn-secondary cc-btn-large [setTheme]="true" (click)="onPrevious()">
      <!-- [ngClass]="
          (this.trackerIndex === 0 && this.launchType === 'DRAWER') ||
          (this.trackerIndex === 1 && this.assetRowData?.selectedAssetDetails?.reportingState === 'Subscribed')
            ? 'hide-back-button'
            : ''
        " -->
      {{ updateCancelButtonLabel() }}
    </button>
    <button
      id="asset-manage-drawer-saveBtn"
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="setNextDisabled()"
      (click)="onNext()"
    >
      {{ updateSubmitButtonLabel() }}
    </button>
  </div>
</div>
