import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  footerNavigation: any;
  constructor() {}
  checkLast3Char(text: any, copiedtextLength: any) {
    const last3Char = text?.substring(text.length - 3);
    if (last3Char === '...') {
      return text.length == copiedtextLength ? true : false;
    } else {
      return false;
    }
  }
  checkString(text: any) {
    return text.includes('...');
  }
  checkLastChar(text: any, copiedtextLength: any) {
    const last3Char = text?.substring(text.length - 1);
    if (last3Char === '…') {
      return text.length == copiedtextLength ? true : false;
    } else {
      return false;
    }
  }

  setClipboardData(event: any, fullText: string) {
    const clipoardDta = event.clipboardData;
    clipoardDta?.setData('text', fullText);
  }

  copyEvent(
    event: any,
    clipboardText: any,
    splitBy: any,
    text1: string,
    text2: any,
    textLength: any,
    compName: string
  ) {
    let fullText = '';
    const arr = clipboardText?.split(splitBy);
    if (arr?.length == 2) {
      switch (compName) {
        case 'Asset History Dealer': {
          const containsDot = this.checkString(clipboardText);
          if (containsDot) {
            event.preventDefault();
            fullText = text1 + '-' + text2;
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Account Product Plan': {
          const containsDot = this.checkLast3Char(arr[0], textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = text1 + '|' + arr[1];
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Asset Ownership':
        case 'Grade Asset Ownership':
        case 'Account Dealer Customer':
        case 'Account Customer': {
          const containsDot = this.checkLast3Char(arr[1].trim(), textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = arr[0] + ': ' + text1 + ' - ' + text2;
            this.setClipboardData(event, fullText);
          }
          break;
        }
      }
    } else if (arr?.length == 1) {
      switch (compName) {
        case 'Asset History Dealer': {
          const containsDot = this.checkString(clipboardText);
          if (containsDot) {
            event.preventDefault();
            fullText = text1 + '-' + text2;
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Account Product Plan': {
          const containsDot = this.checkLast3Char(arr[0].trim(), textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = text1 + ' | ' + text2;
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Asset Ownership':
        case 'Grade Asset Ownership':
        case 'Account Dealer Customer':
        case 'Account Customer': {
          const containsDot = this.checkLast3Char(arr[0].trim(), textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = text1 + ' - ' + text2;
            this.setClipboardData(event, fullText);
          }
          break;
        }
      }
    }
  }
  copyEventFunc(event: any, clipboardText: any, splitBy: any, text: any, textLength: any, compName: string) {
    let fullText = '';
    const arr = clipboardText?.split(splitBy);
    if (arr?.length == 2) {
      switch (compName) {
        case 'Asset Details': {
          const containsDot = this.checkLast3Char(arr[1], textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = arr[0] + ': ' + text;
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Optional Plan':
        case 'Plan':
        case 'Plan Obj': {
          let trimmedText = arr[1].trim();
          const containsDot = this.checkLastChar(trimmedText, textLength);
          if (containsDot) {
            event.preventDefault();
            if (typeof text == 'string') {
              fullText = arr[0] + ': ' + text;
            } else if (typeof text == 'object') {
              fullText = arr[0] + ': ' + text[0]?.name;
            }
            this.setClipboardData(event, fullText);
          }
          break;
        }
      }
    } else if (arr?.length == 1) {
      switch (compName) {
        case 'Asset Details':
        case 'Asset History': {
          const containsDot = this.checkLast3Char(arr[0], textLength);
          if (containsDot) {
            event.preventDefault();
            fullText = text;
            this.setClipboardData(event, fullText);
          }
          break;
        }
        case 'Optional Plan':
        case 'Plan':
        case 'Plan Obj': {
          let trimmedText = arr[0].trim();
          const containsDot = this.checkLastChar(trimmedText, textLength);
          if (containsDot) {
            event.preventDefault();
            if (typeof text == 'string') {
              fullText = text;
            } else if (typeof text == 'object') {
              fullText = text[0]?.name;
            }
            this.setClipboardData(event, fullText);
          }
          break;
        }
      }
    }
  }

  updateDataLayer(object: any) {
    ((window as any).dataLayer = (window as any).dataLayer || []).push(object);
  }
  setNavigationValue(value: any) {
    this.footerNavigation = value;
  }
  getNavigationValue() {
    return this.footerNavigation;
  }

  resetNavigationValue() {
    this.footerNavigation = '';
  }
}
