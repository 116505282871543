import { Component, OnInit, Input } from '@angular/core';
import { isNil } from 'lodash-es';

import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { SharedService } from '../../../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss'],
})
export class SiteInfoComponent implements OnInit {
  @Input() siteData: any;
  @Input() displayType = 'info';
  activeStatus = false;
  inactiveStatus = false;
  statusText: any;
  lengthOfServices: any;
  addOnService: any;
  showTruncatedText = false;
  constructor(private store: Store<DSPAppState>, private sharedService: SharedService) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.showTruncatedText = dsp?.featureFlagInfo?.showTruncatedText
        ? dsp?.featureFlagInfo?.showTruncatedText
        : false;
    });
    this.getActiveStatus();
    this.lengthOfServices = this.siteData?.addOnServices?.length;
    this.additionalServices();
  }
  getActiveStatus() {
    if (this.siteData.status === 'Active') {
      this.activeStatus = true;
      this.inactiveStatus = false;
      this.statusText = 'Active';
    } else if (this.siteData.status === 'InActive') {
      this.inactiveStatus = true;
      this.activeStatus = false;
      this.statusText = 'InActive';
    }
  }

  parseSiteName(sitename: string) {
    return sitename;
  }

  updateCustomerName(customerId: string, customerName: string) {
    const ID = isNil(customerId) ? '' : customerId;
    const name = isNil(customerName) ? '' : customerName;
    const customerInfo = ID.trim() + name.trim();
    if (ID != '' && name != '') {
      if (customerInfo.length > 28) {
        return (name.trim() + ' - ' + ID.trim()).slice(0, 28) + '...';
      } else {
        return name.trim() + ' - ' + ID.trim();
      }
    } else {
      if (customerInfo.length > 28) {
        return (name.trim() + ID.trim()).slice(0, 28) + '...';
      } else return `${name}${ID}`;
    }
  }
  additionalServices() {
    this.addOnService = '';
    if (this.siteData?.addOnServices?.length > 0) {
      this.siteData?.addOnServices.forEach((info: any) => {
        this.addOnService = this.addOnService + info;
        if (this.siteData?.addOnServices?.indexOf(info) < this.siteData?.addOnServices?.length - 1) {
          this.addOnService = this.addOnService + ' | ';
        }
      });
    }
  }
  copyEvent(event: any, name: string, id: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(event, clipboardText?.trim(), ':', name.trim(), id.trim(), 31, 'Account Customer');
  }
}
