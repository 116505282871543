import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, CcModal, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { Router } from '@angular/router';
import { BarChartFilterComponent } from '../bar-chart-filter/bar-chart-filter.component';
import { DashboardService } from '../services/dashboard.service';
import { CancellationExpandedComponent } from './cancellation-expanded/cancellation-expanded.component';
import { fromEvent } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
import { DspUtilsCommonService } from '../../../services/dsp-utils-common.service';
import * as dspConstants from '../../../shared/dspConstants';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { FilterCloseDashboardComponent } from '../../filter-close-dashboard/filter-close-dashboard.component';
@Component({
  selector: 'dsp-next-gen-ui-cancellation-reason',
  templateUrl: './cancellation-reason.component.html',
  styleUrls: ['./cancellation-reason.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancellationReasonComponent implements OnInit {
  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  filterContentsRef: any;
  cancellationRes: any = [];
  cancellationReasonFilterRes: any = [];
  xAxis: any = [];
  maxScrollValue = 8;
  cancellationChartData = {
    series: [
      {
        data: [],
        minPointLength: 5,
      },
    ],
  };

  cancellationChartConfig = {
    height: '430px',
    styledMode: false,
    WrapXaxisLabel: true,
    groupGap: 40,

    xAxis: {
      categories: this.xAxis,
      gridLineDashStyle: 'Dash', // values = 'Solid','ShortDash','ShortDot','ShortDashDot','ShortDashDotDot','Dot','Dash','LongDash','DashDot','LongDashDot','LongDashDotDot'
      gridLineWidth: 1, // width of grid
      gridLineColor: '#e2e2e2', // to apply gridline color
      useLabelHTML: true, // make it to true to apply the styles object
      align: 'left',
      reserveSpace: true,
      style: {
        width: '350px',
        // 'white-space': 'wrap',
        // overflow: 'hidden',
        // 'text-overflow': 'ellipsis',
      }, //  style object
    },
    columnWidth: 40,
    yAxis: { enabled: false, visible: false },
    legend: { enabled: false },
    //dataLabelsInside: true,
    tooltip: {
      useXAxisLabel: true,
      padding: 5,
      shared: false,
      useHTML: true,
      tooltipseperator: ' |',
      hideColor: true,
    },
    dataLabels: true,
    dataLabelsCrop: false,
    dataLabelsOverflow: 'allow',
    scrollbar: {
      max_Axis: 6,
      scrollButtonHide: true,
      scrollBarWidth: 4,
    },
  };

  // cancellationChartConfig = {
  //   title: '',
  //   //width: window.devicePixelRatio === 1.5 ? 490 : window.devicePixelRatio === 1 ? 800 : 600,
  //   height: window.devicePixelRatio === 1.5 ? '67%' : window.devicePixelRatio === 1 ? '40%' : '54%',
  //   styledMode: false,
  //   WrapXaxisLabel: true,
  //   xAxis: {
  //     categories: this.xAxis,
  //     style: {
  //       width: '140px',
  //     },
  //   },
  //   groupGap: 24,
  //   columnWidth: 40,
  //   yAxis: { enabled: false, visible: false },
  //   legend: { enabled: false },
  //   tooltip: {
  //     useXAxisLabel: true,
  //     padding: 5,
  //     shared: false,
  //     useHTML: true,
  //     valueSuffix: '',
  //     tooltipseperator: ' |',
  //     hideColor: true,
  //   },
  //   dataLabels: true,
  //   dataLabelsCrop: false,
  //   dataLabelsOverflow: 'allow',
  //   marginTop: 30,
  //   scrollbar: {
  //     max_Axis: 8,
  //     scrollButtonHide: true,
  //     scrollBarWidth: 4,
  //   },
  //   decimalPoint: ',',
  // };
  cancellationResponsiveConfig = {
    type: 'horizontal',
    height: 305,
    marginBottom: 10,
    marginRight: 30,
    yAxisOpposite: false,
    legendPlacement: 'top',
  };
  filterResponse: any = [];
  chartSpinner: any;
  map = new Map<string, [string]>();
  onClickChartValue: any;
  cancelReasonsArr: Array<string> = [];
  filterSelectedData: any;
  dataToFilter: any;
  dspStoreData!: any;
  isShowNeedsReviewExpandedViewFilter = false;
  filterIcon = false;
  isFullScreenOpened = false;
  modalRef: any;
  constructor(
    private dashboardService: DashboardService,
    private drawerService: DrawerService,
    public modal: CcModal,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dspUtilsCommonService: DspUtilsCommonService,
    private store: Store<DSPAppState>
  ) {
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.setColumnWidth();
    });
  }

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowNeedsReviewExpandedViewFilter = this.dspStoreData?.featureFlagInfo?.isShowNeedsReviewExpandedViewFilter
        ? this.dspStoreData?.featureFlagInfo?.isShowNeedsReviewExpandedViewFilter
        : false;
    });
    if (this.isShowNeedsReviewExpandedViewFilter) {
      this.dashboardService.openFilterClickedCancellation.subscribe((data: any) => {
        if (data && this.isFullScreenOpened) {
          this.openFilter();
        }
      });
      this.chartSpinner = true;
      this.getWorkListQueue();
      this.setColumnWidth();
    } else {
      this.chartSpinner = true;
      this.getWorkListQueue();
      this.setColumnWidth();
    }
  }
  getWorkListQueue() {
    const cancelReasons = this.dspUtilsCommonService.getValue(dspConstants.DspUtilsServiceVariables.CANCEL_REASONS);
    this.store.select('cancellationReasonDashboardFilter').subscribe((x) => {
      this.filterSelectedData = x.cancellationReasonDashboardFilter?.filter((res: any) => res.checked);
      if (this.filterSelectedData.length) {
        this.filterIcon = true;
      } else {
        this.filterIcon = false;
      }
      this.dataToFilter = x.cancellationReasonDashboardFilter;
    });
    if (this.filterSelectedData && this.filterSelectedData.length > 0) {
      const updatedFilterResponse = this.dataToFilter.map((itemOne: any) => {
        const matchingItem = this.filterSelectedData.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      this.chartSpinner = false;
      this.cancellationRes = this.filterSelectedData;
      const sortedArr = this.sortingByNameAsc(this.cancellationRes);
      this.mappingChartData(sortedArr);
      this.filterResponse = updatedFilterResponse;
    } else {
      this.dashboardService.getCancellationReasons(this.dealerCode).subscribe({
        next: (data: any) => {
          this.chartSpinner = false;
          this.cancellationRes = data;
          if (isEmpty(cancelReasons)) {
            this.cancellationRes.forEach((res: any) => {
              this.cancelReasonsArr.push(res.scopeParams['filterValue']);
            });
          }
          const sortedArr = this.sortingByNameAsc(this.cancellationRes);
          this.dspUtilsCommonService.setValue(sortedArr, dspConstants.DspUtilsServiceVariables.CANCEL_REASONS);
          this.mappingChartData(sortedArr);
          this.filterResponse = this.cancellationRes;
        },
        error: (error) => {
          this.chartSpinner = false;
        },
      });
    }
  }

  mappingChartData(chartData: any) {
    const chartValue: any = [];
    const chartLabel: any = [];
    chartData.map((chartDataValue: any) => {
      chartValue.push(Number(chartDataValue.value));
      chartLabel.push(chartDataValue.label.replace(',', ''));
      this.map.set(chartDataValue.label.replace(',', ''), chartDataValue.label);
      this.cancellationChartData = { series: [{ data: chartValue, minPointLength: 5 }] };
      this.cancellationChartConfig.xAxis.categories = [...new Set(chartLabel)];
    });
  }

  openFilter() {
    this.store.select('cancellationReasonDashboardFilter').subscribe((x) => {
      this.filterSelectedData = x.cancellationReasonDashboardFilter?.filter((res: any) => res.checked);
    });

    if (this.filterSelectedData && this.filterSelectedData.length > 0) {
      let updatedFilterResponse = this.dataToFilter.map((itemOne: any) => {
        const matchingItem = this.filterSelectedData.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      updatedFilterResponse = updatedFilterResponse.map((item: any) => {
        return {
          checked: item.checked,
          id: item.id,
          label: item.label.replace(/,/g, ''),
          navigateTo: item.navigateTo,
          scopeParams: {
            filterValue: item.scopeParams.filterValue.replace(/,/g, ''),
            filterBy: item.scopeParams.filterBy,
          },
          value: item.value,
        };
      });
      this.filterResponse = updatedFilterResponse;
    } else {
      let updatedFilterRes: any = [];
      this.filterResponse.map((chartData: any) => {
        const updatedObj = { ...chartData, checked: false };
        updatedFilterRes.push(updatedObj);
      });
      this.filterResponse = [];
      updatedFilterRes = updatedFilterRes.map((item: any) => {
        return {
          checked: item.checked,
          id: item.id,
          label: item.label.replace(/,/g, ''),
          navigateTo: item.navigateTo,
          scopeParams: {
            filterValue: item.scopeParams.filterValue.replace(/,/g, ''),
            filterBy: item.scopeParams.filterBy,
          },
          value: item.value,
        };
      });
      this.filterResponse = updatedFilterRes;
    }
    const sortedArr = this.sortingByNameAsc(this.filterResponse);
    this.filterContentsRef = this.drawerService.openDrawer<BarChartFilterComponent, CcDrawerConfig>(
      BarChartFilterComponent,
      {
        data: {
          filters: this.filterResponse,
          // savedFilters: savedFilters,
          headerType: 'cancellation',
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    if (this.filterContentsRef) {
      this.filterContentsRef?.onBackdropClick?.subscribe((data: any) => {
        const res = this.dashboardService.getbar();
        if (!res) {
          this.filterContentsRef.close();
        }
        if (res && data) {
          this.modalRef = this.modal.openModal(FilterCloseDashboardComponent, {
            width: '524px',
            type: 'semi-modal',
            closeOnEsc: false,
            disableBackdropClick: true,
            isAutoHeightModalContent: true,
          });
          this.modalRef.afterClosed().subscribe((result: any) => {
            if (result === true) {
              this.dashboardService.applyFilterClicked.next(true);
              // this.closePopup(closeSelectedFilter);
            } else if (result === false) {
              this.modalRef.close();
              this.filterContentsRef.close();
            }
          });
        } else {
          this.modalRef.close();
          this.filterContentsRef.close();
        }
        // });
      });
    }
    this.filterContentsRef.afterClosed().subscribe((filteredChartData: any) => {
      if (filteredChartData && filteredChartData.length > 0) {
        // this.filterIcon = true;
        const sortedArr = this.sortingByNameAsc(filteredChartData);
        this.mappingChartData(sortedArr);
        this.cancellationChartConfig.scrollbar.max_Axis = sortedArr.length < 6 ? sortedArr.length - 1 : 6;
      } else {
        if (
          filteredChartData === false ||
          (filteredChartData != undefined &&
            Object.keys(filteredChartData) &&
            Object.keys(filteredChartData).length == 0)
        ) {
          // this.filterIcon = false;
          this.mappingChartData(this.dataToFilter);
          this.cancellationChartConfig.scrollbar.max_Axis = sortedArr.length < 6 ? sortedArr.length - 1 : 6;
        }
      }
      this.setColumnWidth();
      this.dashboardService.applyFilterClicked.next(false);
      this.dashboardService.setbar(null);
    });
  }

  cancellationChartClick(event: any) {
    this.onClickChartValue = this.map.get(event.point.category);
    sessionStorage.setItem('cancelReasonLabel', this.onClickChartValue);
    this.router.navigate(['/asset']);
    const element: HTMLElement = document.getElementsByClassName('arrow-position')[0] as HTMLElement;
    element.click();
  }

  sortingByNameAsc(filteredChartData: any) {
    const sortedArr = filteredChartData.sort((a: any, b: any) => {
      const fa = a.label?.toLowerCase(),
        fb = b.label?.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return sortedArr;
  }

  openExpanedView() {
    this.cdr.detectChanges();
    this.isFullScreenOpened = true;
    const modalRef = this.modal.openModal(CancellationExpandedComponent, {
      type: 'full-modal',
      //width: '52%',
      data: {
        title: 'Cancellation Reasons',
        config: this.cancellationChartConfig,
        chartData: this.cancellationChartData,
        cancelReasonsArr: this.map,
      },
      panelClass: ['cancel-expanded'],
      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: false,
      disableBackdropClick: true,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      this.isFullScreenOpened = false;
      this.cancellationChartConfig.height = '430px';
      this.cancellationChartConfig.groupGap = 40;
      // this.cancellationChartConfig.height =
      //   window.devicePixelRatio === 1.5 ? '67%' : window.devicePixelRatio === 1 ? '40%' : '54%';
    });
  }
  setColumnWidth() {
    this.cancellationChartConfig.groupGap = 40;
    if (window.innerWidth < 1000) {
      this.cancellationChartConfig.columnWidth = 4;
    } else if (window.innerWidth > 1000 && window.innerWidth <= 1100) {
      this.cancellationChartConfig.columnWidth = 15;
    } else if (window.innerWidth > 1100 && window.innerWidth <= 1200) {
      this.cancellationChartConfig.columnWidth = 18;
    } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
      this.cancellationChartConfig.columnWidth = 26;
    } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
      this.cancellationChartConfig.columnWidth = 30;
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1500) {
      this.cancellationChartConfig.columnWidth = 40;
    } else if (window.innerWidth > 1500 && window.innerWidth <= 1600) {
      this.cancellationChartConfig.columnWidth = 40;
    } else {
      this.cancellationChartConfig.groupGap = 70;
      this.cancellationChartConfig.columnWidth = 70;
    }
  }
}
