import { Component, Input, OnInit, OnChanges, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { SubscribeAccountsService } from '../../../services/subscribe-accounts.service';
import { AccountFormType } from '../accounts-interface';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-search-cws-results',
  templateUrl: './search-cws-results.component.html',
  styleUrls: ['./search-cws-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchCwsResultsComponent implements OnInit, OnChanges {
  @Input() users: any;
  @Input() selectedData!: AccountFormType;
  @Input() parentType = '';
  @ViewChild('deleteUserWarningModal') deleteUserWarningModal: any;
  public sortingForm!: FormGroup;
  selectionLimit = 20;
  selectedCustomerLicenseType = '';
  public subscribedUsers: Array<any> = [];
  public currentUsersList: Array<any> = [];
  public searchedUsers: Array<any> = [];
  public selectedUsers: Array<any> = [];
  public pageNumber = [
    { label: 'A to Z', value: 'asc' },
    { label: 'Z to A', value: 'desc' },
  ];
  deleteUserWarningModalRef!: CcModalRef<any, any>;
  removingUser: any;
  deleteUserModalText = '';
  constructor(private subscribeAccountsService: SubscribeAccountsService, private modal: CcModal) {}
  ngOnChanges(): void {
    this.searchedUsers = this.users;
    if (this.parentType === 'edit-plan') {
      this.currentUsersList = this.selectedData.currentUsers;
    }
    this.searchSort();
  }

  ngOnInit(): void {
    this.searchedUsers = this.users;
    this.selectedUsers = this.selectedData?.selectedUsers || [];
    this.subscribedUsers = cloneDeep(this.selectedUsers);
    if (this.parentType === 'edit-plan') {
      this.currentUsersList = cloneDeep(this.selectedUsers);
    }
    this.sortingForm = this.initializeForm();
  }
  initializeForm() {
    const formControls = {
      sortingInSearchData: new FormControl([this.pageNumber[0]]),
      sortingInSelectedData: new FormControl([this.pageNumber[0]]),
    };
    return new FormGroup(formControls);
  }

  sortingFunction(orderBy: string, array: Array<any>) {
    if (orderBy == 'asc') {
      //ascending order
      const sorted = array?.sort((a, b) => {
        const fa = a.firstName?.toLowerCase(),
          fb = b.firstName?.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return sorted;
    } else {
      //descending order
      const sorted = array.sort((a, b) => {
        const fa = a.firstName?.toLowerCase(),
          fb = b.firstName?.toLowerCase();

        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      });
      return sorted;
    }
  }
  searchSort() {
    this.searchedUsers = this.sortingFunction(
      this.sortingForm?.controls['sortingInSearchData'].value[0].value,
      this.searchedUsers
    );
  }
  selectSort() {
    this.selectedUsers = this.sortingFunction(
      this.sortingForm?.controls['sortingInSelectedData'].value[0].value,
      this.selectedUsers
    );
  }
  updateSelectedUsers(data: any) {
    this.selectedUsers = data;
  }
  updateSubscribedUsers(data: any) {
    this.subscribedUsers = data;
    this.selectedData.subscribedUsers = this.subscribedUsers;
  }
  updateCurrentUsersList(data: any) {
    this.currentUsersList = data;
    this.selectedData.subscribedUsers = this.currentUsersList;
  }
  updateSubscribedStatus(user: any) {
    return this.currentUsersList?.some((subscribedUser: any) => {
      return subscribedUser.catRecId === user.catRecId;
    });
  }
  handleAddUser(user: any) {
    const selectedUserIndex = this.searchedUsers.findIndex(function (element: any) {
      return element.catRecId == user.catRecId;
    });
    const addUserDetail = this.searchedUsers.splice(selectedUserIndex, 1);
    if (addUserDetail && addUserDetail.length == 1) {
      this.selectedUsers.push(user);
      this.selectSort();
      this.selectedData.isValidForm = true;
      this.selectedData.selectedUsers = this.selectedUsers;
      this.subscribeAccountsService.setAccountFormData(this.selectedData);
      if (this.subscribedUsers && this.selectedCustomerLicenseType == '') {
        this.updateSubscribedUserAddOperation(user);
      }
    }
  }

  updateSubscribedUserAddOperation(addUser: any) {
    this.checkWithCurrentUserList(addUser);
    this.updateSubscribedUsers(this.subscribedUsers);
  }

  handleRemoveUser(user: any) {
    this.deleteUserModalText = '';
    const removedUserIndex = this.selectedUsers.findIndex(function (element) {
      return element.catRecId == user.catRecId;
    });
    this.selectedUsers.splice(removedUserIndex, 1);
    this.searchedUsers?.push(user);
    this.searchSort();
    if (this.selectedUsers.length > 0) {
      this.selectedData.isValidForm = true;
    } else {
      this.selectedData.isValidForm = false;
    }
    this.selectedData.selectedUsers = this.selectedUsers;
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
    if (this.subscribedUsers && this.subscribedUsers.length > 0) {
      this.updateSubscribedUserRemoveOperation(user);
    }
  }

  updateSubscribedUserRemoveOperation(user: any) {
    const removedSubscribedUserIndex = this.subscribedUsers.findIndex(function (element: any) {
      return element.catRecId == user.catRecId;
    });
    if (removedSubscribedUserIndex >= 0) {
      if (this.validUserRemovalCheck(user)) {
        this.subscribedUsers[removedSubscribedUserIndex].userOperation = 'REMOVE';
      } else {
        this.subscribedUsers.splice(removedSubscribedUserIndex, 1);
      }
      this.updateSubscribedUsers(this.subscribedUsers);
    }
  }

  validUserRemovalCheck(user: any) {
    return this.currentUsersList?.some((currentUser: any) => {
      return currentUser.catRecId === user.catRecId;
    });
  }

  removeAllUsers() {
    this.selectedData.isValidForm = false;
    const selectedUsersList = cloneDeep(this.selectedUsers);
    selectedUsersList.forEach((user: any) => {
      this.searchedUsers?.push(user);
      this.searchSort();
      const removedUserIndex = this.selectedUsers.findIndex(function (element) {
        return element.catRecId == user.catRecId;
      });
      this.selectedUsers.splice(removedUserIndex, 1);
      if (this.subscribedUsers && this.subscribedUsers.length > 0) {
        this.updateSubscribedUserRemoveOperation(user);
      }
    });
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  checkWithCurrentUserList(user: any) {
    const existingUserIndex = this.subscribedUsers?.findIndex(function (currentUser: any) {
      return currentUser.catRecId == user.catRecId;
    });
    if (existingUserIndex != -1) {
      this.subscribedUsers.splice(existingUserIndex, 1);
      user.userOperation = null;
      this.subscribedUsers.push(user);
    } else {
      user.userOperation = 'ADD';
      this.subscribedUsers.push(user);
    }
  }

  disableAddUser(user: any) {
    return user.isSubscribed || this.selectedUsers.length === 25;
  }
}
