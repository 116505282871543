import { Component, Inject, OnInit, ViewEncapsulation, NgModule, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DspCommonService } from '../../services/dsp-common.service';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { loadUserMenuRequestAction } from '../../store/actions/user-info.actions';
import { DSPAppState } from '../../store/state/dsp.state';
import { UserPreferenceService } from '../../user-preference.service';
import * as dspConstants from '../../../app/shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserPreferenceComponent implements OnInit {
  public landingPages: any[] = [];
  public landingPageForm!: FormGroup;
  selectedPath = '';
  currentSelectedItem = '';
  currentSelectedTimeZones = '';
  listOfTimeZones: any;
  dspStoreData: any;
  isTimeZoneSelectionEnabled: any;
  defaultTimeZone: any;
  selectedTimeZoneValue: any;
  selectedMenuId: any;
  landingPageLabel: any;
  userRole: any;
  selectedNumberFormat: any;
  selectedTimeFormat: any;
  selectedDateFormat: any;
  isNumberConventionEnabled: any;
  defaultNumberFormat: any;
  defaultTimeFormat: any;
  defaultDateFormat: any;
  isTimeFormatEnabled: any;
  HomePageFeatureEnabled: any;
  dateFormat = [
    { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
    { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
    { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  ];
  timeFormat = [
    { label: '24 Hours (13:00:00)', value: '24 hours' },
    { label: '12 Hours (1:00:00 PM)', value: '12 hours' },
  ];
  siteObj: any;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private modalRef: CcModalRef<UserPreferenceComponent>,
    private formBuilder: FormBuilder,
    private messageBar: MessageBar,
    private router: Router,
    private apiService: DspCommonService,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.userRole = this.dspStoreData?.userDetails?.roleName;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;

      this.isNumberConventionEnabled = this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        : false;

      this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        : false;

      this.HomePageFeatureEnabled = this.dspStoreData?.featureFlagInfo?.HomePageFeatureEnabled
        ? this.dspStoreData?.featureFlagInfo?.HomePageFeatureEnabled
        : false;
    });
    this.generateLandingPageList(this.data.landingPages);
    for (const menuObj of this.landingPages) {
      if (menuObj.value.isDefault) {
        this.selectedMenuId = menuObj?.value?.menuId;
        this.selectedPath = menuObj?.value?.path;
        this.landingPageLabel = menuObj?.label;
        this.currentSelectedItem = this.landingPageLabel;
      }
    }
    if (!this.selectedPath) {
      const currentPage = this.HomePageFeatureEnabled
        ? this.landingPages.find((item: any) => item.label?.toUpperCase() === 'HOME')
        : this.landingPages.find((item: any) => item.label?.toUpperCase() === 'DASHBOARD');
      this.selectedMenuId = currentPage?.value?.menuId;
      this.selectedPath = currentPage?.label;
      this.landingPageLabel = currentPage?.label;
    }
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.userRole = this.dspStoreData?.userDetails?.roleName;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;

      this.isNumberConventionEnabled = this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isNumberConventionEnabled
        : false;

      this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        : false;
    });
    if (this.isTimeZoneSelectionEnabled) {
      this.listOfTimeZones = this.userPreferenceService.getTimeZonesValues();
      this.listOfTimeZones?.map((timeZone: any) => {
        if (timeZone.value === this.userPreferenceService.getDefaultTimeZone()) {
          this.selectedTimeZoneValue = [timeZone];
          this.currentSelectedTimeZones = this.selectedTimeZoneValue[0].value;
          this.defaultTimeZone = timeZone.label;
          this.userPreferenceService.updateSelectedTimeZone([timeZone]);
        }
      });
    }
    if (this.isNumberConventionEnabled) {
      this.defaultNumberFormat = this.userPreferenceService.getNumberFormat();
      this.selectedNumberFormat = this.userPreferenceService.getNumberFormat();
    }

    if (this.isTimeFormatEnabled) {
      this.defaultTimeFormat = this.userPreferenceService.getTimeFormat();
      this.selectedTimeFormat = this.defaultTimeFormat;
      this.defaultDateFormat = this.userPreferenceService.getDateFormat();
      this.selectedDateFormat = this.defaultDateFormat;
    }
    this.landingPageForm = this.formBuilder.group({
      landingPage: [this.landingPageLabel],
      timeZones: [this.defaultTimeZone],
      numberFormat: [this.defaultNumberFormat],
      timeFormat: [this.defaultTimeFormat],
      dateFormat: [this.defaultDateFormat],
    });
  }

  checkIfDisabled() {
    if (this.isNumberConventionEnabled) {
      if (this.currentSelectedItem !== this.landingPageLabel && this.currentSelectedItem !== '') {
        return false;
      }
      if (
        this.selectedTimeZoneValue &&
        this.selectedTimeZoneValue[0]?.label !== this.defaultTimeZone &&
        this.selectedTimeZoneValue[0]?.label !== ''
      ) {
        return false;
      }
      if (this.selectedNumberFormat !== this.defaultNumberFormat) {
        return false;
      }
      if (this.defaultTimeFormat !== this.selectedTimeFormat) {
        return false;
      }
      if (this.defaultDateFormat !== this.selectedDateFormat) {
        return false;
      }
    } else {
      return this.currentSelectedItem === '' || this.currentSelectedItem === this.selectedPath;
    }
    return true;
  }
  onDropdownChange({ currentSelection }: any, prop: string) {
    this.currentSelectedItem = currentSelection[0].label;
    this.selectedMenuId = currentSelection[0]?.value?.menuId;
    this.selectedPath = currentSelection[0]?.value?.pathName;
    this.landingPageForm.patchValue({
      [prop]: currentSelection,
    });
  }
  onTimeZoneDropdownChange({ currentSelection }: any, prop: string) {
    this.currentSelectedTimeZones = currentSelection[0].value;
    this.landingPageForm.patchValue({
      [prop]: currentSelection,
    });
    this.selectedTimeZoneValue = currentSelection;
  }

  onNumberFormatRadioChange(event: any, prop: string) {
    this.landingPageForm.patchValue({
      [prop]: event.value,
    });
    this.selectedNumberFormat = event.value;
  }

  timeDateFormatRadioChange(event: any, prop: string) {
    this.landingPageForm.patchValue({
      [prop]: event.value,
    });
    if (prop === 'timeFormat') {
      this.selectedTimeFormat = event.value;
    } else {
      this.selectedDateFormat = event.value;
    }
  }

  onClickClose() {
    this.modalRef.close(false);
  }

  onSavePreference() {
    this.siteObj = {
      menuId: this.selectedMenuId,
      timeZonePreference: this.currentSelectedTimeZones,
      numberFormat: this.selectedNumberFormat,
    };
    if (this.isTimeFormatEnabled) {
      this.siteObj.timeFormat = this.selectedTimeFormat;
      this.siteObj.dateFormat = this.selectedDateFormat;
    }
    this.selectedPath =
      this.userRole === dspConstants.Common.DSP_FACTORY_USER
        ? 'factoryuser'
        : this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
            this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
            this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED
          ? 'dft'
          : this.selectedPath;
    if (this.isNumberConventionEnabled) {
      this.savePreference(this.siteObj);
    } else {
      this.apiService.updateUserPreference(this.selectedMenuId, this.siteObj).subscribe({
        next: (_res: any) => {
          // this.store.dispatch(loadUserMenuRequestAction());
          this.router.navigate([
            '/' + this.selectedPath ? this.selectedPath : this.HomePageFeatureEnabled ? 'home' : 'dashboard',
          ]);
          this.modalRef.close(true);
          const messageText = `Changes saved successfully.`;
          this.showToastNotification(messageText, 'success');
        },
        error: (err: any) => {
          const messageText = `Changes not saved.`;
          this.showToastNotification(messageText, 'error');
        },
      });
    }
    // }
  }
  savePreference(payload: any) {
    this.apiService.saveUserPreference(payload).subscribe({
      next: (_res: any) => {
        this.userPreferenceService.updateNumberFormat(this.selectedNumberFormat);
        this.userPreferenceService.updateSelectedTimeZone(this.selectedTimeZoneValue);
        this.userPreferenceService.updateTimeFormat(this.selectedTimeFormat);
        this.userPreferenceService.updateDateformat(this.selectedDateFormat);
        this.userPreferenceService.updateChangeEvent();
        if (this.currentSelectedItem !== this.landingPageLabel) {
          this.router.navigate([
            '/' + this.selectedPath ? this.selectedPath : this.HomePageFeatureEnabled ? 'home' : 'dashboard',
          ]);
        }
        this.modalRef.close(true);
        const messageText = `Changes saved successfully.`;
        this.showToastNotification(messageText, 'success');
      },
      error: (err: any) => {
        const messageText = `Changes not saved.`;
        this.showToastNotification(messageText, 'error');
      },
    });
  }

  showToastNotification(message: string, type: any) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, type, undefined, config, true);
  }
  generateLandingPageList(menuItems: any) {
    menuItems.forEach((item: any) => {
      if (item.children.length > 0) {
        item.children.forEach((childItem: any) => {
          this.landingPages.push({
            label: `${item.name} > ${childItem.name}`,
            value: childItem,
          });
        });
      } else {
        this.landingPages.push({ label: item.name, value: item });
      }
    });
  }

  checkUserRole() {
    if (
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN ||
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_BASIC ||
      this.userRole === dspConstants.Common.DEALER_FIELD_TECHNICIAN_ADVANCED ||
      this.userRole === dspConstants.Common.DSP_FACTORY_USER
    ) {
      return false;
    }
    return true;
  }
}
