<div class="cc-drawer">
  <div
    class="cc-drawer__header"
    [ngClass]="{
      'cc-drawer__withActions': headerContent === 'withActions'
    }"
  >
    <ng-container *ngIf="headerContent === 'withActions'" [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <div class="showAllValues" *ngIf="title === 'Month'">
      <!-- <div class="filter-checkbox-label">
        <cc-checkbox
          [checked]="masterSelected"
          [indeterminate]="indeterminate"
          (toggleChange)="checkUncheckAllFilters($event)"
          >Select All</cc-checkbox
        >
      </div> -->
      <div *ngFor="let value of values" class="checkbox">
        <cc-checkbox
          *ngIf="savedFilters && savedFilters.month && savedFilters.month.indexOf(value.month) !== -1"
          class="filter-attribute-checkbox"
          [checked]="true"
          (toggleChange)="selectedMonthFilter($event, value)"
        >
          {{ value.month }}
        </cc-checkbox>
        <cc-checkbox
          class="filter-attribute-checkbox"
          *ngIf="!savedFilters.month || savedFilters.month.indexOf(value.month) === -1"
          [checked]="
            (!savedFilters.month || savedFilters.month.indexOf(value.month) === -1) && flag ? false : value.checked
          "
          (toggleChange)="selectedMonthFilter($event, value)"
        >
          {{ value.month }}
        </cc-checkbox>
      </div>
    </div>
    <div class="showAllValues" *ngIf="title === 'Product'">
      <div *ngFor="let value of values" class="checkbox">
        <cc-checkbox
          *ngIf="savedFilters && savedFilters.application && savedFilters.application.indexOf(value.product) !== -1"
          [checked]="true"
          [disabled]="false"
          (toggleChange)="selectedProductFilter($event, value)"
        >
          {{ value.product }}
        </cc-checkbox>
        <cc-checkbox
          *ngIf="!savedFilters.application || savedFilters.application.indexOf(value.product) === -1"
          [checked]="value.checked"
          [disabled]="disableCheckbox(value)"
          (toggleChange)="selectedProductFilter($event, value)"
        >
          {{ value.product }}
        </cc-checkbox>
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-medium [setTheme]="true" class="applyChangesBtn" (click)="applyFilters()">
      Apply Changes
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <cc-icon class="drilldown-header__back" (click)="closeShowAllModal()">back-arrow</cc-icon>
  <span class="cc-drawer__ellipsis filters-title">{{ title }} </span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
