<div class="donut-chart-wrapper">
  <div class="header">
    <div class="left-header">Connectivity Summary</div>
    <div class="right-header">
      <div class="icons" (click)="expandedDonutView()">
        <cc-icon fontSet="icomoon">fullscreen</cc-icon>
      </div>
    </div>
  </div>
  <div
    class="chart-content"
    *ngIf="responseData && responseData.length"
    [ngClass]="{ 'total-align': extraClass === 1.5 }"
  >
    <cc-donut-chart
      [chartData]="donutChartData"
      [chartConfig]="chartConfig"
      [donutSize]="donutSizeOption"
      (seriesClick)="connectivityChartClick($event)"
    ></cc-donut-chart>
  </div>
  <div class="cls-loader" *ngIf="chartSpinner">
    <dsp-next-gen-ui-spot-loader></dsp-next-gen-ui-spot-loader>
  </div>

  <div *ngIf="!chartSpinner && responseData && responseData.length === 0" class="no-results-found">
    <img src="../../../../../assets/images/no-data.png" width="198" height="132" />

    <p class="no-results-text">No data to display</p>
  </div>
</div>
