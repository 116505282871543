import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core/drawer';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { DspCommonService } from '../../../../services/dsp-common.service';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { AssetDrawerComponent } from '../../asset-drawer/asset-drawer.component';
import { LastCheckinInfo } from '../../asset-interface';
import { AssetService } from '../../services/asset.service';
import { DatePipe } from '@angular/common';
import { RequestRemoteConnectionCheckPayload } from '../../asset-interface';
import { delay, expand, Subscription, takeLast } from 'rxjs';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { NeedsReviewService } from 'libs/needs-review/src/lib/services/needs-review.service';
import { ManageAssetSubscriptionComponent } from '../../manage-asset-subscription/manage-asset-subscription.component';
import { ManagePlanComponent } from '../../manage-plan/manage-plan.component';
import { Router } from '@angular/router';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { DATA_LAYER_OBJECT } from 'apps/dsp-ui/src/app/google-analyatics.interface';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { ManagePendingRequestComponent } from '../../../manage-pending-request/manage-pending-request.component';
@Component({
  selector: 'dsp-next-gen-ui-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetInfoComponent implements OnInit {
  @Output() refreshTableOnManagePlan = new EventEmitter();
  assetDrawerRef!: any;
  public value: any;
  public dealer: any;
  public rowData: any;
  public rowDataThirdParty: any;

  public customData: any;
  public columnWithHeader: any;
  public searchString = '';
  public connectionStatus: any = [];
  dspConfig: any;
  cvaInfo = {};
  isGetEDandPSRUrlDone = false;
  lastCheckinDateAndTimeArray!: string[];
  lastCheckinInfo!: LastCheckinInfo;
  lastCheckinValue: any;
  convertToUTCTime: any;
  showConnectionCheckIcon = false;
  userActiveFeatures: any;
  deviceFeaturesInfo: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  listForConnectionSecurity = [
    'PLE702',
    'PLE601',
    'PLE631',
    'PLE602',
    'PLE642',
    'PLE643',
    'PLE632',
    'PLE702',
    'PLE742',
    'PLE743',
    'PLE732',
  ];
  connectionCheck: any;
  myObj: any;
  assetConnectionCheck: Array<object> = [];
  storedValues!: Array<any>;
  storedList: any;
  dspWorklistConstants = dspConstants.Worklist;
  dspNeedsReviewConstants = dspConstants.NeedsReview;
  queueReasonId: any;
  queueReasonSubscription!: Subscription;
  isDeviceStatusAPIInProgress = true;
  manageAssetPlanDrawerRef: any;
  dspStoreData!: any;
  isShowManagePlanIcon = false;
  showTruncatedText = false;
  isMultiProductWorkFlowEnabled = false;
  isHighlightMakeModelYear: boolean = true;
  isTimeZoneSelectionEnabled: any;
  isTimeFormatEnabled: any;
  isEnableForwardSlashInPID = false;
  thirdPartySerialNo: any;
  isCcatSearch = false;
  selectedQueueReason: any;
  managePendingRequestDrawerRef: any;
  isSelfServeSubscription: any;
  selectedQueueReasonLabel: any;
  queueReasonList: any;
  isCustRequestedSubscription: any = false;
  constructor(
    private drawerService: DrawerService,
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService,
    private assetDrawerService: AssetDrawerService,
    private assetService: AssetService,
    private datepipe: DatePipe,
    private changeDetector: ChangeDetectorRef,
    private needsReviewService: NeedsReviewService,
    private messageBar: MessageBar,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (['/asset'].includes(this.router.url)) {
      this.isHighlightMakeModelYear = false;
    } else if (['/needsreview'].includes(this.router.url)) {
      this.isHighlightMakeModelYear = true;
    }
    this.dealer = fetchLoggedInDealerDetails();
    this.rowData = this.value;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.userActiveFeatures = dsp.userActiveFeatures;
      this.dspConfig = dsp.dspConfig;
      this.deviceFeaturesInfo = dsp.deviceFeaturesInfo;
      this.isTimeZoneSelectionEnabled = this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeZoneSelectionEnabled
        : false;
      this.isTimeFormatEnabled = this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        ? this.dspStoreData?.featureFlagInfo?.isTimeFormatEnabled
        : false;
      this.isMultiProductWorkFlowEnabled = this.dspStoreData?.multiProductFeatureFlagInfo?.data
        ? this.dspStoreData?.multiProductFeatureFlagInfo?.data
        : false;
      this.isEnableForwardSlashInPID = this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        ? this.dspStoreData?.featureFlagInfo?.isEnableForwardSlashInPID
        : false;
      this.isCcatSearch = this.dspStoreData?.featureFlagInfo?.isCcatSearch
        ? this.dspStoreData?.featureFlagInfo?.isCcatSearch
        : false;
      this.isSelfServeSubscription = this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        ? this.dspStoreData?.featureFlagInfo?.isSelfServeSubscription
        : false;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
    this.isShowManagePlanIcon = this.dspStoreData?.featureFlagInfo?.isShowManagePlanIcon
      ? this.dspStoreData?.featureFlagInfo?.isShowManagePlanIcon
      : false;
    if (this.isCcatSearch) {
      this.thirdPartySerialNo = this.assetService.getThirdPartySerialNumber();
      this.thirdParty();
    }
    this.queueReasonList = this.dspConfig?.Queue_Reason_List ? JSON.parse(this.dspConfig.Queue_Reason_List) : [];
    this.isConnectionCheckIconNeeded();
    let savedConnectionStatus = sessionStorage['Assets'] ? JSON.parse(sessionStorage['Assets']) : [];
    this.getSavedConnectionCheckStatus(this.rowData.make, this.rowData.serialNumber, savedConnectionStatus);
    if (this.assetService?.getConnectionStatus()) {
      this.getSavedConnectionCheckStatus(
        this.rowData.make,
        this.rowData.serialNumber,
        this.assetService.getConnectionStatus()
      );
    }

    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.customData = this.columnWithHeader?.customData;
    this.queueReasonSubscription = this.needsReviewService.queueReason.subscribe((data: any) => {
      this.selectedQueueReason = data.dealerQueueReason;
      this.queueReasonId = data.dealerQueueReasonId;
      this.selectedQueueReasonLabel = data.dealerQueueReason;
    });
    this.fetchDealerDetails(this.rowData);
  }
  fetchDealerDetails(assetDetails: any) {
    const dealerInfoPayload = {
      assetIds: [assetDetails?.assetId] || [],
      dealerCode: this.dealer?.dealerCode || '',
    };
    this.assetDrawerService.getDealerInfo(dealerInfoPayload).subscribe({
      next: (result: any) => {
        if (result) {
          this.rowData.dealerInfo = result;
          this.isCustRequestedSubscription = this.assetService.checkIfReasonListExists(
            this.queueReasonList,
            result[this.rowData.assetId]
          );
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }
  thirdParty(): void {
    let value: any = [];
    value.push(this.rowData);
    if (this.thirdPartySerialNo) {
      const sNo = value.find((item: any) => item.serialNumber === this.thirdPartySerialNo);
      if (sNo) {
        if (Object?.keys(sNo)?.length !== 0) {
          this.rowDataThirdParty = sNo;
          setTimeout(() => {
            this.openAssetDrawer();
          }, 1500);
        }
      }
    }
  }
  openAssetDrawer() {
    if (this.rowDataThirdParty) {
      this.rowData = this.rowDataThirdParty;
    }
    /**Added Asset status change from New to Active on click of connection check**/
    if (this.rowData.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.rowData, this.queueReasonId);
    }
    /**/
    this.assetDrawerRef = this.drawerService.openDrawer<AssetDrawerComponent, CcDrawerConfig>(AssetDrawerComponent, {
      data: {
        headerType: 'drawerContent',
        assetDetails: this.rowData,
      },
      enableBlackBackDrop: true,
      closeOnEsc: false,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.assetDrawerRef.afterClosed().subscribe((response: any) => {
      let savedConnectionStatus = this.assetService.getConnectionStatus();
      this.getSavedConnectionCheckStatus(this.rowData.make, this.rowData.serialNumber, savedConnectionStatus);

      if (response) {
        this.customData.refreshTable();
      }
      this.thirdPartySerialNo = '';

      this.assetService.setThirdPartySerialNumber('');
      this.assetService.setThirdPartymake('');
      window.localStorage.removeItem('thirdPartyApi');
    });
  }
  parseAssetNameData(rowData: any) {
    let assetInfo = '';
    if (rowData) {
      assetInfo += rowData.make ? rowData.make : '';
      assetInfo += rowData.model ? ' - ' + rowData.model : '';
      assetInfo += rowData.modelYear ? ' - ' + rowData.modelYear : '';
      return assetInfo;
    } else {
      return '';
    }
  }
  isConnectionCheckIconNeeded() {
    if (
      this.userActiveFeatures?.features &&
      this.userActiveFeatures?.features?.indexOf('Connection Check') > -1 &&
      this.deviceFeaturesInfo &&
      this.rowData.deviceType &&
      this.deviceFeaturesInfo[this.rowData.deviceType] &&
      this.deviceFeaturesInfo[this.rowData.deviceType].Connection_Check &&
      !(this.rowData.reportingState == this.dspWorklistConstants.SUSPENDED) &&
      (!this.deviceFeaturesInfo[this.rowData.deviceType].Radio_Check ||
        (this.deviceFeaturesInfo[this.rowData.deviceType].Radio_Check &&
          (this.rowData.cellularRadioSerialNumber !== '-' || this.rowData.satelliteRadioSerialNumber !== '-')))
    ) {
      if (
        this.listForConnectionSecurity.indexOf(this.rowData.deviceType) > -1 &&
        !(this.rowData.cellularRadioSerialNumber !== '-' || this.rowData.satelliteRadioSerialNumber !== '-')
      ) {
        this.showConnectionCheckIcon = false;
      } else {
        this.showConnectionCheckIcon = true;
        this.lastCheckinValue = this.getLastCheckInStatus(this.rowData.make, this.rowData.serialNumber);
      }
    }
  }
  getLastCheckInStatus(make: any, serialNumber: any) {
    const payloadObj = {
      make: make,
      serialNumber: serialNumber,
    };
    const deviceStatusPayload = this.isEnableForwardSlashInPID ? payloadObj : make + '/' + serialNumber;
    this.dspCommonService.getDeviceStatus(deviceStatusPayload).subscribe({
      next: (result: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.changeDetector.detectChanges();
        this.lastCheckinInfo = result;
        if (this.lastCheckinInfo?.lastCheckIn) {
          this.lastCheckinDateAndTimeArray = this.lastCheckinInfo?.lastCheckIn?.split(' ');
          if (this.isTimeZoneSelectionEnabled) {
            let convertedTime = this.userPreferenceService.convertTimeToSelectedZone(
              this.lastCheckinDateAndTimeArray[0] + ' ' + this.lastCheckinDateAndTimeArray[1],
              true
            );
            let lastCheckIn = convertedTime;
            if (this.isTimeFormatEnabled) {
              this.lastCheckinValue =
                this.userPreferenceService.convertDateTime(
                  lastCheckIn?.split(' ')[0] + ' ' + lastCheckIn?.split(' ')[1]
                ) +
                ' ' +
                convertedTime?.split(' ')[2];
            } else {
              this.lastCheckinValue = lastCheckIn;
            }
          } else {
            this.lastCheckinValue = `${this.lastCheckinDateAndTimeArray[0]}  ${this.lastCheckinDateAndTimeArray[1]} ${this.lastCheckinDateAndTimeArray[2]}`;
          }
          this.changeDetector.detectChanges();
        }
      },

      error: (err: any) => {
        this.isDeviceStatusAPIInProgress = false;
        this.changeDetector.detectChanges();
        this.lastCheckinValue = '';
      },
    });
    return this.lastCheckinValue || '';
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  getSavedConnectionCheckStatus(make: any, serialNumber: any, status: Array<any>) {
    if (status?.length > 0) {
      status?.forEach((value: any) => {
        value.forEach((val: any) => {
          if (val.eq_make === make && val.eq_sn === serialNumber) {
            this.connectionStatus = [val];
            this.changeDetector.detectChanges();
            if (this.connectionStatus && this.connectionStatus[0]?.connection_status === 'pending') {
              this.checkConnectionStatus(make, serialNumber);
            }
          }
        });
      });
    }
  }

  checkConnectionStatus(make: any, serialNumber: any) {
    const payload = { eq_make: make, eq_sn: serialNumber };
    this.assetService
      .getConnectionCheckStatus(payload)
      .pipe(
        expand((result: any) => {
          if (
            result &&
            result.remote_connection &&
            result.remote_connection[0] &&
            result.remote_connection[0].connection_status === 'pending'
          ) {
            this.connectionStatus = result.remote_connection;
            this.assetConnectionCheck.push(this.connectionStatus);
            sessionStorage.setItem('Assets', JSON.stringify(this.assetConnectionCheck));
            this.assetService.setConnectionStatus(this.connectionStatus);
            return this.assetService.getConnectionCheckStatus(payload).pipe(delay(30000));
          } else {
            this.connectionStatus = result && result.remote_connection;
            sessionStorage.setItem('Assets', '');
            this.changeDetector.detectChanges();
            return '';
          }
        }),
        takeLast(1)
      )
      .subscribe({
        next: (result: any) => {
          if (Object.keys(result).length > 0) {
            this.connectionStatus = result && result.remote_connection;

            if (this.connectionStatus[0].connection_status == 'success') {
              this.assetConnectionCheck.push(this.connectionStatus);
              this.lastCheckinValue = this.getLastCheckInStatus(make, serialNumber);
            } else {
              this.assetConnectionCheck.push(this.connectionStatus);
            }

            this.assetService.setConnectionStatus(this.connectionStatus);
            this.changeDetector.detectChanges();
          }
        },
        error: () => {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
          this.showToastMessage(this.systemError, 'error');
        },
      });
  }

  setDataLayer(make: any, serialNumber: any, status: any, failedReason: any) {
    let isNeedsReview =
      this.rowData?.status === this.dspNeedsReviewConstants.Active ||
      this.rowData?.status === this.dspNeedsReviewConstants.New;
    let object: DATA_LAYER_OBJECT = {
      event: 'formSubmitted',
      pagePath: window.location.href,
      formName: 'Connection Check',
      formContent: make + ' | ' + serialNumber,
      formContent2: '',
      formAdditionalContent: this.dealer?.dealerCode + ' | ' + 'Connection Check Icon Clicked',
      formStatus: status,
      formFieldCausingError: failedReason,
      formLocation: isNeedsReview ? 'Needs Review Page' : 'Manage Assets Page',
    };
    this.sharedService.updateDataLayer(object);
  }

  checkConnection(make: any, serialNumber: any) {
    /**Added Asset status change from New to Active on click of connection check**/
    if (this.rowData.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.rowData, this.queueReasonId);
    }
    /**/
    const payload: RequestRemoteConnectionCheckPayload = {
      eq_make: make,
      eq_sn: serialNumber,
    };
    this.assetService.getConnectionCheck(payload).subscribe({
      next: (result: any) => {
        if (
          result &&
          result.remote_connection &&
          result.remote_connection[0] &&
          result.remote_connection[0].status_code == 0
        ) {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'pending',
            },
          ];
          this.setDataLayer(make, serialNumber, 'success', '');
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
          this.changeDetector.detectChanges();
          this.checkConnectionStatus(make, serialNumber);
        } else {
          this.connectionStatus = [
            {
              eq_make: make,
              eq_sn: serialNumber,
              connection_status: 'failure',
            },
          ];
          this.setDataLayer(make, serialNumber, 'failed', this.systemError);
          this.assetConnectionCheck.push(this.connectionStatus);
          this.assetService.setConnectionStatus(this.connectionStatus);
        }
      },
      error: () => {
        this.connectionStatus = [
          {
            eq_make: make,
            eq_sn: serialNumber,
            connection_status: 'failure',
          },
        ];
        this.setDataLayer(make, serialNumber, 'failed', this.systemError);
        this.assetConnectionCheck.push(this.connectionStatus);
        this.assetService.setConnectionStatus(this.connectionStatus);
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  getColor(status: any) {
    switch (status) {
      case 'success': {
        return 'green';
      }
      case 'pending': {
        return 'amber icon';
      }
      case 'failure': {
        return 'red';
      }
      default: {
        return '2595ff';
      }
    }
  }
  refreshOnPID() {
    this.customData.refreshTable();
  }
  refreshOnManagePlan() {
    this.customData.refreshTable();
  }

  managePlanClicked() {
    if (this.isCustRequestedSubscription && this.isSelfServeSubscription) {
      // pending request flow
      this.openManagePendingRequest();
    } else {
      //usual subscription flow
      this.openManagePlan();
    }
  }

  openManagePlan() {
    if (this.rowData?.status && this.rowData?.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.rowData, this.queueReasonId);
    }

    if (this.isMultiProductWorkFlowEnabled) {
      //multi product
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManagePlanComponent, CcDrawerConfig>(
        ManagePlanComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: this.rowData ? this.rowData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: true,
          id: 'multiSizeDrawer',
          disableBackdropClick: false,
          drawerSize: CcDrawerSize.LARGE,
        }
      );
      this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
        if (response) {
          this.refreshOnManagePlan();
        }
        this.assetService.setThirdPartySerialNumber('');
        window.localStorage.removeItem('thirdPartyApi');
      });
    } else {
      //single product
      this.manageAssetPlanDrawerRef = this.drawerService.openDrawer<ManageAssetSubscriptionComponent, CcDrawerConfig>(
        ManageAssetSubscriptionComponent,
        {
          data: {
            type: 'LANDING',
            selectedAssetDetails: this.rowData ? this.rowData : {},
          },
          enableBlackBackDrop: true,
          closeOnEsc: false,
          id: 'multiSizeDrawer',
          disableBackdropClick: true,
          drawerSize: CcDrawerSize.LARGE,
        }
      );
      this.manageAssetPlanDrawerRef.afterClosed().subscribe((response: any) => {
        if (response) {
          this.refreshOnManagePlan();
        }
        this.assetService.setThirdPartySerialNumber('');
        window.localStorage.removeItem('thirdPartyApi');
      });
    }
  }

  openManagePendingRequest() {
    if (this.rowData?.status && this.rowData?.status == this.dspNeedsReviewConstants.New) {
      this.needsReviewService.statusChangeEvent(this.rowData, this.queueReasonId);
    }
    this.managePendingRequestDrawerRef = this.drawerService.openDrawer<ManagePendingRequestComponent, CcDrawerConfig>(
      ManagePendingRequestComponent,
      {
        data: {
          type: 'LANDING',
          selectedAssetDetails: this.rowData ? this.rowData : {},
        },
        enableBlackBackDrop: true,
        closeOnEsc: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: false,
        drawerSize: CcDrawerSize.LARGE,
      }
    );

    this.managePendingRequestDrawerRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.refreshOnManagePlan();
      }
    });
  }
}
