import { Component, Inject } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef, CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { PrivacyModalComponent } from '../privacy-modal/privacy-modal.component';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared.service';
@Component({
  selector: 'dsp-next-gen-ui-legal-modal',
  templateUrl: './legal-modal.component.html',
  styleUrls: ['./legal-modal.component.scss'],
})
export class LegalModalComponent {
  public subscription: Subscription = new Subscription();
  browserRefresh = false;

  constructor(private modal: CcModal, public router: Router, private sharedService: SharedService) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['privacyModal']);
        }
      }
    });
  }

  dynamicUrl = environment.redirectURL.hostBaseUrl;

  privacyModal() {
    this.router.navigate(['privacyModal']);
    this.sharedService.setNavigationValue('privacyModal');
  }
}
