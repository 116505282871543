import { createReducer, on } from '@ngrx/store';
import { setInitialNRDFItem, setSelectedNeedsReviewItem } from '../../actions/dashboard-needs-review.actions';
import { needsReviewDashboardFilter } from '../../entities/entity';

export interface needsReviewDashboardFilterState {
  needsReviewDashboardFilter: needsReviewDashboardFilter[];
}
export const initialNRDFState: needsReviewDashboardFilterState = {
  needsReviewDashboardFilter: [
    {
      id: 0,
      label: '',
      navigateTo: '',
      scopeParams: {
        dealerQueueReason: '',
        dealerQueueReasonId: '',
      },
      value: '',
    },
  ],
};

export const needsReviewDashFilterReducer = createReducer(
  initialNRDFState,

  on(setInitialNRDFItem, (state, action): needsReviewDashboardFilterState => {
    return { ...state, needsReviewDashboardFilter: action.nrd };
  }),

  on(setSelectedNeedsReviewItem, (state, action): needsReviewDashboardFilterState => {
    return { ...state, needsReviewDashboardFilter: action.nrd };
  })
);
