import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core';
import { Router } from '@angular/router';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { DashboardService } from '../../services/dashboard.service';
@Component({
  selector: 'dsp-next-gen-ui-needs-review-expand-view',
  templateUrl: './needs-review-expand-view.component.html',
  styleUrls: ['./needs-review-expand-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedsReviewExpandViewComponent implements OnInit {
  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  nodata = false;
  finalData: any;
  xAxis: any = [];
  dspStoreData!: any;
  filterSelectedData: any;
  needsReviewChartData = {
    series: [
      {
        data: [],
        minPointLength: 5,
      },
    ],
  };
  needsReviewChartConfig = {
    // width: window.devicePixelRatio === 1.5 ? 495 : window.devicePixelRatio === 1 ? 805 : 610,
    height: '430px',
    styledMode: false,
    WrapXaxisLabel: true,
    groupGap: 40,

    xAxis: {
      categories: this.xAxis,
      gridLineDashStyle: 'Dash', // values = 'Solid','ShortDash','ShortDot','ShortDashDot','ShortDashDotDot','Dot','Dash','LongDash','DashDot','LongDashDot','LongDashDotDot'
      gridLineWidth: 1, // width of grid
      gridLineColor: '#e2e2e2', // to apply gridline color
      useLabelHTML: true, // make it to true to apply the styles object
      align: 'left',
      reserveSpace: true,
      style: {
        width: '350px',
        // 'white-space': 'wrap !important',
        // overflow: 'hidden',
        // 'text-overflow': 'ellipsis',
      }, //  style object
    },
    columnWidth: 40,
    yAxis: { enabled: false, visible: false },
    legend: { enabled: false },
    //dataLabelsInside: true,
    tooltip: {
      useXAxisLabel: true,
      padding: 5,
      shared: false,
      useHTML: true,
      tooltipseperator: ' |',
      hideColor: true,
    },
    dataLabels: true,
    dataLabelsCrop: false,
    dataLabelsOverflow: 'allow',
    scrollbar: {
      max_Axis: 6,
      scrollButtonHide: true,
      scrollBarWidth: 4,
    },
  };
  filterIcon = false;
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private router: Router,
    private modalRef: CcModalRef<NeedsReviewExpandViewComponent>,
    private store: Store<DSPAppState>,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
    this.store.select('needsReviewDashboardFilter').subscribe((filter) => {
      this.finalData = filter.needsReviewDashboardFilter;
      this.filterSelectedData = filter.needsReviewDashboardFilter?.filter((res: any) => res.checked);

      if (this.filterSelectedData.length) {
        this.filterIcon = true;
        this.mapReviewFiltersChart(this.filterSelectedData);
      } else {
        this.filterIcon = false;
        this.mapReviewFiltersChart(this.finalData);
      }
      if (this.finalData && this.finalData?.length === 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
    });
  }

  closeModel() {
    this.modalRef.close();
  }
  needsreviewChartClick(event: any) {
    sessionStorage.setItem('dealerQueueReason', event.point.category);
    this.router.navigate(['/needsreview']);
    this.modalRef.close();
  }
  openFilter(type?: string) {
    this.dashboardService.openFilterClicked.next(true);
  }
  mapReviewFiltersChart(response: any) {
    this.needsReviewChartConfig.groupGap = 100;
    this.needsReviewChartConfig.columnWidth = 26;
    this.needsReviewChartConfig.height = window.innerHeight - 76 + 'px';
    const chartValue: any = [];
    const chartLabel: any = [];
    response.map((item: any) => {
      chartValue.push(Number(item.value));
      chartLabel.push(item.label);
    });
    this.needsReviewChartData = { series: [{ data: chartValue, minPointLength: 5 }] };
    this.needsReviewChartConfig.xAxis.categories = [...new Set(chartLabel)];
    this.cdr.detectChanges();
  }
}
