import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { AdditionalCwsIdComponent } from '../additional-cws-id/additional-cws-id.component';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Common, OrgSubs } from '../../../shared/dspConstants';
import { ProductPlanInfoModal } from '../accounts-interface';
import { SharedService } from '../../../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-account-product-plan-info',
  templateUrl: './account-product-plan-info.component.html',
  styleUrls: ['./account-product-plan-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountProductPlanInfoComponent implements OnInit, OnChanges {
  @Input() accountData: any;
  @Input() ref: any;
  @Input() planConfig: any;
  activeStatus = false;
  accountDataInput: any;
  inactiveStatus = false;
  showTruncatedText = false;
  dspStoreData: any;
  statusText: any;
  addtionalcwsID: any;
  viewSubsTooltipContent = 'This plan is managed by the customer. You will not be able to edit this through DSP.';
  limitedHeading = 'PRODUCT | PLAN AND ACCOUNT';
  unlimitedHeading = 'PRODUCT | PLAN';
  productPlanNames: ProductPlanInfoModal = {};
  subscribeAccountsConst: any;

  constructor(
    private drawerService: DrawerService,
    private store: Store<DSPAppState>,
    private sharedService: SharedService
  ) {
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      const config = dsp.dspConfig;
    });
    this.showTruncatedText = this.dspStoreData?.featureFlagInfo?.showTruncatedText
      ? this.dspStoreData?.featureFlagInfo?.showTruncatedText
      : false;
  }
  ngOnChanges(): void {
    this.accountDataInput = this.accountData;
    this.productPlanNames = this.planConfig?.productPlanInfo;
  }

  ngOnInit(): void {
    this.subscribeAccountsConst = { ...OrgSubs, ...Common };
    this.productPlanNames = this.planConfig?.productPlanInfo;
    this.accountDataInput = this.accountData;
  }
  parseUcNameData(name: string) {
    name = name ? name.trim() : name;
    if (name && name.length > 19) {
      return `${name.substring(0, 19)}...`;
    } else {
      return name;
    }
  }
  getCustomerTooltipData(ucName: string, ucid: string) {
    const productPlanInfo = ucName + ' - ' + ucid;

    if (productPlanInfo.length > 19) {
      return ucid + '-' + ucName;
    } else return '';
  }
  parseAccountCwsIDComponent(rowData: any) {
    let user = '';
    if (rowData.cwsId === null) {
      return (user = rowData.users && rowData.users.length ? rowData.users[0].cwsId : '-');
    } else {
      return (user = `${rowData?.cwsId}`);
    }
  }
  parseProductPlanData(planName: any, productName: any) {
    const data = `${planName + ' | ' + productName}`;
    // const data = `${'SIS2GO | Service Media Access'}`;

    // if (data && data.length > 20) {
    //   return `${data.substring(0, 20)}...`;
    // } else {
    //   return data;
    // }
    return data;
  }

  parseEndDate(data: any) {
    /** data is in the  format mm-dd-yyyy*/
    if (data !== null) {
      const dateList = data.split('-');
      const dateStr = dateList[2] + '-' + dateList[0] + '-' + dateList[1];
      const date = new Date(dateStr); //  yyy-mm-dd
      const monthName = date.toLocaleString('default', { month: 'short' });
      return monthName + ' ' + dateList[1] + ',' + ' ' + dateList[2];
    } else {
      return '-';
    }
  }
  openDrawerInMultiSize(type: string, cwsIds: any) {
    this.addtionalcwsID = this.drawerService.openDrawer<AdditionalCwsIdComponent, CcDrawerConfig>(
      AdditionalCwsIdComponent,
      {
        data: {
          headerType: 'view-detail-cwsid-content',
          listItems: cwsIds,
          ref: this.ref,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
  }
  customerInfo(ucid: string, ucName: string) {
    const custInfo = ucid.trim() + ucName.trim();
    if (custInfo.length > 22) {
      // return `${custInfo.substring(0, 22)}...`;
      return (ucid.trim() + ' - ' + ucName.trim()).slice(0, 22) + '...';
    } else {
      return ucid.trim() + ' - ' + ucName.trim();
    }
  }
  customerInfoTooltip(ucid: string, ucName: string) {
    const custInfo = ucid + ' - ' + ucName;
    if (custInfo.length) {
      return custInfo;
    } else {
      return '';
    }
  }

  dealerInfo(dcn: string, dcName: string) {
    const dealerInfo = dcn.trim() + dcName.trim();
    if (dealerInfo.length > 11) {
      return (dcn.trim() + ' - ' + dcName.trim()).slice(0, 11) + '...';
    } else {
      return dcn.trim() + ' - ' + dcName.trim();
    }
  }
  productPlanInfo(appName: string, name: string) {
    const productPlanInfo = appName.trim() + name.trim();
    if (productPlanInfo.length > 28) {
      return (appName.trim() + ' | ' + name.trim()).slice(0, 28) + '...';
    } else {
      return appName.trim() + ' | ' + name.trim();
    }
  }
  copyEventForCustomer(event: any, dealerCustName: string, dealerCustNo: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(
      event,
      clipboardText?.trim(),
      ':',
      dealerCustName.trim(),
      dealerCustNo.trim(),
      25,
      'Account Customer'
    );
  }
  copyEventForDCName(event: any, dealerCustName: string, dealerCustNo: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(
      event,
      clipboardText?.trim(),
      ':',
      dealerCustName.trim(),
      dealerCustNo.trim(),
      14,
      'Account Dealer Customer'
    );
  }
  copyEventForProductPlan(event: any, appName: string, name: string) {
    let clipboardText = window.getSelection()?.toString();
    this.sharedService.copyEvent(
      event,
      clipboardText?.trim(),
      'None',
      appName.trim(),
      name.trim(),
      31,
      'Account Product Plan'
    );
  }
  customerInfoInEdit(ucid: string, ucName: string) {
    if (ucName && ucName.length > 30) {
      return ucid + '-' + `${ucName.substring(0, 30)}...`;
    } else {
      return ucid + ' - ' + ucName;
    }
  }
}
