<div class="User-admin-wrapper">
  <span class="page-title">Search Users</span>
  <div class="input-field-container" [formGroup]="userAdminForm">
    <div>
      <cc-input-field
        id="cc-atom-textField"
        [inputtype]="'text'"
        [isDense]="true"
        [isTheme]="isTheme"
        [labelValue]="'CWS ID (Min 3 char)'"
      >
        <input
          input-field
          formControlName="cwsId"
          class="input-field"
          autocomplete="off"
          type="text"
          required
          aria-labelledby="Enter Text (Optional)"
          aria-describedby="Enter Text (Optional)"
        />
        <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
              fill="#DF3826"
            />
          </svg>
        </span>
      </cc-input-field>
      <span class="req-text">
        <span
          *ngIf="
            formControl['cwsId'].errors?.['minlength'] &&
            (formControl['cwsId'].touched || formControl['cwsId'].dirty)
          "
          >Minimum 3 characters</span
        >
      </span>
    </div>
    <span class="user-label-or">or</span>
    <div>
      <cc-input-field
        id="cc-atom-textField"
        [inputtype]="'text'"
        [isTheme]="isTheme"
        [isDense]="true"
        [labelValue]="'Last Name (Min 3 char)'"
      >
        <input
          input-field
          formControlName="lastName"
          class="input-field"
          autocomplete="off"
          type="text"
          required
          aria-labelledby="Enter Text (Optional)"
          aria-describedby="Enter Text (Optional)"
        />
      </cc-input-field>
      <span class="req-text">
        <span
          *ngIf="
            formControl['lastName'].errors?.['minlength'] &&
            (formControl['lastName'].touched || formControl['lastName'].dirty)
          "
          >Minimum 3 characters</span
        >
      </span>
    </div>
  </div>
  <div class="button-container">
    <button cc-btn-primary cc-btn-large [setTheme]="true" [disabled]="disableSearchBtn" (click)="onSearchUser()">
      Search
    </button>
    <button
      cc-btn-secondary-outline
      cc-btn-large
      [setTheme]="true"
      [disabled]="disableClearAllBtn"
      (click)="clearAll()"
    >
      Clear All
    </button>
  </div>
</div>

<div>
  <dsp-next-gen-ui-user-admin-row-expansion></dsp-next-gen-ui-user-admin-row-expansion>
</div>
