import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import * as dspConstants from '../shared/dspConstants';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionChangeService {
  isSubscriptionChanged(subsInfo: any) {
    if (subsInfo) {
      return subsInfo?.custSubs?.subscriptionLevel !== subsInfo?.custSubsPrev?.subscriptionLevel;
    } else {
      return false;
    }
  }
  checkProductFamilyExits(addon: any, productFamily: any) {
    const value = addon.split('~');
    const filterList = productFamily.filter(function (itm: any) {
      return itm.subscription == value[0].trim();
    });

    return filterList.length;
  }
  checkValidProductFamily(data: any) {
    const selectedAddonsKeys = Object.keys(data.profileBasedAdditionalServices.optionList);
    const productFamily = data.productFamily.productFamilyList; //backend data
    let isProductFamilyAddon = false;
    if (selectedAddonsKeys.length > 0 && productFamily.length > 0) {
      const filterdProductFamily = selectedAddonsKeys.filter((addonKey: any) => {
        return (
          data.profileBasedAdditionalServices.optionList[addonKey] &&
          this.checkProductFamilyExits(addonKey, productFamily)
        );
      });
      if (filterdProductFamily.length > 0) {
        isProductFamilyAddon = true;
      }
    }
    return isProductFamilyAddon;
  }
  isAddonChanged(data: any) {
    //if(!angular.equals(this.getAllIds(data.profileBasedAdditionalServices.optionList),this.getAllIds(data.profileBasedAdditionalServices.optionListPrev)))
    if (
      !isEqual(
        Object.keys(data.profileBasedAdditionalServices.optionList),
        Object.keys(data.profileBasedAdditionalServices.optionListPrev)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  getAllIds(obj: any) {
    const dataHldr = [];
    const values = Object.keys(obj);
    for (let i = 0; i < values.length; i++) {
      if (obj[values[i]] && values[i] != '0') {
        dataHldr.push(values[i]);
      }
    }
    return dataHldr;
  }

  isProductFamilyChangedFn(data: any, equipmentInfo: any, subsInfo: any) {
    if (
      data.selectedService == dspConstants.Worklist.MINESTAR &&
      data.productFamily?.productFamilyList?.length > 0 &&
      this.checkValidProductFamily(data)
    ) {
      if (data.productFamily?.selectedProductFamily == 'None') {
        return false;
      } else if (this.isAddonChanged(data) || equipmentInfo.siteId != subsInfo?.value?.siteId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
