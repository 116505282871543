import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, NavigationStart } from '@angular/router';
import { DspUtilsCommonService } from '../services/dsp-utils-common.service';
import { Location } from '@angular/common';
import { AssetService } from '../components/assets/services/asset.service';
import { isEmpty } from 'lodash-es';
import { fetchLoggedInDealerDetails } from './shared';
import { DSP_TOKEN } from '../auth/constants/dsp-ui.constants';
import { DspCommonService } from '../services/dsp-common.service';

@Injectable({
  providedIn: 'root',
})
class IAuthGuard {
  stateUrl: any;
  menuList: any;
  constructor(
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private dspCommonService: DspCommonService,
    private assetService: AssetService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const menuItems = this.dspUtilsCommonService.getValue('menuList');
    let menuItemsList = menuItems ? JSON.parse(menuItems) : menuItems;
    if (menuItemsList.length === 0) {
      let menuListSession = sessionStorage.getItem('menuList');
      menuItemsList = menuListSession ? JSON.parse(menuListSession) : menuListSession;
    }
    if (menuItemsList && menuItemsList.length) {
      let a: any;
      const isPresent = menuItemsList.some((el: any) => {
        if (el.children.length > 0) {
          el.children.forEach((child: any) => {
            const regex = /(\/[^?]+)\?make=(.+)/;
            const match = state.url.match(regex);
            if (match) {
              this.stateUrl = match[1];
            } else {
              this.stateUrl = state.url;
            }
            let thirdPartyApi = window.localStorage.getItem('thirdPartyApi');
            if (this.stateUrl === '/asset' && thirdPartyApi !== null && child.path === '/asset') {
              a = true;
            }
            if (this.stateUrl === '/asset' && thirdPartyApi !== null && child.path !== '/asset') {
              window.localStorage.removeItem('thirdPartyApi');
            }
            if (child.path == state.url) {
              a = true;
            }
          });
        }
        if (a) {
          return true;
        } else {
          return el.path == state.url;
        }
      });
      if (isPresent) {
        return true;
      }
    }
    // this.router.navigate(['/home']);

    return false;
  }
}
export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(IAuthGuard).canActivate(route, state);
};
