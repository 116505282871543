<div class="cc-drawer">
  <div class="cc-drawer__header">
    <span class="text"> Product | Plan </span>
    <cc-icon fontSet="icomoon" class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal()"
      >cross</cc-icon
    >
  </div>

  <div class="asset-container" *ngIf="seemoreOverlayUpdate">
    <div
      class="header-content asset-info"
      [cc-tooltip]="getColumnToolTipData()"
      [tooltipPosition]="'top'"
      [isEnableTooltioOnEllipsis]="true"
    >
      <span>{{ getAssetSerialNumber(seeMoreOverlayDetails) }}</span>
      <div class="asset-make">{{ parseAssetNameData(seeMoreOverlayDetails) }}</div>
    </div>
    <div class="status-content">
      <cc-icon [ngClass]="seeMoreOverlayDetails.reportingState" style="font-size: 15px; padding-right: 15px">{{
        seeMoreOverlayDetails.reportingState | lowercase
      }}</cc-icon>
      <span class="status-text">{{ seeMoreOverlayDetails.reportingState }}</span>
    </div>
  </div>

  <div class="cc-drawer__content product-block">
    <cc-list *ngIf="this.rowData && this.rowData.length > 1">
      <cc-list-item class="product">
        <span class="name-title">Product</span>
        <div class="plans-margin">
          <div class="left-border-style">
            <span class="planName"> {{ applicationName }} </span>
          </div>
        </div>
      </cc-list-item>
      <cc-list-item class="product">
        <span class="name-title">Base Plan</span>
        <div *ngIf="!data?.isMultiProductWorkFlow" class="plans-margin">
          <ng-container *ngFor="let product of rowData">
            <div *ngIf="product.type === 'Base Plan' || product.type === 'Base'" class="left-border-style">
              <p class="planName" *ngIf="showRatePlans && subsPricingEligible">
                {{ product?.planName ? product.planName : product.name }}&nbsp;-&nbsp;{{
                  convertNumberFormat(product?.amount)
                }}
              </p>
              <span class="planName" *ngIf="!showRatePlans || !subsPricingEligible">{{
                product?.planName ? product.planName : product.name
              }}</span>
            </div>
          </ng-container>
        </div>
        <div class="plans-margin" *ngIf="data?.isMultiProductWorkFlow">
          <ng-container *ngFor="let product of basePlanMp">
            <div *ngIf="product.type === 'Base'" class="left-border-style">
              <span class="planName" *ngIf="product?.amount && showRatePlans && subsPricingEligible"
                >{{ product?.planName }}&nbsp;-&nbsp;{{ convertNumberFormat(product?.amount) }}</span
              >
              <span class="planName" *ngIf="!product?.amount || !showRatePlans || !subsPricingEligible">{{
                product?.planName
              }}</span>
            </div>
          </ng-container>
        </div>
      </cc-list-item>

      <cc-list-item class="product">
        <div *ngIf="this.rowData.length > 1 && !data?.isMultiProductWorkFlow" class="cc-drawer__content addon">
          <span class="name-title">Optional Plan</span>
          <div class="plans-margin">
            <ng-container *ngFor="let product of rowData; let i = index">
              <div *ngIf="product.type === 'Optional Plan' && i >= 1" class="left-border-style">
                <span class="planName" *ngIf="product?.amount && showRatePlans && subsPricingEligible">
                  {{ product?.planName ? product.planName : product.name }}&nbsp;-&nbsp;{{
                    convertNumberFormat(product?.amount)
                  }}</span
                >
                <span class="planName" *ngIf="!product?.amount || !showRatePlans || !subsPricingEligible">{{
                  product?.name
                }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngIf="this.rowData.length > 1 && data?.isMultiProductWorkFlow" class="cc-drawer__content addon">
          <span class="name-title">Optional Plan</span>
          <div class="plans-margin">
            <ng-container *ngFor="let product of optionalPlanMp; let i = index">
              <div *ngIf="product.type === 'Addon' || (product.planName && !product.type)" class="left-border-style">
                <span class="planName" *ngIf="product?.amount && showRatePlans && subsPricingEligible"
                  >{{ product?.planName }}&nbsp;-&nbsp;{{ convertNumberFormat(product?.amount) }}</span
                >
                <span class="planName" *ngIf="!product?.amount || !showRatePlans || !subsPricingEligible">{{
                  product?.planName
                }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </cc-list-item>
    </cc-list>
  </div>
</div>
