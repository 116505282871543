<ng-continer *ngIf="is2GRetirement1b">
  <div class="header-blocker" *ngIf="blockedDeviceTypeList">
    <div class="header-text-plan-payment-block">
      <div>
        <img src="../../../assets/images/info-group.svg" class="info-icon-style info-group-block" />&nbsp;
        <span class="header-text-alignment-blocked"> {{ comparisonResult }}</span>

        <cc-icon
          [appClass]="'-block-tool-tip'"
          *ngIf="pastTooltip"
          [tooltipPosition]="'bottom'"
          cc-tooltip
          aria-label="help"
          role="complementary"
          [template]="tooltipTemplateFor2G"
          class="info-icon-style-block"
        >
          asset-info1
        </cc-icon>
        <cc-icon
          [appClass]="'-block-tool-tip'"
          *ngIf="futureTooltip"
          [tooltipPosition]="'bottom'"
          cc-tooltip
          aria-label="help"
          role="complementary"
          [template]="tooltipTemplateFor2G"
          class="info-icon-style-block"
        >
          asset-info1
        </cc-icon>
      </div>
    </div>
  </div>
  <ng-template #tooltipTemplateFor2G>
    <span *ngIf="pastTooltip" class="info-tooltip"
      >A device on this asset is impacting the availability of plans. Effective
      <b>{{ effectiveDate }}.</b>
    </span>
    <span *ngIf="futureTooltip" class="info-tooltip"
      >Plans that require a device will become unavailable on this asset. Effective
      <b>{{ blockingMonth }} {{ blockingYear }}.</b></span
    >
  </ng-template>
</ng-continer>
