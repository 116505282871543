import { Component, OnInit, Inject } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core';
import { OnInitEffects } from '@ngrx/effects';
import * as dspConstants from '../../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-filter-close-dashboard',
  templateUrl: './filter-close-dashboard.component.html',
  styleUrls: ['./filter-close-dashboard.component.scss'],
})
export class FilterCloseDashboardComponent implements OnInit {
  modalTitle = '';
  modalBodyLine1 = '';
  modalBodyLine2 = '';

  constructor(
    public modalRef: CcModalRef<FilterCloseDashboardComponent>,
    @Inject(CC_MODAL_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.modalBodyLine1 = dspConstants.Common.FILTER_CLOSE_DASHBOARD_POPUP__BODY_LINE1;
    this.modalTitle = dspConstants.Common.FILTER_CLOSE_DASHBOARD_POPUP_TITLE;
  }

  closePopupModal(closeModal?:any) {
    this.modalRef.close(closeModal);
  }

  closeFilterModal() {
    this.modalRef.close(true);
  }
}
