<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title cursor-changes">{{ data.title }}</div>
      <ng-container>
        <div class="cc-modal-close">
          <div class="site-right-panel">
            <span class="site-panel-items filter-container">
              <ng-container *ngIf="!filterIcon">
                <cc-icon fontSet="icomoon" (click)="openFilter()">filter</cc-icon>
              </ng-container>
              <ng-conatiner *ngIf="filterIcon">
                <img class="filter-icon-checked" src="..\assets\images\filter-applied.svg" (click)="openFilter()" />
              </ng-conatiner>
            </span>
            <span class="site-panel-items download-container">
              <img class="cc-close-full" src="/assets/icons/closeFull.svg" [alt]="'closeIcon'" (click)="closeModel()" />
            </span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="cc-modal-content">
      <div class="expanded-chart-needs-review needs-review-dashboard">
        <ng-conatiner>
          <div class="expanded-chart-needs-review needs-review-dashboard">
            <cc-bar-chart
              *ngIf="!nodata"
              [chartId]="11"
              [chartData]="needsReviewChartData"
              [chartConfig]="needsReviewChartConfig"
              [chartType]="'horizontal'"
              (seriesClick)="needsreviewChartClick($event)"
              [chartDataResponsive]="true"
            ></cc-bar-chart>
          </div>
        </ng-conatiner>
      </div>
    </div>
    <div *ngIf="nodata" class="no-results-found-expanded">
      <img src="../../../../../assets/images/no-data-big.png" />
      <p class="no-results-text">No data to display</p>
    </div>
  </div>
</div>
