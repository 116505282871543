import { Inject, Injectable, NgZone } from '@angular/core';
import { OneTrustService } from './one-trust/one-trust.service';

@Injectable({
  providedIn: 'root',
})
export class WhatfixScriptService {
  constructor(private zone: NgZone, private oneTrustService: OneTrustService, @Inject('Window') readonly window: any) {}

  public loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    // script.async = true;
    script.defer = true;
    this.zone.runOutsideAngular(() => {
      this.oneTrustService.OnConsentChanged().subscribe((consent) => {
        if (consent.includes('C0003')) {
          document.head.appendChild(script);
        } else {
          this.removeScript();
        }
      });
    });
    if (this.oneTrustService.getOneTrustActiveGroups().includes('C0003')) {
      document.head.appendChild(script);
    }
  }
  removeScript(): void {
    if (this.window?._wfx_destroy) this.window._wfx_destroy();
  }
}
