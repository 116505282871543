import { User, DealerList } from '../../models/user-info.interface';

export interface UserState {
  user: User;
  menu: any;
  isLoading?: boolean;
  isLoadingSuccess?: boolean;
  isLoadingFailure?: boolean;
  error?: any;
}

export const initialUserState: UserState = {
  user: {},
  menu: {},
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};

export interface DealerListState {
  dealer: DealerList;
  isLoading?: boolean;
  isLoadingSuccess?: boolean;
  isLoadingFailure?: boolean;
  error?: any;
}

export const initialDealerListState: DealerListState = {
  dealer: {},
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};
