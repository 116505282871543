import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { CcModalRef, CC_MODAL_DATA } from '@cat-digital-workspace/shared-ui-core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dsp-next-gen-ui-dealer-action-required-modal',
  templateUrl: './dealer-action-required-modal.component.html',
  styleUrls: ['./dealer-action-required-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DealerActionRequiredModalComponent implements OnInit {
  modalTitle = '';
  modalBodyMessage = '';
  modalFooterMessage = '';

  constructor(
    public sanitizer: DomSanitizer,
    public modalRef: CcModalRef<DealerActionRequiredModalComponent>,
    @Inject(CC_MODAL_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.modalBodyMessage = this.handleModalBodyMessage();
    this.modalFooterMessage = this.handleModalFooterMessage();
    this.modalTitle = 'Dealer Action Required';
  }

  handleModalBodyMessage() {
    const { billingType, languageTranslations } = this.modalData;
    let message = '';
    if (billingType == 'VIMS') {
      message = languageTranslations?.Worklist?.WL_VIMSMESSAGE;
    } else if (billingType == 'HEI') {
      message = languageTranslations?.Worklist?.WL_HEIMESSAGE;
    } else if (billingType == 'MineStar') {
      message = languageTranslations?.Worklist?.WL_MINESTARMESSAGE;
    }
    return message?.replace('<b>Dealer Action Required:</b><br/></br/>', '');
  }

  handleModalFooterMessage() {
    const { billingType, languageTranslations } = this.modalData;
    let message = '';
    if (billingType == 'VIMS') {
      message = languageTranslations?.Worklist?.WL_FT_VIMSMESSAGE;
    } else if (billingType == 'HEI') {
      message = languageTranslations?.Worklist?.WL_FT_HEIMESSAGE;
    } else if (billingType == 'MineStar') {
      message = languageTranslations?.Worklist?.WL_FT_MINESTARMESSAGE;
    }
    return message;
  }

  closeDealerActionModal(value = 'close') {
    this.modalRef.close(value);
  }
}
