<div class="grade-control-access-drawer cc-drawer">
  <div class="cc-drawer__header" id="manage-asset">
    <svg
      class="cc-drawer__leadingIcon"
      (click)="closeDrawer()"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 5.89258H1"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.3961L1 5.89139L6 1.38672"
        [attr.stroke]="'#000000'"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span class="cc-drawer__ellipsis filters-title"> Grade Control System </span>
    <div class="cc-drawer__headerAction">
      <div><span class="state-pipe">|</span></div>
      <div class="cc-drawer__close cc-close-icon cross-icon" (click)="closeDrawer()">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content">
    <div class="asset-manage-tracker" id="manage-asset-subscription-tracker" *ngIf="!isOwnershipApiCallInProgress">
      <cc-progress-tracker
        #manageAssetSubscriptionTracker
        [activeStepIndex]="trackerIndex"
        [trackerData]="dynamicTrackerData"
        [isTheme]="'2.0'"
      >
      </cc-progress-tracker>
    </div>

    <div>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Select Ownership' && setAssetOwnershipFlag">
        <div
          [ngClass]="{
            'cat-grade-asset-ownership': selectedRowData.ownershipDetails.length > 0 && this.subsPricingEligible,
            'cat-grade-asset-ownership-no-billing':
              selectedRowData.ownershipDetails.length > 0 && !this.subsPricingEligible,
            'cat-grade-no-ownership': selectedRowData.ownershipDetails.length === 0,
          }"
        >
          <dsp-next-gen-ui-select-ownership
            [rowData]="selectedRowData"
            [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
            [launchType]="launchType"
            [hasPartialOwnershipData]="hasPartialOwnershipData"
          ></dsp-next-gen-ui-select-ownership>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Component'">
        <div>
          <dsp-next-gen-ui-cat-grade-edit-serialnumber
            [rowData]="selectedRowData"
            [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
          ></dsp-next-gen-ui-cat-grade-edit-serialnumber>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Request License'">
        <div>
          <dsp-next-gen-ui-cat-grade-request-license
            [rowData]="selectedRowData"
            [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
          ></dsp-next-gen-ui-cat-grade-request-license>
        </div>
      </ng-container>
      <ng-container *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Payment Preview'">
        <div>
          <dsp-next-gen-ui-cat-grade-payment-preview
            [rowData]="selectedRowData"
            [selectedAssetSubscriptionFormData]="selectedAssetSubscriptionFormData"
            [subsPricingEligible]="subsPricingEligible"
          ></dsp-next-gen-ui-cat-grade-payment-preview>
        </div>
      </ng-container>
    </div>
    <div
      class="info-container cat-grade-edit-sno"
      *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Select Ownership' && setAssetOwnershipFlag"
    >
      <div class="info-row">
        <img src="..\assets\images\info-icon.svg" class="info-icon-style" /> For questions on activating a Cat Grade
        license, please see the&nbsp;<a (click)="helpCenterLink()" target="_blank" class="dsp-help-hyperlink"
          >DSP Help Center.</a
        >
      </div>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button
      cc-btn-secondary
      cc-btn-large
      [setTheme]="true"
      [ngClass]="this.trackerIndex === 0 ? 'hide-back-button' : ''"
      (click)="onBack()"
    >
      Back
    </button>
    <button
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [disabled]="setNextDisabled()"
      (click)="onNext()"
      [ngClass]="selectedAssetSubscriptionFormData.enableCloseButton ? 'hide-next-button' : ''"
      *ngIf="dynamicTrackerData[currentPageIndex]?.label !== 'Payment Preview' && enableNextButton"
    >
      Next
    </button>
    <button
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      [ngClass]="!selectedAssetSubscriptionFormData.enableCloseButton ? 'hide-close-button' : ''"
      [disabled]="setCloseDisabled()"
      (click)="onClose()"
      *ngIf="dynamicTrackerData[currentPageIndex]?.label === 'Request License'"
    >
      Close
    </button>
    <button
      cc-btn-primary
      cc-btn-large
      [setTheme]="true"
      (click)="onSubmit()"
      [disabled]="setSubmitDisabled()"
      *ngIf="
        dynamicTrackerData[currentPageIndex]?.label === 'Payment Preview' ||
        (dynamicTrackerData[currentPageIndex]?.label === 'Request License' &&
          !subsPricingEligible &&
          !selectedAssetSubscriptionFormData.enableCloseButton)
      "
    >
      Submit
    </button>
  </div>
</div>
