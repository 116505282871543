import { HostListener, Injectable } from '@angular/core';
import { ASSETDATEOVERLAYDATA } from '../components/assets/asset-constants';
import { findIndex, isEmpty } from 'lodash-es';
import * as dspConstants from '../shared/dspConstants';
import { SharedService } from '../shared.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DspUtilsCommonService {
  constructor(private sharedService: SharedService) {}
  private userRole = '';
  private menuList = '';
  private lineChartLabels = '';
  private selectedMonth = '';
  private monthList = '';
  private rangeCount = '';
  private productList = '';
  private defaultLineChartValues = '';
  public cancelReasons: any;
  private selectedProduct = '';
  private activeModal: any;
  addNewColumnToConfig(
    defaultColumn: any,
    Name: any,
    titleName: any,
    widthValue: any,
    formValue: any,
    isAlwaysValue: any,
    isVisibleValue: any,
    sortValue: any,
    sortOverlay: any,
    overlayDataValue: any,
    minWidthValue: any,
    component?: any,
    isFrozen?: any
  ) {
    const newColumn = {
      name: Name,
      title: titleName,
      width: widthValue,
      formControl: formValue,
      isAlwaysMandatory: isAlwaysValue,
      isVisible: isVisibleValue,
      sortingEnable: sortValue,
      ascendingSort: false,
      sortWithOverlay: sortOverlay,
      overlayData: overlayDataValue,
      minWidth: minWidthValue,
      component: component,
      isFrozen,
    };
    return newColumn;
  }
  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: BeforeUnloadEvent): void {
    this.isAllowedNavigation(true).subscribe((isAllowedNavigation) => {
      if (event && !isAllowedNavigation) {
        event.preventDefault();
        event.returnValue = false;
      }
    });
  }
  public isAllowedNavigation(beforeunloadEvent = false): Observable<boolean> {
    this.activeModal = document.getElementsByTagName('cc-modal-container');
    if (
      this.activeModal.length &&
      this.activeModal[0] &&
      this.activeModal[0].innerText &&
      this.activeModal[0].innerText.includes('Apply Filters')
    ) {
      return of(false);
    }
    return of(true);
  }
  removeColumnFromConfig(columnConfig: any, columnName: any) {
    columnConfig = columnConfig.filter((item: any) => item.name !== columnName);
    return columnConfig;
  }

  addNewColumnToFilter(
    defaultColumn: any,
    labelName: any,
    idValue: any,
    defaultValue: any,
    valuesSelected: any,
    formValue: any
  ) {
    const newColumn = {
      label: labelName,
      id: idValue,
      showChiplabel: true,
      isDefault: defaultValue,
      selectedValues: valuesSelected,
      formControl: formValue,
    };
    return newColumn;
  }

  checkPriceForB2C(b2cCustomers: any, customerInfo: any, selectedService: string) {
    if (
      customerInfo?.length == 1 &&
      customerInfo[0].ucid &&
      findIndex(b2cCustomers?.billDirectCustomers, ['ucid', customerInfo[0].ucid]) !== -1 &&
      b2cCustomers?.b2cSupportedApps?.indexOf(selectedService) !== -1
    ) {
      return false; // B2C customers
    } else {
      return true;
    }
  }

  getValidCustomerForAsset(customer: any) {
    return !isEmpty(customer) && customer !== 'None' ? [customer] : [];
  }

  assignDisplayColumns(filterData: any) {
    if (filterData?.filter) {
      const filterName = Object.keys(filterData.filter);
      filterName.map((column: any) => {
        if (filterData.displayColumns.indexOf(column) === -1) {
          filterData.displayColumns.push(column);
        }
      });
    }
  }

  setValue(Value: any, Module: any) {
    switch (Module) {
      case 'userRole':
        this.userRole = Value;
        break;
      case 'menuList':
        this.menuList = Value;
        break;
      case 'lineChartLabels':
        this.lineChartLabels = Value;
        break;
      case 'selectedMonth':
        this.selectedMonth = Value;
        break;
      case 'selectedProduct':
        this.selectedProduct = Value;
        break;
      case 'monthList':
        this.monthList = Value;
        break;
      case 'rangeCount':
        this.rangeCount = Value;
        break;
      case 'productList':
        this.productList = Value;
        break;
      case 'defaultLineChartValues':
        this.defaultLineChartValues = Value;
        break;
      case dspConstants.DspUtilsServiceVariables.CANCEL_REASONS:
        this.cancelReasons = Value;
        break;
      default:
        break;
    }
  }

  getValue(Module: any) {
    switch (Module) {
      case 'userRole':
        return this.userRole;
        break;
      case 'menuList':
        return this.menuList;
        break;
      case 'lineChartLabels':
        return this.lineChartLabels;
        break;
      case 'selectedMonth':
        return this.selectedMonth;
        break;
      case 'selectedProduct':
        return this.selectedProduct;
        break;
      case 'monthList':
        return this.monthList;
        break;
      case 'rangeCount':
        return this.rangeCount;
        break;
      case 'productList':
        return this.productList;
        break;
      case 'defaultLineChartValues':
        return this.defaultLineChartValues;
        break;
      case dspConstants.DspUtilsServiceVariables.CANCEL_REASONS:
        return this.cancelReasons;
        break;
      default:
        return '';
    }
    return '';
  }

  resetValues(Module: any) {
    switch (Module) {
      case 'lineChartLabels':
        this.lineChartLabels = '';
        break;
      case 'selectedMonth':
        this.selectedMonth = '';
        break;
      case 'selectedProduct':
        this.selectedProduct = '';
        break;
      case 'monthList':
        this.monthList = '';
        break;
      case 'defaultLineChartValues':
        this.defaultLineChartValues = '';
        break;
      case dspConstants.DspUtilsServiceVariables.CANCEL_REASONS:
        this.cancelReasons = '';
        break;
      default:
        break;
    }
  }

  removeIsNewWindowOpened() {
    setTimeout(() => {
      sessionStorage.removeItem('isNewWindowOpened');
    }, 20000);
  }
}
