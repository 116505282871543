import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AccountFormType, editPlanPayload, getUsersSearchPayload } from '../components/accounts/accounts-interface';

@Injectable({
  providedIn: 'root',
})
export class SubscribeAccountsService {
  public errorText = 'There was an error while processing your request. Please try again after sometime';
  rootURL = '';
  @Output() accountFormDataEvent = new EventEmitter<AccountFormType>();
  @Output() autoRefreshSubscriptionEvent = new EventEmitter();

  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getProducts(queryParams: any) {
    return this.http.get(this.rootURL + '/org/applications', {
      params: queryParams,
    });
  }

  getPlans(body: any) {
    return this.http.post(this.rootURL + '/org/plans', body);
  }

  validateAccount(body: any) {
    return this.http.post(this.rootURL + '/org/subscription/validate', body);
  }

  validateUcidDcn(queryParams: any) {
    return this.http.get(this.rootURL + '/org/validateUcidDcnDtls', {
      params: queryParams,
    });
  }

  setAccountFormData(formData: AccountFormType) {
    this.accountFormDataEvent.emit(formData);
  }

  setAutoRefreshSubscriptionData(data: any) {
    this.autoRefreshSubscriptionEvent.emit(data);
  }

  setNewSubscription(body: any) {
    return this.http.post(this.rootURL + '/org/subscribe', body);
  }

  updateSubscription(reqBody: editPlanPayload) {
    return this.http.post(this.rootURL + '/org/subscription/edit', reqBody);
  }

  updateSMASubscription(reqBody: any) {
    return this.http.post(this.rootURL + '/org/subscription/update', reqBody);
  }
  getUsersSearch(reqBody: getUsersSearchPayload) {
    return this.http.post(this.rootURL + '/user/getUserWithSubscriptionStatus', reqBody);
  }
  getCancelReason(payload: any) {
    return this.http.get(this.rootURL + '/org/subscription/cancelReason', {
      params: payload,
    });
  }
  cancelSubscription(payLoad: any) {
    return this.http.post(this.rootURL + '/org/subscription/cancel', payLoad);
  }

  getSalesReason() {
    return this.http.get(this.rootURL + '/org/getSalesReason');
  }

  validatePrefix(queryParam: any) {
    return this.http.get(this.rootURL + '/org/validatePrefix', { params: queryParam });
  }
}
