import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { AssetService } from '../../services/asset.service';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import { catchError, finalize, forkJoin, map, of } from 'rxjs';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-payment-preview',
  templateUrl: './cat-grade-payment-preview.component.html',
  styleUrls: ['./cat-grade-payment-preview.component.scss'],
})
export class CatGradePaymentPreviewComponent implements OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  catPaymentPreviewTableData: any = [];
  infoMsgPaymentPreview = `This is a one-time non-refundable license fee applicable for grade control hardware. By submitting your order, you are agreeing to our <a href="https://catdealer.com" target="_blank" style="color:#2595FF; text-decoration:none; font-weight:400">Terms of Sale</a>`;
  catPaymentPreviewTableConfig = {
    columnConfig: [
      {
        name: 'type',
        title: 'Component',
        width: 150,
        formControl: 'span',
      },
      {
        name: 'serialNumber',
        title: 'Serial Number',
        width: 100,
        formControl: 'span',
      },
      {
        name: 'nextUpgradableLicenseDesc',
        title: 'Current License',
        width: 100,
        formControl: 'span',
      },
      {
        name: 'price',
        title: 'Price',
        width: 100,
        formControl: 'span',
      },
      {
        width: 150,
        name: 'promotion',
        formControl: 'span',
        title: 'Promotion',
      },
      {
        width: 100,
        name: 'discount',
        formControl: 'span',
        title: 'Discount',
      },
      {
        width: 100,
        name: 'total',
        formControl: 'span',
        title: 'Total',
      },
    ],
  };
  componentRes: any;
  loader: boolean = false;
  searchAssetInfo: any;
  estimatedTotal: any;
  filteredPreviewData: any = [];
  dspConfig: any;
  currency!: string;
  dspStoreData!: any;
  isLicenseMappingEnabled: any;
  selectedLicenseDetails: any;
  isNumberConventionEnabled: any;
  validatedLicenseData: any;
  dspHelpUrl: any;

  constructor(
    public assetDrawerService: AssetDrawerService,
    private router: Router,
    public assetService: AssetService,
    private store: Store<DSPAppState>,
    private dspUtilsCommonService: DspUtilsCommonService,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.dspHelpUrl = environment.redirectURL.helpDocUrl + '?language=en_US';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.dspConfig = dsp.dspConfig;
      this.isLicenseMappingEnabled = this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        : false;
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
    });
    if (this.dspStoreData?.featureFlagInfo?.isCatGradeControlBillingEnabled === true) {
      const customerInfo = this.selectedAssetSubscriptionFormData?.customer
        ? this.selectedAssetSubscriptionFormData?.customer
        : {};
      this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
      this.assetDrawerService.searchAssetInfo.subscribe((data: any) => {
        this.searchAssetInfo = data;
      });
      this.assetDrawerService.selectedLicense.subscribe((data: any) => {
        this.selectedLicenseDetails = data;
      });

      this.assetDrawerService.filteredPaymentPreviewData.subscribe((data: any) => {
        if (data) {
          this.validatedLicenseData = data;
        }
      });
      if (this.validatedLicenseData) {
        let result = this.validatedLicenseData.filter(
          (item: any) =>
            (item.nextUpgradableLicenseDesc !== 'No Upgrade Available' &&
              item.nextUpgradableLicenseDesc !== 'Not Upgradable' &&
              item.nextUpgradableLicenseDesc !== null &&
              item.licenseStatus.toLowerCase() !== 'pending' &&
              item.selectedMultiLicense) ||
            item.noMultiLicenseOption
        );

        this.filteredPreviewData = result;
        this.assetDrawerService.selectedLicenseEligiblityStatus.next(this.filteredPreviewData);
        this.assetDrawerService.paymentPreviewConfirmationData.next(this.filteredPreviewData);

        const responses: any = [];
        const requests = this.filteredPreviewData?.map((previewData: any) => {
          const licensePartNumArr: any = [];
          this.loader = true;
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          licensePartNumArr.push(previewData.nextUpgradableLicensePartNum);
          const paymentPreviewPayload = {
            dealerCode: fetchLoggedInDealerDetails().dealerCode,
            assetSerialNumber: this.searchAssetInfo.serialNumber ? this.searchAssetInfo.serialNumber : '',
            assetMake: this.searchAssetInfo.make ? this.searchAssetInfo.make : '',
            deviceId: '',
            deviceSerialNumber: previewData?.serialNumber ? previewData?.serialNumber : '',
            deviceType: this.rowData?.deviceType ? this.rowData?.deviceType : '',
            // deviceType: '',
            dcnNumber: customerInfo.dealerCustNo ? customerInfo.dealerCustNo : '',
            dcnName: customerInfo.dealerCustName ? customerInfo.dealerCustName : '',
            ucid: customerInfo.ucid ? customerInfo.ucid : '',
            customerName: customerInfo.ucidName ? customerInfo.ucidName : '',
            subscriptions: [],
            applicationName: 'Grade Licensing',
            isNewSubscription: true,
            isViewSubscription: false,
            isDowngradedSubscription: false,
            applyOTO: false,
            cva: false,
            newAddOns: [],
            siteName: '',
            licensePartNumbers: licensePartNumArr,
            id: previewData.id,
            componentType: previewData.type,
            nextUpgradableLicenseDesc: previewData.nextUpgradableLicenseDesc,
          };
          return this.assetDrawerService.getCatGradePaymentPreview(paymentPreviewPayload).pipe(
            map((res: any) => {
              const parsedRes = JSON.parse(res.response);
              return {
                type: res?.componentType,
                serialNumber: res?.ecmSerialNumber,
                nextUpgradableLicenseDesc: res?.nextUpgradableLicenseDesc,
                price: parsedRes.new?.monthlyRate,
                promotion: parsedRes.new?.promotions[0]?.name,
                discount: parsedRes.new.promotions[0]?.amount,
                total: parsedRes.new?.monthlyRateAfterDiscount,
              };
            }),
            catchError((error: any) => {
              return of({
                type: paymentPreviewPayload.componentType,
                serialNumber: paymentPreviewPayload.deviceSerialNumber,
                nextUpgradableLicenseDesc: paymentPreviewPayload.nextUpgradableLicenseDesc,
                // price: paymentPreviewPayload.monthlyRate,
                // promotion: resData.new.promotions,
                // discount: resData.new.monthlyRateAfterDiscount,
                // total: resData.new.products[0].amount,
              });
            })
          );
        });

        forkJoin(...requests)
          .pipe(
            finalize(() => {
              this.loader = false;
              this.catPaymentPreviewTableData = responses[0];
              this.selectedAssetSubscriptionFormData.isValidForm = true;
              this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              const totalValue = this.catPaymentPreviewTableData.reduce((acc: any, item: any) => {
                const parts = item.total?.split(' ');
                this.currency = ' ' + parts[1];
                const numericAmount = parseFloat(item.total);
                return acc + numericAmount;
              }, 0);
              this.estimatedTotal = totalValue ? totalValue.toFixed(2) + this.currency : '';
            })
          )
          .subscribe((data: any) => {
            if (data) {
              responses.push(data);
            }
          });
      }
    } else {
      if (this.validatedLicenseData) {
        const result = this.validatedLicenseData.filter(
          (item: any) =>
            item.nextUpgradableLicenseDesc !== 'No Upgrade Available' &&
            item.nextUpgradableLicenseDesc !== 'Not Upgradable' &&
            item.licenseStatus.toLowerCase() !== 'pending'
        );
        this.catPaymentPreviewTableData = result;
        this.assetDrawerService.filteredPaymentPreviewData.next(this.catPaymentPreviewTableData);
      }
    }
  }

  ngAfterViewInit() {
    this.assetDrawerService.searchAssetInfo.subscribe((data: any) => {
      this.searchAssetInfo = data;
    });
    const customerInfo = this.selectedAssetSubscriptionFormData?.customer
      ? this.selectedAssetSubscriptionFormData?.customer
      : {};
    this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
  }

  termsOfModal() {
    sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
    this.sharedService.setNavigationValue('enableFooterComponent');
    this.dspUtilsCommonService.removeIsNewWindowOpened();
    window.open('termSaleModal', '_blank');
  }

  displayAmount(amount: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(amount, true);
    }
    return amount;
  }

  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }
}
