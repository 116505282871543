<div class="dsp-dashboard" *ngIf="loggedUser !== undefined">
  <div class="cc-grid">
    <div
      class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12 first-row"
      *ngIf="isNeedsReview.length"
    >
      <dsp-next-gen-ui-needs-review-dashboard></dsp-next-gen-ui-needs-review-dashboard>
    </div>
    <div
      class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12 first-row"
      *ngIf="isSubscribedAssetTrend.length"
    >
      <dsp-next-gen-ui-subscribed-asset-trend></dsp-next-gen-ui-subscribed-asset-trend>
    </div>
    <div
      class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12 second-row"
      *ngIf="isConnectivitySummary.length"
    >
      <dsp-next-gen-ui-connectivity-summary></dsp-next-gen-ui-connectivity-summary>
    </div>
    <div
      class="cc-col cc-lg-col--6 cc-md-col--6 cc-xl-col--6 cc-sm-col--6 cc-xs-col--12 second-row"
      *ngIf="isCancellationReasons.length"
    >
      <dsp-next-gen-ui-cancellation-reason></dsp-next-gen-ui-cancellation-reason>
    </div>
  </div>
  <img class="hidden" src="/assets/icons/closeSemi.svg" [alt]="'closeIcon'" />
</div>
