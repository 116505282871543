<div class="approve-request-section" *ngIf="newSubscriptionData || oldSubscriptionData">
  <div class="title">
    <span class="title">APPROVE REQUEST</span>
  </div>
  <div class="content">
    <span
      cc-chip="Approve"
      type="check"
      (selected)="updateSelection('Approved'); toggleRadioGroup($event, 0)"
      [radio]="true"
      [ngClass]="radioStates[0] === 'active' ? 'dont-click' : ''"
      [state]="status === 'Approved' ? 'active' : 'default'"
      [singleSelect]="true"
    ></span>

    <span
      class="decline-chip"
      cc-chip="Decline"
      type="check"
      (selected)="updateSelection('Declined'); toggleRadioGroup($event, 1)"
      [ngClass]="radioStates[1] === 'active' ? 'dont-click' : ''"
      [radio]="true"
      [state]="status === 'Declined' ? 'active' : 'default'"
      [singleSelect]="true"
    ></span>
  </div>
</div>
