<div class="modalBody orgModal">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ data.title }}</div>
    </div>
    <div class="cc-modal-content">
      <div class="user-info-wrap">
        <div class="user-prefix">{{ data.shortName }}</div>
        <div class="user-info">
          <div class="user-name">{{ data.userInfo.name }}</div>
          <div class="user-email">{{ data.userInfo.email }}</div>
        </div>
      </div>
      <div class="org-dealer-header">CONTACT</div>
      <div class="yellow-border"></div>
      <div class="cc-grid">
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
          <div>First Name</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--8 cc-sm-col--6 cc-xs-col--12">
          <div>{{ data.userInfo.firstName }}</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
          <div>Last Name</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--8 cc-sm-col--6 cc-xs-col--12">
          <div>{{ data.userInfo.lastName }}</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
          <div>Email</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--8 cc-sm-col--6 cc-xs-col--12">
          <div>{{ data.userInfo.email }}</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--4 cc-sm-col--6 cc-xs-col--12">
          <div>Contact No.</div>
        </div>
        <div class="cc-col cc-lg-col--4 cc-md-col--6 cc-xl-col--8 cc-sm-col--6 cc-xs-col--12">
          <div>{{ data.userInfo.telePhoneNumber }}</div>
        </div>
      </div>
    </div>
    <div class="cc-modal-footer">
      <div>
        <button cc-btn-secondary (click)="onClickClose()" [setTheme]="true">Close</button>
      </div>
    </div>
  </div>
</div>
