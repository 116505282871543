import { ExpandedDetailsComponent } from './expanded-details/expanded-details.component';

export const ExpandedAssetHistoryColumnConfig = [
  {
    name: 'product',
    title: 'Product(s)',
    width: 135,
    priority: 2,
    isFrozen: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: ExpandedDetailsComponent,
  },
  {
    name: 'fromSub',
    title: 'From',
    width: 150,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: ExpandedDetailsComponent,
  },
  {
    name: 'toSub',
    title: 'To',
    width: 150,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: ExpandedDetailsComponent,
  },
  {
    name: 'subscriptionLevel',
    title: 'Subscription Level',
    width: 152,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    formControl: 'span',
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
  },
  {
    name: 'dealerInfo',
    title: 'Dealer Name & Code',
    width: 160,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: ExpandedDetailsComponent,
  },
  {
    name: 'createdByUserName',
    title: 'User Name',
    width: 120,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
    component: ExpandedDetailsComponent,
  },
  {
    name: 'createdByUserCwsId',
    title: 'User CWS ID',
    width: 117,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    formControl: 'span',
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
  },
  {
    name: 'userType',
    title: 'User Type',
    width: 104,
    isFrozen: false,
    isAlwaysMandatory: false,
    isVisible: true,
    formControl: 'span',
    sortingEnable: false,
    ascendingSort: false,
    sortWithOverlay: false,
    overlayData: [],
  },
];
