import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core/drawer';
import { accountData } from '../accounts-interface';
import { EditPlanComponent } from '../edit-plan/edit-plan.component';
import { SubscribeAccountsService } from '../../../services/subscribe-accounts.service';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Common, OrgSubs } from '../../../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-account-action-menus',
  templateUrl: './account-action-menus.component.html',
  styleUrls: ['./account-action-menus.component.scss'],
})
export class AccountActionMenusComponent {
  @Input() accountInfo!: accountData;
  @Output() fetchAccountsCallback = new EventEmitter();
  editPlanRef!: any;
  cancelPlanRef!: any;
  smaCancelPlanRef!: any;
  drawerRef!: any;
  viewPlanRef!: any;
  telUserType = '';
  subscribeAccountsConst: any;
  constructor(
    private store: Store<DSPAppState>,
    private drawerService: DrawerService,
    private accountsService: SubscribeAccountsService
  ) {}
  ngOnInit(): void {
    this.subscribeAccountsConst = { ...OrgSubs, ...Common };
    this.store.select('user').subscribe((user) => {
      this.telUserType = user.user?.telUserType || '';
    });
  }
  //OrgSubs.OPERATIONTYPE_UPDATE
  openEditDrawer(rowData: any, actionType: string) {
    if (actionType === OrgSubs.OPERATIONTYPE_VIEW) {
      actionType =
        rowData?.store === 'DSP'
          ? this.subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER
          : this.subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_CUSTOMER;
    }
    this.editPlanRef = this.drawerService.openDrawer<EditPlanComponent, CcDrawerConfig>(EditPlanComponent, {
      data: {
        headerType: 'drawerContent',
        apply: this.applyFromFooter.bind(this),
        rowData: rowData,
        actionType: actionType,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: this.setDrawerSize(actionType),
    });
    this.editPlanRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        this.fetchAccountsCallback.emit();
        this.accountsService.setAutoRefreshSubscriptionData(true);
      }
    });
  }
  applyFromFooter(Item: any) {
    this.editPlanRef.close(Item);
  }

  setDrawerSize(actionType: string) {
    if (actionType === OrgSubs.OPERATIONTYPE_UPDATE) {
      return CcDrawerSize.LARGE;
    } else if (actionType === OrgSubs.OPERATIONTYPE_CANCEL) {
      return this.accountInfo?.subsDtl?.name === this.subscribeAccountsConst.SMA && this.accountInfo?.store === 'DSP'
        ? CcDrawerSize.LARGE
        : CcDrawerSize.MEDIUM;
    } else return CcDrawerSize.SMALL;
  }
}
