<div [ngClass]="appClass">
  <div class="footer-info">
    <div class="links">
      <div data-href="/legalModal" (click)="legalModal()">Legal<span>-</span></div>
      <div data-href="/privacyModal" (click)="privacyModal()">Privacy<span>-</span></div>
      <div
        data-href="#"
        class="ot-sdk-show-settings"
        role="button"
        tabindex="0"
        onclick="window.OneTrust.ToggleInfoDisplay()"
      >
        Cookie Settings
      </div>
      <span>&nbsp;-&nbsp;</span>
      <div
        data-href="/termSaleModal"
        (click)="termSaleModal()"
        *ngIf="userActiveFeatures && userActiveFeatures.indexOf('TOS') !== -1"
      >
        Terms of Sale<span>-</span>
      </div>
      <div data-href="/quickLinks" (click)="quickLink()">Quick Links<span *ngIf="showDSPV1Link">-</span></div>
    </div>
    <div class="copy">Caterpillar © {{ dateYear }}. All Rights Reserved.</div>
  </div>
</div>
