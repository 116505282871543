import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import * as dspConstants from '../../.././../shared/dspConstants';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-export',
  templateUrl: './cat-grade-export.component.html',
  styleUrls: ['./cat-grade-export.component.scss'],
})
export class CatGradeExportComponent implements OnInit {
  public rowData: any;
  public config: any;
  public value: any;
  public columnWithHeader: any;
  dspWorklistConstants = dspConstants.Worklist;
  dspStoreData: any;
  constructor(private assetDrawerService: AssetDrawerService, private store: Store<DSPAppState>) {}

  ngOnInit(): void {
    this.rowData = this.value ? this.value : '';
    this.config = this.columnWithHeader ? this.columnWithHeader : '';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }

  onExportClick() {
    const catGradeExportObj = {
      serialNumber: this.rowData?.ecmSerialNumber,
      componentType: this.rowData?.componentType,
    };
    this.assetDrawerService.downloadGradeControlLicense(catGradeExportObj).subscribe(
      (res: any) => {
        if (this.rowData?.componentType === 'Grade Control ECM') {
          const xmlResponse = res;
          const blob = new Blob([xmlResponse], { type: 'text/xml' });
          const fileName = this.rowData?.ecmSerialNumber;
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = fileName + '.xml';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else if (this.rowData?.componentType === 'GNSS Receiver') {
          const textContent = JSON.stringify(res);
          const blob = new Blob([textContent], { type: 'text/plain' });
          const fileName = this.rowData?.ecmSerialNumber;
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = 'GNSS timed license ' + fileName + '.txt';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
      (err: any) => {
        if (err.status === 200) {
          if (this.rowData?.componentType === 'Grade Control ECM') {
            const xmlResponse = err.error.text;
            const blob = new Blob([xmlResponse], { type: 'text/xml' });
            const fileName = this.rowData?.ecmSerialNumber;
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = fileName + '.xml';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          } else if (this.rowData?.componentType === 'GNSS Receiver') {
            const textContent = JSON.stringify(err);
            const blob = new Blob([textContent], { type: 'text/plain' });
            const fileName = this.rowData?.ecmSerialNumber;
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'GNSS timed license ' + fileName + '.txt';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        }
      }
    );
  }
}
