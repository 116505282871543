import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  TemplateRef,
  ElementRef,
  ViewChild,
  HostListener,
  Renderer2,
} from '@angular/core';
import { TableConfig, ITableSettingsPanelConfig } from '@cat-digital-workspace/shared-ui-core/cc-table';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import {
  getAccountsPayload,
  accountData,
  getAccountsCountPayload,
  getAccountsCountResponse,
} from './accounts-interface';
import { AccountsService } from './accounts.service';
import { TableSettingsCloseConfirmationComponent } from '../table-settings-close-confirmation/table-settings-close-confirmation.component';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { debounce, isEmpty } from 'lodash-es';
import { DrawerService, CcDrawerConfig, CcDrawerSize } from '@cat-digital-workspace/shared-ui-core/drawer';
import { FiltersService } from '../../services/filters.service';
import { AccountCustomerComponent } from './account-customer/account-customer.component';
import { AccountProductPlanComponent } from './account-product-plan/account-product-plan.component';
import { AccountCwsIDComponent } from './account-cws-id/account-cws-id.component';
import { AccountPrefixComponent } from './account-prefix/account-prefix.component';
import { AccountDealerCustomerIDComponent } from './account-dealer-customer-id/account-dealer-customer-id.component';
import { NewSubscriptionComponent } from './new-subscription/new-subscription.component';
import { dealerType } from '../../models/shared.interface';
import { EditPlanComponent } from './edit-plan/edit-plan.component';
import { fetchLoggedInDealerDetails } from '../../shared/shared';
import { ITableSettings } from '@cat-digital-workspace/shared-ui-core/cc-table-settings/model/table-settings.interface';
import { Observable, Subscription, forkJoin, fromEvent } from 'rxjs';
import { ColumnConfig, OVERLAYDATA } from './accounts-column-config';
import * as dspConstants from '../../shared/dspConstants';
import { SubscribeAccountsService } from '../../services/subscribe-accounts.service';
import { DSPAppState } from '../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { getAccountCancelReasonDetails } from '../../store/actions/dspcommon.action';
import { FormControl, Validators } from '@angular/forms';
import { TablesettingService } from '../../services/tablesetting.service';
import { DspUtilsCommonService } from '../../services/dsp-utils-common.service';
import { AccountSalesReasonComponent } from './account-sales-reason/account-sales-reason.component';
import { CommonFilterService } from '../../services/common-filter.service';
import { CommonConfirmationPopupComponent } from '../common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from '../../models/popup.interface';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'dsp-next-gen-ui-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountsComponent implements OnInit, OnDestroy {
  @ViewChild('settingPanel') settingPanelElement!: ElementRef;
  @ViewChild('settingIcon') settingIconElement!: ElementRef;
  @ViewChild('accountsTableRef') tableRef: any;
  @ViewChild('accountsSearchBox') accountsSearchBox!: ElementRef;
  errorText = 'Please enter atleast 3 characters';
  negative = false;
  isTheme = '2.0';
  editPlanRef: any;
  addtionalcwsID: any;
  public dealerCode: any;
  filterContentsRef: any;
  public filterResponse: object = {};
  pageLabel = 'accounts';
  accountColumnList: any;
  searchValue: FormControl = new FormControl('', {
    validators: [Validators.minLength(3)],
    updateOn: 'change', //blur
  });
  savedFilters: any;
  newColumn: any;

  mobileHeader = 'Search User';
  // overlayData = [
  //   {
  //     name: 'asc',
  //     title: 'Sort A to Z',
  //   },
  //   {
  //     name: 'dsc',
  //     title: 'Sort Z to A',
  //   },
  // ];
  countOverlayData = [
    {
      name: 'asc',
      title: 'Sort Smallest to Largest',
    },
    {
      name: 'dsc',
      title: 'Sort Largest to Smallest',
    },
  ];
  accountTableCustomData: any = {
    searchString: '',
  };
  subcribeAccountTableConfig = {
    height: window.innerHeight - 180,
    stickyHeader: true,
    expandable: false,
    isSelfFoldable: true,
    isBulkAction: false,
    isSortEnable: true,
    dragDisabled: true,
    isTheme: '2.0',
    footer: true,
    isPaginationEnable: true,
    isEnableHeaderPagination: true,
    showFooterPaginationOnlyOnScroll: false,
    pagination: {
      pageSizeOptions: [20, 40, 60, 80, 100],
      advanced: false,
      activePage: 1,
      defaultPageSize: 20,
      setTheme: true,
      enableDropdownWithPrefix: false,
      paginationLabelText: 'Viewing',
    },
    isEnableSettingsPanel: false,
    columnConfig: ColumnConfig,
  };
  accountTableData: accountData[] = [];
  isTableLoading = false;
  searchHelpText = '';
  isGlobalSearchDisabled = false;
  isSettingsOutSideClicked = false;
  enableSearch = false;
  searchDataLoaded = false;
  isSaveTableSettings = '';
  overlayData = OVERLAYDATA;

  fetchAccountAPIPayload: getAccountsPayload = {
    sortBy: 'createdTimestamp',
    orderBy: 'DESC',
    limit: 20,
    offset: 0,
    dealerCode: '',
    searchText: '',
    pageNo: 1,
    subFilters: {
      ucid: [],
      ucidName: [],
      plan: [],
      product: [],
      salesReason: [],
      planType: [],
      planLevel: [],
    },
  };
  public dealer: dealerType = {
    dealerCity: '',
    dealerCode: '',
    dealerCountry: '',
    dealerEDURL: '',
    dealerEmail: '',
    dealerName: '',
    dealerPhone: '',
    dealerState: '',
    dealerStreet: '',
    dealerZipCode: '',
  };
  fetchAccountsCountAPIPayload: getAccountsCountPayload = {
    dealerCode: '',
    searchText: '',
    version: 'v2',
    subFilters: {
      ucid: [],
      ucidName: [],
      plan: [],
      product: [],
      salesReason: [],
      planType: [],
      planLevel: [],
    },
  };

  AccountTableCustomData: any = {
    b2cCustomers: {},
    mineStarDetails: {},
    searchString: '',
  };
  b2cCustomers: any;
  applyFromFooter: any;
  totalSitesCount = 0;
  settingsPanelConfig = {
    settingsPanelHeader: 'Table Settings',
    filterSliderText: 'Filter list to visible columns',
    columnNameText: 'Column Name',
    isVisibleText: 'Visible',
    isFrozenText: 'Frozen',
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    visibleTooltipText: dspConstants.Common.VISIBLETOOLTIPTEXT,
    frozenTooltipText: dspConstants.Common.FROZENTOOLTIPTEXT,
  };
  tableSettingsPanelConfig!: ITableSettings;
  tableSettingsConfig: ITableSettingsPanelConfig = {
    features: [],
    visibilityToggle: false,
  };
  updatedTableConfig: any = [];

  isRemovePaginationSearch = false;
  observable!: Subscription;
  showLoader = false;
  isDataLoading = false;
  modalRef: any;
  isTableSettingsApply = false;
  isTableSettingClose = false;
  isCloseEvent = false;
  searchTextValue: any;
  userDealerCode: any;
  // dealer!: dealerType;
  constructor(
    private dspUtilsService: DspUtilsCommonService,
    private accountsService: AccountsService,
    private filterService: FiltersService,
    private drawerService: DrawerService,
    public modal: CcModal,
    private messageBar: MessageBar,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private subscribeAccountsService: SubscribeAccountsService,
    private store: Store<DSPAppState>,
    private tableSettingService: TablesettingService,
    private commonFilterService: CommonFilterService,
    private sharedService: SharedService
  ) {}
  isMaintainPagination = false;
  settingDrawerRef: any;
  isSettingVisible = false;
  fetchAccountsAPICallData!: Subscription;
  fetchAccountsCountAPICallData!: Subscription;
  isFetchAccountsAPICallCompleted!: boolean;
  isFetchCountAPICallCompleted!: boolean;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  profile = '';
  dspStoreData: any;
  isHideServiceMediaAccess!: any;
  isChangeOwnership!: any;
  isCommonPopup = false;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: Event): void {
    this.dspUtilsService.onBeforeUnload(event);
  }
  canDeactivate(): Observable<boolean> {
    return this.dspUtilsService.isAllowedNavigation();
  }

  ngOnInit(): void {
    this.commonFilterService.getFilterColumn(this.pageLabel).subscribe((response: any) => {
      this.accountColumnList = response;
    });
    this.subscribeAccountsService.autoRefreshSubscriptionEvent.subscribe((data: any) => {
      if (data) {
        this.fetchAccounts(true);
      }
    });
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
      this.isSaveTableSettings = this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        ? this.dspStoreData?.featureFlagInfo?.isSaveTableSettings
        : false;

      this.isHideServiceMediaAccess = this.dspStoreData?.featureFlagInfo?.isHideServiceMediaAccess
        ? this.dspStoreData?.featureFlagInfo?.isHideServiceMediaAccess
        : false;
      this.isChangeOwnership = this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        ? this.dspStoreData?.featureFlagInfo?.separateOwnershipInfo
        : false;

      this.isRemovePaginationSearch = this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        ? this.dspStoreData?.featureFlagInfo?.isRemovePaginationSearch
        : false;
      this.isMaintainPagination = this.dspStoreData?.featureFlagInfo?.isMaintainPagination
        ? this.dspStoreData?.featureFlagInfo?.isMaintainPagination
        : false;
      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });

    if (this.isChangeOwnership) {
      let newCustomerColumn = [
        {
          name: 'dcName',
          title: 'Dealer Customer Name',
          isFrozen: false,
          width: 240,
          isAlwaysMandatory: false,
          isVisible: false,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: AccountCustomerComponent,
          customData: {
            searchString: '',
          },
        },
        {
          name: 'dcn',
          title: 'Dealer Customer Number',
          isFrozen: false,
          width: 240,
          isAlwaysMandatory: false,
          isVisible: false,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.countOverlayData,
          component: AccountCustomerComponent,
          customData: {
            searchString: '',
          },
        },
        {
          name: 'ucName',
          width: 200,
          title: 'Customer Name',
          isFrozen: false,
          isAlwaysMandatory: false,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.overlayData,
          component: AccountCustomerComponent,
          customData: {
            searchString: '',
          },
        },
        {
          name: 'ucid',
          width: 200,
          title: 'Customer ID',
          isFrozen: true,
          isAlwaysMandatory: true,
          isVisible: true,
          sortingEnable: true,
          ascendingSort: false,
          sortWithOverlay: true,
          overlayData: this.countOverlayData,
          component: AccountCustomerComponent,
          customData: {
            searchString: '',
          },
        },
      ];
      let columnNames = ['ucName', 'dcName'];
      columnNames.map((item: any) => {
        this.subcribeAccountTableConfig.columnConfig = this.dspUtilsService.removeColumnFromConfig(
          this.subcribeAccountTableConfig.columnConfig,
          item
        );
      });
      newCustomerColumn.map((data: any) => {
        this.subcribeAccountTableConfig.columnConfig.splice(
          0,
          0,
          this.dspUtilsService.addNewColumnToConfig(
            this.subcribeAccountTableConfig.columnConfig,
            data.name,
            data.title,
            data.width,
            '',
            data.isAlwaysMandatory,
            data.isVisible,
            data.sortingEnable,
            data.sortWithOverlay,
            data.overlayData,
            data.width,
            data.component,
            data.isFrozen
          )
        );
      });
      this.subcribeAccountTableConfig.columnConfig.map((column: any) => {
        column.customData = {
          searchString: '',
        };
      });
    }

    if (this.isHideServiceMediaAccess) {
      const columnNames = ['assetPrefix', 'salesReason', 'cvaNumber'];
      columnNames.forEach((item) => {
        this.subcribeAccountTableConfig.columnConfig = this.dspUtilsService.removeColumnFromConfig(
          this.subcribeAccountTableConfig.columnConfig,
          item
        );
      });
    }
    this.store.select('user').subscribe((user: any) => {
      this.profile = user?.user?.telUserType || 'CAT';
      this.userDealerCode = user.user?.dealerCode || '';
    });
    this.observable = this.tableSettingService.accountObservable.subscribe((res) => {
      this.createSettingsPanelConfig();
      this.updateComponent();
    });
    this.getCancelReason();
    this.dealer = fetchLoggedInDealerDetails();
    this.dealerCode = this.dealer.dealerCode;
    // this.getFilters();
    this.fetchAccountAPIPayload.dealerCode = this.dealerCode;
    this.fetchAccountsCountAPIPayload.dealerCode = this.dealerCode;
    if (this.isSaveTableSettings) {
      let configSaved: any = sessionStorage.getItem('tableSettings');
      configSaved = configSaved ? JSON.parse(configSaved) : null;
      configSaved = configSaved?.accounts;
      this.subcribeAccountTableConfig.columnConfig = configSaved
        ? configSaved
        : this.subcribeAccountTableConfig.columnConfig;
    } else {
      const configSaved = sessionStorage.getItem('accountColumnConfig');
      this.subcribeAccountTableConfig.columnConfig = configSaved
        ? JSON.parse(configSaved)
        : this.subcribeAccountTableConfig.columnConfig;
    }
    this.updateComponent();
    const filtersSaved = this.commonFilterService.getItemFromSessionStorage(
      this.pageLabel + 'Filters',
      'selectedFIlters',
      this.pageLabel,
      '',
      ''
    );
    Object.keys(filtersSaved).forEach((key) => {
      if (key === 'applicationName') {
        filtersSaved['product'] = filtersSaved[key];
        delete filtersSaved[key];
      } else if (key === 'applicationPlan') {
        filtersSaved['plan'] = filtersSaved[key];
        delete filtersSaved[key];
      }
    });

    this.fetchAccountAPIPayload.subFilters = filtersSaved ? filtersSaved : {};
    this.fetchAccountsCountAPIPayload.subFilters = filtersSaved ? filtersSaved : {};
    const sortedColumn = sessionStorage.getItem('sortedColumn');
    this.fetchAccountAPIPayload.sortBy = sortedColumn ? JSON.parse(sortedColumn) : 'createdTimestamp';
    const sortedOrder = sessionStorage.getItem('sortedOrder');
    this.fetchAccountAPIPayload.orderBy = sortedOrder ? JSON.parse(sortedOrder) : 'DESC';

    this.updateCustomData();
    this.fetchSettingDetails();
    this.updateColumnConfigCustomData();
    if (!this.isMaintainPagination) {
      this.fetchAccounts(true);
      this.createSettingsPanelConfig();
    }

    if ((window as any).dataLayer?.filter((element: any) => isEmpty(element.updateUserDetails))) {
      this.sharedService.updateDataLayer({
        dealerCode: this.userDealerCode,
        updateUserDetails: '',
        siteId: 'DSP',
        templateName: 'DSP Accounts Page',
      });
    }

    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.onWindowResize(evt);
    });
  }

  isItemExists(propertyName: string, value: any): boolean {
    return this.subcribeAccountTableConfig.columnConfig.some((item: any) => item[propertyName] === value);
  }

  ngOnDestroy() {
    this.observable.unsubscribe();
  }

  onWindowResize($event: any) {
    this.adjustTableHeight({ eventType: 'WINDOW_RESIZE' });
  }

  @HostListener('document:click', ['$event'])
  documentClick = ($event: any) => {
    const isClickedOnGearIcon =
      this.settingIconElement && this.settingIconElement?.nativeElement.contains($event.target);
    const isClickedOnSettingPanel =
      this.settingIconElement && this.settingPanelElement?.nativeElement.contains($event.target);
    if (!(isClickedOnGearIcon || isClickedOnSettingPanel)) {
      this.closeSettingPanel();
    }
  };
  @HostListener('window:popstate', ['$event'])
  onPopState($event: any) {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
  }
  updateCustomData() {
    this.accountTableCustomData.fetchAccounts = () => this.fetchAccounts(false);
  }
  updateColumnConfigCustomData() {
    this.subcribeAccountTableConfig.columnConfig.forEach((config: any) => {
      if (config && config.customData) config.customData = this.accountTableCustomData;
    });
  }
  fetchSettingDetails() {
    this.isTableLoading = true;

    this.tableSettingService.getTableSettings('accounts', this.subcribeAccountTableConfig);
  }
  fetchAccounts(callPaginationApi: boolean) {
    this.accountTableData = [];
    this.isTableLoading = true;
    this.setAutoHeight();
    this.isFetchAccountsAPICallCompleted = false;
    this.isGlobalSearchDisabled = true;
    if (callPaginationApi) {
      if (this.isRemovePaginationSearch) {
        this.fetchAccountsCountAPIPayload.searchText = this.searchDataLoaded ? this.searchTextValue : '';
        this.fetchAccountAPIPayload.searchText = this.searchDataLoaded ? this.searchTextValue : '';
        this.accountTableCustomData.searchString = this.searchDataLoaded ? this.searchTextValue : '';
      } else {
        this.fetchAccountsCountAPIPayload.searchText = this.fetchAccountAPIPayload.searchText.trim();
      }
      this.fetchAccountsAPICallData = forkJoin([
        this.accountsService.getAccounts(this.fetchAccountAPIPayload),
        this.accountsService.getAccountsCount(this.fetchAccountsCountAPIPayload),
      ]).subscribe(
        ([accounts, count]) => {
          this.isFetchCountAPICallCompleted = true;
          this.isTableLoading = false;
          this.isGlobalSearchDisabled = false;
          if (accounts) {
            this.accountTableData = accounts as accountData[];
            this.isTableLoading = false;
            if (this.accountTableData?.length) {
              this.accountTableData.forEach((element: any) => {
                element.ucName = element.ucName ? element.ucName : '-';
                element.dcName = element.dcName ? element.dcName : '-';
                element.planLevel = element.planLevel ? element.planLevel : '-';
                element.planType =
                  element?.planType && element?.planType === 'Unlimited Users'
                    ? 'Unlimited'
                    : element?.planType && element?.planType === 'Limited Users'
                      ? 'Limited'
                      : element?.planType && element?.planType === 'Not Applicable'
                        ? '-'
                        : element.planType
                          ? element.planType
                          : '-';
                element.salesReason =
                  element.salesReason?.reason && element?.salesReason.reason === 'Other'
                    ? element.salesDescription
                    : element.salesReason?.reason
                      ? element.salesReason.reason
                      : '-';

                element.cvaNumber = element.cvaNumber ? element.cvaNumber : '-';
                element.assetPrefix = element.assetPrefix ? element.assetPrefix : '-';
              });
            }
            this.totalSitesCount = (count as getAccountsCountResponse)?.count;
            const accountsPagination = {
              pageSizeOptions: [20, 40, 60, 80, 100],
              advanced: false,
              activePage: 1,
              defaultPageSize: this.isMaintainPagination
                ? this.subcribeAccountTableConfig.pagination.defaultPageSize
                : 20,
              setTheme: true,
              enableDropdownWithPrefix: false,
              paginationLabelText: 'Viewing',
              totalNumberOfRecords: this.totalSitesCount,
            };
            this.subcribeAccountTableConfig = {
              ...this.subcribeAccountTableConfig,
              pagination: accountsPagination,
            };
            this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
          }
        },
        (err: any) => {
          this.isFetchCountAPICallCompleted = true;
          this.isTableLoading = false;
          this.isGlobalSearchDisabled = false;
          this.showToastMessage(this.systemError, 'error');
        }
      );
    } else {
      this.fetchAccountsAPICallData = this.accountsService.getAccounts(this.fetchAccountAPIPayload).subscribe(
        (res: any) => {
          this.isFetchCountAPICallCompleted = true;
          this.isTableLoading = false;
          this.isGlobalSearchDisabled = false;
          if (res) {
            this.accountTableData = res as accountData[];
            if (this.accountTableData?.length) {
              this.accountTableData.forEach((element: any) => {
                element.ucName = element.ucName ? element.ucName : '-';
                element.dcName = element.dcName === null ? '-' : element.dcName;

                element.planType =
                  element?.planType && element?.planType === 'Unlimited Users'
                    ? 'Unlimited'
                    : element?.planType && element?.planType === 'Limited Users'
                      ? 'Limited'
                      : element?.planType && element?.planType === 'Not Applicable'
                        ? '-'
                        : element.planType
                          ? element.planType
                          : '-';
                element.planLevel = element.planLevel ? element.planLevel : '-';
                element.salesReason =
                  element.salesReason?.reason && element?.salesReason.reason === 'Other'
                    ? element.salesDescription
                    : element.salesReason?.reason
                      ? element.salesReason.reason
                      : '-';
                element.cvaNumber = element.cvaNumber ? element.cvaNumber : '-';
                element.assetPrefix = element.assetPrefix ? element.assetPrefix : '-';
              });

              this.adjustTableHeight({ eventType: 'SCROLL_EVENT' });
            }
          }
        },
        (err: any) => {
          this.isFetchCountAPICallCompleted = true;
          this.isTableLoading = false;
          this.isGlobalSearchDisabled = false;
          this.showToastMessage(this.systemError, 'error');
        }
      );
    }
  }

  updateComponent() {
    this.subcribeAccountTableConfig.columnConfig?.map((data: any) => {
      switch (data?.title) {
        case 'Customer':
          data.width = 260;
          data.component = AccountCustomerComponent;
          break;
        case 'Customer Name | ID':
          data.width = 260;
          data.component = AccountCustomerComponent;
          break;
        case 'Customer ID':
          data.width = 200;
          data.component = AccountCustomerComponent;
          break;
        case 'Customer Name':
          data.width = 200;
          data.component = AccountCustomerComponent;
          break;
        case 'CWS ID':
          data.width = 220;
          data.component = AccountCwsIDComponent;
          break;
        case 'Product | Plan':
          data.width = 220;
          data.component = AccountProductPlanComponent;
          break;
        case 'Dealer Customer':
          data.width = 240;
          data.component = AccountDealerCustomerIDComponent;
          break;
        case 'Dealer Customer Name | ID':
          data.width = 240;
          data.component = AccountDealerCustomerIDComponent;
          break;
        case 'Dealer Customer Number':
          data.width = 240;
          data.component = AccountCustomerComponent;
          break;
        case 'Dealer Customer Name':
          data.width = 240;
          data.component = AccountCustomerComponent;
          break;

        case 'Plan Type':
          data.width = 140;
          break;
        case 'Plan Level':
          data.width = 140;
          break;
        case 'Sale Reason':
          data.width = 175;
          data.component = AccountSalesReasonComponent;
          break;
        case 'Prefix':
          data.width = 80;
          data.component = AccountPrefixComponent;
          break;
        default:
          break;
      }
    });
  }
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  onCickEvent(event: any) {
    if (event?.target?.value === '') {
      this.searchHelpText = this.isHideServiceMediaAccess
        ? 'Find Customer / Product | Plan / CWS ID / Dealer Customer (Min 3 Char)'
        : 'Find Customer / Product | Plan / CWS ID / Dealer Customer / Prefix (Min 3 Char)';
    } else {
      this.searchHelpText = '';
    }
  }

  // getFilters() {
  //   this.isTableLoading = true;
  //   this.accountsService.getAccountFilters(this.dealerCode).subscribe((response: any) => {
  //     if (response) {
  //       this.filterResponse = response;
  //       this.isTableLoading = false;
  //     }
  //   });
  // }

  closeSettingPanel = () => {
    this.isSettingVisible = false;
    return false;
  };

  toggleSetting = () => {
    this.isSettingVisible = !this.isSettingVisible;
  };

  outsideClickDataEvent(event: any) {
    this.isCloseEvent = true;
    this.closeTableSettingsData(event);
  }

  openSettingDrawer(settingPanel: TemplateRef<any>) {
    this.settingDrawerRef = this.drawerService.openDrawer<any, CcDrawerConfig>(settingPanel, {
      disableBackdropClick: true,
      isoutsidePointerClick: true,
    });

    this.settingDrawerRef?.onBackdropClick?.subscribe(() => {
      this.isSettingsOutSideClicked = true;
      setTimeout(() => {
        this.isSettingsOutSideClicked = !this.isSettingsOutSideClicked;
      }, 500);
    });
  }

  closeSettingDrawer = () => {
    if (this.settingDrawerRef) this.settingDrawerRef.close();
    return false;
  };
  onBlurEvent(event: any) {
    if (event?.type === 'blur') this.searchHelpText = '';
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getSitesSearch = debounce(() => this.fetchAccounts(true), 1000);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  getAccountsOnPageChange = debounce(() => this.fetchAccounts(false), 100);

  onAccountSearchChange(event: any) {
    this.searchHelpText = '';

    if (event?.value?.length > 0 && event?.value?.length < 3) {
      this.negative = true;
      this.errorText = 'Please enter atleast 3 characters';
    } else {
      this.negative = false;
    }
    if (event?.value?.trim()?.length > 2) {
      this.enableSearch = true;
    } else {
      this.enableSearch = false;
    }
    if (event.value !== '' && this.searchHelpText !== '') {
      this.searchHelpText = '';
    } else if (event?.value?.trim()?.length > 2 && event?.value?.trim() !== this.fetchAccountAPIPayload?.searchText) {
      this.searchValue.setValue(event.value);
      if (this.isRemovePaginationSearch) {
        this.searchTextValue = event.value.trim();
      } else {
        this.fetchAccountAPIPayload.searchText = event.value.trim();
        this.accountTableCustomData.searchString = event.value.trim();
      }
      this.fetchAccountAPIPayload.offset = 0;
    } else if (event.value === '') {
      if (event?.target?.value === '') {
        this.searchHelpText = this.isHideServiceMediaAccess
          ? 'Find Customer / Product | Plan / CWS ID / Dealer Customer (Min 3 Char)'
          : 'Find Customer / Product | Plan / CWS ID / Dealer Customer / Prefix (Min 3 Char)';
      }
      this.searchValue.setValue('');
      this.accountTableCustomData.searchString = '';
      if (this.isRemovePaginationSearch) {
        this.searchTextValue = '';
      }

      if (this.searchDataLoaded) {
        this.fetchAccountAPIPayload.searchText = '';
        this.fetchAccountAPIPayload.offset = 0;
        this.fetchAccounts(true);
        this.searchDataLoaded = false;
      } else {
        this.fetchAccountAPIPayload.searchText = '';
        this.fetchAccountAPIPayload.offset = 0;
      }
    }
  }
  // Table events likes sorting, orderby
  tableEvents(event: any) {
    this.adjustTableHeight(event);
    if (event && event?.eventType === 'SORTING' && event?.event !== 'filterby') {
      this.fetchAccountAPIPayload.sortBy = event?.data?.name || 'createdTimestamp';
      this.fetchAccountAPIPayload.orderBy = event?.event || 'DESC';
      sessionStorage.setItem('sortedColumn', JSON.stringify(this.fetchAccountAPIPayload.sortBy));
      sessionStorage.setItem('sortedOrder', JSON.stringify(this.fetchAccountAPIPayload.orderBy));
      this.getAccountsOnPageChange();
    } else if (event.eventType === 'PAGE_CHANGE_EVENT' && this.isPageChanged(event)) {
      this.setAutoHeight();
      this.isTableLoading = true;
      if (event?.range?.pageSize !== this.fetchAccountAPIPayload.limit) {
        this.subcribeAccountTableConfig.pagination.activePage = 1;
      }
      this.fetchAccountAPIPayload.offset = event.range?.startIndex || 0;
      this.fetchAccountAPIPayload.limit = event.range?.pageSize || 20;
      if (this.isMaintainPagination) {
        if (this.fetchAccountAPIPayload.limit != this.subcribeAccountTableConfig.pagination.defaultPageSize) {
          const config =
            this.updatedTableConfig.length > 0 ? this.updatedTableConfig : this.subcribeAccountTableConfig.columnConfig;
          this.tableSettingService.updateTableSettings(this.pageLabel, config, this.fetchAccountAPIPayload.limit);
          this.subcribeAccountTableConfig.pagination.defaultPageSize = this.fetchAccountAPIPayload.limit;
        }
      }
      this.getAccountsOnPageChange();
    }
  }

  setAutoHeight() {
    const tableRef = this.tableRef?.table?.nativeElement;
    if (this.renderer && tableRef) {
      this.renderer.setStyle(tableRef, 'height', 'auto');
    }
  }

  adjustTableHeight(event: any) {
    if ((event.eventType === 'SCROLL_EVENT' || event.eventType === 'WINDOW_RESIZE') && this.tableRef) {
      const tableRef = this.tableRef?.table?.nativeElement;

      if (this.accountTableData?.length) {
        const adjustHeight = window.innerHeight - 185 + 'px';
        this.renderer.setStyle(tableRef, 'height', adjustHeight);
        this.renderer.setStyle(tableRef, 'max-height', adjustHeight);
      }
    }
  }

  isPageChanged(pageData: any) {
    return (
      pageData?.range?.startIndex !== this.fetchAccountAPIPayload.offset ||
      pageData?.range?.pageSize !== this.fetchAccountAPIPayload.limit
    );
  }

  //Add new subscription
  addNewSubscription() {
    const modalRef = this.modal.openModal(NewSubscriptionComponent, {
      type: this.isTheme === '1.0' ? 'semi-modal' : 'full-modal',
      backdropClass: 'cc-modal-full-window-backdrop',
      panelClass: 'cc-modal-full-window',
      data: {},
    });
    modalRef.afterClosed().subscribe(() => {
      this.fetchAccounts(true);
      // this.getFilters();
    });
  }
  openEditDrawer() {
    this.editPlanRef = this.drawerService.openDrawer<EditPlanComponent, CcDrawerConfig>(EditPlanComponent, {
      data: {
        headerType: 'drawerContent',
        apply: this.applyFromFooter?.bind(this),
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
  }

  parseCustomername(rowData: accountData) {
    const customerData = `${rowData.ucName}-${rowData.ucid} `;
    if (customerData && customerData.length > 50) {
      return `${customerData.substring(0, 20)}...`;
    } else {
      return customerData;
    }
  }

  createSettingsPanelConfig() {
    if (this.subcribeAccountTableConfig.columnConfig) {
      const trimmedTableConfig = this.subcribeAccountTableConfig.columnConfig
        .filter((obj) => obj)
        .map((config: any) => {
          if (config.isFrozen === undefined) {
            config.isFrozen = false;
          }
          return {
            title: config.title ? config.title : '',
            name: config.name ? config.name : '',
            isFrozen: config.isFrozen ? config.isFrozen : false,
            isVisible: config.isVisible ? config.isVisible : false,
            isAlwaysMandatory: config.isAlwaysMandatory ? config.isAlwaysMandatory : false,
          };
        });
      this.tableSettingsPanelConfig = {
        features: trimmedTableConfig,
        visibilityToggle: false,
        ITableSettingsPanelTooltip: {
          columnTooltip: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          columnTooltipTablet: 'Maximum of frozen columns reached. To freeze these column, you must deselect another.',
          drawerTooltip: 'Maximum of frozen columns reached. To freeze this column, you must deselect another.',
        },
      };
    }
    if (this.isMaintainPagination) {
      this.fetchAccountAPIPayload.limit = this.subcribeAccountTableConfig.pagination.defaultPageSize;
      this.fetchAccounts(true);
    }
  }

  saveSettings(event: ITableSettings) {
    if (!this.isCloseEvent) {
      const originalConfig: any = {};
      Object.assign(originalConfig, this.subcribeAccountTableConfig);
      this.updatedTableConfig = [];
      event.features.forEach((config, index) => {
        const data = originalConfig.columnConfig.find(
          (dataObj: { name: string }) => config.name.indexOf(dataObj.name) !== -1
        );
        if (data) {
          data.isVisible = config.isVisible;
          data.isFrozen = config.isFrozen;
          data.priority = index + 1;
          data.title = config.title;

          switch (data.title) {
            case 'Customer Name | ID':
              data.width = 260;
              data.component = AccountCustomerComponent;
              break;
            case 'Customer ID':
              data.width = 200;
              data.component = AccountCustomerComponent;
              break;
            case 'Customer Name':
              data.width = 200;
              data.component = AccountCustomerComponent;
              break;
            case 'Dealer Customer Number':
              data.width = 240;
              data.component = AccountCustomerComponent;
              break;
            case 'Dealer Customer Name':
              data.width = 240;
              data.component = AccountCustomerComponent;
              break;
            case 'Customer':
              data.width = 260;
              data.component = AccountCustomerComponent;
              break;
            case 'Dealer Customer':
              data.width = 240;
              data.component = AccountDealerCustomerIDComponent;
              break;
            case 'CVA Number':
              data.width = 150;
              break;
            case 'CWS ID':
              data.width = 220;
              data.component = AccountCwsIDComponent;
              break;
            case 'Product | Plan':
              data.width = 220;
              data.component = AccountProductPlanComponent;
              break;
            case 'Dealer Customer Name | ID':
              data.width = 240;
              data.component = AccountDealerCustomerIDComponent;
              break;
            case 'Plan Type':
              data.width = 140;
              break;
            case 'Plan Level':
              data.width = 140;
              break;
            case 'Sale Reason':
              data.width = 175;
              data.component = AccountSalesReasonComponent;
              break;
            case 'Prefix':
              data.width = 80;
              data.component = AccountPrefixComponent;
              break;
            default:
              break;
          }
        }
        this.updatedTableConfig.push(data);
      });
      originalConfig.columnConfig = this.updatedTableConfig;
      this.subcribeAccountTableConfig = { ...originalConfig };
      if (this.isMaintainPagination) {
        this.tableSettingService.updateTableSettings(
          this.pageLabel,
          this.updatedTableConfig,
          this.fetchAccountAPIPayload.limit
        );
      } else {
        this.tableSettingService.updateTableSettings(this.pageLabel, this.updatedTableConfig);
      }
      sessionStorage.setItem('accountColumnConfig', JSON.stringify(this.subcribeAccountTableConfig.columnConfig));
      this.createSettingsPanelConfig();
      this.cdr.detectChanges();
    }
    this.isCloseEvent = false;
  }

  getCancelReason() {
    if (isEmpty(this.dspStoreData?.accountCancelReasons)) {
      const payload = {
        profile: this.profile,
      };
      this.subscribeAccountsService.getCancelReason(payload).subscribe({
        next: (result: any) => {
          if (result.length > 0) {
            result.forEach((element: any) => {
              element.label = element.reason;
              element.value = element.reason;
            });
            this.store.dispatch(getAccountCancelReasonDetails({ accountCancelReasons: result }));
          }
        },
        error: (err: any) => {
          this.store.dispatch(getAccountCancelReasonDetails({ accountCancelReasons: [] }));
        },
      });
    }
  }
  dataLoaded() {
    this.searchDataLoaded = true;
  }
  onFocusEvent() {
    setTimeout(() => {
      const searchLength = this.searchValue.value.length || 0;
      this.accountsSearchBox?.nativeElement.setSelectionRange(searchLength, searchLength);
    }, 100);
  }

  loadChangeEmit(res: any) {
    this.fetchAccountAPIPayload.subFilters = res;
    this.fetchAccountsCountAPIPayload.subFilters = res;
    this.fetchAccounts(true);
  }

  exportSubscription() {
    const message = `Your request is in process. When your extract is ready, the download will begin instantly.`;
    this.showToastMessage(message, 'success');
    this.showLoader = true;
    this.accountsService.accountsExport(this.fetchAccountAPIPayload).subscribe(
      (res: any) => {
        if (res) {
        }
      },
      (err: any) => {
        if (err.status === 200) {
          this.showLoader = false;
          const currentDate = new Date();
          const dateString = currentDate.toISOString().slice(0, 10);
          const year = currentDate.getFullYear();
          const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
          const day = ('0' + currentDate.getDate()).slice(-2);
          const fileName = `MANAGE_ACCOUNTS_${year}_${month}_${day}.csv`;
          const xmlResponse = err.error.text;
          const BOM = '\uFEFF';
          const blob = new Blob([BOM + xmlResponse], { type: 'text/csv;charset=utf-8' });
          this.downloadCSV(blob, fileName);
        } else {
          const message = 'Download failed. Please try again';
          this.showToastMessage(message, 'error');
          this.showLoader = false;
        }
      }
    );
  }

  downloadCSV(csv: any, filename: any) {
    this.showLoader = false;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const csvUrl = URL.createObjectURL(csv);
    a.href = csvUrl;
    a.download = filename;
    a.target = '_parent';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }

  tableSettingsClose(event: any) {
    if (event === 'CLOSE_EVENT') {
      this.isCloseEvent = true;
    }
  }

  closeTableSettingsData(event: any) {
    const originalConfig: any = {};
    let isConfirmationPopupNeeded = false;
    Object.assign(originalConfig, this.subcribeAccountTableConfig);
    for (let i = 0; event?.features?.length > i; i++) {
      const data = originalConfig.columnConfig.find(
        (dataObj: { name: string }) => event?.features[i]?.name === dataObj?.name
      );
      const indexOfOriginalConfig = originalConfig.columnConfig.indexOf(data);
      if (data) {
        if (typeof data.isFrozen === 'undefined') data.isFrozen = false;
        if (
          data.isVisible !== event.features[i].isVisible ||
          data.isFrozen !== event.features[i].isFrozen ||
          indexOfOriginalConfig !== i
        ) {
          isConfirmationPopupNeeded = true;
          break;
        } else {
          isConfirmationPopupNeeded = false;
        }
      }
    }

    if (this.isCloseEvent && isConfirmationPopupNeeded) {
      this.openTableSettingsConfirmationPopUp();
    } else {
      this.isCloseEvent = false;
      this.isTableSettingClose = true;
      this.closeSettingDrawer();
      setTimeout(() => {
        this.isTableSettingClose = false;
      }, 500);
    }
  }

  openTableSettingsConfirmationPopUp() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_TITLE,
        messageArray: [dspConstants.Common.TABLE_SETTINGS_CLOSE_CONFIRMATION_POPUP_BODY_LINE1],
        showCrossMark: true,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SAVE_CLOSE,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL_CHANGES,
      };

      this.modalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '534px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.modalRef?.afterClosed()?.subscribe((result: any) => {
        if (result === dspConstants.PopupButtons.SAVE_CLOSE) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CANCEL_CHANGES) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else if (result === dspConstants.PopupButtons.CROSS) {
          this.isCloseEvent = false;
        }
      });
    } else {
      this.modalRef = this.modal.openModal(TableSettingsCloseConfirmationComponent, {
        width: '534px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef?.afterClosed()?.subscribe((result: any) => {
        if (result === true) {
          this.isCloseEvent = false;
          this.isTableSettingsApply = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingsApply = false;
          }, 500);
        } else if (result === false) {
          this.isCloseEvent = false;
          this.isTableSettingClose = true;
          this.closeSettingDrawer();
          setTimeout(() => {
            this.isTableSettingClose = false;
          }, 500);
        } else {
          this.isCloseEvent = false;
        }
      });
    }
  }
}
