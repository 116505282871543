import { Component } from '@angular/core';
import { UserAdminService } from '../../../services/user-admin.service';
import { ConfigureAccessComponent } from '../../configure-access/configure-access.component';
import { CcDrawerConfig, CcDrawerSize, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-user-admin-value',
  templateUrl: './user-admin-value.component.html',
  styleUrls: ['./user-admin-value.component.scss'],
})
export class UserAdminValueComponent {
  public value: any;
  public rowData: any;
  public columnTitle: any;
  public customData: any;
  public columnWithHeader: any;
  roleName: any;
  configureAccessDrawerRef: any;
  loggedInUserDetails!: any;
  dspConfig: any;
  constructor(
    private userAdminService: UserAdminService,
    private drawerService: DrawerService,
    private store: Store<DSPAppState>
  ) {}

  ngOnInit(): void {
    this.store?.select('dsp')?.subscribe((dsp) => {
      this.loggedInUserDetails = (dsp && dsp?.userDetails) || {};
    });
    this.rowData = this.value;
    this.customData = this.columnWithHeader?.customData;
  }

  configureAccess(evt: any, rowData: any) {
    evt?.preventDefault();

    const displayInfo = rowData?.appInfo;
    displayInfo?.forEach((data: any, index: any) => {
      if (data?.appName === 'Dealer Services Portal') {
        displayInfo[index].appName = 'Dealer Services Portal (Web & Mobile)';
      }
    });

    this.configureAccessDrawerRef = this.drawerService?.openDrawer<ConfigureAccessComponent, CcDrawerConfig>(
      ConfigureAccessComponent,
      {
        data: {
          headerType: 'Configure Access',
          rowData: rowData,
          expandRowDetails: displayInfo,
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    this.configureAccessDrawerRef?.afterClosed()?.subscribe((res: any) => {
      if (res) {
        this.customData?.refreshTable();
      }
    });
  }
}
