export const assetsMultiProductMockData = [
  {
    assetId: 1141165,
    make: 'CAT',
    serialNumber: '12301001',
    model: 'IOPIOPUIPUIOP',
    modelYear: '2021',
    ucid: '2969473025',
    ucidName: 'MANI 1',
    dealerCode: 'TD00',
    dealerCustomerNumber: 'TESTMEL10',
    dcnName: '#5 LTD. OF VIRGINIA',
    deviceType: 'PLE641',
    appId: 'adbc5437-fe26-4719-91c6-10f100ffde73',
    reportingState: 'Subscribed',
    networkManagerSerialNumber: 'Z99-00123001100-3P',
    ownerShipType: 'O',
    lastCancelReason: 'Subscription Changed',
    cancelReasonCreatedTimestamp: '05/17/2023 09:04:28',
    ownerShipCount: 1,
    hasEdRecord: 1,
    loggedInHasEntryEd: 1,
    servicesSubscribed: [
      {
        appId: 123456,
        appName: 'New VisionLink',
        prepay: true,
        contractEndDate: '05/17/2024',
        isPrimaryProduct: true,
        catLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
          {
            planId: 1002,
            planName: 'Onboard Analytics',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        customerLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        dealerLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
          {
            planId: 1003,
            planName: 'Advanced Productivity 1',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
      },
      {
        appId: 123457,
        appName: 'MineStar',
        prepay: false,
        isPrimaryProduct: false,
        catLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        customerLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        dealerLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
      },
    ],
  },
  {
    assetId: 322203,
    make: 'Z99',
    serialNumber: '1DEFX1CB2JKC88401',
    model: '2019',
    modelYear: '2019',
    ucid: '2969473140',
    ucidName: 'Enmaco Motorer',
    dealerCode: 'TD00',
    dealerCustomerNumber: '000114',
    dcnName: '#5 LTD. OF VIRGINIA',
    deviceType: '3PDATA',
    appId: '1f84dfb3-6207-4930-81cd-ea8e723526f1',
    reportingState: 'Subscribed',
    networkManagerSerialNumber: 'Z99-00123001100-3P',
    ownerShipType: 'O',
    lastCancelReason: 'Subscription Changed',
    cancelReasonCreatedTimestamp: '05/17/2023 09:04:28',
    ownerShipCount: 1,
    hasEdRecord: 1,
    loggedInHasEntryEd: 1,
    servicesSubscribed: [
      {
        appId: 123456,
        appName: 'New VisionLink',
        prepay: true,
        contractEndDate: '05/17/2024',
        isPrimaryProduct: false,
        catLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
          {
            planId: 1002,
            planName: 'Onboard Analytics',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        customerLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        dealerLevel: [
          {
            planId: 1001,
            planName: 'Performance Pro',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'promotionName',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Advanced Productivity',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
      },
      {
        appId: 123457,
        appName: 'MineStar',
        prepay: false,
        isPrimaryProduct: true,
        catLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        customerLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
        dealerLevel: [
          {
            planId: 1001,
            planName: 'Fleet on-board',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'Base',
            promotion: 'starMenu',
            promotionExpirationDate: '05/17/2023 09:04:26',
          },
          {
            planId: 1002,
            planName: 'Detect DSS-H - Guardian 2 - Monitoring',
            subscriptionStartDate: '05/17/2023 09:04:26',
            type: 'addOn',
          },
        ],
      },
    ],
  },
  {
    assetId: 350494,
    make: 'Z99',
    serialNumber: '10C11000',
    model: 'CCDD2C',
    modelYear: '2019',
    ucid: '2969481313',
    ucidName: 'MAB',
    dealerCode: 'TD00',
    dealerCustomerNumber: '022232',
    dcnName: '* MARTIN COUNTY COAL',
    deviceType: '3PDATA',
    appId: 'a7097029-0981-492b-8048-6252253b7665',
    reportingState: 'Suspended',
    networkManagerSerialNumber: '-',
    ownerShipType: 'O',
    lastCancelReason: '-',
    cancelReasonCreatedTimestamp: '05/17/2023 09:04:28',
    ownerShipCount: 2,
    hasEdRecord: 1,
    loggedInHasEntryEd: 1,
    servicesSubscribed: [],
  },
];
