<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title cursor-changes">{{ data.title }}</div>

      <ng-container *ngIf="isShowSubscribeFilterOnExpand">
        <div class="cc-modal-close">
          <div class="site-right-panel">
            <span class="site-panel-items filter-container">
              <div *ngIf="!filterIcon">
                <cc-icon fontSet="icomoon" (click)="openFilter('withActions')">filter</cc-icon>
              </div>
              <div *ngIf="filterIcon">
                <img
                  class="filter-icon-checked"
                  src="..\assets\images\filter-applied.svg"
                  (click)="openFilter('withActions')"
                />
              </div>
            </span>
            <span class="site-panel-items download-container">
              <img class="cc-close-full" src="/assets/icons/closeFull.svg" [alt]="'closeIcon'" (click)="closeModel()" />
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isShowSubscribeFilterOnExpand">
        <div class="cc-modal-close">
          <img class="cc-close-full" src="/assets/icons/closeFull.svg" [alt]="'closeIcon'" (click)="closeModel()" />
        </div>
      </ng-container>
    </div>
    <div class="cc-modal-content">
      <div class="expanded-linechart" *ngIf="nodata && nodata.length">
        <cc-bar-chart
          [chartColor]="['#0078d6', '#e56c00', '#9826de', '#006618']"
          [chartId]="100"
          [chartData]="this.chart100OriginalData"
          [chartConfig]="savedConfig"
          [chartType]="'line'"
          [responsiveConfig]="chartResponsiveConfig"
        ></cc-bar-chart>
      </div>
    </div>
    <div class="expanded-linechart" *ngIf="nodata && nodata.length === 0" class="no-results-found-expanded">
      <img src="../../../../../assets/images/no-data-big.png" />
      <p class="no-results-text">No data to display</p>
    </div>
  </div>
</div>
