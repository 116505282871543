import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { MONTH, PRODUCT } from '../../sub-asset-trend.interface';
import { isEmpty, rest } from 'lodash-es';
import {
  CcDrawerConfig,
  CcDrawerRef,
  CcDrawerSize,
  CC_DRAWER_DATA,
  DrawerService,
} from '@cat-digital-workspace/shared-ui-core/drawer';
import { SubAssetTrendShowallFilterComponent } from '../sub-asset-trend-showall-filter/sub-asset-trend-showall-filter.component';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { setSelectedFilterItem } from '../../../actions/dashboard-needs-review.actions';
import { FilterCloseDashboardComponent } from '../../../../filter-close-dashboard/filter-close-dashboard.component';
import { CcModal } from '@cat-digital-workspace/shared-ui-core';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { DashboardService } from '../../../services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dsp-next-gen-ui-sub-asset-trend-filter',
  templateUrl: './sub-asset-trend-filter.component.html',
  styleUrls: ['./sub-asset-trend-filter.component.scss'],
})
export class SubAssetTrendFilterComponent implements OnInit, OnDestroy {
  storedFilters: any;
  savedFilters: any;
  isAccordionClose = false;
  isAccordionCloseProduct = false;

  //selectedRangeOption: any;
  selectedRangeValue: any;
  minValue: any;
  maxValue: any;
  midValue: any;
  clearAll = false;
  clearMonth = false;
  masterSelected = true;
  indeterminate = false;
  event = true;
  clickClear = false;
  checkedMonth = [];
  headerContent: string | undefined;
  selectedMonthFilters: Array<string> = [];
  tempSelectedMonthFilters: Array<string> = [];
  tempSelectedProductFilters: Array<string> = [];
  selectedProductFilters: Array<string> = [];
  //rangeListFilterData: Array<RANGE> = [];
  monthListFilterData: Array<MONTH> = [];
  tempMonthListFilterData: Array<MONTH> = [];
  productListFilterData: Array<PRODUCT> = [];
  tempProductListFilterData: Array<PRODUCT> = [];
  checkboxDisabled = false;
  sliderChanged = false;
  isFilterChange = false;
  flag = false;
  modalRef: any;

  isDrawerOpen = false;
  defaultMonthFilter: Array<string> = [];
  previousSelectedFilters: any;
  disableApplybtn = true;
  subscribedAssetTrend: any;
  chart100OriginalData: any;
  nodata = false;
  filtersSaved: any;
  value: any;
  dspStoreData!: any;
  enablePop = false;

  isShowSubscribeFilterOnExpand = false;
  cancelSelectedMonth: any = [];
  cancelSelectedProduct: any = [];
  monthSelected = false;
  productSelected = false;
  deleteChipSelectedMonth = false;
  deleteChipSelectedProduct = false;
  closeIconClick = false;
  public subscription: Subscription = new Subscription();
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      //rangeList: Array<string>;
      monthList: Array<string>;
      productList: Array<string>;
      filters: object;
      savedFilters: any;
      minValue: Number;
      maxValue: Number;
      savedMonthFilter: boolean;
      isDrawerOpen: boolean;
      noData: boolean;
    },
    public dialogRef: CcDrawerRef<SubAssetTrendFilterComponent, CcDrawerConfig>,
    private drawerService: DrawerService,
    public showAllSitesDialog: CcDrawerRef<SubAssetTrendShowallFilterComponent, CcDrawerConfig>,
    private changeDetector: ChangeDetectorRef,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    private dspUtilsCommonService: DspUtilsCommonService,
    private dashboardService: DashboardService
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowSubscribeFilterOnExpand = this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        ? this.dspStoreData?.featureFlagInfo?.isShowSubscribeFilterOnExpand
        : false;
    });
    this.subscription = this.dashboardService.subscrapplyFilterClicked.subscribe((res: any) => {
      if (res) {
        this.closeModal(false);
      }
    });

    this.nodata = this.header.noData;
    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.maxValue = this.header.maxValue;
    this.isDrawerOpen = this.header.isDrawerOpen;
    this.store.select('subsTrendsavedFilterData').subscribe((x) => {
      this.filtersSaved = x.filterData.subsTrendsavedFilterData;
    });
    // const filtersSaved = sessionStorage.getItem('lineChartFilters');
    // this.header.savedFilters = this.filtersSaved ? this.filtersSaved : {};
    this.savedFilters = this.header.savedFilters ? this.header.savedFilters : {};
    this.selectedMonthFilters = this.savedFilters && this.savedFilters.month ? this.savedFilters.month : [];
    this.selectedProductFilters =
      this.savedFilters && this.savedFilters.application ? this.savedFilters.application : [];
    if (this.selectedMonthFilters.length > 0 || this.selectedProductFilters.length > 0) {
      this.selectedChipsMonth();
      this.selectedChipsProuct();
      // this.openExpand();
    }
    //this.selectedRangeOption = this.savedFilters && this.savedFilters.rangeOption ? this.savedFilters.rangeOption : [];
    //this.selectedRangeValue = this.savedFilters && this.savedFilters.range ? this.savedFilters.range : [];
    //if (this.selectedRangeOption == 'Custom Range') {
    //   this.midValue = this.savedFilters.range[1];
    //   this.minValue = this.savedFilters.range[0];
    // } else {
    //   this.minValue = this.header.minValue;
    //   this.midValue = this.maxValue - this.minValue;
    // }
    this.minValue = this.header.minValue;
    this.midValue = this.maxValue - this.minValue;
    this.loadFilters();
    this.checkboxvalidation1();
    this.productCheckboxValidation();
  }
  loadFilters() {
    // const ismonthSelected = sessionStorage.getItem('clearMonth');
    // this.clearMonth = ismonthSelected ? JSON.parse(ismonthSelected) : false;

    this.header.monthList?.forEach((val) => {
      const monthObj: MONTH = {
        month: val,
        checked: false,
      };
      this.monthListFilterData.push(monthObj);
    });
    // if (this.clearMonth) {
    //   this.header.monthList?.forEach((val) => {
    //     const monthObj: MONTH = {
    //       month: val,
    //       checked: false,
    //     };
    //     this.monthListFilterData.push(monthObj);
    //   });
    // } else {
    //   this.header.monthList?.forEach((val) => {
    //     const monthObj: MONTH = {
    //       month: val,
    //       checked: false,
    //     };
    //     this.monthListFilterData.push(monthObj);
    //   });
    // }
    if (this.selectedMonthFilters.length > 0) {
      this.flag = true;
    }
    if (!this.clearMonth) {
      if (this.selectedMonthFilters.length == 0) {
        this.monthListFilterData.map((val) => {
          // this.selectedMonthFilters.push(val.month);
          this.defaultMonthFilter.push(val.month);
        });
      }
    }
    this.header.productList?.forEach((val) => {
      const productObj: PRODUCT = {
        product: val,
        checked: false,
      };
      this.productListFilterData.push(productObj);
    });
    if (isEmpty(this.header.savedFilters) && !this.clearMonth) {
      this.previousSelectedFilters = {
        range: [this.header.minValue, this.header.maxValue], //this.selectedRangeValue || [],
        month: this.defaultMonthFilter || [],
        application: [],
      };
    } else {
      this.store.select('subsTrendsavedFilterData').subscribe((x) => {
        this.filtersSaved = x.filterData.subsTrendsavedFilterData;
      });
      // const filtersSaved = sessionStorage.getItem('lineChartFilters');
      this.previousSelectedFilters = this.filtersSaved ? this.filtersSaved : {};
    }
    // this.header.rangeList?.forEach((val) => {
    //   const rangeObj: RANGE = {
    //     rangeOption: val,
    //     checked: false,
    //   };
    //   this.rangeListFilterData.push(rangeObj);
    // });
  }
  openExpandMonth() {
    this.isAccordionClose = false;
  }
  selectedChipsMonth() {
    this.isAccordionClose = true;
  }
  selectedChipsProuct() {
    this.isAccordionCloseProduct = true;
  }
  openExpandProduct() {
    this.isAccordionCloseProduct = false;
  }
  checkboxvalidation() {
    if (this.selectedMonthFilters.length == 12) {
      this.indeterminate = false;
      this.masterSelected = true;
      this.flag = false;
      this.monthListFilterData.forEach((element) => {
        element.checked = true;
      });
      this.enablePop = true;
    } else if (this.selectedMonthFilters.length != 0 && this.selectedMonthFilters.length < 12) {
      this.indeterminate = true;
      this.masterSelected = false;
      this.monthListFilterData.forEach((element) => {
        if (this.selectedMonthFilters.find((month: any) => month === element.month)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.enablePop = true;
    } else {
      this.indeterminate = false;
      this.masterSelected = false;
      // this.flag = true;
      this.monthListFilterData.forEach((element) => {
        element.checked = false;
      });
      this.enablePop = false;
    }
  }
  productCheckboxValidation() {
    if (this.selectedProductFilters.length !== 0) {
      this.indeterminate = true;
      this.masterSelected = false;
      this.productListFilterData.forEach((element) => {
        if (this.selectedProductFilters.find((product: any) => product === element.product)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
      this.indeterminate = false;
      this.masterSelected = false;
      // this.flag = true;
      this.productListFilterData.forEach((element) => {
        element.checked = false;
      });
    }
  }
  checkboxvalidation1() {
    if (this.selectedMonthFilters.length == 12) {
      this.indeterminate = false;
      this.masterSelected = true;
      this.flag = false;
      this.monthListFilterData.forEach((element) => {
        element.checked = true;
      });
    } else if (this.selectedMonthFilters.length != 0 && this.selectedMonthFilters.length < 12) {
      this.indeterminate = true;
      this.masterSelected = false;
      this.monthListFilterData.forEach((element) => {
        if (this.selectedMonthFilters.find((month: any) => month === element.month)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
      this.indeterminate = false;
      this.masterSelected = false;
      // this.flag = true;
      this.monthListFilterData.forEach((element) => {
        element.checked = false;
      });
    }
  }
  /**commented below function as we are not using it anywhere */
  // checkUncheckAllFilters(event: any) {
  //   this.isDrawerOpen = false;
  //   this.isFilterChange = true;
  //   this.event = event.checked;
  //   const checkAllFilter: any = [];
  //   if (event.checked) {
  //     if (this.selectedMonthFilters.length > 0) {
  //       this.monthListFilterData?.map((data: any) => {
  //         this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(data.month), 1);
  //       });
  //       this.monthListFilterData?.map((data: any) => {
  //         const updatedObj = { ...data, checked: true };
  //         checkAllFilter.push(updatedObj);
  //         this.selectedMonthFilters.push(data.month);
  //       });
  //     } else {
  //       this.monthListFilterData?.map((data: any) => {
  //         const updatedObj = { ...data, checked: true };
  //         checkAllFilter.push(updatedObj);
  //         this.selectedMonthFilters.push(data.month);
  //       });
  //     }
  //     this.flag = false;

  //     this.monthListFilterData = [...checkAllFilter];
  //     this.masterSelected = true;
  //     this.indeterminate = false;
  //   } else {
  //     this.monthListFilterData?.map((data: any) => {
  //       const updatedObj = { ...data, checked: false };
  //       checkAllFilter.push(updatedObj);
  //       this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(data.month), 1);
  //     });
  //     this.monthListFilterData = [...checkAllFilter];
  //     this.masterSelected = false;
  //     this.indeterminate = false;
  //   }
  //   this.enableApplyActionButton();
  // }
  showAll(type: string, values: Array<object>, title: string) {
    this.showAllSitesDialog = this.drawerService.openDrawer<SubAssetTrendShowallFilterComponent, CcDrawerConfig>(
      SubAssetTrendShowallFilterComponent,
      {
        data: {
          headerType: type,
          allValues: values,
          headerTitle: title,
          selectedMonthFilters: this.selectedMonthFilters,
          selectedProductFilters: this.selectedProductFilters,
          savedFilters: this.savedFilters || {},
          ref: this.dialogRef,
          flag: this.flag,
          closeIconClick: this.closeIconClick,
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    if (this.showAllSitesDialog) {
      this.showAllSitesDialog?.onBackdropClick?.subscribe((data: any) => {
        if (data) {
          const month = this.dashboardService.getMonth();
          const product = this.dashboardService.getProduct();
          if (month || product) {
            this.enableFilterClose();
            this.showAllSitesDialog.close();
          } else {
            this.showAllSitesDialog.close();
          }
        }
      });
    }
    this.showAllSitesDialog.afterClosed().subscribe((res: any) => {
      this.checkboxvalidation();
      if (res) {
        this.closeModal(res);
      }
    });
  }

  clearFilters() {
    this.monthListFilterData.forEach((element) => {
      element.checked = false;
    });
    this.productListFilterData.forEach((element) => {
      element.checked = false;
    });
    // this.clearMonth = false;

    this.selectedMonthFilters = [];
    this.selectedProductFilters = [];
    this.store.select('subsTrendsavedFilterData').subscribe((x: any) => {
      const filtersSaved = x.filterData.subsTrendsavedFilterData;
      if (filtersSaved?.month?.length > 0 || filtersSaved?.application?.length > 0) {
        this.dashboardService.setMonth(this.selectedMonthFilters);
        this.dashboardService.setProduct(this.selectedProductFilters);
        this.selectedMonthFilters = [];
        this.selectedProductFilters = [];
        this.enablePop = true;
      } else {
        this.enablePop = false;
      }
    });
  }
  closeModal(val?: boolean) {
    if (val) this.closeIconClick = val;
    if ((this.enablePop || this.closeIconClick) && this.isShowSubscribeFilterOnExpand) {
      this.enableFilterClose();
    } else {
      this.dialogRef.close();
    }
  }
  enableFilterClose() {
    this.enablePop = false;
    this.modalRef = this.modal.openModal(FilterCloseDashboardComponent, {
      width: '524px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
    this.modalRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.applyAllFilter();
        this.enablePop = false;
      } else if (result === false) {
        let obj: any;
        const restoreLabel = this.dspUtilsCommonService.getValue('selectedMonth');
        this.cancelSelectedMonth = restoreLabel ? JSON.parse(restoreLabel) : null;
        if (this.cancelSelectedMonth?.length > 0 || this.cancelSelectedMonth) {
          this.monthSelected = true;
          this.deleteChipSelectedMonth = true;
          this.cancelSelectedMonth?.forEach((ele: any) => {
            obj = {
              month: ele,
              checked: true,
            };
          });
          this.selectedMonthFilter({ checked: true }, obj);
          this.onDeleteChip(obj?.month);
        }

        const restoreLabel1 = this.dspUtilsCommonService.getValue('selectedProduct');
        this.cancelSelectedProduct = restoreLabel1 ? JSON.parse(restoreLabel1) : null;
        if (this.cancelSelectedProduct?.length > 0 || this.cancelSelectedProduct) {
          this.productSelected = true;
          this.deleteChipSelectedProduct = true;

          this.cancelSelectedProduct?.forEach((ele: any) => {
            obj = {
              product: ele,
              checked: true,
            };
          });
          this.selectedProductFilter({ checked: true }, obj);
          this.onDeleteChipProduct(obj?.month);
        }
        this.dialogRef.close();
        this.enablePop = false;
      }
    });
  }

  onDeleteChip(filter: any) {
    let selectedValue = this.monthListFilterData.find((obj) => obj.month === filter);
    if (selectedValue && !this.deleteChipSelectedMonth) {
      selectedValue.checked = false;
      this.deleteChipSelectedMonth = false;
      this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(selectedValue.month), 1);
    } else {
      this.selectedMonthFilters.splice(0, this.selectedMonthFilters.length);
      this.cancelSelectedMonth.forEach((ele: any) => {
        this.selectedMonthFilters.push(ele);
      });
    }
    this.enablePop = true;
    this.dashboardService.setMonth(this.selectedMonthFilters);
  }
  onDeleteChipProduct(filter: any) {
    let selectedValue = this.productListFilterData.find((obj) => obj.product === filter);
    if (selectedValue && !this.deleteChipSelectedProduct) {
      selectedValue.checked = false;
      this.deleteChipSelectedProduct = false;
      this.selectedProductFilters.splice(this.selectedProductFilters.indexOf(selectedValue.product), 1);
    } else {
      this.selectedProductFilters.splice(0, this.selectedProductFilters.length);
      this.cancelSelectedProduct.forEach((ele: any) => {
        this.selectedProductFilters.push(ele);
      });
    }
    this.enablePop = true;
    this.dashboardService.setProduct(this.selectedProductFilters);
  }

  selectedMonthFilter(event: any, value: any) {
    this.isDrawerOpen = false;
    this.isFilterChange = true;
    if (this.monthSelected) {
      this.cancelSelectedMonth.forEach((ele: any) => {
        this.selectedMonthFilters.push(ele);
        value.checked = true;
      });
    } else {
      if (event.checked) {
        this.selectedMonthFilters.push(value.month);
        value.checked = true;
      } else {
        this.selectedMonthFilters.splice(this.selectedMonthFilters.indexOf(value.month), 1);
        value.checked = false;
      }
      this.enablePop = true;
      this.monthSelected = false;

      if (this.selectedMonthFilters.length == 12) {
        this.indeterminate = false;
        this.masterSelected = true;
      } else if (this.selectedMonthFilters.length != 0 && this.selectedMonthFilters.length < 12) {
        this.indeterminate = true;
        this.masterSelected = false;
      } else {
        this.indeterminate = false;
        this.masterSelected = false;
      }
      if (this.selectedMonthFilters.length > 0) {
        this.clearAll = false;
      }
      this.deleteChipSelectedMonth = false;
    }
    const selectedMonthFilters = this.selectedMonthFilters;
    this.dashboardService.setMonth(selectedMonthFilters);
  }
  selectedProductFilter(event: any, value: any) {
    this.isDrawerOpen = false;
    this.isFilterChange = true;
    if (this.productSelected) {
      this.cancelSelectedProduct.forEach((ele: any) => {
        this.selectedProductFilters.push(ele);
        value.checked = true;
      });
    } else {
      if (event.checked) {
        this.selectedProductFilters.push(value.product);
        value.checked = true;
      } else {
        this.selectedProductFilters.splice(this.selectedProductFilters.indexOf(value.product), 1);
        value.checked = false;
      }
      this.enablePop = true;

      if (this.selectedProductFilters.length > 0) {
        this.clearAll = false;
      }
    }
    this.deleteChipSelectedProduct = false;
    const selectedProductFilters = this.selectedProductFilters;
    this.dashboardService.setProduct(selectedProductFilters);
  }
  disableCheckbox(filter: any) {
    let result = false;
    if (this.selectedProductFilters.length == 3) {
      if (filter.checked == false) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  }
  // rangeOptionClick(event: any) {
  //   this.selectedRangeValue = [];
  //   this.selectedRangeOption = event.value;
  //   this.rangeListFilterData.forEach((ele) => {
  //     if (ele.rangeOption == this.selectedRangeOption) {
  //       ele.checked = true;
  //     }
  //   });
  //   if (this.selectedRangeOption != 'Custom Range') {
  //     this.selectedRangeOption.split('-').map((ele: any) => {
  //       this.selectedRangeValue.push(Number(ele));
  //     });
  //   } else {
  //     this.selectedRangeValue.push(this.minValue);
  //     this.selectedRangeValue.push(this.midValue);
  //   }
  // }
  applyAllFilter() {
    this.changeDetector.detectChanges();
    this.tempSelectedMonthFilters = [];
    this.monthListFilterData.forEach((ele: any) => {
      if (this.selectedMonthFilters.find((mon: any) => mon === ele.month)) {
        const monthObj: MONTH = {
          month: ele.month,
          checked: true,
        };
        this.tempMonthListFilterData.push(monthObj);
      } else {
        const monthObj: MONTH = {
          month: ele.month,
          checked: false,
        };
        this.tempMonthListFilterData.push(monthObj);
      }
    });
    this.tempMonthListFilterData.forEach((ele: any) => {
      if (ele.checked) {
        this.tempSelectedMonthFilters.push(ele.month);
      }
    });
    this.selectedMonthFilters = [];
    this.selectedMonthFilters = this.tempSelectedMonthFilters;
    if ((this.selectedMonthFilters.length == 0 && this.selectedProductFilters.length == 0) || this.clearAll) {
      // sessionStorage.removeItem('lineChartFilters');
      this.header.savedFilters = {};
      this.store.dispatch(
        setSelectedFilterItem({
          filterData: {
            subsTrendsavedFilterData: this.header.savedFilters,
          },
        })
      );
      this.dialogRef.close(this.header.savedFilters);
    } else {
      // this.clearMonth = false;

      const filterObj = {
        //rangeOption: this.selectedRangeOption || [],
        range: [this.header.minValue, this.header.maxValue], //this.selectedRangeValue || [],
        month: this.selectedMonthFilters || [],
        application: this.selectedProductFilters || [],
      };
      // const filterObjResult = JSON.stringify(filterObj);
      if (filterObj) {
        this.header.savedFilters = filterObj;
        this.store.dispatch(
          setSelectedFilterItem({
            filterData: {
              subsTrendsavedFilterData: this.header.savedFilters,
            },
          })
        );
        // this.header.savedFilters?.forEach((val: any) => {
        //   const monthObj: MONTH = {
        //     month: val,
        //     checked: true,
        //   };
        //   this.checkedMonth.push(monthObj);
        // });
        // sessionStorage.setItem('lineChartFilters', JSON.stringify(filterObj));
        this.dspUtilsCommonService.setValue(JSON.stringify(this.header.savedFilters.month), 'selectedMonth');
        this.dspUtilsCommonService.setValue(JSON.stringify(this.header.savedFilters.application), 'selectedProduct');

        this.dialogRef.close(this.header.savedFilters);
      } else {
        this.dialogRef.close();
      }
    }
  }

  // onRangeSliderValueGet(event: any) {
  //   this.selectedRangeValue = [];
  //   this.sliderChanged = true;
  //   this.selectedRangeValue.push(event.minSelected);
  //   this.selectedRangeValue.push(event.maxSelected);
  // }
  // enableApplyActionButton() {
  //   this.selectedMonthFilters = this.getMonthListInOrder();
  //   this.selectedProductFilters = this.getProductListInOrder();

  //   const filterObj = {
  //     //rangeOption: this.selectedRangeOption || [],
  //     range: [this.header.minValue, this.header.maxValue], //this.selectedRangeValue || [],
  //     month: this.selectedMonthFilters || [],
  //     application: this.selectedProductFilters || [],
  //   };
  //   if (JSON.stringify(this.previousSelectedFilters) === JSON.stringify(filterObj)) {
  //     this.disableApplybtn = true;
  //   } else {
  //     this.disableApplybtn = false;
  //   }
  // }
  // getMonthListInOrder() {
  //   this.tempMonthListFilterData = [];
  //   this.tempSelectedMonthFilters = [];
  //   this.monthListFilterData.forEach((ele: any) => {
  //     if (this.selectedMonthFilters.find((mon: any) => mon === ele.month)) {
  //       const monthObj: MONTH = {
  //         month: ele.month,
  //         checked: true,
  //       };
  //       this.tempMonthListFilterData.push(monthObj);
  //     } else {
  //       const monthObj: MONTH = {
  //         month: ele.month,
  //         checked: false,
  //       };
  //       this.tempMonthListFilterData.push(monthObj);
  //     }
  //   });
  //   this.tempMonthListFilterData.forEach((ele: any) => {
  //     if (ele.checked) {
  //       this.tempSelectedMonthFilters.push(ele.month);
  //     }
  //   });
  //   this.selectedMonthFilters = [];
  //   return this.tempSelectedMonthFilters;
  // }
  // getProductListInOrder() {
  //   this.tempProductListFilterData = [];
  //   this.tempSelectedProductFilters = [];
  //   this.productListFilterData.forEach((ele: any) => {
  //     if (this.selectedProductFilters.find((product: any) => product === ele.product)) {
  //       const productObj: PRODUCT = {
  //         product: ele.product,
  //         checked: true,
  //       };
  //       this.tempProductListFilterData.push(productObj);
  //     } else {
  //       const productObj: PRODUCT = {
  //         product: ele.product,
  //         checked: false,
  //       };
  //       this.tempProductListFilterData.push(productObj);
  //     }
  //   });
  //   this.tempProductListFilterData.forEach((ele: any) => {
  //     if (ele.checked) {
  //       this.tempSelectedProductFilters.push(ele.product);
  //     }
  //   });
  //   this.selectedProductFilters = [];
  //   return this.tempSelectedProductFilters;
  // }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
