import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { SubscribeAccountsService } from '../../../../services/subscribe-accounts.service';
import { dealerType } from '../../../../models/shared.interface';
import { AccountFormType } from '../../accounts-interface';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { findIndex, isEmpty } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-product-plan',
  templateUrl: './product-plan.component.html',
  styleUrls: ['./product-plan.component.scss'],
})
export class ProductPlanComponent implements OnInit {
  @Input() productData: any;
  @Input() planData: any;
  @Input() selectedData!: AccountFormType;
  selectedProductOption = '';
  selectedPlanOption = '';
  @Output() fetchPlans = new EventEmitter();
  productPlanForm!: FormGroup;
  dealer!: dealerType;
  isValidProduct = false;
  isValidPlan = false;
  accountTypes: any = [];
  mineStarAccountTypes = [
    { value: 'Customer', label: 'Subscribe by Customer Account', checked: false },
    { value: 'Dealer', label: 'Subscribe by Dealer Account', checked: false },
  ];

  serviceMediaAccTypes = [{ value: 'Customer', label: 'Subscribe by Customer Account', checked: true }];
  customerAccRadioChecked = false;

  constructor(
    private formBuilder: FormBuilder,
    private subscribeAccountsService: SubscribeAccountsService,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    if (this.selectedData.plan != '' && this.selectedData.product != '') {
      this.isValidProduct = true;
      this.isValidPlan = true;
      if (!isEmpty(this.selectedData?.accountType) && this.selectedData.product === 'MineStar Health') {
        this.mineStarAccountTypes.map((data: any) => {
          if (data.value === this.selectedData?.accountType) {
            data.checked = true;
          }
        });
      }
    }
    this.dealer = fetchLoggedInDealerDetails();
    this.productPlanForm = this.formBuilder.group({
      selectedProductName: [this.selectedData.product !== '' ? this.selectedData.product : ''],
      selectedPlanName: [this.selectedData.plan !== '' ? this.selectedData.plan : ''],
      selectedAccountType: [this.selectedData.accountType !== '' ? this.selectedData.accountType : ''],
    });
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'container-overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'product-plan-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  productDropdownChange(event: any) {
    const selectedOption: string = event?.currentSelection[0]?.label || '';
    this.isValidProduct = this.validateSelectedOption(this.productData, selectedOption);
    if (this.selectedData.product !== selectedOption) {
      this.selectedData.product = selectedOption;
      this.selectedData.plan = '';
      this.selectedData.accountType = '';
      this.selectedData.customer = this.updateCustomerData();
      this.selectedData.licenseType = '';
      this.selectedData.dealer = '';
      this.selectedData.isValidLicenseType = true;
      this.selectedData.restrictUnlimitedUsers = false;
      this.selectedData.searchedUsers = [];
      this.selectedData.selectedUsers = [];
      this.productPlanForm.get('selectedPlanName')?.patchValue('');
      this.productPlanForm.get('selectedAccountType')?.patchValue('');
      this.selectedData.isValidForm = false;
      this.subscribeAccountsService.setAccountFormData(this.selectedData);
      if (this.isValidProduct) {
        this.fetchPlans.emit();
      }
    }
  }

  planDropdownChange(event: any) {
    const selectedOption: string = event?.currentSelection[0]?.label || '';
    this.isValidPlan = this.validateSelectedOption(this.planData, selectedOption);
    if (this.selectedData.plan !== selectedOption) {
      this.selectedData.plan = selectedOption;
      this.selectedData.accountType = '';
      this.selectedData.customer = this.updateCustomerData();
      this.selectedData.licenseType = '';
      this.selectedData.isValidLicenseType = true;
      this.selectedData.restrictUnlimitedUsers = false;
      this.selectedData.searchedUsers = [];
      this.selectedData.selectedUsers = [];
      this.productPlanForm.get('selectedAccountType')?.patchValue('');
      if (
        this.selectedData?.product !== '' &&
        (selectedOption === 'SIS2.0 USB' || selectedOption === 'Service Media Access')
      ) {
        this.selectedData.isValidForm = true;
      } else {
        this.accountTypes.forEach((item: any) => {
          item.checked = false;
        });
        this.selectedData.isValidForm = false;
      }
      this.subscribeAccountsService.setAccountFormData(this.selectedData);
    }
  }

  validateSelectedOption(optionsData: any, selectedOption: string) {
    return findIndex(optionsData, ['label', selectedOption]) > -1;
  }

  enableAccountTypeSelection() {
    if (this.selectedData.product === 'SIS2GO') {
      this.accountTypes = this.serviceMediaAccTypes;
      this.customerAccRadioChecked = true;
      return this.selectedData.plan !== '' && this.selectedData.plan !== 'SIS2.0 USB' && this.isValidPlan == true;
    } else {
      this.customerAccRadioChecked = false;
      this.accountTypes = this.mineStarAccountTypes;
      return this.showAccountType();
    }
  }

  showAccountType() {
    return (
      this.selectedData.plan !== '' && this.selectedData.plan !== 'Service Media Access' && this.isValidPlan == true
    );
  }

  accountOptionClick(event: any) {
    this.accountTypes.filter((item: any) => {
      if (item.value === event?.value) {
        item.checked = true;
      }
    });
    const selectedAccountOption = event?.value || '';
    if (selectedAccountOption === 'Dealer') {
      this.selectedData.dealer = this.dealer?.dealerCode;
    } else {
      this.selectedData.dealer = '';
    }
    this.selectedData.accountType = selectedAccountOption;
    this.selectedData.customer = this.updateCustomerData();
    this.selectedData.licenseType = '';
    this.selectedData.isValidLicenseType = true;
    this.selectedData.restrictUnlimitedUsers = false;
    this.selectedData.searchedUsers = [];
    this.selectedData.selectedUsers = [];
    this.subscribeAccountsService.setAccountFormData(this.selectedData);
    const validateAccountPayload = {
      planName: this.selectedData.plan,
      type: selectedAccountOption,
      dealerCode: this.dealer?.dealerCode,
      ucid: '',
      licensedType: '',
    };
    this.subscribeAccountsService.validateAccount(validateAccountPayload).subscribe({
      error: (err) => {
        if (err && err.status === 409) {
          this.selectedData.isValidForm = false;
          const messageText = `This subscription is active for your organization currently.`;
          this.showToastMessage(messageText, 'error');
        } else if (err.status === 202) {
          this.selectedData.isValidForm = true;
        }
        this.subscribeAccountsService.setAccountFormData(this.selectedData);
      },
    });
  }

  updateCustomerData(customerData: any = null) {
    return {
      ucid: customerData?.ucid || '',
      ucName: customerData?.ucName || '',
      dcn: customerData?.dealerCustomerNumber || '',
      dcName: customerData?.name || '',
    };
  }
}
