import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'dsp-next-gen-ui-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
})
export class EulaComponent {
  isChecked = false;
  public subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    private authService: AuthService,
    private location: Location
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/') {
          this.authService.logoutSession();
        }
      }
    });
    window.localStorage.removeItem('thirdPartyApi');
  }
  dateYear = new Date().getFullYear();
  dynamicUrl = environment.redirectURL.hostBaseUrl;

  privacyModal() {
    window.open('/privacyModal', '_blank');
  }
  toggleCheckboxOption(event: any): void {
    if (event === true) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }
  agreeButton(): void {
    this.router.navigate(['tos']);
  }
  disAgreeButton(): void {
    this.authService.logoutSession();
  }
}
