<div class="cc-drawer sub-filter-container">
  <div
    class="cc-drawer__header"
    [ngClass]="{
      'cc-drawer__withActions': headerContent === 'withActions'
    }"
  >
    <ng-container *ngIf="headerContent === 'withActions'" [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <span *ngIf="columnNames && columnNames.length">
      <cc-accordion [ngClass]="{ noResultsCls: noSearchResults }">
        <div *ngFor="let subfilterList of columnNames">
          <div></div>
          <cc-expandable
            *ngIf="!(SearchFilterData && !SearchFilterData[subfilterList.field] && !isDataLoading)"
            [noExpandableContent]="false"
            [expanded]="expandDropdown ? true : false"
            (opened)="loadSubFiltercolumnData(subfilterList.field, subfilterList.selectedValues)"
            (closed)="accordionClosed(subfilterList.field)"
          >
            <cc-expandable-header>
              <div class="expandable-header-title">
                <span class="title-display">{{ subfilterList.label }} </span>
                <span class="selectedLabel" *ngIf="subfilterList && subfilterList.selectedValues.length">
                  ( {{ subfilterList.selectedValues.length }} selected )</span
                >
                <div class="chipList" *ngIf="subfilterList.selectedValues">
                  <ng-container *ngFor="let filter of subfilterList.selectedValues">
                    <span
                      *ngIf="isAccordionClosed[subfilterList.field]"
                      class="chips"
                      [cc-chip]="filter"
                      [tooltipTemplate]="tooltipTemplate"
                      [tooltipText]="filter.length < 100 ? filter : ''"
                      (deleteItem)="onDeleteChip($event, subfilterList, filter)"
                      [type]="'cross'"
                    ></span>
                    <ng-template #tooltipTemplate>
                      <div
                        [ngClass]="{
                          'tooltip-truncate': filter.length > 100
                        }"
                      >
                        {{ filter }}
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </cc-expandable-header>
            <div
              *ngIf="
                !isDataLoading &&
                !subfilterList.formControl &&
                subFilterData &&
                subFilterData[subfilterList.field] &&
                subFilterData[subfilterList.field].length
              "
            >
              <ng-container *ngFor="let filter of subFilterData[subfilterList.field][0].slice(0, 8)">
                <div class="data-display">
                  <cc-checkbox
                    [checked]="subfilterList.selectedValues.indexOf(filter) > -1"
                    (toggleChange)="getSelectedFilter($event, subfilterList, filter)"
                  >
                    <span [innerHTML]="filter | highlightSearch: searchString"></span>
                  </cc-checkbox>
                </div>
              </ng-container>
              <div
                class="category-show-all"
                *ngIf="!isDataLoading && subFilterData[subfilterList.field][0].length > 8 && !isDataLoading"
                (click)="showAllData('withActions', subFilterData, subfilterList)"
              >
                Show All
              </div>
            </div>
            <div class="spinnerCls" *ngIf="noDataAvailableCondition(subfilterList)">No Data Available</div>
            <div class="spinnerCls" *ngIf="isDataLoading">
              <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
            </div>
          </cc-expandable>
        </div> </cc-accordion
    ></span>
  </div>
  <div class="cc-drawer__footer">
    <button cc-btn-secondary cc-btn-medium [setTheme]="true" class="applyChangesBtn" (click)="applyFilters()">
      <span> Apply Changes </span>
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <cc-icon class="drilldown-header__back" (click)="closeModal()">back-arrow</cc-icon>
  <span class="cc-drawer__ellipsis filters-title"> {{ title }} </span>
  <span class="filters-clear" (click)="clearFilters()">Clear All</span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeAllModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
