<div id="message-outlet"></div>
<div class="needs-review-page">
  <div class="cc-table-pag-header-left needs_review-sub-header">
    <div
      class="needs-Review-search"
      [ngClass]="{ helptextStyles: searchHelpText !== '', textboxField: searchValue && searchValue.value?.length > 0 }"
    >
      <cc-input-field
        [labelValue]="'Search Asset / Engine / Device / Radio S/N / Customer  (Min 3 Char)'"
        [inputtype]="'search'"
        isTheme="2.0"
        id="cc-atom-search-s7"
        [value]="searchValue?.value"
        [searchMenuHeader]="mobileHeader"
        [triggerSearchMinChar]="3"
        (valueChange)="onNeedsReviewSearchChange($event)"
        [enableMobileOverlay]="true"
        [isPageControl]="true"
        [isLeading]="true"
        [ngClass]="{ 'search-field': searchValue?.value.length > 2 }"
        [helpText]="''"
        [disabled]="isDataLoading"
      >
        <span class="searchIcon"></span>
        <input
          #needsReviewSearchBox
          input-field
          [formControl]="searchValue"
          class="input-field input-search-width"
          [value]="searchValue.value"
          autocomplete="off"
          [isFloating]="false"
          type="search"
          aria-labelledby="Search Help Text"
          aria-describedby="Search Help Text"
          (click)="onCickEvent($event)"
          (blur)="onBlurEvent($event)"
          (focus)="onFocusEvent()"
          (valueChange)="onNeedsReviewSearchChange($event)"
          (keydown.enter)="
            searchValue.value.trim().length > 2 ? dataLoaded() : '';
            searchValue.value.trim().length > 2 ? getReviewSearch(true) : ''
          "
        />
        <span class="cc-inline-input-error-icon" cc-tooltip="Type at least 3 characters">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.47534 0.107666C6.09087 0.107666 4.7375 0.518209 3.58635 1.28738C2.43521 2.05655 1.538 3.1498 1.00819 4.42888C0.478375 5.70796 0.339751 7.11543 0.609848 8.4733C0.879944 9.83116 1.54663 11.0784 2.5256 12.0574C3.50456 13.0364 4.75184 13.7031 6.10971 13.9732C7.46758 14.2433 8.87504 14.1046 10.1541 13.5748C11.4332 13.045 12.5265 12.1478 13.2956 10.9967C14.0648 9.84551 14.4753 8.49213 14.4753 7.10766C14.4753 6.18841 14.2943 5.27816 13.9425 4.42888C13.5907 3.5796 13.0751 2.80793 12.4251 2.15792C11.7751 1.50791 11.0034 0.992292 10.1541 0.640509C9.30485 0.288726 8.3946 0.107666 7.47534 0.107666ZM7.47534 10.6077C7.34797 10.6077 7.22346 10.5699 7.11756 10.4991C7.01165 10.4284 6.92911 10.3278 6.88036 10.2101C6.83162 10.0924 6.81887 9.96295 6.84372 9.83803C6.86857 9.7131 6.9299 9.59835 7.01997 9.50829C7.11003 9.41822 7.22478 9.35689 7.3497 9.33204C7.47463 9.30719 7.60412 9.31994 7.72179 9.36868C7.83947 9.41743 7.94004 9.49997 8.01081 9.60588C8.08157 9.71178 8.11934 9.83629 8.11934 9.96366C8.11934 10.1345 8.05149 10.2983 7.93072 10.419C7.80995 10.5398 7.64614 10.6077 7.47534 10.6077ZM7.88834 8.13666H7.06234L6.81034 3.60767H8.17534L7.88834 8.13666Z"
              fill="#DF3826"
            />
          </svg>
        </span>
      </cc-input-field>
      <span class="req-text">
        <span *ngIf="searchValue && searchValue.value?.length > 0 && searchValue.value?.length <= 2"
          >Please enter atleast 3 characters</span
        >
      </span>
      <span
        class="searchButton"
        (click)="
          searchValue.value.trim().length > 2 ? dataLoaded() : '';
          searchValue.value.trim().length > 2 ? getReviewSearch(true) : ''
        "
      >
        <a
          cc-btn-primary
          cc-btn-large
          class="primary button-opacity"
          [setTheme]="true"
          [disabled]="!enableSearch || isDataLoading"
          ><svg width="16" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
              fill="black"
            />
          </svg>
        </a>
      </span>
    </div>
    <div class="site-right-panel">
      <span class="site-panel-items filter-container">
        <dsp-next-gen-ui-common-main-filters-icon
          [ngClass]="{ 'disable-menu': isDataLoading }"
          [titleNames]=""
          [tableData]="getcolumnConfig()"
          [customData]="customData"
          [columnNames]="needsReviewCoumnList"
          [pageLabel]="pageLabel"
          (loadChangeEmit)="loadChangeEmit($event)"
          [queueReasonId]="queueReasonId"
          [status]="needsReviewService.getStatus()"
        ></dsp-next-gen-ui-common-main-filters-icon>
      </span>
      <span
        [ngClass]="{ 'disable-menu': isDataLoading }"
        class="site-panel-items settings-container"
        *ngIf="isNeedsReviewTableSettingEnabled"
      >
        <span #settingIcon class="settings-icon" cc-tooltip="Settings" (click)="openSettingDrawer(settingPanelDesktop)">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.1907 11.125H18.6252V8.875H16.19C16.0332 8.22475 15.7767 7.23925 15.4377 6.6835L17.1597 4.96225L15.038 2.8405L13.3152 4.5625C12.7602 4.2235 11.7755 3.96775 11.1252 3.811V1.375H8.87524V3.811C8.22499 3.96775 7.23949 4.2235 6.68449 4.5625L4.96174 2.8405L2.83999 4.96225L4.56274 6.68425C4.22374 7.23925 3.96724 8.22475 3.80974 8.875H1.37524V11.125H3.80974C3.96724 11.7752 4.22374 12.7607 4.56274 13.3157L2.83999 15.0385L4.96174 17.1595L6.68374 15.4368C7.23949 15.7773 8.22499 16.033 8.87524 16.1905V18.625H11.1252V16.1905C11.7755 16.033 12.761 15.7765 13.3152 15.4375L15.0387 17.1595L17.1597 15.0385L15.4377 13.3157C15.7767 12.7607 16.0332 11.7752 16.1907 11.125Z"
              stroke="#43485C"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.0002 13.375C11.8642 13.375 13.3752 11.864 13.3752 10C13.3752 8.13604 11.8642 6.625 10.0002 6.625C8.13628 6.625 6.62524 8.13604 6.62524 10C6.62524 11.864 8.13628 13.375 10.0002 13.375Z"
              stroke="#43485C"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <ng-template #settingPanelDesktop>
          <div class="control-panel__item-options">
            <cat-digital-workspace-table-settings
              [cancel]="closeSettingDrawer"
              [setTableSettings]="tableSettingsPanelConfig"
              [isDisableFrozenTab]="false"
              [closeOniconClick]="false"
              [isDrawer]="true"
              [isApply]="isTableSettingsApply"
              [isClose]="isTableSettingClose"
              (closeTableSettingsEvent)="tableSettingsClose($event)"
              (closeTableDataEvent)="closeTableSettingsData($event)"
              [settingsPanelConfig]="settingsPanelConfig"
              (saveTableSettingsEvent)="saveSettings($event)"
              [isOutsideClick]="isSettingsOutSideClicked"
              (outsideClickDataEvent)="outsideClickDataEvent($event)"
              [isFrozendragdisabled]="true"
            ></cat-digital-workspace-table-settings>
          </div>
        </ng-template>
      </span>

      <span
        class="site-panel-items download-container"
        [ngClass]="{ 'disable-menu': disableHeaderContent || isDataLoading }"
      >
        <div
          class="menu-content"
          cc-menu
          [template]="singleSelectTemplate"
          OverlayPosition="bottom"
          [label]="'Title'"
          *ngIf="!showLoader; else downloadLoader"
        >
          <div *ngIf="isXLDownloadEnabled" class="ellipse-container" id="cc-menu1" cc-tooltip="Export">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.16626 6.66699H8.83293V12.3813H6.16626V6.66699Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.49935 16.667L10.8327 10.9527H4.16602L7.49935 16.667Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.58333 20H14.25C15.1208 20 15.8333 19.2742 15.8333 18.3871V7.95161C15.8333 7.51613 15.675 7.1129 15.3742 6.80645L9.15167 0.467742C8.85083 0.16129 8.455 0 8.0275 0H1.58333C0.7125 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.7125 20 1.58333 20ZM0.632324 1.61325C0.632324 1.08099 1.05982 0.645508 1.58232 0.645508H8.02649C8.27982 0.645508 8.51732 0.742282 8.69149 0.93583L14.914 7.27454C15.104 7.45196 15.199 7.69389 15.199 7.95196V18.3874C15.199 18.9197 14.7715 19.3552 14.249 19.3552H1.58232C1.05982 19.3552 0.632324 18.9197 0.632324 18.3874V1.61325Z"
                fill="black"
              />
            </svg>
            <!-- <span class="file-arrow">
              <svg
                _ngcontent-psg-c182=""
                width="10"
                height="22"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ng-star-inserted"
              >
                <path
                  _ngcontent-psg-c182=""
                  d="M8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L8.64645 0.646447ZM5 5L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355L5 5ZM0.646446 1.35355C0.451184 1.15829 0.451184 0.841709 0.646446 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L0.646446 1.35355ZM9.35355 1.35355L5.35355 5.35355L4.64645 4.64645L8.64645 0.646447L9.35355 1.35355ZM4.64645 5.35355L0.646446 1.35355L1.35355 0.646447L5.35355 4.64645L4.64645 5.35355Z"
                  fill="#43485C"
                ></path>
              </svg>
            </span> -->
          </div>
          <div
            *ngIf="!isXLDownloadEnabled"
            class="ellipse-container"
            id="cc-menu1"
            cc-tooltip="Export"
            (click)="onClickExport()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="20" viewBox="0 0 33 20" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.16626 6.66699H8.83293V12.3813H6.16626V6.66699Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.49935 16.667L10.8327 10.9527H4.16602L7.49935 16.667Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.58333 20H14.25C15.1208 20 15.8333 19.2742 15.8333 18.3871V7.95161C15.8333 7.51613 15.675 7.1129 15.3742 6.80645L9.15167 0.467742C8.85083 0.16129 8.455 0 8.0275 0H1.58333C0.7125 0 0 0.725806 0 1.6129V18.3871C0 19.2742 0.7125 20 1.58333 20ZM0.632324 1.61325C0.632324 1.08099 1.05982 0.645508 1.58232 0.645508H8.02649C8.27982 0.645508 8.51732 0.742282 8.69149 0.93583L14.914 7.27454C15.104 7.45196 15.199 7.69389 15.199 7.95196V18.3874C15.199 18.9197 14.7715 19.3552 14.249 19.3552H1.58232C1.05982 19.3552 0.632324 18.9197 0.632324 18.3874V1.61325Z"
                fill="black"
              />
            </svg>
            <!-- <span class="file-arrow">
              <svg
                _ngcontent-psg-c182=""
                width="10"
                height="22"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ng-star-inserted"
              >
                <path
                  _ngcontent-psg-c182=""
                  d="M8.64645 0.646447C8.84171 0.451184 9.15829 0.451184 9.35355 0.646447C9.54882 0.841709 9.54882 1.15829 9.35355 1.35355L8.64645 0.646447ZM5 5L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355L5 5ZM0.646446 1.35355C0.451184 1.15829 0.451184 0.841709 0.646446 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L0.646446 1.35355ZM9.35355 1.35355L5.35355 5.35355L4.64645 4.64645L8.64645 0.646447L9.35355 1.35355ZM4.64645 5.35355L0.646446 1.35355L1.35355 0.646447L5.35355 4.64645L4.64645 5.35355Z"
                  fill="#43485C"
                ></path>
              </svg>
            </span> -->
          </div>
        </div>
        <ng-template #downloadLoader>
          <div class="loader-container">
            <div class="loader-container__label"></div>
            <div>
              <svg width="7" height="20" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.16602 0.666992H4.83268V6.38128H2.16602V0.666992Z"
                  fill="#43485C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.49935 10.667L6.83268 4.95271H0.166016L3.49935 10.667Z"
                  fill="#43485C"
                />
              </svg>
            </div>
          </div>
        </ng-template>
      </span>
    </div>
  </div>
  <ng-template #singleSelectTemplate>
    <div class="cc-contextual-menu" id="cc-single-menu1" *ngIf="isXLDownloadEnabled">
      <cc-list [theme]="'secondary'" width="1000px">
        <cc-list-item *ngFor="let item of listItems" (listItemClick)="onListItemClick(item.label)">
          {{ item.label }}
        </cc-list-item>
      </cc-list>
    </div>
  </ng-template>
  <!--Needs review container content(left tab and table component)-->
  <div class="needs-review">
    <div class="needs-review-container-left">
      <dsp-next-gen-ui-needs-review-left-tab
        class="needs-review-groups"
        (dealerQueueReasonID)="getDealerQueueReason($event)"
        [searchChange]="searchChange.asObservable()"
      ></dsp-next-gen-ui-needs-review-left-tab>
    </div>
    <div class="needs-review-container-right">
      <dsp-next-gen-ui-need-review-table
        *ngIf="isMaintainPagination"
        [selectedQueueReason]="selectedQueueReason"
        [events]="eventsSubject.asObservable()"
        [searchChange]="searchChange.asObservable()"
        (exportSorting)="onFilterChange($event)"
        (pageLimitPayload)="pageLimitPayload($event)"
      ></dsp-next-gen-ui-need-review-table>
      <dsp-next-gen-ui-need-review-table
        *ngIf="!isMaintainPagination"
        [selectedQueueReason]="selectedQueueReason"
        [events]="eventsSubject.asObservable()"
        [searchChange]="searchChange.asObservable()"
        (exportSorting)="onFilterChange($event)"
      ></dsp-next-gen-ui-need-review-table>
    </div>
  </div>
</div>
