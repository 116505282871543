import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, delay, map } from 'rxjs';
import {
  GetConfigStatusPayload,
  GetConfigFileUploadListPayload,
  UploadConfigFilePayload,
} from '../components/assets/asset-drawer/asset-drawer-interface';
import { PaymentPreviewProduct } from '../components/assets/asset-interface';
import { fetchLoggedInDealerDetails } from '../shared/shared';

@Injectable({
  providedIn: 'root',
})
export class AssetDrawerService {
  catGradeEditInfo: any;
  catGradeCustomerInfo: any;
  searchAssetInfo: any;
  rootURL = '';
  isBackBtnClicked: any;
  isRefreshBtnClicked: any;
  dealer: any;
  enableNextBtn: any;
  filteredPaymentPreviewData: any;
  refreshAssetLanding: any;
  isPlanDetailsDrawerClosed: any;
  productFamilyCode: any;
  licenseEligiblityInfo: any;
  selectedLicense: any;
  paymentPreviewConfirmationData: any;
  selectedLicenseEligiblityStatus: any;
  catGradeEditTableInfo: any;
  isDemoEligibleDealer: any;
  queueReasons: any;

  @Output() assetDrawerEventEmitter = new EventEmitter<any>();
  @Output() assetUnsuspensionFormEvent = new EventEmitter();
  @Output() enableCatGradeControl = new EventEmitter();
  @Output() closeGradeControlDrawer = new EventEmitter();
  @Output() isMultiLicenseEnabled = new EventEmitter();
  @Output() queueReasonList = new EventEmitter();
  @Output() rowDataChange = new EventEmitter<any>();
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
    this.dealer = fetchLoggedInDealerDetails();
    this.catGradeEditInfo = new BehaviorSubject({ editInfo: [], techLevel: '' });
    this.catGradeCustomerInfo = new BehaviorSubject('');
    this.searchAssetInfo = new BehaviorSubject('');
    this.isBackBtnClicked = new BehaviorSubject<boolean>(false);
    this.isRefreshBtnClicked = new BehaviorSubject('');
    this.enableNextBtn = new BehaviorSubject(true);
    this.filteredPaymentPreviewData = new BehaviorSubject([]);
    this.refreshAssetLanding = new BehaviorSubject<boolean>(false);
    this.isPlanDetailsDrawerClosed = new BehaviorSubject<boolean>(false);
    this.productFamilyCode = new BehaviorSubject('');
    this.licenseEligiblityInfo = new BehaviorSubject<any>('');
    this.selectedLicense = new BehaviorSubject('');
    this.paymentPreviewConfirmationData = new BehaviorSubject([]);
    this.selectedLicenseEligiblityStatus = new BehaviorSubject([]);
    this.catGradeEditTableInfo = new BehaviorSubject([]);
    this.isDemoEligibleDealer = new BehaviorSubject<boolean>(false);
    this.queueReasons = new BehaviorSubject([]);
  }
  setAssetUnsuspensionFormData(formData: any, dataChanged: boolean) {
    this.assetUnsuspensionFormEvent.emit({ formData, dataChanged });
  }

  updateAssetDetails(assetData: any) {
    this.assetDrawerEventEmitter.emit(assetData);
  }

  updateCatGradeControl(data: any) {
    this.enableCatGradeControl.emit(data);
  }

  setGradeControlDrawer(data: any) {
    this.closeGradeControlDrawer.emit(data);
  }

  updateCatGradeRowData(data: any) {
    this.rowDataChange.emit(data);
  }

  setMultiLicenseEnabled(data: any) {
    this.isMultiLicenseEnabled.emit(data);
  }

  initOwnershipSync(queryParams: any) {
    return this.http.post(this.rootURL + '/assetdata/syncOwnershipInfo', queryParams);
  }
  getSmhInfo(queryParams: any) {
    return this.http.get(this.rootURL + '/telemetry/smh', {
      params: queryParams,
    });
  }

  getLocationInfo(queryParams: any) {
    return this.http.get(this.rootURL + '/telemetry/location', {
      params: queryParams,
    });
  }

  getLocationAndSmhInfo(queryParams: any) {
    return this.http.get(this.rootURL + '/telemetry/locationAndSmh', {
      params: queryParams,
    });
  }

  getDeviceInfo(queryParams: any) {
    return this.http.post(this.rootURL + '/equipments/getDeviceRadioInfo', queryParams);
  }
  getWifiConfiguration(queryParams: any) {
    return this.http.get(this.rootURL + '/Wifi/configuration', {
      params: queryParams,
    });
  }
  getConnectionCheckInfo(requestBody: any) {
    return this.http.post(this.rootURL + '/equipment/requestRemoteConnectionCheck', requestBody);
  }
  getConnectionCheckStatus(requestBody: any) {
    return this.http.post(this.rootURL + '/equipment/requestRemoteConnectionCheckStatus', requestBody);
  }
  cancelreasons() {
    return this.http.get(this.rootURL + '/subscribe/cancelreasons');
  }

  getConfigStatus(queryParams: any) {
    return this.http.get<GetConfigStatusPayload>(this.rootURL + '/configFiles/status', {
      params: queryParams,
    });
  }

  getConfigFileUploadList(queryParams: any) {
    return this.http.get<GetConfigFileUploadListPayload>(this.rootURL + '/configFiles/', {
      params: queryParams,
    });
  }

  pushConfigFileUploadList(uploadConfigFileObj: UploadConfigFilePayload) {
    return this.http.post(this.rootURL + '/configFiles/pushConfigFile', uploadConfigFileObj);
  }

  updatePartNumber(requestBody: any) {
    return this.http.post(this.rootURL + '/equipments/partnumber', requestBody);
  }

  getPaymentPreview(getPaymentPayload: any) {
    return this.http.post(this.rootURL + '/zuoraPreview/getPaymentPreview', getPaymentPayload);
  }

  getEcmInfo(requestBody: any) {
    return this.http.post(this.rootURL + '/equipments/getECMInfo', requestBody);
  }

  getSitesById(payload: any) {
    return this.http.post(this.rootURL + '/site/getSitesById', payload);
  }

  getSupportedServices(payload: any) {
    return this.http.post(this.rootURL + '/assetdata/getSupportedServices', payload);
  }

  getAssetOwnershipInfo(payload: any) {
    return this.http.post(this.rootURL + '/assetdata/getAssetOwnershipInfo', payload);
  }
  getDCNListInfo(input: any) {
    return this.http.get(this.rootURL + `/asset/search/getdcn?dealerCode=${this.dealer.dealerCode}&ucid=${input.ucid}`);
  }
  getApplicableSubscriptions(input: any) {
    //once the API is ready we could use this URL
    return this.http.post(this.rootURL + '/subscribe/applicable-plans', input);
  }
  getListOfProduct() {
    return this.http.get(this.rootURL + `/assetdata/products`);
  }
  filteredAssets(payload: any) {
    return this.http.post(this.rootURL + '/assetdata/filterassetsV2', payload);
  }

  getAmountFromPaymentPreview(array: Array<PaymentPreviewProduct>, planName: string) {
    const planObj: any = array?.filter((value: PaymentPreviewProduct) => {
      return value.name == planName;
    });
    return planObj[0]?.amount;
  }
  getDealerInfo(queryParams: any) {
    return this.http.post(this.rootURL + '/dealer/getDealerInfo', queryParams);
  }
  getExportID(payload: any) {
    return this.http.post(this.rootURL + '/changeeventlog/export', payload);
  }

  getExportStatus(id: string) {
    return this.http.get(this.rootURL + '/changeeventlog/export/' + id);
  }

  downloadHistoryFile(fileId: string) {
    return this.http.get(this.rootURL + '/changeeventlog/download/' + fileId, { responseType: 'blob' }).pipe(
      map((file: Blob) => {
        return file;
      })
    );
  }

  getAssetSubscriptionHistory(queryParams: any) {
    return this.http.get(this.rootURL + '/changeeventlog/getsubscriptionchangeevents?', {
      params: queryParams,
    });
  }

  getCatGradeControlByDealercode(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/dealerFeatureByDealerCode', {
      params: queryParams,
    });
  }

  getGradeControlResponse(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/catGradeControl/getGradeControlResponse', {
      params: queryParams,
    });
  }

  getCatGradeProductFamilyDetails(queryParams: any) {
    return this.http.get(this.rootURL + '/catGradeControl/getCatGradeProductFamilyDetails', {
      params: queryParams,
    });
  }

  getUpgradeLicenseDetails(payload: any) {
    return this.http.post(this.rootURL + '/catGradeControl/getUpgradeLicenceDetails', payload);
  }
  getHeliosGradeControlResponse(queryParams: any) {
    return this.http.get(this.rootURL + '/catGradeControl/getHeliosGradeControlResponse', {
      params: queryParams,
    });
  }

  validateSerialNo(payload: any) {
    return this.http.post(this.rootURL + '/catGradeControl/validateSerialNumber', payload);
  }

  submitLicense(payload: any) {
    return this.http.post(this.rootURL + '/catGradeControl/submitLicense', payload);
  }

  getCatGradePaymentPreview(payload: any) {
    return this.http.post(this.rootURL + '/zuoraPreview/v2/getPaymentPreview', payload);
  }

  refreshLicenseDetails(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/catGradeControl/refreshLicenseDetails', {
      params: queryParams,
    });
  }

  downloadGradeControlLicense(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/catGradeControl/downloadGradeControlLicense', {
      params: queryParams,
    });
  }

  getRemoteServiceCapability(payload: any): Observable<any> {
    return this.http.post(this.rootURL + '/remoteflash/getRemoteServiceCapability', payload);
  }

  getDealerParentAndChildfeatures(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/user/dealerParentAndChildfeatures', {
      params: queryParams,
    });
  }

  getDemoEligibleDealer(queryParams: any): Observable<any> {
    return this.http.get(this.rootURL + '/catGradeControl/isDemoEligibleDealer', {
      params: queryParams,
    });
  }
  getAssetImageURL(queryParams: any) {
    return this.http.get(this.rootURL + '/assetdata/getImageURL', {
      params: queryParams,
    });
  }

  getAssetImageContent(url: any) {
    const headers = new HttpHeaders();
    return this.http.get(url, {
      responseType: 'arraybuffer',
      headers: headers,
    });
  }
}
