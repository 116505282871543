<div>
  <!-- {{ dealer.dealerCode }} -->
  <div class="img">
    <img class="no-results-notfound-image" src="..\assets\images\child-dealer.svg" />
  </div>
  <div>
    <div class="para">
      Billing for this dealership is included within digital invoices issued to parent dealer
      {{ invoiceParentDealerCode }}. For further information, please contact your parent dealer.
    </div>
  </div>
</div>
