import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';

@Component({
  selector: 'dsp-next-gen-ui-account-dealer-customer-id',
  templateUrl: './account-dealer-customer-id.component.html',
  styleUrls: ['./account-dealer-customer-id.component.scss'],
})
export class AccountDealerCustomerIDComponent implements OnInit {
  public value: any;
  public rowData: any;
  public columnWithHeader: any;
  public searchString = '';
  dspStoreData: any;
  constructor(private store: Store<DSPAppState>) {}
  ngOnInit(): void {
    this.rowData = this.value;
    this.searchString = this.columnWithHeader?.customData?.searchString || '';
    this.store.select('dsp').subscribe((dsp: any) => {
      this.dspStoreData = dsp;
    });
  }
}
