import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef, CcDrawerConfig, CcModal } from '@cat-digital-workspace/shared-ui-core';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { setSavedCancellationReason, setSelectedNeedsReviewItem } from '../actions/dashboard-needs-review.actions';
import { FilterCloseDashboardComponent } from '../../filter-close-dashboard/filter-close-dashboard.component';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'dsp-next-gen-ui-bar-chart-filter',
  templateUrl: './bar-chart-filter.component.html',
  styleUrls: ['./bar-chart-filter.component.scss'],
})
export class BarChartFilterComponent implements OnInit {
  headerContent: string | undefined;
  filterData: any;
  selectedFilters: any = [];
  enableApplyBtn = true;
  clearAllClicked: any = true;
  dspStoreData!: any;
  isShowNeedsReviewExpandedViewFilter = false;
  modalRef: any;
  enableFilterCloseSelectedFilter: any;
  unselectedFilters: any[] = [];
  // filterSelectedDataCancellationReason: any;
  // filterSelectedDataNeedsReview: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      filters: Array<object>;
      savedFilters: any;
    },
    public dialogRef: CcDrawerRef<BarChartFilterComponent, CcDrawerConfig>,
    private store: Store<DSPAppState>,
    public modal: CcModal,
    public dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isShowNeedsReviewExpandedViewFilter = this.dspStoreData?.featureFlagInfo?.isShowNeedsReviewExpandedViewFilter
        ? this.dspStoreData?.featureFlagInfo?.isShowNeedsReviewExpandedViewFilter
        : false;
    });
    this.dashboardService.applyFilterClicked.subscribe((res: any) => {
      if (res) {
        this.drawChart();
      }
    });
    this.filterData = this.header.filters ? this.header.filters : [];

    this.headerContent = this.header.headerType ? this.header.headerType : 'oneLineHeader';
    this.clearAllClicked = true;
  }

  clearFilters() {
    const updatedFilterArr: any = [];
    this.selectedFilters = [];
    this.header.savedFilters = [];
    if (this.headerContent === 'cancellation') {
      this.selectedFilters = [];
      this.store.select('cancellationReasonDashboardFilter').subscribe((x) => {
        const filterSelectedDataCancellationReason = x.cancellationReasonDashboardFilter?.filter(
          (res: any) => res.checked
        );
        if (filterSelectedDataCancellationReason.length > 0) {
          this.selectedFilters.push(filterSelectedDataCancellationReason);
          this.dashboardService.setbar(this.selectedFilters);
        }
      });
    } else if (this.headerContent === 'review') {
      this.selectedFilters = [];
      this.store.select('needsReviewDashboardFilter').subscribe((x) => {
        const filterSelectedDataNeedsReview = x.needsReviewDashboardFilter?.filter((res: any) => res.checked);
        if (filterSelectedDataNeedsReview.length > 0) {
          this.selectedFilters.push(filterSelectedDataNeedsReview);
          this.dashboardService.setbar(this.selectedFilters);
        }
      });
    }
    this.filterData.map((chartData: any) => {
      const updatedObj = { ...chartData, checked: false };
      updatedFilterArr.push(updatedObj);
    });
    this.filterData = [...updatedFilterArr];
    this.header.filters = [...updatedFilterArr];
    this.clearAllClicked = false;
    this.enableApplyBtn = true;
  }

  selectedFilter(event: any, value: any) {
    this.selectedFilters = [];
    this.unselectedFilters = [];
    if (event.checked) {
      this.enableApplyBtn = false;

      const updatedObj = { ...value, checked: true };
      this.selectedFilters.push(updatedObj);
      const updatedFilterResponse = this.filterData.map((itemOne: any) => {
        const matchingItem = this.selectedFilters.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      this.filterData = updatedFilterResponse;
      this.header.filters = updatedFilterResponse;
    } else {
      const updatedObj = { ...value, checked: false };
      this.unselectedFilters.push(updatedObj);
      const updatedFilterResponse = this.filterData.map((itemOne: any) => {
        const matchingItem = this.unselectedFilters.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      this.filterData = updatedFilterResponse;
      this.header.filters = updatedFilterResponse;
    }
    this.dashboardService.setbar(this.selectedFilters);
    // this.dashboardService.selectedFilters.next(true);
  }

  drawChart() {
    const checkedFilterData = this.header.filters.filter((x: any) => x.checked);
    if (this.headerContent === 'cancellation') {
      this.store.dispatch(setSavedCancellationReason({ cancelReason: this.header.filters as any }));
    } else if (this.headerContent === 'review') {
      this.store.dispatch(setSelectedNeedsReviewItem({ nrd: this.header.filters as any }));
    }
    this.header.savedFilters = checkedFilterData;
    if (checkedFilterData.length > 0) {
      this.dialogRef.close(this.header.savedFilters);
    } else {
      this.dialogRef.close(this.header.savedFilters);
    }
  }

  closeModal(filterData?: boolean) {
    if (this.isShowNeedsReviewExpandedViewFilter) {
      if (this.header.savedFilters && this.header.savedFilters.length > 0) {
        this.dialogRef.close(this.header.savedFilters);
      } else if (this.selectedFilters && this.selectedFilters.length > 0) {
        this.enableFilterCloseSelectedFilter = this.selectedFilters;
        this.enableFilterClose(this.enableFilterCloseSelectedFilter);
      } else if (this.unselectedFilters && this.unselectedFilters.length > 0) {
        this.enableFilterCloseSelectedFilter = this.unselectedFilters;
        this.enableFilterClose(this.enableFilterCloseSelectedFilter);
      } else {
        this.dialogRef.close();
      }
    } else {
      if (this.header.savedFilters && this.header.savedFilters.length > 0) {
        this.dialogRef.close(this.header.savedFilters);
      } else {
        this.dialogRef.close();
      }
    }
  }
  enableFilterClose(enableFilterCloseSelectedFilter: any) {
    const closeSelectedFilter = enableFilterCloseSelectedFilter;
    if (this.isShowNeedsReviewExpandedViewFilter) {
      this.modalRef = this.modal.openModal(FilterCloseDashboardComponent, {
        width: '524px',
        type: 'semi-modal',
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
      this.modalRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.closePopup(closeSelectedFilter);
        } else if (result === false) {
          this.modalRef.close();
          this.dialogRef.close();
        }
      });
    }
  }
  closePopup(val: any) {
    const checkedFilterData = val.filter((x: any) => x.checked);
    this.selectedFilter(checkedFilterData, val);
    this.drawChart();
  }
}
