import { Component, Inject, OnInit } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { environment } from '../../../../environments/environment';
import { DSPAppState } from '../../../store/state/dsp.state';
import { Store } from '@ngrx/store';
import { DspCommonService } from '../../../services/dsp-common.service';
import { fetchLoggedInDealerDetails } from '../../../shared/shared';
@Component({
  selector: 'dsp-next-gen-ui-term-modal',
  templateUrl: './term-modal.component.html',
  styleUrls: ['./term-modal.component.scss'],
})
export class TermModalComponent implements OnInit {
  appURL = environment.redirectURL.hostBaseUrl;
  dealerCode = 'TD00';
  dspStoreData!: any;
  constructor(
    private store: Store<DSPAppState>,
    private dspCommonService: DspCommonService
  ) {}
  ngOnInit(): void {
    this.dealerCode = fetchLoggedInDealerDetails().dealerCode;
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
  }
}
