import { Component, OnInit, Input, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CCTooltip, MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core';
import { Store } from '@ngrx/store';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import { DSPAppState } from '../../../../store/state/dsp.state';
import * as dspConstants from '../../../../shared/dspConstants';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { AssetSubscriptionFormDataType } from '../../../../models/assets.interface';
import { findIndex, isEmpty, isNil } from 'lodash-es';

@Component({
  selector: 'dsp-next-gen-ui-asset-details-card',
  templateUrl: './asset-details-card.component.html',
  styleUrls: ['./asset-details-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetDetailsCardComponent implements OnInit {
  @Input() rowData: any;
  @ViewChildren(CCTooltip) cctooltip: QueryList<CCTooltip> | undefined;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() hideBillToPartyOwnership!: boolean;
  @Input() showDealerInfo!: boolean;
  @Input() subsPricingEligible!: boolean;
  ownershipData: any;
  serviceMapping: any;
  datUrl = '';
  systemError = dspConstants.Common.SYSTEM_ERROR;
  dealer: any;
  b2cCustomers!: any;
  billToParty!: string;
  cvaCount!: any;
  cvaStatus!: any;
  cvaUrl!: any;
  datStatus = '';
  deviceInfoWithoutTrim!: any;
  customerInfoWithoutTrim!: any;
  dealerCustomerInfoWithoutTrim!: any;
  cvaIconColor = false;
  cvaProductEnabled = false;

  constructor(
    private store: Store<DSPAppState>,
    public sanitizer: DomSanitizer,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar
  ) {}

  ngOnInit(): void {
    this.deviceInfoWithoutTrim = '';
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.datUrl = 'https://' + dsp.dspConfig?.DATLink || '';
      this.serviceMapping = dsp.serviceMapping || {};
      this.b2cCustomers = dsp.billDirectCustomers || {};
      this.cvaIconColor = JSON.parse(dsp.dspConfig?.CVAIconColor ? dsp.dspConfig?.CVAIconColor : false);
      this.cvaProductEnabled = dsp.featureFlagInfo.CVAProduct ? dsp.featureFlagInfo.CVAProduct : false;
    });
    this.mapOwnershipData();
    this.billToParty = this.checkForB2CCustomers();
  }
  /**
   * This function checks if the current ownership data corresponds to a B2C customer.
   * It performs the following steps:
   * 1. Retrieves the UCID from ownershipData.
   * 2. Uses findIndex to check if the UCID exists in the list of B2C customers' billDirectCustomers.
   * 3. Returns 'Customer' if the UCID is found, otherwise returns 'Dealer'.
   *
   * @returns {string} - 'Customer' if the UCID is found in B2C customers, otherwise 'Dealer'.
   */
  checkForB2CCustomers() {
    const ucid = this.ownershipData?.ucid || '';
    return findIndex(this.b2cCustomers?.billDirectCustomers, ['ucid', ucid]) !== -1 ? 'Customer' : 'Dealer';
  }
  /**
   * This function shows a toast message with the provided details.
   * It performs the following steps:
   * 1. Configures the message bar with specific settings such as position, host selector, and duration.
   * 2. Opens the message bar with the provided message, status, and configuration.
   *
   * @param {string} message - The message to be displayed in the toast.
   * @param {string} status - The status of the message (e.g., 'error', 'success').
   * @returns {void}
   */
  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'asset-drawer-container',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  /**
   * This function maps the ownership data from the selected asset subscription form data.
   * It performs the following steps:
   * 1. Retrieves the customer data from selectedAssetSubscriptionFormData.
   * 2. Maps the CVA (Customer Value Agreement) details such as count, status, and URL.
   * 3. Constructs customer information strings without trimming.
   * 4. Checks if the customer data is not empty and not 'None':
   *    - If true, assigns the customer data to ownershipData.
   *
   * @returns {void}
   */
  mapOwnershipData() {
    const customer = this.selectedAssetSubscriptionFormData?.customer;
    this.cvaCount = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaCount;
    this.cvaStatus = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.cvaStatus;
    this.cvaUrl = this.selectedAssetSubscriptionFormData?.customer?.cvaDetails?.catForesightLink;
    this.customerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.ucidName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.ucid;
    this.dealerCustomerInfoWithoutTrim =
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustName +
      ' - ' +
      this.selectedAssetSubscriptionFormData?.customer?.dealerCustNo;

    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }
  /**
   * This function closes all tooltips referenced in the cctooltip array.
   * It performs the following steps:
   * 1. Iterates over each tooltip reference in the cctooltip array.
   * 2. Checks if the tooltip reference exists.
   * 3. If the tooltip reference exists, calls the hide method to close the tooltip.
   *
   * @returns {void}
   */
  closeTooltip() {
    this.cctooltip?.forEach((tooltipRef: any) => {
      if (tooltipRef) tooltipRef.hide();
    });
  }
  /**
   * This function checks the DAT (Digital Authorization Tool) status and returns the corresponding icon color.
   * It performs the following steps:
   * 1. Destructures CATDigitalAuthStatus from digitalAuthorizationDetails in ownershipData.
   * 2. Uses a switch statement to determine the icon color based on CATDigitalAuthStatus:
   *    - 'AGREE' returns 'icon-green'.
   *    - 'DECLINE' returns 'icon-red'.
   *    - 'UNAVAILABLE' returns 'icon-grey'.
   *    - Default case returns 'icon-amber' for the 'UNREACHABLE' state.
   *
   * @returns {string} - The icon color corresponding to the DAT status.
   */
  checkDATStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'AGREE': {
        return 'icon-green';
      }
      case 'DECLINE': {
        return 'icon-red';
      }
      case 'UNAVAILABLE': {
        return 'icon-grey';
      }
      default: {
        return 'icon-amber'; // for UNREACHABLE state
      }
    }
  }
  /**
   * This function updates the DAT (Digital Authorization Tool) status and returns the corresponding status message.
   * It performs the following steps:
   * 1. Destructures CATDigitalAuthStatus from digitalAuthorizationDetails in ownershipData.
   * 2. Checks the CATDigitalAuthStatus and updates datStatus accordingly:
   *    - If 'AGREE', sets datStatus to 'AGREE' and returns 'AVAILABLE'.
   *    - If 'DECLINE', sets datStatus to 'DECLINE' and returns 'DECLINED'.
   *    - If 'UNAVAILABLE', sets datStatus to 'UNAVAILABLE' and returns 'NOT AVAILABLE'.
   *    - For any other status, sets datStatus to 'SYSTEM ERROR' and returns 'SYSTEM ERROR'.
   *
   * @returns {string} - The status message corresponding to the DAT status.
   */
  updateDatStatus() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    if (CATDigitalAuthStatus == 'AGREE') {
      this.datStatus = 'AGREE';
      return `AVAILABLE`;
    } else if (CATDigitalAuthStatus == 'DECLINE') {
      this.datStatus = 'DECLINE';
      return `DECLINED`;
    } else if (CATDigitalAuthStatus == 'UNAVAILABLE') {
      this.datStatus = 'UNAVAILABLE';
      return `NOT AVAILABLE`;
    } else {
      this.datStatus = 'SYSTEM ERROR';
      return `SYSTEM ERROR`;
    }
  }
  /**
   * This function updates the DAT (Digital Authorization Tool) badge based on the authorization status.
   * It performs the following steps:
   * 1. Destructures CATDigitalAuthStatus from digitalAuthorizationDetails in ownershipData.
   * 2. Uses a switch statement to determine the badge class based on CATDigitalAuthStatus:
   *    - 'DECLINE' returns 'dat-badge-declined'.
   *    - 'UNAVAILABLE' returns 'dat-badge-declined'.
   *    - Default case returns 'dat-badge' for all other states.
   *
   * @returns {string} - The badge class corresponding to the DAT status.
   */
  updateDATBadge() {
    const { CATDigitalAuthStatus } = this.ownershipData?.digitalAuthorizationDetails || '';
    switch (CATDigitalAuthStatus) {
      case 'DECLINE': {
        return 'dat-badge-declined';
      }
      case 'UNAVAILABLE': {
        return 'dat-badge-declined';
      }
      default: {
        return 'dat-badge'; // Except declined, all other states
      }
    }
  }
  /**
   * This function constructs a dealer customer data string from the provided ID and name.
   * It performs the following steps:
   * 1. Checks if the name is null or undefined using isNil:
   *    - If true, uses an empty string.
   *    - If false, uses the provided name.
   * 2. Checks if the ID is null or undefined using isNil:
   *    - If true, uses an empty string.
   *    - If false, uses the provided ID.
   * 3. Concatenates the name and ID with a hyphen.
   * 4. Returns the constructed ownership data string.
   *
   * @param {string} id - The dealer customer ID.
   * @param {string} name - The dealer customer name.
   * @returns {string} - The constructed ownership data string.
   */
  dealerCustomerData(id: string, name: string) {
    const ownershipData = `${isNil(name) ? '' : name} - ${isNil(id) ? '' : id}`;
    return ownershipData;
  }
  /**
   * This function updates the name and ID, ensuring neither is null or undefined.
   * It performs the following steps:
   * 1. Checks if the ID is null or undefined using isNil:
   *    - If true, uses an empty string.
   *    - If false, uses the provided ID.
   * 2. Checks if the name is null or undefined using isNil:
   *    - If true, uses an empty string.
   *    - If false, uses the provided name.
   * 3. Concatenates the name and ID with a hyphen.
   * 4. Returns the constructed string.
   *
   * @param {string} name - The name to be updated.
   * @param {string} id - The ID to be updated.
   * @returns {string} - The constructed string with name and ID.
   */
  updateName(name: string, id: string) {
    const ID = isNil(id) ? '' : id;
    const NAME = isNil(name) ? '' : name;
    return `${NAME} - ${ID}`;
  }
  /**
   * This function retrieves and formats the device information.
   * It performs the following steps:
   * 1. Checks if both deviceSerialNumer and radioSerialNoList are empty:
   *    - If true, returns '-'.
   * 2. Initializes deviceVal with deviceSerialNumer if it is not empty.
   * 3. Adds a separator if both deviceSerialNumer and radioSerialNoList are not empty.
   * 4. Iterates over radioSerialNoList to append each radio serial number to deviceVal:
   *    - Adds a separator between serial numbers.
   * 5. Calls checkForTrim to format the final deviceVal.
   *
   * @returns {string} - The formatted device information.
   */
  getDeviceInformation() {
    if (isEmpty(this.rowData?.deviceSerialNumer) && isEmpty(this.rowData?.radioSerialNoList)) {
      return '-';
    }
    let deviceVal = !isEmpty(this.rowData?.deviceSerialNumer) ? this.rowData.deviceSerialNumer : '';
    if (!isEmpty(this.rowData?.deviceSerialNumer) && !isEmpty(this.rowData?.radioSerialNoList)) {
      deviceVal += ' / ';
    }
    if (this.rowData?.radioSerialNoList?.length > 0) {
      const length = this.rowData?.radioSerialNoList?.length;
      for (let i = 0; i < length; i++) {
        if (i == length - 1) {
          deviceVal += this.rowData?.radioSerialNoList[i] + '';
        } else {
          deviceVal += this.rowData?.radioSerialNoList[i] + ' / ';
        }
      }
      return this.checkForTrim(deviceVal);
    } else {
      return this.checkForTrim(this.rowData?.deviceSerialNumer);
    }
  }
  /**
   * This function assigns the provided string value to the deviceInfoWithoutTrim property
   * and returns the same string value.
   *
   * @param {string} value - The string value to be assigned and returned.
   * @returns {string} - The same string value that was passed in.
   */
  checkForTrim(value: string) {
    this.deviceInfoWithoutTrim = value;
    return value;
  }

  cvaURLClick() {
    window.open(this.cvaUrl, '_blank');
  }
}
