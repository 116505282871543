import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PendingRequestFormData } from '../self-serve.interface';

@Component({
  selector: 'dsp-next-gen-ui-pending-request',
  templateUrl: './pending-request.component.html',
  styleUrls: ['./pending-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PendingRequestComponent {
  @Input() rowData: any;
  @Input() pendingRequestFormData!: PendingRequestFormData;
}
