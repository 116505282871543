import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { AssetDrawerService } from '../../../../services/asset-drawer.service';
import {
  MessageBar,
  MessageBarConfig,
  DrawerService,
  CcDrawerConfig,
  CcDrawerSize,
  CcModal,
} from '@cat-digital-workspace/shared-ui-core';
import * as dspConstants from '../../../../shared/dspConstants';
import { PaymentPreviewPayload } from '../../asset-interface';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { AssetService } from '../../services/asset.service';
import { AssetSubscriptionFormDataType, PrepayContractInfo } from '../../../../models/assets.interface';
import { PlanPaymentComponent } from './plan-payment/plan-payment.component';
import { isEmpty } from 'lodash-es';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TermsofsaleModalComponent } from '../../../footer/termsofsale-modal/termsofsale-modal.component';
import { environment } from 'apps/dsp-ui/src/environments/environment';
import { Router } from '@angular/router';
import { DspUtilsCommonService } from 'apps/dsp-ui/src/app/services/dsp-utils-common.service';
import { SharedService } from 'apps/dsp-ui/src/app/shared.service';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';
@Component({
  selector: 'dsp-next-gen-ui-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPreviewComponent implements OnInit, AfterViewInit {
  @Input() rowData: any;
  @Input() selectedAssetDetails: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  @Input() dynamicTrackerData: any;
  @Input() currentPageIndex = 0;
  @Input() subsPricingEligible: any;
  // @Output() updateTrackerIndexFor = new EventEmitter();
  @Output() updateTrackerIndex = new EventEmitter();
  ownershipData!: any;
  dealer: any;
  appName: any;
  serviceMapping: any;
  paymentDetails: any;
  loader = false;
  currentPaymentDetails: any;
  previousPaymentDetails: any;
  planPayment: any;
  systemError = dspConstants.Common.SYSTEM_ERROR;
  isCurrentProductVision = false;
  isPreviousProductVision = false;
  previousSubscription: any;
  currentSubscription: any;
  currentProductFamily: any;
  previousProductFamily: any;
  productFamily: any;
  footerMsg: any;
  globalInstance: any;
  subscription!: Subscription;
  paymentPreviewAPICallData!: Subscription;
  isPaymentPreviewAPICompleted = false;
  isExpandPlansInEditSubscription = true;
  userActiveFeatures: any;
  isNumberConventionEnabled: any;
  isTimeFormatEnabled: any;
  dspStoreData: any;
  seeMoreOverlayBasicDetails: any;

  constructor(
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    private messageBar: MessageBar,
    public assetService: AssetService,
    private drawerService: DrawerService,
    public modal: CcModal,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private dspUtilsCommonService: DspUtilsCommonService,
    private userPreferenceService: UserPreferenceService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.rowData) {
      this.seeMoreOverlayBasicDetails = {
        make: this.rowData?.make ? this.rowData?.make : '',
        model: this.rowData?.model ? this.rowData?.model : '',
        modelYear: this.rowData?.manufacturingYear ? this.rowData?.manufacturingYear : '',
        serialNumber: this.rowData?.serialNumber ? this.rowData?.serialNumber : '',
        engineSerialNumber: this.rowData?.engineSerialNo ? this.rowData?.engineSerialNo : '',
        reportingState: this.rowData.reportingState ? this.rowData.reportingState : '',
      };
    }
    this.dealer = fetchLoggedInDealerDetails();
    this.subscription = this.assetService.backButtonNotification.subscribe((res: any) => {
      if (res?.isBackButtonPressed) {
        if (this.paymentPreviewAPICallData && !this.isPaymentPreviewAPICompleted) {
          this.paymentPreviewAPICallData.unsubscribe();
        }
      }
    });
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.serviceMapping = dsp.serviceMapping || {};
      this.isExpandPlansInEditSubscription = this.dspStoreData?.featureFlagInfo?.isExpandPlansInEditSubscription
        ? this.dspStoreData?.featureFlagInfo?.isExpandPlansInEditSubscription
        : false;
      this.userActiveFeatures = dsp.userActiveFeatures ? dsp.userActiveFeatures.features : [];
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.isTimeFormatEnabled = dsp?.featureFlagInfo?.isTimeFormatEnabled
        ? dsp?.featureFlagInfo?.isTimeFormatEnabled
        : false;
    });
    this.currentProductFamily = this.selectedAssetSubscriptionFormData.productFamily.currentProductFamily || '';
    this.previousProductFamily = this.selectedAssetSubscriptionFormData.productFamily.previousProductFamily || '';

    this.selectedAssetSubscriptionFormData.isValidForm = false;
    this.previousSubscription =
      this.selectedAssetSubscriptionFormData.subscription.previousSubscription.customerLevel ||
      this.selectedAssetSubscriptionFormData.subscription.previousSubscription.dealerLevel ||
      this.selectedAssetSubscriptionFormData.subscription.previousSubscription.catLevel;
    this.currentSubscription =
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription.customerLevel ||
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription.dealerLevel ||
      this.selectedAssetSubscriptionFormData.subscription.currentSubscription.catLevel;
    if (
      (this.selectedAssetSubscriptionFormData.service.previousService == 'New VisionLink' ||
        this.selectedAssetSubscriptionFormData.service.previousService == 'VisionLink') &&
      this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected == true
    ) {
      this.isPreviousProductVision = true;
    }
    if (
      (this.selectedAssetSubscriptionFormData.service.currentService == 'New VisionLink' ||
        this.selectedAssetSubscriptionFormData.service.currentService == 'VisionLink') &&
      this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected == true
    ) {
      this.isCurrentProductVision = true;
    }
    this.mapOwnershipData();
    const getPaymentPage: PaymentPreviewPayload = {
      assetMake: this.rowData?.make || '',
      assetSerialNumber: this.rowData?.serialNumber || '',
      deviceId: '',
      deviceSerialNumber: this.rowData?.deviceSerialNumer || '',
      deviceType: this.rowData?.deviceType || '',
      dealerCode: this.rowData?.dealerCode || this.dealer?.dealerCode,
      ucid: this.selectedAssetSubscriptionFormData.customer.ucid || '',
      customerName: this.selectedAssetSubscriptionFormData.customer.ucidName || '',
      dcnName: this.selectedAssetSubscriptionFormData.customer.dealerCustName || '',
      dcnNumber: this.selectedAssetSubscriptionFormData.customer.dealerCustNo || '',
      contractEndDate: this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractEndDate || '',
      contractStartDate: this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.contractStartDate || '',
      applicationName: '', //from form data
      siteName: this.selectedAssetSubscriptionFormData?.siteInfo?.siteName || '',
      subscriptions: [], //from form data
      cva: this.rowData?.cvaInfo?.cvaStatus == 'Available' ? true : false,
      prepay: this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected,
    };
    if (this.rowData?.reportingState == 'Subscribed' && this.previousSubscription != '') {
      const getPreviousPaymentPayload = Object.assign({}, getPaymentPage);
      this.getPreviousPaymentInfo(getPreviousPaymentPayload);
    }
    const getPaymentPayload = Object.assign({}, getPaymentPage);
    this.getCurrentPaymentInfo(getPaymentPayload);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.globalInstance = this.renderer?.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (event.srcElement.innerText === 'Terms of Sale') {
        sessionStorage.setItem('isNewWindowOpened', '/termSaleModal');
        this.sharedService.setNavigationValue('enableFooterComponent');
        this.dspUtilsCommonService.removeIsNewWindowOpened();
        this.router.navigate([]).then((result) => {
          window.open('termSaleModal', '_blank');
        });
      }
    });
  }

  getPreviousPaymentInfo(getPreviousPaymentPayload: any) {
    getPreviousPaymentPayload.isViewSubscription = true;
    getPreviousPaymentPayload.subscriptions = [];
    getPreviousPaymentPayload.applicationName = this.selectedAssetSubscriptionFormData.service.previousService;
    getPreviousPaymentPayload.prepay = this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.prepaySelected;
    getPreviousPaymentPayload.contractEndDate =
      this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractEndDate;
    getPreviousPaymentPayload.contractStartDate =
      this.selectedAssetSubscriptionFormData.prepay.previousPrepayData.contractStartDate;
    this.paymentPage(getPreviousPaymentPayload, 'previousPayment');
  }

  getCurrentPaymentInfo(getPaymentPayload: any) {
    const { prepaySelected: currentPrepayStatus } = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData;
    let allCurrentSubscriptions: any = [];
    allCurrentSubscriptions.push(this.currentSubscription);
    if (this.selectedAssetSubscriptionFormData?.addOns?.currentAddons?.length >= 1) {
      allCurrentSubscriptions = allCurrentSubscriptions.concat(
        this.selectedAssetSubscriptionFormData.addOns.currentAddons
      );
    }
    getPaymentPayload.isNewSubscription = true;
    getPaymentPayload.subscriptions = allCurrentSubscriptions;
    getPaymentPayload.applicationName = this.selectedAssetSubscriptionFormData.service.currentService;
    getPaymentPayload.prepay = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData.prepaySelected;
    getPaymentPayload.contractEndDate = this.formatContractDates('contractEndDate', currentPrepayStatus);
    getPaymentPayload.contractStartDate = this.formatContractDates('contractStartDate', currentPrepayStatus);
    this.paymentPage(getPaymentPayload, 'currentPayment');
  }

  mapOwnershipData() {
    const customer = this.selectedAssetSubscriptionFormData.customer;
    if (!isEmpty(customer) && customer !== 'None') {
      this.ownershipData = customer;
    }
  }

  formatContractDates(dateOption: keyof PrepayContractInfo, prepayFlag: boolean) {
    if (!prepayFlag) return null;
    const selectedDateRange = this.selectedAssetSubscriptionFormData.prepay.currentPrepayData[dateOption] || '';
    return !isEmpty(selectedDateRange) ? selectedDateRange : null;
  }

  openDrawer(PaymentDetails: any, paymentType: any) {
    const applicationName =
      paymentType === 'previousPayment'
        ? this.selectedAssetSubscriptionFormData.service.previousService
        : this.selectedAssetSubscriptionFormData.service.currentService;
    const basePlan = paymentType === 'previousPayment' ? this.previousSubscription : this.currentSubscription;
    this.planPayment = this.drawerService.openDrawer<PlanPaymentComponent, CcDrawerConfig>(PlanPaymentComponent, {
      data: {
        rowData: paymentType === 'previousPayment' ? PaymentDetails?.current : PaymentDetails?.new,
        applicationName: applicationName,
        basePlan: basePlan,
        seeMoreOverlayBasicDetails: this.seeMoreOverlayBasicDetails,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.SMALL,
    });
  }

  paymentPage(getPaymentPayload: any, paymentType: any) {
    this.loader = true;
    this.isPaymentPreviewAPICompleted = false;
    this.paymentPreviewAPICallData = this.assetDrawerService.getPaymentPreview(getPaymentPayload).subscribe({
      next: (response: any) => {
        this.isPaymentPreviewAPICompleted = true;
        if (response) {
          this.selectedAssetSubscriptionFormData.newPaymentDetails = response;
          if (paymentType == 'currentPayment') {
            if (this.currentPageIndex === this.dynamicTrackerData?.length - 1) {
              this.updateTrackerIndex.emit();
            }
            this.currentPaymentDetails = response;
            this.currentPaymentDetails.prepay = getPaymentPayload?.prepay;
            this.footerMsg = this.updateFooterMessage(this.currentPaymentDetails);
            this.loader = false;
            this.selectedAssetSubscriptionFormData.isValidForm = true;
            this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
          } else {
            this.previousPaymentDetails = response;
            this.previousPaymentDetails.prepay = getPaymentPayload?.prepay;
          }
        }
      },
      error: (_err: any) => {
        this.isPaymentPreviewAPICompleted = true;
        this.loader = false;
        this.showToastMessage(this.systemError, 'error');
      },
    });
  }

  updateFooterMessage(currentPaymentDetails: any) {
    let footerMsg;
    if (!currentPaymentDetails.cancelNotificationMessage) footerMsg = '';
    if (currentPaymentDetails.cancelNotificationMessage !== '') {
      footerMsg = this.currentPaymentDetails.cancelNotificationMessage
        .split('<br/>')
        .join(' ')
        .replace('href="terms/tos"', 'onclick="termSaleModal()"')
        .replace(
          '"/assets/dsp_help_document/index.htm#t=BillingOverview.htm"',
          '"' + environment.redirectURL.helpDocUrl + 'article/Can-I-prepay-for-subscriptions?language=en_US"'
        );
    }
    // if promotion is there and promotion not eligible message also coming from API, we should hide the message.
    if (
      currentPaymentDetails?.new?.promotions?.length > 0 &&
      currentPaymentDetails.cancelNotificationMessage.indexOf(dspConstants.Worklist.PROMOTION_NOT_ELIGIBLE) != -1
    ) {
      footerMsg = currentPaymentDetails.cancelNotificationMessage
        .split('<br/>')
        .join(' ')
        .replace(dspConstants.Worklist.PROMOTION_NOT_ELIGIBLE, '');
    }
    const TOS_REGEX = new RegExp('By submitting your order, you are agreeing to our <a[^>]*>Terms of Sale</a>');
    if (this.userActiveFeatures && this.userActiveFeatures.indexOf('TOS') === -1) {
      footerMsg = footerMsg.replace(TOS_REGEX, '');
    }
    return footerMsg;
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'manage-asset',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }
  displayAmount(amount: any) {
    if (amount === 'N/A' || isEmpty(amount)) {
      return '-';
    }
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(amount, true);
    }
    return amount;
  }
  getConvertedDate(expirationDate: any) {
    if (this.isTimeFormatEnabled) {
      return !isEmpty(expirationDate) ? this.userPreferenceService.convertDate(expirationDate) : '';
    }
    return expirationDate;
  }
}
