<div class="cc-modal group-info__modal">
  <div class="cc-modal-header">
    <div class="cc-modal-title">{{ data.customTitle }}</div>
  </div>
  <div class="cc-modal-content group-info__content">
    <p *ngIf="!data.trustAsHtml">
      {{ data.customMessage }}
      <span *ngIf="data.releasenoteLink && data.releasenoteLink.length > 10">
        Click <a style="text-decoration: underline" href="{{ data.releasenoteLink }}" target="_blank">here</a>
        for more details.
      </span>
      <span *ngIf="data.hyperlink && data.hyperlink.length">
        Click <a style="text-decoration: underline" href="{{ data.hyperlink }}" target="_blank">here</a>
        for more details.
      </span>
    </p>

    <p *ngIf="data.trustAsHtml" [innerHTML]="sanitizer.bypassSecurityTrustHtml(data.customMessage || '')"></p>

    <div>
      <cc-checkbox [isTheme]="'2.0'" (toggleChange)="checkBoxToggled($event)">Don't show again</cc-checkbox>
    </div>
  </div>
  <div class="cc-modal-footer group-info__footer">
    <button cc-btn-primary cc-btn-large [setTheme]="true" (click)="closePopup()">Ok</button>
  </div>
</div>
