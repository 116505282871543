import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CcDrawerConfig, CcDrawerSize, CcModal, DrawerService } from '@cat-digital-workspace/shared-ui-core';
import { BarChartFilterComponent } from '../../bar-chart-filter/bar-chart-filter.component';
import { DashboardService } from '../../services/dashboard.service';
import { NeedsReviewExpandViewComponent } from '../needs-review-expand-view/needs-review-expand-view.component';
import { fromEvent } from 'rxjs';
import { fetchLoggedInDealerDetails } from '../../../../shared/shared';
import { setInitialNRDFItem } from '../../actions/dashboard-needs-review.actions';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../../store/state/dsp.state';
import { FilterCloseDashboardComponent } from '../../../filter-close-dashboard/filter-close-dashboard.component';
@Component({
  selector: 'dsp-next-gen-ui-needs-review-dashboard',
  templateUrl: './needs-review-dashboard.component.html',
  styleUrls: ['./needs-review-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedsReviewDashboardComponent implements OnInit {
  dealerCode = fetchLoggedInDealerDetails().dealerCode;
  needsReview: any = [];
  xAxis: any = [];

  selectedCategory: any;
  filterContentsRef: any;

  needsReviewChartData = {
    series: [
      {
        data: [],
        minPointLength: 5,
      },
    ],
  };
  needsReviewChartConfig = {
    // width: window.devicePixelRatio === 1.5 ? 495 : window.devicePixelRatio === 1 ? 805 : 610,
    height: '430px',
    styledMode: false,
    WrapXaxisLabel: true,
    groupGap: 40,

    xAxis: {
      categories: this.xAxis,
      gridLineDashStyle: 'Dash', // values = 'Solid','ShortDash','ShortDot','ShortDashDot','ShortDashDotDot','Dot','Dash','LongDash','DashDot','LongDashDot','LongDashDotDot'
      gridLineWidth: 1, // width of grid
      gridLineColor: '#e2e2e2', // to apply gridline color
      useLabelHTML: true, // make it to true to apply the styles object
      align: 'left',
      reserveSpace: true,
      style: {
        width: '350px',
        // 'white-space': 'wrap !important',
        // overflow: 'hidden',
        // 'text-overflow': 'ellipsis',
      }, //  style object
    },
    columnWidth: 40,
    yAxis: { enabled: false, visible: false },
    legend: { enabled: false },
    enableheightConfig: true,
    dataLabelsInside: true,
    seriesChartHeight: 260,
    tooltip: {
      useXAxisLabel: true,
      padding: 5,
      shared: false,
      useHTML: true,
      tooltipseperator: ' |',
      hideColor: true,
    },
    dataLabels: true,
    dataLabelsCrop: false,
    dataLabelsOverflow: 'allow',
    scrollbar: {
      max_Axis: 6,
      scrollButtonHide: true,
      scrollBarWidth: 4,
    },
  };
  chart2ResponsiveConfig = {
    type: 'horizontal',
    height: 305,
    marginBottom: 10,
    marginRight: 30,
    yAxisOpposite: false,
    legendPlacement: 'top',
  };
  filterResponse: any = [];
  chartSpinner: any;
  filterSelectedData: any;
  dataToFilter: any;
  dspStoreData!: any;
  filterIcon = false;
  isFullScreenOpened = false;
  modalRef: any;
  constructor(
    public modal: CcModal,
    private dashboardService: DashboardService,
    private drawerService: DrawerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<DSPAppState>
  ) {
    fromEvent(window, 'resize').subscribe((evt: Event) => {
      this.setColumnWidth();
    });
  }

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
    });
    this.dashboardService.openFilterClicked.subscribe((data: any) => {
      if (data && this.isFullScreenOpened) {
        this.openFilter();
      }
    });
    this.getWorklistQueue(this.dealerCode);
    this.setColumnWidth();
  }

  expandedChartView() {
    this.cdr.detectChanges();
    this.isFullScreenOpened = true;
    const modalRef = this.modal.openModal(NeedsReviewExpandViewComponent, {
      type: 'full-modal',

      data: {
        title: 'Needs Review',
        config: this.needsReviewChartConfig,
        chartData: this.needsReviewChartData,
      },

      hasBackdrop: true,
      closeOnEsc: false,
      hideHeader: false,
      hideFooter: false,
      disableMargin: false,
      disableBackdropClick: true,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      this.isFullScreenOpened = false;
      this.needsReviewChartConfig.height = '430px';
      this.needsReviewChartConfig.groupGap = 40;
    });
  }

  mapReviewFiltersChart(response: any) {
    const chartValue: any = [];
    const chartLabel: any = [];
    response.map((item: any) => {
      chartValue.push(Number(item.value));
      chartLabel.push(item.label);
    });
    this.needsReviewChartData = { series: [{ data: chartValue, minPointLength: 5 }] };
    this.needsReviewChartConfig.xAxis.categories = [...new Set(chartLabel)];
  }

  getWorklistQueue(dealerCode: any) {
    this.chartSpinner = true;
    this.store.select('needsReviewDashboardFilter').subscribe((x) => {
      this.filterSelectedData = x.needsReviewDashboardFilter?.filter((res: any) => res.checked);
      if (this.filterSelectedData.length) {
        this.filterIcon = true;
      } else {
        this.filterIcon = false;
      }
      this.dataToFilter = x.needsReviewDashboardFilter;
    });
    if (this.filterSelectedData && this.filterSelectedData.length > 0) {
      const updatedFilterResponse = this.dataToFilter.map((itemOne: any) => {
        const matchingItem = this.filterSelectedData.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      this.chartSpinner = false;
      this.needsReview = this.filterSelectedData;
      const sortedArr = this.sortingArray(this.needsReview);
      this.mapReviewFiltersChart(sortedArr);
      this.filterResponse = updatedFilterResponse;
    } else {
      this.dashboardService.getWorklistQueue(dealerCode).subscribe({
        next: (res: any) => {
          if (res) {
            this.chartSpinner = false;
            this.needsReview = res;
            this.store.dispatch(setInitialNRDFItem({ nrd: this.needsReview }));
            this.mapReviewFiltersChart(this.needsReview);

            this.filterResponse = this.needsReview;
          }
        },
        error: (error) => {
          this.chartSpinner = false;
        },
      });
    }
  }
  sortingArray(filteredChartData: any) {
    const sortedArr = filteredChartData.sort((a: any, b: any) => {
      const fa = a.label?.toLowerCase(),
        fb = b.label?.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return sortedArr;
  }
  openFilter(type?: string) {
    this.store.select('needsReviewDashboardFilter').subscribe((x) => {
      this.filterSelectedData = x.needsReviewDashboardFilter?.filter((res: any) => res.checked);
    });

    if (this.filterSelectedData && this.filterSelectedData.length > 0) {
      const updatedFilterResponse = this.dataToFilter.map((itemOne: any) => {
        const matchingItem = this.filterSelectedData.find((itemTwo: any) => itemOne.id === itemTwo.id);
        return matchingItem ? { ...itemOne, checked: matchingItem.checked } : itemOne;
      });
      this.filterResponse = updatedFilterResponse;
    } else {
      const updatedFilterRes: any = [];
      this.filterResponse.map((chartData: any) => {
        const updatedObj = { ...chartData, checked: false };
        updatedFilterRes.push(updatedObj);
      });
      this.filterResponse = [];
      this.filterResponse = updatedFilterRes;
    }

    this.filterContentsRef = this.drawerService.openDrawer<BarChartFilterComponent, CcDrawerConfig>(
      BarChartFilterComponent,
      {
        data: {
          filters: this.filterResponse,
          headerType: 'review',
        },
        enableBlackBackDrop: false,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.SMALL,
      }
    );
    if (this.filterContentsRef) {
      this.filterContentsRef?.onBackdropClick?.subscribe((data: any) => {
        const res = this.dashboardService.getbar();
        if (!res) {
          this.filterContentsRef.close();
        }
        if (res && data) {
          this.modalRef = this.modal.openModal(FilterCloseDashboardComponent, {
            width: '524px',
            type: 'semi-modal',
            closeOnEsc: false,
            disableBackdropClick: true,
            isAutoHeightModalContent: true,
          });
          this.modalRef.afterClosed().subscribe((result: any) => {
            if (result === true) {
              this.dashboardService.applyFilterClicked.next(true);
              // this.closePopup(closeSelectedFilter);
            } else if (result === false) {
              this.modalRef.close();
              this.filterContentsRef.close();
            }
          });
        } else {
          this.modalRef.close();
          this.filterContentsRef.close();
        }
        // });
      });
    }
    this.filterContentsRef.afterClosed().subscribe((filteredChartData: any) => {
      this.chartSpinner = true;
      this.cdr.detectChanges();
      if (filteredChartData && filteredChartData.length > 0) {
        // this.filterIcon = true;
        const sortedArr = this.sortingArray(filteredChartData);
        this.mapReviewFiltersChart(sortedArr);
        this.needsReviewChartConfig.scrollbar.max_Axis =
          filteredChartData.length < 6 ? filteredChartData.length - 1 : 6;
      } else {
        if (
          filteredChartData === false ||
          (filteredChartData != undefined &&
            Object.keys(filteredChartData) &&
            Object.keys(filteredChartData).length == 0)
        ) {
          // this.filterIcon = false;
          this.mapReviewFiltersChart(this.dataToFilter);
          this.needsReviewChartConfig.scrollbar.max_Axis =
            filteredChartData?.length < 6 ? filteredChartData?.length - 1 : 6;
        }
      }
      this.setColumnWidth();
      this.dashboardService.applyFilterClicked.next(false);
      this.dashboardService.setbar(null);
      this.chartSpinner = false;
      this.cdr.detectChanges();
      // this.dashboardService.selectedFilters.next(false);
    });
  }

  needsreviewChartClick(event: any) {
    sessionStorage.setItem('dealerQueueReason', event.point.category);
    this.router.navigate(['/needsreview']);
  }
  setColumnWidth() {
    this.needsReviewChartConfig.groupGap = 40;

    if (window.innerWidth <= 1000) {
      this.needsReviewChartConfig.columnWidth = 4;
    } else if (window.innerWidth > 1000 && window.innerWidth <= 1100) {
      this.needsReviewChartConfig.columnWidth = 15;
    } else if (window.innerWidth > 1100 && window.innerWidth <= 1200) {
      this.needsReviewChartConfig.columnWidth = 18;
    } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
      this.needsReviewChartConfig.columnWidth = 26;
    } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
      this.needsReviewChartConfig.columnWidth = 30;
    } else if (window.innerWidth > 1400 && window.innerWidth <= 1500) {
      this.needsReviewChartConfig.columnWidth = 40;
    } else if (window.innerWidth > 1500 && window.innerWidth <= 1600) {
      this.needsReviewChartConfig.columnWidth = 40;
    } else {
      this.needsReviewChartConfig.groupGap = 70;
      this.needsReviewChartConfig.columnWidth = 70;
    }
  }
}
