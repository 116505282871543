<div>
  <span>
    {{ config?.title }}
    <cc-icon
      cc-tooltip
      class="licenseRequestIcon"
      [tooltipPosition]="'top-left'"
      [template]="tooltipTemplateForLicenseEligiblity"
      [appClass]="'-license-icon'"
      fontSet="icomoon"
      >info-tooltip</cc-icon
    >
  </span>
</div>
<ng-template #tooltipTemplateForLicenseEligiblity>
  <div class="license-eligiblity-container">
    <p class="license-eligiblity-text">
      <b>No Upgrade Available:</b> The current license on the component is the highest license available
    </p>
    <p style="margin-top: 10px" class="license-eligiblity-text">
      <b>Not Upgradable:</b> The component is not eligible to be upgraded by Dealer Services Portal
    </p>
  </div>
</ng-template>
