<div class="cc-drawer">
  <div class="cc-drawer__header">
    <ng-container [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="cc-drawer__content filters-drawer" *ngIf="this.ecmInfo?.length>0">
    <div class="accord-info" *ngFor="let ecm of this.ecmInfo">
      <cc-accordion>
        <cc-expandable [expanded]="true">
          <cc-expandable-header>
            <div class="expandable-header-title">
              <span class="ecm-title-display"> {{ ecm.description }} </span>
            </div>
          </cc-expandable-header>
          <ng-container>
            <div style="word-break: break-all"></div>
            <div class="ecm-table">
              <span class="ecm-key">ECM S/N</span><span class="ecm-value">{{ ecm.serialNumber || '-' }}</span>
            </div>
            <div class="ecm-table">
              <span class="ecm-key">Software P/N</span
              ><span class="ecm-value">{{ ecm.softwarePartNumber || '-' }}</span>
            </div>
            <div class="ecm-table">
              <span class="ecm-key">Hardware P/N</span
              ><span class="ecm-value">{{ ecm.hardwarePartNumber || '-' }}</span>
            </div>
            <div class="ecm-table">
              <span class="ecm-key">Sync Clock Enabled</span
              ><span class="ecm-value">{{ ecm.syncClockEnabled || '-' }}</span>
            </div>
            <div class="ecm-table">
              <span class="ecm-key">Sync Clock Level</span
              ><span class="ecm-value">{{ ecm.syncClockMaster || '-' }}</span>
            </div>
          </ng-container>
        </cc-expandable>
      </cc-accordion>
    </div>
  </div>
  <div *ngIf="!this.ecmInfo || this.ecmInfo.length === 0">
    <div class="details-container no-data-found">
      <div>
        <img class="no-results-found-image" src="../../../../../assets/images/no-ecm.svg" />
        <p class="no-plans-text">No data to display</p>
      </div>
    </div>
  </div>
</div>
<ng-template #oneLineHeader>
  <span class="cc-drawer__ellipsis ecm-title"> ECM Information </span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon ecm-close" (click)="closeModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
