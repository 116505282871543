<div class="cc-drawer show-all-filter-drawer">
  <div class="cc-drawer__header cc-drawer__withActions">
    <ng-container [ngTemplateOutlet]="oneLineHeader"></ng-container>
  </div>
  <div class="columnfilter-search" [ngClass]="searchHelpText !== '' ? 'helptext-style' : ''">
    <cc-input-field
      [labelValue]="'Search'"
      [inputtype]="'search'"
      isTheme="2.0"
      [isDense]="true"
      id="cc-atom-search-s7"
      [value]="searchValue"
      [searchMenuHeader]="mobileHeader"
      [triggerSearchMinChar]="3"
      (valueChange)="searchValue = $event.value; onFilterSearchChange($event)"
      [enableMobileOverlay]="true"
      [isLeading]="true"
      [helpText]="searchHelpText"
    >
      <span class="searchIcon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9454 10.6023C10.7538 10.4034 10.4373 10.3976 10.2384 10.5893C10.0396 10.7809 10.0338 11.0974 10.2254 11.2962L10.9454 10.6023ZM14.64 15.8763C14.8316 16.0751 15.1482 16.0809 15.347 15.8893C15.5458 15.6977 15.5516 15.3811 15.36 15.1823L14.64 15.8763ZM6.57944 12.6589C9.93702 12.6589 12.6589 9.93702 12.6589 6.57944H11.6589C11.6589 9.38473 9.38474 11.6589 6.57944 11.6589V12.6589ZM12.6589 6.57944C12.6589 3.22186 9.93702 0.5 6.57944 0.5V1.5C9.38474 1.5 11.6589 3.77414 11.6589 6.57944H12.6589ZM6.57944 0.5C3.22186 0.5 0.5 3.22186 0.5 6.57944H1.5C1.5 3.77414 3.77414 1.5 6.57944 1.5V0.5ZM0.5 6.57944C0.5 9.93702 3.22186 12.6589 6.57944 12.6589V11.6589C3.77414 11.6589 1.5 9.38473 1.5 6.57944H0.5ZM10.2254 11.2962L14.64 15.8763L15.36 15.1823L10.9454 10.6023L10.2254 11.2962ZM15.36 15.1823L10.9454 10.6023L10.2254 11.2962L14.64 15.8763L15.36 15.1823Z"
            fill="black"
          />
        </svg>
      </span>
      <input
        #columnFilterSearchBox
        input-field
        [(ngModel)]="searchValue"
        class="input-field input-search-width"
        [value]="searchValue"
        autocomplete="off"
        [isFloating]="false"
        type="search"
        aria-labelledby="Search Help Text"
        aria-describedby="Search Help Text"
        (valueChange)="searchValue = $event.value"
        (blur)="onBlurEvent($event)"
        (focus)="onFocusEvent()"
      />
    </cc-input-field>
  </div>
  <div (scroll)="onScroll($event)" #showAllContainer class="cc-drawer__content filters-drawer">
    <div *ngIf="SearchFilterData && SearchFilterData[columns.field][0] && SearchFilterData[columns.field][0].length">
      <div
        #dropdownOverlay
        *ngFor="let data of SearchFilterData[columns.field][0]"
        (emitInfiniteScrollEvent)="emitInfiniteScrollEvent()"
        [appOverlayClass]="'app-overlay-class-with-virtualscroll'"
        [loader]="loader"
      >
        <div class="filter-checkbox-label">
          <cc-checkbox
            [checked]="columns.selectedValues.indexOf(data) > -1"
            (toggleChange)="getSelectedFilter($event, columns, data)"
          >
            <span [innerHTML]="data | highlightSearch: searchString.trim()"></span
          ></cc-checkbox>
        </div>
      </div>
    </div>
    <div class="spinnerCls" *ngIf="isLoading">
      <dsp-next-gen-ui-search-loader></dsp-next-gen-ui-search-loader>
    </div>
    <div
      class="noResultsFound"
      *ngIf="
        SearchFilterData &&
        SearchFilterData[columns.field] &&
        SearchFilterData[columns.field].length === 0 &&
        !isLoading &&
        isApiCallCompleted
      "
    >
      No Results Available
    </div>
  </div>

  <div class="cc-drawer__footer">
    <button
      cc-btn-medium
      [setTheme]="true"
      [disabled]="
        SearchFilterData && SearchFilterData[columns.field][0] && SearchFilterData[columns.field][0].length === 0
      "
      cc-btn-secondary
      class="applyChangesBtn"
      (click)="applyFilters()"
    >
      <span> Apply Changes </span>
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <cc-icon class="drilldown-header__back" (click)="closeModal()">back-arrow</cc-icon>
  <span class="cc-drawer__ellipsis filters-title"> {{ columns.label }} </span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeAllModal()">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
