<div class="modalBody">
  <div class="cc-modal">
    <div class="cc-modal-header">
      <div class="cc-modal-title">{{ data.title }}</div>
    </div>
    <div class="cc-modal-content cookies-modal">
      <div>
        <p><strong>Use of Cookies by the Dealer Services Portal Website</strong></p>
        <p>
          This website uses cookies. Cookies are small files that are placed on your computer and
          retrieved by the website in order to make this website work more efficiently and to better
          enable processing of information.
        </p>
        <p>The table below explains what cookies are set and used by this website and why:</p>
        <table>
          <tr>
            <th>Cookie</th>
            <th>Name</th>
            <th>Purpose</th>
          </tr>
          <tr>
            <td>Llogin Account</td>
            <td>
              <ul>
                <li>SSOLogin</li>
                <li>SSOCookie</li>
              </ul>
            </td>
            <td>
              These cookies are necessary for the login to this site to work properly. They are used
              to save information about the account used to log onto this website. They can also
              save you the time and effort from logging into other related sites when you visit
              those sites during the same browsing session
            </td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>
              <p>ga</p>
              <p>_gid</p>
              <p>_gat</p>
            </td>
            <td>
              These cookies are used to collect information about how visitors use this site. These
              cookies collect information in an anonymous form. This includes the number of visitors
              to the site, where visitors came from, and the pages they visited on this site. We use
              this information to help us to improve this site.
              https://policies.google.com/technologies/cookies?hl =en
            </td>
          </tr>
          <tr>
            <td>Citrix - NetScaler *</td>
            <td>
              <p>citrix_ns_id</p>
              <p>citrix_ns_id_.cat.com_%2F_w at</p>
            </td>
            <td>
              This cookie is used to secure the application and to optimize load on the server.
              <a href="https://support.citrix.com/article/CTX131488" target="_blank"
                >https://support.citrix.com/article/CTX131488</a
              >
            </td>
          </tr>
          <tr>
            <td>DSP</td>
            <td>JSESSIONID</td>
            <td>
              This cookie gives visitor a unique ID throughout the session and preserves visitor's
              session state across page requests. It does not hold any personally identifiable
              information.
            </td>
          </tr>
          <tr>
            <td>Azure</td>
            <td>ARRAffinity</td>
            <td>
              This cookie is used by our hosting provider to make sure the visitor is routed to the
              correct website on the server.
              <a target="_blank" href="https://privacy.microsoft.com/enUS/privacystatement"
                >https://privacy.microsoft.com/enUS/privacystatement</a
              >
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjClosedSurveyInvites</td>
            <td>
              Hotjar cookie that is set once a visitor interacts with an External Link Survey
              invitation modal. It is used to ensure that the same invite does not reappear if it
              has already been shown.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjDonePolls</td>
            <td>
              Hotjar cookie that is set once a visitor completes a survey using the On-site Survey
              widget. It is used to ensure that the same survey does not reappear if it has already
              been filled in.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjMinimizedPolls</td>
            <td>
              Hotjar cookie that is set once a visitor minimizes an On-site Survey widget. It is
              used to ensure that the widget stays minimized when the visitor navigates through your
              site.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjShownFeedbackMessage</td>
            <td>
              Hotjar cookie that is set when a visitor minimizes or completes Incoming Feedback.
              This is done so that the Incoming Feedback will load as minimized immediately if the
              visitor navigates to another page where it is set to show.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjid</td>
            <td>
              Hotjar cookie that is set when the customer first lands on a page with the Hotjar
              script. It is used to persist the Hotjar User ID, unique to that site on the browser.
              This ensures that behavior in subsequent visits to the same site will be attributed to
              the same user ID.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjRecordingLastActivity</td>
            <td>
              This should be found in Session storage (as opposed to cookies). This gets updated
              when a visitor recording starts and when data is sent through the WebSocket (the
              visitor performs an action that Hotjar records).
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjTLDTest</td>
            <td>
              When the Hotjar script executes we try to determine the most generic cookie path we
              should use, instead of the page hostname. This is done so that cookies can be shared
              across subdomains (where applicable). To determine this, we try to store the
              _hjTLDTest cookie for different URL substring alternatives until it fails. After this
              check, the cookie is removed.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjUserAttributesHash</td>
            <td>
              User Attributes sent through the Hotjar Identify API are cached for the duration of
              the session in order to know when an attribute has changed and needs to be updated.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjCachedUserAttributes</td>
            <td>
              This cookie stores User Attributes which are sent through the Hotjar Identify API,
              whenever the user is not in the sample. These attributes will only be saved if the
              user interacts with a Hotjar Feedback tool.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjLocalStorageTest</td>
            <td>
              This cookie is used to check if the Hotjar Tracking Script can use local storage. If
              it can, a value of 1 is set in this cookie. The data stored in_hjLocalStorageTest has
              no expiration time, but it is deleted almost immediately after it is created.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>hjIncludedInPageviewSample</td>
            <td>
              This cookie is set to let Hotjar know whether that visitor is included in the data
              sampling defined by your site's pageview limit.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjIncludedInSessionSample</td>
            <td>
              This cookie is set to let Hotjar know whether that visitor is included in the data
              sampling defined by your site's daily session limit.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjAbsoluteSessionInProgress</td>
            <td>
              This cookie is used to detect the first pageview session of a user. This is a
              True/False flag set by the cookie.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjFirstSeen</td>
            <td>
              This is set to identify a new user’s first session. It stores a true/false value,
              indicating whether this was the first time Hotjar saw this user. It is used by
              Recording filters to identify new user sessions.
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>hjViewportId</td>
            <td>This stores information about the user viewport such as size and dimensions.</td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>_hjRecordingEnabled</td>
            <td>
              This is added when a Recording starts and is read when the recording module is
              initialized to see if the user is already in a recording in a particular session.
            </td>
          </tr>
        </table>
        <p>
          Many web browsers allow for controlling whether or not cookies can be set or retrieved
          through the browser’s settings. To learn more about cookies, please visit
          www.aboutcookies.org or www.allaboutcookies.org.
        </p>
      </div>
    </div>
    <div class="cc-modal-footer">
      <button cc-btn-primary (click)="onClickClose()" [setTheme]="true">Close</button>
    </div>
  </div>
</div>
