import { AfterViewInit, Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { CC_MODAL_DATA, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { ccDonutSize } from '@cat-digital-workspace/shared-charts';
import { Router } from '@angular/router';
@Component({
  selector: 'dsp-next-gen-ui-connectivity-summary-expanded-view',
  templateUrl: './connectivity-summary-expanded-view.component.html',
  styleUrls: ['./connectivity-summary-expanded-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectivitySummaryExpandedViewComponent implements OnInit, AfterViewInit {
  el: any;
  nodata: boolean = false;

  donutSizeOption: ccDonutSize = 'mediumLarge';
  constructor(
    @Inject(CC_MODAL_DATA) public data: any,
    private renderer: Renderer2,
    private router: Router,

    private modalRef: CcModalRef<ConnectivitySummaryExpandedViewComponent>
  ) {}
  // onClickClose() {
  //   this.modalRef.close();
  // }
  onNoClick() {
    this.modalRef.close();
  }
  ngOnInit(): void {
    if (!this.data?.chartData?.series[0]?.data[0]?.name) {
      this.nodata = true;
    } else {
      this.nodata = false;
    }
    this.data.config.height = '30%';
  }
  ngAfterViewInit() {
    // const div = this.renderer.createElement('div');
    // const text = this.renderer.createText('Total');
    // this.el = document.getElementsByClassName('highcharts-title')[3];
    // this.renderer.appendChild(div, text);
    // if (this.el && this.el.childNodes && this.el.childNodes.length)
    //   this.renderer.insertBefore(this.el, div, this.el.childNodes[0]);
  }
  connectivityChartClick(event: any) {
    if (event.point.name === 'Available for subscription') {
      sessionStorage.setItem('reportingState', JSON.stringify({ reportingState: ['Unsubscribed'] }));
    } else {
      sessionStorage.setItem('reportingState', JSON.stringify({ reportingState: [event.point.name] }));
    }
    this.router.navigate(['/asset']);
    const element: HTMLElement = document.getElementsByClassName('arrow-position')[0] as HTMLElement;
    element.click();
    this.modalRef.close();
  }
}
