<div id="mainFilterCls" class="cc-drawer">
  <div
    class="cc-drawer__header"
    [ngClass]="{
      'cc-drawer__withActions': headerContent === 'withActions'
    }"
  >
    <span class="cc-drawer__ellipsis filters-title"> Filters </span>
    <span class="filters-clear" (click)="clearFilters()">Clear All</span>
    <div class="cc-drawer__headerAction">
      <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal(false)">
        <cc-icon fontSet="icomoon">cross</cc-icon>
      </div>
    </div>
  </div>
  <div class="cc-drawer__content filters-drawer">
    <div *ngIf="filterData.length === 0 || !filterData" class="no-results-found">
      <section class="img-container">
        <div class="img-container__img">
          <img alt="sourceimage" class="no-instance-image" src="../../../assets/images/nofilter.svg " />
        </div>
        <p class="no-results-text">No Filters Available</p>
      </section>
    </div>
    <div class="filter-checkbox-label" *ngFor="let option of filterData">
      <cc-checkbox [checked]="option.checked" (toggleChange)="selectedFilter($event, option)">{{
        option.label
      }}</cc-checkbox>
    </div>
  </div>
  <div class="cc-drawer__footer">
    <button
      cc-btn-primary
      cc-btn-large
      style="width: 100% !important"
      class="primary button-opacity chart-apply-btn"
      [setTheme]="true"
      
      (click)="drawChart()"
    >
      Apply
    </button>
  </div>
</div>
<ng-template #oneLineHeader>
  <span class="cc-drawer__ellipsis filters-title"> Filters </span>
  <span class="filters-clear" (click)="clearFilters()">Clear All</span>
  <div class="cc-drawer__headerAction">
    <div class="cc-drawer__close cc-close-icon filters-close" (click)="closeModal(false)">
      <cc-icon fontSet="icomoon">cross</cc-icon>
    </div>
  </div>
</ng-template>
