<div
  cc-tooltip
  [template]="template5"
  [innerHTML]="getColumnData() | highlightSearch: searchString"
  [ngClass]="{
    'billing-tooltip': detailArray.indexOf(columnWithHeader.name) !== -1 || columnWithHeader.name === 'subscriptionName'
  }"
  class="detailed-invoice-asset"
  [tooltipPosition]="
    detailArray.indexOf(columnWithHeader.name) !== -1
      ? columnWithHeader.name === 'lineItemTotal'
        ? 'left'
        : 'bottom'
      : columnWithHeader.name === 'subscriptionName'
      ? 'right'
      : 'top'
  "
  [isEnableTooltioOnEllipsis]="true"
  (click)="navigateDetail($event)"
></div>
<ng-template #template5>
  <div style="width: 134px; white-space: normal">
    {{ rowData && rowData[columnWithHeader.name] ? rowData[columnWithHeader.name] : '' }}
  </div>
</ng-template>
