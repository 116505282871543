import { Action, createReducer, on } from '@ngrx/store';
import * as UserInfoActionTypes from '../actions/user-info.actions';
import { UserState, initialUserState, DealerListState, initialDealerListState } from '../state/user-info.state';

export const userReducer = createReducer(
    initialUserState, 
    on(UserInfoActionTypes.loadUserRequestAction, (state)=> ({...state, isLoading:true})),
    on(UserInfoActionTypes.loadUserSuccessAction, (state, result) => ({
        ...state,
        user: result.user, 
        isLoading: false, 
        isLoadingSuccess: true
    })),
    on(UserInfoActionTypes.loadUserFailureAction, (state, error)=> ({
        ...state, 
        isLoading:false,
        isLoadingFailure: true,
        error: error
    })), 
    on(UserInfoActionTypes.loadUserMenuRequestAction, (state)=> ({...state, isLoading:true})),
    on(UserInfoActionTypes.loadUserMenuSuccessAction, (state, result) => ({
        ...state,
        menu: result.menu, 
        isLoading: false, 
        isLoadingSuccess: true
    })),
    on(UserInfoActionTypes.loadUserMenuFailureAction, (state, error)=> ({
        ...state, 
        isLoading:false,
        isLoadingFailure: true,
        error: error
    })),
);

export function UserDetailReducer(state: UserState | undefined, action: Action): any {
  return userReducer(state, action);
}

export const dealerReducer = createReducer(
    initialDealerListState, 
    on(UserInfoActionTypes.loadDealerRequestAction, (state)=> ({...state, isLoading:true})),
    on(UserInfoActionTypes.loadDealerSuccessAction, (state, result) => ({
        ...state,
        dealer: result.dealer, 
        isLoading: false, 
        isLoadingSuccess: true
    })),
    on(UserInfoActionTypes.loadDealerFailureAction, (state, error)=> ({
        ...state, 
        isLoading:false,
        isLoadingFailure: true,
        error: error
    })),
);

export function DealerDetailReducer(state: DealerListState | undefined, action: Action): any {
  return dealerReducer(state, action);
}