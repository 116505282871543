<div class="asset-select-ownership" *ngIf="assetOwners?.length > 0">
  <div class="chips-group" id="ownership-select">
    <div class="chips-align">
      <div class="chips-group">
        <span class="lighter-weight"
          >Select the current asset owner:
          <span *ngIf="enableSyncOwnership()" cc-tooltip="Sync Ownership" (click)="initiateSync()">
            <svg xmlns="http://www.w3.org/2000/svg" width="15px" fill="#0078d6" viewBox="0 0 512 512">
              <path
                d="M440.7 12.6l4 82.8A247.2 247.2 0 0 0 255.8 8C134.7 8 33.9 94.9 12.3 209.8A12 12 0 0 0 24.1 224h49.1a12 12 0 0 0 11.7-9.3 175.9 175.9 0 0 1 317-56.9l-101.5-4.9a12 12 0 0 0 -12.6 12v47.4a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0 -12-12h-47.4a12 12 0 0 0 -12 12.6zM255.8 432a175.6 175.6 0 0 1 -146-77.8l101.8 4.9a12 12 0 0 0 12.6-12v-47.4a12 12 0 0 0 -12-12H12a12 12 0 0 0 -12 12V500a12 12 0 0 0 12 12h47.4a12 12 0 0 0 12-12.6l-4.2-82.6A247.2 247.2 0 0 0 255.8 504c121.1 0 221.9-86.9 243.6-201.8a12 12 0 0 0 -11.8-14.2h-49.1a12 12 0 0 0 -11.7 9.3A175.9 175.9 0 0 1 255.8 432z"
              />
            </svg>
          </span>
        </span>
        <div class="chips-align">
          <div class="chip-flexbox">
            <!-- Commented as a part of Converting EI/VIMS to Single Level Plans Feature.
            <div class="chip-flex-item chip-radio chip-none">
              <span
                id="chip23"
                cc-chip="None"
                type="check"
                (selected)="valueChange('None')"
                [radio]="true"
                description="I won't be subscribing at a customer level"
                [state]="rowData?.isNoneSelected ? 'active' : 'default'"
              ></span>
            </div> -->
            <div class="chip-flex-item chip-radio" *ngFor="let owner of assetOwners">
              <span
                id="chip24"
                [toolTipInteractive]="true"
                [toolTipCloseOnClick]="true"
                cc-tooltip="Customer"
                [tooltipPosition]="'top'"
                [template]="template2"
                cc-chip="Customer: {{ checkCustomerDataLength(owner.ucid, owner.ucidName) }}"
                type="check"
                (selected)="valueChange(owner)"
                [radio]="true"
                description="{{ checkDealerDataLength(owner.dealerCustNo, owner.dealerCustName) }}"
                [state]="owner.checked ? 'active' : 'default'"
                [disabled]="owner.disabled"
                [ngClass]="checkDealerIsNotEmpty(owner) ? '' : 'align-chip-field'"
              ></span>
              <ng-template #template2>
                <div class="ownership-text" style="width: 275px; white-space: normal">
                  <div>
                    <span>Customer: </span>
                    <span>{{ owner.ucid + ' - ' + owner.ucidName }}<br /></span>
                  </div>
                  <div *ngIf="checkDealerIsNotEmpty(owner)">
                    <span>Dealer Customer: </span>
                    <span>{{ owner.dealerCustNo + ' - ' + owner.dealerCustName }}<br /></span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="enableEquipmentData" class="ownership-update">
    <a [setTheme]="true" href="{{ edUrl }}" target="_blank" *ngIf="!replaceEDUrl"
      >Update Ownership in Equipment Data
    </a>
    <a [setTheme]="true" href="{{ edUrl }}" target="_blank" *ngIf="replaceEDUrl">Update Ownership </a>
  </div>
</div>
<div class="asset-select-ownership no-ownership" *ngIf="assetOwners?.length === 0">
  <div class="no-owner-image-manage-plan">
    <img src="..\assets\images\no-ownership.svg" />
  </div>
  <div class="addownertext-manage-plan">
    <div class="addownertext-manage-plan">
      Add Owner
      <span *ngIf="enableSyncOwnership()" cc-tooltip="Sync Ownership" (click)="initiateSync()">
        <svg xmlns="http://www.w3.org/2000/svg" width="15px" fill="#0078d6" viewBox="0 0 512 512">
          <path
            d="M440.7 12.6l4 82.8A247.2 247.2 0 0 0 255.8 8C134.7 8 33.9 94.9 12.3 209.8A12 12 0 0 0 24.1 224h49.1a12 12 0 0 0 11.7-9.3 175.9 175.9 0 0 1 317-56.9l-101.5-4.9a12 12 0 0 0 -12.6 12v47.4a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0 -12-12h-47.4a12 12 0 0 0 -12 12.6zM255.8 432a175.6 175.6 0 0 1 -146-77.8l101.8 4.9a12 12 0 0 0 12.6-12v-47.4a12 12 0 0 0 -12-12H12a12 12 0 0 0 -12 12V500a12 12 0 0 0 12 12h47.4a12 12 0 0 0 12-12.6l-4.2-82.6A247.2 247.2 0 0 0 255.8 504c121.1 0 221.9-86.9 243.6-201.8a12 12 0 0 0 -11.8-14.2h-49.1a12 12 0 0 0 -11.7 9.3A175.9 175.9 0 0 1 255.8 432z"
          />
        </svg>
      </span>
    </div>
    <div class="addcustomertext-manage-plan" *ngIf="!replaceEDUrl">Please add customer in Equipment Data</div>
    <div class="addcustomertext-manage-plan" *ngIf="replaceEDUrl">Please add ownership information</div>
    <div class="no-ownership-update">
      <a [setTheme]="true" href="{{ edUrl }}" target="_blank" *ngIf="!replaceEDUrl"
        >Update Ownership in Equipment Data
      </a>
      <a [setTheme]="true" href="{{ edUrl }}" target="_blank" *ngIf="replaceEDUrl">Update Ownership </a>
    </div>
  </div>
</div>
