<div class="nr-chips-container">
  <span
    class="nr-chip"
    *ngFor="let chip of _dataSource; let i = index; trackBy: trackByValue"
    [cc-chip]="chip.label"
    type="check"
    [radio]="true"
    [state]="selected[i]"
    [ngClass]="selected[i] === 'active' ? 'chip-field' : ''"
    (selected)="changeSelected($event, chip.value, i)"
  ></span>
</div>
