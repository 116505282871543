import { Injectable } from '@angular/core';

export class GlobalEnvAppConfig {
  readonly apiURL: any;
  readonly socDealerPartsUrl: any;
  readonly eCommerceUrl: any;
  readonly emissionsApiUrl: any;
  readonly sisApiUrl: any;
  readonly labReportDeepUrl: any;
  readonly marketingURL: any;
  readonly telemetryInvoicesUrl: any;
}

class EnvAppConfig {
  readonly production!: boolean;
  readonly features!: Map<string, boolean>;
  readonly sideNavRoutes!: Map<string, object>;
  readonly apiURL!: string;
  readonly marketingURL!: string;
  readonly pollingRetryIntervalInMS!: number;
  readonly pollingDurationInMS!: number;
  readonly msalAngularConfig!: {
    popup: boolean;
    consentScopes: string[];
  };
  readonly apiGatewayURL!: string;
  readonly logType!: Array<string>;
  readonly sisApiUrl!: string;
  readonly labReportDeepUrl!: string;
  readonly googleMapsApiKey!: string;
  readonly gtmConfig!: {
    id: string;
    gtm_auth?: string;
    gtm_preview?: string;
  };
  readonly catInspectWebUrl!: string;
  readonly applicationId!: string;
  readonly socDealerPartsUrl!: string;
  readonly pccRedirectURL!: string;
  readonly eCommerceUrl!: string;
  readonly billTrustUrl!: string;
  readonly appInitTimeOut!: string;
  readonly emissionsApiUrl!: string;
  readonly sis2DeepUrl!: string;
  readonly iiqLoginUrl!: string;
  readonly iiqAccessRequestUrl!: string;
  readonly iiqRequestStatusUrl!: string;
  readonly aemRedirectUrl!: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService extends GlobalEnvAppConfig {
  private appConfig: any;

  constructor() {
    super();
    /* istanbul ignore next */
    // if (window['tempConfigStorage']) {
    //   this.loadConfig(window['tempConfigStorage']);
    // }
    // if (window['tempFeatureFlagStorage']) {
    //   this.loadFeatureConfig(window['tempFeatureFlagStorage']);
    // }
  }

  loadFeatureConfig(config: object) {
    this.appConfig.features = config;
  }

  loadConfig = (featureConfig: object) => {
    this.appConfig = featureConfig;
  };

  isProduction = () => this.appConfig.production;

  debounceTime = () => this.appConfig.debounceTime;

  cwsEndpoint = () => this.appConfig.cwsEndpoint;

  oauthKeys = () => this.appConfig.oauthKeys;

  thirdPartyUrl = () => this.appConfig.thirdPartyApiUrl;

  accuWeatherApiUrl = () => this.appConfig.accuWeatherApiUrl;

  labReportUrl = () => this.appConfig.labReportDeepUrl;

  features = () => this.appConfig.features;

  sideNavRoutes = () => this.appConfig.sideNavRoutes;

  pollingRetryIntervalInMS = () => this.appConfig.pollingRetryIntervalInMS;

  pollingDurationInMS = () => this.appConfig.pollingDurationInMS;

  getScopes = () => this.appConfig.msalAngularConfig.consentScopes;

  apiGatewayURL = () => this.appConfig.apiGatewayURL;

  logType = () => this.appConfig.logType;

  googleMapsApiKey = () => this.appConfig.googleMapsApiKey;

  fileLimit = () => this.appConfig.fileLimit;

  tocVersion = () => this.appConfig.tocVersion;

  cookieVersion = () => this.appConfig.cookieVersion;

  msalConfig = () => this.appConfig.msalConfig;

  gtmConfig = () => this.appConfig.gtmConfig;

  catInspectWebUrl = () => this.appConfig.catInspectWebUrl;

  getApplicationId = () => this.appConfig.applicationId;

  graphQLUrl = () => this.appConfig.graphQLURL;

  appName = () => this.appConfig.appName;

  pccRedirectUrl = () => this.appConfig.pccRedirectURL;

  b2cStorageConfig = () => this.appConfig.b2cStorageConfig;

  billTrustUrl = () => this.appConfig.billTrustUrl;

  nativeIonicAuthOptions = () => this.appConfig.nativeIonicAuthOptions;

  webIonicAuthOptions = () => this.appConfig.webIonicAuthOptions;

  biometricDiscoveryUrl: () => string = () => this.appConfig.biometricDiscoveryUrl;

  registerConfig = () => this.appConfig.registerConfig;

  appInitTimeOut = () => this.appConfig.appInitTimeOut;

  sis2SearchUrl = () => this.appConfig.sis2SearchUrl;

  sis2DetailUrl = () => this.appConfig.sis2DetailUrl;

  iiqLoginUrl = () => this.appConfig.iiqLoginUrl;

  iiqAccessRequestUrl = () => this.appConfig.iiqAccessRequestUrl;

  iiqRequestStatusUrl = () => this.appConfig.iiqRequestStatusUrl;

  aemRedirectUrl = () => this.appConfig.aemRedirectUrl;

  helpSupportUrl = () => this.appConfig.helpSupportUrl;
}
