import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { siteData } from '../sites-interface';
import { DspCommonService } from '../../../services/dsp-common.service';
import { CancelSiteDrawerComponent } from '../cancel-site-drawer/cancel-site-drawer.component';
import { EditSiteComponent } from '../edit-site/edit-site.component';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DrawerService, CcDrawerConfig, CcDrawerSize } from '@cat-digital-workspace/shared-ui-core/drawer';
import { isNil, filter } from 'lodash-es';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { PopupData } from '../../../models/popup.interface';
import * as dspConstants from '../../../shared/dspConstants';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
@Component({
  selector: 'dsp-next-gen-ui-site-action-menus',
  templateUrl: './site-action-menus.component.html',
  styleUrls: ['./site-action-menus.component.scss'],
})
export class SiteActionMenusComponent {
  @Input() siteInfo!: siteData;
  @Input() b2cCustomers: any;
  @Input() mineStarDetails: any;
  @Output() fetchSitesCallback = new EventEmitter();
  @Output() fetchSitesCountCallback = new EventEmitter();

  @ViewChild('deleteSiteTemplateRef') deleteSiteWarningModal: any;

  deleteSiteModalRef!: CcModalRef<any, any>;
  multiSizeDrawerRef: any;
  editSiteDrwerRef: any;
  isCommonPopup = false;
  deleteSiteNewModalRef: any;
  constructor(
    private modal: CcModal,
    private apiService: DspCommonService,
    private drawerService: DrawerService,
    private messageBar: MessageBar,
    private store: Store<DSPAppState>
  ) {}
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.isCommonPopup = dsp?.featureFlagInfo?.isCommonPopup ? dsp.featureFlagInfo?.isCommonPopup : false;
    });
  }
  handleEditSiteOption() {
    this.editSiteDrwerRef = this.drawerService.openDrawer<EditSiteComponent, CcDrawerConfig>(EditSiteComponent, {
      data: {
        headerType: 'drawerContent',
        apply: this.applyFromFooter.bind(this),
        siteInfo: this.siteInfo,
        b2cCustomers: this.b2cCustomers,
        mineStarDetails: this.mineStarDetails,
      },
      enableBlackBackDrop: true,
      id: 'multiSizeDrawer',
      disableBackdropClick: true,
      drawerSize: CcDrawerSize.LARGE,
    });
    this.editSiteDrwerRef.afterClosed().subscribe((response: boolean) => {
      if (response) {
        this.fetchSitesCountCallback.emit();
        this.fetchSitesCallback.emit();
      }
    });
  }

  handleDeleteSiteOption() {
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.CONFIRM_POPUP_TITLE,
        messageArray: [dspConstants.Common.DELETE_SITE_POPUP_BODY_LINE1],
        showCrossMark: false,
        showPrimaryButton: false,
        showSecondaryButton: true,
        showDangerButton: true,
        primaryButtonText: dspConstants.PopupButtons.SUBMIT,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
        dangerButtonText: dspConstants.PopupButtons.DELETE,
      };

      this.deleteSiteNewModalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '466px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.deleteSiteNewModalRef.afterClosed().subscribe((res: any) => {
        if (res === dspConstants.PopupButtons.DELETE) {
          this.handleDeleteSite(this.siteInfo);
        }
      });
    } else {
      this.deleteSiteModalRef = this.modal.openModal(this.deleteSiteWarningModal, {
        width: '466px',
        type: 'semi-modal',
        isAutoHeightModalContent: true,
      });
    }
  }

  handleCancelPlanOption(siteInfo: siteData) {
    this.multiSizeDrawerRef = this.drawerService.openDrawer<CancelSiteDrawerComponent, CcDrawerConfig>(
      CancelSiteDrawerComponent,
      {
        data: {
          headerType: 'drawerContent',
          apply: this.applyFromFooter.bind(this),
          siteInfo: siteInfo,
          mineStarDetails: this.mineStarDetails,
          fetchSites: () => this.fetchSitesCallback.emit(),
        },
        enableBlackBackDrop: true,
        id: 'multiSizeDrawer',
        disableBackdropClick: true,
        drawerSize: CcDrawerSize.LARGE,
      }
    );
    this.multiSizeDrawerRef.afterClosed().subscribe((response: any) => {
      if (response) {
        this.fetchSitesCountCallback.emit();
        this.fetchSitesCallback.emit();
      }
    });
  }
  closeDeleteSiteModal() {
    if (!this.isCommonPopup) {
      this.deleteSiteModalRef.close();
    }
  }

  onCancel() {
    this.closeDeleteSiteModal();
  }

  handleDeleteSite(siteInfo: siteData) {
    const deleteSiteId: string = siteInfo.guid || siteInfo.subscriptionId;
    this.apiService.deleteSite(deleteSiteId).subscribe({
      next: (_res) => {
        this.closeDeleteSiteModal();
        this.siteDeleteSuccessMessage();
        this.fetchSitesCountCallback.emit();
        this.fetchSitesCallback.emit();
      },
      error: (err) => {
        this.closeDeleteSiteModal();
        if (err.status === 202 || err.status === 200) {
          this.siteDeleteSuccessMessage();
          this.fetchSitesCountCallback.emit();
          this.fetchSitesCallback.emit();
        } else {
          this.siteDeleteFailureMessage();
        }
      },
    });
  }

  siteDeleteSuccessMessage() {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    const successMessage = 'Site deleted successfully.';
    this.messageBar.open(successMessage, 'success', undefined, config, true);
  }

  siteDeleteFailureMessage() {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    const failureMessage = 'There was error processing your request. Please contact product support.';
    this.messageBar.open(failureMessage, 'error', undefined, config, true);
  }

  applyFromFooter(Item: any) {
    this.multiSizeDrawerRef.close(Item);
  }
}
