import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetSubscriptionFormDataType } from 'apps/dsp-ui/src/app/models/assets.interface';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { findIndex, isEmpty, isNil } from 'lodash-es';
import { CatGradeInlineEditComponentComponent } from '../cat-grade-inline-edit-component/cat-grade-inline-edit-component.component';
import { AssetDrawerService } from 'apps/dsp-ui/src/app/services/asset-drawer.service';
import { CatGradeExportComponent } from '../cat-grade-export/cat-grade-export.component';
import { AssetService } from '../../services/asset.service';
import { LicenseInfoHeaderComponent } from '../license-info-header/license-info-header.component';
import { LicenseInfoComponent } from '../license-info/license-info.component';
import { fetchLoggedInDealerDetails } from 'apps/dsp-ui/src/app/shared/shared';
import * as dspConstants from '../../.././../shared/dspConstants';
import { environment } from 'apps/dsp-ui/src/environments/environment';

@Component({
  selector: 'dsp-next-gen-ui-cat-grade-edit-serialnumber',
  templateUrl: './cat-grade-edit-serialnumber.component.html',
  styleUrls: ['./cat-grade-edit-serialnumber.component.scss'],
})
export class CatGradeEditSerialnumberComponent implements AfterViewInit, OnInit {
  @Input() rowData: any;
  @Input() selectedAssetSubscriptionFormData!: AssetSubscriptionFormDataType;
  isControllerStatusSuccess = false;
  catGradeTableData!: any[];
  componentObj!: any[];
  catGradeEditTableConfig = {
    columnConfig: [
      {
        name: 'componentType',
        title: 'Component',
        width: 100,
        formControl: 'span',
      },
      {
        name: 'ecmSerialNumber',
        title: 'Serial Number',
        width: 170,
        component: CatGradeInlineEditComponentComponent,
      },
      {
        name: 'currentLicenseDesc',
        title: 'Current License',
        width: 250,
        formControl: 'span',
      },
      {
        name: 'licenseStatus',
        title: 'License Request Status',
        width: 150,
        component: LicenseInfoComponent,
        headerComponent: LicenseInfoHeaderComponent,
      },
      {
        width: 50,
        component: CatGradeExportComponent,
        name: 'export',
        title: '',
      },
      {
        name: 'id',
        title: '',
      },
    ],
  };
  techLevel: any;
  isBackBtnClciked = false;
  apiResponseTableData!: any[];
  skeletonTableData!: any[];
  dealer: any;
  loader = false;
  dspConfig: any;
  userActiveFeatures: any;
  dspStoreData: any;
  isCatgradeHeliosSyncEnabled = false;
  dspWorklistConstants = dspConstants.Worklist;
  isLicenseMappingEnabled: any;
  productFamilyCode: any;
  isDemoEligibleDealer = false;
  dspHelpUrl: any;
  constructor(
    private store: Store<DSPAppState>,
    private assetDrawerService: AssetDrawerService,
    public assetService: AssetService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dspHelpUrl = environment.redirectURL.helpDocUrl + '?language=en_US';
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.isCatgradeHeliosSyncEnabled = this.dspStoreData?.featureFlagInfo?.isCatgradeHeliosSyncEnabled
        ? this.dspStoreData?.featureFlagInfo?.isCatgradeHeliosSyncEnabled
        : false;
      this.isLicenseMappingEnabled = this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        ? this.dspStoreData?.featureFlagInfo?.isLicenseMappingEnabled
        : false;
      this.isControllerStatusSuccess = true;
    });
    this.assetDrawerService.rowDataChange.subscribe((data) => {
      if (!data) {
        this.catGradeTableData.forEach((item) => {
          item.isDuplicate = false;
        });
      }
    });
  }

  getCatGradeProductFamilyDetails() {
    const productFamilyObj = {
      serialNumber: this.rowData.serialNumber,
      make: this.rowData.make,
    };
    this.assetDrawerService.getCatGradeProductFamilyDetails(productFamilyObj).subscribe({
      next: (res: any) => {
        this.assetDrawerService.productFamilyCode.next(res?.code);
        this.productFamilyCode = res?.code;
        const catGradeObj = {
          make: this.rowData?.make,
          serialNumber: this.rowData?.serialNumber,
          productFamily: this.productFamilyCode,
        };
        this.assetDrawerService.getGradeControlResponse(catGradeObj).subscribe({
          next: (res: any) => {
            this.ngZone.run(() => {
              if (res && res.length) {
                this.loader = false;
                this.apiResponseTableData = res;
                this.updateApiResponseTableData(res);
                //Call helios api from UI side when iscatgradeheliossyncEnabled is false
                if (!this.isCatgradeHeliosSyncEnabled) this.getHeliosUpdatedLicenseStatus();
                else {
                  this.componentObj = this.apiResponseTableData?.map((res: any, index: any) => {
                    this.techLevel = res.techLevel;
                    return {
                      index: index,
                      assetSerialNumber: res.assetSerialNumber ? res.assetSerialNumber : this.rowData.serialNumber,
                      componentStatus: res.componentStatus ? res.componentStatus : '-',
                      componentType: res.componentType ? res.componentType : '-',
                      currentLicense: res.currentLicense ? res.currentLicense : '-',
                      currentLicenseDesc: res.currentLicenseDesc ? res.currentLicenseDesc : '-',
                      dealerCode: fetchLoggedInDealerDetails().dealerCode,
                      ecmSerialNumber: res.ecmSerialNumber ? res.ecmSerialNumber : '',
                      model: this.rowData?.model,
                      id: res.id ? res.id : '',
                      licenseRequested: res.licenseRequested ? res.licenseRequested : '-',
                      licenseStatus: res.licenseStatus ? res.licenseStatus : '-',
                      parentSerialNumber: res.parentSerialNumber ? res.parentSerialNumber : '-',
                      techLevel: res.techLevel ? res.techLevel : '-',
                      status: false,
                      isDuplicate: false,
                    };
                  });

                  this.catGradeTableData = this.apiResponseTableData;
                  // sessionStorage.setItem('techLevel', this.techLevel);

                  const result = this.catGradeTableData.every((item) => !item.ecmSerialNumber);
                  if (result) {
                    this.selectedAssetSubscriptionFormData.isValidForm = false;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  } else {
                    this.selectedAssetSubscriptionFormData.isValidForm = true;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  }
                  const customerInfo = this.selectedAssetSubscriptionFormData?.customer
                    ? this.selectedAssetSubscriptionFormData?.customer
                    : {};
                  this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
                  this.assetDrawerService.catGradeEditInfo.next({ editInfo: this.componentObj });
                  this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
                  this.emptyColumnDataCheck();
                }
              }
            });
          },
          error: (err: any) => {
            if (err.status === 404 || err.status === 400) {
              this.loader = false;
              this.catGradeTableData = this.skeletonTableData?.map((res: any, index: any) => {
                this.techLevel = res.techLevel;
                return {
                  index: index,
                  assetSerialNumber: res.assetSerialNumber,
                  componentStatus: res.componentStatus,
                  componentType: res.componentType,
                  currentLicense: res.currentLicense,
                  currentLicenseDesc: res.currentLicenseDesc,
                  dealerCode: this.dealer.dealerCode,
                  ecmSerialNumber: res.ecmSerialNumber,
                  model: this.rowData?.model,
                  id: res.id,
                  licenseRequested: res.licenseRequested,
                  licenseStatus: res.licenseStatus,
                  parentSerialNumber: res.parentSerialNumber,
                  techLevel: res.techLevel,
                };
              });
              const customerInfo = this.selectedAssetSubscriptionFormData?.customer
                ? this.selectedAssetSubscriptionFormData?.customer
                : {};
              this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
              this.assetDrawerService.catGradeEditInfo.next({
                editInfo: this.catGradeTableData,
                techLevel: this.techLevel,
              });
              // sessionStorage.setItem('techLevel', this.techLevel);/
              this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
              const result = this.catGradeTableData.every((item) => !item.ecmSerialNumber);
              if (result) {
                this.selectedAssetSubscriptionFormData.isValidForm = false;
                this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              }
              this.emptyColumnDataCheck();
            }
          },
        });
      },
      error: (err: any) => {},
    });
  }
  ngAfterViewInit(): void {
    this.skeletonTableData = [
      {
        assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
        componentStatus: null,
        componentType: 'Grade Control ECM',
        currentLicense: null,
        currentLicenseDesc: null,
        dealerCode: this.dealer ? this.dealer.dealerCode : null,
        ecmSerialNumber: null,
        id: null,
        licenseRequested: null,
        licenseStatus: null,
        parentSerialNumber: null,
        techLevel: null,
        make: this.rowData && this.rowData.make ? this.rowData.make : '',
      },
      {
        assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
        componentStatus: null,
        componentType: 'GNSS Receiver',
        currentLicense: null,
        currentLicenseDesc: null,
        dealerCode: this.dealer ? this.dealer.dealerCode : null,
        ecmSerialNumber: null,
        id: null,
        licenseRequested: null,
        licenseStatus: null,
        parentSerialNumber: null,
        techLevel: null,
        make: this.rowData && this.rowData.make ? this.rowData.make : '',
      },
      {
        assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
        componentStatus: null,
        componentType: 'GNSS Receiver',
        currentLicense: null,
        currentLicenseDesc: null,
        dealerCode: this.dealer ? this.dealer.dealerCode : null,
        ecmSerialNumber: null,
        id: null,
        licenseRequested: null,
        licenseStatus: null,
        parentSerialNumber: null,
        techLevel: null,
        make: this.rowData && this.rowData.make ? this.rowData.make : '',
      },
    ];
    this.dealer = fetchLoggedInDealerDetails();
    this.store.select('dsp').subscribe((dsp) => {
      this.dspConfig = dsp.dspConfig;
      this.userActiveFeatures = dsp.userActiveFeatures?.features || [];
    });
    const customerInfo = this.selectedAssetSubscriptionFormData?.customer
      ? this.selectedAssetSubscriptionFormData?.customer
      : {};

    this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
    // this.assetDrawerService.searchAssetInfo.next(customerInfo);

    this.assetDrawerService.isBackBtnClicked.subscribe((data: any) => {
      this.isBackBtnClciked = data;
    });

    this.assetDrawerService.isRefreshBtnClicked.subscribe((data: any) => {
      if (data) {
        const updatedLicenseStatus = this.catGradeTableData.findIndex(
          (item) => item.ecmSerialNumber === data.serialNumber
        );
        if (updatedLicenseStatus !== -1) {
          this.catGradeTableData[updatedLicenseStatus].licenseStatus = data.status.toUpperCase();
          this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
        }
      }
    });

    this.assetDrawerService.isDemoEligibleDealer.subscribe((data: any) => {
      this.isDemoEligibleDealer = data;
    });

    if (!this.isBackBtnClciked) {
      this.loader = true;
      if (this.isLicenseMappingEnabled) {
        this.getCatGradeProductFamilyDetails();
      } else {
        const catGradeObj = {
          make: this.rowData?.make,
          serialNumber: this.rowData?.serialNumber,
        };
        this.assetDrawerService.getGradeControlResponse(catGradeObj).subscribe({
          next: (res: any) => {
            this.ngZone.run(() => {
              if (res && res.length) {
                this.loader = false;
                this.apiResponseTableData = res;
                this.updateApiResponseTableData(res);
                //Call helios api from UI side when iscatgradeheliossyncEnabled is false
                if (!this.isCatgradeHeliosSyncEnabled) this.getHeliosUpdatedLicenseStatus();
                else {
                  this.componentObj = this.apiResponseTableData?.map((res: any, index: any) => {
                    this.techLevel = res.techLevel;
                    return {
                      index: index,
                      assetSerialNumber: res.assetSerialNumber ? res.assetSerialNumber : this.rowData.serialNumber,
                      componentStatus: res.componentStatus ? res.componentStatus : '-',
                      componentType: res.componentType ? res.componentType : '-',
                      currentLicense: res.currentLicense ? res.currentLicense : '-',
                      currentLicenseDesc: res.currentLicenseDesc ? res.currentLicenseDesc : '-',
                      dealerCode: fetchLoggedInDealerDetails().dealerCode,
                      ecmSerialNumber: res.ecmSerialNumber ? res.ecmSerialNumber : '',
                      model: this.rowData?.model,
                      id: res.id ? res.id : '',
                      licenseRequested: res.licenseRequested ? res.licenseRequested : '-',
                      licenseStatus: res.licenseStatus ? res.licenseStatus : '-',
                      parentSerialNumber: res.parentSerialNumber ? res.parentSerialNumber : '-',
                      techLevel: res.techLevel ? res.techLevel : '-',
                      status: false,
                    };
                  });

                  this.catGradeTableData = this.apiResponseTableData;
                  // sessionStorage.setItem('techLevel', this.techLevel);

                  const result = this.catGradeTableData.every((item) => !item.ecmSerialNumber);
                  if (result) {
                    this.selectedAssetSubscriptionFormData.isValidForm = false;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  } else {
                    this.selectedAssetSubscriptionFormData.isValidForm = true;
                    this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
                  }
                  const customerInfo = this.selectedAssetSubscriptionFormData?.customer
                    ? this.selectedAssetSubscriptionFormData?.customer
                    : {};
                  this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
                  this.assetDrawerService.catGradeEditInfo.next({ editInfo: this.componentObj });
                  this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
                  this.emptyColumnDataCheck();
                }
              }
            });
          },
          error: (err: any) => {
            if (err.status === 404 || err.status === 400) {
              this.loader = false;
              this.catGradeTableData = this.skeletonTableData?.map((res: any, index: any) => {
                this.techLevel = res.techLevel;
                return {
                  index: index,
                  assetSerialNumber: res.assetSerialNumber,
                  componentStatus: res.componentStatus,
                  componentType: res.componentType,
                  currentLicense: res.currentLicense,
                  currentLicenseDesc: res.currentLicenseDesc,
                  dealerCode: this.dealer.dealerCode,
                  ecmSerialNumber: res.ecmSerialNumber,
                  model: this.rowData?.model,
                  id: res.id,
                  licenseRequested: res.licenseRequested,
                  licenseStatus: res.licenseStatus,
                  parentSerialNumber: res.parentSerialNumber,
                  techLevel: res.techLevel,
                };
              });
              const customerInfo = this.selectedAssetSubscriptionFormData?.customer
                ? this.selectedAssetSubscriptionFormData?.customer
                : {};
              this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
              this.assetDrawerService.catGradeEditInfo.next({
                editInfo: this.catGradeTableData,
                techLevel: this.techLevel,
              });
              // sessionStorage.setItem('techLevel', this.techLevel);/
              this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
              const result = this.catGradeTableData.every((item) => !item.ecmSerialNumber);
              if (result) {
                this.selectedAssetSubscriptionFormData.isValidForm = false;
                this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
              }
              this.emptyColumnDataCheck();
            }
          },
        });
      }
    }

    if (this.isBackBtnClciked) {
      this.assetDrawerService.enableNextBtn.next(true);
      const missingComponents: any = [];
      this.assetDrawerService.catGradeEditTableInfo.subscribe((data: any) => {
        this.catGradeTableData = data;
      });

      if (this.catGradeTableData) {
        this.skeletonTableData.forEach((skeletonComponent) => {
          const isComponentMissing = !this.catGradeTableData.some(
            (item) => item.componentType === skeletonComponent.componentType
          );
          if (isComponentMissing) {
            missingComponents.push(skeletonComponent);
          }
        });

        this.catGradeTableData = this.catGradeTableData.concat(missingComponents);
      } else {
        this.catGradeTableData = [
          {
            assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
            componentStatus: null,
            componentType: 'Grade Control ECM',
            currentLicense: null,
            currentLicenseDesc: null,
            dealerCode: this.dealer ? this.dealer.dealerCode : null,
            ecmSerialNumber: null,
            id: null,
            licenseRequested: null,
            licenseStatus: null,
            parentSerialNumber: null,
            techLevel: null,
            make: this.rowData && this.rowData.make ? this.rowData.make : '',
          },
          {
            assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
            componentStatus: null,
            componentType: 'GNSS Receiver',
            currentLicense: null,
            currentLicenseDesc: null,
            dealerCode: this.dealer ? this.dealer.dealerCode : null,
            ecmSerialNumber: null,
            id: null,
            licenseRequested: null,
            licenseStatus: null,
            parentSerialNumber: null,
            techLevel: null,
            make: this.rowData && this.rowData.make ? this.rowData.make : '',
          },
          {
            assetSerialNumber: this.rowData ? this.rowData.serialNumber : null,
            componentStatus: null,
            componentType: 'GNSS Receiver',
            currentLicense: null,
            currentLicenseDesc: null,
            dealerCode: this.dealer ? this.dealer.dealerCode : null,
            ecmSerialNumber: null,
            id: null,
            licenseRequested: null,
            licenseStatus: null,
            parentSerialNumber: null,
            techLevel: null,
            make: this.rowData && this.rowData.make ? this.rowData.make : '',
          },
        ];
        const result = this.catGradeTableData.every((item) => !item.ecmSerialNumber);
        if (result) {
          this.selectedAssetSubscriptionFormData.isValidForm = false;
          this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
        }
      }
      this.emptyColumnDataCheck();
    }
    this.cdr.detectChanges();
  }
  getHeliosUpdatedLicenseStatus() {
    this.isControllerStatusSuccess = false;
    this.apiResponseTableData?.map((res: any, index: any) => {
      if (res?.licenseStatus?.toUpperCase() === this.dspWorklistConstants.SUCCESSFUL) {
        this.isControllerStatusSuccess = true;
      }
    });
    if (this.isControllerStatusSuccess) {
      const catGradeObj = {
        serialNumber: this.rowData?.serialNumber,
        make: this.rowData?.make,
      };

      this.assetDrawerService.getHeliosGradeControlResponse(catGradeObj).subscribe({
        next: (res: any) => {
          const updatedLicenseStatus = res;
          this.apiResponseTableData.map((eachObj: any, index: any) => {
            updatedLicenseStatus.map((updatedObj: any) => {
              if (eachObj.ecmSerialNumber === updatedObj.ecmSerialNumber) {
                return (this.apiResponseTableData[index].licenseStatus = updatedObj.licenseStatus);
              }
            });
          });
          this.setCatGradeTableData();
        },
        error: (error: any) => {
          this.setCatGradeTableData();
        },
      });
    } else {
      this.setCatGradeTableData();
    }
  }
  setCatGradeTableData() {
    this.componentObj = this.apiResponseTableData?.map((res: any, index: any) => {
      this.techLevel = res.techLevel;
      return {
        index: index,
        assetSerialNumber: res.assetSerialNumber ? res.assetSerialNumber : this.rowData.serialNumber,
        componentStatus: res.componentStatus ? res.componentStatus : '-',
        componentType: res.componentType ? res.componentType : '-',
        currentLicense: res.currentLicense ? res.currentLicense : '-',
        currentLicenseDesc: res.currentLicenseDesc ? res.currentLicenseDesc : '-',
        dealerCode: fetchLoggedInDealerDetails().dealerCode,
        ecmSerialNumber: res.ecmSerialNumber ? res.ecmSerialNumber : '',
        model: this.rowData?.model,
        id: res.id ? res.id : '',
        licenseRequested: res.licenseRequested ? res.licenseRequested : '-',
        licenseStatus: res.licenseStatus ? res.licenseStatus : '-',
        parentSerialNumber: res.parentSerialNumber ? res.parentSerialNumber : '-',
        techLevel: res.techLevel ? res.techLevel : '-',
        make: this.rowData && this.rowData.make ? this.rowData.make : '',
      };
    });
    this.catGradeTableData = this.apiResponseTableData;
    // sessionStorage.setItem('techLevel', this.techLevel);

    const result = this.catGradeTableData?.every((item) => !item?.ecmSerialNumber);
    if (result) {
      this.selectedAssetSubscriptionFormData.isValidForm = false;
      this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    } else {
      this.selectedAssetSubscriptionFormData.isValidForm = true;
      this.assetService.setAssetSubscriptionFormData(this.selectedAssetSubscriptionFormData);
    }
    const customerInfo = this.selectedAssetSubscriptionFormData?.customer
      ? this.selectedAssetSubscriptionFormData?.customer
      : {};
    this.assetDrawerService.catGradeCustomerInfo.next(customerInfo);
    this.assetDrawerService.catGradeEditInfo.next({ editInfo: this.componentObj });
    this.assetDrawerService.catGradeEditTableInfo.next(this.catGradeTableData);
    this.emptyColumnDataCheck();
  }
  updateApiResponseTableData(apiResponse: any) {
    const gradeControlExists = this.apiResponseTableData?.some((data) => data.componentType === 'Grade Control ECM');
    const gnssCount = this.apiResponseTableData?.filter((data) => data.componentType === 'GNSS Receiver').length;
    if (gradeControlExists && gnssCount === 2) {
      this.apiResponseTableData = apiResponse;
      return;
    }

    if (gnssCount === 1 && !gradeControlExists) {
      const gnssToAdd = this.skeletonTableData.find((data) => data.componentType === 'GNSS Receiver');
      if (gnssToAdd) {
        this.apiResponseTableData.push(gnssToAdd);
      }
      const gcmToAdd = this.skeletonTableData.find((data) => data.componentType === 'Grade Control ECM');
      if (gcmToAdd) {
        this.apiResponseTableData.push(gcmToAdd);
      }
    } else if (gnssCount < 2 && gradeControlExists) {
      const gnssComponnetsToAdd = this.skeletonTableData
        .filter((data) => data.componentType === 'GNSS Receiver')
        .slice(0, 2 - gnssCount);
      this.apiResponseTableData.push(...gnssComponnetsToAdd);
    } else if (gnssCount === 2 && !gradeControlExists) {
      const gcmToAdd = this.skeletonTableData.find((data) => data.componentType === 'Grade Control ECM');
      if (gcmToAdd) {
        this.apiResponseTableData.push(gcmToAdd);
      }
    }
    return this.apiResponseTableData;
  }

  emptyColumnDataCheck() {
    this.catGradeTableData = this.catGradeTableData.map((res: any, index) => {
      return {
        index: index,
        assetSerialNumber: res.assetSerialNumber ? res.assetSerialNumber : '',
        componentStatus: res.componentStatus ? res.componentStatus : '',
        componentType: res.componentType ? res.componentType : '',
        currentLicense: res.currentLicense ? res.currentLicense : '',
        currentLicenseDesc: res.currentLicenseDesc ? res.currentLicenseDesc : '-',
        dealerCode: fetchLoggedInDealerDetails().dealerCode,
        ecmSerialNumber: res.ecmSerialNumber ? res.ecmSerialNumber : '',
        model: this.rowData?.model,
        id: res.id ? res.id : '',
        licenseRequested: res.licenseRequested ? res.licenseRequested : '',
        licenseStatus: res.licenseStatus ? res.licenseStatus : '-',
        parentSerialNumber: res.parentSerialNumber ? res.parentSerialNumber : '',
        techLevel: res.techLevel ? res.techLevel : '',
        make: this.rowData && this.rowData.make ? this.rowData.make : '',
        isDuplicate: false,
      };
    });
  }

  helpCenterLink() {
    window.open(this.dspHelpUrl, '_blank');
  }
}
