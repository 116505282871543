import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  public errorText =
    'There was an error while processing your request. Please try again after sometime';
  rootURL = '';
  constructor(private http: HttpClient) {
    this.rootURL = environment.redirectURL.apiUrl;
  }

  getQuickLinks() {
    return this.http.get(this.rootURL + '/dashboard/getQuickLinks');
  }

  addQuickLink(body: any) {
    return this.http.post(this.rootURL + '/dashboard/addQuickLink', body);
  }

  deleteQuickLink(id: any) {
    return this.http.delete(this.rootURL + '/dashboard/deleteQuickLink/' + id);
  }
}
