import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { Store } from '@ngrx/store';
import { DSPAppState } from 'apps/dsp-ui/src/app/store/state/dsp.state';
import { UserPreferenceService } from 'apps/dsp-ui/src/app/user-preference.service';

@Component({
  selector: 'dsp-next-gen-ui-plan-payment',
  templateUrl: './plan-payment.component.html',
  styleUrls: ['./plan-payment.component.scss'],
})
export class PlanPaymentComponent implements OnInit {
  rowData: any;
  applicationName = this.data?.applicationName;
  basePlan = this.data.basePlan;
  paymentData: any;
  isNumberConventionEnabled: any;
  seeMoreOverlayDetails: any;
  seemoreOverlayUpdate = false;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeModal();
  }
  constructor(
    public dialogRef: CcDrawerRef<PlanPaymentComponent>,
    private store: Store<DSPAppState>,
    private userPreferenceService: UserPreferenceService,
    @Inject(CC_DRAWER_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.isNumberConventionEnabled = dsp?.featureFlagInfo?.isNumberConventionEnabled
        ? dsp?.featureFlagInfo?.isNumberConventionEnabled
        : false;
      this.seemoreOverlayUpdate = dsp?.featureFlagInfo?.seeMoreOverlayUpdate
        ? dsp.featureFlagInfo.seeMoreOverlayUpdate
        : false;
    });
    this.seeMoreOverlayDetails = this.data.seeMoreOverlayBasicDetails;
    this.paymentData = this.data.rowData?.products || [];
    let i = 0;
    this.paymentData.map((data: any) => {
      if (data.name === this.basePlan && i == 0) {
        i++;
        data.productType = 'Base Plan';
      } else {
        data.productType = 'Optional Plan';
      }
    });
  }
  closeModal() {
    this.dialogRef.close();
  }
  convertNumberFormat(number: any) {
    if (this.isNumberConventionEnabled) {
      return this.userPreferenceService.convertNumberFormat(number, true);
    }
    return number;
  }

  parseAssetNameData(seeMoreOverlayDetails: any) {
    const rowData = seeMoreOverlayDetails;
    let assetInfo = '';
    if (rowData) {
      assetInfo += rowData.make ? rowData.make : '';
      assetInfo += rowData.model ? ' - ' + rowData.model : '';
      assetInfo += rowData.modelYear ? ' - ' + rowData.modelYear : '';
      return assetInfo;
    } else {
      return '';
    }
  }

  getColumnToolTipData() {
    if (this.seeMoreOverlayDetails) {
      return (
        this.getAssetSerialNumber(this.seeMoreOverlayDetails) +
        ' ' +
        this.parseAssetNameData(this.seeMoreOverlayDetails)
      );
    } else {
      return '';
    }
  }

  getAssetSerialNumber(seeMoreOverlayDetails: any) {
    const rowData = seeMoreOverlayDetails;
    let assetSerialNo = '';
    if (rowData) {
      assetSerialNo += rowData.serialNumber;
      assetSerialNo += rowData.engineSerialNumber ? ' | ' + rowData.engineSerialNumber : '';
      return assetSerialNo;
    } else {
      return '';
    }
  }
}
