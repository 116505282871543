export const OVERLAYDATA = [
  {
    name: 'asc',
    title: 'Sort A to Z',
  },
  {
    name: 'dsc',
    title: 'Sort Z to A',
  },
];

export const CUSTOMACCESS = 'CUSTOM ACCESS';
