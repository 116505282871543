import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CC_DRAWER_DATA, CcDrawerRef } from '@cat-digital-workspace/shared-ui-core/drawer';
import { CcModal, CcModalRef } from '@cat-digital-workspace/shared-ui-core/modal';
import { MessageBar, MessageBarConfig } from '@cat-digital-workspace/shared-ui-core/message';
import { SubscribeAccountsService } from '../../../services/subscribe-accounts.service';
import { AccountFormType, PLAN_TEMPLATE, Plan_Template, editPlanPayload } from '../accounts-interface';
import { cloneDeep, isEmpty } from 'lodash-es';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { DSPAppState } from '../../../store/state/dsp.state';
import { getSalesReason } from '../../../store/actions/dspcommon.action';
import { cancelReasonsType } from '../accounts-interface';
import { Common, OrgSubs } from '../../../shared/dspConstants';
import * as dspConstants from '../../../shared/dspConstants';
import { CommonConfirmationPopupComponent } from '../../common-confirmation-popup/common-confirmation-popup.component';
import { PopupData } from '../../../models/popup.interface';

@Component({
  selector: 'dsp-next-gen-ui-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditPlanComponent implements OnInit {
  @ViewChild('licenseChangeTemplate') changeLicenseType!: TemplateRef<any>;
  @ViewChild('removeUsersWarningTemplate') removeUsersWarningTemplate!: TemplateRef<any>;
  @ViewChild('cancelTemplateRef') cancelPlanModalTemplate!: TemplateRef<any>;
  salesData: any = [];
  rowData: any;
  editPayloadObj!: editPlanPayload;
  modalRef!: CcModalRef<any, any>;
  removeUsersModalRef!: CcModalRef<any, any>;
  selectedLicenseType = '';
  selectedData!: AccountFormType;
  selectedCustomerLicenseType: any;
  revertedUsers: any;
  removedUsers: any;
  licenseType: any = [
    {
      label: 'Limited Users',
      value: 'Limited Users',
      disabled: false,
    },
    {
      label: 'Unlimited Users',
      value: 'Unlimited Users',
      disabled: false,
    },
  ];
  finalLicenseType = [];
  accountDetailsForm: FormGroup = new FormGroup({});
  enableCVAfield: boolean = false;
  isListItemSelected = false;
  inputFieldConfig6 = {
    disabled: false,
    labelValue: 'Select',
    width: '355px',
    isLeading: true,
    isTrailing: false,
    ariaDescribedBy: 'Select',
    required: true,
  };
  timeZoneOffset = 660;
  isTwoPoint0 = true;
  dateFormat = 'mmm dd, yyyy';
  setStartDate: any;
  maxStartEndDate!: Date;
  startDateVal: any;
  minStartEndDate!: Date;
  maxEndDate: any;
  endDateVal: any;
  selectedReason = '';
  selectReasonField = false;
  cva = '';
  saleReasonDescription = '';
  prefixSearchHelpText = '';
  formatedDate: any;
  salesList: any = [];
  isTheme = '2.0';
  cvaNumber: any;
  salesDescription: any;
  dspStoreData!: any;
  editPlanConfig: PLAN_TEMPLATE = Plan_Template;
  cancelReasons: cancelReasonsType[] = [];
  subscribeAccountsConst: any;
  isDataAvailable = false;
  isShowNewColumnInAccounts = false;
  isCommonPopup = false;
  accountEditModalRef: any;
  accountCancelModelRef: any;
  accountRemoveUserModelRef: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    this.closeDrawer();
  }
  constructor(
    @Inject(CC_DRAWER_DATA)
    public header: {
      headerType: string;
      rowData: any;
      actionType: any;
    },
    public dialogRef: CcDrawerRef<EditPlanComponent>,
    private formBuilder: FormBuilder,
    private accountsService: SubscribeAccountsService,
    private changeDetectorRef: ChangeDetectorRef,
    public modal: CcModal,
    private messageBar: MessageBar,
    private datepipe: DatePipe,
    private store: Store<DSPAppState>
  ) {
    const selectedDate = new Date();
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();
    const currentDate = selectedDate.getDate();
    this.minStartEndDate = new Date(currentYear, currentMonth, currentDate);
  }
  ngOnInit(): void {
    this.store.select('dsp').subscribe((dsp) => {
      this.dspStoreData = dsp;
      this.cancelReasons = dsp?.accountCancelReasons.accountCancelReasons || [];
      this.isShowNewColumnInAccounts = this.dspStoreData?.featureFlagInfo?.isShowNewColumnInAccounts
        ? this.dspStoreData?.featureFlagInfo?.isShowNewColumnInAccounts
        : false;
      this.isCommonPopup = this.dspStoreData?.featureFlagInfo?.isCommonPopup
        ? this.dspStoreData?.featureFlagInfo?.isCommonPopup
        : false;
    });
    this.subscribeAccountsConst = { ...OrgSubs, ...Common };
    this.rowData = this.header.rowData ? cloneDeep(this.header.rowData) : {};
    this.finalLicenseType = cloneDeep(this.licenseType);
    this.selectedData = {
      product: this.rowData.appName,
      plan: this.rowData && this.rowData?.subsDtl ? this.rowData?.subsDtl?.name : '',
      accountType: this.rowData.type,
      customer: {
        ucid: this.rowData.ucid,
        ucName: this.rowData.ucName,
        dcn: this.rowData.dcn,
        dcName: this.rowData.dcName,
      },
      selectedUsers: !isEmpty(this.rowData.users) ? cloneDeep(this.rowData.users) : [],
      currentUsers: !isEmpty(this.rowData.users) ? this.rowData.users : [],
    };

    if (this.rowData.subsDtl?.name === this.subscribeAccountsConst.SMA) {
      this.selectedData.prefix = this.rowData.assetPrefix ? this.rowData?.assetPrefix : '';
      if (isEmpty(this.dspStoreData?.salesReason)) {
        this.getSalesReason();
      } else {
        delete this.dspStoreData?.salesReason.type;
        this.salesData = Object.values(this.dspStoreData?.salesReason);
      }
    }
    this.revertedUsers = !isEmpty(this.rowData.users) ? cloneDeep(this.rowData.users) : [];
    this.removedUsers = !isEmpty(this.rowData.users) ? cloneDeep(this.rowData.users) : [];
    this.accountDetailsForm = this.formBuilder.group({
      licenseType: new FormControl(''),
      saleReason: [this.rowData.salesReason, [Validators.required]],
      expirydate: [this.rowData.endDate, [Validators.required]],
    });
    this.selectedCustomerLicenseType = this.updateSelectedLicensedType();
    this.selectedData.licenseType = this.selectedCustomerLicenseType;
    this.selectedData.selectReason = this.rowData.salesReason;
    this.rowData.licensedType = cloneDeep(this.selectedCustomerLicenseType);
    this.accountDetailsForm.get('licenseType')?.setValue(this.selectedCustomerLicenseType);
    this.accountDetailsForm.get('saleReason')?.setValue(this.rowData?.salesReason);
    this.validateUcidDcnDetails();
    this.setSalesReasonValue();
    this.editPlanConfig.accountType = this.selectedData?.accountType;
    this.editPlanConfig.actionType = this.header.actionType ? this.header.actionType : '';

    // this.rowData.appName = OrgSubs.SIS_GO;
    // this.rowData.subsDtl.name = 'Service Media Access';
    if (this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_UPDATE) {
      this.editPlanConfig.headerTitle = this.subscribeAccountsConst.EDIT_HEADER;
      if (this.rowData.appName === this.subscribeAccountsConst.MINESTAR_HEI) {
        if (this.selectedCustomerLicenseType === this.subscribeAccountsConst.LU) {
          this.editPlanConfig.searchCws = true;
          this.editPlanConfig.subscriptionNote = false;
          this.editPlanConfig.accountDetails = { type: true };
          this.editPlanConfig.productPlanInfo = { productPlan: true, customerAccount: true };
        } else if (this.selectedCustomerLicenseType === this.subscribeAccountsConst.ULU) {
          this.editPlanConfig.searchCws = false;
          this.editPlanConfig.subscriptionNote = true;
          this.editPlanConfig.accountDetails = { type: true };
          this.editPlanConfig.productPlanInfo = { productPlan: true, dealerCode: true };
        }
      } else {
        this.editPlanConfig.searchCws = true;
        this.editPlanConfig.subscriptionNote = false;
        this.editPlanConfig.accountDetails = { expirationDate: true, saleReason: true };
        this.editPlanConfig.productPlanInfo = {
          productPlan: true,
          prefix: true,
          startDate: true,
          term: true,
          autoRenew: true,
          store: true,
          saleReason: true,
          dealerCode: true,
        };
      }
    } else if (
      this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_CANCEL ||
      this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL
    ) {
      this.editPlanConfig.headerTitle = this.subscribeAccountsConst.CANCEL_HEADER;
      this.editPlanConfig.accountDetails = {};
      this.editPlanConfig.searchCws = false;
      this.editPlanConfig.subscriptionNote = false;
      if (
        this.rowData.appName === this.subscribeAccountsConst.MINESTAR_HEI ||
        (this.rowData.appName === this.subscribeAccountsConst.SIS_GO &&
          this.rowData.subsDtl.name === this.subscribeAccountsConst.SIS_USB)
      ) {
        if (this.selectedData.accountType === Common.CUSTOMER) {
          this.editPlanConfig.productPlanInfo = { productPlan: true, dealerCode: true, ownership: true };
        } else if (this.selectedData.accountType === Common.DEALER) {
          this.editPlanConfig.productPlanInfo = { productPlan: true, dealerCode: true };
        } else if (this.selectedData.accountType === Common.USER) {
          this.editPlanConfig.productPlanInfo = { productPlan: true, dealerCode: true, cwsId: true };
        }
      } else if (
        this.rowData.appName === this.subscribeAccountsConst.SIS_GO &&
        this.rowData.subsDtl.name === this.subscribeAccountsConst.SMA
      ) {
        this.editPlanConfig.productPlanInfo = {
          productPlan: true,
          prefix: true,
          endDate: true,
          term: true,
          autoRenew: true,
          store: true,
          saleReason: true,
          dealerCode: true,
        };
      }
    } else {
      this.editPlanConfig.headerTitle = this.subscribeAccountsConst.VIEW_HEADER;
      this.editPlanConfig.accountDetails = {};
      this.editPlanConfig.searchCws = false;
      this.editPlanConfig.subscriptionNote = false;
      if (this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_VIEW_BY_DEALER) {
        this.editPlanConfig.productPlanInfo = {
          dealerCustomerName: true,
          customerName: true,
          prefix: true,
          productPlan: true,
          cwsId: true,
        };
      } else {
        this.editPlanConfig.productPlanInfo = {
          customerName: true,
          productPlan: true,
          prefix: true,
          cwsId: true,
        };
      }
    }
    this.isDataAvailable = true;
  }

  getSalesReason() {
    this.accountsService.getSalesReason().subscribe(
      (res: any) => {
        if (res) {
          this.salesData = res.map((item: any) => ({
            label: item.reason,
            value: item.id,
          }));
          this.store.dispatch(getSalesReason(this.salesData));
          this.changeDetectorRef.detectChanges();
        }
      },
      (error) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
      }
    );
  }
  setSalesReasonValue() {
    const salesReason = this.rowData.salesReason ? this.rowData.salesReason : '';
    this.accountDetailsForm.patchValue({ saleReason: salesReason });
    if (salesReason === 'CVA') {
      this.accountDetailsForm.removeControl('saleReasonField');
      this.accountDetailsForm.addControl(
        'cva',
        this.formBuilder.control('', [Validators.required, Validators.pattern('[0-9]*')])
      );
      this.accountDetailsForm.patchValue({ cva: this.rowData.cvaNumber ? this.rowData.cvaNumber : '' });
      this.cva = this.rowData.cvaNumber ? this.rowData.cvaNumber : '';
      this.enableCVAfield = true;
      this.selectReasonField = false;
    } else if (salesReason === 'Other') {
      this.accountDetailsForm.removeControl('cva');
      this.accountDetailsForm.addControl('saleReasonField', this.formBuilder.control('', [Validators.required]));
      this.accountDetailsForm.patchValue({
        saleReasonField: this.rowData.salesDescription ? this.rowData.salesDescription : '',
      });
      this.saleReasonDescription = this.rowData.salesDescription ? this.rowData.salesDescription : '';
      this.enableCVAfield = false;
      this.selectReasonField = true;
    } else {
      this.enableCVAfield = false;
      this.selectReasonField = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  selctReasonChange(event: any) {
    if (event.action === 'close') {
      return;
    }
    this.selectedData.isValidForm = false;
    const selectedOption: string = event?.currentSelection[0]?.label || '';
    this.selectedData.selectReason = event?.currentSelection[0]?.label;
    if (selectedOption === 'CVA') {
      this.accountDetailsForm.removeControl('saleReasonField');
      this.accountDetailsForm.addControl(
        'cva',
        this.formBuilder.control('', [Validators.required, Validators.pattern('[0-9]*')])
      );
      this.enableCVAfield = true;
      this.selectReasonField = false;
    } else if (selectedOption === 'Other') {
      this.accountDetailsForm.removeControl('cva');
      this.accountDetailsForm.addControl('saleReasonField', this.formBuilder.control('', [Validators.required]));
      this.enableCVAfield = false;
      this.selectReasonField = true;
    } else {
      this.enableCVAfield = false;
      this.selectReasonField = false;
    }
    // this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  onChangeCVA(event: any) {
    if (event.value !== '') {
      let inputValue = event.value;
      this.selectedData.cvaNo = inputValue;
      if (this.accountDetailsForm.valid) {
        this.selectedData.isValidForm = true;
      }
    } else {
      this.selectedData.isValidForm = false;
    }
    // this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  get formControl() {
    return this.accountDetailsForm.controls;
  }

  onDateChange($event: any) {
    let currentYear = 0;
    let currentMonth = 0;
    let currentDate = 0;
    if (moment) {
      currentYear = moment($event).year();
      currentMonth = moment($event).month();
      currentDate = moment($event).date();
    }
    this.startDateVal = new Date(currentYear, currentMonth, currentDate);
    this.selectedData.startDate = this.startDateVal;
    this.formatedDate = this.datepipe.transform(this.startDateVal, 'MM/dd/yyyy');
    this.selectedData.expirationDate = this.formatedDate;
    if (this.accountDetailsForm.valid) {
      this.selectedData.isValidForm = true;
    }
    // this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  formatDate(value: any) {
    if (value) {
      return (
        value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2) + '-' + ('0' + value.getDate()).slice(-2)
      );
    } else {
      return;
    }
  }

  onDatepickerOpen($event: any) {}

  onChangeOtherDesc(event: any) {
    if (event.value !== '') {
      this.selectedData.selectReasonOtherDesc = event.value;
      if (this.accountDetailsForm.valid) {
        this.selectedData.isValidForm = true;
      }
    } else {
      this.selectedData.isValidForm = false;
    }
    // this.subscribeAccountsService.setAccountFormData(this.selectedData);
  }

  showToastMessage(message: string, status: string) {
    const config: MessageBarConfig = {
      hostType: 'overlay',
      verticalPosition: 'top',
      horizontalPosition: 'center',
      hostSelectorId: 'container-id',
      duration: 3000,
    };
    this.messageBar.open(message, status, undefined, config, true);
  }

  validateUcidDcnDetails() {
    this.accountsService.validateUcidDcn({ ucid: this.rowData.ucid || '' }).subscribe({
      next: (response: any) => {
        if (response.length > 1) {
          const filteredLicensedType = cloneDeep(this.finalLicenseType);
          filteredLicensedType.forEach((item: any) => {
            if (item.label == 'Unlimited Users') {
              item.disabled = true;
            }
          });
          this.finalLicenseType = filteredLicensedType;
          this.accountDetailsForm.get('licenseType')?.patchValue(this.subscribeAccountsConst.LU || '');
          this.selectedCustomerLicenseType = this.subscribeAccountsConst.LU;
        }
      },
      error: (_error: any) => {
        const messageText = `There was error processing your request. Please contact product support.`;
        this.showToastMessage(messageText, 'error');
      },
    });
  }

  updateSelectedLicensedType() {
    return isEmpty(this.rowData.users) ? this.subscribeAccountsConst.ULU : this.subscribeAccountsConst.LU;
  }

  closeDrawer(refresh = false) {
    this.dialogRef.close(refresh);
  }
  licenseTypeDropdownChange(event: any, content: any) {
    this.selectedLicenseType = event?.currentSelection[0]?.label || '';
    let unlimitedLinesArray = [dspConstants.Common.ACCOUNT_UNLIMITED_TYPE_POPUP_BODY_LINE1];
    let limitedLinesArray = [
      dspConstants.Common.ACCOUNT_LIMITED_TYPE_POPUP_BODY_LINE1,
      dspConstants.Common.ACCOUNT_LIMITED_TYPE_POPUP_BODY_LINE2,
    ];
    if (this.isCommonPopup) {
      const popupData: PopupData = {
        title: dspConstants.Common.CONFIRMATION_POPUP_TITLE,
        messageArray: this.selectedLicenseType === 'Limited Users' ? limitedLinesArray : unlimitedLinesArray,
        showCrossMark: false,
        showPrimaryButton: true,
        showSecondaryButton: true,
        primaryButtonText: dspConstants.PopupButtons.SUBMIT,
        secondaryButtonText: dspConstants.PopupButtons.CANCEL,
      };

      this.accountEditModalRef = this.modal.openModal(CommonConfirmationPopupComponent, {
        width: '466px',
        type: 'semi-modal',
        data: popupData,
        closeOnEsc: false,
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });

      this.accountEditModalRef?.afterClosed()?.subscribe((res: any) => {
        if (res === dspConstants.PopupButtons.SUBMIT) {
          this.selectedLicenseType === 'Limited Users'
            ? this.updateLicenseTypeToLimited()
            : this.updateLicenseTypeToUnlimited();
        } else {
          this.closeLicenseChangeModal();
        }
      });
    } else {
      this.modal.openModal(content, {
        width: '466px',
        type: 'semi-modal',
        disableBackdropClick: true,
        isAutoHeightModalContent: true,
      });
    }
  }

  closeLicenseChangeModal() {
    this.selectedCustomerLicenseType = this.updateSelectedLicensedType();
    this.selectedData.licenseType = this.selectedCustomerLicenseType;
    this.accountDetailsForm.get('licenseType')?.setValue(this.selectedCustomerLicenseType);
    this.modal.close();
  }
  updateLicenseTypeToUnlimited() {
    this.selectedCustomerLicenseType = this.selectedLicenseType;
    this.selectedData.licenseType = this.selectedLicenseType;
    this.editPlanConfig.searchCws = this.selectedLicenseType === this.subscribeAccountsConst.LU ? true : false;
    this.editPlanConfig.subscriptionNote = this.selectedLicenseType === this.subscribeAccountsConst.LU ? false : true;
    this.updateRemoveOperation(this.removedUsers);
    this.selectedData.subscribedUsers = cloneDeep(this.removedUsers);
    this.changeDetectorRef.markForCheck();
    this.modal.close();
  }
  updateRemoveOperation(users: any) {
    if (!isEmpty(users)) {
      users.forEach((user: any) => {
        user.userOperation = 'REMOVE';
      });
    }
  }
  updateLicenseTypeToLimited() {
    this.selectedCustomerLicenseType = this.selectedLicenseType;
    this.selectedData.licenseType = this.selectedLicenseType;
    this.editPlanConfig.searchCws = this.selectedLicenseType === this.subscribeAccountsConst.LU ? true : false;
    this.editPlanConfig.subscriptionNote = this.selectedLicenseType === this.subscribeAccountsConst.LU ? false : true;
    this.selectedData.subscribedUsers = cloneDeep(this.revertedUsers || []);
    this.selectedData.selectedUsers = cloneDeep(this.revertedUsers || []);
    this.changeDetectorRef.markForCheck();
    this.modal.close();
  }
  closeRemoveUsersWarningModal(value: boolean) {
    this.removeUsersModalRef?.close(value);
  }
  editAccount(editModalContent: any, cancelModalContent: any) {
    if (
      (this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_CANCEL ||
        this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL) &&
      this.selectedReason?.length
    ) {
      if (this.isCommonPopup) {
        const popupData: PopupData = {
          title: dspConstants.Common.CONFIRMATION_POPUP_TITLE,
          messageArray: [dspConstants.Common.ACCOUNT_CANCEL_PLAN_POPUP_BODY_LINE1],
          showCrossMark: false,
          showPrimaryButton: true,
          showSecondaryButton: true,
          primaryButtonText: dspConstants.PopupButtons.SUBMIT,
          secondaryButtonText: dspConstants.PopupButtons.CANCEL,
        };

        this.accountCancelModelRef = this.modal.openModal(CommonConfirmationPopupComponent, {
          width: '520px',
          type: 'semi-modal',
          data: popupData,
          closeOnEsc: false,
          disableBackdropClick: true,
          isAutoHeightModalContent: true,
        });

        this.accountCancelModelRef?.afterClosed()?.subscribe((res: any) => {
          if (res === dspConstants.PopupButtons.SUBMIT) {
            this.cancelSubscription();
          } else {
            this.closePlanCancelModal();
          }
        });
      } else {
        this.openCancelPlanModal(cancelModalContent);
      }
    } else {
      this.editPayloadObj = this.updateRequestPayload();
      const filteredUsers = this.editPayloadObj.users.filter(function (userEnitity: any) {
        return userEnitity.userOperation === 'REMOVE';
      });
      if (filteredUsers && filteredUsers.length > 0) {
        if (this.isCommonPopup) {
          const popupData: PopupData = {
            title: dspConstants.Common.CONFIRM_POPUP_TITLE,
            messageArray: [dspConstants.Common.ACCOUNT_REMOVE_USERS_POPUP_BODY_LINE1],
            showCrossMark: false,
            showPrimaryButton: false,
            showSecondaryButton: true,
            showDangerButton: true,
            primaryButtonText: dspConstants.PopupButtons.SUBMIT,
            secondaryButtonText: dspConstants.PopupButtons.CANCEL,
            dangerButtonText: dspConstants.PopupButtons.DELETE,
          };

          this.accountRemoveUserModelRef = this.modal.openModal(CommonConfirmationPopupComponent, {
            width: '466px',
            type: 'semi-modal',
            data: popupData,
            closeOnEsc: false,
            disableBackdropClick: true,
            isAutoHeightModalContent: true,
          });

          this.accountRemoveUserModelRef.afterClosed().subscribe((res: any) => {
            if (res === dspConstants.PopupButtons.DELETE) {
              this.handleEditAccount();
            }
          });
        } else {
          this.removeUsersModalRef = this.modal.openModal(editModalContent, {
            width: '466px',
            type: 'semi-modal',
            disableBackdropClick: true,
            isAutoHeightModalContent: true,
          });
          this.removeUsersModalRef.afterClosed().subscribe((res: boolean) => {
            if (res) {
              this.handleEditAccount();
            }
          });
        }
      } else {
        this.handleEditAccount();
      }
    }
  }
  handleEditAccount() {
    if (this.rowData?.subsDtl?.name !== this.subscribeAccountsConst.SMA) {
      this.accountsService.updateSubscription(this.editPayloadObj).subscribe({
        next: (_response: any) => {
          this.closeDrawer(true);
          const messageText = `Plan updated successfully.`;
          this.showToastMessage(messageText, 'success');
        },
        error: () => {
          this.closeDrawer(true);
          const messageText = `There was error processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
        },
      });
    } else {
      const sales = this.salesData.find((item: any) => item.label === this.selectedData.selectReason);
      const endDate = this.selectedData.expirationDate ? this.selectedData.expirationDate : this.rowData.endDate,
        salesReason = sales ? sales.value : this.rowData.salesReason.id;
      const smaDetails: any = {
        appName: this.rowData.appName,
        type: this.selectedData?.accountType || '',
        users: this.selectedData?.subscribedUsers?.length > 0 ? this.mapUpdatedUsersList() : [],
        subsDtl: {
          name: this.selectedData?.plan,
          pricing: [],
        },
        orgSubsId: this.rowData.orgSubsId,
        assetPrefix: this.rowData.assetPrefix ? this.rowData.assetPrefix : '',
        salesReason: {
          id: salesReason,
          // reason: this.salesData.find((item: any) => item.label === salesReason)?.value,
        },
        endDate: this.datepipe.transform(endDate, 'MM/dd/yyyy'),
        store: 'DSP',
      };
      if (this.selectedData.selectReason === 'CVA') {
        smaDetails.cvaNumber = this.selectedData.cvaNo ? this.selectedData.cvaNo : this.rowData.cvaNumber;
      }
      if (this.selectedData.selectReason === 'Other') {
        smaDetails.salesDescription = this.selectedData.selectReasonOtherDesc
          ? this.selectedData.selectReasonOtherDesc
          : this.rowData.salesDescription;
      }
      this.accountsService.updateSMASubscription(smaDetails).subscribe({
        next: (_response: any) => {
          this.closeDrawer(true);
          const messageText = `Plan updated successfully.`;
          this.showToastMessage(messageText, 'success');
        },
        error: () => {
          this.closeDrawer(true);
          const messageText = `There was error processing your request. Please contact product support.`;
          this.showToastMessage(messageText, 'error');
        },
      });
    }
  }
  updateRequestPayload() {
    if (this.isShowNewColumnInAccounts) {
      let planTypeValue = this.selectedCustomerLicenseType;
      if (planTypeValue === 'Limited Users') {
        planTypeValue = 'Limited';
      } else if (planTypeValue === 'Unlimited Users') {
        planTypeValue = 'Unlimited';
      } else {
        planTypeValue = '';
      }
      return {
        appName: this.selectedData?.product || '',
        planName: this.selectedData?.plan || '',
        operation: this.subscribeAccountsConst.OPERATIONTYPE_UPDATE,
        licensedType: this.selectedCustomerLicenseType,
        type: this.selectedData?.accountType || '',
        planType: planTypeValue,
        planLevel: dspConstants.Common.CUSTOMER,
        users: this.selectedData?.subscribedUsers?.length > 0 ? this.mapUpdatedUsersList() : [],
        subsDtl: {
          name: this.selectedData?.plan,
          pricing: [],
          type: this.selectedData?.accountType,
        },
        orgSubsId: this.rowData.orgSubsId,
      };
    } else {
      return {
        appName: this.selectedData?.product || '',
        planName: this.selectedData?.plan || '',
        operation: this.subscribeAccountsConst.OPERATIONTYPE_UPDATE,
        licensedType: this.selectedCustomerLicenseType,
        type: this.selectedData?.accountType || '',
        users: this.selectedData?.subscribedUsers?.length > 0 ? this.mapUpdatedUsersList() : [],
        subsDtl: {
          name: this.selectedData?.plan,
          pricing: [],
          type: this.selectedData?.accountType,
        },
        orgSubsId: this.rowData.orgSubsId,
      };
    }
  }

  mapUpdatedUsersList() {
    const filteredUsers = this.selectedData.subscribedUsers.filter(function (userEnitity: any) {
      return userEnitity.userOperation != null;
    });
    return filteredUsers.map(function (user: any) {
      return {
        cwsId: user.cwsId,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.emailAddress || user.email,
        catRecId: user.catRecId,
        userOperation: user.userOperation,
      };
    });
  }

  enableEditActionButton() {
    if (
      (this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_CANCEL ||
        this.subscribeAccountsConst.OPERATIONTYPE_SMA_CANCEL) &&
      this.selectedReason?.length
    ) {
      return true;
    } else {
      if (
        this.rowData?.subsDtl?.name !== this.subscribeAccountsConst.SMA &&
        this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_UPDATE
      ) {
        if (
          this.rowData.licensedType === this.subscribeAccountsConst.LU &&
          this.selectedCustomerLicenseType === this.subscribeAccountsConst.ULU
        ) {
          return true;
        } else if (
          this.rowData.licensedType === this.subscribeAccountsConst.ULU &&
          this.selectedCustomerLicenseType === this.subscribeAccountsConst.LU
        ) {
          return this.selectedData.selectedUsers?.length > 0 && this.lastUserRemovalCheck();
        } else if (
          this.selectedCustomerLicenseType == this.subscribeAccountsConst.LU &&
          this.modifiedSelectedUsersCheck() &&
          this.lastUserRemovalCheck()
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        (this.editPlanConfig.actionType === this.subscribeAccountsConst.OPERATIONTYPE_UPDATE &&
          this.rowData?.subsDtl?.name == this.subscribeAccountsConst.SMA &&
          this.accountDetailsForm.dirty &&
          this.accountDetailsForm.valid &&
          this.checkIfValueChanged()) ||
        (this.modifiedSelectedUsersCheck() && this.lastUserRemovalCheck())
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkIfValueChanged() {
    let prevEndDate = this.datepipe.transform(this.rowData.endDate, 'MM/dd/yyyy');
    let formValue = this.accountDetailsForm.value;
    if (
      (prevEndDate !== formValue.expirationDate && this.rowData.salesReason.reason !== formValue.saleReason) ||
      this.rowData.cvaNumber !== formValue.cva ||
      this.rowData.salesDescription !== formValue.saleReasonField
    ) {
      return true;
    } else {
      return false;
    }
  }

  modifiedSelectedUsersCheck() {
    return this.selectedData?.subscribedUsers?.some((user: any) => user?.userOperation !== null);
  }

  lastUserRemovalCheck() {
    const removeUserOperationData = this.selectedData.selectedUsers.filter(
      (user: any) => user.userOperation == 'REMOVE'
    );
    const addUserOperationData = this.selectedData.selectedUsers.filter((user: any) => user.userOperation == 'ADD');
    return this.selectedData.selectedUsers?.length > removeUserOperationData.length || addUserOperationData.length > 0;
  }

  enableSubmit() {
    if (
      this.selectedCustomerLicenseType == this.subscribeAccountsConst.LU &&
      this.selectedData.licenseType == this.subscribeAccountsConst.ULU
    ) {
      return true;
    } else if (
      this.selectedCustomerLicenseType == this.subscribeAccountsConst.ULU &&
      this.selectedData.licenseType == this.subscribeAccountsConst.LU
    ) {
      return (
        this.selectedData.subscribedUsers && this.selectedData.subscribedUsers.length > 0 && this.lastUserRemovalCheck()
      );
    } else if (
      this.selectedCustomerLicenseType == this.subscribeAccountsConst.LU &&
      this.modifiedSelectedUsersCheck() &&
      this.lastUserRemovalCheck()
    ) {
      return true;
    }
  }
  onDropdownChange({ currentSelection }: any) {
    this.selectedReason = currentSelection[0].label || '';
  }
  openCancelPlanModal(content: any) {
    this.modalRef = this.modal.openModal(content, {
      width: '520px',
      type: 'semi-modal',
      closeOnEsc: false,
      disableBackdropClick: true,
      isAutoHeightModalContent: true,
    });
  }

  closePlanCancelModal() {
    this.modalRef?.close();
  }
  cancelSubscription() {
    const cancelPayloadObj = {
      orgSubsId: this.rowData.orgSubsId,
      subscriptionCancelReason: this.selectedReason,
    };
    this.accountsService.cancelSubscription(cancelPayloadObj).subscribe({
      error: (err: any) => {
        if (err.status === 202) {
          const messageText = 'Plan cancelled successfully.';
          this.showToastMessage(messageText, 'success');
          this.closePlanCancelModal();
          this.closeDrawer(true);
        } else {
          this.closePlanCancelModal();
          const messageText = 'There is an error in the subscription cancellation. Please try again later.';
          this.showToastMessage(messageText, 'error');
        }
      },
    });
  }
  parseDate(data: any) {
    if (data !== null) {
      const dateList = data?.split('-');
      let dateStr = '';
      if (dateList?.length > 0) {
        dateStr = `${dateList[0] + '/' + dateList[1] + '/' + dateList[2]}`;
      } else {
        dateStr = '-';
      }
      return dateStr;
    } else {
      return '-';
    }
  }
}
